import { StandardEditorProps } from '@grafana/data';
import { InlineField, Input, Select, Slider, Switch } from '@grafana/ui';
import React, { useMemo } from 'react';
import { LABEL_WIDTH } from '../StyleEditor/GeojsonStyle';
import { OSMapInstanceState, OSMapPanelOptions } from '../types/types';
import { makeFirstCapital } from '../LayerEditor/types';

/**
 * Interface representing properties for a timelapse component.
 *
 * @interface TimelapseProps
 * @property {boolean} isTimeLapse - Indicates if the timelapse feature is enabled.
 * @property {number} timeToFinish - The time duration (in seconds) for the timelapse to finish.
 * @property {number} speed - The speed at which the timelapse progresses.
 * @property {string} layerName - The name of the layer associated with the timelapse.
 */
export interface TimelapseProps {
  isTimeLapse: boolean;
  timeToFinish: number;
  speed: number;
  layerName: string;
}

/**
 * Properties for a time-lapse editor component.
 *
 * @typedef {StandardEditorProps<TimelapseProps, any, OSMapPanelOptions, OSMapInstanceState>} TimeLapseEditorProps
 */
type TimeLapseEditorProps = StandardEditorProps<TimelapseProps, any, OSMapPanelOptions, OSMapInstanceState>;

export const TimelapseEditor = (props: TimeLapseEditorProps) => {
  const { layers } = (props.context.instanceState as OSMapInstanceState) ?? {};

  const { value, onChange } = props;

  const heatMapLayer = useMemo(
    () => layers?.filter((layer) => layer.options.type === 'heatmap' || layer.options.type === 'marker'),
    [layers]
  );
  const layerOptions = useMemo(
    () =>
      heatMapLayer?.map((layer) => {
        return { label: `${makeFirstCapital(layer.options.type)} ${layer.options.name}`, value: layer.options.name };
      }),
    [heatMapLayer]
  );

  function onChangeProperty(valueKey: string, val: any) {
    if (val === '') {
      // isClearable
      onChange({ ...value, [valueKey]: val, isTimeLapse: false });
    } else {
      onChange({ ...value, [valueKey]: val });
    }
  }

  return (
    <>
      {layers && layers.length > 0 && (
        <>
          <InlineField label="Layer" labelWidth={LABEL_WIDTH}>
            <Select
              noOptionsMessage="no heatmap or marker layer present"
              options={layerOptions}
              value={value.layerName}
              isClearable
              onChange={(e) => {
                if (!e) {
                  onChangeProperty('layerName', '');
                } else {
                  onChangeProperty('layerName', e.value);
                }
              }}
            />
          </InlineField>
          {value.layerName &&
            layers
              .filter((layer) => layer.options.name === value.layerName)
              .map((layer, i) => {
                return (
                  <div key={i}>
                    <InlineField label="Enable" style={{ alignItems: 'center' }} labelWidth={LABEL_WIDTH}>
                      <Switch
                        value={value.isTimeLapse}
                        onChange={(e) => onChangeProperty('isTimeLapse', e.currentTarget.checked)}
                      />
                    </InlineField>
                    {value.isTimeLapse && (
                      <>
                        <InlineField
                          label="Speed"
                          labelWidth={LABEL_WIDTH}
                          tooltip={'Increase or decrease slider speed by x1'}
                        >
                          <Slider
                            min={-5}
                            max={5}
                            step={1}
                            value={value.speed}
                            onChange={(v) => onChangeProperty('speed', v)}
                          />
                        </InlineField>
                        <InlineField
                          label="Time To Finish"
                          labelWidth={LABEL_WIDTH + 4}
                          tooltip={'Required time in seconds to finish the time lapse'}
                        >
                          <Input
                            type="number"
                            value={value.timeToFinish}
                            onChange={(e) => onChangeProperty('timeToFinish', Number(e.currentTarget.value))}
                            suffix="seconds"
                            min={0}
                          />
                        </InlineField>
                      </>
                    )}
                  </div>
                );
              })}
        </>
      )}
    </>
  );
};
