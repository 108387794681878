import React from 'react';
import { css } from '@emotion/css';
import { last, isArray, isObject, isUndefined } from 'lodash';
import { GrafanaTheme } from '@grafana/data';
import { Icon, useStyles } from '@grafana/ui';
import { Diff, getDiffText } from './utils';
import { getDiffTitleStyles } from './theme';

type DiffGroupProps = {
  diffs: Diff[];
  title: string;
};

export const DiffViewerGroup = ({ diffs, title }: DiffGroupProps) => {
  const styles = useStyles(getStyles);

  if (diffs.length === 1) {
    return (
      <div className={styles.container} data-testid="diffViewerGroup">
        <DiffTitle title={title} diff={diffs[0]} />
      </div>
    );
  }

  return (
    <div className={styles.container} data-testid="diffViewerGroup">
      <DiffTitle title={title} />
      <ul className={styles.list}>
        {diffs.map((diff: Diff, idx: number) => {
          return (
            <li className={styles.listItem} key={`${last(diff.path)}__${idx}`}>
              <span>{getDiffText(diff)}</span> <DiffValues diff={diff} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme) => ({
  container: css`
    background-color: ${theme.colors.bg2};
    margin-bottom: ${theme.spacing.md};
    padding: ${theme.spacing.md};
    overflow-x: scroll;
  `,
  list: css`
    margin-left: ${theme.spacing.lg};
  `,
  listItem: css`
    margin-bottom: ${theme.spacing.sm};
  `,
});

type DiffTitleProps = {
  diff?: Diff;
  title: string;
};

const replaceDiff: Diff = { op: 'replace', originalValue: undefined, path: [''], value: undefined, startLineNumber: 0 };

export const DiffTitle = ({ diff, title }: DiffTitleProps) => {
  const styles = useStyles(getDiffTitleStyles);

  return diff ? (
    <>
      <Icon type="mono" name="circle" className={styles[diff.op]} /> <span className={styles.embolden}>{title}</span>{' '}
      <span>{getDiffText(diff, diff.path.length > 1)}</span> <DiffValues diff={diff} />
    </>
  ) : (
    <div className={styles.withoutDiff}>
      <Icon type="mono" name="circle" className={styles.replace} /> <span className={styles.embolden}>{title}</span>{' '}
      <span>{getDiffText(replaceDiff, false)}</span>
    </div>
  );
};

type DiffProps = {
  diff: Diff;
};

export const DiffValues = ({ diff }: DiffProps) => {
  const styles = useStyles(getValuesStyles);
  const hasLeftValue =
    !isUndefined(diff.originalValue) && !isArray(diff.originalValue) && !isObject(diff.originalValue);
  const hasRightValue = !isUndefined(diff.value) && !isArray(diff.value) && !isObject(diff.value);

  return (
    <>
      {hasLeftValue && <span className={styles}>{String(diff.originalValue)}</span>}
      {hasLeftValue && hasRightValue ? <Icon name="arrow-right" /> : null}
      {hasRightValue && <span className={styles}>{String(diff.value)}</span>}
    </>
  );
};

const getValuesStyles = (theme: GrafanaTheme) => css`
  background-color: ${theme.colors.bg1};
  border-radius: ${theme.border.radius.md};
  color: ${theme.colors.text};
  font-size: ${theme.typography.size.md};
  margin: 0 ${theme.spacing.sm};
  padding: ${theme.spacing.sm};
`;
