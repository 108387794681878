import React, { useState } from 'react';
import { clone } from 'lodash';
import { css, cx } from 'emotion';
import Draggable, { DraggableEvent, DraggableData, ControlPosition } from 'react-draggable';
import { useStyles } from '@grafana/ui';
import SensorType from './Types/Sensor';
import { LinkDrawer, DrawerData } from 'app/features/linkDrawer/LinkDrawer';
import { GrafanaTheme, TimeRange, getContrastText } from '@grafana/data';

type SensorProps = {
  sensor: SensorType;
  draggable: boolean;
  index: number;
  imageDimensions: {
    width: number;
    height: number;
  };
  onPositionChange: Function;
  data: {
    numeric: number;
    prefix: string | undefined;
    suffix: string | undefined;
    text: string | undefined;
    title: string | undefined;
  };
  dashVisit: boolean;
  min: number;
  max: number;
  defaults: any;
  backgroundColor: string;
  fontStyle: string;
  theme: GrafanaTheme;
  timeRange: TimeRange;
  drawerLinkTime: boolean;
};

const pxToPerc = (px: number, size: number): number => {
  return (px * 100) / size;
};

const percToPx = (perc: number, size: number): number => {
  return (perc * size) / 100;
};

export const Sensor: React.FC<SensorProps> = ({
  sensor,
  imageDimensions,
  onPositionChange,
  data,
  index,
  min,
  max,
  backgroundColor,
  dashVisit,
  fontStyle,
  draggable,
  theme,
  timeRange,
  drawerLinkTime,
}: SensorProps) => {
  const styles = useStyles(getStyles);
  let copySensor = clone(sensor);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const onMouseEnter = (event: any) => {
    setIsMouseOver(true);
  };

  const onMouseLeave = (event: any) => {
    setIsMouseOver(false);
  };

  const onDragStop = (_event: DraggableEvent, data: DraggableData) => {
    const newPosition = {
      x: pxToPerc(data.x, imageDimensions.width),
      y: pxToPerc(data.y, imageDimensions.height),
    };
    onPositionChange(newPosition, index);
  };

  const sensorPosition: ControlPosition = {
    x: percToPx(copySensor.position.x, imageDimensions.width),
    y: percToPx(copySensor.position.y, imageDimensions.height),
  };

  const { width } = imageDimensions;
  const { fontColor } = sensor;
  const { Manual } = sensor;
  let FontColor = Manual ? fontColor : getContrastText(backgroundColor, theme, 3);

  const MaxtoMin = String(width / 20 >= max ? max : width / 20);
  const fontSize = Number(MaxtoMin) >= Number(min) ? MaxtoMin : min;

  //*Dashboard link drawer value set.
  const [infoData, setinfoData] = useState<DrawerData>({ location: '', url: '' });
  const [showDrawer, setShowDrawer] = React.useState(false);

  const onCloseDrawer = () => {
    setShowDrawer(false);
  };

  const drawerhandle = (urlName: string, url: string) => {
    if (urlName.length !== 0) {
      setinfoData({ location: urlName, url: url });
    } else {
      setinfoData({ location: url, url: url });
    }
    setShowDrawer(!showDrawer);
  };

  return (
    <>
      {sensor.visible && (
        <Draggable disabled={draggable} bounds="parent" handle=".handle" onStop={onDragStop} position={sensorPosition}>
          {/* sensor.backgroundColor */}
          <div
            id="container"
            className={cx(
              styles.container,
              css`
                color: ${FontColor};
                background: ${backgroundColor};
                font-size: ${width < 550 ? width / 35 : width / 45}px;
              `
            )}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <div className={cx(styles.content)}>
              {dashVisit ? (
                <a
                  className={css`
                    color: ${FontColor};
                  `}
                  // href={sensor.link ? sensor.link : '#'}
                  onClick={() => drawerhandle(sensor.dashboardName, sensor.dashboardUrl ? sensor.dashboardUrl : '')}
                  style={{ fontSize: `${fontSize}px` }}
                >
                  {sensor.dashboardName !== '' ? sensor.dashboardName : data.title}
                </a>
              ) : (
                <span
                  className={css`
                    color: ${FontColor};
                  `}
                  style={{ fontSize: `${fontSize}px` }}
                >
                  {sensor.dashboardName !== '' ? sensor.dashboardName : data.title}
                </span>
              )}
              <span
                style={{
                  fontSize: `${fontSize}px`,
                  color: `${FontColor}`,
                  fontWeight: 'lighter',
                  fontStyle: `${fontStyle}`,
                }}
              >
                {data.prefix}
                {data.text}
                {data.suffix}
              </span>
            </div>

            {!draggable && isMouseOver && (
              <div className={cx(styles.handle, 'handle')}>
                <div className="fa fa-bars" />
              </div>
            )}
          </div>
        </Draggable>
      )}
      {showDrawer && (
        <LinkDrawer infoData={infoData} onClose={onCloseDrawer} timeRange={drawerLinkTime ? timeRange : undefined} />
      )}
    </>
  );
};

const getStyles = () => {
  return {
    container: css`
      position: absolute;
      padding: 0.25em;
    `,
    handle: css`
      float: right;
      margin-left: 0.5em;
    `,
    content: css`
      float: left;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: enter;
    `,
  };
};
