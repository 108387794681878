import { DataFrame, ensureTimeField, FieldType } from '@grafana/data';
import { collectStackingGroups, StackingMode } from '@grafana/ui';
import { AlignedData } from 'uplot';

/**
 * used in bar chart component to create data frame
 * @param {DataFrame} frame
 * @returns {AlignedData} result
 */
// both functions are almost same
// return thing is different
export function preparePlotDataForBar(frame: DataFrame): AlignedData {
  const result: any[] = [];
  const stackingGroups: Map<string, number[]> = new Map();
  let seriesIndex = 0;

  for (let i = 0; i < frame.fields.length; i++) {
    const f = frame.fields[i];

    if (f.type === FieldType.time) {
      result.push(ensureTimeField(f).values.toArray());
      seriesIndex++;
      continue;
    }

    collectStackingGroups(f, stackingGroups, seriesIndex);
    result.push(f.values.toArray());
    seriesIndex++;
  }

  // Stacking
  if (stackingGroups.size !== 0) {
    const byPct = frame.fields[1].config.custom?.stacking?.mode === StackingMode.Percent;
    const dataLength = result[0].length;
    const alignedTotals = Array(stackingGroups.size);
    alignedTotals[0] = null;

    // array or stacking groups
    for (const [_, seriesIdxs] of stackingGroups.entries()) {
      const groupTotals = byPct ? Array(dataLength).fill(0) : null;

      if (byPct) {
        for (let j = 0; j < seriesIdxs.length; j++) {
          const currentlyStacking = result[seriesIdxs[j]];

          for (let k = 0; k < dataLength; k++) {
            const v = currentlyStacking[k];
            groupTotals![k] += v == null ? 0 : +v;
          }
        }
      }

      const acc = Array(dataLength).fill(0);

      for (let j = 0; j < seriesIdxs.length; j++) {
        let seriesIdx = seriesIdxs[j];

        alignedTotals[seriesIdx] = groupTotals;

        const currentlyStacking = result[seriesIdx];

        for (let k = 0; k < dataLength; k++) {
          let v = currentlyStacking[k];
          if (v === null) {
            v = 0;
            acc[k] += v === 0 ? 0 : v / (byPct ? groupTotals![k] : 1);
          }
          if (k === 0 && result.length === 2 && byPct) {
            acc[k] += v === 0 ? 0 : 0.9999;
          } else {
            acc[k] += v === 0 ? 0 : v / (byPct ? groupTotals![k] : 1);
          }
        }

        result[seriesIdx] = acc.slice();
      }
    }
  }
  return result as AlignedData;
}
/**
 * used in tooltip for data
 * @param {DataFrame} frame
 * @returns {AlignedData} aligned totals
 */
export function preparePlotDataForTotals(frame: DataFrame): AlignedData {
  const result: any[] = [];
  const stackingGroups: Map<string, number[]> = new Map();
  let seriesIndex = 0;

  for (let i = 0; i < frame.fields.length; i++) {
    const f = frame.fields[i];

    if (f.type === FieldType.time) {
      result.push(ensureTimeField(f).values.toArray());
      seriesIndex++;
      continue;
    }

    collectStackingGroups(f, stackingGroups, seriesIndex);
    result.push(f.values.toArray());
    seriesIndex++;
  }

  // Stacking
  const alignedTotals = Array(stackingGroups.size);

  if (stackingGroups.size !== 0) {
    const byPct = frame.fields[1].config.custom?.stacking?.mode === StackingMode.Percent;
    const dataLength = result[0].length;
    alignedTotals[0] = null;

    // array or stacking groups
    for (const [_, seriesIdxs] of stackingGroups.entries()) {
      const groupTotals = byPct ? Array(dataLength).fill(0) : null;

      if (byPct) {
        for (let j = 0; j < seriesIdxs.length; j++) {
          const currentlyStacking = result[seriesIdxs[j]];

          for (let k = 0; k < dataLength; k++) {
            const v = currentlyStacking[k];
            groupTotals![k] += v == null ? 0 : +v;
          }
        }
      }

      const acc = Array(dataLength).fill(0);

      for (let j = 0; j < seriesIdxs.length; j++) {
        let seriesIdx = seriesIdxs[j];

        alignedTotals[seriesIdx] = groupTotals;

        const currentlyStacking = result[seriesIdx];

        for (let k = 0; k < dataLength; k++) {
          let v = currentlyStacking[k];
          if (v === null) {
            v = 0;
            acc[k] += v === 0 ? 0 : v / (byPct ? groupTotals![k] : 1);
          }
          acc[k] += v === 0 ? 0 : v / (byPct ? groupTotals![k] : 1);
        }

        result[seriesIdx] = acc.slice();
      }
    }
  }
  return alignedTotals as AlignedData;
}
