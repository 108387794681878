import { css } from 'emotion';
import { GrafanaTheme } from '@grafana/data';

/**
 * Styles
 */
export const getStyles = (theme: GrafanaTheme) => ({
  root: css`
    display: flex;
    flex-direction: column;
  `,
  frameSelect: css`
    padding: 2px;
  `,
  frame: css`
    border-bottom: 1px solid ${theme.colors.border2};
    margin-bottom: 1rem;
    padding: 2px;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
    }

    img {
      max-width: 100%;
    }

    li {
      margin-left: 2px;
    }

    table {
      border-collapse: collapse;

      th,
      td {
        padding: 1px 2px;
        border-top: 1px solid ${theme.colors.border2};
        border-left: 1px solid ${theme.colors.border2};
      }

      th {
        font-weight: ${theme.typography.weight.regular};
        background: ${theme.colors.bg2};
      }

      border-bottom: 1px solid ${theme.colors.border2};
      border-right: 1px solid ${theme.colors.border2};
    }

    blockquote {
      margin: 2px 0;
      border-left: 5px solid ${theme.colors.border3};
      padding: 2px;
      padding-left: 2px;

      p {
        font-size: ${theme.typography.size.md};
        color: ${theme.colors.textStrong};
      }
    }
  `,
});
