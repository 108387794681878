import React from 'react';

import {
  DataFrame,
  PanelOptionsEditorProps,
  PanelPlugin,
  ReducerID,
  VizOrientation,
  standardEditorsRegistry,
} from '@grafana/data';
import { graphFieldOptions } from '@grafana/ui';
import { addAxisConfig, addHideFrom, addLegendOptions } from '../timeseries/config';
import { BarChartPanel } from './BarChartPanel';
import { EditorGroupList } from './customEditor/EditorGroupList';
import { barGraphMigration } from './migrations';
import {
  BarChartFieldConfig,
  BarChartOptions,
  BarGraphMode,
  BarValueVisibility,
  defaultBarChartFieldConfig,
  labelsMinimumSpacing,
} from './types';

export const plugin = new PanelPlugin<BarChartOptions, BarChartFieldConfig>(BarChartPanel)
  .useFieldConfig({
    useCustomConfig: (builder) => {
      const cfg = defaultBarChartFieldConfig;

      builder
        .addSliderInput({
          path: 'lineWidth',
          name: 'Line width',
          defaultValue: cfg.lineWidth,
          settings: {
            min: 0,
            max: 10,
            step: 1,
          },
        })
        .addSliderInput({
          path: 'fillOpacity',
          name: 'Fill opacity',
          defaultValue: cfg.fillOpacity,
          settings: {
            min: 0,
            max: 100,
            step: 1,
          },
        })
        .addRadio({
          path: 'gradientMode',
          name: 'Gradient mode',
          defaultValue: graphFieldOptions.fillGradient[0].value,
          settings: {
            options: graphFieldOptions.fillGradient,
          },
        });
      addAxisConfig(builder, cfg, true);
      addHideFrom(builder);
    },
  })
  .setPanelOptions((builder) => {
    builder
      .addRadio({
        path: 'graphMode',
        name: 'Graph Mode',
        defaultValue: BarGraphMode.Bar,
        settings: {
          options: [
            { value: BarGraphMode.Bar, label: 'Bar Graph' },
            { value: BarGraphMode.Timeseries, label: 'Timeseries Bar Graph' },
          ],
        },
      })
      .addCustomEditor({
        id: 'reduceOptions.calcs',
        path: 'reduceOptions.calcs',
        name: 'Value',
        description: 'Choose a reducer function / calculation',
        editor: standardEditorsRegistry.get('stats-picker').editor as any,
        defaultValue: [ReducerID.mean],
        showIf: (c) => {
          if (c.graphMode === BarGraphMode.Timeseries) {
            return false;
          }
          return true;
        },
        // Hides it when all values mode is on
        // showIf: currentConfig => currentConfig.reduceOptions.values === false,
      })
      .addRadio({
        path: 'toOrientation',
        name: 'Orientation',
        settings: {
          options: [
            { value: VizOrientation.Auto, label: 'Auto' },
            { value: VizOrientation.Horizontal, label: 'Horizontal' },
            { value: VizOrientation.Vertical, label: 'Vertical' },
          ],
        },
        defaultValue: VizOrientation.Auto,
      })
      .addSliderInput({
        path: 'rotationXaxis',
        name: 'Rotate X-Axis ticks',
        defaultValue: 0,
        settings: {
          min: 0,
          max: 90,
          step: 15,
        },
        showIf: (c, data) => {
          if (c.graphMode !== BarGraphMode.Timeseries && c.groupData.groups.length === 0) {
            return false;
          }
          return true;
        },
      })
      .addRadio({
        path: 'labelSpacingXaxis',
        name: 'X-Axis label spacing',
        defaultValue: labelsMinimumSpacing.None,
        settings: {
          options: [
            { value: labelsMinimumSpacing.None, label: 'None', description: 'Show all tick marks' },
            { value: labelsMinimumSpacing.Small, label: 'Small', description: 'Require 100px spacing' },
            { value: labelsMinimumSpacing.Medium, label: 'Medium', description: 'Require 300px spacing' },
            { value: labelsMinimumSpacing.large, label: 'Large', description: 'Require 300px spacing' },
          ],
        },
        showIf: (c, data) => {
          if (c.graphMode !== BarGraphMode.Timeseries && c.groupData.groups.length === 0) {
            return false;
          }
          return true;
        },
      })
      .addBooleanSwitch({
        path: 'spacingLTR',
        name: 'X-axis labels spacing LTR',
        defaultValue: false,
        showIf: (c, data) => {
          if (c.graphMode === BarGraphMode.Timeseries && c.groupData.groups.length === 0) {
            if (c.labelSpacingXaxis === labelsMinimumSpacing.None) {
              return false;
            } else {
              return true;
            }
          }
          return false;
        },
      })
      .addRadio({
        path: 'showValue',
        name: 'Show values',
        settings: {
          options: [
            { value: BarValueVisibility.Auto, label: 'Auto' },
            { value: BarValueVisibility.Always, label: 'Always' },
            { value: BarValueVisibility.Never, label: 'Never' },
          ],
        },
        defaultValue: BarValueVisibility.Auto,
      })
      .addRadio({
        path: 'stacking',
        name: 'Stacking',
        defaultValue: 'none',
        settings: {
          options: graphFieldOptions.stacking,
        },
        showIf: (c, data) => {
          if (c.graphMode !== BarGraphMode.Timeseries && c.groupData.groups.length === 0) {
            return false;
          }
          return true;
        },
      })
      .addSliderInput({
        path: 'groupWidth',
        name: 'Group width',
        defaultValue: 0.7,
        settings: {
          min: 0,
          max: 1,
          step: 0.01,
        },
        showIf: (c, data) => {
          if ((c.stacking && c.stacking !== 'none') || c.graphMode !== BarGraphMode.Timeseries) {
            return false;
          }
          return countNumberFields(data) !== 1;
        },
      })
      .addSliderInput({
        path: 'toBarWidth',
        name: 'Bar width',
        defaultValue: 0.7,
        settings: {
          min: 0,
          max: 1,
          step: 0.01,
        },
      })
      .addRadio({
        path: 'tooltip.mode',
        name: 'Tooltip mode',
        description: '',
        defaultValue: 'single',
        settings: {
          options: [
            { value: 'single', label: 'Single' },
            { value: 'multi', label: 'All' },
            { value: 'none', label: 'Hidden' },
          ],
        },
      })
      .addCustomEditor({
        id: 'groupData',
        path: 'groupData',
        name: 'Groups',
        description: 'List of Groups',
        defaultValue: {
          groups: [],
        },
        editor(props: PanelOptionsEditorProps<any>) {
          return <EditorGroupList bigData={props.context} groupData={props.value} onChange={props.onChange} />;
        },
        showIf: (c) => {
          if (c.graphMode === BarGraphMode.Timeseries) {
            return false;
          }
          return true;
        },
        category: ['Group Data'],
      });
    addLegendOptions(builder);
  })
  .setMigrationHandler(barGraphMigration);

function countNumberFields(data?: DataFrame[]): number {
  let count = 0;
  if (data) {
    for (const frame of data) {
      for (const field of frame.fields) {
        if (field.type === 'number') {
          count++;
        }
      }
    }
  }
  return count;
}
