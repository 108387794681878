/**
 * marker setting
 * @typedef {Object} MarkerSetting
 * @property {number} minMarkerSize minimum marker size
 * @property {number} maxMarkerSize maximum marker size
 * @property {number} strokeWeight marker stroke weight
 * @property {number} fillOpacity opacity of marker
 * @property {boolean} fillColor select color filled in marker
 */
export interface MarkerSetting {
  minMarkerSize: number;
  maxMarkerSize: number;
  strokeWeight: number;
  fillOpacity: number;
  fillColor: boolean;
}
/**
 * heat map required controls
 * @typedef {Object} HeatMap
 * @property {boolean} enabled enable of disable heat map
 * @property {number} opacity opacity of heat map
 * @property {boolean} inWeight should heatmap include value of data
 * @property {string} color color of heat map
 * @property {number} maxRadiusSize maximum radius size
 */
export interface HeatMap {
  enabled: boolean;
  tooltip: boolean;
  opacity: number;
  inWeight: boolean;
  maxRadiusSize: number;
  maxIntensity: number;
  weightFactor: number;
  blurHeatmap: number;
}
/**
 * parameters for geojson layer
 * @typedef {Object} GeoJson
 * @property {boolean} isGeoJsonLayer is user selected geojson layer
 * @property {string} fillColor filling color for polygon
 * @property {string} strokecolor stroke color of polygon
 * @property {number} strokeWeight stroke weight of icon
 * @property {number} strokeOpacity stroke opacity
 * @property {number} opacityIcon opacity of icon
 * @property {number} fillOpacity fill color opacity
 * @property {number} iconSymbol icon symbol
 * @property {string} fillcoloricon color of icon
 * @property {number} iconSize icon size
 * @property {string} strokecoloricon icon stroke color
 * @property {string} path geojson file path
 * @property {string} pathExt external file path
 * @property {boolean} useGooglePin only used in google geojson layer
 */
export interface GeoJson {
  isGeoJsonLayer: boolean;
  fillcolor: string;
  strokecolor: string;
  strokeWeight: number;
  strokeOpacity: number;
  opacityIcon: number;
  fillOpacity: number;
  iconSymbol: number;
  iconSize: number;
  fillcoloricon: string;
  strokecoloricon: string;
  path: string;
  pathExt: string;
  useGooglePin: boolean; // only used in google geojson layer
}
/**
 * query field for marker layer
 * @typedef {Object} QueryField
 * @property {string} location name of the query
 * @property {string} geohash geohash value
 * @property {string} value metric value
 */
export interface QueryField {
  location: string;
  geohash: string;
  value: string;
}

/**
 * custom marker configurations
 * @property {Array<SingleCustomMarker>} markers all markers config
 * @property {number} currIdx current index of selection
 * @property {boolean} enabled custom markers enabled or not
 */
export interface CustomMarkerI {
  markers: SingleCustomMarker[];
  currIdx: number;
  enabled: boolean;
}

// MarkerLinkI.location and SingleCustomMarker.name are equivalent
/**
 * marker dashboard visit
 * @typedef {Object} MarkerLinkI
 * @property {string} location name of the marker/query
 * @property {string} url dashboard url
 */
export interface MarkerLinkI {
  location: string; // name of the marker location. This variabled should be renamed to "name"
  url: string; // dashboard to link
  customName: string; // any custom name
}

/**
 * single custom marker configuration
 * @typedef {Object} SingleCustomMarker
 * @property {number} lat latitude
 * @property {number} lng logitude
 * @property {Array<any>} query Represents the queries being used
 * @property {string} url Represents the url to open in drawer
 * @property {string} name Represents the marker link name (This is equivalent to MarkerLinkI.customName)
 */
export interface SingleCustomMarker {
  lat: number;
  lng: number;
  query: any[]; // Represents the queries being used
  url: string; // Represents the url to open in drawer
  name: string; // This is equivalent to MarkerLinkI.customName
  customName?: string; // any custom name
}

/**
 * custom symbol for marker
 * @typedef {Object} SymbolObj
 * @property {string} sourceType Source to be used, unicon or marker or Url
 * @property {string} iconName Icon name
 * @property {string} url Icon Url when source == Url
 */
export interface SymbolObj {
  sourceType: string;
  iconName: string;
  url: string;
}

/**
 * map center
 * @typedef {Object} Center
 * @property {number} lat
 * @property {number} lng
 */
export interface Center {
  lat: number;
  lng: number;
}

export enum LegendMode {
  Both = 0,
  Heatmap = 1,
  Threshold = 2,
  None = 3,
}
