/* eslint-disable */
import React from 'react';
import * as d3 from 'd3';
import { FieldDisplay } from '@grafana/data';
const Gradient = require('javascript-color-gradient');
interface GaugeProps {
  width: number;
  height: number;
  colors: any;
  fontSize: any;
  needleColor: any;
  nrOfLevels: any;
  percent: any;
  id: any;
  defaults: any;
  gaugeThickness: number;
  rotation: number;
  GaugeArc: number;
  count: number;
  fontSize1: any;
  frame: FieldDisplay;
  svgid: any;
  config: any;
}

class Gauger extends React.Component<GaugeProps> {
  constructor(props: any) {
    super(props);
  }

  removeChart() {
    d3.select(`#uniqueid${this.props.id}`).selectAll('*').remove();
    this.UpdateChart();
  }

  UpdateChart() {
    var pi = Math.PI,
      rad = pi / 180;
    // deg = 180 / pi;

    var properties = {
      width: this.props.width,
      height: this.props.count ? 450 : 300, //this.props.height,
      margin: 50,

      rotation: this.props.rotation,
      thickness: this.props.gaugeThickness,
      arc: this.props.GaugeArc,
      ticks: this.props.nrOfLevels,

      color_scheme: 'interpolatePuBuGn',
      color_step: this.props.nrOfLevels,
      reverse_color_scheme: false,
      tick_color: this.props.config.theme.isDark ? '#202226' : '#FFFFFF',
      needle_color: this.props.needleColor,
      value_type_color: this.props.config.theme.isDark ? '#FFFFFF' : '#000000',
    };
    var needlePercent = this.props.percent - 0.05;
    var center: any = {};
    var radii: any = {};
    var angles: any = {};
    var ticks: any = {};
    var gradient: any = [];
    var scales: any = {};
    var colorMode = this.props.defaults.color?.mode;
    var colors = this.props.colors;
    var divcount = this.props.count;
    var positionHeight = this.props.height;

    if (this.props.count === 4) {
      var setCenter = (function initCenter() {
        (center.x = properties.width / 2), // changed from properties.width/2
          (center.y = positionHeight < 302 ? properties.height / 1.5 : properties.height / 1.5); // properties.height - properties.margin

        return initCenter;
      })();

      var setRadii = (function initRadii() {
        var base = (properties.width + properties.height) / 5.3;

        (radii.base = base),
          (radii.cap = base / 15),
          (radii.inner = base * (1 - properties.thickness)),
          (radii.outer_tick = base + 5),
          (radii.tick_label = base + 15),
          (properties.margin = properties.margin + 1);
        return initRadii;
      })();
    } else if (this.props.count === 2) {
      var setCenter1 = (function initCenter() {
        (center.x = properties.width / 2), // changed from properties.width/2
          (center.y = positionHeight < 302 ? properties.height / 1.5 : properties.height / 1.5); // properties.height - properties.margin

        return initCenter;
      })();
      var setRadii1 = (function initRadii() {
        var base = (properties.height + properties.width) / 5.5;
        (radii.base = base),
          (radii.cap = base / 15),
          (radii.inner = base * (1 - properties.thickness)),
          (radii.outer_tick = base + 5),
          (radii.tick_label = base + 15);
        return initRadii;
      })();
    } else if (this.props.count === 3) {
      var setCenter2 = (function initCenter() {
        (center.x = properties.width / 2), // changed from properties.width/2
          (center.y = positionHeight < 302 ? properties.height / 1.5 : properties.height / 1.5); // properties.height - properties.margin

        return initCenter;
      })();
      var setRadii2 = (function initRadii() {
        var base = (properties.height + properties.width) / 5.2;
        (radii.base = base),
          (radii.cap = base / 15),
          (radii.inner = base * (1 - properties.thickness)),
          (radii.outer_tick = base + 5),
          (radii.tick_label = base + 15);
        return initRadii;
      })();
    } else if (this.props.count === 5) {
      var setCenter3 = (function initCenter() {
        (center.x = properties.width / 2), // changed from properties.width/2
          (center.y = positionHeight < 302 ? properties.height / 1.5 : properties.height / 1.5); // properties.height - properties.margin

        return initCenter;
      })();
      var setRadii3 = (function initRadii() {
        var base = (properties.height + properties.width) / 5;
        (radii.base = base),
          (radii.cap = base / 15),
          (radii.inner = base * (1 - properties.thickness)),
          (radii.outer_tick = base + 5),
          (radii.tick_label = base + 15);
        return initRadii;
      })();
    } else if (this.props.count === 6) {
      var setCenter4 = (function initCenter() {
        (center.x = properties.width / 2), // changed from properties.width/2
          (center.y = positionHeight < 302 ? properties.height / 1.5 : properties.height / 1.5); // properties.height - properties.margin

        return initCenter;
      })();
      var setRadii4 = (function initRadii() {
        var base = (properties.height + properties.width) / 5.5;
        (radii.base = base),
          (radii.cap = base / 15),
          (radii.inner = base * (1 - properties.thickness)),
          (radii.outer_tick = base + 5),
          (radii.tick_label = base + 15);
        return initRadii;
      })();
    } else if (this.props.count === 7) {
      var setCenter5 = (function initCenter() {
        (center.x = properties.width / 2), // changed from properties.width/2
          (center.y = positionHeight < 302 ? properties.height / 1.5 : properties.height / 1.5); // properties.height - properties.margin

        return initCenter;
      })();

      var setRadii5 = (function initRadii() {
        var base = (properties.height + properties.width) / 5.5;
        (radii.base = base),
          (radii.cap = base / 15),
          (radii.inner = base * (1 - properties.thickness)),
          (radii.outer_tick = base + 5),
          (radii.tick_label = base + 15);
        return initRadii;
      })();
    } else if (this.props.count === 8) {
      var setCenter6 = (function initCenter() {
        (center.x = properties.width / 2), // changed from properties.width/2
          (center.y = positionHeight < 302 ? properties.height / 1.5 : properties.height / 1.5); // properties.height - properties.margin

        return initCenter;
      })();
      var setRadii6 = (function initRadii() {
        var base = (properties.height + properties.width) / 6;
        (radii.base = base),
          (radii.cap = base / 15),
          (radii.inner = base * (1 - properties.thickness)),
          (radii.outer_tick = base + 5),
          (radii.tick_label = base + 15);
        return initRadii;
      })();
    } else if (this.props.count === 1) {
      var setCenter7 = (function initCenter() {
        (center.x = properties.width / 2), // changed from properties.width/2
          (center.y = positionHeight < 302 ? properties.height / 1.5 : properties.height / 1.5); // properties.height - properties.margin

        return initCenter;
      })();

      var setRadii7 = (function initRadii() {
        var base = (properties.height + properties.width + 200) / 8;
        (radii.base = base),
          (radii.cap = base / 15),
          (radii.inner = base * (1 - properties.thickness)),
          (radii.outer_tick = base + 5),
          (radii.tick_label = base + 15);
        return initRadii;
      })();
    } else {
      var setCenter8 = (function initCenter() {
        (center.x = properties.width / 2), // changed from properties.width/2
          (center.y = positionHeight < 302 ? properties.height / 1.5 : properties.height / 1.5); // properties.height - properties.margin

        return initCenter;
      })();
      var setRadii8 = (function initRadii() {
        var divider = divcount - 4;
        var base = (properties.height + properties.width) / divider;
        (radii.base = base),
          (radii.cap = base / 15),
          (radii.inner = base * (1 - properties.thickness)),
          (radii.outer_tick = base + 5),
          (radii.tick_label = base + 15);
        return initRadii;
      })();
    }

    var setAngles = (function initAngles() {
      var arc_complement = 1 - properties.arc;

      (angles.arc_complement = arc_complement),
        (angles.start_angle = -pi / 2 + (pi * arc_complement) / 2 + properties.rotation * rad),
        (angles.end_angle = pi / 2 - (pi * arc_complement) / 2 + properties.rotation * rad);

      return initAngles;
    })();

    var setTicks = (function initTicks() {
      var sub_arc = (angles.end_angle - angles.start_angle) / (properties.ticks - 1),
        tick_pct = 100 / (properties.ticks - 1);

      ticks = d3.range(properties.ticks).map(function (d) {
        var sub_angle = angles.start_angle + sub_arc * d;
        return {
          label: (tick_pct * d).toFixed(0) + '%',
          angle: sub_angle,
          coordinates: [
            [sub_angle, radii.inner],
            [sub_angle, radii.outer_tick],
          ],
        };
      });

      return initTicks;
    })();

    var setGradient = (function initGradient() {
      // var c = d3["interpolateRdYlGn"]

      var samples = properties.color_step;
      var total_arc = angles.end_angle - angles.start_angle;
      var sub_arc = total_arc / samples;

      if (colorMode === 'fixed') {
        gradient = d3.range(samples).map(function (d) {
          //var sub_color = d / (samples - 1),
          var sub_start_angle = angles.start_angle + sub_arc * d;
          var sub_end_angle = sub_start_angle + sub_arc;

          return {
            fill: 'grey', //c(properties.reverse_color_scheme ? 1 - sub_color : sub_color),
            start: sub_start_angle,
            end: sub_end_angle,
          };
          UpdateValues();
        });
      } else if (colorMode === 'thresholds' || colorMode === 'palette-classic') {
        gradient = d3.range(samples).map(function (d, i) {
          var sub_color = colors[i], //d / (samples - 1),
            sub_start_angle = angles.start_angle + sub_arc * d,
            sub_end_angle = sub_start_angle + sub_arc;

          return {
            fill: sub_color, //c(properties.reverse_color_scheme ? 1 - sub_color : sub_color),
            start: sub_start_angle,
            end: sub_end_angle,
          };
          UpdateValues();
        });
      } else if (
        colorMode === 'continuous-RdYlGr' ||
        colorMode === 'continuous-GrYlRd' ||
        colorMode === 'continuous-BlYlRd'
      ) {
        const colorGradient = new Gradient();
        colorGradient.setGradient(colors[0], colors[1], colors[2]);
        colorGradient.setMidpoint(samples);
        let colorsArray = colorGradient.getArray();
        gradient = d3.range(samples).map(function (d, i) {
          var sub_color = colorsArray[i],
            sub_start_angle = angles.start_angle + sub_arc * d,
            sub_end_angle = sub_start_angle + sub_arc;

          return {
            fill: sub_color, //c(properties.reverse_color_scheme ? 1 - sub_color : sub_color),
            start: sub_start_angle,
            end: sub_end_angle,
          };
          UpdateValues();
        });
      } else if (
        colorMode === 'continuous-YlRd' ||
        colorMode === 'continuous-BlPu' ||
        colorMode === 'continuous-YlBl'
      ) {
        const colorGradient = new Gradient();
        colorGradient.setGradient(colors[0], colors[1]);
        colorGradient.setMidpoint(samples);
        let colorsArray = colorGradient.getArray();

        gradient = d3.range(samples).map(function (d, i) {
          var sub_color = colorsArray[i],
            sub_start_angle = angles.start_angle + sub_arc * d,
            sub_end_angle = sub_start_angle + sub_arc;

          return {
            fill: sub_color, //c(properties.reverse_color_scheme ? 1 - sub_color : sub_color),
            start: sub_start_angle,
            end: sub_end_angle,
          };
          UpdateValues();
        });
      } else if (
        colorMode === 'continuous-reds' ||
        colorMode === 'continuous-purples' ||
        colorMode === 'continuous-greens' ||
        colorMode === 'continuous-blues'
      ) {
        const colorGradient = new Gradient();

        colorGradient.setGradient(colors[0], colors[1]);
        colorGradient.setMidpoint(samples);
        let colorsArray = colorGradient.getArray();

        gradient = d3.range(samples).map(function (d, i) {
          var sub_color = colorsArray[i],
            sub_start_angle = angles.start_angle + sub_arc * d,
            sub_end_angle = sub_start_angle + sub_arc;

          return {
            fill: sub_color, //c(properties.reverse_color_scheme ? 1 - sub_color : sub_color),
            start: sub_start_angle,
            end: sub_end_angle,
          };
          UpdateValues();
        });
      } else {
        gradient = d3.range(samples).map(function (d, i) {
          var sub_start_angle = angles.start_angle + sub_arc * d,
            sub_end_angle = sub_start_angle + sub_arc;

          return {
            fill: '#FF0000', //c(properties.reverse_color_scheme ? 1 - sub_color : sub_color),
            start: sub_start_angle,
            end: sub_end_angle,
          };
          UpdateValues();
        });
      }
      return initGradient;
    })();

    var setScales = (function initScales() {
      scales.lineRadial = d3.lineRadial();

      scales.subArcScale = d3
        .arc()
        .innerRadius(radii.inner + 1)
        .outerRadius(radii.base)
        .startAngle((d: any): any => d.start)
        .endAngle((d: any): any => d.end);

      scales.needleScale = d3.scaleLinear().domain([0, 1]).range([angles.start_angle, angles.end_angle]);

      return initScales;
    })();

    function UpdateValues() {
      setCenter();
      setCenter1();
      setCenter2();
      setCenter3();
      setCenter4();
      setCenter5();
      setCenter6();
      setCenter7();
      setCenter8();
      setRadii();
      setRadii1();
      setRadii2();
      setRadii3();
      setRadii4();
      setRadii5();
      setRadii6();
      setRadii7();
      setRadii8();
      setAngles();
      setTicks();
      setGradient();
      setScales();
    }

    const svg = d3
      .select(`#uniqueid${this.props.id}`)
      .append('svg')
      .attr('id', `svgid${this.props.svgid}`)
      .attr('viewBox', `0 0 ${this.props.width} ${properties.height + 14}`)
      //     .style('max-width','400px')
      .style('width', this.props.width)
      .style('height', properties.height); //this.props.height-70

    var gauge = svg
      .append('g')
      .attr('transform', `translate(${center.x}, ${center.y})`)
      .attr('class', 'gauge-container');

    gauge
      .append('g')
      .attr('class', 'gauge-arc')
      .selectAll('path')
      .data(gradient)
      .enter()
      .append('path')
      .attr('d', scales.subArcScale)
      .attr('fill', (d: any): any => d.fill)
      .attr('stroke-width', 0.5)
      .attr('stroke', (d: any): any => d.fill);

    gauge
      .append('g')
      .attr('class', 'gauge-ticks')
      .selectAll('path')
      .data(ticks)
      .enter()
      .append('g')
      .attr('class', 'tick')
      .append('path')
      .attr('d', (d: any): any => scales.lineRadial(d.coordinates))
      .attr('stroke', properties.tick_color)
      .attr('stroke-width', 2)
      .attr('stroke-linecap', 'round')
      .attr('fill', 'none');

    gauge
      .append('g')
      .attr('class', 'needle')
      .selectAll('path')
      .data([needlePercent])
      .enter()
      .append('path')
      .transition()
      .duration(5000)
      .ease(d3.easeElastic)
      .attr('transform', 'rotate(10)')
      .attr('d', (d) =>
        scales.lineRadial([
          [0, 0],
          [scales.needleScale(d), radii.outer_tick],
        ])
      )
      .attr('stroke', properties.needle_color)
      .attr('stroke-width', 6)
      .attr('stroke-linecap', 'round');

    gauge
      .select('g.needle')
      .append('circle')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', radii.cap)
      .attr('stroke', properties.needle_color)
      .attr('stroke-width', 6)
      .style('fill', 'white');

    const tagging = d3
      .select(`#svgid${this.props.svgid}`)
      .append('g')
      .attr('transform', `translate(${center.x},${center.y + 65})`); //

    tagging
      .append('text')
      .text(
        `${
          (this.props.frame.display.prefix || '') +
          this.props.frame.display.text +
          (this.props.frame.display.suffix || '')
        }`
      )
      .attr('fill', `${this.props.needleColor}`)
      .attr('font-size', `${this.props.fontSize1}`)
      .attr('font-weight', 'bold')
      .attr('text-anchor', 'middle');

    tagging
      .append('text')
      .attr('y', '25')
      .text(`${this.props.frame.display.title}`)
      .attr('fill', `${properties.value_type_color}`)
      .attr('font-size', `${this.props.fontSize}`)
      .attr('text-anchor', 'middle');
  }

  DrawChart() {
    var pi = Math.PI,
      rad = pi / 180;
    // deg = 180 / pi;

    var properties = {
      width: this.props.width,
      height: 400, //this.props.height,
      margin: 50,

      rotation: this.props.rotation,
      thickness: this.props.gaugeThickness,
      arc: this.props.GaugeArc,
      ticks: this.props.nrOfLevels,

      color_scheme: 'interpolatePuBuGn',
      color_step: this.props.nrOfLevels,
      reverse_color_scheme: false,
      tick_color: this.props.config.theme.isDark ? '#202226' : '#FFFFFF',
      needle_color: this.props.needleColor,
      value_type_color: this.props.config.theme.isDark ? '#FFFFFF' : '#000000',
    };
    var needlePercent = this.props.percent - 0.05,
      center: any = {},
      radii: any = {},
      angles: any = {},
      ticks: any = {},
      gradient: any = [],
      scales: any = {};
    var colorMode = this.props.defaults.color?.mode;
    var colors = this.props.colors;
    var divcount = this.props.count;
    var positionHeight = this.props.height;

    if (this.props.count === 4) {
      var setCenter = (function initCenter() {
        (center.x = properties.width / 2), // changed from properties.width/2
          (center.y = positionHeight < 302 ? properties.height / 1.5 : properties.height / 1.5); // properties.height - properties.margin

        return initCenter;
      })();
      var setRadii = (function initRadii() {
        var base = (properties.width + properties.height) / 5;

        (radii.base = base),
          (radii.cap = base / 15),
          (radii.inner = base * (1 - properties.thickness)),
          (radii.outer_tick = base + 5),
          (radii.tick_label = base + 15),
          (properties.margin = properties.margin + 1);
        return initRadii;
      })();
    } else if (this.props.count === 2) {
      var setCenter1 = (function initCenter() {
        (center.x = properties.width / 2), // changed from properties.width/2
          (center.y = positionHeight < 302 ? properties.height / 1.5 : properties.height / 1.5); // properties.height - properties.margin

        return initCenter;
      })();
      var setRadii1 = (function initRadii() {
        properties.height = 400;
        var base = (properties.height + properties.width) / 6.5;
        (radii.base = base),
          (radii.cap = base / 15),
          (radii.inner = base * (1 - properties.thickness)),
          (radii.outer_tick = base + 5),
          (radii.tick_label = base + 15);
        return initRadii;
      })();
    } else if (this.props.count === 3) {
      var setCenter2 = (function initCenter() {
        (center.x = properties.width / 2), // changed from properties.width/2
          (center.y = positionHeight < 302 ? properties.height / 1.5 : properties.height / 1.5); // properties.height - properties.margin

        return initCenter;
      })();
      var setRadii2 = (function initRadii() {
        var base = (properties.height + properties.width) / 5.2;
        (radii.base = base),
          (radii.cap = base / 15),
          (radii.inner = base * (1 - properties.thickness)),
          (radii.outer_tick = base + 5),
          (radii.tick_label = base + 15);
        return initRadii;
      })();
    } else if (this.props.count === 5) {
      var setCenter3 = (function initCenter() {
        (center.x = properties.width / 2), // changed from properties.width/2
          (center.y = positionHeight < 302 ? properties.height / 1.5 : properties.height / 1.5); // properties.height - properties.margin

        return initCenter;
      })();

      var setRadii3 = (function initRadii() {
        var base = (properties.height + properties.width) / 5;
        (radii.base = base),
          (radii.cap = base / 15),
          (radii.inner = base * (1 - properties.thickness)),
          (radii.outer_tick = base + 5),
          (radii.tick_label = base + 15);
        return initRadii;
      })();
    } else if (this.props.count === 6) {
      var setCenter4 = (function initCenter() {
        (center.x = properties.width / 2), // changed from properties.width/2
          (center.y = positionHeight < 302 ? properties.height / 1.5 : properties.height / 1.5); // properties.height - properties.margin

        return initCenter;
      })();
      var setRadii4 = (function initRadii() {
        var base = (properties.height + properties.width) / 5.5;
        (radii.base = base),
          (radii.cap = base / 15),
          (radii.inner = base * (1 - properties.thickness)),
          (radii.outer_tick = base + 5),
          (radii.tick_label = base + 15);
        return initRadii;
      })();
    } else if (this.props.count === 7) {
      var setCenter5 = (function initCenter() {
        (center.x = properties.width / 2), // changed from properties.width/2
          (center.y = positionHeight < 302 ? properties.height / 1.5 : properties.height / 1.5); // properties.height - properties.margin

        return initCenter;
      })();

      var setRadii5 = (function initRadii() {
        var base = (properties.height + properties.width) / 5.5;
        (radii.base = base),
          (radii.cap = base / 15),
          (radii.inner = base * (1 - properties.thickness)),
          (radii.outer_tick = base + 5),
          (radii.tick_label = base + 15);
        return initRadii;
      })();
    } else if (this.props.count === 8) {
      var setCenter6 = (function initCenter() {
        (center.x = properties.width / 2), // changed from properties.width/2
          (center.y = positionHeight < 302 ? properties.height / 1.5 : properties.height / 1.5); // properties.height - properties.margin

        return initCenter;
      })();

      var setRadii6 = (function initRadii() {
        var base = (properties.height + properties.width) / 6;
        (radii.base = base),
          (radii.cap = base / 15),
          (radii.inner = base * (1 - properties.thickness)),
          (radii.outer_tick = base + 5),
          (radii.tick_label = base + 15);
        return initRadii;
      })();
    } else if (this.props.count === 1) {
      var setCenter7 = (function initCenter() {
        (center.x = properties.width / 2), // changed from properties.width/2
          (center.y = positionHeight < 302 ? properties.height / 1.5 : properties.height / 1.5); // properties.height - properties.margin

        return initCenter;
      })();

      var setRadii7 = (function initRadii() {
        var base = (properties.height + properties.width) / 8.2;
        (radii.base = base),
          (radii.cap = base / 15),
          (radii.inner = base * (1 - properties.thickness)),
          (radii.outer_tick = base + 5),
          (radii.tick_label = base + 15);
        return initRadii;
      })();
    } else {
      var setCenter8 = (function initCenter() {
        (center.x = properties.width / 2), // changed from properties.width/2
          (center.y = positionHeight < 302 ? properties.height / 1.5 : properties.height / 1.5); // properties.height - properties.margin

        return initCenter;
      })();

      var setRadii8 = (function initRadii() {
        var divider = divcount - 4;
        var base = (properties.height + properties.width) / divider;
        (radii.base = base),
          (radii.cap = base / 15),
          (radii.inner = base * (1 - properties.thickness)),
          (radii.outer_tick = base + 5),
          (radii.tick_label = base + 15);
        return initRadii;
      })();
    }
    var setAngles = (function initAngles() {
      var arc_complement = 1 - properties.arc;

      (angles.arc_complement = arc_complement),
        (angles.start_angle = -pi / 2 + (pi * arc_complement) / 2 + properties.rotation * rad),
        (angles.end_angle = pi / 2 - (pi * arc_complement) / 2 + properties.rotation * rad);

      return initAngles;
    })();

    var setTicks = (function initTicks() {
      var sub_arc = (angles.end_angle - angles.start_angle) / (properties.ticks - 1),
        tick_pct = 100 / (properties.ticks - 1);

      ticks = d3.range(properties.ticks).map(function (d) {
        var sub_angle = angles.start_angle + sub_arc * d;
        return {
          label: (tick_pct * d).toFixed(0) + '%',
          angle: sub_angle,
          coordinates: [
            [sub_angle, radii.inner],
            [sub_angle, radii.outer_tick],
          ],
        };
      });

      return initTicks;
    })();

    var setGradient = (function initGradient() {
      // var c = d3["interpolateRdYlGn"]

      var samples = properties.color_step;
      var total_arc = angles.end_angle - angles.start_angle;
      var sub_arc = total_arc / samples;

      if (colorMode === 'fixed') {
        gradient = d3.range(samples).map(function (d) {
          //var sub_color = d / (samples - 1),
          var sub_start_angle = angles.start_angle + sub_arc * d;
          var sub_end_angle = sub_start_angle + sub_arc;

          return {
            fill: 'grey', //c(properties.reverse_color_scheme ? 1 - sub_color : sub_color),
            start: sub_start_angle,
            end: sub_end_angle,
          };
          UpdateValues();
        });
      } else if (colorMode === 'thresholds' || colorMode === 'palette-classic') {
        gradient = d3.range(samples).map(function (d, i) {
          var sub_color = colors[i], //d / (samples - 1),
            sub_start_angle = angles.start_angle + sub_arc * d,
            sub_end_angle = sub_start_angle + sub_arc;
          return {
            fill: sub_color, //c(properties.reverse_color_scheme ? 1 - sub_color : sub_color),
            start: sub_start_angle,
            end: sub_end_angle,
          };
          UpdateValues();
        });
      } else if (
        colorMode === 'continuous-RdYlGr' ||
        colorMode === 'continuous-GrYlRd' ||
        colorMode === 'continuous-BlYlRd'
      ) {
        const colorGradient = new Gradient();
        colorGradient.setGradient(colors[0], colors[1], colors[2]);
        colorGradient.setMidpoint(samples);
        let colorsArray = colorGradient.getArray();

        gradient = d3.range(samples).map(function (d, i) {
          var sub_color = colorsArray[i],
            sub_start_angle = angles.start_angle + sub_arc * d,
            sub_end_angle = sub_start_angle + sub_arc;

          return {
            fill: sub_color, //c(properties.reverse_color_scheme ? 1 - sub_color : sub_color),
            start: sub_start_angle,
            end: sub_end_angle,
          };
          UpdateValues();
        });
      } else if (
        colorMode === 'continuous-YlRd' ||
        colorMode === 'continuous-BlPu' ||
        colorMode === 'continuous-YlBl'
      ) {
        const colorGradient = new Gradient();
        colorGradient.setGradient(colors[0], colors[1]);
        colorGradient.setMidpoint(samples);
        let colorsArray = colorGradient.getArray();

        gradient = d3.range(samples).map(function (d, i) {
          var sub_color = colorsArray[i],
            sub_start_angle = angles.start_angle + sub_arc * d,
            sub_end_angle = sub_start_angle + sub_arc;

          return {
            fill: sub_color, //c(properties.reverse_color_scheme ? 1 - sub_color : sub_color),
            start: sub_start_angle,
            end: sub_end_angle,
          };
          UpdateValues();
        });
      } else if (
        colorMode === 'continuous-reds' ||
        colorMode === 'continuous-purples' ||
        colorMode === 'continuous-greens' ||
        colorMode === 'continuous-blues'
      ) {
        const colorGradient = new Gradient();

        colorGradient.setGradient(colors[0], colors[1]);
        colorGradient.setMidpoint(samples);
        let colorsArray = colorGradient.getArray();

        gradient = d3.range(samples).map(function (d, i) {
          var sub_color = colorsArray[i],
            sub_start_angle = angles.start_angle + sub_arc * d,
            sub_end_angle = sub_start_angle + sub_arc;

          return {
            fill: sub_color, //c(properties.reverse_color_scheme ? 1 - sub_color : sub_color),
            start: sub_start_angle,
            end: sub_end_angle,
          };
          UpdateValues();
        });
      } else {
        gradient = d3.range(samples).map(function (d, i) {
          var sub_start_angle = angles.start_angle + sub_arc * d,
            sub_end_angle = sub_start_angle + sub_arc;

          return {
            fill: '#FF0000', //c(properties.reverse_color_scheme ? 1 - sub_color : sub_color),
            start: sub_start_angle,
            end: sub_end_angle,
          };
          UpdateValues();
        });
      }
      return initGradient;
    })();
    var setScales = (function initScales() {
      scales.lineRadial = d3.lineRadial();

      scales.subArcScale = d3
        .arc()
        .innerRadius(radii.inner + 1)
        .outerRadius(radii.base)
        .startAngle((d: any): any => d.start)
        .endAngle((d: any): any => d.end);

      scales.needleScale = d3.scaleLinear().domain([0, 1]).range([angles.start_angle, angles.end_angle]);

      return initScales;
    })();

    function UpdateValues() {
      setCenter();
      setCenter1();
      setCenter2();
      setCenter3();
      setCenter4();
      setCenter5();
      setCenter6();
      setCenter7();
      setCenter8();
      setRadii();
      setRadii1();
      setRadii2();
      setRadii3();
      setRadii4();
      setRadii5();
      setRadii6();
      setRadii7();
      setRadii8();
      setAngles();
      setTicks();
      setGradient();
      setScales();
    }

    const svg = d3
      .select(`#uniqueid${this.props.id}`)
      .append('svg')
      .attr('id', `svgid${this.props.svgid}`)
      .attr('viewBox', `0 0 ${this.props.width} ${properties.height}`)
      //  .style('max-width','400px')
      .style('width', this.props.width)
      // .style('border','1px solid red')
      .style('height', properties.height); //this.props.height-100

    var gauge = svg
      .append('g')
      .attr('transform', `translate(${center.x}, ${center.y})`)
      .attr('class', 'gauge-container');

    gauge
      .append('g')
      .attr('class', 'gauge-arc')
      .selectAll('path')
      .data(gradient)
      .enter()
      .append('path')
      .attr('d', scales.subArcScale)
      .attr('fill', (d: any): any => d.fill)
      .attr('stroke-width', 0.5)
      .attr('stroke', (d: any): any => d.fill);

    gauge
      .append('g')
      .attr('class', 'gauge-ticks')
      .selectAll('path')
      .data(ticks)
      .enter()
      .append('g')
      .attr('class', 'tick')
      .append('path')
      .attr('d', (d: any): any => scales.lineRadial(d.coordinates))
      .attr('stroke', properties.tick_color)
      .attr('stroke-width', 2)
      .attr('stroke-linecap', 'round')
      .attr('fill', 'none');

    gauge
      .append('g')
      .attr('class', 'needle')
      .selectAll('path')
      .data([needlePercent])
      .enter()
      .append('path')
      .transition()
      .duration(5000)
      .ease(d3.easeElastic)
      .attr('transform', 'rotate(10)')
      .attr('d', (d) =>
        scales.lineRadial([
          [0, 0],
          [scales.needleScale(d), radii.outer_tick],
        ])
      )
      .attr('stroke', properties.needle_color)
      .attr('stroke-width', 6)
      .attr('stroke-linecap', 'round');

    gauge
      .select('g.needle')
      .append('circle')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', radii.cap)
      .attr('stroke', properties.needle_color)
      .attr('stroke-width', 6)
      .style('fill', 'white');

    const tagging = d3
      .select(`#svgid${this.props.svgid}`)
      .append('g')
      .attr('transform', `translate(${center.x},${center.y + 65})`);

    tagging
      .append('text')

      .text(
        `${
          (this.props.frame.display.prefix || '') +
          this.props.frame.display.text +
          (this.props.frame.display.suffix || '')
        }`
      )
      .attr('fill', `${this.props.needleColor}`)
      .attr('font-size', `${this.props.fontSize1}`)
      .attr('font-weight', 'bold')
      .attr('text-anchor', 'middle');

    tagging
      .append('text')
      .text(`${this.props.frame.display.title}`)
      .attr('fill', `${properties.value_type_color}`)
      .attr('y', '25')
      .attr('font-size', `${this.props.fontSize}`)
      .attr('text-anchor', 'middle');
  }

  componentDidMount() {
    this.DrawChart();
  }

  componentDidUpdate() {
    this.removeChart();
  }

  render() {
    return (
      <div
        id={`uniqueid${this.props.id}`}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: this.props.width,
          height: `${this.props.height}px`,
        }}
      ></div>
    );
  }
}

export default Gauger;
