import { SelectableValue } from '@grafana/data';

type numstr = Number | String;

export interface Types {
  days: any[];
  hours: any[];
  minutes: any[];
  frequencies: any[];
  layoutOptions: Array<SelectableValue<string>>;
  themeOptions: Array<SelectableValue<string>>;
  orientationOptions: Array<SelectableValue<string>>;
  pageOptions: Array<SelectableValue<numstr[]>>;
  dates: any[];
  timePicker: any;
  timePickerMode: any;
  fromTime: any;
  toTime: any;
}

export interface StateI {
  dashboards: Array<SelectableValue<string>>;
  day: string;
  date: string;
  frequency: string;
  layout: string;
  orientation: string;
  pageSize: any;
  hour: string;
  minute: string;
  selectedDashboards: Array<SelectableValue<string>>;
  dTimezone: string;
  timezone: string;
  timePickerSelected: string;
  timePickerModeSelected: string;
  kiosk: boolean;
  theme: string;
  alerting: boolean;
  cron1: string;
  cron2: string;
  cron3: string;
  cron4: string;
  cron5: string;
  email: string;
  subject: string;
  body: string;
  fromTime: string;
  toTime: string;
  status: string;
}

export interface StateSync {
  cronValue: string;
  isScheduling: boolean;
  isValid: boolean;
  day: string;
  date: string;
  frequency: string;
  pageSize: any;
  hour: string;
  minute: string;
  dTimezone: string;
  timezone: string;
  timePickerSelected: string;
  timePickerModeSelected: string;
  cron1: string;
  cron2: string;
  cron3: string;
  cron4: string;
  cron5: string;
  fromTime: string;
  toTime: string;
}

export interface StateExport {
  day: string;
  date: string;
  frequency: string;
  pageSize: any;
  hour: string;
  minute: string;
  dTimezone: string;
  timezone: string;
  timePickerSelected: string;
  timePickerModeSelected: string;
  cron1: string;
  cron2: string;
  cron3: string;
  cron4: string;
  cron5: string;
  fromTime: string;
  toTime: string;
}

export interface CronJob {
  urlstring: string;
  cronsum: string;
  addresses: string;
  subject: string;
  body: string;
  uuid: string;
  username: string;
  orgid: number;
  role: string;
  filename: string;
  status: string;
  settings: string;
}

export const options: Types = {
  days: [
    { label: 'Sunday', value: 7 },
    { label: 'Monday', value: 1 },
    { label: 'Tuesday', value: 2 },
    { label: 'Wednesday', value: 3 },
    { label: 'Thursday', value: 4 },
    { label: 'Friday', value: 5 },
    { label: 'Saturday', value: 6 },
  ],
  hours: [
    { label: '00', value: '00' },
    { label: '01', value: '01' },
    { label: '02', value: '02' },
    { label: '03', value: '03' },
    { label: '04', value: '04' },
    { label: '05', value: '05' },
    { label: '06', value: '06' },
    { label: '07', value: '07' },
    { label: '08', value: '08' },
    { label: '09', value: '09' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: '13', value: '13' },
    { label: '14', value: '14' },
    { label: '15', value: '15' },
    { label: '16', value: '16' },
    { label: '17', value: '17' },
    { label: '18', value: '18' },
    { label: '19', value: '19' },
    { label: '20', value: '20' },
    { label: '21', value: '21' },
    { label: '22', value: '22' },
    { label: '23', value: '23' },
  ],
  minutes: [
    { label: '00', value: '00' },
    { label: '01', value: '01' },
    { label: '02', value: '02' },
    { label: '03', value: '03' },
    { label: '04', value: '04' },
    { label: '05', value: '05' },
    { label: '06', value: '06' },
    { label: '07', value: '07' },
    { label: '08', value: '08' },
    { label: '09', value: '09' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: '13', value: '13' },
    { label: '14', value: '14' },
    { label: '15', value: '15' },
    { label: '16', value: '16' },
    { label: '17', value: '17' },
    { label: '18', value: '18' },
    { label: '19', value: '19' },
    { label: '20', value: '20' },
    { label: '21', value: '21' },
    { label: '22', value: '22' },
    { label: '23', value: '23' },
    { label: '24', value: '24' },
    { label: '25', value: '25' },
    { label: '26', value: '26' },
    { label: '27', value: '27' },
    { label: '28', value: '28' },
    { label: '29', value: '29' },
    { label: '30', value: '30' },
    { label: '31', value: '31' },
    { label: '32', value: '32' },
    { label: '33', value: '33' },
    { label: '34', value: '34' },
    { label: '35', value: '35' },
    { label: '36', value: '36' },
    { label: '37', value: '37' },
    { label: '38', value: '38' },
    { label: '39', value: '39' },
    { label: '40', value: '40' },
    { label: '41', value: '41' },
    { label: '42', value: '42' },
    { label: '43', value: '43' },
    { label: '44', value: '44' },
    { label: '45', value: '45' },
    { label: '46', value: '46' },
    { label: '47', value: '47' },
    { label: '48', value: '48' },
    { label: '49', value: '49' },
    { label: '50', value: '50' },
    { label: '51', value: '51' },
    { label: '52', value: '52' },
    { label: '53', value: '53' },
    { label: '54', value: '54' },
    { label: '55', value: '55' },
    { label: '56', value: '56' },
    { label: '57', value: '57' },
    { label: '58', value: '58' },
    { label: '59', value: '59' },
  ],
  frequencies: [
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Custom', value: 'custom' },
  ],
  layoutOptions: [
    { label: 'Single Page', value: 'single' },
    { label: 'Grid', value: 'grid' },
    { label: 'Simple', value: 'simple' },
  ],
  themeOptions: [
    { label: 'Current', value: 'current' },
    { label: 'Dark', value: 'dark' },
    { label: 'Light', value: 'light' },
  ],
  orientationOptions: [
    { label: 'Portrait', value: 'portrait' },
    { label: 'Landscape', value: 'landscape' },
  ],
  pageOptions: [
    { label: 'A2', value: [1587, 2245, 1191, 1684, 'a2'] },
    { label: 'A3', value: [1123, 1587, 842, 1191, 'a3'] },
    { label: 'A4', value: [794, 1123, 595, 842, 'a4'] },
    { label: 'A5', value: [559, 794, 420, 595, 'a5'] },
  ],
  dates: [
    { label: '01', value: '01' },
    { label: '02', value: '02' },
    { label: '03', value: '03' },
    { label: '04', value: '04' },
    { label: '05', value: '05' },
    { label: '06', value: '06' },
    { label: '07', value: '07' },
    { label: '08', value: '08' },
    { label: '09', value: '09' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: '13', value: '13' },
    { label: '14', value: '14' },
    { label: '15', value: '15' },
    { label: '16', value: '16' },
    { label: '17', value: '17' },
    { label: '18', value: '18' },
    { label: '19', value: '19' },
    { label: '20', value: '20' },
    { label: '21', value: '21' },
    { label: '22', value: '22' },
    { label: '23', value: '23' },
    { label: '24', value: '24' },
    { label: '25', value: '25' },
    { label: '26', value: '26' },
    { label: '27', value: '27' },
    { label: '28', value: '28' },
    { label: '29', value: '29' },
    { label: '30', value: '30' },
    { label: '31', value: '31' },
  ],
  timePicker: [
    { value: '', label: 'Dashboard Default' },
    { value: '&from=now-5m&to=now', label: 'Last 5 minutes' },
    { value: '&from=now-15m&to=now', label: 'Last 15 minutes' },
    { value: '&from=now-30m&to=now', label: 'Last 30 minutes' },
    { value: '&from=now-1h&to=now', label: 'Last 1 hour' },
    { value: '&from=now-3h&to=now', label: 'Last 3 hours' },
    { value: '&from=now-6h&to=now', label: 'Last 6 hours' },
    { value: '&from=now-12h&to=now', label: 'Last 12 hours' },
    { value: '&from=now-24h&to=now', label: 'Last 24 hours' },
    { value: '&from=now-2d&to=now', label: 'Last 2 days' },
    { value: '&from=now-7d&to=now', label: 'Last 7 days' },
    { value: '&from=now-30d&to=now', label: 'Last 30 days' },
    { value: '&from=now-90d&to=now', label: 'Last 90 days' },
    { value: '&from=now-6M&to=now', label: 'Last 6 months' },
    { value: '&from=now-1y&to=now', label: 'Last 1 year' },
    { value: '&from=now-2y&to=now', label: 'Last 2 years' },
    { value: '&from=now-5y&to=now', label: 'Last 5 years' },
    { value: '&from=now-1d%2Fd&to=now-1d%2Fd', label: 'Yesterday' },
    { value: '&from=now-2d%2Fd&to=now-2d%2Fd', label: 'Day before yesterday' },
    { value: '&from=now-7d%2Fd&to=now-7d%2Fd', label: 'This day last week' },
    { value: '&from=now-1w%2Fw&to=now-1w%2Fw', label: 'Previous week' },
    { value: '&from=now-1M%2FM&to=now-1M%2FM', label: 'Previous month' },
    { value: '&from=now-1y%2Fy&to=now-1y%2Fy', label: 'Previous year' },
    { value: '&from=now%2Fd&to=now%2Fd', label: 'Today' },
    { value: '&from=now%2Fd&to=now', label: 'Today so far' },
    { value: '&from=now%2Fw&to=now%2Fw', label: 'This week' },
    { value: '&from=now%2Fw&to=now', label: 'This week so far' },
    { value: '&from=now%2FM&to=now%2FM', label: 'This month' },
    { value: '&from=now%2FM&to=now', label: 'This month so far' },
    { value: '&from=now%2Fy&to=now%2Fy', label: 'This year' },
    { value: '&from=now%2Fy&to=now', label: 'This year so far' },
  ],
  timePickerMode: [
    { label: 'Default', value: 'default' },
    { label: 'Custom', value: 'custom' },
  ],
  fromTime: 'now-12h',
  toTime: 'now',
};
