import { MapBrowserEvent, Map as OpenLayerMap } from 'ol';
import { FeatureLike } from 'ol/Feature';
import React from 'react';
import { getUniqueFeatureValues } from '../StyleEditor/util';
import { OSMapPanelOptions } from '../types/types';
import { TooltipOpenLayer } from './TooltipOpenLayer';

/**
 * Represents the properties for an OSMapPanel component.
 *
 * @interface Props
 * @property {OpenLayerMap | undefined} map - The OpenLayers map instance or undefined.
 * @property {OSMapPanelOptions} options - The options specific to the OSMapPanel.
 * @property {function} onOptionsChange - A callback function to update options.
 */
interface Props {
  map: OpenLayerMap | undefined;
  options: OSMapPanelOptions;
  /**
   * A callback function to update options.
   * @param {OSMapPanelOptions} options - The updated options for the OSMapPanel.
   */
  onOptionsChange: (options: OSMapPanelOptions) => void;
}

/**
 * Represents the state of a tooltip in your application.
 *
 * @interface TooltipState
 * @property {number} pageX - The X-coordinate of the tooltip's position on the page.
 * @property {number} pageY - The Y-coordinate of the tooltip's position on the page.
 * @property {FeatureLike[][]} tooltipData - The data associated with the tooltip.
 * @property {boolean} isOpen - Indicates whether the tooltip is currently open.
 * @property {boolean} isHolding - Indicates whether the user is holding the tooltip.
 */
interface State {
  pageX: number;
  pageY: number;
  tooltipData: FeatureLike[][];
  isOpen: boolean;
  isHolding: boolean;
}

/**
 * OpenLayer Tooltip component.
 *
 * @class MainTooltip
 * @extends {React.Component<Props, State>}
 */
class MainTooltip extends React.Component<Props, State> {
  /**
   * Constructor for MainTooltip component.
   *
   * @param {Props} props - The component's properties.
   */
  constructor(props: Props) {
    super(props);
    this.state = { pageX: 0, pageY: 0, tooltipData: [], isOpen: false, isHolding: false };
  }

  /**
   * Event listener for mouse move on the map.
   *
   * @param {MapBrowserEvent<UIEvent>} evt - The mouse event.
   * @returns {boolean} True if a feature is found at the mouse location, false otherwise.
   */
  mouseMoveListener = (evt: MapBrowserEvent<UIEvent>) => {
    const { map, options } = this.props;

    if (!map) {
      return false;
    }
    // if (this.state.isHolding) {
    //   return false;
    // }
    const mouse = evt.originalEvent as any;
    const pixel = map.getEventPixel(mouse);

    const coordX = mouse.pageX;
    const coordsY = mouse.pageY;
    this.setState({ ...this.state, pageX: coordX, pageY: coordsY });
    const isFeature = map.hasFeatureAtPixel(pixel!, {
      layerFilter: (l) => {
        return true;
      },
    });

    if (isFeature) {
      const features = map.getFeaturesAtPixel(pixel);
      const layersName = getUniqueFeatureValues(features, 'layerName');
      let featuresLayer = layersName.map((layerName) => {
        return features.filter((feature) => {
          return (
            feature.get('layerName') === layerName &&
            options.layers.some((layer) => layer.name === layerName && layer.tooltip)
          );
        });
      });
      featuresLayer = featuresLayer.filter((arr) => arr.length > 0);

      this.setState({
        ...this.state,
        tooltipData: featuresLayer,
        // isOpen: true,
      });
    } else {
      this.onCloseClick();
    }
    return isFeature;
  };

  /**
   * Closes the tooltip and clears tooltip data.
   */
  onCloseClick = () => {
    this.setState({
      isOpen: false,
      tooltipData: [],
      isHolding: false,
    });
  };

  /**
   * Event listener for mouse out from the map.
   */
  mouseOutListenerFromMap = () => {
    if (!this.state.isHolding) {
      this.setState({
        tooltipData: [],
        isOpen: false,
      });
    }
  };

  /**
   * Sets the holding state when the tooltip is open.
   */
  mouseIsOpenHoldingOff = () => {
    if (this.state.isOpen) {
      this.setState({
        isHolding: true,
      });
    }
  };

  /**
   * Event listener for mouse click on the map.
   *
   * @param {MapBrowserEvent<UIEvent>} env - The mouse event.
   */
  mouseClickListener = (env: MapBrowserEvent<UIEvent>) => {
    if (this.mouseMoveListener(env)) {
      env.preventDefault();
      this.setState({
        ...this.state,
        isOpen: true,
      });
    }
  };

  /**
   * Lifecycle method called when the component is mounted.
   */
  componentDidMount() {
    const { map } = this.props;

    map?.on('singleclick', this.mouseClickListener);
    map?.on('pointermove', this.mouseMoveListener);
    map?.getViewport().addEventListener('mouseout', this.mouseOutListenerFromMap);
  }

  render() {
    const { pageX, pageY, tooltipData, isOpen } = this.state;

    return (
      <TooltipOpenLayer
        isOpen={isOpen}
        tooltipData={tooltipData}
        pageX={pageX}
        pageY={pageY}
        onClose={this.onCloseClick}
      />
    );
  }
}

export default MainTooltip;
