import { StateExport, StateI, StateSync } from './types';

/**
 * Validate a Cron string
 * @function
 * @private
 * @param {StateI} state - Report state
 * @returns {boolean} - True if cron is valid.
 */
export const validateCron = (state: StateI | StateSync | StateExport): boolean => {
  if (state.cron1 !== '' && state.cron2 !== '' && state.cron3 !== '' && state.cron4 !== '' && state.cron5 !== '') {
    return true;
  } else {
    return false;
  }
};
