import { SelectableValue } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';

// interface ImageList {
//   images: SelectableValue[];
//   error: null | Error;
// }

interface FileList {
  files: SelectableValue[];
  error: null | Error;
}

/**
 * Retrieves a list of images from the S3 server.
 *
 * @return {Promise<ImageList>} A Promise that resolves to an ImageList object containing the list of images and any error that occurred during the retrieval.
 */
export const loadS3Documents = async (): Promise<FileList> => {
  const resp: FileList = {
    files: [],
    error: null,
  };
  try {
    const response = await getBackendSrv().get('/api/document/');

    if (response.items || response.items === null) {
      const availableImgTemp: SelectableValue[] = [];

      if (response.items === null) {
        resp.files = [];
      } else {
        response.items.forEach((item: any) => {
          availableImgTemp.push({ label: item, value: item });
        });
        resp.files = availableImgTemp;
      }
    } else if (response.message) {
      resp.error = Error(response.message);
    } else {
      resp.error = Error('Failed to load Documents.');
    }
  } catch (err) {
    resp.error = Error(err);
  }
  return resp;
};

/**
 * Retrieves a list of images from the S3 server.
 *
 * @return {Promise<ImageList>} A Promise that resolves to an ImageList object containing the list of images and any error that occurred during the retrieval.
 */
export const loadS3Images = async (): Promise<FileList> => {
  const resp: FileList = {
    files: [],
    error: null,
  };
  try {
    const response = await getBackendSrv().get('/api/image/');

    if (response.items || response.items === null) {
      const availableImgTemp: SelectableValue[] = [];

      if (response.items === null) {
        resp.files = [];
      } else {
        response.items.forEach((item: any) => {
          availableImgTemp.push({ label: item, value: item });
        });
        resp.files = availableImgTemp;
      }
    } else if (response.message) {
      resp.error = Error(response.message);
    } else {
      resp.error = Error('Failed to load images.');
    }
  } catch (err) {
    resp.error = Error(err);
  }
  return resp;
};
