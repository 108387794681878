import React, { createContext, useState } from 'react';

export const TooltipDataContext = createContext({
  tooltipData: { lat: 0, lng: 0, data: undefined, layer: 'Layer', tooltipType: '', locField: '' },
  setTooltipData: (markerData: any) => {},
});
// * onClick - hold the marker on map
export const TooltipDataProvider = (props: any) => {
  const [tooltipData, setTooltipData] = useState({
    lat: 0,
    lng: 0,
    data: undefined,
    layer: 'Layer',
    tooltipType: '',
    locField: '',
  });
  return (
    <TooltipDataContext.Provider value={{ tooltipData, setTooltipData }}>{props.children}</TooltipDataContext.Provider>
  );
};
