import React from 'react';
import {
  ColorPicker,
  Switch,
  Select,
  InlineFormLabel,
  HorizontalGroup,
  VerticalGroup,
  InlineLabel,
  Button,
  useTheme,
} from '@grafana/ui';
import Sensor from '../Types/Sensor';
import { DashExpand } from '../../../../features/linkDrawer/dashboard/DashExpand';

interface Props {
  sensor: Sensor;
  onChange: (sensor: Sensor, index: number) => void;
  index: number;
  data: any;
  QueryData: any;
  handleRemoveSensor: (index: number) => void;
}

export const EditorSensorItem: React.FC<Props> = (props: Props) => {
  const { sensor, index, data, QueryData, handleRemoveSensor } = props;
  const theme = useTheme();

  if (QueryData.length > 0) {
    data.splice(0, data.length);
    for (let i = 0; i < QueryData.length; i++) {
      const toPush = {
        value: QueryData[i].fields[1].state?.displayName,
        label: QueryData[i].fields[1].state?.displayName,
      };
      data.push(toPush);
    }
  }

  const onDashboardChange = (target: any) => {
    props.onChange({ ...sensor, dashboardUrl: target.value, dashboardName: target.label }, index);
  };

  const onUrlChangeCustomInput = (value: string, idx: number, name: boolean) => {
    let sensordata;
    if (name) {
      sensordata = { ...sensor, dashboardName: value };
    } else {
      sensordata = { ...sensor, dashboardUrl: value };
    }
    props.onChange(sensordata, idx);
  };

  return (
    <div style={{ border: `1px solid ${theme.colors.border1}`, padding: '8px', width: '100%' }}>
      <VerticalGroup spacing="xs">
        <InlineLabel width={10} transparent>
          Sensor {props.index + 1}
        </InlineLabel>
        <HorizontalGroup>
          <InlineFormLabel width={11} tooltip="Reload queries to load data" className="gf-form-label">
            Sensor Data
          </InlineFormLabel>
          <Select
            width={22}
            options={data}
            // defaultValue={data[0]}
            onChange={(target) => props.onChange({ ...sensor, value: target.value }, index)}
            // onChange={(data) => onSensorSelect(data, sensors[key].id)}
            value={data.find((item: any) => item.value === sensor.value)}
          />
        </HorizontalGroup>
        <HorizontalGroup>
          <label className="gf-form-label width-10">Sensor Show</label>
          <Switch
            value={sensor.visible}
            onChange={(event) => props.onChange({ ...sensor, visible: event.currentTarget.checked }, index)}
          />
        </HorizontalGroup>
        {/* Manual Color show */}
        <HorizontalGroup>
          <label className="gf-form-label width-10">Manual Text Color</label>
          <Switch
            value={sensor.Manual}
            onChange={(event) => props.onChange({ ...sensor, Manual: event.currentTarget.checked }, index)}
          />
        </HorizontalGroup>
        {sensor.Manual && (
          <HorizontalGroup>
            <label className="gf-form-label width-10">Text Color</label>
            <ColorPicker
              color={sensor.fontColor}
              onChange={(color) => props.onChange({ ...sensor, fontColor: color }, index)}
              enableNamedColors={true}
            />
          </HorizontalGroup>
        )}
        {/* Dashboard link */}
        <DashExpand
          option={sensor}
          index={index}
          onUrlChangeCustomInput={onUrlChangeCustomInput}
          onDashboardChange={onDashboardChange}
        />
        <Button icon="trash" onClick={() => handleRemoveSensor(index)} variant="destructive" size="md">
          Remove Sensor
        </Button>
      </VerticalGroup>
    </div>
  );
};
