import tinycolor from 'tinycolor2';

/**
 * Default style configuration for map features.
 *
 * @constant {StyleConfig}
 * @default
 * @property {number} size - The size of the feature (e.g., for point features).
 * @property {string} color - The color of the feature in RGB format (e.g., '#RRGGBB').
 * @property {number} opacity - The opacity of the feature, represented as a value between 0 and 1.
 * @property {string} symbol - The symbol used for the feature (e.g., 'circle', 'square').
 * @property {number} rotation - The rotation angle of the feature in degrees.
 */
export const defaultStyleConfig = Object.freeze({
  size: 5,
  color: tinycolor.random().toRgbString(),
  opacity: 0.4,
  symbol: 'circle',
  rotation: 0,
});

/**
 * Enumeration of comparison operations.
 * These operations are used to compare values in conditional expressions.
 *
 * @enum {string}
 */
export enum ComparisonOperation {
  EQ = 'eq',
  NEQ = 'neq',
  LT = 'lt',
  LTE = 'lte',
  GT = 'gt',
  GTE = 'gte',
}
