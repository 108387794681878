import { PanelData } from '@grafana/data';
import Map from 'ol/Map';
import OLVectorLayer from 'ol/layer/Vector';
import OLVectorSource from 'ol/source/Vector';
import { OSMapLayerOptions, OSMapLayerRegistryItem } from '../../LayerEditor/types';
import { createStyledMarker } from './general';
import { CustomMarkerConfig } from './type';
import { CompleteMarker } from 'app/plugins/panel/googlemap/Google/interface';
import { createMarker, generalBigData, getCustomMarkerValues } from '../hetamapLayer/general';
import { getOpacity, getRGBColor } from '../geojsonLayer/general';
import { PanelConfig } from '../../types/types';
import { defaultCustomMarkerConfig, defaultStyleConfig } from './default';
import { MapLayerType } from '../../types/interface';

const defaultOptions: CustomMarkerConfig = {
  style: defaultStyleConfig,
  customMarkers: defaultCustomMarkerConfig,
};

export const customMarkerLayer: OSMapLayerRegistryItem<CustomMarkerConfig> = {
  id: MapLayerType.CUSTOMMARKER,
  name: 'Custom Marker',
  description: 'use markers to render each data point',
  isBaseMap: false,
  showLocation: true,
  defaultOptions,

  create: async (map: Map, options: OSMapLayerOptions<CustomMarkerConfig>) => {
    // Assert default values
    const config = {
      ...defaultOptions,
      ...options?.config,
    };

    let vectorSource = new OLVectorSource();

    // vector layer
    let vectorLayer = new OLVectorLayer({
      source: vectorSource,
      opacity: getOpacity(options.opacity),
    });

    return {
      init: () => vectorLayer,
      update: (data: PanelData, panelConfig: PanelConfig) => {
        const { fieldConfig, reduceOptions, replaceVariables, theme } = panelConfig;
        if (data.series && fieldConfig && reduceOptions && replaceVariables && theme) {
          const { style, customMarkers } = config;
          const {
            size: { max, min },
            color: styleColor,
            fillOpacity,
            symbol,
            markerIcon,
            rotation,
          } = style;
          //Getting FieldDisplay data for custom markers.
          const newData = generalBigData(fieldConfig, reduceOptions, replaceVariables, theme, data.series, 'browser');
          const dataFromCustomMarker = getCustomMarkerValues(newData, customMarkers);

          // clear data of the layer
          vectorSource.clear();

          if (dataFromCustomMarker) {
            dataFromCustomMarker.forEach((markers: CompleteMarker[]) => {
              const { lat, lng, numeric, title, url, name, color, percent } = markers[0];

              const finalSize = (max - min) * (percent ?? 1) + min;
              const colorMain = getRGBColor(styleColor);
              //Create marker Feature
              const markerFeature = createMarker(lng, lat, numeric, options.name, title, name, url);

              vectorSource.addFeature(
                createStyledMarker(
                  markerFeature,
                  fillOpacity,
                  color ?? colorMain,
                  finalSize,
                  symbol,
                  markerIcon,
                  rotation
                )
              );
            });
            try {
            } catch (error) {
              // clear data of the layer
              vectorSource.clear();
            }
          }
        }

        return; // ignore empty
      },
    };
  },
};
