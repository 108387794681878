import { FeatureLike } from 'ol/Feature';
import { GeometryTypeId, LabelValue, LayerContentInfo } from './type';
import { DataFrame, GrafanaTheme } from '@grafana/data';
import { prepareGraphableFields } from '../utils/prepareFields';

/**
 * Extracts unique values from a specified property (key) in a list of features.
 *
 * @param {FeatureLike[]} features - An array of features to extract unique values from.
 * @param {string} key - The property key to extract values from.
 * @returns {string[]} An array of unique values extracted from the specified property.
 */
export function getUniqueFeatureValues(features: FeatureLike[], key: string): string[] {
  const unique = new Set<string>();
  for (const feature of features) {
    const v = feature.get(key);
    if (v != null) {
      unique.add(`${v}`); // always string
    }
  }
  const buffer = Array.from(unique);
  buffer.sort();
  return buffer;
}

/**
 * Extracts layer property information from a list of features.
 *
 * @param {FeatureLike[]} features - An array of features to extract property information from.
 * @returns {LayerContentInfo} An object containing information about the properties and geometry type.
 */
export function getLayerPropertyInfo(features: FeatureLike[]): LayerContentInfo {
  const types = new Set<string>();
  const props = new Set<string>();
  features.some((feature, idx) => {
    for (const key of Object.keys(feature.getProperties())) {
      if (key === 'geometry') {
        continue;
      }
      props.add(key);
      const g = feature.getGeometry();
      if (g) {
        types.add(g.getType());
      }
    }
    return idx > 10; // first 10 items
  });

  let geometryType = GeometryTypeId.Any;
  if (types.size === 1 || types.size === 2) {
    switch (String(types.values().next().value).toLowerCase()) {
      case 'point':
      case 'multipoint':
        geometryType = GeometryTypeId.Point;
        break;
      case 'line':
      case 'multiline':
        geometryType = GeometryTypeId.Line;
        break;
      case 'polygon':
      case 'multipolygon':
        geometryType = GeometryTypeId.Polygon;
    }
  }

  return {
    geometryType,
    properties: Array.from(props.keys()).map((v) => ({ label: v, value: v })),
  };
}

/**
 * Creating Geohash selection options name,geohash,value.
 * @function
 * @param {DataFrame[]} geohashData
 * @param {string} geohashData
 * @param {GrafanaTheme} theme
 * @returns {Array<LabelValue>} { queryOptionsString: LabelValue[]; queryOptionsNum: LabelValue[] }
 */
export function prepareGeohashSelections(
  geohashData: DataFrame[],
  dataField: string,
  theme: GrafanaTheme
): { queryOptionsString: LabelValue[]; queryOptionsNum: LabelValue[] } {
  let queryOptionsString: LabelValue[] = [];
  let queryOptionsNum: LabelValue[] = [];

  if (geohashData?.length === 0 && dataField.length !== 0) {
    return {
      queryOptionsString,
      queryOptionsNum,
    };
  }
  const frames = prepareGraphableFields(geohashData, theme);

  const data = frames?.filter((item) => {
    return item.refId === dataField;
  });

  if (data && data?.length > 0) {
    for (const field of data[0].fields) {
      if (field.type === 'string') {
        queryOptionsString.push({
          label: field.name,
          value: field.name,
        });
      }
      if (field.type === 'number') {
        queryOptionsNum.push({
          label: field.name,
          value: field.name,
        });
      }
    }
  }
  return {
    queryOptionsString,
    queryOptionsNum,
  };
}

/**
 * Checks if a given latitude value is valid.
 *
 * @param {number} lat - The latitude value to validate.
 * @returns {boolean} Returns `true` if the latitude is valid; otherwise, `false`.
 */
export function isValidLatitude(lat: number): boolean {
  return typeof lat === 'number' && lat !== 0 && lat >= -90 && lat <= 90;
}

/**
 * Checks if a given longitude value is valid.
 *
 * @param {number} lon - The longitude value to validate.
 * @returns {boolean} Returns `true` if the longitude is valid; otherwise, `false`.
 */
export function isValidLongitude(lon: number): boolean {
  return typeof lon === 'number' && lon !== 0 && lon >= -180 && lon <= 180;
}

/**
 * Creating the quary data field options
 * @function
 * @param {DataFrame[]} geohashData
 * @returns {Array<LabelValue>} { dataFieldOptions: LabelValue[] }
 */
export function prepareOptionsForDataField(geohashData: DataFrame[]): { dataFieldOptions: LabelValue[] } {
  let dataFieldOptions: LabelValue[] = [];

  if (geohashData?.length === 0) {
    return {
      dataFieldOptions,
    };
  }
  for (let index = 0; index < geohashData.length; index++) {
    const nameField = geohashData[index].refId;
    dataFieldOptions.push({
      label: nameField ?? '',
      value: nameField ?? '',
    });
  }
  return {
    dataFieldOptions,
  };
}
