import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const Sync: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 18 18"
      viewBox="0 0 18 18"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M16.1,9c-0.3,0-0.6,0.2-0.6,0.6c-0.3,3.5-3.4,6.1-6.9,5.8c-1.5-0.1-3-0.8-4-1.9h1.8c0.4,0,0.6-0.3,0.6-0.6
        c0-0.4-0.3-0.6-0.6-0.6H3.7c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,0.4,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6v-1.6c3,3,7.9,2.9,10.9-0.1
        c1.2-1.3,2-2.9,2.2-4.7C16.7,9.4,16.5,9,16.1,9C16.1,9,16.1,9,16.1,9z M15,1.3c-0.4,0-0.6,0.3-0.6,0.6v1.6c-3-3-7.9-2.9-10.9,0.1
        c-1.2,1.3-2,2.9-2.2,4.7C1.3,8.6,1.5,9,1.9,9c0,0,0,0,0.1,0c0.3,0,0.6-0.2,0.6-0.6C2.9,4.9,6,2.3,9.5,2.6c1.5,0.1,3,0.8,4,1.9h-1.8
        c-0.4,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h2.6c0.7,0,1.3-0.6,1.3-1.3V1.9C15.6,1.6,15.3,1.3,15,1.3z"
      />
    </svg>
  );
};
