import React from 'react';
import { Handles, Rail, Slider, Ticks, Tracks } from 'react-compound-slider';
import { Handle, SliderRail, Tick, Track } from './SliderChild';
import { formatTime } from '../utils/prepareFields';

/**
 * Interface representing properties for a slider component.
 *
 * @interface PropsSlider
 * @property {number} minTime - The minimum time value for the slider.
 * @property {number} maxTime - The maximum time value for the slider.
 * @property {number} value - The current value of the slider.
 * @property {function} onChange - A callback function to handle value changes.
 * @property {number} stepsMark - The number of steps or marks on the slider.
 */
interface PropsSlider {
  minTime: number;
  maxTime: number;
  value: number;
  onChange: (value: any) => void;
  stepsMark: number;
}

/**
 * A slider component for selecting a value within a specified range.
 *
 * @param {PropsSlider} props - The properties for the MapSlider component.
 * @returns {JSX.Element} The slider component.
 */
export const MapSlider = ({ minTime, maxTime, value, onChange, stepsMark }: PropsSlider) => {
  const sliderStyle = {
    position: 'relative' as 'relative',
    width: '100%',
    touchAction: 'none',
  };

  function formatTick(ms: number) {
    return formatTime(ms, (maxTime - minTime) / (stepsMark + 2));
  }

  const customInterval = Math.floor((maxTime - minTime) / stepsMark);
  const slideMarks = [];
  for (let i = 0; i < stepsMark; i++) {
    slideMarks.push(minTime + i * customInterval);
  }
  slideMarks.push(maxTime);

  return (
    <Slider
      mode={1}
      domain={[+minTime, +maxTime]}
      rootStyle={sliderStyle}
      onChange={onChange}
      onUpdate={onChange}
      values={[+value]}
    >
      <Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <div>
            {handles.map((handle) => (
              <Handle key={handle.id} handle={handle} domain={[+minTime, +maxTime]} getHandleProps={getHandleProps} />
            ))}
          </div>
        )}
      </Handles>
      <Tracks right={false}>
        {({ tracks, getTrackProps }) => (
          <div>
            {tracks.map(({ id, source, target }) => (
              <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
            ))}
          </div>
        )}
      </Tracks>
      <Ticks values={slideMarks}>
        {({ ticks }) => {
          return (
            <div>
              {ticks.map((tick) => (
                <Tick key={tick.id} tick={tick} count={ticks.length} format={formatTick} />
              ))}
            </div>
          );
        }}
      </Ticks>
    </Slider>
  );
};
