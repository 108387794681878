import React, { useCallback } from 'react';
import { PanelProps, getFieldDisplayValues, FieldDisplay, outerJoinDataFramesBox } from '@grafana/data';
import { GraphNGLegendEvent, useTheme } from '@grafana/ui';
import { cloneDeep } from 'lodash';

import { changeSeriesColorConfigFactory } from '../timeseries/overrides/colorSeriesConfigFactory';
import { hideSeriesConfigFactory } from '../timeseries/overrides/hideSeriesConfigFactory';
import { prepareGraphableFields } from './utils';
import { BoxPlotOptions, BoxPlotData } from './types';
import { calculateBoxData } from './calculation/BoxDataCalc';
import { calculateBoxTimeData } from './calculation/BoxDataTimeCalc';
import { calculateCandleData } from './calculation/CandleDataCalc';
import { BoxPlot } from './boxwhisker/GraphNG';
import { CandlePlot } from './candleplot/GraphNG';

/**
 * @typedef {Object} BoxPlotProps
 * @property {PanelProps<BoxPlotOptions>} PanelProps panel props extends with box plot options
 */

interface BoxPlotProps extends PanelProps<BoxPlotOptions> {}
/**
 * box plot panel to render candle stick and box plot chart
 * @function
 * @param {BoxPlotProps} BoxPlotProps
 * @returns {JSX.Element}
 *
 * @mermaid
 *  graph TD;
 *      A(first run) --> B[Add Query or Queries];
 *      B --> |default| C[boxplot]
 *      B --> D[candlestick]
 *      C --> E[With Time];
 *      C --> |default| F[Normal];
 *      D --> G[time series graph];
 *      F --> H[No. of Queries = No. of Box Plot]
 *      E --> I[Time interval and cluster size decide boxes]
 *      G --> J[When Open Low High Close Empty]
 *      J --> K{Additional Fields}
 *      K --> L[ignore]
 *      L --> M[Candle stick created from first query]
 *      K --> N[include]
 *      N --> O[Candle stick created from first query, Other queries if available draws line chart]
 *      G --> P[OHLC not empty]
 *      P --> Q[OHLC query make candle chart]
 *      P --> R[Other Queries make line,bar etc chart if Additional Fields Included]
 */
export const BoxPlotPanel: React.FunctionComponent<BoxPlotProps> = ({
  data,
  options,
  width,
  height,
  fieldConfig,
  onFieldConfigChange,
  replaceVariables,
  timeZone,
  timeRange,
}) => {
  const theme = useTheme();
  const onLegendClick = useCallback(
    (event: GraphNGLegendEvent) => {
      onFieldConfigChange(hideSeriesConfigFactory(event, fieldConfig, data.series));
    },
    [fieldConfig, onFieldConfigChange, data.series]
  );

  const onSeriesColorChange = useCallback(
    (label: string, color: string) => {
      onFieldConfigChange(changeSeriesColorConfigFactory(label, color, fieldConfig));
    },
    [fieldConfig, onFieldConfigChange]
  );
  /**
   * * join all frames with time
   * * another advantage is buffer will come in general database
   */
  const aligned = outerJoinDataFramesBox({ frames: data?.series });
  const getValues = (): FieldDisplay[] => {
    return getFieldDisplayValues({
      fieldConfig,
      // @ts-ignore
      reduceOptions: options.reduceOptions,
      replaceVariables,
      theme: theme,
      data: data.series,
      timeZone,
    });
  };

  const getData = getValues();
  const timeRangeMs = timeRange.to.valueOf() - timeRange.from.valueOf();
  if (!aligned?.length) {
    return (
      <div className="panel-empty">
        <p>{'No data found in response'}</p>
      </div>
    );
  }

  if (options.toShowPlot === 'boxplot' && options.typeBoxPlot === 'withtime') {
    if (timeRangeMs < options.toCluster * 60 * 1e3 || timeRangeMs < options.customClusterSize * 60 * 1e3) {
      return (
        <div className="panel-empty">
          <p>{'Time Range Should Be Greater Than The Cluster Size'}</p>
        </div>
      );
    }
  }
  const frames = prepareGraphableFields([aligned], theme);

  const dataMain: BoxPlotData = {
    frames: [],
    box: {},
    boxTime: {},
    candle: {},
  };
  const newFrames = cloneDeep(frames);
  const newFrames2 = cloneDeep(frames);
  //* Preparing All Data
  const boxData = calculateBoxData(newFrames!, getData);

  const boxTimeData = calculateBoxTimeData(
    newFrames!,
    options.toCluster,
    timeRangeMs,
    getData,
    options.customClusterSize
  );

  //* Getting min-max value according the type of boxplot.
  const minvalue = boxData.min?.values;
  const maxvalue = boxData.max?.values;
  let minmax = {
    min: Math.min(...(minvalue as any)),
    max: Math.max(...(maxvalue as any)),
  };
  if (options.typeBoxPlot === 'withtime') {
    const minvaluetime = boxTimeData.min?.values;
    const maxvaluetime = boxTimeData.max?.values;
    minmax = {
      min: Math.min(...(minvaluetime as any)),
      max: Math.max(...(maxvaluetime as any)),
    };
  }

  dataMain.box = boxData;
  dataMain.boxTime = boxTimeData;
  const insert = (arr: any, index: number, ...newItems: any) => [
    // @ts-ignore
    ...arr.slice(0, index),
    ...newItems,
    ...arr.slice(index),
  ];
  /**
   * query switch - manually defined.
   * this query is true when all Open, Low, High and Close select boxes are filled.
   * very important for candle stick chart. using throughout the UI.
   */
  let querySwitch = false;
  const { open, close, high, low } = options.ohlcData;
  let queryName = [];
  let queryIndex: number[] = [];
  if (open && close && high && low) {
    // * getData index and frames are not same - frames are plus one(for time)
    for (let index = 0; index < getData.length; index++) {
      const ele: any = getData[index];
      queryName.push(ele.display.title);
    }

    // * finding data index according to select options
    const openItem = queryName.findIndex((ele) => ele === open);
    const highItem = queryName.findIndex((ele) => ele === high);
    const lowItem = queryName.findIndex((ele) => ele === low);
    const closeItem = queryName.findIndex((ele) => ele === close);
    queryIndex.push(openItem);
    queryIndex.push(highItem);
    queryIndex.push(lowItem);
    queryIndex.push(closeItem);

    // * inserting index which are same as frames index
    let queryIndexNewFilter: number[] = [];
    queryIndexNewFilter.push(openItem + 1);
    queryIndexNewFilter.push(highItem + 1);
    queryIndexNewFilter.push(lowItem + 1);
    queryIndexNewFilter.push(closeItem + 1);
    if (queryIndex.some((ele) => ele === -1)) {
      querySwitch = false;
    }
    querySwitch = true;
    // * newFrames2
    // * first field always time
    // * next four field always OHLC order
    // * then inserting remaining fields if include is true
    if (!options.includeAllFields) {
      newFrames2![0].fields.splice(1);
      queryIndexNewFilter.forEach((ele, index) => {
        newFrames2![0].fields[index + 1] = frames![0].fields[ele];
      });
    } else {
      newFrames2![0].fields.splice(1);
      queryIndexNewFilter.forEach((ele, index) => {
        newFrames2![0].fields[index + 1] = frames![0].fields[ele];
      });
      const newFramesFilter: any = frames![0].fields.filter((ele, index) => {
        return queryIndexNewFilter.indexOf(index) === -1;
      });
      newFramesFilter.splice(0, 1);
      // @ts-ignore
      newFrames2![0].fields.push(...newFramesFilter);
    }
  }

  if (options.toShowPlot === 'candlestick' && !querySwitch) {
    if (timeRangeMs < options.toCluster * 60 * 1e3 || timeRangeMs < options.customClusterSize * 60 * 1e3) {
      return (
        <div className="panel-empty">
          <p>{'Time Range Should Be Greater Than The Cluster Size'}</p>
        </div>
      );
    }
  }

  const candleData = calculateCandleData(
    newFrames!,
    options.toCluster,
    timeRangeMs,
    getData,
    options.customClusterSize
  );

  //* Getting min-max value for CandleStick.
  const minvalueForcandle = candleData.open?.values;
  const maxvalueForCandle = candleData.open?.values;
  const minmaxCandleStick = {
    min: Math.min(...(minvalueForcandle as any)),
    max: Math.max(...(maxvalueForCandle as any)),
  };
  dataMain.candle = candleData;

  if (!(open && close && high && low)) {
    if (frames![0].fields.length >= 2) {
      // single query candle making
      const firstFrame = frames![0].fields[1];
      const firstFrameO = cloneDeep(firstFrame);
      const firstFrameH = cloneDeep(firstFrame);
      const firstFrameL = cloneDeep(firstFrame);
      const firstFrameC = cloneDeep(firstFrame);
      newFrames2![0].fields.splice(1);
      const result = insert(newFrames2![0].fields, 1, firstFrameO, firstFrameH, firstFrameL, firstFrameC);
      newFrames2![0].fields = result;

      const timeObj: any = newFrames2![0].fields[0].values;
      timeObj.buffer = [];
      timeObj.buffer = candleData.time?.values;

      const openObj: any = newFrames2![0].fields[1].values;
      openObj.buffer = [];
      openObj.buffer = candleData.open?.values;

      const highObj: any = newFrames2![0].fields[2].values;
      highObj.buffer = [];
      highObj.buffer = candleData.high?.values;

      const lowObj: any = newFrames2![0].fields[3].values;
      lowObj.buffer = [];
      lowObj.buffer = candleData.low?.values;

      const closeObj: any = newFrames2![0].fields[4].values;
      closeObj.buffer = [];
      closeObj.buffer = candleData.close?.values;
      /**
       * adding data to the frames when includeAllFields is true
       */
      if (options.includeAllFields) {
        // adding data for time series
        for (let index = 2; index < frames![0].fields.length; index++) {
          const field = frames![0].fields[index];
          field.values.toArray();
          const indexValues = candleData?.indexArray?.map((item: any) => field.values.toArray()[item]);
          if (indexValues) {
            for (let index = 0; index < indexValues.length; index++) {
              let element = indexValues[index];
              if (element === null) {
                indexValues[index] = 0;
              }
            }
          }
          newFrames2![0].fields.push(field);
          const valueObj: any = newFrames2![0].fields[index + 3].values;
          valueObj.buffer = [];
          valueObj.buffer = indexValues;
        }
      }
    }
  }

  if (
    !querySwitch &&
    options.typeBoxPlot !== 'normal' &&
    newFrames![0].fields[0].values.toArray().length < newFrames2![0].fields[0].values.toArray().length
  ) {
    return (
      <div className="panel-empty">
        <p>
          {
            'The query returns the Max number of data points and you choose a cluster size too small according to the timerange. Please Adjust Max Data Point or Cluster Size .'
          }
        </p>
      </div>
    );
  }
  // setting up customized data
  dataMain.frames! = options.toShowPlot === 'boxplot' ? frames! : newFrames2!;
  if (options.toShowPlot === 'boxplot') {
    return (
      <BoxPlot
        dataMain={dataMain}
        width={width}
        height={height}
        onLegendClick={onLegendClick}
        onSeriesColorChange={onSeriesColorChange}
        {...options}
        timeRange={timeRange}
        timeZone={timeZone}
        tooltipMode={options.tooltip.mode}
        minmax={minmax}
      />
    );
  } else {
    return (
      <CandlePlot
        dataMain={dataMain}
        timeRange={timeRange}
        width={width}
        height={height}
        onLegendClick={onLegendClick}
        onSeriesColorChange={onSeriesColorChange}
        querySwitch={querySwitch}
        queryIndex={queryIndex}
        {...options}
        tooltipMode={options.tooltip.mode}
        minmax={minmaxCandleStick}
      />
    );
  }
};
