// Store
import store from 'app/core/store';

// Models
import { DashboardModel } from 'app/features/dashboard/state/DashboardModel';
import { PanelModel } from 'app/features/dashboard/state/PanelModel';
import { TimeRange, AppEvents, rangeUtil, dateMath } from '@grafana/data';

// Utils
import { isString as _isString } from 'lodash';
import appEvents from 'app/core/app_events';
import config from 'app/core/config';

// Services
import { getTemplateSrv } from '@grafana/runtime';

// Constants
import { LS_PANEL_COPY_KEY, PANEL_BORDER } from 'app/core/constants';
import { CoreEvents } from 'app/types';

import { ShareModal } from 'app/features/dashboard/components/ShareModal';
import { deleteData, getAlertRuleList, getCarbonPullData, getCarbonPushData } from './utils';

export const removePanel = async (dashboard: DashboardModel, panel: PanelModel, ask: boolean) => {
  // confirm deletion
  var pushData = await getCarbonPushData(panel.id, dashboard.id);
  var pullData = await getCarbonPullData(panel.id, dashboard.id);
  var alerts = await getAlertRuleList(panel, dashboard);
  if (ask !== false) {
    var text2 = getText(pushData, pullData, alerts);
    appEvents.emit(CoreEvents.showConfirmModal, {
      title: 'Remove Panel',
      text: 'Are you sure you want to remove this panel?',
      text2: text2,
      icon: 'trash',
      // confirmText: confirmText,
      yesText: 'Remove',
      onConfirm: () => removePanel(dashboard, panel, false),
    });
    return;
  }
  await deleteData(pushData, pullData, alerts);
  dashboard.removePanel(panel);
};

const getText = (pushData: any, pullData: any, alerts: any) => {
  var text;
  if (
    (pushData === null || pushData.length === 0) &&
    (pullData === null || pullData.length === 0) &&
    (alerts === null || alerts.length === 0)
  ) {
    text = undefined;
  } else {
    var pushLength = pushData !== null && pushData.length !== 0 ? pushData.length : 0;
    var pullLength = pullData !== null && pullData.length !== 0 ? pullData.length : 0;
    var alertLength = alerts !== null && alerts.length !== 0 ? alerts.length : 0;
    if (pushLength === 0 && pullLength === 0 && alertLength !== 0) {
      text = 'There are some active Alerts associated with this panel.';
    } else if ((pushLength !== 0 || pullLength !== 0) && alertLength === 0) {
      text = 'There are some active Exports associated with this panel.';
    } else {
      text = 'There are some active Alerts & Exports associated with this panel.';
    }
  }
  return text;
};

export const duplicatePanel = (dashboard: DashboardModel, panel: PanelModel) => {
  dashboard.duplicatePanel(panel);
};

export const copyPanel = (panel: PanelModel) => {
  let saveModel = panel;
  if (panel instanceof PanelModel) {
    saveModel = panel.getSaveModel();
  }

  store.set(LS_PANEL_COPY_KEY, JSON.stringify(saveModel));
  appEvents.emit(AppEvents.alertSuccess, ['Panel copied. Open Add Panel to paste']);
};

export const sharePanel = (dashboard: DashboardModel, panel: PanelModel) => {
  appEvents.emit(CoreEvents.showModalReact, {
    component: ShareModal,
    props: {
      dashboard: dashboard,
      panel: panel,
    },
  });
};

export const refreshPanel = (panel: PanelModel) => {
  panel.refresh();
};

export const toggleLegend = (panel: PanelModel) => {
  console.warn('Toggle legend is not implemented yet');
  // We need to set panel.legend defaults first
  // panel.legend.show = !panel.legend.show;
  refreshPanel(panel);
};

export interface TimeOverrideResult {
  timeRange: TimeRange;
  timeInfo: string;
}

export function applyPanelTimeOverrides(panel: PanelModel, timeRange: TimeRange): TimeOverrideResult {
  const newTimeData = {
    timeInfo: '',
    timeRange: timeRange,
  };

  if (panel.timeFrom) {
    const timeFromInterpolated = getTemplateSrv().replace(panel.timeFrom, panel.scopedVars);
    const timeFromInfo = rangeUtil.describeTextRange(timeFromInterpolated);
    if (timeFromInfo.invalid) {
      newTimeData.timeInfo = 'invalid time override';
      return newTimeData;
    }

    if (_isString(timeRange.raw.from)) {
      const timeFromDate = dateMath.parse(timeFromInfo.from)!;
      newTimeData.timeInfo = timeFromInfo.display;
      newTimeData.timeRange = {
        from: timeFromDate,
        to: dateMath.parse(timeFromInfo.to)!,
        raw: {
          from: timeFromInfo.from,
          to: timeFromInfo.to,
        },
      };
    }
  }

  if (panel.timeShift) {
    const timeShiftInterpolated = getTemplateSrv().replace(panel.timeShift, panel.scopedVars);
    const timeShiftInfo = rangeUtil.describeTextRange(timeShiftInterpolated);
    if (timeShiftInfo.invalid) {
      newTimeData.timeInfo = 'invalid timeshift';
      return newTimeData;
    }

    const timeShift = '-' + timeShiftInterpolated;
    newTimeData.timeInfo += ' timeshift ' + timeShift;
    const from = dateMath.parseDateMath(timeShift, newTimeData.timeRange.from, false)!;
    const to = dateMath.parseDateMath(timeShift, newTimeData.timeRange.to, true)!;

    newTimeData.timeRange = {
      from,
      to,
      raw: {
        from,
        to,
      },
    };
  }

  if (panel.hideTimeOverride) {
    newTimeData.timeInfo = '';
  }

  return newTimeData;
}

export function getResolution(panel: PanelModel): number {
  const htmlEl = document.getElementsByTagName('html')[0];
  const width = htmlEl.getBoundingClientRect().width; // https://stackoverflow.com/a/21454625

  return panel.maxDataPoints ? panel.maxDataPoints : Math.ceil(width * (panel.gridPos.w / 24));
}

export function calculateInnerPanelHeight(panel: PanelModel, containerHeight: number): number {
  const chromePadding = panel.plugin && panel.plugin.noPadding ? 0 : config.theme.panelPadding * 2;
  const headerHeight = panel.hasTitle() ? config.theme.panelHeaderHeight : 0;
  return containerHeight - headerHeight - chromePadding - PANEL_BORDER;
}
