// export const API_URL_SERVER = `http://localhost:8050/_dash-update-component`;
// export const API_URL_SERVER = '/api/carbon-ml/';

export const defaultCheckList: ModelValues = [
  { name: 'AutoETS', active: true },
  { name: 'AutoProphet', active: true },
  { name: 'AutoSarima', active: true },
  { name: 'DefaultForecaster', active: true },
  { name: 'Arima', active: false },
  { name: 'LGBMForecaster', active: false },
  { name: 'ETS', active: false },
  { name: 'Prophet', active: false },
  { name: 'Sarima', active: false },
  { name: 'VectorAR', active: false },
  { name: 'RandomForestForecaster', active: false },
  { name: 'ExtraTreesForecaster', active: false },
];

export interface ClickhouseInfo {
  id: number;
  defaultDB: string;
}

export interface ModelOptionsList {
  exogVariables: any[];
  otherFeature: any[];
  Algos: any[];
  algoCheckList: ModelValues;
  settingList: any;
  settingValues: any;
}

export interface ModelOptions {
  name: string;
  mode: string;
  dataSettings: {
    Type: string;
    split: number;
    testingData: number | null;
    splitOrder: string;
  };
  interval: string;
  usePreviousTestingData: boolean;
  trainingDataframe: number;
  targetColumn: string;
  alias: string;
  algorithm: string;
  cron: string;
  otherFeature: any;
  exog: {
    isExog: boolean;
    variables: string[];
    dataFrame: number | null;
  };
  ensemble: {
    isEnsemble: boolean;
    algorithms: string[];
    ensembleCombinerMetric: string;
    baseMetric: string;
  };
}

export const DEFAULT_MODEL_OPTIONS_LIST: ModelOptionsList = {
  exogVariables: [],
  otherFeature: [],
  Algos: [],
  algoCheckList: defaultCheckList,
  settingList: [],
  settingValues: [],
};

export const DEFAULT_MODEL_OPTIONS: ModelOptions = {
  name: '',
  mode: 'normal',
  dataSettings: {
    Type: 'single',
    split: 80,
    testingData: null,
    splitOrder: 'serial',
  },
  interval: '',
  usePreviousTestingData: false,
  trainingDataframe: NaN,
  targetColumn: '',
  alias: '',
  algorithm: '',
  cron: 'disable',
  otherFeature: [],
  exog: {
    isExog: false,
    variables: [],
    dataFrame: null,
  },
  ensemble: {
    isEnsemble: false,
    algorithms: ['AutoETS', 'AutoProphet', 'AutoSarima', 'DefaultForecaster'],
    ensembleCombinerMetric: 'Mean',
    baseMetric: 'sMAPE',
  },
};

export interface SettingValue {
  Parameter: string;
  Value: any;
}

export interface SettingList {
  name: string;
  value: any;
  component: string;
  type?: string;
  options?: any;
  desc: string;
}

export const timeIntervalOptions = [
  { label: '6 Hours', value: '6h' },
  { label: '12 Hours', value: '12h' },
  { label: '1 Day', value: '1d' },
  { label: '5 Days', value: '5d' },
  { label: '7 Days', value: '7d' },
  { label: '10 Days', value: '10d' },
  { label: '15 Days', value: '15d' },
  { label: '3 Weeks', value: '3w' },
  { label: '4 Weeks', value: '4w' },
  { label: '1 Month', value: '4w' },
  { label: '2 Months', value: '8w' },
  { label: '3 Months', value: '12w' },
  { label: '6 Months', value: '24w' },
  { label: '1 Year', value: '52w' },
  { label: '2 Years', value: '104w' },
  { label: '5 Years', value: '260w' },
];

export const boolOptions = [
  { label: 'True', value: 'True' },
  { label: 'False', value: 'False' },
];

export const trainingModes = [
  { label: 'Normal', value: 'normal' },
  { label: 'Ensemble', value: 'ensemble' },
];

export const trainTestOption = [
  { label: 'Single', value: 'single' },
  { label: 'Separate train/test', value: 'separate' },
];

export const splitOrderOptions = [
  { label: 'Serial', value: 'serial' },
  { label: 'Random', value: 'random' },
];

export const restrictedRandomSplitModels = [
  'AutoETS',
  'AutoSarima',
  'DefaultForecaster',
  'LGBMForecaster',
  'ETS',
  'Sarima',
  'VectorAR',
  'RandomForestForecaster',
  'ExtraTreesForecaster',
  'DefaultDetector',
  'ETSDetector',
  'MSESDetector',
  'SarimaDetector',
  'DeepPointAnomalyDetector',
];

export enum EnumTabs {
  TrainTab = 'train-tab',
  RetrainTab = 'retrain-tab',
  NewForecast = 'new-forecast',
  TrainingTestPlot = 'train-test-plot',
  TrainMetrics = 'train-metrics',
}

export const trainTabs = [
  { id: EnumTabs.TrainTab, text: 'Create Model' },
  { id: EnumTabs.TrainMetrics, text: 'Training Metrics' },
];
export const editTabs = [
  { id: EnumTabs.RetrainTab, text: 'Retrain Model' },
  { id: EnumTabs.TrainingTestPlot, text: 'Train/Test Plot' },
  // { id: EnumTabs.TrainMetrics, text: 'Training Metrics' },
  { id: EnumTabs.NewForecast, text: 'New Forecast' },
];

export const forecastSince = [
  { label: 'Current Timestamp', value: 'current' },
  { label: 'Training Timestamp', value: 'train' },
  { label: 'Custom Timestamp', value: 'custom' },
];

export const anomalyEditTabs = [{ id: EnumTabs.RetrainTab, text: 'Retrain Model' }];
interface Model {
  name: string;
  active: boolean;
}

export type ModelValues = Model[];

export interface BaseMetricsType {
  [key: string]: string;
}
export const EnsembleMetricsOptions = [
  { label: 'Mean', value: 'Mean' },
  { label: 'Median', value: 'Median' },
  { label: 'Max', value: 'Max' },
];

export const BaseMetricsOptions = [
  { label: 'MAE', value: 'MAE', description: 'Mean Absolute Error' },
  { label: 'MARRE', value: 'MARRE', description: 'Mean Absolute Ranged Relative Error' },
  { label: 'RMSE', value: 'RMSE', description: 'Root Mean Square Error' },
  { label: 'sMAPE', value: 'sMAPE', description: 'Symmetric Mean Absolute Percentage Error' },
  { label: 'RMSPE', value: 'RMSPE', description: 'Root Mean Square Percentage Error' },
];

export const BaseMetrics: BaseMetricsType = {
  MAE: 'Mean Absolute Error',
  MARRE: 'Mean Absolute Ranged Relative Error',
  RMSE: 'Root Mean Square Error',
  sMAPE: 'Symmetric Mean Absolute Percentage Error',
  RMSPE: 'Root Mean Square Percentage Error',
};

export enum PayloadI {
  Interval = 'interval',
  Algorithm = 'algorithm',
  AlgoSettings = 'algorithm-settings',
}

export const Payload = {
  [PayloadI.Interval]: {
    output: 'forecasting-prediction-interval.options',
    outputs: { id: 'forecasting-prediction-interval', property: 'options' },
    inputs: [{ id: 'forecasting-prediction-interval-parent', property: 'n_clicks', value: 1 }],
    changedPropIds: ['forecasting-prediction-interval-parent.n_clicks'],
  },
  [PayloadI.Algorithm]: {
    output: 'forecasting-select-algorithm.options',
    outputs: {
      id: 'forecasting-select-algorithm',
      property: 'options',
    },
    inputs: [
      {
        id: 'forecasting-select-algorithm-parent',
        property: 'n_clicks',
        value: 2,
      },
    ],
    changedPropIds: ['forecasting-select-algorithm-parent.n_clicks'],
    state: [
      {
        id: 'forecasting-select-target',
        property: 'value',
        value: null,
      },
    ],
  },
  [PayloadI.AlgoSettings]: {
    output: 'forecasting-param-table.children',
    outputs: { id: 'forecasting-param-table', property: 'children' },
    changedPropIds: ['forecasting-select-algorithm.value'],
  },
};

export const allSettings: any = {
  max_forecast_steps: {
    name: 'max_forecast_steps',
    value: 'None',
    component: 'input',
    options: [{ label: 'None', value: 'None' }],
    desc: `Max # of steps we would like to forecast for. Required for some models like MSES. Positive integer values are allowed.`,
  },
  granularity: {
    name: 'granularity',
    value: 'None',
    component: 'input',
    options: [{ label: 'None', value: 'None' }],
    desc: `the granularity at which the input time series should be sampled, e.g. “5min”, “1h”, “1d”, etc`,
  },
  order: {
    name: 'order',
    value: '(4, 1, 2)',
    component: 'input',
    type: 'text',
    desc: `Order is (p, d, q) for an ARIMA(p, d, q) process. d must be an integer indicating the 
    integration order of the process, while p and q must be integers indicating the AR and MA orders 
    (so that all lags up to those orders are included).`,
  },
  seasonal_order: {
    name: 'seasonal_order',
    value: '(0, 0, 0, 0)',
    component: 'input',
    type: 'text',
    desc: `(0, 0, 0, 0) because ARIMA has no seasonal order`,
  },
  exog_aggregation_policy: {
    name: 'exog_aggregation_policy',
    value: 'Mean',
    component: 'select',
    options: [
      {
        label: 'Mean',
        value: 'Mean',
      },
      {
        label: 'Sum',
        value: 'Sum',
      },
      {
        label: 'Min',
        value: 'Min',
      },
      {
        label: 'Median',
        value: 'Median',
      },
      {
        label: 'Max',
        value: 'Max',
      },
      {
        label: 'Last',
        value: 'Last',
      },
      {
        label: 'First',
        value: 'First',
      },
    ],
    desc: `The policy to use for aggregating values in exogenous data, to ensure it is sampled at the same timestamps as the endogenous data.\n
    Mean: This type of aggregation policy calculates the average value of the exogenous variables across the corresponding timestamps. 
    It provides a measure of the central tendency of the data.\n
    Sum: This type of aggregation policy calculates the total sum of the exogenous variable values across the corresponding timestamps. 
    It provides a measure of the cumulative or collective effect of the data.\n
    Min: This type of aggregation policy selects the minimum value of the exogenous variable across the corresponding timestamps. 
    It represents the smallest observed value.\n
    Median: This type of aggregation policy selects the median value of the exogenous variable across the corresponding timestamps. 
    The median represents the middle value when the data is arranged in ascending or descending order.\n
    Max: This type of aggregation policy selects the maximum value of the exogenous variable across the corresponding timestamps. 
    It represents the largest observed value.\n
    Last: This type of aggregation policy selects the most recent value of the exogenous variable at the last available timestamp. 
    It represents the value at the end of the observed period.\n
    First: This type of aggregation policy selects the earliest value of the exogenous variable at the first available timestamp. 
    It represents the value at the beginning of the observed period.
    `,
  },
  exog_missing_value_policy: {
    name: 'exog_missing_value_policy',
    value: 'ZFill',
    component: 'select',
    options: [
      {
        label: 'ZFill',
        value: 'ZFill',
      },
      {
        label: 'FFill',
        value: 'FFill',
      },
      {
        label: 'Interpolate',
        value: 'Interpolate',
      },
      {
        label: 'BFill',
        value: 'BFill',
        description: ``,
      },
      {
        label: 'Nearest',
        value: 'Nearest',
      },
    ],
    desc:
      'The policy to use for imputing missing values in exogenous data, to ensure it is sampled at the same timestamps as the endogenous data.\nZFill: This type of missing value policy involves imputing missing values in the exogenous data by replacing them with zeros (0). ZFill assumes that the missing values represent a lack of information and assigns a neutral value to those entries.\nFFill: This type of missing value policy involves propagating the last observed value forward in time to fill in the missing values in the exogenous data. It assumes that the value remains constant until a new observation is available.\nInterpolate: This type of missing value policy uses interpolation techniques to estimate the missing values in the exogenous data. Interpolation methods such as linear interpolation, spline interpolation, or polynomial interpolation are commonly used to approximate the missing values based on the observed data.\nBFill: This type of missing value policy involves propagating the next observed value backward in time to fill in the missing values in the exogenous data. It assumes that the value remains constant until a new observation is available.\nNearest: This type of missing value policy imputes missing values in the exogenous data by using the value of the nearest available observation. It assigns the value of the closest available timestamp to the missing value.',
  },
  learning_rate: {
    name: 'learning_rate',
    value: '0.1',
    component: 'input',
    type: 'number',
    desc: `learning rate for boosting`,
  },
  n_jobs: {
    name: 'n_jobs',
    value: '-1',
    component: 'input',
    type: 'number',
    desc: ` num of threading, -1 or 0 indicates device default, positive int indicates num of threads`,
  },
  n_estimators: {
    name: 'n_estimators',
    value: '100',
    component: 'input',
    type: 'number',
    desc: `number of base estimators for the tree ensemble. Positve ineger values are allowed`,
  },
  max_depth: {
    name: 'max_depth',
    value: 'None',
    component: 'input',
    options: [{ label: 'None', value: 'None' }],
    desc: `max depth of base estimators. Positive interger value is allowed`,
  },
  random_state: {
    name: 'random_state',
    value: 'None',
    component: 'input',
    options: [{ label: 'None', value: 'None' }],
    desc: `random seed for bagging. Integer values are allowed.`,
  },
  maxlags: {
    name: 'maxlags',
    value: 'None',
    component: 'input',
    options: [{ label: 'None', value: 'None' }],
    desc: `Size of historical window to base the forecast on. Positive integer value is allowed.`,
  },
  prediction_stride: {
    name: 'prediction_stride',
    value: '1',
    type: 'number',
    component: 'input',
    desc: `the number of steps being forecasted in a single call to underlying the model. Positive integer values are allowed.`,
  },
  error: {
    name: 'error',
    value: 'add',
    component: 'select',
    options: [
      { label: 'add', value: 'add' },
      { label: 'mul', value: 'mul' },
    ],
    desc: `The error term. “add” or “mul”.`,
  },
  trend: {
    name: 'trend',
    value: 'add',
    component: 'select',
    options: [
      { label: 'add', value: 'add' },
      { label: 'mul', value: 'mul' },
      { label: 'None', value: 'None' },
    ],
    desc: `The trend component. “add”, “mul” or None.`,
  },
  damped_trend: {
    name: 'damped_trend',
    value: 'True',
    component: 'select',
    options: boolOptions,
    desc: `Whether or not an included trend component is damped.`,
  },
  seasonal: {
    name: 'seasonal',
    value: 'add',
    component: 'select',
    options: [
      { label: 'add', value: 'add' },
      { label: 'mul', value: 'mul' },
      { label: 'None', value: 'None' },
    ],
    desc: `The seasonal component. “add”, “mul” or None.`,
  },
  seasonal_periods: {
    name: 'seasonal_periods',
    value: 'None',
    component: 'input',
    options: [{ label: 'None', value: 'None' }],
    desc: `The length of the seasonality cycle. None by default. Positive integer values are allowed.`,
  },
  refit: {
    name: 'refit',
    value: 'True',
    component: 'select',
    options: boolOptions,
    desc: ` if True, refit the full ETS model when 
    time_series_prev is given to the forecast method (slower). If False, simply perform exponential smoothing (faster).`,
  },
  auto_seasonality: {
    name: 'auto_seasonality',
    value: 'True',
    component: 'select',
    options: boolOptions,
    desc: `Whether to automatically detect the seasonality.`,
  },
  auto_error: {
    name: 'auto_error',
    value: 'True',
    component: 'select',
    options: boolOptions,
    desc: `Whether to automatically detect the error components.`,
  },
  auto_trend: {
    name: 'auto_trend',
    value: 'True',
    component: 'select',
    options: boolOptions,
    desc: `Whether to automatically detect the seasonal components.`,
  },
  auto_seasonal: {
    name: 'auto_seasonal',
    value: 'True',
    component: 'select',
    options: boolOptions,
    desc: `Whether to automatically detect the seasonal components.`,
  },
  auto_damped: {
    name: 'auto_damped',
    value: 'True',
    component: 'select',
    options: boolOptions,
    desc: `Whether to automatically detect the damped trend components.`,
  },
  periodicity_strategy: {
    name: 'periodicity_strategy',
    value: 'ACF',
    component: 'select',
    options: [
      {
        label: 'ACF',
        value: 'ACF',
      },
      {
        label: 'Min',
        value: 'Min',
      },
      {
        label: 'Max',
        value: 'Max',
      },
      {
        label: 'All',
        value: 'All',
      },
    ],
    desc: `Strategy to choose the seasonality if multiple candidates are detected.\n
    ACF: This type of periodicity strategy involves analyzing the Autocorrelation Function of the time series. The ACF measures the correlation between 
    a time series and its lagged values. By examining the peaks in the ACF plot, the most prominent periodicity can be identified as the seasonality.\n
    Min: This type of periodicity strategy selects the minimum candidate from the multiple detected seasonality options. 
    It chooses the seasonality with the shortest period among the candidates.\n
    Max: This type of periodicity strategy selects the maximum candidate from the multiple detected seasonality options. 
    It chooses the seasonality with the longest period among the candidates.\n
    All: This type of periodicity strategy considers all the detected seasonality candidates and does not make a selection. 
    It retains all the identified seasonalities for further analysis or modeling.`,
  },
  auto_pqPQ: {
    name: 'auto_pqPQ',
    value: 'True',
    component: 'select',
    options: boolOptions,
    desc: ``,
  },
  auto_d: {
    name: 'auto_d',
    value: 'True',
    component: 'select',
    options: boolOptions,
    desc: ``,
  },
  auto_D: {
    name: 'auto_D',
    value: 'True',
    component: 'select',
    options: boolOptions,
    desc: ``,
  },
  maxiter: {
    name: 'maxiter',
    value: 'None',
    component: 'input',
    options: [{ label: 'None', value: 'None' }],
    desc: `The maximum number of iterations to perform`,
  },
  max_k: {
    name: 'max_k',
    value: '100',
    type: 'number',
    component: 'input',
    desc: `Maximum number of models considered in the stepwise search`,
  },
  max_dur: {
    name: 'max_dur',
    value: '3600',
    type: 'number',
    component: 'input',
    desc: `Maximum training time considered in the stepwise search`,
  },
  approximation: {
    name: 'approximation',
    value: 'None',
    component: 'select',
    options: [
      { label: 'None', value: 'None' },
      { label: 'True', value: 'True' },
      { label: 'False', value: 'False' },
    ],
    desc: `Whether to use approx_iter iterations (instead of maxiter) to speed up computation. If None, we use approximation mode when the training data is too long (>150), or when the length off the period is too high (periodicity > 12).`,
  },
  approx_iter: {
    name: 'approx_iter',
    value: 'None',
    component: 'input',
    options: [{ label: 'None', value: 'None' }],
    desc: ` The number of iterations to perform in approximation mode`,
  },
  information_criterion: {
    name: 'information_criterion',
    value: 'AIC',
    options: [
      {
        label: 'AIC',
        value: 'AIC',
      },
      {
        label: 'BIC',
        value: 'BIC',
      },
      {
        label: 'AICc',
        value: 'AICc',
      },
    ],
    component: 'select',
    desc: `information criterion to select the best model.\n
    AIC: (Akaike Information Criterion): AIC is an information criterion that evaluates the goodness of fit of a model 
    while penalizing for the number of parameters used. It balances the trade-off between model complexity and goodness of fit. 
    The lower the AIC value, the better the model is considered to be.\n
    BIC: (Bayesian Information Criterion): BIC, also known as Schwarz Information Criterion (SIC), 
    is another information criterion used for model selection. Similar to AIC, it takes into account the goodness of fit and model complexity. \n
    BIC penalizes model complexity more severely than AIC, making it suitable when selecting among a large number of models. As with AIC, a lower BIC value indicates a better model.\n
    AICc: (AIC with a correction for small sample sizes): AICc is a modification of AIC that applies a correction term for small sample sizes. 
    It is particularly useful when the number of observations is small relative to the number of model parameters. \n
    AICc provides a more reliable estimate of model performance in such cases.`,
  },
  additive_only: {
    name: 'additive_only',
    value: 'False',
    component: 'select',
    options: boolOptions,
    desc: `If True, the search space will only consider additive models.`,
  },
  allow_multiplicative_trend: {
    name: 'allow_multiplicative_trend',
    value: 'False',
    component: 'select',
    options: boolOptions,
    desc: `If True, models with multiplicative trend are allowed in the search space.`,
  },
  restrict: {
    name: 'restrict',
    value: 'True',
    component: 'select',
    options: boolOptions,
    desc: `If True, the models with infinite variance will not be allowed in the search space.`,
  },
  pval: {
    name: 'pval',
    value: '0.05',
    component: 'input',
    type: 'number',
    desc: `p-value for deciding whether a detected seasonality is statistically significant. Decimal values are allowed`,
  },
  max_lag: {
    name: 'max_lag',
    value: 'None',
    component: 'input',
    options: [{ label: 'None', value: 'None' }],
    desc: `max lag considered for seasonality detection. Positive integer values are allowed.`,
  },
  yearly_seasonality: {
    name: 'yearly_seasonality',
    value: 'auto',
    component: 'input',
    options: [
      { label: 'auto', value: 'auto' },
      // { label: 'True', value: 'True' },
      // { label: 'False', value: 'False' },
    ],
    desc: ` If bool, whether to enable yearly seasonality. By default, it is activated if there are >= 2 years of history, 
    but deactivated otherwise. If int, this is the number of Fourier series components used to model the seasonality (default = 10).`,
  },
  weekly_seasonality: {
    name: 'weekly_seasonality',
    value: 'auto',
    component: 'input',
    options: [
      { label: 'auto', value: 'auto' },
      // { label: 'True', value: 'True' },
      // { label: 'False', value: 'False' },
    ],
    desc: ` If bool, whether to enable weekly seasonality. By default, it is activated if there are >= 2 weeks of history, but deactivated otherwise. 
    If int, this is the number of Fourier series components used to model the seasonality (default = 3).`,
  },
  daily_seasonality: {
    name: 'daily_seasonality',
    value: 'auto',
    component: 'input',
    options: [
      { label: 'auto', value: 'auto' },
      // { label: 'True', value: 'True' },
      // { label: 'False', value: 'False' },
    ],
    desc: `If bool, whether to enable daily seasonality. By default, it is activated if there are >= 2 days of history, 
    but deactivated otherwise. If int, this is the number of Fourier series components used to model the seasonality (default = 4).`,
  },
  seasonality_mode: {
    name: 'seasonality_mode',
    value: 'additive',
    component: 'select',
    options: [
      {
        label: 'additive',
        value: 'additive',
      },
      {
        label: 'multiplicative',
        value: 'multiplicative',
      },
    ],
    desc: `‘additive’ (default) or ‘multiplicative’.
    additive: In additive seasonality, the seasonal component is added to the trend and residual components of the time series. 
    It assumes that the seasonal effects have a consistent magnitude regardless of the level of the time series. 
    In other words, the seasonal pattern is constant over time and is added to the overall trend in a linear fashion.
    multiplicative: In multiplicative seasonality, the seasonal component is multiplied with the trend and residual components of the time series. 
    It assumes that the seasonal effects are proportional to the level of the time series. 
    This means that the magnitude of the seasonal pattern varies with the level of the time series.`,
  },
  uncertainty_samples: {
    name: 'uncertainty_samples',
    value: '100',
    component: 'input',
    type: 'number',
    desc: `The number of posterior samples to draw in order to calibrate the anomaly scores.`,
  },
  min_samples_split: {
    name: 'min_samples_split',
    value: '2',
    component: 'input',
    type: 'number',
    desc: `min split for tree leaves`,
  },
  n_threads: {
    name: 'n_threads',
    value: '1',
    component: 'input',
    type: 'number',
    desc: `the number of parallel threads to use for relevant models`,
  },
  max_score: {
    name: 'max_score',
    value: '1000',
    component: 'input',
    type: 'number',
    desc: `the maximum possible uncalibrated score`,
  },
  enable_calibrator: {
    name: 'enable_calibrator',
    value: 'True',
    component: 'select',
    options: boolOptions,
    desc: `Whether to enable calibration of the ensemble anomaly score. True by default.`,
  },
  enable_threshold: {
    name: 'enable_threshold',
    value: 'True',
    component: 'select',
    options: boolOptions,
    desc: `whether to enable the thresholding rule when post-processing anomaly scores.`,
  },
  train_window: {
    name: 'train_window',
    value: 'None',
    component: 'input',
    type: 'text',
    desc: `A string representing a duration of time to serve as the scope for a rolling dynamic baseline model.`,
  },
  wind_sz: {
    name: 'wind_sz',
    value: '1h',
    component: 'input',
    type: 'text',
    desc: `The window size in minutes to bucket times of day. This parameter only applied if a daily trend is one of the trends used.`,
  },
  max_n_samples: {
    name: 'max_n_samples',
    value: 'None',
    component: 'input',
    options: [{ label: 'None', value: 'None' }],
    desc: `Maximum number of samples to allow the isolation forest to train on. Specify None to use all samples in the training data.`,
  },
  online_updates: {
    name: 'online_updates',
    value: 'True',
    component: 'select',
    options: boolOptions,
    desc: `Whether to update the model while running using it to evaluate new data.`,
  },
  max_backstep: {
    name: 'max_backstep',
    value: 'None',
    component: 'input',
    options: [{ label: 'None', value: 'None' }],
    desc: `Max backstep to use in forecasting. `,
  },
  recency_weight: {
    name: 'recency_weight',
    value: '0.5',
    component: 'input',
    type: 'number',
    desc: `The recency weight parameter to use when estimating delta_hat.`,
  },
  accel_weight: {
    name: 'accel_weight',
    value: '1.0',
    component: 'input',
    type: 'number',
    desc: `The weight to scale the acceleration by when computing delta_hat. `,
  },
  optimize_acc: {
    name: 'optimize_acc',
    value: 'True',
    component: 'select',
    options: boolOptions,
    desc: `If True, the acceleration correction will only be used at scales ranging from 1,…(max_backstep+max_forecast_steps)/2.`,
  },
  eta: {
    name: 'eta',
    value: '0.0',
    component: 'input',
    type: 'number',
    desc: `The parameter used to control the rate at which recency_weight gets tuned when online updates are made to the model and losses can be computed.`,
  },
  rho: {
    name: 'rho',
    value: '0.0',
    component: 'input',
    type: 'number',
    desc: `The parameter that determines what fraction of the overall error is due to velcity error, while the rest is due to the complement.`,
  },
  phi: {
    name: 'phi',
    value: '2.0',
    component: 'input',
    type: 'number',
    desc: `The parameter used to exponentially inflate the magnitude of loss error at different scales.`,
  },
  inflation: {
    name: 'inflation',
    value: '1.0',
    component: 'input',
    type: 'number',
    desc: `The inflation exponent to use when computing the distribution p(b|h) over the models when forecasting at horizon h according to standard errors of the estimated velocities over the models; inflation=1 is equivalent to using the softmax function.`,
  },
  parallel: {
    name: 'parallel',
    value: 'True',
    component: 'select',
    options: boolOptions,
    desc: ` If true, then the forest will create an internal thread pool. Forest updates and traversals will be submitted to this thread pool, and individual trees will be updated or traversed in parallel. For larger shingle sizes, dimensions, and number of trees, parallelization may improve throughput. We recommend users benchmark against their target use case.`,
  },
  seed: {
    name: 'seed',
    value: 'None',
    component: 'input',
    options: [{ label: 'None', value: 'None' }],
    desc: `the random seed.`,
  },
  thread_pool_size: {
    name: 'thread_pool_size',
    value: '1',
    component: 'input',
    type: 'number',
    desc: `The number of threads to use in the internal thread pool.`,
  },
  max_day: {
    name: 'max_day',
    value: '4',
    component: 'input',
    type: 'number',
    desc: `maximum number of week days stored in memory (only mean and std of each window are stored). Here, the days are first bucketed by weekday and then by window id.`,
  },
  local_wind_sz: {
    name: 'local_wind_sz',
    value: '21',
    component: 'input',
    type: 'number',
    desc: `Number of previous saliency points to consider when computing the anomaly score`,
  },
  q: {
    name: 'q',
    value: '3',
    component: 'input',
    type: 'number',
    desc: `Window size of local frequency average computations`,
  },
  estimated_points: {
    name: 'estimated_points',
    value: '5',
    component: 'input',
    type: 'number',
    desc: `Number of padding points to add to the timeseries for saliency map calculations.`,
  },
  predicting_points: {
    name: 'predicting_points',
    value: '5',
    component: 'input',
    type: 'number',
    desc: `Number of points to consider when computing gradient for padding points`,
  },
  base: {
    name: 'base',
    value: '2',
    component: 'input',
    type: 'number',
    desc: `The base to use for computing exponentially distant lags.`,
  },
  n_lags: {
    name: 'n_lags',
    value: 'None',
    component: 'input',
    options: [{ label: 'None', value: 'None' }],
    desc: `The number of lags to be used. If None, n_lags will be chosen later as the maximum number of lags possible for the initial training set.`,
  },
  lag_inflation: {
    name: 'lag_inflation',
    value: '1.0',
    component: 'input',
    type: 'number',
    desc: `If lag_inflation > 1, the higher the lag inflation, the less likely the model is to select a higher lag’s z-score as the anomaly score.`,
  },
};

export const allInputValidationRegex: any = {
  max_forecast_steps: /^(None|\d+)$/,
  granularity: /^(None|[1-9]\d*(s|min|h|d|w|mo|y))$/,
  order: /^\(\d+(,\s*\d+){2}\)$/,
  seasonal_order: /^\(-?\d+(,\s*-?\d+){3}\)$/,
  learning_rate: /^(0(\.\d+)?|1(\.0+)?|[0-9]\.\d+)$/,
  n_jobs: /^(-1|0|\d+)$/,
  n_estimators: /^\d+$/,
  max_depth: /^(None|\d+)$/,
  maxlags: /^(None|\d+)$/,
  random_state: /^(None|-?\d+)$/,
  prediction_stride: /^(None|\d+)$/,
  seasonal_periods: /^(None|\d+)$/,
  pval: /^(0(?:\.\d+)?|1(?:\.0+)?)$/,
  max_lag: /^(None|\d+)$/,
  uncertainty_samples: /^\d+$/,
  min_samples_split: /^\d+$/,
  daily_seasonality: /^(auto|\d+)$/,
  weekly_seasonality: /^(auto|\d+)$/,
  yearly_seasonality: /^(auto|\d+)$/,
  n_threads: /^[1-9]\d*$/,
  max_score: /^[1-9]\d*$/,
  train_window: /^(None|[1-9]\d*(s|min|h|d|w|mo|y))$/,
  wind_sz: /^[1-9]\d*(s|min|h|d|w|mo|y)$/,
  max_n_samples: /^(None|\d+)$/,
  max_backstep: /^(None|\d+)$/,
  recency_weight: /^\+?(\d+\.\d+|\.\d+|\d+)$/,
  accel_weight: /^\+?(\d+\.\d+|\.\d+|\d+)$/,
  eta: /^\+?(\d+\.\d+|\.\d+|\d+)$/,
  rho: /^\+?(\d+\.\d+|\.\d+|\d+)$/,
  phi: /^\+?(\d+\.\d+|\.\d+|\d+)$/,
  inflation: /^\+?(\d+\.\d+|\.\d+|\d+)$/,
  seed: /^(None|\d+)$/,
  thread_pool_size: /^[1-9]\d*$/,
  max_day: /^[1-9]\d*$/,
  local_wind_sz: /^[1-9]\d*$/,
  q: /^(None|\d+)$/,
  estimated_points: /^[1-9]\d*$/,
  predicting_points: /^[1-9]\d*$/,
  base: /^[1-9]\d*$/,
  n_lags: /^(None|\d+)$/,
  lag_inflation: /^(0(\.\d+)?|1(\.0+)?|[0-9]\.\d+)$/,
  maxiter: /^(None|\d+)$/,
  max_k: /^[1-9]\d*$/,
  max_dur: /^\d+(\.\d+)?$/,
  approx_iter: /^(None|\d+)$/,
};
