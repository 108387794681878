import React, { PureComponent } from 'react';
import { DialOptions } from './types';
import { FieldDisplay, getFieldDisplayValues, PanelProps, VizOrientation, getColorForTheme } from '@grafana/data';
import { DataLinksContextMenu, VizRepeater, VizRepeaterRenderValueProps } from '@grafana/ui';
import { v4 as uuidv4 } from 'uuid';

//@ts-ignore
import Gradient from 'javascript-color-gradient';
import LiquidGauge from './components/LiquidGauge';
import CanvasGauge from './components/CanvasGauge';
import DGauge from './components/DGauge';
import Gauger from './components/DefGauge';
import { config } from '@grafana/runtime';

const schema = (expression: string | undefined) => {
  switch (expression) {
    case 'continuous-GrYlRd':
      return ['green', 'yellow', 'red'];
    case 'continuous-RdYlGr':
      return ['red', 'yellow', 'green'];
    case 'continuous-BlYlRd':
      return ['dark-blue', 'super-light-yellow', 'dark-red'];
    case 'continuous-YlRd':
      return ['super-light-yellow', 'dark-red'];
    case 'continuous-BlPu':
      return ['blue', 'purple'];
    case 'continuous-YlBl':
      return ['super-light-yellow', 'dark-blue'];
    case 'continuous-blues':
      return ['panel-bg', 'dark-blue'];
    case 'continuous-reds':
      return ['panel-bg', 'dark-red'];
    case 'continuous-greens':
      return ['panel-bg', 'dark-green'];
    case 'continuous-purples':
      return ['panel-bg', 'dark-purple'];
    default:
      return ['transparent'];
  }
};

export class DialGauge extends PureComponent<PanelProps<DialOptions>> {
  checkColor(currFrame: FieldDisplay, nrOfLevels: number) {
    const { defaults } = this.props.fieldConfig;

    let colors: any = [];
    const max: any = currFrame.field.max;
    if (defaults.color?.mode === 'thresholds') {
      const steps: any = defaults.thresholds?.steps;
      for (let i = 0; i < steps.length - 1; i++) {
        if (i === 0) {
          let times = Math.round((steps[i + 1].value / max) * nrOfLevels);
          while (times > 0) {
            colors.push(getColorForTheme(steps[i].color, config.theme));
            times -= 1;
          }
        } else {
          let times = Math.round(((steps[i + 1].value - steps[i].value) / max) * nrOfLevels);
          while (times > 0) {
            colors.push(getColorForTheme(steps[i].color, config.theme));
            times -= 1;
          }
        }
      }
      let balance = colors.length - nrOfLevels;
      if (balance < 0) {
        while (balance < 0) {
          colors.push(getColorForTheme(steps[steps.length - 1].color, config.theme));
          balance += 1;
        }
      }
      if (balance > 0) {
        while (balance > 0) {
          colors.pop();
          balance -= 1;
        }
      }
    } else if (defaults.color?.mode === 'fixed') {
      // @ts-ignore
      colors.push(getColorForTheme(defaults.color.fixedColor, config.theme));
    } else if (defaults.color?.mode === 'palette-classic') {
      colors = Array(nrOfLevels).fill(currFrame.display.color);
    } else if (defaults.color?.mode === undefined) {
      colors = Array(nrOfLevels).fill(currFrame.display.color);
    } else {
      const set = schema(defaults.color?.mode);
      set.forEach((color: string) => {
        // if (colors.length < nrOfLevels) {
        colors.push(getColorForTheme(color, config.theme));
        // }
      });
    }
    return colors;
  }
  // checkcolor function ends here

  renderComponent = (
    valueProps: VizRepeaterRenderValueProps<FieldDisplay>
    // menuProps: DataLinksContextMenuApi
  ): JSX.Element => {
    const { options } = this.props;
    const { width, height, value, count } = valueProps;
    const { defaults } = this.props.fieldConfig;

    if (value.colIndex === 1) {
      return (
        <Gauge
          // value={clearNameForSingleSeries(count, fieldConfig.defaults, display)}
          width={width}
          height={height}
          frame={value}
          colors={this.checkColor(value, options.nrOfLevels)}
          nrOfLevels={options.nrOfLevels}
          gaugeThickness={options.gaugeThickness}
          rotation={options.Rotation}
          gaugeType2={options.gaugeType2}
          showNum2={options.showNum2}
          showName2={options.showName2}
          count={count}
          defaults={defaults}
          GaugeArc={options.GaugeArc}
          config={config}
        />
      );
    } else {
      return (
        <Gauge
          // value={clearNameForSingleSeries(count, fieldConfig.defaults, display)}
          width={width}
          height={height}
          frame={value}
          colors={value.display.color}
          nrOfLevels={options.nrOfLevels}
          gaugeType2={options.gaugeType2}
          rotation={options.Rotation}
          showNum2={options.showNum2}
          showName2={options.showName2}
          count={count}
          defaults={defaults}
          gaugeThickness={options.gaugeThickness}
          GaugeArc={options.GaugeArc}
          config={config}
        />
      );
    }
  };

  renderValue = (valueProps: VizRepeaterRenderValueProps<FieldDisplay>): JSX.Element => {
    const { value } = valueProps;
    const { getLinks, hasLinks } = value;

    if (hasLinks && getLinks) {
      return (
        <DataLinksContextMenu links={getLinks} config={value.field}>
          {(api) => {
            return this.renderComponent(valueProps);
          }}
        </DataLinksContextMenu>
      );
    }

    return this.renderComponent(valueProps);
  };

  getValues = (): FieldDisplay[] => {
    const { data, options, replaceVariables, fieldConfig, timeZone } = this.props;
    return getFieldDisplayValues({
      fieldConfig,
      reduceOptions: options.reduceOptions,
      replaceVariables,
      theme: config.theme,
      data: data.series,
      //@ts-ignore
      autoMinMax: true,
      timeZone,
    });
  };

  render() {
    const { height, width, data, renderCounter } = this.props;
    return (
      <VizRepeater
        getValues={this.getValues}
        renderValue={this.renderValue}
        width={width}
        height={height}
        source={data}
        autoGrid={true}
        renderCounter={renderCounter}
        orientation={VizOrientation.Auto}
      />
    );
  }
}

interface GaugeProps {
  frame: FieldDisplay;
  width: number;
  height: number;
  colors: string[] | any;
  nrOfLevels: number;
  gaugeType2: string;
  showNum2: boolean;
  showName2: boolean;
  count: number;
  defaults: any;
  gaugeThickness: number;
  rotation: number;
  GaugeArc: number;
  config: any;
}

// valueProps added here
export const Gauge = (props: GaugeProps) => {
  const {
    GaugeArc,
    rotation,
    gaugeThickness,
    defaults,
    frame,
    width,
    height,
    colors,
    nrOfLevels,
    gaugeType2,
    showNum2,
    showName2,
    count,
    config,
  } = props;

  let needlecolor: string;
  // const percent = frame.display.numeric / Number(frame.field.max);
  let percent: any | undefined;
  percent = frame.display.percent;
  if (percent === undefined) {
    percent = 0;
  }
  let originalPercent = Number(percent.toFixed(2));

  const colorGradient = new Gradient();
  let colorval: number;
  if (colors.length > 1) {
    let len = colors.length;
    colorGradient.setGradient(colors[0], colors[len - 1]);
    colorGradient.setMidpoint(nrOfLevels);
    colorval = Number(originalPercent * nrOfLevels);
    if (isNaN(originalPercent)) {
      needlecolor = colors[0];
    } else if (originalPercent === 0) {
      needlecolor = colors[0];
    } else {
      needlecolor = colorGradient.getColor(colorval);
    }
  } else {
    needlecolor = colors[0];
  }
  // fontSize edited
  const fontSize1 = String(width / 20 < 14 ? width / 12 : 30);
  const fontSizeCDL = String(width / 20 < 14 ? width / 20 : 28);
  const fontSize = String(width / 20 < 14 ? width / 20 : 20);

  // RENDERING OF THE GAUGES START
  if (gaugeType2 === 'first') {
    if (frame.colIndex === 1) {
      if (isNaN(height)) {
        return <div>Height is Not defined</div>;
      } else if (height < 130) {
        return (
          <div
            style={{
              //position: 'relative',
              width: width,
              height: height,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',

              // overflow: 'auto',
              // overflowY: 'hidden'
              // zIndex: 'auto',
            }}
            className="container active"
          >
            <span
              style={{
                fontSize: fontSize1 + 'px',
                textAlign: 'center',
                color: needlecolor,
                fontWeight: 'bold',
              }}
            >
              {(frame.display.prefix || '') + frame.display.text + (frame.display.suffix || '')}
            </span>{' '}
            <span style={{ fontSize: fontSize + 'px', textAlign: 'center' }}>{frame.display.title}</span>
          </div>
        );
      } else {
        return (
          <div
            style={{
              //position: 'relative',
              width: width,
              height: height,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              // overflow: 'auto',
              // overflowY: 'hidden'
              // zIndex: 'auto',
            }}
            className="container active"
          >
            <Gauger
              id={uuidv4()}
              svgid={uuidv4()}
              height={height}
              width={width}
              colors={colors}
              fontSize={fontSize}
              needleColor={needlecolor}
              nrOfLevels={nrOfLevels}
              percent={percent}
              defaults={defaults}
              gaugeThickness={gaugeThickness}
              rotation={rotation}
              GaugeArc={GaugeArc}
              count={count}
              frame={frame}
              fontSize1={fontSize1}
              config={config}
            />
          </div>
        );
      }
    } else {
      return (
        <div
          style={{
            // position: 'relative',
            width: width,
            height: height,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',

            // overflow: 'auto',
            // overflowY: 'hidden'
            // zIndex: 'auto',
          }}
          className="container active"
        >
          <div>{frame.display.text}</div>
        </div>
      );
    }
  } else if (gaugeType2 === 'second') {
    //coding for the liquid gauge start
    if (isNaN(height)) {
      return <div>Height is Not defined</div>;
    } else if (height < 100) {
      return (
        <div
          style={{
            // position: 'relative',
            width: width,
            height: height,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',

            // overflow: 'auto',
            // overflowY: 'hidden'
            // zIndex: 'auto',
          }}
          className="container active"
        >
          <span
            style={{
              fontSize: fontSizeCDL + 'px',
              textAlign: 'center',
              color: needlecolor,
              fontWeight: 'bold',
            }}
          >
            {(frame.display.prefix || '') + frame.display.text + (frame.display.suffix || '')}
          </span>{' '}
          <span style={{ fontSize: fontSize + 'px', textAlign: 'center' }}>{frame.display.title}</span>
        </div>
      );
    } else {
      return (
        <div
          style={{
            width: width,
            height: height,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
          className="container active"
        >
          <LiquidGauge
            valueField={percent}
            //@ts-ignore
            customColor={frame.display.color}
            customHeight={height}
            customWidth={width}
            showNum={showNum2}
            showName={showName2}
            fontSize={fontSize}
            fontSizeCDL={fontSizeCDL}
            frame={frame}
            // customStyle={chartStyle}
          />
        </div>
      );
    }
  } else if (gaugeType2 === 'third') {
    // code for the canvas gauge
    if (isNaN(height)) {
      return <div>Height is Not defined</div>;
    } else if (height < 100) {
      return (
        <div
          style={{
            // position: 'relative',
            width: width,
            height: height,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',

            // overflow: 'auto',
            // overflowY: 'hidden'
            // zIndex: 'auto',
          }}
          className="container active"
        >
          <span
            style={{
              fontSize: fontSizeCDL + 'px',
              textAlign: 'center',
              color: needlecolor,
              fontWeight: 'bold',
            }}
          >
            {(frame.display.prefix || '') + frame.display.text + (frame.display.suffix || '')}
          </span>{' '}
          <span style={{ fontSize: fontSize + 'px', textAlign: 'center' }}>{frame.display.title}</span>
        </div>
      );
    } else {
      return (
        <div
          style={{
            // position: 'relative',
            width: width,
            height: height,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',

            // overflow: 'auto',
            // overflowY: 'hidden'
            // zIndex: 'auto',
          }}
        >
          <CanvasGauge
            height={height}
            width={width}
            valueField={percent}
            //@ts-ignore
            customColor={frame.display.color}
            showNum={showNum2}
            showName={showName2}
            fontSize={fontSize}
            fontSizeCDL={fontSizeCDL}
            frame={frame}
          />
        </div>
      );
    }
  } else {
    if (isNaN(height)) {
      return <div>Height is Not defined</div>;
    } else if (height < 100) {
      return (
        <div
          style={{
            // position: 'relative',
            width: width,
            height: height,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',

            // overflow: 'auto',
            // overflowY: 'hidden'
            // zIndex: 'auto',
          }}
          className="container active"
        >
          <span
            style={{
              fontSize: fontSizeCDL + 'px',
              textAlign: 'center',
              color: needlecolor,
              fontWeight: 'bold',
            }}
          >
            {(frame.display.prefix || '') + frame.display.text + (frame.display.suffix || '')}
          </span>{' '}
          <span style={{ fontSize: fontSize + 'px', textAlign: 'center' }}>{frame.display.title}</span>
        </div>
      );
    } else {
      return (
        <div
          style={{
            // position: 'relative',
            width: width,
            height: height,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',

            // overflow: 'auto',
            // overflowY: 'hidden'
            // zIndex: 'auto',
          }}
        >
          <DGauge
            height={height}
            width={width}
            valueField={percent}
            //@ts-ignore
            customColor={frame.display.color}
            showNum={showNum2}
            showName={showName2}
            fontSize={fontSize}
            fontSizeCDL={fontSizeCDL}
            frame={frame}
          />
        </div>
      );
    }
  }
};
