import { GrafanaTheme } from '@grafana/data';
import { TabContent, useStyles } from '@grafana/ui';
import { css } from 'emotion';
import { FeatureLike } from 'ol/Feature';
import React, { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TooltipContent } from './TooltipContent';

/**
 * Props for the TooltipTabs component.
 *
 * @typedef {Object} TooltipTabsProps
 * @property {FeatureLike[][]} featureLayer - An array of feature data for the tooltip.
 * @property {boolean} [isOpenLayer] - A boolean indicating if the layer is open.
 * @property {boolean} [isOpen] - A boolean indicating if the tooltip is open.
 * @property {function} [handleDashboardVisit] - A function to handle visiting a dashboard link.
 */
interface MapToolTipTabsProps {
  featureLayer: FeatureLike[][];
  isOpenLayer?: boolean;
  isOpen?: boolean;
  handleDashboardVisit?: (text: string, custom?: string) => void;
}

/**
 * TooltipTabs is a functional component that displays tabs for tooltip content.
 *
 * @param {TooltipTabsProps} props - The component's props.
 * @returns {JSX.Element} The rendered tooltip tabs component.
 */
export const TooltipTabs: FC<MapToolTipTabsProps> = (props) => {
  const { featureLayer, handleDashboardVisit, isOpen } = props;
  const styles = useStyles(getStyles);

  return (
    <div key={'tooltip content'}>
      <TabContent className={styles.tabContent}>
        {featureLayer &&
          featureLayer.map((arr, index) => {
            return (
              <div key={`prop-${index}${uuidv4()}`}>
                {arr && arr.length > 0 && (
                  <div>
                    <span>{arr[0].get('layerName')}</span>
                    <TooltipContent jsonDataObj={arr} isOpen={isOpen} handleDashboardVisit={handleDashboardVisit} />
                  </div>
                )}
              </div>
            );
          })}
      </TabContent>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme) => {
  return {
    tabContent: css`
      background: ${theme.colors.panelBg};
      height: 100%;
    `,
    tabBarContent: css`
      font-weight: ${theme.typography.weight.semibold};
      font-size: ${theme.typography.size.md};
    `,
  };
};
