import { PanelData } from '@grafana/data';
import React, { Component } from 'react';
import { SliderWrapper } from './SliderWrapper';
import { TimelapseProps } from '../custom/TimelapseEditor';

/**
 * Interface representing properties for the MainSlider component.
 *
 * @interface MainSliderProps
 * @property {number} intervalMs - The interval in milliseconds for the slider.
 * @property {number} minTime - The minimum time value for the slider.
 * @property {number} maxTime - The maximum time value for the slider.
 * @property {function} onDataChange - A callback function to handle data changes.
 * @property {boolean} isShowInfo - A boolean indicating whether adjust height or not.
 * @property {number} windowWidth - The width of the slider window.
 * @property {TimelapseProps} timeLapseOpts - Time-lapse properties for the slider.
 * @property {function} updateSliderTime - A callback function to update the slider's time.
 */
interface MainSliderProps {
  intervalMs: number;
  minTime: number;
  maxTime: number;
  onDataChange: (data?: PanelData, time?: number) => void;
  isShowInfo: boolean;
  windowWidth: number;
  timeLapseOpts: TimelapseProps;
  updateSliderTime: (time: number) => void;
}

/**
 * Interface representing the state of a component.
 *
 * @interface State
 * @property {boolean} isPlaying - A boolean indicating whether a component is in a playing state.
 * @property {number} slide - The current slide or step in the component.
 * @property {any | undefined | null} intervalId - An identifier for an interval, which may be undefined or null.
 */
interface State {
  isPlaying: boolean;
  slide: number;
  intervalId: any | undefined | null;
}

/**
 * A component that represents a main slider with time-lapse functionality.
 *
 * @class MainSlider
 * @extends {Component<MainSliderProps, State>}
 */
export class MainSlider extends Component<MainSliderProps, State> {
  constructor(props: MainSliderProps) {
    super(props);
    this.state = { isPlaying: false, slide: props.minTime, intervalId: null };
  }

  componentDidMount() {
    const { onDataChange, minTime } = this.props;
    onDataChange(undefined, minTime);
  }
  shouldComponentUpdate(nextProps: Readonly<MainSliderProps>, nextState: Readonly<State>, nextContext: any): boolean {
    return true;
  }

  componentDidUpdate(prevProps: Readonly<MainSliderProps>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevState.isPlaying !== this.state.isPlaying) {
      const { isPlaying } = this.state;
      if (!isPlaying) {
        this.clean();
      } else {
        this.play();
      }
    }
  }

  /**
   * Cleans up the interval timer if it's running.
   *
   * @private
   */
  private clean() {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
      this.setState({ ...this.state, intervalId: null, isPlaying: false });
    }
  }

  /**
   * Handles the start/stop button click for the time-lapse playback.
   */
  onStartLapse = () => {
    const { isPlaying, slide } = this.state;
    const { maxTime, minTime } = this.props;

    this.setState((prevState: State) => ({ ...prevState, isPlaying: !isPlaying }));

    // if slider reaches on max and user presses the play button
    if (slide === maxTime) {
      this.setState((prevState: State) => ({ ...prevState, slide: minTime }));
      this.props.updateSliderTime(minTime);
    }
    this.forceUpdate();
  };

  /**
   * Handles the slider value change.
   *
   * @param {any} evt - The slider event.
   */
  onSliderChange = (evt: any) => {
    const { onDataChange } = this.props;
    this.setState(() => {
      onDataChange(undefined, evt[0]);
      this.props.updateSliderTime(evt[0]);
      return { ...this.state, slide: evt[0] };
    });
  };

  /**
   * Starts or resumes the time-lapse playback.
   *
   * @private
   */
  private play() {
    let intervalId: any = null;
    const { intervalMs, onDataChange, maxTime, timeLapseOpts, minTime, updateSliderTime } = this.props;
    const { speed, timeToFinish } = timeLapseOpts;
    const step = intervalMs;
    if (step && this.state.isPlaying) {
      intervalId = setInterval(
        () => {
          const { slide } = this.state;
          const newTime = slide + step;
          if (newTime >= maxTime) {
            this.setState({ ...this.state, isPlaying: false });
            window.clearInterval(intervalId);
            intervalId = null;
            this.setState({ ...this.state, slide: maxTime });
            updateSliderTime(maxTime);
          }
          onDataChange(undefined, newTime);

          this.setState({ ...this.state, slide: newTime });
          updateSliderTime(newTime);
        },
        speed === 0 || speed === 1 || speed === -1
          ? (timeToFinish * 1000) / ((maxTime - minTime) / intervalMs)
          : speed > 1
          ? (timeToFinish * 1000) / (((maxTime - minTime) / intervalMs) * +speed)
          : (timeToFinish * 1000 * Math.abs(speed)) / ((maxTime - minTime) / intervalMs)
      );
    }
    this.setState({ ...this.state, intervalId: intervalId });
  }

  render() {
    const { minTime, maxTime, intervalMs, isShowInfo, windowWidth } = this.props;
    const { isPlaying, slide } = this.state;

    return (
      <SliderWrapper
        minTime={minTime}
        maxTime={maxTime}
        isPlaying={isPlaying}
        onPlaying={this.onStartLapse}
        onSliderChange={this.onSliderChange}
        intervalMs={intervalMs}
        currTime={slide}
        windowWidth={windowWidth}
        isShowInfo={isShowInfo}
      />
    );
  }
}
