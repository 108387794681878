import { MenuItemsGroup as GrafanaMenuItemProps } from '@grafana/ui';
import React from 'react';
import { LegacyContextMenu } from './LegacyContextMenu';

export interface MenuGroup {
  label: string;
  items: GrafanaMenuItemProps[];
}

interface Props {
  x: number;
  y: number;
  onClose: () => void;
  renderMenuItems: () => MenuGroup[];
  renderHeader: () => React.ReactNode;
}

/**
 * ContextMenu is a wrapper for the grafana/ui ContextMenu that falls back to
 * a legacy version on earlier versions of Carbon.
 */
export const ContextMenu = ({ x, y, onClose, renderMenuItems, renderHeader }: Props) => {
  return (
    <LegacyContextMenu x={x} y={y} onClose={onClose} renderMenuItems={renderMenuItems} renderHeader={renderHeader} />
  );
};
