import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const Org: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 16 16"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M8,7.4c-1.1,0-1.9,0.9-1.9,1.9v3.9C6.1,14.2,6.9,15,8,15s1.9-0.9,1.9-1.9V9.3C9.9,8.2,9.1,7.4,8,7.4z M8.8,13.1
        C8.8,13.6,8.5,14,8,14s-0.8-0.4-0.8-0.8V9.3c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8V13.1z M13,3.7c-1.1,0-1.9,0.9-1.9,1.9v7.5
        c0,1.1,0.9,1.9,1.9,1.9s1.9-0.9,1.9-1.9V5.6C14.9,4.6,14,3.7,13,3.7z M13.8,13.1c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8V5.6
        c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8V13.1z M3,1C2,1,1.1,1.8,1.1,2.9v10.3C1.1,14.2,2,15,3,15s1.9-0.9,1.9-1.9V2.9
        C4.9,1.8,4.1,1,3,1z M3.8,13.1C3.8,13.6,3.5,14,3,14s-0.8-0.4-0.8-0.8V2.9C2.2,2.4,2.6,2,3,2s0.8,0.4,0.8,0.8V13.1z"
      />
    </svg>
  );
};
