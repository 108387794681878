import React, { FC } from 'react';
import { css } from 'emotion';
import { GrafanaTheme } from '@grafana/data';
import { useStyles } from '@grafana/ui';
import { FeatureLike } from 'ol/Feature';
import { omit } from 'lodash';

/**
 * Props for the TooltipContent component.
 *
 * @typedef {Object} MapTooltipContentProps
 * @property {FeatureLike[]} jsonDataObj - An array of feature objects containing data to display in the tooltip.
 * @property {Function} [handleDashboardVisit] - A function to handle visiting a dashboard.
 * @property {boolean} [isOpen] - A boolean flag indicating if the tooltip is open.
 */
interface MapTooltipContentProps {
  jsonDataObj: FeatureLike[];
  handleDashboardVisit?: (text: string, custom?: string) => void;
  isOpen?: boolean;
}

/**
 * TooltipContent is a functional component that renders tooltip content based on JSON data.
 *
 * @param {MapTooltipContentProps} props - The component's props.
 * @returns {JSX.Element} The rendered tooltip content.
 */
export const TooltipContent: FC<MapTooltipContentProps> = (props) => {
  const { jsonDataObj, handleDashboardVisit, isOpen } = props;
  const styles = useStyles(getStyles);
  const data = omit(jsonDataObj[0].getProperties(), 'geometry', 'layerName');
  if (isOpen && data.dashboardName && data.dashboardUrl) {
    handleDashboardVisit!(data.dashboardUrl, data.dashboardName);
  }

  return (
    <table key={`tab-div`} className={styles.infoWrap}>
      <tbody>
        {jsonDataObj
          ? jsonDataObj.map((feature, index) => {
              const properties = omit(feature.getProperties(), 'geometry', 'layerName', 'weight');
              if (typeof properties === 'object') {
                return Object.keys(properties).map((property, i) => {
                  return (
                    properties[property] && (
                      <tr key={`property - ${index} - ${i}`}>
                        <th>{property}</th>
                        <td>{properties[property]}</td>
                      </tr>
                    )
                  );
                });
              } else {
                return null;
              }
            })
          : null}
      </tbody>
    </table>
  );
};

const getStyles = (theme: GrafanaTheme) => {
  return {
    infoWrap: css`
      padding: 8px;
      width: -webkit-fill-available;
      th {
        font-weight: ${theme.typography.weight.semibold};
        padding: 2px 16px;
        font-size: ${theme.typography.size.md};
        line-height: 21px;
      }
      td {
        font-size: ${theme.typography.size.md};
        padding: 0 6px;
        text-align: end;
      }
    `,
  };
};
