import React, { useState, useEffect } from 'react';
// import RacingBarChart from "./RacingBarChart";
import { PanelProps, GrafanaTheme } from '@grafana/data';
import { withTheme } from '@grafana/ui';
import { ChartRaceOptions } from './types';
import RacingBarChart from './components/RacingBarChart';
import { getGraphSeriesModel } from './getGraphSeriesModel';
import useKeyframes from './components/useKeyframes';
// import useWindowSize from "./useWindowSize";
// import './style.css';

interface Props extends PanelProps<ChartRaceOptions> {
  theme: GrafanaTheme;
}
function ChartRacePanel(props: Props) {
  const [bigData, setBigData] = useState([]);
  const [relativeSpeed, setRelativeSpeed] = useState(+props.options.speed);
  // useEffect(() => {
  // }, [props.options]);
  useEffect(() => {
    setRelativeSpeed(+props.options.speed);
  }, [props.options.speed]);
  // const numOfBars = 12;
  const numOfSlice = 10;

  const chartMargin = {
    top: 30,
    right: 10,
    bottom: 30,
    left: 10,
  };
  const { width, height, theme } = props;

  // const { width: windowWidth } = useWindowSize();

  const chartWidth = props.width;
  const chartHeight = props.height;

  let keyframes: any;
  // if (props.options.useCsv && props.options.csvUrl !== '') {
  // const keyframes = useKeyframes(dataUrl, numOfSlice);
  keyframes = useKeyframes(props.options.csvUrl, numOfSlice);
  // }
  const { options, data } = props;
  const chartRef = React.useRef();
  useEffect(() => {
    const newData = getGraphSeriesModel(
      props.data.series,
      props.timeZone,
      props.options.series || {},
      props.options.graph,
      props.options.legend,
      props.fieldConfig
    );
    if (newData.length > 0) {
      const smallData: any = [];
      let innerData = [];
      let currentTime: any;
      const secondarySeries = [];
      let largeIndex = 0;
      for (let k = 0; k < newData.length; k++) {
        if (newData[k].data.length > newData[largeIndex].data.length) {
          largeIndex = k;
        }
        const values: any = [];
        const timestamps: any = [];
        newData[k].data.map((item) => {
          timestamps.push(item[0]);
          values.push(item[1]);
        });
        secondarySeries.push({
          name: newData[k].label,
          length: newData[k].data.length,
          fields: [
            { name: newData[k].label, value: values },
            { name: newData[k].label, value: timestamps },
          ],
        });
      }
      if (options.order) {
        if (options.cumulative) {
          for (let k = 0; k < secondarySeries.length; k++) {
            // @ts-ignore
            const value = secondarySeries[k].fields[0].value;
            let prevValue = 0;
            const newValues = value.map((item: number) => {
              const toReturn = item + prevValue;
              prevValue = toReturn;
              return toReturn;
            });
            // @ts-ignore
            secondarySeries[k].fields[0].value = newValues;
          }
        }
        if (options.firstMinus) {
          for (let k = 0; k < secondarySeries.length; k++) {
            // @ts-ignore
            const value = secondarySeries[k].fields[0].value;
            const first = value[0];
            const newValues = value.map((item: number) => {
              return item - first;
            });
            // @ts-ignore
            secondarySeries[k].fields[0].value = newValues;
          }
        }
      } else {
        if (options.firstMinus) {
          for (let k = 0; k < secondarySeries.length; k++) {
            // @ts-ignore
            const value = secondarySeries[k].fields[0].value;
            const first = value[0];
            const newValues = value.map((item: number) => {
              return item - first;
            });
            // @ts-ignore
            secondarySeries[k].fields[0].value = newValues;
          }
        }
        if (options.cumulative) {
          for (let k = 0; k < secondarySeries.length; k++) {
            // @ts-ignore
            const value = secondarySeries[k].fields[0].value;
            let prevValue = 0;
            const newValues = value.map((item: number) => {
              const toReturn = item + prevValue;
              prevValue = toReturn;
              return toReturn;
            });
            // @ts-ignore
            secondarySeries[k].fields[0].value = newValues;
          }
        }
      }

      const queryLength = secondarySeries[largeIndex].length;
      for (let j = 0; j < queryLength; j++) {
        // @ts-ignore
        currentTime = new Date(secondarySeries[largeIndex].fields[1].value[j]);
        for (let i = 0; i < secondarySeries.length; i++) {
          innerData.push({
            name: secondarySeries[i].name,
            // @ts-ignore
            value: Math.trunc(secondarySeries[i].fields[0].value[j]) || 0,
            date: currentTime,
            // @ts-ignore
            category: secondarySeries[i].name.split('.')[0],
          });
        }
        innerData.sort((a: any, b: any) => parseFloat(b.value) - parseFloat(a.value));
        smallData.push({
          date: currentTime,
          data: innerData,
        });
        innerData = [];
      }
      setBigData(smallData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, options.firstMinus, options.cumulative, options.order]);

  const handleReplay = ({ target }: any) => {
    //  if(chartRef.current){
    // @ts-ignore
    chartRef.current.replay();
    //  }
    props.onOptionsChange({ ...props.options });
  };

  const handleStart = ({ target }: any) => {
    //  if(chartRef.current){
    // @ts-ignore
    chartRef.current.start();
    //  }
    props.onOptionsChange({ ...props.options });
  };

  const handleStop = ({ target }: any) => {
    //  if(chartRef.current){
    // @ts-ignore
    chartRef.current.stop();
    //  }
    props.onOptionsChange({ ...props.options });
  };
  // @ts-ignore
  const playing = chartRef.current ? chartRef.current.playing : false;
  // @ts-ignore
  // const [ _, forceUpdate] = useState(Boolean);
  const handleSpeedIncrease = ({ target }: any) => {
    setRelativeSpeed(+relativeSpeed - 100);
  };
  const handleSpeedDecrease = ({ target }: any) => {
    setRelativeSpeed(+relativeSpeed + 100);
  };
  const textColor = theme.isLight ? '#333' : '#f4f4f4';
  // @ts-ignore
  const [_, forceUpdate] = useState(true);
  return (
    <div
      style={{ position: 'relative', width, height, paddingLeft: '10px' }}
      className={theme.isLight ? 'light-race' : 'dark-race'}
    >
      <div>
        <div style={{ float: 'right', marginRight: '1rem', marginBottom: '1rem' }}>
          <span style={{ marginRight: '0.3rem', fontWeight: 600 }}>Speed: </span>
          <button className="speed-controller" onClick={handleSpeedIncrease} style={{ padding: '0.1rem 0.3rem' }}>
            <i className="fa fa-arrow-circle-up" aria-hidden="true"></i>
          </button>
          <button
            className="speed-controller"
            onClick={handleSpeedDecrease}
            style={{ padding: '0.1rem 0.3rem', marginRight: '1rem' }}
          >
            <i className="fa fa-arrow-circle-down" aria-hidden="true"></i>
          </button>
          <span style={{ marginRight: '0.3rem', fontWeight: 600 }}>PlayBack: </span>
          <button onClick={playing ? handleStop : handleStart}>{playing ? 'Pause' : 'Start'}</button>
          <button onClick={handleReplay}>Replay</button>
        </div>
        <div>
          {!props.options.useCsv
            ? bigData.length > 0 && (
                <RacingBarChart
                  // keyframes={keyframes}
                  keyframes={bigData}
                  numOfBars={+props.options.runningQueries}
                  width={chartWidth - 30}
                  height={chartHeight - 10}
                  margin={chartMargin}
                  onStart={() => forceUpdate(true)}
                  onStop={() => forceUpdate(false)}
                  ref={chartRef}
                  // ref={(element: any) => (setChartRef(element))}
                  legendBottom={props.options.legendBottom}
                  pallet={props.options.pallet}
                  textColor={textColor}
                  legendSize={props.options.legendSize}
                  // speed={props.options.speed}
                  speed={relativeSpeed}
                />
              )
            : props.options.csvUrl !== '' &&
              keyframes.length > 0 && (
                <RacingBarChart
                  keyframes={keyframes}
                  // keyframes={smallData}
                  numOfBars={+props.options.runningQueries}
                  width={chartWidth - 30}
                  height={chartHeight - 10}
                  margin={chartMargin}
                  onStart={() => forceUpdate(true)}
                  onStop={() => forceUpdate(false)}
                  ref={chartRef}
                  // ref={(element: any) => (setChartRef(element))}
                  legendBottom={props.options.legendBottom}
                  pallet={props.options.pallet}
                  textColor={textColor}
                  legendSize={props.options.legendSize}
                  // speed={props.options.speed}
                  speed={relativeSpeed}
                />
              )}
        </div>
      </div>
    </div>
  );
}

export default withTheme(ChartRacePanel);
// export default App;
