import { Field } from '@grafana/ui';
import React from 'react';
import { AddMarker } from './CustomMarkers/AddMarker';
import { PropsCustom } from './CustomHeatmap';
import { MarkerStyle } from './MarkerStyle';

/**
 * Renders custom markers for a given map layer.
 *
 * @param {PropsCustom} props - The custom marker component props.
 * @returns {JSX.Element} - The custom marker component.
 */
export function CustomMarker({ layer, map, replaceVariables, options, data }: PropsCustom) {
  return (
    <>
      <MarkerStyle layer={layer} />
      <Field label="Custom Markers">
        <AddMarker map={map} layer={layer} replaceVariables={replaceVariables} options={options} data={data} />
      </Field>
    </>
  );
}
