import { DataFrame, FieldDisplay } from '@grafana/data';
import { WaterfallData } from './interface';

/**
 * calculate candle stick data
 * @function
 * @param {Array<DataFrame>} mainData
 * @param {number} timeRange
 * @param {Array<FieldDidplay>} getData
 * @param {boolean} total
 */

/**
 * calculateWaterfallDataQuery function is used to calculate the data that should be inserted
 * in all the four arrays that are used as to draw plot only when waterfall chart is selected with
 * time to draw.
 */
export const calculateWaterfallData = (
  mainData: DataFrame[],
  timeRange: number,
  getData: FieldDisplay[],
  total: boolean
) => {
  const waterfallData: WaterfallData = {};
  // let timeArray: any[] = [];
  let openArray: any[] = [];
  let highArray: any[] = [];
  let lowArray: any[] = [];
  let closeArray: any[] = [];
  let indexArray: number[] = [];
  let valueArray: [] = [];
  let colorArray: string[] = [];
  let nameArray: any[] = [];
  // let timeMs = clusterSwitch ? customCluster * 60 * 1e3 : toCluster * 60 * 1e3;
  const timeObj: any = mainData[0].fields[0].values;
  const valueObj: any = mainData[0].fields[1].values;
  let fulltimeArray: number[] = timeObj.buffer;

  //* data series added into valueArray
  valueArray = valueObj.buffer;

  for (let j = 0; j < valueArray.length; j++) {
    if (valueArray[j] == null) {
      //@ts-ignore
      valueArray[j] = 0;
    }
  }

  let baseValue: any = 0;
  // let k = 'high';
  if (valueArray.length > 0) {
    //@ts-ignore
    baseValue = valueArray[0];
  }

  for (let i = 0; i < valueArray.length; i++) {
    if (i === 0) {
      lowArray.push(0);
      closeArray.push(0);
      openArray.push(baseValue);
      highArray.push(baseValue);
    } else {
      highArray.push(valueArray[i] + highArray[i - 1]);
      openArray.push(valueArray[i] + openArray[i - 1]);
      lowArray.push(highArray[i - 1]);
      closeArray.push(openArray[i - 1]);
    }
  }

  /**
   * to plot the bar for total value, we adding values in all the array used to draw plot.
   */
  if (total) {
    lowArray[lowArray.length - 1] = lowArray[0];
    closeArray[closeArray.length - 1] = closeArray[0];
    openArray[openArray.length - 1] = openArray[openArray.length - 2];
    highArray[highArray.length - 1] = highArray[highArray.length - 2];
    let riseOrFallForTimeSeriesTotal = 0;
    //@ts-ignore
    valueArray[valueArray.length - 1] = riseOrFallForTimeSeriesTotal;
  }

  waterfallData.time = {
    name: 'time',
    values: fulltimeArray,
  };
  waterfallData.open = {
    name: 'open',
    values: openArray,
  };
  waterfallData.close = {
    name: 'close',
    values: closeArray,
  };
  waterfallData.high = {
    name: 'high',
    values: highArray,
  };
  waterfallData.low = {
    name: 'low',
    values: lowArray,
  };
  for (let index = 0; index < getData.length; index++) {
    const ele: any = getData[index];
    colorArray.push(ele.display.color);
    nameArray.push(ele.display.title);
  }

  waterfallData.color = colorArray;
  waterfallData.name = nameArray;
  waterfallData.indexArray = indexArray;
  waterfallData.valueArray = valueArray;
  return waterfallData;
};
