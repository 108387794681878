import { useEffect, useContext, useCallback } from 'react';
import { MapPanelOptions } from '../../../types/types';
import tinycolor from 'tinycolor2';
import darkTheme from '@grafana/ui/src/themes/dark';
import { getColorForTheme } from '@grafana/data';
import { TooltipContext, TooltipDataContext } from '../../../context';

/**
 * @typedef {Object} GeoJsonProps
 * @property {MapPanelOptions} options
 * @property {google.maps.Map | null} map
 */
interface GeoJsonProps {
  options: MapPanelOptions;
  map: google.maps.Map | null;
}

export function GeoJson(props: GeoJsonProps): null {
  const { showInfo, setShowInfo } = useContext(TooltipContext);
  const { setTooltipData } = useContext(TooltipDataContext);
  const { map, options } = props;

  const { geojson } = options;
  const {
    path,
    pathExt,
    isGeoJsonLayer,
    useGooglePin,
    iconSize,
    iconSymbol,
    opacityIcon,
    fillOpacity,
    fillcolor,
    fillcoloricon,
    strokeOpacity,
    strokeWeight,
    strokecolor,
    strokecoloricon,
  } = geojson;
  // * sending mouseOver, mouseOut and mouseClick(with data) event to tooltip
  const onMouseOver = useCallback(
    (data: any, name?: string) => {
      const newData = data?.feature.Gg;
      setTooltipData({
        lat: data.latLng.lat(),
        lng: data.latLng.lng(),
        data: newData,
        layer: 'JSON Layer',
        tooltipType: 'json',
        locField: name || '',
      });
      setShowInfo({ ...showInfo, onHover: true });
    },
    [showInfo]
  );

  const onMouseOut = useCallback(() => {
    if (!showInfo.onClick) {
      setShowInfo({ ...showInfo, onHover: false });
    }
  }, [showInfo]);

  const onMouseClick = (event: any) => {
    if (!showInfo.onClick) {
      setShowInfo({ ...showInfo, onClick: true, onHover: false });
    }
  };

  useEffect(() => {
    map?.data.addListener('mouseover', onMouseOver);
    map?.data.addListener('mouseout', onMouseOut);
    map?.data.addListener('click', onMouseClick);

    const outsideStyle = {
      fillColor: tinycolor(getColorForTheme(fillcolor, darkTheme)).toString(),
      strokeWeight: strokeWeight,
      fillOpacity: fillOpacity,
      strokeOpacity: strokeOpacity,
      strokeColor: tinycolor(getColorForTheme(strokecolor, darkTheme)).toString(),
      icon: undefined,
    };
    const pinMarker = {
      path: iconSymbol,
      strokeColor: tinycolor(getColorForTheme(strokecoloricon, darkTheme)).toString(),
      strokeWeight: strokeWeight,
      fillColor: tinycolor(getColorForTheme(fillcoloricon, darkTheme)).toString(),
      fillOpacity: opacityIcon,
      scale: iconSize,
    };
    map?.data.setStyle({ ...outsideStyle });

    if (!useGooglePin) {
      map?.data.setStyle({
        ...outsideStyle,
        icon: pinMarker,
      });
    }
  }, [
    isGeoJsonLayer,
    useGooglePin,
    iconSize,
    iconSymbol,
    opacityIcon,
    fillOpacity,
    fillcolor,
    fillcoloricon,
    strokeOpacity,
    strokeWeight,
    strokecolor,
    strokecoloricon,
  ]);

  useEffect(() => {
    if (isGeoJsonLayer) {
      props.map?.data.loadGeoJson(pathExt || path);
    }

    return () => {
      props.map?.data.forEach((feature: any) => {
        props.map?.data.remove(feature);
      });
    };
  }, [path, pathExt, isGeoJsonLayer]);

  return null;
}
