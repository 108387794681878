import { DataFrame, FieldDisplay } from '@grafana/data';
import { CandleData } from './interface';

/**
 * calculate candle stick data
 * @function
 * @param {Array<DataFrame>} mainData
 * @param {number} toCluster
 * @param {number} timeRange
 * @param {Array<FieldDidplay>} getData
 * @param {boolean} clusterSwitch
 * @param {number} customCluster
 * @returns {CandleData} candle data
 */
export const calculateCandleData = (
  mainData: DataFrame[],
  toCluster: number,
  timeRange: number,
  getData: FieldDisplay[],
  customCluster: number
) => {
  const candleData: CandleData = {};
  let timeArray: number[] = [];
  let openArray: number[] = [];
  let highArray: number[] = [];
  let lowArray: number[] = [];
  let closeArray: number[] = [];
  let indexArray: number[] = [];
  let valueArray: [] = [];
  let colorArray: string[] = [];
  let nameArray: any[] = [];
  let timeMs = toCluster === 0 ? customCluster * 60 * 1e3 : toCluster * 60 * 1e3;
  const timeObj: any = mainData[0].fields[0].values;
  const valueObj: any = mainData[0].fields[1].values;
  let fulltimeArray: number[] = timeObj.buffer;
  let initialTime: number = timeObj.buffer[0];

  let interval = Math.round(timeRange / timeMs);
  const intervalTime = fulltimeArray[1] - fulltimeArray[0];

  for (let idx = 1; idx <= interval; idx++) {
    let newtime = initialTime + timeMs * idx;
    const found = fulltimeArray.find((ele: number) => {
      return ele >= newtime;
    });
    if (found !== undefined) {
      if (found === newtime) {
        timeArray.push(found);
      } else {
        timeArray.push(found - intervalTime);
      }
    }
  }

  for (let i = 0; i < timeArray.length; i++) {
    let index = fulltimeArray.findIndex((ele: number) => {
      return ele === timeArray[i];
    });
    indexArray.push(index);
  }
  valueArray = valueObj.buffer;
  for (let i = 0; i < indexArray.length; i++) {
    let newArray: number[] = valueArray.slice(indexArray[i - 1], indexArray[i]);
    for (let i = 0; i < newArray.length; i++) {
      if (newArray[i] == null) {
        newArray[i] = 0;
      }
    }
    let low: number = getMin(newArray, newArray.length);
    let high: number = getMax(newArray, newArray.length);

    lowArray.push(low);
    highArray.push(high);
    openArray.push(newArray[0]);
    closeArray.push(newArray[newArray.length - 1]);
  }
  candleData.time = {
    name: 'time',
    values: timeArray,
  };
  candleData.open = {
    name: 'open',
    values: openArray,
  };
  candleData.close = {
    name: 'close',
    values: closeArray,
  };
  candleData.high = {
    name: 'high',
    values: highArray,
  };
  candleData.low = {
    name: 'low',
    values: lowArray,
  };
  for (let index = 0; index < getData.length; index++) {
    const ele: any = getData[index];
    colorArray.push(ele.display.color);
    nameArray.push(ele.display.title);
  }

  candleData.color = colorArray;
  candleData.name = nameArray;
  candleData.indexArray = indexArray;
  return candleData;
};
/**
 * calculate min of the array
 * @param {Array<number>} arr
 * @param {number} n
 * @returns {number} minimum value from array
 */
export function getMin(arr: number[], n: number) {
  let res = arr[0];
  for (let i = 1; i < n; i++) {
    res = Math.min(res, arr[i]);
  }
  return res;
}

/**
 * calculate max of the array
 * @param {Array<number>} arr
 * @param {number} n
 * @returns {number} maximum value from array
 */
export function getMax(arr: number[], n: number) {
  let res = arr[0];
  for (let i = 1; i < n; i++) {
    res = Math.max(res, arr[i]);
  }
  return res;
}
