import React, { useLayoutEffect, useState, useCallback, useRef } from 'react';

import { usePlotContext } from '@grafana/ui';
import { CartesianCoords2D, DataFrame, TimeZone } from '@grafana/data';
import { AnnotationEditor } from './plugins/annotations/AnnotationEditor';
import { useMountedState } from 'react-use';
export interface PlotSelection {
  min: number;
  max: number;

  // selection bounding box, relative to canvas
  bbox: {
    top: number;
    left: number;
    width: number;
    height: number;
  };
}
// import { AnnotationEditor } from './annotations/AnnotationEditor';

type StartAnnotatingFn = (props: {
  // pixel coordinates of the clicked point on the uPlot canvas
  coords: { viewport: CartesianCoords2D; plotCanvas: CartesianCoords2D } | null;
}) => void;

interface AnnotationEditorPluginProps {
  data: DataFrame;
  timeZone: TimeZone;
  children?: (props: { startAnnotating: StartAnnotatingFn }) => React.ReactNode;
  panelID: number;
}

/**
 * @alpha
 */
export const AnnotationEditorPlugin: React.FC<AnnotationEditorPluginProps> = ({
  data,
  timeZone,
  children,
  panelID,
}) => {
  const plotCtx = usePlotContext();
  const [isAddingAnnotation, setIsAddingAnnotation] = useState(false);
  const [selection, setSelection] = useState<PlotSelection | null>(null);
  const [bbox, setBbox] = useState<DOMRect>();
  const isMounted = useMountedState();
  const plotInstance = useRef<uPlot>();

  const clearSelection = useCallback(() => {
    setSelection(null);
    const plotInstance = plotCtx.getPlotInstance();
    if (plotInstance) {
      plotInstance.setSelect({ top: 0, left: 0, width: 0, height: 0 });
    }
    setIsAddingAnnotation(false);
  }, [setSelection, , setIsAddingAnnotation, plotCtx]);

  useLayoutEffect(() => {
    let annotating = false;

    plotCtx.registerPlugin({
      id: 'AnnotationsPluginSetStateInit',
      hooks: {
        // Render annotation lines on the canvas
        init: (u: uPlot) => {
          plotInstance.current = u;
          // Wrap all setSelect hooks to prevent them from firing if user is annotating
          const setSelectHooks = u.hooks['setSelect'];
          if (setSelectHooks) {
            for (let i = 0; i < setSelectHooks.length; i++) {
              const hook = setSelectHooks[i];
              if (hook === setSelect) {
                continue;
              }

              setSelectHooks[i] = (...args) => {
                if (!annotating) {
                  hook!(...args);
                }
              };
            }
          }
        },
      },
    });

    const setSelect = (u: uPlot) => {
      if (annotating) {
        setIsAddingAnnotation(true);
        const min = u.posToVal(u.select.left, 'x');
        const max = u.posToVal(u.select.left + u.select.width, 'x');

        setSelection({
          min,
          max,
          bbox: {
            left: u.select.left,
            top: 0,
            height: u.bbox.height / window.devicePixelRatio,
            width: u.select.width,
          },
        });
        annotating = false;
      }
    };

    plotCtx.registerPlugin({
      id: 'AnnotationsPluginSetSelect',
      hooks: {
        setSelect: setSelect,
      },
    });

    plotCtx.registerPlugin({
      id: 'AnnotationsPluginSyncRect',
      hooks: {
        syncRect: (u: uPlot, rect: any) => {
          if (!isMounted()) {
            return;
          }
          setBbox(rect);
        },
      },
    });

    //   config.setCursor({
    //     bind: {
    //       mousedown: (u, targ, handler) => (e) => {
    //         annotating = e.button === 0 && (e.metaKey || e.ctrlKey);
    //         handler(e);
    //         return null;
    //       },
    //       mouseup: (u, targ, handler) => (e) => {
    //         // uPlot will not fire setSelect hooks for 0-width && 0-height selections
    //         // so we force it to fire on single-point clicks by mutating left & height
    //         if (annotating && u.select.width === 0) {
    //           u.select.left = u.cursor.left!;
    //           u.select.height = u.bbox.height / window.devicePixelRatio;
    //         }
    //         handler(e);
    //         return null;
    //       },
    //     },
    //   });
  }, [setBbox, isMounted]);

  const startAnnotating = useCallback<StartAnnotatingFn>(
    ({ coords }) => {
      if (!plotInstance.current || !bbox || !coords) {
        return;
      }

      const min = plotInstance.current?.posToVal(coords.plotCanvas.x, 'x');

      if (!min) {
        return;
      }

      setSelection({
        min,
        max: min,
        bbox: {
          left: coords.plotCanvas.x,
          top: 0,
          height: bbox.height,
          width: 0,
        },
      });
      setIsAddingAnnotation(true);
    },
    [bbox]
  );

  return (
    <>
      {isAddingAnnotation && selection && bbox && (
        <AnnotationEditor
          selection={selection}
          onDismiss={clearSelection}
          onSave={clearSelection}
          data={data}
          timeZone={timeZone}
          panelID={panelID}
          style={{
            position: 'absolute',
            top: `${bbox.top}px`,
            left: `${bbox.left}px`,
            width: `${bbox.width}px`,
            height: `${bbox.height}px`,
          }}
        />
      )}
      {children ? children({ startAnnotating }) : null}
    </>
  );
};
