import { PanelModel } from '@grafana/data';
import { sharedSingleStatPanelChangedHandler } from '@grafana/ui';
import { TimelineOptions } from './types';

// This is called when the panel changes from another panel
export const StateHandler = (
  panel: PanelModel<Partial<TimelineOptions>> | any,
  prevPluginId: string,
  prevOptions: any
) => {
  // This handles most config changes
  const options = sharedSingleStatPanelChangedHandler(panel, prevPluginId, prevOptions) as TimelineOptions;
  return options;
};
