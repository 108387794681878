import React from 'react';
import { MarkerClusterer } from '@react-google-maps/api';
import { DisplayValue } from '@grafana/data';
import Geohash from 'latlon-geohash';
import { MarkerComponent } from './MarkerComponent';
import { MarkerLayerI, CompleteMarker } from '../../interface';

/**
 * @function
 * @param {MarkerLayerI} props
 * @returns {JSX.Element}
 */

export function MarkerLayer(props: MarkerLayerI) {
  const { options, dataFromAutoQuery, dataFromGeohashQuery, dataFromCustomMarker, handleDashboardVisit } = props;
  const { markerSett, querySetting, isQueryForHeat } = options;

  return (
    <>
      {/* geohash markers */}
      {!isQueryForHeat && querySetting === 'geohash' && dataFromGeohashQuery && (
        <MarkerClusterer>
          {(clusterer) => (
            <>
              {dataFromGeohashQuery.hashField.map((hash: DisplayValue, index: number) => {
                const latlon = Geohash.decode(hash.text);
                const size =
                  (markerSett.maxMarkerSize - markerSett.minMarkerSize) *
                    (dataFromGeohashQuery.valueField[index].percent || 0) +
                  markerSett.minMarkerSize;
                const value = `${dataFromGeohashQuery.valueField[index].prefix || ''} ${
                  dataFromGeohashQuery.valueField[index].text
                } ${dataFromGeohashQuery.valueField[index].suffix || ''}`;
                const name = dataFromGeohashQuery.locationField[index].text;
                return (
                  <MarkerComponent
                    key={`geohash-marker-${index}`}
                    index={index}
                    options={options}
                    size={size}
                    markerQueryType={'geohash'}
                    name={name}
                    value={value}
                    lat={latlon.lat}
                    lng={latlon.lon}
                    clusterer={clusterer}
                    valueColor={dataFromGeohashQuery.valueField[index].color}
                    handleDashboardVisit={handleDashboardVisit}
                  />
                );
              })}
            </>
          )}
        </MarkerClusterer>
      )}
      {/* auto query markers */}
      {!isQueryForHeat && querySetting === 'auto' && dataFromAutoQuery && (
        <MarkerClusterer>
          {(clusterer) => (
            <>
              {dataFromAutoQuery.hashField.map((hash: DisplayValue, index: number) => {
                const latlon = Geohash.decode(hash.text);

                const size =
                  (markerSett.maxMarkerSize - markerSett.minMarkerSize) *
                    (dataFromAutoQuery.valueField[index].percent || 0) +
                  markerSett.minMarkerSize;
                const value = `${dataFromAutoQuery.valueField[index].prefix || ''} ${
                  dataFromAutoQuery.valueField[index].text
                } ${dataFromAutoQuery.valueField[index].suffix || ''}`;
                return (
                  <MarkerComponent
                    key={`auto-marker-${index}`}
                    index={index}
                    options={options}
                    size={size}
                    markerQueryType={'auto'}
                    value={value}
                    lat={latlon.lat}
                    lng={latlon.lon}
                    clusterer={clusterer}
                    valueColor={dataFromAutoQuery.valueField[index].color}
                  />
                );
              })}
            </>
          )}
        </MarkerClusterer>
      )}
      {/* custom markers */}
      {dataFromCustomMarker && (
        <MarkerClusterer>
          {(clusterer) => (
            <>
              {dataFromCustomMarker.map((markers: CompleteMarker[], index: number) => {
                const size =
                  (markerSett.maxMarkerSize - markerSett.minMarkerSize) * (markers[0].percent ?? 1) +
                  markerSett.minMarkerSize;
                return (
                  <MarkerComponent
                    key={`custom-marker-${index}`}
                    index={index}
                    options={options}
                    size={size}
                    markerQueryType={'custom'}
                    value={markers}
                    lat={markers[0].lat}
                    lng={markers[0].lng}
                    clusterer={clusterer}
                    valueColor={markers[0].color}
                    handleDashboardVisit={handleDashboardVisit}
                  />
                );
              })}
            </>
          )}
        </MarkerClusterer>
      )}
    </>
  );
}
