import React from 'react';
import { BoxOptions, getConfig } from './box';
import { GraphNGLegendEventMode, defaultGraphConfig, BoxPlotData } from '../types';
import {
  DataFrame,
  formattedValueToString,
  getFieldColorModeForField,
  getFieldDisplayName,
  GrafanaTheme,
} from '@grafana/data';
import {
  AxisPlacement,
  ScaleDirection,
  ScaleOrientation,
  ScaleDistribution,
  UPlotConfigBuilder,
  GraphFieldConfig,
} from '@grafana/ui';
import { BoxPlotProps } from './GraphNG';
import { getMin, getMax } from '../calculation/CandleDataCalc';
import uPlot, { Scale } from 'uplot';
interface ScaleProps {
  scaleKey: string;
  isTime?: boolean;
  min?: number | null;
  max?: number | null;
  softMin?: number | null;
  softMax?: number | null;
  range?: Scale.Range;
  distribution?: ScaleDistribution;
  orientation: ScaleOrientation;
  direction: ScaleDirection;
  log?: number;
}
export function mapMouseEventToMode(event: React.MouseEvent): GraphNGLegendEventMode {
  if (event.ctrlKey || event.metaKey || event.shiftKey) {
    return GraphNGLegendEventMode.AppendToSelection;
  }
  return GraphNGLegendEventMode.ToggleSelection;
}

export function preparePlotConfigBuilderBox(
  data: DataFrame,
  theme: GrafanaTheme,
  dataAll: BoxPlotData,
  rawValue: (seriesIdx: number, valueIdx: number) => number,
  { toShowPlot, timeZone, toCluster, customClusterSize, typeBoxPlot }: BoxPlotProps
) {
  const UPlot_UNIT = '__fixed';
  const builder = new UPlotConfigBuilder();
  // bar orientation -> x scale orientation & direction
  let xOri = ScaleOrientation.Vertical;
  let xDir = ScaleDirection.Down;
  const formatValue = (seriesIdx: number, value: any) => formattedValueToString(data.fields[seriesIdx].display!(value));

  const opts: BoxOptions = {
    xOri,
    xDir,
    formatValue,
    rawValue,
    data,
    dataAll,
    toCluster,
    customClusterSize,
  };

  // y-range setting
  const minAll = getMin(dataAll.box?.min?.values!, dataAll.box?.min?.values.length!);
  const maxAll = getMax(dataAll.box?.max?.values!, dataAll.box?.max?.values.length!);
  const maxAllBoxTime = getMax(dataAll.boxTime?.max?.values!, dataAll.boxTime?.max?.values.length!);
  const minAllBoxTime = getMin(dataAll.boxTime?.min?.values!, dataAll.boxTime?.min?.values.length!);

  /**
   * setting up y axis range
   */
  let mainRange: any | undefined = undefined;

  if (typeBoxPlot === 'normal') {
    let minAll1 = minAll < 0 ? minAll + minAll * 0.5 : minAll - minAll * 0.5;
    let maxAll2 = maxAll < 0 ? maxAll - maxAll * 0.5 : maxAll + maxAll * 0.5;
    mainRange = [minAll1, maxAll2];
  } else {
    let minAllBoxTime1 = minAllBoxTime < 0 ? minAllBoxTime + minAllBoxTime * 0.5 : minAllBoxTime - minAllBoxTime * 0.5;
    let maxAllBoxTime1 = maxAllBoxTime < 0 ? maxAllBoxTime - maxAllBoxTime * 0.5 : maxAllBoxTime + maxAllBoxTime * 0.5;
    mainRange = [minAllBoxTime1, maxAllBoxTime1];
  }

  const config = getConfig(opts, theme);
  builder.setCursor(config.cursor);
  builder.setSelect(config.select);
  builder.addHook('init', config.init);
  builder.addHook('drawClear', config.drawClear);
  builder.setShowTooltip(config.tooltipMaker);

  if (toShowPlot === 'boxplot' && typeBoxPlot === 'normal') {
    builder.addHook('draw', config.drawBoxes);
  }
  if (toShowPlot === 'boxplot' && typeBoxPlot === 'withtime') {
    builder.addHook('draw', config.drawTimeBox);
  }

  const incrNew = toCluster === 0 ? customClusterSize * 60 * 1e3 : toCluster * 60 * 1e3;
  builder.addScale({
    scaleKey: 'x',
    isTime: false,
    distribution: ScaleDistribution.Ordinal,
    orientation: ScaleOrientation.Horizontal,
    direction: ScaleDirection.Right,
    range:
      typeBoxPlot === 'normal'
        ? [
            dataAll.box?.queryIndex?.values[0]! - 1,
            dataAll.box?.queryIndex?.values[dataAll.box?.queryIndex?.values.length! - 1]! + 1,
          ]
        : [
            dataAll.boxTime?.time?.values[0]! - incrNew,
            dataAll.boxTime?.time?.values[dataAll.boxTime?.time?.values.length! - 1]! + incrNew,
          ],
  });
  /**
   * * splits and values accepts array of same length
   * * splits - divided axis into no. parts as the length of array
   * * values - values to show on axis
   */

  builder.addAxis({
    scaleKey: 'x',
    isTime: false,
    placement: AxisPlacement.Bottom,
    grid: false,
    splits: typeBoxPlot === 'normal' ? undefined : dataAll.boxTime?.time?.values,
    values: typeBoxPlot === 'normal' ? config.xValuesBox : config.xValuesBoxTime,
    ticks: false,
    timeZone,
    gap: 5,
    theme,
  });
  let seriesIndex = 0;

  // * iterate the y values
  for (let i = 1; i < data.fields.length; i++) {
    const field = data.fields[i];
    field.state!.seriesIndex = seriesIndex++;
    const customConfig: GraphFieldConfig = { ...defaultGraphConfig, ...field.config.custom };

    const scaleKey = field.config.unit || UPlot_UNIT;
    const colorMode = getFieldColorModeForField(field);
    // const scaleColor = getFieldSeriesColor(field, theme);
    // const seriesColor = scaleColor.color;
    builder.addSeries({
      scaleKey,
      pxAlign: false,
      lineWidth: 1,
      lineColor: undefined,
      // lineStyle: customConfig.lineStyle,
      fillOpacity: 0,
      theme,
      colorMode,
      // pathBuilder,
      // pointsBuilder,
      show: !customConfig.hideFrom?.graph,
      gradientMode: undefined,
      thresholds: field.config.thresholds,

      // The following properties are not used in the uPlot config, but are utilized as transport for legend config
      dataFrameFieldIndex: {
        fieldIndex: i,
        frameIndex: 0,
      },
      fieldName: getFieldDisplayName(field, data),
      hideInLegend: customConfig.hideFrom?.legend,
    });
    // The builder will manage unique scaleKeys and combine where appropriate
    builder.addScale({
      scaleKey,
      min: field.config.min,
      max: field.config.max,
      softMin: customConfig.axisSoftMin,
      softMax: customConfig.axisSoftMax,
      orientation: ScaleOrientation.Vertical,
      direction: ScaleDirection.Up,
      distribution: ScaleDistribution.Linear,
      range: mainRange,
    });

    builder.addAxis({
      scaleKey,
      label: customConfig.axisLabel,
      size: customConfig.axisWidth,
      placement: customConfig.axisPlacement ?? AxisPlacement.Left,
      formatValue: (v) => formattedValueToString(field.display!(v)),
      theme,
    });
  }

  return builder;
}

//* For scale update in boxplot.
export function setBoxplotMinMaxRange(min: number, max: number) {
  const RangeMinmax = uPlot.rangeNum(min, max, 0.1, true);
  const newrange: uPlot.Scale.Range = RangeMinmax;
  const newscale: ScaleProps = {
    min: min,
    max: max,
    scaleKey: '__fixed',
    range: newrange,
    orientation: 1,
    direction: 1,
    softMax: newrange[1],
    softMin: newrange[0],
  };
  return newscale;
}
