import React from 'react';
import { sharedSingleStatMigrationHandler } from '@grafana/ui';
import { PanelPlugin } from '@grafana/data';
import { StackedBarOptions, addStandardDataReduceOptions } from './types';
import { StackedBar } from './StackedBar';
import { statPanelChangedHandler } from './StatMigrations';
import { EditAxis } from './components/EditAxis';
import { EditBar } from './components/EditBar';

export const plugin = new PanelPlugin<StackedBarOptions>(StackedBar)
  .useFieldConfig()
  .setPanelOptions((builder) => {
    addStandardDataReduceOptions(builder);

    builder
      .addRadio({
        path: 'chartOrient',
        name: 'Chart Orientation',
        settings: {
          options: [
            { value: 'vertical', label: 'Vertical' },
            { value: 'horizontal', label: 'Horizontal' },
          ],
        },
        defaultValue: 'vertical',
      })
      .addRadio({
        path: 'legendOrientation',
        name: 'Legend Position',
        // description: 'Positions the legend on the chart',
        settings: {
          options: [
            { value: 'top', label: 'Top' },
            { value: 'bottom', label: 'Bottom' },
            { value: 'left', label: 'Left' },
            { value: 'right', label: 'Right' },
          ],
        },
        defaultValue: 'top',
      })
      .addRadio({
        path: 'horizontalAlign',
        name: 'Horizontal Align',
        description: 'Horizontal alignment of the legend',
        settings: {
          options: [
            { value: 'center', label: 'Center' },
            { value: 'left', label: 'Left' },
            { value: 'right', label: 'Right' },
          ],
        },
        defaultValue: 'center',
      })
      .addNumberInput({
        path: 'columns',
        name: 'Number of Stacks',
        description: 'Provide the number of stacks you want on the axis',
        defaultValue: 2,
        settings: {
          integer: true,
        },
      })
      .addBooleanSwitch({
        path: 'stacked',
        name: 'Stacked',
        description: 'Toggles between stacked and grouped bar charts',
        defaultValue: true,
      })
      .addBooleanSwitch({
        path: 'horizontalTooltip',
        name: 'Ruler',
        description: 'Enables a ruler with the tooltip, works only in the vertical mode',
        defaultValue: false,
      })
      .addBooleanSwitch({
        path: 'dataLabels',
        name: 'Data Labels',
        description: 'Shows data labels on top of the chart',
        defaultValue: true,
      })
      .addBooleanSwitch({
        path: 'legendShow',
        name: 'Show Legend',
        description: 'Show or hide the legend container',
        defaultValue: true,
      })
      .addTextInput({
        path: 'xAxis',
        name: 'X Axis',
        description: 'Provide a name to the x axis ',
        defaultValue: '',
        category: ['Axis Editor'],
      })
      .addTextInput({
        path: 'yAxis',
        name: 'Y Axis',
        description: 'Provide a name to the y axis ',
        defaultValue: '',
        category: ['Axis Editor'],
      })
      .addBooleanSwitch({
        path: 'labels',
        name: 'Axis Labels',
        description: 'Enables labels on the x-axis',
        defaultValue: true,
        category: ['Axis Editor'],
      })
      .addBooleanSwitch({
        path: 'editAxis',
        name: 'Enable Axis Edit Mode',
        description:
          'Enables the `axis-edit-mode`, where you can edit names of the different stacks present on the axis.',
        defaultValue: false,
        category: ['Axis Editor'],
      })
      // Custom editor for editing axes
      .addCustomEditor({
        id: 'toEditAxis',
        path: 'toEditAxis',
        name: 'Axis Edit Mode',
        defaultValue: [],
        editor(props: any) {
          return <EditAxis data={props.context.data} toEditAxis={props.value} onChange={props.onChange} />;
        },
        showIf: (options: StackedBarOptions) => options.editAxis,
        category: ['Axis Editor'],
      })
      .addBooleanSwitch({
        path: 'editBar',
        name: 'Enable Bar Edit Mode',
        description: 'Enables the `bar-edit-mode`, where you can delete bars present in a stack.',
        defaultValue: false,
        category: ['Bar Editor'],
      })
      // Custom editor for deleting queries
      .addCustomEditor({
        id: 'toDeleteQuery',
        path: 'toDeleteQuery',
        name: 'Deletes a Bar (use with care)',
        defaultValue: [{ deletedQueryValue: [] }],
        editor(props: any) {
          return <EditBar bigData={props.context} toDeleteQuery={props.value} onChange={props.onChange} />;
        },
        showIf: (options: StackedBarOptions) => options.editBar,
        category: ['Bar Editor'],
      });
  })
  .setNoPadding()
  .setPanelChangeHandler(statPanelChangedHandler)
  .setMigrationHandler(sharedSingleStatMigrationHandler);
