import React, { FC, SyntheticEvent, useState, FormEvent } from 'react';
import { Tooltip, Form, Field, Input, VerticalGroup, Button, useTheme, Icon } from '@grafana/ui';
import { selectors } from '@grafana/e2e-selectors';
import { submitButton, eyeButton, passContainer } from '../Login/LoginForm';
import { getPasswordStrengthLvl } from '@grafana/data';
interface Props {
  onSubmit: (pw: string) => void;
  onSkip?: (event?: SyntheticEvent) => void;
  passwordHint?: string;
}

interface PasswordDTO {
  newPassword: string;
  confirmNew: string;
}

export const ChangePassword: FC<Props> = ({ onSubmit, onSkip, passwordHint }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleNew, setVisibleNew] = useState<boolean>(false);

  const visibleChange = (event: FormEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setVisible(!visible);
  };

  const submit = (passwords: PasswordDTO) => {
    onSubmit(passwords.newPassword);
  };

  const theme = useTheme();
  return (
    <Form onSubmit={submit}>
      {({ errors, register, getValues, watch }) => {
        let passwordStrength = getPasswordStrengthLvl(watch().newPassword).level;
        return (
          <>
            <Field
              label="New password"
              invalid={!!errors.newPassword}
              error={errors.newPassword ? selectors.pages.Login.weakPasswordWarn : undefined}
              className={passContainer}
              description={selectors.pages.Login.weakPasswordWarn}
            >
              <Input
                autoFocus
                type={visibleNew ? 'text' : 'password'}
                name="newPassword"
                ref={register({
                  required: 'password is required',
                  validate: (v) => getPasswordStrengthLvl(v).isWeek,
                })}
                placeholder={passwordHint ?? 'password'}
                invalid={!!errors.newPassword}
                suffix={
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '110px' }}>
                    <div
                      style={{
                        color: passwordStrength === 'good' ? 'blue' : passwordStrength === 'strong' ? 'green' : 'red',
                        marginBottom: '2px',
                      }}
                    >
                      {passwordStrength}
                    </div>
                    <div onClick={() => setVisibleNew(!visibleNew)}>
                      <Icon
                        name={!visibleNew ? 'eye' : 'eye-off'}
                        className={eyeButton}
                        style={{ color: theme.colors.formLabel }}
                        aria-label={selectors.pages.Login.hideUnhide}
                      />
                    </div>
                  </div>
                }
              />
            </Field>
            <Field
              label="Confirm new password"
              invalid={!!errors.confirmNew}
              error={errors?.confirmNew?.message}
              className={passContainer}
            >
              <Input
                type={visible ? 'text' : 'password'}
                name="confirmNew"
                ref={register({
                  required: 'Confirmed password is required',
                  validate: (v) => v === getValues().newPassword || 'Passwords must match!',
                })}
                placeholder={passwordHint ?? 'password'}
                invalid={!!errors.confirmNew}
                suffix={
                  <div onClick={visibleChange}>
                    <Icon
                      name={!visible ? 'eye' : 'eye-off'}
                      className={eyeButton}
                      style={{ color: theme.colors.formLabel }}
                      aria-label={selectors.pages.Login.hideUnhide}
                    />
                  </div>
                }
              />
            </Field>
            <VerticalGroup>
              <Button disabled={errors.newPassword ? true : false} type="submit" className={submitButton}>
                Submit
              </Button>

              {onSkip && (
                <Tooltip
                  content="If you skip you will be prompted to change password next time you log in."
                  placement="bottom"
                >
                  <Button variant="link" onClick={onSkip} type="button" aria-label={selectors.pages.Login.skip}>
                    Skip
                  </Button>
                </Tooltip>
              )}
            </VerticalGroup>
          </>
        );
      }}
    </Form>
  );
};
