import { PanelModel } from '@grafana/data';
import { BoxPlotOptions } from './types';

export const boxPlotMigration = (panel: PanelModel<BoxPlotOptions>): Partial<BoxPlotOptions> => {
  const options: any = panel.options || {};
  // First check if the key exists or not
  if ('chartType' in options) {
    options.toShowPlot = 'boxplot';
    if (options['chartType'] === 'simple') {
      options['typeBoxPlot'] = 'normal';
    } else {
      // timeseries chart
      options['typeBoxPlot'] = 'withtime';
      options['toCluster'] = 0;
      options['customClusterSize'] = options['clusterSize'] / (60 * 1000);
      options['clusterSize'] = 'toCluster';
    }
    delete options['chartType'];
    delete options['xaxisName'];
    delete options['yaxisName'];
    delete options['clusteringMethod'];
    delete options['numBoxes'];
    delete options['clusterSize'];
    delete options['info'];

    return options;
  }
  return options;
};

// This is called when the panel changes from another panel
export const boxPanelChangedHandler = (
  panel: PanelModel<Partial<BoxPlotOptions>> | any,
  prevPluginId: string,
  prevOptions: any
) => {
  // This handles most config changes
  let newOpts = panel.options as BoxPlotOptions;
  if (prevPluginId === 'boxplot2') {
    return prevOptions as BoxPlotOptions;
  }
  return newOpts;
};
