import { Registry } from '@grafana/data';
import Map from 'ol/Map';
import { OSMapLayerOptions, OSMapLayerRegistryItem } from '../LayerEditor/types';
import { baseLayers, standardOS } from './basemaps/baseLayers';
import { geojsonLayer } from './geojsonLayer/geojsonLayer';
import { heatmapLayer } from './hetamapLayer/heatmap';
import { markersLayer } from './markerLayer/markerlayer';
import { customMarkerLayer } from './markerLayer/customMarkerLayer';
import { customHeatmapLayer } from './hetamapLayer/customHeatmapLayer';
import { MapLayerType } from '../types/interface';

/**
 * The default configuration for a base map layer in an OpenStreetMap visualization.
 *
 * @constant {OSMapLayerOptions} DEFAULT_BASEMAP_CONFIG
 * @property {MapLayerType} type - The type of map layer.
 * @property {string} name - The name of the base map layer.
 * @property {Object} config - The configuration object for the base map layer.
 */
export const DEFAULT_BASEMAP_CONFIG: OSMapLayerOptions = {
  type: MapLayerType.DEFAULT,
  name: '',
  config: {},
};

/**
 * The default base layer configuration for an OpenStreetMap visualization.
 *
 * @constant {OSMapLayerRegistryItem} defaultBaseLayer
 * @property {string} id - The unique identifier for the base layer.
 * @property {string} name - The name of the base layer.
 * @property {boolean} isBaseMap - A boolean indicating whether it's a base map layer.
 * @property {function} create - A function that creates the base map layer with the given map and options.
 */
export const defaultBaseLayer: OSMapLayerRegistryItem = {
  id: DEFAULT_BASEMAP_CONFIG.type,
  name: 'Default base layer',
  isBaseMap: true,

  /**
   * Create the default base map layer.
   *
   * @param {Map} map - The map instance to which the layer will be added.
   * @param {OSMapLayerOptions} options - The options for configuring the base map layer.
   * @returns {BaseLayer} The created base map layer.
   */
  create: (map: Map, options: OSMapLayerOptions) => {
    return standardOS.create(map, options);
  },
};

/**
 * A registry containing various OpenStreetMap layer configurations and options.
 *
 * @constant {Registry<OSMapLayerRegistryItem<any>>} osmapLayerRegistry
 * @property {function} constructor - The constructor function for creating the registry.
 * @property {Array<OSMapLayerRegistryItem<any>>} items - An array of OpenStreetMap layer registry items.
 */
export const osmapLayerRegistry = new Registry<OSMapLayerRegistryItem<any>>(() => [
  defaultBaseLayer,
  markersLayer,
  geojsonLayer,
  heatmapLayer,
  customHeatmapLayer,
  customMarkerLayer,
  ...baseLayers,
]);
