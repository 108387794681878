import React from 'react';
import { DataFrame, FieldConfigSource, InterpolateFunction, PanelData, TimeRange } from '@grafana/data';
import { Alert, useTheme } from '@grafana/ui';
import { generateHtml } from '../../helpers';
import { getStyles } from '../../styles';
import { TextOptions } from '../../types';
import { css, cx } from '@emotion/css';

/**
 * @property
 * @type {TextOptions} Options
 * @type {DataFrame} Frame
 * @type {number}width
 * @type {TimeRange} TimeRange
 * @type {FieldConfigSource<any>} FieldConfig
 * @type {InterpolateFunction} ReplaceVariables
 * @type {string}TimeZone
 * @type {PanelData} Dataseries
 */
export interface Props {
  options: TextOptions;
  frame?: DataFrame;
  width: number;
  timeRange: TimeRange;
  fieldConfig: FieldConfigSource<any>;
  replaceVariables: InterpolateFunction;
  timeZone: string;
  dataseries: PanelData;
}

/**
 * Text
 */
export const Text: React.FC<Props> = ({
  width,
  options,
  frame,
  timeRange,
  fieldConfig,
  replaceVariables,
  timeZone,
  dataseries,
}) => {
  //* Calculate fontoverride factor value.
  let factor = 0;
  if (options.fontoverride) {
    factor = width < 500 ? width * 0.002 : width * 0.001;
    if (factor < options.minlimit) {
      factor = options.minlimit;
    } else if (factor > options.maxlimit) {
      factor = options.maxlimit;
    }
  }
  /**
   * Theme
   */
  const theme = useTheme();
  const styles = getStyles(theme);

  //* change font size according to the fontoverride.
  const fontstyle = makeStyles(factor, theme.isDark ? '#D8D9DA' : '#464C54');

  try {
    /**
     * Default Content if no frames returned
     */
    if (!frame?.length) {
      return (
        <div
          className={cx('markdown-html', styles.frame, options.fontoverride ? fontstyle.fonts : '')}
          dangerouslySetInnerHTML={{
            __html: generateHtml(
              {},
              options.defaultContent,
              options,
              timeRange,
              theme,
              fieldConfig,
              replaceVariables,
              timeZone,
              dataseries
            ),
          }}
        />
      );
    }

    /**
     * Frame returned
     */
    const data = frame.fields.reduce((out, { config, name, values }) => {
      values.toArray().forEach((v, i) => {
        out[i] = { ...out[i], [config.displayName || name]: v };
      });

      return out;
    }, [] as Array<Record<string, any>>);
    /**
     * Every Row
     */
    if (options.everyRow) {
      return (
        <>
          {data.map((row, key) => (
            <div
              key={key}
              className={cx('markdown-html', styles.frame, options.fontoverride ? fontstyle.fonts : '')}
              dangerouslySetInnerHTML={{
                __html: generateHtml(
                  { data: [row] },
                  options.content,
                  options,
                  timeRange,
                  theme,
                  fieldConfig,
                  replaceVariables,
                  timeZone,

                  dataseries
                ),
              }}
            />
          ))}
        </>
      );
    }
    /**
     * All Rows
     */
    return (
      <div
        className={cx('markdown-html', styles.frame, options.fontoverride ? fontstyle.fonts : '')}
        dangerouslySetInnerHTML={{
          __html: generateHtml(
            { data },
            options.content,
            options,
            timeRange,
            theme,
            fieldConfig,
            replaceVariables,
            timeZone,
            dataseries
          ),
        }}
      />
    );
  } catch (e: any) {
    /**
     * Error
     */
    return (
      <div className={cx('markdown-html', styles.frame, options.fontoverride ? fontstyle.fonts : '')}>
        <Alert title="Couldn't build text from template" severity="error">
          Please make sure the Content is a valid template and Helpers are correct.
        </Alert>

        {<pre>{e instanceof Error ? e.message : e}</pre>}
      </div>
    );
  }
};

const makeStyles = (FactorText: number, color: string) => ({
  fonts: css`
    font-size: ${14 * FactorText}px !important;
    color: ${color};
    & h1 {
      font-size: ${28 * FactorText}px !important;
    }
    & h2 {
      font-size: ${24 * FactorText}px !important;
    }
    & h3 {
      font-size: ${21 * FactorText}px !important;
    }
    & h4 {
      font-size: ${18 * FactorText}px !important;
    }
    & h5 {
      font-size: ${16 * FactorText}px !important;
    }
    & h6 {
      font-size: ${14 * FactorText}px !important;
    }
  `,
});
