import { Field } from '@grafana/ui';
import React from 'react';
import { HeatmapStyle } from './CustomMarkerStyle/HeatmapStyle';
import { QueryModeStyle } from './QueryModeStyle';
import { PropsLayer } from './type';

/**
 * Render the style configuration for a heatmap layer.
 *
 * @param {PropsLayer} props - The component props containing layer and data.
 * @returns {JSX.Element} The JSX element representing the heatmap layer style configuration.
 */

export function HeatmapLayerStyle({ layer, data }: PropsLayer) {
  return (
    <>
      <Field label="Select Data">
        <QueryModeStyle data={data} layer={layer} />
      </Field>
      <HeatmapStyle layer={layer} />
    </>
  );
}
