import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const Datasources: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 100 100"
      viewBox="0 0 100 100"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M94.4,50c0-5-2.3-9.8-6.3-12.9c7.2-5.7,8.4-16.1,2.8-23.3c-3.2-4-8-6.3-13.1-6.3H22.3C13.1,7.5,5.7,14.8,5.6,24
        c0,5.1,2.3,9.9,6.3,13.1C4.8,42.6,3.5,53,9.1,60.1c0.8,1.1,1.8,2,2.8,2.8C4.8,68.6,3.5,79,9.2,86.2c3.2,4,8,6.3,13.1,6.3h55.4
        c9.1,0,16.6-7.3,16.6-16.5c0-5.1-2.3-9.9-6.3-13.1C92,59.8,94.3,55,94.4,50z M13,24.1c0-5.1,4.1-9.2,9.2-9.2h1.8v3.7
        c0,2,1.7,3.7,3.7,3.7s3.7-1.7,3.7-3.7v-3.7h7.4v3.7c0,2,1.7,3.7,3.7,3.7s3.7-1.7,3.7-3.7v-3.7h31.4c5.1,0,9.2,4.1,9.2,9.2
        s-4.1,9.2-9.2,9.2H22.3C17.2,33.4,13,29.2,13,24.1z M87,75.9c0,5.1-4.1,9.2-9.2,9.2H22.3c-5.1,0-9.2-4.1-9.2-9.2s4.1-9.2,9.2-9.2
        h1.8v3.7c0,2,1.7,3.7,3.7,3.7s3.7-1.7,3.7-3.7v-3.7h7.4v3.7c0,2,1.7,3.7,3.7,3.7s3.7-1.7,3.7-3.7v-3.7h31.4
        C82.8,66.6,87,70.8,87,75.9z M22.3,59.2c-5.1,0-9.2-4.1-9.2-9.2s4.1-9.2,9.2-9.2h1.8v3.7c0,2,1.7,3.7,3.7,3.7s3.7-1.7,3.7-3.7v-3.7
        h7.4v3.7c0,2,1.7,3.7,3.7,3.7s3.7-1.7,3.7-3.7v-3.7h31.4c5.1,0,9.2,4.1,9.2,9.2s-4.1,9.2-9.2,9.2H22.3z"
      />
    </svg>
  );
};
