import { SingleStatBaseOptions, VizLegendOptions } from '@grafana/ui';
import { ReducerID, standardEditorsRegistry, PanelOptionsEditorBuilder } from '@grafana/data';

/**
 * Radar panel options
 * @typedef {Object} RadarPanelOptions
 * @property {Array<string>} categories
 * @property {number} levels
 * @property {string} pointcolor
 * @property {Array<Group>} groupData
 * @property {number} fillopacity
 * @property {boolean} showpoint
 * @property {RadarChartLegendOptions} legend
 * @extends {SingleStatBaseOptions}
 */
export interface RadarPanelOptions extends SingleStatBaseOptions {
  categories: string[];
  levels: number;
  pointcolor: string;
  groupData: Group[];
  fillopacity: number;
  showpoint: boolean;
  legend: RadarChartLegendOptions;
}
/**
 * radar chart group
 * @typedef {Object} Group
 * @property {string} name
 * @property {Array<any>} query
 */
export interface Group {
  name: string;
  query: any[];
}
export function addStandardDataReduceOptions(
  builder: PanelOptionsEditorBuilder<SingleStatBaseOptions>,
  includeOrientation = true
) {
  builder.addCustomEditor({
    id: 'reduceOptions.calcs',
    path: 'reduceOptions.calcs',
    name: 'Value',
    description: 'Choose a reducer function / calculation',
    editor: standardEditorsRegistry.get('stats-picker').editor as any,
    defaultValue: [ReducerID.mean],
    // Hides it when all values mode is on
    // showIf: currentConfig => currentConfig.reduceOptions.values === false,
  });
}

/**
 * @typedef {Object} DataToUse
 * @property {string} name
 * @property {Array<string>} data
 * @property {Array<string>} [dataStr]
 * @property {string} stroke
 */
export interface DataToUse {
  name: string;
  data: number[];
  dataStr?: string[];
  stroke: string;
}
/**
 * @enum {RadarChartLegendValues}
 */
export enum RadarChartLegendValues {
  Value = 'value',
  Percent = 'percent',
}
/**
 * @enum {RadarChartLabels}
 */
export enum RadarChartLabels {
  Name = 'name',
  Value = 'value',
  Percent = 'percent',
}

/**
 * @typedef {Object} RadarChartLegendOptions
 * @property {Array<RadarChartLegendValues>} values
 * @extends {VizLegendOptions}
 */
export interface RadarChartLegendOptions extends VizLegendOptions {
  values: RadarChartLegendValues[];
}
/**
 * @typedef {Object} VizTooltipOptions
 * @property {TooltipDisplayMode} mode
 */
export type VizTooltipOptions = {
  mode: TooltipDisplayMode;
};
/**
 * @enum {TooltipDisplayMode}
 */
export enum TooltipDisplayMode {
  Single = 'single',
  Multi = 'multi',
  None = 'none',
}
