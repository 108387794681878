import { Anomaly, AppEvents, DataFrame } from '@grafana/data';
import { connectApi } from 'app/features/forecasting/api/model';
import { API_URL_SERVER, apiTypes } from 'app/features/forecasting/constants';
import { convertSeriesToJSONString } from 'app/features/forecasting/utils/utilFunction';
import { convertToPanelData } from './convertMerlionDataToPanelData';
import { config } from 'app/core/config';
import axios from 'axios';
import appEvents from 'app/core/app_events';

export const getAnomalyData = async (anomaly: Anomaly, seriesData: DataFrame) => {
  const jsonString = convertSeriesToJSONString(seriesData);

  var Allmodels = await fetchAllModel();
  const matchedModel = Allmodels.find((ele: any) => ele.name === anomaly.modelName);
  if (!matchedModel) {
    var finaldata = {
      series: [seriesData],
    };
    return finaldata;
  }
  const modelPayload = JSON.parse(Allmodels.find((ele: any) => ele.name === anomaly.modelName)?.payload).state;
  const payload = {
    output:
      '..anomaly-predict-training-metrics.children...anomaly-predict-test-metrics.children...anomaly-predict-plots.children...anomaly-predict-exception-modal.is_open...anomaly-predict-exception-modal-content.children..',
    outputs: [
      { id: 'anomaly-predict-training-metrics', property: 'children' },
      { id: 'anomaly-predict-test-metrics', property: 'children' },
      { id: 'anomaly-predict-plots', property: 'children' },
      { id: 'anomaly-predict-exception-modal', property: 'is_open' },
      { id: 'anomaly-predict-exception-modal-content', property: 'children' },
    ],
    inputs: [
      { id: 'anomaly-predict-test-btn', property: 'n_clicks', value: 1 },
      { id: 'anomaly-predict-exception-modal-close', property: 'n_clicks', value: 0 },
    ],
    changedPropIds: ['anomaly-predict-test-btn.n_clicks'],
    state: [
      { id: 'anomaly-predict-select-file', property: 'value', value: '' },
      { id: 'anomaly-predict-select-features', property: 'value', value: anomaly.column },
      { id: 'anomaly-predict-select-label', property: 'value', value: null },
      { id: 'anomaly-predict-select-threshold', property: 'value', value: anomaly.threshold },
      {
        id: 'anomaly-predict-threshold-param-table',
        property: 'children',
        value: {
          props: {
            data: anomaly.thresholdSettings,
            columns: [
              { id: 'Parameter', name: 'Parameter' },
              { id: 'Value', name: 'Value' },
            ],
            editable: true,
            style_table: { overflowX: 'scroll', overflowY: 'scroll', height: 60, minHeight: '200px' },
            style_data: { backgroundColor: 'white' },
            style_header: { backgroundColor: '#014486', color: 'white' },
            style_cell_conditional: [{ textAlign: 'center', 'font-family': 'Salesforce Sans' }],
            style_header_conditional: [{ textAlign: 'center', 'font-family': 'Salesforce Sans' }],
            derived_virtual_data: anomaly.thresholdSettings,
            derived_virtual_indices: anomaly.thresholdSettings.map((_: any, index: number) => index),
            derived_virtual_row_ids: anomaly.thresholdSettings.map((_: any) => null),
            derived_viewport_data: anomaly.thresholdSettings,
            derived_viewport_indices: anomaly.thresholdSettings.map((_: any, index: number) => index),
            derived_viewport_row_ids: anomaly.thresholdSettings.map((_: any) => null),
            derived_virtual_selected_rows: [],
            derived_virtual_selected_row_ids: [],
            derived_viewport_selected_columns: [],
            derived_viewport_selected_rows: [],
            derived_viewport_selected_row_ids: [],
          },
          type: 'DataTable',
          namespace: 'dash_table',
        },
      },
      {
        id: 'anomaly-predict-training-metrics',
        property: 'children',
        value: {
          props: {
            data: [{ 'Metric 0': '-', 'Metric 1': '-', 'Metric 2': '-', 'Metric 3': '-' }],
            columns: [
              { id: 'Metric 0', name: 'Metric 0' },
              { id: 'Metric 1', name: 'Metric 1' },
              { id: 'Metric 2', name: 'Metric 2' },
              { id: 'Metric 3', name: 'Metric 3' },
            ],
            editable: false,
            style_table: { overflowX: 'scroll' },
            style_data: { backgroundColor: 'white' },
            style_header: { backgroundColor: '#014486', color: 'white' },
            style_cell_conditional: [{ textAlign: 'center', 'font-family': 'Salesforce Sans' }],
            style_header_conditional: [{ textAlign: 'center', 'font-family': 'Salesforce Sans' }],
            derived_virtual_data: [{ 'Metric 0': '-', 'Metric 1': '-', 'Metric 2': '-', 'Metric 3': '-' }],
            derived_virtual_indices: [0],
            derived_virtual_row_ids: [null],
            derived_viewport_data: [{ 'Metric 0': '-', 'Metric 1': '-', 'Metric 2': '-', 'Metric 3': '-' }],
            derived_viewport_indices: [0],
            derived_viewport_row_ids: [null],
            derived_virtual_selected_rows: [],
            derived_virtual_selected_row_ids: [],
            derived_viewport_selected_columns: [],
            derived_viewport_selected_rows: [],
            derived_viewport_selected_row_ids: [],
          },
          type: 'DataTable',
          namespace: 'dash_table',
        },
      },
      { id: 'anomaly-predict-json-data', property: 'value', value: jsonString },
      { id: 'anomaly-predict-json-test-data', property: 'value', value: null },
      {
        id: 'anomaly-predict-model-name',
        property: 'value',
        value: `${anomaly.modelName}_anomaly___${
          modelPayload.find((ele: any) => ele.id === 'anomaly-select-algorithm').value
        }`,
      },

      { id: 'anomaly-predict-data-roundoff', property: 'value', value: 4 },
    ],
  };
  const { error, data } = await connectApi(payload, apiTypes.anomalyDetectionStart);
  if (data.cacheKey && data.job) {
    return await new Promise((resolve, reject) => {
      const timerId = setInterval(async () => {
        try {
          const resp1 = await connectApi(payload, apiTypes.anomalyDetectionStatus, data.cacheKey, data.job);
          if (!resp1.data) {
            clearInterval(timerId);
            resolve(false);
            appEvents.emit(AppEvents.alertWarning, [`No anomalies recieved for the panel.`]);
          } else if (resp1.data.response) {
            clearInterval(timerId);
            appEvents.emit(AppEvents.alertSuccess, [`Anomalies fetched`]);
            resolve(convertToPanelData(resp1.data.response['anomaly-predict-plots'].children.props.figure.data));
            return;
          }
        } catch (err) {
          appEvents.emit(AppEvents.alertError, [`Error occured while getting the anomalies`]);
          console.log(err);
          clearInterval(timerId);
          resolve(false);
          return err;
        }
      }, 1000);
    });
  } else {
    return error;
  }
};

const fetchAllModel = async () => {
  const formData = new FormData();
  formData.append('userid', JSON.stringify(config.bootData.user.id));
  formData.append('host', config.appUrl);
  formData.append('orgid', JSON.stringify(config.bootData.user.orgId));
  formData.append('activity', apiTypes.getAnomalyModelListFromMiddleware);
  const { data } = await axios.post(`${API_URL_SERVER}api/anomaly/list`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
};
