import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const Playlist: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 16 16"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M9.6,4.9L7.4,3.8C7.2,3.7,7.1,3.7,6.9,3.7c-0.5,0-1,0.4-1,1v2.2c0,0.3,0.2,0.7,0.5,0.8c0.1,0.1,0.3,0.1,0.5,0.1
        c0.2,0,0.3,0,0.5-0.1l2.2-1.1C9.7,6.5,9.9,6.3,10,6.2C10.2,5.7,10,5.1,9.6,4.9z M9.2,5.8L7,7c0,0,0,0-0.1,0C6.8,7,6.8,6.9,6.8,6.8
        V4.6c0-0.1,0-0.1,0.1-0.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0l2.2,1.1c0,0,0.1,0.1,0.1,0.1C9.2,5.8,9.2,5.8,9.2,5.8z M14.3,9.2h-0.6V2.3
        h0.6c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6h-0.6h-1.1H3.4H2.3H1.7c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h0.6v6.9H1.7
        c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h5.7v2.3H6.3c-0.9,0-1.7,0.8-1.7,1.7c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6
        c0-0.3,0.3-0.6,0.6-0.6h3.4c0.3,0,0.6,0.3,0.6,0.6c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6c0-0.9-0.8-1.7-1.7-1.7H8.6v-2.3h5.7
        c0.3,0,0.6-0.3,0.6-0.6C14.8,9.4,14.6,9.2,14.3,9.2z M3.4,9.2V2.3h4h1.1h4v6.9H3.4z"
      />
    </svg>
  );
};
