import React from 'react';
import { PanelProps, FieldDisplay, getFieldDisplayValues } from '@grafana/data';
import { config } from 'app/core/config';
import { TreeMapPanelOptions, TreeStru } from './types';
import TreeMapSvg from './TreeMapSvg';

interface Props extends PanelProps<TreeMapPanelOptions> {}

export function TreeMapPanel(props: Props) {
  const { height, options, width, data, fieldConfig, replaceVariables, timeZone } = props;

  // @ts-ignore
  let treedata: any = data.series[0] ? (data.series[0].tree ? JSON.parse(data.series[0].tree).format : '') : '';

  let colorset: string[] = [];
  // * Parent is bind
  let mainDataToUse: TreeStru[] = [
    {
      id: 'Arnowa',
      parent: null,
      size: null,
      color: '',
      tooltip: '',
    },
  ];
  const getValues = (): FieldDisplay[] => {
    return getFieldDisplayValues({
      fieldConfig,
      reduceOptions: options.reduceOptions,
      replaceVariables,
      theme: config.theme,
      data: data.series,
      timeZone,
    });
  };
  /**
   * * mainDataToUse has special structure provided by visx/hierarchy
   * * we are inserting data in the form of parent and child
   * todo - we can implement the tree structure of family here - like gradparent => parent => child
   */

  const bigData: any = getValues();
  if (bigData[0].name !== 'No data') {
    if (Array.isArray(treedata) && treedata.length) {
      const findItemNested = (arr: any, itemId: Number, nestingKey: string) =>
        arr.reduce((a: any, item: any) => {
          if (a) {
            return a;
          }
          if (item.id === itemId) {
            return item.name;
          }
          if (item[nestingKey]) {
            return findItemNested(item[nestingKey], itemId, nestingKey);
          }
        }, null);
      const cb = (e: any) => {
        for (let i = 0; i < bigData.length; i++) {
          if (bigData[i].display.title === e.name) {
            let res: any = findItemNested(treedata, e.parentId, 'children');

            if (res) {
              if (e.children.length) {
                mainDataToUse.push({
                  id: bigData[i].display.title,
                  // * fixed single parent
                  parent: res,
                  size: null,
                  color: bigData[i].display.color,
                  tooltip: `${bigData[i].display.prefix ? bigData[i].display.prefix : ''}${bigData[i].display.text}${
                    bigData[i].display.suffix ? bigData[i].display.suffix : ''
                  }`,
                });
              } else {
                mainDataToUse.push({
                  id: bigData[i].display.title,
                  // * fixed single parent
                  parent: res,
                  size: Number(bigData[i].display.numeric.toFixed(Number(fieldConfig.defaults?.unit) ?? 0)),
                  color: bigData[i].display.color,
                  tooltip: `${bigData[i].display.prefix ? bigData[i].display.prefix : ''}${bigData[i].display.text}${
                    bigData[i].display.suffix ? bigData[i].display.suffix : ''
                  }`,
                });
              }
            } else {
              mainDataToUse.push({
                id: bigData[i].display.title,
                // * fixed single parent
                parent: 'Arnowa',
                size: null,
                color: bigData[i].display.color,
                tooltip: `${bigData[i].display.prefix ? bigData[i].display.prefix : ''}${bigData[i].display.text}${
                  bigData[i].display.suffix ? bigData[i].display.suffix : ''
                }`,
              });
            }
            colorset.push(bigData[i].display.color);
          }
        }
        e.children && e.children.forEach(cb);
      };
      treedata.forEach(cb);
    }
  }

  if (mainDataToUse.length <= 1 || !Array.isArray(treedata)) {
    return (
      <div className="panel-empty">
        <p>Please add tree data transformation to use treemap plugin</p>
      </div>
    );
  }

  return (
    <div>
      <TreeMapSvg
        width={width}
        height={height}
        color={colorset}
        mainData={mainDataToUse}
        tileMethod={options.toTileMethod}
        labels={options.dataLabels}
        options={options}
      ></TreeMapSvg>
    </div>
  );
}
