import moment from 'moment-timezone';
import {
  Field,
  FieldType,
  Threshold,
  fieldColorModeRegistry,
  getFieldColorModeForField,
  getColorForTheme,
} from '@grafana/data';
import { config } from '@grafana/runtime';

const timelyday = [
  'now-5m',
  'now-15m',
  'now-30m',
  'now-1h',
  'now-3h',
  'now-6h',
  'now-12h',
  'now-24h',
  'now/d',
  '5m',
  '15m',
  '30m',
  '1h',
  '3h',
  '6h',
  '12h',
  '24h',
];
const timelyfull = ['now-2d', 'now-1d/d', 'now-2d/d', 'now-7d/d', '2d', '1d/d', '2d/d', '7d/d'];

const STANDARD_DATE_FORMAT = 'YYYY-MM-DD';
const STANDARD_TIME_FORMAT = 'HH:mm';
const STANDARD_DATETIME_FORMAT = 'MMMM D YYYY, HH:mm:ss.SSS';

export const timeConverter = (time: string | Date, timeRange: any, timeZone: string, overridetime: boolean) => {
  if (timeZone === 'browser') {
    if (overridetime) {
      return moment(time).format(STANDARD_DATETIME_FORMAT);
    } else if (timeRange.raw && timelyday.includes(timeRange.raw.from)) {
      return moment(time).format(STANDARD_TIME_FORMAT);
    } else if (timeRange.raw && timelyfull.includes(timeRange.raw.from)) {
      return moment(time).format(STANDARD_DATETIME_FORMAT);
    } else {
      return moment(time).format(STANDARD_DATE_FORMAT);
    }
  } else if (timeZone === 'utc') {
    if (overridetime) {
      return moment.utc(time).format(STANDARD_DATETIME_FORMAT);
    } else if (timeRange.raw && timelyday.includes(timeRange.raw.from)) {
      return moment.utc(time).format(STANDARD_TIME_FORMAT);
    } else if (timeRange.raw && timelyfull.includes(timeRange.raw.from)) {
      return moment.utc(time).format(STANDARD_DATETIME_FORMAT);
    } else {
      return moment.utc(time).format(STANDARD_DATE_FORMAT);
    }
  } else {
    if (overridetime) {
      return moment(time).tz(timeZone).format(STANDARD_DATETIME_FORMAT);
    } else if (timeRange.raw && timelyday.includes(timeRange.raw.from)) {
      return moment(time).tz(timeZone).format(STANDARD_TIME_FORMAT);
    } else if (timeRange.raw && timelyfull.includes(timeRange.raw.from)) {
      return moment(time).tz(timeZone).format(STANDARD_DATETIME_FORMAT);
    } else {
      return moment(time).tz(timeZone).format(STANDARD_DATE_FORMAT);
    }
  }
};

export const timeConverter2 = (time: string | Date, timeZone: string) => {
  if (timeZone === 'browser') {
    return time;
  } else if (timeZone === 'utc') {
    return time;
  } else {
    var aestTime = new Date(time).toLocaleString('en-US', { timeZone: timeZone });
    return Date.parse(aestTime);
    // return moment(time).tz(timeZone);
  }
};

interface GetCalcOptions {
  mode: string;
  seriesIndex?: number;
}

export type FieldValueColorCalculator = (value: number, percent: number, Threshold?: Threshold) => string;

function getCalculator(options: GetCalcOptions): FieldValueColorCalculator {
  const mode = fieldColorModeRegistry.get(options.mode);
  return mode.getCalculator({ state: { seriesIndex: options.seriesIndex } } as Field, config.theme);
}

// const calcFn = getCalculator({ mode: FieldColorModeId.PaletteClassic, seriesIndex: 0 }); // #7EB26D
// console.log(calcFn(70, 0, undefined));

export const colorConverter = (fieldConfig: any, newChartData: any) => {
  const colors: any = {};
  if (fieldConfig.defaults.color) {
    if (fieldConfig.defaults.color.mode === 'palette-classic') {
      newChartData.map((data: any, index: number) => {
        const calcFn = getCalculator({ mode: fieldConfig.defaults.color.mode, seriesIndex: index });
        colors[data.type] = getColorForTheme(calcFn(data.range[2], 1, fieldConfig.defaults.thresholds), config.theme);
      });
    } else if (fieldConfig.defaults.color.mode === 'thresholds') {
      newChartData.map((data: any, index: number) => {
        if (fieldConfig.defaults.thresholds.mode === 'percentage') {
          return fieldConfig.defaults.thresholds.steps[0].color;
        } else {
          let color = '#255aee';
          fieldConfig.defaults.thresholds.steps.map((items: any) => {
            if (data.range[2] > items.value) {
              color = items.color;
            }
          });
          colors[data.type] = getColorForTheme(color, config.theme);
        }
      });
    } else {
      newChartData.map((data: any, index: number) => {
        const field: Field = {
          name: `Box${data.range[2]}`,
          config: fieldConfig.defaults,
          type: FieldType.string,
          // @ts-ignore
          values: [data.range[2]],
        };
        const colorMode = getFieldColorModeForField(field);
        const seriesColor = colorMode.getCalculator(field, config.theme);
        colors[data.type] = getColorForTheme(
          seriesColor(data.range[2], 1, fieldConfig.defaults.thresholds),
          config.theme
        );
      });
    }
  }
  return colors;
};

export const colorConverter2 = (fieldConfig: any, singleData: number, idx: number) => {
  if (fieldConfig.defaults.color) {
    if (fieldConfig.defaults.color.mode === 'palette-classic') {
      const calcFn = getCalculator({ mode: fieldConfig.defaults.color.mode, seriesIndex: idx });
      return getColorForTheme(calcFn(singleData, 1, fieldConfig.defaults.thresholds), config.theme);
    } else if (fieldConfig.defaults.color.mode === 'thresholds') {
      if (fieldConfig.defaults.thresholds.mode === 'percentage') {
        return fieldConfig.defaults.thresholds.steps[0].color;
      } else {
        let color = '#255aee';
        fieldConfig.defaults.thresholds.steps.map((items: any) => {
          if (singleData > items.value) {
            color = items.color;
          }
        });
        return getColorForTheme(color, config.theme);
      }
    } else {
      const field: Field = {
        name: `Box${singleData}`,
        config: fieldConfig.defaults,
        type: FieldType.string,
        // @ts-ignore
        values: [singleData],
      };
      const colorMode = getFieldColorModeForField(field);
      const seriesColor = colorMode.getCalculator(field, config.theme);
      return getColorForTheme(seriesColor(singleData, 1, fieldConfig.defaults.thresholds), config.theme);
    }
  }
  return '#255aee';
};
