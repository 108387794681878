import { SingleStatBaseOptions } from '@grafana/ui';
import { ReducerID, standardEditorsRegistry, PanelOptionsEditorBuilder } from '@grafana/data';

// Structure copied from angular
export interface TrendPanelOptions extends SingleStatBaseOptions {
  querytype: any;
  percentage: boolean;
  subtext: string;
}

export function addStandardDataReduceOptions(
  builder: PanelOptionsEditorBuilder<SingleStatBaseOptions>,
  includeOrientation = true
) {
  builder.addCustomEditor({
    id: 'reduceOptions.calcs',
    path: 'reduceOptions.calcs',
    name: 'Value',
    description: 'Choose a reducer function / calculation',
    editor: standardEditorsRegistry.get('stats-picker').editor as any,
    defaultValue: [ReducerID.firstNotNull],
    // Hides it when all values mode is on
    // showIf: (currentConfig) => currentConfig.querytype === 'two',
  });
}
