import React from 'react';
import { LegendDisplayMode } from '@grafana/ui';
import { PanelPlugin } from '@grafana/data';
import { RadarPanelOptions, addStandardDataReduceOptions } from './types';
import { RadarPanel } from './RadarPanel';
import { EditorGroupList } from './LayerEditor';

export const plugin = new PanelPlugin<RadarPanelOptions>(RadarPanel).useFieldConfig().setPanelOptions((builder) => {
  addStandardDataReduceOptions(builder);
  builder
    .addSliderInput({
      path: 'levels',
      name: 'Level In Web',
      description: 'Choose no of levels in web',
      defaultValue: 5,
      settings: {
        min: 1,
        max: 10,
        step: 1,
      },
    })
    .addSliderInput({
      path: 'fillopacity',
      name: 'Fill Opacity',
      description: 'Fill Opacity In Chart',
      defaultValue: 0.3,
      settings: {
        min: 0,
        max: 1,
        step: 0.1,
      },
    })
    .addBooleanSwitch({
      path: 'showpoint',
      name: 'Show Points',
      description: 'Show point on corner of the polygon',
      defaultValue: true,
    })
    .addColorPicker({
      path: 'pointcolor',
      name: 'Point Color',
      defaultValue: 'gray',
      showIf: (c, data) => c.showpoint,
    })
    .addRadio({
      path: 'legend.displayMode',
      name: 'Legend mode',
      description: '',
      defaultValue: LegendDisplayMode.List,
      settings: {
        options: [
          { value: LegendDisplayMode.List, label: 'List' },
          { value: LegendDisplayMode.Table, label: 'Table' },
          { value: LegendDisplayMode.Hidden, label: 'Hidden' },
        ],
      },
    })
    .addRadio({
      path: 'legend.placement',
      name: 'Legend placement',
      description: '',
      defaultValue: 'bottom',
      settings: {
        options: [
          { value: 'bottom', label: 'Bottom' },
          { value: 'right', label: 'Right' },
        ],
      },
      showIf: (c) => c.legend.displayMode !== LegendDisplayMode.Hidden,
    })
    .addCustomEditor({
      id: 'groupData',
      path: 'groupData',
      name: 'Groups',
      description: 'List of Groups',
      defaultValue: [],
      editor(props: any) {
        return <EditorGroupList dataAll={props.context} groupData={props.value} onChange={props.onChange} />;
      },
      category: ['Chart Settings'],
    })
    .addStringArray({
      path: 'categories',
      name: 'Categories',
      description: 'Defines the keys upon which each group is destributed to',
      defaultValue: ['2010', '2012', '2014', '2016'],
      category: ['Axis'],
    });
});
