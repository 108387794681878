import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { AppEvents } from '@grafana/data';
import appEvents from 'app/core/app_events';
import { updateLocation } from 'app/core/reducers/location';
import { deleteDashboard } from 'app/features/manage-dashboards/state/actions';
import { deleteData, getAlertRuleList, getCarbonPullData, getCarbonPushData } from '../../utils/utils';

export const useDashboardDelete = (dashboard: any) => {
  const dispatch = useDispatch();
  const [state, onRestoreDashboard] = useAsyncFn(async () => await deleteDashboardAndPush(dashboard, false), []);
  useEffect(() => {
    if (state.value) {
      dispatch(
        updateLocation({
          path: '/',
          replace: true,
          query: {},
        })
      );
      appEvents.emit(AppEvents.alertSuccess, ['Dashboard Deleted', state.value.title + ' has been deleted']);
    }
  }, [state]);
  return { state, onRestoreDashboard };
};

const deleteDashboardAndPush = async (dashboard: any, condition: boolean) => {
  for (let i = 0; i < dashboard.panels.length; i++) {
    const panel = dashboard.panels[i];
    var pushData = await getCarbonPushData(panel.id, dashboard.id);
    var pullData = await getCarbonPullData(panel.id, dashboard.id);
    var alerts = await getAlertRuleList(panel, dashboard);
    await deleteData(pushData, pullData, alerts);
  }
  const result = deleteDashboard(dashboard.uid, condition);
  return result;
};
