import { PanelPlugin } from '@grafana/data';
import { ChartRaceOptions } from './types';
import ChartRacePanel from './ChartRacePanel';

// export const plugin = new PanelPlugin<ChartRaceOptions>(ChartRacePanel).setPanelOptions(builder => {
export const plugin = new PanelPlugin<ChartRaceOptions>(ChartRacePanel)
  .useFieldConfig()
  .setPanelOptions((builder) => {
    builder
      .addNumberInput({
        path: 'runningQueries',
        name: 'Bars Running',
        description: 'Select the number of Bars Running.',
        defaultValue: 10,
        settings: {
          // placeholder: '10000',
          integer: true,
          min: 0,
          max: 12,
        },
      })
      .addSelect({
        path: 'legendBottom',
        name: 'Legend Data',
        description: 'Adjust DateTime of the legend showing on bottom right corner.',
        settings: {
          options: [
            { value: 'toString()', label: 'Day&Time' },
            { value: 'toLocaleTimeString()', label: 'Time' },
            { value: 'toDateString()', label: 'Date' },
            { value: 'toLocaleString()', label: 'Date&Time' },
            { value: 'getFullYear()', label: 'Year' },
            { value: 'getSomething()', label: 'Month' },
          ],
        },
        defaultValue: 'full',
      })
      .addNumberInput({
        path: 'legendSize',
        name: 'Legend Size',
        description: 'Adjust the size like 1,2,3... etc',
        defaultValue: 3,
        settings: {
          // placeholder: '10000',
          // integer: true,
          min: 0,
          max: 10,
        },
      })
      .addSelect({
        path: 'pallet',
        name: 'Pallets',
        description: 'Select a theme for the bars.',
        settings: {
          options: [
            { value: 'schemeTableau10', label: 'Pallet1' },
            { value: 'schemeCategory10', label: 'Pallet2' },
            { value: 'schemeAccent', label: 'Pallet3' },
            { value: 'schemeDark2', label: 'Pallet4' },
            { value: 'schemePaired', label: 'Pallet5' },
            { value: 'schemePastel1', label: 'Pallet6' },
            { value: 'schemePastel2', label: 'Pallet7' },
            { value: 'schemeSet1', label: 'Pallet8' },
            { value: 'schemeSet2', label: 'Pallet9' },
            { value: 'schemeSet3', label: 'Pallet10' },
          ],
        },
        defaultValue: 'schemeTableau10',
      })
      .addSelect({
        path: 'speed',
        name: 'Bar Speed',
        // description: 'Select a theme for the bars.',
        settings: {
          options: [
            { value: '10', label: '10ms' },
            { value: '20', label: '20ms' },
            { value: '30', label: '30ms' },
            { value: '50', label: '50ms' },
            { value: '100', label: '100ms' },
            { value: '200', label: '200ms' },
            { value: '250', label: '250ms' },
            { value: '500', label: '500ms' },
            { value: '700', label: '700ms' },
            { value: '1000', label: '1000ms' },
            { value: '1200', label: '1200ms' },
            { value: '1500', label: '1500ms' },
            { value: '2000', label: '2000ms' },
            { value: '2500', label: '2500ms' },
            { value: '3000', label: '3000ms' },
          ],
        },
        defaultValue: '250',
      })
      .addBooleanSwitch({
        path: 'firstMinus',
        name: 'Difference',
        description: 'Subtracts first value of from other values available in the query.',
        defaultValue: false,
      })
      .addBooleanSwitch({
        path: 'cumulative',
        name: 'Cumulative Sum',
        description: 'Performs cumulative sum between queries.',
        defaultValue: false,
      })
      .addBooleanSwitch({
        path: 'order',
        name: 'Reverse Order',
        description:
          'When turned off, performs Difference first then Cumulative Sum.' +
          'Turning it on will perform Cumulative Sum first and then the Difference',
        defaultValue: false,
      })

      .addBooleanSwitch({
        path: 'useCsv',
        name: 'External CSV',
        description: 'Toggle to use external CSV and provide its URL.',
        defaultValue: false,
      })
      .addTextInput({
        path: 'csvUrl',
        name: 'CSV Url',
        description:
          'Provide URL to the CSV file. CSV file must contain columns labelling date,name,category and value respectivily.',
        defaultValue: '',
        showIf: (options: ChartRaceOptions) => options.useCsv,
      });
  })
  .setNoPadding();
