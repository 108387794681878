import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const AlertRules: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 64 64"
      viewBox="0 0 64 64"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M19.9,17.4h38.7c1.3,0,2.4-1.1,2.4-2.4s-1.1-2.4-2.4-2.4H19.9c-1.3,0-2.4,1.1-2.4,2.4S18.6,17.4,19.9,17.4z
        M58.6,29.5H19.9c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4h38.7c1.3,0,2.4-1.1,2.4-2.4C61,30.6,60,29.5,58.6,29.5z M58.6,46.5
        H19.9c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4h38.7c1.3,0,2.4-1.1,2.4-2.4C61,47.6,60,46.5,58.6,46.5z M7.8,10.2
        c2.7,0,4.8,2.2,4.8,4.8s-2.2,4.8-4.8,4.8S3,17.7,3,15S5.1,10.2,7.8,10.2z M7.8,27.1c2.7,0,4.8,2.2,4.8,4.8s-2.2,4.8-4.8,4.8
        S3,34.6,3,32S5.1,27.1,7.8,27.1z M7.8,44.1c2.7,0,4.8,2.2,4.8,4.8c0,2.7-2.2,4.8-4.8,4.8S3,51.6,3,48.9C3,46.2,5.1,44.1,7.8,44.1z"
      />
    </svg>
  );
};
