export function getPasswordStrengthLvl(password: string | undefined): { level: string; isWeek: boolean } {
  if (password === undefined) {
    return { level: 'week', isWeek: false };
  }
  let strength = 0;
  if (password.match(/[a-zA-Z]+/)) {
    strength += 1;
  }
  if (password.match(/[0-9]+/)) {
    strength += 1;
  }
  if (password.match(/[!@#$%^&*(),.?":{}|<>]+/)) {
    strength += 1;
  }
  if (password.includes(' ')) {
    return { level: 'week', isWeek: false };
  }
  if (password.length <= 4 && strength === 3) {
    return { level: 'week', isWeek: false };
  } else if (password.length >= 6 && password.length <= 10 && strength === 3) {
    return { level: 'good', isWeek: true };
  } else if (password.length >= 10 && strength === 3) {
    return { level: 'strong', isWeek: true };
  } else {
    return { level: 'week', isWeek: false };
  }
}
