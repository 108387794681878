import { CartesianCoords2D, DataFrame, TimeZone, getValueFormat } from '@grafana/data';
import React, { useLayoutEffect, useState } from 'react';
import { useMountedState } from 'react-use';
import uPlot from 'uplot';
import {
  Portal,
  VizTooltipContainer,
  SeriesTable,
  SeriesTableRowProps,
  findMidPointYPosition,
  UPlotConfigBuilder,
} from '@grafana/ui';
import { BoxPlotData } from '../types';

/**
 * tooltip plugin props
 * @typedef {Object} TooltipPluginBoxProps
 * @property {TimeZone} timezone timezone
 * @property {DataFrame} data data frame for tooltip data
 * @property {UPlotConfigBuilder} config config to see tooltip enable or not setting up hook
 * @property {string} [mode] tooltip mode
 * @property {BoxPlotData} [dataMain]
 * @property {string} plotType
 * @property {string} boxxPlotCateg
 */
interface TooltipPluginBoxProps {
  timeZone: TimeZone;
  data: DataFrame;
  config: UPlotConfigBuilder;
  mode?: string;
  dataMain?: BoxPlotData;
  plotType: string;
  boxPlotCateg: string;
}
/**
 * @constant
 * @default
 */
const TOOLTIP_OFFSET = 10;

/**
 * tooltip component
 * @function
 * @param {TooltipPluginBoxProps} props
 * @returns {JSX.Element}
 */

export const TooltipPluginBox: React.FC<TooltipPluginBoxProps> = ({ mode, timeZone, config, ...otherProps }) => {
  const [focusedSeriesIdx, setFocusedSeriesIdx] = useState<number | null>(null);
  const [focusedPointIdx, setFocusedPointIdx] = useState<number | null>(null);
  const [coords, setCoords] = useState<CartesianCoords2D | null>(null);
  // const [isActive, setIsActive] = useState<boolean>(false);
  const isMounted = useMountedState();
  // Add uPlot hooks to the config, or re-add when the config changed
  useLayoutEffect(() => {
    let plotInstance: uPlot | undefined = undefined;
    let bbox: DOMRect | undefined = undefined;

    const plotMouseLeave = () => {
      if (!isMounted()) {
        return;
      }
      setCoords(null);
      // setIsActive(false);
      plotInstance?.root.classList.remove('plot-active');
    };

    const plotMouseEnter = () => {
      if (!isMounted()) {
        return;
      }
      // setIsActive(true);
      plotInstance?.root.classList.add('plot-active');
    };

    // cache uPlot plotting area bounding box
    config.addHook('syncRect', (u: uPlot, rect: any) => {
      bbox = rect;
    });

    config.addHook('init', (u: uPlot) => {
      plotInstance = u;

      u.over.addEventListener('mouseleave', plotMouseLeave);
      u.over.addEventListener('mouseenter', plotMouseEnter);
    });

    const tooltipPlugin = config.getShowTooltip();
    if (tooltipPlugin) {
      config.addHook('setCursor', (u: uPlot) => {
        tooltipPlugin(
          setFocusedSeriesIdx,
          setFocusedPointIdx,
          (clear: any) => {
            if (clear) {
              setCoords(null);
              return;
            }

            if (!bbox) {
              return;
            }

            const { x, y } = positionTooltip(u, bbox);
            if (x !== undefined && y !== undefined) {
              setCoords({ x, y });
            }
          },
          u
        );
      });
    } else {
      config.addHook('setLegend', (u: uPlot) => {
        if (!isMounted()) {
          return;
        }
        setFocusedPointIdx(u.legend.idx!);
      });

      // default series/datapoint idx retireval
      config.addHook('setCursor', (u: uPlot) => {
        if (!bbox || !isMounted()) {
          return;
        }

        const { x, y } = positionTooltip(u, bbox);
        if (x !== undefined && y !== undefined) {
          setCoords({ x, y });
        } else {
          setCoords(null);
        }
      });

      config.addHook('setSeries', (_: any, idx: number) => {
        if (!isMounted()) {
          return;
        }
        setFocusedSeriesIdx(idx);
      });
    }

    return () => {
      setCoords(null);
      if (plotInstance) {
        plotInstance.over.removeEventListener('mouseleave', plotMouseLeave);
        plotInstance.over.removeEventListener('mouseenter', plotMouseEnter);
      }
    };
  }, [config, setCoords, setFocusedPointIdx]);

  if (focusedPointIdx === null) {
    return null;
  }
  const xValModifiedBoxTime = fmtDate(otherProps.dataMain?.boxTime?.time?.values[focusedPointIdx]);

  let tooltip: React.ReactNode = null;
  /**
   * get unit from fields. used in calculation of value according to it
   */
  const unitGet = otherProps.data.fields[0].config.unit;
  /**
   * gives value after formatting
   */
  const formatFunc = getValueFormat(unitGet || 'none');
  /**
   * normal box plot query based
   */
  if (
    mode === 'single' &&
    focusedSeriesIdx !== null &&
    otherProps.plotType === 'boxplot' &&
    otherProps.boxPlotCateg === 'normal'
  ) {
    const boxObj = otherProps.dataMain?.box;
    const field = otherProps.data.fields[focusedSeriesIdx];
    if (!field && !boxObj) {
      return null;
    }
    /**
     * Preparing series table row props for tooltip. Not passing color. Label is fixed.
     * If will see main tooltip it uses for loop here
     */
    let series2: SeriesTableRowProps[] = [];
    series2.push({
      color: '',
      label: 'Maximum: ',
      value: `${formatFunc(boxObj?.max?.values[focusedPointIdx]!, 2, null).text}${
        formatFunc(boxObj?.max?.values[focusedPointIdx]!, 2, null).suffix ?? ''
      }`,
    });
    series2.push({
      color: '',
      label: 'Q3: ',
      value: `${formatFunc(boxObj?.q3?.values[focusedPointIdx]!, 2, null).text}${
        formatFunc(boxObj?.q3?.values[focusedPointIdx]!, 2, null).suffix ?? ''
      }`,
    });
    series2.push({
      color: '',
      label: 'Median: ',
      value: `${formatFunc(boxObj?.median?.values[focusedPointIdx]!, 2, null).text}${
        formatFunc(boxObj?.median?.values[focusedPointIdx]!, 2, null).suffix ?? ''
      }`,
    });
    series2.push({
      color: '',
      label: 'Q1: ',
      value: `${formatFunc(boxObj?.q1?.values[focusedPointIdx]!, 2, null).text}${
        formatFunc(boxObj?.q1?.values[focusedPointIdx]!, 2, null).suffix ?? ''
      }`,
    });
    series2.push({
      color: '',
      label: 'Minimum: ',
      value: `${formatFunc(boxObj?.min?.values[focusedPointIdx]!, 2, null).text}${
        formatFunc(boxObj?.min?.values[focusedPointIdx]!, 2, null).suffix ?? ''
      }`,
    });

    tooltip = <SeriesTable series={series2} timestamp={boxObj?.queryName?.values[focusedPointIdx]!} />;
  }
  /**
   * time based box plot
   */
  if (
    mode === 'single' &&
    focusedSeriesIdx !== null &&
    otherProps.plotType === 'boxplot' &&
    otherProps.boxPlotCateg === 'withtime'
  ) {
    const boxTimeObj = otherProps.dataMain?.boxTime;
    if (!boxTimeObj) {
      return null;
    }
    let series2: SeriesTableRowProps[] = [];
    series2.push({
      color: '',
      label: 'Maximum: ',
      value: `${formatFunc(boxTimeObj.max?.values[focusedPointIdx]!, 2, null).text}${
        formatFunc(boxTimeObj.max?.values[focusedPointIdx]!, 2, null).suffix ?? ''
      }`,
    });
    series2.push({
      color: '',
      label: 'Q3: ',
      value: `${formatFunc(boxTimeObj.q3?.values[focusedPointIdx]!, 2, null).text}${
        formatFunc(boxTimeObj.q3?.values[focusedPointIdx]!, 2, null).suffix ?? ''
      }`,
    });
    series2.push({
      color: '',
      label: 'Median: ',
      value: `${formatFunc(boxTimeObj.median?.values[focusedPointIdx]!, 2, null).text}${
        formatFunc(boxTimeObj.median?.values[focusedPointIdx]!, 2, null).suffix ?? ''
      }`,
    });
    series2.push({
      color: '',
      label: 'Q1: ',
      value: `${formatFunc(boxTimeObj.q1?.values[focusedPointIdx]!, 2, null).text}${
        formatFunc(boxTimeObj.q1?.values[focusedPointIdx]!, 2, null).suffix ?? ''
      }`,
    });
    series2.push({
      color: '',
      label: 'Minimum: ',
      value: `${formatFunc(boxTimeObj.min?.values[focusedPointIdx]!, 2, null).text}${
        formatFunc(boxTimeObj.min?.values[focusedPointIdx]!, 2, null).suffix ?? ''
      }`,
    });

    tooltip = <SeriesTable series={series2} timestamp={xValModifiedBoxTime} />;
  }

  return (
    <Portal>
      {tooltip && coords && (
        <VizTooltipContainer position={{ x: coords.x, y: coords.y }} offset={{ x: TOOLTIP_OFFSET, y: TOOLTIP_OFFSET }}>
          {tooltip}
        </VizTooltipContainer>
      )}
    </Portal>
  );
};

function isCursourOutsideCanvas({ left, top }: uPlot.Cursor, canvas: DOMRect) {
  if (left === undefined || top === undefined) {
    return false;
  }
  return left < 0 || left > canvas.width || top < 0 || top > canvas.height;
}

export function positionTooltip(u: uPlot, bbox: DOMRect) {
  let x, y;
  const cL = u.cursor.left || 0;
  const cT = u.cursor.top || 0;

  if (isCursourOutsideCanvas(u.cursor, bbox)) {
    const idx = u.posToIdx(cL);
    // when cursor outside of uPlot's canvas
    if (cT < 0 || cT > bbox.height) {
      let pos = findMidPointYPosition(u, idx);

      if (pos) {
        y = bbox.top + pos;
        if (cL >= 0 && cL <= bbox.width) {
          // find x-scale position for a current cursor left position
          x = bbox.left + u.valToPos(u.data[0][u.posToIdx(cL)], u.series[0].scale!);
        }
      }
    }
  } else {
    x = bbox.left + cL;
    y = bbox.top + cT;
  }

  return { x, y };
}
export function fmtDate(ts: any) {
  const d = new Date(ts);

  if (!isNaN(d.getTime())) {
    let text = d.toLocaleString().replace(',', '');
    return text;
  }
  return ts;
}
