import React from 'react';

import { PanelProps, FieldDisplay, getFieldDisplayValues, FALLBACK_COLOR } from '@grafana/data';
import { config } from 'app/core/config';
import { RadarPanelOptions, DataToUse } from './types';
import { RadarChart } from './RadarChart';
/**
 * @typedef {Object} RadarPanelProps
 * @extends {PanelProps<RadarPanelOptions>}
 */
interface RadarPanelProps extends PanelProps<RadarPanelOptions> {}
// structure of radar chart and pie chart is almost same
/**
 * radar chart panel
 * @param {RadarPanelProps} props
 * @returns {JSX.Element}
 *
 * @mermaid
 *  graph LR;
 *      A(first run) --> B[Add at least three Queries];
 *      B --> C[Add Group Or Groups]
 *      C --> D[Provide Name for group or groups]
 *      D --> E[add multi query in select box]
 */
export function RadarPanel(props: RadarPanelProps) {
  const localObject: any = {};
  // Generate variables for group name editing
  const { data, replaceVariables, fieldConfig, timeZone, height, width, options } = props;

  const getValues = (): FieldDisplay[] => {
    return getFieldDisplayValues({
      fieldConfig,
      reduceOptions: options.reduceOptions,
      replaceVariables,
      theme: config.theme,
      data: data.series,
      timeZone,
    });
  };

  const bigData: FieldDisplay[] = getValues();

  if (bigData.length > 0) {
    for (let i = 0; i < bigData.length; i++) {
      localObject[bigData[i].display?.title!] = {
        title: bigData[i].display.title,
        numeric: +bigData[i].display.numeric.toFixed(0),
        prefix: bigData[i].display.prefix,
        suffix: bigData[i].display.suffix,
        text: bigData[i].display.text,
        color: bigData[i].display.color,
      };
    }
  }
  // generating main radar data
  let mainDataToUse: DataToUse[] = [];

  const { groupData } = options;
  if (groupData.length) {
    groupData.map((group: any) => {
      const processedData: any = [];
      const processedDataStr: any = [];
      group.query.map((item: any) => {
        localObject[item] && processedData.push(localObject[item].numeric);
        localObject[item] &&
          processedDataStr.push(
            `${localObject[item].prefix || ''}${localObject[item].text}${localObject[item].suffix || ''}`
          );
      });
      mainDataToUse.push({
        name: group.name,
        data: processedData,
        dataStr: processedDataStr,
        stroke: localObject[group.query[0]!]?.color ?? FALLBACK_COLOR,
      });
    });
  }

  if (mainDataToUse.length <= 0 || mainDataToUse[0].data.length <= 0) {
    return (
      <div className="panel-empty">
        <p>Add at least one group</p>
      </div>
    );
  }
  return <RadarChart width={width} height={height} newData={mainDataToUse} options={options} />;
}
