import { XYFieldMatchers } from '@grafana/ui/src/components/GraphNG2/types';
import {
  DataFrame,
  FALLBACK_COLOR,
  Field,
  FieldColorModeId,
  FieldConfig,
  FieldType,
  formattedValueToString,
  getFieldDisplayName,
  getValueFormat,
  GrafanaTheme,
  outerJoinDataFrames,
  ThresholdsMode,
  getColorForTheme,
  ScaleDirection,
  ScaleOrientation,
  AxisPlacement,
  VizLegendOptions,
} from '@grafana/data';
import { UPlot_UNIT, UPlotConfigBuild, UPlotConfigPrep, UPlotLegendItem } from '@grafana/ui';
import { getConfig, TimelineCoreOptions } from './helper';
import { TimelineFieldConfig, TimelineOptions } from '../types';
import { PlotTooltipInterpolator } from '@grafana/ui/src/components/uPlot/types';
import { preparePlotDataUplot2 } from '@grafana/ui/src/components/uPlot/utils';

const defaultConfig: TimelineFieldConfig = {
  lineWidth: 0,
  fillOpacity: 80,
};

export function preparePlotFrame(data: DataFrame[], dimFields: XYFieldMatchers) {
  return outerJoinDataFrames({
    frames: data,
    joinBy: dimFields.x,
    keep: dimFields.y,
    keepOriginIndices: true,
  });
}

export const preparePlotConfigBuilder: UPlotConfigPrep<TimelineOptions> = ({
  frame,
  theme,
  timeZone,
  getTimeRange,
  mode,
  rowHeight,
  colWidth,
  yAxisShowHide,
  showValue,
  alignValue,
}) => {
  const isDiscrete = (field: Field) => {
    const mode = field.config?.color?.mode;
    return !(mode && field.display && mode.startsWith('continuous-'));
  };

  const getValueColor = (seriesIdx: number, value: any) => {
    const field = frame.fields[seriesIdx];

    if (field.display) {
      const disp = field.display(value);
      if (disp.color) {
        return disp.color;
      }
    }

    return FALLBACK_COLOR;
  };

  const opt: TimelineCoreOptions = {
    mode: mode,
    numSeries: frame.fields.length - 1,
    isDiscrete: (seriesIdx) => isDiscrete(frame.fields[seriesIdx]),
    rowHeight: rowHeight!,
    colWidth: colWidth,
    showValue: showValue!,
    alignValue,
    theme,
    yAxisShowHide: yAxisShowHide,
    label: (seriesIdx) => getFieldDisplayName(frame.fields[seriesIdx], frame),
    getFieldConfig: (seriesIdx) => frame.fields[seriesIdx].config.custom,
    getValueColor,
    getTimeRange,
    formatValue: (seriesIdx, value) => formattedValueToString(frame.fields[seriesIdx].display!(value)),
    onHover: (seriesIndex, valueIndex) => {
      hoveredSeriesIdx = seriesIndex;
      hoveredDataIdx = valueIndex;
      shouldChangeHover = true;
    },
    onLeave: () => {
      hoveredSeriesIdx = null;
      hoveredDataIdx = null;
      shouldChangeHover = true;
    },
  };

  let shouldChangeHover = false;
  let hoveredSeriesIdx: number | null = null;
  let hoveredDataIdx: number | null = null;

  const coreConfig = getConfig(opt);

  const builder = new UPlotConfigBuild(timeZone);

  builder.addHook('init', coreConfig.init);
  builder.addHook('drawClear', coreConfig.drawClear);
  builder.addHook('setCursor', coreConfig.setCursor);

  const interpolateTooltip: PlotTooltipInterpolator = (
    updateActiveSeriesIdx,
    updateActiveDatapointIdx,
    updateTooltipPosition
  ) => {
    if (shouldChangeHover) {
      if (hoveredSeriesIdx != null) {
        updateActiveSeriesIdx(hoveredSeriesIdx);
        updateActiveDatapointIdx(hoveredDataIdx);
      }

      shouldChangeHover = false;
    }

    updateTooltipPosition(hoveredSeriesIdx == null);
  };

  builder.setTooltipInterpolator(interpolateTooltip);

  builder.setPrepData(preparePlotDataUplot2);

  builder.setCursor(coreConfig.cursor);

  builder.addScale({
    scaleKey: 'x',
    isTime: true,
    orientation: ScaleOrientation.Horizontal,
    direction: ScaleDirection.Right,
    range: coreConfig.xRange,
  });

  builder.addScale({
    scaleKey: UPlot_UNIT,
    isTime: false,
    orientation: ScaleOrientation.Vertical,
    direction: ScaleDirection.Up,
    range: coreConfig.yRange,
  });

  builder.addAxis({
    scaleKey: 'x',
    isTime: true,
    splits: coreConfig.xSplits!,
    placement: AxisPlacement.Bottom,
    timeZone,
    theme,
    grid: true,
  });

  builder.addAxis({
    scaleKey: UPlot_UNIT,
    isTime: false,
    placement: AxisPlacement.Left,
    splits: coreConfig.ySplits,
    values: coreConfig.yValues,
    grid: false,
    ticks: false,
    gap: 16,
    theme,
  });

  let seriesIndex = 0;
  frame.fields.forEach((field: Field, idx: number) => {
    if (idx === 0) {
      return;
    }
    const config = field.config as FieldConfig<TimelineFieldConfig>;
    const mergeConfig: TimelineFieldConfig = {
      ...defaultConfig,
      ...config.custom,
    };

    field.state!.seriesIndex = seriesIndex++;

    builder.addSeries({
      scaleKey: UPlot_UNIT,
      pathBuilder: coreConfig.drawPaths,
      pointsBuilder: coreConfig.drawPoints,
      lineWidth: mergeConfig.lineWidth,
      fillOpacity: mergeConfig.fillOpacity,
      theme,
      show: !mergeConfig.hideFrom?.viz,
      thresholds: config.thresholds,
      dataFrameFieldIndex: field.state?.origin,
    });
  });

  return builder;
};

export function getTimelineData(series: DataFrame[] | undefined): { frames?: DataFrame[]; err?: string } {
  if (!series?.length) {
    return { err: 'No data present' };
  }
  const frames: DataFrame[] = [];
  let TsOrTb = false; // Check whether timeseries or table
  series.forEach((frame: DataFrame) => {
    let includeTime = false;
    const fields: Field[] = [];
    let updated = false;
    frame.fields.forEach((frameField: Field) => {
      if (frameField.type === FieldType.time) {
        includeTime = true;
        TsOrTb = true;
        fields.push(frameField);
      } else if (frameField.type === FieldType.number) {
        frameField = {
          ...frameField,
          config: {
            ...frameField.config,
            custom: {
              ...frameField.config.custom,
              spanNulls: -1,
            },
          },
        };
        fields.push(frameField);
      } else {
        updated = true;
      }
    });
    if (includeTime && fields.length > 1) {
      TsOrTb = true;
      if (updated) {
        frames.push({
          ...frame,
          fields,
        });
      } else {
        frames.push(frame);
      }
    }
  });
  if (!TsOrTb) {
    return { err: `Data doesn't include timeseries` };
  }
  if (!frames.length) {
    return { err: 'Not sufficient data to generate graph' };
  }
  return { frames };
}

const getFirstFieldConfig = (frames: DataFrame[]): FieldConfig => {
  let config: FieldConfig = {};
  frames[0].fields.forEach((field: Field) => {
    if (field.type === FieldType.number) {
      config = field.config;
    }
  });
  return config;
};

const OthercolorModelegendItems = (frames: DataFrame[]) => {
  const legendvalue: UPlotLegendItem[] = [];
  let old: string;
  frames?.forEach((item, i) => {
    item.fields?.forEach((field: Field) => {
      if (field.type === FieldType.number) {
        if (legendvalue.length !== 0 && legendvalue[0].label === field.name && i - 1 >= 0) {
          old = field.name + ' ' + (i += 1);
        } else {
          old = field.name;
        }
        const color = field.display!(field.values.get(i)).color;
        legendvalue.push({
          label: `${old}`,
          color: color,
          yAxis: 1,
        });
      }
    });
  });
  return legendvalue;
};

export function getLegendItems(
  frames: DataFrame[] | undefined,
  options: VizLegendOptions,
  theme: GrafanaTheme
): UPlotLegendItem[] | undefined {
  if (!frames || options.displayMode === 'hidden') {
    return undefined;
  }
  const config = getFirstFieldConfig(frames);
  const colorMode = config.color?.mode ?? FieldColorModeId.Fixed;

  //*When choosing other color-palette.
  if (colorMode !== FieldColorModeId.Thresholds) {
    const legend = OthercolorModelegendItems(frames);
    return legend;
  }

  const items: UPlotLegendItem[] = [];
  const thresholds = config.thresholds;
  if (thresholds && thresholds?.steps && thresholds.steps.length > 1) {
    const steps = thresholds.steps;
    const display = getValueFormat(thresholds.mode === ThresholdsMode.Percentage ? 'percent' : config.unit ?? '');
    const formatter = (v: number) => formattedValueToString(display(v));
    for (let i = 1; i <= steps.length; i++) {
      const step = steps[i - 1];
      items.push({
        label: i === 1 ? `< ${formatter(step.value)}` : `${formatter(step.value)}+`,
        color: getColorForTheme(step.color, theme),
        yAxis: 1,
      });
    }
  }
  return items;
}

export function getNextIndex(field: Field, dataIdx: number) {
  let end;
  let rightPoint = dataIdx + 1;

  if (rightPoint >= field.values.length) {
    return null;
  }

  while (end === undefined) {
    if (rightPoint >= field.values.length) {
      return null;
    }
    const rightValue = field.values.get(rightPoint);

    if (rightValue !== undefined) {
      end = rightPoint;
    } else {
      rightPoint++;
    }
  }

  return end;
}
