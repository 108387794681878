import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const Cog: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 64 64"
      viewBox="0 0 64 64"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M32,22.5c-5.2,0-9.5,4.2-9.5,9.5s4.2,9.5,9.5,9.5s9.5-4.2,9.5-9.5S37.2,22.5,32,22.5z M32,36.7
        c-2.6,0-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7s4.7,2.1,4.7,4.7S34.6,36.7,32,36.7z M54,36.5L53,35.9c0.5-2.6,0.5-5.2,0-7.8l1.1-0.6
        c3.4-2,4.6-6.3,2.6-9.7c-2-3.4-6.3-4.6-9.7-2.6l-1.1,0.6c-2-1.7-4.3-3-6.7-3.9v-1.2c0-3.9-3.2-7.1-7.1-7.1s-7.1,3.2-7.1,7.1v1.2
        c-2.5,0.9-4.8,2.2-6.7,3.9l-1.1-0.6c-3.4-2-7.7-0.8-9.7,2.6s-0.8,7.7,2.6,9.7l0,0l1.1,0.6c-0.5,2.6-0.5,5.2,0,7.8L10,36.5
        c-3.4,2-4.6,6.3-2.6,9.7c2,3.4,6.3,4.6,9.7,2.6l0,0l1.1-0.6c2,1.7,4.3,3,6.7,3.9v1.2c0,3.9,3.2,7.1,7.1,7.1s7.1-3.2,7.1-7.1v-1.2
        c2.5-0.9,4.8-2.2,6.7-3.9l1.1,0.6c3.4,2,7.7,0.8,9.7-2.6C58.6,42.8,57.4,38.4,54,36.5L54,36.5L54,36.5z M48,27.6
        c0.8,2.9,0.8,6,0,8.9c-0.3,1,0.2,2.1,1.1,2.7l2.6,1.5c1.1,0.7,1.5,2.1,0.9,3.2c-0.7,1.1-2.1,1.5-3.2,0.9l0,0l-2.6-1.5
        c-0.9-0.5-2.1-0.4-2.9,0.4c-2.1,2.2-4.8,3.7-7.7,4.4c-1,0.3-1.8,1.2-1.8,2.3v3c0,1.3-1.1,2.4-2.4,2.4c-1.3,0-2.4-1.1-2.4-2.4v-3
        c0-1.1-0.7-2-1.8-2.3c-2.9-0.8-5.6-2.3-7.7-4.4c-0.8-0.8-1.9-0.9-2.9-0.4l-2.6,1.5c-1.1,0.7-2.6,0.3-3.2-0.8
        c-0.7-1.1-0.3-2.6,0.8-3.2c0,0,0,0,0,0l2.6-1.5c0.9-0.5,1.4-1.6,1.1-2.7c-0.8-2.9-0.8-6,0-8.9c0.3-1-0.2-2.1-1.1-2.7l-2.6-1.5
        c-1.1-0.7-1.5-2.1-0.9-3.2c0.7-1.1,2.1-1.5,3.2-0.9l2.6,1.5c0.9,0.5,2.1,0.4,2.9-0.4c2.1-2.2,4.8-3.7,7.7-4.4
        c1.1-0.3,1.8-1.2,1.8-2.3v-3c0-1.3,1.1-2.4,2.4-2.4c1.3,0,2.4,1.1,2.4,2.4v3c0,1.1,0.7,2,1.8,2.3c2.9,0.8,5.6,2.3,7.7,4.4
        c0.8,0.8,1.9,0.9,2.9,0.4l2.6-1.5c1.1-0.7,2.6-0.3,3.2,0.8c0.7,1.1,0.3,2.6-0.8,3.2c0,0,0,0,0,0l-2.6,1.5
        C48.1,25.4,47.7,26.5,48,27.6L48,27.6z"
      />
    </svg>
  );
};
