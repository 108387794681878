import {
  DataTransformerInfo,
  DataTransformerID,
  DataFrame,
  AppEvents,
  ArrayVector,
  FieldType,
  MutableDataFrame,
} from '@grafana/data';
import appEvents from 'app/core/app_events';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { map } from 'rxjs/operators';

export type CustomTranformationOptions = {
  isTimeSeries: boolean;
  timeField: string | undefined;
  codeString: string | undefined;
};

export function dataToSendAfterExecutingJSCode(data: DataFrame[], options: CustomTranformationOptions): DataFrame[] {
  // Check if data is an array and not empty
  if (!Array.isArray(data) || data.length === 0) {
    return data;
  }

  // Check if there is more than one series in the data
  if (data.length > 1) {
    appEvents.emit(AppEvents.alertError, ['There should not be more than one series in the data.']);
    return data;
  }

  // Validate and execute user-provided code
  try {
    // Create a new Function from the user-provided code
    if (options.codeString === undefined || options.codeString === '') {
      return data;
    }
    //passed moment-timezone lib to the custom JS editor transform.
    const userFunction = new Function(
      'dataframe',
      'MutableDataFrame',
      'ArrayVector',
      'FieldType',
      'cloneDeep',
      'moment',
      options.codeString
    );

    // Execute the user-provided function with the first dataframe in the data array
    const manipulatedDataframe = userFunction(data[0], MutableDataFrame, ArrayVector, FieldType, cloneDeep, moment);

    // Return the manipulated dataframe as the first child in the series
    return [manipulatedDataframe];
  } catch (error) {
    appEvents.emit(AppEvents.alertError, ['An error occurred while executing the user-provided code:', error]);
    return data;
  }
}

export function prepareDataToSend(data: DataFrame[], options: CustomTranformationOptions): DataFrame[] {
  return dataToSendAfterExecutingJSCode(data, options);
}

export const CustomTransformationTransformer: DataTransformerInfo<CustomTranformationOptions> = {
  id: DataTransformerID.customTransformation,
  name: 'Custom Transformation',
  description: 'Transforms data by executing a user-provided typescript code.',
  defaultOptions: {},
  operator: (options) => (source) => source.pipe(map((data) => prepareDataToSend(data, options))),
};

// let resultDataFrame = new MutableDataFrame({
//   fields: cloneDeep(dataframe.fields),
//   length: dataframe.length,
//   meta: cloneDeep(dataframe.meta),
//   name: dataframe.name,
//   refId: dataframe.refId,
// });

// let solarField = "Solar Generated"
// let householdField = "House Light and Power"

// // Convert solar production to array
// let solarProductionArray = dataframe.fields.find(field => field.name === solarField)?.values.toArray();

// // Extract device fields from dataframe
// let deviceFields = dataframe.fields.filter(field => field.name !== solarField && field.name !== householdField && field.name !== "Solar Generated · Time");
// let deviceRequirements = deviceFields.map(field => ({
//   name: field.name,
//   requirements: field.values.toArray(),
//   solarImport: [],
//   gridImport: []
// }));

// let householdSolarImportArray = []; // Array to store solar import for each hour
// let householdGridImportArray = []; // Array to store grid import for each hour

// // Calculate solar and grid import for each hour
// solarProductionArray.forEach((solarProduction, hourIndex) => {
//   if (solarProduction === undefined) {
//     // If solar production is not defined for this hour, skip calculations
//     householdSolarImportArray.push(undefined);
//     householdGridImportArray.push(undefined);
//     deviceRequirements.forEach(device => {
//       device.solarImport.push(undefined);
//       device.gridImport.push(undefined);
//     });
//     return;
//   }

//   let remainingSolar = solarProduction;

//   // Distribute solar energy to the household first
//   let householdRequirement = dataframe.fields.find(field => field.name === householdField)?.values.get(hourIndex);
//   if (householdRequirement === undefined) {
//     // If household requirement is not defined for this hour, skip calculations
//     householdSolarImportArray.push(undefined);
//     householdGridImportArray.push(undefined);
//     deviceRequirements.forEach(device => {
//       device.solarImport.push(undefined);
//       device.gridImport.push(undefined);
//     });
//     return;
//   }

//   let householdSolarImport = Math.min(remainingSolar, householdRequirement);
//   remainingSolar -= householdSolarImport;
//   let householdGridImport = householdRequirement - householdSolarImport; // Calculate grid import for household

//   householdSolarImportArray.push(householdSolarImport); // Store solar import for this hour
//   householdGridImportArray.push(householdGridImport); // Store grid import for this hour

//   // Calculate total requirement of other devices
//   let totalOtherRequirement = deviceRequirements.reduce((total, device) => total + (device.requirements[hourIndex] || 0), 0);
//   let remainingSolarAfterHouseholdConsumption = remainingSolar;

//   // Distribute remaining solar energy to other devices based on their requirement percentage of the remaining solar energy
//   deviceRequirements.forEach(device => {
//     let deviceRequirement = device.requirements[hourIndex];
//     if (deviceRequirement === undefined) {
//       // If device requirement is not defined for this hour, skip calculations
//       device.solarImport.push(undefined);
//       device.gridImport.push(undefined);
//       return;
//     }

//     let deviceSolarImport = remainingSolar > 0 ? Math.min(remainingSolarAfterHouseholdConsumption * (deviceRequirement / totalOtherRequirement), deviceRequirement) : 0;
//     remainingSolar -= deviceSolarImport;
//     let deviceGridImport = deviceRequirement - deviceSolarImport;

//     // Store the calculated imports
//     device.solarImport.push(deviceSolarImport);
//     device.gridImport.push(deviceGridImport);
//   });
// });

// // Add household solar and grid import to the result
// resultDataFrame.addField({
//   name: `${householdField}_SolarImport`,
//   type: FieldType.number,
//   values: new ArrayVector(householdSolarImportArray) // Use the array of solar imports
// });
// resultDataFrame.addField({
//   name: `${householdField}_GridImport`,
//   type: FieldType.number,
//   values: new ArrayVector(householdGridImportArray) // Use the array of grid imports
// });

// // Append solar and grid import fields for each device to the result dataframe
// deviceRequirements.forEach(device => {
//   resultDataFrame.addField({
//     name: `${device.name}_SolarImport`,
//     type: FieldType.number,
//     values: new ArrayVector(device.solarImport)
//   });
//   resultDataFrame.addField({
//     name: `${device.name}_GridImport`,
//     type: FieldType.number,
//     values: new ArrayVector(device.gridImport)
//   });
// });

// return resultDataFrame;

//2nd case

// let resultDataFrame = new MutableDataFrame({
//   fields: cloneDeep(dataframe.fields),
//   length: dataframe.length,
//   meta: cloneDeep(dataframe.meta),
//   name: dataframe.name,
//   refId: dataframe.refId,
// });

// let solarField = "Solar Generated Total";
// let commonServiceField = "Common Service";
// let carChargerField = "Car Charger";

// // Convert solar production to array
// let solarProductionArray = dataframe.fields.find(field => field.name === solarField)?.values.toArray() || [];

// // Extract device fields from dataframe
// let deviceFields = dataframe.fields.filter(field => field.name !== solarField && field.name !== commonServiceField && field.name !== carChargerField && field.name !== "PV Generated · Time");
// let deviceRequirements = deviceFields.map(field => ({
//   name: field.name,
//   requirements: field.values.toArray(),
//   onpeakSolarImport: [],
//   onpeakGridImport: [],
//   offpeakSolarImport: [],
//   offpeakGridImport: []
// }));

// let commonServiceOnpeakSolarImportArray = [];
// let commonServiceOnpeakGridImportArray = [];
// let commonServiceOffpeakSolarImportArray = [];
// let commonServiceOffpeakGridImportArray = [];

// let carChargerOnpeakSolarImportArray = [];
// let carChargerOnpeakGridImportArray = [];
// let carChargerOffpeakSolarImportArray = [];
// let carChargerOffpeakGridImportArray = [];

// // Determine if a given date is on-peak
// function isOnPeak(date) {
//   let day = date.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
//   let hour = date.getHours();
//   return (day >= 1 && day <= 5 && hour >= 8 && hour < 22); // Monday to Friday 8am to 10pm
// }

// // Calculate solar and grid import for each hour
// let timestamps = dataframe.fields.find(field => field.name === "PV Generated · Time")?.values.toArray() || [];

// for (let hourIndex = 0; hourIndex < timestamps.length; hourIndex++) {
//   let timestamp = timestamps[hourIndex];
//   let dateInIST = new Date(timestamp);

//   // Calculate the total time difference between IST and AWST in milliseconds
//   // IST is UTC+5:30 and AWST is UTC+8, so the difference is 2 hours and 30 minutes
//   let timeDifference = (2 * 60 + 30) * 60000;

//   // Convert IST date to AWST by subtracting the time difference
//   let dateInAWST = new Date(dateInIST.getTime() + timeDifference);
//   let onPeak = isOnPeak(dateInAWST);

//   let solarProduction = solarProductionArray[hourIndex];
//   if (solarProduction === undefined) {
//     commonServiceOnpeakSolarImportArray.push(undefined);
//     commonServiceOnpeakGridImportArray.push(undefined);
//     commonServiceOffpeakSolarImportArray.push(undefined);
//     commonServiceOffpeakGridImportArray.push(undefined);

//     carChargerOnpeakSolarImportArray.push(undefined);
//     carChargerOnpeakGridImportArray.push(undefined);
//     carChargerOffpeakSolarImportArray.push(undefined);
//     carChargerOffpeakGridImportArray.push(undefined);

//     for (let device of deviceRequirements) {
//       device.onpeakSolarImport.push(undefined);
//       device.onpeakGridImport.push(undefined);
//       device.offpeakSolarImport.push(undefined);
//       device.offpeakGridImport.push(undefined);
//     }
//     continue;
//   }

//   let remainingSolar = solarProduction;

//   // Distribute solar energy to the Common Service first
//   let commonServiceRequirement = dataframe.fields.find(field => field.name === commonServiceField)?.values.get(hourIndex);
//   if (commonServiceRequirement === undefined) {
//     commonServiceOnpeakSolarImportArray.push(undefined);
//     commonServiceOnpeakGridImportArray.push(undefined);
//     commonServiceOffpeakSolarImportArray.push(undefined);
//     commonServiceOffpeakGridImportArray.push(undefined);
//   } else {
//     let commonServiceSolarImport = Math.min(remainingSolar, commonServiceRequirement);
//     remainingSolar -= commonServiceSolarImport;
//     let commonServiceGridImport = commonServiceRequirement - commonServiceSolarImport;

//     if (onPeak) {
//       commonServiceOnpeakSolarImportArray.push(commonServiceSolarImport);
//       commonServiceOnpeakGridImportArray.push(commonServiceGridImport);
//       commonServiceOffpeakSolarImportArray.push(0);
//       commonServiceOffpeakGridImportArray.push(0);
//     } else {
//       commonServiceOffpeakSolarImportArray.push(commonServiceSolarImport);
//       commonServiceOffpeakGridImportArray.push(commonServiceGridImport);
//       commonServiceOnpeakSolarImportArray.push(0);
//       commonServiceOnpeakGridImportArray.push(0);
//     }
//   }

//   // Distribute solar energy to the Car Charger next
//   let carChargerRequirement = dataframe.fields.find(field => field.name === carChargerField)?.values.get(hourIndex);
//   if (carChargerRequirement === undefined) {
//     carChargerOnpeakSolarImportArray.push(undefined);
//     carChargerOnpeakGridImportArray.push(undefined);
//     carChargerOffpeakSolarImportArray.push(undefined);
//     carChargerOffpeakGridImportArray.push(undefined);
//   } else {
//     let carChargerSolarImport = Math.min(remainingSolar, carChargerRequirement);
//     remainingSolar -= carChargerSolarImport;
//     let carChargerGridImport = carChargerRequirement - carChargerSolarImport;

//     if (onPeak) {
//       carChargerOnpeakSolarImportArray.push(carChargerSolarImport);
//       carChargerOnpeakGridImportArray.push(carChargerGridImport);
//       carChargerOffpeakSolarImportArray.push(0);
//       carChargerOffpeakGridImportArray.push(0);
//     } else {
//       carChargerOffpeakSolarImportArray.push(carChargerSolarImport);
//       carChargerOffpeakGridImportArray.push(carChargerGridImport);
//       carChargerOnpeakSolarImportArray.push(0);
//       carChargerOnpeakGridImportArray.push(0);
//     }
//   }

//   // Calculate total requirement of other devices
//   let totalOtherRequirement = deviceRequirements.reduce((total, device) => total + (device.requirements[hourIndex] || 0), 0);
//   let remainingSolarAfterPriorityConsumption = remainingSolar;
//   console.log("hi");
//   // Distribute remaining solar energy to other devices based on their requirement percentage of the remaining solar energy
//   for (let device of deviceRequirements) {
//     let deviceRequirement = device.requirements[hourIndex];
//     if (deviceRequirement === undefined) {
//       device.onpeakSolarImport.push(undefined);
//       device.onpeakGridImport.push(undefined);
//       device.offpeakSolarImport.push(undefined);
//       device.offpeakGridImport.push(undefined);
//     } else {
//       let deviceSolarImport = remainingSolar > 0 ? Math.min(remainingSolarAfterPriorityConsumption * (deviceRequirement / totalOtherRequirement), deviceRequirement) : 0;
//       remainingSolar -= deviceSolarImport;
//       let deviceGridImport = deviceRequirement - deviceSolarImport;
//       console.log(onPeak);
//       if (onPeak) {
//         device.onpeakSolarImport.push(deviceSolarImport);
//         device.onpeakGridImport.push(deviceGridImport);
//         device.offpeakSolarImport.push(0);
//         device.offpeakGridImport.push(0);
//       } else {
//         device.offpeakSolarImport.push(deviceSolarImport);
//         device.offpeakGridImport.push(deviceGridImport);
//         device.onpeakSolarImport.push(0);
//         device.onpeakGridImport.push(0);
//       }
//     }
//   }
// }

// // Add common service solar and grid import to the result
// resultDataFrame.addField({
//   name: `${commonServiceField}_OnpeakSolarImport`,
//   type: FieldType.number,
//   values: new ArrayVector(commonServiceOnpeakSolarImportArray) // Use the array of solar imports
// });
// resultDataFrame.addField({
//   name: `${commonServiceField}_OnpeakGridImport`,
//   type: FieldType.number,
//   values: new ArrayVector(commonServiceOnpeakGridImportArray) // Use the array of grid imports
// });
// resultDataFrame.addField({
//   name: `${commonServiceField}_OffpeakSolarImport`,
//   type: FieldType.number,
//   values: new ArrayVector(commonServiceOffpeakSolarImportArray) // Use the array of solar imports
// });
// resultDataFrame.addField({
//   name: `${commonServiceField}_OffpeakGridImport`,
//   type: FieldType.number,
//   values: new ArrayVector(commonServiceOffpeakGridImportArray) // Use the array of grid imports
// });

// // Add car charger solar and grid import to the result
// resultDataFrame.addField({
//   name: `${carChargerField}_OnpeakSolarImport`,
//   type: FieldType.number,
//   values: new ArrayVector(carChargerOnpeakSolarImportArray) // Use the array of solar imports
// });
// resultDataFrame.addField({
//   name: `${carChargerField}_OnpeakGridImport`,
//   type: FieldType.number,
//   values: new ArrayVector(carChargerOnpeakGridImportArray) // Use the array of grid imports
// });
// resultDataFrame.addField({
//   name: `${carChargerField}_OffpeakSolarImport`,
//   type: FieldType.number,
//   values: new ArrayVector(carChargerOffpeakSolarImportArray) // Use the array of solar imports
// });
// resultDataFrame.addField({
//   name: `${carChargerField}_OffpeakGridImport`,
//   type: FieldType.number,
//   values: new ArrayVector(carChargerOffpeakGridImportArray) // Use the array of grid imports
// });

// // Append solar and grid import fields for each device to the result dataframe
// for (let device of deviceRequirements) {
//   resultDataFrame.addField({
//     name: `${device.name}_OnpeakSolarImport`,
//     type: FieldType.number,
//     values: new ArrayVector(device.onpeakSolarImport)
//   });
//   resultDataFrame.addField({
//     name: `${device.name}_OnpeakGridImport`,
//     type: FieldType.number,
//     values: new ArrayVector(device.onpeakGridImport)
//   });
//   resultDataFrame.addField({
//     name: `${device.name}_OffpeakSolarImport`,
//     type: FieldType.number,
//     values: new ArrayVector(device.offpeakSolarImport)
//   });
//   resultDataFrame.addField({
//     name: `${device.name}_OffpeakGridImport`,
//     type: FieldType.number,
//     values: new ArrayVector(device.offpeakGridImport)
//   });
// }

// return resultDataFrame;
