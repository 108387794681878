import SensorType from './Sensor';
import { SingleStatBaseOptions } from '@grafana/ui';
import { ReducerID, standardEditorsRegistry, PanelOptionsEditorBuilder } from '@grafana/data';
type SeriesSize = 'sm' | 'md' | 'lg';

export interface SimpleOptions {
  imageUrl: string;
  lockSensors: boolean;
  sensorTextSize: SeriesSize;
  sensorData: {
    sensors: SensorType[];
    data: any;
    reload: number;
    drawerLinkTime: boolean;
  };
  toShowImg: string;
  dashVisit: boolean;
  minimum: number;
  maximum: number;
  fontStyle: string;
}

export function addStandardDataReduceOptions(
  builder: PanelOptionsEditorBuilder<SingleStatBaseOptions>,
  includeOrientation = true
) {
  builder.addCustomEditor({
    id: 'reduceOptions.calcs',
    path: 'reduceOptions.calcs',
    name: 'Value',
    description: 'Choose a reducer function / calculation',
    editor: standardEditorsRegistry.get('stats-picker').editor as any,
    defaultValue: [ReducerID.mean],
    // Hides it when all values mode is on
    // showIf: currentConfig => currentConfig.reduceOptions.values === false,
  });
}
