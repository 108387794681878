import React from 'react';
import { Button, MultiSelect, Input, InlineFormLabel, useTheme } from '@grafana/ui';
import { Group } from './types';
import { FieldDisplay, getFieldDisplayValues } from '@grafana/data';
import { cloneDeep } from 'lodash';

/**
 * props for custom editor in radar chart
 * @typedef {Object} Props
 * @property {Array<Group>} groupData
 * @property {any} dataAll
 * @property {function} onChange
 */
interface Props {
  groupData: Group[];
  dataAll: any;
  onChange: (groupData: Group[]) => void;
}

export const EditorGroupList: React.FC<Props> = (props: Props) => {
  //   const querySelect: any = [];
  const [queries, setQueries]: any = React.useState([]);

  const theme = useTheme();

  const { data, options, replaceVariables } = props.dataAll;
  const { fieldConfig } = options;
  const setoptions = cloneDeep(options.reduceOptions);
  delete setoptions.fields;
  delete setoptions.limit;
  delete setoptions.values;
  let newData: FieldDisplay[];

  const dataLoad = () => {
    if (data!.length > 0) {
      const getValues3 = (): FieldDisplay[] => {
        return getFieldDisplayValues({
          fieldConfig,
          reduceOptions: setoptions,
          replaceVariables,
          theme: theme,
          data: data!,
          sparkline: false,
          timeZone: 'browser',
        });
      };
      newData = getValues3();
    }
    const localQueries: any = [];
    newData?.map((ele: FieldDisplay) => {
      if (ele?.display?.title && ele.view?.dataFrame.fields[1].type === 'number') {
        localQueries.push({
          label: ele?.display?.title,
          value: ele?.display?.title,
        });
      }
    });
    setQueries(localQueries);
  };

  let groups = props.groupData;
  function addNewGroup() {
    groups.push({
      name: '',
      query: [],
    });
    props.onChange(groups);
  }
  function updateGroupName(value: string, index: number) {
    groups[index].name = value;
    props.onChange(groups);
  }
  function updateQueryList(value: any, index: number) {
    groups[index].query = [];
    value.map((ele: any) => {
      groups[index].query.push(ele.value);
    });
    props.onChange(groups);
  }
  function removeGroup(idx: number) {
    const newGroups = props.groupData.filter((item, index) => {
      return index !== idx;
    });
    props.onChange(newGroups);
  }

  return (
    <>
      {/* list of existing sensors */}
      {groups &&
        groups.map((group: Group, index: number) => {
          return (
            <div className="gf-form gf-form--grow" style={{ display: 'flex', flexDirection: 'column' }} key={index}>
              <div className="gf-form gf-form--grow">
                <label className="gf-form-label width-5">Name</label>
                <Input value={group.name} onChange={(e) => updateGroupName(e.currentTarget.value, index)} />
              </div>
              <div className="gf-form gf-form--grow">
                <InlineFormLabel width={7} className="gf-form-label">
                  Select Query
                </InlineFormLabel>
                <MultiSelect
                  noOptionsMessage="no options found try Reload Queries"
                  options={queries}
                  onChange={(target: any) => {
                    updateQueryList(target, index);
                  }}
                  value={group.query}
                />
              </div>

              <Button
                style={{ marginTop: '5px', marginBottom: '5px' }}
                variant="destructive"
                onClick={(event: any) => {
                  removeGroup(index);
                }}
                size="md"
              >
                Remove Group
              </Button>
            </div>
          );
        })}
      <Button style={{ marginTop: '5px', marginBottom: '5px' }} onClick={addNewGroup} variant="primary" size="md">
        Add New Group
      </Button>
      <div className="gf-form">
        <InlineFormLabel>Reload Queries</InlineFormLabel>
        <Button onClick={dataLoad} variant="secondary" size="md">
          <i className="fa fa-refresh" aria-hidden="true"></i>
        </Button>
      </div>
    </>
  );
};
