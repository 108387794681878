import { FieldColorModeId, LoadingState, dateTime } from '@grafana/data';
import { calculateTimeDifference } from './convertToPanelData';

/**
 * Determines the type of the input value.
 * @param {string} input - The input value to determine the type.
 * @returns {string} The type of the input value.
 */
export function getType(input: any) {
  if (+input || input === 0) {
    return 'number';
  } else if (Date.parse(input)) {
    return 'time';
  } else if (input === 'null') {
    return 'null';
  }

  return 'string';
}

/**
 * Converts a CSV string into panel data format.
 * @param {string} csvString - The CSV string to be converted.
 * @param {(e: any) => void} onUpdateTimeRange - The function to update the time range.
 * @param {boolean} shouldUpdateTime - A flag indicating whether the time range should be updated.
 * @returns {PanelData} The panel data object.
 */
export function csvStringToPanelData(csvString: string, onUpdateTimeRange?: (e: any) => void) {
  const rows = csvString.split('\r\n');
  const columnNames = rows[0].split(',');
  let columnValues: any = {}; //{columnName1: values, columnName2: values, ...}

  columnNames.forEach((columnName: any) => {
    columnValues[columnName] = [];
  });

  for (let i = 1; i < rows.length; i++) {
    const values = rows[i].split(',');

    for (let j = 0; j < values.length; j++) {
      const columnName = columnNames[j];
      columnValues[columnName].push(values[j]);
    }
  }

  const timeArray = columnValues['time'] ?? columnValues['Time'];

  const series: any = {
    length: timeArray.length,
    refId: 'A',
    fields: Object.keys(columnValues).map((key: string) => ({
      name: key,
      type: getType(columnValues[key][0]),
      config: {
        color: {
          mode: FieldColorModeId.PaletteClassic,
        },
      },
      values: {
        length: columnValues[key].length,
        get: (index: number) => columnValues[key][index],
        toArray: () => columnValues[key],
      },
    })),
  };
  const startTime = timeArray[0];
  const endTime = timeArray[timeArray.length - 1];
  const timeRange = {
    from: dateTime(startTime),
    to: dateTime(endTime),
    raw: {
      from: `now${calculateTimeDifference(startTime)}`,
      to: `now${calculateTimeDifference(endTime)}`,
    },
  };
  if (onUpdateTimeRange) {
    onUpdateTimeRange(timeRange);
  }
  const state = LoadingState.Done;
  return { state, series: [series], timeRange };
}
