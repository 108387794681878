import { DataQuery, ReducerID } from '@grafana/data';
import { evalFunction } from '../alerting/alertEngine/state/alertDef';

export enum OperationType {
  math = 'math',
  reduce = 'reduce',
  resample = 'resample',
  classicCondition = 'classic_conditions',
}

export const gelTypes: any = [
  { value: OperationType.math, label: 'Math' },
  { value: OperationType.reduce, label: 'Reduce' },
  { value: OperationType.resample, label: 'Resample' },
  { value: OperationType.classicCondition, label: 'Classic condition' },
];

export const reducerTypes: any = [
  { value: ReducerID.min, label: 'Min', description: 'Get the minimum value' },
  { value: ReducerID.max, label: 'Max', description: 'Get the maximum value' },
  { value: ReducerID.mean, label: 'Mean', description: 'Get the average value' },
  { value: ReducerID.sum, label: 'Sum', description: 'Get the sum of all values' },
  { value: ReducerID.count, label: 'Count', description: 'Get the number of values' },
];

export const downsamplingTypes: any = [
  { value: ReducerID.min, label: 'Min', description: 'Fill with the minimum value' },
  { value: ReducerID.max, label: 'Max', description: 'Fill with the maximum value' },
  { value: ReducerID.mean, label: 'Mean', description: 'Fill with the average value' },
  { value: ReducerID.sum, label: 'Sum', description: 'Fill with the sum of all values' },
];

export const upsamplingTypes: any = [
  { value: 'pad', label: 'pad', description: 'fill with the last known value' },
  { value: 'backfilling', label: 'backfilling', description: 'fill with the next known value' },
  { value: 'fillna', label: 'fillna', description: 'Fill with NaNs' },
];

export interface ExpressionQuery extends DataQuery {
  type: OperationType;
  reducer?: string;
  expression?: string;
  window?: string;
  downsampler?: string;
  upsampler?: string;
  conditions?: ClassicCondition[];
}
export interface ClassicCondition {
  evaluator: {
    params: number[];
    type: evalFunction;
  };
  operator?: {
    type: string;
  };
  query: {
    params: string[];
  };
  reducer: {
    params: [];
    type: reducerType;
  };
  type: 'query';
}

export type reducerType =
  | 'avg'
  | 'min'
  | 'max'
  | 'sum'
  | 'count'
  | 'last'
  | 'median'
  | 'diff'
  | 'diff_abs'
  | 'percent_diff'
  | 'percent_diff_abs'
  | 'count_non_null';

export const defaultValues = (query: ExpressionQuery) => {
  if (query.type === OperationType.reduce) {
    if (!query.reducer) {
      query.reducer = ReducerID.mean;
    }
    query.expression = undefined;
  } else if (query.type === OperationType.resample) {
    if (!query.downsampler) {
      query.downsampler = ReducerID.mean;
    }

    if (!query.upsampler) {
      query.upsampler = 'fillna';
    }

    query.reducer = undefined;
  } else if (query.type === OperationType.classicCondition) {
    if (!query.conditions) {
      query.conditions = [defaultClassicCondition];
    }
  } else {
    query.reducer = undefined;
  }
  return query;
};

export const defaultClassicCondition: ClassicCondition = {
  type: 'query',
  reducer: {
    params: [],
    type: 'avg',
  },
  operator: {
    type: 'and',
  },
  query: { params: [] },
  evaluator: {
    params: [0, 0],
    type: evalFunction.If_Above,
  },
};
