import React from 'react';
import { PanelPlugin } from '@grafana/data';
import { MapPanelOptions } from './types/types';
import { GoogleMapPanel } from './GoogleMapPanel';
import {
  heatmaplayerOptions,
  defaultOptions,
  markerlayerOptions,
  geojsonlayerOptions,
  addStandardDataReduceOptions,
} from './config';
import { QuerySelection, CustomMarker, CustomSymbol, MarkerLink } from './custom';

// todo - add custom editors to their own config files

export const plugin = new PanelPlugin<MapPanelOptions>(GoogleMapPanel)
  .useFieldConfig()
  .setPanelOptions((builder) => {
    builder
      .addRadio({
        path: 'querySetting',
        name: 'Select Query Mode',
        defaultValue: 'auto',
        category: ['Query Editor'],
        settings: {
          options: [
            {
              value: 'auto',
              label: 'Auto',
            },
            {
              value: 'geohash',
              label: 'Geohash',
            },
          ],
        },
      })
      .addCustomEditor({
        id: 'querySelections',
        name: 'Query Selection',
        path: 'querySelections',
        description: 'Select Different Query For Each Field',
        defaultValue: {},
        editor(props: any) {
          return <QuerySelection query={props.value} onChange={props.onChange} bigData={props.context} />;
        },
        category: ['Query Editor'],
        showIf: (options: MapPanelOptions) => options.querySetting === 'geohash',
      })
      .addBooleanSwitch({
        path: 'isQueryForHeat',
        name: 'Register Query',
        description: 'Register query for heatmap layer. By default query is registered for Markers Layer',
        defaultValue: false,
        category: ['Query Editor'],
      });

    addStandardDataReduceOptions(builder);
    defaultOptions(builder);

    builder
      .addBooleanSwitch({
        path: 'isMarkerCluster',
        name: 'Enable Marker Clustering',
        description: 'Save and reload to take effect.',
        defaultValue: false,
        category: ['Marker Editor'],
      })
      .addSelect({
        path: 'markerSymbol',
        name: 'Marker Symbol',
        settings: {
          options: [
            { value: 0, label: 'CIRCLE' },
            { value: 1, label: 'FORWARD_CLOSED_ARROW' },
            { value: 2, label: 'FORWARD_OPEN_ARROW' },
            { value: 3, label: 'BACKWARD_CLOSED_ARROW' },
            { value: 4, label: 'BACKWARD_OPEN_ARROW' },
          ],
        },
        defaultValue: 0,
        showIf: (options: MapPanelOptions) => !options.useCustomMarker,
        category: ['Marker Editor'],
      })
      .addBooleanSwitch({
        path: 'useCustomMarker',
        name: 'Use Custom Marker Symbol',
        defaultValue: false,
        category: ['Marker Editor'],
      })
      .addCustomEditor({
        id: 'customMarkerSymbol',
        name: 'Marker Symbol',
        path: 'customMarkerSymbol',
        description: 'Select Custom Marker from Url. Adjust Min and Max marker scale if not visible',
        defaultValue: {},
        editor(props: any) {
          return <CustomSymbol symbol={props.value} onChange={props.onChange} data={props.context} />;
        },
        category: ['Marker Editor'],
        showIf: (options: MapPanelOptions) => options.useCustomMarker,
      });
    markerlayerOptions(builder);
    builder.addCustomEditor({
      id: 'customMarkers',
      path: 'customMarkers',
      name: 'Custom Markers',
      defaultValue: { markers: [], currIdx: -1, enabled: false, url: '' },
      editor(props: any) {
        return <CustomMarker customMarkers={props.value} onChange={props.onChange} bigData={props.context} />;
      },
      category: ['Marker Editor'],
    });
    heatmaplayerOptions(builder);
    geojsonlayerOptions(builder);
    builder.addCustomEditor({
      id: 'markerLink',
      path: 'markerLink',
      name: 'Marker Links',
      defaultValue: [],
      editor(props: any) {
        return <MarkerLink markerLink={props.value} onChange={props.onChange} bigData={props.context} />;
      },
      category: ['Marker Link'],
    });
  })
  .setNoPadding();
