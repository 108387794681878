import React from 'react';
import { PanelPlugin, FieldConfigProperty } from '@grafana/data';
import { DialOptions, addStandardDataReduceOptions } from './types';
import { DialGauge } from './DialGauge';
import FieldSlider from './components/FieldSlider';

export const plugin = new PanelPlugin<DialOptions>(DialGauge)
  .useFieldConfig({
    standardOptions: {
      [FieldConfigProperty.Unit]: {},
      [FieldConfigProperty.Color]: {},
      [FieldConfigProperty.Decimals]: {},
      [FieldConfigProperty.NoValue]: {},
      [FieldConfigProperty.Thresholds]: {},
      [FieldConfigProperty.Mappings]: {},
      [FieldConfigProperty.DisplayName]: {},
    },
  })
  .setPanelOptions((builder) => {
    //@ts-ignore
    addStandardDataReduceOptions(builder);
    builder.addSelect({
      path: 'gaugeType2',
      name: 'Select Gauge',
      description: 'select from Gauges',
      settings: {
        options: [
          { value: 'first', label: 'Default Gauge' },
          { value: 'second', label: 'Liquid Gauge' },
          { value: 'third', label: 'Canvas Gauge' },
          { value: 'fourth', label: 'D3 Gauge' },
        ],
      },
      defaultValue: 'first',
    });
    builder.addCustomEditor({
      id: 'nrOfLevels',
      path: 'nrOfLevels',
      name: 'Number Of Levels',
      description: 'Limit the displayed item',
      defaultValue: 10,
      editor: function sliderExec(props: any) {
        const { onChange, context } = props;
        const data: number = context.options.nrOfLevels;
        const type: number = context.options.gaugeType2;
        return <FieldSlider changer={onChange} context={context} data={data} type={type} min={10} max={80} step={10} />;
      },
      showIf: (config: DialOptions) => config.gaugeType2 === 'first',
    });
    builder.addCustomEditor({
      id: 'gaugeThickness',
      path: 'gaugeThickness',
      name: 'Gauge Thickness',
      description: 'Limit the thickness of Gauge',
      defaultValue: 0.35,
      editor: function sliderExec(props: any) {
        const { onChange, context } = props;
        const data: number = context.options.gaugeThickness;
        const type: number = context.options.gaugeType2;
        return <FieldSlider changer={onChange} data={data} type={type} min={0.01} max={1} step={0.01} />;
      },
      showIf: (config: DialOptions) => config.gaugeType2 === 'first',
    });
    builder.addCustomEditor({
      id: 'GaugeArc',
      path: 'GaugeArc',
      name: 'GaugeArc',
      description: 'Limit Gauge Arc',
      defaultValue: 1.05,
      editor: function sliderExec(props: any) {
        const { onChange, context } = props;
        const data: number = context.options.GaugeArc;
        const type: number = context.options.gaugeType2;
        return (
          <FieldSlider changer={onChange} context={context} data={data} type={type} min={0.01} max={1.99} step={0.01} />
        );
      },
      showIf: (config: DialOptions) => config.gaugeType2 === 'first',
    });
    builder.addCustomEditor({
      id: 'Rotation',
      path: 'Rotation',
      name: 'Gauge Rotation',
      description: 'Rotates the Gauge',
      defaultValue: '0',
      editor: function sliderExec(props: any) {
        const { onChange, context } = props;
        const data: number = context.options.Rotation;
        const type: number = context.options.gaugeType2;
        return <FieldSlider changer={onChange} context={context} data={data} type={type} min={0} max={360} step={5} />;
      },
      showIf: (config: DialOptions) => config.gaugeType2 === 'first',
    });
    builder.addBooleanSwitch({
      path: 'showNum2',
      name: 'Hide/Show number',
      description: 'To hide or show the number',
      defaultValue: true,
      showIf: (config: DialOptions) => config.gaugeType2 !== 'first',
    });
    builder.addBooleanSwitch({
      path: 'showName2',
      name: 'Hide/Show Name',
      description: 'To show or hide Name',
      defaultValue: true,
      showIf: (config: DialOptions) => config.gaugeType2 !== 'first',
    });
  });
