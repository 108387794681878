import { config, getBackendSrv } from '@grafana/runtime';

const msToTime = (s: any) => {
  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  var hrs = (s - mins) / 60;

  if (hrs === 0 && mins === 0) {
    return secs + ' ' + 'Seconds';
  } else if (hrs === 0) {
    return mins + ' ' + 'Minutes' + ' ' + secs + ' ' + 'Seconds';
  } else {
    return hrs + ' ' + 'Hours' + ' ' + mins + ' ' + 'Minutes' + ' ' + secs + ' ' + 'Seconds';
  }
};

const getStateDisplayModel = (state: string) => {
  switch (state) {
    case 'ok': {
      return {
        text: 'OK',
        iconClass: 'heart',
        stateClass: 'alert-state-ok',
      };
    }
    case 'alerting': {
      return {
        text: 'ALERTING',
        iconClass: 'heart-break',
        stateClass: 'alert-state-critical',
      };
    }
    case 'NoData': {
      return {
        text: 'NO DATA',
        iconClass: 'info-circle',
        stateClass: 'alert-state-warning',
      };
    }
    case 'paused': {
      return {
        text: 'PAUSED',
        iconClass: 'player-pause',
        stateClass: 'alert-state-paused',
      };
    }
    case 'pending': {
      return {
        text: 'PENDING',
        iconClass: 'alert-triangle',
        stateClass: 'alert-state-warning',
      };
    }
    case 'unknown': {
      return {
        text: 'UNKNOWN',
        iconClass: 'info-circle',
        stateClass: 'alert-state-paused',
      };
    }
    case 'Pending': {
      return {
        text: 'PENDING',
        iconClass: 'alert-triangle',
        stateClass: 'alert-state-warning',
      };
    }
    case 'Alerting': {
      return {
        text: 'ALERTING',
        iconClass: 'heart-break',
        stateClass: 'alert-state-critical',
      };
    }
    case 'Error': {
      return {
        text: 'Error',
        iconClass: 'heart-break',
        stateClass: 'alert-state-critical',
      };
    }
    case 'Normal': {
      return {
        text: 'NORMAL',
        iconClass: 'heart',
        stateClass: 'alert-state-ok',
      };
    }
  }
  console.log('Unknown state:', state);
  throw { message: 'Unknown alert state' };
};

export const getAlertRulesAsync = async () => {
  if (config.ngAlertingEnabled) {
    const ngAlertDefinitions = await getBackendSrv().get('/api/alert-definitions');
    const instances = await getBackendSrv().get('/api/alert-instances');
    ngAlertDefinitions.results.map((item: any) => {
      for (let i = 0; i < instances.length; i++) {
        if (item.uid === instances[i].definitionUid) {
          item.currentState = instances[i].currentState;
          item.currentStateSince = instances[i].currentStateSince;
          let currentState: any = new Date(instances[i].currentStateSince);
          let date: any = new Date();
          item.stateAge = msToTime(Math.abs(currentState - date));
          item.stateClass = getStateDisplayModel(instances[i].currentState);
        }
      }
    });
    ngAlertDefinitions.results.map((item: any) => {
      if (item.stateClass && item.stateAge) {
      } else {
        item.currentState = 'Normal';
        item.currentStateSince = new Date();
        item.stateAge = 0;
        item.stateClass = getStateDisplayModel('Normal');
      }
    });
    return ngAlertDefinitions.results;
  }
};
