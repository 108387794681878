import {
  DataFrame,
  FieldType,
  GrafanaTheme,
  Field,
  ArrayVector,
  getTimeZone,
  systemDateFormats,
  dateTimeFormat,
} from '@grafana/data';

/**
 * Prepare and filter DataFrame objects for graphing by keeping only frames that contain at least one time field,
 * one numeric field, and one string field. Numeric fields are sanitized to replace non-finite or null values with null.
 *
 * @param {DataFrame[]} series - An array of DataFrame objects.
 * @param {GrafanaTheme} theme - The Grafana theme.
 *
 * @returns {DataFrame[] | null} An array of filtered DataFrame objects or null if no suitable frames are found.
 */
export function prepareGraphableFields(series: DataFrame[], theme: GrafanaTheme): DataFrame[] | null {
  if (!series?.length) {
    return null;
  }

  // if (series[0].fields.length <= 2) {
  //   return null;
  // }

  let copy: Field;

  const frames: DataFrame[] = [];

  for (let frame of series) {
    const fields: Field[] = [];

    let hasTimeField = false;
    let hasValueField = false;
    let hasStringField = false;

    for (const field of frame.fields) {
      switch (field.type) {
        case FieldType.time:
          hasTimeField = true;
          fields.push(field);
          break;
        case FieldType.number:
          hasValueField = true;
          copy = {
            ...field,
            values: new ArrayVector(
              field.values.toArray().map((v) => {
                if (!(Number.isFinite(v) || v === null)) {
                  return null;
                }
                return v;
              })
            ),
          };
          fields.push(copy);
          break; // ok
        case FieldType.string:
          hasStringField = true;
          fields.push(field);
          break;
      }
    }

    if (hasTimeField && hasValueField && hasStringField) {
      frames.push({
        ...frame,
        fields,
      });
    }
  }
  if (frames.length) {
    return frames;
  }

  return null;
}

/**
 * Defines the size of various time units in milliseconds.
 *
 * @constant {Object} timeUnitSize
 * @property {number} second - The size of one second in milliseconds (1000).
 * @property {number} minute - The size of one minute in milliseconds (60,000, which is 60 seconds).
 * @property {number} hour - The size of one hour in milliseconds (3,600,000, which is 60 minutes).
 * @property {number} day - The size of one day in milliseconds (86,400,000, which is 24 hours).
 * @property {number} month - The approximate size of one month in milliseconds (2,419,200,000, which is about 28 days).
 * @property {number} year - The approximate size of one year in milliseconds (31,536,000,000, which is about 365 days).
 */
const timeUnitSize = {
  second: 1000,
  minute: 60 * 1000,
  hour: 60 * 60 * 1000,
  day: 24 * 60 * 60 * 1000,
  month: 28 * 24 * 60 * 60 * 1000,
  year: 365 * 24 * 60 * 60 * 1000,
};

/** Format time axis ticks */
export function formatTime(v: number | string, foundIncr: number) {
  const timeZone = getTimeZone();
  const yearRoundedToDay = Math.round(timeUnitSize.year / timeUnitSize.day) * timeUnitSize.day;
  const incrementRoundedToDay = Math.round(foundIncr / timeUnitSize.day) * timeUnitSize.day;

  let format = systemDateFormats.interval.minute;
  if (foundIncr < timeUnitSize.second) {
    format = systemDateFormats.interval.second.replace('ss', 'ss.SS');
  } else if (foundIncr <= timeUnitSize.minute) {
    format = systemDateFormats.interval.second;
  } else if (foundIncr <= timeUnitSize.hour) {
    format = systemDateFormats.interval.minute;
  } else if (foundIncr <= timeUnitSize.day) {
    format = systemDateFormats.interval.hour;
  } else if (foundIncr <= timeUnitSize.month) {
    format = systemDateFormats.interval.day;
  } else if (incrementRoundedToDay === yearRoundedToDay) {
    format = systemDateFormats.interval.year;
  } else if (foundIncr <= timeUnitSize.year) {
    format = systemDateFormats.interval.month;
  }

  let x = parseInt(typeof v === 'number' ? `${v}` : v, 10);
  return dateTimeFormat(x, { format, timeZone });
}
