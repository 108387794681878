import React, { useRef } from 'react';
import { Group } from '@visx/group';
import { Text } from '@visx/text';
import { scaleSequential } from 'd3-scale';
import { interpolateCool } from 'd3';
import { extent } from 'd3-array';
import { linkHorizontal } from 'd3-shape';
import { localPoint } from '@visx/event';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { getColorForTheme, GrafanaTheme } from '@grafana/data';
import { useStyles, useTheme, SeriesTable, SeriesTableRowProps } from '@grafana/ui';
import { css } from 'emotion';
import Sankey from './Sankey';
import CircularSankey from './CircularSankey';
import { LinkI, NodeI, NodeI2 } from '../types';

const path = linkHorizontal()
  .source((d: any) => [d.source.x1, d.y0])
  .target((d: any) => [d.target.x0, d.y1]);

const color: any = scaleSequential(interpolateCool);

interface ExampleI {
  data: any;
  width: number;
  height: number;
  nWidth: number;
  nPadding: number;
  nColor: string;
  nTextSize: number;
  diagram: boolean;
  margin: { top: number; left: number; right: number; bottom: number };
}

const Draw = ({
  data,
  width,
  height,
  nWidth,
  nPadding,
  nColor,
  nTextSize,
  diagram,
  margin = {
    top: 0,
    left: 0,
    right: 200,
    bottom: 0,
  },
}: ExampleI) => {
  const ref = useRef(null);
  const theme = useTheme();
  const styles = useStyles(getStyles);
  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });

  const onMouseMoveOverArc = (event: any, link: LinkI) => {
    const coords = localPoint(event.target.ownerSVGElement, event);
    const series: SeriesTableRowProps[] = [];
    series.push({ label: 'Start', value: link.from });
    series.push({ label: 'End', value: link.to });
    series.push({ label: 'Weight', value: String(link.value) });
    // const fmtDate = (
    //   <div>
    //     Start: {link.from}
    //     <br />
    //     End: {link.to}
    //     <br />
    //     Weight: {link.value}
    //   </div>
    // );
    showTooltip({
      tooltipLeft: coords!.x,
      tooltipTop: coords!.y,
      tooltipData: series,
    });
  };

  // Get the Supportive Color from grafana
  var newColor = getColorForTheme(nColor, theme);
  var textColor = theme.colors.text;

  //Diagram will based on this variable
  var userDiagram = '';
  // Will check the condition true or false{ diagram is a user selected value}
  if (diagram !== undefined) {
    diagram === true ? (userDiagram = 'CircularSankey') : (userDiagram = 'Sankey');
  } else {
    userDiagram = 'Sankey';
  }

  // it a dynamic component will change on the basis of userDiagram
  const Component = userDiagram === 'Sankey' ? Sankey : CircularSankey;

  return (
    <div>
      <svg width={width + margin.left + margin.right} height={height + margin.top + margin.bottom} ref={containerRef}>
        {/* will Create the Entire path */}
        <Component
          top={margin.top}
          left={margin.left}
          data={data}
          size={[width, height]}
          nodeWidth={nWidth}
          nodePadding={nPadding}
          extent={[
            [1, 1],
            [width - 1, height - 6],
          ]}
          className={''}
          nodePaddingRatio={0}
          iterations={0}
          circularLinkGap={0}
          nodeId={undefined}
          nodeAlign={undefined}
          nodeComponent={undefined}
        >
          {({ data }) => (
            <Group>
              {color.domain(extent(data.nodes, (d: NodeI) => d.depth))}
              <Group>
                {data.links.map((link: LinkI, i: any) => (
                  <path
                    ref={ref.current}
                    key={`node-link-${i}`}
                    // @ts-ignore
                    d={path(link)}
                    stroke={data.links[i].layerColors}
                    strokeWidth={Math.max(1, link.width)}
                    opacity={1}
                    fill="none"
                    onMouseMove={(event) => onMouseMoveOverArc(event, link)}
                    onMouseOut={hideTooltip}
                  />
                ))}
              </Group>
              {tooltipOpen && (
                <TooltipInPortal
                  className={styles.tooltipPortal}
                  unstyled={true}
                  applyPositionStyle={true}
                  key={Math.random()}
                  top={tooltipTop}
                  left={tooltipLeft}
                >
                  {/* {tooltipData as any} */}
                  <SeriesTable series={tooltipData as any} />
                </TooltipInPortal>
              )}

              {data.nodes.map((node: NodeI2, i: number) => (
                <Group top={node.y0} left={node.x0} key={`node-${i}`}>
                  {/* this is a node  */}
                  <rect
                    id={`rect-${i}`}
                    width={node.x1 - node.x0}
                    height={node.y1 - node.y0}
                    fill={newColor}
                    opacity={0.5}
                    stroke="white"
                    strokeWidth={2}
                  />

                  {
                    // this will check the condition like text is from starting node or not
                    // And helps to change the direction of the text
                    node.sourceLinks.length === 0 ? (
                      <Text
                        x={-3}
                        y={(node.y1 - node.y0) / 2}
                        verticalAnchor="middle"
                        style={{
                          direction: 'rtl',
                          // font: `${nTextSize}px sans-serif`,
                          fill: textColor,
                        }}
                      >
                        {node.name}
                      </Text>
                    ) : (
                      <Text
                        x={18}
                        y={(node.y1 - node.y0) / 2}
                        verticalAnchor="middle"
                        style={{
                          // font: `${nTextSize}px sans-serif`,
                          fill: textColor,
                        }}
                      >
                        {node.name}
                      </Text>
                    )
                  }
                </Group>
              ))}
            </Group>
          )}
        </Component>
      </svg>
    </div>
  );
};

export const getTooltipContainerStyles = (theme: GrafanaTheme) => `
  // pointer-events: none;
  overflow: hidden;
  background: ${theme.colors.bg2};
  box-shadow: ${theme.colors.bodyBg};
  max-width: 800px;
  padding: ${theme.spacing.md};
  border-radius: 1px;
  z-index: ${theme.zIndex.tooltip};
`;

const getStyles = (theme: GrafanaTheme) => {
  return {
    container: css`
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    `,
    svgArg: css`
      transition: all 200ms ease-in-out;
      &:hover {
        transform: scale3d(1.03, 1.03, 1);
      }
    `,
    tooltipPortal: css`
      ${getTooltipContainerStyles(theme)}
    `,
  };
};

export default Draw;
