import React, { FC, CSSProperties } from 'react';
import { getColorForTheme, TimeRange, GrafanaTheme } from '@grafana/data';
import { css } from 'emotion';
import { TimeOptions } from './types';
const moment = require('moment');
import tinycolor from 'tinycolor2';

interface TimeProps {
  text: string;
  options: TimeOptions;
  fontSize: number;
  timeRange: TimeRange | any;
  dataTime: TimeRange | any;
  style: CSSProperties;
  theme: GrafanaTheme;
}

export const Time: FC<TimeProps> = ({ text, options, fontSize, timeRange, dataTime, style, theme }) => {
  const styles = getStyles();

  return (
    <div className={styles.outerDiv} style={style}>
      {options.leftFrom && (
        <h2 style={{ fontSize: fontSize, marginTop: '0.6rem' }}>
          <span
            style={{
              fontWeight: options.textBold ? 'bold' : 'normal',
              fontStyle: options.textItalic ? 'italic' : 'normal',
              textDecoration: options.textUnderline ? 'underline' : 'none',
              color: getColorString(options.textcolor, theme),
            }}
          >
            {text}:
          </span>
        </h2>
      )}
      <div
        className={styles.innerDiv}
        style={{
          color: getColorString(options.boxtextcolor, theme),
          fontSize: fontSize - 2,
          background: getColorString(options.borderboxcolor, theme),
        }}
      >
        {options.date && <span style={{ fontSize: fontSize }}>{formatDate(dataTime, options.changeDateFormat)}</span>}
        {options.showTime && <p>{formatTime(dataTime, options.twelveHourTime)}</p>}
        {options.timeZone && (
          <span style={{ fontSize: fontSize - 5 }}> {timeRange.from._d.toTimeString().split(' ')[1]}</span>
        )}
      </div>
    </div>
  );
};

const getColorString = (color: any, theme: any) => {
  return tinycolor(getColorForTheme(color, theme)).toString();
};
const formatTime = (time: Date, ampm: boolean) => {
  if (ampm) {
    return moment(time).format('hh:mm:ss A');
  }
  return moment(time).format('HH:mm:ss');
};

const formatDate = (date: Date, format: boolean) => {
  if (format) {
    return moment(date).format('DD MMM YYYY');
  }
  return moment(date).format('MM-DD-YYYY');
};

const getStyles = () => {
  return {
    outerDiv: css`
      & {
        display: flex;
      }
      & h2 {
        margin-right: 0.3rem;
      }
    `,
    innerDiv: css`
      & {
        border-radius: 22px;
        padding: 0.5rem 0.8rem;
      }
      & span {
        margin-right: 0.3rem;
      }
    `,
  };
};
