import React from 'react';
import { AlignedData } from 'uplot';
import { Themeable } from '../../types';
import { pluginLog2, findMidPointYPosition } from '../uPlot/utils';
import {
  DataFrame,
  FieldMatcherID,
  fieldMatchers,
  LegacyGraphHoverClearEvent,
  LegacyGraphHoverEvent,
  TimeRange,
  TimeZone,
  VizLegendOptions,
} from '@grafana/data';
import { preparePlotFrame as defaultPreparePlotFrame } from './utils';
import { PanelContext, PanelContextRoot } from '../PanelChrome/PanelContext';
import { Subscription } from 'rxjs';
import { GraphNGLegendEvent, XYFieldMatchers } from './types';
import { UPlotConfigBuild } from '../uPlot/config/UPlotConfigBuild';
import { UPlotLayout } from '../UPlotLayout/UPlotLayout';
import { UPlotChart2 } from '../uPlot/Plot2';

/**
 * @internal -- not a public API
 */
export const UPlot_UNIT = '__fixed';

/**
 * @internal -- not a public API
 */
export type PropDiffFn<T extends any = any> = (prev: T, next: T) => boolean;

export interface UPlotGraphProps extends Themeable {
  frames: DataFrame[];
  structureRev?: number; // a number that will change when the frames[] structure changes
  width: number;
  height: number;
  timeRange: TimeRange;
  timeZone: TimeZone;
  legend: VizLegendOptions;
  fields?: XYFieldMatchers; // default will assume timeseries data
  onLegendClick?: (event: GraphNGLegendEvent) => void;
  children?: (builder: UPlotConfigBuild, timelineFrame: DataFrame) => React.ReactNode;
  prepareConfig: (timelineFrame: DataFrame, allFrames: DataFrame[], getTimeRange: () => TimeRange) => UPlotConfigBuild;
  chartProps?: Array<string | PropDiffFn>;
  preparePlotFrame?: (frames: DataFrame[], dimFields: XYFieldMatchers) => DataFrame;
  prepareLegend: (config: UPlotConfigBuild) => React.ReactElement | null;
}

function sameProps(prevProps: any, nextProps: any, chartProps: Array<string | PropDiffFn> = []) {
  for (const propName of chartProps) {
    if (typeof propName === 'function') {
      if (!propName(prevProps, nextProps)) {
        return false;
      }
    } else if (nextProps[propName] !== prevProps[propName]) {
      return false;
    }
  }

  return true;
}

/**
 * @internal -- not a public API
 */
export interface GraphNGState {
  timelineFrame: DataFrame;
  timelineData: AlignedData;
  config?: UPlotConfigBuild;
}

/**
 * "Time as X" core component, expects ascending x
 */
export class UPlotGraph extends React.Component<UPlotGraphProps, GraphNGState> {
  static contextType = PanelContextRoot;
  panelContext: PanelContext = {} as PanelContext;
  private plotInstance: React.RefObject<uPlot>;

  private subscription = new Subscription();

  constructor(props: UPlotGraphProps) {
    super(props);
    this.state = this.prepState(props);
    this.plotInstance = React.createRef();
  }

  getTimeRange = () => this.props.timeRange;

  prepState(props: UPlotGraphProps, withConfig = true) {
    let state: GraphNGState = null as any;

    const { frames, fields, preparePlotFrame } = props;

    const preparePlotFrameFn = preparePlotFrame || defaultPreparePlotFrame;

    const timelineFrame = preparePlotFrameFn(
      frames,
      fields || {
        x: fieldMatchers.get(FieldMatcherID.firstTimeField).get({}),
        y: fieldMatchers.get(FieldMatcherID.numeric).get({}),
      }
    );
    pluginLog2('UPlotGraph', false, 'data aligned', timelineFrame);

    if (timelineFrame) {
      let config = this.state?.config;

      if (withConfig) {
        config = props.prepareConfig(timelineFrame, this.props.frames, this.getTimeRange);
        pluginLog2('UPlotGraph', false, 'config prepared', config);
      }

      state = {
        timelineFrame,
        timelineData: config!.prepData!([timelineFrame]) as AlignedData,
        config,
      };

      pluginLog2('UPlotGraph', false, 'data prepared', state.timelineData);
    }

    return state;
  }

  componentDidMount() {
    this.panelContext = this.context as PanelContext;
    const { eventBus } = this.panelContext;

    this.subscription.add(
      eventBus.getStream(LegacyGraphHoverEvent).subscribe({
        next: (evt) => {
          const u = this.plotInstance.current;
          if (u) {
            // Try finding left position on time axis
            // @ts-ignore
            const left = u.valToPos(evt.payload.point.time, 'x');
            let top;
            if (left) {
              // find midpoint between points at current idx
              top = findMidPointYPosition(u, u.posToIdx(left));
            }

            if (!top || !left) {
              return;
            }

            u.setCursor({
              left,
              top,
            });
          }
        },
      })
    );

    this.subscription.add(
      eventBus.getStream(LegacyGraphHoverClearEvent).subscribe({
        next: () => {
          const u = this.plotInstance?.current;

          if (u) {
            u.setCursor({
              left: -10,
              top: -10,
            });
          }
        },
      })
    );
  }

  componentDidUpdate(prevProps: UPlotGraphProps) {
    const { frames, structureRev, timeZone, chartProps } = this.props;

    const propsChanged = !sameProps(prevProps, this.props, chartProps);

    if (frames !== prevProps.frames || propsChanged) {
      let newState = this.prepState(this.props, false);

      if (newState) {
        const shouldReconfig =
          this.state.config === undefined ||
          timeZone !== prevProps.timeZone ||
          structureRev !== prevProps.structureRev ||
          !structureRev ||
          propsChanged;

        if (shouldReconfig) {
          newState.config = this.props.prepareConfig(newState.timelineFrame, this.props.frames, this.getTimeRange);
          newState.timelineData = newState.config.prepData!([newState.timelineFrame]) as AlignedData;
          pluginLog2('UPlotGraph', false, 'config recreated', newState.config);
        }
      }

      newState && this.setState(newState);
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  render() {
    const { width, height, children, timeRange, prepareLegend } = this.props;
    const { config, timelineFrame, timelineData } = this.state;

    if (!config) {
      return null;
    }

    return (
      <UPlotLayout width={width} height={height} legend={prepareLegend(config)}>
        {(vizWidth: number, vizHeight: number) => (
          <UPlotChart2
            config={config}
            data={timelineData}
            width={vizWidth}
            height={vizHeight}
            timeRange={timeRange}
            plotRef={(u) => ((this.plotInstance as React.MutableRefObject<uPlot>).current = u)}
          >
            {children ? children(config, timelineFrame) : null}
          </UPlotChart2>
        )}
      </UPlotLayout>
    );
  }
}
