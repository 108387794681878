import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const Stats: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 16 16"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M14.5,13.9H2.7c-0.3,0-0.6-0.3-0.6-0.6V1.5c0-0.3-0.3-0.6-0.6-0.6S0.9,1.2,0.9,1.5v11.8c0,1,0.8,1.8,1.8,1.8
        h11.8c0.3,0,0.6-0.3,0.6-0.6C15.1,14.2,14.8,13.9,14.5,13.9z M9.8,12.7c0.3,0,0.6-0.3,0.6-0.6V8c0-0.3-0.3-0.6-0.6-0.6
        S9.2,7.7,9.2,8v4.1C9.2,12.5,9.4,12.7,9.8,12.7z M5.1,12.7c0.3,0,0.6-0.3,0.6-0.6V8c0-0.3-0.3-0.6-0.6-0.6S4.5,7.7,4.5,8v4.1
        C4.5,12.5,4.7,12.7,5.1,12.7z M12.1,12.7c0.3,0,0.6-0.3,0.6-0.6V5.1c0-0.3-0.3-0.6-0.6-0.6s-0.6,0.3-0.6,0.6v7.1
        C11.5,12.5,11.8,12.7,12.1,12.7z M7.4,12.7c0.3,0,0.6-0.3,0.6-0.6V5.1c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6v7.1
        C6.8,12.5,7.1,12.7,7.4,12.7z"
      />
    </svg>
  );
};
