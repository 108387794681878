import { DataFrame, FieldDisplay } from '@grafana/data';
import { WaterfallData } from './interface';

/**
 * calculateWaterfallDataQuery function is used to calculate the data that should be inserted
 * in all the four arrays that are used as to draw plot only when waterfall chart is selected without
 * time to draw.
 */
export const calculateWaterfallDataQuery = (
  mainData: DataFrame[],
  getData: FieldDisplay[],
  total: boolean,
  totalName: string,
  includeTimeField: boolean,
  endyearSwitch: boolean,
  addyear: number
) => {
  const waterfallData: WaterfallData = {};
  let timeArray: any[] = [];
  let openArray: any[] = [];
  let highArray: any[] = [];
  let lowArray: any[] = [];
  let closeArray: any[] = [];
  let indexArray: number[] = [];
  let valueArray: any = [];
  let colorArray: string[] = [];
  let nameArray: any[] = [];

  const timeObj: any = mainData[0].fields[0].values;
  let fulltimeArray: number[] = timeObj.buffer;

  let years = [];

  for (let i = 0; i < getData.length; i++) {
    if (includeTimeField) {
      // We will include timestamp as tiitle to display on x-axis
      // @ts-ignore
      getData[i]?.view?.data?.fields?.map((field) => {
        if (field.name === 'time') {
          // @ts-ignore
          const buffer = field.values.buffer;
          timeArray.push(buffer[buffer.length - 1]);
        }
      });
    } else {
      if (endyearSwitch) {
        let val = getData[i].display.numeric;
        const valyear = new Date(fulltimeArray[fulltimeArray.length - 1]);
        if (addyear === undefined) {
          addyear = new Date().getFullYear() + 3;
        }
        if (addyear >= valyear.getFullYear()) {
          for (let index = valyear.getFullYear(); index <= addyear; index++) {
            if (addyear === index) {
              years.push(valyear.getTime());
            } else {
              years.push(valyear.getTime());
              valyear.setFullYear(valyear.getFullYear() + 1);
            }
          }
        } else {
          for (let index = fulltimeArray.length; index > 0; index--) {
            const y = new Date(fulltimeArray[index - 1]).getFullYear();
            if (y === addyear) {
              years.push(fulltimeArray[index - 1]);
              break;
            }
            years.push(fulltimeArray[index - 1]);
          }
        }
        let IntervalToZero = val / (years.length - 1);
        for (let i = 0; i < years.length; i++) {
          indexArray.push(i);
          const element = years[i];
          const year = new Date(element);
          timeArray.push(`${year.getFullYear()}`);
          if (i > 0) {
            let val2 = val - IntervalToZero;
            val = val2;
            valueArray.push(val2);
          } else {
            valueArray.push(val);
          }
        }
      } else {
        timeArray.push(getData[i].display.title);
      }
      if (!endyearSwitch) {
        valueArray.push(getData[i].display.numeric);
      }
    }
  }

  let baseValue: any = 0;
  // let k = 'high';
  if (valueArray.length > 0) {
    //@ts-ignore
    baseValue = valueArray[0];
  }

  //* if the length of the valueArray is 1;
  if (endyearSwitch) {
    for (let i = 0; i < valueArray.length; i++) {
      if (i === 0) {
        lowArray.push(0);
        closeArray.push(0);
        openArray.push(baseValue);
        highArray.push(baseValue);
      } else {
        highArray.push(valueArray[i]);
        openArray.push(valueArray[i]);
        lowArray.push(highArray[i - 1]);
        closeArray.push(openArray[i - 1]);
      }
    }
  } else {
    for (let i = 0; i < valueArray.length; i++) {
      if (i === 0) {
        lowArray.push(0);
        closeArray.push(0);
        openArray.push(baseValue);
        highArray.push(baseValue);
      } else {
        highArray.push(valueArray[i] + highArray[i - 1]);
        openArray.push(valueArray[i] + openArray[i - 1]);
        lowArray.push(highArray[i - 1]);
        closeArray.push(openArray[i - 1]);
      }
    }
  }

  /**
   * to plot the bar for total value, we adding values in all the array used to draw plot.
   */
  if (endyearSwitch) {
    if (total) {
      lowArray[lowArray.length - 1] = lowArray[0];
      closeArray[closeArray.length - 1] = closeArray[0];
      openArray[openArray.length - 1] = openArray[openArray.length - 2];
      highArray[highArray.length - 1] = highArray[highArray.length - 2];
      let riseOrFallFor = 0;
      //@ts-ignore
      valueArray[valueArray.length - 1] = riseOrFallFor;
    }
  } else {
    if (total) {
      lowArray.push(lowArray[0]);
      closeArray.push(lowArray[0]);
      highArray.push(highArray[highArray.length - 1]);
      openArray.push(openArray[openArray.length - 1]);
      timeArray.push(totalName);
      valueArray.push(0);
    }
  }

  /**
   * an object consisting an array of time with title of the values as 'time'.
   */
  waterfallData.time = {
    name: 'time',
    values: endyearSwitch ? years : timeArray,
  };
  /**
   * an object consisting an array of data with title of the values as 'open'.
   */
  waterfallData.open = {
    name: 'open',
    values: openArray,
  };
  /**
   * an object consisting an array of data with title of the values as 'close'.
   */
  waterfallData.close = {
    name: 'close',
    values: closeArray,
  };
  /**
   * an object consisting an array of data with title of the values as 'high'.
   */
  waterfallData.high = {
    name: 'high',
    values: highArray,
  };
  /**
   * an object consisting an array of data with title of the values as 'low'.
   */
  waterfallData.low = {
    name: 'low',
    values: lowArray,
  };

  /**
   * creating array's consisting of colors and names and index for each of the bar plot on the graph.
   */
  for (let index = 0; index < getData.length; index++) {
    const ele: any = getData[index];
    colorArray.push(ele.display.color);
    nameArray.push(ele.display.title);
    if (!endyearSwitch) {
      indexArray.push(index);
    }
  }

  waterfallData.color = colorArray;
  waterfallData.name = nameArray;
  waterfallData.indexArray = indexArray;
  waterfallData.valueArray = valueArray;

  return waterfallData;
};
