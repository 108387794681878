import { DataTransformerInfo, DataFrame, FieldType, DataTransformerID } from '@grafana/data';
import { map } from 'rxjs/operators';

export type PrepareTreeDataOptions = {
  format: any;
};

/**
 * Convert to [][time,number]
 */
export function toTreeDataToSend(data: DataFrame[], options: PrepareTreeDataOptions): DataFrame[] {
  if (!Array.isArray(data) || data.length === 0) {
    return data;
  }

  const result: DataFrame[] = [];
  for (const frame of data) {
    const timeField = frame.fields.find((field) => {
      return field.type === FieldType.time;
    });

    if (!timeField) {
      continue;
    }

    for (const field of frame.fields) {
      if (field.type !== FieldType.number) {
        continue;
      }

      result.push({
        name: frame.name,
        refId: frame.refId,
        meta: frame.meta,
        fields: [timeField, field],
        // @ts-ignore
        tree: JSON.stringify(options),
        length: frame.length,
      });
    }
  }
  return result;
}

export function prepareTreeData(data: DataFrame[], options: PrepareTreeDataOptions): DataFrame[] {
  return toTreeDataToSend(data, options);
}

export const prepareTreeDataTransformer: DataTransformerInfo<PrepareTreeDataOptions> = {
  id: DataTransformerID.treeData,
  name: 'Prepare tree data',
  description: 'Will help create a tree data structure. Useful for plugins like Treemap & Sunburst etc.',
  defaultOptions: {},
  operator: (options) => (source) => source.pipe(map((data) => prepareTreeData(data, options))),
};
