import React from 'react';
import {
  DataFrame,
  FALLBACK_COLOR,
  formattedValueToString,
  getDisplayProcessor,
  getFieldDisplayName,
  getValueFormat,
  TimeZone,
} from '@grafana/data';
import { SeriesTableRow, useTheme } from '@grafana/ui';
import { getNextIndex } from './utils/utils';

interface StateTimelineTooltipProps {
  data: DataFrame[];
  timelineData: DataFrame;
  seriesIdx: number;
  dataIdx: number;
  timeZone: TimeZone;
}

export const TimelineTooltip: React.FC<StateTimelineTooltipProps> = ({
  data,
  timelineData,
  seriesIdx,
  dataIdx,
  timeZone,
}) => {
  const theme = useTheme();

  const xField = timelineData.fields[0];
  const xFieldFmt = xField.display || getDisplayProcessor({ field: xField, timeZone, theme });

  const field = timelineData.fields[seriesIdx!];

  const dataFrameFieldIndex = field.state?.origin;
  const fieldFmt = field.display || getDisplayProcessor({ field, timeZone, theme });
  const value = field.values.get(dataIdx!);
  const display = fieldFmt(value);
  const fieldDisplayName = dataFrameFieldIndex
    ? getFieldDisplayName(
        data[dataFrameFieldIndex.frameIndex].fields[dataFrameFieldIndex.fieldIndex],
        data[dataFrameFieldIndex.frameIndex],
        data
      )
    : null;

  const nextIdx = getNextIndex(field, dataIdx!);
  let nextTimestamp;
  if (nextIdx) {
    nextTimestamp = xField.values.get(nextIdx!);
  }

  const stateTs = xField.values.get(dataIdx!);

  let toFragment = null;
  let durationFragment = null;

  if (nextTimestamp) {
    const interval =
      nextTimestamp && formattedValueToString(getValueFormat('dtdurationms')(nextTimestamp - stateTs, 0));
    durationFragment = (
      <>
        <br />
        Interval: <strong>{interval}</strong>
      </>
    );
    toFragment = (
      <>
        {' To'} &emsp; &ensp; <strong>{xFieldFmt(xField.values.get(nextIdx!)).text}</strong>
      </>
    );
  }

  return (
    <div style={{ fontSize: theme.typography.size.sm }}>
      <SeriesTableRow
        label={fieldDisplayName ?? ''}
        value={(display.prefix ?? '') + display.text + (display.suffix ?? '')}
        color={display.color || FALLBACK_COLOR}
        isActive
      />
      From &ensp; <strong>{xFieldFmt(xField.values.get(dataIdx!)).text}</strong>
      <br />
      {toFragment}
      {durationFragment}
    </div>
  );
};

TimelineTooltip.displayName = 'TimelineTooltip';
