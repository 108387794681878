import React from 'react';
import { Button, useTheme, VerticalGroup } from '@grafana/ui';
import { cloneDeep } from 'lodash';
import { FieldDisplay, getFieldDisplayValues } from '@grafana/data';
import { DashExpand } from './dashboard/DashExpand';
import { DashboardTime } from './dashboard/DashboardTime';

export interface VisitDashboard {
  visitDashboard: Visit[];
  drawerLinkTime: boolean;
}

export interface Visit {
  queryName: string;
  dashboardUrl: string;
  dashboardName: string;
}

interface Props {
  toVisitDashboard: VisitDashboard;
  onChange: (toVisitDashboard: VisitDashboard) => void;
  bigData: any;
}

export function CircleOrPieSelectEditor({ toVisitDashboard, onChange, bigData }: Props) {
  let { drawerLinkTime, visitDashboard } = toVisitDashboard;
  const theme = useTheme();
  const { options, replaceVariables, data } = bigData;
  const { fieldConfig } = options;
  const setoptions = cloneDeep(options.reduceOptions);
  delete setoptions.fields;
  delete setoptions.limit;
  delete setoptions.values;
  let newData: FieldDisplay[];

  const onQueryReload = () => {
    if (data!.length > 0) {
      const getValues3 = (): FieldDisplay[] => {
        return getFieldDisplayValues({
          fieldConfig,
          reduceOptions: setoptions,
          replaceVariables,
          theme: theme,
          data: data!,
          sparkline: false,
          timeZone: 'browser',
        });
      };
      newData = getValues3();
    }
    visitDashboard = visitDashboard ?? [];
    newData?.map((ele: FieldDisplay) => {
      if (ele?.display?.title) {
        // If frame.display.title does not exist in markerLink then add it
        // else do nothing
        const exists = visitDashboard?.some((link: Visit) => link.queryName === ele?.display?.title);
        if (!exists) {
          visitDashboard.push({
            queryName: ele?.display?.title,
            dashboardUrl: '',
            dashboardName: '',
          });
          onChange({ ...toVisitDashboard, visitDashboard });
        }
      }
    });
  };
  const onDashboardUrlChange = (target: any, idx: any) => {
    visitDashboard[idx] = { ...visitDashboard[idx], dashboardUrl: target.value, dashboardName: target.label };
    onChange({ ...toVisitDashboard, visitDashboard });
  };

  const onUrlChangeCustomInput = (value: string, idx: number, name: boolean) => {
    if (name) {
      visitDashboard[idx] = { ...visitDashboard[idx], dashboardName: value };
    } else {
      visitDashboard[idx] = { ...visitDashboard[idx], dashboardUrl: value };
    }
    onChange({ ...toVisitDashboard, visitDashboard });
  };

  const handleDrawerLinkTime = (value: boolean) => {
    onChange({ ...toVisitDashboard, drawerLinkTime: value });
  };
  const handleRemoveLink = (idx: number) => {
    const newVisitDashboard = visitDashboard?.filter((dash, index) => index !== idx);
    onChange({ ...toVisitDashboard, visitDashboard: newVisitDashboard });
  };

  return (
    <>
      <DashboardTime value={drawerLinkTime} drawerLinkTime={handleDrawerLinkTime} />
      <VerticalGroup spacing="xs">
        {visitDashboard &&
          Object.keys(visitDashboard).map((key: any, index) => {
            return (
              <DashExpand
                key={index}
                title={visitDashboard[key].queryName}
                option={visitDashboard[key]}
                index={index}
                onUrlChangeCustomInput={onUrlChangeCustomInput}
                onDashboardChange={onDashboardUrlChange}
                handleRemoveLink={handleRemoveLink}
              />
            );
          })}
        <div className="gf-form gf-form--grow">
          <Button icon="refresh" onClick={onQueryReload} variant="secondary" size="md">
            Refresh Queries
          </Button>
        </div>
      </VerticalGroup>
    </>
  );
}
