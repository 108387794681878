import { Card, Icon, IconName } from '@grafana/ui';
import React, { Component } from 'react';

type AlertCardProps = {
  alertItem: any;
};

export default class AlertCard extends Component<AlertCardProps> {
  render() {
    const { alertItem } = this.props;
    return (
      <>
        <Card heading={`${alertItem.title}`}>
          <Card.Figure>
            <Icon
              size="xl"
              name={alertItem.stateClass.iconClass as IconName}
              className={`alert-rule-item__icon ${alertItem.stateClass.stateClass}`}
            />
          </Card.Figure>
          <Card.Meta>
            <span>
              <span key="text" className={alertItem.stateClass.stateClass}>
                {alertItem.stateClass.text}
              </span>{' '}
              for {alertItem.stateAge}
            </span>
          </Card.Meta>
        </Card>
      </>
    );
  }
}
