import React, { createContext, useState } from 'react';

export const TooltipContext = createContext({
  showInfo: {
    onClick: false,
    onHover: false,
  },
  setShowInfo: (showInfo: any) => {},
});
// * onClick - hold the marker on map
export const TooltipProvider = (props: any) => {
  const [showInfo, setShowInfo] = useState({
    onClick: false,
    onHover: false,
  });
  return <TooltipContext.Provider value={{ showInfo, setShowInfo }}>{props.children}</TooltipContext.Provider>;
};
