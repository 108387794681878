import { GrafanaTheme } from '@grafana/data';
import tinycolor from 'tinycolor2';
import { css } from '@emotion/css';

export const ReactDiffThemable = (theme: GrafanaTheme) => {
  return {
    variables: {
      light: {
        diffViewerBackground: theme.colors.bg2,
        diffViewerColor: theme.colors.text,
        addedBackground: tinycolor(theme.palette.greenShade).setAlpha(0.3).toString(),
        addedColor: 'black',
        removedBackground: tinycolor(theme.palette.redShade).setAlpha(0.3).toString(),
        removedColor: 'black',
        wordAddedBackground: tinycolor(theme.palette.greenBase).setAlpha(0.4).toString(),
        wordRemovedBackground: tinycolor(theme.palette.redBase).setAlpha(0.4).toString(),
        addedGutterBackground: tinycolor(theme.palette.greenShade).setAlpha(0.2).toString(),
        removedGutterBackground: tinycolor(theme.palette.redShade).setAlpha(0.2).toString(),
        gutterBackground: theme.colors.bg1,
        gutterBackgroundDark: theme.colors.bg1,
        highlightBackground: tinycolor(theme.colors.bg1).setAlpha(0.4).toString(),
        highlightGutterBackground: tinycolor(theme.colors.bg1).setAlpha(0.2).toString(),
        codeFoldGutterBackground: theme.colors.bg2,
        codeFoldBackground: theme.colors.bg2,
        emptyLineBackground: theme.colors.bg2,
        gutterColor: theme.colors.linkDisabled,
        addedGutterColor: theme.colors.text,
        removedGutterColor: theme.colors.text,
        codeFoldContentColor: theme.colors.linkDisabled,
        diffViewerTitleBackground: theme.colors.bg2,
        diffViewerTitleColor: theme.colors.linkDisabled,
        diffViewerTitleBorderColor: theme.colors.border1,
      },
      dark: {
        diffViewerBackground: theme.colors.bg2,
        diffViewerColor: theme.colors.text,
        addedBackground: tinycolor(theme.palette.greenShade).setAlpha(0.3).toString(),
        addedColor: 'white',
        removedBackground: tinycolor(theme.palette.redShade).setAlpha(0.3).toString(),
        removedColor: 'white',
        wordAddedBackground: tinycolor(theme.palette.greenBase).setAlpha(0.4).toString(),
        wordRemovedBackground: tinycolor(theme.palette.redBase).setAlpha(0.4).toString(),
        addedGutterBackground: tinycolor(theme.palette.greenShade).setAlpha(0.2).toString(),
        removedGutterBackground: tinycolor(theme.palette.redShade).setAlpha(0.2).toString(),
        gutterBackground: theme.colors.bg1,
        gutterBackgroundDark: theme.colors.bg1,
        highlightBackground: tinycolor(theme.colors.bg1).setAlpha(0.4).toString(),
        highlightGutterBackground: tinycolor(theme.colors.bg1).setAlpha(0.2).toString(),
        codeFoldGutterBackground: theme.colors.bg2,
        codeFoldBackground: theme.colors.bg2,
        emptyLineBackground: theme.colors.bg2,
        gutterColor: theme.colors.linkDisabled,
        addedGutterColor: theme.colors.text,
        removedGutterColor: theme.colors.text,
        codeFoldContentColor: theme.colors.linkDisabled,
        diffViewerTitleBackground: theme.colors.bg2,
        diffViewerTitleColor: theme.colors.linkDisabled,
        diffViewerTitleBorderColor: theme.colors.border1,
      },
    },
    codeFold: {
      fontSize: theme.typography.size.sm,
    },
    gutter: `
      pre {
        color: ${tinycolor(theme.colors.linkDisabled).setAlpha(1).toString()};
        opacity: 0.61;
      }
    `,
  };
};

export const getDiffTitleStyles = (theme: GrafanaTheme) => ({
  embolden: css`
    font-weight: ${theme.typography.weight.bold};
  `,
  add: css`
    color: ${theme.colors.textBlue};
  `,
  replace: css`
    color: ${theme.colors.textBlue};
  `,
  move: css`
    color: ${theme.colors.textBlue};
  `,
  copy: css`
    color: ${theme.colors.textBlue};
  `,
  _get: css`
    color: ${theme.colors.textBlue};
  `,
  test: css`
    color: ${theme.colors.textBlue};
  `,
  remove: css`
    color: ${theme.colors.textBlue};
  `,
  withoutDiff: css`
    margin-bottom: ${theme.spacing.md};
  `,
});
