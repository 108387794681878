import React from 'react';
import { Drawer, LinkButton } from '@grafana/ui';
import { TimeRange } from '@grafana/data';

/**
 * custom drawer for dashboard visit
 * @typedef {Object} CDrawerI
 * @property {DrawerData} infoData
 * @property {function} onClose
 * @property {TimeRange} timeRange
 */
export interface CDrawerII {
  infoData: DrawerData;
  onClose: () => void;
  timeRange?: TimeRange;
}

export interface DrawerData {
  url: string;
  location?: string;
}

/**
 * LinkDrawer opens a drawer for a link
 * @param {CDrawerII}
 * @returns {JSX.Element}
 */
export function LinkDrawer({ infoData, onClose, timeRange }: CDrawerII): JSX.Element {
  const link =
    timeRange !== undefined
      ? infoData.url.substring(1) + `?orgId=1&from=${timeRange?.raw.from}&to=${timeRange?.raw.to}`
      : infoData.url.substring(1);

  return (
    <Drawer
      title={titleParser(infoData)}
      subtitle={
        <LinkButton target="_blank" variant="link" icon="external-link" className="max-width-7" href={link}>
          Open Dashboard
        </LinkButton>
      }
      width="50%"
      onClose={onClose}
      expandable
    >
      <iframe src={findKioskModel(infoData.url)} width="100%" height="100%" frameBorder="0"></iframe>
    </Drawer>
  );
}

const titleParser = (infoData: DrawerData): string => {
  if (infoData.location) {
    return infoData.location;
  }
  // Derive title from the url
  try {
    return infoData.url.split('/').pop()!.split('?')[0];
  } catch {
    return '';
  }
};

/**
 * Find kiosk mode for a URL
 * @param url - URL to check
 * @returns url with kiosk enabled
 */
const findKioskModel = (url: string) => {
  if (url.includes('?')) {
    return url + '&kiosk';
  }
  return url + '?kiosk';
};
