import React, { useState } from 'react';
import { OSMapLayerState } from '../../../types/types';
import { Collapse } from '@grafana/ui';
import { Onemarker } from '../../../types/interface';
import { Marker } from './Marker';
import Map from 'ol/Map';

/**
 * Interface representing custom props for expanding a marker.
 *
 * @interface PropsMarkerExpand
 * @property {Onemarker} marker - The marker to expand.
 * @property {number} Idx - The index of the marker.
 * @property {OSMapLayerState<any>} layer - The OSMapLayerState for the component.
 * @property {Map | undefined} map - The Map object, if available.
 * @property {any} queries - Queries associated with the marker.
 */
export interface PropsMarkerExpand {
  marker: Onemarker;
  Idx: number;
  layer: OSMapLayerState<any>;
  map: Map | undefined;
  queries: any;
}

/**
 * Functional component for expanding a marker.
 *
 * @param {PropsMarkerExpand} props - The props for the MarkerExpand component.
 * @returns {React.ReactNode} The expanded marker component.
 */
export function MarkerExpand(props: PropsMarkerExpand) {
  const { marker, Idx, layer, map, queries } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Collapse
      label={'Marker ' + (Idx + 1)}
      isOpen={isOpen}
      onToggle={() => setIsOpen(!isOpen)}
      collapsible={true}
      overflowOffMap={true}
    >
      <Marker marker={marker} Idx={Idx} layer={layer} map={map} queries={queries} />
    </Collapse>
  );
}
