import { css } from '@emotion/css';
import { GrafanaTheme } from '@grafana/data';
import { Button, ColorPicker, InlineField, InlineFieldRow, InlineLabel, Input, Select, Slider } from '@grafana/ui';
import { config } from 'app/core/config';
import { cloneDeep } from 'lodash';
import { FeatureLike } from 'ol/Feature';
import React from 'react';
import { useObservable } from 'react-use';
import { ReplaySubject } from 'rxjs';
import { ComparisonOperation } from '../Layers/geojsonLayer/default';
import { FeatureStyleConfig } from '../Layers/geojsonLayer/type';
import { getIconOptions } from '../custom/CustomSymbol';
import { OSMapLayerState } from '../types/types';
import { getSelectionInfo } from '../utils/selection';
import { LABEL_WIDTH, setProperty } from './GeojsonStyle';
import { DEFAULT_STYLE_RULE, comparators } from './default';
import { LayerContentInfo } from './type';
import { getUniqueFeatureValues } from './util';

/**
 * Render the style configuration for a GeoJSON layer.
 *
 * @param {Props} props - The component's props.
 * @param {OSMapLayerState<any>} props.layer - The layer state.
 * @param {Observable<LayerContentInfo | undefined>} props.layerInfoObj - An observable containing layer content information.
 * @param {FeatureStyleConfig} props.value - The feature style configuration.
 * @param {ReplaySubject<FeatureLike[]>} props.featuresObj - A replay subject containing an array of feature-like objects.
 * @param {number} props.ruleIdx - The index of the style rule within the configuration.
 * @returns {React.ReactNode | null} The rendered GeoJSON style configuration.
 */
interface Props {
  layerInfoObj: any;
  layer: OSMapLayerState<any>;
  value: FeatureStyleConfig;
  featuresObj: ReplaySubject<FeatureLike[]>;
  ruleIdx: number;
}

export function GeojsonRuleStyle({ layerInfoObj, layer, value, featuresObj, ruleIdx }: Props) {
  const features = useObservable(featuresObj);
  const layerInfo: LayerContentInfo | undefined = useObservable(layerInfoObj);

  if (!features || !layerInfo) {
    return null;
  }

  const styles = getStyles(config.theme);
  const uniqueSelectables = () => {
    const key = value?.check?.property;
    if (
      key &&
      features &&
      (value.check?.operation === ComparisonOperation.EQ || value.check?.operation === ComparisonOperation.NEQ)
    ) {
      return getUniqueFeatureValues(features, key).map((v) => {
        let newValue;
        let isNewValueNumber = !isNaN(Number(v));

        if (isNewValueNumber) {
          newValue = {
            value: Number(v),
            label: v,
          };
        } else {
          newValue = { value: v, label: v };
        }

        return newValue;
      });
    }
    return [];
  };

  const check = value.check ?? DEFAULT_STYLE_RULE.check!;
  const propv = getSelectionInfo(check.property, layerInfo?.properties);
  const valuev = getSelectionInfo(check.value, uniqueSelectables());

  const newRules = cloneDeep(layer.options.config.rules);

  const onChangeProperty = (path: string, val: any) => {
    const newValueObj = setProperty(Object.assign({}, value), path, val);
    newRules[ruleIdx] = newValueObj;
    const newOpt = setProperty(cloneDeep(layer.options), 'config.rules', newRules);
    layer.onChange(newOpt);
  };

  return (
    <div className={styles.rule}>
      <InlineFieldRow className={styles.row}>
        <InlineField label="Rule" labelWidth={LABEL_WIDTH} grow={true}>
          <Select
            menuShouldPortal
            placeholder={'Feature property'}
            value={propv.current}
            options={propv.options}
            onChange={(e) => onChangeProperty('check.property', e.value)}
            aria-label={'Feature property'}
          />
        </InlineField>
        <InlineField className={styles.inline}>
          <Select
            menuShouldPortal
            value={comparators.find((v) => v.value === check.operation)}
            onChange={(e) => onChangeProperty('check.operation', e.value)}
            options={comparators}
            aria-label={'Comparison operator'}
            width={8}
          />
        </InlineField>
        <InlineField className={styles.inline} grow={true}>
          <>
            {(check.operation === ComparisonOperation.EQ || check.operation === ComparisonOperation.NEQ) && (
              <Select
                menuShouldPortal
                placeholder={'value'}
                value={valuev.current}
                options={valuev.options}
                aria-label={'Comparison value'}
                onChange={(e) => onChangeProperty('check.value', e.value || '')}
              />
            )}
            {check.operation !== ComparisonOperation.EQ && (
              <Input
                type="number"
                key={`${check.property}/${check.operation}`}
                value={!isNaN(Number(check.value)) ? Number(check.value) : 0}
                placeholder="numeric value"
                onChange={(e) => onChangeProperty('check.value', Number(e.currentTarget.value))}
              />
            )}
          </>
        </InlineField>
        <Button
          size="md"
          onClick={() => {
            newRules.splice(ruleIdx, 1);
            const newOpt = setProperty(cloneDeep(layer.options), 'config.rules', newRules);
            layer.onChange(newOpt);
          }}
          variant="destructive"
          aria-label={'Delete style rule'}
          className={styles.button}
        >
          <i className="fa fa-trash" aria-hidden="true"></i>
        </Button>
      </InlineFieldRow>
      {layerInfo.geometryType === 'point' && (
        <>
          <InlineField label="Icon" labelWidth={LABEL_WIDTH} grow={true}>
            <Select
              menuShouldPortal
              placeholder={'Circle'}
              value={value.style?.symbol || 'circle'}
              options={getIconOptions}
              onChange={(e) => onChangeProperty('style.symbol', e.value)}
              aria-label={'icon select'}
            />
          </InlineField>
          <InlineField label="Icon Size" labelWidth={LABEL_WIDTH} grow={true}>
            <Input
              type="number"
              key={`${value.style?.size}`}
              value={value.style?.size || 3}
              placeholder="numeric value"
              onChange={(e) => onChangeProperty('style.size', Number(e.currentTarget.value))}
            />
          </InlineField>
          <InlineField label="Rotation" labelWidth={LABEL_WIDTH} grow={true}>
            <Input
              min={0}
              max={360}
              type="number"
              value={value.style?.rotation || 0}
              placeholder="numeric value"
              onChange={(e) => onChangeProperty('style.rotation', Number(e.currentTarget.value))}
            />
          </InlineField>
        </>
      )}
      <InlineFieldRow>
        <InlineField label="Color" labelWidth={LABEL_WIDTH}>
          <InlineLabel width={4}>
            <ColorPicker
              color={value.style?.color || 'dark-red'}
              onChange={(color) => onChangeProperty('style.color', color)}
              enableNamedColors={true}
            />
          </InlineLabel>
        </InlineField>
      </InlineFieldRow>
      <InlineField label="Fil Opacity" labelWidth={LABEL_WIDTH}>
        <Slider
          min={0}
          max={1}
          step={0.1}
          value={value.style?.opacity || 1}
          onChange={(v) => onChangeProperty('style.opacity', v)}
        />
      </InlineField>
    </div>
  );
}

const getStyles = (theme: GrafanaTheme) => ({
  rule: css`
    margin-bottom: ${theme.spacing.sm};
  `,
  row: css`
    display: flex;
    margin-bottom: 4px;
  `,
  inline: css`
    margin-bottom: 0;
    margin-left: 4px;
  `,
  button: css`
    margin-left: 4px;
  `,
});
