/**
 * Converts a DataFrame object into a CSV string.
 * @param {Object} dataFrame - The DataFrame object to convert.
 * @returns {string} The CSV string representation of the DataFrame.
 */
export function convertToCSV(dataFrame: any): string {
  const fields = dataFrame.fields;
  let csvString = '';
  let timeIndex: number;
  const rowArray = [];

  for (let i = 0; i < fields.length; i++) {
    const ele = fields[i];
    if (ele.type === 'time') {
      timeIndex = i;
    }
    csvString += `${ele.name}${i === fields.length - 1 ? '\n' : ','}`;
  }
  for (let i = 0; i < fields[0].values.length; i++) {
    const row = fields.map((field: any, j: number) => {
      const value = field.values.get(i);
      if (j === timeIndex && value && typeof value === 'number') {
        const date = new Date(value);
        return covertToRequiredDateString(date);
      } else {
        return value;
      }
    });
    if (row.every(Boolean)) {
      rowArray.push(row);
    }
  }
  for (let i = 0; i < rowArray.length; i++) {
    csvString += `${rowArray[i].join(',')}${i === rowArray.length - 1 ? '' : '\n'}`;
  }
  return csvString;
}

export const covertToRequiredDateString = (date: Date) => {
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  const seconds = ('0' + date.getSeconds()).slice(-2);
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedDate;
};
