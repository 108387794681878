import React, { PureComponent } from 'react';
import { LegendDisplayMode, DataFrame, FieldType, TimeRange, VisibilityMode } from '@grafana/data';

import {
  PanelContext,
  UPlotGraph,
  UPlotGraphProps,
  UPlotConfigBuild,
  UPlotLayout,
  UPlotLegend,
  UPlotLegendItem,
} from '@grafana/ui';
import { preparePlotConfigBuilder } from './utils/utils';
import { TimelineOptions, ValueAlignment } from './types';

export interface TimelineProps
  extends TimelineOptions,
    Omit<UPlotGraphProps, 'prepareConfig' | 'chartProps' | 'prepareLegend'> {
  mode: string;
  rowHeight: number;
  colWidth?: number;
  legendItems?: UPlotLegendItem[];
  showValue: VisibilityMode;
  alignValue?: ValueAlignment;
}

const chartProps = ['rowHeight', 'colWidth', 'showValue', 'mergeValues', 'alignValue', 'yAxisShowHide'];

export class TimelineChart extends PureComponent<TimelineProps> {
  panelContext: PanelContext = {} as PanelContext;

  prepareConfig = (timelineFrame: DataFrame, allFrames: DataFrame[], getTimeRange: () => TimeRange) => {
    this.panelContext = this.context as PanelContext;
    const { eventBus } = this.panelContext;

    return preparePlotConfigBuilder({
      frame: timelineFrame,
      getTimeRange,
      eventBus,
      allFrames: this.props.frames,
      ...this.props,
      rowHeight: timelineFrame.fields.length > 2 ? this.props.rowHeight : 1, // if only one item, give full height
    });
  };

  prepareLegend = (config: UPlotConfigBuild) => {
    const { legend, legendItems } = this.props;

    if (!config || !legendItems || !legend || legend.displayMode === LegendDisplayMode.Hidden) {
      return null;
    }

    return (
      <UPlotLayout.Legend placement={legend.placement}>
        <UPlotLegend placement={legend.placement} items={legendItems} displayMode={legend.displayMode} readonly />
      </UPlotLayout.Legend>
    );
  };

  render() {
    return (
      <UPlotGraph
        {...this.props}
        fields={{
          x: (f) => f.type === FieldType.time,
          y: (f) => f.type === FieldType.number || f.type === FieldType.boolean || f.type === FieldType.string,
        }}
        prepareConfig={this.prepareConfig}
        chartProps={chartProps}
        prepareLegend={this.prepareLegend}
      />
    );
  }
}
