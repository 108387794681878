import { PanelModel } from '@grafana/data';
import { BarChartOptions, BarGraphMode } from './types';

export const barGraphMigration = (panel: PanelModel<BarChartOptions>): Partial<BarChartOptions> => {
  const options: any = panel.options || {};
  // First check if the key exists or not
  if ('timeSeries' in options) {
    if (options.timeSeries) {
      options.graphMode = BarGraphMode.Timeseries;
    } else {
      options.graphMode = BarGraphMode.Bar;
    }
    delete options.timeSeries;
  }
  return options;
};
