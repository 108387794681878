import { PanelOptionsEditorBuilder } from '@grafana/data';
import { MapPanelOptions } from '../types/types';

export function markerlayerOptions<T extends MapPanelOptions>(builder: PanelOptionsEditorBuilder<T>) {
  builder
    .addNumberInput({
      path: 'markerSett.minMarkerSize',
      name: 'Min Marker Scale',
      defaultValue: 1,
      category: ['Marker Editor'],
    })
    .addNumberInput({
      path: 'markerSett.maxMarkerSize',
      name: 'Max Marker Scale',
      defaultValue: 15,
      category: ['Marker Editor'],
    })
    .addBooleanSwitch({
      path: 'markerSett.fillColor',
      name: 'Marker Fill Color',
      defaultValue: true,
      category: ['Marker Editor'],
    })
    .addNumberInput({
      path: 'markerSett.strokeWeight',
      name: 'Marker Stroke Weight',
      defaultValue: 3,
      category: ['Marker Editor'],
    })
    .addNumberInput({
      path: 'markerSett.fillOpacity',
      name: 'Marker Fill Opacity',
      defaultValue: 0.5,
      settings: {
        min: 0,
        max: 1,
        step: 0.1,
      },
      category: ['Marker Editor'],
      showIf: (c) => c.markerSett.fillColor,
    });
}
