import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const PanelAdd: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 25 25"
      viewBox="0 0 25 25"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M7.8,1.1H4.9c-2.1,0-3.8,1.7-3.8,3.8v2.8c0,2.1,1.7,3.8,3.8,3.8h2.8c2.1,0,3.8-1.7,3.8-3.8V4.9
        C11.6,2.8,9.9,1.1,7.8,1.1z M9.7,7.8c0,1-0.9,1.9-1.9,1.9H4.9C3.9,9.7,3,8.8,3,7.8V4.9C3,3.9,3.9,3,4.9,3h2.8c1,0,1.9,0.9,1.9,1.9
        V7.8z M7.8,13.4H4.9c-2.1,0-3.8,1.7-3.8,3.8v2.8c0,2.1,1.7,3.8,3.8,3.8h2.8c2.1,0,3.8-1.7,3.8-3.8v-2.8C11.6,15.1,9.9,13.4,7.8,13.4
        z M9.7,20.1c0,1-0.9,1.9-1.9,1.9H4.9c-1,0-1.9-0.9-1.9-1.9v-2.8c0-1,0.9-1.9,1.9-1.9h2.8c1,0,1.9,0.9,1.9,1.9V20.1z M20.1,13.4h-2.8
        c-2.1,0-3.8,1.7-3.8,3.8v2.8c0,2.1,1.7,3.8,3.8,3.8h2.8c2.1,0,3.8-1.7,3.8-3.8v-2.8C23.9,15.1,22.2,13.4,20.1,13.4z M22,20.1
        c0,1-0.9,1.9-1.9,1.9h-2.8c-1,0-1.9-0.9-1.9-1.9v-2.8c0-1,0.9-1.9,1.9-1.9h2.8c1,0,1.9,0.9,1.9,1.9V20.1z M14.4,7.8h2.8v2.8
        c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9V7.8H22c0.5,0,0.9-0.4,0.9-0.9S22.5,5.9,22,5.9h-2.8V3c0-0.5-0.4-0.9-0.9-0.9
        c-0.5,0-0.9,0.4-0.9,0.9v2.8h-2.8c-0.5,0-0.9,0.4-0.9,0.9S13.9,7.8,14.4,7.8z"
      />
    </svg>
  );
};
