import React, { HTMLAttributes, useRef, useState } from 'react';
import { css, cx } from '@emotion/css';
import { Button, Form, HorizontalGroup, InlineField, TagsInput, TextArea, useStyles } from '@grafana/ui';
import { AnnotationEventUIModel, GrafanaTheme } from '@grafana/data';
import useClickAway from 'react-use/lib/useClickAway';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import { addAnnotations, deleteAnnotations, upDateAnnotations } from './api';
import { getDashboardSrv } from 'app/features/dashboard/services/DashboardSrv';

export interface AnnotationEditFormDTO {
  text: string;
  tags: string[];
}

interface AnnotationEditorFormProps extends HTMLAttributes<HTMLDivElement> {
  annotation: any;
  timeFormatter: (v: number) => string;
  onSave: () => void;
  onDismiss: () => void;
  panelID: number;
}

export const AnnotationEditorForm = React.forwardRef<HTMLDivElement, AnnotationEditorFormProps>(
  ({ annotation, onSave, onDismiss, timeFormatter, className, panelID, ...otherProps }, ref) => {
    const styles = useStyles(getStyles);
    const clickAwayRef = useRef(null);

    const [Tags, setTags] = useState<string[]>(annotation.tags ?? []);

    useClickAway(clickAwayRef, () => {
      onDismiss();
    });

    const [createAnnotationState, createAnnotation] = useAsyncFn(async (event: AnnotationEventUIModel) => {
      const isRegion = event.from !== event.to;
      const dash = getDashboardSrv().getCurrent();
      const annotation = {
        dashboardId: dash.id,
        panelId: panelID,
        isRegion,
        time: event.from,
        timeEnd: isRegion ? event.to : 0,
        tags: event.tags,
        text: event.text,
      };
      const result = await addAnnotations(annotation, panelID);
      if (onSave) {
        onSave();
      }
      return result;
    });

    const [updateAnnotationState, updateAnnotation] = useAsyncFn(async (event: AnnotationEventUIModel) => {
      const dash = getDashboardSrv().getCurrent();
      const isRegion = event.from !== event.to;
      const updateannotation = {
        id: event.id,
        dashboardId: dash.id,
        panelId: panelID,
        isRegion,
        time: event.from,
        timeEnd: isRegion ? event.to : 0,
        tags: event.tags,
        text: event.text,
      };
      const result = await upDateAnnotations(updateannotation, panelID);
      if (onSave) {
        onSave();
      }
      return result;
    });

    const isUpdatingAnnotation = annotation.id !== undefined;
    const isRegionAnnotation = annotation.time !== annotation.timeEnd;
    const operation = isUpdatingAnnotation ? updateAnnotation : createAnnotation;
    const stateIndicator = isUpdatingAnnotation ? updateAnnotationState : createAnnotationState;
    const ts = isRegionAnnotation
      ? `${timeFormatter(annotation.time)} - ${timeFormatter(annotation.timeEnd)}`
      : timeFormatter(annotation.time);

    const onSubmit = ({ tags, text }: AnnotationEditFormDTO) => {
      operation({
        id: annotation.id,
        tags: Tags,
        text,
        from: Math.round(annotation.time!),
        to: Math.round(annotation.timeEnd!),
      });
    };

    const form = (
      <div // Annotation editor
        ref={ref}
        className={cx(styles.editor, className)}
        {...otherProps}
      >
        <div className={styles.header}>
          <HorizontalGroup justify={'space-between'} align={'center'}>
            <div className={styles.title}> {isUpdatingAnnotation ? 'Edit Annotation' : 'Add Annotation'} </div>
            <div className={styles.ts}>{ts}</div>
          </HorizontalGroup>
        </div>
        <div className={styles.editorForm}>
          <Form<AnnotationEditFormDTO>
            onSubmit={onSubmit}
            defaultValues={{ text: annotation?.text, tags: annotation?.tags || [] }}
          >
            {({ register, errors, control }) => {
              return (
                <>
                  <InlineField
                    label={'Description'}
                    labelWidth={13}
                    invalid={!!errors.text}
                    style={{ marginBottom: '10px' }}
                  >
                    <TextArea
                      name="text"
                      ref={register({
                        required: 'Annotation description is required',
                      })}
                      rows={4}
                    />
                  </InlineField>
                  <InlineField label={'Tags'} labelWidth={13} style={{ marginBottom: '24px' }}>
                    <TagsInput tags={Tags} placeholder="Add tags" onChange={(tags: string[]) => setTags(tags)} />
                  </InlineField>
                  <HorizontalGroup justify="center" align="center">
                    <Button size={'md'} type={'submit'} disabled={stateIndicator?.loading}>
                      {isUpdatingAnnotation ? 'Edit Save' : 'Save'}
                    </Button>
                    {isUpdatingAnnotation && (
                      <Button
                        size={'md'}
                        onClick={async () => {
                          await deleteAnnotations(annotation.id, panelID);
                          if (onSave) {
                            onSave();
                          }
                        }}
                        variant="destructive"
                        disabled={stateIndicator?.loading}
                      >
                        Delete
                      </Button>
                    )}
                    <Button size={'md'} variant="secondary" onClick={onDismiss}>
                      Cancel
                    </Button>
                  </HorizontalGroup>
                </>
              );
            }}
          </Form>
        </div>
      </div>
    );

    return (
      <>
        <div className={styles.backdrop} />
        <div ref={clickAwayRef}>{form}</div>
      </>
    );
  }
);

AnnotationEditorForm.displayName = 'AnnotationEditorForm';

const getStyles = (theme: GrafanaTheme) => {
  return {
    backdrop: css`
      label: backdrop;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      z-index: ${theme.zIndex.navbarFixed};
    `,
    editorContainer: css`
      position: absolute;
      top: calc(100% + 10px);
      transform: translate3d(-50%, 0, 0);
    `,
    editor: css`
      background: ${theme.colors.bg1};
      box-shadow: 0px 13px 20px 1px ${theme.colors.bg2};
      z-index: 1030;
      border: 1px solid ${theme.colors.border1};
      border-radius: 2px;
      // width: 460px;
      width: fit-content;
    `,
    editorForm: css`
      padding: 8px;
    `,
    header: css`
      border-bottom: 1px solid ${theme.colors.border1};
      padding: 12px 8px;
      background: ${theme.colors.bg2};
    `,
    title: css`
      font-weight: 500;
      padding-right: 16px;
    `,
    ts: css`
      font-size: 0.8571428571428571rem;
      color: ${theme.palette.gray2};
    `,
  };
};
