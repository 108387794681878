import React from 'react';

export interface NoDataProps {
  width: number;
  height: number;
  color?: string;
  text?: string;
  factor?: number;
}

export function NoData(props: NoDataProps) {
  const { width, height, color, text, factor } = props;
  return (
    <div
      style={{
        width: width,
        height: height,
        color: color,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <h1
        style={{
          color: color,
          fontSize: width / (factor ?? 10) + 'px',
        }}
      >
        {text ?? 'No data'}
      </h1>
    </div>
  );
}
