import { GrafanaTheme, FieldDisplay } from '@grafana/data';
import { SankeyDataI, SankeyPanelOptions, NodeI, NodesLinksI } from '../types';

/**
 * This will create the final data, with the help of which the structure of the panel will be formed. Inside it will be the names of all the nodes and their links.
 * @function
 * @param {FieldDisplay} BigData - Field display
 * @param {GrafanaTheme} theme - Carbon theme
 * @param {SankeyDataI} sankeyData - Sankey data after refactoring variables, like from, to, query.
 * @returns {object} - Object containing layerData and layerColors
 */
export const prepareSankeyDataNew = (BigData: FieldDisplay[], theme: GrafanaTheme, sankeyData: SankeyDataI[]) => {
  var graph: NodesLinksI = { nodes: [], links: [] };
  sankeyData.map((data: SankeyDataI) => {
    for (let k = 0; k < BigData.length; k++) {
      if (
        BigData[k].display.title === data.query ||
        BigData[k].display.title === data.from ||
        BigData[k].display.title === data.to
      ) {
        graph.nodes.push({
          name: data.from,
          y0: 0,
          x0: 0,
          x1: 0,
          y1: 0,
          sourceLinks: 0,
          depth: 0,
          height: 0,
          targetLinks: 0,
          value: 0,
        });
        graph.nodes.push({
          name: data.to,
          y0: 0,
          x0: 0,
          x1: 0,
          y1: 0,
          sourceLinks: 0,
          depth: 0,
          height: 0,
          targetLinks: 0,
          value: 0,
        });
      }
    }
  });
  const obj = graph.nodes.filter((value, index, self) => index === self.findIndex((t) => t.name === value.name));
  graph.nodes = obj;
  sankeyData.map((data: SankeyDataI) => {
    for (let k = 0; k < BigData.length; k++) {
      if (
        BigData[k].display.title === data.query ||
        BigData[k].display.title === data.from ||
        BigData[k].display.title === data.to
      ) {
        graph.links.push({
          source: graph.nodes.findIndex((item: NodeI) => item.name === data.from),
          target: graph.nodes.findIndex((item: NodeI) => item.name === data.to),
          value: +BigData[k].display.text ?? BigData[k].display.numeric,
          layerColors: BigData[k].display.color ?? '',
          from: data.from,
          to: data.to,
          width: 0,
        });
      }
    }
  });

  return {
    // layerData,
    graph,
  };
};

export const prepareSankeyOptions = (options: SankeyPanelOptions, colorset: string[], theme: GrafanaTheme) => {
  return {
    sankey: {
      link: {
        colorMode: options.selectMode,
        colors: colorset,
      },
      node: {
        interactivity: options.selectNode,
        labelPadding: options.labelPadding,
        nodePadding: options.nodePadding,
        nodeAlign: 'right',
        width: options.width,
        colors: colorset,
        label: {
          color: theme.colors.text,
          fontSize: options.textsize,
        },
      },
    },
    tooltip: { isHtml: true },
  };
};
