import React, { Component } from 'react';
import { ItemDesc } from '../types';

interface Props {
  items: ItemDesc[];
  onSelected?: (item: ItemDesc) => void;
}

export class ItemList extends Component<Props, {}> {
  constructor(props: Props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {}

  onClick(event: React.MouseEvent) {
    if (this.props.onSelected) {
      for (const item of this.props.items) {
        if (item.name === event.currentTarget.textContent) {
          this.props.onSelected(item);
        }
      }
    }
  }

  render() {
    if (this.props.items.length === 0) {
      return null;
    }

    return (
      <div className="list-wrapper">
        <ol className="list">
          {this.props.items.map((item: ItemDesc, index: number) => {
            return (
              <li className="list-item" key={index} onClick={this.onClick}>
                {item.name}
                {/* <ListItemText primary={item.name} onClick={this.onClick} /> */}
              </li>
            );
          })}
        </ol>
      </div>
    );
  }
}
