import React from 'react';
import { Input, Button } from '@grafana/ui';
import { AxisEditor } from '../types';

interface Props {
  toEditAxis: AxisEditor[];
  onChange: (toEditAxis: any[]) => void;
  data: any;
}

export function EditAxis({ toEditAxis, onChange, data }: Props) {
  const onAxisTextChange = ({ target }: any) => {
    const obj = toEditAxis;
    Object.keys(obj).map((key: any, index) => {
      if (+obj[key].id === +target.name) {
        obj[key].name = target.value;
      }
    });
    onChange(obj);
  };

  const dataLoad = (event: React.MouseEvent) => {
    const newArr: AxisEditor[] = [];
    data.map((frame: any, index: number) => {
      newArr.push({
        id: `${index}`,
        name: `Column ${index + 1}`,
      });
    });
    onChange(newArr);
  };

  return (
    <div>
      {Object.keys(toEditAxis).map((key: any, index) => {
        return (
          <div className="gf-form gf-form--grow" key={toEditAxis[key].id}>
            <label className="gf-form-label width-9">{`Column ${+toEditAxis[key].id + 1}`}</label>
            <Input placeholder={`rename ${toEditAxis[key].name}`} onBlur={onAxisTextChange} name={toEditAxis[key].id} />
          </div>
        );
      })}
      <div className="gf-form gf-form--grow width-20">
        <label className="gf-form-label width-9">Reload Queries</label>
        <Button style={{ width: 'fit-content' }} onClick={dataLoad} variant="secondary" size="md">
          <i className="fa fa-refresh" aria-hidden="true"></i>
        </Button>
      </div>
    </div>
  );
}
