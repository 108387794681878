import {
  DataFrame,
  FALLBACK_COLOR,
  FieldColorModeId,
  FieldType,
  TimeZone,
  formattedValueToString,
  getDisplayProcessor,
  getFieldDisplayName,
} from '@grafana/data';
import React from 'react';
import { useTheme } from '../../../themes/ThemeContext';
import { TooltipMode } from '../../Chart/Tooltip';
import { TooltipContainer } from '../../Chart/TooltipContainer';
import { SeriesTable, SeriesTableRowProps } from '../../Graph/GraphTooltip/SeriesTable';
import { useGraphNGContext } from '../../GraphNG2/hooks';
import { Portal } from '../../Portal/Portal';
import { GraphGradientMode } from '../config';
import { usePlotContext } from '../context';
import { CursorPlugin } from './CursorPlugin';

interface TooltipPluginProps {
  mode?: TooltipMode;
  timeZone: TimeZone;
  data: DataFrame[];
  isTimeSeries?: boolean;
}

/**
 * @alpha
 */
export const TooltipPlugin: React.FC<TooltipPluginProps> = ({ mode = 'single', timeZone, ...otherProps }) => {
  const pluginId = 'PlotTooltip';
  const plotContext = usePlotContext();
  const graphContext = useGraphNGContext();
  const theme = useTheme();

  let xField = graphContext.getXAxisField();
  if (!xField) {
    return null;
  }

  const xFieldFmt = xField.display || getDisplayProcessor({ field: xField, timeZone });

  return (
    <CursorPlugin id={pluginId}>
      {({ focusedSeriesIdx, focusedPointIdx, coords }) => {
        if (!plotContext.getPlotInstance()) {
          return null;
        }
        let tooltip = null;

        // when no no cursor interaction
        if (focusedPointIdx === null) {
          return null;
        }

        const xVal = xFieldFmt(xField!.values.get(focusedPointIdx)).text;

        // origin field/frame indexes for inspecting the data
        const originFieldIndex = focusedSeriesIdx
          ? graphContext.mapSeriesIndexToDataFrameFieldIndex(focusedSeriesIdx)
          : null;

        // when interacting with a point in single mode

        if (mode === 'single' && originFieldIndex !== null && focusedSeriesIdx !== null) {
          const lableName = otherProps.data[originFieldIndex.frameIndex].fields[originFieldIndex.fieldIndex];

          const field = graphContext.timelineData.fields[focusedSeriesIdx!];
          const plotSeries = plotContext.getSeries();
          const fieldFmt = field.display || getDisplayProcessor({ field, timeZone, theme });

          const value = fieldFmt(field.values.get(focusedPointIdx));

          const isColorFromPalette =
            field.config?.color?.mode === FieldColorModeId.PaletteClassic ||
            field.config?.color?.mode === FieldColorModeId.PaletteCarbon;

          let pointColor = (plotSeries[focusedSeriesIdx!].points?.stroke as any)() || FALLBACK_COLOR;
          const pixel = plotContext.getPlotInstance()?.ctx.getImageData(coords.viewport.x, coords.viewport.y, 1, 1)
            .data;
          if ((plotSeries[focusedSeriesIdx!].points?.stroke as any)() === '' && pixel) {
            pointColor = `rgb(${pixel[0]}, ${pixel[1]}, ${pixel[2]})`;
          }
          const tooltipColor =
            field.config.custom.drawStyle === 'points'
              ? plotSeries.length === 2 && !isColorFromPalette
                ? value.color
                : (plotSeries[focusedSeriesIdx!].points?.stroke as any)()
              : (field.config.custom.gradientMode === GraphGradientMode.Scheme
                  ? isColorFromPalette
                    ? () => pointColor
                    : () => value.color || FALLBACK_COLOR
                  : (plotSeries[focusedSeriesIdx!].stroke as any))() || FALLBACK_COLOR;

          //* Hide Tooltip when series data have null or zero value.
          if (field.config.custom?.hideSeries && field.config.custom.hideSeries.withOnlyZeros) {
            const HideSeries =
              formattedValueToString(value) === '0' || formattedValueToString(value) === null
                ? ''
                : {
                    color: tooltipColor,
                    label: getFieldDisplayName(lableName, otherProps.data[originFieldIndex.frameIndex]),
                    value: value ? formattedValueToString(value) : null,
                  };
            tooltip = <SeriesTable series={HideSeries === '' ? [] : [HideSeries]} timestamp={xVal} />;
          } else {
            tooltip = (
              <SeriesTable
                series={[
                  {
                    color: tooltipColor,
                    label: getFieldDisplayName(lableName, otherProps.data[originFieldIndex.frameIndex]),
                    value: value ? formattedValueToString(value) : null,
                  },
                ]}
                timestamp={xVal}
              />
            );
          }
        }

        if (mode === 'multi' && focusedSeriesIdx !== null) {
          let series: SeriesTableRowProps[] = [];
          const plotSeries = plotContext.getSeries();

          for (let i = 0; i < plotSeries.length; i++) {
            const dataFrameFieldIndex = graphContext.mapSeriesIndexToDataFrameFieldIndex(i);

            const frame = otherProps.data[dataFrameFieldIndex.frameIndex];
            const field = otherProps.data[dataFrameFieldIndex.frameIndex].fields[dataFrameFieldIndex.fieldIndex];

            if (
              field === xField ||
              field.type === FieldType.time ||
              field.type !== FieldType.number ||
              field.config.custom?.hideFrom?.tooltip
            ) {
              continue;
            }
            const isColorFromPalette =
              field.config?.color?.mode === FieldColorModeId.PaletteClassic ||
              field.config?.color?.mode === FieldColorModeId.PaletteCarbon;

            const valueField = graphContext.timelineData.fields[i];
            const value = valueField.display!(valueField.values.get(focusedPointIdx));
            let pointColor = (plotSeries[focusedSeriesIdx!].points?.stroke as any)() || FALLBACK_COLOR;
            const pixel = plotContext.getPlotInstance()?.ctx.getImageData(coords.viewport.x, coords.viewport.y, 1, 1)
              .data;
            if ((plotSeries[focusedSeriesIdx!].points?.stroke as any)() === '' && pixel) {
              pointColor = `rgb(${pixel[0]}, ${pixel[1]}, ${pixel[2]})`;
            }
            const tooltipColorForGradient = otherProps.isTimeSeries
              ? (i > 0 && field.config.custom.drawStyle === 'points'
                  ? plotSeries.length === 2 && !isColorFromPalette
                    ? value.color
                    : (plotSeries[i].points?.stroke as any)()
                  : (field.config.custom.gradientMode === GraphGradientMode.Scheme
                      ? isColorFromPalette
                        ? () => pointColor
                        : () => value.color || FALLBACK_COLOR
                      : (plotSeries[i].stroke as any))()) || FALLBACK_COLOR
              : (plotSeries[focusedSeriesIdx!].stroke as any)();

            //* Hide Tooltip when multi series data have null or zero value.
            if (field.config.custom?.hideSeries && field.config.custom.hideSeries.withOnlyZeros) {
              const HideSeries =
                formattedValueToString(value) === '0' || formattedValueToString(value) === null
                  ? ''
                  : {
                      // TODO: align with uPlot typings
                      color: tooltipColorForGradient,
                      label: getFieldDisplayName(field, frame),
                      value: value ? formattedValueToString(value) : null,
                      isActive: originFieldIndex
                        ? dataFrameFieldIndex.frameIndex === originFieldIndex.frameIndex &&
                          dataFrameFieldIndex.fieldIndex === originFieldIndex.fieldIndex
                        : false,
                    };
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              HideSeries === '' ? '' : series.push(HideSeries);
            } else {
              series.push({
                // TODO: align with uPlot typings
                color: tooltipColorForGradient,
                label: getFieldDisplayName(field, frame),
                value: value ? formattedValueToString(value) : null,
                isActive: originFieldIndex
                  ? dataFrameFieldIndex.frameIndex === originFieldIndex.frameIndex &&
                    dataFrameFieldIndex.fieldIndex === originFieldIndex.fieldIndex
                  : false,
              });
            }
          }
          tooltip = <SeriesTable series={series} timestamp={xVal} />;
        }

        if (!tooltip) {
          return null;
        }

        return (
          <Portal>
            <TooltipContainer position={{ x: coords.viewport.x, y: coords.viewport.y }} offset={{ x: 10, y: 10 }}>
              {tooltip}
            </TooltipContainer>
          </Portal>
        );
      }}
    </CursorPlugin>
  );
};
