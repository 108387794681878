import { ComparisonOperation, defaultStyleConfig } from './default';

/**
 * Configuration for styling features on a map.
 *
 * @interface StyleConfig
 * @property {string} [color] - The color of the feature (e.g., "#FF0000" for red).
 * @property {number} [opacity] - The opacity of the feature (a value between 0 and 1).
 * @property {number} [lineWidth] - The width of the feature's line (for lines/polygons).
 * @property {number} [size] - The size of the feature (e.g., for point features).
 * @property {string} [symbol] - The symbol used to represent the feature (e.g., "circle").
 * @property {number} [rotation] - The rotation angle of the feature in degrees.
 */
export interface StyleConfig {
  color?: string;
  opacity?: number;
  lineWidth?: number;
  size?: number;
  symbol?: string;
  rotation?: number;
}

/**
 * Configuration options for the GeoJSON Mapper.
 *
 * @interface GeoJSONMapperConfig
 * @property {string} [src] - URL for a GeoJSON file or any custom URL. If not provided, data should be supplied directly.
 * @property {FeatureStyleConfig[]} rules - An array of feature style configurations to pick based on rules.
 * @property {StyleConfig} style - The default style configuration applied if no rules match.
 */
export interface GeoJSONMapperConfig {
  src?: string;
  rules: FeatureStyleConfig[];
  style: StyleConfig;
}

/**
 * Default configuration options for the GeoJSON Mapper.
 *
 * @constant {GeoJSONMapperConfig} defaultOptions
 * @property {string} src - The source path to the GeoJSON data.
 * @property {FeatureRuleConfig[]} rules - An array of feature rules to apply.
 * @property {FeatureStyleConfig} style - The default style configuration for features.
 */
export const defaultOptions: GeoJSONMapperConfig = {
  src: 'public/maps/countries.geojson',
  rules: [],
  style: defaultStyleConfig,
};

/**
 * Represents the configuration for styling a feature.
 *
 * @interface FeatureStyleConfig
 * @property {StyleConfig} [style] - The style configuration for the feature.
 * @property {FeatureRuleConfig} [check] - The rule configuration for the feature.
 */
export interface FeatureStyleConfig {
  style?: StyleConfig;
  check?: FeatureRuleConfig;
}

/**
 * Represents the configuration for a feature rule.
 *
 * @interface FeatureRuleConfig
 * @property {string} property - The property to which the rule applies.
 * @property {ComparisonOperation} operation - The comparison operation used in the rule.
 * @property {string|boolean|number} value - The value used in the comparison.
 */
export interface FeatureRuleConfig {
  property: string;
  operation: ComparisonOperation;
  value: string | boolean | number;
}
