import React, { useContext, useCallback } from 'react';
import { Marker } from '@react-google-maps/api';
import { TooltipContext, TooltipDataContext } from '../../../context';
import { MapPanelOptions } from '../../../types/types';

declare var google: any;

/**
 * @typedef {Object} MarkerProps
 * @property {any} lat
 * @property {any} lng
 * @property {number} index
 * @property {MapPanelOptions} options
 * @property {number} size
 * @property {any} [clusterer]
 * @property {any} value
 * @property {string} [markerQueryType] auto geohash custom
 * @property {function} [handleDashboardVisit]
 * @property {any} [name]
 * @property {string} [valueColor]
 */
interface MarkerProps {
  lat: any;
  lng: any;
  index: number;
  options: MapPanelOptions;
  size: number;
  clusterer?: any;
  valueColor?: string;
  value: any;
  name?: any;
  markerQueryType: string; // auto geohash custom
  handleDashboardVisit?: (text: string, custom?: string) => void;
}
/**
 * @function
 * @param {MarkerProps} props
 * @returns {JSX.Element}
 */
export const MarkerComponent: React.FC<MarkerProps> = (props) => {
  const {
    lat,
    lng,
    index,
    options,
    size,
    clusterer,
    valueColor,
    value,
    name,
    markerQueryType,
    handleDashboardVisit,
  } = props;
  const { useCustomMarker, markerSett, markerSymbol, customMarkerSymbol, isMarkerCluster } = options;
  const { showInfo, setShowInfo } = useContext(TooltipContext);
  const { setTooltipData } = useContext(TooltipDataContext);

  // * sending mouseOver, mouseOut and mouseClick(with data) event to tooltip
  const onMouseOverMarker = useCallback(
    (data: any, markers: any, type: string, name?: string) => {
      setTooltipData({
        lat: data.latLng.lat(),
        lng: data.latLng.lng(),
        data: markers,
        layer: 'Marker Editor',
        tooltipType: type,
        locField: name,
      });
      setShowInfo({ ...showInfo, onHover: true });
    },
    [showInfo]
  );
  const onMouseOutMarker = useCallback(() => {
    setShowInfo({ ...showInfo, onHover: false });
  }, [showInfo]);

  const onMouseClickMarker = useCallback(
    (markers: any, type: string, name?: string) => {
      if (type === 'custom') {
        if (markers) {
          if (markers[0].customName !== undefined && markers[0].customName !== '') {
            handleDashboardVisit!(markers[0].customName, markers[0].url);
            return;
          }
          handleDashboardVisit!(markers[0].name, markers[0].url);
          return;
        }
      }
      if (type === 'geohash' && name) {
        handleDashboardVisit!(name);
      }
    },
    [showInfo]
  );

  return (
    <Marker
      key={`${markerQueryType}--marker-${index}`}
      position={{ lat: lat, lng: lng }}
      // icon={'http://maps.google.com/mapfiles/kml/shapes/placemark_circle_highlight.png'}
      icon={
        !useCustomMarker
          ? {
              path: markerSymbol,
              strokeColor: valueColor,
              strokeWeight: markerSett.strokeWeight,
              fillColor: markerSett.fillColor ? valueColor : 'transparent',
              fillOpacity: markerSett.fillOpacity,
              scale: size,
            }
          : {
              url:
                customMarkerSymbol.url || 'http://maps.google.com/mapfiles/kml/shapes/placemark_circle_highlight.png',
              scaledSize: new google.maps.Size(size, size),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(0, 0),
            }
      }
      onMouseOver={(data) => onMouseOverMarker(data, value, markerQueryType, name)}
      onMouseOut={onMouseOutMarker}
      onClick={(data) => onMouseClickMarker(value, markerQueryType, name)}
      clusterer={isMarkerCluster ? clusterer : undefined}
    />
  );
};
