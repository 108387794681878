import { TileMethod } from './types';
import {
  treemapSquarify,
  treemapBinary,
  treemapDice,
  treemapResquarify,
  treemapSlice,
  treemapSliceDice,
} from '@visx/hierarchy';

export function findTileMethod(tileMethod: string) {
  if (tileMethod === TileMethod.treemapSquarify) {
    return treemapSquarify;
  } else if (tileMethod === TileMethod.treemapResquarify) {
    return treemapResquarify;
  } else if (tileMethod === TileMethod.treemapBinary) {
    return treemapBinary;
  } else if (tileMethod === TileMethod.treemapDice) {
    return treemapDice;
  } else if (tileMethod === TileMethod.treemapSlice) {
    return treemapSlice;
  } else {
    return treemapSliceDice;
  }
}
