import {
  DataFrame,
  DisplayValue,
  Field,
  FieldConfigSource,
  FieldDisplay,
  GrafanaTheme,
  InterpolateFunction,
  ReduceDataOptions,
  Vector,
  getFieldDisplayValues,
} from '@grafana/data';
import distance from '@turf/distance';
import { CompleteMarker } from 'app/plugins/panel/googlemap/Google/interface';
import Geohash from 'latlon-geohash';
import { Feature } from 'ol';
import { Point, Polygon } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import OLVectorSource from 'ol/source/Vector';
import { isValidLatitude, isValidLongitude } from '../../StyleEditor/util';
import { AggregationForHeat, CustomMarkerI, QueryModes, checkRadiusLevels } from '../../types/interface';
import { GeohashField, QueryConfig } from './type';

/**
 * After calculating weight and then add weight in vectorSource.
 * @param {OLVectorSource} vectorSource - The OpenLayers vector source containing the features.
 * @param {number[]} arr - An array of values used to calculate weights.
 * @param {string} valueKey - The key used to access the value property in features.
 */
export function addWeights(vectorSource: OLVectorSource, arr: number[], valueKey: string) {
  vectorSource.forEachFeature((feature: Feature) => {
    const value = feature.get(valueKey);
    const weightedValue = normalizeValue(Number(value), arr[arr.length - 1], arr[0]);
    feature.set('weight', weightedValue);
  });
}

/**
 * Normalize a numeric value to a range between 0 and 1.
 *
 * @param {number} val - The input value to normalize.
 * @param {number} max - The maximum value in the range.
 * @param {number} min - The minimum value in the range.
 * @returns {number} A normalized value between 0 and 1.
 */
export const normalizeValue = (val: number, max: number, min: number) => {
  return (val - min) / (max - min);
};

/**
 * Get a sorted array of unique values from the input array.
 *
 * @param {number[]} arr - The input array containing numeric values.
 * @returns {number[]} A sorted array of unique numeric values.
 */
export const getSortedUniqueArray = (arr: number[]) => {
  // get unique values
  const uniqueValueArray = new Set(arr);
  // sort the array
  const sortedNumbersArray = Array.from(uniqueValueArray).sort((a, b) => a - b);
  return sortedNumbersArray;
};

const latitudeMatcher = ['latitude', 'lat'];
const longitudeMatcher = ['longitude', 'lon', 'lng'];

/**
 * Getting Geohash value from data.
 * @param {DataFrame[]} geohashData
 * @param {QueryConfig} option
 * @returns {QueryDataCal | null}
 */
export function getGeohashValues(geohashData: DataFrame[], option: QueryConfig): GeohashField[] | null {
  // return if no data is present
  if (geohashData.length === 0) {
    return null;
  }

  // return till user not selected the refId
  if (!option.dataField) {
    return null;
  }

  const dataField = option.dataField;

  const dataFrame = geohashData.filter((item) => {
    return item.refId === dataField;
  });

  // when no frame is present return null
  if (dataFrame.length === 0) {
    return null;
  }

  const {
    geohash,
    location,
    value,
    longitude,
    latitude,
    lat1,
    lat2,
    lat3,
    lat4,
    lng1,
    lng2,
    lng3,
    lng4,
  } = option.geohashQuerySelections;

  const locationField: DisplayValue[] = [];
  const valueField: DisplayValue[] = [];

  const hashField: any[] = [];

  const lngField: number[] = [];
  const latField: number[] = [];

  const lngField1: number[] = [];
  const latField1: number[] = [];

  const lngField2: number[] = [];
  const latField2: number[] = [];

  const lngField3: number[] = [];
  const latField3: number[] = [];

  const lngField4: number[] = [];
  const latField4: number[] = [];

  const timeField: number[] = geohashData[0].fields[0].type === 'time' ? geohashData[0].fields[0].values.toArray() : [];

  let hashFieldName = '';
  let locationFieldName = '';
  let valueFieldName = '';

  const mode = option.queryMode;

  //* Auto mode
  if (mode === QueryModes.Auto) {
    const latFieldName = getLatLngMatchers(dataFrame[0].fields, latitudeMatcher);
    const lngFieldName = getLatLngMatchers(dataFrame[0].fields, longitudeMatcher);

    for (const field of dataFrame[0].fields) {
      if (field.type === 'string') {
        const hashData = field.values.toArray()[0];
        try {
          const latlon = Geohash.decode(hashData);
          if (latlon) {
            hashFieldName = field.name;
          }
        } catch (error) {}
      }

      if (field.type === 'string' && field.name !== hashFieldName) {
        locationFieldName = field.name;
      }
      if (field.type === 'number') {
        valueFieldName = field.name;
      }
    }

    dataFrame[0].fields.map((field) => {
      //Auto as Coords
      if (lngFieldName.length !== 0 && latFieldName.length !== 0 && hashFieldName.length === 0) {
        if (field.name === lngFieldName) {
          field.values.toArray().map((v) => {
            lngField.push(Number(v));
          });
        }
        if (field.name === latFieldName) {
          field.values.toArray().map((v) => {
            latField.push(Number(v));
          });
        }
      }
    });
    //Auto as geohash
    const geohashField = getFieldValues(dataFrame[0], hashFieldName);
    const locationField1 = getFieldValues(dataFrame[0], locationFieldName);
    const valueField1 = getFieldValues(dataFrame[0], valueFieldName);

    hashField.push(...geohashField);
    locationField.push(...locationField1);
    valueField.push(...valueField1);
  }

  //* Geohash mode
  if (mode === QueryModes.Geohash) {
    const geohashField = getFieldValues(dataFrame[0], geohash);
    const locationField1 = getFieldValues(dataFrame[0], location);
    const valueField1 = getFieldValues(dataFrame[0], value);

    hashField.push(...geohashField);
    locationField.push(...locationField1);
    valueField.push(...valueField1);
  }

  //* Coords mode
  if (mode === QueryModes.Coords) {
    if (longitude.length !== 0 && latitude.length !== 0) {
      const locationField1 = getFieldValues(dataFrame[0], location);
      const valueField1 = getFieldValues(dataFrame[0], value);

      locationField.push(...locationField1);
      valueField.push(...valueField1);

      dataFrame[0].fields.map((field) => {
        if (field.name === longitude) {
          field.values.toArray().map((v) => {
            lngField.push(Number(v));
          });
        }
        if (field.name === latitude) {
          field.values.toArray().map((v) => {
            latField.push(Number(v));
          });
        }
      });
    }
  }

  //* Coords mode
  if (mode === QueryModes.Choro) {
    if (lat1 && lat2 && lat3 && lng1 && lng2 && lng3 && lat4 && lng4) {
      const locationField1 = getFieldValues(dataFrame[0], location);
      const valueField1 = getFieldValues(dataFrame[0], value);

      locationField.push(...locationField1);
      valueField.push(...valueField1);

      dataFrame[0].fields.map((field) => {
        if (field.name === lat1) {
          field.values.toArray().map((v) => {
            latField1.push(Number(v));
          });
        }
        if (field.name === lat2) {
          field.values.toArray().map((v) => {
            latField2.push(Number(v));
          });
        }
        if (field.name === lat3) {
          field.values.toArray().map((v) => {
            latField3.push(Number(v));
          });
        }
        if (field.name === lat4) {
          field.values.toArray().map((v) => {
            latField4.push(Number(v));
          });
        }
        if (field.name === lng1) {
          field.values.toArray().map((v) => {
            lngField1.push(Number(v));
          });
        }
        if (field.name === lng2) {
          field.values.toArray().map((v) => {
            lngField2.push(Number(v));
          });
        }
        if (field.name === lng3) {
          field.values.toArray().map((v) => {
            lngField3.push(Number(v));
          });
        }
        if (field.name === lng4) {
          field.values.toArray().map((v) => {
            lngField4.push(Number(v));
          });
        }
      });
    }
  }

  if (lngField.length !== 0 && latField.length !== 0) {
    lngField.map((long, index) => {
      if (isValidLongitude(long) && isValidLatitude(latField[index])) {
        const hash = Geohash.encode(latField[index], long, 12);
        hashField[index] = { ...hashField[index], text: hash };
      }
    });
  }
  if (
    lngField1.length !== 0 &&
    latField1.length !== 0 &&
    lngField2.length !== 0 &&
    latField2.length !== 0 &&
    lngField3.length !== 0 &&
    latField3.length !== 0 &&
    lngField4.length !== 0 &&
    latField4.length !== 0
  ) {
    lngField1.map((long, index) => {
      if (isValidLongitude(long) && isValidLatitude(latField1[index])) {
        const hash = Geohash.encode(latField1[index], long, 12);
        hashField[index] = { ...hashField[index], text1: hash };
      }
    });
    lngField2.map((long, index) => {
      if (isValidLongitude(long) && isValidLatitude(latField2[index])) {
        const hash = Geohash.encode(latField2[index], long, 12);
        hashField[index] = { ...hashField[index], text2: hash };
      }
    });
    lngField3.map((long, index) => {
      if (isValidLongitude(long) && isValidLatitude(latField3[index])) {
        const hash = Geohash.encode(latField3[index], long, 12);
        hashField[index] = { ...hashField[index], text3: hash };
      }
    });
    lngField4.map((long, index) => {
      if (isValidLongitude(long) && isValidLatitude(latField4[index])) {
        const hash = Geohash.encode(latField4[index], long, 12);
        hashField[index] = { ...hashField[index], text4: hash };
      }
    });
  }
  if (hashField.length && valueField.length && locationField.length) {
    const fieldArray: GeohashField[] = [];

    locationField.forEach((value, i) => {
      const singleEle: GeohashField = getField(
        value.text,
        valueField[i].numeric === null ? 0 : valueField[i].numeric,
        hashField[i].text,
        timeField[i],
        valueField[i].percent,
        valueField[i].color,
        hashField[i].text1,
        hashField[i].text2,
        hashField[i].text3,
        hashField[i].text4
      );
      fieldArray.push(singleEle);
    });

    // return fieldArray.length > 0 ? (mode === QueryModes.Choro ? fieldArray : [...getUniqueMarkers(fieldArray)]) : null;
    return fieldArray.length > 0 ? (mode === QueryModes.Choro ? fieldArray : fieldArray) : null;
  }

  return null;
}

/**
 * Retrieve field values by field name from a DataFrame.
 *
 * @param {DataFrame} dataFrame - The DataFrame to search for field values.
 * @param {string} fieldName - The name of the field to retrieve values for.
 * @returns {any[]} An array containing the values of the specified field.
 */
function getFieldValues(dataFrame: DataFrame, fieldName: string) {
  // Find the field in the DataFrame by its name
  const field = dataFrame.fields.find((field) => field.name === fieldName);
  if (!field) {
    return [];
  }

  return field.values.toArray().map((v) => field.display!(v));
}

/**
 * Find a field in an array of fields by matching against a list of matchers.
 *
 * @param {Array<Field<any, Vector<any>>>} fields - An array of fields to search within.
 * @param {string[]} matchers - An array of strings to match against field names.
 * @returns {string} The name of the first field that matches any of the provided matchers, or an empty string if no match is found.
 */
function getLatLngMatchers(fields: Array<Field<any, Vector<any>>>, matchers: string[]) {
  let result = '';

  for (const field of fields) {
    const lowerName = field.name.toLowerCase();
    if (matchers.some((matcher: any) => lowerName.includes(matcher))) {
      result = field.name;
      break; // Stop searching after the first match is found
    }
  }
  return result;
}

/**
 * Create a field object with the specified properties.
 *
 * @param {string} name - The name of the field.
 * @param {number} value - The numeric value associated with the field.
 * @param {string} hash - The geohash associated with the field.
 * @param {number} time - The time value associated with the field.
 * @param {number|string=} percent - The percent value associated with the field (optional, default is 0).
 * @param {string=} color - The color associated with the field (optional).
 * @param {string=} hash1 - Additional geohash 1 associated with the field (optional).
 * @param {string=} hash2 - Additional geohash 2 associated with the field (optional).
 * @param {string=} hash3 - Additional geohash 3 associated with the field (optional).
 * @param {string=} hash4 - Additional geohash 4 associated with the field (optional).
 * @returns {GeohashField} An object representing the field with the specified properties.
 */
export function getField(
  name: string,
  value: number,
  hash: string,
  time: number,
  percent?: number | string,
  color?: string,
  hash1?: string,
  hash2?: string,
  hash3?: string,
  hash4?: string
): GeohashField {
  const singleEle: GeohashField = {
    name,
    value,
    hash,
    ...(hash1 && { hash1 }),
    ...(hash2 && { hash2 }),
    ...(hash3 && { hash3 }),
    ...(hash4 && { hash4 }),
    time,
    percent: percent || 0,
    color,
  };
  return singleEle;
}

/**
 * Adds features to an OpenLayers vector source based on an array of GeohashField objects.
 *
 * @param {OLVectorSource} vectorSource - The OpenLayers vector source to add features to.
 * @param {GeohashField[]} arr - An array of GeohashField objects representing the features to be added.
 * @param {string} layerName - The name of the layer where the features will be added.
 */
export function addFeatures(vectorSource: OLVectorSource, arr: GeohashField[], layerName: string) {
  arr.forEach((ele) => {
    const latlon = Geohash.decode(ele.hash);
    vectorSource.addFeature(createMarker(latlon.lon, latlon.lat, ele.value, layerName, ele.name));
  });
}

/**
 * Creates a marker feature for OpenStreetMap (OSM).
 *
 * @param {number} lng - The longitude coordinate of the marker.
 * @param {number} lat - The latitude coordinate of the marker.
 * @param {number} data - The data associated with the marker.
 * @param {string} layerName - The name of the layer where the marker will be placed.
 * @param {string | undefined} fieldName - The name of the field associated with the marker (optional).
 * @param {string | undefined} dashboardName - The name of the dashboard (optional).
 * @param {string | undefined} dashboardUrl - The URL of the dashboard (optional).
 * @returns {Feature<Point>} - A marker feature for OSM.
 */
export function createMarker(
  lng: number,
  lat: number,
  data: number,
  layerName: string,
  fieldName?: string,
  dashboardName?: string,
  dashboardUrl?: string
) {
  // this key value pair will appear in feature value section
  const markerFeature = new Feature({
    geometry: new Point(fromLonLat([lng, lat])),
    value: data,
    layerName: layerName,
    ...(fieldName && { name: fieldName }),
    ...(dashboardName && { dashboardName }),
    ...(dashboardUrl && { dashboardUrl }),
  });

  return markerFeature;
}

/**
 * Creates a polygon marker feature on a map.
 *
 * @param {number} lng1 - Longitude of the first point.
 * @param {number} lng2 - Longitude of the second point.
 * @param {number} lng3 - Longitude of the third point.
 * @param {number} lng4 - Longitude of the fourth point.
 * @param {number} lat1 - Latitude of the first point.
 * @param {number} lat2 - Latitude of the second point.
 * @param {number} lat3 - Latitude of the third point.
 * @param {number} lat4 - Latitude of the fourth point.
 * @param {number} data - Data value associated with the marker.
 * @param {string} layerName - The name of the layer to which the marker belongs.
 * @param {string | undefined} fieldName - The name of the field associated with the marker.
 * @param {string | undefined} dashboardName - The name of the dashboard associated with the marker.
 * @param {string | undefined} dashboardUrl - The URL of the dashboard associated with the marker.
 * @returns {Feature} - A polygon marker feature.
 */
export function createPolygonMarker(
  lng1: number,
  lng2: number,
  lng3: number,
  lng4: number,
  lat1: number,
  lat2: number,
  lat3: number,
  lat4: number,
  data: number,
  layerName: string,
  fieldName?: string,
  dashboardName?: string,
  dashboardUrl?: string
) {
  // this key value pair will appear in feature value section
  const coordinates = [
    [lng1, lat1],
    [lng2, lat2],
    [lng3, lat3],
    [lng4, lat4],
    [lng1, lat1], // The last point should be the same as the first to close the polygon
  ];

  // Convert the coordinates from longitude and latitude to the map's projection (e.g., Web Mercator)
  const transformedCoordinates = coordinates.map((coord) => fromLonLat(coord));

  // Create a polygon geometry
  const polygonGeometry = new Polygon([transformedCoordinates]);

  const markerFeature = new Feature({
    geometry: polygonGeometry,
    value: data,
    layerName: layerName,
    ...(fieldName && { name: fieldName }),
    ...(dashboardName && { dashboardName }),
    ...(dashboardUrl && { dashboardUrl }),
  });

  return markerFeature;
}

/**
 * Get custom marker values based on provided geohash data and custom marker configuration.
 *
 * @param {FieldDisplay[]} geohashData - Array of geohash data.
 * @param {CustomMarkerI} option - Custom marker configuration.
 * @returns {CompleteMarker[][] | null} - An array of custom marker values or null if no data is found.
 */
export function getCustomMarkerValues(geohashData: FieldDisplay[], option: CustomMarkerI): CompleteMarker[][] | null {
  const customMarkerData: CompleteMarker[][] = [];

  // * creating markerData for custom markers added by user
  if (geohashData.length > 0) {
    for (let k = 0; k < option.markers.length; k++) {
      let newData = [];

      if (option.markers[k].query.length > 0) {
        for (let index = 0; index < option.markers[k].query.length; index++) {
          for (let j = 0; j < geohashData.length; j++) {
            if (option.markers[k].query[index].value === geohashData[j].display.title) {
              // const value = ;
              newData.push({ ...option.markers[k], ...geohashData[j].display });
            }
          }
        }
      }
      if (newData.length > 0) {
        customMarkerData.push(newData);
      }
    }
  }
  return customMarkerData.length ? customMarkerData : null;
}

/**
 * General function to calculate display values for big data.
 *
 * @param {FieldConfigSource} fieldConfig - Field configuration source.
 * @param {ReduceDataOptions} reduceOptions - Options for reducing data.
 * @param {InterpolateFunction} replaceVariables - Function to replace variables.
 * @param {GrafanaTheme} theme - Grafana theme.
 * @param {Array<DataFrame>} frames - Array of data frames.
 * @param {string} timeZone - Time zone for data.
 * @returns {Array<FieldDisplay>} - An array of field display values.
 */
export function generalBigData(
  fieldConfig: FieldConfigSource,
  reduceOptions: ReduceDataOptions,
  replaceVariables: InterpolateFunction,
  theme: GrafanaTheme,
  frames: DataFrame[],
  timeZone: string
) {
  /**
   * Function to get display values.
   * @returns {Array<FieldDisplay>} - An array of field display values.
   */
  const getValues = (): FieldDisplay[] => {
    return getFieldDisplayValues({
      fieldConfig,
      reduceOptions: reduceOptions,
      replaceVariables,
      theme: theme,
      data: frames,
      sparkline: false,
      timeZone: timeZone,
    });
  };
  return getValues();
}

/**
 * Update the heatmap layer with data points based on the current time and time step.
 *
 * @param {number} currTime - The current time value.
 * @param {number} step - The time step value.
 * @param {GeohashField[]} fieldArray - Array of geohash field data.
 * @param {OLVectorSource} vectorSource - The OpenLayers vector source to update.
 * @param {boolean} inWeight - Indicates whether to add weight to the markers.
 * @param {string} layerName - The name of the layer.
 */
export function updateHeatmap(
  currTime: number,
  step: number,
  fieldArray: GeohashField[],
  vectorSource: OLVectorSource,
  inWeight: boolean,
  layerName: string
) {
  // Filter the data points based on the current time value
  let finalMarkersForHeat: GeohashField[] = [];
  fieldArray.map((field) => {
    if (field.time <= currTime && field.time > currTime - step) {
      finalMarkersForHeat.push({
        name: field.name,
        hash: field.hash,
        value: field.value,
        time: field.time,
      });
    }
  });

  if (finalMarkersForHeat && finalMarkersForHeat.length > 0) {
    addFeatures(vectorSource, finalMarkersForHeat, layerName);
    // add weight to map
    if (inWeight) {
      const sortedUnique = getSortedUniqueArray(fieldArray.map((field) => field.value));
      addWeights(vectorSource, sortedUnique, 'value');
    }
  }
}

/**
 * Check if a point with given latitude and longitude is within a specified circle.
 *
 * @param {number} centerLat - Latitude of the circle center.
 * @param {number} centerLng - Longitude of the circle center.
 * @param {number} radius - Radius of the circle in meters.
 * @param {number} pointLat - Latitude of the point to check.
 * @param {number} pointLng - Longitude of the point to check.
 * @returns {boolean} True if the point is inside the circle, false otherwise.
 */
export function isCoordinateInCircle(
  centerLat: number,
  centerLng: number,
  radius: number,
  pointLat: number,
  pointLng: number
) {
  const distanceBetween = distance([centerLat, centerLng], [pointLat, pointLng]);
  return distanceBetween <= radius;
}

/**
 * Create marker groups based on zoom level, aggregation, and input fields.
 *
 * @param {number} zoom - The current zoom level.
 * @param {string} aggregation - The aggregation method (SUM, MIN, MAX, or AVG).
 * @param {GeohashField[]} fields - An array of GeohashField objects representing the data points.
 * @returns {GeohashField[]} An array of GeohashField objects representing the aggregated marker groups.
 */
export function createMarkerGroups(zoom: number, aggregation: string, fields: GeohashField[]) {
  const markerGroups: GeohashField[][] = [];
  // taking zeroth index as center point every time.
  // finding all markers nearer to it with given radius.
  //
  // remove all markers which are in the radius from array.

  while (fields.length > 1) {
    const center = Geohash.decode(fields[0].hash);
    const aggMarker: GeohashField[] = [];

    for (let index = 1; index < fields.length; index++) {
      const element = fields[index];
      const latlon = Geohash.decode(element.hash);
      if (
        isCoordinateInCircle(center.lat, center.lon, checkRadiusLevels[`${Math.round(zoom)}`], latlon.lat, latlon.lon)
      ) {
        aggMarker.push(element);
        fields.splice(index, 1);
      }
    }

    // adding last round of element into array group
    if (fields.length === 1) {
      markerGroups[markerGroups.length - 1].push(fields[0]);
    }

    if (aggMarker.length > 0) {
      aggMarker.push(fields[0]);
      fields.splice(0, 1);
      markerGroups.push(aggMarker);
    } else {
      markerGroups.push([fields[0]]);
      fields.splice(0, 1);
    }
  }

  // aggregate data
  return markerGroups.map((group: GeohashField[]) => ({
    value: Math.round(
      aggregation === AggregationForHeat.SUM
        ? group.reduce((acc, curr) => acc + curr.value, 0)
        : aggregation === AggregationForHeat.MIN
        ? Math.min(...group.map((field) => field.value))
        : aggregation === AggregationForHeat.MAX
        ? Math.max(...group.map((field) => field.value))
        : group.reduce((acc, curr) => acc + curr.value, 0) / group.length
    ),
    hash: group[0].hash,
    name: group[0].name,
    time: group[0].time,
  }));
}

/**
 * Calculates the unique value of locations and calculates the mean of markers when more than one marker is available at one location.
 *
 * @param {GeohashField[]} fieldArray - An array of GeohashField objects representing markers.
 * @returns {GeohashField[]} An array of GeohashField objects with unique location values and mean marker values.
 */
export function getUniqueMarkers(fieldArray: GeohashField[]) {
  const distinctHashValues = new Set(fieldArray.map((obj) => obj.hash));

  const fieldArrayNew: GeohashField[] = [];
  distinctHashValues.forEach((hash) => {
    const group = fieldArray.filter((field) => field.hash === hash);
    if (group) {
      const avg = group.reduce((total, next) => total + next.value, 0) / group.length;
      const singleArray = { ...group[0], value: Number(avg.toFixed(2)) };
      fieldArrayNew.push(singleArray);
    }
  });
  return fieldArrayNew;
}

/**
 * Calculates the minimum and maximum values of heatmap markers and checks if the markers are present or not.
 *
 * @param {GeohashField[]} heatmapMarkers - An array of GeohashField objects representing heatmap markers.
 * @returns {Object} An object containing the minimum and maximum values, and a MarkerHeat indicator.
 */
export const heatMapGradientMinMax = (heatmapMarkers: GeohashField[]) => {
  const values = heatmapMarkers.map((fields) => fields.value);
  return heatmapMarkers && heatmapMarkers.length
    ? { min: Math.min(...values), max: Math.max(...values), MarkerHeat: 'Notnull' }
    : { min: 0, max: 0, MarkerHeat: 'null' };
};
