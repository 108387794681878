import React from 'react';
import { PanelPlugin } from '@grafana/data';
import { SankeyPanelOptions, addStandardDataReduceOptions } from './types';
import SankeyPanel from './SankeyPanel';
import { statPanelChangedHandler } from './StatMigrations';
import { HandleEditor } from './components/HandleEditor';

export const plugin = new PanelPlugin<SankeyPanelOptions>(SankeyPanel)
  .useFieldConfig()
  .setPanelOptions((builder) => {
    addStandardDataReduceOptions(builder);
    builder
      .addCustomEditor({
        category: ['Editor'],
        id: 'sankeyData',
        path: 'sankeyData',
        name: 'Add Node',
        description: 'Adds node to the chart',
        defaultValue: [],
        editor(props: any) {
          return (
            <HandleEditor
              data={props.context.data}
              bigData={props.context}
              sankeyData={props.value}
              onChange={props.onChange}
            />
          );
        },
      })
      // SWITCH FOR CIRCULAR SANKEY
      .addBooleanSwitch({
        path: 'diagram',
        name: 'Circular Sankey',
        settings: {
          options: [
            { value: 'left', label: 'left' },
            { value: 'right', label: 'right' },
          ],
        },
      })
      // INPUT FIELD FOR TEXT SIZE
      .addNumberInput({
        path: 'textsize',
        name: 'Text Size',
        settings: {
          integer: true,
        },
        defaultValue: 14,
      })
      // INPUT FIELD FOR WIDTH
      .addNumberInput({
        path: 'width',
        name: 'Node Width',
        defaultValue: 10,
      })
      // INPUT FIELD FOR PADDING
      .addNumberInput({
        path: 'nodePadding',
        name: 'Node Padding',
        description: 'Vertical distance between nodes.',
        defaultValue: 6,
      })
      // INPUT FIELD FOR PANEL ALIGNMENT
      .addSelect({
        path: 'nodealign',
        name: 'Node Align',
        settings: {
          options: [
            { value: 'left', label: 'left' },
            { value: 'right', label: 'right' },
            { value: 'justify', label: 'Justify' },
          ],
        },
        defaultValue: 'left',
      })
      // INPUT FIELD FOR CHOOSING THE COLOR
      .addColorPicker({
        path: 'nodecolor',
        name: 'Node Color',
        defaultValue: '#0000FF',
      });
  })
  .setNoPadding()
  .setPanelChangeHandler(statPanelChangedHandler);
