import React from 'react';
import { ChordChartSvg } from './ChordChartSvg';
import { VizLayout, VizLegend, VizLegendItem, LegendDisplayMode } from '@grafana/ui';
import { ChordChartLegendOptions, ChordChartLegendValues, ChordChartOptions } from './types';
import { FALLBACK_COLOR, FieldDisplay, formattedValueToString } from '@grafana/data';

// structure is same as radar and pie chart
/**
 * @typedef {Object} ChordChartProps
 * @property {Array<FieldDisplay>} values
 * @property {function} [onSeriesColorChange]
 * @property {number} width
 * @property {number} height
 * @property {any} data
 * @property {Array<string>} color
 * @property {ChordChartOptions} options
 */
interface ChordChartProps {
  values: FieldDisplay[];
  onSeriesColorChange?: (label: string, color: string) => void;
  width: number;
  height: number;
  data: any;
  color: string[];
  options: ChordChartOptions;
}
/**
 * chord chart legend component
 * @param {ChordChartProps} props
 * @returns {JSX.Element}
 */
export const ChordChart: React.FunctionComponent<ChordChartProps> = (props: ChordChartProps) => {
  const { values, onSeriesColorChange, width, height, data, color, options } = props;
  const getLegend = (values: FieldDisplay[], legendOptions: ChordChartLegendOptions) => {
    if (legendOptions.displayMode === LegendDisplayMode.Hidden) {
      return undefined;
    }
    const total = values.reduce((acc, item) => item.display.numeric + acc, 0);
    let legendItems: VizLegendItem[] = [];
    legendItems = values.map<VizLegendItem>((value, idx) => {
      return {
        label: value.display.title ?? '',
        color: value.display.color ?? FALLBACK_COLOR,
        yAxis: 1,
        getItemKey: () => (value.display.title ?? '') + idx,
        getDisplayValues: () => {
          const valuesToShow = legendOptions.values ?? [];
          let displayValues = [];

          if (valuesToShow.includes(ChordChartLegendValues.Value)) {
            displayValues.push({
              numeric: value.display.numeric,
              text: formattedValueToString(value.display),
              title: 'Value',
            });
          }

          if (valuesToShow.includes(ChordChartLegendValues.Percent)) {
            const fractionOfTotal = value.display.numeric / total;
            const percentOfTotal = fractionOfTotal * 100;

            displayValues.push({
              numeric: fractionOfTotal,
              percent: percentOfTotal,
              text: percentOfTotal.toFixed(2) + '%',
              title: valuesToShow.length > 1 ? 'Percent' : undefined,
            });
          }

          return displayValues;
        },
      };
    });
    return (
      <VizLegend
        items={legendItems}
        onSeriesColorChange={onSeriesColorChange}
        placement={legendOptions.placement}
        displayMode={legendOptions.displayMode}
      />
    );
  };

  return (
    <VizLayout width={width} height={height} legend={getLegend(values, options.legend)}>
      {(vizWidth: number, vizHeight: number) => {
        return (
          <ChordChartSvg
            width={vizWidth}
            height={vizHeight}
            data={data}
            color={color}
            options={options}
            values={values}
          />
        );
      }}
    </VizLayout>
  );
};
