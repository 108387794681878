import { sharedSingleStatPanelChangedHandler } from '@grafana/ui';
import { PanelModel } from '@grafana/data';
import { MatterportOptions } from './types';

// This is called when the panel changes from another panel
export const statPanelChangedHandler = (
  panel: PanelModel<Partial<MatterportOptions>> | any,
  prevPluginId: string,
  prevOptions: any
) => {
  // This handles most config changes
  const options = sharedSingleStatPanelChangedHandler(panel, prevPluginId, prevOptions) as MatterportOptions;
  return options;
};
