import Handlebars from 'handlebars';
import MarkdownIt from 'markdown-it';
import {
  DisplayValue,
  FieldConfigSource,
  FieldDisplay,
  getDisplayForValue,
  getFieldDisplayValues,
  getLocale,
  GrafanaTheme,
  InterpolateFunction,
  PanelData,
  textUtil,
  TimeRange,
} from '@grafana/data';
import { config } from '@grafana/runtime';
import { registerHelpers } from './handlebars';
import { TextOptions } from '../types';
/**
 * Helpers
 */
registerHelpers(Handlebars);
/**
 * Generate HTML according to new dynamic text.
 */
export const generateHtml = (
  data: Record<string, any>,
  content: string,
  options: TextOptions,
  timeRange: TimeRange,
  theme: GrafanaTheme,
  fieldConfig: FieldConfigSource<any>,
  replaceVariables: InterpolateFunction,
  timeZone: string,
  dataseries: PanelData
) => {
  const { helpers, reduceOptions } = options;

  const FieldDisplayValues = getFieldDisplayValues({
    fieldConfig,
    reduceOptions: reduceOptions,
    replaceVariables,
    theme: theme,
    data: dataseries.series,
    timeZone,
  });

  const display: DisplayValue[] = [];

  FieldDisplayValues.map((frame: FieldDisplay) => {
    data[frame.display.title!] = (frame.display.prefix || '') + frame.display.text + (frame.display.suffix || '');
    data[`_${frame.display.title}`] = Number(frame.display.text);
    display.push(frame.display);
  });

  // Load Dashboard Time
  data['StartTime'] = timeRange.from;
  data['EndTime'] = timeRange.to;
  data['MomentTime'] = timeRange;
  data['DisplayValue'] = display;

  /**
   * Register Helpers
   * Field display formatter to be used as a helper.
   * It Will take a value and a property and return the formatted value
   * If no property is specified, it will return the whole display value
   * @param {number} value - value
   * @param {string} property - property
   * @returns {DisplayValue} - formatted value
   */
  const fieldDisplayFmt = (value: number, property: string) => {
    const series = dataseries.series[0];
    let idx = 0;
    if (series.fields[idx].type === 'time') {
      idx++;
    }
    const f = dataseries.series[0].fields[idx];
    const display = getDisplayForValue(f, value, theme, timeZone);

    // @ts-ignore
    return property === '' ? display : display[property];
  };

  // Register fieldDisplayFmt as a helper
  Handlebars.registerHelper('fieldDisplayFmt', fieldDisplayFmt);

  /**
   * Add Custom Helpers
   */
  if (helpers) {
    const func = new Function('data', 'handlebars', 'getLocale', 'fieldDisplayFmt', helpers);
    func(data, Handlebars, getLocale, fieldDisplayFmt, helpers);
  }

  /**
   * Handlebars
   */
  const template = Handlebars.compile(content ?? '');
  const markdown = template(data);

  /**
   * Render Markdown
   */
  const md = new MarkdownIt({ html: true });
  const html = md.render(markdown);

  /**
   * Skip sanitizing if disabled in Grafana
   */
  if (config.disableSanitizeHtml) {
    return html;
  }

  /**
   * Return sanitized HTML
   */
  return textUtil.sanitize(html);
};
