import { FieldColorModeId, FieldConfigSource, Threshold } from '@grafana/data';
import { config } from 'app/core/config';
import { HeatmapLegend } from '../types/types';

/**
 * Generates and returns a legend element based on field configuration and visibility.
 *
 * @param {FieldConfigSource<any>} fieldConfig - The field configuration.
 * @param {boolean} isShowInfo - A boolean indicating whether to show the legend.
 * @returns {{legend: HTMLElement | null, stepsLength: number}} An object containing the generated legend element and the number of legend steps.
 */
export const setLegend = (fieldConfig: FieldConfigSource<any>, isShowInfo: boolean) => {
  let steps: Threshold[] = [];
  const theme = config.theme;
  if (fieldConfig.defaults.color?.mode === FieldColorModeId.Thresholds) {
    const mainDiv = document.createElement('div');
    const div = document.createElement('div');
    const bottomGap = isShowInfo ? '30px' : '5px';
    mainDiv.setAttribute(
      'style',
      `color: ${theme.colors.text};margin: 10px;padding: 10px; display:flex; flex-direction: column;font-size: 12px; position: absolute; bottom: ${bottomGap}; border-radius: 3px; border: 2px solid ${theme.colors.border1}; background-color: ${theme.colors.bg1}`
    );
    let element: string | any = '';
    steps = fieldConfig.defaults.thresholds?.steps || [];
    for (let i = 0; i < steps.length; i++) {
      if (i === 0) {
        element +=
          '<div style="display: flex; align-items: center;"><span style="float: left;height: 10px;width: 20px;margin-right: 3px; background-color: ' +
          steps[i].color +
          '"></span><span style="margin-left: 10px">' +
          '<' +
          steps[1].value +
          '</span></div>';
      } else if (i === steps.length - 1) {
        element +=
          '<div style="display: flex; align-items: center;"><span style="float: left;height: 10px;width: 20px;margin-right: 3px;background-color: ' +
          steps[i].color +
          '"></span><span style="margin-left: 10px">' +
          ' ' +
          steps[i].value +
          '+' +
          '</span></div>';
      } else {
        element +=
          '<div style="display: flex; align-items: center;"><span style="float: left;height: 10px;width: 20px;margin-right: 3px;background-color: ' +
          steps[i].color +
          '"></span><span style="margin-left: 10px">' +
          steps[i].value +
          '-' +
          steps[i + 1].value +
          '</span></div>';
      }
    }
    div.innerHTML = element;
    mainDiv.appendChild(div);
    return { legend: mainDiv, stepsLength: steps.length };
  }
  return { legend: null, stepsLength: 0 };
};

/**
 * Generates and returns a legend element for a heatmap visualization.
 *
 * @param {string[]} heatmapColors - An array of colors used in the heatmap gradient.
 * @param {HeatmapLegend} heatmapMinMax - The minimum and maximum values of the heatmap.
 * @param {number} heatmapSteps - The number of steps or marks on the heatmap legend.
 * @param {boolean} isBoth - A boolean indicating whether both legends are displayed.
 * @param {number} stepsLength - The number of steps in the previous legend.
 * @param {string} layerName - The name of the layer associated with the legend.
 * @param {boolean} isShowInfo - A boolean indicating whether to adjust height or not.
 * @returns {HTMLElement | null} The generated legend element or null if not applicable.
 */
export const setLegendForHeatmap = (
  heatmapColors: string[],
  heatmapMinMax: HeatmapLegend,
  heatmapSteps: number,
  isBoth: boolean,
  stepsLength: number,
  layerName: string,
  isShowInfo: boolean
) => {
  if (heatmapMinMax.MarkerHeat === 'null') {
    return null;
  }
  if (!heatmapColors) {
    return null;
  }
  const theme = config.theme;
  const differenceValue = heatmapMinMax.max - heatmapMinMax.min;
  const value = differenceValue / (heatmapSteps - 1);
  const gradientSteps = [];
  let temp = heatmapMinMax.min;
  gradientSteps.push(heatmapMinMax.min);
  // calculate steps value
  for (let i = 0; i < heatmapSteps - 2; i++) {
    temp += value;
    gradientSteps.push(temp);
  }
  gradientSteps.push(heatmapMinMax.max);

  // Create the legend element
  var Heatmaplegend = document.createElement('div');
  const div1 = document.createElement('div');

  const gapFromThresh = isBoth ? stepsLength * 30 + 13 : 5;
  const bottomGap = isShowInfo ? gapFromThresh + 25 : gapFromThresh;

  div1.setAttribute(
    'style',
    `margin: 10px;padding: 10px; display:flex; flex-direction: column; border-radius: 2px;border: 2px solid ${theme.colors.border1}; position: absolute; bottom: ${bottomGap}px; background-color: ${theme.colors.bg1}`
  );

  // Create the gradient canvas
  var canvas = document.createElement('canvas');
  canvas.style.height = '100%';
  canvas.style.width = '20px';
  canvas.height = gradientSteps.length * 20;
  canvas.width = 20;

  var ctx = canvas.getContext('2d');

  // Create the gradient
  var gradient = ctx!.createLinearGradient(0, 0, 0, canvas.height);
  for (var i = 0; i < heatmapColors.length; i++) {
    gradient.addColorStop(i / (heatmapColors.length - 1), heatmapColors[i]);
  }

  // Fill the canvas with the gradient
  ctx!.fillStyle = gradient;
  ctx!.fillRect(0, 0, canvas.width, canvas.height);

  // Create the Heatmaplegend labels
  var heatmaplabels = document.createElement('div');
  heatmaplabels.setAttribute('style', 'margin-left: 10px; display:flex; flex-direction: column;');

  for (var j = 0; j < gradientSteps.length; j++) {
    var labelValue = document.createElement('div');
    labelValue.setAttribute(
      'style',
      'display: flex; align-items:center; justify-content: center; height: 20px; font-size: 12px;'
    );

    labelValue.innerHTML = `${Math.floor(gradientSteps[j] * 10) / 10}`;

    // Add the labelValue to the Heatmaplegend element
    heatmaplabels.appendChild(labelValue);
  }

  const span = document.createElement('span');
  span.innerText = layerName || 'Layer';
  span.setAttribute('style', 'display:flex; flex-direction: row;');
  Heatmaplegend.setAttribute('style', 'display:flex; flex-direction: row;');

  // Add the canvas to the Heatmaplegend element
  Heatmaplegend.appendChild(canvas);
  // Add the labels to the Heatmaplegend element
  Heatmaplegend.appendChild(heatmaplabels);

  div1.appendChild(Heatmaplegend);
  div1.appendChild(span);
  return div1;
};
