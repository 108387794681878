import { PanelOptionsEditorBuilder } from '@grafana/data';
import { LegendMode } from '../types/interface';
import { MapPanelOptions } from '../types/types';

export function defaultOptions<T extends MapPanelOptions>(builder: PanelOptionsEditorBuilder<T>) {
  builder
    .addNumberInput({
      path: 'zoom',
      name: 'Zooming Level',
      description: 'Sets the initial zoom when loading the map.',
      defaultValue: 5,
    })
    .addNumberInput({
      path: 'defaultCenter.lat',
      name: 'Default Latitude',
      defaultValue: -24.777970412891463,
    })
    .addNumberInput({
      path: 'defaultCenter.lng',
      name: 'Default Longitude',
      defaultValue: 134.45082499999998,
    })
    .addBooleanSwitch({
      path: 'latLngPicker',
      name: 'Pick Lat-Lng with Click',
      description: 'Pick Default Latitude and Longitude from the Map with a click.',
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'shared',
      description:
        'Use the same view across multiple panels. Use Only when you need it. It may Decrease Performance. Note: this may require a dashboard reload.',
      name: 'Share view',
      defaultValue: false,
    })
    .addSelect({
      path: 'mapTypeId',
      name: 'Map Type',
      settings: {
        options: [
          { value: 'roadmap', label: 'ROADMAP', description: 'normal, default 2D map' },
          { value: 'hybrid', label: 'HYBRID', description: 'photographic map + roads and city names' },
          { value: 'satellite', label: 'SATELLITE', description: 'photographic map' },
          { value: 'terrain', label: 'TERRAIN', description: 'map with mountains, rivers, etc.' },
        ],
      },
      defaultValue: 'roadmap',
      category: ['Map Control'],
    })
    .addSelect({
      path: 'mapTheme',
      name: 'Map Theme',
      settings: {
        options: [
          { value: 0, label: 'Default' },
          { value: 1, label: 'Retro' },
          { value: 2, label: 'Dark' },
          { value: 3, label: 'Night' },
          { value: 4, label: 'Aubergine' },
          { value: 5, label: 'Silver' },
        ],
      },
      defaultValue: 0,
      category: ['Map Control'],
    })
    .addRadio({
      path: 'legendMode',
      name: 'Legend',
      description: 'Enables legend over the map',
      defaultValue: LegendMode.Threshold,
      settings: {
        options: [
          { value: LegendMode.Both, label: 'Both' },
          { value: LegendMode.Heatmap, label: 'Heatmap' },
          { value: LegendMode.Threshold, label: 'Threshold' },
          { value: LegendMode.None, label: 'None' },
        ],
      },
      category: ['Map Control'],
    })
    .addNumberInput({
      path: 'heatmapSteps',
      name: 'Heatmap legend steps',
      defaultValue: 10,
      category: ['Map Control'],
      settings: {
        min: 2,
        step: 1,
      },
      showIf: (options: MapPanelOptions) => {
        if (options.legendMode === LegendMode.Both || options.legendMode === LegendMode.Heatmap) {
          return true;
        }
        return false;
      },
    })
    .addBooleanSwitch({
      path: 'showTools',
      name: 'Show Tools',
      description: 'Enables tools over the map like zoom, full-screen, view-type etc.',
      defaultValue: true,
      category: ['Map Control'],
    })
    .addBooleanSwitch({
      path: 'showController',
      name: 'Show Controller',
      description: 'Enables mouse controls over the map.',
      defaultValue: true,
      category: ['Map Control'],
    })
    .addBooleanSwitch({
      path: 'traffic',
      name: 'Enable Traffic Layer',
      defaultValue: false,
      category: ['Map Control'],
    });
}
