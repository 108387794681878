import React from 'react';
import { PanelProps } from '@grafana/data';
import { BarOptions } from './types';
import { Bar } from './components/Bar';
import { Line1 } from './components/Line1';
import { Both } from './components/Both';

interface Props extends PanelProps<BarOptions> {}

export const BarPanel: React.FC<Props> = ({ options, data, width, height, timeZone, fieldConfig, timeRange }) => {
  return (
    <div>
      {options.graphType === 'bar' ? (
        <Bar
          options={{ ...options }}
          data={{ ...data }}
          width={width}
          height={height}
          timeZone={timeZone}
          fieldConfig={{ ...fieldConfig }}
          timeRange={{ ...timeRange }}
        />
      ) : options.graphType === 'line' ? (
        <Line1
          options={{ ...options }}
          data={{ ...data }}
          width={width}
          height={height}
          timeZone={timeZone}
          fieldConfig={{ ...fieldConfig }}
          timeRange={{ ...timeRange }}
        />
      ) : (
        <Both
          options={{ ...options }}
          data={{ ...data }}
          width={width}
          height={height}
          timeZone={timeZone}
          fieldConfig={{ ...fieldConfig }}
          timeRange={{ ...timeRange }}
        />
      )}
    </div>
  );
};
