import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const Channels: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 16 16"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M13.7,4.2c-0.3,0-0.7,0.1-0.9,0.4l-1.3-0.6c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2L12.8,3c0.5,0.5,1.3,0.5,1.8,0
        s0.5-1.3,0-1.8s-1.3-0.5-1.8,0c-0.2,0.2-0.4,0.6-0.4,0.9c0,0.1,0,0.1,0,0.2l-1.3,0.6c-0.5-0.5-1.3-0.5-1.8,0c-0.5,0.5-0.5,1.3,0,1.8
        c0.5,0.5,1.3,0.5,1.8,0l1.3,0.6c0,0.1,0,0.1,0,0.2c0,0.7,0.6,1.3,1.3,1.3S15,6.2,15,5.5S14.5,4.2,13.7,4.2z M13.7,1.6
        c0.2,0,0.4,0.2,0.4,0.4S14,2.5,13.7,2.5c-0.2,0-0.4-0.2-0.4-0.4S13.5,1.6,13.7,1.6z M10.3,4.2c-0.2,0-0.4-0.2-0.4-0.4
        s0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4S10.5,4.2,10.3,4.2z M13.7,5.9c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4
        c0.2,0,0.4,0.2,0.4,0.4C14.2,5.7,14,5.9,13.7,5.9z M3.5,6.1h2.6c0.3,0,0.5-0.2,0.5-0.5S6.4,5.1,6.1,5.1H3.5C3.3,5.1,3,5.3,3,5.6
        S3.3,6.1,3.5,6.1z M9.7,7.1H3.5C3.3,7.1,3,7.4,3,7.6s0.2,0.5,0.5,0.5h6.1c0.3,0,0.5-0.2,0.5-0.5S9.9,7.1,9.7,7.1z M9.7,9.2H3.5
        C3.3,9.2,3,9.4,3,9.7c0,0.3,0.2,0.5,0.5,0.5h6.1c0.3,0,0.5-0.2,0.5-0.5C10.2,9.4,9.9,9.2,9.7,9.2z M13.2,8.9v0.5v1.1v4.1
        c0,0.3-0.4,0.5-0.7,0.3c-1-0.9-2.5-2.1-2.5-2.1H3c-1.1,0-2-0.9-2-2V4.6c0-1.1,0.9-2,2-2h2.1h1h0.5C7,2.5,7.2,2.8,7.2,3
        c0,0.3-0.2,0.5-0.5,0.5H6.1h-1H3c-0.6,0-1,0.5-1,1v6.1c0,0.6,0.5,1,1,1h7.1c0.2,0,0.5,0.1,0.7,0.2l1.5,1.3V9.4h0V8.9
        c0-0.3,0.2-0.5,0.5-0.5C13,8.4,13.2,8.7,13.2,8.9z"
      />
    </svg>
  );
};
