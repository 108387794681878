import React, { FC } from 'react';
import { PanelProps } from '@grafana/data';
import { useTheme } from '@grafana/ui';
import { css } from 'emotion';
import { TimeOptions } from './types';
import { Time } from './Time';
const moment = require('moment-timezone');
import { cloneDeep } from 'lodash';

interface Props extends PanelProps<TimeOptions> {}

export const TimePanel: FC<Props> = ({ options, width, height, timeRange, timeZone }: any) => {
  const theme = useTheme();

  let dataTimeFrom = cloneDeep(timeRange.from);
  let dataTimeTo = cloneDeep(timeRange.to);

  let currentTime = new Date();
  if (timeZone !== '' && timeZone !== 'browser') {
    dataTimeFrom = moment.tz(dataTimeFrom, timeZone);
    dataTimeTo = moment.tz(dataTimeTo, timeZone);
    currentTime = moment.tz(currentTime, timeZone);
  }

  const fontSize = width + height < 1000 ? width / 100 + height / 100 + 9 : width / 100 + height / 100 + 5;
  if (options.addm) {
    dataTimeFrom._d.setMinutes(dataTimeFrom._d.getMinutes() + options.addm);
    dataTimeTo._d.setMinutes(dataTimeTo._d.getMinutes() + options.addm);
    currentTime.setMinutes(currentTime.getMinutes() + options.addm);
  }
  if (options.addh) {
    dataTimeFrom._d.setHours(dataTimeFrom._d.getHours() + options.addh);
    dataTimeTo._d.setHours(dataTimeTo._d.getHours() + options.addh);
    currentTime.setHours(currentTime.getHours() + options.addh);
  }
  if (options.addD) {
    dataTimeFrom._d.setDate(dataTimeFrom._d.getDate() + options.addD);
    dataTimeTo._d.setDate(dataTimeTo._d.getDate() + options.addD);
    currentTime.setDate(currentTime.getDate() + options.addD);
  }
  if (options.addM) {
    dataTimeFrom._d.setMonth(dataTimeFrom._d.getMonth() + options.addM);
    dataTimeTo._d.setMonth(dataTimeTo._d.getMonth() + options.addM);
    currentTime.setMonth(currentTime.getMonth() + options.addM);
  }
  if (options.addY) {
    dataTimeFrom._d.setFullYear(dataTimeFrom._d.getFullYear() + options.addY);
    dataTimeTo._d.setFullYear(dataTimeTo._d.getFullYear() + options.addY);
    currentTime.setFullYear(currentTime.getFullYear() + options.addY);
  }

  const styles = getStyles();

  return (
    <div style={{ position: 'relative', height, width, color: options.boxTextColor }}>
      <div className={styles.divOne} style={{ width }}>
        <Time
          text={options.middle}
          options={options}
          fontSize={fontSize}
          timeRange={timeRange}
          dataTime={currentTime}
          style={{ width }}
          theme={theme}
        />
      </div>
      <div
        className={styles.divTwo}
        style={{
          display: 'flex',
          flexDirection: width < 430 ? 'column' : 'row',
          paddingTop: width < 430 ? '0.5rem' : '0.5rem',
          marginTop: width < 430 ? '0' : '0.5rem',
        }}
      >
        <Time
          text={options.left}
          options={options}
          fontSize={fontSize}
          timeRange={timeRange}
          dataTime={dataTimeFrom}
          style={{ marginRight: '1rem' }}
          theme={theme}
        />
        <Time
          text={options.right}
          options={options}
          fontSize={fontSize}
          timeRange={timeRange}
          dataTime={dataTimeTo}
          style={{ marginTop: width < 430 ? '0.5rem' : '0rem' }}
          theme={theme}
        />
      </div>
    </div>
  );
};

const getStyles = () => {
  return {
    divOne: css`
      & p {
        display: inline-block;
        margin: 0;
      }
    `,
    divTwo: css`
      & {
        display: flex;
        flex: 1;
      }
      & p {
        display: inline-block;
        margin: 0;
      }
    `,
  };
};
