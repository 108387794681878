import {
  DataTransformerInfo,
  DataTransformerID,
  DataFrame,
  ArrayVector,
  FieldType,
  Field,
  MutableDataFrame,
  getTimeField,
} from '@grafana/data';
import { cloneDeep } from 'lodash';
import { map } from 'rxjs/operators';

export const Default_Alias = 'Anomaly Score';

export type AnomalyScoreOptions = {
  isTimeSeries: boolean;
  timeField: string | undefined;
  actualField: string | undefined;
  predictedField: string | undefined;
  alias: string;
};

export function anomalyDataToSend(data: DataFrame[], options: AnomalyScoreOptions): DataFrame[] {
  if (!Array.isArray(data) || data.length === 0) {
    return data;
  }

  if (options.isTimeSeries && options.actualField && options.predictedField && data.length > 1) {
    const actualDataFrame = findDataFrame(data, options.actualField);
    const predictedDataframe = findDataFrame(data, options.predictedField);

    if (actualDataFrame && predictedDataframe) {
      const anomalyDataFrame = createAnomalyDataFrame(actualDataFrame, predictedDataframe, options);
      return [anomalyDataFrame];
    }
  }

  return data;
}

function findDataFrame(data: DataFrame[], fieldName: string): DataFrame | undefined {
  return data.find((frame) => frame.fields.some((field) => field.name === fieldName));
}
function syncTimestamps(
  actualTimestampArray: number[],
  predictedTimestampArray: number[],
  predictedFieldArray: number[]
): number[] {
  let syncedPredictedTimestampArray: number[] = [];
  let syncedPredictedFieldArray: any[] = [];

  // Create a map for quick lookup of predicted timestamps
  let predictedTimestampMap = new Map();
  predictedTimestampArray.forEach((timestamp, index) => {
    predictedTimestampMap.set(timestamp, predictedFieldArray[index]);
  });

  // Iterate over the actual timestamps
  for (let timestamp of actualTimestampArray) {
    if (predictedTimestampMap.has(timestamp)) {
      // If the predicted timestamp exists, use it
      syncedPredictedTimestampArray.push(timestamp);
      syncedPredictedFieldArray.push(predictedTimestampMap.get(timestamp));
    } else {
      // If the predicted timestamp doesn't exist, push NaN
      syncedPredictedTimestampArray.push(timestamp);
      syncedPredictedFieldArray.push(NaN);
    }
  }

  // Now handle the non-matching timestamps
  for (let i = 0; i < syncedPredictedTimestampArray.length; i++) {
    if (isNaN(syncedPredictedFieldArray[i])) {
      // Find the closest predicted timestamp within 5 minutes
      for (let j = -300000; j <= 300000; j += 60000) {
        if (predictedTimestampMap.has(syncedPredictedTimestampArray[i] + j)) {
          syncedPredictedFieldArray[i] = predictedTimestampMap.get(syncedPredictedTimestampArray[i] + j);
          break;
        }
      }
    }
  }

  return syncedPredictedFieldArray;
}

function createAnomalyDataFrame(
  actualDataFrame: DataFrame,
  predictedDataframe: DataFrame,
  options: AnomalyScoreOptions
): DataFrame {
  const anomalyDataFrame = new MutableDataFrame({
    fields: cloneDeep(actualDataFrame.fields),
    length: actualDataFrame.length,
    meta: cloneDeep(actualDataFrame.meta),
    name: actualDataFrame.name,
    refId: actualDataFrame.refId,
  });

  const timeFieldActualArray = getTimeField(actualDataFrame).timeField?.values.toArray();
  const timeFieldPredictedArray = getTimeField(predictedDataframe).timeField?.values.toArray();
  const actualField = actualDataFrame.fields.find((field) => field.name === options.actualField);
  const predictedField = predictedDataframe.fields.find((field) => field.name === options.predictedField);
  const actualArray = actualField?.values.toArray();
  const predictedArray = predictedField?.values.toArray();

  const syncedPredictedArray = syncTimestamps(timeFieldActualArray!, timeFieldPredictedArray!, predictedArray!);
  if (predictedField) {
    predictedField.values = new ArrayVector(syncedPredictedArray);
  }
  anomalyDataFrame.addField(cloneDeep(predictedField!));
  const residualArray = actualArray!.map((value, index) => Math.abs(value - syncedPredictedArray![index]));
  const filteredResidualArray = residualArray.filter((value) => !isNaN(value));
  const maxResidual = filteredResidualArray.length > 0 ? Math.max(...filteredResidualArray) : 0;

  const anomalyArray = residualArray!.map((value) => (!isNaN(value) ? value / maxResidual : 0));

  const anomalyField: Field = {
    name: options.alias || Default_Alias,
    type: FieldType.number,
    config: {
      filterable: true,
    },
    values: new ArrayVector(anomalyArray),
  };

  anomalyDataFrame.addField(anomalyField);
  return anomalyDataFrame;
}

export function prepareAnomalyScore(data: DataFrame[], options: AnomalyScoreOptions): DataFrame[] {
  return anomalyDataToSend(data, options);
}

export const anomalyScoreTransformer: DataTransformerInfo<AnomalyScoreOptions> = {
  id: DataTransformerID.anomalyScore,
  name: 'Anomaly Score',
  description: 'Will calculate anomaly score with given actual and predicted data',
  defaultOptions: {},
  operator: (options) => (source) => source.pipe(map((data) => prepareAnomalyScore(data, options))),
};
