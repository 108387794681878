import { config, CarbonBootConfig } from '@grafana/runtime';
// Legacy binding paths
export { config, CarbonBootConfig as Settings };

let grafanaConfig: CarbonBootConfig = config;

export default grafanaConfig;

export const getConfig = () => {
  return grafanaConfig;
};

export const updateConfig = (update: Partial<CarbonBootConfig>) => {
  grafanaConfig = {
    ...grafanaConfig,
    ...update,
  };
};
