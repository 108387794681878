import React from 'react';
import { sharedSingleStatMigrationHandler } from '@grafana/ui';
import { PanelPlugin } from '@grafana/data';
import { CircleChartOptions } from './types';
import { addStandardDataReduceOptions } from '../stat/types';
import { CircleChart } from './CircleChart';
// import { SelectEditor } from './components/SelectEditor';
import { CircleOrPieSelectEditor } from 'app/features/linkDrawer/CircleOrPieSelectEditor';

export const plugin = new PanelPlugin<CircleChartOptions>(CircleChart)
  .useFieldConfig()
  .setPanelOptions((builder) => {
    addStandardDataReduceOptions(builder, false, true, false);

    builder
      .addRadio({
        path: 'graphType',
        name: 'Type Of Graph',
        description: 'Select your Graph Type',
        settings: {
          options: [
            { value: 'circle', label: 'Circle' },
            { value: 'polar', label: 'PolarChart' },
          ],
        },
        defaultValue: 'circle',
        category: ['Circle Chart Options'],
      })
      .addBooleanSwitch({
        path: 'polarAxis',
        name: 'Polar Axis',
        description: 'Add axis in Polar Chart',
        defaultValue: false,
        showIf: (options: CircleChartOptions) => options.graphType === 'polar',
        category: ['Circle Chart Options'],
      })
      .addRadio({
        path: 'chartType',
        name: 'Chart Type',
        description: 'Select the type of Chart',
        settings: {
          options: [
            { value: 'pie', label: 'Pie' },
            { value: 'donut', label: 'Donut' },
            { value: 'radialBar', label: 'Radial' },
          ],
        },
        defaultValue: 'pie',
        showIf: (options: CircleChartOptions) => options.graphType === 'circle',
        category: ['Circle Chart Options'],
      })
      .addRadio({
        path: 'radialOptionsSelected',
        name: 'Radial Data',
        description: 'Radial Data Options',
        settings: {
          options: [
            { value: 'max', label: 'Maximum' },
            { value: 'total', label: 'Total' },
            { value: 'custom', label: 'Custom' },
          ],
        },
        defaultValue: 'max',
        showIf: (options: CircleChartOptions) => options.chartType === 'radialBar' && options.graphType === 'circle',
        category: ['Circle Chart Options'],
      })
      .addNumberInput({
        path: 'radialInput',
        name: 'Custom Value',
        description: 'Enter a value to adjust the radial bar',
        defaultValue: 10000,
        settings: {
          placeholder: '10000',
          integer: true,
          // min: 0,
          // max: 10,
        },
        showIf: (options: CircleChartOptions) =>
          options.radialOptionsSelected === 'custom' && options.graphType === 'circle',
        category: ['Circle Chart Options'],
      })
      .addRadio({
        path: 'legendOrientation',
        name: 'Legend Position',
        // description: 'Position the legend.',
        settings: {
          options: [
            { value: 'top', label: 'Top' },
            { value: 'bottom', label: 'Bottom' },
            { value: 'left', label: 'Left' },
            { value: 'right', label: 'Right' },
          ],
        },
        defaultValue: 'top',
        category: ['Circle Chart Options'],
      })
      .addRadio({
        path: 'horizontalAlign',
        name: 'Horizontal Align',
        description: 'Horizontal alignment of the legend',
        settings: {
          options: [
            { value: 'center', label: 'Center' },
            { value: 'left', label: 'Left' },
            { value: 'right', label: 'Right' },
          ],
        },
        defaultValue: 'center',
        showIf: (options: CircleChartOptions) => options.graphType === 'circle',
        category: ['Circle Chart Options'],
      })
      .addBooleanSwitch({
        path: 'limitLabels',
        name: 'Enable Limit',
        description: 'Display data according to limit',
        defaultValue: false,
        category: ['Circle Chart Options'],
      })
      .addNumberInput({
        path: 'limit',
        name: 'Limit',
        description: 'Limit displayed items',
        defaultValue: 10,
        showIf: (options: CircleChartOptions) => options.limitLabels,
        category: ['Circle Chart Options'],
      })
      .addRadio({
        path: 'limit2',
        name: 'Limit Order',
        description: 'Filters data from top or bottom',
        settings: {
          options: [
            { value: 'none', label: 'None' },
            { value: 'top', label: 'Top' },
            { value: 'bottom', label: 'Bottom' },
          ],
        },
        defaultValue: 'none',
        showIf: (options: CircleChartOptions) => options.limitLabels,
        category: ['Circle Chart Options'],
      })
      .addBooleanSwitch({
        path: 'limitRemaining',
        name: 'Aggregate Remaining Data',
        description: 'Aggregate remaining data into Others',
        defaultValue: false,
        showIf: (options: CircleChartOptions) => options.limitLabels,
        category: ['Circle Chart Options'],
      })
      .addBooleanSwitch({
        path: 'dataLabels',
        name: 'Data Labels',
        description: 'Shows data labels on top of the chart',
        defaultValue: true,
        category: ['Circle Chart Options'],
      })
      .addBooleanSwitch({
        path: 'legendFloat',
        name: 'Float Legend',
        description: 'Puts legend in the background of the chart',
        defaultValue: false,
        category: ['Circle Chart Options'],
      })
      .addBooleanSwitch({
        path: 'legendShow',
        name: 'Show Legend',
        description: 'Show or hide the legend container',
        defaultValue: true,
        category: ['Circle Chart Options'],
      })
      .addBooleanSwitch({
        path: 'dashboardVisitMode',
        name: 'Enable Link Dashboard',
        // description: 'Enables `dashboard-visit-mode`',
        defaultValue: false,
        showIf: (options: CircleChartOptions) => options.graphType === 'circle',
        category: ['Link Dashboard'],
      })
      .addCustomEditor({
        id: 'toVisitDashboard',
        path: 'toVisitDashboard',
        name: 'Dashboard Links',
        // description: 'To Link Dashboard',
        defaultValue: [],
        editor(props: any) {
          return (
            <CircleOrPieSelectEditor bigData={props.context} toVisitDashboard={props.value} onChange={props.onChange} />
          );
        },
        showIf: (options: CircleChartOptions) => options.dashboardVisitMode && options.graphType === 'circle',
        category: ['Link Dashboard'],
      });
  })
  .setNoPadding()
  .setMigrationHandler(sharedSingleStatMigrationHandler);
