import React from 'react';
import { PieChartSvg } from './PieChartsvg';
import { VizLayout, VizLegend, VizLegendItem, LegendDisplayMode } from '@grafana/ui';
import { PieChartLegendOptions, PieChartLegendValues, PieChartOptions } from './types/types';
import { FALLBACK_COLOR, FieldDisplay, TimeRange, formattedValueToString } from '@grafana/data';

/**
 * pie chart props
 * @typedef {Object} PieChartProps
 * @property {Array<FieldDisplay>} values
 * @property {function} [onSeriesColorChange]
 * @property {object} restProps
 * @property {number} restProps.width
 * @property {number} restProps.height
 * @property {PieChartOptions} restProps.options
 */
interface PieChartProps {
  values: FieldDisplay[];
  onSeriesColorChange?: (label: string, color: string) => void;
  restProps: {
    width: number;
    height: number;
    options: PieChartOptions;
  };
  timeRange: TimeRange;
  drawerLinkTime: boolean;
}
/**
 * pie chart component for legend manipulation
 * @param {PieChartProps} props
 * @returns {JSX.Element}
 */
export const PieChart: React.FunctionComponent<PieChartProps> = (props: PieChartProps) => {
  const {
    values,
    onSeriesColorChange,
    restProps: { width, height, options },
    timeRange,
    drawerLinkTime,
  } = props;
  /**
   * @function
   * @param {Array<FieldDisplay>} values
   * @param {PieChartLegendOptions} legendOptions
   * @returns {JSX.Element}
   */
  const getLegend = (values: FieldDisplay[], legendOptions: PieChartLegendOptions) => {
    if (legendOptions.displayMode === LegendDisplayMode.Hidden) {
      return undefined;
    }
    const total = values.reduce((acc, item) => item.display.numeric + acc, 0);
    let legendItems: VizLegendItem[] = [];
    legendItems = values.map<VizLegendItem>((value, idx) => {
      return {
        label: value.display.title ?? '',
        color: value.display.color ?? FALLBACK_COLOR,
        yAxis: 1,
        getItemKey: () => (value.display.title ?? '') + idx,
        getDisplayValues: () => {
          const valuesToShow = legendOptions.values ?? [];
          let displayValues = [];

          if (valuesToShow.includes(PieChartLegendValues.Value)) {
            displayValues.push({
              numeric: value.display.numeric,
              text: formattedValueToString(value.display),
              title: 'Value',
            });
          }

          if (valuesToShow.includes(PieChartLegendValues.Percent)) {
            const fractionOfTotal = value.display.numeric / total;
            const percentOfTotal = fractionOfTotal * 100;

            displayValues.push({
              numeric: fractionOfTotal,
              percent: percentOfTotal,
              text: percentOfTotal.toFixed(2) + '%',
              title: valuesToShow.length > 1 ? 'Percent' : undefined,
            });
          }

          return displayValues;
        },
      };
    });
    return (
      <VizLegend
        items={legendItems}
        onSeriesColorChange={onSeriesColorChange}
        placement={legendOptions.placement}
        displayMode={legendOptions.displayMode}
      />
    );
  };

  return (
    <VizLayout width={width} height={height} legend={getLegend(values, options.legend)}>
      {(vizWidth: number, vizHeight: number) => {
        return (
          <PieChartSvg
            timeRange={timeRange}
            drawerLinkTime={drawerLinkTime}
            width={vizWidth}
            height={vizHeight}
            {...{ values }}
            options={options}
          />
        );
      }}
    </VizLayout>
  );
};
