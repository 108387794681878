import React, { useEffect, useState } from 'react';
import { Select, Input, InlineFormLabel, Button, Field, useTheme } from '@grafana/ui';
import { cloneDeep } from 'lodash';
import { SankeyDataI } from '../types';
import { DataFrame, FieldDisplay, getFieldDisplayValues } from '@grafana/data';

interface Props {
  sankeyData: SankeyDataI[];
  onChange: (sankeyData: SankeyDataI[]) => void;
  data: DataFrame[] | undefined;
  bigData: any;
}

type IOptions = {
  label: string;
  value: string;
};

export function HandleEditor({ sankeyData, onChange, data, bigData }: Props) {
  const [queries, setQueries] = React.useState<IOptions[]>([]);
  const [fromOptions, setFromOptions] = React.useState<IOptions[]>([]);
  const [toOptions, setToOptions] = React.useState<IOptions[]>([]);

  const [fromNode, setFromNode] = useState<string>('');
  const [toNode, setToNode] = useState<string>('');
  const [queryNode, setQueryNode] = useState<string>('');

  const [deleteFrom, setDeleteFrom] = useState('');
  const [deleteTo, setDeleteTo] = useState('');

  const { options, replaceVariables } = bigData;
  const { fieldConfig } = options;
  const theme = useTheme();

  useEffect(() => {
    let fromArray: IOptions[] = [];
    let toArray: IOptions[] = [];

    sankeyData.map((data: SankeyDataI) => {
      // PUSH THE INPUTED VALUES OF FROM
      fromArray.push({
        label: data.from,
        value: data.from,
      });
      // PUSH THE INPUTED VALUES OF TO
      toArray.push({
        label: data.to,
        value: data.to,
      });
    });

    // Remove duplicates
    fromArray = fromArray.filter(
      (value, index, self) => index === self.findIndex((t) => t.label === value.label && t.value === value.value)
    );
    // Remove duplicates
    toArray = toArray.filter(
      (value, index, self) => index === self.findIndex((t) => t.label === value.label && t.value === value.value)
    );
    setFromOptions(fromArray);
    setToOptions(toArray);
  }, [sankeyData]);

  // THIS WILL LOAD THE LATEST DATA
  const dataLoad = () => {
    const setoptions = cloneDeep(options.reduceOptions);
    delete setoptions.fields;
    delete setoptions.limit;
    delete setoptions.values;

    if (data!.length > 0) {
      const panelData = getFieldDisplayValues({
        fieldConfig,
        reduceOptions: options.reduceOptions,
        replaceVariables,
        theme: theme,
        data: data!,
        sparkline: false,
        timeZone: 'browser',
      });
      let localQueries: IOptions[] = [];
      panelData?.map((ele: FieldDisplay) => {
        if (ele?.display?.title && ele.view?.dataFrame.fields[1].type === 'number') {
          localQueries.push({
            label: ele?.display?.title,
            value: ele?.display?.title,
          });
        }
      });
      // @ts-ignore
      setQueries(localQueries);
    }
  };

  const onDeleteFromChange = ({ value }: any) => {
    setDeleteFrom(value);
  };

  const onDeleteToChange = ({ value }: any) => {
    setDeleteTo(value);
  };

  const onQueryChange = ({ value }: any) => {
    setQueryNode(value);
  };
  const onFromChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    setFromNode(event.currentTarget.value);
  };

  const onToChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    setToNode(event.currentTarget.value);
  };

  /**
   * it helps ro delete a value
   * @function
   * @returns it will delete a vluae
   */

  // This function will run user click on delete button
  const deleteValue = () => {
    // it will filter the selected values from the main data.
    sankeyData = sankeyData.filter((item: SankeyDataI) => {
      if (item.from === deleteFrom && item.to === deleteTo) {
        return false;
      } else {
        return true;
      }
      // return item.from !=== deleteFrom && item.to !=== deleteTo;
    });
    // it will update the full panel
    onChange(sankeyData);
    setDeleteFrom('');
    setDeleteTo('');
  };

  // It will run when user click on the add Button
  const handleSubmitData = () => {
    let itemFound = false;
    // Check duplicate
    sankeyData.map((data: SankeyDataI) => {
      if (data.from === fromNode && data.to === toNode) {
        itemFound = true;
      }
    });

    if (!itemFound) {
      sankeyData.push({
        from: fromNode,
        to: toNode,
        query: queryNode,
      });
      onChange(sankeyData);
    }
  };

  return (
    <div>
      <div className="gf-form max-width-30">
        <InlineFormLabel tooltip="Provide name for the starting point">Starting Point</InlineFormLabel>
        <Input value={fromNode} onChange={onFromChange} />
      </div>
      <div className="gf-form max-width-30">
        <InlineFormLabel tooltip="Provide name for the ending point">Ending Point</InlineFormLabel>
        <Input value={toNode} onChange={onToChange} />
      </div>
      <div className="gf-form">
        <InlineFormLabel tooltip="Select Query to load data">Query Name</InlineFormLabel>
        <Select
          width={20}
          options={queries}
          onChange={onQueryChange}
          value={queries.find((item: any) => item.value === queryNode)}
        />
        <Button onClick={dataLoad} variant="secondary" size="md">
          <i className="fa fa-refresh" aria-hidden="true"></i>
        </Button>
      </div>
      <Button variant="primary" onClick={handleSubmitData}>
        Add
      </Button>

      <div className="gf-form" style={{ marginTop: '1rem' }}>
        <Field label="Remove Node">
          <>
            <div className="gf-form">
              <InlineFormLabel>Start Point</InlineFormLabel>
              <Select
                width={20}
                options={fromOptions}
                value={fromOptions.find((option, index) => option.value === deleteFrom)}
                onChange={onDeleteFromChange}
              />
            </div>
            <div className="gf-form">
              <InlineFormLabel>End Point</InlineFormLabel>
              <Select
                width={20}
                options={toOptions}
                value={toOptions.find((option, index) => option.value === deleteTo)}
                onChange={onDeleteToChange}
              />
            </div>
            <Button className="max-width-5" variant="destructive" onClick={deleteValue}>
              Delete
            </Button>
          </>
        </Field>
      </div>
    </div>
  );
}
