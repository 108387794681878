import React from 'react';
import { Axis, Geom, Chart, Tooltip } from 'bizcharts';
import { config } from 'app/core/config';

interface TimeSeriesI {
  width: number;
  height: number;
  newChartData: any;
  bg: string;
  thresholds: any;
  tooltipTpl: any;
}
// //TO PASS DATA TO TOOLTIP
// const generateBoxPlotItemData = (range: any) => {
//   return {
//     value: range,
//   };
// };

export default function TimeSeries(props: TimeSeriesI) {
  const {
    height,
    width,
    newChartData,
    tooltipTpl,
    // bg
  } = props;
  const giveColor = (value: number) => {
    if (props.thresholds.mode === 'percentage') {
      return props.thresholds.steps[0].color;
    } else {
      let color = '#255aee';
      props.thresholds.steps.map((items: any) => {
        if (value > items.value) {
          color = items.color;
        }
      });
      return color;
    }
  };
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Chart
        height={height}
        width={width}
        data={newChartData}
        // scale={scales}
        padding="auto"
        theme={config.theme.type}
        background={{ fill: config.theme.isDark ? '#2C3144' : 'white' }}
        plotBackground={{ fill: config.theme.isDark ? '#2C3144' : 'white' }}
      >
        <Axis
          name="range"
          // grid={{
          //   lineStyle: {
          //     opacity: 0.5,
          //     stroke: 'gray',
          //     lineDash: [4, 4],
          //   },
          // }}
          // title={{ autoRotate: true, offset: 60, textStyle: { fill: config.theme.isDark ? 'white' : 'black' } }}
        />
        <Axis title={{ textStyle: { fill: config.theme.isDark ? 'white' : 'black' } }} />
        <Tooltip crosshairs={{ type: 'rect' }} itemTpl={tooltipTpl} />
        {/*
        // @ts-ignore */}
        <Geom
          type="interval"
          // shape="bar"
          // color={config.theme.isDark ? 'white' : 'black'}
          position="type*range"
          // style={{ fill: bg, stroke: 'rgba(0, 0, 0, 1)', fillOpacity: 0.7 }}
          color={[
            'range',
            (val) => {
              return giveColor(val);
            },
          ]}
          style={[
            'range',
            {
              stroke: 'rgba(0, 0, 0, 1)',
              fill: (val: any) => {
                return giveColor(val);
              },
              fillOpacity: 1,
            },
          ]}
          tooltip={[
            ' type*range ',
            (type, range) => {
              return {
                name: 'range',
                title: type,
                value: range,
              };
            },
          ]}
          // tooltip={['range', generateBoxPlotItemData]}
          // (newMaxData,this.props.options.decimal)
        />
      </Chart>
    </div>
  );
}
