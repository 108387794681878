import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import { OSM, Stamen, XYZ } from 'ol/source';
import { OSMapLayerOptions, OSMapLayerRegistryItem } from '../../LayerEditor/types';
import { CartoConfig } from './type';

/**
 * Represents a CARTO reference map configuration for mapping.
 *
 * @typedef {Object} CartoLayerConfig
 * @property {string} id - The identifier for the CARTO reference map layer.
 * @property {string} name - The display name for the CARTO reference map layer.
 * @property {boolean} isBaseMap - Indicates whether it's a base map.
 */
const carto: OSMapLayerRegistryItem<CartoConfig> = {
  id: 'carto',
  name: 'CARTO reference map',
  isBaseMap: true,

  /**
   * Creates a CARTO reference map layer based on the provided configuration.
   *
   * @function
   * @async
   * @param {Map} map - The map to which the CARTO reference map layer is added.
   * @param {OSMapLayerOptions<CartoConfig>} options - Options for the CARTO reference map layer.
   * @returns {Promise<{ init: Function }>} A promise that resolves to an object with an `init` function.
   */
  create: async (map: Map, options: OSMapLayerOptions<CartoConfig>) => ({
    init: () => {
      return new TileLayer({
        source: new XYZ({
          attributions: `<a href="https://carto.com/attribution/">© CARTO</a>`,
          url: `https://{1-4}.basemaps.cartocdn.com/${options.config?.theme}/{z}/{x}/{y}.png`,
        }),
      });
    },
  }),
};

/**
 * Creates a Google Maps layer configuration for mapping.
 *
 * @function
 * @param {string} id - The identifier for the Google Maps layer.
 * @param {string} name - The display name for the Google Maps layer.
 * @param {string} url - The URL for the Google Maps tile source.
 * @returns {OSMapLayerRegistryItem<any>} An object representing the Google Maps layer configuration.
 */
const googleMaps = (id: string, name: string, url: string) => {
  const map: OSMapLayerRegistryItem<any> = {
    id,
    name,
    isBaseMap: true,
    defaultOptions: {},

    create: async (map: Map, options: OSMapLayerOptions<any>) => ({
      init: () => {
        return new TileLayer({
          source: new XYZ({
            url,
          }),
        });
      },
    }),
  };
  return map;
};

const googleRoadmap = googleMaps('roadmap', 'Google roadmap', `https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}`);
const googleHybrid = googleMaps('hybrid', 'Google hybrid', `https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}`);
const googleS = googleMaps('satellite', 'Google satellite', `https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}`);
const googleTerrain = googleMaps('terrainOS', 'Google terrainOS', `https://mt1.google.com/vt/lyrs=t&x={x}&y={y}&z={z}`);

const stamenMaps = (id: string, name: string, layerName: string) => {
  const map: OSMapLayerRegistryItem<any> = {
    id,
    name,
    isBaseMap: true,
    defaultOptions: {},

    create: async (map: Map, options: OSMapLayerOptions<any>) => ({
      init: () => {
        return new TileLayer({
          source: new Stamen({
            layer: layerName,
          }),
        });
      },
    }),
  };
  return map;
};

const waterColor = stamenMaps('watercolor', 'Stamen watercolor', `watercolor`);
const stamenTerrain = stamenMaps('terrain', 'Stamen terrain', `terrain`);
const stamenToner = stamenMaps('toner', 'Stamen toner', `toner`);
const osMaps = (id: string, name: string, osm: any) => {
  const map: OSMapLayerRegistryItem<any> = {
    id,
    name,
    isBaseMap: true,
    defaultOptions: {},

    create: async (map: Map, options: OSMapLayerOptions<any>) => ({
      init: () => {
        return new TileLayer({
          source: osm,
        });
      },
    }),
  };
  return map;
};
export const standardOS = osMaps('standerOS', 'standerOSMap', new OSM());

export const baseLayers = [
  carto,
  googleS,
  googleRoadmap,
  googleHybrid,
  googleTerrain,
  waterColor,
  stamenTerrain,
  stamenToner,
  standardOS,
];
