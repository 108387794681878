import { FieldDisplay, GrafanaTheme, formattedValueToString, FALLBACK_COLOR } from '@grafana/data';
import { PieChartType, PieLayout, VizTooltipOptions } from './types/types';
import tinycolor from 'tinycolor2';
import { PieArcDatum, ProvidedProps } from '@visx/shape/lib/shapes/Pie';
import { PolarArrayType } from './types/interfaceSvg';

export function filterDisplayItems(item: FieldDisplay) {
  return !item.field.custom?.hideFrom?.viz && !isNaN(item.display.numeric);
}
/**
 * gradient color generate. value of from
 * @param {string} color
 * @param {GrafanaTheme} theme
 * @returns {string}
 */
export function getGradientColorFrom(color: string, theme: GrafanaTheme) {
  return tinycolor(color)
    .darken(20 * (theme.isDark ? 1 : -0.7))
    .spin(4)
    .toRgbString();
}
/**
 * gradient color generate. value of to
 * @param {string} color
 * @param {GrafanaTheme} theme
 * @returns {string}
 */
export function getGradientColorTo(color: string, theme: GrafanaTheme) {
  return tinycolor(color)
    .darken(10 * (theme.isDark ? 1 : -0.7))
    .spin(-4)
    .toRgbString();
}

/**
 * get pie layout
 * @param {number} height
 * @param {number} width
 * @param {PieChartType} pieType
 * @param {number} margin
 * @returns {PieLayout}
 */
export function getPieLayout(height: number, width: number, pieType: PieChartType, margin = 16): PieLayout {
  const size = Math.abs(Math.min(width, height));
  const outerRadius = size > 64 ? (size - margin * 2) / 2 : 32;
  const donutThickness = pieType === PieChartType.Pie ? outerRadius : Math.max(outerRadius / 3, 20);
  const innerRadius = outerRadius - donutThickness;
  const centerOffset = size > 64 ? (size - margin * 2) / 2 : 32;
  // for non donut pie charts shift gradient out a bit
  const gradientFromOffset = 1 - (outerRadius - innerRadius) / outerRadius;
  return {
    position: centerOffset + margin,
    size: size,
    outerRadius: outerRadius,
    innerRadius: innerRadius,
    gradientFromOffset: gradientFromOffset,
  };
}

/**
 * give label position on pie slice
 * @param {PieArcDatum<FieldDisplay>} arc
 * @param {number} outerRadius
 * @param {number} innerRadius
 * @returns {Array<number,number>}
 */
export function getLabelPosition(
  arc: PieArcDatum<FieldDisplay>,
  outerRadius: number,
  innerRadius: number
): [number, number] {
  const r = (outerRadius + innerRadius) / 2;
  const a = (+arc.startAngle + +arc.endAngle) / 2 - Math.PI / 2;
  return [Math.cos(a) * r, Math.sin(a) * r];
}
/**
 * tooltip for pie chart when pie and donut is selected
 * @param {Array<PolarArrayType>} pie
 * @param {PolarArrayType} arc
 * @param {VizTooltipOptions} tooltipOptions
 * @returns {Object}
 */
export function getTooltipData(
  pie: ProvidedProps<FieldDisplay>,
  arc: PieArcDatum<FieldDisplay>,
  tooltipOptions: VizTooltipOptions
) {
  if (tooltipOptions.mode === 'multi') {
    return pie.arcs.map((pieArc) => {
      return {
        color: pieArc.data.display.color ?? FALLBACK_COLOR,
        label: pieArc.data.display.title,
        value: formattedValueToString(pieArc.data.display),
        isActive: pieArc.index === arc.index,
      };
    });
  }
  return [
    {
      color: arc.data.display.color ?? FALLBACK_COLOR,
      label: arc.data.display.title,
      value: formattedValueToString(arc.data.display),
    },
  ];
}
/**
 * get tooltip for radial and polar chart
 * @param {Array<PolarArrayType>}  arcs
 * @param {PolarArrayType} arc
 * @param {VizTooltipOptions} tooltipOptions
 * @returns {Object}
 */
export function getTooltipDataPolar(arcs: PolarArrayType[], arc: PolarArrayType, tooltipOptions: VizTooltipOptions) {
  if (tooltipOptions.mode === 'multi') {
    return arcs.map((ele: any) => {
      return {
        color: ele.color ?? FALLBACK_COLOR,
        label: ele.title,
        value: Math.round(ele.numericValue),
        isActive: ele.key === arc.key,
      };
    });
  }
  return [
    {
      color: arc.color ?? FALLBACK_COLOR,
      label: arc.title,
      value: Math.round(arc.numericValue),
    },
  ];
}
