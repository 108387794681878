import React, { useCallback, useState } from 'react';
import { Group } from '@visx/group';

import { Treemap, hierarchy, stratify } from '@visx/hierarchy';
import { TreeStru, TreeMapPanelOptions } from './types';
import { findTileMethod } from './utils';
import { SeriesTable, SeriesTableRowProps, useStyles, useTheme } from '@grafana/ui';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { GrafanaTheme } from '@grafana/data';
import { localPoint } from '@visx/event';
import { getTooltipContainerStyles } from '@grafana/ui/src/themes/mixins';
import { css } from 'emotion';
import tinycolor from 'tinycolor2';

export type TreemapProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  tileMethod: string;
  mainData: TreeStru[];
  color: string[];
  labels: any;
  options: TreeMapPanelOptions;
};

export default function TreeMapSvg({ width, height, tileMethod, mainData, color, labels, options }: TreemapProps) {
  const xMax1 = width;
  const yMax1 = height;
  const theme = useTheme();
  const [total, setTotal] = useState('');
  // * making parent node structure for treemap - inbuilt function of visx
  const data = stratify<TreeStru>()
    .id((d) => d.id)
    .parentId((d) => d.parent)(mainData)
    .sum((d) => d.size || 0);
  const root = hierarchy(data).sort((a, b) => (b.value || 0) - (a.value || 0));

  const { tooltip: tooltipOptions } = options;
  const styles = useStyles(getStyles);
  /**
   * * node.xo => start X coordinate of the box
   * * node.x1 => end X coordinate of the box
   * * node.yo => start Y coordinate of the box
   * * node.y1 => end Y coordinate of the box
   */

  // * toolltip start
  const tooltip = useTooltip<SeriesTableRowProps[]>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });
  const showTooltip = tooltipOptions.mode !== 'none' && tooltip.tooltipOpen;
  const onMouseOut = useCallback(() => {
    tooltip.hideTooltip();
  }, [tooltip]);

  const onMouseOver = (event: any, color: string, name: string, node: any) => {
    let toolipData = [];

    let SumOfChildsValue = 0;

    //* get the value of child and made a tooltip.
    for (let i = 0; i < mainData.length; i++) {
      if (name === mainData[i].id) {
        //* assign parent color to child when options.childcolor is true.
        let parentColor = getColor(name);
        toolipData.push({
          label: mainData[i].id,
          value: mainData[i].tooltip,
          color: parentColor,
        });
        //* find the childs value related to the same parent and add values in SumOfChildsValue variable.
        for (let j = 0; j < mainData.length; j++) {
          if (mainData[i].parent === mainData[j].parent) {
            const getsize = mainData[j]?.size === null ? 0 : mainData[j]?.size;
            // @ts-ignore: Object is possibly 'null'.
            SumOfChildsValue += getsize;
          }
        }
      }
    }
    setTotal(`Total : ${SumOfChildsValue}`);
    toolipData = toolipData.filter((item: any) => item.value);
    const coords = localPoint(event.target.ownerSVGElement, event);
    tooltip.showTooltip({
      tooltipLeft: coords!.x,
      tooltipTop: coords!.y,
      tooltipData: toolipData,
    });
  };
  // * tooltip end

  const themeFactor = theme.isDark ? 1 : -0.7;
  const getColor = (name: any) => {
    var querycolor;
    //* Finding the color of the parent of the child. When (options.childcolor) is true.
    if (options.childcolor) {
      for (let i = 0; i < mainData.length; i++) {
        if (name === mainData[i].id) {
          for (let j = 0; j < mainData.length; j++) {
            if (mainData[i].parent === mainData[j].id) {
              querycolor = mainData[j].color;
            }
          }
        }
      }
    } else {
      for (let i = 0; i < mainData.length; i++) {
        if (name === mainData[i].id) {
          querycolor = mainData[i].color;
        }
      }
    }
    return querycolor;
  };
  const getFromColor = (passedColor: any) => {
    let gColor = getColor(passedColor);
    return tinycolor(gColor)
      .darken(10 * themeFactor)
      .spin(5)
      .toRgbString();
  };
  const newRange: string[] | undefined = [];
  const newDomain: number[] | undefined = [];

  color.map((singleColor, index) => {
    newRange.push(`url(#${index})`);
    newDomain.push(index);
  });

  return width < 10 ? null : (
    <div>
      <svg width={xMax1} height={yMax1} ref={containerRef}>
        <Treemap<typeof data> top={5} root={root} size={[xMax1, yMax1]} tile={findTileMethod(tileMethod)} round>
          {(treemap) => (
            <Group>
              {treemap.descendants().map((node, i) => {
                const nodeWidth = node.x1 - node.x0 - options.spaceBwTile;
                const nodeHeight = node.y1 - node.y0 - options.spaceBwTile;
                return (
                  <Group key={`node-${i}`} top={node.y0} left={node.x0}>
                    {node.depth === 1 && (
                      <>
                        <rect
                          width={nodeWidth}
                          height={nodeHeight}
                          stroke={theme.colors.bg1}
                          strokeWidth={options.spaceBwTile}
                          fillOpacity={0}
                          fill="transparent"
                          onMouseOver={(event) => {
                            onMouseOver(event, color[i], node.data.id!, node);
                          }}
                          onMouseOut={onMouseOut}
                          className={styles.svgArg}
                        />
                      </>
                    )}

                    {node.depth >= 2 && (
                      <>
                        <rect
                          width={nodeWidth}
                          height={nodeHeight}
                          stroke={theme.colors.bg1}
                          strokeWidth={options.spaceBwTile}
                          fillOpacity={options.fillopacity}
                          fill={
                            node.data.children
                              ? 'transparent'
                              : options.colorMode === 'gradient'
                              ? getFromColor(node.data.id)
                              : getColor(node.data.id)
                          }
                          onMouseOver={(event) => {
                            onMouseOver(event, color[i], node.data.id!, node);
                          }}
                          onMouseOut={onMouseOut}
                          className={styles.svgArg}
                        />
                        {/* Show/hide labels according to the lable value */}
                        {labels === 'showDatalabel' && !node.data.children && nodeWidth > 48 && nodeHeight > 20 && (
                          <text
                            dx={nodeWidth / 2}
                            dy={nodeHeight / 2}
                            fontFamily="Arial"
                            textAnchor="middle"
                            style={{
                              fill: theme.colors.text,
                              fontSize: nodeWidth > 100 ? 16 : 12,
                              fontWeight: 600,
                            }}
                          >
                            {node.data.id}
                          </text>
                        )}
                        {/* Show/hide label Value according to the dataLabelvalue */}
                        {options.dataLabelvalue === 'showDatalabelValue' &&
                          !node.data.children &&
                          nodeWidth > 48 &&
                          nodeHeight > 20 && (
                            <text
                              dx={nodeWidth / 2}
                              dy={nodeHeight / 2 + 20}
                              fontFamily="Arial"
                              textAnchor="middle"
                              style={{
                                fill: theme.colors.text,
                                fontSize: nodeWidth > 100 ? 12 : 8,
                                fontWeight: 200,
                              }}
                            >
                              {node.data.data.tooltip}
                            </text>
                          )}
                      </>
                    )}
                  </Group>
                );
              })}
            </Group>
          )}
        </Treemap>
      </svg>
      {showTooltip ? (
        <TooltipInPortal
          key={Math.random()}
          top={tooltip.tooltipTop}
          className={styles.tooltipPortal}
          left={tooltip.tooltipLeft}
          unstyled={true}
          applyPositionStyle={true}
        >
          <SeriesTable timestamp={total} series={tooltip.tooltipData!} />
        </TooltipInPortal>
      ) : null}
    </div>
  );
}
const getStyles = (theme: GrafanaTheme) => {
  return {
    tooltipPortal: css`
      ${getTooltipContainerStyles(theme)}
    `,
    svgArg: css`
      transition: all 200ms ease-in-out;
      &:hover {
        transform: scale(1.007, 1.007);
      }
    `,
  };
};
