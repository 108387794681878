import React from 'react';
import { cloneDeep, isNumber } from 'lodash';
import { GraphNGLegendEventMode, XYFieldMatchers } from './types';
import {
  ArrayVector,
  DataFrame,
  FieldConfig,
  FieldType,
  formattedValueToString,
  getFieldColorModeForField,
  getFieldDisplayName,
  getFieldSeriesColor,
  GrafanaTheme,
  outerJoinDataFrames,
  TimeRange,
  TimeZone,
} from '@grafana/data';
import { UPlotConfigBuilder } from '../uPlot/config/UPlotConfigBuilder';
import { UPlot_UNIT } from './GraphNG2';
import {
  AxisPlacement,
  DrawStyle,
  GraphFieldConfig,
  PointVisibility,
  ScaleDirection,
  ScaleOrientation,
  StackingMode,
} from '../uPlot/config';
import { nullToUndefThreshold } from './nullToUndefThreshold';

const defaultFormatter = (v: any) => (v == null ? '-' : v.toFixed(1));

const defaultConfig: GraphFieldConfig = {
  drawStyle: DrawStyle.Line,
  showPoints: PointVisibility.Auto,
  axisPlacement: AxisPlacement.Auto,
};

export function mapMouseEventToMode(event: React.MouseEvent): GraphNGLegendEventMode {
  if (event.ctrlKey || event.metaKey || event.shiftKey) {
    return GraphNGLegendEventMode.AppendToSelection;
  }
  return GraphNGLegendEventMode.ToggleSelection;
}

// will mutate the DataFrame's fields' values
function applySpanNullsThresholds(frame: DataFrame) {
  let refField = frame.fields.find((field) => field.type === FieldType.time); // this doesnt need to be time, just any numeric/asc join field
  let refValues = refField?.values.toArray() as any[];

  for (let i = 0; i < frame.fields.length; i++) {
    let field = frame.fields[i];

    if (field === refField) {
      continue;
    }

    if (field.type === FieldType.number) {
      let spanNulls = field.config.custom?.spanNulls;

      if (typeof spanNulls === 'number') {
        if (spanNulls !== -1) {
          field.values = new ArrayVector(nullToUndefThreshold(refValues, field.values.toArray(), spanNulls));
        }
      }
    }
  }

  return frame;
}

export function preparePlotFrame(frames: DataFrame[], dimFields: XYFieldMatchers) {
  let timelineFrame = outerJoinDataFrames({
    frames: frames,
    joinBy: dimFields.x,
    keep: dimFields.y,
    keepOriginIndices: true,
  });

  return timelineFrame && applySpanNullsThresholds(timelineFrame);
}

export function preparePlotFrame2(data: DataFrame[], dimFields: XYFieldMatchers) {
  return outerJoinDataFrames({
    frames: data,
    joinBy: dimFields.x,
    keep: dimFields.y,
    keepOriginIndices: true,
  });
}

export function preparePlotConfigBuilder(
  frame: DataFrame,
  theme: GrafanaTheme,
  getTimeRange: () => TimeRange,
  getTimeZone: () => TimeZone,
  isAnomalyGraph?: boolean | undefined,
  yAxis2Field?: string | undefined
): UPlotConfigBuilder {
  const builder = new UPlotConfigBuilder(getTimeZone);
  // X is the first field in the aligned frame
  const xField = frame.fields[0];
  let seriesIndex = 0;

  if (xField.type && xField.type === FieldType.time) {
    builder.addScale({
      scaleKey: 'x',
      orientation: ScaleOrientation.Horizontal,
      direction: ScaleDirection.Right,
      isTime: true,
      range: () => {
        const r = getTimeRange();
        return [r.from.valueOf(), r.to.valueOf()];
      },
    });

    builder.addAxis({
      scaleKey: 'x',
      isTime: true,
      placement: AxisPlacement.Bottom,
      timeZone: getTimeZone(),
      theme,
    });
  } else {
    // Not time!
    builder.addScale({
      scaleKey: 'x',
      orientation: ScaleOrientation.Horizontal,
      direction: ScaleDirection.Right,
    });

    builder.addAxis({
      scaleKey: 'x',
      placement: AxisPlacement.Bottom,
      theme,
    });
  }

  let indexByName: Map<string, number> | undefined = undefined;

  for (let i = 0; i < frame.fields.length; i++) {
    const field = frame.fields[i];
    const config = field.config as FieldConfig<GraphFieldConfig>;
    const customConfig: GraphFieldConfig = {
      ...defaultConfig,
      ...config.custom,
    };

    if (field === xField || field.type !== FieldType.number) {
      continue;
    }
    field.state!.seriesIndex = seriesIndex++;

    const fmt = field.display ?? defaultFormatter;
    let scaleKey = config.unit || UPlot_UNIT;
    const colorMode = getFieldColorModeForField(field);
    const scaleColor = getFieldSeriesColor(field, theme);
    const seriesColor = scaleColor.color;

    let axisPlacement = AxisPlacement.Left; // default placement
    if ((field.name === 'Anomaly Score' && isAnomalyGraph) || (yAxis2Field && field.name === yAxis2Field)) {
      axisPlacement = AxisPlacement.Right; // place "Anomaly Score" on the right
      if (field.name === 'Anomaly Score' && isAnomalyGraph) {
        scaleKey = 'anomaly'; // unique scale key for "Anomaly Score"
      } else if (yAxis2Field && field.name === yAxis2Field) {
        scaleKey = yAxis2Field;
      }
    }

    builder.addScale({
      scaleKey,
      orientation: ScaleOrientation.Vertical,
      direction: ScaleDirection.Up,
      distribution: customConfig.scaleDistribution?.type,
      log: customConfig.scaleDistribution?.log,
      min: field.config.min,
      max: field.config.max,
      softMin: customConfig.axisSoftMin,
      softMax: customConfig.axisSoftMax,
    });

    if (customConfig.axisPlacement !== AxisPlacement.Hidden) {
      builder.addAxis({
        scaleKey,
        label: customConfig.axisLabel,
        size: customConfig.axisWidth,
        placement: axisPlacement,
        formatValue: (v) => {
          return customConfig.stacking?.mode === StackingMode.Percent
            ? formattedValueToString(fmt(v * 100)) + '%'
            : formattedValueToString(fmt(v));
        },
        theme,
      });
    }

    const showPoints = customConfig.drawStyle === DrawStyle.Points ? PointVisibility.Always : customConfig.showPoints;

    let { fillOpacity } = customConfig;
    if (customConfig.fillBelowTo) {
      if (!indexByName) {
        indexByName = getNamesToFieldIndex(frame);
      }
      const t = indexByName.get(getFieldDisplayName(field, frame));
      const b = indexByName.get(customConfig.fillBelowTo);
      if (isNumber(b) && isNumber(t)) {
        builder.addBand({
          series: [t, b],
          fill: null as any, // using null will have the band use fill options from `t`
        });
      }
      if (!fillOpacity) {
        fillOpacity = 35; // default from flot
      }
    }

    builder.addSeries({
      scaleKey,
      showPoints,
      colorMode,
      fillOpacity,
      theme,
      drawStyle: customConfig.drawStyle!,
      lineColor: customConfig.lineColor ?? seriesColor,
      lineWidth: customConfig.lineWidth,
      lineInterpolation: customConfig.lineInterpolation,
      lineStyle: customConfig.lineStyle,
      barAlignment: customConfig.barAlignment,
      pointSize: customConfig.pointSize,
      pointColor: customConfig.pointColor ?? seriesColor,
      spanNulls: customConfig.spanNulls || false,
      show: !customConfig.hideFrom?.graph,
      gradientMode: customConfig.gradientMode,
      thresholds: config.thresholds,

      // The following properties are not used in the uPlot config, but are utilized as transport for legend config
      dataFrameFieldIndex: field.state?.origin,
      fieldName: getFieldDisplayName(field, frame),
      hideInLegend: customConfig.hideFrom?.legend,
    });
  }

  return builder;
}

export function getNamesToFieldIndex(frame: DataFrame): Map<string, number> {
  const names = new Map<string, number>();
  for (let i = 0; i < frame.fields.length; i++) {
    names.set(getFieldDisplayName(frame.fields[i], frame), i);
  }
  return names;
}
export function preparePlotFilterNullandZeroValue(frame: DataFrame[]) {
  const dataframes = cloneDeep(frame);
  const config = dataframes[0].fields[0].config as FieldConfig<GraphFieldConfig>;
  const customConfig: GraphFieldConfig = {
    ...defaultConfig,
    ...config.custom,
  };
  for (let i = 0; i < dataframes.length; i++) {
    const data = dataframes[i].fields.filter((field) => {
      //* Remove zero value from series
      if (customConfig.hideSeries?.withOnlyZeros && !customConfig.hideSeries?.withOnlyNulls) {
        if (field.type === FieldType.number) {
          let zeroValue = new ArrayVector(
            field.values.toArray().filter((v) => {
              return v !== 0;
            })
          );
          if (zeroValue.length === 0) {
            //* Series have all valuse is zero.
            return;
          } else {
            return {
              ...field,
              values: zeroValue,
            };
          }
        } else if (field.type === FieldType.time) {
          return field;
        }
      }
      //* Remove null value from series
      else if (customConfig.hideSeries?.withOnlyNulls && !customConfig.hideSeries?.withOnlyZeros) {
        if (field.type === FieldType.number) {
          let nullValue = new ArrayVector(
            field.values.toArray().filter((v) => {
              return v !== null;
            })
          );
          if (nullValue.length === 0) {
            //* Series have all valuse is null.
            return;
          } else {
            return {
              ...field,
              values: nullValue,
            };
          }
        } else if (field.type === FieldType.time) {
          return field;
        }
      }
      //* Remove both null,zero value from series
      else if (customConfig.hideSeries?.withOnlyNulls && customConfig.hideSeries?.withOnlyZeros) {
        if (field.type === FieldType.number) {
          let bothNullZero = new ArrayVector(
            field.values.toArray().filter((v) => {
              return v !== null && v !== 0;
            })
          );
          if (bothNullZero.length === 0) {
            //* Series have all valuse is zero and null.
            return;
          } else {
            return {
              ...field,
              values: bothNullZero,
            };
          }
        } else if (field.type === FieldType.time) {
          return field;
        }
      } else {
        return field;
      }
      return;
    });
    if (data.length === 1) {
      dataframes[i].fields = [];
    } else if (data.length === 2) {
      dataframes[i].fields = data;
    }
  }
  return dataframes;
}
