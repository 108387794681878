import Control from 'ol/control/Control'; // Import the base Control class from OpenLayers

/**
 * Represents a custom control for displaying additional content on the map.
 *
 * @class
 * @extends Control
 * @param {any} options - The options for configuring the custom control.
 */
export class CustomControl extends Control {
  constructor(options: any) {
    super(options);

    // Create the control's element and set its initial content or appearance.
    const element = document.createElement('div');
    element.className = '';
    element.textContent = ''; // Initial content

    // Set the element to the control.
    this.element = element;
  }

  /**
   * Updates the content of the custom control element.
   *
   * @param {HTMLDivElement | null} element - The HTML element to append to the control.
   * @param {boolean} isBoth - Indicates whether both legends are displayed.
   * @param {number} stepsLength - The length of steps for positioning the control.
   */
  updateContent(element: HTMLDivElement | null, isBoth: boolean, stepsLength: number) {
    this.element.innerHTML = '';

    if (isBoth) {
      this.element.setAttribute('style', `bottom: ${stepsLength * 30 + 38}px;`);
    } else {
      this.element.setAttribute('style', `bottom: 30px;`);
    }
    if (element) {
      this.element.append(element);
    }
  }
}
