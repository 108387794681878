import { css } from '@emotion/css';
import { GrafanaTheme } from '@grafana/data';
import { Button, ColorPicker, InlineField, InlineFieldRow, InlineLabel, Input, Select, Slider } from '@grafana/ui';
import { config } from 'app/core/config';
import { cloneDeep } from 'lodash';
import { FeatureLike } from 'ol/Feature';
import React from 'react';
import { ComparisonOperation } from '../Layers/geojsonLayer/default';
import { MarkerFeatureStyleConfig } from '../Layers/markerLayer/type';
import { getIconOptions } from '../custom/CustomSymbol';
import { OSMapLayerState } from '../types/types';
import { getSelectionInfo } from '../utils/selection';
import { LABEL_WIDTH, setProperty } from './GeojsonStyle';
import { comparators } from './default';
import { getLayerPropertyInfo, getUniqueFeatureValues } from './util';
import { DEFAULT_MARKER_STYLE_RULE } from '../Layers/markerLayer/default';

/**
 * Props for a component dealing with marker feature styles in an OpenStreetMap layer.
 * @interface Props
 * @property {OSMapLayerState<any>} layer - The state of the OpenStreetMap layer.
 * @property {MarkerFeatureStyleConfig} value - The configuration for marker feature styles.
 * @property {FeatureLike[]} features - An array of OpenLayers features.
 * @property {number} ruleIdx - The index of the rule to be edited.
 */
interface Props {
  layer: OSMapLayerState<any>;
  value: MarkerFeatureStyleConfig;
  features: FeatureLike[];
  ruleIdx: number;
}

/**
 * Renders the style settings for a marker feature rule in an OpenStreetMap layer.
 *
 * @param {Props} Props - The component props.
 * @param {OSMapLayerState<any>} Props.layer - The state of the OpenStreetMap layer.
 * @param {MarkerFeatureStyleConfig} Props.value - The configuration for marker feature styles.
 * @param {FeatureLike[]} Props.features - An array of OpenLayers features.
 * @param {number} Props.ruleIdx - The index of the rule to be edited.
 * @returns {JSX.Element | null} The JSX element for the marker feature style settings, or null if features are not available.
 */
export function MarkerRuleStyle({ layer, value, features, ruleIdx }: Props) {
  if (!features) {
    return null;
  }
  const properties = getLayerPropertyInfo(features);
  const styles = getStyles(config.theme);

  /**
   * Generates a list of unique selectable values based on feature properties and marker feature style configuration.
   *
   * @returns {Array<SelectableValue<number | string>>} An array of unique selectable values.
   */
  const uniqueSelectables = () => {
    const key = value?.check?.property;
    if (
      key &&
      features &&
      (value.check?.operation === ComparisonOperation.EQ || value.check?.operation === ComparisonOperation.NEQ)
    ) {
      return getUniqueFeatureValues(features, key).map((v) => {
        let newValue;
        let isNewValueNumber = !isNaN(Number(v));

        if (isNewValueNumber) {
          newValue = {
            value: Number(v),
            label: v,
          };
        } else {
          newValue = { value: v, label: v };
        }

        return newValue;
      });
    }
    return [];
  };

  const check = value.check ?? DEFAULT_MARKER_STYLE_RULE.check!;
  const propv = getSelectionInfo(check.property, properties.properties);
  const valuev = getSelectionInfo(check.value, uniqueSelectables());

  const newRules = cloneDeep(layer.options.config.rules);

  const onChangeProperty = (path: string, val: any) => {
    const newValueObj = setProperty(Object.assign({}, value), path, val);
    newRules[ruleIdx] = newValueObj;
    const newOpt = setProperty(cloneDeep(layer.options), 'config.rules', newRules);
    layer.onChange(newOpt);
  };

  return (
    <div className={styles.rule}>
      <InlineFieldRow className={styles.row}>
        <InlineField label="Rule" labelWidth={LABEL_WIDTH} grow={true}>
          <Select
            menuShouldPortal
            placeholder={'Feature property'}
            value={propv.current}
            options={propv.options}
            onChange={(e) => onChangeProperty('check.property', e.value)}
            aria-label={'Feature property'}
          />
        </InlineField>
        <InlineField className={styles.inline}>
          <Select
            menuShouldPortal
            value={comparators.find((v) => v.value === check.operation)}
            onChange={(e) => onChangeProperty('check.operation', e.value)}
            options={comparators}
            aria-label={'Comparison operator'}
            width={8}
          />
        </InlineField>
        <InlineField className={styles.inline} grow={true}>
          <>
            {(check.operation === ComparisonOperation.EQ || check.operation === ComparisonOperation.NEQ) && (
              <Select
                menuShouldPortal
                placeholder={'value'}
                value={valuev.current}
                options={valuev.options}
                aria-label={'Comparison value'}
                onChange={(e) => onChangeProperty('check.value', e.value || '')}
              />
            )}
            {check.operation !== ComparisonOperation.EQ && (
              <Input
                type="number"
                key={`${check.property}/${check.operation}`}
                value={!isNaN(Number(check.value)) ? Number(check.value) : 0}
                placeholder="numeric value"
                onChange={(e) => onChangeProperty('check.value', Number(e.currentTarget.value))}
              />
            )}
            <Button
              size="md"
              onClick={() => {
                newRules.splice(ruleIdx, 1);
                const newOpt = setProperty(cloneDeep(layer.options), 'config.rules', newRules);
                layer.onChange(newOpt);
              }}
              variant="destructive"
              aria-label={'Delete style rule'}
              className={styles.button}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </Button>
          </>
        </InlineField>
      </InlineFieldRow>

      {properties.geometryType === 'point' && (
        <>
          <InlineField label="Symbol" labelWidth={LABEL_WIDTH}>
            <Select
              noOptionsMessage="no Marker Symbol fields found"
              options={getIconOptions}
              value={value.style?.symbol}
              onChange={(e) => onChangeProperty('style.symbol', e.value)}
            />
          </InlineField>
          <InlineField label="Min Scale" labelWidth={LABEL_WIDTH}>
            <Input
              type="number"
              value={value.style?.size.min || 2}
              min={0}
              onChange={(e) => onChangeProperty('style.size.min', Number(e.currentTarget.value))}
            />
          </InlineField>
          <InlineField label="Max Scale" labelWidth={LABEL_WIDTH}>
            <Input
              type="number"
              value={value.style?.size.max || 15}
              min={0}
              onChange={(e) => onChangeProperty('style.size.max', Number(e.currentTarget.value))}
            />
          </InlineField>
          <InlineField label="Rotation" labelWidth={LABEL_WIDTH} grow={true}>
            <Input
              min={0}
              max={360}
              type="number"
              value={value.style?.rotation || 0}
              placeholder="numeric value"
              onChange={(e) => onChangeProperty('style.rotation', Number(e.currentTarget.value))}
            />
          </InlineField>
        </>
      )}
      <InlineField label="Color" labelWidth={LABEL_WIDTH}>
        <InlineLabel width={4}>
          <ColorPicker
            onChange={(color) => onChangeProperty('style.color', color)}
            color={value.style?.color || 'dark-red'}
            enableNamedColors={true}
          />
        </InlineLabel>
      </InlineField>
      <InlineField label="Fill Opacity" labelWidth={LABEL_WIDTH}>
        <Slider
          min={0}
          max={1}
          step={0.1}
          value={value.style?.fillOpacity || 0.4}
          onChange={(v) => onChangeProperty('style.fillOpacity', v)}
        />
      </InlineField>
    </div>
  );
}

const getStyles = (theme: GrafanaTheme) => ({
  rule: css`
    margin-bottom: ${theme.spacing.sm};
  `,
  row: css`
    display: flex;
    margin-bottom: 4px;
  `,
  inline: css`
    margin-bottom: 0;
    margin-left: 4px;
  `,
  button: css`
    margin-left: 4px;
  `,
});
