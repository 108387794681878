import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const Wrangler: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 32 32"
      viewBox="0 0 32 32"
      width={size}
      height={size}
      {...rest}
    >
      <path d="M4.53,22.62a1.16,1.16,0,0,1-.89-.43,6.35,6.35,0,0,1,.66-8.63,1.18,1.18,0,0,0,.4-1,9.44,9.44,0,0,1,0-3.31A9.13,9.13,0,0,1,12,1.74a9.26,9.26,0,0,1,10,5.1,1.18,1.18,0,0,0,.85.65,8.67,8.67,0,0,1,5.59,13,1.15,1.15,0,0,1-1.95-1.22,6.35,6.35,0,0,0-2.07-8.75,6.44,6.44,0,0,0-2-.81,3.45,3.45,0,0,1-2.48-1.92,6.88,6.88,0,0,0-13,1.76,7.24,7.24,0,0,0,0,2.5,3.43,3.43,0,0,1-1.1,3.17,4,4,0,0,0-.43,5.5,1.14,1.14,0,0,1-.17,1.61A1.12,1.12,0,0,1,4.53,22.62Z" />
      <path d="M21.1,16.24H16.47a.71.71,0,0,1-.32-.07l-2.23-1.12a2.07,2.07,0,0,0-.95-.22H11.18a3.54,3.54,0,0,0-3.55,3.54v8.5a3.55,3.55,0,0,0,3.55,3.54H13.3a.71.71,0,1,0,0-1.41H11.18a2.13,2.13,0,0,1-2.13-2.13V20.49H23.22v6.38A2.13,2.13,0,0,1,21.1,29H19a.71.71,0,1,0,0,1.41H21.1a3.55,3.55,0,0,0,3.54-3.54V19.78A3.55,3.55,0,0,0,21.1,16.24Zm-12,2.13a2.13,2.13,0,0,1,2.13-2.13H13a.65.65,0,0,1,.31.08l2.24,1.11a2.08,2.08,0,0,0,.95.23H21.1a2.11,2.11,0,0,1,2,1.42h-14v-.71Zm8.71,7-.92-.92V29.7a.71.71,0,1,1-1.41,0V24.46l-.92.92a.71.71,0,0,1-1-1l1.14-1.15a2.16,2.16,0,0,1,3,0l1.14,1.15a.71.71,0,0,1-1,1Z" />
    </svg>
  );
};
