import React, { forwardRef, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import AxisTop from './AxisTop';

interface RacingAxisTopI {
  domainMax: number;
  xMax: number;
  textColor: string;
}

const AnimatedAxisTop = animated(AxisTop);

const RacingAxisTop = forwardRef(({ domainMax, xMax, textColor }: RacingAxisTopI, ref) => {
  const prevDomainMaxRef: any = useRef(domainMax);
  const prevDomainMax: any = prevDomainMaxRef.current;
  // @ts-ignore
  const springProps: any = useSpring({
    // @ts-ignore
    from: { domainMax: prevDomainMax },
    // @ts-ignore
    to: { domainMax },
    // @ts-ignore
    ref,
    // @ts-ignore
  });
  return <AnimatedAxisTop textColor={textColor} xMax={xMax} {...springProps} />;
});

export default RacingAxisTop;
