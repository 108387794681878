import { Portal, useTheme, VizTooltipContainer } from '@grafana/ui';
import { FeatureLike } from 'ol/Feature';
import React, { useState } from 'react';
import { TooltipTabs } from './TooltipTabs';
import { MarkerLinkI } from '../types/interface';
import { LinkDrawer } from 'app/features/linkDrawer/LinkDrawer';
const TOOLTIP_OFFSET = 10;

/**
 * Props for the TooltipOpenLayer component.
 *
 * @typedef {Object} TooltipOpenLayerProps
 * @property {number} pageX - The X-coordinate of the tooltip.
 * @property {number} pageY - The Y-coordinate of the tooltip.
 * @property {boolean} isOpen - A boolean indicating if the tooltip is open.
 * @property {FeatureLike[][]} tooltipData - An array of feature data for the tooltip.
 * @property {function} onClose - A function to close the tooltip.
 */
interface Props {
  pageX: number;
  pageY: number;
  isOpen: boolean;
  tooltipData: FeatureLike[][];
  onClose: () => void;
}

/**
 * TooltipOpenLayer is a functional component that displays a tooltip for OpenLayers.
 *
 * @param {TooltipOpenLayerProps} props - The component's props.
 * @returns {JSX.Element} The rendered tooltip component.
 */
export const TooltipOpenLayer = (props: Props) => {
  const { pageX, pageY, tooltipData, onClose, isOpen } = props;
  const [showDrawer, setShowDrawer] = useState(false);
  const [infoData, setinfoData] = useState<MarkerLinkI>({ location: '', url: '' });
  const theme = useTheme();

  /**
   * Handles visiting a dashboard link and opens the dashboard drawer.
   *
   * @param {string} text - The dashboard URL.
   * @param {string} custom - Custom dashboard name.
   */
  const handleDashboardVisit = (text: string, custom?: string) => {
    if (custom?.length !== 0 || text.length !== 0) {
      setinfoData({ location: custom ?? '', url: text });
      setShowDrawer(true);
    }
    onClose();
  };

  /**
   * Closes the dashboard drawer.
   */
  const onCloseDrawer = () => {
    setShowDrawer(false);
  };

  return (
    <>
      <Portal>
        {tooltipData && tooltipData.length > 0 && (
          <VizTooltipContainer
            position={{ x: pageX, y: pageY }}
            offset={{ x: TOOLTIP_OFFSET, y: TOOLTIP_OFFSET }}
            style={{
              position: 'fixed',
              left: 0,
              pointerEvents: 'auto',
              top: 0,
              transform: `translate(${pageX + TOOLTIP_OFFSET}px, ${pageY + TOOLTIP_OFFSET}px)`,
              transition: 'transform ease-out 0.1s',
              zIndex: 0,
              overflow: 'hidden',
              background: `${theme.colors.bg2}`,
              boxShadow: `${theme.colors.bodyBg}`,
              maxWidth: '800px',
              padding: '8px',
              borderRadius: `${2}px`,
              overflowY: 'auto',
            }}
          >
            <div style={{ zIndex: 0 }}>
              <TooltipTabs featureLayer={tooltipData} isOpen={isOpen} handleDashboardVisit={handleDashboardVisit} />
            </div>
          </VizTooltipContainer>
        )}
      </Portal>
      {showDrawer && <LinkDrawer infoData={infoData} onClose={onCloseDrawer} />}
    </>
  );
};
