import React, { FC } from 'react';
import { css, cx } from 'emotion';
import { useTheme } from '@grafana/ui';
import { config } from '@grafana/runtime';

export interface BrandComponentProps {
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

const LoginLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src="/api/image/carbon_icon.png" alt={`${config.appName}`} />;
};

const LoginBackground: FC<BrandComponentProps> = ({ className, children }) => {
  const background = css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(/api/image/carbon_background.svg);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 100vh;
  `;

  return <div className={cx(background, className)}>{children}</div>;
};

const MenuLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src="/api/image/carbon_icon_home.svg" alt={`${config.appName}`} />;
};

const LoginBoxBackground = () => {
  const theme = useTheme();
  return css`
    // background: ${theme.isLight ? 'rgba(6, 30, 200, 0.1 )' : 'rgba(18, 28, 41, 0.65)'};
    // background-size: cover;
  `;
};

//#43 class property changed for Carbon
export class Branding {
  static LoginLogo = LoginLogo;
  static LoginBackground = LoginBackground;
  static MenuLogo = MenuLogo;
  static LoginBoxBackground = LoginBoxBackground;
  static AppTitle = config.appName;
  static LoginTitle = `Welcome to ${config.appName}`;
  static GetLoginSubTitle = () => {
    const slogans = [
      "Don't get in the way of the data",
      'Your single pane of glass',
      'Built better together',
      'Democratising data',
    ];
    const count = slogans.length;
    return slogans[Math.floor(Math.random() * count)];
  };
}
