import React, { useCallback } from 'react';
import {
  UPlotConfigBuilder,
  VizLegendItem,
  VizLegendOptions,
  VizLayout,
  VizLayoutLegendProps,
  VizLegend,
  GraphNGLegendEvent,
} from '@grafana/ui';
import { mapMouseEventToMode } from '../utils';
import { BoxPlotData } from '../types';

interface PlotLegendProps extends VizLegendOptions, Omit<VizLayoutLegendProps, 'children'> {
  data: BoxPlotData;
  config: UPlotConfigBuilder;
  chartType: any;
  onSeriesColorChange?: (label: string, color: string) => void;
  onLegendClick?: (event: GraphNGLegendEvent) => void;
}

/**
 * @typedef {Object} PlotLegendCandleProps
 * @property {Array<DataFrame>} data
 * @property {UPlotConfigBuilder} config
 * @property {boolean} includeAllFields
 * @property {Fucntion} [onSeriesColorChange]
 * @property {Function} [onLabelClick]
 * @extends {VizLegendOptions}
 * @extends {Omit<VizLayoutLegendProps, 'children'>}
 */

export const PlotLegendWaterfall: React.FC<PlotLegendProps> = ({
  data,
  config,
  onSeriesColorChange,
  onLegendClick,
  placement,
  calcs,
  displayMode,
  chartType,
  ...vizLayoutLegendProps
}) => {
  const onLegendLabelClick = useCallback(
    (legend: VizLegendItem, event: React.MouseEvent) => {
      const { fieldIndex } = legend;

      if (!onLegendClick || !fieldIndex) {
        return;
      }

      onLegendClick({
        fieldIndex,
        mode: mapMouseEventToMode(event),
      });
    },
    [onLegendClick]
  );

  let timeArray: any = [];
  if (chartType === 'query') {
    for (let i = 0; i < data.frames![0].fields[0].values.length; i++) {
      timeArray.push({
        label: data.waterfall?.name![i],
        yAxis: 1,
        getItemKey: () => `${data.waterfall?.name![i]}-${i}-${0}`,
      });
    }
  } else {
    for (let i = 4; i < data.frames![0].fields.length; i++) {
      timeArray.push({
        label: data.waterfall?.name![i - 4],
        yAxis: 1,
      });
    }
  }

  const legendItems2 = timeArray;

  return (
    <VizLayout.Legend placement={placement} {...vizLayoutLegendProps}>
      <VizLegend
        onLabelClick={onLegendLabelClick}
        placement={placement}
        items={legendItems2}
        displayMode={displayMode}
        onSeriesColorChange={onSeriesColorChange}
      />
    </VizLayout.Legend>
  );
};

PlotLegendWaterfall.displayName = 'PlotLegendWaterfall';
