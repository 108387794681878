import { BusEventWithPayload } from '@grafana/data';
import { View } from 'ol';

/**
 * Payload for the ShareZoomEvent.
 *
 * @interface ShareZoomPayLoad
 * @property {View} sharedView - The shared OpenLayers View.
 */
export interface ShareZoomPayLoad {
  sharedView: View;
}

/**
 * Represents a Share Zoom Event.
 *
 * @class
 * @extends {BusEventWithPayload<ShareZoomPayLoad>}
 */
export class ShareZoomEvent extends BusEventWithPayload<ShareZoomPayLoad> {
  /**
   * The type of ShareZoomEvent.
   *
   * @static
   * @memberof ShareZoomEvent
   * @type {string}
   */
  static type = 'share-zoom';
}
