import React, { useCallback, useMemo, FC } from 'react';
import { DataFrame, PanelProps } from '@grafana/data';
import { UPlotTooltip, useTheme, UPlotZoom } from '@grafana/ui';
import { TimelineOptions, ChartTypeConfig } from './types';
import { TimelineChart } from './TimelineChart';
import { getTimelineData, getLegendItems } from './utils/utils';
import { TimelineTooltip } from './TimelineTooltip';

interface TimelineProps extends PanelProps<TimelineOptions> {}

export const TimelinePanel: FC<TimelineProps> = ({
  data,
  timeRange,
  timeZone,
  options,
  width,
  height,
  onChangeTimeRange,
}) => {
  const theme = useTheme();

  const { frames, err } = useMemo(() => getTimelineData(data?.series), [data]);

  const legendItems = useMemo(() => getLegendItems(frames, options.legend, theme), [frames, options.legend, theme]);

  const renderTooltip = useCallback(
    (timelineData: DataFrame, seriesIdx: number | null, dataIdx: number | null) => {
      const data = frames ?? [];
      if (seriesIdx === null || dataIdx === null) {
        return null;
      }
      if (
        (!timelineData.meta?.transformations?.length && timelineData.fields.length - 1 !== data.length) ||
        !timelineData.fields[seriesIdx]
      ) {
        return null;
      }

      return (
        <TimelineTooltip
          data={data}
          timelineData={timelineData}
          seriesIdx={seriesIdx}
          dataIdx={dataIdx}
          timeZone={timeZone}
        />
      );
    },
    [timeZone, frames]
  );

  if (!frames || err) {
    return (
      <div className="panel-empty">
        <p>{err ?? 'No data'}</p>
      </div>
    );
  }

  // Status history requires some space between values
  if (options.chartType === ChartTypeConfig.Status && frames[0].length > width / 2) {
    return (
      <div className="panel-empty">
        <p>
          Too many points to visualize properly. <br />
          Update the query to return fewer points. <br />({frames[0].length} points recieved)
        </p>
      </div>
    );
  }

  return (
    <TimelineChart
      theme={theme}
      frames={frames}
      timeRange={timeRange}
      timeZone={timeZone}
      width={width}
      height={height}
      legendItems={legendItems}
      {...options}
      mode={options.chartType}
    >
      {(config, timelineFrame) => {
        return (
          <>
            <UPlotZoom config={config} onZoom={onChangeTimeRange} />
            <UPlotTooltip data={timelineFrame} config={config} timeZone={timeZone} renderTooltip={renderTooltip} />
          </>
        );
      }}
    </TimelineChart>
  );
};
