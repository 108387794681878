import React from 'react';
import { AlignedData } from 'uplot';
import {
  compareArrayValues,
  compareDataFrameStructures,
  DataFrame,
  FieldConfigSource,
  TimeRange,
  TimeZone,
} from '@grafana/data';
import { BarChartOptions } from './types';
import { preparePlotConfigBuilder } from './utils/utils';
import { preparePlotDataForBar } from './utils/preparePlotData';
import {
  VizLayout,
  Themeable,
  UPlotChart,
  GraphNGLegendEvent,
  withTheme,
  LegendDisplayMode,
  VizLegendOptions,
  UPlotConfigBuilder,
} from '@grafana/ui';
import { PlotLegendBarChart } from './utils/PlotLegendBarchart';
import { TooltipPluginBarChart } from './utils/TooltipPluginBarChart';

/**
 * @typedef {Object} BarChartProps
 * @property {number} width
 * @property {number} height
 * @property {Array<DataFrame>} data
 * @property {TimeRange} timeRange
 * @property {VizLegendOptions} legend
 * @property {string} tooltipMode
 * @property {Function} [onLegendClick]
 * @property {Function} [onSeriesColorChange]
 * @property {FieldConfigSource} fieldConfig
 * @property {React.ReactNode} [children]
 * @extends {Themeable}
 * @extends {BarChartOptions}
 */

export interface BarChartProps extends Themeable, BarChartOptions {
  width: number;
  height: number;
  data: DataFrame[];
  timeRange: TimeRange;
  legend: VizLegendOptions;
  timeZone: TimeZone;
  tooltipMode: string;
  onLegendClick?: (event: GraphNGLegendEvent) => void;
  onSeriesColorChange?: (label: string, color: string) => void;
  fieldConfig: FieldConfigSource;
  children?: React.ReactNode;
}
/**
 * @typedef {Object} BarChartState
 * @property {AlignedData} data
 * @property {DataFrame} alignedDataFrame
 * @property {UPlotConfigBuilder} config
 */
interface BarChartState {
  data: AlignedData;
  alignedDataFrame: DataFrame;
  config: UPlotConfigBuilder;
}
/**
 * Bar chart component fo main work.
 * @class The BarChart
 * @extends {React.Component<BarChartProps, BarChartState>}
 */
class BarChart extends React.Component<BarChartProps, BarChartState> {
  /**
   * Create the BarChart.
   * @constructs BarChart
   */
  constructor(props: BarChartProps) {
    super(props);
    this.state = {} as BarChartState;
  }
  static getDerivedStateFromProps(props: BarChartProps, state: BarChartState) {
    const frame = props.data[0];
    if (!frame) {
      return { ...state };
    }

    return {
      ...state,
      data: preparePlotDataForBar(frame),
      alignedDataFrame: frame,
    };
  }
  /**
   * returns the value
   *
   * @param {number} seriesIdx index
   * @param {number} valueIdx index
   *
   * @method
   */
  rawValue = (seriesIdx: number, valueIdx: number) => {
    let field = this.props.data[0].fields.find((f) => f.type === 'number' && f.state?.seriesIndex === seriesIdx - 1);
    return field!.values.get(valueIdx);
  };
  /**
   * update config state when we received aligned data frame
   *
   * @method
   */
  componentDidMount() {
    const { theme } = this.props;
    // alignedDataFrame is already prepared by getDerivedStateFromProps method
    const { alignedDataFrame } = this.state;

    if (!alignedDataFrame) {
      return;
    }

    this.setState({
      config: preparePlotConfigBuilder(
        alignedDataFrame,
        theme,
        this.rawValue,
        this.props,
        this.getTimeRange,
        this.getTimeZone
      ),
    });
  }

  /**
   * Invoked immediately after updating occurred and also called for the initial
   * render.
   * @param {BarChartProps} prevProps The new props.
   * @method
   */

  componentDidUpdate(prevProps: BarChartProps) {
    const { data, theme, toOrientation, groupWidth, toBarWidth, showValue, rawValue } = this.props;
    const { alignedDataFrame } = this.state;
    let shouldConfigUpdate = false;
    let stateUpdate = {} as BarChartState;
    if (
      this.state.config === undefined ||
      this.props.timeZone !== prevProps.timeZone ||
      toOrientation !== prevProps.toOrientation ||
      groupWidth !== prevProps.groupWidth ||
      toBarWidth !== prevProps.toBarWidth ||
      showValue !== prevProps.showValue ||
      rawValue !== prevProps.rawValue
    ) {
      shouldConfigUpdate = true;
    }
    /**
     * check that previous data frame and present data frame is same or not
     */
    const hasStructureChanged = !compareArrayValues(data, prevProps.data, compareDataFrameStructures);

    /**
     * Update state whenever hasStructureChanged or shouldConfigUpdate is true
     */
    if (shouldConfigUpdate || hasStructureChanged) {
      const builder = preparePlotConfigBuilder(
        alignedDataFrame,
        theme,
        this.rawValue,
        this.props,
        this.getTimeRange,
        this.getTimeZone
      );
      stateUpdate = { ...stateUpdate, config: builder };
    }

    if (Object.keys(stateUpdate).length > 0) {
      this.setState(stateUpdate);
    }
  }
  /**
   * get time range
   * @method
   */
  getTimeRange = () => {
    return this.props.timeRange;
  };
  /**
   * get time zone
   * @method
   */
  getTimeZone = () => {
    return this.props.timeZone;
  };
  /**
   * render legend
   * @method
   * @returns {JSX.Element} PlotLegendBarChart
   */
  renderLegend() {
    const { legend, onSeriesColorChange, onLegendClick, data, graphMode, colorArray, stacking } = this.props;
    const { config } = this.state;
    if (!config || (legend && legend.displayMode === LegendDisplayMode.Hidden)) {
      return;
    }

    /**
     *  customized legend
     */
    return (
      <PlotLegendBarChart
        noTime={graphMode}
        data={data}
        config={config}
        onSeriesColorChange={onSeriesColorChange}
        onLegendClick={onLegendClick}
        maxHeight="35%"
        maxWidth="60%"
        {...legend}
        colorArray={colorArray}
        stackingMode={stacking}
      />
    );
  }
  /**
   * The render function.
   */
  render() {
    const { width, height, children, timeZone, timeRange, tooltipMode, ...plotProps } = this.props;
    const { config, data } = this.state;

    if (!this.state.data || !this.state.config) {
      return null;
    }
    return (
      <VizLayout width={width} height={height} legend={this.renderLegend()}>
        {(vizWidth: number, vizHeight: number) => (
          <UPlotChart
            {...plotProps}
            config={config!}
            data={data}
            width={vizWidth}
            height={vizHeight}
            timeRange={timeRange}
          >
            {children}
            {/* customized tooltip */}
            <TooltipPluginBarChart
              config={config}
              mode={tooltipMode}
              data={this.props.data[0]}
              timeZone={this.props.timeZone}
              colorArray={this.props.colorArray}
              noTime={this.props.graphMode}
              colorArrayStack={this.props.colorArrayStack}
              stacking={plotProps.stacking}
              fieldConfig={plotProps.fieldConfig}
            />
          </UPlotChart>
        )}
      </VizLayout>
    );
  }
}

export const GraphNG = withTheme(BarChart);
GraphNG.displayName = 'GraphNG';
