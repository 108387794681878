import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const ApiKeys: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 64 64"
      viewBox="0 0 64 64"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M21.4,60.2c-9.7,0-17.6-7.9-17.6-17.7c0-7.9,5.2-14.8,12.8-17c3-0.9,6.1-0.9,9.1-0.1L44.4,6.9
        c2-2,4.7-3.1,7.5-3.1l0,0c4.6,0,8.3,3.7,8.3,8.3c0,2.8-1.1,5.5-3.1,7.5l-1.6,1.6c-0.9,0.9-2.1,1.4-3.3,1.4h-3.7v2.4
        c0,2.6-2.1,4.7-4.7,4.7h-2.4v3.7c0,1.2-0.5,2.4-1.4,3.3l-1.5,1.5c0.8,3,0.7,6.2-0.1,9.1c-2,6.9-8,12-15.1,12.8
        C22.6,60.2,22,60.2,21.4,60.2z M21.4,29.6c-7.1,0-12.9,5.8-13,12.9s5.8,12.9,12.9,13c5.8,0,10.9-3.9,12.5-9.4
        c0.7-2.5,0.7-5.3-0.2-7.8c-0.3-0.9-0.1-1.8,0.6-2.4l2.5-2.5v-3.7c0-2.6,2.1-4.7,4.7-4.7h2.4v-2.4c0-2.6,2.1-4.7,4.7-4.7h3.7l1.6-1.6
        c1.1-1.1,1.7-2.6,1.7-4.2c0-2-1.6-3.6-3.6-3.6c-1.6,0-3.1,0.6-4.2,1.7L28.1,29.8c-0.6,0.6-1.6,0.9-2.4,0.6
        C24.3,29.9,22.8,29.6,21.4,29.6L21.4,29.6z M15.5,46.1c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4c0-1.3-1.1-2.4-2.4-2.4
        C16.6,43.8,15.5,44.8,15.5,46.1z"
      />
    </svg>
  );
};
