import React, { FC } from 'react';
import { cx, css, keyframes } from 'emotion';
import { useStyles } from '@grafana/ui';
import { Branding } from '../Branding/Branding';
import { GrafanaTheme } from '@grafana/data';
import { Footer } from '../Footer/Footer';

interface InnerBoxProps {
  enterAnimation?: boolean;
}
export const InnerBox: FC<InnerBoxProps> = ({ children, enterAnimation = true }) => {
  const loginStyles = useStyles(getLoginStyles);
  return <div className={cx(loginStyles.loginInnerBox, enterAnimation && loginStyles.enterAnimation)}>{children}</div>;
};

export const LoginLayout: FC = ({ children }) => {
  const loginStyles = useStyles(getLoginStyles);
  return (
    <Branding.LoginBackground className={loginStyles.container}>
      <div className={cx(loginStyles.loginContent, Branding.LoginBoxBackground())}>
        <div className={loginStyles.loginLogoWrapper}>
          <Branding.LoginLogo className={loginStyles.loginLogo} />
          <div className={loginStyles.titleWrapper}>
            {/* <h1 className={loginStyles.mainTitle}>{Branding.LoginTitle}</h1>
            <h3 className={loginStyles.subTitle}>{Branding.GetLoginSubTitle()}</h3> */}
          </div>
        </div>
        <div className={loginStyles.loginOuterBox}>{children}</div>
      </div>
      <Footer />
    </Branding.LoginBackground>
  );
};

const flyInAnimation = keyframes`
from{
  opacity: 0;
  transform: translate(-60px, 0px);
}

to{
  opacity: 1;
  transform: translate(0px, 0px);
}`;

export const getLoginStyles = (theme: GrafanaTheme) => {
  // const bgColor = theme.isDark ? theme.palette.black : theme.palette.white;
  return {
    container: css`
      min-height: 100vh;
      background-position: center;
      background-repeat: no-repeat;
      min-width: 100%;
      margin-left: 0;
      background-color: $black;
      display: flex;
      align-items: center;
      justify-content: center;
    `,
    submitButton: css`
      justify-content: center;
      width: 100%;
    `,
    loginLogo: css`
      // width: 100%;
      max-width: 270px;
      margin-top: 15px;
    `,
    loginLogoWrapper: css`
      display: flex;
      z-index: 2;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: ${theme.spacing.lg};
      padding-bottom: 0;
    `,
    titleWrapper: css`
      text-align: center;
      margin: 1rem;
    `,
    mainTitle: css`
      // font-size: '32px';
      margin-bottom: 8px;
      font-weight: 400;
      line-height: 1.1;
      color: #d8d9da;
    `,
    subTitle: css`
      font-size: ${theme.typography.size.md};
      color: ${theme.colors.textSemiWeak};
    `,
    loginContent: css`
      & {
        max-width: 700px;
        width: 100%;
        display: flex;
        align-items: stretch;
        flex-direction: column;
        position: relative;
        justify-content: center;
        z-index: 1;
        min-height: 320px;
        position: relative;
        max-width: 600px;
        padding: 5px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        overflow: hidden;
        color: #000;
      }
      // Added for transparency effect Melville
      &::before {
        content: '';
        position: absolute;
        top: -20px;
        left: -20px;
        right: -20px;
        bottom: -20px;
        background: url(/api/image/carbon_background.svg);
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        filter: blur(3px) !important;
      }
    `,
    loginOuterBox: css`
      & {
        display: flex;
        overflow-y: hidden;
        align-items: center;
        justify-content: center;
      }
      & form div div label div {
        color: ${theme.colors.text};
      }
      & a {
        color: ${theme.colors.text};
      }
    `,
    loginInnerBox: css`
      padding: ${theme.spacing.xl};
      @media (max-width: 320px) {
        padding: ${theme.spacing.lg};
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      max-width: 415px;
      width: 100%;
      transform: translate(0px, 0px);
      transition: 0.25s ease;
    `,
    enterAnimation: css`
      animation: ${flyInAnimation} ease-out 0.2s;
    `,
  };
};
