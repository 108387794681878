import React, { useState } from 'react';
import { Collapse } from '@grafana/ui';
import { DashLink } from './DashLink';

export interface PropsExpand {
  index: number;
  title?: string;
  onUrlChangeCustomInput: (event: any, idx: number, name: boolean) => void;
  onDashboardChange: (target: any, idx?: any) => void;
  option: any;
  handleRemoveLink?: (idx: number) => void;
}

export function DashExpand(props: PropsExpand) {
  const { index, onUrlChangeCustomInput, onDashboardChange, option, handleRemoveLink, title } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Collapse
      label={title ? title : 'Dashboard Link'}
      isOpen={isOpen}
      onToggle={() => setIsOpen(!isOpen)}
      collapsible={true}
      overflowOffMap={true}
    >
      <DashLink
        option={option}
        index={index}
        onUrlChangeCustomInput={onUrlChangeCustomInput}
        onDashboardChange={onDashboardChange}
        handleRemoveLink={handleRemoveLink}
      />
    </Collapse>
  );
}
