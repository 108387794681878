import { FieldDisplay, StandardEditorContext, getFieldDisplayValues } from '@grafana/data';
import { Button, Field, InlineFormLabel, Input, MultiSelect, useTheme } from '@grafana/ui';
import { cloneDeep } from 'lodash';
import React from 'react';
import Group from '../types';

/**
 * props type for group editor
 * @typedef {Object} Props
 * @property {object<Array<Group>>} groupData group formation data used in no time series bar graph
 * @property {any} data
 * @property {Function} onChange
 */
interface CustomEditorProps {
  groupData: {
    groups: Group[];
  };
  bigData: StandardEditorContext<any>;
  /**
   * @param {object<Array<Group>>}
   * @method
   */
  onChange: (groups: { groups: Group[] }) => void;
}
/**
 * component is used to create groups in no timeseries bar graph and stackingmode is on
 * @function
 * @param {CustomEditorProps} props
 * @returns {JSX.Element}
 */
export const EditorGroupList: React.FC<CustomEditorProps> = (props: CustomEditorProps) => {
  const [queries, setQueries]: any = React.useState([]);

  const theme = useTheme();
  const { options, replaceVariables, data } = props.bigData;
  const { fieldConfig } = options;
  const setoptions = cloneDeep(options.reduceOptions);
  delete setoptions.fields;
  delete setoptions.limit;
  delete setoptions.values;
  let newData: FieldDisplay[];

  const dataLoad = () => {
    if (data!.length > 0 && replaceVariables) {
      const getValues3 = (): FieldDisplay[] => {
        return getFieldDisplayValues({
          fieldConfig,
          reduceOptions: setoptions,
          replaceVariables,
          theme: theme,
          data: data,
          sparkline: false,
          timeZone: 'browser',
        });
      };
      newData = getValues3();
    }
    const localQueries: any = [];
    newData?.map((ele: FieldDisplay, index: number) => {
      if (ele?.display?.title && ele.view?.dataFrame.fields[1].type === 'number') {
        localQueries.push({
          label: ele?.display?.title,
          value: index + 1,
        });
      }
    });
    setQueries(localQueries);
  };

  let groups = props.groupData.groups;
  function addNewGroup() {
    groups.push({
      name: '',
      query: [],
    });
    props.onChange({
      ...props.groupData,
      groups: groups,
    });
  }
  function updateGroupName(value: string, index: number) {
    groups[index].name = value;
    props.onChange({
      ...props.groupData,
      groups: groups,
    });
  }
  function updateQueryList(value: any, index: number) {
    groups[index].query = [];
    value.map((ele: any) => {
      groups[index].query.push(ele);
    });
    props.onChange({
      ...props.groupData,
      groups: groups,
    });
  }
  function removeGroup(idx: number) {
    const newGroups = props.groupData.groups.filter((item, index) => {
      return index !== idx;
    });
    props.onChange({
      ...props.groupData,
      groups: newGroups,
    });
  }

  return (
    <>
      {groups &&
        groups.map((group: Group, index: number) => {
          return (
            <Field key={index}>
              <div>
                <div className="gf-form">
                  <label className="gf-form-label">Name</label>
                  <Input value={group.name} onChange={(e) => updateGroupName(e.currentTarget.value, index)} />
                </div>
                <div className="gf-form gf-form--grow">
                  <InlineFormLabel className="gf-form-label">Select Query</InlineFormLabel>
                  <MultiSelect
                    noOptionsMessage="Reload Queries"
                    options={queries}
                    onChange={(target: any) => {
                      updateQueryList(target, index);
                    }}
                    value={queries.filter((item: any) => {
                      const newA = group.query.find((ele: any) => {
                        return item.label === ele.label;
                      });
                      return newA;
                    })}
                  />
                </div>
                <div className="gf-form">
                  <InlineFormLabel className="gf-form-label">Remove Group</InlineFormLabel>
                  <Button onClick={() => removeGroup(index)} variant="destructive" size="md">
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </Button>
                </div>
              </div>
            </Field>
          );
        })}
      <div className="gf-form">
        <InlineFormLabel>Add New Group</InlineFormLabel>
        <Button onClick={addNewGroup} variant="secondary" size="md">
          <i className="fa fa-plus" aria-hidden="true"></i>
        </Button>
      </div>
      <div className="gf-form">
        <InlineFormLabel>Reload Queries</InlineFormLabel>
        <Button onClick={dataLoad} variant="secondary" size="md">
          <i className="fa fa-refresh" aria-hidden="true"></i>
        </Button>
      </div>
    </>
  );
};
