import { variable } from './variable';

/**
 * Helper for Date
 */
const date = require('helper-date');

/**
 * To Fixed
 */
const toFixed = (num: unknown, digits: unknown) => {
  if (typeof num !== 'number' || typeof digits !== 'number') {
    return 0;
  }
  return num.toFixed(digits);
};

const join = (arr: string[], sep: string): string => {
  return arr.join(sep);
};

const eq = (left: string, right: string): boolean => {
  return left === right;
};

const unlessEq = (left: string, right: string): boolean => {
  return left !== right;
};

const gt = (left: number, right: number): boolean => {
  return left > right;
};

const lt = (left: number, right: number): boolean => {
  return left < right;
};

const gte = (left: number, right: number): boolean => {
  return left >= right;
};

const lte = (left: number, right: number): boolean => {
  return left <= right;
};

const and = (left: boolean, right: boolean): boolean => {
  return left && right;
};

const or = (left: boolean, right: boolean): boolean => {
  return left || right;
};

const not = (left: boolean): boolean => {
  return !left;
};

const add = (left: number, right: number): number => {
  return Number(left) + Number(right);
};

const sub = (left: number, right: number): number => {
  return Number(left) - Number(right);
};

const mul = (left: number, right: number): number => {
  return Number(left) * Number(right);
};

const div = (left: number, right: number): number => {
  return Number(left) / Number(right);
};

const ceil = (num: number): number => {
  return Math.ceil(num);
};

const floor = (num: number): number => {
  return Math.floor(num);
};

const round = (num: number, digits: number): number => {
  if (typeof digits === 'number') {
    return Math.round((num + Number.EPSILON) * Math.pow(10, digits)) / Math.pow(10, digits);
  }
  return Math.round(num);
};

const random = (): number => {
  return Math.random();
};

const addm = (date: any, num: number): Date | Number => {
  const d = new Date(date);
  d.setMinutes(d.getMinutes() + num);
  if (Object.prototype.toString.call(date) === '[object Date]') {
    return d;
  } else {
    return d.getTime();
  }
};

const addh = (date: any, num: number): Date | Number => {
  const d = new Date(date);
  d.setHours(d.getHours() + num);
  if (Object.prototype.toString.call(date) === '[object Date]') {
    return d;
  } else {
    return d.getTime();
  }
};

const addD = (date: any, num: number): Date | Number => {
  const d = new Date(date);
  d.setDate(d.getDate() + num);
  if (Object.prototype.toString.call(date) === '[object Date]') {
    return d;
  } else {
    return d.getTime();
  }
};

const addM = (date: any, num: number): Date | Number => {
  const d = new Date(date);
  d.setMonth(d.getMonth() + num);
  if (Object.prototype.toString.call(date) === '[object Date]') {
    return d;
  } else {
    return d.getTime();
  }
};

const addY = (date: any, num: number): Date | Number => {
  const d = new Date(date);
  d.setFullYear(d.getFullYear() + num);
  if (Object.prototype.toString.call(date) === '[object Date]') {
    return d;
  } else {
    return d.getTime();
  }
};

/**
 * Register Helpers
 */
export const registerHelpers = (handlebars: any) => {
  handlebars.registerHelper('date', date);
  handlebars.registerHelper('toFixed', toFixed);
  handlebars.registerHelper('variable', variable);
  handlebars.registerHelper('join', join);
  handlebars.registerHelper('eq', eq);
  handlebars.registerHelper('unlessEq', unlessEq);
  handlebars.registerHelper('gt', gt);
  handlebars.registerHelper('lt', lt);
  handlebars.registerHelper('gte', gte);
  handlebars.registerHelper('lte', lte);
  handlebars.registerHelper('and', and);
  handlebars.registerHelper('or', or);
  handlebars.registerHelper('not', not);
  handlebars.registerHelper('add', add);
  handlebars.registerHelper('sub', sub);
  handlebars.registerHelper('mul', mul);
  handlebars.registerHelper('div', div);
  handlebars.registerHelper('ceil', ceil);
  handlebars.registerHelper('floor', floor);
  handlebars.registerHelper('round', round);
  handlebars.registerHelper('random', random);
  handlebars.registerHelper('addm', addm);
  handlebars.registerHelper('addh', addh);
  handlebars.registerHelper('addD', addD);
  handlebars.registerHelper('addM', addM);
  handlebars.registerHelper('addY', addY);
  handlebars.registerHelper('contains', (arr: string[], value: string): boolean => arr.indexOf(value) !== -1);
  handlebars.registerHelper('json', (context: any) => JSON.stringify(context, null, 2));
  handlebars.registerHelper('split', (str: string, sep: string): string[] => str.split(sep));
};
