import React, { useState, useEffect } from 'react';
import { Select, Field } from '@grafana/ui';
import { loadS3Images } from 'app/features/s3-upload/api';
import { SelectableValue } from '@grafana/data';

interface Props {
  toShowImg: string;
  onChange: (toShowImg: string) => void;
}

export function ImageViewer(props: Props) {
  const [availableImg, setAvailableImg] = useState<SelectableValue[]>([]);

  useEffect(() => {
    (async () => {
      const { files, error } = await loadS3Images();
      if (error === null) {
        setAvailableImg(files);
      } else {
        console.log(error);
      }
    })();
  }, []);

  const onImageChange = ({ value }: any) => {
    props.onChange(value);
  };
  return (
    <Field title="Available Images">
      <Select
        options={availableImg}
        onChange={onImageChange}
        value={availableImg.find((item: any) => item.value === props.toShowImg)}
      />
    </Field>
  );
}
