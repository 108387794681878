import React, { useEffect, useState } from 'react';
import { Field, InlineFormLabel, MultiSelect, Switch, Button, Select, Input } from '@grafana/ui';
import { cloneDeep } from 'lodash';
import { onchangeLayerOption, setProperty } from '../../GeojsonStyle';
import { CustomMarkerI, MapLayerType } from '../../../types/interface';
import { getBackendSrv } from '@grafana/runtime';
import { toLonLat } from 'ol/proj';
import { MapBrowserEvent } from 'ol';
import { PropsMarkerExpand } from './MarkerExpand';

/**
 * Functional component for displaying and configuring a marker.
 *
 * @param {PropsMarkerExpand} props - The props for the Marker component.
 * @returns {React.ReactNode} The marker configuration component.
 */
export function Marker(props: PropsMarkerExpand) {
  const { marker, Idx, layer, map, queries } = props;
  const { lat, lng, query, url, name } = marker;
  let newMarker: CustomMarkerI = cloneDeep(layer.options.config.customMarkers);
  const [latLng, setlatLng] = useState({ lat: newMarker.markers[Idx].lat, lng: newMarker.markers[Idx].lng });
  const [dashboards, setDashboards]: any = useState([]);

  useEffect(() => {
    getBackendSrv()
      .get('api/search?type=dash-db')
      .then((res: any) => {
        const dashboardsLocal = [];
        for (let i = 0; i < res.length; i++) {
          dashboardsLocal.push({ label: res[i].title, value: res[i].url });
        }
        setDashboards(dashboardsLocal);
      });

    if (newMarker.enabled && newMarker.currIdx !== -1) {
      newMarker = { ...newMarker, currIdx: -1, enabled: false };
      onchangeLayerOption(newMarker, 'config.customMarkers', layer);
    }
  }, []);

  useEffect(() => {
    if (newMarker.enabled && Idx === newMarker.currIdx) {
      newMarker.markers[Idx] = { ...newMarker.markers[Idx], lng: latLng.lng, lat: latLng.lat };
      onchangeLayerOption(newMarker, 'config.customMarkers', layer);
    }
  }, [newMarker.enabled && latLng]);

  const pickLatLng = (value: boolean) => {
    if (value) {
      newMarker = { ...newMarker, currIdx: Idx, enabled: value };
      onchangeLayerOption(newMarker, 'config.customMarkers', layer);
      map?.on('singleclick', handleClick);
    } else {
      newMarker = { ...newMarker, currIdx: -1, enabled: false };
      onchangeLayerOption(newMarker, 'config.customMarkers', layer);
    }
  };

  function handleClick(evt: MapBrowserEvent<UIEvent>) {
    if (!map) {
      return false;
    }
    if (newMarker.enabled) {
      const mouse = evt.originalEvent as any;
      const pixel = map?.getEventPixel(mouse);
      const hover = toLonLat(map?.getCoordinateFromPixel(pixel!)!);
      // map?.getView().setCenter(fromLonLat([hover[0], hover[1]]));

      if (hover.length !== 0) {
        setlatLng({ lng: hover[0], lat: hover[1] });
      }
      return true;
    }
    return false;
  }

  const onChangeProperty = (path: string, val: any, target?: any) => {
    if (target && path.length === 0) {
      newMarker.markers[Idx] = { ...newMarker.markers[Idx], url: target.value, name: target.label };
      const newOpt = setProperty(cloneDeep(layer.options), 'config.customMarkers', newMarker);
      layer.onChange(newOpt);
    } else {
      const newValueObj = setProperty(Object.assign({}, marker), path, val);
      newMarker.markers[Idx] = newValueObj;
      const newOpt = setProperty(cloneDeep(layer.options), 'config.customMarkers', newMarker);
      layer.onChange(newOpt);
    }
  };

  return (
    <>
      <div className="gf-form" style={{ gap: '4px' }}>
        <InlineFormLabel width={7}>Lat-Lng</InlineFormLabel>
        <Input
          type="number"
          value={lat}
          onChange={(event: any) => onChangeProperty('lat', Number(event.currentTarget.value))}
        />
        <Input
          type="number"
          value={lng}
          onChange={(event: any) => onChangeProperty('lng', Number(event.currentTarget.value))}
        />
      </div>
      <div className="gf-form gf-form--grow">
        <InlineFormLabel width={7} className="gf-form-label">
          Select Query
        </InlineFormLabel>
        <MultiSelect
          options={queries}
          onChange={(target: any) => {
            const query: any[] = [];
            target.map((ele: any) => {
              query.push(ele);
            });
            newMarker.markers[Idx] = { ...newMarker.markers[Idx], query: query };
            onchangeLayerOption(newMarker, 'config.customMarkers', layer);
          }}
          value={query}
        />
      </div>
      <div className="gf-form" style={{ alignItems: 'center' }}>
        <InlineFormLabel width={7} tooltip="Pick Latitude and Longitude from the Map with a click.">
          Pick Lat-Lng
        </InlineFormLabel>
        <Switch
          value={Idx === newMarker.currIdx ? true : false}
          onChange={(event) => pickLatLng(event.currentTarget.checked)}
        />
      </div>
      {layer.options.type === MapLayerType.CUSTOMMARKER && (
        <Field label={'Dashboard link'} style={{ marginBottom: '8px', marginTop: '14px' }}>
          <>
            <div className="gf-form gf-form--grow">
              <InlineFormLabel width={10} tooltip={'Select dashboard from the given list'}>
                Select Dashboard
              </InlineFormLabel>
              <Select
                options={dashboards}
                value={dashboards.find((item: any) => item.value === marker.url)}
                onChange={(target: any) => onChangeProperty('', '', target)}
              />
            </div>
            <div className="gf-form">
              <InlineFormLabel width={10} tooltip={'Provide a custom Name'}>
                Custom Name
              </InlineFormLabel>
              <Input value={name} onChange={(event: any) => onChangeProperty('name', event.currentTarget.value)} />
            </div>
            <div className="gf-form">
              <InlineFormLabel width={10} tooltip={'Provide a custom URL'}>
                Custom URL
              </InlineFormLabel>
              <Input value={url} onChange={(event: any) => onChangeProperty('url', event.currentTarget.value)} />
            </div>
          </>
        </Field>
      )}
      <div className="gf-form" style={{ justifyContent: 'end' }}>
        <Button
          onClick={() => {
            newMarker.markers.splice(Idx, 1);
            const newOpt = setProperty(cloneDeep(layer.options), 'config.customMarkers', newMarker);
            layer.onChange(newOpt);
          }}
          variant="destructive"
          size="md"
        >
          <i className="fa fa-trash" aria-hidden="true"></i>
        </Button>
      </div>
    </>
  );
}
