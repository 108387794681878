import { sharedSingleStatPanelChangedHandler } from '@grafana/ui';
import { PanelModel } from '@grafana/data';
import { StackedBarOptions } from './types';

// This is called when the panel changes from another panel
export const statPanelChangedHandler = (
  panel: PanelModel<Partial<StackedBarOptions>> | any,
  prevPluginId: string,
  prevOptions: any
) => {
  // This handles most config changes
  const options = sharedSingleStatPanelChangedHandler(panel, prevPluginId, prevOptions) as StackedBarOptions;
  return options;
};
