import React, { FC } from 'react';
import { useTheme, NoData } from '@grafana/ui';
import { StackedBarOptions } from './types';
import { PanelProps, getFieldDisplayValues } from '@grafana/data';
import { Stack } from './components/Stack';

interface Props extends PanelProps<StackedBarOptions> {}

export const StackedBar: FC<Props> = ({
  height,
  options,
  width,
  data,
  fieldConfig,
  replaceVariables,
  timeZone,
  renderCounter,
}) => {
  const theme = useTheme();

  const bigData = getFieldDisplayValues({
    fieldConfig,
    reduceOptions: options.reduceOptions,
    replaceVariables,
    theme: theme,
    data: data.series,
    timeZone,
  });

  if (data.series.length <= 0) {
    return <NoData width={width} height={height} />;
  }

  return (
    <Stack
      bigData={bigData}
      width={width}
      height={height}
      options={options}
      units={Number(fieldConfig.defaults?.unit) ?? 0}
      theme={theme}
      renderCounter={renderCounter}
    />
  );
};
