import { SelectableValue } from '@grafana/data';

export interface SelectionInfo<T = any> {
  options: Array<SelectableValue<T>>;
  current?: SelectableValue<T>;
}

/**
 * Finds options according to the current value within a list of selectable values.
 *
 * @template T
 * @param {T} [v] - The current value.
 * @param {Array<SelectableValue<T>>} [options] - An array of selectable values.
 * @returns {SelectionInfo<T>} An object containing the options and the current value.
 */
export function getSelectionInfo<T>(v?: T, options?: Array<SelectableValue<T>>): SelectionInfo<T> {
  if (v && !options) {
    const current = { label: `${v}`, value: v };
    return { options: [current], current };
  }
  if (!options) {
    options = [];
  }
  let current = options.find((item) => item.value === v);

  if (v && !current) {
    current = {
      label: `${v} (not found)`,
      value: v,
    };
    options.push(current);
  }
  return {
    options,
    current,
  };
}
