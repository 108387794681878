import React, { useContext } from 'react';
import { useTheme } from '@grafana/ui';
import { InfoBox } from '@react-google-maps/api';
import { TooltipTabs } from './TooltipTabs';
import { TooltipContext, TooltipDataContext } from '../../context';
import { MapPanelOptions } from '../../types/types';
/**
 * @typedef {Object} MapToolTipProps
 * @property {MapPanelOptions} options
 * @property {Function} [handleDashboardVisit]
 */
interface MapToolTipProps {
  options: MapPanelOptions;
  handleDashboardVisit?: (text: string, custom?: string) => void;
}
/**
 * @function
 * @param {MapToolTipProps} props
 * @returns {JSX.Element | null}
 */
export const Tooltip: React.FC<MapToolTipProps> = (props: MapToolTipProps): JSX.Element | null => {
  const { showInfo } = useContext(TooltipContext);
  const { tooltipData } = useContext(TooltipDataContext);

  const theme = useTheme();

  // const onCloseClick = () => {
  //   setShowInfo({ ...showInfo, onHover: false });
  // };

  return showInfo.onHover ? (
    <InfoBox
      options={{
        alignBottom: true,
        pane: 'floatPane',
        boxStyle: {
          background: theme.colors.bg2,
          boxShadow: theme.colors.bodyBg,
          borderRadius: '5px',
          width: 'auto',
          overflow: 'auto',
        },
        closeBoxURL: '',
        enableEventPropagation: true,
        // * this pixel offset removes flickering problem of tooltip on hover
        pixelOffset: new google.maps.Size(15, -15),
        disableAutoPan: false,
      }}
      position={new google.maps.LatLng({ lat: tooltipData.lat, lng: tooltipData.lng })}
    >
      <div style={{ display: 'flex', flexDirection: 'column', margin: '8px' }}>
        <TooltipTabs
          markers={tooltipData.tooltipType === 'custom' ? tooltipData.data : undefined}
          jsonDataObj={tooltipData.tooltipType === 'json' ? tooltipData.data : undefined}
          tabLayerName={tooltipData.layer}
          valueField={
            tooltipData.tooltipType === 'auto'
              ? tooltipData.data
              : tooltipData.tooltipType === 'geohash'
              ? tooltipData.data
              : undefined
          }
          locField={tooltipData.tooltipType === 'geohash' ? tooltipData.locField : undefined}
          handleDashboardVisit={props.handleDashboardVisit}
        />
      </div>
    </InfoBox>
  ) : null;
};
