import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const Addfolder: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M18.4,3.8h-5.9c-0.1,0-0.3,0-0.4-0.1L9.2,2.3C8.8,2.1,8.4,2,8,2H5.7C3.1,2,1.1,4,1.1,6.5v10.9
        c0,2.5,2,4.5,4.5,4.5h12.7c2.5,0,4.5-2,4.5-4.5V8.4C22.9,5.9,20.9,3.8,18.4,3.8z M5.7,3.8H8c0.1,0,0.3,0,0.4,0.1l2.9,1.4
        c0.4,0.2,0.8,0.3,1.2,0.3h5.9c1.1,0,2.1,0.7,2.5,1.7l-18,0.1V6.5C2.9,5,4.2,3.8,5.7,3.8z M18.4,20.2H5.7c-1.5,0-2.7-1.2-2.7-2.7V9.3
        l18.2-0.1v8.3C21.1,19,19.9,20.2,18.4,20.2z"
      />
    </svg>
  );
};
