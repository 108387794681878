import { PanelOptionsEditorBuilder, PanelPlugin, ReducerID, standardEditorsRegistry } from '@grafana/data';
import { BaseLayerEditor } from './LayerEditor/BaseLayerEditor';
import { LayersEditor } from './LayerEditor/LayersEditor';
import { OSMapPanel } from './OSMapPanel';
import { ControlEditor } from './custom/ControlEditor';
import { OSMapInstanceState, OSMapPanelOptions } from './types/types';
import { SingleStatBaseOptions } from '@grafana/ui';
import { LegendMode } from './types/interface';
import { TimelapseEditor } from './custom/TimelapseEditor';

export const plugin = new PanelPlugin<OSMapPanelOptions>(OSMapPanel)
  .useFieldConfig()
  .setPanelOptions((builder, context) => {
    addStandardDataReduceOptions(builder);
    builder
      .addCustomEditor({
        id: 'mapView',
        path: 'mapView',
        name: 'Map Controls',
        description: 'select zoom level, latitude and longitude.',
        defaultValue: { zoom: 4, center: { lat: -24.777970412891463, lng: 134.45082499999998 } },
        editor: ControlEditor,
      })
      .addBooleanSwitch({
        path: 'shared',
        description: 'Use the same view across multiple panels. Note: this may require a dashboard reload.',
        name: 'Share view',
        defaultValue: false,
      })
      .addBooleanSwitch({
        path: 'showTools',
        name: 'Show Tools',
        description: 'Enables tools over the map like zoom, full-screen, view-type etc.',
        defaultValue: true,
      })
      .addBooleanSwitch({
        path: 'showController',
        name: 'Show Controller',
        description: 'Enables mouse controls over the map.',
        defaultValue: true,
      })
      .addBooleanSwitch({
        path: 'showInfo',
        name: 'Show Map Info',
        description: 'Show Map Info like current zoom, center, scaleline etc.',
        defaultValue: false,
      })
      .addRadio({
        path: 'legendMode',
        name: 'Legend',
        description: 'Enables legend over the map',
        defaultValue: LegendMode.Threshold,
        settings: {
          options: [
            { value: LegendMode.Both, label: 'Both' },
            { value: LegendMode.Heatmap, label: 'Heatmap' },
            { value: LegendMode.Threshold, label: 'Threshold' },
            { value: LegendMode.None, label: 'None' },
          ],
        },
      })
      .addNumberInput({
        path: 'heatmapSteps',
        name: 'Heatmap legend steps',
        defaultValue: 10,
        settings: {
          min: 2,
          step: 1,
        },
        showIf: (options: OSMapPanelOptions) => {
          if (options.legendMode === LegendMode.Both || options.legendMode === LegendMode.Heatmap) {
            return true;
          }
          return false;
        },
      });

    const state = context.instanceState as OSMapInstanceState;
    if (state?.layers) {
      builder.addCustomEditor({
        id: 'baseLayer',
        path: 'baseLayer',
        name: 'Change Base Layer',
        description: '',
        defaultValue: [],
        category: ['Base Tile Layer'],
        editor: BaseLayerEditor,
      });

      builder.addCustomEditor({
        id: 'layers',
        path: 'layers',
        name: 'Layers Editor',
        description: '',
        defaultValue: [],
        category: ['Data Layers'],
        editor: LayersEditor,
      });
    }
    builder.addCustomEditor({
      id: 'timelapse',
      path: 'timelapse',
      name: 'TimeLapse Editor',
      description: 'Select heatmap layer for timelapse options',
      defaultValue: { isTimeLapse: false, timeToFinish: 15, speed: 0, layerName: '' },
      category: ['Timelapse Control'],
      editor: TimelapseEditor,
    });
  })
  .setNoPadding();

export function addStandardDataReduceOptions(builder: PanelOptionsEditorBuilder<SingleStatBaseOptions>) {
  builder.addCustomEditor({
    id: 'reduceOptions.calcs',
    path: 'reduceOptions.calcs',
    name: 'Value',
    description: 'Choose a reducer function / calculation',
    editor: standardEditorsRegistry.get('stats-picker').editor as any,
    defaultValue: [ReducerID.mean],
  });
}
