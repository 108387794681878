import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const Manage: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 16 16"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M13.3,10.4l0-2.3c0-0.4-0.3-0.7-0.7-0.7H8.6l0-1.8c1,0,1.8-0.8,1.8-1.8V2.7c0-1-0.8-1.8-1.8-1.8H7.4
        c-1,0-1.8,0.8-1.8,1.8v1.2v0c0,1,0.8,1.8,1.8,1.8h0l0,1.8H3.4C3,7.4,2.7,7.7,2.7,8.1l0,2.3h0c-1,0-1.8,0.8-1.8,1.8v0v1.2
        c0,1,0.8,1.8,1.8,1.8h1.2c1,0,1.8-0.8,1.8-1.8v-1.2c0-1-0.8-1.8-1.8-1.8l0-1.8h8.3l0,1.8h0c-1,0-1.8,0.8-1.8,1.8v0v1.2
        c0,1,0.8,1.8,1.8,1.8h1.2c1,0,1.8-0.8,1.8-1.8v-1.2C15.1,11.2,14.3,10.4,13.3,10.4z M7.4,4.4c-0.3,0-0.6-0.3-0.6-0.6V2.7
        c0-0.3,0.3-0.6,0.6-0.6h1.2c0.3,0,0.6,0.3,0.6,0.6v1.2c0,0.3-0.3,0.6-0.6,0.6H7.4z M4.4,12.2v1.2c0,0.3-0.3,0.6-0.6,0.6H2.7
        c-0.3,0-0.6-0.3-0.6-0.6v-1.2c0-0.3,0.3-0.6,0.6-0.6h1.2C4.2,11.6,4.4,11.8,4.4,12.2z M13.9,13.3c0,0.3-0.3,0.6-0.6,0.6h-1.2
        c-0.3,0-0.6-0.3-0.6-0.6v-1.2c0-0.3,0.3-0.6,0.6-0.6h1.2c0.3,0,0.6,0.3,0.6,0.6V13.3z"
      />
    </svg>
  );
};
