import React, { useCallback, useRef, useState } from 'react';
import { AnnotationEvent, GrafanaTheme } from '@grafana/data';
import { HorizontalGroup, IconButton, Portal, Tag, TooltipContainer, useStyles } from '@grafana/ui';
import { css } from 'emotion';
import { AnnotationEditorForm } from './annotations/AnnotationEditorForm';
import { usePopper } from 'react-popper';
import { deleteAnnotations } from './annotations/api';

interface AnnotationMarkerProps {
  data: AnnotationEvent;
  time: string;
  panelID: number;
  timeFormatter: (value: number) => string;
}

const POPPER_CONFIG = {
  modifiers: [
    { name: 'arrow', enabled: false },
    {
      name: 'preventOverflow',
      enabled: true,
      options: {
        rootBoundary: 'viewport',
      },
    },
  ],
};

export const AnnotationMarker: React.FC<AnnotationMarkerProps> = ({ data, time, panelID, timeFormatter }) => {
  const { tags, text, id, timeEnd, color, isRegion } = data;
  const styles = useStyles(getAnnotationMarkerStyles);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const markerRef = useRef<HTMLDivElement>(null);
  const annotationPopoverRef = useRef<HTMLDivElement>(null);
  const popoverRenderTimeout = useRef<NodeJS.Timer>();
  const [editorRef, setEditorRef] = useState<HTMLDivElement | null>(null);
  const editorPopper = usePopper(markerRef.current, editorRef, POPPER_CONFIG);

  const onMouseEnter = useCallback(() => {
    if (popoverRenderTimeout.current) {
      clearTimeout(popoverRenderTimeout.current);
    }
    setIsOpen(true);
  }, [setIsOpen]);

  const onMouseLeave = useCallback(() => {
    popoverRenderTimeout.current = setTimeout(() => {
      setIsOpen(false);
    }, 100);
  }, [setIsOpen]);

  const renderMarker = useCallback(() => {
    if (!markerRef?.current) {
      return null;
    }

    const el = markerRef.current;
    const elBBox = el.getBoundingClientRect();

    return (
      <TooltipContainer
        position={{ x: elBBox.left, y: elBBox.top + elBBox.height }}
        offset={{ x: 0, y: 0 }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={styles.tooltip}
      >
        <div ref={annotationPopoverRef} className={styles.wrapper}>
          <div className={styles.header}>
            <HorizontalGroup justify={'space-between'} align={'center'}>
              <div className={styles.title}>
                <img className={styles.avatar} src={data.avatarUrl}></img>
              </div>
              <div className={styles.ts}>
                <span className={styles.time}>
                  {Boolean(isRegion) ? `${time} - ${timeFormatter(timeEnd ?? 0)}` : time}
                </span>
              </div>
              <IconButton
                name="edit"
                size={'sm'}
                iconType={'mono'}
                onClick={() => {
                  setIsOpen(false);
                  setIsEditing(true);
                }}
              />
              <IconButton
                name="trash"
                size={'sm'}
                onClick={async () => {
                  if (id) {
                    await deleteAnnotations(id, panelID);
                    setIsOpen(false);
                  }
                }}
              />
            </HorizontalGroup>
          </div>
          <div className={styles.body}>
            <div style={{ marginBottom: '8px' }}>{text && <div dangerouslySetInnerHTML={{ __html: text }} />}</div>
            <HorizontalGroup spacing="xs" wrap>
              {tags?.map((t, i) => (
                <Tag name={t} key={`${t}-${i}`} />
              ))}
            </HorizontalGroup>
          </div>
        </div>
      </TooltipContainer>
    );
  }, [time, tags, text]);

  let anno;
  if (tags && text && id && data.time && timeEnd && color) {
    anno = {
      tags: tags,
      text: text,
      id: id,
      time: data.time,
      timeEnd: timeEnd,
      color: color,
    };
  }
  return (
    <>
      <div ref={markerRef} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={styles.markerWrapper}>
        <div className={styles.marker} />
      </div>
      {isOpen && <Portal>{renderMarker()}</Portal>}
      {isEditing && (
        <Portal>
          <AnnotationEditorForm
            panelID={panelID}
            onDismiss={() => setIsEditing(false)}
            onSave={() => setIsEditing(false)}
            timeFormatter={timeFormatter}
            annotation={anno}
            ref={setEditorRef}
            style={editorPopper.styles.popper}
            {...editorPopper.attributes.popper}
          />
        </Portal>
      )}
    </>
  );
};

const getAnnotationMarkerStyles = (theme: GrafanaTheme) => {
  const bg = theme.isDark ? theme.palette.dark2 : theme.palette.white;
  const headerBg = theme.isDark ? theme.palette.dark9 : theme.palette.gray5;
  const shadowColor = theme.isDark ? theme.palette.black : theme.palette.white;

  return {
    markerWrapper: css`
      padding: 0 4px 4px 4px;
    `,
    marker: css`
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px solid ${theme.palette.red};
      pointer-events: none;
    `,
    wrapper: css`
      background: ${bg};
      border: 1px solid ${headerBg};
      border-radius: ${theme.border.radius.md};
      max-width: 400px;
      box-shadow: 0 0 20px ${shadowColor};
    `,
    tooltip: css`
      background: none;
      padding: 0;
    `,
    title: css`
      font-weight: ${theme.typography.weight.semibold};
      padding-right: ${theme.spacing.md};
      overflow: hidden;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex-grow: 1;
    `,
    time: css`
      color: ${theme.colors.textWeak};
      font-style: italic;
      font-weight: normal;
      display: inline-block;
      position: relative;
      top: 1px;
    `,
    body: css`
      padding: ${theme.spacing.sm};
      font-weight: ${theme.typography.weight.semibold};
    `,
    header: css`
      border-bottom: 1px solid ${theme.colors.border1};
      padding: 6px 10px;
      background: ${headerBg};
    `,
    ts: css`
      font-size: 0.8571428571428571rem;
      color: ${theme.palette.gray2};
    `,
    avatar: css`
      border-radius: 50%;
      width: 16px;
      height: 16px;
      margin-right: 5px;
    `,
  };
};
