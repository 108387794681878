import { css, cx } from '@emotion/css';
import {
  FieldConfigSource,
  GrafanaTheme,
  InterpolateFunction,
  PanelData,
  PanelProps,
  ReduceDataOptions,
} from '@grafana/data';
import { config } from '@grafana/runtime';
import { PanelContext, stylesFactory } from '@grafana/ui';
import { PanelContextRoot } from '@grafana/ui/src/components/PanelChrome';
import { cloneDeep } from 'lodash';
import { Map as OpenLayerMap, View } from 'ol';
import { defaults as defaultControls } from 'ol/control';
import React, { Component } from 'react';
import { Controller } from './Control/Control';
import { CustomControl } from './Control/CustomControlLegend';
import { HeatMapData } from './HeatMapData';
import { OSMapLayerOptions, getDefaultGeojsonConfig } from './LayerEditor/types';
import { DEFAULT_BASEMAP_CONFIG, osmapLayerRegistry } from './Layers/registry';
import { MainSlider } from './Slider/main';
import MainTooltip from './TooltipNew/MainTooltip';
import { MapLayerType } from './types/interface';
import { OSMapLayerActions, OSMapLayerState, OSMapPanelOptions } from './types/types';

/**
 * @typedef {Object} OsMapState
 * @property {number} selected provide index of current layer selected
 * @property {number} slide store the current time chosen in time slider
 */
interface OsMapState {
  selected: number;
  slide: number;
}

/**
 * Represents the properties for an OSMapPanel component.
 *
 * @template OSMapPanelOptions - The options specific to the OSMapPanel.
 * @extends PanelProps<OSMapPanelOptions>
 *
 * @typedef {PanelProps<OSMapPanelOptions>} OsMapProps
 */
type OsMapProps = PanelProps<OSMapPanelOptions>;

/**
 * Open Street map panel
 * @class
 * @extends {Component<OsMapProps, OsMapState>}
 * @returns {JSX.Element}
 */
export class OSMapPanel extends Component<OsMapProps, OsMapState> {
  static contextType = PanelContextRoot;
  panelContext: PanelContext = {} as PanelContext;

  theme = config.theme;
  style = getStyles(config.theme);
  customControl = new CustomControl({});
  map?: OpenLayerMap;
  layers: OSMapLayerState[] = [];
  readonly layerNameMap = new Map<string, OSMapLayerState>();

  constructor(props: OsMapProps) {
    super(props);
    this.state = {
      selected: props.options.layers ? props.options.layers.length : 1,
      slide: props.timeRange.from.toDate().getTime(),
    };
    this.dataChanged = this.dataChanged.bind(this);
    this.updateSliderTime = this.updateSliderTime.bind(this);
  }

  componentDidMount() {
    this.panelContext = this.context as PanelContext;
  }

  /**
   * Update the slider time when sliding the time slider and control the play and pause behavior.
   *
   * @function
   * @param {number} time - The new time value in milliseconds to set for the slider.
   */
  updateSliderTime(time: number) {
    this.setState({ slide: time });
  }

  /**
   * Get the minimum time in milliseconds from the time range provided in the component's props.
   *
   * @function
   * @returns {number} The minimum time in milliseconds.
   */
  getMinTime() {
    return this.props.timeRange.from.toDate().getTime();
  }

  /**
   * Get the maximum time in milliseconds from the time range provided in the component's props.
   *
   * @function
   * @returns {number} The maximum time in milliseconds.
   */
  getMaxTime() {
    return this.props.timeRange.to.toDate().getTime();
  }

  shouldComponentUpdate(nextProps: OsMapProps) {
    if (!this.map) {
      return true; // not yet initialized
    }

    // External data changed
    if (this.props.data !== nextProps.data) {
      this.dataChanged(
        nextProps.data,
        undefined,
        nextProps.options.reduceOptions,
        nextProps.fieldConfig,
        nextProps.replaceVariables,
        nextProps.options
      );
    }

    // Options changed
    if (this.props.options !== nextProps.options) {
      this.dataChanged(
        nextProps.data,
        undefined,
        nextProps.options.reduceOptions,
        nextProps.fieldConfig,
        nextProps.replaceVariables,
        nextProps.options
      );
    }
    if (this.props.fieldConfig !== nextProps.fieldConfig) {
      this.dataChanged(
        nextProps.data,
        undefined,
        nextProps.options.reduceOptions,
        nextProps.fieldConfig,
        nextProps.replaceVariables,
        nextProps.options
      );
    }

    return true;
  }

  /**
   * Update the component's options, including the selected layer, base map layer, and layers configuration.
   * Also handles updating the timelapse settings when a layer is deleted.
   *
   * @private
   * @function
   * @param {number} selected - The index of the selected layer.
   */
  private doOptionsUpdate(selected: number) {
    this.setState({ ...this.state, selected });

    const { options, onOptionsChange } = this.props;
    const layers = this.layers;

    onOptionsChange({
      ...options,
      baseMaplayer: layers[0].options,
      layers: layers.slice(1).map((v) => v.options),
      // updating timelapse when layer is deleted
      timelapse: !layers.some((layer) => layer.getName() === options.timelapse.layerName)
        ? { ...options.timelapse, isTimeLapse: false, layerName: '' }
        : { ...options.timelapse },
    });

    updateInstanceState(this, layers, selected);
  }

  /**
   * Generate a unique name for the next layer based on existing layer names.
   *
   * @private
   * @function
   * @returns {string} A unique layer name.
   */
  getNextLayerName = () => {
    let idx = this.layers.length; // bcz basemap is always at 0 index
    while (true && idx < 100) {
      const name = `Layer ${idx++}`;
      if (!this.layerNameMap.has(name)) {
        return name;
      }
    }
    return `Layer ${Date.now()}`;
  };

  /**
   * Actions for managing layers and interactions within the OSMapPanel component.
   *
   * @typedef {Object} OSMapLayerActions
   * @property {function} selectLayer - Select a layer by its unique identifier (uid).
   * @property {function} deleteLayer - Delete a layer by its unique identifier (uid).
   * @property {function} addLayer - Add a new layer of the specified type.
   */
  actions: OSMapLayerActions = {
    selectLayer: (uid: string) => {
      const selected = this.layers.findIndex((v) => v.options.name === uid);
      this.setState({ ...this.state, selected });
      this.dataChanged(undefined, undefined, undefined, undefined, undefined, undefined, selected);
      updateInstanceState(this, this.layers, selected);
    },
    deleteLayer: (uid: string) => {
      const layers: OSMapLayerState[] = [];
      for (const lyr of this.layers) {
        if (lyr.options.name === uid) {
          this.customControl.updateContent(null, false, 0);
          this.map?.removeLayer(lyr.layer);
        } else {
          layers.push(lyr);
        }
      }

      this.layers = layers;
      this.doOptionsUpdate(0);
    },
    addLayer: (type: string) => {
      const item = osmapLayerRegistry.getIfExists(type);
      if (!item) {
        return; // ignore empty request
      }
      this.initLayer(
        this.map!,
        {
          type: item.id as MapLayerType,
          name: this.getNextLayerName(),
          config: cloneDeep(item.defaultOptions),
          tooltip: true,
          opacity: 1,
        },
        false
      ).then((lyr) => {
        this.layers = this.layers.slice(0);
        this.layers.push(lyr);
        this.map?.addLayer(lyr.layer);

        this.doOptionsUpdate(this.layers.length - 1);
      });
    },
  };

  /**
   * Update the data for all layers based on the provided parameters and configuration.
   *
   * @function
   * @param {PanelData | undefined} data - The new data for the layers.
   * @param {number | undefined} time - The time value to apply to the layers.
   * @param {ReduceDataOptions | undefined} reduceOptions - Options for data reduction.
   * @param {FieldConfigSource<any> | undefined} fieldConfig - Field configuration for data processing.
   * @param {InterpolateFunction | undefined} replaceVariables - Function to replace variables in the data.
   * @param {OSMapPanelOptions | undefined} displayOptions - Display options for the layers.
   * @param {number | undefined} selected - The index of the selected layer.
   */
  dataChanged(
    data?: PanelData,
    time?: number,
    reduceOptions?: ReduceDataOptions,
    fieldConfig?: FieldConfigSource<any>,
    replaceVariables?: InterpolateFunction,
    displayOptions?: OSMapPanelOptions,
    selected?: number
  ) {
    for (const state of this.layers) {
      if (state.handler.update) {
        if (
          (state.options.type === MapLayerType.HEATMAP || state.options.type === MapLayerType.MARKER) &&
          state.options.name === this.props.options.timelapse.layerName
        ) {
          state.handler.update(data || this.props.data, {
            timeLapseCurrTime: time || this.state.slide,
            fieldConfig: fieldConfig || this.props.fieldConfig,
            replaceVariables: replaceVariables || this.props.replaceVariables,
            theme: this.theme,
            reduceOptions: reduceOptions || this.props.options.reduceOptions,
            displayOptions: displayOptions || this.props.options,
            customControl: this.customControl,
            selected: selected || this.state.selected,
          });
        } else {
          state.handler.update(data || this.props.data, {
            timeLapseCurrTime: this.state.slide,
            fieldConfig: fieldConfig || this.props.fieldConfig,
            replaceVariables: replaceVariables || this.props.replaceVariables,
            theme: this.theme,
            reduceOptions: reduceOptions || this.props.options.reduceOptions,
            displayOptions: displayOptions || this.props.options,
            customControl: this.customControl,
            selected: selected || this.state.selected,
          });
        }
      }
    }
  }

  /**
   * Callback method used as a ref for the map's containing <div>.
   *
   * @function
   * @async
   * @param {HTMLDivElement | null} div - The HTML div element associated with the map.
   * @returns {Promise<void>}
   */
  mapRef = async (div: HTMLDivElement) => {
    if (this.map) {
      this.map.dispose();
    }

    if (!div) {
      this.map = undefined;
      return;
    }
    const { options, onOptionsChange } = this.props;

    let view = new View({
      center: [0, 0],
      zoom: 1,
      showFullExtent: true,
      rotation: 0,
    });

    const map = (this.map = new OpenLayerMap({
      view: view,
      pixelRatio: 1, // or zoom?
      layers: [],
      controls: defaultControls({
        zoom: false,
        attribution: false,
        rotate: false,
      }),
      target: div,
      interactions: undefined,
    }));

    this.layerNameMap.clear();
    const layers: OSMapLayerState[] = [];
    try {
      layers.push(await this.initLayer(map, options.baseMaplayer ?? DEFAULT_BASEMAP_CONFIG, true));

      // Default layer values
      let layerOptions = options.layers;
      if (!layerOptions) {
        layerOptions = [getDefaultGeojsonConfig];
        onOptionsChange({
          ...options,
          layers: layerOptions,
        });
      }

      for (const lyr of layerOptions) {
        layers.push(await this.initLayer(map, lyr, false));
      }
    } catch (ex) {}

    this.layers = layers;
    for (const lyr of layers) {
      this.map.addLayer(lyr.layer);
    }

    this.forceUpdate();
    updateInstanceState(this, layers, layers.length - 1);
  };

  /**
   * Asynchronously updates a map layer's options and reinitializes it.
   *
   * @function
   * @async
   * @param {string} uid - The unique identifier of the map layer to update.
   * @param {OSMapLayerOptions} newOptions - The new options for the map layer.
   * @returns {Promise<boolean>} A Promise that resolves to `true` if the update is successful, or `false` if there's an error.
   */
  private updateLayer = async (uid: string, newOptions: OSMapLayerOptions): Promise<boolean> => {
    if (!this.map) {
      return false;
    }
    const current = this.layerNameMap.get(uid);
    if (!current) {
      return false;
    }

    let layerIndex = -1;
    const group = this.map?.getLayers()!;
    for (let i = 0; i < group?.getLength(); i++) {
      if (group.item(i) === current.layer) {
        layerIndex = i;
        break;
      }
    }

    if (newOptions.name !== uid) {
      if (!newOptions.name) {
        newOptions.name = uid;
      } else if (this.layerNameMap.has(newOptions.name)) {
        return false;
      }
      this.layerNameMap.delete(uid);

      uid = newOptions.name;
      this.layerNameMap.set(uid, current);
    }

    const layers = this.layers.slice(0);
    try {
      const info = await this.initLayer(this.map, newOptions, current.isBasemap);
      layers[layerIndex] = info;
      group.setAt(layerIndex, info.layer);
      const { data, options, fieldConfig, replaceVariables } = this.props;

      if (this.props.options.timelapse.isTimeLapse) {
        // initialize with new data
        if (info.handler.update) {
          info.handler.update(data, {
            timeLapseCurrTime: this.state.slide,
            fieldConfig: fieldConfig,
            replaceVariables: replaceVariables,
            theme: this.theme,
            reduceOptions: options.reduceOptions,
            displayOptions: options,
            customControl: this.customControl,
            selected: this.state.selected,
          });
        }
      } else {
        // initialize with new data
        if (info.handler.update) {
          info.handler.update(data, {
            timeLapseCurrTime: this.state.slide,
            fieldConfig: fieldConfig,
            replaceVariables: replaceVariables,
            theme: this.theme,
            reduceOptions: options.reduceOptions,
            displayOptions: options,
            customControl: this.customControl,
            selected: this.state.selected,
          });
        }
      }
    } catch (err) {
      return false;
    }

    this.layers = layers;
    this.doOptionsUpdate(layerIndex);
    return true;
  };

  /**
   * Asynchronously initializes a map layer based on the provided options and adds it to the map.
   *
   * @async
   * @param {OpenLayerMap} map - The OpenLayers map to which the layer will be added.
   * @param {OSMapLayerOptions} options - The options for configuring the map layer.
   * @param {boolean} [isBasemap] - Indicates whether the layer is a basemap. Default is `false`.
   * @returns {Promise<OSMapLayerState>} A Promise that resolves to the initialized map layer state.
   * @throws {string} Throws an error if the layer type specified in `options.type` is unknown.
   */
  async initLayer(map: OpenLayerMap, options: OSMapLayerOptions, isBasemap?: boolean): Promise<OSMapLayerState> {
    if (isBasemap && !options?.type) {
      options = DEFAULT_BASEMAP_CONFIG;
    }

    // Use default geojson layer
    if (!options?.type) {
      options = {
        type: MapLayerType.GEOJSON,
        name: this.getNextLayerName(),
        config: {},
      };
    }

    const item = osmapLayerRegistry.getIfExists(options.type);
    if (!item) {
      return Promise.reject('unknown layer: ' + options.type);
    }

    const handler = await item.create(map, options);
    const layer = handler.init();
    const {
      data,
      options: { reduceOptions },
      fieldConfig,
      replaceVariables,
    } = this.props;

    if (handler.update) {
      handler.update(data, {
        timeLapseCurrTime: this.state.slide,
        fieldConfig: fieldConfig,
        replaceVariables: replaceVariables,
        theme: this.theme,
        reduceOptions: reduceOptions,
        displayOptions: this.props.options,
        customControl: this.customControl,
        selected: this.state.selected,
      });
    }

    if (!options.name) {
      options.name = this.getNextLayerName();
    }

    const name = options.name;
    const state = {
      name,
      isBasemap,
      options,
      layer,
      handler,
      getName: () => name,
      onChange: (cfg: OSMapLayerOptions) => {
        this.updateLayer(name, cfg);
      },
    };

    this.layerNameMap.set(name, state);
    (state.layer as any).__state = state;
    return state;
  }

  componentWillUnmount(): void {
    this.map?.dispose();
  }

  render() {
    const { width, height, options, eventBus, onOptionsChange, data, fieldConfig } = this.props;
    const { timelapse, layers } = options;

    return (
      <>
        <div
          className={cx(
            this.theme.isDark && 'googlemaps-dark',
            this.style.wrapper,
            css`
              width: ${width}px;
              height: ${height}px;
            `
          )}
        >
          <div className={this.style.map} ref={this.mapRef}>
            <Controller
              map={this.map}
              width={width}
              options={options}
              height={height}
              eventBus={eventBus}
              fieldConfig={fieldConfig}
              customControl={this.customControl}
            />
            {this.map && this.layers.some((layer) => layer.options.type === MapLayerType.HEATMAP) && (
              <HeatMapData map={this.map} onDataChange={this.dataChanged} />
            )}
            {this.map && layers && <MainTooltip map={this.map} options={options} onOptionsChange={onOptionsChange} />}
            {this.map && data.request?.intervalMs && timelapse.isTimeLapse && (
              <MainSlider
                minTime={this.getMinTime()}
                maxTime={this.getMaxTime()}
                intervalMs={data.request.intervalMs}
                onDataChange={this.dataChanged}
                windowWidth={width}
                isShowInfo={options.showInfo}
                timeLapseOpts={timelapse}
                updateSliderTime={this.updateSliderTime}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

/**
 * Updates the instance state of an OSMapPanel.
 *
 * @function
 * @param {OSMapPanel} osMapPanel - The OSMapPanel component to update.
 * @param {OSMapLayerState[]} layers - An array of map layer states.
 * @param {number} selected - The index of the currently selected map layer.
 */

function updateInstanceState(osMapPanel: OSMapPanel, layers: OSMapLayerState[], selected: number) {
  if (osMapPanel.panelContext && osMapPanel.panelContext.onInstanceStateChange) {
    osMapPanel.panelContext.onInstanceStateChange({
      map: osMapPanel.map,
      layers: layers,
      selected,
      actions: osMapPanel.actions,
    });
  }
}

const getStyles = stylesFactory((theme: GrafanaTheme) => ({
  wrapper: css`
    position: relative;
  `,
  map: css`
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
  `,
}));
