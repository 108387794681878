import { DataFrame, LoadingState, PanelData, dateTime } from '@grafana/data';
import { jsonStringToPanelData } from './jsonStringToPanelData';
import { cloneDeep } from 'lodash';

var alphabetArray = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');

export function calculateTimeDifference(timestamp: string) {
  // Convert timestamp to milliseconds
  var currentTime = Date.now();
  var diff = new Date(timestamp).getTime() - currentTime;

  // Calculate the difference in milliseconds, seconds, minutes, hours, and days
  var diffInSeconds = Math.floor(diff / 1000);
  var diffInMinutes = Math.floor(diffInSeconds / 60);
  var diffInHours = Math.floor(diffInMinutes / 60);
  var diffInDays = Math.floor(diffInHours / 24);

  // Determine the time unit and value
  var unit, value;
  if (Math.abs(diffInDays) >= 365) {
    unit = 'y';
    value = Math.floor(diffInDays / 365);
  } else if (Math.abs(diffInDays) >= 30) {
    unit = 'M';
    value = Math.floor(diffInDays / 30);
  } else if (Math.abs(diffInDays) >= 1) {
    unit = 'd';
    value = diffInDays;
  } else if (Math.abs(diffInHours) >= 1) {
    unit = 'h';
    value = diffInHours;
  } else if (Math.abs(diffInMinutes) >= 1) {
    unit = 'm';
    value = diffInMinutes;
  } else {
    unit = 's';
    value = diffInSeconds;
  }

  // Create the formatted string
  var formattedDiff = (diff < 0 ? '-' : '+') + Math.abs(value) + unit;

  return formattedDiff;
}

/**
 * Converts forecast data into a series format.
 * @param {PanelData} dataObj - The panel data object.
 * @param {any} forecast - The forecast data.
 * @param {(e: any) => void} onUpdateTimeRange - The function to update the time range.
 * @returns {PanelData} The updated panel data object.
 */
export const convertToSeries = (training: PanelData, testing: PanelData, forecast: any) => {
  const { x: timeArray } = forecast;

  var jsonResultData = [];
  for (let i = 0; i < forecast.x.length; i++) {
    const Time = forecast.x[i];
    const Value = forecast.y[i];
    jsonResultData.push({ Time: Time, [forecast.alias]: Value });
  }
  var resultData = jsonStringToPanelData(JSON.stringify(jsonResultData));
  var finalDataSeries = cloneDeep(training);
  resultData.series.map((element) => finalDataSeries.series.push(element));
  finalDataSeries.series.push(cloneDeep(testing.series[0]));
  finalDataSeries.series.push(resultData.series[0]);
  finalDataSeries.series.map((element, i) => {
    element.refId = alphabetArray[i];
    return element;
  });

  const startTime = training
    ? training?.series[0].fields.find((ele) => ele.type === 'time')?.values.toArray()[0]
    : timeArray[0];
  const endTime = timeArray[timeArray.length - 1];
  finalDataSeries.timeRange = {
    from: dateTime(startTime),
    to: dateTime(endTime),
    raw: {
      from: `now${calculateTimeDifference(startTime)}`,
      to: `now${calculateTimeDifference(endTime)}`,
    },
  };
  finalDataSeries.state = LoadingState.Done;
  return finalDataSeries;
};

export const convertToSeries2 = (
  trainingData: PanelData,
  testingData: PanelData,
  forecast: DataFrame,
  alias: string
) => {
  const timeArray = forecast.fields[0].values.toArray();

  forecast.fields = forecast.fields.filter((field, _) => field.name === 'time' || field.name === 'value');
  forecast.fields[1].name = alias;
  delete forecast.name;
  delete forecast.refId;
  // var resultData = jsonStringToPanelData(JSON.stringify(jsonResultData));
  var finalDataSeries = cloneDeep(trainingData);
  finalDataSeries.series.push(cloneDeep(testingData.series[0]));
  finalDataSeries.series.push(forecast);

  const startTime = trainingData
    ? trainingData?.series[0].fields.find((ele) => ele.type === 'time')?.values.toArray()[0]
    : timeArray[0];
  const endTime = timeArray[timeArray.length - 1];
  finalDataSeries.timeRange = {
    from: dateTime(startTime),
    to: dateTime(endTime),
    raw: {
      from: `now${calculateTimeDifference(startTime)}`,
      to: `now${calculateTimeDifference(endTime)}`,
    },
  };
  finalDataSeries.state = LoadingState.Done;
  return finalDataSeries;
};

export const convertToSeries2Ensemble = (
  trainingData: PanelData,
  testingData: PanelData,
  forecastData: any, // Change this to accept an object of forecast dataframes
  alias: string
) => {
  var finalDataSeries = cloneDeep(trainingData);
  finalDataSeries.series.push(cloneDeep(testingData.series[0]));
  let timeArray: any[] = []; // Define timeArray outside the loop
  // Iterate over each model in forecastData
  for (let modelName in forecastData) {
    let forecast = forecastData[modelName]; // Get the forecast dataframe for this model

    timeArray = forecast.fields[0].values.toArray();

    forecast.fields = forecast.fields.filter((field: any) => field.name === 'time' || field.name === 'value');
    forecast.fields[1].name = alias + '_' + modelName; // Append the model name to the alias
    delete forecast.name;
    delete forecast.refId;

    finalDataSeries.series.push(forecast);
  }

  const startTime = trainingData
    ? trainingData?.series[0].fields.find((ele) => ele.type === 'time')?.values.toArray()[0]
    : timeArray[0];
  const endTime = timeArray[timeArray.length - 1];
  finalDataSeries.timeRange = {
    from: dateTime(startTime),
    to: dateTime(endTime),
    raw: {
      from: `now${calculateTimeDifference(startTime)}`,
      to: `now${calculateTimeDifference(endTime)}`,
    },
  };
  finalDataSeries.state = LoadingState.Done;
  return finalDataSeries;
};
