import { EditorOptions } from './editor';

import { SingleStatBaseOptions } from '@grafana/ui';
import {
  ReducerID,
  standardEditorsRegistry,
  FieldOverrideContext,
  getFieldDisplayName,
  escapeStringForRegex,
  PanelOptionsEditorBuilder,
} from '@grafana/data';

// export interface TextOptions {
//   content: string;
//   defaultContent: string;
//   everyRow: boolean;
//   editor: EditorOptions;
//   helpers: string;
//   reduceOptions: any;
//   fontoverride: boolean;
//   minlimit: number;
//   maxlimit: number;
// }

export function addStandardDataReduceOptions(
  builder: PanelOptionsEditorBuilder<SingleStatBaseOptions>,
  includeOrientation = true
) {
  builder.addCustomEditor({
    id: 'reduceOptions.calcs',
    path: 'reduceOptions.calcs',
    name: 'Value',
    description: 'Choose a reducer function / calculation',
    editor: standardEditorsRegistry.get('stats-picker').editor as any,
    defaultValue: [ReducerID.mean],
    // Hides it when all values mode is on
    // showIf: currentConfig => currentConfig.reduceOptions.values === false,
  });
  builder.addSelect({
    path: 'reduceOptions.fields',
    name: 'Fields',
    description: 'Select the fields that should be included in the panel',
    settings: {
      allowCustomValue: true,
      options: [],
      getOptions: async (context: FieldOverrideContext) => {
        const options = [
          { value: '', label: 'Numeric Fields' },
          { value: '/.*/', label: 'All Fields' },
        ];
        if (context && context.data) {
          for (const frame of context.data) {
            for (const field of frame.fields) {
              const name = getFieldDisplayName(field, frame, context.data);
              const value = `/^${escapeStringForRegex(name)}$/`;
              options.push({ value, label: name });
            }
          }
        }
        return Promise.resolve(options);
      },
    },
    defaultValue: '',
  });
}

/**
 * Options
 */
export interface TextOptions {
  /**
   * Content
   *
   * @type {string}
   */
  content: string;

  /**
   * Default Content
   *
   * @type {string}
   */
  defaultContent: string;

  /**
   * Every Row
   *
   * @type {boolean}
   */
  everyRow: boolean;

  /**
   * Editor
   *
   * @type {EditorOptions}
   */
  editor: EditorOptions;

  /**
   * Helpers
   *
   * @type {string}
   */
  helpers: string;

  /**
   * ReduceOptions
   *
   * @type {any}
   */
  reduceOptions: any;

  /**
   * Fontoverride boolean switch
   *
   * @type {boolean}
   */
  fontoverride: boolean;

  /**
   * Minlimit for font override.
   *
   * @type {number}
   */
  minlimit: number;

  /**
   * Maxlimit font override.
   *
   * @type {number}
   */
  maxlimit: number;

  /**
   * Seriespicker option
   *
   * @type {boolean}
   */
  seriespicker: boolean;
}
