import React, { PureComponent } from 'react';
import { LegacyForms, Icon, InfoBox, LinkButton, RadioButtonGroup } from '@grafana/ui';
const { Switch } = LegacyForms;
import { SelectableValue, AppEvents, getTimeZoneInfo } from '@grafana/data';
import { DashboardModel, PanelModel } from 'app/features/dashboard/state';
import { config } from 'app/core/config';
import { appEvents } from 'app/core/core';
import { buildDashPDFUrl, buildPanelPDFUrl } from './utils';
import { getDashboardSrv } from 'app/features/dashboard/services/DashboardSrv';

interface Props {
  dashboard: DashboardModel;
  panel?: PanelModel;
  onDismiss(): void;
}
export interface State {
  useCurrentTimeRange: boolean;
  includeTemplateVars: boolean;
  selectedTheme: string;
  imageUrl: string;
  kiosk: boolean;
  layout: string;
  orientation: string;
  pageSize: any;
  invoice: boolean;
}

const themeOptions: Array<SelectableValue<string>> = [
  { label: 'Current', value: 'current' },
  { label: 'Dark', value: 'dark' },
  { label: 'Light', value: 'light' },
];

const layoutOptions: Array<SelectableValue<string>> = [
  { label: 'Single Page', value: 'single' },
  { label: 'Grid', value: 'grid' },
  { label: 'Simple', value: 'simple' },
];

const orientationOptions: Array<SelectableValue<string>> = [
  { label: 'Portrait', value: 'portrait' },
  { label: 'Landscape', value: 'landscape' },
];

type numstr = Number | String;

const pageOptions: Array<SelectableValue<numstr[]>> = [
  { label: 'A2', value: [1587, 2245, 1191, 1684, 'a2'] },
  { label: 'A3', value: [1123, 1587, 842, 1191, 'a3'] },
  { label: 'A4', value: [794, 1123, 595, 842, 'a4'] },
  { label: 'A5', value: [559, 794, 420, 595, 'a5'] },
];

export class DownloadPDF extends PureComponent<Props, State> {
  // @ts-ignore
  private dashboard: DashboardModel;
  constructor(props: Props) {
    super(props);
    this.state = {
      useCurrentTimeRange: true,
      includeTemplateVars: false,
      selectedTheme: 'current',
      imageUrl: '',
      kiosk: false,
      layout: 'single',
      orientation: 'portrait',
      pageSize: pageOptions[2].value,
      invoice: false,
    };
  }
  componentDidMount() {
    this.buildUrl();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      useCurrentTimeRange,
      includeTemplateVars,
      selectedTheme,
      kiosk,
      layout,
      orientation,
      pageSize,
      invoice,
    } = this.state;
    if (
      prevState.useCurrentTimeRange !== useCurrentTimeRange ||
      prevState.includeTemplateVars !== includeTemplateVars ||
      prevState.selectedTheme !== selectedTheme ||
      prevState.kiosk !== kiosk ||
      prevState.layout !== layout ||
      prevState.orientation !== orientation ||
      prevState.pageSize !== pageSize ||
      prevState.invoice !== invoice
    ) {
      this.buildUrl();
    }
  }

  buildUrl = () => {
    const {
      useCurrentTimeRange,
      includeTemplateVars,
      selectedTheme,
      kiosk,
      layout,
      orientation,
      pageSize,
      invoice,
    } = this.state;
    let imageUrl = '';
    const info = getTimeZoneInfo(getDashboardSrv().getCurrent().timezone, Date.now());
    if (!this.props.panel) {
      imageUrl = buildDashPDFUrl(
        useCurrentTimeRange,
        includeTemplateVars,
        info?.ianaName || 'UTC',
        kiosk,
        layout,
        orientation,
        pageSize,
        invoice,
        selectedTheme
      );
    } else {
      imageUrl = buildPanelPDFUrl(
        useCurrentTimeRange,
        includeTemplateVars,
        info?.ianaName || 'UTC',
        kiosk,
        layout,
        orientation,
        pageSize,
        selectedTheme,
        this.props.panel
      );
    }
    this.setState({ imageUrl });
  };

  onUseCurrentTimeRangeChange = () => {
    this.setState({ useCurrentTimeRange: !this.state.useCurrentTimeRange });
  };

  onIncludeTemplateVarsChange = () => {
    this.setState({ includeTemplateVars: !this.state.includeTemplateVars });
  };

  onKioskChange = () => {
    this.setState({ kiosk: !this.state.kiosk });
  };

  onInvoiceChange = () => {
    this.setState({ invoice: !this.state.invoice });
  };

  onThemeChange = (value: string) => {
    this.setState({ selectedTheme: value });
  };

  onLayoutChange = (value: string) => {
    this.setState({ layout: value });
  };

  onOrientationChange = (value: string) => {
    this.setState({ orientation: value });
  };

  onPageSizeChange = (value: Number[]) => {
    this.setState({ pageSize: value });
  };

  onShareUrlCopy = () => {
    appEvents.emit(AppEvents.alertSuccess, ['Content copied to clipboard']);
  };

  renderStep1() {
    const {
      useCurrentTimeRange,
      includeTemplateVars,
      selectedTheme,
      imageUrl,
      kiosk,
      layout,
      orientation,
      pageSize,
      invoice,
    } = this.state;
    return (
      <>
        <div className="share-modal-body">
          <div className="share-modal-header">
            <Icon name="link" className="share-modal-big-icon" size="xxl" />
            <div className="share-modal-content">
              <p className="share-modal-info-text">
                Download a copy of the current dashboard, customized with the options below.
              </p>
              <div className="gf-form-group">
                <Switch
                  labelClass="width-12"
                  label="Current time range"
                  checked={useCurrentTimeRange}
                  onChange={this.onUseCurrentTimeRangeChange}
                />
                <Switch
                  labelClass="width-12"
                  label="Template variables"
                  checked={includeTemplateVars}
                  onChange={this.onIncludeTemplateVarsChange}
                />
                <Switch labelClass="width-12" label="Full Screen" checked={kiosk} onChange={this.onKioskChange} />
                {!this.props.panel && (
                  <Switch labelClass="width-12" label="Invoice" checked={invoice} onChange={this.onInvoiceChange} />
                )}
                <div className="gf-form">
                  <label className="gf-form-label width-12">Theme</label>
                  <RadioButtonGroup options={themeOptions} value={selectedTheme} onChange={this.onThemeChange} />
                </div>
                {!this.props.panel && (
                  <div className="gf-form">
                    <label className="gf-form-label width-12">Layout</label>
                    <RadioButtonGroup options={layoutOptions} value={layout} onChange={this.onLayoutChange} />
                  </div>
                )}
                <div className="gf-form">
                  <label className="gf-form-label width-12">Orientation</label>
                  <RadioButtonGroup
                    options={orientationOptions}
                    value={orientation}
                    onChange={this.onOrientationChange}
                  />
                </div>
                {!this.props.panel && layout !== 'single' && (
                  <div className="gf-form">
                    <label className="gf-form-label width-12">Page Size</label>
                    <RadioButtonGroup options={pageOptions} value={pageSize} onChange={this.onPageSizeChange} />
                  </div>
                )}
              </div>
              {config.rendererAvailable && (
                <div className="gf-form">
                  <LinkButton
                    variant="secondary"
                    target="_blank"
                    aria-label="Click to download pdf"
                    icon="copy"
                    className="max-width-7"
                    href={imageUrl}
                  >
                    Download
                  </LinkButton>
                </div>
              )}
              {!config.rendererAvailable && (
                <InfoBox>
                  <p>
                    <>To render a panel image, you must install the </>
                    <a href="https://arnowa.com/" target="_blank" rel="noreferrer" className="external-link">
                      Image Renderer plugin
                    </a>
                    . Please contact your administrator to install the plugin.
                  </p>
                </InfoBox>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="share-modal-body">
        <div className="share-modal-header">
          <Icon name="file-text" className="share-modal-big-icon" size="xxl" />
          <div className="share-modal-content">{this.renderStep1()}</div>
        </div>
      </div>
    );
  }
}
