import React from 'react';
import { css } from 'emotion';
import { GrafanaTheme } from '@grafana/data';
import { Button, Icon, InlineLabel, InlineFieldRow, Input, Select, useStyles } from '@grafana/ui';
import alertDef, { evalFunction } from '../../alerting/alertEngine/state/alertDef';
import { reducerType } from '../types';

const reducerFunctions = alertDef.reducerTypes.map((rt) => ({ label: rt.text, value: rt.value }));
const evalOperators = alertDef.evalOperators.map((eo) => ({ label: eo.text, value: eo.value }));
const evalFunctions = alertDef.evalFunctions.map((ef) => ({ label: ef.text, value: ef.value }));

export const Classic_conditions = ({ condition, index, onChange, onRemoveOperation, refIds, onRunQuery }: any) => {
  const styles = useStyles(getStyles);
  const onEvalOperatorChange = (e: any) => {
    onChange({
      ...condition,
      operator: { type: e.value! },
    });
  };

  const onReducerFunctionChange = (e: any) => {
    onChange({
      ...condition,
      reducer: { type: e.value! as reducerType, params: [] },
    });
  };

  const onRefIdChange = (e: any) => {
    onChange({
      ...condition,
      query: { params: [e.value!] },
    });
  };

  const onEvalFunctionChange = (e: any) => {
    onChange({
      ...condition,
      evaluator: { params: condition.evaluator.params, type: e.value! },
    });
  };

  const onEvaluateValueChange = (e: any, index: number) => {
    const newValue = parseFloat(e.currentTarget.value);
    const newParams = [...condition.evaluator.params];
    newParams[index] = newValue;

    onChange({
      ...condition,
      evaluator: { ...condition.evaluator, params: newParams },
    });
    onRunQuery();
  };

  const rangeSelected =
    condition.evaluator.type === evalFunction.If_Within_Range ||
    condition.evaluator.type === evalFunction.If_Outside_Range;

  return (
    <InlineFieldRow>
      {index === 0 ? (
        <InlineLabel width={10}>WHEN</InlineLabel>
      ) : (
        <Select
          width={15}
          options={evalOperators}
          onChange={onEvalOperatorChange}
          value={evalOperators.find((item: any) => item.value === condition.operator!.type)}
        />
      )}
      <Select
        className={styles.select}
        options={reducerFunctions}
        onChange={onReducerFunctionChange}
        width={20}
        value={reducerFunctions.find((item: any) => item.value === condition.reducer.type)}
      />
      <InlineLabel width={5}>OF</InlineLabel>
      <Select
        className={styles.select1}
        onChange={onRefIdChange}
        options={refIds}
        width={15}
        value={refIds.find((ref: any) => ref.value === condition.query.params[0])}
      />
      <Select
        className={styles.select}
        width={15}
        options={evalFunctions}
        onChange={onEvalFunctionChange}
        value={evalFunctions.find((ef) => ef.value === condition.evaluator.type)}
      />
      {rangeSelected ? (
        <>
          <Input
            type="number"
            width={10}
            onChange={(event) => onEvaluateValueChange(event, 0)}
            value={condition.evaluator.params[0]}
          />
          <InlineLabel width={5}>TO</InlineLabel>
          <Input
            type="number"
            width={10}
            onChange={(event) => onEvaluateValueChange(event, 1)}
            value={condition.evaluator.params[1]}
          />
        </>
      ) : condition.evaluator.type !== evalFunction.Has_No_Value ? (
        <Input
          type="number"
          width={10}
          onChange={(event) => onEvaluateValueChange(event, 0)}
          value={condition.evaluator.params[0]}
        />
      ) : null}

      <Button variant="secondary" type="button" onClick={() => onRemoveOperation(index)}>
        <Icon name="trash" />
      </Button>
    </InlineFieldRow>
  );
};

const getStyles = (theme: GrafanaTheme) => {
  const buttonStyle = css`
    color: ${theme.colors.textBlue};
    font-size: ${theme.typography.size.sm};
  `;
  return {
    buttonSelectText: buttonStyle,
    select: css`
      margin-left: 0.1rem;
      margin-right: 0.1rem;
    `,
    select1: css`
      margin-right: 0.1rem;
    `,
    button: css`
      display: flex;
      align-items: center;
      border-radius: ${theme.border.radius.sm};
      font-weight: ${theme.typography.weight.semibold};
      border: 1px solid ${theme.colors.border1};
      white-space: nowrap;
      padding: 0 ${theme.spacing.sm};
      background-color: ${theme.colors.bodyBg};
      color: ${theme.colors.textBlue};
      font-size: ${theme.typography.size.sm};
    `,
  };
};
