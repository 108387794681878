import React, { ChangeEvent, PureComponent } from 'react';
import { css } from 'emotion';
import { GrafanaTheme, CustomPaletteConfig, SelectableValue, PaletteMode } from '@grafana/data';
import { colors as colorsStock } from '../../utils';
import { ThemeContext } from '../../themes/ThemeContext';
import { Input } from '../Input/Input';
import { ColorPicker } from '../ColorPicker/ColorPicker';
import { stylesFactory } from '../../themes';
import { Icon } from '../Icon/Icon';
import { Button } from '../Button';
import { createPalette, checkValidColors } from './utils';
import { Label } from '../Forms/Label';
import { FullWidthButtonContainer } from '../Button/FullWidthButtonContainer';
import { RadioButtonGroup } from '../Forms/RadioButtonGroup/RadioButtonGroup';

const modes: Array<SelectableValue<PaletteMode>> = [
  { value: PaletteMode.Random, label: 'Random color', description: '' },
  { value: PaletteMode.Custom, label: 'Custom color', description: '' },
  { value: PaletteMode.Loader, label: 'Loader', description: '' },
];

export interface Props {
  palette: CustomPaletteConfig;
  onChange: (palette: CustomPaletteConfig) => void;
}

export class CustomPaletteEditor extends PureComponent<Props, CustomPaletteConfig> {
  private latestThresholdInputRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    this.state = {
      colors: this.props.palette.colors,
      mode: PaletteMode.Random,
      currColor: '',
      numOfShades: this.props.palette?.colors?.length || 0,
      rawColors: this.props.palette.colors.join(';'),
    };
    this.latestThresholdInputRef = React.createRef();
  }

  /*
    Add input box to take color input and generate palette for that color and use
    Provide copy option for created palette
    Provide three radio buttons
    1. Random color
    2. Custom color
    3. Loader
  */

  addNewPalette = (colors: string[]) => {
    this.setState({ colors: colors }, () => {
      if (this.latestThresholdInputRef.current) {
        this.latestThresholdInputRef.current.focus();
      }
      this.onChange();
    });
  };

  onAddNewColor = () => {
    const { colors } = this.state;
    const color = colorsStock[Math.floor(Math.random() * colorsStock.length)];
    const newColors = [...colors, color];

    this.setState({ colors: newColors }, () => {
      if (this.latestThresholdInputRef.current) {
        this.latestThresholdInputRef.current.focus();
      }
      this.onChange();
    });
  };

  onRemoveThreshold = (index: number) => {
    const { colors } = this.state;

    // If there is only one color, do not remove
    if (colors.length === 1) {
      return;
    }

    this.setState({ colors: colors.filter((t, idx) => idx !== index) }, this.onChange);
  };

  onChangeThresholdColor = (index: number, color: string) => {
    const { colors } = this.state;
    colors[index] = color;

    this.setState({ colors }, this.onChange);
  };

  onChangeColor = (color: string) => {
    const shades = createPalette(color, this.state.numOfShades);
    // check if all shades are valid colors
    const colors = checkValidColors(shades);
    this.setState({ colors: colors, currColor: color }, this.onChange);
  };

  onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const cleanValue = event.target.value.replace(/,/g, '.');
    const parsedValue = parseFloat(cleanValue);
    const value = isNaN(parsedValue) ? 10 : parsedValue;
    this.setState({ numOfShades: value });
  };

  onChangeRawColor = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ rawColors: event.target.value });
  };

  onBlur = () => {
    const shades = createPalette(this.state.currColor, this.state.numOfShades);
    // check if all shades are valid colors
    const colors = checkValidColors(shades);
    this.setState({ colors: colors }, this.onChange);
  };

  onInputBlur = () => {
    const rawColors = this.state.rawColors.split(';');
    const colors = checkValidColors(rawColors);
    this.setState({ colors: colors }, this.onChange);
  };

  onChange = () => {
    this.props.onChange(this.state);
  };

  onModeChanged = (value?: PaletteMode) => {
    this.setState({ ...this.state, mode: value! });
  };

  renderInput(color: string, styles: ThresholdStyles, idx: number) {
    return (
      <Input
        type="text"
        value={''}
        disabled
        ref={idx === 0 ? this.latestThresholdInputRef : null}
        prefix={
          <div className={styles.inputPrefix}>
            {color && (
              <div className={styles.colorPicker}>
                <ColorPicker
                  color={color}
                  onChange={(color) => this.onChangeThresholdColor(idx, color)}
                  enableNamedColors={true}
                />
              </div>
            )}
          </div>
        }
        suffix={<Icon className={styles.trashIcon} name="trash" onClick={() => this.onRemoveThreshold(idx)} />}
      />
    );
  }

  render() {
    const { colors, mode, numOfShades, currColor, rawColors } = this.state;
    return (
      <ThemeContext.Consumer>
        {(theme) => {
          const styles = getStyles(theme);
          return (
            <div className={styles.wrapper}>
              <div className={styles.buttonGroup}>
                <Label description="">Palette mode</Label>
                <FullWidthButtonContainer>
                  <RadioButtonGroup size="sm" options={modes} onChange={this.onModeChanged} value={this.state.mode} />
                </FullWidthButtonContainer>
              </div>
              {mode === PaletteMode.Random && (
                <>
                  <div className={styles.thresholds}>
                    {colors.map((color, idx) => (
                      <div className={styles.item} key={`custom-palette${idx}`}>
                        {this.renderInput(color, styles, idx)}
                      </div>
                    ))}
                  </div>
                  <Button
                    size="sm"
                    icon="plus"
                    onClick={() => this.onAddNewColor()}
                    variant="secondary"
                    className={styles.addButton}
                    fullWidth
                  >
                    Add color
                  </Button>
                </>
              )}
              {mode === PaletteMode.Custom && (
                <Input
                  type="number"
                  step="1"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => this.onChangeValue(event)}
                  value={numOfShades}
                  onBlur={this.onBlur}
                  prefix={
                    <div className={styles.colorPicker}>
                      <ColorPicker
                        color={currColor}
                        onChange={(color) => this.onChangeColor(color)}
                        enableNamedColors={false}
                      />
                    </div>
                  }
                />
              )}
              {mode === PaletteMode.Loader && (
                <div>
                  <Label description="Only hex & rgb color accepted">Provide semi-colon separated colors</Label>
                  <Input
                    type="text"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => this.onChangeRawColor(event)}
                    value={rawColors}
                    onBlur={this.onInputBlur}
                  />
                </div>
              )}
            </div>
          );
        }}
      </ThemeContext.Consumer>
    );
  }
}

interface ThresholdStyles {
  wrapper: string;
  thresholds: string;
  item: string;
  buttonGroup: string;
  colorPicker: string;
  addButton: string;
  percentIcon: string;
  inputPrefix: string;
  trashIcon: string;
}

const getStyles = stylesFactory(
  (theme: GrafanaTheme): ThresholdStyles => {
    return {
      wrapper: css`
        display: flex;
        flex-direction: column;
      `,
      thresholds: css`
        display: flex;
        flex-direction: column;
        margin-bottom: ${theme.spacing.formSpacingBase * 2}px;
      `,
      item: css`
        margin-bottom: ${theme.spacing.sm};

        &:last-child {
          margin-bottom: 0;
        }
      `,
      buttonGroup: css`
        margin-bottom: ${theme.spacing.md};
      `,
      colorPicker: css`
        padding: 0 ${theme.spacing.sm};
      `,
      addButton: css`
        margin-bottom: ${theme.spacing.sm};
      `,
      percentIcon: css`
        font-size: ${theme.typography.size.sm};
        color: ${theme.colors.textWeak};
      `,
      inputPrefix: css`
        display: flex;
        align-items: center;
      `,
      trashIcon: css`
        color: ${theme.colors.textWeak};
        cursor: pointer;

        &:hover {
          color: ${theme.colors.text};
        }
      `,
    };
  }
);
