export const sceneTypes = (
  type: string,
  url: string,
  xPos: number,
  yPos: number,
  zPos: number,
  xRot: number,
  yRot: number,
  zRot: number,
  val: string,
  query: string,
  visitUrl: string,
  orient: string
) => {
  if (type === 'sensor') {
    const toReturn = {
      name: 'sensor',
      position: { x: xPos, y: yPos, z: zPos },
      rotation: { x: xRot, y: yRot, z: zRot },
      scale: { x: 1, y: 1, z: 1 },
      components: [
        {
          type: 'mp.daeLoader',
          inputs: {
            url:
              'https://static.matterport.com/showcase-sdk/examples/assets-1.0-2-g6b74572/assets/models/iot/nest-1/model.dae',
            localScale: { x: 0.04, y: 0.04, z: 0.04 },
            localRotation: { x: 0, y: 0, z: 0 },
          },
        },
        {
          type: 'mp.planeRenderer',
        },
        {
          type: 'mp.canvasRenderer',
          inputs: {
            textureRes: {
              w: 1024,
              h: 1024,
            },
          },
        },
        {
          type: 'mp.nestThermostat',
          inputs: {
            data: val,
            query: query,
          },
        },
      ],
      bindings: [
        [3, 'loadingState', 0, 'loadingState'],
        [1, 'texture', 2, 'texture'],
        [2, 'painter', 3, 'painter'],
        [3, 'texture', 2, 'texture'],
        [1, 'visible', 3, 'visible'],
      ],
      events: [
        [3, 'hover', 0, 'INTERACTION.HOVER'],
        [2, 'repaint', 3, 'repaint'],
      ],
    };
    // toReturn.push({
    //   name: 'shadow',
    //   components: [],
    // })
    return toReturn;
  } else if (type === 'tv') {
    const toReturn = {
      name: 'video',
      position: { x: xPos, y: yPos, z: zPos },
      rotation: { x: xRot, y: yRot, z: zRot },
      scale: { x: 1.75, y: 1.75, z: 1.75 },
      components: [
        {
          type: 'mp.tuner',
          inputs: {
            urls: [url],
          },
          events: {
            next: true,
          },
        },
        {
          type: 'mp.hlsLoader',
        },
        {
          type: 'mp.videoRenderer',
        },
        {
          type: 'mp.planeRenderer',
          inputs: {
            transparent: false,
            localScale: { x: 0.6, y: 0.6, z: 0.6 },
            localPosition: { x: 0, y: 0, z: 0 },
          },
        },
        {
          type: 'mp.toggleState',
          inputs: {
            initialState: false,
          },
        },

        {
          type: 'mp.planeRenderer',
          inputs: {
            transparent: true,
            opacity: 0.8,
            localScale: { x: 0.6, y: 0.6, z: 0.6 },
          },
        },
        {
          type: 'mp.canvasRenderer',
          inputs: {
            textureRes: {
              w: 1024,
              h: 1024,
            },
          },
        },
        {
          type: 'mp.canvasBorder',
          inputs: {
            position: { x: 0, y: 0 },
            size: { h: 1024, w: 1024 },
            radius: 50,
          },
        },

        {
          type: 'mp.planeRenderer',
          inputs: {
            localScale: { x: 0.6, y: 0.6, z: 0.6 },
            transparent: true,
            opacity: 0.8,
            polygonOffset: true,
            polygonOffsetFactor: -2,
          },
        },
        {
          type: 'mp.canvasRenderer',
          inputs: {
            textureRes: {
              w: 512,
              h: 512,
            },
          },
        },
        {
          type: 'mp.canvasText',
          inputs: {
            size: {
              h: 512,
              w: 512,
            },
            text: 'Click me.',
            font: 'normal bold 40px sans-serif',
            position: { x: 160, y: 210, z: 0 },
            textWidth: 250,
          },
        },

        {
          type: 'mp.planeRenderer',
          inputs: {
            localScale: { x: 0.08, y: 0.08, z: 1.0 },
            localPosition: { x: 0.22, y: -0.13, z: 0.01 },
          },
          events: {
            'INTERACTION.CLICK': true,
          },
        },
        {
          type: 'mp.canvasRenderer',
          inputs: {
            textureRes: {
              w: 128,
              h: 128,
            },
          },
        },
        {
          type: 'mp.canvasImage',
          inputs: {
            src: 'https://static.matterport.com/showcase-sdk/examples/assets-1.0-2-g6b74572/assets/textures/ff.png',
            srcPosition: { x: 0, y: 0, z: 0 },
            srcSize: { w: 128, h: 128 },
            destPosition: { x: 0, y: 0, z: 0 },
            destSize: { w: 128, h: 128 },
          },
        },
      ],
      bindings: [
        [1, 'src', 0, 'src'],
        [2, 'src', 1, 'video'],
        [3, 'texture', 2, 'texture'],
        [1, 'enabled', 4, 'state'],
        [3, 'aspect', 1, 'aspect'],

        [6, 'painter', 7, 'painter'],
        [5, 'texture', 6, 'texture'],
        [5, 'aspect', 1, 'aspect'],

        [9, 'painter', 10, 'painter'],
        [8, 'texture', 9, 'texture'],
        [8, 'visible', 4, 'negated'],

        [12, 'painter', 13, 'painter'],
        [11, 'texture', 12, 'texture'],
        [11, 'visible', 4, 'state'],
      ],
      events: [
        [4, 'toggle', 3, 'INTERACTION.CLICK'],
        [0, 'next', 11, 'INTERACTION.CLICK'],
        [12, 'repaint', 13, 'paint.ready'],
      ],
    };
    return toReturn;
  } else if (type === 'camera') {
    const toReturn = {
      name: 'slot_camera',
      position: {
        x: xPos,
        y: yPos,
        z: zPos,
      },
      rotation: {
        x: xRot,
        y: yRot,
        z: zRot,
      },
      scale: {
        x: 1,
        y: 1,
        z: 1,
      },
      components: [
        {
          type: 'mp.gltfLoader',
          inputs: {
            url:
              'https://static.matterport.com/showcase-sdk/examples/assets-1.0-2-g6b74572/assets/models/camera-1/scene.gltf',
            localScale: { x: 0.0008, y: 0.0008, z: 0.0008 },
            localRotation: { x: 0, y: 90, z: 0 },
          },
          events: {
            'INTERACTION.CLICK': false,
            'INTERACTION.HOVER': false,
            'INTERACTION.DRAG': false,
          },
        },
        {
          type: 'mp.securityCamera',
          inputs: {
            nearPlane: 0.1,
            farPlane: 10,
            horizontalFOV: 52,
            aspect: 1.7777777777777777,
            localPosition: {
              x: 0,
              y: 0.18,
              z: -0.3,
            },
            localRotation: {
              x: -15,
              y: 0,
              z: 0,
            },
            color: 65280,
            panPeriod: 5,
            panAngle: -45,
          },
          events: {
            'INTERACTION.CLICK': false,
            'INTERACTION.HOVER': false,
            'INTERACTION.DRAG': false,
          },
        },
      ],
    };
    return toReturn;
  } else if (type === 'image') {
    let settings = {
      planeWidth: 965,
      planeHeight: 600,
      iconPosX: 0.42,
      iconPosY: -0.24,
      iconPosZ: 0.01,
    };
    if (orient === 'vertical') {
      settings = {
        planeWidth: 630,
        planeHeight: 920,
        iconPosX: 0.42,
        iconPosY: -0.65,
        iconPosZ: 0.01,
      };
    }
    const toReturn = {
      name: 'nesting',
      position: { x: xPos, y: yPos, z: zPos },
      rotation: { x: xRot, y: yRot, z: zRot },
      scale: { x: 1, y: 1, z: 1 },
      components: [
        {
          type: 'mp.planeRenderer',
          inputs: {
            transparent: false,
            localScale: { x: 1, y: 1, z: 1 },
            localPosition: { x: 0, y: 0, z: 0 },
            aspect: settings.planeWidth / settings.planeHeight,
            carbonImage: true,
          },
          events: {
            'INTERACTION.CLICK': true,
          },
        },
        {
          type: 'mp.canvasRenderer',
          inputs: {
            textureRes: {
              w: settings.planeWidth,
              h: settings.planeHeight,
            },
            carbonImage: true,
          },
        },
        {
          type: 'mp.canvasImage',
          inputs: {
            src: url,
            srcPosition: { x: 0, y: 0, z: 0 },
            srcSize: { w: settings.planeWidth, h: settings.planeHeight },
            destPosition: { x: 0, y: 0, z: 0 },
            destSize: { w: settings.planeWidth, h: settings.planeHeight },
          },
          events: {
            'INTERACTION.CLICK': false,
          },
        },
        {
          type: 'mp.planeRenderer',
          inputs: {
            localScale: { x: 0.08, y: 0.08, z: 1.0 },
            localPosition: { x: settings.iconPosX, y: settings.iconPosY, z: settings.iconPosZ },
            clickVisit: true,
            clickVisitUrl: visitUrl,
          },
          events: {
            'INTERACTION.CLICK': true,
          },
        },
        {
          type: 'mp.canvasRenderer',
          inputs: {
            textureRes: {
              w: 128,
              h: 128,
            },
          },
        },
        {
          type: 'mp.canvasImage',
          inputs: {
            src: '/public/img/external-matterport.png',
            srcPosition: { x: 0, y: 0, z: 0 },
            srcSize: { w: 128, h: 128 },
            destPosition: { x: 0, y: 0, z: 0 },
            destSize: { w: 128, h: 128 },
          },
          events: {
            'INTERACTION.CLICK': false,
          },
        },
      ],
      bindings: [
        [1, 'painter', 2, 'painter'],
        [0, 'texture', 1, 'texture'],
        // [0, 'visible', 0, 'visible'],

        [4, 'painter', 5, 'painter'],
        [3, 'texture', 4, 'texture'],
        // [3, 'visible', *4, 'state'],
      ],
      events: [
        [0, 'INTERACTION.CLICK', 2, 'INTERACTION.CLICK'],
        [1, 'repaint', 2, 'paint.ready'],

        [3, 'INTERACTION.CLICK', 5, 'INTERACTION.CLICK'],
        [4, 'repaint', 5, 'paint.ready'],
      ],
    };
    return toReturn;
  } else {
    return {};
  }
};
