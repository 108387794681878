import {
  ArrayVector,
  DataFrame,
  Field,
  FieldType,
  // getFieldSeriesColor,
  GrafanaTheme,
  MutableDataFrame,
  TimeRange,
  TimeZone,
  VizOrientation,
  formattedValueToString,
  getDisplayProcessor,
  getFieldColorModeForField,
  getFieldDisplayName,
} from '@grafana/data';
import {
  AxisPlacement,
  ScaleDirection,
  ScaleDistribution,
  ScaleOrientation,
  UPlotConfigBuilder,
  collectStackingGroups,
} from '@grafana/ui';
import React from 'react';
import { BarsOptions, getConfig } from '../bars';
import {
  BarChartFieldConfig,
  BarChartOptions,
  BarGraphMode,
  BarStackingMode,
  GraphNGLegendEventMode,
  defaultBarChartFieldConfig,
} from '../types';

export function mapMouseEventToMode(event: React.MouseEvent): GraphNGLegendEventMode {
  if (event.ctrlKey || event.metaKey || event.shiftKey) {
    return GraphNGLegendEventMode.AppendToSelection;
  }
  return GraphNGLegendEventMode.ToggleSelection;
}

export function preparePlotConfigBuilder(
  data: DataFrame,
  theme: GrafanaTheme,
  rawValue: (seriesIdx: number, valueIdx: number) => number,
  {
    toOrientation,
    showValue,
    groupWidth,
    toBarWidth,
    stacking,
    graphMode,
    colorArray,
    colorArrayStack,
    rotationXaxis,
    labelSpacingXaxis,
    spacingLTR,
  }: BarChartOptions,
  getTimeRange: () => TimeRange,
  getTimeZone: () => TimeZone
) {
  const UPlot_UNIT = '__fixed';
  const builder = new UPlotConfigBuilder();
  // bar orientation -> x scale orientation & direction
  let xOri = ScaleOrientation.Vertical;
  let xDir = ScaleDirection.Down;
  let yOri = ScaleOrientation.Horizontal;
  let yDir = ScaleDirection.Right;

  if (toOrientation === VizOrientation.Vertical) {
    xOri = ScaleOrientation.Horizontal;
    xDir = ScaleDirection.Right;
    yOri = ScaleOrientation.Vertical;
    yDir = ScaleDirection.Up;
  }
  const formatValue = (seriesIdx: number, value: any) => formattedValueToString(data.fields[seriesIdx].display!(value));

  // Use bar width when only one field
  if (data.fields.length === 2) {
    groupWidth = toBarWidth;
    toBarWidth = 1;
  }

  const opts: BarsOptions = {
    xOri,
    xDir,
    groupWidth,
    toBarWidth,
    formatValue,
    showValue,
    rawValue,
    stacking,
    toOrientation,
    graphMode,
    data,
    colorArray,
    spaceXaxis: graphMode === BarGraphMode.Timeseries ? labelSpacingXaxis : 0,
    spacingLTR: spacingLTR,
    onHover: (seriesIdx: number, valueIdx: number) => {
      console.log('hover', { seriesIdx, valueIdx });
    },
    onLeave: (seriesIdx: number, valueIdx: number) => {
      console.log('leave', { seriesIdx, valueIdx });
    },
  };
  const config = getConfig(opts, theme);
  builder.addHook('init', config.init);
  builder.addHook('drawClear', config.drawClear);
  builder.setShowTooltip(config.tooltipMaker);
  builder.setCursor(config.cursor);
  builder.setSelect(config.select);
  builder.addHook('draw', config.draw);

  builder.addScale({
    scaleKey: 'x',
    isTime: false,
    distribution: ScaleDistribution.Ordinal,
    orientation: xOri,
    direction: xDir,
  });
  if (xOri === ScaleOrientation.Horizontal && rotationXaxis !== 0 && graphMode === BarGraphMode.Timeseries) {
    builder.setPadding(rotationPadding(data, rotationXaxis));
  }
  builder.addAxis({
    scaleKey: 'x',
    isTime: false,
    placement: xOri === 0 ? AxisPlacement.Bottom : AxisPlacement.Left,
    splits: config.xSplits,
    values: graphMode === BarGraphMode.Timeseries ? config.xValuesTime : config.xValues,
    grid: false,
    ticks: false,
    gap: 15,
    xRotation: graphMode === BarGraphMode.Timeseries ? rotationXaxis : 0,
    theme,
  });
  let seriesIndex = 0;
  const stackingGroups: Map<string, number[]> = new Map();

  // iterate the y values
  for (let i = 1; i < data.fields.length; i++) {
    const field = data.fields[i];
    field.state!.seriesIndex = seriesIndex++;
    const customConfig: BarChartFieldConfig = { ...defaultBarChartFieldConfig, ...field.config.custom };

    const scaleKey = field.config.unit || UPlot_UNIT;
    const colorMode = getFieldColorModeForField(field);
    builder.addSeries({
      scaleKey,
      pxAlign: false,
      lineWidth: customConfig.lineWidth,
      lineColor: colorArrayStack[i - 1],
      fillOpacity: customConfig.fillOpacity,
      theme,
      colorMode,
      pathBuilder: config.drawBars,
      show: !customConfig.hideFrom?.graph,
      gradientMode: customConfig.gradientMode,
      thresholds: field.config.thresholds,

      // The following properties are not used in the uPlot config, but are utilized as transport for legend config
      dataFrameFieldIndex: {
        fieldIndex: i,
        frameIndex: 0,
      },
      fieldName: getFieldDisplayName(field, data),
      hideInLegend: customConfig.hideFrom?.legend,
    });
    // The builder will manage unique scaleKeys and combine where appropriate
    builder.addScale({
      scaleKey,
      min: field.config.min,
      max: field.config.max,
      softMin: customConfig.axisSoftMin,
      softMax: customConfig.axisSoftMax,
      orientation: yOri,
      direction: yDir,
    });

    if (customConfig.axisPlacement !== AxisPlacement.Hidden) {
      let placement = customConfig.axisPlacement;
      if (!placement || placement === AxisPlacement.Auto) {
        placement = AxisPlacement.Left;
      }
      if (xOri === 1) {
        if (placement === AxisPlacement.Left) {
          placement = AxisPlacement.Bottom;
        }
        if (placement === AxisPlacement.Right) {
          placement = AxisPlacement.Top;
        }
      }

      builder.addAxis({
        scaleKey,
        label: customConfig.axisLabel,
        size: customConfig.axisWidth,
        placement,
        formatValue: (v) => {
          return formattedValueToString(field.display!(v));
        },
        theme,
      });
    }
    collectStackingGroups(field, stackingGroups, seriesIndex);
  }
  if (stackingGroups.size !== 0) {
    builder.setStacking(true);
    for (const [_, seriesIdxs] of stackingGroups.entries()) {
      for (let j = seriesIdxs.length - 1; j > 0; j--) {
        builder.addBand({
          series: [seriesIdxs[j], seriesIdxs[j - 1]],
        });
      }
    }
  }

  return builder;
}
export function preparePlotFrame(data: DataFrame[]) {
  const firstFrame = data[0];
  const firstString = firstFrame.fields.find((f) => f.type === FieldType.string);

  if (!firstString) {
    throw new Error('No string field in DF');
  }

  const resultFrame = new MutableDataFrame();
  resultFrame.addField(firstString);

  for (const f of firstFrame.fields) {
    if (f.type === FieldType.number) {
      resultFrame.addField(f);
    }
  }

  return resultFrame;
}
export function getNamesToFieldIndex(frame: DataFrame): Map<string, number> {
  const names = new Map<string, number>();
  for (let i = 0; i < frame.fields.length; i++) {
    names.set(getFieldDisplayName(frame.fields[i], frame), i);
  }
  return names;
}

export function prepareGraphableFrames(
  series: DataFrame[],
  theme: GrafanaTheme,
  stacking: BarStackingMode,
  graphMode: string
): { frames?: DataFrame[]; warn?: string } {
  if (!series?.length) {
    return { warn: 'No data in response' };
  }

  const frames: DataFrame[] = [];
  const firstFrame = series[0];

  if (!firstFrame.fields.some((f) => f.type === FieldType.number)) {
    return {
      warn: 'No numeric fields found',
    };
  }

  // converting time type to string string
  firstFrame.fields[0].type = FieldType.string;

  let seriesIndex = 0;
  for (let frame of series) {
    const fields: Field[] = [];
    for (const field of frame.fields) {
      if (field.type === FieldType.number) {
        field.state = field.state ?? {};

        field.state.seriesIndex = seriesIndex++;

        let copy = {
          ...field,
          config: {
            ...field.config,
            custom: {
              ...field.config.custom,
              stacking: {
                group: '_',
                mode: stacking,
              },
            },
          },
          values: new ArrayVector(
            field.values.toArray().map((v) => {
              if (!(Number.isFinite(v) || v == null)) {
                return null;
              }
              return v;
            })
          ),
        };
        if (stacking === BarStackingMode.Percent) {
          copy.config.unit = 'percentunit';
          copy.display = getDisplayProcessor({ field: copy, theme });
        }

        fields.push(copy);
      } else {
        fields.push({ ...field });
      }
    }

    frames.push({
      ...frame,
      fields,
    });
  }

  return { frames };
}

function rotationPadding(frame: DataFrame, xRotateTicks: number): any {
  const paddingBottom = Math.sin(((xRotateTicks >= 0 ? xRotateTicks : xRotateTicks * -1) * Math.PI) / 180) * 50;
  return [5, 10, paddingBottom, 10];
}
