import { DataFrame, DisplayValue, FieldDisplay } from '@grafana/data';
import Geohash from 'latlon-geohash';
import { CompleteMarker } from '../Google/interface';
import { CustomMarkerI, QueryField } from '../types/interface';
import { QueryDataCal } from './interfcae';

/**
 * calculate data based on auto query setting
 * @param {Array<DataFrame>} frames
 * @param {Array<FieldDisplay>} bigData3
 * @returns {QueryDataCal | null}
 */
export function autoQueryData(frames: DataFrame[], bigData3: FieldDisplay[]): QueryDataCal | null {
  const hashField: DisplayValue[] = [];
  const locationField: DisplayValue[] = [];
  const valueField: DisplayValue[] = [];
  let hashFieldName = '';
  let locationFieldName = '';
  let valueFieldName = '';

  for (const field of frames[0].fields) {
    if (field.type === 'string') {
      const hashData = field.values.toArray()[0];
      try {
        const latlon = Geohash.decode(hashData);
        if (latlon) {
          hashFieldName = field.name;
        }
      } catch (error) {}
    }

    if (field.type === 'string' && field.name !== hashFieldName) {
      locationFieldName = field.name;
    }
    if (field.type === 'number') {
      valueFieldName = field.name;
    }
  }

  if (bigData3.length > 0) {
    for (let j = 0; j < bigData3.length; j++) {
      if (bigData3[j].display.title === valueFieldName) {
        valueField.push(bigData3[j].display);
      } else if (bigData3[j].display.title === hashFieldName) {
        hashField.push(bigData3[j].display);
      } else if (bigData3[j].display.title === locationFieldName) {
        locationField.push(bigData3[j].display);
      }
    }
  }

  if (hashField.length && valueField.length && locationField.length) {
    return { hashField, valueField, locationField };
  }
  return null;
}
/**
 * calculate data based on geohash query setting
 * @param {Array<FieldDisplay>} bigData3
 * @param {QueryField} querySelections
 * @returns {QueryDataCal | null}
 */
export function geohashQueryData(bigData3: FieldDisplay[], querySelections: QueryField): QueryDataCal | null {
  const hashField: DisplayValue[] = [];
  const locationField: DisplayValue[] = [];
  const valueField: DisplayValue[] = [];
  if (bigData3.length > 0) {
    for (let j = 0; j < bigData3.length; j++) {
      if (bigData3[j].display.title === querySelections.value) {
        valueField.push(bigData3[j].display);
      } else if (bigData3[j].display.title === querySelections.geohash) {
        hashField.push(bigData3[j].display);
      } else if (bigData3[j].display.title === querySelections.location) {
        locationField.push(bigData3[j].display);
      }
    }
  }

  if (hashField.length && valueField.length && locationField.length) {
    return { hashField, valueField, locationField };
  }

  return null;
}
/**
 * calculate custom marker data for marker data layer
 * @param {Array<FieldDisplay>} bigData2
 * @param {CustomMarkerI} customMarkers
 * @returns {Array<Array<CustomMarker>> | null}
 */
export function customMarkerData(bigData2: FieldDisplay[], customMarkers: CustomMarkerI): CompleteMarker[][] | null {
  const customMarkerData: CompleteMarker[][] = [];

  // * creating markerData for custom markers added by user
  if (bigData2.length > 0) {
    for (let k = 0; k < customMarkers.markers.length; k++) {
      let newData = [];

      if (customMarkers.markers[k].query.length > 0) {
        for (let index = 0; index < customMarkers.markers[k].query.length; index++) {
          for (let j = 0; j < bigData2.length; j++) {
            if (customMarkers.markers[k].query[index].value === bigData2[j].display.title) {
              // const value = ;
              newData.push({ ...customMarkers.markers[k], ...bigData2[j].display });
            }
          }
        }
      }
      if (newData.length > 0) {
        customMarkerData.push(newData);
      }
    }
  }

  return customMarkerData.length ? customMarkerData : null;
}

/**
 * calculate custom marker data for heat map layer
 * @param {Array<FieldDisplay>} bigData2
 * @param {CustomMarkerI} customMarkersHeat
 * @returns {Array<Array<CustomMarker>> | null}
 */
export function customMarkerDataHeat(
  bigData2: FieldDisplay[],
  customMarkersHeat: CustomMarkerI
): CompleteMarker[][] | null {
  const customMarkerDataHeat: CompleteMarker[][] = [];

  // * creating markerData for custom markers added by user
  if (bigData2.length > 0) {
    for (let k = 0; k < customMarkersHeat.markers.length; k++) {
      let newData = [];

      if (customMarkersHeat.markers[k].query.length > 0) {
        for (let index = 0; index < customMarkersHeat.markers[k].query.length; index++) {
          for (let j = 0; j < bigData2.length; j++) {
            if (customMarkersHeat.markers[k].query[index].value === bigData2[j].display.title) {
              // const value = ;
              newData.push({ ...customMarkersHeat.markers[k], ...bigData2[j].display });
            }
          }
        }
      }
      if (newData.length > 0) {
        customMarkerDataHeat.push(newData);
      }
    }
  }

  return customMarkerDataHeat.length ? customMarkerDataHeat : null;
}
