import React from 'react';
import { Input, Button, InlineFormLabel, Switch, Field, MultiSelect, useTheme } from '@grafana/ui';
import { AppEvents, FieldDisplay, getFieldDisplayValues } from '@grafana/data';
import { CustomMarkerI, SingleCustomMarker } from '../types/interface';
import appEvents from 'app/core/app_events';
import { cloneDeep } from 'lodash';

/**
 * props for custom marker in google heat map
 * @typedef {Object} Props
 * @property {CustomMarkerI} customMarkers
 * @property {function} onChange
 * @property {any} bigData
 */
interface Props {
  customMarkers: CustomMarkerI;
  onChange: (customMarkers: CustomMarkerI) => void;
  bigData: any;
}

/**
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function CustomMarkerHeat({ customMarkers, onChange, bigData }: Props) {
  const [queries, setQueries]: any = React.useState([]);
  const { markers, currIdx, enabled } = customMarkers;
  const theme = useTheme();
  const { options, replaceVariables, data } = bigData;
  const { fieldConfig } = options;
  const setoptions = cloneDeep(options.reduceOptions);
  delete setoptions.fields;
  delete setoptions.limit;
  delete setoptions.values;
  let newData: FieldDisplay[];

  const dataLoad = () => {
    if (data!.length > 0) {
      const getValues3 = (): FieldDisplay[] => {
        return getFieldDisplayValues({
          fieldConfig,
          reduceOptions: setoptions,
          replaceVariables,
          theme: theme,
          data: data!,
          sparkline: false,
          timeZone: 'browser',
        });
      };
      newData = getValues3();
    }
    const localQueries: any = [];
    newData?.map((ele: FieldDisplay) => {
      if (ele?.display?.title && ele.view?.dataFrame.fields[1].type === 'number') {
        localQueries.push({
          label: ele?.display?.title,
          value: ele?.display?.title,
        });
      }
    });
    setQueries(localQueries);
  };

  const latUpdate = (event: any, index: number) => {
    customMarkers.markers[index].lat = event.currentTarget.value;
    onChange(customMarkers);
  };
  const lngUpdate = (event: any, index: number) => {
    customMarkers.markers[index].lng = event.currentTarget.value;
    onChange(customMarkers);
  };

  const pickLatLng = (event: React.SyntheticEvent<HTMLInputElement>, index: number) => {
    if (!event.currentTarget.checked) {
      customMarkers.currIdx = -1;
      customMarkers.enabled = false;
      onChange(customMarkers);
    } else if (!enabled) {
      customMarkers.currIdx = index;
      customMarkers.enabled = event.currentTarget.checked;
      onChange(customMarkers);
    } else {
      appEvents.emit(AppEvents.alertWarning, ['Aleady Enabled']);
    }
  };

  const remMarker = (index: number) => {
    customMarkers.markers = customMarkers.markers.filter((marker, idx) => {
      return index !== idx;
    });
    onChange(customMarkers);
  };

  const addNew = () => {
    customMarkers.markers.push({
      lat: 0,
      lng: 0,
      query: [{ label: '', value: '' }],
      url: '',
      name: '',
    });
    onChange(customMarkers);
  };
  const queryUpdateMulti = (target: any, index: any) => {
    customMarkers.markers[index].query = [];
    target.map((ele: any) => {
      customMarkers.markers[index].query.push(ele);
    });
    onChange(customMarkers);
  };

  return (
    <div>
      {markers.map((marker: SingleCustomMarker, index: number) => {
        return (
          <Field key={index} label={'Marker ' + (index + 1)}>
            <div>
              <div className="gf-form">
                <InlineFormLabel>Latitude</InlineFormLabel>
                <Input
                  value={marker.lat}
                  onChange={(event) => {
                    latUpdate(event, index);
                  }}
                />
              </div>
              <div className="gf-form">
                <InlineFormLabel>Longitude</InlineFormLabel>
                <Input
                  value={marker.lng}
                  onChange={(event) => {
                    lngUpdate(event, index);
                  }}
                />
              </div>
              <div className="gf-form gf-form--grow">
                <InlineFormLabel width={7} className="gf-form-label">
                  Select Query
                </InlineFormLabel>
                <MultiSelect
                  options={queries}
                  onChange={(target: any) => {
                    queryUpdateMulti(target, index);
                  }}
                  value={marker.query}
                />
              </div>
              <div className="gf-form">
                <InlineFormLabel tooltip="Pick Default Latitude and Longitude from the Map with a click.">
                  Pick Lat-Lng
                </InlineFormLabel>
                <Switch
                  value={index === currIdx ? true : false}
                  onChange={(event) => {
                    pickLatLng(event, index);
                  }}
                />
              </div>
              <div className="gf-form">
                <InlineFormLabel>Remove</InlineFormLabel>
                <Button onClick={() => remMarker(index)} variant="destructive" size="md">
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </Button>
              </div>
            </div>
          </Field>
        );
      })}
      <div className="gf-form">
        <InlineFormLabel>Add New</InlineFormLabel>
        <Button onClick={addNew} variant="secondary" size="md">
          <i className="fa fa-plus" aria-hidden="true"></i>
        </Button>
      </div>
      <div className="gf-form">
        <InlineFormLabel>Reload Queries</InlineFormLabel>
        <Button onClick={dataLoad} variant="secondary" size="md">
          <i className="fa fa-refresh" aria-hidden="true"></i>
        </Button>
      </div>
    </div>
  );
}
