import React, { useEffect, useState } from 'react';
import { DecimalCount, DecimalInfo, getValueFormat } from '@grafana/data';
import { css, cx } from 'emotion';
import { stylesFactory, useTheme } from '@grafana/ui';
import Chart from 'react-apexcharts';
import { getGraphSeriesModel } from '../getGraphSeriesModel';
import { isNumber, toString, isBoolean } from 'lodash';
import { colorConverter2, timeConverter, timeConverter2 } from '../Converter';

interface Props {
  options: any;
  data: any;
  width: number;
  height: number;
  timeZone: string;
  fieldConfig: any;
  timeRange: any;
}

export const Both: React.FC<Props> = ({ options, data, width, height, timeZone, fieldConfig, timeRange }) => {
  const theme = useTheme();
  const styles = getStyles();
  const [cOptions, setCoptions] = useState({});
  const [series, setSeries]: any = useState([]);

  const getDecimalsForValue = (value: number, decimalOverride?: DecimalCount): DecimalInfo => {
    if (isNumber(decimalOverride)) {
      return { decimals: decimalOverride, scaledDecimals: null };
    }
    let dec = -Math.floor(Math.log(value) / Math.LN10) + 1;
    const magn = Math.pow(10, -dec);
    const norm = value / magn;
    let size;

    if (norm < 1.5) {
      size = 1;
    } else if (norm < 3) {
      size = 2;
      if (norm > 2.25) {
        size = 2.5;
        ++dec;
      }
    } else if (norm < 7.5) {
      size = 5;
    } else {
      size = 10;
    }

    size *= magn;

    if (value % 1 === 0) {
      dec = 0;
    }

    const decimals = Math.max(0, dec);
    const scaledDecimals = decimals - Math.floor(Math.log(size) / Math.LN10) + 2;

    return { decimals, scaledDecimals };
  };
  const toNumber = (value: any): number => {
    if (typeof value === 'number') {
      return value;
    }
    if (value === '' || value === null || value === undefined || Array.isArray(value)) {
      return NaN;
    }
    if (typeof value === 'boolean') {
      return value ? 1 : 0;
    }
    return toNumber(value);
  };

  const unitDataProducer = (value: any) => {
    if (fieldConfig.defaults.unit === undefined || fieldConfig.defaults.unit === 'none') {
      return value;
    }
    let text = toString(value);
    let numeric = toNumber(value);
    // @ts-ignore
    let prefix: string | undefined = '';
    let suffix: string | undefined = '';
    let shouldFormat = true;
    if (!isNaN(numeric)) {
      if (shouldFormat && !isBoolean(value)) {
        const { decimals, scaledDecimals } = getDecimalsForValue(value, fieldConfig.defaults.decimals || 2);
        const formatFunc = getValueFormat(fieldConfig.defaults.unit || 'none');
        const v = formatFunc(numeric, decimals, scaledDecimals, timeZone);
        text = v.text;
        suffix = v.suffix || '';
        prefix = v.prefix || '';
        if (text) {
          return prefix + '' + text + '' + suffix;
          // return { text, numeric, prefix, suffix};
        }
      }
      if (!text) {
        text = ''; // No data
      }
      return prefix + '' + text + '' + suffix;
      // return { text, numeric, prefix, suffix};
    } else {
      return prefix + '' + text + '' + suffix;
    }
  };

  useEffect(() => {
    const bigData = getGraphSeriesModel(
      data.series,
      timeZone,
      options.series || {},
      options.graph,
      options.legend,
      fieldConfig
    );
    if (bigData && bigData.length > 1) {
      const seriesData = [];
      const seriesTime: any = [];
      const seriesMain: any = {};
      let localData: any = bigData[0].data;
      localData = localData.filter((item: any) => {
        return item[1] !== null;
      });
      localData.unshift([localData[0][0] - (localData[1][0] - localData[0][0]), 0]);
      for (let i = 1; i < localData.length; i++) {
        const dt: any = timeConverter2(localData[i - 1][0], timeZone);
        seriesTime.push(dt);
        seriesMain[dt] = {
          start: timeConverter(localData[i - 1][0], timeRange, timeZone, options.overridetime),
          end: timeConverter(localData[i][0], timeRange, timeZone, options.overridetime),
        };
        seriesData.push(localData[i][1].toFixed(fieldConfig.defaults.decimals || 0));
      }
      let dataToPush: any = {};
      let dataToPush1: any = {};
      const seriesData1 = [];
      dataToPush = {
        name: bigData[0].label,
        type: 'column',
        data: seriesData,
      };
      setSeries([dataToPush]);
      let localData1: any = bigData[1].data;
      localData1 = localData1.filter((item: any) => {
        return item[1] !== null;
      });
      for (let i = 0; i < localData1.length - 1; i++) {
        seriesData1.push(localData1[i + 1][1].toFixed(fieldConfig.defaults.decimals || 0));
      }
      dataToPush1 = {
        name: bigData[1].label,
        type: 'line',
        data: seriesData1,
      };
      setSeries([dataToPush, dataToPush1]);
      const options3 = {
        chart: {
          animations: {
            enabled: false,
          },
          type: 'line',
          toolbar: {
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
        },
        dataLabels: {
          enabledOnSeries: [1],
        },
        colors: [colorConverter2(fieldConfig, localData[1][1], 1), colorConverter2(fieldConfig, localData1[1][1], 2)],
        fill: {
          opacity: 1,
        },
        legend: {
          labels: {
            colors: theme.isLight ? '#373D3F' : '#d8d9da',
          },
        },
        labels: seriesTime,
        xaxis: {
          type: 'datetime',
          categories: seriesTime,
          labels: {
            show: options.xaxisLabels,
            style: {
              colors: theme.isLight ? '#373D3F' : '#d8d9da',
            },
            datetimeUTC: timeZone === 'utc' ? true : false,
          },
          tooltip: {
            enabled: options.horizontalTooltip,
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: theme.isLight ? '#373D3F' : '#d8d9da',
            },
          },
        },
        tooltip: {
          enabled: true,
          theme: 'light',
          shared: true,
          followCursor: false,
          intersect: false,
          fillSeriesColor: false,
          custom: function ({ series, dataPointIndex }: any) {
            return (
              '<div class="apexcharts-tooltip-series-group" style="display: flex;">' +
              '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">' +
              '<div class="apexcharts-tooltip-y-group carbon-list">' +
              '<span class="apexcharts-tooltip-text-label">' +
              options.tooltipStart +
              ': ' +
              seriesMain[seriesTime[dataPointIndex]]?.start +
              '</span>' +
              '<span class="apexcharts-tooltip-text-label">' +
              options.tooltipEnd +
              ': ' +
              seriesMain[seriesTime[dataPointIndex]]?.end +
              '</span>' +
              '<span class="apexcharts-tooltip-text-value">' +
              unitDataProducer(series[0][dataPointIndex]) +
              '</span>' +
              '</div>' +
              '</div>' +
              '</div>'
            );
          },
        },
      };
      setCoptions(options3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, options]);
  return (
    <div
      className={cx(
        styles.wrapper,
        css`
          width: ${width}px;
          height: ${height}px;
        `
      )}
    >
      {series[0] && <Chart options={cOptions} series={series} type="line" width={width} height={height} />}
    </div>
  );
};

const getStyles = stylesFactory(() => {
  return {
    wrapper: css`
      position: relative;
    `,
    svg: css`
      position: absolute;
      top: 0;
      left: 0;
    `,
    textBox: css`
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
    `,
  };
});
