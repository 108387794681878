import { connectApi } from 'app/features/forecasting/api/model';
import { ModelOptions, ModelOptionsList, Payload, PayloadI, allSettings } from '../constants';
import { Model, apiTypes } from 'app/features/forecasting/constants';
import { cloneDeep } from 'lodash';

enum PayloadType {
  Normal = 'normal',
  Ensemble = 'ensemble',
  Retrain = 'retrain',
}

export function getPayload(
  payloadName: string,
  model: Model,
  modelOptions: ModelOptions,
  modelOptionsList: ModelOptionsList,
  jsonString: string,
  testDataJsonString: string,
  actionForNotNullValues: string,
  retrainType?: string,
  exogJsonData?: string
) {
  var payload: any = {};
  var payloadType = payloadName as PayloadType;

  // const jsonString: string = convertSeriesToJSONString(dataObj.series[modelOptions.trainingDataframe]);
  // const testDataJsonString: string = modelOptions.dataSettings.testingData
  //   ? convertSeriesToJSONString(dataObj.series[modelOptions.dataSettings.testingData])
  //   : '';
  // const exog: boolean = modelOptions.exog.variables.length !== 0;
  // const exogJsonData: string = exog ? convertSeriesToJSONString(dataObj.series[modelOptions.exog.dataFrame!]) : '';
  const algoCheckActiveList: string[] = modelOptionsList.algoCheckList
    .filter((ele: any) => ele.active)
    .map((ele) => ele.name);
  if (payloadType === PayloadType.Normal) {
    payload = {
      output:
        '..forecasting-training-metrics.children...forecasting-test-metrics.children...forecasting-plots.children...forecasting-plots-future.children...forecasting-exception-modal.is_open...forecasting-exception-modal-content.children..',
      outputs: [
        { id: 'forecasting-training-metrics', property: 'children' },
        { id: 'forecasting-test-metrics', property: 'children' },
        { id: 'forecasting-plots', property: 'children' },
        { id: 'forecasting-plots-future', property: 'children' },
        { id: 'forecasting-exception-modal', property: 'is_open' },
        { id: 'forecasting-exception-modal-content', property: 'children' },
      ],
      inputs: [
        { id: 'forecasting-train-btn', property: 'n_clicks', value: 1 },
        { id: 'forecasting-exception-modal-close', property: 'n_clicks', value: 0 },
      ],
      changedPropIds: ['forecasting-train-btn.n_clicks'],
      state: [
        { id: 'forecasting-select-file', property: 'value', value: '' },
        { id: 'forecasting-select-target', property: 'value', value: modelOptions.targetColumn },
        { id: 'forecasting-select-features', property: 'value', value: modelOptions.otherFeature },
        { id: 'forecasting-select-exog', property: 'value', value: modelOptions.exog.variables },
        { id: 'forecasting-select-exog-data', property: 'value', value: '' },
        { id: 'forecasting-select-algorithm', property: 'value', value: modelOptions.algorithm },
        {
          id: 'forecasting-param-table',
          property: 'children',
          value: {
            props: {
              data: modelOptionsList.settingValues,
              columns: [
                { id: 'Parameter', name: 'Parameter' },
                { id: 'Value', name: 'Value' },
              ],
              editable: true,
              style_table: { overflowX: 'scroll', overflowY: 'scroll', height: 120, minHeight: '200px' },
              style_data: { backgroundColor: 'white' },
              style_header: { backgroundColor: '#014486', color: 'white' },
              style_cell_conditional: [{ textAlign: 'center', 'font-family': 'Salesforce Sans' }],
              style_header_conditional: [{ textAlign: 'center', 'font-family': 'Salesforce Sans' }],
              derived_virtual_data: modelOptionsList.settingValues,
              derived_virtual_indices: modelOptionsList.settingValues.map((_: any, index: number) => index),
              derived_virtual_row_ids: modelOptionsList.settingValues.map((_: any) => null),
              derived_viewport_data: modelOptionsList.settingValues,
              derived_viewport_indices: modelOptionsList.settingValues.map((_: any, index: number) => index),
              derived_viewport_row_ids: modelOptionsList.settingValues.map((_: any) => null),
              derived_virtual_selected_rows: [],
              derived_virtual_selected_row_ids: [],
              derived_viewport_selected_columns: [],
              derived_viewport_selected_rows: [],
              derived_viewport_selected_row_ids: [],
            },
            type: 'DataTable',
            namespace: 'dash_table',
          },
        },
        { id: 'forecasting-training-slider', property: 'value', value: modelOptions.dataSettings.split },
        { id: 'forecasting-select-test-file', property: 'value', value: '' },
        { id: 'forecasting-file-radio', property: 'value', value: modelOptions.dataSettings.Type },
        { id: 'forecasting-prediction-interval', property: 'value', value: modelOptions.interval },
        { id: 'forecasting-model-radio', property: 'value', value: 'new' },
        { id: 'forecasting-model-name', property: 'value', value: modelOptions.name },
        { id: 'forecasting-retrain-file', property: 'value' },
        { id: 'forecasting-json-data', property: 'value', value: jsonString },
        { id: 'forecasting-json-test-data', property: 'value', value: testDataJsonString },
        { id: 'forecasting-json-exog-data', property: 'value', value: exogJsonData },
        { id: 'forecasting-data-split-order', property: 'value', value: modelOptions.dataSettings.splitOrder },
        { id: 'forecasting-data-fill', property: 'value', value: actionForNotNullValues },
        { id: 'forecasting-data-roundoff', property: 'value', value: 4 },
        { id: 'forecasting-retrain-retain-data', property: 'value', value: retrainType },
      ],
    };
  } else if (payloadType === PayloadType.Ensemble) {
    payload = {
      output:
        '..ensemble-forecast-plots.children...leaderboard-metrics.children...ensemble-leaderboard-exception-modal.is_open...ensemble-leaderboard-exception-modal-content.children...ensemble-forecasting-plots-future.children...ensemble-data-plots.children..',
      outputs: [
        { id: 'ensemble-forecast-plots', property: 'children' },
        { id: 'leaderboard-metrics', property: 'children' },
        { id: 'ensemble-leaderboard-exception-modal', property: 'is_open' },
        { id: 'ensemble-leaderboard-exception-modal-content', property: 'children' },
        { id: 'ensemble-forecasting-plots-future', property: 'children' },
        {
          id: 'ensemble-data-plots',
          property: 'children',
        },
      ],
      inputs: [
        { id: 'ensemble-leaderboard-btn', property: 'n_clicks', value: 1 },
        { id: 'ensemble-leaderboard-exception-modal-close', property: 'n_clicks', value: 0 },
      ],
      changedPropIds: ['ensemble-leaderboard-btn.n_clicks'],
      state: [
        { id: 'ensemble-select-file', property: 'value', value: '' },
        { id: 'ensemble-select-test-file', property: 'value', value: '' },
        { id: 'ensemble-select-target', property: 'value', value: modelOptions.targetColumn },
        { id: 'ensemble-select-features', property: 'value', value: modelOptions.otherFeature },
        { id: 'ensemble-training-slider', property: 'value', value: modelOptions.dataSettings.split },
        { id: 'ensemble-file-radio', property: 'value', value: modelOptions.dataSettings.Type },
        { id: 'ensemble-select-exog', property: 'value', value: modelOptions.exog.variables },
        { id: 'ensemble-select-exog-data', property: 'value', value: '' },
        { id: 'ensemble-forecast-interval', property: 'value', value: modelOptions.interval },
        { id: 'ensemble-model-name', property: 'value', value: modelOptions.name },
        { id: 'forecaster-checklist', property: 'value', value: algoCheckActiveList },
        {
          id: 'ensemble-radio-metric-base',
          property: 'value',
          value: modelOptions.ensemble.baseMetric,
        },
        {
          id: 'ensemble-radio-metric-combiner',
          property: 'value',
          value: modelOptions.ensemble.ensembleCombinerMetric,
        },

        { id: 'ensemble-json-data', property: 'value', value: jsonString },
        { id: 'ensemble-json-test-data', property: 'value', value: testDataJsonString },
        { id: 'ensemble-json-exog-data', property: 'value', value: exogJsonData },
        { id: 'ensemble-data-split-order', property: 'value', value: modelOptions.dataSettings.splitOrder },
        { id: 'ensemble-data-fill', property: 'value', value: actionForNotNullValues },
        { id: 'ensemble-data-roundoff', property: 'value', value: 4 },
      ],
    };
  } else if (payloadType === PayloadType.Retrain) {
    payload = {
      output:
        '..forecasting-training-metrics.children...forecasting-test-metrics.children...forecasting-plots.children...forecasting-plots-future.children...forecasting-exception-modal.is_open...forecasting-exception-modal-content.children..',
      outputs: [
        { id: 'forecasting-training-metrics', property: 'children' },
        { id: 'forecasting-test-metrics', property: 'children' },
        { id: 'forecasting-plots', property: 'children' },
        { id: 'forecasting-plots-future', property: 'children' },
        { id: 'forecasting-exception-modal', property: 'is_open' },
        { id: 'forecasting-exception-modal-content', property: 'children' },
      ],
      inputs: [
        { id: 'forecasting-train-btn', property: 'n_clicks', value: 1 },
        { id: 'forecasting-exception-modal-close', property: 'n_clicks', value: 0 },
      ],
      changedPropIds: ['forecasting-train-btn.n_clicks'],
      state: [
        { id: 'forecasting-select-file', property: 'value', value: null },
        { id: 'forecasting-select-target', property: 'value', value: modelOptions.targetColumn },
        { id: 'forecasting-select-features', property: 'value', value: modelOptions.otherFeature },
        { id: 'forecasting-select-exog', property: 'value', value: modelOptions.exog.variables },
        { id: 'forecasting-select-exog-data', property: 'value' },
        { id: 'forecasting-select-algorithm', property: 'value', value: modelOptions.algorithm },
        {
          id: 'forecasting-param-table',
          property: 'children',
          value: {
            props: {
              data: modelOptionsList.settingValues,
              columns: [
                { id: 'Parameter', name: 'Parameter' },
                { id: 'Value', name: 'Value' },
              ],
              editable: true,
              style_table: { overflowX: 'scroll', overflowY: 'scroll', height: 120, minHeight: '200px' },
              style_data: { backgroundColor: 'white' },
              style_header: { backgroundColor: '#014486', color: 'white' },
              style_cell_conditional: [{ textAlign: 'center', 'font-family': 'Salesforce Sans' }],
              style_header_conditional: [{ textAlign: 'center', 'font-family': 'Salesforce Sans' }],
              derived_virtual_data: modelOptionsList.settingValues,
              derived_virtual_indices: modelOptionsList.settingValues.map((_: any, index: number) => index),
              derived_virtual_row_ids: modelOptionsList.settingValues.map(() => null),
              derived_viewport_data: modelOptionsList.settingValues,
              derived_viewport_indices: modelOptionsList.settingValues.map((_: any, index: number) => index),
              derived_viewport_row_ids: modelOptionsList.settingValues.map(() => null),
              derived_virtual_selected_rows: [],
              derived_virtual_selected_row_ids: [],
              derived_viewport_selected_columns: [],
              derived_viewport_selected_rows: [],
              derived_viewport_selected_row_ids: [],
            },
            type: 'DataTable',
            namespace: 'dash_table',
          },
        },
        { id: 'forecasting-training-slider', property: 'value', value: modelOptions.dataSettings.split },
        { id: 'forecasting-select-test-file', property: 'value' },
        { id: 'forecasting-file-radio', property: 'value', value: modelOptions.dataSettings.Type },
        { id: 'forecasting-prediction-interval', property: 'value', value: modelOptions.interval },
        { id: 'forecasting-model-radio', property: 'value', value: 'old' },
        { id: 'forecasting-model-name', property: 'value', value: '' },
        {
          id: 'forecasting-retrain-file',
          property: 'value',
          value: `${model.info.merlionModelName}`,
        },
        {
          id: 'forecasting-json-data',
          property: 'value',
          value: jsonString,
        },
        { id: 'forecasting-json-test-data', property: 'value', value: testDataJsonString },
        { id: 'forecasting-json-exog-data', property: 'value', value: model.info.exog_data },
        { id: 'forecasting-data-split-order', property: 'value', value: modelOptions.dataSettings.splitOrder },
        { id: 'forecasting-data-fill', property: 'value', value: actionForNotNullValues },
        { id: 'forecasting-data-roundoff', property: 'value', value: 4 },
        { id: 'forecasting-retrain-retain-data', property: 'value', value: retrainType },
      ],
    };
  }
  return payload;
}

export async function getNormalPayloadFromEnsembleSettings(
  modelOptions: ModelOptions,
  modelOptionsList: ModelOptionsList,
  selectedAlgo: string
) {
  const { error, data } = await connectApi(
    {
      ...Payload[PayloadI.AlgoSettings],
      inputs: [{ id: 'forecasting-select-algorithm', property: 'value', value: selectedAlgo }],
    },
    apiTypes.getAlgoSettings
  );
  if (!error) {
    const newSettingList = data.response['forecasting-param-table'].children.props.data;

    modelOptionsList.settingList = newSettingList.map((algo: any) => allSettings[algo.Parameter]);
    modelOptionsList.settingValues = newSettingList;
  }

  const payload = {
    output:
      '..forecasting-training-metrics.children...forecasting-test-metrics.children...forecasting-plots.children...forecasting-plots-future.children...forecasting-exception-modal.is_open...forecasting-exception-modal-content.children..',
    outputs: [
      { id: 'forecasting-training-metrics', property: 'children' },
      { id: 'forecasting-test-metrics', property: 'children' },
      { id: 'forecasting-plots', property: 'children' },
      { id: 'forecasting-plots-future', property: 'children' },
      { id: 'forecasting-exception-modal', property: 'is_open' },
      { id: 'forecasting-exception-modal-content', property: 'children' },
    ],
    inputs: [
      { id: 'forecasting-train-btn', property: 'n_clicks', value: 1 },
      { id: 'forecasting-exception-modal-close', property: 'n_clicks', value: 0 },
    ],
    changedPropIds: ['forecasting-train-btn.n_clicks'],
    state: [
      { id: 'forecasting-select-file', property: 'value', value: '' },
      { id: 'forecasting-select-target', property: 'value', value: modelOptions.targetColumn },
      { id: 'forecasting-select-features', property: 'value', value: modelOptions.otherFeature },
      { id: 'forecasting-select-exog', property: 'value', value: modelOptions.exog.variables },
      { id: 'forecasting-select-exog-data', property: 'value', value: '' },
      { id: 'forecasting-select-algorithm', property: 'value', value: selectedAlgo },
      {
        id: 'forecasting-param-table',
        property: 'children',
        value: {
          props: {
            data: modelOptionsList.settingValues,
            columns: [
              { id: 'Parameter', name: 'Parameter' },
              { id: 'Value', name: 'Value' },
            ],
            editable: true,
            style_table: { overflowX: 'scroll', overflowY: 'scroll', height: 120, minHeight: '200px' },
            style_data: { backgroundColor: 'white' },
            style_header: { backgroundColor: '#014486', color: 'white' },
            style_cell_conditional: [{ textAlign: 'center', 'font-family': 'Salesforce Sans' }],
            style_header_conditional: [{ textAlign: 'center', 'font-family': 'Salesforce Sans' }],
            derived_virtual_data: modelOptionsList.settingValues,
            derived_virtual_indices: modelOptionsList.settingValues.map((_: any, index: number) => index),
            derived_virtual_row_ids: modelOptionsList.settingValues.map((_: any) => null),
            derived_viewport_data: modelOptionsList.settingValues,
            derived_viewport_indices: modelOptionsList.settingValues.map((_: any, index: number) => index),
            derived_viewport_row_ids: modelOptionsList.settingValues.map((_: any) => null),
            derived_virtual_selected_rows: [],
            derived_virtual_selected_row_ids: [],
            derived_viewport_selected_columns: [],
            derived_viewport_selected_rows: [],
            derived_viewport_selected_row_ids: [],
          },
          type: 'DataTable',
          namespace: 'dash_table',
        },
      },
      { id: 'forecasting-training-slider', property: 'value', value: modelOptions.dataSettings.split },
      { id: 'forecasting-select-test-file', property: 'value', value: '' },
      { id: 'forecasting-file-radio', property: 'value', value: modelOptions.dataSettings.Type },
      { id: 'forecasting-prediction-interval', property: 'value', value: modelOptions.interval },
      { id: 'forecasting-model-radio', property: 'value', value: 'new' },
      { id: 'forecasting-model-name', property: 'value', value: modelOptions.name },
      { id: 'forecasting-retrain-file', property: 'value' },
      { id: 'forecasting-json-data', property: 'value', value: '' },
      { id: 'forecasting-json-test-data', property: 'value', value: '' },
      { id: 'forecasting-json-exog-data', property: 'value', value: '' },
      { id: 'forecasting-data-split-order', property: 'value', value: modelOptions.dataSettings.splitOrder },
      { id: 'forecasting-data-fill', property: 'value', value: 'none' },
    ],
  };
  return payload;
}

export function updateMetricsFromEnsembleToNormal(jsonData: any, selectedAlgo: string) {
  let data = cloneDeep(jsonData);

  // Find the metrics of the selected algorithm from the leaderboard-metrics
  let selectedMetrics = data['leaderboard-metrics'].children.props.data.find(
    (item: any) => item.Model === selectedAlgo
  );

  // If the selected algorithm is found in the leaderboard-metrics
  if (selectedMetrics) {
    // Update the forecasting-test-metrics.children.props.data with the metrics of the selected algorithm
    data['forecasting-test-metrics'].children.props.data = [selectedMetrics];
  } else {
    console.log(`The selected algorithm ${selectedAlgo} is not found in the leaderboard-metrics.`);
  }

  // Return the updated JSON data
  return data;
}
