import { SingleStatBaseOptions } from '@grafana/ui';
import { ReducerID, standardEditorsRegistry, PanelOptionsEditorBuilder } from '@grafana/data';

// Structure copied from angular
export interface MatterportOptions extends SingleStatBaseOptions {
  sceneList: any;
  tagList: any;
  reloader: number;
  lock: boolean;
  sid: string;
  cameraP: number;
  cameraX: number;
  cameraY: number;
  // newScene: string;
  // url: string;
  // x: number;
  // y: number;
  // z: number;
}

export function addStandardDataReduceOptions(
  builder: PanelOptionsEditorBuilder<SingleStatBaseOptions>,
  includeOrientation = true
) {
  builder.addCustomEditor({
    id: 'reduceOptions.calcs',
    path: 'reduceOptions.calcs',
    name: 'Value',
    description: 'Choose a reducer function / calculation',
    editor: standardEditorsRegistry.get('stats-picker').editor as any,
    defaultValue: [ReducerID.mean],
    // Hides it when all values mode is on
    // showIf: currentConfig => currentConfig.reduceOptions.values === false,
  });
}

import { Vector3 } from 'three';

export enum Category {
  Sofa = 'Sofa',
  EndTable = 'End Table',
  ArmChair = 'Arm Chair',
  CoffeTable = 'Coffee Table',
}

export type ItemDesc = {
  name: string;
  url: string;
  position: Vector3;
  rotation: Vector3;
  scale: Vector3;
  categories: Category[];
};

export type SlotDesc = {
  name: string;
  category: Category;
};

export type Instance<T> = {
  desc: T;
  sceneObject: any;
};
