// This file was autogenerated for icons

import { cacheStore } from 'react-inlinesvg';

export let cacheInitialized = false;
export let iconRoot = 'public/img/icons';

function cacheItem(content: string, path: string) {
  cacheStore[iconRoot + path] = { content, status: 'loaded', queue: [] };
}

import u1001 from '!!raw-loader!../../../../../public/img/icons/default/2fa.svg';
import u1002 from '!!raw-loader!../../../../../public/img/icons/default/3d-cube-sphere.svg';
import u1003 from '!!raw-loader!../../../../../public/img/icons/default/3d-rotate.svg';
import u1004 from '!!raw-loader!../../../../../public/img/icons/default/a-b-off.svg';
import u1005 from '!!raw-loader!../../../../../public/img/icons/default/a-b.svg';
import u1006 from '!!raw-loader!../../../../../public/img/icons/default/abacus.svg';
import u1007 from '!!raw-loader!../../../../../public/img/icons/default/access-point-off.svg';
import u1008 from '!!raw-loader!../../../../../public/img/icons/default/access-point.svg';
import u1009 from '!!raw-loader!../../../../../public/img/icons/default/accessible-off.svg';
import u1010 from '!!raw-loader!../../../../../public/img/icons/default/accessible.svg';
import u1011 from '!!raw-loader!../../../../../public/img/icons/default/activity-heartbeat.svg';
import u1012 from '!!raw-loader!../../../../../public/img/icons/default/activity.svg';
import u1013 from '!!raw-loader!../../../../../public/img/icons/default/ad-2.svg';
import u1014 from '!!raw-loader!../../../../../public/img/icons/default/ad.svg';
import u1015 from '!!raw-loader!../../../../../public/img/icons/default/address-book.svg';
import u1016 from '!!raw-loader!../../../../../public/img/icons/default/adjustments-alt.svg';
import u1017 from '!!raw-loader!../../../../../public/img/icons/default/adjustments-horizontal.svg';
import u1018 from '!!raw-loader!../../../../../public/img/icons/default/adjustments-off.svg';
import u1019 from '!!raw-loader!../../../../../public/img/icons/default/adjustments.svg';
import u1020 from '!!raw-loader!../../../../../public/img/icons/default/aerial-lift.svg';
import u1021 from '!!raw-loader!../../../../../public/img/icons/default/affiliate.svg';
import u1022 from '!!raw-loader!../../../../../public/img/icons/default/alarm-off.svg';
import u1023 from '!!raw-loader!../../../../../public/img/icons/default/alarm.svg';
import u1024 from '!!raw-loader!../../../../../public/img/icons/default/album.svg';
import u1025 from '!!raw-loader!../../../../../public/img/icons/default/alert-circle.svg';
import u1026 from '!!raw-loader!../../../../../public/img/icons/default/alert-octagon.svg';
import u1027 from '!!raw-loader!../../../../../public/img/icons/default/alert-triangle.svg';
import u1028 from '!!raw-loader!../../../../../public/img/icons/default/alien.svg';
import u1029 from '!!raw-loader!../../../../../public/img/icons/default/align-center.svg';
import u1030 from '!!raw-loader!../../../../../public/img/icons/default/align-justified.svg';
import u1031 from '!!raw-loader!../../../../../public/img/icons/default/align-left.svg';
import u1032 from '!!raw-loader!../../../../../public/img/icons/default/align-right.svg';
import u1033 from '!!raw-loader!../../../../../public/img/icons/default/ambulance.svg';
import u1034 from '!!raw-loader!../../../../../public/img/icons/default/anchor.svg';
import u1035 from '!!raw-loader!../../../../../public/img/icons/default/angle.svg';
import u1036 from '!!raw-loader!../../../../../public/img/icons/default/antenna-bars-1.svg';
import u1037 from '!!raw-loader!../../../../../public/img/icons/default/antenna-bars-2.svg';
import u1038 from '!!raw-loader!../../../../../public/img/icons/default/antenna-bars-3.svg';
import u1039 from '!!raw-loader!../../../../../public/img/icons/default/antenna-bars-4.svg';
import u1040 from '!!raw-loader!../../../../../public/img/icons/default/antenna-bars-5.svg';
import u1041 from '!!raw-loader!../../../../../public/img/icons/default/antenna-bars-off.svg';
import u1042 from '!!raw-loader!../../../../../public/img/icons/default/antenna.svg';
import u1043 from '!!raw-loader!../../../../../public/img/icons/default/aperture.svg';
import u1044 from '!!raw-loader!../../../../../public/img/icons/default/api-app-off.svg';
import u1045 from '!!raw-loader!../../../../../public/img/icons/default/api-app.svg';
import u1046 from '!!raw-loader!../../../../../public/img/icons/default/api.svg';
import u1047 from '!!raw-loader!../../../../../public/img/icons/default/app-window.svg';
import u1048 from '!!raw-loader!../../../../../public/img/icons/default/apple.svg';
import u1049 from '!!raw-loader!../../../../../public/img/icons/default/apps-off.svg';
import u1050 from '!!raw-loader!../../../../../public/img/icons/default/apps.svg';
import u1051 from '!!raw-loader!../../../../../public/img/icons/default/archive-off.svg';
import u1052 from '!!raw-loader!../../../../../public/img/icons/default/archive.svg';
import u1053 from '!!raw-loader!../../../../../public/img/icons/default/armchair-2.svg';
import u1054 from '!!raw-loader!../../../../../public/img/icons/default/armchair.svg';
import u1055 from '!!raw-loader!../../../../../public/img/icons/default/arrow-autofit-content.svg';
import u1056 from '!!raw-loader!../../../../../public/img/icons/default/arrow-autofit-down.svg';
import u1057 from '!!raw-loader!../../../../../public/img/icons/default/arrow-autofit-height.svg';
import u1058 from '!!raw-loader!../../../../../public/img/icons/default/arrow-autofit-left.svg';
import u1059 from '!!raw-loader!../../../../../public/img/icons/default/arrow-autofit-right.svg';
import u1060 from '!!raw-loader!../../../../../public/img/icons/default/arrow-autofit-up.svg';
import u1061 from '!!raw-loader!../../../../../public/img/icons/default/arrow-autofit-width.svg';
import u1062 from '!!raw-loader!../../../../../public/img/icons/default/arrow-back-up.svg';
import u1063 from '!!raw-loader!../../../../../public/img/icons/default/arrow-back.svg';
import u1064 from '!!raw-loader!../../../../../public/img/icons/default/arrow-bar-down.svg';
import u1065 from '!!raw-loader!../../../../../public/img/icons/default/arrow-bar-left.svg';
import u1066 from '!!raw-loader!../../../../../public/img/icons/default/arrow-bar-right.svg';
import u1067 from '!!raw-loader!../../../../../public/img/icons/default/arrow-bar-to-down.svg';
import u1068 from '!!raw-loader!../../../../../public/img/icons/default/arrow-bar-to-left.svg';
import u1069 from '!!raw-loader!../../../../../public/img/icons/default/arrow-bar-to-right.svg';
import u1070 from '!!raw-loader!../../../../../public/img/icons/default/arrow-bar-to-up.svg';
import u1071 from '!!raw-loader!../../../../../public/img/icons/default/arrow-bar-up.svg';
import u1072 from '!!raw-loader!../../../../../public/img/icons/default/arrow-bear-left-2.svg';
import u1073 from '!!raw-loader!../../../../../public/img/icons/default/arrow-bear-left.svg';
import u1074 from '!!raw-loader!../../../../../public/img/icons/default/arrow-bear-right-2.svg';
import u1075 from '!!raw-loader!../../../../../public/img/icons/default/arrow-bear-right.svg';
import u1076 from '!!raw-loader!../../../../../public/img/icons/default/arrow-big-down-line.svg';
import u1077 from '!!raw-loader!../../../../../public/img/icons/default/arrow-big-down-lines.svg';
import u1078 from '!!raw-loader!../../../../../public/img/icons/default/arrow-big-down.svg';
import u1079 from '!!raw-loader!../../../../../public/img/icons/default/arrow-big-left-line.svg';
import u1080 from '!!raw-loader!../../../../../public/img/icons/default/arrow-big-left-lines.svg';
import u1081 from '!!raw-loader!../../../../../public/img/icons/default/arrow-big-left.svg';
import u1082 from '!!raw-loader!../../../../../public/img/icons/default/arrow-big-right-line.svg';
import u1083 from '!!raw-loader!../../../../../public/img/icons/default/arrow-big-right-lines.svg';
import u1084 from '!!raw-loader!../../../../../public/img/icons/default/arrow-big-right.svg';
import u1085 from '!!raw-loader!../../../../../public/img/icons/default/arrow-big-top.svg';
import u1086 from '!!raw-loader!../../../../../public/img/icons/default/arrow-big-up-line.svg';
import u1087 from '!!raw-loader!../../../../../public/img/icons/default/arrow-big-up-lines.svg';
import u1088 from '!!raw-loader!../../../../../public/img/icons/default/arrow-bottom-bar.svg';
import u1089 from '!!raw-loader!../../../../../public/img/icons/default/arrow-bottom-circle.svg';
import u1090 from '!!raw-loader!../../../../../public/img/icons/default/arrow-bottom-square.svg';
import u1091 from '!!raw-loader!../../../../../public/img/icons/default/arrow-bottom-tail.svg';
import u1092 from '!!raw-loader!../../../../../public/img/icons/default/arrow-curve-left.svg';
import u1093 from '!!raw-loader!../../../../../public/img/icons/default/arrow-curve-right.svg';
import u1094 from '!!raw-loader!../../../../../public/img/icons/default/arrow-down-circle.svg';
import u1095 from '!!raw-loader!../../../../../public/img/icons/default/arrow-down-left-circle.svg';
import u1096 from '!!raw-loader!../../../../../public/img/icons/default/arrow-down-left.svg';
import u1097 from '!!raw-loader!../../../../../public/img/icons/default/arrow-down-right-circle.svg';
import u1098 from '!!raw-loader!../../../../../public/img/icons/default/arrow-down-right.svg';
import u1099 from '!!raw-loader!../../../../../public/img/icons/default/arrow-down.svg';
import u1100 from '!!raw-loader!../../../../../public/img/icons/default/arrow-fork.svg';
import u1101 from '!!raw-loader!../../../../../public/img/icons/default/arrow-forward-up.svg';
import u1102 from '!!raw-loader!../../../../../public/img/icons/default/arrow-forward.svg';
import u1103 from '!!raw-loader!../../../../../public/img/icons/default/arrow-left-bar.svg';
import u1104 from '!!raw-loader!../../../../../public/img/icons/default/arrow-left-circle.svg';
import u1105 from '!!raw-loader!../../../../../public/img/icons/default/arrow-left-right.svg';
import u1106 from '!!raw-loader!../../../../../public/img/icons/default/arrow-left-square.svg';
import u1107 from '!!raw-loader!../../../../../public/img/icons/default/arrow-left-tail.svg';
import u1108 from '!!raw-loader!../../../../../public/img/icons/default/arrow-left.svg';
import u1109 from '!!raw-loader!../../../../../public/img/icons/default/arrow-loop-left-2.svg';
import u1110 from '!!raw-loader!../../../../../public/img/icons/default/arrow-loop-left.svg';
import u1111 from '!!raw-loader!../../../../../public/img/icons/default/arrow-loop-right-2.svg';
import u1112 from '!!raw-loader!../../../../../public/img/icons/default/arrow-loop-right.svg';
import u1113 from '!!raw-loader!../../../../../public/img/icons/default/arrow-merge.svg';
import u1114 from '!!raw-loader!../../../../../public/img/icons/default/arrow-narrow-down.svg';
import u1115 from '!!raw-loader!../../../../../public/img/icons/default/arrow-narrow-left.svg';
import u1116 from '!!raw-loader!../../../../../public/img/icons/default/arrow-narrow-right.svg';
import u1117 from '!!raw-loader!../../../../../public/img/icons/default/arrow-narrow-up.svg';
import u1118 from '!!raw-loader!../../../../../public/img/icons/default/arrow-ramp-left-2.svg';
import u1119 from '!!raw-loader!../../../../../public/img/icons/default/arrow-ramp-left-3.svg';
import u1120 from '!!raw-loader!../../../../../public/img/icons/default/arrow-ramp-left.svg';
import u1121 from '!!raw-loader!../../../../../public/img/icons/default/arrow-ramp-right-2.svg';
import u1122 from '!!raw-loader!../../../../../public/img/icons/default/arrow-ramp-right-3.svg';
import u1123 from '!!raw-loader!../../../../../public/img/icons/default/arrow-ramp-right.svg';
import u1124 from '!!raw-loader!../../../../../public/img/icons/default/arrow-right-bar.svg';
import u1125 from '!!raw-loader!../../../../../public/img/icons/default/arrow-right-circle.svg';
import u1126 from '!!raw-loader!../../../../../public/img/icons/default/arrow-right-square.svg';
import u1127 from '!!raw-loader!../../../../../public/img/icons/default/arrow-right-tail.svg';
import u1128 from '!!raw-loader!../../../../../public/img/icons/default/arrow-right.svg';
import u1129 from '!!raw-loader!../../../../../public/img/icons/default/arrow-rotary-first-left.svg';
import u1130 from '!!raw-loader!../../../../../public/img/icons/default/arrow-rotary-first-right.svg';
import u1131 from '!!raw-loader!../../../../../public/img/icons/default/arrow-rotary-last-left.svg';
import u1132 from '!!raw-loader!../../../../../public/img/icons/default/arrow-rotary-last-right.svg';
import u1133 from '!!raw-loader!../../../../../public/img/icons/default/arrow-rotary-left.svg';
import u1134 from '!!raw-loader!../../../../../public/img/icons/default/arrow-rotary-right.svg';
import u1135 from '!!raw-loader!../../../../../public/img/icons/default/arrow-rotary-straight.svg';
import u1136 from '!!raw-loader!../../../../../public/img/icons/default/arrow-sharp-turn-left.svg';
import u1137 from '!!raw-loader!../../../../../public/img/icons/default/arrow-sharp-turn-right.svg';
import u1138 from '!!raw-loader!../../../../../public/img/icons/default/arrow-top-bar.svg';
import u1139 from '!!raw-loader!../../../../../public/img/icons/default/arrow-top-circle.svg';
import u1140 from '!!raw-loader!../../../../../public/img/icons/default/arrow-top-square.svg';
import u1141 from '!!raw-loader!../../../../../public/img/icons/default/arrow-top-tail.svg';
import u1142 from '!!raw-loader!../../../../../public/img/icons/default/arrow-up-circle.svg';
import u1143 from '!!raw-loader!../../../../../public/img/icons/default/arrow-up-left-circle.svg';
import u1144 from '!!raw-loader!../../../../../public/img/icons/default/arrow-up-left.svg';
import u1145 from '!!raw-loader!../../../../../public/img/icons/default/arrow-up-right-circle.svg';
import u1146 from '!!raw-loader!../../../../../public/img/icons/default/arrow-up-right.svg';
import u1147 from '!!raw-loader!../../../../../public/img/icons/default/arrow-up.svg';
import u1148 from '!!raw-loader!../../../../../public/img/icons/default/arrow-wave-left-down.svg';
import u1149 from '!!raw-loader!../../../../../public/img/icons/default/arrow-wave-left-up.svg';
import u1150 from '!!raw-loader!../../../../../public/img/icons/default/arrow-wave-right-down.svg';
import u1151 from '!!raw-loader!../../../../../public/img/icons/default/arrow-wave-right-up.svg';
import u1152 from '!!raw-loader!../../../../../public/img/icons/default/arrows-cross.svg';
import u1153 from '!!raw-loader!../../../../../public/img/icons/default/arrows-diagonal-2.svg';
import u1154 from '!!raw-loader!../../../../../public/img/icons/default/arrows-diagonal-minimize-2.svg';
import u1155 from '!!raw-loader!../../../../../public/img/icons/default/arrows-diagonal-minimize.svg';
import u1156 from '!!raw-loader!../../../../../public/img/icons/default/arrows-diagonal.svg';
import u1157 from '!!raw-loader!../../../../../public/img/icons/default/arrows-double-ne-sw.svg';
import u1158 from '!!raw-loader!../../../../../public/img/icons/default/arrows-double-nw-se.svg';
import u1159 from '!!raw-loader!../../../../../public/img/icons/default/arrows-double-se-nw.svg';
import u1160 from '!!raw-loader!../../../../../public/img/icons/default/arrows-double-sw-ne.svg';
import u1161 from '!!raw-loader!../../../../../public/img/icons/default/arrows-down-up.svg';
import u1162 from '!!raw-loader!../../../../../public/img/icons/default/arrows-down.svg';
import u1163 from '!!raw-loader!../../../../../public/img/icons/default/arrows-horizontal.svg';
import u1164 from '!!raw-loader!../../../../../public/img/icons/default/arrows-join-2.svg';
import u1165 from '!!raw-loader!../../../../../public/img/icons/default/arrows-join.svg';
import u1166 from '!!raw-loader!../../../../../public/img/icons/default/arrows-left-down.svg';
import u1167 from '!!raw-loader!../../../../../public/img/icons/default/arrows-left-right.svg';
import u1168 from '!!raw-loader!../../../../../public/img/icons/default/arrows-left.svg';
import u1169 from '!!raw-loader!../../../../../public/img/icons/default/arrows-maximize.svg';
import u1170 from '!!raw-loader!../../../../../public/img/icons/default/arrows-minimize.svg';
import u1171 from '!!raw-loader!../../../../../public/img/icons/default/arrows-random.svg';
import u1172 from '!!raw-loader!../../../../../public/img/icons/default/arrows-right-down.svg';
import u1173 from '!!raw-loader!../../../../../public/img/icons/default/arrows-right-left.svg';
import u1174 from '!!raw-loader!../../../../../public/img/icons/default/arrows-right.svg';
import u1175 from '!!raw-loader!../../../../../public/img/icons/default/arrows-shuffle-2.svg';
import u1176 from '!!raw-loader!../../../../../public/img/icons/default/arrows-shuffle.svg';
import u1177 from '!!raw-loader!../../../../../public/img/icons/default/arrows-sort.svg';
import u1178 from '!!raw-loader!../../../../../public/img/icons/default/arrows-split-2.svg';
import u1179 from '!!raw-loader!../../../../../public/img/icons/default/arrows-split.svg';
import u1180 from '!!raw-loader!../../../../../public/img/icons/default/arrows-up-down.svg';
import u1181 from '!!raw-loader!../../../../../public/img/icons/default/arrows-up-left.svg';
import u1182 from '!!raw-loader!../../../../../public/img/icons/default/arrows-up-right.svg';
import u1183 from '!!raw-loader!../../../../../public/img/icons/default/arrows-up.svg';
import u1184 from '!!raw-loader!../../../../../public/img/icons/default/arrows-vertical.svg';
import u1185 from '!!raw-loader!../../../../../public/img/icons/default/artboard-off.svg';
import u1186 from '!!raw-loader!../../../../../public/img/icons/default/artboard.svg';
import u1187 from '!!raw-loader!../../../../../public/img/icons/default/aspect-ratio-off.svg';
import u1188 from '!!raw-loader!../../../../../public/img/icons/default/aspect-ratio.svg';
import u1189 from '!!raw-loader!../../../../../public/img/icons/default/asterisk-simple.svg';
import u1190 from '!!raw-loader!../../../../../public/img/icons/default/asterisk.svg';
import u1191 from '!!raw-loader!../../../../../public/img/icons/default/at-off.svg';
import u1192 from '!!raw-loader!../../../../../public/img/icons/default/at.svg';
import u1193 from '!!raw-loader!../../../../../public/img/icons/default/atom-2.svg';
import u1194 from '!!raw-loader!../../../../../public/img/icons/default/atom.svg';
import u1195 from '!!raw-loader!../../../../../public/img/icons/default/augmented-reality.svg';
import u1196 from '!!raw-loader!../../../../../public/img/icons/default/award.svg';
import u1197 from '!!raw-loader!../../../../../public/img/icons/default/axe.svg';
import u1198 from '!!raw-loader!../../../../../public/img/icons/default/axis-x.svg';
import u1199 from '!!raw-loader!../../../../../public/img/icons/default/axis-y.svg';
import u1200 from '!!raw-loader!../../../../../public/img/icons/default/baby-carriage.svg';
import u1201 from '!!raw-loader!../../../../../public/img/icons/default/backhoe.svg';
import u1202 from '!!raw-loader!../../../../../public/img/icons/default/backpack.svg';
import u1203 from '!!raw-loader!../../../../../public/img/icons/default/backspace.svg';
import u1204 from '!!raw-loader!../../../../../public/img/icons/default/badge.svg';
import u1205 from '!!raw-loader!../../../../../public/img/icons/default/badges.svg';
import u1206 from '!!raw-loader!../../../../../public/img/icons/default/ball-american-football.svg';
import u1207 from '!!raw-loader!../../../../../public/img/icons/default/ball-baseball.svg';
import u1208 from '!!raw-loader!../../../../../public/img/icons/default/ball-basketball.svg';
import u1209 from '!!raw-loader!../../../../../public/img/icons/default/ball-bowling.svg';
import u1210 from '!!raw-loader!../../../../../public/img/icons/default/ball-football-off.svg';
import u1211 from '!!raw-loader!../../../../../public/img/icons/default/ball-football.svg';
import u1212 from '!!raw-loader!../../../../../public/img/icons/default/ball-tennis.svg';
import u1213 from '!!raw-loader!../../../../../public/img/icons/default/ball-volleyball.svg';
import u1214 from '!!raw-loader!../../../../../public/img/icons/default/ballon.svg';
import u1215 from '!!raw-loader!../../../../../public/img/icons/default/ballpen-off.svg';
import u1216 from '!!raw-loader!../../../../../public/img/icons/default/ballpen.svg';
import u1217 from '!!raw-loader!../../../../../public/img/icons/default/ban.svg';
import u1218 from '!!raw-loader!../../../../../public/img/icons/default/bandage.svg';
import u1219 from '!!raw-loader!../../../../../public/img/icons/default/barbell-off.svg';
import u1220 from '!!raw-loader!../../../../../public/img/icons/default/barbell.svg';
import u1221 from '!!raw-loader!../../../../../public/img/icons/default/barcode-off.svg';
import u1222 from '!!raw-loader!../../../../../public/img/icons/default/barcode.svg';
import u1223 from '!!raw-loader!../../../../../public/img/icons/default/barrel.svg';
import u1224 from '!!raw-loader!../../../../../public/img/icons/default/barrier-block-off.svg';
import u1225 from '!!raw-loader!../../../../../public/img/icons/default/barrier-block.svg';
import u1226 from '!!raw-loader!../../../../../public/img/icons/default/baseline.svg';
import u1227 from '!!raw-loader!../../../../../public/img/icons/default/basket-off.svg';
import u1228 from '!!raw-loader!../../../../../public/img/icons/default/basket.svg';
import u1229 from '!!raw-loader!../../../../../public/img/icons/default/bath.svg';
import u1230 from '!!raw-loader!../../../../../public/img/icons/default/battery-1.svg';
import u1231 from '!!raw-loader!../../../../../public/img/icons/default/battery-2.svg';
import u1232 from '!!raw-loader!../../../../../public/img/icons/default/battery-3.svg';
import u1233 from '!!raw-loader!../../../../../public/img/icons/default/battery-4.svg';
import u1234 from '!!raw-loader!../../../../../public/img/icons/default/battery-automotive.svg';
import u1235 from '!!raw-loader!../../../../../public/img/icons/default/battery-charging-2.svg';
import u1236 from '!!raw-loader!../../../../../public/img/icons/default/battery-charging.svg';
import u1237 from '!!raw-loader!../../../../../public/img/icons/default/battery-eco.svg';
import u1238 from '!!raw-loader!../../../../../public/img/icons/default/battery-off.svg';
import u1239 from '!!raw-loader!../../../../../public/img/icons/default/battery.svg';
import u1240 from '!!raw-loader!../../../../../public/img/icons/default/beach-off.svg';
import u1241 from '!!raw-loader!../../../../../public/img/icons/default/beach.svg';
import u1242 from '!!raw-loader!../../../../../public/img/icons/default/bed.svg';
import u1243 from '!!raw-loader!../../../../../public/img/icons/default/beer.svg';
import u1244 from '!!raw-loader!../../../../../public/img/icons/default/bell-minus.svg';
import u1245 from '!!raw-loader!../../../../../public/img/icons/default/bell-off.svg';
import u1246 from '!!raw-loader!../../../../../public/img/icons/default/bell-plus.svg';
import u1247 from '!!raw-loader!../../../../../public/img/icons/default/bell-ringing-2.svg';
import u1248 from '!!raw-loader!../../../../../public/img/icons/default/bell-ringing.svg';
import u1249 from '!!raw-loader!../../../../../public/img/icons/default/bell-school.svg';
import u1250 from '!!raw-loader!../../../../../public/img/icons/default/bell-x.svg';
import u1251 from '!!raw-loader!../../../../../public/img/icons/default/bell-z.svg';
import u1252 from '!!raw-loader!../../../../../public/img/icons/default/bell.svg';
import u1253 from '!!raw-loader!../../../../../public/img/icons/default/bible.svg';
import u1254 from '!!raw-loader!../../../../../public/img/icons/default/bike-off.svg';
import u1255 from '!!raw-loader!../../../../../public/img/icons/default/bike.svg';
import u1256 from '!!raw-loader!../../../../../public/img/icons/default/binary.svg';
import u1257 from '!!raw-loader!../../../../../public/img/icons/default/biohazard-off.svg';
import u1258 from '!!raw-loader!../../../../../public/img/icons/default/biohazard.svg';
import u1259 from '!!raw-loader!../../../../../public/img/icons/default/blockquote.svg';
import u1260 from '!!raw-loader!../../../../../public/img/icons/default/bluetooth-connected.svg';
import u1261 from '!!raw-loader!../../../../../public/img/icons/default/bluetooth-off.svg';
import u1262 from '!!raw-loader!../../../../../public/img/icons/default/bluetooth-x.svg';
import u1263 from '!!raw-loader!../../../../../public/img/icons/default/bluetooth.svg';
import u1264 from '!!raw-loader!../../../../../public/img/icons/default/blur.svg';
import u1265 from '!!raw-loader!../../../../../public/img/icons/default/bold-off.svg';
import u1266 from '!!raw-loader!../../../../../public/img/icons/default/bold.svg';
import u1267 from '!!raw-loader!../../../../../public/img/icons/default/bolt-off.svg';
import u1268 from '!!raw-loader!../../../../../public/img/icons/default/bolt.svg';
import u1269 from '!!raw-loader!../../../../../public/img/icons/default/bone-off.svg';
import u1270 from '!!raw-loader!../../../../../public/img/icons/default/bone.svg';
import u1271 from '!!raw-loader!../../../../../public/img/icons/default/book-2.svg';
import u1272 from '!!raw-loader!../../../../../public/img/icons/default/book-download.svg';
import u1273 from '!!raw-loader!../../../../../public/img/icons/default/book-off.svg';
import u1274 from '!!raw-loader!../../../../../public/img/icons/default/book-upload.svg';
import u1275 from '!!raw-loader!../../../../../public/img/icons/default/book.svg';
import u1276 from '!!raw-loader!../../../../../public/img/icons/default/bookmark-off.svg';
import u1277 from '!!raw-loader!../../../../../public/img/icons/default/bookmark.svg';
import u1278 from '!!raw-loader!../../../../../public/img/icons/default/bookmarks-off.svg';
import u1279 from '!!raw-loader!../../../../../public/img/icons/default/bookmarks.svg';
import u1280 from '!!raw-loader!../../../../../public/img/icons/default/books-off.svg';
import u1281 from '!!raw-loader!../../../../../public/img/icons/default/books.svg';
import u1282 from '!!raw-loader!../../../../../public/img/icons/default/border-all.svg';
import u1283 from '!!raw-loader!../../../../../public/img/icons/default/border-bottom.svg';
import u1284 from '!!raw-loader!../../../../../public/img/icons/default/border-horizontal.svg';
import u1285 from '!!raw-loader!../../../../../public/img/icons/default/border-inner.svg';
import u1286 from '!!raw-loader!../../../../../public/img/icons/default/border-left.svg';
import u1287 from '!!raw-loader!../../../../../public/img/icons/default/border-none.svg';
import u1288 from '!!raw-loader!../../../../../public/img/icons/default/border-outer.svg';
import u1289 from '!!raw-loader!../../../../../public/img/icons/default/border-radius.svg';
import u1290 from '!!raw-loader!../../../../../public/img/icons/default/border-right.svg';
import u1291 from '!!raw-loader!../../../../../public/img/icons/default/border-style-2.svg';
import u1292 from '!!raw-loader!../../../../../public/img/icons/default/border-style.svg';
import u1293 from '!!raw-loader!../../../../../public/img/icons/default/border-top.svg';
import u1294 from '!!raw-loader!../../../../../public/img/icons/default/border-vertical.svg';
import u1295 from '!!raw-loader!../../../../../public/img/icons/default/bottle.svg';
import u1296 from '!!raw-loader!../../../../../public/img/icons/default/bow.svg';
import u1297 from '!!raw-loader!../../../../../public/img/icons/default/box-margin.svg';
import u1298 from '!!raw-loader!../../../../../public/img/icons/default/box-model-2.svg';
import u1299 from '!!raw-loader!../../../../../public/img/icons/default/box-model.svg';
import u1300 from '!!raw-loader!../../../../../public/img/icons/default/box-multiple-0.svg';
import u1301 from '!!raw-loader!../../../../../public/img/icons/default/box-multiple-1.svg';
import u1302 from '!!raw-loader!../../../../../public/img/icons/default/box-multiple-2.svg';
import u1303 from '!!raw-loader!../../../../../public/img/icons/default/box-multiple-3.svg';
import u1304 from '!!raw-loader!../../../../../public/img/icons/default/box-multiple-4.svg';
import u1305 from '!!raw-loader!../../../../../public/img/icons/default/box-multiple-5.svg';
import u1306 from '!!raw-loader!../../../../../public/img/icons/default/box-multiple-6.svg';
import u1307 from '!!raw-loader!../../../../../public/img/icons/default/box-multiple-7.svg';
import u1308 from '!!raw-loader!../../../../../public/img/icons/default/box-multiple-8.svg';
import u1309 from '!!raw-loader!../../../../../public/img/icons/default/box-multiple-9.svg';
import u1310 from '!!raw-loader!../../../../../public/img/icons/default/box-multiple.svg';
import u1311 from '!!raw-loader!../../../../../public/img/icons/default/box-padding.svg';
import u1312 from '!!raw-loader!../../../../../public/img/icons/default/box.svg';
import u1313 from '!!raw-loader!../../../../../public/img/icons/default/braces-off.svg';
import u1314 from '!!raw-loader!../../../../../public/img/icons/default/braces.svg';
import u1315 from '!!raw-loader!../../../../../public/img/icons/default/brackets-off.svg';
import u1316 from '!!raw-loader!../../../../../public/img/icons/default/brackets.svg';
import u1317 from '!!raw-loader!../../../../../public/img/icons/default/brand-adobe.svg';
import u1318 from '!!raw-loader!../../../../../public/img/icons/default/brand-airbnb.svg';
import u1319 from '!!raw-loader!../../../../../public/img/icons/default/brand-airtable.svg';
import u1320 from '!!raw-loader!../../../../../public/img/icons/default/brand-android.svg';
import u1321 from '!!raw-loader!../../../../../public/img/icons/default/brand-angular.svg';
import u1322 from '!!raw-loader!../../../../../public/img/icons/default/brand-apple-arcade.svg';
import u1323 from '!!raw-loader!../../../../../public/img/icons/default/brand-apple.svg';
import u1324 from '!!raw-loader!../../../../../public/img/icons/default/brand-appstore.svg';
import u1325 from '!!raw-loader!../../../../../public/img/icons/default/brand-asana.svg';
import u1326 from '!!raw-loader!../../../../../public/img/icons/default/brand-behance.svg';
import u1327 from '!!raw-loader!../../../../../public/img/icons/default/brand-bing.svg';
import u1328 from '!!raw-loader!../../../../../public/img/icons/default/brand-bitbucket.svg';
import u1329 from '!!raw-loader!../../../../../public/img/icons/default/brand-booking.svg';
import u1330 from '!!raw-loader!../../../../../public/img/icons/default/brand-bootstrap.svg';
import u1331 from '!!raw-loader!../../../../../public/img/icons/default/brand-chrome.svg';
import u1332 from '!!raw-loader!../../../../../public/img/icons/default/brand-codepen.svg';
import u1333 from '!!raw-loader!../../../../../public/img/icons/default/brand-codesandbox.svg';
import u1334 from '!!raw-loader!../../../../../public/img/icons/default/brand-css3.svg';
import u1335 from '!!raw-loader!../../../../../public/img/icons/default/brand-cucumber.svg';
import u1336 from '!!raw-loader!../../../../../public/img/icons/default/brand-debian.svg';
import u1337 from '!!raw-loader!../../../../../public/img/icons/default/brand-deviantart.svg';
import u1338 from '!!raw-loader!../../../../../public/img/icons/default/brand-discord.svg';
import u1339 from '!!raw-loader!../../../../../public/img/icons/default/brand-disqus.svg';
import u1340 from '!!raw-loader!../../../../../public/img/icons/default/brand-docker.svg';
import u1341 from '!!raw-loader!../../../../../public/img/icons/default/brand-doctrine.svg';
import u1342 from '!!raw-loader!../../../../../public/img/icons/default/brand-dribbble.svg';
import u1343 from '!!raw-loader!../../../../../public/img/icons/default/brand-edge.svg';
import u1344 from '!!raw-loader!../../../../../public/img/icons/default/brand-facebook.svg';
import u1345 from '!!raw-loader!../../../../../public/img/icons/default/brand-figma.svg';
import u1346 from '!!raw-loader!../../../../../public/img/icons/default/brand-firebase.svg';
import u1347 from '!!raw-loader!../../../../../public/img/icons/default/brand-firefox.svg';
import u1348 from '!!raw-loader!../../../../../public/img/icons/default/brand-flickr.svg';
import u1349 from '!!raw-loader!../../../../../public/img/icons/default/brand-foursquare.svg';
import u1350 from '!!raw-loader!../../../../../public/img/icons/default/brand-framer.svg';
import u1351 from '!!raw-loader!../../../../../public/img/icons/default/brand-git.svg';
import u1352 from '!!raw-loader!../../../../../public/img/icons/default/brand-github.svg';
import u1353 from '!!raw-loader!../../../../../public/img/icons/default/brand-gitlab.svg';
import u1354 from '!!raw-loader!../../../../../public/img/icons/default/brand-gmail.svg';
import u1355 from '!!raw-loader!../../../../../public/img/icons/default/brand-google-analytics.svg';
import u1356 from '!!raw-loader!../../../../../public/img/icons/default/brand-google-drive.svg';
import u1357 from '!!raw-loader!../../../../../public/img/icons/default/brand-google-play.svg';
import u1358 from '!!raw-loader!../../../../../public/img/icons/default/brand-google.svg';
import u1359 from '!!raw-loader!../../../../../public/img/icons/default/brand-gravatar.svg';
import u1360 from '!!raw-loader!../../../../../public/img/icons/default/brand-hipchat.svg';
import u1361 from '!!raw-loader!../../../../../public/img/icons/default/brand-html5.svg';
import u1362 from '!!raw-loader!../../../../../public/img/icons/default/brand-instagram.svg';
import u1363 from '!!raw-loader!../../../../../public/img/icons/default/brand-javascript.svg';
import u1364 from '!!raw-loader!../../../../../public/img/icons/default/brand-kickstarter.svg';
import u1365 from '!!raw-loader!../../../../../public/img/icons/default/brand-kotlin.svg';
import u1366 from '!!raw-loader!../../../../../public/img/icons/default/brand-lastfm.svg';
import u1367 from '!!raw-loader!../../../../../public/img/icons/default/brand-linkedin.svg';
import u1368 from '!!raw-loader!../../../../../public/img/icons/default/brand-loom.svg';
import u1369 from '!!raw-loader!../../../../../public/img/icons/default/brand-mastercard.svg';
import u1370 from '!!raw-loader!../../../../../public/img/icons/default/brand-medium.svg';
import u1371 from '!!raw-loader!../../../../../public/img/icons/default/brand-mercedes.svg';
import u1372 from '!!raw-loader!../../../../../public/img/icons/default/brand-messenger.svg';
import u1373 from '!!raw-loader!../../../../../public/img/icons/default/brand-meta.svg';
import u1374 from '!!raw-loader!../../../../../public/img/icons/default/brand-netbeans.svg';
import u1375 from '!!raw-loader!../../../../../public/img/icons/default/brand-netflix.svg';
import u1376 from '!!raw-loader!../../../../../public/img/icons/default/brand-nextjs.svg';
import u1377 from '!!raw-loader!../../../../../public/img/icons/default/brand-notion.svg';
import u1378 from '!!raw-loader!../../../../../public/img/icons/default/brand-nuxt.svg';
import u1379 from '!!raw-loader!../../../../../public/img/icons/default/brand-nytimes.svg';
import u1380 from '!!raw-loader!../../../../../public/img/icons/default/brand-open-source.svg';
import u1381 from '!!raw-loader!../../../../../public/img/icons/default/brand-opera.svg';
import u1382 from '!!raw-loader!../../../../../public/img/icons/default/brand-pagekit.svg';
import u1383 from '!!raw-loader!../../../../../public/img/icons/default/brand-patreon.svg';
import u1384 from '!!raw-loader!../../../../../public/img/icons/default/brand-paypal.svg';
import u1385 from '!!raw-loader!../../../../../public/img/icons/default/brand-php.svg';
import u1386 from '!!raw-loader!../../../../../public/img/icons/default/brand-pinterest.svg';
import u1387 from '!!raw-loader!../../../../../public/img/icons/default/brand-pocket.svg';
import u1388 from '!!raw-loader!../../../../../public/img/icons/default/brand-producthunt.svg';
import u1389 from '!!raw-loader!../../../../../public/img/icons/default/brand-python.svg';
import u1390 from '!!raw-loader!../../../../../public/img/icons/default/brand-react-native.svg';
import u1391 from '!!raw-loader!../../../../../public/img/icons/default/brand-reddit.svg';
import u1392 from '!!raw-loader!../../../../../public/img/icons/default/brand-safari.svg';
import u1393 from '!!raw-loader!../../../../../public/img/icons/default/brand-sass.svg';
import u1394 from '!!raw-loader!../../../../../public/img/icons/default/brand-sentry.svg';
import u1395 from '!!raw-loader!../../../../../public/img/icons/default/brand-shazam.svg';
import u1396 from '!!raw-loader!../../../../../public/img/icons/default/brand-sketch.svg';
import u1397 from '!!raw-loader!../../../../../public/img/icons/default/brand-skype.svg';
import u1398 from '!!raw-loader!../../../../../public/img/icons/default/brand-slack.svg';
import u1399 from '!!raw-loader!../../../../../public/img/icons/default/brand-snapchat.svg';
import u1400 from '!!raw-loader!../../../../../public/img/icons/default/brand-soundcloud.svg';
import u1401 from '!!raw-loader!../../../../../public/img/icons/default/brand-spotify.svg';
import u1402 from '!!raw-loader!../../../../../public/img/icons/default/brand-stackoverflow.svg';
import u1403 from '!!raw-loader!../../../../../public/img/icons/default/brand-steam.svg';
import u1404 from '!!raw-loader!../../../../../public/img/icons/default/brand-stripe.svg';
import u1405 from '!!raw-loader!../../../../../public/img/icons/default/brand-sublime-text.svg';
import u1406 from '!!raw-loader!../../../../../public/img/icons/default/brand-svelte.svg';
import u1408 from '!!raw-loader!../../../../../public/img/icons/default/brand-tailwind.svg';
import u1409 from '!!raw-loader!../../../../../public/img/icons/default/brand-telegram.svg';
import u1410 from '!!raw-loader!../../../../../public/img/icons/default/brand-tidal.svg';
import u1411 from '!!raw-loader!../../../../../public/img/icons/default/brand-tiktok.svg';
import u1412 from '!!raw-loader!../../../../../public/img/icons/default/brand-tinder.svg';
import u1413 from '!!raw-loader!../../../../../public/img/icons/default/brand-tripadvisor.svg';
import u1414 from '!!raw-loader!../../../../../public/img/icons/default/brand-tumblr.svg';
import u1415 from '!!raw-loader!../../../../../public/img/icons/default/brand-twitch.svg';
import u1416 from '!!raw-loader!../../../../../public/img/icons/default/brand-twitter.svg';
import u1417 from '!!raw-loader!../../../../../public/img/icons/default/brand-uber.svg';
import u1418 from '!!raw-loader!../../../../../public/img/icons/default/brand-ubuntu.svg';
import u1419 from '!!raw-loader!../../../../../public/img/icons/default/brand-unsplash.svg';
import u1420 from '!!raw-loader!../../../../../public/img/icons/default/brand-vercel.svg';
import u1421 from '!!raw-loader!../../../../../public/img/icons/default/brand-vimeo.svg';
import u1422 from '!!raw-loader!../../../../../public/img/icons/default/brand-visual-studio.svg';
import u1423 from '!!raw-loader!../../../../../public/img/icons/default/brand-vk.svg';
import u1424 from '!!raw-loader!../../../../../public/img/icons/default/brand-vue.svg';
import u1425 from '!!raw-loader!../../../../../public/img/icons/default/brand-whatsapp.svg';
import u1426 from '!!raw-loader!../../../../../public/img/icons/default/brand-windows.svg';
import u1427 from '!!raw-loader!../../../../../public/img/icons/default/brand-yahoo.svg';
import u1428 from '!!raw-loader!../../../../../public/img/icons/default/brand-ycombinator.svg';
import u1429 from '!!raw-loader!../../../../../public/img/icons/default/brand-youtube.svg';
import u1430 from '!!raw-loader!../../../../../public/img/icons/default/bread.svg';
import u1431 from '!!raw-loader!../../../../../public/img/icons/default/briefcase.svg';
import u1432 from '!!raw-loader!../../../../../public/img/icons/default/brightness-2.svg';
import u1433 from '!!raw-loader!../../../../../public/img/icons/default/brightness-down.svg';
import u1434 from '!!raw-loader!../../../../../public/img/icons/default/brightness-half.svg';
import u1435 from '!!raw-loader!../../../../../public/img/icons/default/brightness-up.svg';
import u1436 from '!!raw-loader!../../../../../public/img/icons/default/brightness.svg';
import u1437 from '!!raw-loader!../../../../../public/img/icons/default/browser-check.svg';
import u1438 from '!!raw-loader!../../../../../public/img/icons/default/browser-off.svg';
import u1439 from '!!raw-loader!../../../../../public/img/icons/default/browser-plus.svg';
import u1440 from '!!raw-loader!../../../../../public/img/icons/default/browser-x.svg';
import u1441 from '!!raw-loader!../../../../../public/img/icons/default/browser.svg';
import u1442 from '!!raw-loader!../../../../../public/img/icons/default/brush-off.svg';
import u1443 from '!!raw-loader!../../../../../public/img/icons/default/brush.svg';
import u1444 from '!!raw-loader!../../../../../public/img/icons/default/bucket.svg';
import u1445 from '!!raw-loader!../../../../../public/img/icons/default/bug-off.svg';
import u1446 from '!!raw-loader!../../../../../public/img/icons/default/bug.svg';
import u1447 from '!!raw-loader!../../../../../public/img/icons/default/building-arch.svg';
import u1448 from '!!raw-loader!../../../../../public/img/icons/default/building-bank.svg';
import u1449 from '!!raw-loader!../../../../../public/img/icons/default/building-bridge-2.svg';
import u1450 from '!!raw-loader!../../../../../public/img/icons/default/building-bridge.svg';
import u1451 from '!!raw-loader!../../../../../public/img/icons/default/building-carousel.svg';
import u1452 from '!!raw-loader!../../../../../public/img/icons/default/building-castle.svg';
import u1453 from '!!raw-loader!../../../../../public/img/icons/default/building-church.svg';
import u1454 from '!!raw-loader!../../../../../public/img/icons/default/building-community.svg';
import u1455 from '!!raw-loader!../../../../../public/img/icons/default/building-cottage.svg';
import u1456 from '!!raw-loader!../../../../../public/img/icons/default/building-factory-2.svg';
import u1457 from '!!raw-loader!../../../../../public/img/icons/default/building-factory.svg';
import u1458 from '!!raw-loader!../../../../../public/img/icons/default/building-fortress.svg';
import u1459 from '!!raw-loader!../../../../../public/img/icons/default/building-hospital.svg';
import u1460 from '!!raw-loader!../../../../../public/img/icons/default/building-lighthouse.svg';
import u1461 from '!!raw-loader!../../../../../public/img/icons/default/building-monument.svg';
import u1462 from '!!raw-loader!../../../../../public/img/icons/default/building-pavilon.svg';
import u1463 from '!!raw-loader!../../../../../public/img/icons/default/building-skyscraper.svg';
import u1464 from '!!raw-loader!../../../../../public/img/icons/default/building-store.svg';
import u1465 from '!!raw-loader!../../../../../public/img/icons/default/building-warehouse.svg';
import u1466 from '!!raw-loader!../../../../../public/img/icons/default/building.svg';
import u1467 from '!!raw-loader!../../../../../public/img/icons/default/bulb-off.svg';
import u1468 from '!!raw-loader!../../../../../public/img/icons/default/bulb.svg';
import u1469 from '!!raw-loader!../../../../../public/img/icons/default/bulldozer.svg';
import u1470 from '!!raw-loader!../../../../../public/img/icons/default/bus.svg';
import u1471 from '!!raw-loader!../../../../../public/img/icons/default/businessplan.svg';
import u1472 from '!!raw-loader!../../../../../public/img/icons/default/butterfly.svg';
import u1473 from '!!raw-loader!../../../../../public/img/icons/default/c-sharp.svg';
import u1474 from '!!raw-loader!../../../../../public/img/icons/default/cake.svg';
import u1475 from '!!raw-loader!../../../../../public/img/icons/default/calculator-off.svg';
import u1476 from '!!raw-loader!../../../../../public/img/icons/default/calculator.svg';
import u1477 from '!!raw-loader!../../../../../public/img/icons/default/calendar-event.svg';
import u1478 from '!!raw-loader!../../../../../public/img/icons/default/calendar-minus.svg';
import u1479 from '!!raw-loader!../../../../../public/img/icons/default/calendar-off.svg';
import u1480 from '!!raw-loader!../../../../../public/img/icons/default/calendar-plus.svg';
import u1481 from '!!raw-loader!../../../../../public/img/icons/default/calendar-stats.svg';
import u1482 from '!!raw-loader!../../../../../public/img/icons/default/calendar-time.svg';
import u1483 from '!!raw-loader!../../../../../public/img/icons/default/calendar.svg';
import u1484 from '!!raw-loader!../../../../../public/img/icons/default/camera-minus.svg';
import u1485 from '!!raw-loader!../../../../../public/img/icons/default/camera-off.svg';
import u1486 from '!!raw-loader!../../../../../public/img/icons/default/camera-plus.svg';
import u1487 from '!!raw-loader!../../../../../public/img/icons/default/camera-rotate.svg';
import u1488 from '!!raw-loader!../../../../../public/img/icons/default/camera-selfie.svg';
import u1489 from '!!raw-loader!../../../../../public/img/icons/default/camera.svg';
import u1490 from '!!raw-loader!../../../../../public/img/icons/default/candle.svg';
import u1491 from '!!raw-loader!../../../../../public/img/icons/default/candy-off.svg';
import u1492 from '!!raw-loader!../../../../../public/img/icons/default/candy.svg';
import u1493 from '!!raw-loader!../../../../../public/img/icons/default/capture-off.svg';
import u1494 from '!!raw-loader!../../../../../public/img/icons/default/capture.svg';
import u1495 from '!!raw-loader!../../../../../public/img/icons/default/car-crane.svg';
import u1496 from '!!raw-loader!../../../../../public/img/icons/default/car-crash.svg';
import u1497 from '!!raw-loader!../../../../../public/img/icons/default/car-off.svg';
import u1498 from '!!raw-loader!../../../../../public/img/icons/default/car.svg';
import u1499 from '!!raw-loader!../../../../../public/img/icons/default/caravan.svg';
import u1500 from '!!raw-loader!../../../../../public/img/icons/default/cardboards-off.svg';
import u1501 from '!!raw-loader!../../../../../public/img/icons/default/cardboards.svg';
import u1502 from '!!raw-loader!../../../../../public/img/icons/default/caret-down.svg';
import u1503 from '!!raw-loader!../../../../../public/img/icons/default/caret-left.svg';
import u1504 from '!!raw-loader!../../../../../public/img/icons/default/caret-right.svg';
import u1505 from '!!raw-loader!../../../../../public/img/icons/default/caret-up.svg';
import u1506 from '!!raw-loader!../../../../../public/img/icons/default/cash-banknote-off.svg';
import u1507 from '!!raw-loader!../../../../../public/img/icons/default/cash-banknote.svg';
import u1508 from '!!raw-loader!../../../../../public/img/icons/default/cash.svg';
import u1509 from '!!raw-loader!../../../../../public/img/icons/default/cast-off.svg';
import u1510 from '!!raw-loader!../../../../../public/img/icons/default/cast.svg';
import u1511 from '!!raw-loader!../../../../../public/img/icons/default/ce-off.svg';
import u1512 from '!!raw-loader!../../../../../public/img/icons/default/ce.svg';
import u1513 from '!!raw-loader!../../../../../public/img/icons/default/cell-signal-1.svg';
import u1514 from '!!raw-loader!../../../../../public/img/icons/default/cell-signal-2.svg';
import u1515 from '!!raw-loader!../../../../../public/img/icons/default/cell-signal-3.svg';
import u1516 from '!!raw-loader!../../../../../public/img/icons/default/cell-signal-4.svg';
import u1517 from '!!raw-loader!../../../../../public/img/icons/default/cell-signal-5.svg';
import u1518 from '!!raw-loader!../../../../../public/img/icons/default/cell-signal-off.svg';
import u1519 from '!!raw-loader!../../../../../public/img/icons/default/cell.svg';
import u1520 from '!!raw-loader!../../../../../public/img/icons/default/certificate-2-off.svg';
import u1521 from '!!raw-loader!../../../../../public/img/icons/default/certificate-2.svg';
import u1522 from '!!raw-loader!../../../../../public/img/icons/default/certificate-off.svg';
import u1523 from '!!raw-loader!../../../../../public/img/icons/default/certificate.svg';
import u1524 from '!!raw-loader!../../../../../public/img/icons/default/charging-pile.svg';
import u1525 from '!!raw-loader!../../../../../public/img/icons/default/chart-arcs-3.svg';
import u1526 from '!!raw-loader!../../../../../public/img/icons/default/chart-arcs.svg';
import u1527 from '!!raw-loader!../../../../../public/img/icons/default/chart-area-line.svg';
import u1528 from '!!raw-loader!../../../../../public/img/icons/default/chart-area.svg';
import u1529 from '!!raw-loader!../../../../../public/img/icons/default/chart-arrows-vertical.svg';
import u1530 from '!!raw-loader!../../../../../public/img/icons/default/chart-arrows.svg';
import u1531 from '!!raw-loader!../../../../../public/img/icons/default/chart-bar.svg';
import u1532 from '!!raw-loader!../../../../../public/img/icons/default/chart-bubble.svg';
import u1533 from '!!raw-loader!../../../../../public/img/icons/default/chart-candle.svg';
import u1534 from '!!raw-loader!../../../../../public/img/icons/default/chart-circles.svg';
import u1535 from '!!raw-loader!../../../../../public/img/icons/default/chart-donut-2.svg';
import u1536 from '!!raw-loader!../../../../../public/img/icons/default/chart-donut-3.svg';
import u1537 from '!!raw-loader!../../../../../public/img/icons/default/chart-donut-4.svg';
import u1538 from '!!raw-loader!../../../../../public/img/icons/default/chart-donut.svg';
import u1539 from '!!raw-loader!../../../../../public/img/icons/default/chart-dots-2.svg';
import u1540 from '!!raw-loader!../../../../../public/img/icons/default/chart-dots-3.svg';
import u1541 from '!!raw-loader!../../../../../public/img/icons/default/chart-dots.svg';
import u1542 from '!!raw-loader!../../../../../public/img/icons/default/chart-infographic.svg';
import u1543 from '!!raw-loader!../../../../../public/img/icons/default/chart-line.svg';
import u1544 from '!!raw-loader!../../../../../public/img/icons/default/chart-pie-2.svg';
import u1545 from '!!raw-loader!../../../../../public/img/icons/default/chart-pie-3.svg';
import u1546 from '!!raw-loader!../../../../../public/img/icons/default/chart-pie-4.svg';
import u1547 from '!!raw-loader!../../../../../public/img/icons/default/chart-pie.svg';
import u1548 from '!!raw-loader!../../../../../public/img/icons/default/chart-radar.svg';
import u1549 from '!!raw-loader!../../../../../public/img/icons/default/check.svg';
import u1550 from '!!raw-loader!../../../../../public/img/icons/default/checkbox.svg';
import u1551 from '!!raw-loader!../../../../../public/img/icons/default/checklist.svg';
import u1552 from '!!raw-loader!../../../../../public/img/icons/default/checks.svg';
import u1553 from '!!raw-loader!../../../../../public/img/icons/default/checkup-list.svg';
import u1554 from '!!raw-loader!../../../../../public/img/icons/default/cheese.svg';
import u1555 from '!!raw-loader!../../../../../public/img/icons/default/chevron-down-left.svg';
import u1556 from '!!raw-loader!../../../../../public/img/icons/default/chevron-down-right.svg';
import u1557 from '!!raw-loader!../../../../../public/img/icons/default/chevron-down.svg';
import u1558 from '!!raw-loader!../../../../../public/img/icons/default/chevron-left.svg';
import u1559 from '!!raw-loader!../../../../../public/img/icons/default/chevron-right.svg';
import u1560 from '!!raw-loader!../../../../../public/img/icons/default/chevron-up-left.svg';
import u1561 from '!!raw-loader!../../../../../public/img/icons/default/chevron-up-right.svg';
import u1562 from '!!raw-loader!../../../../../public/img/icons/default/chevron-up.svg';
import u1563 from '!!raw-loader!../../../../../public/img/icons/default/chevrons-down-left.svg';
import u1564 from '!!raw-loader!../../../../../public/img/icons/default/chevrons-down-right.svg';
import u1565 from '!!raw-loader!../../../../../public/img/icons/default/chevrons-down.svg';
import u1566 from '!!raw-loader!../../../../../public/img/icons/default/chevrons-left.svg';
import u1567 from '!!raw-loader!../../../../../public/img/icons/default/chevrons-right.svg';
import u1568 from '!!raw-loader!../../../../../public/img/icons/default/chevrons-up-left.svg';
import u1569 from '!!raw-loader!../../../../../public/img/icons/default/chevrons-up-right.svg';
import u1570 from '!!raw-loader!../../../../../public/img/icons/default/chevrons-up.svg';
import u1571 from '!!raw-loader!../../../../../public/img/icons/default/christmas-tree.svg';
import u1572 from '!!raw-loader!../../../../../public/img/icons/default/circle-0.svg';
import u1573 from '!!raw-loader!../../../../../public/img/icons/default/circle-1.svg';
import u1574 from '!!raw-loader!../../../../../public/img/icons/default/circle-2.svg';
import u1575 from '!!raw-loader!../../../../../public/img/icons/default/circle-3.svg';
import u1576 from '!!raw-loader!../../../../../public/img/icons/default/circle-4.svg';
import u1577 from '!!raw-loader!../../../../../public/img/icons/default/circle-5.svg';
import u1578 from '!!raw-loader!../../../../../public/img/icons/default/circle-6.svg';
import u1579 from '!!raw-loader!../../../../../public/img/icons/default/circle-7.svg';
import u1580 from '!!raw-loader!../../../../../public/img/icons/default/circle-8.svg';
import u1581 from '!!raw-loader!../../../../../public/img/icons/default/circle-9.svg';
import u1582 from '!!raw-loader!../../../../../public/img/icons/default/circle-check.svg';
import u1583 from '!!raw-loader!../../../../../public/img/icons/default/circle-dashed.svg';
import u1584 from '!!raw-loader!../../../../../public/img/icons/default/circle-dot.svg';
import u1585 from '!!raw-loader!../../../../../public/img/icons/default/circle-dotted.svg';
import u1586 from '!!raw-loader!../../../../../public/img/icons/default/circle-half-2.svg';
import u1587 from '!!raw-loader!../../../../../public/img/icons/default/circle-half-vertical.svg';
import u1588 from '!!raw-loader!../../../../../public/img/icons/default/circle-half.svg';
import u1589 from '!!raw-loader!../../../../../public/img/icons/default/circle-minus.svg';
import u1590 from '!!raw-loader!../../../../../public/img/icons/default/circle-off.svg';
import u1591 from '!!raw-loader!../../../../../public/img/icons/default/circle-plus.svg';
import u1592 from '!!raw-loader!../../../../../public/img/icons/default/circle-rectangle-off.svg';
import u1593 from '!!raw-loader!../../../../../public/img/icons/default/circle-rectangle.svg';
import u1594 from '!!raw-loader!../../../../../public/img/icons/default/circle-square.svg';
import u1595 from '!!raw-loader!../../../../../public/img/icons/default/circle-triangle.svg';
import u1596 from '!!raw-loader!../../../../../public/img/icons/default/circle-x.svg';
import u1597 from '!!raw-loader!../../../../../public/img/icons/default/circle.svg';
import u1598 from '!!raw-loader!../../../../../public/img/icons/default/circles.svg';
import u1599 from '!!raw-loader!../../../../../public/img/icons/default/clear-all.svg';
import u1600 from '!!raw-loader!../../../../../public/img/icons/default/clear-formatting.svg';
import u1601 from '!!raw-loader!../../../../../public/img/icons/default/click.svg';
import u1602 from '!!raw-loader!../../../../../public/img/icons/default/clipboard-check.svg';
import u1603 from '!!raw-loader!../../../../../public/img/icons/default/clipboard-list.svg';
import u1604 from '!!raw-loader!../../../../../public/img/icons/default/clipboard-off.svg';
import u1605 from '!!raw-loader!../../../../../public/img/icons/default/clipboard-plus.svg';
import u1606 from '!!raw-loader!../../../../../public/img/icons/default/clipboard-text.svg';
import u1607 from '!!raw-loader!../../../../../public/img/icons/default/clipboard-x.svg';
import u1608 from '!!raw-loader!../../../../../public/img/icons/default/clipboard.svg';
import u1609 from '!!raw-loader!../../../../../public/img/icons/default/clock-2.svg';
import u1610 from '!!raw-loader!../../../../../public/img/icons/default/clock-off.svg';
import u1611 from '!!raw-loader!../../../../../public/img/icons/default/clock.svg';
import u1612 from '!!raw-loader!../../../../../public/img/icons/default/cloud-download.svg';
import u1613 from '!!raw-loader!../../../../../public/img/icons/default/cloud-fog.svg';
import u1614 from '!!raw-loader!../../../../../public/img/icons/default/cloud-lock-open.svg';
import u1615 from '!!raw-loader!../../../../../public/img/icons/default/cloud-lock.svg';
import u1616 from '!!raw-loader!../../../../../public/img/icons/default/cloud-off.svg';
import u1617 from '!!raw-loader!../../../../../public/img/icons/default/cloud-rain.svg';
import u1618 from '!!raw-loader!../../../../../public/img/icons/default/cloud-snow.svg';
import u1619 from '!!raw-loader!../../../../../public/img/icons/default/cloud-storm.svg';
import u1620 from '!!raw-loader!../../../../../public/img/icons/default/cloud-upload.svg';
import u1621 from '!!raw-loader!../../../../../public/img/icons/default/cloud.svg';
import u1622 from '!!raw-loader!../../../../../public/img/icons/default/clubs.svg';
import u1623 from '!!raw-loader!../../../../../public/img/icons/default/code-minus.svg';
import u1624 from '!!raw-loader!../../../../../public/img/icons/default/code-off.svg';
import u1625 from '!!raw-loader!../../../../../public/img/icons/default/code-plus.svg';
import u1626 from '!!raw-loader!../../../../../public/img/icons/default/code.svg';
import u1627 from '!!raw-loader!../../../../../public/img/icons/default/coffee.svg';
import u1628 from '!!raw-loader!../../../../../public/img/icons/default/coin-off.svg';
import u1629 from '!!raw-loader!../../../../../public/img/icons/default/coin.svg';
import u1630 from '!!raw-loader!../../../../../public/img/icons/default/color-picker-off.svg';
import u1631 from '!!raw-loader!../../../../../public/img/icons/default/color-picker.svg';
import u1632 from '!!raw-loader!../../../../../public/img/icons/default/color-swatch-off.svg';
import u1633 from '!!raw-loader!../../../../../public/img/icons/default/color-swatch.svg';
import u1634 from '!!raw-loader!../../../../../public/img/icons/default/column-insert-left.svg';
import u1635 from '!!raw-loader!../../../../../public/img/icons/default/column-insert-right.svg';
import u1636 from '!!raw-loader!../../../../../public/img/icons/default/columns-off.svg';
import u1637 from '!!raw-loader!../../../../../public/img/icons/default/columns.svg';
import u1638 from '!!raw-loader!../../../../../public/img/icons/default/comet.svg';
import u1639 from '!!raw-loader!../../../../../public/img/icons/default/command.svg';
import u1640 from '!!raw-loader!../../../../../public/img/icons/default/compass-off.svg';
import u1641 from '!!raw-loader!../../../../../public/img/icons/default/compass.svg';
import u1642 from '!!raw-loader!../../../../../public/img/icons/default/components-off.svg';
import u1643 from '!!raw-loader!../../../../../public/img/icons/default/components.svg';
import u1644 from '!!raw-loader!../../../../../public/img/icons/default/cone-2.svg';
import u1645 from '!!raw-loader!../../../../../public/img/icons/default/cone.svg';
import u1646 from '!!raw-loader!../../../../../public/img/icons/default/confetti.svg';
import u1647 from '!!raw-loader!../../../../../public/img/icons/default/container.svg';
import u1648 from '!!raw-loader!../../../../../public/img/icons/default/contrast-2.svg';
import u1649 from '!!raw-loader!../../../../../public/img/icons/default/contrast.svg';
import u1650 from '!!raw-loader!../../../../../public/img/icons/default/cookie-off.svg';
import u1651 from '!!raw-loader!../../../../../public/img/icons/default/cookie.svg';
import u1652 from '!!raw-loader!../../../../../public/img/icons/default/copy-off.svg';
import u1653 from '!!raw-loader!../../../../../public/img/icons/default/copy.svg';
import u1654 from '!!raw-loader!../../../../../public/img/icons/default/copyleft-off.svg';
import u1655 from '!!raw-loader!../../../../../public/img/icons/default/copyleft.svg';
import u1656 from '!!raw-loader!../../../../../public/img/icons/default/copyright-off.svg';
import u1657 from '!!raw-loader!../../../../../public/img/icons/default/copyright.svg';
import u1658 from '!!raw-loader!../../../../../public/img/icons/default/corner-down-left-double.svg';
import u1659 from '!!raw-loader!../../../../../public/img/icons/default/corner-down-left.svg';
import u1660 from '!!raw-loader!../../../../../public/img/icons/default/corner-down-right-double.svg';
import u1661 from '!!raw-loader!../../../../../public/img/icons/default/corner-down-right.svg';
import u1662 from '!!raw-loader!../../../../../public/img/icons/default/corner-left-down-double.svg';
import u1663 from '!!raw-loader!../../../../../public/img/icons/default/corner-left-down.svg';
import u1664 from '!!raw-loader!../../../../../public/img/icons/default/corner-left-up-double.svg';
import u1665 from '!!raw-loader!../../../../../public/img/icons/default/corner-left-up.svg';
import u1666 from '!!raw-loader!../../../../../public/img/icons/default/corner-right-down-double.svg';
import u1667 from '!!raw-loader!../../../../../public/img/icons/default/corner-right-down.svg';
import u1668 from '!!raw-loader!../../../../../public/img/icons/default/corner-right-up-double.svg';
import u1669 from '!!raw-loader!../../../../../public/img/icons/default/corner-right-up.svg';
import u1670 from '!!raw-loader!../../../../../public/img/icons/default/corner-up-left-double.svg';
import u1671 from '!!raw-loader!../../../../../public/img/icons/default/corner-up-left.svg';
import u1672 from '!!raw-loader!../../../../../public/img/icons/default/corner-up-right-double.svg';
import u1673 from '!!raw-loader!../../../../../public/img/icons/default/corner-up-right.svg';
import u1674 from '!!raw-loader!../../../../../public/img/icons/default/cpu-2.svg';
import u1675 from '!!raw-loader!../../../../../public/img/icons/default/cpu.svg';
import u1676 from '!!raw-loader!../../../../../public/img/icons/default/crane.svg';
import u1677 from '!!raw-loader!../../../../../public/img/icons/default/creative-commons.svg';
import u1678 from '!!raw-loader!../../../../../public/img/icons/default/credit-card-off.svg';
import u1679 from '!!raw-loader!../../../../../public/img/icons/default/credit-card.svg';
import u1680 from '!!raw-loader!../../../../../public/img/icons/default/cricket.svg';
import u1681 from '!!raw-loader!../../../../../public/img/icons/default/crop.svg';
import u1682 from '!!raw-loader!../../../../../public/img/icons/default/cross.svg';
import u1683 from '!!raw-loader!../../../../../public/img/icons/default/crosshair.svg';
import u1684 from '!!raw-loader!../../../../../public/img/icons/default/crown-off.svg';
import u1685 from '!!raw-loader!../../../../../public/img/icons/default/crown.svg';
import u1686 from '!!raw-loader!../../../../../public/img/icons/default/crutches.svg';
import u1687 from '!!raw-loader!../../../../../public/img/icons/default/cup.svg';
import u1688 from '!!raw-loader!../../../../../public/img/icons/default/curling.svg';
import u1689 from '!!raw-loader!../../../../../public/img/icons/default/curly-loop.svg';
import u1690 from '!!raw-loader!../../../../../public/img/icons/default/currency-bahraini.svg';
import u1691 from '!!raw-loader!../../../../../public/img/icons/default/currency-baht.svg';
import u1692 from '!!raw-loader!../../../../../public/img/icons/default/currency-bitcoin.svg';
import u1693 from '!!raw-loader!../../../../../public/img/icons/default/currency-cent.svg';
import u1694 from '!!raw-loader!../../../../../public/img/icons/default/currency-dinar.svg';
import u1695 from '!!raw-loader!../../../../../public/img/icons/default/currency-dirham.svg';
import u1696 from '!!raw-loader!../../../../../public/img/icons/default/currency-dogecoin.svg';
import u1697 from '!!raw-loader!../../../../../public/img/icons/default/currency-dollar-australian.svg';
import u1698 from '!!raw-loader!../../../../../public/img/icons/default/currency-dollar-canadian.svg';
import u1699 from '!!raw-loader!../../../../../public/img/icons/default/currency-dollar-singapore.svg';
import u1700 from '!!raw-loader!../../../../../public/img/icons/default/currency-dollar.svg';
import u1701 from '!!raw-loader!../../../../../public/img/icons/default/currency-ethereum.svg';
import u1702 from '!!raw-loader!../../../../../public/img/icons/default/currency-euro.svg';
import u1703 from '!!raw-loader!../../../../../public/img/icons/default/currency-forint.svg';
import u1704 from '!!raw-loader!../../../../../public/img/icons/default/currency-frank.svg';
import u1705 from '!!raw-loader!../../../../../public/img/icons/default/currency-krone-czech.svg';
import u1706 from '!!raw-loader!../../../../../public/img/icons/default/currency-krone-danish.svg';
import u1707 from '!!raw-loader!../../../../../public/img/icons/default/currency-krone-swedish.svg';
import u1708 from '!!raw-loader!../../../../../public/img/icons/default/currency-leu.svg';
import u1709 from '!!raw-loader!../../../../../public/img/icons/default/currency-lira.svg';
import u1710 from '!!raw-loader!../../../../../public/img/icons/default/currency-litecoin.svg';
import u1711 from '!!raw-loader!../../../../../public/img/icons/default/currency-naira.svg';
import u1712 from '!!raw-loader!../../../../../public/img/icons/default/currency-pound.svg';
import u1713 from '!!raw-loader!../../../../../public/img/icons/default/currency-real.svg';
import u1714 from '!!raw-loader!../../../../../public/img/icons/default/currency-renminbi.svg';
import u1715 from '!!raw-loader!../../../../../public/img/icons/default/currency-ripple.svg';
import u1716 from '!!raw-loader!../../../../../public/img/icons/default/currency-riyal.svg';
import u1717 from '!!raw-loader!../../../../../public/img/icons/default/currency-rubel.svg';
import u1718 from '!!raw-loader!../../../../../public/img/icons/default/currency-rupee.svg';
import u1719 from '!!raw-loader!../../../../../public/img/icons/default/currency-shekel.svg';
import u1720 from '!!raw-loader!../../../../../public/img/icons/default/currency-taka.svg';
import u1721 from '!!raw-loader!../../../../../public/img/icons/default/currency-tugrik.svg';
import u1722 from '!!raw-loader!../../../../../public/img/icons/default/currency-won.svg';
import u1723 from '!!raw-loader!../../../../../public/img/icons/default/currency-yen.svg';
import u1724 from '!!raw-loader!../../../../../public/img/icons/default/currency-zloty.svg';
import u1725 from '!!raw-loader!../../../../../public/img/icons/default/currency.svg';
import u1726 from '!!raw-loader!../../../../../public/img/icons/default/current-location.svg';
import u1727 from '!!raw-loader!../../../../../public/img/icons/default/cursor-text.svg';
import u1728 from '!!raw-loader!../../../../../public/img/icons/default/cut.svg';
import u1729 from '!!raw-loader!../../../../../public/img/icons/default/dashboard.svg';
import u1730 from '!!raw-loader!../../../../../public/img/icons/default/database-export.svg';
import u1731 from '!!raw-loader!../../../../../public/img/icons/default/database-import.svg';
import u1732 from '!!raw-loader!../../../../../public/img/icons/default/database-off.svg';
import u1733 from '!!raw-loader!../../../../../public/img/icons/default/database.svg';
import u1734 from '!!raw-loader!../../../../../public/img/icons/default/dental.svg';
import u1735 from '!!raw-loader!../../../../../public/img/icons/default/details.svg';
import u1736 from '!!raw-loader!../../../../../public/img/icons/default/device-analytics.svg';
import u1737 from '!!raw-loader!../../../../../public/img/icons/default/device-audio-tape.svg';
import u1738 from '!!raw-loader!../../../../../public/img/icons/default/device-cctv.svg';
import u1739 from '!!raw-loader!../../../../../public/img/icons/default/device-computer-camera-off.svg';
import u1740 from '!!raw-loader!../../../../../public/img/icons/default/device-computer-camera.svg';
import u1741 from '!!raw-loader!../../../../../public/img/icons/default/device-desktop-analytics.svg';
import u1742 from '!!raw-loader!../../../../../public/img/icons/default/device-desktop-off.svg';
import u1743 from '!!raw-loader!../../../../../public/img/icons/default/device-desktop.svg';
import u1744 from '!!raw-loader!../../../../../public/img/icons/default/device-floppy.svg';
import u1745 from '!!raw-loader!../../../../../public/img/icons/default/device-gamepad.svg';
import u1746 from '!!raw-loader!../../../../../public/img/icons/default/device-heart-monitor.svg';
import u1747 from '!!raw-loader!../../../../../public/img/icons/default/device-laptop-off.svg';
import u1748 from '!!raw-loader!../../../../../public/img/icons/default/device-laptop.svg';
import u1749 from '!!raw-loader!../../../../../public/img/icons/default/device-mobile-message.svg';
import u1750 from '!!raw-loader!../../../../../public/img/icons/default/device-mobile-off.svg';
import u1751 from '!!raw-loader!../../../../../public/img/icons/default/device-mobile-rotated.svg';
import u1752 from '!!raw-loader!../../../../../public/img/icons/default/device-mobile-vibration.svg';
import u1753 from '!!raw-loader!../../../../../public/img/icons/default/device-mobile.svg';
import u1754 from '!!raw-loader!../../../../../public/img/icons/default/device-nintendo.svg';
import u1755 from '!!raw-loader!../../../../../public/img/icons/default/device-speaker.svg';
import u1756 from '!!raw-loader!../../../../../public/img/icons/default/device-tablet-off.svg';
import u1757 from '!!raw-loader!../../../../../public/img/icons/default/device-tablet.svg';
import u1758 from '!!raw-loader!../../../../../public/img/icons/default/device-tv-off.svg';
import u1759 from '!!raw-loader!../../../../../public/img/icons/default/device-tv.svg';
import u1760 from '!!raw-loader!../../../../../public/img/icons/default/device-watch-off.svg';
import u1761 from '!!raw-loader!../../../../../public/img/icons/default/device-watch-stats-2.svg';
import u1762 from '!!raw-loader!../../../../../public/img/icons/default/device-watch-stats.svg';
import u1763 from '!!raw-loader!../../../../../public/img/icons/default/device-watch.svg';
import u1764 from '!!raw-loader!../../../../../public/img/icons/default/devices-2.svg';
import u1765 from '!!raw-loader!../../../../../public/img/icons/default/devices-off.svg';
import u1766 from '!!raw-loader!../../../../../public/img/icons/default/devices-pc.svg';
import u1767 from '!!raw-loader!../../../../../public/img/icons/default/devices.svg';
import u1768 from '!!raw-loader!../../../../../public/img/icons/default/dialpad.svg';
import u1769 from '!!raw-loader!../../../../../public/img/icons/default/diamond.svg';
import u1770 from '!!raw-loader!../../../../../public/img/icons/default/diamonds.svg';
import u1771 from '!!raw-loader!../../../../../public/img/icons/default/dice-1.svg';
import u1772 from '!!raw-loader!../../../../../public/img/icons/default/dice-2.svg';
import u1773 from '!!raw-loader!../../../../../public/img/icons/default/dice-3.svg';
import u1774 from '!!raw-loader!../../../../../public/img/icons/default/dice-4.svg';
import u1775 from '!!raw-loader!../../../../../public/img/icons/default/dice-5.svg';
import u1776 from '!!raw-loader!../../../../../public/img/icons/default/dice-6.svg';
import u1777 from '!!raw-loader!../../../../../public/img/icons/default/dice.svg';
import u1778 from '!!raw-loader!../../../../../public/img/icons/default/dimensions.svg';
import u1779 from '!!raw-loader!../../../../../public/img/icons/default/direction-horizontal.svg';
import u1780 from '!!raw-loader!../../../../../public/img/icons/default/direction.svg';
import u1781 from '!!raw-loader!../../../../../public/img/icons/default/directions.svg';
import u1782 from '!!raw-loader!../../../../../public/img/icons/default/disabled-2.svg';
import u1783 from '!!raw-loader!../../../../../public/img/icons/default/disabled.svg';
import u1784 from '!!raw-loader!../../../../../public/img/icons/default/disc.svg';
import u1785 from '!!raw-loader!../../../../../public/img/icons/default/discount-2.svg';
import u1786 from '!!raw-loader!../../../../../public/img/icons/default/discount.svg';
import u1787 from '!!raw-loader!../../../../../public/img/icons/default/divide.svg';
import u1788 from '!!raw-loader!../../../../../public/img/icons/default/dna-2.svg';
import u1789 from '!!raw-loader!../../../../../public/img/icons/default/dna.svg';
import u1790 from '!!raw-loader!../../../../../public/img/icons/default/dog-bowl.svg';
import u1791 from '!!raw-loader!../../../../../public/img/icons/default/door-enter.svg';
import u1792 from '!!raw-loader!../../../../../public/img/icons/default/door-exit.svg';
import u1793 from '!!raw-loader!../../../../../public/img/icons/default/door.svg';
import u1794 from '!!raw-loader!../../../../../public/img/icons/default/dots-circle-horizontal.svg';
import u1795 from '!!raw-loader!../../../../../public/img/icons/default/dots-diagonal-2.svg';
import u1796 from '!!raw-loader!../../../../../public/img/icons/default/dots-diagonal.svg';
import u1797 from '!!raw-loader!../../../../../public/img/icons/default/dots-vertical.svg';
import u1798 from '!!raw-loader!../../../../../public/img/icons/default/dots.svg';
import u1799 from '!!raw-loader!../../../../../public/img/icons/default/download.svg';
import u1800 from '!!raw-loader!../../../../../public/img/icons/default/drag-drop-2.svg';
import u1801 from '!!raw-loader!../../../../../public/img/icons/default/drag-drop.svg';
import u1802 from '!!raw-loader!../../../../../public/img/icons/default/drone-off.svg';
import u1803 from '!!raw-loader!../../../../../public/img/icons/default/drone.svg';
import u1804 from '!!raw-loader!../../../../../public/img/icons/default/drop-circle.svg';
import u1805 from '!!raw-loader!../../../../../public/img/icons/default/droplet-filled-2.svg';
import u1806 from '!!raw-loader!../../../../../public/img/icons/default/droplet-filled.svg';
import u1807 from '!!raw-loader!../../../../../public/img/icons/default/droplet-half-2.svg';
import u1808 from '!!raw-loader!../../../../../public/img/icons/default/droplet-half.svg';
import u1809 from '!!raw-loader!../../../../../public/img/icons/default/droplet-off.svg';
import u1810 from '!!raw-loader!../../../../../public/img/icons/default/droplet.svg';
import u1811 from '!!raw-loader!../../../../../public/img/icons/default/ear-off.svg';
import u1812 from '!!raw-loader!../../../../../public/img/icons/default/ear.svg';
import u1813 from '!!raw-loader!../../../../../public/img/icons/default/edit-circle.svg';
import u1814 from '!!raw-loader!../../../../../public/img/icons/default/edit.svg';
import u1815 from '!!raw-loader!../../../../../public/img/icons/default/egg.svg';
import u1816 from '!!raw-loader!../../../../../public/img/icons/default/elevator.svg';
import u1817 from '!!raw-loader!../../../../../public/img/icons/default/emergency-bed.svg';
import u1818 from '!!raw-loader!../../../../../public/img/icons/default/emphasis.svg';
import u1819 from '!!raw-loader!../../../../../public/img/icons/default/engine.svg';
import u1820 from '!!raw-loader!../../../../../public/img/icons/default/equal-not.svg';
import u1821 from '!!raw-loader!../../../../../public/img/icons/default/equal.svg';
import u1822 from '!!raw-loader!../../../../../public/img/icons/default/eraser.svg';
import u1823 from '!!raw-loader!../../../../../public/img/icons/default/error-404.svg';
import u1824 from '!!raw-loader!../../../../../public/img/icons/default/exchange.svg';
import u1825 from '!!raw-loader!../../../../../public/img/icons/default/exclamation-mark.svg';
import u1826 from '!!raw-loader!../../../../../public/img/icons/default/exposure.svg';
import u1827 from '!!raw-loader!../../../../../public/img/icons/default/external-link.svg';
import u1828 from '!!raw-loader!../../../../../public/img/icons/default/eye-check.svg';
import u1829 from '!!raw-loader!../../../../../public/img/icons/default/eye-off.svg';
import u1830 from '!!raw-loader!../../../../../public/img/icons/default/eye-table.svg';
import u1831 from '!!raw-loader!../../../../../public/img/icons/default/eye.svg';
import u1832 from '!!raw-loader!../../../../../public/img/icons/default/eyeglass-2.svg';
import u1833 from '!!raw-loader!../../../../../public/img/icons/default/eyeglass.svg';
import u1834 from '!!raw-loader!../../../../../public/img/icons/default/face-id-error.svg';
import u1835 from '!!raw-loader!../../../../../public/img/icons/default/face-id.svg';
import u1836 from '!!raw-loader!../../../../../public/img/icons/default/face-mask.svg';
import u1837 from '!!raw-loader!../../../../../public/img/icons/default/fall.svg';
import u1838 from '!!raw-loader!../../../../../public/img/icons/default/feather.svg';
import u1839 from '!!raw-loader!../../../../../public/img/icons/default/fence.svg';
import u1840 from '!!raw-loader!../../../../../public/img/icons/default/fidget-spinner.svg';
import u1841 from '!!raw-loader!../../../../../public/img/icons/default/file-3d.svg';
import u1842 from '!!raw-loader!../../../../../public/img/icons/default/file-alert.svg';
import u1843 from '!!raw-loader!../../../../../public/img/icons/default/file-analytics.svg';
import u1844 from '!!raw-loader!../../../../../public/img/icons/default/file-arrow-left.svg';
import u1845 from '!!raw-loader!../../../../../public/img/icons/default/file-arrow-right.svg';
import u1846 from '!!raw-loader!../../../../../public/img/icons/default/file-barcode.svg';
import u1847 from '!!raw-loader!../../../../../public/img/icons/default/file-certificate.svg';
import u1848 from '!!raw-loader!../../../../../public/img/icons/default/file-chart.svg';
import u1849 from '!!raw-loader!../../../../../public/img/icons/default/file-check.svg';
import u1850 from '!!raw-loader!../../../../../public/img/icons/default/file-code-2.svg';
import u1851 from '!!raw-loader!../../../../../public/img/icons/default/file-code.svg';
import u1852 from '!!raw-loader!../../../../../public/img/icons/default/file-database.svg';
import u1853 from '!!raw-loader!../../../../../public/img/icons/default/file-description.svg';
import u1854 from '!!raw-loader!../../../../../public/img/icons/default/file-diff.svg';
import u1855 from '!!raw-loader!../../../../../public/img/icons/default/file-digit.svg';
import u1856 from '!!raw-loader!../../../../../public/img/icons/default/file-dislike.svg';
import u1857 from '!!raw-loader!../../../../../public/img/icons/default/file-dollar.svg';
import u1858 from '!!raw-loader!../../../../../public/img/icons/default/file-dots.svg';
import u1859 from '!!raw-loader!../../../../../public/img/icons/default/file-download.svg';
import u1860 from '!!raw-loader!../../../../../public/img/icons/default/file-euro.svg';
import u1861 from '!!raw-loader!../../../../../public/img/icons/default/file-export.svg';
import u1862 from '!!raw-loader!../../../../../public/img/icons/default/file-horizontal.svg';
import u1863 from '!!raw-loader!../../../../../public/img/icons/default/file-import.svg';
import u1864 from '!!raw-loader!../../../../../public/img/icons/default/file-info.svg';
import u1865 from '!!raw-loader!../../../../../public/img/icons/default/file-invoice.svg';
import u1866 from '!!raw-loader!../../../../../public/img/icons/default/file-like.svg';
import u1867 from '!!raw-loader!../../../../../public/img/icons/default/file-minus.svg';
import u1868 from '!!raw-loader!../../../../../public/img/icons/default/file-music.svg';
import u1869 from '!!raw-loader!../../../../../public/img/icons/default/file-off.svg';
import u1870 from '!!raw-loader!../../../../../public/img/icons/default/file-pencil.svg';
import u1871 from '!!raw-loader!../../../../../public/img/icons/default/file-phone.svg';
import u1872 from '!!raw-loader!../../../../../public/img/icons/default/file-plus.svg';
import u1873 from '!!raw-loader!../../../../../public/img/icons/default/file-power.svg';
import u1874 from '!!raw-loader!../../../../../public/img/icons/default/file-report.svg';
import u1875 from '!!raw-loader!../../../../../public/img/icons/default/file-rss.svg';
import u1876 from '!!raw-loader!../../../../../public/img/icons/default/file-scissors.svg';
import u1877 from '!!raw-loader!../../../../../public/img/icons/default/file-search.svg';
import u1878 from '!!raw-loader!../../../../../public/img/icons/default/file-settings.svg';
import u1879 from '!!raw-loader!../../../../../public/img/icons/default/file-shredder.svg';
import u1880 from '!!raw-loader!../../../../../public/img/icons/default/file-signal.svg';
import u1881 from '!!raw-loader!../../../../../public/img/icons/default/file-spreadsheet.svg';
import u1882 from '!!raw-loader!../../../../../public/img/icons/default/file-star.svg';
import u1883 from '!!raw-loader!../../../../../public/img/icons/default/file-symlink.svg';
import u1884 from '!!raw-loader!../../../../../public/img/icons/default/file-text.svg';
import u1885 from '!!raw-loader!../../../../../public/img/icons/default/file-time.svg';
import u1886 from '!!raw-loader!../../../../../public/img/icons/default/file-typography.svg';
import u1887 from '!!raw-loader!../../../../../public/img/icons/default/file-unknown.svg';
import u1888 from '!!raw-loader!../../../../../public/img/icons/default/file-upload.svg';
import u1889 from '!!raw-loader!../../../../../public/img/icons/default/file-vector.svg';
import u1890 from '!!raw-loader!../../../../../public/img/icons/default/file-x.svg';
import u1891 from '!!raw-loader!../../../../../public/img/icons/default/file-zip.svg';
import u1892 from '!!raw-loader!../../../../../public/img/icons/default/file.svg';
import u1893 from '!!raw-loader!../../../../../public/img/icons/default/files-off.svg';
import u1894 from '!!raw-loader!../../../../../public/img/icons/default/files.svg';
import u1895 from '!!raw-loader!../../../../../public/img/icons/default/filter-off.svg';
import u1896 from '!!raw-loader!../../../../../public/img/icons/default/filter.svg';
import u1897 from '!!raw-loader!../../../../../public/img/icons/default/fingerprint.svg';
import u1898 from '!!raw-loader!../../../../../public/img/icons/default/firetruck.svg';
import u1899 from '!!raw-loader!../../../../../public/img/icons/default/first-aid-kit.svg';
import u1900 from '!!raw-loader!../../../../../public/img/icons/default/fish.svg';
import u1901 from '!!raw-loader!../../../../../public/img/icons/default/flag-2.svg';
import u1902 from '!!raw-loader!../../../../../public/img/icons/default/flag-3.svg';
import u1903 from '!!raw-loader!../../../../../public/img/icons/default/flag.svg';
import u1904 from '!!raw-loader!../../../../../public/img/icons/default/flame.svg';
import u1905 from '!!raw-loader!../../../../../public/img/icons/default/flare.svg';
import u1906 from '!!raw-loader!../../../../../public/img/icons/default/flask-2.svg';
import u1907 from '!!raw-loader!../../../../../public/img/icons/default/flask.svg';
import u1908 from '!!raw-loader!../../../../../public/img/icons/default/flip-horizontal.svg';
import u1909 from '!!raw-loader!../../../../../public/img/icons/default/flip-vertical.svg';
import u1910 from '!!raw-loader!../../../../../public/img/icons/default/float-center.svg';
import u1911 from '!!raw-loader!../../../../../public/img/icons/default/float-left.svg';
import u1912 from '!!raw-loader!../../../../../public/img/icons/default/float-none.svg';
import u1913 from '!!raw-loader!../../../../../public/img/icons/default/float-right.svg';
import u1914 from '!!raw-loader!../../../../../public/img/icons/default/flower.svg';
import u1915 from '!!raw-loader!../../../../../public/img/icons/default/focus-2.svg';
import u1916 from '!!raw-loader!../../../../../public/img/icons/default/focus-centered.svg';
import u1917 from '!!raw-loader!../../../../../public/img/icons/default/focus.svg';
import u1918 from '!!raw-loader!../../../../../public/img/icons/default/fold-down.svg';
import u1919 from '!!raw-loader!../../../../../public/img/icons/default/fold-up.svg';
import u1920 from '!!raw-loader!../../../../../public/img/icons/default/fold.svg';
import u1921 from '!!raw-loader!../../../../../public/img/icons/default/folder-minus.svg';
import u1922 from '!!raw-loader!../../../../../public/img/icons/default/folder-off.svg';
import u1923 from '!!raw-loader!../../../../../public/img/icons/default/folder-plus.svg';
import u1924 from '!!raw-loader!../../../../../public/img/icons/default/folder-x.svg';
import u1925 from '!!raw-loader!../../../../../public/img/icons/default/folder.svg';
import u1926 from '!!raw-loader!../../../../../public/img/icons/default/folders.svg';
import u1927 from '!!raw-loader!../../../../../public/img/icons/default/forbid-2.svg';
import u1928 from '!!raw-loader!../../../../../public/img/icons/default/forbid.svg';
import u1929 from '!!raw-loader!../../../../../public/img/icons/default/forklift.svg';
import u1930 from '!!raw-loader!../../../../../public/img/icons/default/forms.svg';
import u1931 from '!!raw-loader!../../../../../public/img/icons/default/fountain.svg';
import u1932 from '!!raw-loader!../../../../../public/img/icons/default/frame.svg';
import u1933 from '!!raw-loader!../../../../../public/img/icons/default/free-rights.svg';
import u1934 from '!!raw-loader!../../../../../public/img/icons/default/friends.svg';
import u1935 from '!!raw-loader!../../../../../public/img/icons/default/gas-station.svg';
import u1936 from '!!raw-loader!../../../../../public/img/icons/default/gauge.svg';
import u1937 from '!!raw-loader!../../../../../public/img/icons/default/gavel.svg';
import u1938 from '!!raw-loader!../../../../../public/img/icons/default/gender-agender.svg';
import u1939 from '!!raw-loader!../../../../../public/img/icons/default/gender-androgyne.svg';
import u1940 from '!!raw-loader!../../../../../public/img/icons/default/gender-bigender.svg';
import u1941 from '!!raw-loader!../../../../../public/img/icons/default/gender-demiboy.svg';
import u1942 from '!!raw-loader!../../../../../public/img/icons/default/gender-demigirl.svg';
import u1943 from '!!raw-loader!../../../../../public/img/icons/default/gender-epicene.svg';
import u1944 from '!!raw-loader!../../../../../public/img/icons/default/gender-female.svg';
import u1945 from '!!raw-loader!../../../../../public/img/icons/default/gender-femme.svg';
import u1946 from '!!raw-loader!../../../../../public/img/icons/default/gender-genderfluid.svg';
import u1947 from '!!raw-loader!../../../../../public/img/icons/default/gender-genderless.svg';
import u1948 from '!!raw-loader!../../../../../public/img/icons/default/gender-genderqueer.svg';
import u1949 from '!!raw-loader!../../../../../public/img/icons/default/gender-hermaphrodite.svg';
import u1950 from '!!raw-loader!../../../../../public/img/icons/default/gender-intergender.svg';
import u1951 from '!!raw-loader!../../../../../public/img/icons/default/gender-male.svg';
import u1952 from '!!raw-loader!../../../../../public/img/icons/default/gender-neutrois.svg';
import u1953 from '!!raw-loader!../../../../../public/img/icons/default/gender-third.svg';
import u1954 from '!!raw-loader!../../../../../public/img/icons/default/gender-transgender.svg';
import u1955 from '!!raw-loader!../../../../../public/img/icons/default/gender-trasvesti.svg';
import u1956 from '!!raw-loader!../../../../../public/img/icons/default/geometry.svg';
import u1957 from '!!raw-loader!../../../../../public/img/icons/default/ghost.svg';
import u1958 from '!!raw-loader!../../../../../public/img/icons/default/gift.svg';
import u1959 from '!!raw-loader!../../../../../public/img/icons/default/git-branch.svg';
import u1960 from '!!raw-loader!../../../../../public/img/icons/default/git-commit.svg';
import u1961 from '!!raw-loader!../../../../../public/img/icons/default/git-compare.svg';
import u1962 from '!!raw-loader!../../../../../public/img/icons/default/git-fork.svg';
import u1963 from '!!raw-loader!../../../../../public/img/icons/default/git-merge.svg';
import u1964 from '!!raw-loader!../../../../../public/img/icons/default/git-pull-request-closed.svg';
import u1965 from '!!raw-loader!../../../../../public/img/icons/default/git-pull-request-draft.svg';
import u1966 from '!!raw-loader!../../../../../public/img/icons/default/git-pull-request.svg';
import u1967 from '!!raw-loader!../../../../../public/img/icons/default/gizmo.svg';
import u1968 from '!!raw-loader!../../../../../public/img/icons/default/glass-full.svg';
import u1969 from '!!raw-loader!../../../../../public/img/icons/default/glass-off.svg';
import u1970 from '!!raw-loader!../../../../../public/img/icons/default/glass.svg';
import u1971 from '!!raw-loader!../../../../../public/img/icons/default/globe.svg';
import u1972 from '!!raw-loader!../../../../../public/img/icons/default/golf.svg';
import u1973 from '!!raw-loader!../../../../../public/img/icons/default/gps.svg';
import u1974 from '!!raw-loader!../../../../../public/img/icons/default/grain.svg';
import u1975 from '!!raw-loader!../../../../../public/img/icons/default/grid-dots.svg';
import u1976 from '!!raw-loader!../../../../../public/img/icons/default/grid-pattern.svg';
import u1977 from '!!raw-loader!../../../../../public/img/icons/default/grill.svg';
import u1978 from '!!raw-loader!../../../../../public/img/icons/default/grip-horizontal.svg';
import u1979 from '!!raw-loader!../../../../../public/img/icons/default/grip-vertical.svg';
import u1980 from '!!raw-loader!../../../../../public/img/icons/default/growth.svg';
import u1981 from '!!raw-loader!../../../../../public/img/icons/default/h-1.svg';
import u1982 from '!!raw-loader!../../../../../public/img/icons/default/h-2.svg';
import u1983 from '!!raw-loader!../../../../../public/img/icons/default/h-3.svg';
import u1984 from '!!raw-loader!../../../../../public/img/icons/default/h-4.svg';
import u1985 from '!!raw-loader!../../../../../public/img/icons/default/h-5.svg';
import u1986 from '!!raw-loader!../../../../../public/img/icons/default/h-6.svg';
import u1987 from '!!raw-loader!../../../../../public/img/icons/default/hammer.svg';
import u1988 from '!!raw-loader!../../../../../public/img/icons/default/hand-click.svg';
import u1989 from '!!raw-loader!../../../../../public/img/icons/default/hand-finger.svg';
import u1990 from '!!raw-loader!../../../../../public/img/icons/default/hand-grab.svg';
import u1991 from '!!raw-loader!../../../../../public/img/icons/default/hand-little-finger.svg';
import u1992 from '!!raw-loader!../../../../../public/img/icons/default/hand-middle-finger.svg';
import u1993 from '!!raw-loader!../../../../../public/img/icons/default/hand-move.svg';
import u1994 from '!!raw-loader!../../../../../public/img/icons/default/hand-off.svg';
import u1995 from '!!raw-loader!../../../../../public/img/icons/default/hand-ring-finger.svg';
import u1996 from '!!raw-loader!../../../../../public/img/icons/default/hand-rock.svg';
import u1997 from '!!raw-loader!../../../../../public/img/icons/default/hand-stop.svg';
import u1998 from '!!raw-loader!../../../../../public/img/icons/default/hand-three-fingers.svg';
import u1999 from '!!raw-loader!../../../../../public/img/icons/default/hand-two-fingers.svg';
import u2000 from '!!raw-loader!../../../../../public/img/icons/default/hanger-2.svg';
import u2001 from '!!raw-loader!../../../../../public/img/icons/default/hanger.svg';
import u2002 from '!!raw-loader!../../../../../public/img/icons/default/hash.svg';
import u2003 from '!!raw-loader!../../../../../public/img/icons/default/haze.svg';
import u2004 from '!!raw-loader!../../../../../public/img/icons/default/heading.svg';
import u2005 from '!!raw-loader!../../../../../public/img/icons/default/headphones-off.svg';
import u2006 from '!!raw-loader!../../../../../public/img/icons/default/headphones.svg';
import u2007 from '!!raw-loader!../../../../../public/img/icons/default/headset.svg';
import u2008 from '!!raw-loader!../../../../../public/img/icons/default/heart-broken.svg';
import u2009 from '!!raw-loader!../../../../../public/img/icons/default/heart-handshake.svg';
import u2010 from '!!raw-loader!../../../../../public/img/icons/default/heart-rate-monitor.svg';
import u2011 from '!!raw-loader!../../../../../public/img/icons/default/heart.svg';
import u2012 from '!!raw-loader!../../../../../public/img/icons/default/heartbeat.svg';
import u2013 from '!!raw-loader!../../../../../public/img/icons/default/helicopter-landing.svg';
import u2014 from '!!raw-loader!../../../../../public/img/icons/default/helicopter.svg';
import u2015 from '!!raw-loader!../../../../../public/img/icons/default/helmet.svg';
import u2016 from '!!raw-loader!../../../../../public/img/icons/default/help.svg';
import u2017 from '!!raw-loader!../../../../../public/img/icons/default/hexagon-off.svg';
import u2018 from '!!raw-loader!../../../../../public/img/icons/default/hexagon.svg';
import u2019 from '!!raw-loader!../../../../../public/img/icons/default/hexagons.svg';
import u2020 from '!!raw-loader!../../../../../public/img/icons/default/hierarchy-2.svg';
import u2021 from '!!raw-loader!../../../../../public/img/icons/default/hierarchy.svg';
import u2022 from '!!raw-loader!../../../../../public/img/icons/default/highlight.svg';
import u2023 from '!!raw-loader!../../../../../public/img/icons/default/history.svg';
import u2024 from '!!raw-loader!../../../../../public/img/icons/default/home-2.svg';
import u2025 from '!!raw-loader!../../../../../public/img/icons/default/home.svg';
import u2026 from '!!raw-loader!../../../../../public/img/icons/default/hotel-service.svg';
import u2027 from '!!raw-loader!../../../../../public/img/icons/default/hourglass-high.svg';
import u2028 from '!!raw-loader!../../../../../public/img/icons/default/hourglass-low.svg';
import u2029 from '!!raw-loader!../../../../../public/img/icons/default/hourglass.svg';
import u2030 from '!!raw-loader!../../../../../public/img/icons/default/ice-cream-2.svg';
import u2031 from '!!raw-loader!../../../../../public/img/icons/default/ice-cream.svg';
import u2032 from '!!raw-loader!../../../../../public/img/icons/default/ice-skating.svg';
import u2033 from '!!raw-loader!../../../../../public/img/icons/default/id-badge-2.svg';
import u2034 from '!!raw-loader!../../../../../public/img/icons/default/id-badge.svg';
import u2035 from '!!raw-loader!../../../../../public/img/icons/default/id.svg';
import u2036 from '!!raw-loader!../../../../../public/img/icons/default/inbox.svg';
import u2037 from '!!raw-loader!../../../../../public/img/icons/default/indent-decrease.svg';
import u2038 from '!!raw-loader!../../../../../public/img/icons/default/indent-increase.svg';
import u2039 from '!!raw-loader!../../../../../public/img/icons/default/infinity.svg';
import u2040 from '!!raw-loader!../../../../../public/img/icons/default/info-circle.svg';
import u2041 from '!!raw-loader!../../../../../public/img/icons/default/info-square.svg';
import u2042 from '!!raw-loader!../../../../../public/img/icons/default/italic.svg';
import u2043 from '!!raw-loader!../../../../../public/img/icons/default/jump-rope.svg';
import u2044 from '!!raw-loader!../../../../../public/img/icons/default/karate.svg';
import u2045 from '!!raw-loader!../../../../../public/img/icons/default/kering.svg';
import u2046 from '!!raw-loader!../../../../../public/img/icons/default/key.svg';
import u2047 from '!!raw-loader!../../../../../public/img/icons/default/keyboard-hide.svg';
import u2048 from '!!raw-loader!../../../../../public/img/icons/default/keyboard-off.svg';
import u2049 from '!!raw-loader!../../../../../public/img/icons/default/keyboard-show.svg';
import u2050 from '!!raw-loader!../../../../../public/img/icons/default/keyboard.svg';
import u2051 from '!!raw-loader!../../../../../public/img/icons/default/ladder.svg';
import u2052 from '!!raw-loader!../../../../../public/img/icons/default/lamp-2.svg';
import u2053 from '!!raw-loader!../../../../../public/img/icons/default/lamp.svg';
import u2054 from '!!raw-loader!../../../../../public/img/icons/default/language-hiragana.svg';
import u2055 from '!!raw-loader!../../../../../public/img/icons/default/language-katakana.svg';
import u2056 from '!!raw-loader!../../../../../public/img/icons/default/language.svg';
import u2057 from '!!raw-loader!../../../../../public/img/icons/default/lasso.svg';
import u2058 from '!!raw-loader!../../../../../public/img/icons/default/layers-difference.svg';
import u2059 from '!!raw-loader!../../../../../public/img/icons/default/layers-intersect-2.svg';
import u2060 from '!!raw-loader!../../../../../public/img/icons/default/layers-intersect.svg';
import u2061 from '!!raw-loader!../../../../../public/img/icons/default/layers-linked.svg';
import u2062 from '!!raw-loader!../../../../../public/img/icons/default/layers-subtract.svg';
import u2063 from '!!raw-loader!../../../../../public/img/icons/default/layers-union.svg';
import u2064 from '!!raw-loader!../../../../../public/img/icons/default/layout-2.svg';
import u2065 from '!!raw-loader!../../../../../public/img/icons/default/layout-align-bottom.svg';
import u2066 from '!!raw-loader!../../../../../public/img/icons/default/layout-align-center.svg';
import u2067 from '!!raw-loader!../../../../../public/img/icons/default/layout-align-left.svg';
import u2068 from '!!raw-loader!../../../../../public/img/icons/default/layout-align-middle.svg';
import u2069 from '!!raw-loader!../../../../../public/img/icons/default/layout-align-right.svg';
import u2070 from '!!raw-loader!../../../../../public/img/icons/default/layout-align-top.svg';
import u2071 from '!!raw-loader!../../../../../public/img/icons/default/layout-board-split.svg';
import u2072 from '!!raw-loader!../../../../../public/img/icons/default/layout-board.svg';
import u2073 from '!!raw-loader!../../../../../public/img/icons/default/layout-bottombar.svg';
import u2074 from '!!raw-loader!../../../../../public/img/icons/default/layout-cards.svg';
import u2075 from '!!raw-loader!../../../../../public/img/icons/default/layout-columns.svg';
import u2076 from '!!raw-loader!../../../../../public/img/icons/default/layout-dashboard.svg';
import u2077 from '!!raw-loader!../../../../../public/img/icons/default/layout-distribute-horizontal.svg';
import u2078 from '!!raw-loader!../../../../../public/img/icons/default/layout-distribute-vertical.svg';
import u2079 from '!!raw-loader!../../../../../public/img/icons/default/layout-grid-add.svg';
import u2080 from '!!raw-loader!../../../../../public/img/icons/default/layout-grid.svg';
import u2081 from '!!raw-loader!../../../../../public/img/icons/default/layout-kanban.svg';
import u2082 from '!!raw-loader!../../../../../public/img/icons/default/layout-list.svg';
import u2083 from '!!raw-loader!../../../../../public/img/icons/default/layout-navbar.svg';
import u2084 from '!!raw-loader!../../../../../public/img/icons/default/layout-rows.svg';
import u2085 from '!!raw-loader!../../../../../public/img/icons/default/layout-sidebar-left-collapse.svg';
import u2086 from '!!raw-loader!../../../../../public/img/icons/default/layout-sidebar-left-expand.svg';
import u2087 from '!!raw-loader!../../../../../public/img/icons/default/layout-sidebar-right-collapse.svg';
import u2088 from '!!raw-loader!../../../../../public/img/icons/default/layout-sidebar-right-expand.svg';
import u2089 from '!!raw-loader!../../../../../public/img/icons/default/layout-sidebar-right.svg';
import u2090 from '!!raw-loader!../../../../../public/img/icons/default/layout-sidebar.svg';
import u2091 from '!!raw-loader!../../../../../public/img/icons/default/layout.svg';
import u2092 from '!!raw-loader!../../../../../public/img/icons/default/leaf.svg';
import u2093 from '!!raw-loader!../../../../../public/img/icons/default/lego.svg';
import u2094 from '!!raw-loader!../../../../../public/img/icons/default/lemon-2.svg';
import u2095 from '!!raw-loader!../../../../../public/img/icons/default/lemon.svg';
import u2096 from '!!raw-loader!../../../../../public/img/icons/default/letter-a.svg';
import u2097 from '!!raw-loader!../../../../../public/img/icons/default/letter-b.svg';
import u2098 from '!!raw-loader!../../../../../public/img/icons/default/letter-c.svg';
import u2099 from '!!raw-loader!../../../../../public/img/icons/default/letter-case-lower.svg';
import u2100 from '!!raw-loader!../../../../../public/img/icons/default/letter-case-toggle.svg';
import u2101 from '!!raw-loader!../../../../../public/img/icons/default/letter-case-upper.svg';
import u2102 from '!!raw-loader!../../../../../public/img/icons/default/letter-case.svg';
import u2103 from '!!raw-loader!../../../../../public/img/icons/default/letter-d.svg';
import u2104 from '!!raw-loader!../../../../../public/img/icons/default/letter-e.svg';
import u2105 from '!!raw-loader!../../../../../public/img/icons/default/letter-f.svg';
import u2106 from '!!raw-loader!../../../../../public/img/icons/default/letter-g.svg';
import u2107 from '!!raw-loader!../../../../../public/img/icons/default/letter-h.svg';
import u2108 from '!!raw-loader!../../../../../public/img/icons/default/letter-i.svg';
import u2109 from '!!raw-loader!../../../../../public/img/icons/default/letter-j.svg';
import u2110 from '!!raw-loader!../../../../../public/img/icons/default/letter-k.svg';
import u2111 from '!!raw-loader!../../../../../public/img/icons/default/letter-l.svg';
import u2112 from '!!raw-loader!../../../../../public/img/icons/default/letter-m.svg';
import u2113 from '!!raw-loader!../../../../../public/img/icons/default/letter-n.svg';
import u2114 from '!!raw-loader!../../../../../public/img/icons/default/letter-o.svg';
import u2115 from '!!raw-loader!../../../../../public/img/icons/default/letter-p.svg';
import u2116 from '!!raw-loader!../../../../../public/img/icons/default/letter-q.svg';
import u2117 from '!!raw-loader!../../../../../public/img/icons/default/letter-r.svg';
import u2118 from '!!raw-loader!../../../../../public/img/icons/default/letter-s.svg';
import u2119 from '!!raw-loader!../../../../../public/img/icons/default/letter-spacing.svg';
import u2120 from '!!raw-loader!../../../../../public/img/icons/default/letter-t.svg';
import u2121 from '!!raw-loader!../../../../../public/img/icons/default/letter-u.svg';
import u2122 from '!!raw-loader!../../../../../public/img/icons/default/letter-v.svg';
import u2123 from '!!raw-loader!../../../../../public/img/icons/default/letter-w.svg';
import u2124 from '!!raw-loader!../../../../../public/img/icons/default/letter-x.svg';
import u2125 from '!!raw-loader!../../../../../public/img/icons/default/letter-y.svg';
import u2126 from '!!raw-loader!../../../../../public/img/icons/default/letter-z.svg';
import u2127 from '!!raw-loader!../../../../../public/img/icons/default/license.svg';
import u2128 from '!!raw-loader!../../../../../public/img/icons/default/lifebuoy.svg';
import u2129 from '!!raw-loader!../../../../../public/img/icons/default/line-dashed.svg';
import u2130 from '!!raw-loader!../../../../../public/img/icons/default/line-dotted.svg';
import u2131 from '!!raw-loader!../../../../../public/img/icons/default/line-height.svg';
import u2132 from '!!raw-loader!../../../../../public/img/icons/default/line.svg';
import u2133 from '!!raw-loader!../../../../../public/img/icons/default/link.svg';
import u2134 from '!!raw-loader!../../../../../public/img/icons/default/list-check.svg';
import u2135 from '!!raw-loader!../../../../../public/img/icons/default/list-details.svg';
import u2136 from '!!raw-loader!../../../../../public/img/icons/default/list-numbers.svg';
import u2137 from '!!raw-loader!../../../../../public/img/icons/default/list-search.svg';
import u2138 from '!!raw-loader!../../../../../public/img/icons/default/list.svg';
import u2139 from '!!raw-loader!../../../../../public/img/icons/default/live-photo.svg';
import u2140 from '!!raw-loader!../../../../../public/img/icons/default/live-view.svg';
import u2141 from '!!raw-loader!../../../../../public/img/icons/default/loader-quarter.svg';
import u2142 from '!!raw-loader!../../../../../public/img/icons/default/loader.svg';
import u2143 from '!!raw-loader!../../../../../public/img/icons/default/location.svg';
import u2144 from '!!raw-loader!../../../../../public/img/icons/default/lock-access.svg';
import u2145 from '!!raw-loader!../../../../../public/img/icons/default/lock-off.svg';
import u2146 from '!!raw-loader!../../../../../public/img/icons/default/lock-open.svg';
import u2147 from '!!raw-loader!../../../../../public/img/icons/default/lock-square.svg';
import u2148 from '!!raw-loader!../../../../../public/img/icons/default/lock.svg';
import u2149 from '!!raw-loader!../../../../../public/img/icons/default/login.svg';
import u2150 from '!!raw-loader!../../../../../public/img/icons/default/logout.svg';
import u2151 from '!!raw-loader!../../../../../public/img/icons/default/lollipop.svg';
import u2152 from '!!raw-loader!../../../../../public/img/icons/default/luggage.svg';
import u2153 from '!!raw-loader!../../../../../public/img/icons/default/lungs.svg';
import u2154 from '!!raw-loader!../../../../../public/img/icons/default/macro.svg';
import u2155 from '!!raw-loader!../../../../../public/img/icons/default/magnet.svg';
import u2156 from '!!raw-loader!../../../../../public/img/icons/default/mail-fast.svg';
import u2157 from '!!raw-loader!../../../../../public/img/icons/default/mail-forward.svg';
import u2158 from '!!raw-loader!../../../../../public/img/icons/default/mail-opened.svg';
import u2159 from '!!raw-loader!../../../../../public/img/icons/default/mail.svg';
import u2160 from '!!raw-loader!../../../../../public/img/icons/default/mailbox.svg';
import u2161 from '!!raw-loader!../../../../../public/img/icons/default/man.svg';
import u2162 from '!!raw-loader!../../../../../public/img/icons/default/manual-gearbox.svg';
import u2163 from '!!raw-loader!../../../../../public/img/icons/default/map-2.svg';
import u2164 from '!!raw-loader!../../../../../public/img/icons/default/map-pin-off.svg';
import u2165 from '!!raw-loader!../../../../../public/img/icons/default/map-pin.svg';
import u2166 from '!!raw-loader!../../../../../public/img/icons/default/map-pins.svg';
import u2167 from '!!raw-loader!../../../../../public/img/icons/default/map-search.svg';
import u2168 from '!!raw-loader!../../../../../public/img/icons/default/map.svg';
import u2169 from '!!raw-loader!../../../../../public/img/icons/default/markdown.svg';
import u2170 from '!!raw-loader!../../../../../public/img/icons/default/marquee-2.svg';
import u2171 from '!!raw-loader!../../../../../public/img/icons/default/marquee.svg';
import u2172 from '!!raw-loader!../../../../../public/img/icons/default/mars.svg';
import u2173 from '!!raw-loader!../../../../../public/img/icons/default/mask-off.svg';
import u2174 from '!!raw-loader!../../../../../public/img/icons/default/mask.svg';
import u2175 from '!!raw-loader!../../../../../public/img/icons/default/massage.svg';
import u2176 from '!!raw-loader!../../../../../public/img/icons/default/math-avg.svg';
import u2177 from '!!raw-loader!../../../../../public/img/icons/default/math-function.svg';
import u2178 from '!!raw-loader!../../../../../public/img/icons/default/math-max.svg';
import u2179 from '!!raw-loader!../../../../../public/img/icons/default/math-min.svg';
import u2180 from '!!raw-loader!../../../../../public/img/icons/default/math-symbols.svg';
import u2181 from '!!raw-loader!../../../../../public/img/icons/default/math.svg';
import u2182 from '!!raw-loader!../../../../../public/img/icons/default/maximize.svg';
import u2183 from '!!raw-loader!../../../../../public/img/icons/default/meat.svg';
import u2184 from '!!raw-loader!../../../../../public/img/icons/default/medal-2.svg';
import u2185 from '!!raw-loader!../../../../../public/img/icons/default/medal.svg';
import u2186 from '!!raw-loader!../../../../../public/img/icons/default/medical-cross.svg';
import u2187 from '!!raw-loader!../../../../../public/img/icons/default/medicine-syrup.svg';
import u2188 from '!!raw-loader!../../../../../public/img/icons/default/menu-2.svg';
import u2189 from '!!raw-loader!../../../../../public/img/icons/default/menu.svg';
import u2190 from '!!raw-loader!../../../../../public/img/icons/default/message-2-code.svg';
import u2191 from '!!raw-loader!../../../../../public/img/icons/default/message-2-share.svg';
import u2192 from '!!raw-loader!../../../../../public/img/icons/default/message-2.svg';
import u2193 from '!!raw-loader!../../../../../public/img/icons/default/message-circle-2.svg';
import u2194 from '!!raw-loader!../../../../../public/img/icons/default/message-circle-off.svg';
import u2195 from '!!raw-loader!../../../../../public/img/icons/default/message-circle.svg';
import u2196 from '!!raw-loader!../../../../../public/img/icons/default/message-code.svg';
import u2197 from '!!raw-loader!../../../../../public/img/icons/default/message-dots.svg';
import u2198 from '!!raw-loader!../../../../../public/img/icons/default/message-language.svg';
import u2199 from '!!raw-loader!../../../../../public/img/icons/default/message-off.svg';
import u2200 from '!!raw-loader!../../../../../public/img/icons/default/message-plus.svg';
import u2201 from '!!raw-loader!../../../../../public/img/icons/default/message-report.svg';
import u2202 from '!!raw-loader!../../../../../public/img/icons/default/message-share.svg';
import u2203 from '!!raw-loader!../../../../../public/img/icons/default/message.svg';
import u2204 from '!!raw-loader!../../../../../public/img/icons/default/messages-off.svg';
import u2205 from '!!raw-loader!../../../../../public/img/icons/default/messages.svg';
import u2206 from '!!raw-loader!../../../../../public/img/icons/default/microphone-2.svg';
import u2207 from '!!raw-loader!../../../../../public/img/icons/default/microphone-off.svg';
import u2208 from '!!raw-loader!../../../../../public/img/icons/default/microphone.svg';
import u2209 from '!!raw-loader!../../../../../public/img/icons/default/microscope.svg';
import u2210 from '!!raw-loader!../../../../../public/img/icons/default/military-award.svg';
import u2211 from '!!raw-loader!../../../../../public/img/icons/default/military-rank.svg';
import u2212 from '!!raw-loader!../../../../../public/img/icons/default/milk.svg';
import u2213 from '!!raw-loader!../../../../../public/img/icons/default/minimize.svg';
import u2214 from '!!raw-loader!../../../../../public/img/icons/default/minus-vertical.svg';
import u2215 from '!!raw-loader!../../../../../public/img/icons/default/minus.svg';
import u2216 from '!!raw-loader!../../../../../public/img/icons/default/mist.svg';
import u2217 from '!!raw-loader!../../../../../public/img/icons/default/mood-boy.svg';
import u2218 from '!!raw-loader!../../../../../public/img/icons/default/mood-confuzed.svg';
import u2219 from '!!raw-loader!../../../../../public/img/icons/default/mood-crazy-happy.svg';
import u2220 from '!!raw-loader!../../../../../public/img/icons/default/mood-cry.svg';
import u2221 from '!!raw-loader!../../../../../public/img/icons/default/mood-empty.svg';
import u2222 from '!!raw-loader!../../../../../public/img/icons/default/mood-happy.svg';
import u2223 from '!!raw-loader!../../../../../public/img/icons/default/mood-kid.svg';
import u2224 from '!!raw-loader!../../../../../public/img/icons/default/mood-nervous.svg';
import u2225 from '!!raw-loader!../../../../../public/img/icons/default/mood-neutral.svg';
import u2226 from '!!raw-loader!../../../../../public/img/icons/default/mood-sad.svg';
import u2227 from '!!raw-loader!../../../../../public/img/icons/default/mood-smile.svg';
import u2228 from '!!raw-loader!../../../../../public/img/icons/default/mood-suprised.svg';
import u2229 from '!!raw-loader!../../../../../public/img/icons/default/mood-tongue.svg';
import u2230 from '!!raw-loader!../../../../../public/img/icons/default/moon-2.svg';
import u2231 from '!!raw-loader!../../../../../public/img/icons/default/moon-stars.svg';
import u2232 from '!!raw-loader!../../../../../public/img/icons/default/moon.svg';
import u2233 from '!!raw-loader!../../../../../public/img/icons/default/moped.svg';
import u2234 from '!!raw-loader!../../../../../public/img/icons/default/motorbike.svg';
import u2235 from '!!raw-loader!../../../../../public/img/icons/default/mountain.svg';
import u2236 from '!!raw-loader!../../../../../public/img/icons/default/mouse.svg';
import u2237 from '!!raw-loader!../../../../../public/img/icons/default/movie.svg';
import u2238 from '!!raw-loader!../../../../../public/img/icons/default/mug.svg';
import u2239 from '!!raw-loader!../../../../../public/img/icons/default/multiplier-0-5x.svg';
import u2240 from '!!raw-loader!../../../../../public/img/icons/default/multiplier-1-5x.svg';
import u2241 from '!!raw-loader!../../../../../public/img/icons/default/multiplier-1x.svg';
import u2242 from '!!raw-loader!../../../../../public/img/icons/default/multiplier-2x.svg';
import u2243 from '!!raw-loader!../../../../../public/img/icons/default/mushroom.svg';
import u2244 from '!!raw-loader!../../../../../public/img/icons/default/music.svg';
import u2245 from '!!raw-loader!../../../../../public/img/icons/default/network.svg';
import u2246 from '!!raw-loader!../../../../../public/img/icons/default/new-section.svg';
import u2247 from '!!raw-loader!../../../../../public/img/icons/default/news.svg';
import u2248 from '!!raw-loader!../../../../../public/img/icons/default/nfc.svg';
import u2249 from '!!raw-loader!../../../../../public/img/icons/default/no-copyright.svg';
import u2250 from '!!raw-loader!../../../../../public/img/icons/default/no-creative-commons.svg';
import u2251 from '!!raw-loader!../../../../../public/img/icons/default/no-derivatives.svg';
import u2252 from '!!raw-loader!../../../../../public/img/icons/default/north-star.svg';
import u2253 from '!!raw-loader!../../../../../public/img/icons/default/note.svg';
import u2254 from '!!raw-loader!../../../../../public/img/icons/default/notebook.svg';
import u2255 from '!!raw-loader!../../../../../public/img/icons/default/notes.svg';
import u2256 from '!!raw-loader!../../../../../public/img/icons/default/notification.svg';
import u2257 from '!!raw-loader!../../../../../public/img/icons/default/number-0.svg';
import u2258 from '!!raw-loader!../../../../../public/img/icons/default/number-1.svg';
import u2259 from '!!raw-loader!../../../../../public/img/icons/default/number-2.svg';
import u2260 from '!!raw-loader!../../../../../public/img/icons/default/number-3.svg';
import u2261 from '!!raw-loader!../../../../../public/img/icons/default/number-4.svg';
import u2262 from '!!raw-loader!../../../../../public/img/icons/default/number-5.svg';
import u2263 from '!!raw-loader!../../../../../public/img/icons/default/number-6.svg';
import u2264 from '!!raw-loader!../../../../../public/img/icons/default/number-7.svg';
import u2265 from '!!raw-loader!../../../../../public/img/icons/default/number-8.svg';
import u2266 from '!!raw-loader!../../../../../public/img/icons/default/number-9.svg';
import u2267 from '!!raw-loader!../../../../../public/img/icons/default/numbers.svg';
import u2268 from '!!raw-loader!../../../../../public/img/icons/default/nurse.svg';
import u2269 from '!!raw-loader!../../../../../public/img/icons/default/octagon-off.svg';
import u2270 from '!!raw-loader!../../../../../public/img/icons/default/octagon.svg';
import u2271 from '!!raw-loader!../../../../../public/img/icons/default/old.svg';
import u2272 from '!!raw-loader!../../../../../public/img/icons/default/olympics.svg';
import u2273 from '!!raw-loader!../../../../../public/img/icons/default/omega.svg';
import u2274 from '!!raw-loader!../../../../../public/img/icons/default/outlet.svg';
import u2275 from '!!raw-loader!../../../../../public/img/icons/default/oval-vertical.svg';
import u2276 from '!!raw-loader!../../../../../public/img/icons/default/oval.svg';
import u2277 from '!!raw-loader!../../../../../public/img/icons/default/overline.svg';
import u2278 from '!!raw-loader!../../../../../public/img/icons/default/package.svg';
import u2279 from '!!raw-loader!../../../../../public/img/icons/default/packge-export.svg';
import u2280 from '!!raw-loader!../../../../../public/img/icons/default/packge-import.svg';
import u2281 from '!!raw-loader!../../../../../public/img/icons/default/pacman.svg';
import u2282 from '!!raw-loader!../../../../../public/img/icons/default/page-break.svg';
import u2283 from '!!raw-loader!../../../../../public/img/icons/default/paint.svg';
import u2284 from '!!raw-loader!../../../../../public/img/icons/default/palette.svg';
import u2285 from '!!raw-loader!../../../../../public/img/icons/default/panorama-horizontal.svg';
import u2286 from '!!raw-loader!../../../../../public/img/icons/default/panorama-vertical.svg';
import u2287 from '!!raw-loader!../../../../../public/img/icons/default/paper-bag.svg';
import u2288 from '!!raw-loader!../../../../../public/img/icons/default/paperclip.svg';
import u2289 from '!!raw-loader!../../../../../public/img/icons/default/parachute.svg';
import u2290 from '!!raw-loader!../../../../../public/img/icons/default/parentheses.svg';
import u2291 from '!!raw-loader!../../../../../public/img/icons/default/parking.svg';
import u2292 from '!!raw-loader!../../../../../public/img/icons/default/paw.svg';
import u2293 from '!!raw-loader!../../../../../public/img/icons/default/peace.svg';
import u2294 from '!!raw-loader!../../../../../public/img/icons/default/pencil.svg';
import u2295 from '!!raw-loader!../../../../../public/img/icons/default/pennant-2.svg';
import u2296 from '!!raw-loader!../../../../../public/img/icons/default/pennant.svg';
import u2297 from '!!raw-loader!../../../../../public/img/icons/default/pentagon.svg';
import u2298 from '!!raw-loader!../../../../../public/img/icons/default/pepper.svg';
import u2299 from '!!raw-loader!../../../../../public/img/icons/default/percentage.svg';
import u2300 from '!!raw-loader!../../../../../public/img/icons/default/perspective.svg';
import u2301 from '!!raw-loader!../../../../../public/img/icons/default/phone-call.svg';
import u2302 from '!!raw-loader!../../../../../public/img/icons/default/phone-calling.svg';
import u2303 from '!!raw-loader!../../../../../public/img/icons/default/phone-check.svg';
import u2304 from '!!raw-loader!../../../../../public/img/icons/default/phone-incoming.svg';
import u2305 from '!!raw-loader!../../../../../public/img/icons/default/phone-off.svg';
import u2306 from '!!raw-loader!../../../../../public/img/icons/default/phone-outgoing.svg';
import u2307 from '!!raw-loader!../../../../../public/img/icons/default/phone-pause.svg';
import u2308 from '!!raw-loader!../../../../../public/img/icons/default/phone-plus.svg';
import u2309 from '!!raw-loader!../../../../../public/img/icons/default/phone-x.svg';
import u2310 from '!!raw-loader!../../../../../public/img/icons/default/phone.svg';
import u2311 from '!!raw-loader!../../../../../public/img/icons/default/photo-off.svg';
import u2312 from '!!raw-loader!../../../../../public/img/icons/default/photo.svg';
import u2313 from '!!raw-loader!../../../../../public/img/icons/default/physotherapist.svg';
import u2314 from '!!raw-loader!../../../../../public/img/icons/default/picture-in-picture-off.svg';
import u2315 from '!!raw-loader!../../../../../public/img/icons/default/picture-in-picture-on.svg';
import u2316 from '!!raw-loader!../../../../../public/img/icons/default/picture-in-picture-top.svg';
import u2317 from '!!raw-loader!../../../../../public/img/icons/default/picture-in-picture.svg';
import u2318 from '!!raw-loader!../../../../../public/img/icons/default/pig.svg';
import u2319 from '!!raw-loader!../../../../../public/img/icons/default/pill.svg';
import u2320 from '!!raw-loader!../../../../../public/img/icons/default/pills.svg';
import u2321 from '!!raw-loader!../../../../../public/img/icons/default/pin.svg';
import u2322 from '!!raw-loader!../../../../../public/img/icons/default/pinned-off.svg';
import u2323 from '!!raw-loader!../../../../../public/img/icons/default/pinned.svg';
import u2324 from '!!raw-loader!../../../../../public/img/icons/default/pizza.svg';
import u2325 from '!!raw-loader!../../../../../public/img/icons/default/plane-arrival.svg';
import u2326 from '!!raw-loader!../../../../../public/img/icons/default/plane-departure.svg';
import u2327 from '!!raw-loader!../../../../../public/img/icons/default/plane-inflight.svg';
import u2328 from '!!raw-loader!../../../../../public/img/icons/default/plane.svg';
import u2329 from '!!raw-loader!../../../../../public/img/icons/default/planet.svg';
import u2330 from '!!raw-loader!../../../../../public/img/icons/default/plant-2.svg';
import u2331 from '!!raw-loader!../../../../../public/img/icons/default/plant.svg';
import u2332 from '!!raw-loader!../../../../../public/img/icons/default/play-card.svg';
import u2333 from '!!raw-loader!../../../../../public/img/icons/default/player-eject.svg';
import u2334 from '!!raw-loader!../../../../../public/img/icons/default/player-pause.svg';
import u2335 from '!!raw-loader!../../../../../public/img/icons/default/player-play.svg';
import u2336 from '!!raw-loader!../../../../../public/img/icons/default/player-record.svg';
import u2337 from '!!raw-loader!../../../../../public/img/icons/default/player-skip-back.svg';
import u2338 from '!!raw-loader!../../../../../public/img/icons/default/player-skip-forward.svg';
import u2339 from '!!raw-loader!../../../../../public/img/icons/default/player-stop.svg';
import u2340 from '!!raw-loader!../../../../../public/img/icons/default/player-track-next.svg';
import u2341 from '!!raw-loader!../../../../../public/img/icons/default/player-track-prev.svg';
import u2342 from '!!raw-loader!../../../../../public/img/icons/default/playlist-add.svg';
import u2343 from '!!raw-loader!../../../../../public/img/icons/default/playlist-x.svg';
import u2344 from '!!raw-loader!../../../../../public/img/icons/default/playlist.svg';
import u2345 from '!!raw-loader!../../../../../public/img/icons/default/plug-connected-x.svg';
import u2346 from '!!raw-loader!../../../../../public/img/icons/default/plug-connected.svg';
import u2347 from '!!raw-loader!../../../../../public/img/icons/default/plug-x.svg';
import u2348 from '!!raw-loader!../../../../../public/img/icons/default/plug.svg';
import u2349 from '!!raw-loader!../../../../../public/img/icons/default/plus.svg';
import u2350 from '!!raw-loader!../../../../../public/img/icons/default/point.svg';
import u2351 from '!!raw-loader!../../../../../public/img/icons/default/pokeball.svg';
import u2352 from '!!raw-loader!../../../../../public/img/icons/default/polaroid.svg';
import u2353 from '!!raw-loader!../../../../../public/img/icons/default/polygon.svg';
import u2354 from '!!raw-loader!../../../../../public/img/icons/default/pool.svg';
import u2355 from '!!raw-loader!../../../../../public/img/icons/default/power.svg';
import u2356 from '!!raw-loader!../../../../../public/img/icons/default/pray.svg';
import u2357 from '!!raw-loader!../../../../../public/img/icons/default/premium-rights.svg';
import u2358 from '!!raw-loader!../../../../../public/img/icons/default/prescription.svg';
import u2359 from '!!raw-loader!../../../../../public/img/icons/default/presentation-analytics.svg';
import u2360 from '!!raw-loader!../../../../../public/img/icons/default/presentation.svg';
import u2361 from '!!raw-loader!../../../../../public/img/icons/default/printer.svg';
import u2362 from '!!raw-loader!../../../../../public/img/icons/default/prison.svg';
import u2363 from '!!raw-loader!../../../../../public/img/icons/default/prompt.svg';
import u2364 from '!!raw-loader!../../../../../public/img/icons/default/propeller.svg';
import u2365 from '!!raw-loader!../../../../../public/img/icons/default/puzzle-2.svg';
import u2366 from '!!raw-loader!../../../../../public/img/icons/default/puzzle.svg';
import u2367 from '!!raw-loader!../../../../../public/img/icons/default/pyramid.svg';
import u2368 from '!!raw-loader!../../../../../public/img/icons/default/qrcode.svg';
import u2369 from '!!raw-loader!../../../../../public/img/icons/default/question-mark.svg';
import u2370 from '!!raw-loader!../../../../../public/img/icons/default/quote.svg';
import u2371 from '!!raw-loader!../../../../../public/img/icons/default/radar-2.svg';
import u2372 from '!!raw-loader!../../../../../public/img/icons/default/radar.svg';
import u2373 from '!!raw-loader!../../../../../public/img/icons/default/radio.svg';
import u2374 from '!!raw-loader!../../../../../public/img/icons/default/radioactive.svg';
import u2375 from '!!raw-loader!../../../../../public/img/icons/default/radius-bottom-left.svg';
import u2376 from '!!raw-loader!../../../../../public/img/icons/default/radius-bottom-right.svg';
import u2377 from '!!raw-loader!../../../../../public/img/icons/default/radius-top-left.svg';
import u2378 from '!!raw-loader!../../../../../public/img/icons/default/radius-top-right.svg';
import u2379 from '!!raw-loader!../../../../../public/img/icons/default/rainbow.svg';
import u2380 from '!!raw-loader!../../../../../public/img/icons/default/receipt-2.svg';
import u2381 from '!!raw-loader!../../../../../public/img/icons/default/receipt-off.svg';
import u2382 from '!!raw-loader!../../../../../public/img/icons/default/receipt-refund.svg';
import u2383 from '!!raw-loader!../../../../../public/img/icons/default/receipt-tax.svg';
import u2384 from '!!raw-loader!../../../../../public/img/icons/default/receipt.svg';
import u2385 from '!!raw-loader!../../../../../public/img/icons/default/recharging.svg';
import u2386 from '!!raw-loader!../../../../../public/img/icons/default/record-mail.svg';
import u2387 from '!!raw-loader!../../../../../public/img/icons/default/rectangle-vertical.svg';
import u2388 from '!!raw-loader!../../../../../public/img/icons/default/rectangle.svg';
import u2389 from '!!raw-loader!../../../../../public/img/icons/default/recycle.svg';
import u2390 from '!!raw-loader!../../../../../public/img/icons/default/refresh-alert.svg';
import u2391 from '!!raw-loader!../../../../../public/img/icons/default/refresh-dot.svg';
import u2392 from '!!raw-loader!../../../../../public/img/icons/default/refresh.svg';
import u2393 from '!!raw-loader!../../../../../public/img/icons/default/registered.svg';
import u2394 from '!!raw-loader!../../../../../public/img/icons/default/relation-many-to-many.svg';
import u2395 from '!!raw-loader!../../../../../public/img/icons/default/relation-one-to-many.svg';
import u2396 from '!!raw-loader!../../../../../public/img/icons/default/relation-one-to-one.svg';
import u2397 from '!!raw-loader!../../../../../public/img/icons/default/repeat-once.svg';
import u2398 from '!!raw-loader!../../../../../public/img/icons/default/repeat.svg';
import u2399 from '!!raw-loader!../../../../../public/img/icons/default/replace.svg';
import u2400 from '!!raw-loader!../../../../../public/img/icons/default/report-analytics.svg';
import u2401 from '!!raw-loader!../../../../../public/img/icons/default/report-medical.svg';
import u2402 from '!!raw-loader!../../../../../public/img/icons/default/report-money.svg';
import u2403 from '!!raw-loader!../../../../../public/img/icons/default/report-search.svg';
import u2404 from '!!raw-loader!../../../../../public/img/icons/default/report.svg';
import u2405 from '!!raw-loader!../../../../../public/img/icons/default/resize.svg';
import u2406 from '!!raw-loader!../../../../../public/img/icons/default/ripple.svg';
import u2407 from '!!raw-loader!../../../../../public/img/icons/default/road-sign.svg';
import u2408 from '!!raw-loader!../../../../../public/img/icons/default/road.svg';
import u2409 from '!!raw-loader!../../../../../public/img/icons/default/robot.svg';
import u2410 from '!!raw-loader!../../../../../public/img/icons/default/rocket.svg';
import u2411 from '!!raw-loader!../../../../../public/img/icons/default/roller-skating.svg';
import u2412 from '!!raw-loader!../../../../../public/img/icons/default/rollercoaster.svg';
import u2413 from '!!raw-loader!../../../../../public/img/icons/default/rotate-2.svg';
import u2414 from '!!raw-loader!../../../../../public/img/icons/default/rotate-360.svg';
import u2415 from '!!raw-loader!../../../../../public/img/icons/default/rotate-clockwise-2.svg';
import u2416 from '!!raw-loader!../../../../../public/img/icons/default/rotate-clockwise.svg';
import u2417 from '!!raw-loader!../../../../../public/img/icons/default/rotate-dot.svg';
import u2418 from '!!raw-loader!../../../../../public/img/icons/default/rotate-rectangle.svg';
import u2419 from '!!raw-loader!../../../../../public/img/icons/default/rotate.svg';
import u2420 from '!!raw-loader!../../../../../public/img/icons/default/route.svg';
import u2421 from '!!raw-loader!../../../../../public/img/icons/default/router.svg';
import u2422 from '!!raw-loader!../../../../../public/img/icons/default/row-insert-bottom.svg';
import u2423 from '!!raw-loader!../../../../../public/img/icons/default/row-insert-top.svg';
import u2424 from '!!raw-loader!../../../../../public/img/icons/default/rss.svg';
import u2425 from '!!raw-loader!../../../../../public/img/icons/default/ruler-2.svg';
import u2426 from '!!raw-loader!../../../../../public/img/icons/default/ruler.svg';
import u2427 from '!!raw-loader!../../../../../public/img/icons/default/run.svg';
import u2428 from '!!raw-loader!../../../../../public/img/icons/default/sailboat.svg';
import u2429 from '!!raw-loader!../../../../../public/img/icons/default/salt.svg';
import u2430 from '!!raw-loader!../../../../../public/img/icons/default/satellite.svg';
import u2431 from '!!raw-loader!../../../../../public/img/icons/default/sausage.svg';
import u2432 from '!!raw-loader!../../../../../public/img/icons/default/scale-outline.svg';
import u2433 from '!!raw-loader!../../../../../public/img/icons/default/scale.svg';
import u2434 from '!!raw-loader!../../../../../public/img/icons/default/scan.svg';
import u2435 from '!!raw-loader!../../../../../public/img/icons/default/school.svg';
import u2436 from '!!raw-loader!../../../../../public/img/icons/default/scissors.svg';
import u2437 from '!!raw-loader!../../../../../public/img/icons/default/scooter-electric.svg';
import u2438 from '!!raw-loader!../../../../../public/img/icons/default/scooter.svg';
import u2439 from '!!raw-loader!../../../../../public/img/icons/default/screen-share-off.svg';
import u2440 from '!!raw-loader!../../../../../public/img/icons/default/screen-share.svg';
import u2441 from '!!raw-loader!../../../../../public/img/icons/default/scribble.svg';
import u2442 from '!!raw-loader!../../../../../public/img/icons/default/scuba-mask.svg';
import u2443 from '!!raw-loader!../../../../../public/img/icons/default/search.svg';
import u2444 from '!!raw-loader!../../../../../public/img/icons/default/section-sign.svg';
import u2445 from '!!raw-loader!../../../../../public/img/icons/default/section.svg';
import u2446 from '!!raw-loader!../../../../../public/img/icons/default/seeding.svg';
import u2447 from '!!raw-loader!../../../../../public/img/icons/default/select.svg';
import u2448 from '!!raw-loader!../../../../../public/img/icons/default/selector.svg';
import u2449 from '!!raw-loader!../../../../../public/img/icons/default/send.svg';
import u2450 from '!!raw-loader!../../../../../public/img/icons/default/separator-horizontal.svg';
import u2451 from '!!raw-loader!../../../../../public/img/icons/default/separator-vertical.svg';
import u2452 from '!!raw-loader!../../../../../public/img/icons/default/separator.svg';
import u2453 from '!!raw-loader!../../../../../public/img/icons/default/server-2.svg';
import u2454 from '!!raw-loader!../../../../../public/img/icons/default/server.svg';
import u2455 from '!!raw-loader!../../../../../public/img/icons/default/servicemark.svg';
import u2456 from '!!raw-loader!../../../../../public/img/icons/default/settings-automation.svg';
import u2457 from '!!raw-loader!../../../../../public/img/icons/default/settings.svg';
import u2458 from '!!raw-loader!../../../../../public/img/icons/default/shadow-off.svg';
import u2459 from '!!raw-loader!../../../../../public/img/icons/default/shadow.svg';
import u2460 from '!!raw-loader!../../../../../public/img/icons/default/shape-2.svg';
import u2461 from '!!raw-loader!../../../../../public/img/icons/default/shape-3.svg';
import u2462 from '!!raw-loader!../../../../../public/img/icons/default/shape.svg';
import u2463 from '!!raw-loader!../../../../../public/img/icons/default/share.svg';
import u2464 from '!!raw-loader!../../../../../public/img/icons/default/shield-check.svg';
import u2465 from '!!raw-loader!../../../../../public/img/icons/default/shield-checkered.svg';
import u2466 from '!!raw-loader!../../../../../public/img/icons/default/shield-chevron.svg';
import u2467 from '!!raw-loader!../../../../../public/img/icons/default/shield-lock.svg';
import u2468 from '!!raw-loader!../../../../../public/img/icons/default/shield-off.svg';
import u2469 from '!!raw-loader!../../../../../public/img/icons/default/shield-x.svg';
import u2470 from '!!raw-loader!../../../../../public/img/icons/default/shield.svg';
import u2471 from '!!raw-loader!../../../../../public/img/icons/default/ship.svg';
import u2472 from '!!raw-loader!../../../../../public/img/icons/default/shirt.svg';
import u2473 from '!!raw-loader!../../../../../public/img/icons/default/shoe.svg';
import u2474 from '!!raw-loader!../../../../../public/img/icons/default/shopping-cart-discount.svg';
import u2475 from '!!raw-loader!../../../../../public/img/icons/default/shopping-cart-off.svg';
import u2476 from '!!raw-loader!../../../../../public/img/icons/default/shopping-cart-plus.svg';
import u2477 from '!!raw-loader!../../../../../public/img/icons/default/shopping-cart-x.svg';
import u2478 from '!!raw-loader!../../../../../public/img/icons/default/shopping-cart.svg';
import u2479 from '!!raw-loader!../../../../../public/img/icons/default/shredder.svg';
import u2480 from '!!raw-loader!../../../../../public/img/icons/default/sign-left.svg';
import u2481 from '!!raw-loader!../../../../../public/img/icons/default/sign-right.svg';
import u2482 from '!!raw-loader!../../../../../public/img/icons/default/signature.svg';
import u2483 from '!!raw-loader!../../../../../public/img/icons/default/sitemap.svg';
import u2484 from '!!raw-loader!../../../../../public/img/icons/default/skateboard.svg';
import u2485 from '!!raw-loader!../../../../../public/img/icons/default/sleigh.svg';
import u2486 from '!!raw-loader!../../../../../public/img/icons/default/slice.svg';
import u2487 from '!!raw-loader!../../../../../public/img/icons/default/slideshow.svg';
import u2488 from '!!raw-loader!../../../../../public/img/icons/default/smart-home.svg';
import u2489 from '!!raw-loader!../../../../../public/img/icons/default/smoking-no.svg';
import u2490 from '!!raw-loader!../../../../../public/img/icons/default/smoking.svg';
import u2491 from '!!raw-loader!../../../../../public/img/icons/default/snowflake.svg';
import u2492 from '!!raw-loader!../../../../../public/img/icons/default/soccer-field.svg';
import u2493 from '!!raw-loader!../../../../../public/img/icons/default/social.svg';
import u2494 from '!!raw-loader!../../../../../public/img/icons/default/sock.svg';
import u2495 from '!!raw-loader!../../../../../public/img/icons/default/sofa.svg';
import u2496 from '!!raw-loader!../../../../../public/img/icons/default/sort-ascending-2.svg';
import u2497 from '!!raw-loader!../../../../../public/img/icons/default/sort-ascending-letters.svg';
import u2498 from '!!raw-loader!../../../../../public/img/icons/default/sort-ascending-numbers.svg';
import u2499 from '!!raw-loader!../../../../../public/img/icons/default/sort-ascending.svg';
import u2500 from '!!raw-loader!../../../../../public/img/icons/default/sort-descending-2.svg';
import u2501 from '!!raw-loader!../../../../../public/img/icons/default/sort-descending-letters.svg';
import u2502 from '!!raw-loader!../../../../../public/img/icons/default/sort-descending-numbers.svg';
import u2503 from '!!raw-loader!../../../../../public/img/icons/default/sort-descending.svg';
import u2504 from '!!raw-loader!../../../../../public/img/icons/default/soup.svg';
import u2505 from '!!raw-loader!../../../../../public/img/icons/default/space.svg';
import u2506 from '!!raw-loader!../../../../../public/img/icons/default/spacing-horizontal.svg';
import u2507 from '!!raw-loader!../../../../../public/img/icons/default/spacing-vertical.svg';
import u2508 from '!!raw-loader!../../../../../public/img/icons/default/spade.svg';
import u2509 from '!!raw-loader!../../../../../public/img/icons/default/speakerphone.svg';
import u2510 from '!!raw-loader!../../../../../public/img/icons/default/speedboat.svg';
import u2511 from '!!raw-loader!../../../../../public/img/icons/default/sport-billard.svg';
import u2512 from '!!raw-loader!../../../../../public/img/icons/default/square-0.svg';
import u2513 from '!!raw-loader!../../../../../public/img/icons/default/square-1.svg';
import u2514 from '!!raw-loader!../../../../../public/img/icons/default/square-2.svg';
import u2515 from '!!raw-loader!../../../../../public/img/icons/default/square-3.svg';
import u2516 from '!!raw-loader!../../../../../public/img/icons/default/square-4.svg';
import u2517 from '!!raw-loader!../../../../../public/img/icons/default/square-5.svg';
import u2518 from '!!raw-loader!../../../../../public/img/icons/default/square-6.svg';
import u2519 from '!!raw-loader!../../../../../public/img/icons/default/square-7.svg';
import u2520 from '!!raw-loader!../../../../../public/img/icons/default/square-8.svg';
import u2521 from '!!raw-loader!../../../../../public/img/icons/default/square-9.svg';
import u2522 from '!!raw-loader!../../../../../public/img/icons/default/square-asterisk.svg';
import u2523 from '!!raw-loader!../../../../../public/img/icons/default/square-check.svg';
import u2524 from '!!raw-loader!../../../../../public/img/icons/default/square-dot.svg';
import u2525 from '!!raw-loader!../../../../../public/img/icons/default/square-forbid-2.svg';
import u2526 from '!!raw-loader!../../../../../public/img/icons/default/square-forbid.svg';
import u2527 from '!!raw-loader!../../../../../public/img/icons/default/square-half.svg';
import u2528 from '!!raw-loader!../../../../../public/img/icons/default/square-minus.svg';
import u2529 from '!!raw-loader!../../../../../public/img/icons/default/square-off.svg';
import u2530 from '!!raw-loader!../../../../../public/img/icons/default/square-plus.svg';
import u2531 from '!!raw-loader!../../../../../public/img/icons/default/square-root-2.svg';
import u2532 from '!!raw-loader!../../../../../public/img/icons/default/square-root.svg';
import u2533 from '!!raw-loader!../../../../../public/img/icons/default/square-rotated-forbid-2.svg';
import u2534 from '!!raw-loader!../../../../../public/img/icons/default/square-rotated-forbid.svg';
import u2535 from '!!raw-loader!../../../../../public/img/icons/default/square-rotated-off.svg';
import u2536 from '!!raw-loader!../../../../../public/img/icons/default/square-rotated.svg';
import u2537 from '!!raw-loader!../../../../../public/img/icons/default/square-toggle-horizontal.svg';
import u2538 from '!!raw-loader!../../../../../public/img/icons/default/square-toggle.svg';
import u2539 from '!!raw-loader!../../../../../public/img/icons/default/square-x.svg';
import u2540 from '!!raw-loader!../../../../../public/img/icons/default/square.svg';
import u2541 from '!!raw-loader!../../../../../public/img/icons/default/squares-diagonal.svg';
import u2542 from '!!raw-loader!../../../../../public/img/icons/default/squares-filled.svg';
import u2543 from '!!raw-loader!../../../../../public/img/icons/default/stack-2.svg';
import u2544 from '!!raw-loader!../../../../../public/img/icons/default/stack-3.svg';
import u2545 from '!!raw-loader!../../../../../public/img/icons/default/stack.svg';
import u2546 from '!!raw-loader!../../../../../public/img/icons/default/stairs-down.svg';
import u2547 from '!!raw-loader!../../../../../public/img/icons/default/stairs-up.svg';
import u2548 from '!!raw-loader!../../../../../public/img/icons/default/stairs.svg';
import u2549 from '!!raw-loader!../../../../../public/img/icons/default/star-half.svg';
import u2550 from '!!raw-loader!../../../../../public/img/icons/default/star-off.svg';
import u2551 from '!!raw-loader!../../../../../public/img/icons/default/star.svg';
import u2552 from '!!raw-loader!../../../../../public/img/icons/default/stars.svg';
import u2553 from '!!raw-loader!../../../../../public/img/icons/default/steering-wheel.svg';
import u2554 from '!!raw-loader!../../../../../public/img/icons/default/step-into.svg';
import u2555 from '!!raw-loader!../../../../../public/img/icons/default/step-out.svg';
import u2556 from '!!raw-loader!../../../../../public/img/icons/default/stethoscope.svg';
import u2557 from '!!raw-loader!../../../../../public/img/icons/default/sticker.svg';
import u2558 from '!!raw-loader!../../../../../public/img/icons/default/strikethrough.svg';
import u2559 from '!!raw-loader!../../../../../public/img/icons/default/submarine.svg';
import u2560 from '!!raw-loader!../../../../../public/img/icons/default/subscript.svg';
import u2561 from '!!raw-loader!../../../../../public/img/icons/default/subtask.svg';
import u2562 from '!!raw-loader!../../../../../public/img/icons/default/sum.svg';
import u2563 from '!!raw-loader!../../../../../public/img/icons/default/sun-off.svg';
import u2564 from '!!raw-loader!../../../../../public/img/icons/default/sun.svg';
import u2565 from '!!raw-loader!../../../../../public/img/icons/default/sunrise.svg';
import u2566 from '!!raw-loader!../../../../../public/img/icons/default/sunset.svg';
import u2567 from '!!raw-loader!../../../../../public/img/icons/default/superscript.svg';
import u2568 from '!!raw-loader!../../../../../public/img/icons/default/swimming.svg';
import u2569 from '!!raw-loader!../../../../../public/img/icons/default/switch-2.svg';
import u2570 from '!!raw-loader!../../../../../public/img/icons/default/switch-3.svg';
import u2571 from '!!raw-loader!../../../../../public/img/icons/default/switch-horizontal.svg';
import u2572 from '!!raw-loader!../../../../../public/img/icons/default/switch-vertical.svg';
import u2573 from '!!raw-loader!../../../../../public/img/icons/default/switch.svg';
import u2574 from '!!raw-loader!../../../../../public/img/icons/default/sword.svg';
import u2575 from '!!raw-loader!../../../../../public/img/icons/default/table-export.svg';
import u2576 from '!!raw-loader!../../../../../public/img/icons/default/table-import.svg';
import u2577 from '!!raw-loader!../../../../../public/img/icons/default/table-off.svg';
import u2578 from '!!raw-loader!../../../../../public/img/icons/default/table.svg';
import u2579 from '!!raw-loader!../../../../../public/img/icons/default/tag-off.svg';
import u2580 from '!!raw-loader!../../../../../public/img/icons/default/tag.svg';
import u2581 from '!!raw-loader!../../../../../public/img/icons/default/tags-off.svg';
import u2582 from '!!raw-loader!../../../../../public/img/icons/default/tags.svg';
import u2583 from '!!raw-loader!../../../../../public/img/icons/default/tallymark-1.svg';
import u2584 from '!!raw-loader!../../../../../public/img/icons/default/tallymark-2.svg';
import u2585 from '!!raw-loader!../../../../../public/img/icons/default/tallymark-3.svg';
import u2586 from '!!raw-loader!../../../../../public/img/icons/default/tallymark-4.svg';
import u2587 from '!!raw-loader!../../../../../public/img/icons/default/tallymarks.svg';
import u2588 from '!!raw-loader!../../../../../public/img/icons/default/tank.svg';
import u2589 from '!!raw-loader!../../../../../public/img/icons/default/target.svg';
import u2590 from '!!raw-loader!../../../../../public/img/icons/default/telescope.svg';
import u2591 from '!!raw-loader!../../../../../public/img/icons/default/temperature-celsius.svg';
import u2592 from '!!raw-loader!../../../../../public/img/icons/default/temperature-fahrenheit.svg';
import u2593 from '!!raw-loader!../../../../../public/img/icons/default/temperature-minus.svg';
import u2594 from '!!raw-loader!../../../../../public/img/icons/default/temperature-plus.svg';
import u2595 from '!!raw-loader!../../../../../public/img/icons/default/temperature.svg';
import u2596 from '!!raw-loader!../../../../../public/img/icons/default/template.svg';
import u2597 from '!!raw-loader!../../../../../public/img/icons/default/tent.svg';
import u2598 from '!!raw-loader!../../../../../public/img/icons/default/terminal-2.svg';
import u2599 from '!!raw-loader!../../../../../public/img/icons/default/terminal.svg';
import u2600 from '!!raw-loader!../../../../../public/img/icons/default/test-pipe-2.svg';
import u2601 from '!!raw-loader!../../../../../public/img/icons/default/test-pipe.svg';
import u2602 from '!!raw-loader!../../../../../public/img/icons/default/text-direction-ltr.svg';
import u2603 from '!!raw-loader!../../../../../public/img/icons/default/text-direction-rtl.svg';
import u2604 from '!!raw-loader!../../../../../public/img/icons/default/text-resize.svg';
import u2605 from '!!raw-loader!../../../../../public/img/icons/default/text-wrap-disabled.svg';
import u2606 from '!!raw-loader!../../../../../public/img/icons/default/text-wrap.svg';
import u2607 from '!!raw-loader!../../../../../public/img/icons/default/thermometer.svg';
import u2608 from '!!raw-loader!../../../../../public/img/icons/default/thumb-down.svg';
import u2609 from '!!raw-loader!../../../../../public/img/icons/default/thumb-up.svg';
import u2610 from '!!raw-loader!../../../../../public/img/icons/default/ticket.svg';
import u2611 from '!!raw-loader!../../../../../public/img/icons/default/tie.svg';
import u2612 from '!!raw-loader!../../../../../public/img/icons/default/tilt-shift.svg';
import u2613 from '!!raw-loader!../../../../../public/img/icons/default/timeline.svg';
import u2614 from '!!raw-loader!../../../../../public/img/icons/default/tir.svg';
import u2615 from '!!raw-loader!../../../../../public/img/icons/default/toggle-left.svg';
import u2616 from '!!raw-loader!../../../../../public/img/icons/default/toggle-right.svg';
import u2617 from '!!raw-loader!../../../../../public/img/icons/default/toilet-paper.svg';
import u2618 from '!!raw-loader!../../../../../public/img/icons/default/tool.svg';
import u2619 from '!!raw-loader!../../../../../public/img/icons/default/tools-kitchen-2.svg';
import u2620 from '!!raw-loader!../../../../../public/img/icons/default/tools-kitchen.svg';
import u2621 from '!!raw-loader!../../../../../public/img/icons/default/tools.svg';
import u2622 from '!!raw-loader!../../../../../public/img/icons/default/tornado.svg';
import u2623 from '!!raw-loader!../../../../../public/img/icons/default/tournament.svg';
import u2624 from '!!raw-loader!../../../../../public/img/icons/default/track.svg';
import u2625 from '!!raw-loader!../../../../../public/img/icons/default/tractor.svg';
import u2626 from '!!raw-loader!../../../../../public/img/icons/default/trademark.svg';
import u2627 from '!!raw-loader!../../../../../public/img/icons/default/traffic-cone.svg';
import u2628 from '!!raw-loader!../../../../../public/img/icons/default/traffic-lights.svg';
import u2629 from '!!raw-loader!../../../../../public/img/icons/default/train.svg';
import u2630 from '!!raw-loader!../../../../../public/img/icons/default/transfer-in.svg';
import u2631 from '!!raw-loader!../../../../../public/img/icons/default/transfer-out.svg';
import u2632 from '!!raw-loader!../../../../../public/img/icons/default/trash-off.svg';
import u2633 from '!!raw-loader!../../../../../public/img/icons/default/trash-x.svg';
import u2634 from '!!raw-loader!../../../../../public/img/icons/default/trash.svg';
import u2635 from '!!raw-loader!../../../../../public/img/icons/default/tree.svg';
import u2636 from '!!raw-loader!../../../../../public/img/icons/default/trees.svg';
import u2637 from '!!raw-loader!../../../../../public/img/icons/default/trending-down-2.svg';
import u2638 from '!!raw-loader!../../../../../public/img/icons/default/trending-down-3.svg';
import u2639 from '!!raw-loader!../../../../../public/img/icons/default/trending-down.svg';
import u2640 from '!!raw-loader!../../../../../public/img/icons/default/trending-up-2.svg';
import u2641 from '!!raw-loader!../../../../../public/img/icons/default/trending-up-3.svg';
import u2642 from '!!raw-loader!../../../../../public/img/icons/default/trending-up.svg';
import u2643 from '!!raw-loader!../../../../../public/img/icons/default/triangle-inverted.svg';
import u2644 from '!!raw-loader!../../../../../public/img/icons/default/triangle-off.svg';
import u2645 from '!!raw-loader!../../../../../public/img/icons/default/triangle-square-circle.svg';
import u2646 from '!!raw-loader!../../../../../public/img/icons/default/triangle.svg';
import u2647 from '!!raw-loader!../../../../../public/img/icons/default/triangles.svg';
import u2648 from '!!raw-loader!../../../../../public/img/icons/default/trident.svg';
import u2649 from '!!raw-loader!../../../../../public/img/icons/default/trophy.svg';
import u2650 from '!!raw-loader!../../../../../public/img/icons/default/truck-delivery.svg';
import u2651 from '!!raw-loader!../../../../../public/img/icons/default/truck-off.svg';
import u2652 from '!!raw-loader!../../../../../public/img/icons/default/truck-return.svg';
import u2653 from '!!raw-loader!../../../../../public/img/icons/default/truck.svg';
import u2654 from '!!raw-loader!../../../../../public/img/icons/default/typography.svg';
import u2655 from '!!raw-loader!../../../../../public/img/icons/default/umbrella.svg';
import u2656 from '!!raw-loader!../../../../../public/img/icons/default/underline.svg';
import u2657 from '!!raw-loader!../../../../../public/img/icons/default/unlink.svg';
import u2658 from '!!raw-loader!../../../../../public/img/icons/default/upload.svg';
import u2659 from '!!raw-loader!../../../../../public/img/icons/default/urgent.svg';
import u2660 from '!!raw-loader!../../../../../public/img/icons/default/usb.svg';
import u2661 from '!!raw-loader!../../../../../public/img/icons/default/user-check.svg';
import u2662 from '!!raw-loader!../../../../../public/img/icons/default/user-circle.svg';
import u2663 from '!!raw-loader!../../../../../public/img/icons/default/user-exclamation.svg';
import u2664 from '!!raw-loader!../../../../../public/img/icons/default/user-minus.svg';
import u2665 from '!!raw-loader!../../../../../public/img/icons/default/user-off.svg';
import u2666 from '!!raw-loader!../../../../../public/img/icons/default/user-plus.svg';
import u2667 from '!!raw-loader!../../../../../public/img/icons/default/user-search.svg';
import u2668 from '!!raw-loader!../../../../../public/img/icons/default/user-x.svg';
import u2669 from '!!raw-loader!../../../../../public/img/icons/default/user.svg';
import u2670 from '!!raw-loader!../../../../../public/img/icons/default/users.svg';
import u2671 from '!!raw-loader!../../../../../public/img/icons/default/vaccine-bottle.svg';
import u2672 from '!!raw-loader!../../../../../public/img/icons/default/vaccine.svg';
import u2673 from '!!raw-loader!../../../../../public/img/icons/default/variable.svg';
import u2674 from '!!raw-loader!../../../../../public/img/icons/default/vector-beizer-2.svg';
import u2675 from '!!raw-loader!../../../../../public/img/icons/default/vector-bezier.svg';
import u2676 from '!!raw-loader!../../../../../public/img/icons/default/vector-triangle.svg';
import u2677 from '!!raw-loader!../../../../../public/img/icons/default/vector.svg';
import u2678 from '!!raw-loader!../../../../../public/img/icons/default/venus.svg';
import u2679 from '!!raw-loader!../../../../../public/img/icons/default/versions.svg';
import u2680 from '!!raw-loader!../../../../../public/img/icons/default/video-minus.svg';
import u2681 from '!!raw-loader!../../../../../public/img/icons/default/video-off.svg';
import u2682 from '!!raw-loader!../../../../../public/img/icons/default/video-plus.svg';
import u2683 from '!!raw-loader!../../../../../public/img/icons/default/video.svg';
import u2684 from '!!raw-loader!../../../../../public/img/icons/default/view-360.svg';
import u2685 from '!!raw-loader!../../../../../public/img/icons/default/viewfinder.svg';
import u2686 from '!!raw-loader!../../../../../public/img/icons/default/viewport-narrow.svg';
import u2687 from '!!raw-loader!../../../../../public/img/icons/default/viewport-wide.svg';
import u2688 from '!!raw-loader!../../../../../public/img/icons/default/vinyl.svg';
import u2689 from '!!raw-loader!../../../../../public/img/icons/default/virus-off.svg';
import u2690 from '!!raw-loader!../../../../../public/img/icons/default/virus-search.svg';
import u2691 from '!!raw-loader!../../../../../public/img/icons/default/virus.svg';
import u2692 from '!!raw-loader!../../../../../public/img/icons/default/vocabulary.svg';
import u2693 from '!!raw-loader!../../../../../public/img/icons/default/volume-2.svg';
import u2694 from '!!raw-loader!../../../../../public/img/icons/default/volume-3.svg';
import u2695 from '!!raw-loader!../../../../../public/img/icons/default/volume.svg';
import u2696 from '!!raw-loader!../../../../../public/img/icons/default/walk.svg';
import u2697 from '!!raw-loader!../../../../../public/img/icons/default/wall.svg';
import u2698 from '!!raw-loader!../../../../../public/img/icons/default/wallet.svg';
import u2699 from '!!raw-loader!../../../../../public/img/icons/default/wallpaper.svg';
import u2700 from '!!raw-loader!../../../../../public/img/icons/default/wand.svg';
import u2701 from '!!raw-loader!../../../../../public/img/icons/default/wave-saw-tool.svg';
import u2702 from '!!raw-loader!../../../../../public/img/icons/default/wave-sine.svg';
import u2703 from '!!raw-loader!../../../../../public/img/icons/default/wave-square.svg';
import u2704 from '!!raw-loader!../../../../../public/img/icons/default/webhook.svg';
import u2705 from '!!raw-loader!../../../../../public/img/icons/default/wifi-0.svg';
import u2706 from '!!raw-loader!../../../../../public/img/icons/default/wifi-1.svg';
import u2707 from '!!raw-loader!../../../../../public/img/icons/default/wifi-2.svg';
import u2708 from '!!raw-loader!../../../../../public/img/icons/default/wifi-off.svg';
import u2709 from '!!raw-loader!../../../../../public/img/icons/default/wifi.svg';
import u2710 from '!!raw-loader!../../../../../public/img/icons/default/wind.svg';
import u2711 from '!!raw-loader!../../../../../public/img/icons/default/windmill.svg';
import u2712 from '!!raw-loader!../../../../../public/img/icons/default/window.svg';
import u2713 from '!!raw-loader!../../../../../public/img/icons/default/windsock.svg';
import u2714 from '!!raw-loader!../../../../../public/img/icons/default/wiper-wash.svg';
import u2715 from '!!raw-loader!../../../../../public/img/icons/default/wiper.svg';
import u2716 from '!!raw-loader!../../../../../public/img/icons/default/woman.svg';
import u2717 from '!!raw-loader!../../../../../public/img/icons/default/world-download.svg';
import u2718 from '!!raw-loader!../../../../../public/img/icons/default/world-latitude.svg';
import u2719 from '!!raw-loader!../../../../../public/img/icons/default/world-longitude.svg';
import u2720 from '!!raw-loader!../../../../../public/img/icons/default/world-upload.svg';
import u2721 from '!!raw-loader!../../../../../public/img/icons/default/world.svg';
import u2722 from '!!raw-loader!../../../../../public/img/icons/default/wrecking-ball.svg';
import u2723 from '!!raw-loader!../../../../../public/img/icons/default/writing-sign.svg';
import u2724 from '!!raw-loader!../../../../../public/img/icons/default/writing.svg';
import u2725 from '!!raw-loader!../../../../../public/img/icons/default/x.svg';
import u2726 from '!!raw-loader!../../../../../public/img/icons/default/yin-yang.svg';
import u2727 from '!!raw-loader!../../../../../public/img/icons/default/yoga.svg';
import u2728 from '!!raw-loader!../../../../../public/img/icons/default/zodiac-aquarius.svg';
import u2729 from '!!raw-loader!../../../../../public/img/icons/default/zodiac-aries.svg';
import u2730 from '!!raw-loader!../../../../../public/img/icons/default/zodiac-cancer.svg';
import u2731 from '!!raw-loader!../../../../../public/img/icons/default/zodiac-capricorn.svg';
import u2732 from '!!raw-loader!../../../../../public/img/icons/default/zodiac-gemini.svg';
import u2733 from '!!raw-loader!../../../../../public/img/icons/default/zodiac-leo.svg';
import u2734 from '!!raw-loader!../../../../../public/img/icons/default/zodiac-libra.svg';
import u2735 from '!!raw-loader!../../../../../public/img/icons/default/zodiac-pisces.svg';
import u2736 from '!!raw-loader!../../../../../public/img/icons/default/zodiac-sagittarius.svg';
import u2737 from '!!raw-loader!../../../../../public/img/icons/default/zodiac-scorpio.svg';
import u2738 from '!!raw-loader!../../../../../public/img/icons/default/zodiac-taurus.svg';
import u2739 from '!!raw-loader!../../../../../public/img/icons/default/zodiac-virgo.svg';
import u2740 from '!!raw-loader!../../../../../public/img/icons/default/zoom-cancel.svg';
import u2741 from '!!raw-loader!../../../../../public/img/icons/default/zoom-check.svg';
import u2742 from '!!raw-loader!../../../../../public/img/icons/default/zoom-code.svg';
import u2743 from '!!raw-loader!../../../../../public/img/icons/default/zoom-exclamation.svg';
import u2744 from '!!raw-loader!../../../../../public/img/icons/default/zoom-in.svg';
import u2745 from '!!raw-loader!../../../../../public/img/icons/default/zoom-money.svg';
import u2746 from '!!raw-loader!../../../../../public/img/icons/default/zoom-out.svg';
import u2747 from '!!raw-loader!../../../../../public/img/icons/default/zoom-question.svg';

export function initIconCache() {
  cacheInitialized = true;

  // This function needs to be called after index.js loads to give the
  // application time to modify __webpack_public_path__ with a CDN path
  const publicPath = (window as any).__carbon_public_path__;
  if (publicPath) {
    iconRoot = publicPath + 'img/icons/';
  }
  cacheItem(u1001, 'default/2fa.svg');
  cacheItem(u1002, 'default/3d-cube-sphere.svg');
  cacheItem(u1003, 'default/3d-rotate.svg');
  cacheItem(u1004, 'default/a-b-off.svg');
  cacheItem(u1005, 'default/a-b.svg');
  cacheItem(u1006, 'default/abacus.svg');
  cacheItem(u1007, 'default/access-point-off.svg');
  cacheItem(u1008, 'default/access-point.svg');
  cacheItem(u1009, 'default/accessible-off.svg');
  cacheItem(u1010, 'default/accessible.svg');
  cacheItem(u1011, 'default/activity-heartbeat.svg');
  cacheItem(u1012, 'default/activity.svg');
  cacheItem(u1013, 'default/ad-2.svg');
  cacheItem(u1014, 'default/ad.svg');
  cacheItem(u1015, 'default/address-book.svg');
  cacheItem(u1016, 'default/adjustments-alt.svg');
  cacheItem(u1017, 'default/adjustments-horizontal.svg');
  cacheItem(u1018, 'default/adjustments-off.svg');
  cacheItem(u1019, 'default/adjustments.svg');
  cacheItem(u1020, 'default/aerial-lift.svg');
  cacheItem(u1021, 'default/affiliate.svg');
  cacheItem(u1022, 'default/alarm-off.svg');
  cacheItem(u1023, 'default/alarm.svg');
  cacheItem(u1024, 'default/album.svg');
  cacheItem(u1025, 'default/alert-circle.svg');
  cacheItem(u1026, 'default/alert-octagon.svg');
  cacheItem(u1027, 'default/alert-triangle.svg');
  cacheItem(u1028, 'default/alien.svg');
  cacheItem(u1029, 'default/align-center.svg');
  cacheItem(u1030, 'default/align-justified.svg');
  cacheItem(u1031, 'default/align-left.svg');
  cacheItem(u1032, 'default/align-right.svg');
  cacheItem(u1033, 'default/ambulance.svg');
  cacheItem(u1034, 'default/anchor.svg');
  cacheItem(u1035, 'default/angle.svg');
  cacheItem(u1036, 'default/antenna-bars-1.svg');
  cacheItem(u1037, 'default/antenna-bars-2.svg');
  cacheItem(u1038, 'default/antenna-bars-3.svg');
  cacheItem(u1039, 'default/antenna-bars-4.svg');
  cacheItem(u1040, 'default/antenna-bars-5.svg');
  cacheItem(u1041, 'default/antenna-bars-off.svg');
  cacheItem(u1042, 'default/antenna.svg');
  cacheItem(u1043, 'default/aperture.svg');
  cacheItem(u1044, 'default/api-app-off.svg');
  cacheItem(u1045, 'default/api-app.svg');
  cacheItem(u1046, 'default/api.svg');
  cacheItem(u1047, 'default/app-window.svg');
  cacheItem(u1048, 'default/apple.svg');
  cacheItem(u1049, 'default/apps-off.svg');
  cacheItem(u1050, 'default/apps.svg');
  cacheItem(u1051, 'default/archive-off.svg');
  cacheItem(u1052, 'default/archive.svg');
  cacheItem(u1053, 'default/armchair-2.svg');
  cacheItem(u1054, 'default/armchair.svg');
  cacheItem(u1055, 'default/arrow-autofit-content.svg');
  cacheItem(u1056, 'default/arrow-autofit-down.svg');
  cacheItem(u1057, 'default/arrow-autofit-height.svg');
  cacheItem(u1058, 'default/arrow-autofit-left.svg');
  cacheItem(u1059, 'default/arrow-autofit-right.svg');
  cacheItem(u1060, 'default/arrow-autofit-up.svg');
  cacheItem(u1061, 'default/arrow-autofit-width.svg');
  cacheItem(u1062, 'default/arrow-back-up.svg');
  cacheItem(u1063, 'default/arrow-back.svg');
  cacheItem(u1064, 'default/arrow-bar-down.svg');
  cacheItem(u1065, 'default/arrow-bar-left.svg');
  cacheItem(u1066, 'default/arrow-bar-right.svg');
  cacheItem(u1067, 'default/arrow-bar-to-down.svg');
  cacheItem(u1068, 'default/arrow-bar-to-left.svg');
  cacheItem(u1069, 'default/arrow-bar-to-right.svg');
  cacheItem(u1070, 'default/arrow-bar-to-up.svg');
  cacheItem(u1071, 'default/arrow-bar-up.svg');
  cacheItem(u1072, 'default/arrow-bear-left-2.svg');
  cacheItem(u1073, 'default/arrow-bear-left.svg');
  cacheItem(u1074, 'default/arrow-bear-right-2.svg');
  cacheItem(u1075, 'default/arrow-bear-right.svg');
  cacheItem(u1076, 'default/arrow-big-down-line.svg');
  cacheItem(u1077, 'default/arrow-big-down-lines.svg');
  cacheItem(u1078, 'default/arrow-big-down.svg');
  cacheItem(u1079, 'default/arrow-big-left-line.svg');
  cacheItem(u1080, 'default/arrow-big-left-lines.svg');
  cacheItem(u1081, 'default/arrow-big-left.svg');
  cacheItem(u1082, 'default/arrow-big-right-line.svg');
  cacheItem(u1083, 'default/arrow-big-right-lines.svg');
  cacheItem(u1084, 'default/arrow-big-right.svg');
  cacheItem(u1085, 'default/arrow-big-top.svg');
  cacheItem(u1086, 'default/arrow-big-up-line.svg');
  cacheItem(u1087, 'default/arrow-big-up-lines.svg');
  cacheItem(u1088, 'default/arrow-bottom-bar.svg');
  cacheItem(u1089, 'default/arrow-bottom-circle.svg');
  cacheItem(u1090, 'default/arrow-bottom-square.svg');
  cacheItem(u1091, 'default/arrow-bottom-tail.svg');
  cacheItem(u1092, 'default/arrow-curve-left.svg');
  cacheItem(u1093, 'default/arrow-curve-right.svg');
  cacheItem(u1094, 'default/arrow-down-circle.svg');
  cacheItem(u1095, 'default/arrow-down-left-circle.svg');
  cacheItem(u1096, 'default/arrow-down-left.svg');
  cacheItem(u1097, 'default/arrow-down-right-circle.svg');
  cacheItem(u1098, 'default/arrow-down-right.svg');
  cacheItem(u1099, 'default/arrow-down.svg');
  cacheItem(u1100, 'default/arrow-fork.svg');
  cacheItem(u1101, 'default/arrow-forward-up.svg');
  cacheItem(u1102, 'default/arrow-forward.svg');
  cacheItem(u1103, 'default/arrow-left-bar.svg');
  cacheItem(u1104, 'default/arrow-left-circle.svg');
  cacheItem(u1105, 'default/arrow-left-right.svg');
  cacheItem(u1106, 'default/arrow-left-square.svg');
  cacheItem(u1107, 'default/arrow-left-tail.svg');
  cacheItem(u1108, 'default/arrow-left.svg');
  cacheItem(u1109, 'default/arrow-loop-left-2.svg');
  cacheItem(u1110, 'default/arrow-loop-left.svg');
  cacheItem(u1111, 'default/arrow-loop-right-2.svg');
  cacheItem(u1112, 'default/arrow-loop-right.svg');
  cacheItem(u1113, 'default/arrow-merge.svg');
  cacheItem(u1114, 'default/arrow-narrow-down.svg');
  cacheItem(u1115, 'default/arrow-narrow-left.svg');
  cacheItem(u1116, 'default/arrow-narrow-right.svg');
  cacheItem(u1117, 'default/arrow-narrow-up.svg');
  cacheItem(u1118, 'default/arrow-ramp-left-2.svg');
  cacheItem(u1119, 'default/arrow-ramp-left-3.svg');
  cacheItem(u1120, 'default/arrow-ramp-left.svg');
  cacheItem(u1121, 'default/arrow-ramp-right-2.svg');
  cacheItem(u1122, 'default/arrow-ramp-right-3.svg');
  cacheItem(u1123, 'default/arrow-ramp-right.svg');
  cacheItem(u1124, 'default/arrow-right-bar.svg');
  cacheItem(u1125, 'default/arrow-right-circle.svg');
  cacheItem(u1126, 'default/arrow-right-square.svg');
  cacheItem(u1127, 'default/arrow-right-tail.svg');
  cacheItem(u1128, 'default/arrow-right.svg');
  cacheItem(u1129, 'default/arrow-rotary-first-left.svg');
  cacheItem(u1130, 'default/arrow-rotary-first-right.svg');
  cacheItem(u1131, 'default/arrow-rotary-last-left.svg');
  cacheItem(u1132, 'default/arrow-rotary-last-right.svg');
  cacheItem(u1133, 'default/arrow-rotary-left.svg');
  cacheItem(u1134, 'default/arrow-rotary-right.svg');
  cacheItem(u1135, 'default/arrow-rotary-straight.svg');
  cacheItem(u1136, 'default/arrow-sharp-turn-left.svg');
  cacheItem(u1137, 'default/arrow-sharp-turn-right.svg');
  cacheItem(u1138, 'default/arrow-top-bar.svg');
  cacheItem(u1139, 'default/arrow-top-circle.svg');
  cacheItem(u1140, 'default/arrow-top-square.svg');
  cacheItem(u1141, 'default/arrow-top-tail.svg');
  cacheItem(u1142, 'default/arrow-up-circle.svg');
  cacheItem(u1143, 'default/arrow-up-left-circle.svg');
  cacheItem(u1144, 'default/arrow-up-left.svg');
  cacheItem(u1145, 'default/arrow-up-right-circle.svg');
  cacheItem(u1146, 'default/arrow-up-right.svg');
  cacheItem(u1147, 'default/arrow-up.svg');
  cacheItem(u1148, 'default/arrow-wave-left-down.svg');
  cacheItem(u1149, 'default/arrow-wave-left-up.svg');
  cacheItem(u1150, 'default/arrow-wave-right-down.svg');
  cacheItem(u1151, 'default/arrow-wave-right-up.svg');
  cacheItem(u1152, 'default/arrows-cross.svg');
  cacheItem(u1153, 'default/arrows-diagonal-2.svg');
  cacheItem(u1154, 'default/arrows-diagonal-minimize-2.svg');
  cacheItem(u1155, 'default/arrows-diagonal-minimize.svg');
  cacheItem(u1156, 'default/arrows-diagonal.svg');
  cacheItem(u1157, 'default/arrows-double-ne-sw.svg');
  cacheItem(u1158, 'default/arrows-double-nw-se.svg');
  cacheItem(u1159, 'default/arrows-double-se-nw.svg');
  cacheItem(u1160, 'default/arrows-double-sw-ne.svg');
  cacheItem(u1161, 'default/arrows-down-up.svg');
  cacheItem(u1162, 'default/arrows-down.svg');
  cacheItem(u1163, 'default/arrows-horizontal.svg');
  cacheItem(u1164, 'default/arrows-join-2.svg');
  cacheItem(u1165, 'default/arrows-join.svg');
  cacheItem(u1166, 'default/arrows-left-down.svg');
  cacheItem(u1167, 'default/arrows-left-right.svg');
  cacheItem(u1168, 'default/arrows-left.svg');
  cacheItem(u1169, 'default/arrows-maximize.svg');
  cacheItem(u1170, 'default/arrows-minimize.svg');
  cacheItem(u1171, 'default/arrows-random.svg');
  cacheItem(u1172, 'default/arrows-right-down.svg');
  cacheItem(u1173, 'default/arrows-right-left.svg');
  cacheItem(u1174, 'default/arrows-right.svg');
  cacheItem(u1175, 'default/arrows-shuffle-2.svg');
  cacheItem(u1176, 'default/arrows-shuffle.svg');
  cacheItem(u1177, 'default/arrows-sort.svg');
  cacheItem(u1178, 'default/arrows-split-2.svg');
  cacheItem(u1179, 'default/arrows-split.svg');
  cacheItem(u1180, 'default/arrows-up-down.svg');
  cacheItem(u1181, 'default/arrows-up-left.svg');
  cacheItem(u1182, 'default/arrows-up-right.svg');
  cacheItem(u1183, 'default/arrows-up.svg');
  cacheItem(u1184, 'default/arrows-vertical.svg');
  cacheItem(u1185, 'default/artboard-off.svg');
  cacheItem(u1186, 'default/artboard.svg');
  cacheItem(u1187, 'default/aspect-ratio-off.svg');
  cacheItem(u1188, 'default/aspect-ratio.svg');
  cacheItem(u1189, 'default/asterisk-simple.svg');
  cacheItem(u1190, 'default/asterisk.svg');
  cacheItem(u1191, 'default/at-off.svg');
  cacheItem(u1192, 'default/at.svg');
  cacheItem(u1193, 'default/atom-2.svg');
  cacheItem(u1194, 'default/atom.svg');
  cacheItem(u1195, 'default/augmented-reality.svg');
  cacheItem(u1196, 'default/award.svg');
  cacheItem(u1197, 'default/axe.svg');
  cacheItem(u1198, 'default/axis-x.svg');
  cacheItem(u1199, 'default/axis-y.svg');
  cacheItem(u1200, 'default/baby-carriage.svg');
  cacheItem(u1201, 'default/backhoe.svg');
  cacheItem(u1202, 'default/backpack.svg');
  cacheItem(u1203, 'default/backspace.svg');
  cacheItem(u1204, 'default/badge.svg');
  cacheItem(u1205, 'default/badges.svg');
  cacheItem(u1206, 'default/ball-american-football.svg');
  cacheItem(u1207, 'default/ball-baseball.svg');
  cacheItem(u1208, 'default/ball-basketball.svg');
  cacheItem(u1209, 'default/ball-bowling.svg');
  cacheItem(u1210, 'default/ball-football-off.svg');
  cacheItem(u1211, 'default/ball-football.svg');
  cacheItem(u1212, 'default/ball-tennis.svg');
  cacheItem(u1213, 'default/ball-volleyball.svg');
  cacheItem(u1214, 'default/ballon.svg');
  cacheItem(u1215, 'default/ballpen-off.svg');
  cacheItem(u1216, 'default/ballpen.svg');
  cacheItem(u1217, 'default/ban.svg');
  cacheItem(u1218, 'default/bandage.svg');
  cacheItem(u1219, 'default/barbell-off.svg');
  cacheItem(u1220, 'default/barbell.svg');
  cacheItem(u1221, 'default/barcode-off.svg');
  cacheItem(u1222, 'default/barcode.svg');
  cacheItem(u1223, 'default/barrel.svg');
  cacheItem(u1224, 'default/barrier-block-off.svg');
  cacheItem(u1225, 'default/barrier-block.svg');
  cacheItem(u1226, 'default/baseline.svg');
  cacheItem(u1227, 'default/basket-off.svg');
  cacheItem(u1228, 'default/basket.svg');
  cacheItem(u1229, 'default/bath.svg');
  cacheItem(u1230, 'default/battery-1.svg');
  cacheItem(u1231, 'default/battery-2.svg');
  cacheItem(u1232, 'default/battery-3.svg');
  cacheItem(u1233, 'default/battery-4.svg');
  cacheItem(u1234, 'default/battery-automotive.svg');
  cacheItem(u1235, 'default/battery-charging-2.svg');
  cacheItem(u1236, 'default/battery-charging.svg');
  cacheItem(u1237, 'default/battery-eco.svg');
  cacheItem(u1238, 'default/battery-off.svg');
  cacheItem(u1239, 'default/battery.svg');
  cacheItem(u1240, 'default/beach-off.svg');
  cacheItem(u1241, 'default/beach.svg');
  cacheItem(u1242, 'default/bed.svg');
  cacheItem(u1243, 'default/beer.svg');
  cacheItem(u1244, 'default/bell-minus.svg');
  cacheItem(u1245, 'default/bell-off.svg');
  cacheItem(u1246, 'default/bell-plus.svg');
  cacheItem(u1247, 'default/bell-ringing-2.svg');
  cacheItem(u1248, 'default/bell-ringing.svg');
  cacheItem(u1249, 'default/bell-school.svg');
  cacheItem(u1250, 'default/bell-x.svg');
  cacheItem(u1251, 'default/bell-z.svg');
  cacheItem(u1252, 'default/bell.svg');
  cacheItem(u1253, 'default/bible.svg');
  cacheItem(u1254, 'default/bike-off.svg');
  cacheItem(u1255, 'default/bike.svg');
  cacheItem(u1256, 'default/binary.svg');
  cacheItem(u1257, 'default/biohazard-off.svg');
  cacheItem(u1258, 'default/biohazard.svg');
  cacheItem(u1259, 'default/blockquote.svg');
  cacheItem(u1260, 'default/bluetooth-connected.svg');
  cacheItem(u1261, 'default/bluetooth-off.svg');
  cacheItem(u1262, 'default/bluetooth-x.svg');
  cacheItem(u1263, 'default/bluetooth.svg');
  cacheItem(u1264, 'default/blur.svg');
  cacheItem(u1265, 'default/bold-off.svg');
  cacheItem(u1266, 'default/bold.svg');
  cacheItem(u1267, 'default/bolt-off.svg');
  cacheItem(u1268, 'default/bolt.svg');
  cacheItem(u1269, 'default/bone-off.svg');
  cacheItem(u1270, 'default/bone.svg');
  cacheItem(u1271, 'default/book-2.svg');
  cacheItem(u1272, 'default/book-download.svg');
  cacheItem(u1273, 'default/book-off.svg');
  cacheItem(u1274, 'default/book-upload.svg');
  cacheItem(u1275, 'default/book.svg');
  cacheItem(u1276, 'default/bookmark-off.svg');
  cacheItem(u1277, 'default/bookmark.svg');
  cacheItem(u1278, 'default/bookmarks-off.svg');
  cacheItem(u1279, 'default/bookmarks.svg');
  cacheItem(u1280, 'default/books-off.svg');
  cacheItem(u1281, 'default/books.svg');
  cacheItem(u1282, 'default/border-all.svg');
  cacheItem(u1283, 'default/border-bottom.svg');
  cacheItem(u1284, 'default/border-horizontal.svg');
  cacheItem(u1285, 'default/border-inner.svg');
  cacheItem(u1286, 'default/border-left.svg');
  cacheItem(u1287, 'default/border-none.svg');
  cacheItem(u1288, 'default/border-outer.svg');
  cacheItem(u1289, 'default/border-radius.svg');
  cacheItem(u1290, 'default/border-right.svg');
  cacheItem(u1291, 'default/border-style-2.svg');
  cacheItem(u1292, 'default/border-style.svg');
  cacheItem(u1293, 'default/border-top.svg');
  cacheItem(u1294, 'default/border-vertical.svg');
  cacheItem(u1295, 'default/bottle.svg');
  cacheItem(u1296, 'default/bow.svg');
  cacheItem(u1297, 'default/box-margin.svg');
  cacheItem(u1298, 'default/box-model-2.svg');
  cacheItem(u1299, 'default/box-model.svg');
  cacheItem(u1300, 'default/box-multiple-0.svg');
  cacheItem(u1301, 'default/box-multiple-1.svg');
  cacheItem(u1302, 'default/box-multiple-2.svg');
  cacheItem(u1303, 'default/box-multiple-3.svg');
  cacheItem(u1304, 'default/box-multiple-4.svg');
  cacheItem(u1305, 'default/box-multiple-5.svg');
  cacheItem(u1306, 'default/box-multiple-6.svg');
  cacheItem(u1307, 'default/box-multiple-7.svg');
  cacheItem(u1308, 'default/box-multiple-8.svg');
  cacheItem(u1309, 'default/box-multiple-9.svg');
  cacheItem(u1310, 'default/box-multiple.svg');
  cacheItem(u1311, 'default/box-padding.svg');
  cacheItem(u1312, 'default/box.svg');
  cacheItem(u1313, 'default/braces-off.svg');
  cacheItem(u1314, 'default/braces.svg');
  cacheItem(u1315, 'default/brackets-off.svg');
  cacheItem(u1316, 'default/brackets.svg');
  cacheItem(u1317, 'default/brand-adobe.svg');
  cacheItem(u1318, 'default/brand-airbnb.svg');
  cacheItem(u1319, 'default/brand-airtable.svg');
  cacheItem(u1320, 'default/brand-android.svg');
  cacheItem(u1321, 'default/brand-angular.svg');
  cacheItem(u1322, 'default/brand-apple-arcade.svg');
  cacheItem(u1323, 'default/brand-apple.svg');
  cacheItem(u1324, 'default/brand-appstore.svg');
  cacheItem(u1325, 'default/brand-asana.svg');
  cacheItem(u1326, 'default/brand-behance.svg');
  cacheItem(u1327, 'default/brand-bing.svg');
  cacheItem(u1328, 'default/brand-bitbucket.svg');
  cacheItem(u1329, 'default/brand-booking.svg');
  cacheItem(u1330, 'default/brand-bootstrap.svg');
  cacheItem(u1331, 'default/brand-chrome.svg');
  cacheItem(u1332, 'default/brand-codepen.svg');
  cacheItem(u1333, 'default/brand-codesandbox.svg');
  cacheItem(u1334, 'default/brand-css3.svg');
  cacheItem(u1335, 'default/brand-cucumber.svg');
  cacheItem(u1336, 'default/brand-debian.svg');
  cacheItem(u1337, 'default/brand-deviantart.svg');
  cacheItem(u1338, 'default/brand-discord.svg');
  cacheItem(u1339, 'default/brand-disqus.svg');
  cacheItem(u1340, 'default/brand-docker.svg');
  cacheItem(u1341, 'default/brand-doctrine.svg');
  cacheItem(u1342, 'default/brand-dribbble.svg');
  cacheItem(u1343, 'default/brand-edge.svg');
  cacheItem(u1344, 'default/brand-facebook.svg');
  cacheItem(u1345, 'default/brand-figma.svg');
  cacheItem(u1346, 'default/brand-firebase.svg');
  cacheItem(u1347, 'default/brand-firefox.svg');
  cacheItem(u1348, 'default/brand-flickr.svg');
  cacheItem(u1349, 'default/brand-foursquare.svg');
  cacheItem(u1350, 'default/brand-framer.svg');
  cacheItem(u1351, 'default/brand-git.svg');
  cacheItem(u1352, 'default/brand-github.svg');
  cacheItem(u1353, 'default/brand-gitlab.svg');
  cacheItem(u1354, 'default/brand-gmail.svg');
  cacheItem(u1355, 'default/brand-google-analytics.svg');
  cacheItem(u1356, 'default/brand-google-drive.svg');
  cacheItem(u1357, 'default/brand-google-play.svg');
  cacheItem(u1358, 'default/brand-google.svg');
  cacheItem(u1359, 'default/brand-gravatar.svg');
  cacheItem(u1360, 'default/brand-hipchat.svg');
  cacheItem(u1361, 'default/brand-html5.svg');
  cacheItem(u1362, 'default/brand-instagram.svg');
  cacheItem(u1363, 'default/brand-javascript.svg');
  cacheItem(u1364, 'default/brand-kickstarter.svg');
  cacheItem(u1365, 'default/brand-kotlin.svg');
  cacheItem(u1366, 'default/brand-lastfm.svg');
  cacheItem(u1367, 'default/brand-linkedin.svg');
  cacheItem(u1368, 'default/brand-loom.svg');
  cacheItem(u1369, 'default/brand-mastercard.svg');
  cacheItem(u1370, 'default/brand-medium.svg');
  cacheItem(u1371, 'default/brand-mercedes.svg');
  cacheItem(u1372, 'default/brand-messenger.svg');
  cacheItem(u1373, 'default/brand-meta.svg');
  cacheItem(u1374, 'default/brand-netbeans.svg');
  cacheItem(u1375, 'default/brand-netflix.svg');
  cacheItem(u1376, 'default/brand-nextjs.svg');
  cacheItem(u1377, 'default/brand-notion.svg');
  cacheItem(u1378, 'default/brand-nuxt.svg');
  cacheItem(u1379, 'default/brand-nytimes.svg');
  cacheItem(u1380, 'default/brand-open-source.svg');
  cacheItem(u1381, 'default/brand-opera.svg');
  cacheItem(u1382, 'default/brand-pagekit.svg');
  cacheItem(u1383, 'default/brand-patreon.svg');
  cacheItem(u1384, 'default/brand-paypal.svg');
  cacheItem(u1385, 'default/brand-php.svg');
  cacheItem(u1386, 'default/brand-pinterest.svg');
  cacheItem(u1387, 'default/brand-pocket.svg');
  cacheItem(u1388, 'default/brand-producthunt.svg');
  cacheItem(u1389, 'default/brand-python.svg');
  cacheItem(u1390, 'default/brand-react-native.svg');
  cacheItem(u1391, 'default/brand-reddit.svg');
  cacheItem(u1392, 'default/brand-safari.svg');
  cacheItem(u1393, 'default/brand-sass.svg');
  cacheItem(u1394, 'default/brand-sentry.svg');
  cacheItem(u1395, 'default/brand-shazam.svg');
  cacheItem(u1396, 'default/brand-sketch.svg');
  cacheItem(u1397, 'default/brand-skype.svg');
  cacheItem(u1398, 'default/brand-slack.svg');
  cacheItem(u1399, 'default/brand-snapchat.svg');
  cacheItem(u1400, 'default/brand-soundcloud.svg');
  cacheItem(u1401, 'default/brand-spotify.svg');
  cacheItem(u1402, 'default/brand-stackoverflow.svg');
  cacheItem(u1403, 'default/brand-steam.svg');
  cacheItem(u1404, 'default/brand-stripe.svg');
  cacheItem(u1405, 'default/brand-sublime-text.svg');
  cacheItem(u1406, 'default/brand-svelte.svg');
  cacheItem(u1408, 'default/brand-tailwind.svg');
  cacheItem(u1409, 'default/brand-telegram.svg');
  cacheItem(u1410, 'default/brand-tidal.svg');
  cacheItem(u1411, 'default/brand-tiktok.svg');
  cacheItem(u1412, 'default/brand-tinder.svg');
  cacheItem(u1413, 'default/brand-tripadvisor.svg');
  cacheItem(u1414, 'default/brand-tumblr.svg');
  cacheItem(u1415, 'default/brand-twitch.svg');
  cacheItem(u1416, 'default/brand-twitter.svg');
  cacheItem(u1417, 'default/brand-uber.svg');
  cacheItem(u1418, 'default/brand-ubuntu.svg');
  cacheItem(u1419, 'default/brand-unsplash.svg');
  cacheItem(u1420, 'default/brand-vercel.svg');
  cacheItem(u1421, 'default/brand-vimeo.svg');
  cacheItem(u1422, 'default/brand-visual-studio.svg');
  cacheItem(u1423, 'default/brand-vk.svg');
  cacheItem(u1424, 'default/brand-vue.svg');
  cacheItem(u1425, 'default/brand-whatsapp.svg');
  cacheItem(u1426, 'default/brand-windows.svg');
  cacheItem(u1427, 'default/brand-yahoo.svg');
  cacheItem(u1428, 'default/brand-ycombinator.svg');
  cacheItem(u1429, 'default/brand-youtube.svg');
  cacheItem(u1430, 'default/bread.svg');
  cacheItem(u1431, 'default/briefcase.svg');
  cacheItem(u1432, 'default/brightness-2.svg');
  cacheItem(u1433, 'default/brightness-down.svg');
  cacheItem(u1434, 'default/brightness-half.svg');
  cacheItem(u1435, 'default/brightness-up.svg');
  cacheItem(u1436, 'default/brightness.svg');
  cacheItem(u1437, 'default/browser-check.svg');
  cacheItem(u1438, 'default/browser-off.svg');
  cacheItem(u1439, 'default/browser-plus.svg');
  cacheItem(u1440, 'default/browser-x.svg');
  cacheItem(u1441, 'default/browser.svg');
  cacheItem(u1442, 'default/brush-off.svg');
  cacheItem(u1443, 'default/brush.svg');
  cacheItem(u1444, 'default/bucket.svg');
  cacheItem(u1445, 'default/bug-off.svg');
  cacheItem(u1446, 'default/bug.svg');
  cacheItem(u1447, 'default/building-arch.svg');
  cacheItem(u1448, 'default/building-bank.svg');
  cacheItem(u1449, 'default/building-bridge-2.svg');
  cacheItem(u1450, 'default/building-bridge.svg');
  cacheItem(u1451, 'default/building-carousel.svg');
  cacheItem(u1452, 'default/building-castle.svg');
  cacheItem(u1453, 'default/building-church.svg');
  cacheItem(u1454, 'default/building-community.svg');
  cacheItem(u1455, 'default/building-cottage.svg');
  cacheItem(u1456, 'default/building-factory-2.svg');
  cacheItem(u1457, 'default/building-factory.svg');
  cacheItem(u1458, 'default/building-fortress.svg');
  cacheItem(u1459, 'default/building-hospital.svg');
  cacheItem(u1460, 'default/building-lighthouse.svg');
  cacheItem(u1461, 'default/building-monument.svg');
  cacheItem(u1462, 'default/building-pavilon.svg');
  cacheItem(u1463, 'default/building-skyscraper.svg');
  cacheItem(u1464, 'default/building-store.svg');
  cacheItem(u1465, 'default/building-warehouse.svg');
  cacheItem(u1466, 'default/building.svg');
  cacheItem(u1467, 'default/bulb-off.svg');
  cacheItem(u1468, 'default/bulb.svg');
  cacheItem(u1469, 'default/bulldozer.svg');
  cacheItem(u1470, 'default/bus.svg');
  cacheItem(u1471, 'default/businessplan.svg');
  cacheItem(u1472, 'default/butterfly.svg');
  cacheItem(u1473, 'default/c-sharp.svg');
  cacheItem(u1474, 'default/cake.svg');
  cacheItem(u1475, 'default/calculator-off.svg');
  cacheItem(u1476, 'default/calculator.svg');
  cacheItem(u1477, 'default/calendar-event.svg');
  cacheItem(u1478, 'default/calendar-minus.svg');
  cacheItem(u1479, 'default/calendar-off.svg');
  cacheItem(u1480, 'default/calendar-plus.svg');
  cacheItem(u1481, 'default/calendar-stats.svg');
  cacheItem(u1482, 'default/calendar-time.svg');
  cacheItem(u1483, 'default/calendar.svg');
  cacheItem(u1484, 'default/camera-minus.svg');
  cacheItem(u1485, 'default/camera-off.svg');
  cacheItem(u1486, 'default/camera-plus.svg');
  cacheItem(u1487, 'default/camera-rotate.svg');
  cacheItem(u1488, 'default/camera-selfie.svg');
  cacheItem(u1489, 'default/camera.svg');
  cacheItem(u1490, 'default/candle.svg');
  cacheItem(u1491, 'default/candy-off.svg');
  cacheItem(u1492, 'default/candy.svg');
  cacheItem(u1493, 'default/capture-off.svg');
  cacheItem(u1494, 'default/capture.svg');
  cacheItem(u1495, 'default/car-crane.svg');
  cacheItem(u1496, 'default/car-crash.svg');
  cacheItem(u1497, 'default/car-off.svg');
  cacheItem(u1498, 'default/car.svg');
  cacheItem(u1499, 'default/caravan.svg');
  cacheItem(u1500, 'default/cardboards-off.svg');
  cacheItem(u1501, 'default/cardboards.svg');
  cacheItem(u1502, 'default/caret-down.svg');
  cacheItem(u1503, 'default/caret-left.svg');
  cacheItem(u1504, 'default/caret-right.svg');
  cacheItem(u1505, 'default/caret-up.svg');
  cacheItem(u1506, 'default/cash-banknote-off.svg');
  cacheItem(u1507, 'default/cash-banknote.svg');
  cacheItem(u1508, 'default/cash.svg');
  cacheItem(u1509, 'default/cast-off.svg');
  cacheItem(u1510, 'default/cast.svg');
  cacheItem(u1511, 'default/ce-off.svg');
  cacheItem(u1512, 'default/ce.svg');
  cacheItem(u1513, 'default/cell-signal-1.svg');
  cacheItem(u1514, 'default/cell-signal-2.svg');
  cacheItem(u1515, 'default/cell-signal-3.svg');
  cacheItem(u1516, 'default/cell-signal-4.svg');
  cacheItem(u1517, 'default/cell-signal-5.svg');
  cacheItem(u1518, 'default/cell-signal-off.svg');
  cacheItem(u1519, 'default/cell.svg');
  cacheItem(u1520, 'default/certificate-2-off.svg');
  cacheItem(u1521, 'default/certificate-2.svg');
  cacheItem(u1522, 'default/certificate-off.svg');
  cacheItem(u1523, 'default/certificate.svg');
  cacheItem(u1524, 'default/charging-pile.svg');
  cacheItem(u1525, 'default/chart-arcs-3.svg');
  cacheItem(u1526, 'default/chart-arcs.svg');
  cacheItem(u1527, 'default/chart-area-line.svg');
  cacheItem(u1528, 'default/chart-area.svg');
  cacheItem(u1529, 'default/chart-arrows-vertical.svg');
  cacheItem(u1530, 'default/chart-arrows.svg');
  cacheItem(u1531, 'default/chart-bar.svg');
  cacheItem(u1532, 'default/chart-bubble.svg');
  cacheItem(u1533, 'default/chart-candle.svg');
  cacheItem(u1534, 'default/chart-circles.svg');
  cacheItem(u1535, 'default/chart-donut-2.svg');
  cacheItem(u1536, 'default/chart-donut-3.svg');
  cacheItem(u1537, 'default/chart-donut-4.svg');
  cacheItem(u1538, 'default/chart-donut.svg');
  cacheItem(u1539, 'default/chart-dots-2.svg');
  cacheItem(u1540, 'default/chart-dots-3.svg');
  cacheItem(u1541, 'default/chart-dots.svg');
  cacheItem(u1542, 'default/chart-infographic.svg');
  cacheItem(u1543, 'default/chart-line.svg');
  cacheItem(u1544, 'default/chart-pie-2.svg');
  cacheItem(u1545, 'default/chart-pie-3.svg');
  cacheItem(u1546, 'default/chart-pie-4.svg');
  cacheItem(u1547, 'default/chart-pie.svg');
  cacheItem(u1548, 'default/chart-radar.svg');
  cacheItem(u1549, 'default/check.svg');
  cacheItem(u1550, 'default/checkbox.svg');
  cacheItem(u1551, 'default/checklist.svg');
  cacheItem(u1552, 'default/checks.svg');
  cacheItem(u1553, 'default/checkup-list.svg');
  cacheItem(u1554, 'default/cheese.svg');
  cacheItem(u1555, 'default/chevron-down-left.svg');
  cacheItem(u1556, 'default/chevron-down-right.svg');
  cacheItem(u1557, 'default/chevron-down.svg');
  cacheItem(u1558, 'default/chevron-left.svg');
  cacheItem(u1559, 'default/chevron-right.svg');
  cacheItem(u1560, 'default/chevron-up-left.svg');
  cacheItem(u1561, 'default/chevron-up-right.svg');
  cacheItem(u1562, 'default/chevron-up.svg');
  cacheItem(u1563, 'default/chevrons-down-left.svg');
  cacheItem(u1564, 'default/chevrons-down-right.svg');
  cacheItem(u1565, 'default/chevrons-down.svg');
  cacheItem(u1566, 'default/chevrons-left.svg');
  cacheItem(u1567, 'default/chevrons-right.svg');
  cacheItem(u1568, 'default/chevrons-up-left.svg');
  cacheItem(u1569, 'default/chevrons-up-right.svg');
  cacheItem(u1570, 'default/chevrons-up.svg');
  cacheItem(u1571, 'default/christmas-tree.svg');
  cacheItem(u1572, 'default/circle-0.svg');
  cacheItem(u1573, 'default/circle-1.svg');
  cacheItem(u1574, 'default/circle-2.svg');
  cacheItem(u1575, 'default/circle-3.svg');
  cacheItem(u1576, 'default/circle-4.svg');
  cacheItem(u1577, 'default/circle-5.svg');
  cacheItem(u1578, 'default/circle-6.svg');
  cacheItem(u1579, 'default/circle-7.svg');
  cacheItem(u1580, 'default/circle-8.svg');
  cacheItem(u1581, 'default/circle-9.svg');
  cacheItem(u1582, 'default/circle-check.svg');
  cacheItem(u1583, 'default/circle-dashed.svg');
  cacheItem(u1584, 'default/circle-dot.svg');
  cacheItem(u1585, 'default/circle-dotted.svg');
  cacheItem(u1586, 'default/circle-half-2.svg');
  cacheItem(u1587, 'default/circle-half-vertical.svg');
  cacheItem(u1588, 'default/circle-half.svg');
  cacheItem(u1589, 'default/circle-minus.svg');
  cacheItem(u1590, 'default/circle-off.svg');
  cacheItem(u1591, 'default/circle-plus.svg');
  cacheItem(u1592, 'default/circle-rectangle-off.svg');
  cacheItem(u1593, 'default/circle-rectangle.svg');
  cacheItem(u1594, 'default/circle-square.svg');
  cacheItem(u1595, 'default/circle-triangle.svg');
  cacheItem(u1596, 'default/circle-x.svg');
  cacheItem(u1597, 'default/circle.svg');
  cacheItem(u1598, 'default/circles.svg');
  cacheItem(u1599, 'default/clear-all.svg');
  cacheItem(u1600, 'default/clear-formatting.svg');
  cacheItem(u1601, 'default/click.svg');
  cacheItem(u1602, 'default/clipboard-check.svg');
  cacheItem(u1603, 'default/clipboard-list.svg');
  cacheItem(u1604, 'default/clipboard-off.svg');
  cacheItem(u1605, 'default/clipboard-plus.svg');
  cacheItem(u1606, 'default/clipboard-text.svg');
  cacheItem(u1607, 'default/clipboard-x.svg');
  cacheItem(u1608, 'default/clipboard.svg');
  cacheItem(u1609, 'default/clock-2.svg');
  cacheItem(u1610, 'default/clock-off.svg');
  cacheItem(u1611, 'default/clock.svg');
  cacheItem(u1612, 'default/cloud-download.svg');
  cacheItem(u1613, 'default/cloud-fog.svg');
  cacheItem(u1614, 'default/cloud-lock-open.svg');
  cacheItem(u1615, 'default/cloud-lock.svg');
  cacheItem(u1616, 'default/cloud-off.svg');
  cacheItem(u1617, 'default/cloud-rain.svg');
  cacheItem(u1618, 'default/cloud-snow.svg');
  cacheItem(u1619, 'default/cloud-storm.svg');
  cacheItem(u1620, 'default/cloud-upload.svg');
  cacheItem(u1621, 'default/cloud.svg');
  cacheItem(u1622, 'default/clubs.svg');
  cacheItem(u1623, 'default/code-minus.svg');
  cacheItem(u1624, 'default/code-off.svg');
  cacheItem(u1625, 'default/code-plus.svg');
  cacheItem(u1626, 'default/code.svg');
  cacheItem(u1627, 'default/coffee.svg');
  cacheItem(u1628, 'default/coin-off.svg');
  cacheItem(u1629, 'default/coin.svg');
  cacheItem(u1630, 'default/color-picker-off.svg');
  cacheItem(u1631, 'default/color-picker.svg');
  cacheItem(u1632, 'default/color-swatch-off.svg');
  cacheItem(u1633, 'default/color-swatch.svg');
  cacheItem(u1634, 'default/column-insert-left.svg');
  cacheItem(u1635, 'default/column-insert-right.svg');
  cacheItem(u1636, 'default/columns-off.svg');
  cacheItem(u1637, 'default/columns.svg');
  cacheItem(u1638, 'default/comet.svg');
  cacheItem(u1639, 'default/command.svg');
  cacheItem(u1640, 'default/compass-off.svg');
  cacheItem(u1641, 'default/compass.svg');
  cacheItem(u1642, 'default/components-off.svg');
  cacheItem(u1643, 'default/components.svg');
  cacheItem(u1644, 'default/cone-2.svg');
  cacheItem(u1645, 'default/cone.svg');
  cacheItem(u1646, 'default/confetti.svg');
  cacheItem(u1647, 'default/container.svg');
  cacheItem(u1648, 'default/contrast-2.svg');
  cacheItem(u1649, 'default/contrast.svg');
  cacheItem(u1650, 'default/cookie-off.svg');
  cacheItem(u1651, 'default/cookie.svg');
  cacheItem(u1652, 'default/copy-off.svg');
  cacheItem(u1653, 'default/copy.svg');
  cacheItem(u1654, 'default/copyleft-off.svg');
  cacheItem(u1655, 'default/copyleft.svg');
  cacheItem(u1656, 'default/copyright-off.svg');
  cacheItem(u1657, 'default/copyright.svg');
  cacheItem(u1658, 'default/corner-down-left-double.svg');
  cacheItem(u1659, 'default/corner-down-left.svg');
  cacheItem(u1660, 'default/corner-down-right-double.svg');
  cacheItem(u1661, 'default/corner-down-right.svg');
  cacheItem(u1662, 'default/corner-left-down-double.svg');
  cacheItem(u1663, 'default/corner-left-down.svg');
  cacheItem(u1664, 'default/corner-left-up-double.svg');
  cacheItem(u1665, 'default/corner-left-up.svg');
  cacheItem(u1666, 'default/corner-right-down-double.svg');
  cacheItem(u1667, 'default/corner-right-down.svg');
  cacheItem(u1668, 'default/corner-right-up-double.svg');
  cacheItem(u1669, 'default/corner-right-up.svg');
  cacheItem(u1670, 'default/corner-up-left-double.svg');
  cacheItem(u1671, 'default/corner-up-left.svg');
  cacheItem(u1672, 'default/corner-up-right-double.svg');
  cacheItem(u1673, 'default/corner-up-right.svg');
  cacheItem(u1674, 'default/cpu-2.svg');
  cacheItem(u1675, 'default/cpu.svg');
  cacheItem(u1676, 'default/crane.svg');
  cacheItem(u1677, 'default/creative-commons.svg');
  cacheItem(u1678, 'default/credit-card-off.svg');
  cacheItem(u1679, 'default/credit-card.svg');
  cacheItem(u1680, 'default/cricket.svg');
  cacheItem(u1681, 'default/crop.svg');
  cacheItem(u1682, 'default/cross.svg');
  cacheItem(u1683, 'default/crosshair.svg');
  cacheItem(u1684, 'default/crown-off.svg');
  cacheItem(u1685, 'default/crown.svg');
  cacheItem(u1686, 'default/crutches.svg');
  cacheItem(u1687, 'default/cup.svg');
  cacheItem(u1688, 'default/curling.svg');
  cacheItem(u1689, 'default/curly-loop.svg');
  cacheItem(u1690, 'default/currency-bahraini.svg');
  cacheItem(u1691, 'default/currency-baht.svg');
  cacheItem(u1692, 'default/currency-bitcoin.svg');
  cacheItem(u1693, 'default/currency-cent.svg');
  cacheItem(u1694, 'default/currency-dinar.svg');
  cacheItem(u1695, 'default/currency-dirham.svg');
  cacheItem(u1696, 'default/currency-dogecoin.svg');
  cacheItem(u1697, 'default/currency-dollar-australian.svg');
  cacheItem(u1698, 'default/currency-dollar-canadian.svg');
  cacheItem(u1699, 'default/currency-dollar-singapore.svg');
  cacheItem(u1700, 'default/currency-dollar.svg');
  cacheItem(u1701, 'default/currency-ethereum.svg');
  cacheItem(u1702, 'default/currency-euro.svg');
  cacheItem(u1703, 'default/currency-forint.svg');
  cacheItem(u1704, 'default/currency-frank.svg');
  cacheItem(u1705, 'default/currency-krone-czech.svg');
  cacheItem(u1706, 'default/currency-krone-danish.svg');
  cacheItem(u1707, 'default/currency-krone-swedish.svg');
  cacheItem(u1708, 'default/currency-leu.svg');
  cacheItem(u1709, 'default/currency-lira.svg');
  cacheItem(u1710, 'default/currency-litecoin.svg');
  cacheItem(u1711, 'default/currency-naira.svg');
  cacheItem(u1712, 'default/currency-pound.svg');
  cacheItem(u1713, 'default/currency-real.svg');
  cacheItem(u1714, 'default/currency-renminbi.svg');
  cacheItem(u1715, 'default/currency-ripple.svg');
  cacheItem(u1716, 'default/currency-riyal.svg');
  cacheItem(u1717, 'default/currency-rubel.svg');
  cacheItem(u1718, 'default/currency-rupee.svg');
  cacheItem(u1719, 'default/currency-shekel.svg');
  cacheItem(u1720, 'default/currency-taka.svg');
  cacheItem(u1721, 'default/currency-tugrik.svg');
  cacheItem(u1722, 'default/currency-won.svg');
  cacheItem(u1723, 'default/currency-yen.svg');
  cacheItem(u1724, 'default/currency-zloty.svg');
  cacheItem(u1725, 'default/currency.svg');
  cacheItem(u1726, 'default/current-location.svg');
  cacheItem(u1727, 'default/cursor-text.svg');
  cacheItem(u1728, 'default/cut.svg');
  cacheItem(u1729, 'default/dashboard.svg');
  cacheItem(u1730, 'default/database-export.svg');
  cacheItem(u1731, 'default/database-import.svg');
  cacheItem(u1732, 'default/database-off.svg');
  cacheItem(u1733, 'default/database.svg');
  cacheItem(u1734, 'default/dental.svg');
  cacheItem(u1735, 'default/details.svg');
  cacheItem(u1736, 'default/device-analytics.svg');
  cacheItem(u1737, 'default/device-audio-tape.svg');
  cacheItem(u1738, 'default/device-cctv.svg');
  cacheItem(u1739, 'default/device-computer-camera-off.svg');
  cacheItem(u1740, 'default/device-computer-camera.svg');
  cacheItem(u1741, 'default/device-desktop-analytics.svg');
  cacheItem(u1742, 'default/device-desktop-off.svg');
  cacheItem(u1743, 'default/device-desktop.svg');
  cacheItem(u1744, 'default/device-floppy.svg');
  cacheItem(u1745, 'default/device-gamepad.svg');
  cacheItem(u1746, 'default/device-heart-monitor.svg');
  cacheItem(u1747, 'default/device-laptop-off.svg');
  cacheItem(u1748, 'default/device-laptop.svg');
  cacheItem(u1749, 'default/device-mobile-message.svg');
  cacheItem(u1750, 'default/device-mobile-off.svg');
  cacheItem(u1751, 'default/device-mobile-rotated.svg');
  cacheItem(u1752, 'default/device-mobile-vibration.svg');
  cacheItem(u1753, 'default/device-mobile.svg');
  cacheItem(u1754, 'default/device-nintendo.svg');
  cacheItem(u1755, 'default/device-speaker.svg');
  cacheItem(u1756, 'default/device-tablet-off.svg');
  cacheItem(u1757, 'default/device-tablet.svg');
  cacheItem(u1758, 'default/device-tv-off.svg');
  cacheItem(u1759, 'default/device-tv.svg');
  cacheItem(u1760, 'default/device-watch-off.svg');
  cacheItem(u1761, 'default/device-watch-stats-2.svg');
  cacheItem(u1762, 'default/device-watch-stats.svg');
  cacheItem(u1763, 'default/device-watch.svg');
  cacheItem(u1764, 'default/devices-2.svg');
  cacheItem(u1765, 'default/devices-off.svg');
  cacheItem(u1766, 'default/devices-pc.svg');
  cacheItem(u1767, 'default/devices.svg');
  cacheItem(u1768, 'default/dialpad.svg');
  cacheItem(u1769, 'default/diamond.svg');
  cacheItem(u1770, 'default/diamonds.svg');
  cacheItem(u1771, 'default/dice-1.svg');
  cacheItem(u1772, 'default/dice-2.svg');
  cacheItem(u1773, 'default/dice-3.svg');
  cacheItem(u1774, 'default/dice-4.svg');
  cacheItem(u1775, 'default/dice-5.svg');
  cacheItem(u1776, 'default/dice-6.svg');
  cacheItem(u1777, 'default/dice.svg');
  cacheItem(u1778, 'default/dimensions.svg');
  cacheItem(u1779, 'default/direction-horizontal.svg');
  cacheItem(u1780, 'default/direction.svg');
  cacheItem(u1781, 'default/directions.svg');
  cacheItem(u1782, 'default/disabled-2.svg');
  cacheItem(u1783, 'default/disabled.svg');
  cacheItem(u1784, 'default/disc.svg');
  cacheItem(u1785, 'default/discount-2.svg');
  cacheItem(u1786, 'default/discount.svg');
  cacheItem(u1787, 'default/divide.svg');
  cacheItem(u1788, 'default/dna-2.svg');
  cacheItem(u1789, 'default/dna.svg');
  cacheItem(u1790, 'default/dog-bowl.svg');
  cacheItem(u1791, 'default/door-enter.svg');
  cacheItem(u1792, 'default/door-exit.svg');
  cacheItem(u1793, 'default/door.svg');
  cacheItem(u1794, 'default/dots-circle-horizontal.svg');
  cacheItem(u1795, 'default/dots-diagonal-2.svg');
  cacheItem(u1796, 'default/dots-diagonal.svg');
  cacheItem(u1797, 'default/dots-vertical.svg');
  cacheItem(u1798, 'default/dots.svg');
  cacheItem(u1799, 'default/download.svg');
  cacheItem(u1800, 'default/drag-drop-2.svg');
  cacheItem(u1801, 'default/drag-drop.svg');
  cacheItem(u1802, 'default/drone-off.svg');
  cacheItem(u1803, 'default/drone.svg');
  cacheItem(u1804, 'default/drop-circle.svg');
  cacheItem(u1805, 'default/droplet-filled-2.svg');
  cacheItem(u1806, 'default/droplet-filled.svg');
  cacheItem(u1807, 'default/droplet-half-2.svg');
  cacheItem(u1808, 'default/droplet-half.svg');
  cacheItem(u1809, 'default/droplet-off.svg');
  cacheItem(u1810, 'default/droplet.svg');
  cacheItem(u1811, 'default/ear-off.svg');
  cacheItem(u1812, 'default/ear.svg');
  cacheItem(u1813, 'default/edit-circle.svg');
  cacheItem(u1814, 'default/edit.svg');
  cacheItem(u1815, 'default/egg.svg');
  cacheItem(u1816, 'default/elevator.svg');
  cacheItem(u1817, 'default/emergency-bed.svg');
  cacheItem(u1818, 'default/emphasis.svg');
  cacheItem(u1819, 'default/engine.svg');
  cacheItem(u1820, 'default/equal-not.svg');
  cacheItem(u1821, 'default/equal.svg');
  cacheItem(u1822, 'default/eraser.svg');
  cacheItem(u1823, 'default/error-404.svg');
  cacheItem(u1824, 'default/exchange.svg');
  cacheItem(u1825, 'default/exclamation-mark.svg');
  cacheItem(u1826, 'default/exposure.svg');
  cacheItem(u1827, 'default/external-link.svg');
  cacheItem(u1828, 'default/eye-check.svg');
  cacheItem(u1829, 'default/eye-off.svg');
  cacheItem(u1830, 'default/eye-table.svg');
  cacheItem(u1831, 'default/eye.svg');
  cacheItem(u1832, 'default/eyeglass-2.svg');
  cacheItem(u1833, 'default/eyeglass.svg');
  cacheItem(u1834, 'default/face-id-error.svg');
  cacheItem(u1835, 'default/face-id.svg');
  cacheItem(u1836, 'default/face-mask.svg');
  cacheItem(u1837, 'default/fall.svg');
  cacheItem(u1838, 'default/feather.svg');
  cacheItem(u1839, 'default/fence.svg');
  cacheItem(u1840, 'default/fidget-spinner.svg');
  cacheItem(u1841, 'default/file-3d.svg');
  cacheItem(u1842, 'default/file-alert.svg');
  cacheItem(u1843, 'default/file-analytics.svg');
  cacheItem(u1844, 'default/file-arrow-left.svg');
  cacheItem(u1845, 'default/file-arrow-right.svg');
  cacheItem(u1846, 'default/file-barcode.svg');
  cacheItem(u1847, 'default/file-certificate.svg');
  cacheItem(u1848, 'default/file-chart.svg');
  cacheItem(u1849, 'default/file-check.svg');
  cacheItem(u1850, 'default/file-code-2.svg');
  cacheItem(u1851, 'default/file-code.svg');
  cacheItem(u1852, 'default/file-database.svg');
  cacheItem(u1853, 'default/file-description.svg');
  cacheItem(u1854, 'default/file-diff.svg');
  cacheItem(u1855, 'default/file-digit.svg');
  cacheItem(u1856, 'default/file-dislike.svg');
  cacheItem(u1857, 'default/file-dollar.svg');
  cacheItem(u1858, 'default/file-dots.svg');
  cacheItem(u1859, 'default/file-download.svg');
  cacheItem(u1860, 'default/file-euro.svg');
  cacheItem(u1861, 'default/file-export.svg');
  cacheItem(u1862, 'default/file-horizontal.svg');
  cacheItem(u1863, 'default/file-import.svg');
  cacheItem(u1864, 'default/file-info.svg');
  cacheItem(u1865, 'default/file-invoice.svg');
  cacheItem(u1866, 'default/file-like.svg');
  cacheItem(u1867, 'default/file-minus.svg');
  cacheItem(u1868, 'default/file-music.svg');
  cacheItem(u1869, 'default/file-off.svg');
  cacheItem(u1870, 'default/file-pencil.svg');
  cacheItem(u1871, 'default/file-phone.svg');
  cacheItem(u1872, 'default/file-plus.svg');
  cacheItem(u1873, 'default/file-power.svg');
  cacheItem(u1874, 'default/file-report.svg');
  cacheItem(u1875, 'default/file-rss.svg');
  cacheItem(u1876, 'default/file-scissors.svg');
  cacheItem(u1877, 'default/file-search.svg');
  cacheItem(u1878, 'default/file-settings.svg');
  cacheItem(u1879, 'default/file-shredder.svg');
  cacheItem(u1880, 'default/file-signal.svg');
  cacheItem(u1881, 'default/file-spreadsheet.svg');
  cacheItem(u1882, 'default/file-star.svg');
  cacheItem(u1883, 'default/file-symlink.svg');
  cacheItem(u1884, 'default/file-text.svg');
  cacheItem(u1885, 'default/file-time.svg');
  cacheItem(u1886, 'default/file-typography.svg');
  cacheItem(u1887, 'default/file-unknown.svg');
  cacheItem(u1888, 'default/file-upload.svg');
  cacheItem(u1889, 'default/file-vector.svg');
  cacheItem(u1890, 'default/file-x.svg');
  cacheItem(u1891, 'default/file-zip.svg');
  cacheItem(u1892, 'default/file.svg');
  cacheItem(u1893, 'default/files-off.svg');
  cacheItem(u1894, 'default/files.svg');
  cacheItem(u1895, 'default/filter-off.svg');
  cacheItem(u1896, 'default/filter.svg');
  cacheItem(u1897, 'default/fingerprint.svg');
  cacheItem(u1898, 'default/firetruck.svg');
  cacheItem(u1899, 'default/first-aid-kit.svg');
  cacheItem(u1900, 'default/fish.svg');
  cacheItem(u1901, 'default/flag-2.svg');
  cacheItem(u1902, 'default/flag-3.svg');
  cacheItem(u1903, 'default/flag.svg');
  cacheItem(u1904, 'default/flame.svg');
  cacheItem(u1905, 'default/flare.svg');
  cacheItem(u1906, 'default/flask-2.svg');
  cacheItem(u1907, 'default/flask.svg');
  cacheItem(u1908, 'default/flip-horizontal.svg');
  cacheItem(u1909, 'default/flip-vertical.svg');
  cacheItem(u1910, 'default/float-center.svg');
  cacheItem(u1911, 'default/float-left.svg');
  cacheItem(u1912, 'default/float-none.svg');
  cacheItem(u1913, 'default/float-right.svg');
  cacheItem(u1914, 'default/flower.svg');
  cacheItem(u1915, 'default/focus-2.svg');
  cacheItem(u1916, 'default/focus-centered.svg');
  cacheItem(u1917, 'default/focus.svg');
  cacheItem(u1918, 'default/fold-down.svg');
  cacheItem(u1919, 'default/fold-up.svg');
  cacheItem(u1920, 'default/fold.svg');
  cacheItem(u1921, 'default/folder-minus.svg');
  cacheItem(u1922, 'default/folder-off.svg');
  cacheItem(u1923, 'default/folder-plus.svg');
  cacheItem(u1924, 'default/folder-x.svg');
  cacheItem(u1925, 'default/folder.svg');
  cacheItem(u1926, 'default/folders.svg');
  cacheItem(u1927, 'default/forbid-2.svg');
  cacheItem(u1928, 'default/forbid.svg');
  cacheItem(u1929, 'default/forklift.svg');
  cacheItem(u1930, 'default/forms.svg');
  cacheItem(u1931, 'default/fountain.svg');
  cacheItem(u1932, 'default/frame.svg');
  cacheItem(u1933, 'default/free-rights.svg');
  cacheItem(u1934, 'default/friends.svg');
  cacheItem(u1935, 'default/gas-station.svg');
  cacheItem(u1936, 'default/gauge.svg');
  cacheItem(u1937, 'default/gavel.svg');
  cacheItem(u1938, 'default/gender-agender.svg');
  cacheItem(u1939, 'default/gender-androgyne.svg');
  cacheItem(u1940, 'default/gender-bigender.svg');
  cacheItem(u1941, 'default/gender-demiboy.svg');
  cacheItem(u1942, 'default/gender-demigirl.svg');
  cacheItem(u1943, 'default/gender-epicene.svg');
  cacheItem(u1944, 'default/gender-female.svg');
  cacheItem(u1945, 'default/gender-femme.svg');
  cacheItem(u1946, 'default/gender-genderfluid.svg');
  cacheItem(u1947, 'default/gender-genderless.svg');
  cacheItem(u1948, 'default/gender-genderqueer.svg');
  cacheItem(u1949, 'default/gender-hermaphrodite.svg');
  cacheItem(u1950, 'default/gender-intergender.svg');
  cacheItem(u1951, 'default/gender-male.svg');
  cacheItem(u1952, 'default/gender-neutrois.svg');
  cacheItem(u1953, 'default/gender-third.svg');
  cacheItem(u1954, 'default/gender-transgender.svg');
  cacheItem(u1955, 'default/gender-trasvesti.svg');
  cacheItem(u1956, 'default/geometry.svg');
  cacheItem(u1957, 'default/ghost.svg');
  cacheItem(u1958, 'default/gift.svg');
  cacheItem(u1959, 'default/git-branch.svg');
  cacheItem(u1960, 'default/git-commit.svg');
  cacheItem(u1961, 'default/git-compare.svg');
  cacheItem(u1962, 'default/git-fork.svg');
  cacheItem(u1963, 'default/git-merge.svg');
  cacheItem(u1964, 'default/git-pull-request-closed.svg');
  cacheItem(u1965, 'default/git-pull-request-draft.svg');
  cacheItem(u1966, 'default/git-pull-request.svg');
  cacheItem(u1967, 'default/gizmo.svg');
  cacheItem(u1968, 'default/glass-full.svg');
  cacheItem(u1969, 'default/glass-off.svg');
  cacheItem(u1970, 'default/glass.svg');
  cacheItem(u1971, 'default/globe.svg');
  cacheItem(u1972, 'default/golf.svg');
  cacheItem(u1973, 'default/gps.svg');
  cacheItem(u1974, 'default/grain.svg');
  cacheItem(u1975, 'default/grid-dots.svg');
  cacheItem(u1976, 'default/grid-pattern.svg');
  cacheItem(u1977, 'default/grill.svg');
  cacheItem(u1978, 'default/grip-horizontal.svg');
  cacheItem(u1979, 'default/grip-vertical.svg');
  cacheItem(u1980, 'default/growth.svg');
  cacheItem(u1981, 'default/h-1.svg');
  cacheItem(u1982, 'default/h-2.svg');
  cacheItem(u1983, 'default/h-3.svg');
  cacheItem(u1984, 'default/h-4.svg');
  cacheItem(u1985, 'default/h-5.svg');
  cacheItem(u1986, 'default/h-6.svg');
  cacheItem(u1987, 'default/hammer.svg');
  cacheItem(u1988, 'default/hand-click.svg');
  cacheItem(u1989, 'default/hand-finger.svg');
  cacheItem(u1990, 'default/hand-grab.svg');
  cacheItem(u1991, 'default/hand-little-finger.svg');
  cacheItem(u1992, 'default/hand-middle-finger.svg');
  cacheItem(u1993, 'default/hand-move.svg');
  cacheItem(u1994, 'default/hand-off.svg');
  cacheItem(u1995, 'default/hand-ring-finger.svg');
  cacheItem(u1996, 'default/hand-rock.svg');
  cacheItem(u1997, 'default/hand-stop.svg');
  cacheItem(u1998, 'default/hand-three-fingers.svg');
  cacheItem(u1999, 'default/hand-two-fingers.svg');
  cacheItem(u2000, 'default/hanger-2.svg');
  cacheItem(u2001, 'default/hanger.svg');
  cacheItem(u2002, 'default/hash.svg');
  cacheItem(u2003, 'default/haze.svg');
  cacheItem(u2004, 'default/heading.svg');
  cacheItem(u2005, 'default/headphones-off.svg');
  cacheItem(u2006, 'default/headphones.svg');
  cacheItem(u2007, 'default/headset.svg');
  cacheItem(u2008, 'default/heart-broken.svg');
  cacheItem(u2009, 'default/heart-handshake.svg');
  cacheItem(u2010, 'default/heart-rate-monitor.svg');
  cacheItem(u2011, 'default/heart.svg');
  cacheItem(u2012, 'default/heartbeat.svg');
  cacheItem(u2013, 'default/helicopter-landing.svg');
  cacheItem(u2014, 'default/helicopter.svg');
  cacheItem(u2015, 'default/helmet.svg');
  cacheItem(u2016, 'default/help.svg');
  cacheItem(u2017, 'default/hexagon-off.svg');
  cacheItem(u2018, 'default/hexagon.svg');
  cacheItem(u2019, 'default/hexagons.svg');
  cacheItem(u2020, 'default/hierarchy-2.svg');
  cacheItem(u2021, 'default/hierarchy.svg');
  cacheItem(u2022, 'default/highlight.svg');
  cacheItem(u2023, 'default/history.svg');
  cacheItem(u2024, 'default/home-2.svg');
  cacheItem(u2025, 'default/home.svg');
  cacheItem(u2026, 'default/hotel-service.svg');
  cacheItem(u2027, 'default/hourglass-high.svg');
  cacheItem(u2028, 'default/hourglass-low.svg');
  cacheItem(u2029, 'default/hourglass.svg');
  cacheItem(u2030, 'default/ice-cream-2.svg');
  cacheItem(u2031, 'default/ice-cream.svg');
  cacheItem(u2032, 'default/ice-skating.svg');
  cacheItem(u2033, 'default/id-badge-2.svg');
  cacheItem(u2034, 'default/id-badge.svg');
  cacheItem(u2035, 'default/id.svg');
  cacheItem(u2036, 'default/inbox.svg');
  cacheItem(u2037, 'default/indent-decrease.svg');
  cacheItem(u2038, 'default/indent-increase.svg');
  cacheItem(u2039, 'default/infinity.svg');
  cacheItem(u2040, 'default/info-circle.svg');
  cacheItem(u2041, 'default/info-square.svg');
  cacheItem(u2042, 'default/italic.svg');
  cacheItem(u2043, 'default/jump-rope.svg');
  cacheItem(u2044, 'default/karate.svg');
  cacheItem(u2045, 'default/kering.svg');
  cacheItem(u2046, 'default/key.svg');
  cacheItem(u2047, 'default/keyboard-hide.svg');
  cacheItem(u2048, 'default/keyboard-off.svg');
  cacheItem(u2049, 'default/keyboard-show.svg');
  cacheItem(u2050, 'default/keyboard.svg');
  cacheItem(u2051, 'default/ladder.svg');
  cacheItem(u2052, 'default/lamp-2.svg');
  cacheItem(u2053, 'default/lamp.svg');
  cacheItem(u2054, 'default/language-hiragana.svg');
  cacheItem(u2055, 'default/language-katakana.svg');
  cacheItem(u2056, 'default/language.svg');
  cacheItem(u2057, 'default/lasso.svg');
  cacheItem(u2058, 'default/layers-difference.svg');
  cacheItem(u2059, 'default/layers-intersect-2.svg');
  cacheItem(u2060, 'default/layers-intersect.svg');
  cacheItem(u2061, 'default/layers-linked.svg');
  cacheItem(u2062, 'default/layers-subtract.svg');
  cacheItem(u2063, 'default/layers-union.svg');
  cacheItem(u2064, 'default/layout-2.svg');
  cacheItem(u2065, 'default/layout-align-bottom.svg');
  cacheItem(u2066, 'default/layout-align-center.svg');
  cacheItem(u2067, 'default/layout-align-left.svg');
  cacheItem(u2068, 'default/layout-align-middle.svg');
  cacheItem(u2069, 'default/layout-align-right.svg');
  cacheItem(u2070, 'default/layout-align-top.svg');
  cacheItem(u2071, 'default/layout-board-split.svg');
  cacheItem(u2072, 'default/layout-board.svg');
  cacheItem(u2073, 'default/layout-bottombar.svg');
  cacheItem(u2074, 'default/layout-cards.svg');
  cacheItem(u2075, 'default/layout-columns.svg');
  cacheItem(u2076, 'default/layout-dashboard.svg');
  cacheItem(u2077, 'default/layout-distribute-horizontal.svg');
  cacheItem(u2078, 'default/layout-distribute-vertical.svg');
  cacheItem(u2079, 'default/layout-grid-add.svg');
  cacheItem(u2080, 'default/layout-grid.svg');
  cacheItem(u2081, 'default/layout-kanban.svg');
  cacheItem(u2082, 'default/layout-list.svg');
  cacheItem(u2083, 'default/layout-navbar.svg');
  cacheItem(u2084, 'default/layout-rows.svg');
  cacheItem(u2085, 'default/layout-sidebar-left-collapse.svg');
  cacheItem(u2086, 'default/layout-sidebar-left-expand.svg');
  cacheItem(u2087, 'default/layout-sidebar-right-collapse.svg');
  cacheItem(u2088, 'default/layout-sidebar-right-expand.svg');
  cacheItem(u2089, 'default/layout-sidebar-right.svg');
  cacheItem(u2090, 'default/layout-sidebar.svg');
  cacheItem(u2091, 'default/layout.svg');
  cacheItem(u2092, 'default/leaf.svg');
  cacheItem(u2093, 'default/lego.svg');
  cacheItem(u2094, 'default/lemon-2.svg');
  cacheItem(u2095, 'default/lemon.svg');
  cacheItem(u2096, 'default/letter-a.svg');
  cacheItem(u2097, 'default/letter-b.svg');
  cacheItem(u2098, 'default/letter-c.svg');
  cacheItem(u2099, 'default/letter-case-lower.svg');
  cacheItem(u2100, 'default/letter-case-toggle.svg');
  cacheItem(u2101, 'default/letter-case-upper.svg');
  cacheItem(u2102, 'default/letter-case.svg');
  cacheItem(u2103, 'default/letter-d.svg');
  cacheItem(u2104, 'default/letter-e.svg');
  cacheItem(u2105, 'default/letter-f.svg');
  cacheItem(u2106, 'default/letter-g.svg');
  cacheItem(u2107, 'default/letter-h.svg');
  cacheItem(u2108, 'default/letter-i.svg');
  cacheItem(u2109, 'default/letter-j.svg');
  cacheItem(u2110, 'default/letter-k.svg');
  cacheItem(u2111, 'default/letter-l.svg');
  cacheItem(u2112, 'default/letter-m.svg');
  cacheItem(u2113, 'default/letter-n.svg');
  cacheItem(u2114, 'default/letter-o.svg');
  cacheItem(u2115, 'default/letter-p.svg');
  cacheItem(u2116, 'default/letter-q.svg');
  cacheItem(u2117, 'default/letter-r.svg');
  cacheItem(u2118, 'default/letter-s.svg');
  cacheItem(u2119, 'default/letter-spacing.svg');
  cacheItem(u2120, 'default/letter-t.svg');
  cacheItem(u2121, 'default/letter-u.svg');
  cacheItem(u2122, 'default/letter-v.svg');
  cacheItem(u2123, 'default/letter-w.svg');
  cacheItem(u2124, 'default/letter-x.svg');
  cacheItem(u2125, 'default/letter-y.svg');
  cacheItem(u2126, 'default/letter-z.svg');
  cacheItem(u2127, 'default/license.svg');
  cacheItem(u2128, 'default/lifebuoy.svg');
  cacheItem(u2129, 'default/line-dashed.svg');
  cacheItem(u2130, 'default/line-dotted.svg');
  cacheItem(u2131, 'default/line-height.svg');
  cacheItem(u2132, 'default/line.svg');
  cacheItem(u2133, 'default/link.svg');
  cacheItem(u2134, 'default/list-check.svg');
  cacheItem(u2135, 'default/list-details.svg');
  cacheItem(u2136, 'default/list-numbers.svg');
  cacheItem(u2137, 'default/list-search.svg');
  cacheItem(u2138, 'default/list.svg');
  cacheItem(u2139, 'default/live-photo.svg');
  cacheItem(u2140, 'default/live-view.svg');
  cacheItem(u2141, 'default/loader-quarter.svg');
  cacheItem(u2142, 'default/loader.svg');
  cacheItem(u2143, 'default/location.svg');
  cacheItem(u2144, 'default/lock-access.svg');
  cacheItem(u2145, 'default/lock-off.svg');
  cacheItem(u2146, 'default/lock-open.svg');
  cacheItem(u2147, 'default/lock-square.svg');
  cacheItem(u2148, 'default/lock.svg');
  cacheItem(u2149, 'default/login.svg');
  cacheItem(u2150, 'default/logout.svg');
  cacheItem(u2151, 'default/lollipop.svg');
  cacheItem(u2152, 'default/luggage.svg');
  cacheItem(u2153, 'default/lungs.svg');
  cacheItem(u2154, 'default/macro.svg');
  cacheItem(u2155, 'default/magnet.svg');
  cacheItem(u2156, 'default/mail-fast.svg');
  cacheItem(u2157, 'default/mail-forward.svg');
  cacheItem(u2158, 'default/mail-opened.svg');
  cacheItem(u2159, 'default/mail.svg');
  cacheItem(u2160, 'default/mailbox.svg');
  cacheItem(u2161, 'default/man.svg');
  cacheItem(u2162, 'default/manual-gearbox.svg');
  cacheItem(u2163, 'default/map-2.svg');
  cacheItem(u2164, 'default/map-pin-off.svg');
  cacheItem(u2165, 'default/map-pin.svg');
  cacheItem(u2166, 'default/map-pins.svg');
  cacheItem(u2167, 'default/map-search.svg');
  cacheItem(u2168, 'default/map.svg');
  cacheItem(u2169, 'default/markdown.svg');
  cacheItem(u2170, 'default/marquee-2.svg');
  cacheItem(u2171, 'default/marquee.svg');
  cacheItem(u2172, 'default/mars.svg');
  cacheItem(u2173, 'default/mask-off.svg');
  cacheItem(u2174, 'default/mask.svg');
  cacheItem(u2175, 'default/massage.svg');
  cacheItem(u2176, 'default/math-avg.svg');
  cacheItem(u2177, 'default/math-function.svg');
  cacheItem(u2178, 'default/math-max.svg');
  cacheItem(u2179, 'default/math-min.svg');
  cacheItem(u2180, 'default/math-symbols.svg');
  cacheItem(u2181, 'default/math.svg');
  cacheItem(u2182, 'default/maximize.svg');
  cacheItem(u2183, 'default/meat.svg');
  cacheItem(u2184, 'default/medal-2.svg');
  cacheItem(u2185, 'default/medal.svg');
  cacheItem(u2186, 'default/medical-cross.svg');
  cacheItem(u2187, 'default/medicine-syrup.svg');
  cacheItem(u2188, 'default/menu-2.svg');
  cacheItem(u2189, 'default/menu.svg');
  cacheItem(u2190, 'default/message-2-code.svg');
  cacheItem(u2191, 'default/message-2-share.svg');
  cacheItem(u2192, 'default/message-2.svg');
  cacheItem(u2193, 'default/message-circle-2.svg');
  cacheItem(u2194, 'default/message-circle-off.svg');
  cacheItem(u2195, 'default/message-circle.svg');
  cacheItem(u2196, 'default/message-code.svg');
  cacheItem(u2197, 'default/message-dots.svg');
  cacheItem(u2198, 'default/message-language.svg');
  cacheItem(u2199, 'default/message-off.svg');
  cacheItem(u2200, 'default/message-plus.svg');
  cacheItem(u2201, 'default/message-report.svg');
  cacheItem(u2202, 'default/message-share.svg');
  cacheItem(u2203, 'default/message.svg');
  cacheItem(u2204, 'default/messages-off.svg');
  cacheItem(u2205, 'default/messages.svg');
  cacheItem(u2206, 'default/microphone-2.svg');
  cacheItem(u2207, 'default/microphone-off.svg');
  cacheItem(u2208, 'default/microphone.svg');
  cacheItem(u2209, 'default/microscope.svg');
  cacheItem(u2210, 'default/military-award.svg');
  cacheItem(u2211, 'default/military-rank.svg');
  cacheItem(u2212, 'default/milk.svg');
  cacheItem(u2213, 'default/minimize.svg');
  cacheItem(u2214, 'default/minus-vertical.svg');
  cacheItem(u2215, 'default/minus.svg');
  cacheItem(u2216, 'default/mist.svg');
  cacheItem(u2217, 'default/mood-boy.svg');
  cacheItem(u2218, 'default/mood-confuzed.svg');
  cacheItem(u2219, 'default/mood-crazy-happy.svg');
  cacheItem(u2220, 'default/mood-cry.svg');
  cacheItem(u2221, 'default/mood-empty.svg');
  cacheItem(u2222, 'default/mood-happy.svg');
  cacheItem(u2223, 'default/mood-kid.svg');
  cacheItem(u2224, 'default/mood-nervous.svg');
  cacheItem(u2225, 'default/mood-neutral.svg');
  cacheItem(u2226, 'default/mood-sad.svg');
  cacheItem(u2227, 'default/mood-smile.svg');
  cacheItem(u2228, 'default/mood-suprised.svg');
  cacheItem(u2229, 'default/mood-tongue.svg');
  cacheItem(u2230, 'default/moon-2.svg');
  cacheItem(u2231, 'default/moon-stars.svg');
  cacheItem(u2232, 'default/moon.svg');
  cacheItem(u2233, 'default/moped.svg');
  cacheItem(u2234, 'default/motorbike.svg');
  cacheItem(u2235, 'default/mountain.svg');
  cacheItem(u2236, 'default/mouse.svg');
  cacheItem(u2237, 'default/movie.svg');
  cacheItem(u2238, 'default/mug.svg');
  cacheItem(u2239, 'default/multiplier-0-5x.svg');
  cacheItem(u2240, 'default/multiplier-1-5x.svg');
  cacheItem(u2241, 'default/multiplier-1x.svg');
  cacheItem(u2242, 'default/multiplier-2x.svg');
  cacheItem(u2243, 'default/mushroom.svg');
  cacheItem(u2244, 'default/music.svg');
  cacheItem(u2245, 'default/network.svg');
  cacheItem(u2246, 'default/new-section.svg');
  cacheItem(u2247, 'default/news.svg');
  cacheItem(u2248, 'default/nfc.svg');
  cacheItem(u2249, 'default/no-copyright.svg');
  cacheItem(u2250, 'default/no-creative-commons.svg');
  cacheItem(u2251, 'default/no-derivatives.svg');
  cacheItem(u2252, 'default/north-star.svg');
  cacheItem(u2253, 'default/note.svg');
  cacheItem(u2254, 'default/notebook.svg');
  cacheItem(u2255, 'default/notes.svg');
  cacheItem(u2256, 'default/notification.svg');
  cacheItem(u2257, 'default/number-0.svg');
  cacheItem(u2258, 'default/number-1.svg');
  cacheItem(u2259, 'default/number-2.svg');
  cacheItem(u2260, 'default/number-3.svg');
  cacheItem(u2261, 'default/number-4.svg');
  cacheItem(u2262, 'default/number-5.svg');
  cacheItem(u2263, 'default/number-6.svg');
  cacheItem(u2264, 'default/number-7.svg');
  cacheItem(u2265, 'default/number-8.svg');
  cacheItem(u2266, 'default/number-9.svg');
  cacheItem(u2267, 'default/numbers.svg');
  cacheItem(u2268, 'default/nurse.svg');
  cacheItem(u2269, 'default/octagon-off.svg');
  cacheItem(u2270, 'default/octagon.svg');
  cacheItem(u2271, 'default/old.svg');
  cacheItem(u2272, 'default/olympics.svg');
  cacheItem(u2273, 'default/omega.svg');
  cacheItem(u2274, 'default/outlet.svg');
  cacheItem(u2275, 'default/oval-vertical.svg');
  cacheItem(u2276, 'default/oval.svg');
  cacheItem(u2277, 'default/overline.svg');
  cacheItem(u2278, 'default/package.svg');
  cacheItem(u2279, 'default/packge-export.svg');
  cacheItem(u2280, 'default/packge-import.svg');
  cacheItem(u2281, 'default/pacman.svg');
  cacheItem(u2282, 'default/page-break.svg');
  cacheItem(u2283, 'default/paint.svg');
  cacheItem(u2284, 'default/palette.svg');
  cacheItem(u2285, 'default/panorama-horizontal.svg');
  cacheItem(u2286, 'default/panorama-vertical.svg');
  cacheItem(u2287, 'default/paper-bag.svg');
  cacheItem(u2288, 'default/paperclip.svg');
  cacheItem(u2289, 'default/parachute.svg');
  cacheItem(u2290, 'default/parentheses.svg');
  cacheItem(u2291, 'default/parking.svg');
  cacheItem(u2292, 'default/paw.svg');
  cacheItem(u2293, 'default/peace.svg');
  cacheItem(u2294, 'default/pencil.svg');
  cacheItem(u2295, 'default/pennant-2.svg');
  cacheItem(u2296, 'default/pennant.svg');
  cacheItem(u2297, 'default/pentagon.svg');
  cacheItem(u2298, 'default/pepper.svg');
  cacheItem(u2299, 'default/percentage.svg');
  cacheItem(u2300, 'default/perspective.svg');
  cacheItem(u2301, 'default/phone-call.svg');
  cacheItem(u2302, 'default/phone-calling.svg');
  cacheItem(u2303, 'default/phone-check.svg');
  cacheItem(u2304, 'default/phone-incoming.svg');
  cacheItem(u2305, 'default/phone-off.svg');
  cacheItem(u2306, 'default/phone-outgoing.svg');
  cacheItem(u2307, 'default/phone-pause.svg');
  cacheItem(u2308, 'default/phone-plus.svg');
  cacheItem(u2309, 'default/phone-x.svg');
  cacheItem(u2310, 'default/phone.svg');
  cacheItem(u2311, 'default/photo-off.svg');
  cacheItem(u2312, 'default/photo.svg');
  cacheItem(u2313, 'default/physotherapist.svg');
  cacheItem(u2314, 'default/picture-in-picture-off.svg');
  cacheItem(u2315, 'default/picture-in-picture-on.svg');
  cacheItem(u2316, 'default/picture-in-picture-top.svg');
  cacheItem(u2317, 'default/picture-in-picture.svg');
  cacheItem(u2318, 'default/pig.svg');
  cacheItem(u2319, 'default/pill.svg');
  cacheItem(u2320, 'default/pills.svg');
  cacheItem(u2321, 'default/pin.svg');
  cacheItem(u2322, 'default/pinned-off.svg');
  cacheItem(u2323, 'default/pinned.svg');
  cacheItem(u2324, 'default/pizza.svg');
  cacheItem(u2325, 'default/plane-arrival.svg');
  cacheItem(u2326, 'default/plane-departure.svg');
  cacheItem(u2327, 'default/plane-inflight.svg');
  cacheItem(u2328, 'default/plane.svg');
  cacheItem(u2329, 'default/planet.svg');
  cacheItem(u2330, 'default/plant-2.svg');
  cacheItem(u2331, 'default/plant.svg');
  cacheItem(u2332, 'default/play-card.svg');
  cacheItem(u2333, 'default/player-eject.svg');
  cacheItem(u2334, 'default/player-pause.svg');
  cacheItem(u2335, 'default/player-play.svg');
  cacheItem(u2336, 'default/player-record.svg');
  cacheItem(u2337, 'default/player-skip-back.svg');
  cacheItem(u2338, 'default/player-skip-forward.svg');
  cacheItem(u2339, 'default/player-stop.svg');
  cacheItem(u2340, 'default/player-track-next.svg');
  cacheItem(u2341, 'default/player-track-prev.svg');
  cacheItem(u2342, 'default/playlist-add.svg');
  cacheItem(u2343, 'default/playlist-x.svg');
  cacheItem(u2344, 'default/playlist.svg');
  cacheItem(u2345, 'default/plug-connected-x.svg');
  cacheItem(u2346, 'default/plug-connected.svg');
  cacheItem(u2347, 'default/plug-x.svg');
  cacheItem(u2348, 'default/plug.svg');
  cacheItem(u2349, 'default/plus.svg');
  cacheItem(u2350, 'default/point.svg');
  cacheItem(u2351, 'default/pokeball.svg');
  cacheItem(u2352, 'default/polaroid.svg');
  cacheItem(u2353, 'default/polygon.svg');
  cacheItem(u2354, 'default/pool.svg');
  cacheItem(u2355, 'default/power.svg');
  cacheItem(u2356, 'default/pray.svg');
  cacheItem(u2357, 'default/premium-rights.svg');
  cacheItem(u2358, 'default/prescription.svg');
  cacheItem(u2359, 'default/presentation-analytics.svg');
  cacheItem(u2360, 'default/presentation.svg');
  cacheItem(u2361, 'default/printer.svg');
  cacheItem(u2362, 'default/prison.svg');
  cacheItem(u2363, 'default/prompt.svg');
  cacheItem(u2364, 'default/propeller.svg');
  cacheItem(u2365, 'default/puzzle-2.svg');
  cacheItem(u2366, 'default/puzzle.svg');
  cacheItem(u2367, 'default/pyramid.svg');
  cacheItem(u2368, 'default/qrcode.svg');
  cacheItem(u2369, 'default/question-mark.svg');
  cacheItem(u2370, 'default/quote.svg');
  cacheItem(u2371, 'default/radar-2.svg');
  cacheItem(u2372, 'default/radar.svg');
  cacheItem(u2373, 'default/radio.svg');
  cacheItem(u2374, 'default/radioactive.svg');
  cacheItem(u2375, 'default/radius-bottom-left.svg');
  cacheItem(u2376, 'default/radius-bottom-right.svg');
  cacheItem(u2377, 'default/radius-top-left.svg');
  cacheItem(u2378, 'default/radius-top-right.svg');
  cacheItem(u2379, 'default/rainbow.svg');
  cacheItem(u2380, 'default/receipt-2.svg');
  cacheItem(u2381, 'default/receipt-off.svg');
  cacheItem(u2382, 'default/receipt-refund.svg');
  cacheItem(u2383, 'default/receipt-tax.svg');
  cacheItem(u2384, 'default/receipt.svg');
  cacheItem(u2385, 'default/recharging.svg');
  cacheItem(u2386, 'default/record-mail.svg');
  cacheItem(u2387, 'default/rectangle-vertical.svg');
  cacheItem(u2388, 'default/rectangle.svg');
  cacheItem(u2389, 'default/recycle.svg');
  cacheItem(u2390, 'default/refresh-alert.svg');
  cacheItem(u2391, 'default/refresh-dot.svg');
  cacheItem(u2392, 'default/refresh.svg');
  cacheItem(u2393, 'default/registered.svg');
  cacheItem(u2394, 'default/relation-many-to-many.svg');
  cacheItem(u2395, 'default/relation-one-to-many.svg');
  cacheItem(u2396, 'default/relation-one-to-one.svg');
  cacheItem(u2397, 'default/repeat-once.svg');
  cacheItem(u2398, 'default/repeat.svg');
  cacheItem(u2399, 'default/replace.svg');
  cacheItem(u2400, 'default/report-analytics.svg');
  cacheItem(u2401, 'default/report-medical.svg');
  cacheItem(u2402, 'default/report-money.svg');
  cacheItem(u2403, 'default/report-search.svg');
  cacheItem(u2404, 'default/report.svg');
  cacheItem(u2405, 'default/resize.svg');
  cacheItem(u2406, 'default/ripple.svg');
  cacheItem(u2407, 'default/road-sign.svg');
  cacheItem(u2408, 'default/road.svg');
  cacheItem(u2409, 'default/robot.svg');
  cacheItem(u2410, 'default/rocket.svg');
  cacheItem(u2411, 'default/roller-skating.svg');
  cacheItem(u2412, 'default/rollercoaster.svg');
  cacheItem(u2413, 'default/rotate-2.svg');
  cacheItem(u2414, 'default/rotate-360.svg');
  cacheItem(u2415, 'default/rotate-clockwise-2.svg');
  cacheItem(u2416, 'default/rotate-clockwise.svg');
  cacheItem(u2417, 'default/rotate-dot.svg');
  cacheItem(u2418, 'default/rotate-rectangle.svg');
  cacheItem(u2419, 'default/rotate.svg');
  cacheItem(u2420, 'default/route.svg');
  cacheItem(u2421, 'default/router.svg');
  cacheItem(u2422, 'default/row-insert-bottom.svg');
  cacheItem(u2423, 'default/row-insert-top.svg');
  cacheItem(u2424, 'default/rss.svg');
  cacheItem(u2425, 'default/ruler-2.svg');
  cacheItem(u2426, 'default/ruler.svg');
  cacheItem(u2427, 'default/run.svg');
  cacheItem(u2428, 'default/sailboat.svg');
  cacheItem(u2429, 'default/salt.svg');
  cacheItem(u2430, 'default/satellite.svg');
  cacheItem(u2431, 'default/sausage.svg');
  cacheItem(u2432, 'default/scale-outline.svg');
  cacheItem(u2433, 'default/scale.svg');
  cacheItem(u2434, 'default/scan.svg');
  cacheItem(u2435, 'default/school.svg');
  cacheItem(u2436, 'default/scissors.svg');
  cacheItem(u2437, 'default/scooter-electric.svg');
  cacheItem(u2438, 'default/scooter.svg');
  cacheItem(u2439, 'default/screen-share-off.svg');
  cacheItem(u2440, 'default/screen-share.svg');
  cacheItem(u2441, 'default/scribble.svg');
  cacheItem(u2442, 'default/scuba-mask.svg');
  cacheItem(u2443, 'default/search.svg');
  cacheItem(u2444, 'default/section-sign.svg');
  cacheItem(u2445, 'default/section.svg');
  cacheItem(u2446, 'default/seeding.svg');
  cacheItem(u2447, 'default/select.svg');
  cacheItem(u2448, 'default/selector.svg');
  cacheItem(u2449, 'default/send.svg');
  cacheItem(u2450, 'default/separator-horizontal.svg');
  cacheItem(u2451, 'default/separator-vertical.svg');
  cacheItem(u2452, 'default/separator.svg');
  cacheItem(u2453, 'default/server-2.svg');
  cacheItem(u2454, 'default/server.svg');
  cacheItem(u2455, 'default/servicemark.svg');
  cacheItem(u2456, 'default/settings-automation.svg');
  cacheItem(u2457, 'default/settings.svg');
  cacheItem(u2458, 'default/shadow-off.svg');
  cacheItem(u2459, 'default/shadow.svg');
  cacheItem(u2460, 'default/shape-2.svg');
  cacheItem(u2461, 'default/shape-3.svg');
  cacheItem(u2462, 'default/shape.svg');
  cacheItem(u2463, 'default/share.svg');
  cacheItem(u2464, 'default/shield-check.svg');
  cacheItem(u2465, 'default/shield-checkered.svg');
  cacheItem(u2466, 'default/shield-chevron.svg');
  cacheItem(u2467, 'default/shield-lock.svg');
  cacheItem(u2468, 'default/shield-off.svg');
  cacheItem(u2469, 'default/shield-x.svg');
  cacheItem(u2470, 'default/shield.svg');
  cacheItem(u2471, 'default/ship.svg');
  cacheItem(u2472, 'default/shirt.svg');
  cacheItem(u2473, 'default/shoe.svg');
  cacheItem(u2474, 'default/shopping-cart-discount.svg');
  cacheItem(u2475, 'default/shopping-cart-off.svg');
  cacheItem(u2476, 'default/shopping-cart-plus.svg');
  cacheItem(u2477, 'default/shopping-cart-x.svg');
  cacheItem(u2478, 'default/shopping-cart.svg');
  cacheItem(u2479, 'default/shredder.svg');
  cacheItem(u2480, 'default/sign-left.svg');
  cacheItem(u2481, 'default/sign-right.svg');
  cacheItem(u2482, 'default/signature.svg');
  cacheItem(u2483, 'default/sitemap.svg');
  cacheItem(u2484, 'default/skateboard.svg');
  cacheItem(u2485, 'default/sleigh.svg');
  cacheItem(u2486, 'default/slice.svg');
  cacheItem(u2487, 'default/slideshow.svg');
  cacheItem(u2488, 'default/smart-home.svg');
  cacheItem(u2489, 'default/smoking-no.svg');
  cacheItem(u2490, 'default/smoking.svg');
  cacheItem(u2491, 'default/snowflake.svg');
  cacheItem(u2492, 'default/soccer-field.svg');
  cacheItem(u2493, 'default/social.svg');
  cacheItem(u2494, 'default/sock.svg');
  cacheItem(u2495, 'default/sofa.svg');
  cacheItem(u2496, 'default/sort-ascending-2.svg');
  cacheItem(u2497, 'default/sort-ascending-letters.svg');
  cacheItem(u2498, 'default/sort-ascending-numbers.svg');
  cacheItem(u2499, 'default/sort-ascending.svg');
  cacheItem(u2500, 'default/sort-descending-2.svg');
  cacheItem(u2501, 'default/sort-descending-letters.svg');
  cacheItem(u2502, 'default/sort-descending-numbers.svg');
  cacheItem(u2503, 'default/sort-descending.svg');
  cacheItem(u2504, 'default/soup.svg');
  cacheItem(u2505, 'default/space.svg');
  cacheItem(u2506, 'default/spacing-horizontal.svg');
  cacheItem(u2507, 'default/spacing-vertical.svg');
  cacheItem(u2508, 'default/spade.svg');
  cacheItem(u2509, 'default/speakerphone.svg');
  cacheItem(u2510, 'default/speedboat.svg');
  cacheItem(u2511, 'default/sport-billard.svg');
  cacheItem(u2512, 'default/square-0.svg');
  cacheItem(u2513, 'default/square-1.svg');
  cacheItem(u2514, 'default/square-2.svg');
  cacheItem(u2515, 'default/square-3.svg');
  cacheItem(u2516, 'default/square-4.svg');
  cacheItem(u2517, 'default/square-5.svg');
  cacheItem(u2518, 'default/square-6.svg');
  cacheItem(u2519, 'default/square-7.svg');
  cacheItem(u2520, 'default/square-8.svg');
  cacheItem(u2521, 'default/square-9.svg');
  cacheItem(u2522, 'default/square-asterisk.svg');
  cacheItem(u2523, 'default/square-check.svg');
  cacheItem(u2524, 'default/square-dot.svg');
  cacheItem(u2525, 'default/square-forbid-2.svg');
  cacheItem(u2526, 'default/square-forbid.svg');
  cacheItem(u2527, 'default/square-half.svg');
  cacheItem(u2528, 'default/square-minus.svg');
  cacheItem(u2529, 'default/square-off.svg');
  cacheItem(u2530, 'default/square-plus.svg');
  cacheItem(u2531, 'default/square-root-2.svg');
  cacheItem(u2532, 'default/square-root.svg');
  cacheItem(u2533, 'default/square-rotated-forbid-2.svg');
  cacheItem(u2534, 'default/square-rotated-forbid.svg');
  cacheItem(u2535, 'default/square-rotated-off.svg');
  cacheItem(u2536, 'default/square-rotated.svg');
  cacheItem(u2537, 'default/square-toggle-horizontal.svg');
  cacheItem(u2538, 'default/square-toggle.svg');
  cacheItem(u2539, 'default/square-x.svg');
  cacheItem(u2540, 'default/square.svg');
  cacheItem(u2541, 'default/squares-diagonal.svg');
  cacheItem(u2542, 'default/squares-filled.svg');
  cacheItem(u2543, 'default/stack-2.svg');
  cacheItem(u2544, 'default/stack-3.svg');
  cacheItem(u2545, 'default/stack.svg');
  cacheItem(u2546, 'default/stairs-down.svg');
  cacheItem(u2547, 'default/stairs-up.svg');
  cacheItem(u2548, 'default/stairs.svg');
  cacheItem(u2549, 'default/star-half.svg');
  cacheItem(u2550, 'default/star-off.svg');
  cacheItem(u2551, 'default/star.svg');
  cacheItem(u2552, 'default/stars.svg');
  cacheItem(u2553, 'default/steering-wheel.svg');
  cacheItem(u2554, 'default/step-into.svg');
  cacheItem(u2555, 'default/step-out.svg');
  cacheItem(u2556, 'default/stethoscope.svg');
  cacheItem(u2557, 'default/sticker.svg');
  cacheItem(u2558, 'default/strikethrough.svg');
  cacheItem(u2559, 'default/submarine.svg');
  cacheItem(u2560, 'default/subscript.svg');
  cacheItem(u2561, 'default/subtask.svg');
  cacheItem(u2562, 'default/sum.svg');
  cacheItem(u2563, 'default/sun-off.svg');
  cacheItem(u2564, 'default/sun.svg');
  cacheItem(u2565, 'default/sunrise.svg');
  cacheItem(u2566, 'default/sunset.svg');
  cacheItem(u2567, 'default/superscript.svg');
  cacheItem(u2568, 'default/swimming.svg');
  cacheItem(u2569, 'default/switch-2.svg');
  cacheItem(u2570, 'default/switch-3.svg');
  cacheItem(u2571, 'default/switch-horizontal.svg');
  cacheItem(u2572, 'default/switch-vertical.svg');
  cacheItem(u2573, 'default/switch.svg');
  cacheItem(u2574, 'default/sword.svg');
  cacheItem(u2575, 'default/table-export.svg');
  cacheItem(u2576, 'default/table-import.svg');
  cacheItem(u2577, 'default/table-off.svg');
  cacheItem(u2578, 'default/table.svg');
  cacheItem(u2579, 'default/tag-off.svg');
  cacheItem(u2580, 'default/tag.svg');
  cacheItem(u2581, 'default/tags-off.svg');
  cacheItem(u2582, 'default/tags.svg');
  cacheItem(u2583, 'default/tallymark-1.svg');
  cacheItem(u2584, 'default/tallymark-2.svg');
  cacheItem(u2585, 'default/tallymark-3.svg');
  cacheItem(u2586, 'default/tallymark-4.svg');
  cacheItem(u2587, 'default/tallymarks.svg');
  cacheItem(u2588, 'default/tank.svg');
  cacheItem(u2589, 'default/target.svg');
  cacheItem(u2590, 'default/telescope.svg');
  cacheItem(u2591, 'default/temperature-celsius.svg');
  cacheItem(u2592, 'default/temperature-fahrenheit.svg');
  cacheItem(u2593, 'default/temperature-minus.svg');
  cacheItem(u2594, 'default/temperature-plus.svg');
  cacheItem(u2595, 'default/temperature.svg');
  cacheItem(u2596, 'default/template.svg');
  cacheItem(u2597, 'default/tent.svg');
  cacheItem(u2598, 'default/terminal-2.svg');
  cacheItem(u2599, 'default/terminal.svg');
  cacheItem(u2600, 'default/test-pipe-2.svg');
  cacheItem(u2601, 'default/test-pipe.svg');
  cacheItem(u2602, 'default/text-direction-ltr.svg');
  cacheItem(u2603, 'default/text-direction-rtl.svg');
  cacheItem(u2604, 'default/text-resize.svg');
  cacheItem(u2605, 'default/text-wrap-disabled.svg');
  cacheItem(u2606, 'default/text-wrap.svg');
  cacheItem(u2607, 'default/thermometer.svg');
  cacheItem(u2608, 'default/thumb-down.svg');
  cacheItem(u2609, 'default/thumb-up.svg');
  cacheItem(u2610, 'default/ticket.svg');
  cacheItem(u2611, 'default/tie.svg');
  cacheItem(u2612, 'default/tilt-shift.svg');
  cacheItem(u2613, 'default/timeline.svg');
  cacheItem(u2614, 'default/tir.svg');
  cacheItem(u2615, 'default/toggle-left.svg');
  cacheItem(u2616, 'default/toggle-right.svg');
  cacheItem(u2617, 'default/toilet-paper.svg');
  cacheItem(u2618, 'default/tool.svg');
  cacheItem(u2619, 'default/tools-kitchen-2.svg');
  cacheItem(u2620, 'default/tools-kitchen.svg');
  cacheItem(u2621, 'default/tools.svg');
  cacheItem(u2622, 'default/tornado.svg');
  cacheItem(u2623, 'default/tournament.svg');
  cacheItem(u2624, 'default/track.svg');
  cacheItem(u2625, 'default/tractor.svg');
  cacheItem(u2626, 'default/trademark.svg');
  cacheItem(u2627, 'default/traffic-cone.svg');
  cacheItem(u2628, 'default/traffic-lights.svg');
  cacheItem(u2629, 'default/train.svg');
  cacheItem(u2630, 'default/transfer-in.svg');
  cacheItem(u2631, 'default/transfer-out.svg');
  cacheItem(u2632, 'default/trash-off.svg');
  cacheItem(u2633, 'default/trash-x.svg');
  cacheItem(u2634, 'default/trash.svg');
  cacheItem(u2635, 'default/tree.svg');
  cacheItem(u2636, 'default/trees.svg');
  cacheItem(u2637, 'default/trending-down-2.svg');
  cacheItem(u2638, 'default/trending-down-3.svg');
  cacheItem(u2639, 'default/trending-down.svg');
  cacheItem(u2640, 'default/trending-up-2.svg');
  cacheItem(u2641, 'default/trending-up-3.svg');
  cacheItem(u2642, 'default/trending-up.svg');
  cacheItem(u2643, 'default/triangle-inverted.svg');
  cacheItem(u2644, 'default/triangle-off.svg');
  cacheItem(u2645, 'default/triangle-square-circle.svg');
  cacheItem(u2646, 'default/triangle.svg');
  cacheItem(u2647, 'default/triangles.svg');
  cacheItem(u2648, 'default/trident.svg');
  cacheItem(u2649, 'default/trophy.svg');
  cacheItem(u2650, 'default/truck-delivery.svg');
  cacheItem(u2651, 'default/truck-off.svg');
  cacheItem(u2652, 'default/truck-return.svg');
  cacheItem(u2653, 'default/truck.svg');
  cacheItem(u2654, 'default/typography.svg');
  cacheItem(u2655, 'default/umbrella.svg');
  cacheItem(u2656, 'default/underline.svg');
  cacheItem(u2657, 'default/unlink.svg');
  cacheItem(u2658, 'default/upload.svg');
  cacheItem(u2659, 'default/urgent.svg');
  cacheItem(u2660, 'default/usb.svg');
  cacheItem(u2661, 'default/user-check.svg');
  cacheItem(u2662, 'default/user-circle.svg');
  cacheItem(u2663, 'default/user-exclamation.svg');
  cacheItem(u2664, 'default/user-minus.svg');
  cacheItem(u2665, 'default/user-off.svg');
  cacheItem(u2666, 'default/user-plus.svg');
  cacheItem(u2667, 'default/user-search.svg');
  cacheItem(u2668, 'default/user-x.svg');
  cacheItem(u2669, 'default/user.svg');
  cacheItem(u2670, 'default/users.svg');
  cacheItem(u2671, 'default/vaccine-bottle.svg');
  cacheItem(u2672, 'default/vaccine.svg');
  cacheItem(u2673, 'default/variable.svg');
  cacheItem(u2674, 'default/vector-beizer-2.svg');
  cacheItem(u2675, 'default/vector-bezier.svg');
  cacheItem(u2676, 'default/vector-triangle.svg');
  cacheItem(u2677, 'default/vector.svg');
  cacheItem(u2678, 'default/venus.svg');
  cacheItem(u2679, 'default/versions.svg');
  cacheItem(u2680, 'default/video-minus.svg');
  cacheItem(u2681, 'default/video-off.svg');
  cacheItem(u2682, 'default/video-plus.svg');
  cacheItem(u2683, 'default/video.svg');
  cacheItem(u2684, 'default/view-360.svg');
  cacheItem(u2685, 'default/viewfinder.svg');
  cacheItem(u2686, 'default/viewport-narrow.svg');
  cacheItem(u2687, 'default/viewport-wide.svg');
  cacheItem(u2688, 'default/vinyl.svg');
  cacheItem(u2689, 'default/virus-off.svg');
  cacheItem(u2690, 'default/virus-search.svg');
  cacheItem(u2691, 'default/virus.svg');
  cacheItem(u2692, 'default/vocabulary.svg');
  cacheItem(u2693, 'default/volume-2.svg');
  cacheItem(u2694, 'default/volume-3.svg');
  cacheItem(u2695, 'default/volume.svg');
  cacheItem(u2696, 'default/walk.svg');
  cacheItem(u2697, 'default/wall.svg');
  cacheItem(u2698, 'default/wallet.svg');
  cacheItem(u2699, 'default/wallpaper.svg');
  cacheItem(u2700, 'default/wand.svg');
  cacheItem(u2701, 'default/wave-saw-tool.svg');
  cacheItem(u2702, 'default/wave-sine.svg');
  cacheItem(u2703, 'default/wave-square.svg');
  cacheItem(u2704, 'default/webhook.svg');
  cacheItem(u2705, 'default/wifi-0.svg');
  cacheItem(u2706, 'default/wifi-1.svg');
  cacheItem(u2707, 'default/wifi-2.svg');
  cacheItem(u2708, 'default/wifi-off.svg');
  cacheItem(u2709, 'default/wifi.svg');
  cacheItem(u2710, 'default/wind.svg');
  cacheItem(u2711, 'default/windmill.svg');
  cacheItem(u2712, 'default/window.svg');
  cacheItem(u2713, 'default/windsock.svg');
  cacheItem(u2714, 'default/wiper-wash.svg');
  cacheItem(u2715, 'default/wiper.svg');
  cacheItem(u2716, 'default/woman.svg');
  cacheItem(u2717, 'default/world-download.svg');
  cacheItem(u2718, 'default/world-latitude.svg');
  cacheItem(u2719, 'default/world-longitude.svg');
  cacheItem(u2720, 'default/world-upload.svg');
  cacheItem(u2721, 'default/world.svg');
  cacheItem(u2722, 'default/wrecking-ball.svg');
  cacheItem(u2723, 'default/writing-sign.svg');
  cacheItem(u2724, 'default/writing.svg');
  cacheItem(u2725, 'default/x.svg');
  cacheItem(u2726, 'default/yin-yang.svg');
  cacheItem(u2727, 'default/yoga.svg');
  cacheItem(u2728, 'default/zodiac-aquarius.svg');
  cacheItem(u2729, 'default/zodiac-aries.svg');
  cacheItem(u2730, 'default/zodiac-cancer.svg');
  cacheItem(u2731, 'default/zodiac-capricorn.svg');
  cacheItem(u2732, 'default/zodiac-gemini.svg');
  cacheItem(u2733, 'default/zodiac-leo.svg');
  cacheItem(u2734, 'default/zodiac-libra.svg');
  cacheItem(u2735, 'default/zodiac-pisces.svg');
  cacheItem(u2736, 'default/zodiac-sagittarius.svg');
  cacheItem(u2737, 'default/zodiac-scorpio.svg');
  cacheItem(u2738, 'default/zodiac-taurus.svg');
  cacheItem(u2739, 'default/zodiac-virgo.svg');
  cacheItem(u2740, 'default/zoom-cancel.svg');
  cacheItem(u2741, 'default/zoom-check.svg');
  cacheItem(u2742, 'default/zoom-code.svg');
  cacheItem(u2743, 'default/zoom-exclamation.svg');
  cacheItem(u2744, 'default/zoom-in.svg');
  cacheItem(u2745, 'default/zoom-money.svg');
  cacheItem(u2746, 'default/zoom-out.svg');
  cacheItem(u2747, 'default/zoom-question.svg');
}
