import { FieldType, PanelPlugin, ReducerID, standardEditorsRegistry } from '@grafana/data';
import { BoxPlotPanel } from './BoxPlotPanel';
import { addHideFrom } from '../timeseries/config';
import { BoxPlotOptions, defaultGraphConfig } from './types';
import { addAxisConfig, addLegendOptions, addCandleStickOptions } from './config';
import { FieldSelectEditor } from '@grafana/ui';
import { boxPanelChangedHandler, boxPlotMigration } from './migration';

export const plugin = new PanelPlugin<BoxPlotOptions>(BoxPlotPanel)
  .useFieldConfig({
    useCustomConfig: (builder) => {
      const cfg = defaultGraphConfig;
      addCandleStickOptions(builder, cfg);
      addAxisConfig(builder, cfg, true);
      addHideFrom(builder);
    },
  })
  .setMigrationHandler(boxPlotMigration)
  .setPanelChangeHandler(boxPanelChangedHandler)
  .setPanelOptions((builder) => {
    builder
      .addSelect({
        path: 'toShowPlot',
        name: 'Plot Type',
        description: 'Choose Plot',
        defaultValue: 'boxplot',
        settings: {
          options: [
            {
              value: 'boxplot',
              label: 'Box Plot',
            },
            {
              value: 'candlestick',
              label: 'Candle Stick',
            },
          ],
        },
      })
      .addRadio({
        path: 'typeBoxPlot',
        name: 'Select Box Plot',
        description: 'Select Type Of Box Plot',
        defaultValue: 'normal',
        settings: {
          options: [
            { value: 'normal', label: 'Normal' },
            { value: 'withtime', label: 'With Time' },
          ],
        },
        showIf: (config) => {
          if (config.toShowPlot === 'boxplot') {
            return true;
          }
          return false;
        },
      })
      .addColorPicker({
        path: 'upcolor',
        name: 'Bearish Color',
        defaultValue: 'red',
        showIf: (c) => {
          if (c.toShowPlot === 'boxplot') {
            return false;
          }
          return true;
        },
      })
      .addColorPicker({
        path: 'downcolor',
        name: 'Bullish Color',
        defaultValue: 'green',
        showIf: (c) => {
          if (c.toShowPlot === 'boxplot') {
            return false;
          }
          return true;
        },
      })
      .addCustomEditor({
        id: 'ohlcData.open',
        path: 'ohlcData.open',
        name: 'Open',
        description: 'Select Open Query Field For Candle Stick Chart',
        editor: FieldSelectEditor,
        settings: {
          filterByType: [FieldType.number],
        },
        showIf: (c) => {
          if (c.toShowPlot === 'boxplot') {
            return false;
          }
          return true;
        },
      })
      .addCustomEditor({
        id: 'ohlcData.high',
        path: 'ohlcData.high',
        name: 'High',
        description: 'Select High Query Field For Candle Stick Chart',
        editor: FieldSelectEditor,
        settings: {
          filterByType: [FieldType.number],
        },
        showIf: (c) => {
          if (c.toShowPlot === 'boxplot') {
            return false;
          }
          return true;
        },
      })
      .addCustomEditor({
        id: 'ohlcData.low',
        path: 'ohlcData.low',
        name: 'Low',
        description: 'Select Low Query Field For Candle Stick Chart',
        editor: FieldSelectEditor,
        settings: {
          filterByType: [FieldType.number],
        },
        showIf: (c) => {
          if (c.toShowPlot === 'boxplot') {
            return false;
          }
          return true;
        },
      })
      .addCustomEditor({
        id: 'ohlcData.close',
        path: 'ohlcData.close',
        name: 'Close',
        description: 'Select Close Query Field For Candle Stick Chart',
        editor: FieldSelectEditor,
        settings: {
          filterByType: [FieldType.number],
        },
        showIf: (c) => {
          if (c.toShowPlot === 'boxplot') {
            return false;
          }
          return true;
        },
      })
      .addSelect({
        path: 'toCluster',
        name: 'Cluster Size',
        description: 'Choose Cluster Size (will not work when All Open, High, Low and Close fields are filled)',
        defaultValue: 30,
        settings: {
          options: [
            {
              value: 30,
              label: '30 Min',
            },
            {
              value: 60,
              label: '1 Hour',
            },
            {
              value: 1440,
              label: '24 Hour',
            },
            {
              value: 10080,
              label: '1 Week',
            },
            {
              value: 43200,
              label: '1 Month',
            },
            {
              value: 0,
              label: 'Custom',
            },
          ],
        },
        showIf: (config, data) => {
          if (
            (config.toShowPlot === 'boxplot' && config.typeBoxPlot !== 'normal') ||
            config.toShowPlot === 'candlestick'
          ) {
            return true;
          }
          return false;
        },
      })
      .addNumberInput({
        path: 'customClusterSize',
        name: 'Custom Cluster Size',
        description: 'Select Cluster Size in Minutes',
        defaultValue: 30,
        showIf: (c) => {
          if (
            c.toCluster === 0 &&
            ((c.toShowPlot === 'boxplot' && c.typeBoxPlot !== 'normal') || c.toShowPlot === 'candlestick')
          ) {
            return true;
          }
          return false;
        },
      })
      .addRadio({
        path: 'includeAllFields',
        name: 'Additional fields',
        description: 'Use standard timeseries options to configure any fields not mapped above',
        defaultValue: false,
        settings: {
          options: [
            { label: 'Ignore', value: false },
            { label: 'Include', value: true },
          ],
        },
        showIf: (c) => c.toShowPlot === 'candlestick',
      })
      .addRadio({
        path: 'tooltip.mode',
        name: 'Tooltip mode',
        description: '',
        defaultValue: 'single',
        category: ['Tooltip & Legend'],
        settings: {
          options: [
            { value: 'single', label: 'Show' },
            { value: 'none', label: 'Hidden' },
          ],
        },
      })
      .addCustomEditor({
        id: 'reduceOptions.calcs',
        path: 'reduceOptions.calcs',
        name: 'Value',
        description: 'Choose a reducer function / calculation',
        editor: standardEditorsRegistry.get('stats-picker').editor as any,
        defaultValue: [ReducerID.mean],
        showIf: (c) => {
          return false;
        },
        // Hides it when all values mode is on
        // showIf: currentConfig => currentConfig.reduceOptions.values === false,
      });
    addLegendOptions(builder);
  });
