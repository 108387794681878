import { InlineField, TextArea } from '@grafana/ui';
import { css } from 'emotion';
import React from 'react';

const reference =
  'Math operations on one more queries, you reference the query by ${refId} ie. $A, $B, $C etc\n' +
  'Example: $A + $B\n' +
  'Available functions: abs(), log(), nan(), inf(), null()';

export const Math = ({ labelWidth, onChange, query, onRunQuery }: any) => {
  const handleExpressionChange = (e: any) => {
    const { value } = e.target;
    onChange({ ...query, expression: value });
    onRunQuery();
  };

  return (
    <InlineField
      label="Expression"
      labelWidth={labelWidth}
      className={css`
        align-items: baseline;
      `}
    >
      <TextArea value={query.expression} onChange={handleExpressionChange} rows={4} placeholder={reference} />
    </InlineField>
  );
};
