import {
  GrafanaTheme,
  Threshold,
  FieldConfigSource,
  DataFrame,
  getDisplayForValue,
  getFormattedDisplayValue,
} from '@grafana/data';
import { retroTheme, darkTheme, nightTheme, aubergineTheme, silverTheme, darkTheme2 } from './mapThemes';
import { HeatmapLegend } from '../Google/interface';
import { cloneDeep } from 'lodash';

export const setLegend = (map: any, position: number, fieldConfig: FieldConfigSource<any>) => {
  if (fieldConfig.defaults.color?.mode === 'thresholds') {
    const div = document.createElement('div');
    div.className = 'gmnoprint map-legend-container';
    div.setAttribute('style', 'margin: 0px 0px 7px 10px !important;');
    let element: string | any = '';
    const steps: Threshold[] = fieldConfig.defaults.thresholds?.steps || [];
    for (let i = 0; i < steps.length; i++) {
      if (i === 0) {
        element +=
          '<div class="gm-style-mtc map-legend"><span class="gmap-th-box" style="background-color: ' +
          steps[i].color +
          '"></span>' +
          '<' +
          steps[1].value +
          '</div>';
      } else if (i === steps.length - 1) {
        element +=
          '<div class="gm-style-mtc map-legend"><span class="gmap-th-box" style="background-color: ' +
          steps[i].color +
          '"></span>' +
          ' ' +
          steps[i].value +
          '+' +
          '</div>';
      } else {
        element +=
          '<div class="gm-style-mtc map-legend"><span class="gmap-th-box" style="background-color: ' +
          steps[i].color +
          '"></span>' +
          steps[i].value +
          '-' +
          steps[i + 1].value +
          '</div>';
      }
    }
    div.innerHTML = element;
    map.controls[position].push(div);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
};

export const setLegendForHeatmap = (
  map: any,
  position: number,
  heatmapColors: string[],
  heatmapMinMax: HeatmapLegend,
  heatmapSteps: number,
  series: DataFrame[],
  theme: GrafanaTheme,
  timeZone: string
) => {
  const differenceValue = heatmapMinMax.max - heatmapMinMax.min;
  const value = differenceValue / (heatmapSteps - 1);
  const gradientSteps = [];
  let temp = heatmapMinMax.min;
  gradientSteps.push(heatmapMinMax.min);
  // calculate steps value
  for (let i = 0; i < heatmapSteps - 2; i++) {
    temp += value;
    gradientSteps.push(temp);
  }
  gradientSteps.push(heatmapMinMax.max);

  // Create the legend element
  var Heatmaplegend = document.createElement('div');
  Heatmaplegend.className = 'gmnoprint map-legendheatmap gmnoheatmap ';

  Heatmaplegend.setAttribute(
    'style',
    'margin: 10px;padding: 10px; display:flex; flex-direction: row; border-radius: 2px;'
  );

  // Add the Heatmaplegend element to the map controls
  map.controls[position].push(Heatmaplegend);

  // Create the gradient canvas
  var canvas = document.createElement('canvas');
  canvas.style.height = '100%';
  canvas.style.width = '20px';
  canvas.height = gradientSteps.length * 20;
  canvas.width = 20;

  var ctx = canvas.getContext('2d');

  // Create the gradient
  var gradient = ctx!.createLinearGradient(0, 0, 0, canvas.height);
  for (var i = 0; i < heatmapColors.length; i++) {
    gradient.addColorStop(i / (heatmapColors.length - 1), heatmapColors[i]);
  }

  // Fill the canvas with the gradient
  ctx!.fillStyle = gradient;
  ctx!.fillRect(0, 0, canvas.width, canvas.height);

  // Add the canvas to the Heatmaplegend element
  Heatmaplegend.appendChild(canvas);

  // Create the Heatmaplegend labels
  var heatmaplabels = document.createElement('div');
  heatmaplabels.setAttribute('style', 'margin-left: 10px; display:flex; flex-direction: column;');

  const f = filterNumericField(series);
  for (var j = 0; j < gradientSteps.length; j++) {
    var labelValue = document.createElement('div');
    labelValue.setAttribute(
      'style',
      'display: flex; align-items:center; justify-content: center; height: 20px; font-size: 12px;'
    );
    const display = getDisplayForValue(cloneDeep(f), gradientSteps[j], theme, timeZone);
    labelValue.innerHTML = getFormattedDisplayValue(display);

    // Add the labelValue to the Heatmaplegend element
    heatmaplabels.appendChild(labelValue);
  }

  // Add the labels to the Heatmaplegend element
  Heatmaplegend.appendChild(heatmaplabels);
};

export const themeCalc = (mapTheme: number, theme: GrafanaTheme) => {
  switch (mapTheme) {
    case 0:
      if (theme.isDark) {
        return darkTheme;
      } else {
        return [];
      }
    case 1:
      return retroTheme;
    case 2:
      return darkTheme2;
    case 3:
      return nightTheme;
    case 4:
      return aubergineTheme;
    case 5:
      return silverTheme;
    default:
      return [];
  }
};

export const filterNumericField = (series: DataFrame[]) => {
  let numericField;
  series[0].fields.forEach((field) => {
    if (field.type === 'number') {
      numericField = field;
    }
  });
  return numericField ?? series[0].fields[0];
};
