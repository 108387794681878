import React, { useCallback } from 'react';
import { FieldDisplay, getDisplayForValue, getFieldDisplayValues, GrafanaTheme } from '@grafana/data';
import { WindRose } from './windrose/WindRose';
import { PieChart } from './PieChart';

import { PiePanelProps } from './types/types';
import { useTheme } from '@grafana/ui';
import { changeSeriesColorConfigFactory } from '../timeseries/overrides/colorSeriesConfigFactory';
import { cloneDeep } from 'lodash';

/**
 * pie chart panel
 * @function
 * @param {PiePanelProps} props
 * @returns {JSX.Element}
 */
export const PieChartPanel: React.FunctionComponent<PiePanelProps> = (props: PiePanelProps): JSX.Element => {
  const { data, timeZone, fieldConfig, replaceVariables, options, onFieldConfigChange, timeRange } = props;

  const onSeriesColorChange = useCallback(
    (label: string, color: string) => {
      onFieldConfigChange(changeSeriesColorConfigFactory(label, color, fieldConfig));
    },
    [fieldConfig, onFieldConfigChange]
  );
  const theme: GrafanaTheme = useTheme();
  const values: FieldDisplay[] = getFieldDisplayValues({
    fieldConfig,
    reduceOptions: options.reduceOptions,
    data: data.series,
    theme: theme,
    replaceVariables,
    timeZone,
  });
  if (data.series.length <= 0) {
    return (
      <div className="panel-empty">
        <p>No data</p>
      </div>
    );
  }
  const modifiedData: FieldDisplay[] = cloneDeep(values);
  const units = Number(fieldConfig.defaults.unit) || 0;
  /**
   * we can limit the no. of pie chart slices.
   * By default no. of pie chart slices is equal to the no of queries.
   */
  if (options.limitSwitch && options.limitValue > 0) {
    /**
     * limit order filters the queries from top or bottom.
     * @example
     * no. of queries - 10,
     * limitValue - 3,
     * if limitOrder -> top - 1,2,3
     * if limitOrder -> bottom - 10,9,8
     */
    if (options.limitOrder === 'none' || options.limitOrder === 'top') {
      modifiedData.splice(options.limitValue, values.length);
      if (options.limitRemaining && values.length > options.limitValue) {
        /** merge all other remaining queries into one named as 'Others' */
        let remData = 0;
        for (let i = options.limitValue; i < values.length; i++) {
          remData += Number(values[i].display.numeric.toFixed(units));
        }
        modifiedData[options.limitValue] = cloneDeep(values[options.limitValue]);
        modifiedData[options.limitValue].display.title = 'Others';
        modifiedData[options.limitValue].display.numeric = remData;
        modifiedData[options.limitValue].display.text = `${remData}`;
        const f = data.series[0].fields[0];
        modifiedData[options.limitValue].display.color = getDisplayForValue(f, remData, theme, timeZone).color;
      }
    } else {
      /** when limit order is from bottom */
      if (!(values.length < options.limitValue)) {
        /**
         * when no. of queries is less than the limit value
         * which most general case if someone is not playing with it
         */
        modifiedData.splice(0, values.length - options.limitValue);
      }
      if (options.limitRemaining && values.length > options.limitValue) {
        /** merge all other remaining queries into one named as 'Others' */
        let remData = 0;
        for (let i = options.limitValue; i < values.length; i++) {
          remData += Number(values[i].display.numeric.toFixed(units));
        }
        modifiedData[options.limitValue] = cloneDeep(values[values.length - options.limitValue - 1]);
        modifiedData[options.limitValue].display.title = 'Others';
        modifiedData[options.limitValue].display.numeric = remData;
        modifiedData[options.limitValue].display.text = `${remData}`;
        const f = data.series[0].fields[0];
        modifiedData[options.limitValue].display.color = getDisplayForValue(f, remData, theme, timeZone).color;
      }
    }
  }
  /**
   * return if we didn't get any data in modified Data array if limit switch is on
   */
  if (options.limitSwitch && modifiedData.length <= 0) {
    return (
      <div className="panel-empty">
        <p>No Limit Provided</p>
      </div>
    );
  }
  switch (options.pieType) {
    case 'windrose':
      return <WindRose {...props} />;
    default:
      return (
        <PieChart
          values={options.limitSwitch ? modifiedData : values}
          onSeriesColorChange={onSeriesColorChange}
          restProps={props}
          timeRange={timeRange}
          drawerLinkTime={options.toVisitDashboard.drawerLinkTime}
        />
      );
  }
};
