import React, { useState } from 'react';
import { Input, Button, Select, InlineFormLabel } from '@grafana/ui';

interface Props {
  sceneList: {
    scenes: any[];
    queries: any[];
    currLoc: {
      x: number;
      y: number;
      z: number;
    };
  };
  onChange: (sceneList: { scenes: any[]; queries: any[] }) => void;
}
interface Scene {
  type: string;
  url: string;
  query: string;
  xPos: number;
  yPos: number;
  zPos: number;
  xRot: number;
  yRot: number;
  zRot: number;
  toVisit: string;
  orient: string;
}
const options = [
  { label: 'Camera', value: 'camera' },
  // { label: 'Sofa', value: 'sofa' },
  // { label: 'Chair', value: 'chair' },
  // { label: 'table', value: 'table' },
  // { label: 'Tag', value: 'tag' },
  { label: 'IOT Sensor', value: 'sensor' },
  { label: 'TV', value: 'tv' },
  { label: 'Image', value: 'image' },
];

export function SceneEditor(props: Props) {
  const [newType, setNewType] = useState('');
  const addNewSene = () => {
    const oldScenes = props.sceneList;
    if (newType === 'camera') {
      oldScenes.scenes.push({
        type: newType,
        xPos: 0,
        yPos: 0,
        zPos: 0,
        xRot: 0,
        yRot: 0,
        zRot: 0,
      });
    } else if (newType === 'sensor') {
      oldScenes.scenes.push({
        type: newType,
        query: '',
        xPos: 0,
        yPos: 0,
        zPos: 0,
        xRot: 0,
        yRot: 0,
        zRot: 0,
      });
    } else if (newType === 'tv') {
      oldScenes.scenes.push({
        type: newType,
        url: '',
        xPos: 0,
        yPos: 0,
        zPos: 0,
        xRot: 0,
        yRot: 0,
        zRot: 0,
      });
    } else {
      oldScenes.scenes.push({
        type: newType,
        url: '',
        xPos: 0,
        yPos: 0,
        zPos: 0,
        xRot: 0,
        yRot: 0,
        zRot: 0,
        toVisit: '',
        orient: '',
      });
    }
    props.onChange(oldScenes);
  };
  // const onTypeChange = (value: any, index: number) => {
  //   const oldScenes = props.sceneList;
  //   oldScenes.scenes[index].type = value.value;
  //   props.onChange(oldScenes);
  // };
  const newTypeChange = ({ value }: any) => {
    setNewType(value);
  };
  const onQueryChange = (value: any, index: number) => {
    const oldScenes = props.sceneList;
    oldScenes.scenes[index].query = value.value;
    props.onChange(oldScenes);
  };
  const onUrlChange = (event: React.SyntheticEvent<HTMLInputElement>, index: number) => {
    const oldScenes = props.sceneList;
    oldScenes.scenes[index].url = event.currentTarget.value;
    props.onChange(oldScenes);
  };
  const onVisitUrlChange = (event: React.SyntheticEvent<HTMLInputElement>, index: number) => {
    const oldScenes = props.sceneList;
    oldScenes.scenes[index].toVisit = event.currentTarget.value;
    props.onChange(oldScenes);
  };
  const onXPosChange = (event: React.SyntheticEvent<HTMLInputElement>, index: number) => {
    const oldScenes = props.sceneList;
    oldScenes.scenes[index].xPos = +event.currentTarget.value;
    props.onChange(oldScenes);
  };
  const onYPosChange = (event: React.SyntheticEvent<HTMLInputElement>, index: number) => {
    const oldScenes = props.sceneList;
    oldScenes.scenes[index].yPos = +event.currentTarget.value;
    props.onChange(oldScenes);
  };
  const onZPosChange = (event: React.SyntheticEvent<HTMLInputElement>, index: number) => {
    const oldScenes = props.sceneList;
    oldScenes.scenes[index].zPos = +event.currentTarget.value;
    props.onChange(oldScenes);
  };
  const onXRotChange = (event: React.SyntheticEvent<HTMLInputElement>, index: number) => {
    const oldScenes = props.sceneList;
    oldScenes.scenes[index].xRot = +event.currentTarget.value;
    props.onChange(oldScenes);
  };
  const onYRotChange = (event: React.SyntheticEvent<HTMLInputElement>, index: number) => {
    const oldScenes = props.sceneList;
    oldScenes.scenes[index].yRot = +event.currentTarget.value;
    props.onChange(oldScenes);
  };
  const onZRotChange = (event: React.SyntheticEvent<HTMLInputElement>, index: number) => {
    const oldScenes = props.sceneList;
    oldScenes.scenes[index].zRot = +event.currentTarget.value;
    props.onChange(oldScenes);
  };
  const deleteLabel = (index: number) => {
    let oldScenes = props.sceneList;
    oldScenes.scenes = oldScenes.scenes.filter((item, idx) => {
      return index !== idx;
    });
    props.onChange(oldScenes);
  };
  const orientCheck = (index: number) => {
    let oldScenes = props.sceneList;
    var img = new Image();
    img.src = oldScenes.scenes[index].url;
    img.onload = function () {
      if (img.width < img.height) {
        oldScenes.scenes[index].orient = 'vertical';
        props.onChange(oldScenes);
      } else {
        oldScenes.scenes[index].orient = 'horizontal';
        props.onChange(oldScenes);
      }
    };
  };
  const loadCurrLoc = (index: number) => {
    let oldScenes = props.sceneList;
    oldScenes.scenes[index].xPos = oldScenes.currLoc.x;
    oldScenes.scenes[index].yPos = oldScenes.currLoc.y;
    oldScenes.scenes[index].zPos = oldScenes.currLoc.z;
    props.onChange(oldScenes);
  };
  const { scenes, queries } = props.sceneList;
  return (
    <div className="width-20">
      {scenes.map((scene: Scene, index: number) => {
        return (
          <div key={index} className="cmb-1">
            <div className="form-field gf-form max-width-30">
              <label className="gf-form-label width-6">Type</label>
              <label className="gf-form-label width-5">{scene.type}</label>
              {/* <Select
                width={15}
                options={options}
                // defaultValue={options[0]}
                onChange={(value) => onTypeChange(value, index)}
                value={options.find((item: any) => item.value === scene.type)}
              /> */}
            </div>
            {scene.query !== undefined && (
              <div className="form-field gf-form max-width-30">
                <label className="gf-form-label width-6">Query</label>
                <Select
                  width={15}
                  options={queries}
                  // defaultValue={queries[0]}
                  onChange={(value) => onQueryChange(value, index)}
                  value={queries.find((item: any) => item.value === scene.query)}
                />
              </div>
            )}
            {scene.url !== undefined && (
              <div className="gf-form max-width-30">
                <InlineFormLabel width={6}>URL</InlineFormLabel>
                <Input value={scene.url} onChange={(event) => onUrlChange(event, index)} />
              </div>
            )}
            {scene.orient !== undefined && (
              <div className="form-field gf-form max-width-30">
                <label className="gf-form-label width-6">Orientation</label>
                <label className="gf-form-label width-5">{scene.orient}</label>
                <Button onClick={() => orientCheck(index)} variant="secondary">
                  <i className="fa fa-refresh" aria-hidden="true"></i>
                </Button>
              </div>
            )}
            {scene.toVisit !== undefined && (
              <div className="gf-form max-width-30">
                <InlineFormLabel width={6}>Click URL</InlineFormLabel>
                <Input value={scene.toVisit} onChange={(event) => onVisitUrlChange(event, index)} />
              </div>
            )}
            <div className="gf-form max-width-30">
              <InlineFormLabel
                tooltip="Increment in x will move the object in your right direction, decrement will move it to the left. To pass the current co-ordinates use reload button."
                width={6}
              >
                X Position
              </InlineFormLabel>
              <Input value={scene.xPos} onChange={(event) => onXPosChange(event, index)} />
              <Button onClick={() => loadCurrLoc(index)} variant="secondary">
                <i className="fa fa-refresh" aria-hidden="true"></i>
              </Button>
            </div>
            <div className="gf-form max-width-30">
              <InlineFormLabel
                tooltip="Increment in y will move the object upwards, decrement will move it downwards. To pass the current co-ordinates use reload button."
                width={6}
              >
                Y Position
              </InlineFormLabel>
              <Input value={scene.yPos} onChange={(event) => onYPosChange(event, index)} />
            </div>
            <div className="gf-form max-width-30">
              <InlineFormLabel
                tooltip="Increment in z will move the object forward, decrement will move it backwards. To pass the current co-ordinates use reload button."
                width={6}
              >
                Z Position
              </InlineFormLabel>
              <Input value={scene.zPos} onChange={(event) => onZPosChange(event, index)} />
            </div>
            <div className="gf-form max-width-30">
              <InlineFormLabel tooltip="Rotates the object vertically." width={6}>
                X Rotation
              </InlineFormLabel>
              <Input value={scene.xRot} onChange={(event) => onXRotChange(event, index)} />
            </div>
            <div className="gf-form max-width-30">
              <InlineFormLabel tooltip="Rotates the object horizontally." width={6}>
                Y Rotation
              </InlineFormLabel>
              <Input value={scene.yRot} onChange={(event) => onYRotChange(event, index)} />
            </div>
            <div className="gf-form max-width-30">
              <InlineFormLabel tooltip="Rotates the object diagonally." width={6}>
                Z Rotation
              </InlineFormLabel>
              <Input value={scene.zRot} onChange={(event) => onZRotChange(event, index)} />
            </div>
            <Button onClick={() => deleteLabel(index)} variant="destructive">
              <i className="fa fa-trash-o" aria-hidden="true"></i>
            </Button>
          </div>
        );
      })}
      <div className="form-field gf-form max-width-30">
        <label className="gf-form-label width-6">Type</label>
        <Select
          width={15}
          options={options}
          // defaultValue={options[0]}
          onChange={newTypeChange}
          value={options.find((item: any) => item.value === newType)}
        />
        <Button onClick={addNewSene} variant="secondary">
          Add
        </Button>
      </div>
      {/* {Object.keys(toEditAxis).map((key: any, index) => {
        return (
          <div className="gf-form gf-form--grow" key={toEditAxis[key].id}>
            <label className="gf-form-label width-9">{`Column ${+toEditAxis[key].id + 1}`}</label>
            <Input
              placeholder={`rename ${toEditAxis[key].name}`}
              onChange={onAxisTextChange}
              name={toEditAxis[key].id}
            />
          </div>
        );
      })} */}
    </div>
  );
}
