import { Circle, Fill, RegularShape, Stroke } from 'ol/style';
import ImageStyle from 'ol/style/Image';

/**
 * Get predefined icons/markers for a layer.
 *
 * @param {string} icon - The type of icon (e.g., "circle", "square").
 * @param {Fill} fill - The fill style for the icon.
 * @param {Stroke} stroke - The stroke style for the icon.
 * @param {number} size - The size of the icon.
 * @param {number} [rotation] - The rotation angle for the icon (optional).
 *
 * @returns {Circle | RegularShape | RegularShape[]} The icon style.
 */
export function getPreDefinedIcon(
  icon: string,
  fill: Fill,
  stroke: Stroke,
  size: number,
  rotation?: number
): ImageStyle {
  const styles: { [key: string]: ImageStyle } = {
    circle: new Circle({
      fill,
      stroke,
      radius: size,
    }),
    square: new RegularShape({
      fill: fill,
      stroke: stroke,
      points: 4,
      radius: size,
      angle: Math.PI / 4,
      rotation: rotation || 0,
    }),
    rectangle: new RegularShape({
      fill: fill,
      stroke: stroke,
      radius: size / Math.SQRT2,
      radius2: size,
      points: 4,
      angle: 0,
      scale: [1, 0.5],
      rotation: rotation || 0,
    }),
    triangle: new RegularShape({
      fill: fill,
      stroke: stroke,
      points: 3,
      radius: size,
      // rotation: Math.PI / 4,
      angle: 0,
      rotation: rotation || 0,
    }),
    star: new RegularShape({
      fill: fill,
      stroke: stroke,
      points: 5,
      radius: size,
      radius2: size / 2.5,
      angle: 0,
      rotation: rotation || 0,
    }),
    cross: new RegularShape({
      fill: fill,
      stroke: stroke,
      points: 4,
      radius: size,
      radius2: 0,
      angle: 0,
      rotation: rotation || 0,
    }),
    x: new RegularShape({
      fill: fill,
      stroke: stroke,
      points: 4,
      radius: size,
      radius2: 0,
      angle: Math.PI / 4,
      rotation: rotation || 0,
    }),
  };
  return styles[icon];
}
