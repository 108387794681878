import React, { useMemo } from 'react';
import { PanelProps, getFieldDisplayValues } from '@grafana/data';
import { useTheme } from '@grafana/ui';
import { getTemplateSrv } from '@grafana/runtime';
import { SankeyPanelOptions } from './types';
import { prepareSankeyDataNew } from './helpers/utils';
import Draw from './components/Draw';
import { refactorVariable, getNodeName } from './helpers/function';

interface Props extends PanelProps<SankeyPanelOptions> {}

export default function SankeyPanel({
  height,
  options,
  width,
  fieldConfig,
  replaceVariables,
  data,
  timeZone,
  renderCounter,
}: Props) {
  const theme = useTheme();

  const panelData = getFieldDisplayValues({
    fieldConfig,
    reduceOptions: options.reduceOptions,
    replaceVariables,
    theme: theme,
    data: data.series,
    timeZone,
  });

  // this will receive the global variables data.
  const vars = getTemplateSrv().getVariables();

  // data Refactoring
  const newSankeyData = refactorVariable(getNodeName(vars), options.sankeyData);

  // THIS WILL PREPARE THE FINAL DATA THAT WILL USE FOR THE PANEL.
  const { graph }: any = useMemo(() => prepareSankeyDataNew(panelData, theme, newSankeyData), [
    panelData,
    theme,
    newSankeyData,
  ]);

  if (graph.nodes.length <= 1) {
    return (
      <div className="panel-empty">
        <p>No data</p>
      </div>
    );
  }
  return (
    <div style={{ position: 'relative', width: width, height: height }}>
      {graph.nodes.length > 1 && (
        <Draw
          data={graph}
          width={width}
          height={height}
          nWidth={options.width}
          nPadding={options.nodePadding}
          nColor={options.nodecolor}
          nTextSize={options.textsize}
          diagram={options.diagram}
          margin={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        />
      )}
    </div>
  );
}
