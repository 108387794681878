import React, { useState } from 'react';
import { css, cx } from 'emotion';
import { PanelProps, SelectableValue } from '@grafana/data';
// import { PanelProps, renderMarkdown, FieldDisplay, getFieldDisplayValues, SelectableValue } from '@grafana/data';
// import { useTheme, CustomScrollbar, Select } from '@grafana/ui';
import { useTheme, Select } from '@grafana/ui';
import { TextOptions } from '../../types';
import { Text } from '../Text';
import Handlebars from 'handlebars';
// @ts-ignore
import DangerouslySetHtmlContent from 'dangerously-set-html-content';
import { registerHelpers } from '../../helpers/handlebars';
registerHelpers(Handlebars);

/**
 * Properties
 */
interface Props extends PanelProps<TextOptions> {}

/**
 * Panel
 */
export const TextPanel: React.FC<Props> = ({
  options,
  data,
  width,
  height,
  replaceVariables,
  fieldConfig,
  timeZone,
  timeRange,
}) => {
  /**
   * States
   */
  const [frameIndex, setFrameIndex] = useState(0);

  /**
   * Theme
   */
  const theme = useTheme();
  // const styles = getStyles(theme);

  /**
   * Change Frame
   */
  const onChangeFrame = (selectableValue: SelectableValue<string>) => {
    const index = data.series.findIndex((frame) => frame.fields[1].name === selectableValue.value);
    setFrameIndex(index);
  };

  /**
   * Frames
   */
  const selectableFrames = data.series.map((frame) => ({
    label: frame.fields[1]?.name ? frame.fields[1]?.name : '',
    value: frame.fields[1]?.name ? frame.fields[1]?.name : '',
  }));

  /**
   * Selected Frame
   */
  const frame = data.series[frameIndex];

  const styles = makeStyles(width, height, theme.isDark ? '#D8D9DA' : '#464C54');
  return (
    <div
      className={cx(
        styles.root,
        css`
          width: ${width}px;
          height: ${height}px;
        `
      )}
    >
      <div
        className={cx(
          styles.root,
          css`
            flex-grow: 1;
            overflow: auto;
          `
        )}
      >
        <Text
          width={width}
          frame={frame}
          options={options}
          timeRange={timeRange}
          fieldConfig={fieldConfig}
          replaceVariables={replaceVariables}
          timeZone={timeZone}
          dataseries={data}
        />
      </div>
      {/* Render Series Picker when it enable  */}
      {options.seriespicker
        ? data.series.length > 1 && (
            <div className={styles.frameSelect}>
              <Select onChange={onChangeFrame} value={frame.fields[1]?.name} options={selectableFrames} />
            </div>
          )
        : ''}
    </div>
  );
};

const makeStyles = (width: number, height: number, color: string) => ({
  frameSelect: css`
    padding: 10px;
  `,
  content: css`
    height: 100%;
    width: ${width}px;
    height: ${height}px;
    border-radius: 5px;
    & pre {
      background-color: transparent;
      background: transparent;
      color: ${color};
      border-radius: 0;
      border: none;
    }
  `,
  root: css`
    color: ${color};
    display: flex;
    flex-direction: column;
  `,
});
