import React, { useState } from 'react';
import { RadarChartSvg } from './RadarChartSvg';
import { VizLayout, VizLegend, VizLegendItem, LegendDisplayMode } from '@grafana/ui';
import { DataToUse, RadarChartLegendOptions, RadarPanelOptions } from './types';
import { FALLBACK_COLOR } from '@grafana/data';

/**
 * @typedef {Object} RadarChartProps
 * @property {Array<DataToUse>} newData
 * @property {RadarPanelOptions} options
 * @property {number} width
 * @property {number} height
 */
interface RadarChartProps {
  newData: DataToUse[];
  options: RadarPanelOptions;
  width: number;
  height: number;
}

/**
 * this component is mainly for legend
 * @param {RadarChartProps} props
 * @returns {JSX.Element}
 */
export const RadarChart: React.FunctionComponent<RadarChartProps> = (props: RadarChartProps) => {
  const { newData, options, width, height } = props;
  const [seletedData, setSelectedData] = useState(newData);
  const [toggle, setToggle] = useState<string>('');

  const handleButtonClick = (selected: VizLegendItem) => {
    if (selected.label === toggle) {
      setSelectedData(newData); // Toggle off if already selected
      setToggle('');
    } else {
      const foundObject = newData.find((obj) => obj.name === selected.label);
      if (foundObject === undefined) {
        setSelectedData(newData);
      } else {
        const selectedData: DataToUse[] = [foundObject];
        setSelectedData(selectedData);
        setToggle(selected.label);
      }
    }
  };

  const getLegend = (values: DataToUse[], legendOptions: RadarChartLegendOptions) => {
    if (legendOptions.displayMode === LegendDisplayMode.Hidden) {
      return undefined;
    }

    let legendItems: VizLegendItem[] = [];
    legendItems = values.map<VizLegendItem>((value, idx) => {
      return {
        label: value.name ?? '',
        color: value.stroke ?? FALLBACK_COLOR,
        yAxis: 1,
        getItemKey: () => (value.name ?? '') + idx,
        disabled: value.name === toggle ? false : true,
      };
    });
    return (
      <VizLegend
        items={legendItems}
        onSeriesColorChange={undefined}
        placement={legendOptions.placement}
        displayMode={legendOptions.displayMode}
        onLabelClick={(e) => handleButtonClick(e)}
      />
    );
  };

  return (
    <VizLayout width={width} height={height} legend={getLegend(newData, options.legend)}>
      {(vizWidth: number, vizHeight: number) => {
        return <RadarChartSvg width={vizWidth} height={vizHeight} newData={seletedData} options={options} />;
      }}
    </VizLayout>
  );
};
