import React, { useEffect, useState } from 'react';
import {
  AppEvents,
  DataFrame,
  SelectableValue,
  TransformerRegistryItem,
  TransformerUIProps,
  getTimeField,
} from '@grafana/data';
import { AnomalyScoreOptions, Default_Alias, anomalyScoreTransformer } from './AnomalyScore';
import { InlineField, Input, Select } from '@grafana/ui';
import appEvents from 'app/core/app_events';

export function AnomalyScoreEditor(props: TransformerUIProps<AnomalyScoreOptions>): React.ReactElement {
  const { options, onChange, input } = props;
  const [fieldOptions, setFieldOptions] = useState<SelectableValue[]>([]);

  const onActualFieldChanged = (value: SelectableValue<string>) => {
    onChange({ ...options, actualField: value.value });
  };

  const onPredictedFieldChanged = (value: SelectableValue<string>) => {
    onChange({ ...options, predictedField: value.value });
  };

  const onAliasChanged = (e: any) => {
    onChange({ ...options, alias: e.target.value });
  };

  const checkIfTimeseriesData = (input: DataFrame[]) => {
    let isTimeSeries = true;
    if (!input.length) {
      return isTimeSeries;
    }
    for (const item of input) {
      if (!item.fields || item.fields.length === 0 || item.fields[0].type !== 'time') {
        isTimeSeries = false; // If any dataframe is not a time series, set to false
        break; // No need to continue checking
      }
    }
    return isTimeSeries;
  };

  const fetchNonTimeFields = (input: DataFrame[]) => {
    const fieldNames = new Set(); // To keep track of field names encountered
    const uniqueFieldNames = new Set(); // To store unique field names

    for (let i = 0; i < input.length; i++) {
      const dataframe = input[i];
      if (dataframe.fields) {
        for (const field of dataframe.fields) {
          if (field.type !== 'time') {
            const fieldName = field.name;
            if (fieldNames.has(fieldName)) {
              // Field name already encountered, make it unique by adding dataframe index
              let uniqueFieldName = `${fieldName} ${i}`;
              let j = 1;
              while (uniqueFieldNames.has(uniqueFieldName)) {
                uniqueFieldName = `${fieldName} ${i}_${j}`;
                j++;
              }
              uniqueFieldNames.add(uniqueFieldName);
            } else {
              uniqueFieldNames.add(fieldName);
            }
            fieldNames.add(fieldName);
          }
        }
      }
    }
    return Array.from(uniqueFieldNames);
  };

  useEffect(() => {
    if (!checkIfTimeseriesData(input)) {
      appEvents.emit(AppEvents.alertError, ['Anomaly Score Transformer only works with time series dataframes.']);
      return;
    }
    if (input.length) {
      onChange({ ...options, isTimeSeries: true, timeField: getTimeField(input[0]).timeField?.name });
      setFieldOptions(fetchNonTimeFields(input).map((ele: any) => ({ label: ele, value: ele })));
    }
  }, [input]);
  return (
    <>
      <InlineField labelWidth={25} label="Select Actual Field">
        <Select options={fieldOptions} value={options.actualField} onChange={onActualFieldChanged} />
      </InlineField>
      <InlineField labelWidth={25} label="Select Predicted Field">
        <Select options={fieldOptions} value={options.predictedField} onChange={onPredictedFieldChanged} />
      </InlineField>
      <InlineField labelWidth={25} label="Alias">
        <Input value={options.alias === undefined ? Default_Alias : options.alias} onChange={onAliasChanged} />
      </InlineField>
    </>
  );
}
export const anomalyScoreRegistryItem: TransformerRegistryItem<AnomalyScoreOptions> = {
  id: anomalyScoreTransformer.id,
  editor: AnomalyScoreEditor,
  transformation: anomalyScoreTransformer,
  name: anomalyScoreTransformer.name,
  description: anomalyScoreTransformer.description,
};
