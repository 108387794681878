import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const Question: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 64 64"
      viewBox="0 0 64 64"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M32,3.3c-16,0-28.9,13-28.9,28.9S16,61.2,32,61.2s28.9-13,28.9-28.9C60.9,16.3,48,3.3,32,3.3z M32,56.3
        c-13.3,0-24.1-10.8-24.1-24.1S18.7,8.1,32,8.1s24.1,10.8,24.1,24.1C56.1,45.5,45.3,56.3,32,56.3z M33.7,15.5
        c-5.2-1-10.3,2.5-11.2,7.8c-0.1,0.6-0.2,1.2-0.2,1.7c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4c0-2.7,2.2-4.8,4.8-4.8
        c0.3,0,0.6,0,0.9,0.1c1.9,0.4,3.5,1.9,3.9,3.8c0.4,2.1-0.6,4.1-2.4,5.1c-3,1.7-4.8,5-4.8,8.5v1.8c0,1.3,1.1,2.4,2.4,2.4
        s2.4-1.1,2.4-2.4v-1.8c-0.1-1.7,0.8-3.3,2.2-4.2c4.7-2.6,6.4-8.4,3.8-13.1C39.1,17.8,36.6,16,33.7,15.5z M32,44.3L32,44.3
        c1.3,0,2.4,1.1,2.4,2.4l0,0c0,1.3-1.1,2.4-2.4,2.4l0,0c-1.3,0-2.4-1.1-2.4-2.4l0,0C29.6,45.4,30.7,44.3,32,44.3z"
      />
    </svg>
  );
};
