import { CalendarEvent } from '../types';

/**
 * Format Event Interval
 */
export const formatEventInterval = (event: CalendarEvent): string => {
  if (event.end) {
    if (event.start.startOf('day').isSame(event.end?.startOf('day'))) {
      return `${event.start.format('LLL')}–${event.end.format('LT')}`;
    }
    return `${event.start.format('LLL')}–${event.end.format('LLL')}`;
  }
  return `${event.start.format('LLL')}`;
};
