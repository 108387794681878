import { AnnotationEvent } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
// import { getDashboardSrv } from 'app/features/dashboard/services/DashboardSrv';
import { getTimeSrv } from 'app/features/dashboard/services/TimeSrv';

export const addAnnotations = async (event: AnnotationEvent, panelID: number) => {
  // const dash = getDashboardSrv().getCurrent();
  // const panel = dash.panels.filter((item) => item.id === panelID)[0];
  // console.log(panel);
  await getBackendSrv().post('/api/annotations', event);
  // panel.refresh();
  getTimeSrv().refreshDashboard();
  return;
};

export const deleteAnnotations = async (annotationID: string, panelID: number) => {
  // const dash = getDashboardSrv().getCurrent();
  // const panel = dash.panels.filter((item) => item.id === panelID)[0];
  // console.log(panel);
  await getBackendSrv().delete(`/api/annotations/${annotationID}`);
  // panel.refresh();
  getTimeSrv().refreshDashboard();
  return;
};

export const upDateAnnotations = async (event: AnnotationEvent, panelID: number) => {
  // const dash = getDashboardSrv().getCurrent();
  // const panel = dash.panels.filter((item) => item.id === panelID)[0];
  await getBackendSrv().put(`/api/annotations/${event.id}`, event);
  // panel.refresh();
  getTimeSrv().refreshDashboard();
  return;
};
