import React, { useMemo } from 'react';
import { Chart } from './windrosesvg';
import { getTimelineData } from '../../timeline/utils/utils';
import { calculateWindRose, calculateWindRoseColumns, windRoseFrames, generateCount } from './utils';
import { buildColorMapper } from '../../carpetplot/components/Chart';
import { PiePanelProps } from '../types/types';
import { DataFrame } from '@grafana/data';
import { Count } from './types';

export function WindRose(props: PiePanelProps) {
  const { data } = props;
  const { frames, err } = useMemo(() => getTimelineData(data?.series), [data]);

  if (err || !frames) {
    return (
      <div className="panel-empty">
        <p>{err ?? 'No data'}</p>
      </div>
    );
  }

  return <WindRoseEditor frames={frames} windProps={props} />;
}

interface WindEditor {
  windProps: PiePanelProps;
  frames: DataFrame[];
}

export function WindRoseEditor({ windProps, frames }: WindEditor) {
  const { options, width, height, data } = windProps;
  const { windData, err, valueField } = useMemo(() => windRoseFrames(frames, options), [frames]);

  if (err || !windData || !valueField) {
    return (
      <div className="panel-empty">
        <p>{err ?? 'No data'}</p>
      </div>
    );
  }

  // @ts-ignore
  const maxSpeed: number = data.request?.targets[1]?.max;
  const count: Count | any = generateCount(maxSpeed);

  const windRoseData = calculateWindRose(windData, count, maxSpeed);
  const windRoseColumns = calculateWindRoseColumns(windRoseData[0]);

  let colorArray: string[] = [];

  const colorMapper = buildColorMapper(valueField, 0, windRoseColumns.slice(1).length);

  for (let i = 0; i < windRoseColumns.slice(1).length; i++) {
    colorArray.push(colorMapper(i));
  }

  return (
    <Chart
      width={width}
      height={height}
      chartData={windRoseData}
      columns={windRoseColumns}
      responsive={false}
      legendGap={options.legendGap}
      colors={colorArray}
      placement={options.legend.placement}
      maxSpeed={maxSpeed}
    />
  );
}
