export interface Threshold {
  value: number;
  color: string;
  /**
   *  Warning, Error, LowLow, Low, OK, High, HighHigh etc
   */
  state?: string;
}

/**
 *  Display mode
 */
export enum ThresholdsMode {
  Absolute = 'absolute',
  /**
   *  between 0 and 1 (based on min/max)
   */
  Percentage = 'percentage',
}

/**
 *  Config that is passed to the ThresholdsEditor
 */
export interface ThresholdsConfig {
  mode: ThresholdsMode;

  /**
   *  Must be sorted by 'value', first value is always -Infinity
   */
  steps: Threshold[];
}

export enum PaletteMode {
  Random = 'random',
  Custom = 'custom',
  Loader = 'loader',
}

export interface CustomPaletteConfig {
  colors: string[];
  mode: PaletteMode;
  currColor: string; // Single color used to generate palette for Palette Mode 'Custom'
  numOfShades: number; // numOfShades used to generate palette for Palette Mode 'Custom'
  rawColors: string;
}
