import { FieldDisplay, DataFrame } from '@grafana/data';
import { BoxDataType } from './interface';

/**
 * calculate box data using frames and getValues function
 * @function
 * @param {Array<DataFrame>} newFrames data frames
 * @param {Array<FieldDisplay>} getData values from getValues Function
 * @returns {BoxDataType} boxData
 */

export const calculateBoxData = (newFrames: DataFrame[], getData: FieldDisplay[]) => {
  let boxData: BoxDataType = {};
  let medArray: number[] = [];
  let q1Array: number[] = [];
  let q3Array: number[] = [];
  let minArray: number[] = [];
  let maxArray: number[] = [];
  let valueArrayBox: number[] = [];
  let queryIndex: number[] = [];
  let queryName: string[] = [];
  let colorArray: string[] = [];

  for (let index = 1; index < newFrames![0].fields.length; index++) {
    const field = newFrames![0].fields[index];
    const getDataFrame = getData[index - 1];
    valueArrayBox = field.values.toArray();
    /**
     * sort value buffer so that Q1 Q3 Median Min Max are easy to calculate
     */
    valueArrayBox.sort((a: number, b: number) => {
      return a - b;
    });

    /**
     * fill zero at the place of null in value buffer
     */
    // for (let i = 0; i < valueArrayBox.length; i++) {
    //   if (valueArrayBox[i] == null) {
    //     valueArrayBox[i] = 0;
    //   }
    // }

    //filter the value except null value. if you fill zero at the place of null , so always min value is 0.
    const newvalueArrayBox = valueArrayBox.filter((item) => {
      return item !== null;
    });

    // even length of array
    if (newvalueArrayBox.length % 2 === 0 && newvalueArrayBox.length !== 0) {
      //  when length is even
      let med = findMedian(newvalueArrayBox, newvalueArrayBox.length);
      medArray.push(Math.round(med));
      let preMedIndex = newvalueArrayBox.length / 2;
      let q1 = newvalueArrayBox[Math.floor(preMedIndex / 2) - 1];
      q1Array.push(Math.round(q1));
      let q3 = newvalueArrayBox[Math.floor(preMedIndex + preMedIndex / 2) - 1];
      q3Array.push(Math.round(q3));
    } else {
      // when length is odd
      let medIndex = Math.floor(newvalueArrayBox.length / 2);
      let med = newvalueArrayBox[medIndex];
      medArray.push(Math.round(med));
      let preMedIndex = (newvalueArrayBox.length - 1) / 2;
      let q1 = newvalueArrayBox[Math.floor(preMedIndex / 2) - 1];
      q1Array.push(Math.round(q1));
      let q3 = newvalueArrayBox[Math.floor(preMedIndex + preMedIndex / 2)];
      q3Array.push(Math.round(q3));
    }
    minArray.push(Math.round(newvalueArrayBox[0]));
    maxArray.push(Math.round(newvalueArrayBox[newvalueArrayBox.length - 1]));
    queryName.push(getDataFrame.display.title!);
    colorArray.push(getDataFrame.display.color!);
  }

  for (let i = 0; i < getData.length; i++) {
    queryIndex.push(i);
  }
  boxData.queryIndex = {
    name: 'queryIndex',
    values: queryIndex,
  };
  boxData.queryName = {
    name: 'queryName',
    values: queryName,
  };
  boxData.q1 = {
    name: 'q1',
    values: q1Array,
  };
  boxData.q3 = {
    name: 'q3',
    values: q3Array,
  };
  boxData.median = {
    name: 'median',
    values: medArray,
  };
  boxData.min = {
    name: 'min',
    values: minArray,
  };
  boxData.max = {
    name: 'max',
    values: maxArray,
  };
  boxData.color = {
    name: 'color',
    values: colorArray,
  };
  return boxData;
};

export function findMedian(a: number[], n: number) {
  return (a[Math.floor((n - 1) / 2)] + a[n / 2]) / 2;
}
