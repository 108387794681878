import { StandardEditorProps } from '@grafana/data';
import { Button, Container, Field, HorizontalGroup, InlineField, ValuePicker } from '@grafana/ui';
import React from 'react';

import { FeatureStyleConfig } from '../Layers/geojsonLayer/type';
import { GeojsonRuleStyle } from '../StyleEditor/GeojsonRuleStyle';
import { GeojsonStyle, LABEL_WIDTH } from '../StyleEditor/GeojsonStyle';
import { DEFAULT_STYLE_RULE } from '../StyleEditor/default';
import { layerOptions } from '../StyleEditor/type';
import { OSMapLayerState, OSMapPanelOptions, OSMapInstanceState } from '../types/types';
import { makeFirstCapital } from './types';
import { CustomHeatmap } from '../StyleEditor/CustomMarkerStyle/CustomHeatmap';
import { HeatmapLayerStyle } from '../StyleEditor/HeatmapLayerStyle';
import { MarkerLayerStyle } from '../StyleEditor/MarkerLayerStyle';
import { CustomMarker } from '../StyleEditor/CustomMarkerStyle/CustomMarker';
import { MapLayerType } from '../types/interface';
import { MarkerRuleStyle } from '../StyleEditor/MarkerRuleStyle';
import { MarkerFeatureStyleConfig } from '../Layers/markerLayer/type';
import { DEFAULT_MARKER_STYLE_RULE } from '../Layers/markerLayer/default';
import { LinkDashboard } from '../StyleEditor/markerDashboardLink/LinkDashboard';

/**
 * Properties for a layers editor component.
 *
 * @typedef {StandardEditorProps<any, any, OSMapPanelOptions, OSMapInstanceState>} LayersEditorProps
 */
export type LayersEditorProps = StandardEditorProps<any, any, OSMapPanelOptions, OSMapInstanceState>;

/**
 * A component for editing map layers and their styles.
 *
 * @function
 * @param {LayersEditorProps} props - The properties for the LayersEditor component.
 * @param {StandardEditorContext} props.context - The context object containing map-related information.
 * @param {Object} props.data - The data associated with the map.
 * @param {Object} props.instanceState - The state of the map instance.
 */
export const LayersEditor = (props: LayersEditorProps) => {
  const { replaceVariables, options, data, instanceState } = props.context;
  const { layers, selected, actions, map } = instanceState ?? {};

  if (!layers || !actions) {
    return <div>No layers?</div>;
  }
  const onDelete = (name: string) => {
    actions.deleteLayer(name);
  };

  const onAddRule = (layer: OSMapLayerState<any>) => {
    const newRule = [...layer.options.config.rules];
    newRule.push(layer.options.type === MapLayerType.GEOJSON ? DEFAULT_STYLE_RULE : DEFAULT_MARKER_STYLE_RULE);
    layer.onChange({
      ...layer.options,
      config: { ...layer.options.config, rules: newRule },
    });
  };

  return (
    <>
      <Container>
        <ValuePicker
          label="Add a layer"
          options={layerOptions}
          onChange={(v) => actions.addLayer(v.value!)}
          variant="primary"
          size="xs"
        />
      </Container>

      <br />
      {layers &&
        layers.length > 1 &&
        layers.map((layer, i) => {
          if (i === 0) {
            return null;
          } else {
            return (
              <div key={i}>
                <HorizontalGroup>
                  <InlineField label={layer.getName()} labelWidth={LABEL_WIDTH} grow={true}>
                    <>
                      <Button
                        onClick={() => actions.selectLayer(layer.getName())}
                        variant={selected === i ? 'secondary' : 'primary'}
                        style={{ marginRight: '4px', width: '185px' }}
                      >
                        {makeFirstCapital(layer.options.type) + ' Layer'}
                      </Button>
                      <Button onClick={() => onDelete(layer.getName())} variant="destructive" size="md">
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </Button>
                    </>
                  </InlineField>
                </HorizontalGroup>
              </div>
            );
          }
        })}
      {layers &&
        layers.length > 1 &&
        layers.map((layer, i) => {
          if (i === 0) {
            return null;
          } else {
            return (
              <div style={{ marginTop: '5px' }} key={i}>
                {selected === i && layer.options.type === MapLayerType.GEOJSON && (
                  <>
                    {layer.handler.getLayerInfo && (
                      <GeojsonStyle layer={layer} layerInfoObj={layer.handler.getLayerInfo()} />
                    )}
                    {layer.options.config.rules &&
                      layer.options.config.rules.length > 0 &&
                      layer.options.config.rules.map((rule: FeatureStyleConfig, i: number) => {
                        const layerInfo = layer.handler.getLayerInfo ? layer.handler.getLayerInfo() : null;
                        const featuresObj = layer.handler.getFeatures ? layer.handler.getFeatures() : null;
                        if (layerInfo && featuresObj) {
                          return (
                            <Field label="Style Features Rule" key={i}>
                              <GeojsonRuleStyle
                                layerInfoObj={layerInfo}
                                featuresObj={featuresObj}
                                layer={layer}
                                value={rule}
                                ruleIdx={i}
                              />
                            </Field>
                          );
                        } else {
                          return null;
                        }
                      })}
                    <Button
                      size="sm"
                      icon="plus"
                      onClick={() => onAddRule(layer)}
                      variant="secondary"
                      aria-label={'Add style rule for feature in geojson'}
                    >
                      {'Add style rule'}
                    </Button>
                  </>
                )}
                {selected === i && layer.options.type === MapLayerType.HEATMAP && (
                  <HeatmapLayerStyle layer={layer} data={data} />
                )}
                {selected === i && layer.options.type === MapLayerType.MARKER && (
                  <>
                    {layer.handler.getFeaturesNew && (
                      <MarkerLayerStyle features={layer.handler.getFeaturesNew()} layer={layer} data={data} />
                    )}
                    {layer.options.config.rules &&
                      layer.options.config.rules.length > 0 &&
                      layer.options.config.rules.map((rule: FeatureStyleConfig, i: number) => {
                        const features = layer.handler.getFeaturesNew ? layer.handler.getFeaturesNew() : null;
                        if (features) {
                          return (
                            <Field label="Style Feature Rule" key={i}>
                              <MarkerRuleStyle
                                features={features}
                                layer={layer}
                                value={rule as MarkerFeatureStyleConfig}
                                ruleIdx={i}
                              />
                            </Field>
                          );
                        } else {
                          return null;
                        }
                      })}
                    <Button
                      size="sm"
                      icon="plus"
                      onClick={() => onAddRule(layer)}
                      variant="secondary"
                      aria-label={'Add style rule for feature in marker layer'}
                    >
                      {'Add style rule'}
                    </Button>
                    <Field label={'Dashboard link'} style={{ marginBottom: '8px', marginTop: '14px' }}>
                      <LinkDashboard layer={layer} replaceVariables={replaceVariables} options={options} data={data} />
                    </Field>
                  </>
                )}
                {selected === i && layer.options.type === MapLayerType.CUSTOMHEATMAP && (
                  <CustomHeatmap
                    map={map}
                    layer={layer}
                    replaceVariables={replaceVariables}
                    options={options}
                    data={data}
                  />
                )}
                {selected === i && layer.options.type === MapLayerType.CUSTOMMARKER && (
                  <CustomMarker
                    map={map}
                    layer={layer}
                    replaceVariables={replaceVariables}
                    options={options}
                    data={data}
                  />
                )}
              </div>
            );
          }
        })}
    </>
  );
};
