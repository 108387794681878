import { css } from '@emotion/css';
import React from 'react';
import ReactDiffViewer, { ReactDiffViewerProps, DiffMethod } from 'react-diff-viewer';

import { useTheme } from '@grafana/ui';
import { ReactDiffThemable } from './theme';

export const DiffViewerReact = ({ oldValue, newValue }: ReactDiffViewerProps) => {
  const theme = useTheme();
  return (
    <div
      className={css`
        font-size: ${theme.typography.size.sm};
        overflow-x: scroll;
        // prevent global styles interfering with diff viewer
        pre {
          all: revert;
        }
      `}
    >
      <ReactDiffViewer
        styles={ReactDiffThemable(theme)}
        oldValue={oldValue}
        newValue={newValue}
        splitView={false}
        compareMethod={DiffMethod.CSS}
        useDarkTheme={theme.isDark}
      />
    </div>
  );
};
