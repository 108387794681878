import { openStreetSymbol } from '../../StyleEditor/type';
import { QueryModes } from '../../types/interface';
import { ComparisonOperation } from '../geojsonLayer/default';
import { MarkerFeatureStyleConfig, MarkerStyleConfig, MarkersConfig } from './type';

export const defaultQueryConfig = {
  queryMode: QueryModes.Auto,
  geohashQuerySelections: {
    location: '',
    geohash: '',
    value: '',
    latitude: '',
    longitude: '',
    lat1: '',
    lat2: '',
    lat3: '',
    lat4: '',
    lng1: '',
    lng2: '',
    lng3: '',
    lng4: '',
  },
  dataField: '',
};

export const defaultCustomMarkerConfig = {
  markers: [],
  currIdx: -1,
  enabled: false,
};

export const defaultStyleConfig: MarkerStyleConfig = {
  size: {
    min: 2,
    max: 15,
  },
  color: 'dark-green',
  fillOpacity: 0.4,
  rotation: 0,
  symbol: openStreetSymbol[0].value,
  markerIcon: {
    sourceType: '',
    iconName: '',
    url: '',
    urlPath: '',
  },
};

export const defaultOptions: MarkersConfig = {
  rules: [],
  style: defaultStyleConfig,
  queryOptions: defaultQueryConfig,
  markersLink: [],
  drawerLinkTime: false,
};

export const DEFAULT_MARKER_STYLE_RULE: MarkerFeatureStyleConfig = {
  style: defaultStyleConfig,
  check: {
    property: '',
    operation: ComparisonOperation.EQ,
    value: '',
  },
};
