import React, { FC, useMemo } from 'react';
import Chart from 'react-apexcharts';
import { FieldDisplay, GrafanaTheme } from '@grafana/data';
import { StackedBarOptions } from '../types';

interface StackProps {
  bigData: FieldDisplay[];
  width: number;
  height: number;
  options: StackedBarOptions;
  units: number;
  theme: GrafanaTheme;
  renderCounter: number;
}

export const Stack: FC<StackProps> = ({ width, height, bigData, options, units, theme, renderCounter }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { noNewSeries, colorset, newCategories, force } = useMemo(() => computeData(bigData, options, units), [
    renderCounter,
    bigData,
  ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const chartOptions = useMemo(() => generateOptions(bigData, options, colorset, newCategories, theme, width), [force]);

  return (
    <div style={{ position: 'relative', width, height }}>
      <Chart key={force} options={chartOptions} series={noNewSeries} type="bar" width={width} height={height} />
    </div>
  );
};

const computeData = (bigData: FieldDisplay[], options: StackedBarOptions, units: number) => {
  let colorset: any = [];
  const noNewSeries = [];
  const newCategories: any = [];
  for (let i = 0; i < bigData.length; i++) {
    colorset.push(bigData[i].display.color);
    let dataToPush: any = {};
    const arrayNew: any = [];
    const minimum = Math.min(bigData.length, options.columns);
    for (let j = 0; j < minimum; j++) {
      if (
        options.toDeleteQuery[0].deletedQueryValue.some(
          (person: any) => person.deleteQuery === bigData[i].display.title && person.deleteValue === `Column ${j + 1}`
        )
      ) {
        arrayNew.push(0);
      } else {
        arrayNew.push(Number(bigData[i].display.numeric.toFixed(units)));
      }
    }
    if (i < options.columns) {
      if (options.toEditAxis[i]) {
        newCategories.push(options.toEditAxis[i].name);
      } else {
        newCategories.push(`Column ${i + 1}`);
      }
    }
    dataToPush = {
      name: bigData[i].display.title,
      data: arrayNew,
    };
    noNewSeries.push(dataToPush);
  }
  return {
    noNewSeries,
    colorset,
    newCategories,
    force: Math.floor(Math.random() * 100000),
  };
};

const generateOptions = (
  bigData: FieldDisplay[],
  options: StackedBarOptions,
  colorset: string[],
  newCategories: string[],
  theme: GrafanaTheme,
  width: number
) => {
  return {
    chart: {
      animations: {
        enabled: false,
      },
      type: 'bar',
      stacked: options.stacked,
      toolbar: {
        show: true,
      },
    },
    colors: colorset,
    fill: {
      opacity: 1,
    },
    plotOptions: {
      bar: {
        horizontal: options.chartOrient === 'vertical' ? false : true,
      },
    },
    xaxis: {
      categories: newCategories,
      labels: {
        show: options.labels,
        style: {
          colors: theme.isLight ? '#373D3F' : '#d8d9da',
        },
      },
      axisBorder: {
        show: options.axisBorder,
      },
      axisTicks: {
        show: options.axisTicks,
      },
      title: {
        text: options.chartOrient === 'vertical' ? options.xAxis : options.yAxis,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: theme.isLight ? '#373D3F' : '#d8d9da',
          fontSize: '12px',
          fontWeight: 'bold',
        },
      },
      tooltip: {
        enabled: options.chartOrient === 'vertical' ? options.horizontalTooltip : false,
        offsetY: 0,
      },
    },
    yaxis: {
      show: true,
      // showAlways: true,
      showForNullSeries: true,
      labels: {
        show: options.labels,
        style: {
          colors: theme.isLight ? '#373D3F' : '#d8d9da',
        },
      },
      style: {
        colors: theme.isLight ? ['#373D3F'] : ['#d8d9da'],
      },
      title: {
        text: options.chartOrient === 'vertical' ? options.yAxis : options.xAxis,
        rotate: '90',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: theme.isLight ? '#373D3F' : '#d8d9da',
          fontSize: '12px',
          fontWeight: 'bold',
        },
      },
      crosshairs: {
        show: true,
        position: 'back',
        stroke: {
          color: '#b6b6b6',
          width: 1,
          dashArray: 0,
        },
      },
      tooltip: {
        enabled: options.chartOrient === 'vertical' ? options.horizontalTooltip : false,
        offsetX: 0,
      },
    },
    tooltip: {
      enabled: true,
      theme: 'light',
      shared: false,
      followCursor: true,
      inverseOrder: false,
      y: {
        formatter(value: any, opts: any) {
          const item = bigData[opts.seriesIndex].display;
          return (item.prefix || '') + item.text + (item.suffix || '');
        },
      },
    },
    dataLabels: {
      enabled: options.dataLabels,
      formatter: function (value: any, opts: any) {
        const item = bigData[opts.seriesIndex].display;
        return (item.prefix || '') + item.text + (item.suffix || '');
      },
    },
    legend: {
      show: width > 650 ? options.legendShow : false,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: options.legendOrientation,
      horizontalAlign: options.horizontalAlign,
      floating: false,
      labels: {
        colors: theme.isDark ? '#d8d9da' : undefined,
        // useSeriesColors: false
      },
    },
  };
};
