import React from 'react';
import { TransformerRegistryItem, TransformerUIProps } from '@grafana/data';
import { prepareTimeSeriesTransformer, PrepareTimeSeriesOptions } from './prepareTimeSeries';
import { Select } from '@grafana/ui';

const wideInfo = {
  label: 'Wide time series',
  value: 'wide',
  description: 'Creates a single frame joined by time',
  info: (
    <ul>
      <li>Single frame</li>
      <li>1st field is shared time field</li>
      <li>Time in ascending order</li>
      <li>Multiple value fields of any type</li>
    </ul>
  ),
};

const manyInfo = {
  label: 'Multi-frame time series',
  value: 'many',
  description: 'Creates a new frame for each time/number pair',
  info: (
    <ul>
      <li>Multiple frames</li>
      <li>Each frame has two fields: time, value</li>
      <li>Time in ascending order</li>
      <li>String values are represented as labels</li>
      <li>All values are numeric</li>
    </ul>
  ),
};

const longInfo = {
  label: 'Long time series',
  value: 'long',
  description: 'Convert each frame to long format',
  info: (
    <ul>
      <li>Single frame</li>
      <li>1st field is time field</li>
      <li>Time in ascending order, but may have duplicates</li>
      <li>String values are represented as separate fields rather than as labels</li>
      <li>Multiple value fields may exist</li>
    </ul>
  ),
};

const formats = [wideInfo, manyInfo, longInfo];

export function PrepareTimeSeriesEditor(props: TransformerUIProps<PrepareTimeSeriesOptions>): React.ReactElement {
  const { options, onChange } = props;

  const onSelectFormat = (value: any) => {
    onChange({
      ...options,
      format: value.value!,
    });
  };

  return (
    <>
      <div>
        <div style={{ margin: '5px' }}>
          <div>Format</div>
          <Select
            menuShouldPortal
            width={35}
            options={formats}
            value={formats.find((v) => v.value === options.format) || formats[0]}
            onChange={onSelectFormat}
          />
        </div>
      </div>
      <div>
        <div>
          <div>Info</div>
          <div>{(formats.find((v) => v.value === options.format) || formats[0]).info}</div>
        </div>
      </div>
    </>
  );
}

export const prepareTimeseriesTransformerRegistryItem: TransformerRegistryItem<PrepareTimeSeriesOptions> = {
  id: prepareTimeSeriesTransformer.id,
  editor: PrepareTimeSeriesEditor,
  transformation: prepareTimeSeriesTransformer,
  name: prepareTimeSeriesTransformer.name,
  description: prepareTimeSeriesTransformer.description,
};
