import { LinkDrawer, DrawerData } from 'app/features/linkDrawer/LinkDrawer';
import React, { useRef, useState } from 'react';
import { TimeRange } from '@grafana/data';

interface Props {
  onClick: boolean;
  dashboardUrl: string;
  width: number;
  height: number;
  imageUrl: string;
  dashboardName: string;
  timeRange: TimeRange;
  drawerTimeRange: boolean;
}

export const NormalImage: React.FC<Props> = ({
  imageUrl,
  width,
  height,
  onClick,
  dashboardUrl,
  dashboardName,
  timeRange,
  drawerTimeRange,
}) => {
  const [infoData, setinfoData] = useState<DrawerData>({ location: '', url: '' });
  const [showDrawer, setShowDrawer] = React.useState(false);
  const onCloseDrawer = () => {
    setShowDrawer(false);
  };

  const drawerhandle = (open: boolean, url: string) => {
    if (open) {
      if (dashboardName.length !== 0) {
        setinfoData({ location: dashboardName, url: url });
      } else {
        setinfoData({ location: url, url: url });
      }
      setShowDrawer(!showDrawer);
    }
  };
  return (
    <div style={{ position: 'relative', width, height }}>
      {!onClick && <img src={imageUrl} height={height} width={width} alt="image" />}
      {onClick && (
        <a onClick={() => drawerhandle(onClick, dashboardUrl)} rel="noreferrer">
          <img src={imageUrl} height={height} width={width} alt="image" />
        </a>
      )}
      {showDrawer && (
        <LinkDrawer infoData={infoData} onClose={onCloseDrawer} timeRange={drawerTimeRange ? timeRange : undefined} />
      )}
    </div>
  );
};

export const AspectRatioImage: React.FC<Props> = ({
  imageUrl,
  width,
  height,
  onClick,
  dashboardUrl,
  dashboardName,
  timeRange,
  drawerTimeRange,
}) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const [infoData, setinfoData] = useState<DrawerData>({ location: '', url: '' });
  const [showDrawer, setShowDrawer] = React.useState(false);

  const onCloseDrawer = () => {
    setShowDrawer(false);
  };

  const drawerhandle = (open: boolean, url: string) => {
    if (open) {
      if (dashboardName && dashboardName.length !== 0) {
        setinfoData({ location: dashboardName, url: url });
      } else {
        setinfoData({ location: url, url: url });
      }
      setShowDrawer(!showDrawer);
    }
  };

  return (
    <div
      style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width, height }}
    >
      {!onClick && <img style={{ maxWidth: '100%', maxHeight: `${height}px` }} src={imageUrl} ref={imageRef} />}
      {onClick && (
        <a onClick={() => drawerhandle(onClick, dashboardUrl)} rel="noreferrer">
          <img style={{ maxWidth: '100%', maxHeight: `${height}px` }} src={imageUrl} ref={imageRef} />
        </a>
      )}
      {showDrawer && (
        <LinkDrawer infoData={infoData} onClose={onCloseDrawer} timeRange={drawerTimeRange ? timeRange : undefined} />
      )}
    </div>
  );
};
