import { SingleStatBaseOptions } from '@grafana/ui';
import { ReducerID, standardEditorsRegistry, PanelOptionsEditorBuilder } from '@grafana/data';

export interface DialOptions extends SingleStatBaseOptions {
  reduceOptions: any;
  nrOfLevels: number;
  gaugeType: string;
  gaugeType2: string;
  showNum: boolean;
  showName: boolean;
  showNum2: boolean;
  showName2: boolean;
  gaugeThickness: number;
  Rotation: number;
  GaugeArc: number;
  // groupBy:string;
}

export function addStandardDataReduceOptions(
  builder: PanelOptionsEditorBuilder<SingleStatBaseOptions>,
  includeOrientation = true
) {
  builder.addCustomEditor({
    id: 'reduceOptions.calcs',
    path: 'reduceOptions.calcs',
    name: 'Value',
    description: 'Choose a reducer function / calculation',
    editor: standardEditorsRegistry.get('stats-picker').editor as any,
    defaultValue: [ReducerID.mean],
  });
}
