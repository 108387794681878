import { AppEvents } from '@grafana/data';
import axios from 'axios';
import appEvents from 'app/core/app_events';
import { config, getDataSourceSrv } from '@grafana/runtime';
import { API_URL_SERVER, Model, apiTypes } from '../constants';
import { ModelOptions, ModelOptionsList } from '../components/Train/constants';
import {
  getNormalPayloadFromEnsembleSettings,
  updateMetricsFromEnsembleToNormal,
} from '../components/Train/utils/payload';
import { generateUniqueString } from '../utils/utilFunction';

interface ConnectAPI {
  payload: string;
  cacheKey?: string;
  job?: string;
}

/**
 * Makes an API request with the provided payload and returns the response.
 * @param {any} payload - The payload for the API request.
 * @param {string} [customUrl] - The custom URL to override the default API URL.
 * @returns {Promise<{ error: any, data: any }>} - A promise that resolves with the response containing the error and data.
 */
export const connectApi = async (payload: any, activity: string, cacheKey?: string, job?: string) => {
  try {
    const reqData: ConnectAPI = {
      payload: JSON.stringify(payload),
    };
    if (cacheKey) {
      reqData['cacheKey'] = cacheKey;
    }
    if (job) {
      reqData['job'] = job.toString();
    }
    // const response = await fetch(`${API_URL_SERVER}api/connect`, {
    //   headers: {
    //     accept: 'application/json',
    //     'content-type': 'application/json',
    //   },
    //   body: JSON.stringify(reqData),
    //   method: 'POST',
    // });
    const { data } = await axios.post(`${API_URL_SERVER}api/connect`, reqData);
    // const resJson = await response.json();
    return {
      error: null,
      data: data ? JSON.parse(data) : null,
    };
  } catch (err) {
    appEvents.emit(AppEvents.alertError, [`${err.message}`]);
    return { error: err, data: null };
  }
};

/**
 * Deletes a model with the provided information.
 * @param {string} type - The type of the model ('imported' or 'custom').
 * @param {string} modelId - The ID of the model to delete.
 * @param {string} title - The title of the model to delete.
 * @param {string} predictionAlgo - The prediction algorithm of the model to delete.
 * @param {() => void} fetchModel - A function to fetch and update the list of models after deletion.
 * @returns {Promise<void>} - A promise that resolves after the model is successfully deleted.
 */
export const deleteModelApi = async (type: string, modelId: string, title: string, dbtype: string) => {
  // const modelDeletePayload = {
  //   output: '..impexp-delete-exception-modal.is_open...impexp-delete-exception-modal-content.children..',
  //   outputs: [
  //     { id: 'impexp-delete-exception-modal', property: 'is_open' },
  //     { id: 'impexp-delete-exception-modal-content', property: 'children' },
  //   ],
  //   inputs: [
  //     { id: 'impexp-delete-model-btn', property: 'n_clicks', value: 1 },
  //     { id: 'impexp-delete-exception-modal-close', property: 'n_clicks', value: 0 },
  //   ],
  //   changedPropIds: ['impexp-delete-model-btn.n_clicks'],
  //   state: [
  //     {
  //       id: 'impexp-delete-model',
  //       property: 'value',
  //       value:
  //         type === 'imported'
  //           ? title
  //           : type === 'anomaly'
  //           ? `${title}_anomaly___${predictionAlgo}`
  //           : `${title}___${predictionAlgo}`,
  //     },
  //   ],
  // };
  const formData = new FormData();
  formData.append('modelid', modelId);
  formData.append('userid', config.bootData.user.id);
  formData.append('orgid', config.bootData.user.orgId);
  formData.append('host', config.appUrl);
  formData.append('modelname', title);
  formData.append('activity', apiTypes.deleteModelFromMiddleware);
  // formData.append('payload', JSON.stringify(modelDeletePayload));
  formData.append('dbtype', dbtype);
  let response = await axios.post(`${API_URL_SERVER}api/deleteModel`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  if (response.status === 200) {
    appEvents.emit(AppEvents.alertSuccess, [`Model Deleted Successfully.`]);
    return true;
  } else {
    appEvents.emit(AppEvents.alertError, [`Something went wrong.`]);
  }
  return false;
};

/**
 * Imports a model with the provided ZIP string and file name.
 * @param {string} zipString - The ZIP string containing the model data.
 * @param {string} fileName - The name of the model file.
 * @returns {Promise<boolean>} - A promise that resolves with a boolean indicating whether the model was imported successfully.
 */
export const importModelApi = async (zipString: string, fileName: string) => {
  const payload = {
    output: '..impexp-upload-model-exception-modal.is_open...impexp-upload-model-exception-modal-content.children..',
    outputs: [
      { id: 'impexp-upload-model-exception-modal', property: 'is_open' },
      { id: 'impexp-upload-model-exception-modal-content', property: 'children' },
    ],
    inputs: [
      { id: 'impexp-upload-model-btn', property: 'n_clicks', value: 1 },
      { id: 'impexp-upload-model-exception-modal-close', property: 'n_clicks', value: 0 },
      { id: 'impexp-upload-model', property: 'filename', value: fileName },
      {
        id: 'impexp-upload-model',
        property: 'contents',
        value: zipString,
      },
    ],
    changedPropIds: ['impexp-upload-model-btn.n_clicks'],
  };
  const resp = await connectApi(payload, apiTypes.importModelStart);
  if (!resp.error && resp.data.cacheKey && resp.data.job) {
    return await new Promise((resolve, reject) => {
      const timerIdModelImport = setInterval(async () => {
        try {
          const resp1 = await connectApi(payload, apiTypes.checkImportModelStatus, resp.data.cacheKey, resp.data.job);
          if (
            resp1.data.response &&
            resp1.data.response['impexp-upload-model-exception-modal-content'].children.includes(
              'Model uploaded and working. Model last trained on'
            )
          ) {
            clearInterval(timerIdModelImport);
            resolve(true);
          } else if (
            resp1.data.response &&
            resp1.data.response['impexp-upload-model-exception-modal-content'].children ===
              'A Model with the same name already exists.'
          ) {
            clearInterval(timerIdModelImport);
            resolve(false);
          }
        } catch (err) {
          clearInterval(timerIdModelImport);
          reject(false);
        }
      }, 1000);
    });
  }
};

export const fetchForecastResultFromClickhouse = async (
  modelName: string,
  dataSourceId: number,
  databaseName: string
) => {
  const dataSourceSrv = await getDataSourceSrv();
  const ds = dataSourceSrv.getList();
  const clickHouseDs = ds.find(
    (ele: any) => ele.type === 'clickhouse-datasource' && ele.name === config.carbonMLDatasource
  );
  if (!clickHouseDs) {
    appEvents.emit(AppEvents.alertError, [
      `Please add a clickhouse datasource with the name ${config.carbonMLDatasource} before start training any model.`,
    ]);
  } else if (!clickHouseDs.jsonData.hasEditorPermission) {
    appEvents.emit(AppEvents.alertError, [
      `Please give default database inside clickhouse datasource with the name ${config.carbonMLDatasource} editor permission before training any model.`,
    ]);
  }

  const body = {
    queries: [
      {
        format: 1,
        hide: false,
        meta: {
          builderOptions: {
            fields: [],
            limit: 100,
            mode: 'list',
          },
        },
        queryType: 'sql',
        rawSql: `SELECT * FROM ${databaseName}.${modelName};`,
        refId: 'A',
        datasource: 'ClickHouse',
        datasourceId: dataSourceId,
        intervalMs: 900000,
        maxDataPoints: 670,
      },
    ],
  };
  try {
    const { data } = await axios.post('/api/ds/query', body);
    return data;
  } catch (err) {
    if (err.response.data.message === 'error querying the database: EOF') {
      return;
    }
  }
};

export const deleteTableFromClickhouse = async (modelName: string, dataSourceId: number, databaseName: string) => {
  const dataSourceSrv = await getDataSourceSrv();
  const ds = dataSourceSrv.getList();
  const clickHouseDs = ds.find(
    (ele: any) => ele.type === 'clickhouse-datasource' && ele.name === config.carbonMLDatasource
  );
  if (!clickHouseDs) {
    appEvents.emit(AppEvents.alertError, [
      `Please add a clickhouse datasource with the name ${config.carbonMLDatasource} before start training any model.`,
    ]);
  } else if (!clickHouseDs.jsonData.hasEditorPermission) {
    appEvents.emit(AppEvents.alertError, [
      `Please give default database inside clickhouse datasource with the name ${config.carbonMLDatasource} editor permission before training any model.`,
    ]);
  }
  const body = {
    queries: [
      {
        format: 1,
        hide: false,
        meta: {
          builderOptions: {
            fields: [],
            limit: 100,
            mode: 'list',
          },
        },
        queryType: 'sql',
        rawSql: `DROP TABLE IF EXISTS ${databaseName}.${modelName}`,
        refId: 'A',
        datasource: 'ClickHouse',
        datasourceId: dataSourceId,
        intervalMs: 900000,
        maxDataPoints: 670,
      },
    ],
  };
  try {
    await axios.post('/api/ds/query/withoutResult', body);
  } catch (err) {
    if (err.response.data.message === 'error querying the database: EOF') {
      return;
    }
  }
};

const saveTempMerlionEnsembleModel = async (modelName: string) => {
  const payload = {
    output:
      '..impexp-ensemble-transfer-exception-modal.is_open...impexp-ensemble-transfer-exception-modal-content.children..',
    outputs: [
      {
        id: 'impexp-ensemble-transfer-exception-modal',
        property: 'is_open',
      },
      {
        id: 'impexp-ensemble-transfer-exception-modal-content',
        property: 'children',
      },
    ],
    inputs: [
      {
        id: 'impexp-ensemble-transfer-model-btn',
        property: 'n_clicks',
        value: 1,
      },
      {
        id: 'impexp-ensemble-delete-model-btn',
        property: 'n_clicks',
        value: 0,
      },
      {
        id: 'impexp-ensemble-transfer-exception-modal-close',
        property: 'n_clicks',
        value: 0,
      },
    ],
    changedPropIds: ['impexp-ensemble-transfer-model-btn.n_clicks'],
    state: [
      {
        id: 'impexp-ensemble-selected-model',
        property: 'value',
        value: modelName,
      },
    ],
  };
  const resp = await connectApi(payload, apiTypes.saveTempMerlionEnsembleModel);
  if (!resp.error && resp.data.cacheKey && resp.data.job) {
    return await new Promise((resolve, reject) => {
      const timerIdModelImport = setInterval(async () => {
        try {
          const resp1 = await connectApi(
            payload,
            apiTypes.saveTempMerlionEnsembleModel,
            resp.data.cacheKey,
            resp.data.job
          );
          if (
            resp1.data.response &&
            resp1.data.response['impexp-ensemble-transfer-exception-modal-content'].children.includes(
              'transferred successfully.'
            )
          ) {
            clearInterval(timerIdModelImport);
            resolve(true);
          }
        } catch (err) {
          console.log(err);
          clearInterval(timerIdModelImport);
          reject(false);
        }
      }, 1000);
    });
  }
};

export const deleteNormalModelFromMerlion = async (modelName: string) => {
  const payload = {
    output: '..impexp-delete-exception-modal.is_open...impexp-delete-exception-modal-content.children..',
    outputs: [
      { id: 'impexp-delete-exception-modal', property: 'is_open' },
      { id: 'impexp-delete-exception-modal-content', property: 'children' },
    ],
    inputs: [
      { id: 'impexp-delete-model-btn', property: 'n_clicks', value: 1 },
      { id: 'impexp-delete-exception-modal-close', property: 'n_clicks', value: 0 },
    ],
    changedPropIds: ['impexp-delete-model-btn.n_clicks'],
    state: [{ id: 'impexp-delete-model', property: 'value', value: modelName }],
  };
  const resp = await connectApi(payload, apiTypes.deleteNormalModelFromMerlion);
  if (!resp.error && resp.data.cacheKey && resp.data.job) {
    return await new Promise((resolve, reject) => {
      const timerIdModelImport = setInterval(async () => {
        try {
          const resp1 = await connectApi(
            payload,
            apiTypes.deleteNormalModelFromMerlion,
            resp.data.cacheKey,
            resp.data.job
          );
          if (
            resp1.data.response &&
            resp1.data.response['impexp-delete-exception-modal-content'].children.includes('Model removed.')
          ) {
            clearInterval(timerIdModelImport);
            resolve(true);
          }
        } catch (err) {
          console.log(err);
          clearInterval(timerIdModelImport);
          reject(false);
        }
      }, 1000);
    });
  }
};

export const deleteTempEnsembledModel = async (modelName: string) => {
  const payload = {
    output:
      '..impexp-ensemble-transfer-exception-modal.is_open...impexp-ensemble-transfer-exception-modal-content.children..',
    outputs: [
      { id: 'impexp-ensemble-transfer-exception-modal', property: 'is_open' },
      { id: 'impexp-ensemble-transfer-exception-modal-content', property: 'children' },
    ],
    inputs: [
      { id: 'impexp-ensemble-transfer-model-btn', property: 'n_clicks', value: 0 },
      { id: 'impexp-ensemble-delete-model-btn', property: 'n_clicks', value: 1 },
      { id: 'impexp-ensemble-transfer-exception-modal-close', property: 'n_clicks', value: 0 },
    ],
    changedPropIds: ['impexp-ensemble-delete-model-btn.n_clicks'],
    state: [{ id: 'impexp-ensemble-selected-model', property: 'value', value: modelName }],
  };
  const resp = await connectApi(payload, apiTypes.deleteTempEnsembledModel);
  if (!resp.error && resp.data.cacheKey && resp.data.job) {
    return await new Promise((resolve, reject) => {
      const timerIdModelImport = setInterval(async () => {
        try {
          const resp1 = await connectApi(payload, apiTypes.deleteTempEnsembledModel, resp.data.cacheKey, resp.data.job);
          if (
            resp1.data.response &&
            resp1.data.response['impexp-ensemble-transfer-exception-modal-content'].children.includes(
              'removed successfully.'
            )
          ) {
            clearInterval(timerIdModelImport);
            resolve(true);
          }
        } catch (err) {
          console.log(err);
          clearInterval(timerIdModelImport);
          reject(false);
        }
      }, 1000);
    });
  }
};

export const exportSelectedModel = async (
  oldModelId: string,
  newModelName: string,
  selectedAlgo: string,
  model: Model,
  modelOptions: ModelOptions,
  modelOptionsList: ModelOptionsList,
  modelToSave: string
) => {
  try {
    const requestBody = {
      userid: config.bootData.user.id,
      orgid: config.bootData.user.orgId,
      host: config.appUrl,
      activity: apiTypes.exportEnsembledModel,
      oldModelId,
      newModelName: newModelName,
      newModelId: generateUniqueString(),
      selectedAlgo: modelToSave,
      newPayload: JSON.stringify(
        await getNormalPayloadFromEnsembleSettings(modelOptions, modelOptionsList, modelToSave)
      ),
      newMetrics: JSON.stringify(updateMetricsFromEnsembleToNormal(JSON.parse(model.info.metrics), modelToSave)),
    };

    // Handle success case
    // if (response.status === 200) {
    if (!(await saveTempMerlionEnsembleModel(modelOptions.name + '___' + modelToSave))) {
      appEvents.emit(AppEvents.alertError, ['Failed to transfer model on Merlion. Please try again.']);
    }
    appEvents.emit(AppEvents.alertSuccess, ['Model exported successfully']);
    model.info.isExported = 'true';
    // Delete all other ensembled models
    const ensembledModels = JSON.parse(model.info.payload).state.find((ele: any) => ele.id === 'forecaster-checklist')
      .value;
    ensembledModels.push('Ensemble');
    ensembledModels.push('Selector');
    for (const modelName of ensembledModels) {
      if (modelName !== modelToSave) {
        if (!(await deleteTempEnsembledModel(modelOptions.name + '___' + modelName))) {
          appEvents.emit(AppEvents.alertError, ['Failed to delete ensembled model ' + modelName]);
        }
      }
    }
    await axios.post(`${API_URL_SERVER}api/export/ensembled/model`, requestBody);

    // getLocationSrv().update({
    //   path: `/forecasting/${oldModelId}/edit`,
    // });
    // }
  } catch (err) {
    console.log(err);
    // Provide more informative error messages
    if (err.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      appEvents.emit(AppEvents.alertError, ['Server responded with an error:', err.response.data]);
    } else if (err.request) {
      // The request was made but no response was received
      appEvents.emit(AppEvents.alertError, ['No response received:', err.request]);
    } else {
      // Something happened in setting up the request that triggered an Error
      appEvents.emit(AppEvents.alertError, ['Error', err.message]);
    }
  }
};

export async function ExportedModelExists(filename: string) {
  const formData = new FormData();
  formData.append('userid', config.bootData.user.id);
  formData.append('orgid', config.bootData.user.orgId);
  formData.append('host', config.appUrl);
  formData.append('filename', filename);
  formData.append('activity', apiTypes.deleteModelFromMiddleware);
  let { data } = await axios.post(`${API_URL_SERVER}api/export/model/exists`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data.sharedData;
}
