import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const Dashboard: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 77.3 75.3"
      viewBox="0 0 77.3 75.3"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M12.5,35.1h29c2.9,0,5.3-2.4,5.3-5.3V11.3c0-2.9-2.4-5.3-5.3-5.3h-29c-2.9,0-5.3,2.4-5.3,5.3v18.5
        C7.3,32.7,9.6,35.1,12.5,35.1z M65.3,6h-7.9c-2.9,0-5.3,2.4-5.3,5.3v18.5c0,2.9,2.4,5.3,5.3,5.3h7.9c2.9,0,5.3-2.4,5.3-5.3V11.3
        C70.6,8.4,68.2,6,65.3,6z M20.4,40.3h-7.9c-2.9,0-5.3,2.4-5.3,5.3v18.5c0,2.9,2.4,5.3,5.3,5.3h7.9c2.9,0,5.3-2.4,5.3-5.3V45.6
        C25.7,42.7,23.4,40.3,20.4,40.3z M65.3,40.3h-29c-2.9,0-5.3,2.4-5.3,5.3v18.5c0,2.9,2.4,5.3,5.3,5.3h29c2.9,0,5.3-2.4,5.3-5.3V45.6
        C70.6,42.7,68.2,40.3,65.3,40.3z"
      />
    </svg>
  );
};
