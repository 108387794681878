import React, { useState, useEffect } from 'react';
import { Field, Select, useTheme } from '@grafana/ui';
import { QueryField } from '../types/interface';
import { prepareGraphableFields } from '../utils/prepareFields';
import { SelectableValue } from '@grafana/data';

interface LabelValue {
  label: string;
  value: string;
}
interface Props {
  query: QueryField;
  bigData: any;
  onChange: (query: any) => void;
}
export const QuerySelection = (props: Props) => {
  const theme = useTheme();
  const { bigData } = props;
  const [queryOptionsString, setQueryOptionsString] = useState<LabelValue[]>([]);
  const [queryOptionsNum, setQueryOptionsNum] = useState<LabelValue[]>([]);

  const { data } = bigData;
  const frames = prepareGraphableFields(data, theme);

  /**
   * creating query options for geohash field.
   * choosing string field for Name and Geohash and number field for value.
   */
  useEffect(() => {
    if (frames && frames.length > 0) {
      for (let index = 0; index < frames[0].fields.length; index++) {
        const element = frames[0].fields[index];
        if (element.type === 'string') {
          setQueryOptionsString((prevState: LabelValue[]) => [
            ...prevState,
            {
              label: element.name,
              value: element.name,
            },
          ]);
        }
        if (element.type === 'number') {
          setQueryOptionsNum((prevState: LabelValue[]) => [
            ...prevState,
            {
              label: element.name,
              value: element.name,
            },
          ]);
        }
      }
    }
    return () => {
      setQueryOptionsNum([]);
      setQueryOptionsString([]);
    };
  }, [bigData]);

  const onLocation = (e: SelectableValue<string>) => {
    props.onChange({
      ...props.query,
      location: e.value,
    });
  };
  const onGeohash = (e: SelectableValue<string>) => {
    props.onChange({
      ...props.query,
      geohash: e.value,
    });
  };
  const onValue = (e: SelectableValue<string>) => {
    props.onChange({
      ...props.query,
      value: e.value,
    });
  };

  return (
    <>
      <div>
        <Field label={'Name'}>
          <Select
            noOptionsMessage="no strings fields found"
            options={queryOptionsString}
            onChange={(e: SelectableValue<string>) => onLocation(e)}
            value={queryOptionsString.find((item: any) => item.value === props.query.location)}
          />
        </Field>
        <Field label={'Geohash'}>
          <Select
            noOptionsMessage="no strings fields found"
            options={queryOptionsString}
            onChange={(e: SelectableValue<string>) => onGeohash(e)}
            value={queryOptionsString.find((item: any) => item.value === props.query.geohash)}
          />
        </Field>
        <Field label={'Metric'}>
          <Select
            noOptionsMessage="no geohash numeric fields found"
            options={queryOptionsNum}
            onChange={(e: SelectableValue<string>) => onValue(e)}
            value={queryOptionsNum.find((item: any) => item.value === props.query.value)}
          />
        </Field>
      </div>
    </>
  );
};
