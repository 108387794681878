import React, { useEffect, useRef, useState } from 'react';
import { PanelProps, getFieldDisplayValues } from '@grafana/data';
import { SimpleOptions } from './Types/SimpleOptions';
import { css, cx } from 'emotion';
import { cloneDeep } from 'lodash';
import { useTheme, useStyles } from '@grafana/ui';
import { Sensor } from './Sensor';
import SensorType from './Types/Sensor';

interface Props extends PanelProps<SimpleOptions> {}

export const ImageItPanel: React.FC<Props> = ({
  options,
  data,
  width,
  height,
  onOptionsChange,
  replaceVariables,
  fieldConfig,
  timeZone,
  timeRange,
}) => {
  const styles = useStyles(getStyles);
  const theme = useTheme();
  const imageRef = useRef<HTMLImageElement>(null);

  const [imageDimensions, setImageDimensions] = useState({ height: 0, width: 0 });

  useEffect(() => {
    setImageDimensions({
      height: imageRef.current?.offsetHeight ?? 0,
      width: imageRef.current?.offsetWidth ?? 0,
    });
  }, [width, height]);

  const NewBigData = getFieldDisplayValues({
    fieldConfig,
    // @ts-ignore
    reduceOptions: options.reduceOptions,
    replaceVariables,
    theme: theme,
    data: data.series,
    timeZone,
  });

  const onImageLoad = ({ target: image }: any) => {
    setImageDimensions({
      height: image.offsetHeight,
      width: image.offsetWidth,
    });
  };

  const onSensorPositionChange = (position: any, index: number) => {
    const newOptions = cloneDeep(options);
    newOptions.sensorData.sensors[index].position = position;
    onOptionsChange(newOptions);
  };

  const getData = (sensorName: string | undefined): any => {
    let toReturn: any = {};
    NewBigData.map((item: any) => {
      if (item.display.title === sensorName) {
        toReturn = item.display;
      }
    });
    return toReturn;
  };
  let URLTOSHOW = '';
  if (options.toShowImg && options.toShowImg !== '') {
    URLTOSHOW = `/api/image/${options.toShowImg}`;
  }
  if (options.imageUrl !== undefined && options.imageUrl !== '') {
    URLTOSHOW = options.imageUrl;
  }
  if (URLTOSHOW === '') {
    return (
      <div className="panel-empty">
        <p>No Image</p>
      </div>
    );
  }
  return (
    <div className={styles.wrapper}>
      <div
        id="imageItBgImage"
        className={cx(
          styles.imageWrapper,
          css`
            max-height: ${height}px;
            max-width: '${width}px';
          `
        )}
      >
        {options.sensorData.sensors &&
          options.sensorData.sensors.map((sensor: SensorType, index: number) => {
            const bgColor = NewBigData[index] !== undefined ? NewBigData[index]?.display?.color : '#000';
            return (
              <Sensor
                draggable={options.lockSensors}
                sensor={sensor}
                dashVisit={options.dashVisit}
                index={index}
                imageDimensions={imageDimensions}
                onPositionChange={onSensorPositionChange}
                data={getData(sensor.value)}
                key={index}
                min={options.minimum}
                max={options.maximum}
                defaults={fieldConfig.defaults}
                backgroundColor={bgColor as any}
                fontStyle={options.fontStyle}
                theme={theme}
                timeRange={timeRange}
                drawerLinkTime={options.sensorData.drawerLinkTime}
              />
            );
          })}
        <img
          className={cx(
            styles.bgImage,
            css`
              max-height: ${height}px;
            `
          )}
          src={URLTOSHOW}
          ref={imageRef}
          onLoad={onImageLoad}
        />
      </div>
    </div>
  );
};

const getStyles = () => {
  return {
    wrapper: css`
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    imageWrapper: css`
      position: relative;
      display: inline-block;
      max-width: 100%;
    `,
    bgImage: css`
      max-width: 100%;
    `,
    lockIcon: css`
      position: absolute;
      top: 25px;
      right: 25px;
      z-index: 1;
    `,
    textBox: css`
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
    `,
  };
};
