import React from 'react';
import { HeatmapStyle } from './HeatmapStyle';
import { AddMarker } from './CustomMarkers/AddMarker';
import { Field } from '@grafana/ui';
import { OSMapLayerState, OSMapPanelOptions } from '../../types/types';
import { DataFrame, InterpolateFunction } from '@grafana/data';
import Map from 'ol/Map';

/**
 * Interface representing custom props for a component.
 *
 * @interface PropsCustom
 * @property {OSMapLayerState<any>} layer - The OSMapLayerState for the component.
 * @property {Map | undefined} map - The Map object, if available.
 * @property {InterpolateFunction | undefined} replaceVariables - The InterpolateFunction for variable replacement.
 * @property {OSMapPanelOptions | undefined} options - The OSMapPanelOptions for the component.
 * @property {DataFrame[]} data - An array of DataFrame objects.
 */
export interface PropsCustom {
  layer: OSMapLayerState<any>;
  map: Map | undefined;
  replaceVariables: InterpolateFunction | undefined;
  options: OSMapPanelOptions | undefined;
  data: DataFrame[];
}

/**
 * CustomHeatmap is a component for configuring custom heatmaps within the map panel.
 * It allows users to define custom heatmap styles and add custom markers with variable replacement.
 *
 * @param {PropsCustom} props - The props required for configuring custom heatmaps.
 * @returns {JSX.Element} A JSX element representing the CustomHeatmap component.
 */
export function CustomHeatmap({ layer, map, replaceVariables, options, data }: PropsCustom) {
  return (
    <>
      <HeatmapStyle layer={layer} />
      <Field label="Custom Markers">
        <AddMarker map={map} layer={layer} replaceVariables={replaceVariables} options={options} data={data} />
      </Field>
    </>
  );
}
