import React, { ChangeEvent } from 'react';
import {
  DataTransformerID,
  FieldNamePickerConfigSettings,
  FieldType,
  StandardEditorsRegistryItem,
  standardTransformers,
  TransformerRegistryItem,
  TransformerUIProps,
} from '@grafana/data';

import { ConvertFieldTypeTransformerOptions } from '@grafana/data/src/transformations/transformers/convertFieldType';
import { Button, Input, Select } from '@grafana/ui';
import { FieldNamePicker } from '../../../../../packages/grafana-ui/src/components/MatchersUI/FieldNamePicker';
const fieldNamePickerSettings: StandardEditorsRegistryItem<string, FieldNamePickerConfigSettings> = {
  settings: { width: 18 },
} as any;

export const ConvertFieldTypeTransformerEditor: React.FC<TransformerUIProps<ConvertFieldTypeTransformerOptions>> = ({
  input,
  options,
  onChange,
}) => {
  const availableTypes: any = [
    { value: FieldType.number, label: 'Numeric' },
    { value: FieldType.string, label: 'String' },
    { value: FieldType.time, label: 'Time' },
    { value: FieldType.boolean, label: 'Boolean' },
  ];
  const defaultOptions = { targetField: undefined, destinationType: undefined, dateFormat: undefined };

  function onAdd() {
    onChange({
      ...options,
      conversions: [
        // @ts-ignore
        ...options.conversions,
        defaultOptions,
      ],
    });
  }

  function onRemove(i: number) {
    options.conversions.splice(i, 1);
    onChange({
      ...options,
      conversions: options.conversions,
    });
  }

  function onFieldSelection(index: number, value: string | undefined) {
    options.conversions[index] = { ...options.conversions[index], targetField: value ?? '' };
    onChange({
      ...options,
      conversions: options.conversions,
    });
  }

  function onTypeSelection(index: number, evt: any) {
    options.conversions[index] = { ...options.conversions[index], destinationType: evt.value };
    onChange({
      ...options,
      conversions: options.conversions,
    });
  }

  function onInputFormatChange(index: number, evt: ChangeEvent<HTMLInputElement>) {
    options.conversions[index] = { ...options.conversions[index], dateFormat: evt.currentTarget.value };
    onChange({
      ...options,
      conversions: options.conversions,
    });
  }

  return (
    <>
      {options.conversions.map((ele: any, i: number) => {
        return (
          <div style={{ margin: '5px' }} key={`${ele.targetField}-${i}`}>
            <div className="gf-form">
              <div className="gf-form-label width-8">Field</div>
              <div>
                <FieldNamePicker
                  context={{ data: input }}
                  value={ele.targetField ?? ''}
                  onChange={(e) => onFieldSelection(i, e)}
                  item={fieldNamePickerSettings}
                />
              </div>
            </div>
            <div className="gf-form">
              <div className="gf-form-label width-8">Choose Type</div>
              <Select
                menuShouldPortal
                options={availableTypes}
                value={ele.destinationType}
                placeholder={'Type'}
                onChange={(evt) => onTypeSelection(i, evt)}
                width={18}
              />
            </div>
            {ele.destinationType === FieldType.time && (
              <div className="gf-form">
                <div className="gf-form-label width-8">Date Format</div>
                <Input
                  value={ele.dateFormat}
                  placeholder={'e.g. YYYY-MM-DD'}
                  onChange={(evt: any) => onInputFormatChange(i, evt)}
                  width={24}
                />
              </div>
            )}
            <Button
              style={{ margin: '5px' }}
              size="md"
              variant="destructive"
              onClick={() => onRemove(i)}
              aria-label={'Remove'}
            >
              {'Remove'}
            </Button>
          </div>
        );
      })}
      <Button style={{ margin: '5px' }} size="md" onClick={onAdd} variant="secondary" aria-label={'Add'}>
        {'Add Field Type'}
      </Button>
    </>
  );
};

export const convertFieldTypeTransformRegistryItem: TransformerRegistryItem<ConvertFieldTypeTransformerOptions> = {
  id: DataTransformerID.convertFieldType,
  editor: ConvertFieldTypeTransformerEditor,
  transformation: standardTransformers.convertFieldTypeTransformer,
  name: 'Convert Field Type',
  description: 'Convert a field to a specific field type',
};
