import React, { FC } from 'react';
import config from 'app/core/config';

export interface FooterLink {
  text: string;
  icon?: string;
  url?: string;
  target?: string;
  img?: string;
}

export let getFooterLinks = (): FooterLink[] => {
  return [
    {
      text: 'Documentation',
      icon: 'license',
      url: 'https://docs.analytics.arnowa.com/docs/documentation',
      target: '_blank',
    },
    {
      text: 'Support',
      icon: 'info-circle',
      url: 'https://arnowa.com/contact/',
      target: '_blank',
    },
  ];
};

export let getVersionLinks = (): FooterLink[] => {
  const { buildInfo } = config;
  const links: FooterLink[] = [];
  // const stateInfo = licenseInfo.stateInfo ? ` (${licenseInfo.stateInfo})` : '';
  // links.push({ text: `${buildInfo.edition}${stateInfo}`, url: licenseInfo.licenseUrl });

  if (buildInfo.hideVersion) {
    return links;
  }

  links.push({ text: `v${buildInfo.version} (${buildInfo.commit})` });

  // if (buildInfo.hasUpdate) {
  //   links.push({
  //     text: `New version available!`,
  //     icon: 'download',
  //     url: 'http://support.arnowa.com/',
  //     target: '_blank',
  //   });
  // }

  return links;
};

export function setFooterLinksFn(fn: typeof getFooterLinks) {
  getFooterLinks = fn;
}

export function setVersionLinkFn(fn: typeof getFooterLinks) {
  getVersionLinks = fn;
}

export const Footer: FC = React.memo(() => {
  return (
    <footer className="footer">
      <div className="text-center">
        <img alt={`Powered by ${config.appName}`} src="https://carbon.b-cdn.net/Powered-by.png" />
      </div>
    </footer>
  );
});

Footer.displayName = 'Footer';
