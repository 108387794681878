import { FieldColorModeId, PanelData, Threshold, getFieldColorModeForField } from '@grafana/data';
import Map from 'ol/Map';
import { Point } from 'ol/geom';
import { Heatmap as HeatmapLayer } from 'ol/layer';
import OLVectorSource from 'ol/source/Vector';
import { OSMapLayerOptions, OSMapLayerRegistryItem } from '../../LayerEditor/types';
import { colorGradient, colorGradientArray } from '../../utils/heatcolor';
import {
  addFeatures,
  addWeights,
  createMarkerGroups,
  getGeohashValues,
  getSortedUniqueArray,
  heatMapGradientMinMax,
  updateHeatmap,
} from './general';
import { HeatmapConfig } from './type';

import { cloneDeep } from 'lodash';
import { setLegendForHeatmap } from '../../Legend/legend';
import { AggregationForHeat, LegendMode, MapLayerType } from '../../types/interface';
import { PanelConfig } from '../../types/types';
import { getRGBColor } from '../geojsonLayer/general';
import { defaultOptions } from './default';

/**
 * Heatmap layer configuration for the map.
 *
 * @constant
 * @type {OSMapLayerRegistryItem<HeatmapConfig>}
 * @property {string} id - The unique identifier for the heatmap layer.
 * @property {string} name - The name or label for the heatmap layer.
 * @property {string} description - A description of the heatmap layer.
 * @property {boolean} isBaseMap - Indicates whether the heatmap layer is a base map.
 * @property {boolean} showLocation - Indicates whether the heatmap layer should display location information.
 * @property {OSMapLayerOptions<HeatmapConfig>} defaultOptions - The default options for the heatmap layer.
 * @property {Function} create - A function that creates and configures the heatmap layer on the map.
 */
export const heatmapLayer: OSMapLayerRegistryItem<HeatmapConfig> = {
  id: MapLayerType.HEATMAP,
  name: 'Heatmap',
  description: 'visualizes a heatmap of the data',
  isBaseMap: false,
  showLocation: true,
  defaultOptions,

  /**
   * Creates and configures a heatmap layer on the map.
   *
   * @async
   * @function
   * @param {Map} map - The map object on which to create the heatmap layer.
   * @param {OSMapLayerOptions<HeatmapConfig>} options - The configuration options for the heatmap layer.
   * @returns {Promise<{ init: Function, update: Function }>} An object containing the initialization and update functions for the heatmap layer.
   * @throws {Error} Throws an error if there's an issue creating the heatmap layer.
   */
  create: async (map: Map, options: OSMapLayerOptions<HeatmapConfig>) => {
    const config = { ...defaultOptions, ...options.config };
    const vectorSource: OLVectorSource<Point> = new OLVectorSource();

    //* Layer opacity and heatmap opacity is same for heatmap.
    // Create a new Heatmap layer
    // Weight function takes a feature as attribute and returns a normalized weight value
    const vectorLayer = new HeatmapLayer({
      source: vectorSource,
      blur: config.style.blur,
      radius: config.style.radius,
      opacity: config.style.opacity,
      // Before getting the weight function, we need to set the weight property
      // weight: (feature) => {
      //   return feature.get('weight');
      // },
    });
    return {
      /**
       * Initializes the heatmap layer.
       *
       * @function
       * @returns {OLVectorLayer<Point>} The initialized heatmap layer.
       */
      init: () => vectorLayer,
      /**
       * Updates the heatmap layer with new data.
       *
       * @function
       * @param {PanelData} data - The data to update the heatmap layer with.
       * @param {PanelConfig} panelConfig - The configuration for the panel.
       */
      update: (data: PanelData, panelConfig: PanelConfig) => {
        const { timeLapseCurrTime, fieldConfig, displayOptions, customControl, selected } = panelConfig;
        const { aggregationData, queryOptions, style } = config;

        // clear data of the layer
        vectorSource.clear();

        let heatmapColors: string[] = [];
        if (
          style.colorGradient === colorGradient.customPalette &&
          fieldConfig &&
          data.series &&
          data.series.length > 0
        ) {
          const colorMode = getFieldColorModeForField(data.series[0].fields[0]);
          heatmapColors = colorMode.colors?.map((color) => getRGBColor(color)) || colorGradientArray['defaultColor'];
        } else {
          heatmapColors = colorGradientArray[style.colorGradient];
        }
        vectorLayer.setGradient(heatmapColors);

        let stepsLength: Threshold[] = [];
        if (fieldConfig) {
          if (fieldConfig.defaults.color?.mode === FieldColorModeId.Thresholds) {
            stepsLength = fieldConfig.defaults.thresholds?.steps || [];
          }
        }
        let heatmapLegend = null;

        //Get Geohash QueryDataCal values.
        const fieldArray = getGeohashValues(data.series ?? [], queryOptions);
        if (fieldArray && displayOptions) {
          const { heatmapSteps, legendMode, timelapse } = displayOptions;
          const GradientMinMax = heatMapGradientMinMax(fieldArray);
          heatmapLegend = setLegendForHeatmap(
            heatmapColors,
            GradientMinMax,
            heatmapSteps ?? 10,
            legendMode === LegendMode.Both,
            stepsLength.length,
            options.name,
            displayOptions.showInfo
          );

          if (timelapse.isTimeLapse && timeLapseCurrTime && data.request?.intervalMs && fieldArray) {
            updateHeatmap(
              timeLapseCurrTime,
              data.request?.intervalMs,
              fieldArray,
              vectorSource,
              style.weight,
              options.name
            );
          } else {
            const currZoom = map.getView().getZoom();

            if (currZoom && 7 <= currZoom && currZoom <= 16 && aggregationData !== AggregationForHeat.NONE) {
              const clonedFieldArray = cloneDeep(fieldArray);
              // get nearby markers
              const finalMarkersForHeat = createMarkerGroups(currZoom, aggregationData, clonedFieldArray);
              const GradientMinMax = heatMapGradientMinMax(finalMarkersForHeat);
              heatmapLegend = setLegendForHeatmap(
                heatmapColors,
                GradientMinMax,
                heatmapSteps ?? 10,
                legendMode === LegendMode.Both,
                stepsLength.length,
                options.name,
                displayOptions.showInfo
              );
              if (finalMarkersForHeat && finalMarkersForHeat.length > 0) {
                addFeatures(vectorSource, finalMarkersForHeat, options.name);

                //Add weight to map.
                if (style.weight) {
                  const sortedUnique = getSortedUniqueArray(finalMarkersForHeat.map((ele) => ele.value));
                  addWeights(vectorSource, sortedUnique, 'value');
                }
              }
            } else {
              addFeatures(vectorSource, fieldArray, options.name);

              //Add weight to map.
              if (style.weight) {
                const sortedUnique = getSortedUniqueArray(fieldArray.map((ele) => ele.value));
                addWeights(vectorSource, sortedUnique, 'value');
              }
            }
          }
        }
        // heatmap legend
        // Dynamically update the content of the control
        if (
          selected &&
          displayOptions &&
          (displayOptions?.layers.find((v, index) => selected - 1 === index)?.type === MapLayerType.HEATMAP ||
            displayOptions?.layers.find((v, index) => selected - 1 === index)?.type === MapLayerType.CUSTOMHEATMAP)
        ) {
          if (selected - 1 === displayOptions?.layers.findIndex((v) => v.name === options.name)) {
            if (
              heatmapLegend &&
              (displayOptions.legendMode === LegendMode.Heatmap || displayOptions.legendMode === LegendMode.Both)
            ) {
              customControl?.updateContent(
                heatmapLegend,
                displayOptions.legendMode === LegendMode.Both,
                stepsLength.length
              );
            } else {
              customControl?.updateContent(null, displayOptions?.legendMode === LegendMode.Both, stepsLength.length);
            }
          }
        } else {
          customControl?.updateContent(null, displayOptions?.legendMode === LegendMode.Both, stepsLength.length);
        }
      },
    };
  },
};
