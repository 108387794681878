import React, { PureComponent } from 'react';
import { Icon, InfoBox, LegacyForms } from '@grafana/ui';
const { Switch } = LegacyForms;
import { getBackendSrv } from '@grafana/runtime';
import { SelectableValue } from '@grafana/data';
import { DashboardModel, PanelModel } from 'app/features/dashboard/state';
import { DashboardAcl, NewDashboardAclItem } from 'app/types/acl';

interface Props {
  dashboard: DashboardModel;
  panel?: PanelModel;
  onDismiss(): void;
}
export interface State {
  users: SelectableValue<string>;
  permissions: SelectableValue<DashboardAcl>;
  reload: number;
}

export class ShareDashboard extends PureComponent<Props, State> {
  // @ts-ignore
  private dashboard: DashboardModel;
  constructor(props: Props) {
    super(props);
    this.state = {
      users: [],
      permissions: [],
      reload: 0,
    };
  }

  async componentDidMount() {
    // /api/users
    let users = await getBackendSrv().get('/api/org/users');
    users = users.filter((user: any) => {
      return user.login === 'carbon-special-user';
    });
    const permissions = await getBackendSrv().get(`/api/dashboards/id/${this.props.dashboard.id}/permissions`);
    this.setState({ users: users, permissions: permissions });
  }

  handleSwitch = (id: number | undefined, active: boolean) => {
    if (active) {
      this.onRemovePermission(id);
    } else {
      this.onAddPermission(id);
    }
  };
  onAddPermission = async (id: number | undefined) => {
    const itemsToUpdate = [];
    this.state.permissions.map((item: DashboardAcl) => {
      itemsToUpdate.push({
        userId: item.userId,
        teamId: item.teamId,
        role: item.role,
        permission: item.permission,
      });
    });
    itemsToUpdate.push({
      userId: id,
      teamId: 0,
      role: 'Viewer',
      permission: 1,
    } as NewDashboardAclItem);
    await getBackendSrv().post(`/api/dashboards/id/${this.props.dashboard.id}/permissions`, { items: itemsToUpdate });
    const permissions = await getBackendSrv().get(`/api/dashboards/id/${this.props.dashboard.id}/permissions`);
    this.setState({ permissions: permissions });
  };

  onRemovePermission = async (id: number | undefined) => {
    const itemsToUpdate: SelectableValue<NewDashboardAclItem> = [];
    this.state.permissions.map((item: DashboardAcl) => {
      if (item.userId !== id) {
        itemsToUpdate.push({
          userId: item.userId,
          teamId: item.teamId,
          role: item.role,
          permission: item.permission,
        });
      }
    });
    await getBackendSrv().post(`/api/dashboards/id/${this.props.dashboard.id}/permissions`, { items: itemsToUpdate });
    const permissions = await getBackendSrv().get(`/api/dashboards/id/${this.props.dashboard.id}/permissions`);
    this.setState({ permissions: permissions });
  };
  checkEnable = (id: number | undefined) => {
    const res = this.state.permissions.some(function (item: DashboardAcl) {
      return item.userId === id;
    });
    return res;
  };
  renderStep1() {
    const { users } = this.state;
    return (
      <>
        <div className="share-modal-body">
          <div className="share-modal-header">
            <Icon name="link" className="share-modal-big-icon" size="xxl" />
            <div className="share-modal-content">
              <>
                {users.length > 0 ? (
                  <Switch
                    labelClass="width-16"
                    label="Share externally"
                    checked={this.checkEnable(users[0].userId)}
                    onChange={() => this.handleSwitch(users[0].userId, this.checkEnable(users[0].userId))}
                  />
                ) : (
                  <InfoBox>
                    <p>User not available. Please contact your administrator.</p>
                  </InfoBox>
                )}
              </>
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="share-modal-body">
        <div className="share-modal-header">
          <Icon name="shield" className="share-modal-big-icon" size="xxl" />
          <div className="share-modal-content">{this.renderStep1()}</div>
        </div>
      </div>
    );
  }
}
