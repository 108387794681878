import React from 'react';
import { InlineField, InlineFieldRow, Input, Select } from '@grafana/ui';
import { downsamplingTypes, upsamplingTypes } from '../types';

export const Resample = ({ labelWidth, onChange, refIds, query, onRunQuery }: any) => {
  const downsampler = downsamplingTypes.find((o: any) => o.value === query.downsampler);
  const upsampler = upsamplingTypes.find((o: any) => o.value === query.upsampler);

  const onResampleChange = (e: any) => {
    const { value } = e.target;
    onChange({ ...query, window: value });
    onRunQuery();
  };

  const onRefIdChange = (e: any) => {
    onChange({ ...query, expression: e.value });
    onRunQuery();
  };

  const selectDownsampler = (e: any) => {
    onChange({ ...query, downsampler: e.value });
    onRunQuery();
  };

  const selectUpsampler = (e: any) => {
    onChange({ ...query, upsampler: e.value });
    onRunQuery();
  };

  return (
    <>
      <InlineFieldRow>
        <InlineField label="Input" labelWidth={labelWidth}>
          <Select onChange={onRefIdChange} options={refIds} value={query.expression} width={20} />
        </InlineField>
      </InlineFieldRow>
      <InlineFieldRow>
        <InlineField label="Resample to" labelWidth={labelWidth} tooltip="10s, 1m, 30m, 1h">
          <Input onChange={onResampleChange} value={query.window} width={15} />
        </InlineField>
        <InlineField label="Downsample">
          <Select options={downsamplingTypes} value={downsampler} onChange={selectDownsampler} width={25} />
        </InlineField>
        <InlineField label="Upsample">
          <Select options={upsamplingTypes} value={upsampler} onChange={selectUpsampler} width={25} />
        </InlineField>
      </InlineFieldRow>
    </>
  );
};
