import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const ImportDashboard: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 16 16"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M14.4,10.3L14.4,10.3c-0.3,0-0.6,0.3-0.6,0.6v2.3c0,0.3-0.3,0.6-0.6,0.6H2.8c-0.3,0-0.6-0.3-0.6-0.6v-2.3
        c0-0.3-0.3-0.6-0.6-0.6l0,0c-0.3,0-0.6,0.3-0.6,0.6v2.3c0,1,0.8,1.7,1.7,1.7h10.5c1,0,1.7-0.8,1.7-1.7v-2.3
        C15,10.6,14.7,10.3,14.4,10.3z M4.1,8.9l2.7,2.7c0.7,0.7,1.8,0.7,2.5,0l2.7-2.7c0.2-0.2,0.2-0.6,0-0.8c-0.2-0.2-0.6-0.2-0.8,0
        c0,0,0,0,0,0l-2.5,2.5v-9C8.6,1.3,8.3,1,8,1S7.4,1.3,7.4,1.6v9L4.9,8.1c-0.2-0.2-0.6-0.2-0.8,0C3.9,8.3,3.9,8.6,4.1,8.9
        C4.1,8.9,4.1,8.9,4.1,8.9z"
      />
    </svg>
  );
};
