import React, { useCallback, useState } from 'react';
import { FieldDisplay, FALLBACK_COLOR, formattedValueToString, GrafanaTheme, TimeRange } from '@grafana/data';
import { useTheme, useStyles, SeriesTableRowProps, SeriesTable } from '@grafana/ui';
import { PieChartLabels, PieChartOptions } from './types/types';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import Pie from '@visx/shape/lib/shapes/Pie';
import { Arc } from '@visx/shape';
import { RadialGradient } from '@visx/gradient';
import { localPoint } from '@visx/event';
import { Group } from '@visx/group';
import tinycolor from 'tinycolor2';
import { css } from 'emotion';

import { useComponentInstanceId } from '@grafana/ui/src/utils/useComponetInstanceId';
import { getTooltipContainerStyles } from '@grafana/ui/src/themes/mixins';

import {
  getGradientColorFrom,
  getGradientColorTo,
  getPieLayout,
  getLabelPosition,
  getTooltipData,
  getTooltipDataPolar,
} from './utils';

import {
  // PieChartSvgProps,
  PieTooltipProps,
  PieLabelProps,
  PolarArrayType,
  PolarLabelProps,
  PolarTooltipProps,
} from './types/interfaceSvg';
import { selectors } from '@grafana/e2e-selectors';
import { LinkDrawer, DrawerData } from 'app/features/linkDrawer/LinkDrawer';

interface PieChartSvgProps {
  width: number;
  height: number;
  values: FieldDisplay[];
  options: PieChartOptions;
  timeRange: TimeRange;
  drawerLinkTime: boolean;
  children?: React.ReactNode;
}

export const PieChartSvg: React.FunctionComponent<PieChartSvgProps> = ({
  values,
  width,
  height,
  options: {
    tooltip: tooltipOptions,
    pieType,
    displayLabels = [],
    dashboardVisitMode,
    toVisitDashboard,
    radialExtend,
    radialInput,
    radialOptionsSelected,
  },
  timeRange,
  drawerLinkTime,
}) => {
  const theme = useTheme();
  const componentInstanceId = useComponentInstanceId('PieChart');
  const styles = useStyles(getStyles);
  const tooltip = useTooltip<SeriesTableRowProps[]>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });
  const getValue = (d: FieldDisplay) => d.display.numeric;
  const getGradientId = (color: string) => `${componentInstanceId}-${tinycolor(color).toHex()}`;

  const showLabel = displayLabels.length > 0;
  const showTooltip = tooltipOptions.mode !== 'none' && tooltip.tooltipOpen;

  /** very important for pie chart panel as we always need summ of all values*/
  const total = values.reduce((acc, item) => item.display.numeric + acc, 0);

  /**
   * get layout object from width height and pie type
   */
  const layout = getPieLayout(width, height, pieType);
  // maximum value from data
  let max = values[0].display?.numeric;
  for (let index = 1; index < values.length; index++) {
    const element = values[index];
    if (element.display.numeric > max) {
      max = element.display.numeric;
    }
  }
  // forming polar data array
  const polarArray: PolarArrayType[] = [];
  const angleDiff = (Math.PI * 2) / values.length;
  const stAngle = 0;

  for (let index = 0; index < values.length; index++) {
    const element = values[index];
    const outerRadiusHigh = Math.sqrt((max * 2) / angleDiff);
    const outerRadiusActual = Math.sqrt((element.display.numeric * 2) / angleDiff);
    const currentWidth = layout.outerRadius;
    const calWidth = (outerRadiusActual / outerRadiusHigh) * currentWidth;
    polarArray.push({
      key: index,
      innerRadius: 0,
      outerRadius: element.display.numeric === max ? currentWidth : calWidth,
      startAnglePolar: index === stAngle ? stAngle : polarArray[index - 1].endAnglePolar,
      endAnglePolar: index === stAngle ? angleDiff : angleDiff * (index + 1),
      color: element.display.color,
      numericValue: element.display.numeric,
      title: element.display.title,
    });
  }

  // forming radial bar chart array
  const radialArray: PolarArrayType[] = [];
  const angleBind = radialExtend === '270' ? (Math.PI * 2 * 3) / 4 : Math.PI * 2;
  const defaultInnerRadius = 20;
  const thicknessOfBar = (layout.outerRadius - defaultInnerRadius) / values.length;

  for (let index = 0; index < values.length; index++) {
    const element = values[index];
    let endAngleRadial = 0;
    if (radialOptionsSelected === 'max') {
      endAngleRadial = (element.display.numeric / max) * angleBind;
    } else if (radialOptionsSelected === 'total') {
      endAngleRadial = (element.display.numeric / total) * angleBind;
    } else {
      const inputValue = radialInput;
      endAngleRadial = (element.display.numeric / inputValue) * angleBind;
    }
    radialArray.push({
      key: index,
      innerRadius: index === 0 ? defaultInnerRadius : radialArray[index - 1].outerRadius,
      outerRadius:
        index === 0 ? thicknessOfBar + defaultInnerRadius : radialArray[index - 1].outerRadius + thicknessOfBar,
      startAnglePolar: stAngle,
      endAnglePolar:
        radialOptionsSelected === 'max'
          ? element.display.numeric === max
            ? angleBind
            : endAngleRadial
          : endAngleRadial,
      color: element.display.color,
      numericValue: element.display.numeric,
      title: element.display.title,
    });
  }

  //* Deshdoard linkind drawer value set.
  const [infoData, setinfoData] = useState<DrawerData>({ location: '', url: '' });
  const [showDrawer, setShowDrawer] = React.useState(false);

  const onCloseDrawer = () => {
    setShowDrawer(false);
  };

  const drawerhandle = (urlName: string, url: string) => {
    if (urlName.length !== 0) {
      setinfoData({ location: urlName, url: url });
    } else {
      setinfoData({ location: url, url: url });
    }
    setShowDrawer(!showDrawer);
  };

  return (
    <div className={styles.container}>
      <svg width={layout.size} height={layout.size} ref={containerRef}>
        <Group top={layout.position} left={layout.position}>
          {(pieType === 'pie' || pieType === 'donut') &&
            values.map((value: FieldDisplay, index: number) => {
              const color = value.display.color ?? FALLBACK_COLOR;
              return (
                <RadialGradient
                  key={`${value.display.color}-${index}`}
                  id={getGradientId(color)}
                  from={getGradientColorFrom(color, theme)}
                  to={getGradientColorTo(color, theme)}
                  fromOffset={layout.gradientFromOffset}
                  toOffset="1"
                  gradientUnits="userSpaceOnUse"
                  cx={0}
                  cy={0}
                  radius={layout.outerRadius}
                />
              );
            })}
          {(pieType === 'pie' || pieType === 'donut') && (
            <Pie
              data={values}
              pieValue={getValue}
              outerRadius={layout.outerRadius}
              innerRadius={layout.innerRadius}
              cornerRadius={3}
              padAngle={0.005}
            >
              {(pie) => (
                <>
                  {pie.arcs.map((arc) => {
                    const color = arc.data.display.color ?? FALLBACK_COLOR;
                    return (
                      <PieTooltip
                        key={arc.index}
                        tooltip={tooltip}
                        arc={arc}
                        pie={pie}
                        fill={color}
                        // fill={getGradientColor(color)}
                        tooltipOptions={tooltipOptions}
                        dashVisitMode={dashboardVisitMode}
                        dashVisit={toVisitDashboard.visitDashboard}
                        drawerhandle={drawerhandle}
                      />
                    );
                  })}
                  {showLabel &&
                    pie.arcs.map((arc) => {
                      return (
                        <PieLabel
                          arc={arc}
                          key={arc.index}
                          outerRadius={layout.outerRadius}
                          innerRadius={layout.innerRadius}
                          displayLabels={displayLabels}
                          total={total}
                          color={theme.colors.text}
                        />
                      );
                    })}
                </>
              )}
            </Pie>
          )}
          {pieType === 'polar' &&
            polarArray.map((ele: PolarArrayType) => {
              return (
                <Arc
                  key={ele.key}
                  innerRadius={ele.innerRadius}
                  outerRadius={ele.outerRadius}
                  startAngle={ele.startAnglePolar}
                  endAngle={ele.endAnglePolar}
                  fill={ele.color}
                  cornerRadius={3}
                >
                  {({ path }) => {
                    return (
                      <>
                        <PolarTooltip
                          arcs={polarArray}
                          arc={ele}
                          path={path}
                          tooltip={tooltip}
                          tooltipOptions={tooltipOptions}
                          dashVisitMode={dashboardVisitMode}
                          dashVisit={toVisitDashboard.visitDashboard}
                          drawerhandle={drawerhandle}
                        />
                        {showLabel && <PolarLabel path={path} arc={ele} total={total} displayLabels={displayLabels} />}
                      </>
                    );
                  }}
                </Arc>
              );
            })}
          {pieType === 'radial' &&
            radialArray.map((ele: PolarArrayType) => {
              return (
                <Arc
                  key={ele.key}
                  innerRadius={ele.innerRadius}
                  outerRadius={ele.outerRadius}
                  startAngle={ele.startAnglePolar}
                  endAngle={ele.endAnglePolar}
                  fill={ele.color}
                  cornerRadius={3}
                >
                  {({ path }) => {
                    return (
                      <>
                        <PolarTooltip
                          arcs={radialArray}
                          arc={ele}
                          path={path}
                          tooltip={tooltip}
                          tooltipOptions={tooltipOptions}
                          dashVisitMode={dashboardVisitMode}
                          dashVisit={toVisitDashboard.visitDashboard}
                          drawerhandle={drawerhandle}
                        />
                        {showLabel && (
                          <PolarLabel
                            path={path}
                            arc={ele}
                            total={total}
                            displayLabels={displayLabels}
                            pieType={pieType}
                          />
                        )}
                      </>
                    );
                  }}
                </Arc>
              );
            })}
        </Group>
      </svg>
      {showTooltip ? (
        <TooltipInPortal
          key={Math.random()}
          top={tooltip.tooltipTop}
          className={styles.tooltipPortal}
          left={tooltip.tooltipLeft}
          unstyled={true}
          applyPositionStyle={true}
        >
          <SeriesTable series={tooltip.tooltipData!} />
        </TooltipInPortal>
      ) : null}

      {showDrawer && (
        <LinkDrawer infoData={infoData} onClose={onCloseDrawer} timeRange={drawerLinkTime ? timeRange : undefined} />
      )}
    </div>
  );
};

function PieTooltip({
  arc,
  pie,
  fill,
  tooltip,
  tooltipOptions,
  dashVisitMode,
  dashVisit,
  drawerhandle,
}: PieTooltipProps) {
  const theme = useTheme();
  const styles = useStyles(getStyles);
  const onMouseOut = useCallback(() => {
    tooltip.hideTooltip();
  }, [tooltip]);

  const onMouseMoveOverArc = (event: any) => {
    const coords = localPoint(event.target.ownerSVGElement, event);
    tooltip.showTooltip({
      tooltipLeft: coords!.x,
      tooltipTop: coords!.y,
      tooltipData: getTooltipData(pie, arc, tooltipOptions),
    });
  };
  const onClickDash = (event: any) => {
    if (dashVisitMode) {
      Object.keys(dashVisit).map((key: any) => {
        if (dashVisit[key].queryName === arc.data.display.title) {
          const urlToGo: any = dashVisit[key].dashboardUrl;
          const urlName: any = dashVisit[key].dashboardName;
          if (urlToGo !== '') {
            // parent.open(urlToGo);
            drawerhandle(urlName, urlToGo);
          }
        }
      });
    }
  };
  return (
    <g
      key={arc.data.display.title}
      className={styles.svgArg}
      onMouseMove={tooltipOptions.mode !== 'none' ? onMouseMoveOverArc : undefined}
      onMouseOut={onMouseOut}
      aria-label={selectors.components.Panels.Visualization.PieChart.svgSlice}
      onClick={(e) => onClickDash(e)}
    >
      <path d={pie.path({ ...arc })!} fill={fill} stroke={theme.colors.bg1} strokeWidth={1} />
    </g>
  );
}

function PieLabel({ arc, outerRadius, innerRadius, displayLabels, total, color }: PieLabelProps) {
  const labelRadius = innerRadius === 0 ? outerRadius / 6 : innerRadius;
  const [labelX, labelY] = getLabelPosition(arc, outerRadius, labelRadius);
  const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.3;

  if (!hasSpaceForLabel) {
    return null;
  }

  let labelFontSize = displayLabels.includes(PieChartLabels.Name)
    ? Math.min(Math.max((outerRadius / 150) * 14, 12), 20)
    : Math.min(Math.max((outerRadius / 100) * 14, 12), 24);

  return (
    <g>
      <text
        fill={color}
        x={labelX}
        y={labelY}
        dy=".33em"
        fontSize={labelFontSize}
        fontWeight={500}
        textAnchor="middle"
        pointerEvents="none"
      >
        {displayLabels.includes(PieChartLabels.Name) && (
          <tspan x={labelX} dy="1.2em">
            {arc.data.display.title}
          </tspan>
        )}
        {displayLabels.includes(PieChartLabels.Value) && (
          <tspan x={labelX} dy="1.2em">
            {formattedValueToString(arc.data.display)}
          </tspan>
        )}
        {displayLabels.includes(PieChartLabels.Percent) && (
          <tspan x={labelX} dy="1.2em">
            {((arc.data.display.numeric / total) * 100).toFixed(2) + '%'}
          </tspan>
        )}
      </text>
    </g>
  );
}

const PolarLabel = ({ path, arc, total, displayLabels, pieType }: PolarLabelProps) => {
  const theme = useTheme();
  let labelFontSize = displayLabels.includes(PieChartLabels.Name)
    ? Math.min(Math.max((arc.outerRadius / 150) * 14, 12), 20)
    : displayLabels.includes(PieChartLabels.Value)
    ? Math.min(Math.max((arc.outerRadius / 100) * 14, 12), 16)
    : Math.min(Math.max((arc.outerRadius / 100) * 14, 12), 20);
  if (pieType === 'radial') {
    labelFontSize = Math.min(Math.max(((arc.outerRadius - arc.innerRadius) / 100) * 14, 12), 16);
  }
  return (
    <>
      <g>
        <text
          fill={theme.colors.text}
          x={path.centroid(path)[0]}
          y={path.centroid(path)[1]}
          dy=".33em"
          fontSize={labelFontSize}
          textAnchor="middle"
          pointerEvents="none"
        >
          {displayLabels.includes(PieChartLabels.Name) && <tspan dy="1.2em">{arc.title}</tspan>}
          {displayLabels.includes(PieChartLabels.Value) && <tspan dy="1.2em">{Math.round(arc.numericValue)}</tspan>}
          {displayLabels.includes(PieChartLabels.Percent) && (
            <tspan dy="1.2em">{((arc.numericValue / total) * 100).toFixed(2) + '%'}</tspan>
          )}
        </text>
      </g>
    </>
  );
};

const PolarTooltip = ({
  path,
  arc,
  tooltip,
  tooltipOptions,
  arcs,
  dashVisitMode,
  dashVisit,
  drawerhandle,
}: PolarTooltipProps) => {
  const theme = useTheme();
  const styles = useStyles(getStyles);
  const onMouseOut = useCallback(() => {
    tooltip.hideTooltip();
  }, [tooltip]);

  const onMouseMoveOverArc = (event: any) => {
    const coords = localPoint(event.target.ownerSVGElement, event);
    tooltip.showTooltip({
      tooltipLeft: coords!.x,
      tooltipTop: coords!.y,
      tooltipData: getTooltipDataPolar(arcs, arc, tooltipOptions),
    });
  };
  const onClickDash = (event: any) => {
    if (dashVisitMode) {
      Object.keys(dashVisit).map((key: any) => {
        if (dashVisit[key].queryName === arc.title) {
          const urlToGo: any = dashVisit[key].dashboardUrl;
          const urlName: any = dashVisit[key].dashboardName;
          if (urlToGo !== '') {
            // parent.open(urlToGo);
            drawerhandle(urlName, urlToGo);
          }
        }
      });
    }
  };
  return (
    <g
      key={arc.color}
      className={styles.svgArg}
      onMouseMove={tooltipOptions.mode !== 'none' ? onMouseMoveOverArc : undefined}
      onMouseOut={onMouseOut}
      aria-label={selectors.components.Panels.Visualization.PieChart.svgSlice}
      onClick={(e) => onClickDash(e)}
    >
      <path d={path()} fill={arc.color} stroke={theme.colors.bg1} strokeWidth={1} />
    </g>
  );
};

const getStyles = (theme: GrafanaTheme) => {
  return {
    container: css`
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    `,
    svgArg: css`
      transition: all 200ms ease-in-out;
      &:hover {
        transform: scale3d(1.03, 1.03, 1);
      }
    `,
    tooltipPortal: css`
      ${getTooltipContainerStyles(theme)}
    `,
  };
};
