import React from 'react';
import { css } from 'emotion';
import { getColorForTheme } from '@grafana/data';
import { stylesFactory, useTheme } from '@grafana/ui';
import tinycolor from 'tinycolor2';

interface Props {
  color: string;
  width: number;
  height: number;
  text: any;
  isPositive: boolean;
  subtext: string;
}

export function Display(props: Props) {
  const { width, height, color, text, isPositive } = props;
  const theme = useTheme();
  const themeFactor = theme.isDark ? 1 : -0.7;
  const rgborHex = getColorForTheme(color || '#255aee', theme);
  const bgColor2 = tinycolor(rgborHex)
    .darken(15 * themeFactor)
    .spin(8)
    .toRgbString();
  const bgColor3 = tinycolor(rgborHex)
    .darken(5 * themeFactor)
    .spin(-8)
    .toRgbString();
  const bcolor = `linear-gradient(120deg, ${bgColor2}, ${bgColor3})`;

  let mb = '0.5';
  if (props.width > 400) {
    mb = '1.2';
  }
  if (props.width > 600) {
    mb = '1.5';
  }
  if (props.width > 800) {
    mb = '2.2';
  }
  if (props.height < 200) {
    mb = '0.5';
  }
  const styles = getStyles(width, height, mb);

  return (
    <div
      style={{
        width: width,
        height: height,
        // backgroundColor: color,
        background: bcolor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: width > 750 && height < 250 ? 'row' : 'column',
        overflow: 'hidden',
      }}
    >
      <div className={styles.div1 + ' ' + styles.mb1}>
        <h3 style={{ fontSize: width / 20 + 'px' }}>{props.subtext}</h3>
      </div>
      <div className={styles.div2 + ' ' + styles.mb1}>
        <img
          width={width / 10}
          src={`public/img/${isPositive ? 'UpArrow' : 'DownArrow'}.svg`}
          alt="downarrow"
          style={{ marginBottom: '3px' }}
        />
        <h1 style={{ fontSize: width / 10 + 'px' }}>{text}</h1>
      </div>
    </div>
  );
}

const getStyles = stylesFactory((width, height, mb) => {
  return {
    div1: css`
      // width: ${width}px;
      // height: ${height / 2}px;
    `,
    div2: css`
      // width: ${width}px;
      // height: ${height / 2}px;
      display: flex;
    `,
    mb1: css`
      margin-bottom: ${mb}rem;
    `,
    mb2: css`
      margin-bottom: ${mb * 2}rem;
    `,
  };
});
