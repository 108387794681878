import uPlot, { Axis, Cursor, BBox } from 'uplot';
import { DataFrame, GrafanaTheme } from '@grafana/data';
import { BoxPlotData, TooltipInterface } from '../types';
import { Quadtree, Rect, pointWithin } from '../quadtree';
import { formatTime } from '../utils';
/**
 * @typedef {Object} BoxOptions
 * @property {number} xOri
 * @property {number} xDir
 * @property {DataFrame} data
 * @property { BoxPlotData} dataAll
 * @property {number} toCluster
 * @property {number} customClusterSize
 * @property {boolean} clusterSwitch
 * @property {Function} rawValue
 * @property {function} formatValue
 */
export interface BoxOptions {
  xOri: 1 | 0;
  xDir: 1 | -1;
  data: DataFrame;
  dataAll: BoxPlotData;
  toCluster: number;
  customClusterSize: number;
  rawValue: (seriesIdx: number, valueIdx: number) => number | null;
  formatValue: (seriesIdx: number, value: any) => string;
}
/**
 * @typedef {Object} ValueStop
 * @property {number} value
 * @property {string} color
 */
interface ValueStop {
  value: number;
  color: string;
}
/**
 * @constant
 * @default
 */
const pxRatio = devicePixelRatio;
/**
 * hooks, tooltip, x axis etc. calculated here to add in uplot builder
 * @param {BoxOptions} opts
 * @param {GrafanaTheme} theme
 * @returns {any} returns many hooks and data
 */
export function getConfig(opts: BoxOptions, theme: GrafanaTheme) {
  const { dataAll, toCluster, customClusterSize } = opts;

  let qt: Quadtree;
  let hovered: Rect | undefined;
  let barMark = document.createElement('div');
  let valueStops: ValueStop[] = [];

  barMark.classList.add('bar-mark');
  barMark.style.position = 'absolute';
  barMark.style.background = 'rgba(255,255,255,0.4)';

  // hide crosshair cursor & hover points
  const cursor: Cursor = {
    x: true,
    y: false,
    points: {
      show: false,
    },
  };
  const select: Partial<BBox> = {
    show: false,
  };
  const init = (u: uPlot) => {
    let over = u.root.querySelector('.u-over')! as HTMLElement;
    over.style.overflow = 'hidden';
    over.appendChild(barMark);
  };
  /**
   * draw boxes when normal is selected
   */
  const drawBoxes = (u: uPlot) => {
    /**
     * @constant
     * @default
     */
    const gap = 2,
      bodyWidthFactor = 0.5,
      shadowWidth = 2,
      bodyOutline = 2;
    u.ctx.save();
    /**
     * @constant
     * @default
     */
    const offset = (shadowWidth % 2) / 2;

    u.ctx.translate(offset, offset);
    for (let i = u.scales.x.min!; i <= u.scales.x.max!; i++) {
      let med = dataAll.box?.median?.values[i]!;
      let q1 = dataAll.box?.q1?.values[i]!;
      let q3 = dataAll.box?.q3?.values[i]!;
      let min = dataAll.box?.min?.values[i]!;
      let max = dataAll.box?.max?.values[i]!;
      /**
       * u.valToPos function gives pixel value in canvas according to value
       */
      let timeAsX = u.valToPos(i, 'x', true);
      let lowAsY = u.valToPos(min, u.series[1].scale!, true);
      let highAsY = u.valToPos(max, u.series[1].scale!, true);
      let openAsY = u.valToPos(q1, u.series[1].scale!, true);
      let closeAsY = u.valToPos(q3, u.series[1].scale!, true);
      let medAsY = u.valToPos(med, u.series[1].scale!, true);
      // shadow rect
      let shadowHeight = Math.max(highAsY, lowAsY) - Math.min(highAsY, lowAsY);
      let shadowX = timeAsX;
      let shadowY = Math.min(highAsY, lowAsY);
      u.ctx.beginPath();
      u.ctx.setLineDash([4, 4]);
      u.ctx.lineWidth = shadowWidth;
      u.ctx.strokeStyle = dataAll.box?.color?.values[i]!;
      u.ctx.moveTo(Math.round(shadowX), Math.round(shadowY));
      u.ctx.lineTo(Math.round(shadowX), Math.round(shadowY + shadowHeight));
      u.ctx.stroke();

      // body rect
      let columnWidth = u.bbox.width / dataAll.box?.queryIndex?.values.length!;
      let bodyWidth = Math.round(bodyWidthFactor * (columnWidth - gap));
      let bodyHeight = Math.max(closeAsY, openAsY) - Math.min(closeAsY, openAsY);
      let bodyX = timeAsX - bodyWidth / 2;

      let bodyY = Math.min(closeAsY, openAsY);
      /**
       * color is passing from already created color array
       */
      let bodyColor = dataAll.box?.color?.values[i]!;
      /**
       * tooltip hover interaction quadtree.
       * adding tooltip related data to quad tree.
       * this will gives us that tooltip is present in cursor vicinity or not
       */
      qt.add({
        x: timeAsX - u.bbox.left - bodyWidth / 2,
        y: shadowY - u.bbox.top,
        w: bodyWidth,
        h: shadowHeight,
        sidx: 1,
        didx: i,
      });
      u.ctx.fillStyle = dataAll.box?.color?.values[i]!;
      u.ctx.fillRect(Math.round(bodyX), Math.round(bodyY), Math.round(bodyWidth), Math.round(bodyHeight));

      u.ctx.fillStyle = bodyColor;
      u.ctx.fillRect(
        Math.round(bodyX + bodyOutline),
        Math.round(bodyY + bodyOutline),
        Math.round(bodyWidth - bodyOutline * 2),
        Math.round(bodyHeight - bodyOutline * 2)
      );

      // median properties
      u.ctx.fillStyle = 'rgba(0,0,0,1)';
      u.ctx.fillRect(Math.round(bodyX), Math.round(medAsY - 1), Math.round(bodyWidth), Math.round(2));

      // hz min/max whiskers
      u.ctx.beginPath();
      u.ctx.setLineDash([]);
      u.ctx.lineWidth = shadowWidth;
      u.ctx.strokeStyle = dataAll.box?.color?.values[i]!;
      u.ctx.moveTo(Math.round(bodyX), Math.round(highAsY));
      u.ctx.lineTo(Math.round(bodyX + bodyWidth), Math.round(highAsY));
      u.ctx.moveTo(Math.round(bodyX), Math.round(lowAsY));
      u.ctx.lineTo(Math.round(bodyX + bodyWidth), Math.round(lowAsY));
      u.ctx.stroke();
    }

    u.ctx.translate(-offset, -offset);

    u.ctx.restore();
  };
  /**
   * draw boxes when with time is selected
   */
  const drawTimeBox = (u: uPlot) => {
    const gap = 2,
      bodyWidthFactor = 0.5,
      shadowWidth = 2,
      bodyOutline = 2;
    u.ctx.save();

    const offset = (shadowWidth % 2) / 2;
    u.ctx.translate(offset, offset);
    for (let i = 0; i < dataAll.boxTime?.time?.values.length!; i++) {
      let med = dataAll.boxTime?.median?.values[i]!;
      let q1 = dataAll.boxTime?.q1?.values[i]!;
      let q3 = dataAll.boxTime?.q3?.values[i]!;
      let min = dataAll.boxTime?.min?.values[i]!;
      let max = dataAll.boxTime?.max?.values[i]!;

      // position of each boxes from their value
      let timeAsX = u.valToPos(dataAll.boxTime?.time?.values[i]!, 'x', true);
      let lowAsY = u.valToPos(min, u.series[1].scale!, true);
      let highAsY = u.valToPos(max, u.series[1].scale!, true);
      let openAsY = u.valToPos(q1, u.series[1].scale!, true);
      let closeAsY = u.valToPos(q3, u.series[1].scale!, true);
      let medAsY = u.valToPos(med, u.series[1].scale!, true);
      // shadow rect
      let shadowHeight = Math.max(highAsY, lowAsY) - Math.min(highAsY, lowAsY);
      let shadowX = timeAsX;
      let shadowY = Math.min(highAsY, lowAsY);
      u.ctx.beginPath();
      u.ctx.setLineDash([4, 4]);
      u.ctx.lineWidth = shadowWidth;
      // color
      u.ctx.strokeStyle = valueStops.length > 0 ? findColor(med, valueStops) : dataAll.boxTime?.color!;
      u.ctx.moveTo(Math.round(shadowX), Math.round(shadowY));
      u.ctx.lineTo(Math.round(shadowX), Math.round(shadowY + shadowHeight));
      u.ctx.stroke();

      // body rect
      let columnWidth = u.bbox.width / dataAll.boxTime?.time?.values.length!;
      let bodyWidth = Math.round(bodyWidthFactor * (columnWidth - gap));
      let bodyHeight = Math.max(closeAsY, openAsY) - Math.min(closeAsY, openAsY);
      let bodyX = timeAsX - bodyWidth / 2;
      let bodyY = Math.min(closeAsY, openAsY);
      // color
      let bodyColor = valueStops.length > 0 ? findColor(med, valueStops) : dataAll.boxTime?.color!;
      qt.add({
        x: timeAsX - u.bbox.left - bodyWidth / 2,
        y: shadowY - u.bbox.top,
        w: bodyWidth,
        h: shadowHeight,
        sidx: 1,
        didx: i,
      });
      // color
      u.ctx.fillStyle = valueStops.length > 0 ? findColor(med, valueStops) : dataAll.boxTime?.color!;
      u.ctx.fillRect(Math.round(bodyX), Math.round(bodyY), Math.round(bodyWidth), Math.round(bodyHeight));

      u.ctx.fillStyle = bodyColor;
      u.ctx.fillRect(
        Math.round(bodyX + bodyOutline),
        Math.round(bodyY + bodyOutline),
        Math.round(bodyWidth - bodyOutline * 2),
        Math.round(bodyHeight - bodyOutline * 2)
      );

      // median properties
      u.ctx.fillStyle = 'rgba(0,0,0,1)';
      u.ctx.fillRect(Math.round(bodyX), Math.round(medAsY - 1), Math.round(bodyWidth), Math.round(2));

      // hz min/max whiskers
      u.ctx.beginPath();
      u.ctx.setLineDash([]);
      u.ctx.lineWidth = shadowWidth;
      // color
      u.ctx.strokeStyle = valueStops.length > 0 ? findColor(med, valueStops) : dataAll.boxTime?.color!;
      u.ctx.moveTo(Math.round(bodyX), Math.round(highAsY));
      u.ctx.lineTo(Math.round(bodyX + bodyWidth), Math.round(highAsY));
      u.ctx.moveTo(Math.round(bodyX), Math.round(lowAsY));
      u.ctx.lineTo(Math.round(bodyX + bodyWidth), Math.round(lowAsY));
      u.ctx.stroke();
    }

    u.ctx.translate(-offset, -offset);

    u.ctx.restore();
  };
  // * splitting x axis in predefined values
  const xValuesBox: Axis.Values = (u: uPlot, val) => {
    let xData = [];
    for (let index = -1; index <= dataAll.box?.queryName?.values.length!; index++) {
      const name = dataAll.box?.queryName?.values[index]!;
      xData.push(name ?? '');
    }
    return xData;
  };
  // * splitting x axis in predefined values
  const xValuesBoxTime: Axis.Values = (u: uPlot, val) => {
    let xDataTime = [];
    for (let index = 0; index < dataAll.boxTime?.time?.values.length!; index++) {
      const incrNew = toCluster === 0 ? customClusterSize * 60 * 1e3 : toCluster * 60 * 1e3;
      const time = formatTime(dataAll.boxTime?.time?.values[index]!, incrNew);
      xDataTime.push(time);
    }
    return xDataTime;
  };

  const drawClear = (u: uPlot) => {
    qt = qt || new Quadtree(0, 0, u.bbox.width, u.bbox.height);

    qt.clear();

    // clear the path cache to force drawBox() to rebuild new quadtree
    u.series.forEach((s) => {
      // @ts-ignore
      s._paths = null;
    });
  };
  const tooltipMaker: TooltipInterface = (seriesIndex, datapointIndex, showTooltip, u) => {
    let found: Rect | undefined;
    let cx = u.cursor.left! * pxRatio;
    let cy = u.cursor.top! * pxRatio;
    qt.get(cx, cy, 1, 1, (o) => {
      if (pointWithin(cx, cy, o.x, o.y, o.x + o.w, o.y + o.h)) {
        found = o;
      }
    });
    if (found) {
      // prettier-ignore
      if (found !== hovered) {
        /* eslint-disable no-multi-spaces */
        barMark.style.display = '';
        barMark.style.left   = found!.x / pxRatio + 'px';
        barMark.style.top    = found!.y / pxRatio + 'px';
        barMark.style.width  = found!.w / pxRatio + 'px';
        barMark.style.height = found!.h / pxRatio + 'px';
        hovered = found;
        seriesIndex(hovered.sidx);
        datapointIndex(hovered.didx);
        showTooltip();
        /* eslint-enable */
      }
    } else if (hovered !== undefined) {
      seriesIndex(hovered!.sidx);
      datapointIndex(hovered!.didx);
      showTooltip();
      hovered = undefined;
      barMark.style.display = 'none';
    } else {
      showTooltip(true);
    }
  };

  return {
    // cursor & select opts
    cursor,
    select,

    // scale & axis opts
    xValuesBox,
    xValuesBoxTime,
    // xValuesCandleTime,

    // hooks
    init,
    drawBoxes,
    drawTimeBox,
    drawClear,
    tooltipMaker,
  };
}

/**
 *
 * @param {number} med
 * @param {Array<ValueStop>} valueStops
 * @returns {string} color
 */
function findColor(med: number, valueStops: ValueStop[]): string {
  let color = '';
  if (med >= valueStops[valueStops.length - 1].value) {
    color = valueStops[valueStops.length - 1].color;
    return color;
  } else {
    for (let index = valueStops.length - 1; index > 0; index--) {
      const eleValue = valueStops[index].value;
      if (med < eleValue && med >= valueStops[index - 1].value) {
        color = valueStops[index - 1].color;
      }
    }
    return color;
  }
}
