import React, { useCallback } from 'react';
import { PanelProps, getFieldDisplayValues, FieldDisplay, outerJoinDataFramesBox } from '@grafana/data';
import { GraphNGLegendEvent, useTheme } from '@grafana/ui';
import { changeSeriesColorConfigFactory } from '../timeseries/overrides/colorSeriesConfigFactory';
import { hideSeriesConfigFactory } from '../timeseries/overrides/hideSeriesConfigFactory';
import { BoxPlotOptions, BoxPlotData } from './types';
import _, { cloneDeep } from 'lodash';
import { prepareGraphableFields } from './utils';
import { calculateWaterfallData } from './calculation/WaterfallDataCalc';
import { calculateWaterfallDataQuery } from './calculation/WaterfallDataQuery';
import { WaterfallPlot } from './waterfallplot/GraphNG';

/**
 * @typedef {Object} Props
 * @property {PanelProps<BoxPlotOptions>} PanelProps panel props extends with box plot options
 */
interface Props extends PanelProps<BoxPlotOptions> {}

/**
 * box plot panel to render waterfall chart with query and time-series
 * @function
 * @param {BoxPlotProps} Props
 * @returns {JSX.Element}
 *
 * @mermaid
 * graph TD;
 *    A(Select Plugin) --> B[Visualization];
 *    B --> |Select Waterfall Chart Type, Default|C{Query};
 *    B --> |Select Waterfall Chart Type|D{Time Series};
 *    C --> E[Select Query];
 *    D --> E[Select Query];
 *    E --> E1[Display];
 *    E1 --> F[Show];
 *    F --> F1[Calculate];
 *    F --> F2[All Values];
 *    F1 --> |Choose a reducer Function| F11[Calculation];
 *    F2 --> |Select Limit| F21[Limit];
 *    E1 --> |Select Field| F3[Fields];
 *    E1 --> |Include time in display value| F4[Include Time Field];
 *    E1 --> |Select color for bar goes up| F5[Rise];
 *    E1 --> |Select color for bar goes down| F6[Fall];
 *    E1 --> |Works in Query Type Waterfall| F7[Rename Total];
 *    E1 --> |To either display final output or not| F8[Display Final Output];
 *    E --> |Custom Text Size| E2[Text Size];
 *    E --> E3[Tooltip & Legend];
 *    E --> E4[Standard Options];
 *    E2 --> |Custom Size| E21[Title];
 *    E2 --> |Custom Size| E22[Value];
 */
export const BoxPlotPanel: React.FunctionComponent<Props> = ({
  data,
  options,
  width,
  height,
  fieldConfig,
  onFieldConfigChange,
  replaceVariables,
  timeZone,
  timeRange,
  onOptionsChange,
}) => {
  const theme = useTheme();
  const onLegendClick = useCallback(
    (event: GraphNGLegendEvent) => {
      onFieldConfigChange(hideSeriesConfigFactory(event, fieldConfig, data.series));
    },
    [fieldConfig, onFieldConfigChange, data.series]
  );

  const onSeriesColorChange = useCallback(
    (label: string, color: string) => {
      onFieldConfigChange(changeSeriesColorConfigFactory(label, color, fieldConfig));
    },
    [fieldConfig, onFieldConfigChange]
  );
  /**
   * * join all frames with time
   * * another advantage is buffer will come in general database
   */
  const aligned = outerJoinDataFramesBox({ frames: data?.series });

  const getValues = (): FieldDisplay[] => {
    return getFieldDisplayValues({
      fieldConfig,
      // @ts-ignore
      reduceOptions: options.reduceOptions,
      replaceVariables,
      theme: theme,
      data: data.series,
      timeZone,
    });
  };

  const getData = getValues();

  const timeRangeMs = timeRange.to.valueOf() - timeRange.from.valueOf();

  /**
   * If not any query applied or any query applied but not any data found in response.
   */
  if (!aligned?.length) {
    return (
      <div className="panel-empty">
        <p>{'No data found in response'}</p>
      </div>
    );
  }

  const frames = prepareGraphableFields([aligned], theme);

  const dataMain: BoxPlotData = {
    frames: [],
    waterfallQuery: {},
    waterfall: {},
  };

  const waterfallFrames = cloneDeep(frames);

  const insert = (arr: any, index: number, ...newItems: any) => [
    // @ts-ignore
    ...arr.slice(0, index),
    ...newItems,
    ...arr.slice(index),
  ];
  let queryIndex: any = [];

  /**
   * calculateWaterfallDataQuery prepares the structure of the data that is required
   * to plot waterfall chart and returned response is assigned to variable.
   */
  const waterfallDataQuery = calculateWaterfallDataQuery(
    waterfallFrames!,
    getData,
    options.total,
    options.totalName,
    options.includeTimeField,
    options.endyearSwitch,
    options.addyear
  );

  /**
   * calculateWaterfallData prepares the structure of the data that is required
   * to plot waterfall chart and returned response is assigned to variable.
   */
  const waterfallDataWithTime = calculateWaterfallData(waterfallFrames!, timeRangeMs, getData, options.total);

  /**
   * on the basis of waterfall chart type selected by the user, data prepared for to draw chart
   * is assigned to the dataMain variable.
   */
  if (options.typeWaterfallPlot === 'query') {
    dataMain.waterfall = waterfallDataQuery;
  } else {
    dataMain.waterfall = waterfallDataWithTime;
  }
  const waterfallData: any = options.typeWaterfallPlot === 'query' ? waterfallDataQuery : waterfallDataWithTime;

  if (frames![0].fields.length >= 2) {
    // single query candle making
    const firstFrame = waterfallFrames![0].fields[1];
    const firstFrameO = cloneDeep(firstFrame);
    const firstFrameH = cloneDeep(firstFrame);
    const firstFrameL = cloneDeep(firstFrame);
    const firstFrameC = cloneDeep(firstFrame);
    waterfallFrames![0].fields.splice(1);
    const result = insert(waterfallFrames![0].fields, 1, firstFrameO, firstFrameH, firstFrameL, firstFrameC);
    waterfallFrames![0].fields = result;

    const timeObj: any = waterfallFrames![0].fields[0].values;
    timeObj.buffer = [];
    timeObj.buffer = waterfallData.time.values;

    const openObj: any = waterfallFrames![0].fields[1].values;
    openObj.buffer = [];
    openObj.buffer = waterfallData.open.values;

    const highObj: any = waterfallFrames![0].fields[2].values;
    highObj.buffer = [];
    highObj.buffer = waterfallData.high.values;

    const lowObj: any = waterfallFrames![0].fields[3].values;
    lowObj.buffer = [];
    lowObj.buffer = waterfallData.low.values;

    const closeObj: any = waterfallFrames![0].fields[4].values;
    closeObj.buffer = [];
    closeObj.buffer = waterfallData.close.values;
  }

  if (dataMain.frames.length > 0) {
    dataMain.frames[0].length = waterfallData.time.values.length;
  }

  dataMain.frames! = waterfallFrames!;
  return (
    <WaterfallPlot
      dataMain={dataMain}
      timeRange={timeRange}
      width={width}
      height={height}
      onLegendClick={onLegendClick}
      onSeriesColorChange={onSeriesColorChange}
      chartType={options.typeWaterfallPlot}
      queryIndex={queryIndex}
      {...options}
      tooltipMode={options.tooltip.mode}
      endyearSwitch={options.endyearSwitch}
    />
  );
};
