import {
  DataFrame,
  FieldType,
  GrafanaTheme,
  Field,
  ArrayVector,
  getDisplayProcessor,
  isBooleanUnit,
  systemDateFormats,
  dateTimeFormat,
} from '@grafana/data';
import { getTimeZone } from '@grafana/data/src/datetime/common';
import { GraphNGLegendEventMode } from './types';

export function mapMouseEventToMode(event: React.MouseEvent): GraphNGLegendEventMode {
  if (event.ctrlKey || event.metaKey || event.shiftKey) {
    return GraphNGLegendEventMode.AppendToSelection;
  }
  return GraphNGLegendEventMode.ToggleSelection;
}
/**
 * function arrange the DataFrame array in number and time
 * @function
 * @param {Array<DataFrame>} series
 * @param {GrafanaTheme} theme
 * @return {Array<DataFrame> | null} data frame array or null value
 */

export function prepareGraphableFields(series: DataFrame[], theme: GrafanaTheme): DataFrame[] | null {
  if (!series?.length) {
    return null;
  }
  let copy: Field;

  const frames: DataFrame[] = [];

  for (let frame of series) {
    const fields: Field[] = [];

    let hasTimeField = false;
    let hasValueField = false;

    for (const field of frame.fields) {
      switch (field.type) {
        case FieldType.time:
          hasTimeField = true;
          fields.push(field);
          break;
        case FieldType.number:
          hasValueField = true;
          copy = {
            ...field,
            values: new ArrayVector(
              field.values.toArray().map((v) => {
                if (!(Number.isFinite(v) || v === null)) {
                  return null;
                }
                return v;
              })
            ),
          };
          fields.push(copy);
          break; // ok
        case FieldType.boolean:
          hasValueField = true;
          const custom: any = field.config?.custom ?? {};
          const config = {
            ...field.config,
            max: 1,
            min: 0,
            custom,
          };

          copy = {
            ...field,
            config,
            type: FieldType.number,
            values: new ArrayVector(
              field.values.toArray().map((v) => {
                if (v == null) {
                  return v;
                }
                return Boolean(v) ? 1 : 0;
              })
            ),
          };

          if (!isBooleanUnit(config.unit)) {
            config.unit = 'bool';
            copy.display = getDisplayProcessor({ field: copy, theme });
          }

          fields.push(copy);
          break;
      }
    }
    if (hasTimeField && hasValueField) {
      frames.push({
        ...frame,
        fields,
      });
    }
  }
  if (frames.length) {
    return frames;
  }

  return null;
}

const timeUnitSize = {
  second: 1000,
  minute: 60 * 1000,
  hour: 60 * 60 * 1000,
  day: 24 * 60 * 60 * 1000,
  month: 28 * 24 * 60 * 60 * 1000,
  year: 365 * 24 * 60 * 60 * 1000,
};

/** Format time axis ticks */
export function formatTime(v: any, foundIncr: number) {
  const timeZone = getTimeZone();
  const yearRoundedToDay = Math.round(timeUnitSize.year / timeUnitSize.day) * timeUnitSize.day;
  const incrementRoundedToDay = Math.round(foundIncr / timeUnitSize.day) * timeUnitSize.day;

  let format = systemDateFormats.interval.minute;
  if (foundIncr < timeUnitSize.second) {
    format = systemDateFormats.interval.second.replace('ss', 'ss.SS');
  } else if (foundIncr <= timeUnitSize.minute) {
    format = systemDateFormats.interval.second;
  } else if (foundIncr <= timeUnitSize.hour) {
    format = systemDateFormats.interval.minute;
  } else if (foundIncr <= timeUnitSize.day) {
    format = systemDateFormats.interval.hour;
  } else if (foundIncr <= timeUnitSize.month) {
    format = systemDateFormats.interval.day;
  } else if (incrementRoundedToDay === yearRoundedToDay) {
    format = systemDateFormats.interval.year;
  } else if (foundIncr <= timeUnitSize.year) {
    format = systemDateFormats.interval.month;
  }
  // eslint-disable-next-line radix
  let x = parseInt(v);
  return dateTimeFormat(x, { format, timeZone });
}
