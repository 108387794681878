import VectorSource from 'ol/source/Vector';

/**
 * marker setting
 * @typedef {Object} MarkerSetting
 * @property {number} minMarkerSize minimum marker size
 * @property {number} maxMarkerSize maximum marker size
 * @property {number} strokeWeight marker stroke weight
 * @property {number} fillOpacity opacity of marker
 * @property {boolean} fillColor select color filled in marker
 */
export interface MarkerSetting {
  minMarkerSize: number;
  maxMarkerSize: number;
  strokeWeight: number;
  fillOpacity: number;
  fillColor: boolean;
}
/**
 * heat map required controls
 * @typedef {Object} HeatMap
 * @property {boolean} enabled enable of disable heat map
 * @property {number} radius radius of heat map
 * @property {number} opacity opacity of heat map
 * @property {boolean} inWeight should heatmap include value of data
 * @property {number} color color of heat map
 * @property {number} minRadiusSize minimum radius size
 * @property {number} maxRadiusSize maximum radius size
 */
export interface HeatMap {
  enabled: boolean;
  // radius: number;
  opacity: number;
  inWeight: boolean;
  color: number;
  minRadiusSize: number;
  maxRadiusSize: number;
  weightFactor: number;
  blurHeatmap: number;
}
/**
 * parameters for geojson layer
 * @typedef {Object} GeoJson
 * @property {boolean} isGeoJsonLayer is user selected geojson layer
 * @property {string} fillColor filling color for polygon
 * @property {string} strokecolor stroke color of polygon
 * @property {number} strokeWeight stroke weight of icon
 * @property {number} strokeOpacity stroke opacity
 * @property {number} opacityIcon opacity of icon
 * @property {number} fillOpacity fill color opacity
 * @property {number} iconSymbol icon symbol
 * @property {string} fillcoloricon color of icon
 * @property {number} iconSize icon size
 * @property {string} strokecoloricon icon stroke color
 * @property {string} path geojson file path
 * @property {string} pathExt external file path
 * @property {string} iconSymbolOsm icon symbol for open layer
 * @property {boolean} useGooglePin only used in google geojson layer
 */
export interface GeoJson {
  isGeoJsonLayer: boolean;
  fillcolor: string;
  strokecolor: string;
  strokeWeight: number;
  strokeOpacity: number;
  opacityIcon: number;
  fillOpacity: number;
  iconSymbol: number;
  iconSize: number;
  fillcoloricon: string;
  strokecoloricon: string;
  path: string;
  pathExt: string;
  iconSymbolOsm: string;
  source: VectorSource;
  styleRule: any; // only used in google geojson layer
}
/**
 * query field for marker layer
 * @typedef {Object} QueryField
 * @property {string} location name of the query
 * @property {string} geohash geohash value
 * @property {string} value metric value
 */
export interface QueryField {
  location: string;
  geohash: string;
  value: string;
}
/**
 * marker dashboard visit
 * @typedef {Object} MarkerLinkI
 * @property {string} location name of the marker/query
 * @property {string} url dashboard url
 */
export interface MarkerLinkI {
  location: string;
  url: string;
}

/**
 * custom marker configurations
 * @property {Array<Onemarker>} markers all markers config
 * @property {number} currIdx current index of selection
 * @property {boolean} enabled custom markers enabled or not
 */
export interface CustomMarkerI {
  markers: Onemarker[];
  currIdx: number;
  enabled: boolean;
}
/**
 * single custom marker configuration
 * @typedef {Object} Onemarker
 * @property {number} lat latitude
 * @property {number} lng logitude
 * @property {Array<any>} query
 * @property {string} url dashboard url
 * @property {string} name name of the marker
 */
export interface Onemarker {
  lat: number;
  lng: number;
  query: any[];
  url: string;
  name: string;
}

/**
 * custom symbol for marker
 * @typedef {Object} SymbolObj
 * @property {string} sourceType Source to be used, unicon or marker or Url
 * @property {string} iconName Icon name
 * @property {string} url Icon Url when source == Url
 */
export interface SymbolObj {
  sourceType: string;
  iconName: string;
  url: string;
  urlPath: string;
}

/**
 * map center
 * @typedef {Object} Center
 * @property {number} lat
 * @property {number} lng
 */
export interface Center {
  lat: number;
  lng: number;
}

/**
 * Enumerates the legend display modes.
 *
 * @enum {number}
 * @readonly
 */
export enum LegendMode {
  Both = 0,
  Heatmap = 1,
  Threshold = 2,
  None = 3,
}

/**
 * Enumerates types of map layers.
 *
 * @enum {string}
 * @readonly
 */
export enum MapLayerType {
  CUSTOMHEATMAP = 'customHeatmap',
  CUSTOMMARKER = 'customMarker',
  HEATMAP = 'heatmap',
  MARKER = 'marker',
  GEOJSON = 'geojson',
  DEFAULT = 'default',
  // tiles options
  ROADMAP = 'roadmap',
  HYBRID = 'hybrid',
  SATELLITE = 'satellite',
  TERRAIN = 'terrainOS',
  STAMENWATERCOLOR = 'watercolor',
  STAMENTERRAIN = 'terrain',
  STAMENTONER = 'toner',
  CARTO = 'carto',
  STANDARD = 'standerOS',
}

/**
 * Represents a mapping of string keys to number values.
 *
 * @interface LevelType
 *
 * @property {number} [name:string] - A string key mapped to a number value.
 */
interface LevelType {
  [name: string]: number;
}

/**
 * Represents a mapping of zoom levels to corresponding radius values in kilometers.
 *
 * @const {LevelType}
 * @property {number} '7' - Radius value for zoom level 7 in kilometers.
 * @property {number} '8' - Radius value for zoom level 8 in kilometers.
 * @property {number} '9' - Radius value for zoom level 9 in kilometers.
 * @property {number} '10' - Radius value for zoom level 10 in kilometers.
 * @property {number} '11' - Radius value for zoom level 11 in kilometers.
 * @property {number} '12' - Radius value for zoom level 12 in kilometers.
 * @property {number} '13' - Radius value for zoom level 13 in kilometers.
 * @property {number} '14' - Radius value for zoom level 14 in kilometers.
 * @property {number} '15' - Radius value for zoom level 15 in kilometers.
 * @property {number} '16' - Radius value for zoom level 16 in kilometers.
 */
export const checkRadiusLevels: LevelType = {
  '7': 25,
  '8': 10,
  '9': 5,
  '10': 2,
  '11': 1,
  '12': 0.5,
  '13': 0.25,
  '14': 0.125,
  '15': 0.0625,
  '16': 0.03125,
};

/**
 * Enumerates aggregation methods for heatmap data.
 *
 * @enum {string}
 * @readonly
 */
export enum AggregationForHeat {
  SUM = 'sum',
  MIN = 'min',
  MAX = 'max',
  MEAN = 'mean',
  NONE = 'none',
}

/**
 * Enumerates query modes for geographical data.
 *
 * @enum {string}
 * @readonly
 */
export enum QueryModes {
  Auto = 'auto',
  Geohash = 'geohash',
  Coords = 'coords',
  Choro = 'choro',
}
