import React, { useEffect, useState } from 'react';
import { css } from 'emotion';
import { Modal, ConfirmModal, HorizontalGroup, Button } from '@grafana/ui';
import { DashboardModel } from '../../state';
import { useDashboardDelete } from './useDashboardDelete';
import { getAlertRuleList, getCarbonPullData, getCarbonPushData } from '../../utils/utils';

type DeleteDashboardModalProps = {
  hideModal(): void;
  dashboard: DashboardModel;
};

export const DeleteDashboardModal: React.FC<DeleteDashboardModalProps> = ({ hideModal, dashboard }) => {
  const isProvisioned = dashboard.meta.provisioned;
  const { onRestoreDashboard } = useDashboardDelete(dashboard);
  const modalBody = GetModalBody(dashboard);

  if (isProvisioned) {
    return <ProvisionedDeleteModal hideModal={hideModal} provisionedId={dashboard.meta.provisionedExternalId!} />;
  }

  return (
    <ConfirmModal
      isOpen={true}
      body={modalBody}
      onConfirm={onRestoreDashboard}
      onDismiss={hideModal}
      title="Delete"
      icon="trash"
      confirmText="Delete"
    />
  );
};

function GetModalBody(dashboard: DashboardModel) {
  const [data, setData] = useState<any>({});
  useEffect(() => {
    async function fetchData() {
      var text;
      var pushDataLength = 0;
      var pullDataLength = 0;
      var alertsLength = 0;
      for (let i = 0; i < dashboard.panels.length; i++) {
        const panel = dashboard.panels[i];
        var pushData = await getCarbonPushData(panel.id, dashboard.id);
        var pullData = await getCarbonPullData(panel.id, dashboard.id);
        var alerts = await getAlertRuleList(panel, dashboard);
        pushDataLength =
          pushData !== null && pushData.length !== 0 ? pushDataLength + pushData.length : pushDataLength + 0;
        pullDataLength =
          pullData !== null && pullData.length !== 0 ? pullDataLength + pullData.length : pullDataLength + 0;
        alertsLength = alerts !== null && alerts.length !== 0 ? alertsLength + alerts.length : alertsLength + 0;
      }
      if (pushDataLength === 0 && pullDataLength === 0 && alertsLength !== 0) {
        text = 'There are some active Alerts associated with this dashboard.';
      } else if ((pushDataLength !== 0 || pullDataLength !== 0) && alertsLength === 0) {
        text = 'There are some active Exports associated with this dashboard.';
      } else {
        text = 'There are some active Alerts & Exports associated with this dashboard.';
      }
      setData({ pushData: pushDataLength, pullData: pullDataLength, alerts: alertsLength, text: text });
    }
    fetchData();
  }, []);
  return data.pushData > 0 || data.pullData > 0 || data.alerts > 0 ? (
    <>
      <p>Do you want to delete this dashboard?</p>
      <small>{data.text}</small>
    </>
  ) : (
    <>
      <p>Do you want to delete this dashboard?</p>
      <p>{dashboard.title}</p>
    </>
  );
}

const ProvisionedDeleteModal = ({ hideModal, provisionedId }: { hideModal(): void; provisionedId: string }) => (
  <Modal
    isOpen={true}
    title="Cannot delete provisioned dashboard"
    icon="trash"
    onDismiss={hideModal}
    className={css`
      text-align: center;
      width: 500px;
    `}
  >
    <p>
      This dashboard is managed by Grafanas provisioning and cannot be deleted. Remove the dashboard from the config
      file to delete it.
    </p>
    <p>
      <i>
        See{' '}
        <a
          className="external-link"
          href="http://docs.grafana.org/administration/provisioning/#dashboards"
          target="_blank"
          rel="noreferrer"
        >
          documentation
        </a>{' '}
        for more information about provisioning.
      </i>
      <br />
      File path: {provisionedId}
    </p>
    <HorizontalGroup justify="center">
      <Button variant="secondary" onClick={hideModal}>
        OK
      </Button>
    </HorizontalGroup>
  </Modal>
);
