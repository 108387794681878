import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const Shield: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M17.8,3.4l-5.5-1.8c-0.2-0.1-0.4-0.1-0.6,0L6.2,3.4c-1.8,0.6-3,2.3-3,4.2V12c0,6.6,8,10.3,8.4,10.4
        c0.2,0.1,0.5,0.1,0.7,0c0.3-0.2,8.4-3.8,8.4-10.4V7.5C20.8,5.6,19.5,4,17.8,3.4z M19,12c0,4.8-5.5,7.9-7,8.7c-1.5-0.7-7-3.9-7-8.7
        V7.5C5,6.4,5.7,5.4,6.8,5L12,3.3L17.2,5C18.3,5.4,19,6.4,19,7.5V12z"
      />
    </svg>
  );
};
