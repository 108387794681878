import React, { useState, useEffect } from 'react';

import { PanelProps, FieldDisplay, getFieldDisplayValues } from '@grafana/data';
import { config } from 'app/core/config';
import { MatterportOptions } from './types';
import { Main } from './components/Main';
import { AppState, items, slots } from './AppState';

const appState = new AppState();
appState.items = items;
appState.slots = slots;

interface Props extends PanelProps<MatterportOptions> {}

export function Matterport(props: Props) {
  const { width, height, options, data } = props;
  const [bigData, setBigData]: any = useState();
  useEffect(() => {
    const getValues = (): FieldDisplay[] => {
      const { data, options, replaceVariables, fieldConfig, timeZone } = props;
      return getFieldDisplayValues({
        fieldConfig,
        reduceOptions: options.reduceOptions,
        replaceVariables,
        theme: config.theme,
        data: data.series,
        timeZone,
      });
    };
    const bigData: any = getValues();
    if (bigData.length > 0) {
      options.sceneList.queries = [];
      for (let i = 0; i < bigData.length; i++) {
        options.sceneList.queries.push({
          value: bigData[i].display.title,
          label: bigData[i].display.title,
        });
      }
      options.sceneList.queries.push({
        value: 'none',
        label: 'None',
      });
    }
  }, []);
  useEffect(() => {
    const getValues = (): FieldDisplay[] => {
      const { data, options, replaceVariables, fieldConfig, timeZone } = props;
      return getFieldDisplayValues({
        fieldConfig,
        reduceOptions: options.reduceOptions,
        replaceVariables,
        theme: config.theme,
        data: data.series,
        timeZone,
      });
    };
    // const bigData = getValues();
    setBigData(getValues());
  }, [data]);
  const updateCord = (cameraLocation: any) => {
    const oldScenes = options.sceneList;
    oldScenes.currLoc = cameraLocation;
    const oldTags = options.tagList;
    oldTags.currLoc = cameraLocation;
    props.onOptionsChange({ ...props.options, sceneList: oldScenes, tagList: oldTags });
  };
  return (
    <div style={{ position: 'relative', width, height }} className="matterport">
      <Main
        key={options.reloader}
        appState={appState}
        sceneList={options.sceneList}
        tagList={options.tagList}
        bigData={bigData}
        updateCord={updateCord}
        lock={options.lock}
        sid={options.sid}
        cameraP={options.cameraP}
        cameraX={options.cameraX}
        cameraY={options.cameraY}
        // newScene={options.newScene}
        // url={options.url}
        // x={options.x}
        // y={options.y}
        // z={options.z}
      />
    </div>
  );
}
