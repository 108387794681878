/**
 * Rows Options
 */
export const EveryRowOptions = [
  { value: true, label: 'Every row' },
  { value: false, label: 'All rows' },
];

export const RenderSeriesPicker = [
  { value: true, label: 'Enable' },
  { value: false, label: 'Disable' },
];
