import tinycolor from 'tinycolor2';

export const createPalette = (selectedPalette: string, numOfShades: number) => {
  const shades = [];
  const delta = 1.8 / numOfShades;
  let lum = -0.9;
  for (let i = 0; i < numOfShades; i++) {
    if (lum < 0.1) {
      shades.push(increase_darkness(selectedPalette, lum));
    } else {
      shades.push(increase_brightness(selectedPalette, lum));
    }
    lum += delta;
  }
  return shades;
};

export const checkValidColors = (colors: string[]) => {
  const shades: string[] = [];
  colors.forEach((color: string) => {
    const tcolor = tinycolor(color);
    if (tcolor.isValid() && tcolor.getFormat() !== 'name') {
      shades.push(color);
    }
  });
  return shades;
};

// export const changePaletteMultiple = (selectedPalette: string[], numOfShades: number) => {
//   const mainShades = [];
//   // const numOfShades = 10;
//   // const selectedPalette = ['#FF0011','#CC0011'];
//   for (let colorIndex = 0; colorIndex < selectedPalette.length; colorIndex++) {
//     const shades = [];
//     const delta = 1.8 / numOfShades;
//     let lum = -0.9;
//     for (let i = 0; i < numOfShades; i++) {
//       if (lum < 0.1) {
//         shades.push(increase_darkness(selectedPalette[colorIndex], lum));
//       } else {
//         shades.push(increase_brightness(selectedPalette[colorIndex], lum));
//       }
//       lum += delta;
//     }
//     mainShades.push(shades);
//   }
//   return mainShades;
// };

var increase_darkness = (hex: string | any[], lum: number) => {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  var rgb = '#',
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }
  return rgb;
};

var increase_brightness = (hex: string, lum: number) => {
  // strip the leading # if it's there
  hex = hex.replace(/^\s*#|\s*$/g, '');

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (hex.length === 3) {
    hex = hex.replace(/(.)/g, '$1$1');
  }

  var r = parseInt(hex.substr(0, 2), 16),
    g = parseInt(hex.substr(2, 2), 16),
    b = parseInt(hex.substr(4, 2), 16);
  return (
    '#' +
    pad((0 | ((1 << 8) + r + (256 - r) * lum)).toString(16).substr(1)) +
    pad((0 | ((1 << 8) + g + (256 - g) * lum)).toString(16).substr(1)) +
    pad((0 | ((1 << 8) + b + (256 - b) * lum)).toString(16).substr(1))
  );
};

var pad = (n: string | number) => {
  return n < 10 ? '0' + n : n;
};
