import React, { memo } from 'react';
import { DataSourceApi, QueryEditorProps } from '@grafana/data';
import { InlineField, Select } from '@grafana/ui';
import { Resample } from './components/Resample';
import { Reduce } from './components/Reduce';
import { Math } from './components/Math';
import { Classic } from './components/Classic';
import { OperationType, gelTypes, ExpressionQuery, defaultValues } from './types';

type Props = QueryEditorProps<DataSourceApi<ExpressionQuery>, ExpressionQuery>;

const labelWidth = 14;
const ExpressionQueryEditor = (props: Props) => {
  const onSelectOperationType = (e: any) => {
    const { query, onChange } = props;

    onChange(defaultValues({ ...query, type: e.value! }));
  };

  //@ts-ignore
  function renderOperationType() {
    const { onChange, query, queries } = props;
    const refIds = queries!
      .filter((q: any) => query.refId !== q.refId)
      .map((q: any) => ({ value: q.refId, label: q.refId }));

    if (query.type === OperationType.math) {
      return <Math onChange={onChange} query={query} labelWidth={labelWidth} onRunQuery={props.onRunQuery} />;
    } else if (query.type === OperationType.reduce) {
      return (
        <Reduce
          refIds={refIds}
          onChange={onChange}
          onRunQuery={props.onRunQuery}
          labelWidth={labelWidth}
          query={query}
        />
      );
    } else if (query.type === OperationType.resample) {
      return (
        <Resample
          query={query}
          labelWidth={labelWidth}
          onChange={onChange}
          refIds={refIds}
          onRunQuery={props.onRunQuery}
        />
      );
    } else if (query.type === OperationType.classicCondition) {
      return <Classic onChange={onChange} query={query} refIds={refIds} onRunQuery={props.onRunQuery} />;
    }
  }

  const { query } = props;
  const selected = gelTypes.find((o: any) => o.value === query.type);

  return (
    <div>
      <InlineField label="Operation" labelWidth={labelWidth}>
        <Select options={gelTypes} value={selected} onChange={onSelectOperationType} width={25} />
      </InlineField>
      {renderOperationType()}
    </div>
  );
};

export default memo(ExpressionQueryEditor);
