import { SingleStatBaseOptions } from '@grafana/ui';
import { ReducerID, standardEditorsRegistry, PanelOptionsEditorBuilder } from '@grafana/data';

// Structure copied from angular
export interface TreeMapPanelOptions extends SingleStatBaseOptions, OptionsWithTooltip {
  childcolor: boolean;
  dataLabels: string;
  dataLabelvalue: string;
  showValue: any;
  toTileMethod: string;
  fillopacity: number;
  spaceBwTile: number;
  colorMode: string;
}
export interface OptionsWithTooltip {
  tooltip: VizTooltipOptions;
}
export type VizTooltipOptions = {
  mode: TooltipDisplayMode;
};
export enum TooltipDisplayMode {
  Single = 'single',
  Multi = 'multi',
  None = 'none',
}
export function addStandardDataReduceOptions(
  builder: PanelOptionsEditorBuilder<SingleStatBaseOptions>,
  includeOrientation = true
) {
  builder.addCustomEditor({
    id: 'reduceOptions.calcs',
    path: 'reduceOptions.calcs',
    name: 'Value',
    description: 'Choose a reducer function / calculation',
    editor: standardEditorsRegistry.get('stats-picker').editor as any,
    defaultValue: [ReducerID.mean],
    // Hides it when all values mode is on
    // showIf: currentConfig => currentConfig.reduceOptions.values === false,
  });
}

export enum TileMethod {
  treemapSquarify = 'treemapSquarify',
  treemapBinary = 'treemapBinary',
  treemapDice = 'treemapDice',
  treemapResquarify = 'treemapResquarify',
  treemapSlice = 'treemapSlice',
  treemapSliceDice = 'treemapSliceDice',
}

export interface TreeStru {
  id: string;
  parent: string | null;
  size: number | null;
  color: string;
  tooltip: string;
}
