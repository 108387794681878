import { FieldColorModeId, FieldConfigProperty, PanelPlugin, LegendDisplayMode, VisibilityMode } from '@grafana/data';
import { TimelinePanel } from './TimelinePanel';
import {
  TimelineOptions,
  TimelineFieldConfig,
  defaultPanelOptions,
  defaultTimelineFieldConfig,
  ChartTypeConfig,
} from './types';
import { StateHandler } from './StateMigrations';

export const plugin = new PanelPlugin<TimelineOptions, TimelineFieldConfig>(TimelinePanel)
  .setPanelChangeHandler(StateHandler)
  .useFieldConfig({
    standardOptions: {
      [FieldConfigProperty.Color]: {
        settings: {
          byValueSupport: true,
        },
        defaultValue: {
          mode: FieldColorModeId.Thresholds,
        },
      },
    },
    useCustomConfig: (builder) => {
      builder
        .addSliderInput({
          path: 'lineWidth',
          name: 'Line width',
          defaultValue: defaultTimelineFieldConfig.lineWidth,
          settings: {
            min: 0,
            max: 10,
            step: 1,
          },
        })
        .addSliderInput({
          path: 'fillOpacity',
          name: 'Fill opacity',
          defaultValue: defaultTimelineFieldConfig.fillOpacity,
          settings: {
            min: 0,
            max: 100,
            step: 1,
          },
        });
    },
  })
  .setPanelOptions((builder) => {
    builder
      .addSelect({
        path: 'chartType',
        name: 'Chart Type',
        description: 'Select between timeline and status history',
        defaultValue: ChartTypeConfig.Timeline,
        settings: {
          options: [
            { value: ChartTypeConfig.Timeline, label: 'Timeline' },
            { value: ChartTypeConfig.Status, label: 'Status History' },
          ],
        },
      })
      .addRadio({
        path: 'showValue',
        name: 'Show values',
        settings: {
          options: [
            { value: VisibilityMode.Auto, label: 'Auto' },
            { value: VisibilityMode.Always, label: 'Always' },
            { value: VisibilityMode.Never, label: 'Never' },
          ],
        },
        defaultValue: defaultPanelOptions.showValue,
      })
      .addRadio({
        path: 'alignValue',
        name: 'Align values',
        settings: {
          options: [
            { value: 'left', label: 'Left' },
            { value: 'center', label: 'Center' },
            { value: 'right', label: 'Right' },
          ],
        },
        defaultValue: defaultPanelOptions.alignValue,
        showIf: (c) => c.chartType === ChartTypeConfig.Timeline,
      })
      .addSliderInput({
        path: 'rowHeight',
        name: 'Row height',
        settings: {
          min: 0,
          max: 1,
          step: 0.01,
        },
        defaultValue: defaultPanelOptions.rowHeight,
      })
      // TODO: Used for status history only
      .addSliderInput({
        path: 'colWidth',
        name: 'Column width',
        defaultValue: 0.9,
        settings: {
          min: 0,
          max: 1,
          step: 0.01,
        },
        showIf: (c) => c.chartType === ChartTypeConfig.Status,
      })
      .addRadio({
        path: 'yAxisShowHide',
        name: 'Y-Axis',
        description: 'Y-Axis hide/unhide.',
        defaultValue: false,
        settings: {
          options: [
            { value: true, label: 'Hide' },
            { value: false, label: 'UnHide' },
          ],
        },
      })
      .addRadio({
        path: 'legend.displayMode',
        name: 'Legend mode',
        category: ['Legend'],
        description: '',
        defaultValue: LegendDisplayMode.List,
        settings: {
          options: [
            { value: LegendDisplayMode.List, label: 'List' },
            { value: LegendDisplayMode.Table, label: 'Table' },
            { value: LegendDisplayMode.Hidden, label: 'Hidden' },
          ],
        },
      })
      .addRadio({
        path: 'legend.placement',
        name: 'Legend placement',
        category: ['Legend'],
        description: '',
        defaultValue: 'bottom',
        settings: {
          options: [
            { value: 'bottom', label: 'Bottom' },
            { value: 'right', label: 'Right' },
          ],
        },
        showIf: (c) => c.legend.displayMode !== LegendDisplayMode.Hidden,
      });
  });
