import { Circle, Fill, Icon, Stroke, Style } from 'ol/style';
import { SymbolObj } from '../../types/interface';
import { getPreDefinedIcon } from '../../utils/icon';
import { getFill, getStroke } from '../geojsonLayer/general';
import { Point } from 'ol/geom';
import Feature from 'ol/Feature';
import Geohash from 'latlon-geohash';

/**
 * Creates a styled marker feature with the specified attributes.
 *
 * @function
 * @param {Feature<Point>} markerFeature - The marker feature to style.
 * @param {number | undefined} opacity - The opacity of the marker.
 * @param {string | undefined} color - The color of the marker.
 * @param {number} size - The size of the marker.
 * @param {string | undefined} symbol - The symbol representing the marker.
 * @param {SymbolObj | undefined} customSymbol - The custom symbol object for the marker.
 * @param {number} rotation - The rotation angle of the marker.
 * @returns {Feature<Point>} - The styled marker feature.
 */
export function createStyledMarker(
  markerFeature: Feature<Point>,
  opacity: number | undefined,
  color: string | undefined,
  size: number,
  symbol: string | undefined,
  customSymbol: SymbolObj | undefined,
  rotation: number
): Feature<Point> {
  const fill = getFill(color || 'dark-red', opacity);
  const stroke = getStroke(color || 'dark-red', 1, 1);

  markerFeature.setStyle(iconFinderForMap(symbol || 'circle', customSymbol, fill, stroke, size, color, rotation));

  return markerFeature;
}

/**
 * Finds and returns the appropriate style for a map icon based on the selected icon type and options.
 *
 * @function
 * @param {string} selectedIcon - The selected icon type.
 * @param {SymbolObj | undefined} marker - The custom marker object, if applicable.
 * @param {Fill} fill - The fill style for the icon.
 * @param {Stroke} stroke - The stroke style for the icon.
 * @param {number} size - The size of the icon.
 * @param {string} [customMarkerColor] - The custom marker color, if applicable.
 * @param {number} [rotation] - The rotation angle for the icon.
 * @returns {Style} - The style object for rendering the icon on the map.
 */
export function iconFinderForMap(
  selectedIcon: string,
  marker: SymbolObj | undefined,
  fill: Fill,
  stroke: Stroke,
  size: number,
  customMarkerColor?: string,
  rotation?: number
) {
  if (marker) {
    const { url } = marker;
    /**
     * if custom marker not select
     */
    if (selectedIcon !== 'custom') {
      const exists = getPreDefinedIcon(selectedIcon, fill, stroke, size, rotation);

      if (exists) {
        return new Style({
          image: exists,
        });
      }

      // Do not block code rendering. Return at least the default one
      return new Style({
        image: new Circle({
          fill,
          stroke,
          radius: size,
        }),
      });
    }

    // Others are URL based
    if (url.length !== 0) {
      return new Style({
        image: new Icon({
          anchor: [0.5, 1],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          src: url,
          color: marker.sourceType === 'url' ? undefined : customMarkerColor,
          rotation: rotation || 0,
          scale: size / 10,
          // size: [size, size],
        }),
      });
    }
    return new Style({
      image: new Circle({
        fill,
        stroke,
        radius: size,
      }),
    });
  } else {
    return new Style({
      image: new Circle({
        fill,
        stroke,
        radius: size,
      }),
    });
  }
}

/**
 * Decodes a Geohash string and returns the corresponding latitude and longitude coordinates.
 *
 * @function
 * @param {string} hash - The Geohash string to decode.
 * @returns {{ lat: number, lng: number }} - An object containing the latitude (lat) and longitude (lng) coordinates.
 */
export function getLatLng(hash: string) {
  const latlon = Geohash.decode(hash);
  return latlon ? { lat: latlon.lat, lng: latlon.lon } : { lat: 0, lng: 0 };
}

/**
 * Fetches an SVG image from a URL, modifies its stroke color, and returns the modified SVG as a data URI.
 *
 * @async
 * @function
 * @param {string} url - The URL of the SVG image.
 * @returns {Promise<string>} - A Promise that resolves to the modified SVG as a data URI, or an empty string if an error occurs.
 * @throws {Error} - If an error occurs during the fetch or SVG modification process.
 */
export async function prepareSVGImageForColor(url: string): Promise<string> {
  try {
    // Fetch SVG content
    const res = await fetch(url, { method: 'GET' });
    const text = await res.text();

    // Parse SVG content
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'image/svg+xml');
    const svg = doc.getElementsByTagName('svg')[0];

    if (!svg) {
      return '';
    }

    // Modify and serialize SVG
    svg.setAttribute('stroke', '#ff1');
    const serializer = new XMLSerializer();
    const modifiedSVGString = serializer.serializeToString(doc);

    // Encode SVG string
    const svgURI = encodeURIComponent(modifiedSVGString);

    return `data:image/svg+xml,${svgURI}`;
  } catch (error) {
    return '';
  }
}

/**
 * Generates a style for a marker feature on a map, with options for opacity, color, size, symbol, custom symbol,
 * and rotation.
 *
 * @function
 * @param {number | undefined} opacity - The opacity of the marker.
 * @param {string | undefined} color - The color of the marker.
 * @param {number} size - The size of the marker.
 * @param {string | undefined} symbol - The symbol used for the marker.
 * @param {SymbolObj | undefined} customSymbol - A custom symbol object for the marker.
 * @param {number} rotation - The rotation angle of the marker.
 * @returns {Style} - The generated style for the marker feature.
 */
export function getMarkerStyle(
  opacity: number | undefined,
  color: string | undefined,
  size: number,
  symbol: string | undefined,
  customSymbol: SymbolObj | undefined,
  rotation: number
): Style {
  const fill = getFill(color || 'dark-red', opacity);
  const stroke = getStroke(color || 'dark-red', 1, 1);

  const style = iconFinderForMap(symbol || 'circle', customSymbol, fill, stroke, size, color, rotation);

  return style;
}
