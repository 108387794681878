import {
  DataFrame,
  FieldConfigSource,
  FieldDisplay,
  getFieldDisplayValues,
  GrafanaTheme,
  InterpolateFunction,
  ReduceDataOptions,
} from '@grafana/data';

/**
 * general function to calculate display values
 * @param {FieldConfigSource} fieldConfig
 * @param {ReduceDataOptions} reduceOptions
 * @param {InterpolateFunction} replaceVariables
 * @param {GrafanaTheme} theme
 * @param {Array<DataFrame>} frames
 * @param {string} timeZone
 * @returns {Array<FieldDisplay>}
 */
export function generalBigData(
  fieldConfig: FieldConfigSource,
  reduceOptions: ReduceDataOptions,
  replaceVariables: InterpolateFunction,
  theme: GrafanaTheme,
  frames: DataFrame[],
  timeZone: string
) {
  const getValues = (): FieldDisplay[] => {
    return getFieldDisplayValues({
      fieldConfig,
      reduceOptions: reduceOptions,
      replaceVariables,
      theme: theme,
      data: frames,
      sparkline: false,
      timeZone: timeZone,
    });
  };
  return getValues();
}
