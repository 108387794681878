import { FieldColorModeId, PanelData, Threshold, getFieldColorModeForField } from '@grafana/data';
import Geohash from 'latlon-geohash';
import Map from 'ol/Map';
import { Point } from 'ol/geom';
import { Heatmap as HeatmapLayer } from 'ol/layer';
import OLVectorSource from 'ol/source/Vector';
import { OSMapLayerOptions, OSMapLayerRegistryItem } from '../../LayerEditor/types';
import { PanelConfig } from '../../types/types';
import { colorGradient, colorGradientArray } from '../../utils/heatcolor';
import { defaultCustomMarkerConfig } from '../markerLayer/default';
import {
  addFeatures,
  addWeights,
  generalBigData,
  getCustomMarkerValues,
  getField,
  getSortedUniqueArray,
  heatMapGradientMinMax,
} from './general';
import { CustomHeatMapConfig, GeohashField } from './type';
import { defaultHetmapStyleConfig } from './default';
import { getRGBColor } from '../geojsonLayer/general';
import { setLegendForHeatmap } from '../../Legend/legend';
import { LegendMode, MapLayerType } from '../../types/interface';

/**
 * Default configuration options for a custom heatmap visualization.
 *
 * @constant
 * @type {CustomHeatMapConfig}
 * @property {HeatmapStyleConfig} style - The default style configuration for the custom heatmap.
 * @property {CustomMarkerConfig} customMarkers - The default configuration for custom markers on the heatmap.
 */
const defaultOptions: CustomHeatMapConfig = {
  style: defaultHetmapStyleConfig,
  customMarkers: defaultCustomMarkerConfig,
};

/**
 * Map layer configuration for Custom heatmap.
 * @type {OSMapLayerRegistryItem<CustomHeatMapConfig>}
 */
export const customHeatmapLayer: OSMapLayerRegistryItem<CustomHeatMapConfig> = {
  id: MapLayerType.CUSTOMHEATMAP,
  name: 'Custom Heatmap',
  description: 'visualizes a heatmap of the data',
  isBaseMap: false,
  showLocation: true,
  defaultOptions,

  create: async (map: Map, options: OSMapLayerOptions<CustomHeatMapConfig>) => {
    const config = { ...defaultOptions, ...options.config };

    const vectorSource: OLVectorSource<Point> = new OLVectorSource();

    // Create a new Heatmap layer
    // Weight function takes a feature as attribute and returns a normalized weight value
    const vectorLayer = new HeatmapLayer({
      source: vectorSource,
      blur: config.style.blur,
      radius: config.style.radius,
      opacity: config.style.opacity,
      weight: (feature) => {
        return feature.get('weight');
      },
    });

    return {
      init: () => vectorLayer,
      update: (data: PanelData, panelConfig: PanelConfig) => {
        const {
          fieldConfig,
          reduceOptions,
          replaceVariables,
          theme,
          displayOptions,
          customControl,
          selected,
        } = panelConfig;
        if (data.series && fieldConfig && reduceOptions && replaceVariables && displayOptions) {
          //Getting FieldDisplay data for custom markers.
          const newData = generalBigData(fieldConfig, reduceOptions, replaceVariables, theme, data.series, 'browser');
          const dataFromCustomMarkerHeat = getCustomMarkerValues(newData, config.customMarkers);
          const { style } = config;
          // clear data of the layer
          vectorSource.clear();

          // setting up heatmap colors
          let heatmapColors: string[] = [];
          if (
            style.colorGradient === colorGradient.customPalette &&
            fieldConfig &&
            data.series &&
            data.series.length > 0
          ) {
            const colorMode = getFieldColorModeForField(data.series[0].fields[0]);
            heatmapColors = colorMode.colors?.map((color) => getRGBColor(color)) || colorGradientArray['defaultColor'];
          } else {
            heatmapColors = colorGradientArray[style.colorGradient];
          }
          vectorLayer.setGradient(heatmapColors);

          let stepsLength: Threshold[] = [];
          if (fieldConfig) {
            if (fieldConfig.defaults.color?.mode === FieldColorModeId.Thresholds) {
              stepsLength = fieldConfig.defaults.thresholds?.steps || [];
            }
          }
          let heatmapLegend = null;

          const fieldArray: GeohashField[] = [];

          if (dataFromCustomMarkerHeat) {
            dataFromCustomMarkerHeat.forEach((ele) => {
              const { title, numeric, lat, lng } = ele[0];
              const singleEle = getField(title || 'custom', numeric, Geohash.encode(lat, lng, 12), 0);
              fieldArray.push(singleEle);
            });
          }
          if (fieldArray) {
            const { heatmapSteps, legendMode, layers } = displayOptions;
            const GradientMinMax = heatMapGradientMinMax(fieldArray);
            heatmapLegend = setLegendForHeatmap(
              heatmapColors,
              GradientMinMax,
              heatmapSteps ?? 10,
              legendMode === LegendMode.Both,
              stepsLength.length,
              options.name,
              displayOptions.showInfo
            );
            try {
              addFeatures(vectorSource, fieldArray, options.name);

              //Add weight to map.
              if (style.weight) {
                const sortedUnique = getSortedUniqueArray(fieldArray.map((ele) => ele.value));
                addWeights(vectorSource, sortedUnique, 'value');
              }
              vectorLayer.setSource(vectorSource);
            } catch (error) {
              // clear data of the layer
              vectorSource.clear();
            }
            /**
             *
             * heatmap legend
             * Dynamically update the content of the control
             * checking which layer is selected
             */
            if (selected && selected - 1 === layers.findIndex((v) => v.name === options.name)) {
              if (heatmapLegend && (legendMode === LegendMode.Heatmap || legendMode === LegendMode.Both)) {
                customControl?.updateContent(heatmapLegend, legendMode === LegendMode.Both, stepsLength.length);
              } else {
                customControl?.updateContent(null, legendMode === LegendMode.Both, stepsLength.length);
              }
            }
          }
        }
      },
    };
  },
};
