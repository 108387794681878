import { ColorPicker, Field, InlineField, InlineLabel, Input, Select, Slider, Switch } from '@grafana/ui';
import React from 'react';
import { MarkerStyleConfig } from '../../Layers/markerLayer/type';
import { CustomSymbol } from '../../custom/CustomSymbol';
import { LABEL_WIDTH, onchangeLayerOption } from '../GeojsonStyle';
import { PropsLayer, openStreetSymbol } from '../type';
import { getLayerPropertyInfo } from '../util';

/**
 * Render the marker style configuration for a map layer.
 *
 * @param {PropsLayer} props - The component props.
 * @param {OSMapLayerState<any>} props.layer - The map layer state.
 * @param {FeatureLike[]} props.features - The map features associated with the layer.
 * @returns {JSX.Element | null} The JSX element representing the marker style configuration.
 */
export function MarkerStyle({ layer, features }: PropsLayer) {
  // Extract style and properties from layer options
  const { opacity } = layer.options;
  const { style } = layer.options.config;
  const { symbol, color, fillOpacity, size, rotation }: MarkerStyleConfig = style;
  const { min, max } = size;

  // If features are not available, return null (no rendering)
  if (!features) {
    return null;
  }

  // Get information about layer properties
  const properties = getLayerPropertyInfo(features);

  return (
    <>
      <Field label="Marker style">
        <>
          {/* Opacity Slider */}
          <InlineField label="Layer Opacity" labelWidth={LABEL_WIDTH}>
            <Slider
              min={0}
              max={1}
              step={0.1}
              value={opacity}
              onChange={(v) => onchangeLayerOption(v, 'opacity', layer)}
            />
          </InlineField>

          {/* Show Tooltip Switch */}
          <InlineField label="Show Tooltip" style={{ alignItems: 'center' }} labelWidth={LABEL_WIDTH}>
            <Switch
              value={layer.options.tooltip}
              onChange={(e) => onchangeLayerOption(e.currentTarget.checked, 'tooltip', layer)}
            />
          </InlineField>

          {/* Marker Symbol Selection */}
          {properties.geometryType === 'point' && (
            <>
              <InlineField label="Symbol" labelWidth={LABEL_WIDTH}>
                <Select
                  noOptionsMessage="no Marker Symbol fields found"
                  options={openStreetSymbol}
                  value={symbol}
                  onChange={(v) => onchangeLayerOption(v.value, 'config.style.symbol', layer)}
                />
              </InlineField>

              {/* Custom Symbol Configuration */}
              {symbol === 'custom' && (
                <Field>
                  <CustomSymbol layer={layer} />
                </Field>
              )}

              {/* Min Scale Input */}
              <InlineField label="Min Scale" labelWidth={LABEL_WIDTH}>
                <Input
                  type="number"
                  value={min}
                  min={0}
                  onChange={(e) => onchangeLayerOption(Number(e.currentTarget.value), 'config.style.size.min', layer)}
                />
              </InlineField>

              {/* Max Scale Input */}
              <InlineField label="Max Scale" labelWidth={LABEL_WIDTH}>
                <Input
                  type="number"
                  value={max}
                  min={0}
                  onChange={(e) => onchangeLayerOption(Number(e.currentTarget.value), 'config.style.size.max', layer)}
                />
              </InlineField>

              {/* Rotation Input */}
              <InlineField label="Rotation" labelWidth={LABEL_WIDTH} grow={true}>
                <Input
                  min={0}
                  max={360}
                  type="number"
                  value={rotation || 0}
                  placeholder="numeric value"
                  onChange={(e) => onchangeLayerOption(Number(e.currentTarget.value), 'config.style.rotation', layer)}
                />
              </InlineField>
            </>
          )}

          {/* Color Picker */}
          <InlineField label="Color" labelWidth={LABEL_WIDTH}>
            <InlineLabel width={4}>
              <ColorPicker
                color={color || 'dark-red'}
                onChange={(color) => onchangeLayerOption(color, 'config.style.color', layer)}
                enableNamedColors={true}
              />
            </InlineLabel>
          </InlineField>

          {/* Fill Opacity Slider */}
          <InlineField label="Fill Opacity" labelWidth={LABEL_WIDTH}>
            <Slider
              min={0}
              max={1}
              step={0.1}
              value={fillOpacity}
              onChange={(v) => onchangeLayerOption(v, 'config.style.fillOpacity', layer)}
            />
          </InlineField>
        </>
      </Field>
    </>
  );
}
