import React from 'react';
import {
  FieldConfigEditorProps,
  CustomPaletteConfig,
  ThresholdsFieldConfigSettings,
  customPaletteDefault,
  PaletteMode,
} from '@grafana/data';
import { CustomPaletteEditor as Editor } from '../ThresholdsEditorNew/CustomPaletteEditor';

export class CustomPaletteEditor extends React.PureComponent<
  FieldConfigEditorProps<CustomPaletteConfig, ThresholdsFieldConfigSettings>
> {
  constructor(props: FieldConfigEditorProps<CustomPaletteConfig, ThresholdsFieldConfigSettings>) {
    super(props);
  }

  render() {
    const { onChange } = this.props;
    let value = this.props.value;
    if (!value) {
      value = {
        colors: customPaletteDefault,
        mode: PaletteMode.Random,
        numOfShades: 6,
        currColor: '',
        rawColors: '',
      };
    }

    return <Editor palette={value} onChange={onChange} />;
  }
}
