import { PanelPlugin } from '@grafana/data';
import { TreeMapPanelOptions, addStandardDataReduceOptions, TileMethod } from './types';
import { TreeMapPanel } from './TreeMapPanel';

export const plugin = new PanelPlugin<TreeMapPanelOptions>(TreeMapPanel)
  .useFieldConfig()
  .setPanelOptions((builder) => {
    addStandardDataReduceOptions(builder);

    builder
      .addRadio({
        path: 'dataLabels',
        name: 'Data Labels',
        description: 'Shows data labels on top of the chart',
        defaultValue: 'showDatalabel',
        settings: {
          options: [
            { value: 'showDatalabel', label: 'Show' },
            { value: 'hideDatalabel', label: 'Hide' },
          ],
        },
      })
      .addRadio({
        path: 'dataLabelvalue',
        name: 'Data Label Value',
        description: 'Shows data label Value on top of the chart',
        defaultValue: 'showDatalabelValue',
        settings: {
          options: [
            { value: 'showDatalabelValue', label: 'Show' },
            { value: 'hideDatalabelvalue', label: 'Hide' },
          ],
        },
      })
      .addRadio({
        path: 'tooltip.mode',
        name: 'Tooltip mode',
        description: '',
        defaultValue: 'single',
        settings: {
          options: [
            { value: 'single', label: 'Show' },
            { value: 'none', label: 'Hide' },
          ],
        },
      })
      .addRadio({
        path: 'colorMode',
        name: 'Color Mode',
        description: 'Select color mode options',
        defaultValue: 'gradient',
        settings: {
          options: [
            { value: 'basic', label: 'Basic' },
            { value: 'gradient', label: 'Gradient' },
          ],
        },
      })
      .addBooleanSwitch({
        path: 'childcolor',
        name: ' Child color',
        description: 'Set child node color acccording to parent node color',
        defaultValue: false,
      })
      .addSliderInput({
        path: 'fillopacity',
        name: 'Fill Opacity',
        description: 'Fill Opacity In Chart',
        defaultValue: 0.9,
        settings: {
          min: 0,
          max: 1,
          step: 0.1,
        },
      })
      .addNumberInput({
        path: 'spaceBwTile',
        name: 'Space Between Tiles',
        description: 'Choose Space Between tiles',
        defaultValue: 3,
        settings: {
          min: 0,
          max: 10,
          step: 0.5,
        },
      })
      .addSelect({
        path: 'toTileMethod',
        name: 'Tile Method',
        description: 'Select Tile Method From Given Options',
        defaultValue: TileMethod.treemapSquarify,
        settings: {
          options: [
            {
              value: TileMethod.treemapSquarify,
              label: 'Squarify',
            },
            {
              value: TileMethod.treemapResquarify,
              label: 'Resquarify',
            },
            {
              value: TileMethod.treemapBinary,
              label: 'Binary',
            },
            {
              value: TileMethod.treemapDice,
              label: 'Dice',
            },
            {
              value: TileMethod.treemapSlice,
              label: 'Slice',
            },
            {
              value: TileMethod.treemapSliceDice,
              label: 'Slice-Dice',
            },
          ],
        },
      });
  })
  .setNoPadding();
