import {
  DataFrameFieldIndex,
  FALLBACK_COLOR,
  FieldColorMode,
  FieldColorModeId,
  GrafanaTheme,
  ThresholdsConfig,
  colorManipulator,
} from '@grafana/data';
import uPlot, { Series } from 'uplot';
import {
  BarAlignment,
  BarConfig,
  DrawStyle,
  FillConfig,
  GraphGradientMode,
  LineConfig,
  LineInterpolation,
  PointsConfig,
  PointVisibility,
} from '../config';
import { PlotConfigBuilder } from '../types';
import { getHueGradientFn, getOpacityGradientFn, getScaleGradientFn } from './gradientFills';
// import tinycolor from 'tinycolor2';

export interface SeriesProps extends LineConfig, BarConfig, FillConfig, PointsConfig {
  scaleKey: string;
  pxAlign?: boolean;
  gradientMode?: GraphGradientMode;
  facets?: any[];
  /** Used when gradientMode is set to Scheme */
  thresholds?: ThresholdsConfig;
  /** Used when gradientMode is set to Scheme  */
  colorMode?: FieldColorMode;
  fieldName?: string;
  hardMin?: number | null;
  hardMax?: number | null;
  softMin?: number | null;
  softMax?: number | null;

  drawStyle?: DrawStyle;
  pathBuilder?: Series.PathBuilder;
  pointsFilter?: any;
  pointsBuilder?: Series.Points.Show;
  show?: boolean;
  dataFrameFieldIndex?: DataFrameFieldIndex;
  theme: GrafanaTheme;
  value?: uPlot.Series.Value;
  hideInLegend?: boolean;
}

export class UPlotSeriesBuilder extends PlotConfigBuilder<SeriesProps, Series> {
  getConfig() {
    let {
      facets,
      drawStyle,
      pathBuilder,
      pointsBuilder,
      pointsFilter,
      lineInterpolation,
      lineWidth,
      lineStyle,
      barAlignment,
      showPoints,
      // pointColor,
      pointSize,
      scaleKey,
      pxAlign,
      spanNulls,
      show = true,
    } = this.props;

    let lineConfig: Partial<Series> = {};

    let lineColor = this.getLineColor();

    // GraphDrawStyle.Points mode also needs this for fill/stroke sharing & re-use in series.points. see getColor() below.
    // lineConfig.stroke = lineColor;

    if (pathBuilder != null) {
      lineConfig.paths = pathBuilder;
      lineConfig.stroke = lineColor;
      lineConfig.width = lineWidth;
    } else if (drawStyle === DrawStyle.Points) {
      lineConfig.paths = () => null;
    } else if (drawStyle != null) {
      lineConfig.stroke = lineColor;
      lineConfig.width = lineWidth;
      if (lineStyle && lineStyle.fill !== 'solid') {
        if (lineStyle.fill === 'dot') {
          lineConfig.cap = 'round';
        }
        lineConfig.dash = lineStyle.dash ?? [10, 10];
      }
      lineConfig.paths = (self: uPlot, seriesIdx: number, idx0: number, idx1: number) => {
        let pathsBuilder = mapDrawStyleToPathBuilder(drawStyle!, lineInterpolation, barAlignment);
        return pathsBuilder(self, seriesIdx, idx0, idx1);
      };
    }
    // const useColor: uPlot.Series.Stroke =
    //   // @ts-ignore
    //   typeof lineColor === 'string' ? lineColor : (u, seriesIdx) => u.series[seriesIdx]._stroke;

    const pointsConfig: Partial<Series> = {
      points: {
        stroke: lineColor,
        fill: lineColor,
        size: pointSize,
        filter: pointsFilter,
      },
    };

    if (pointsBuilder != null) {
      pointsConfig.points!.show = pointsBuilder;
    } else {
      // we cannot set points.show property above (even to undefined) as that will clear uPlot's default auto behavior
      if (drawStyle === DrawStyle.Points) {
        pointsConfig.points!.show = true;
      } else {
        if (showPoints === PointVisibility.Auto) {
          if (drawStyle === DrawStyle.Bars) {
            pointsConfig.points!.show = false;
          }
        } else if (showPoints === PointVisibility.Never) {
          pointsConfig.points!.show = false;
        } else if (showPoints === PointVisibility.Always) {
          pointsConfig.points!.show = true;
        }
      }
    }

    return {
      scale: scaleKey,
      facets,
      spanGaps: typeof spanNulls === 'number' ? false : spanNulls,
      value: () => '',
      pxAlign,
      show,
      fill: this.getFill(),
      ...lineConfig,
      ...pointsConfig,
    };
  }

  private getLineColor(): Series.Stroke {
    const { lineColor, gradientMode, colorMode, thresholds, theme, hardMin, hardMax, softMin, softMax } = this.props;

    if (gradientMode === GraphGradientMode.Scheme && colorMode?.id !== FieldColorModeId.Fixed) {
      return getScaleGradientFn(1, theme, colorMode, thresholds, hardMin, hardMax, softMin, softMax);
    }

    return lineColor ?? FALLBACK_COLOR;
  }

  private getFill(): Series.Fill | undefined {
    const {
      lineColor,
      fillColor,
      gradientMode,
      fillOpacity,
      colorMode,
      thresholds,
      theme,
      hardMin,
      hardMax,
      softMin,
      softMax,
    } = this.props;

    if (fillColor) {
      return fillColor;
    }

    const mode = gradientMode ?? GraphGradientMode.None;
    const opacityPercent = (fillOpacity ?? 0) / 100;

    switch (mode) {
      case GraphGradientMode.Opacity:
        return getOpacityGradientFn((fillColor ?? lineColor)!, opacityPercent);
      case GraphGradientMode.Hue:
        return getHueGradientFn((fillColor ?? lineColor)!, opacityPercent, theme);
      case GraphGradientMode.Scheme:
        if (colorMode?.id !== FieldColorModeId.Fixed) {
          return getScaleGradientFn(
            opacityPercent,
            theme,
            colorMode,
            thresholds,
            hardMin,
            hardMax,
            softMin,
            softMax,
            lineColor,
            fillColor
          );
        }
      default:
        if (opacityPercent > 0) {
          return colorManipulator.alpha(lineColor ?? '', opacityPercent);
        }
    }

    return undefined;
  }
}

interface PathBuilders {
  linear: Series.PathBuilder;
  smooth: Series.PathBuilder;
  stepBefore: Series.PathBuilder;
  stepAfter: Series.PathBuilder;
  bars: Series.PathBuilder;
  barsAfter: Series.PathBuilder;
  barsBefore: Series.PathBuilder;
  [key: string]: Series.PathBuilder;
}

let builders: PathBuilders | undefined = undefined;

function mapDrawStyleToPathBuilder(
  style: DrawStyle,
  lineInterpolation?: LineInterpolation,
  barAlignment?: BarAlignment
): Series.PathBuilder {
  const barWidthFactor = 0.6;
  const barMaxWidth = Infinity;
  const pathBuilders = uPlot.paths;
  if (!builders) {
    // This should be global static, but Jest initalization was failing so we lazy load to avoid the issue

    builders = {
      linear: pathBuilders.linear!(),
      smooth: pathBuilders.spline!(),
      stepBefore: pathBuilders.stepped!({ align: -1 }),
      stepAfter: pathBuilders.stepped!({ align: 1 }),
      bars: pathBuilders.bars!({ size: [barWidthFactor, barMaxWidth] }),
      barsBefore: pathBuilders.bars!({ size: [barWidthFactor, barMaxWidth], align: -1 }),
      barsAfter: pathBuilders.bars!({ size: [barWidthFactor, barMaxWidth], align: 1 }),
    };
  }

  if (style === DrawStyle.Bars) {
    if (barAlignment === BarAlignment.After) {
      return builders.barsAfter;
    }
    if (barAlignment === BarAlignment.Before) {
      return builders.barsBefore;
    }
    return builders.bars;
  }
  if (style === DrawStyle.Line) {
    if (lineInterpolation === LineInterpolation.StepBefore) {
      return builders.stepBefore;
    }
    if (lineInterpolation === LineInterpolation.StepAfter) {
      return builders.stepAfter;
    }
    if (lineInterpolation === LineInterpolation.Smooth) {
      return builders.smooth;
    }
  }

  return builders.linear; // the default
}
