import React, { FC } from 'react';
import { cloneDeep, filter } from 'lodash';
import TopSectionItem from './TopSectionItem';
import config from '../../config';
import { getLocationSrv } from '@grafana/runtime';

const TopSection: FC<any> = ({ navIconColor }) => {
  const navTree = cloneDeep(config.bootData.navTree);
  const mainLinks = filter(navTree, (item) => !item.hideFromMenu);
  const searchLink = {
    text: 'Search',
    // icon: 'file-search-alt',
    icon: 'search',
    // icon: 'magnifying-glass',
  };

  const onOpenSearch = () => {
    getLocationSrv().update({ query: { search: 'open' }, partial: true });
  };

  // Filtering Plugins Menu for CARBON
  mainLinks.map((item) => {
    if (item.id === 'cfg') {
      item.children = item.children.filter((itemToFilter: any) => {
        return itemToFilter.id !== 'plugins';
      });
    }
  });

  return (
    <div className="sidemenu__top">
      <TopSectionItem link={searchLink} onClick={onOpenSearch} navIconColor={navIconColor} />
      {mainLinks.map((link, index) => {
        return <TopSectionItem link={link} key={`${link.id}-${index}`} navIconColor={navIconColor} />;
      })}
    </div>
  );
};

export default TopSection;
