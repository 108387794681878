import { getColorForTheme } from '@grafana/data';
import darkTheme from '@grafana/ui/src/themes/dark';
import { Fill, Stroke, Style } from 'ol/style';
import tinycolor from 'tinycolor2';
import { getPreDefinedIcon } from '../../utils/icon';
import { ComparisonOperation } from './default';

/**
 * Generate a style for a feature on a map.
 *
 * @function
 * @param {string|undefined} symbol - The symbol used for the feature (e.g., 'circle', 'square').
 * @param {string|undefined} color - The color of the feature (e.g., '#FF0000' for red).
 * @param {number|undefined} opacity - The opacity of the feature (a value between 0 and 1).
 * @param {number|undefined} size - The size of the feature (e.g., for point features).
 * @param {number|undefined} rotation - The rotation angle of the feature in degrees.
 * @returns {Style} The style configuration for the feature.
 */
export const getStyle = (
  symbol: string | undefined,
  color: string | undefined,
  opacity: number | undefined,
  size: number | undefined,
  rotation: number | undefined
) => {
  const strokePoly = getStroke(color || 'dark-red', 1, 1);
  const fillPoly = getFill(color || 'light-red', opacity);

  return new Style({
    image: getPreDefinedIcon(symbol || 'circle', fillPoly, strokePoly, size || 3, rotation || 0),
    stroke: strokePoly,
    fill: fillPoly,
  });
};

// left -> value from geojson property
// right -> value from user input
export const checkRuleIsMatched = (left: any, operation: string, right: any) => {
  switch (operation) {
    case ComparisonOperation.EQ:
      return `${left}` === `${right}`;
    case ComparisonOperation.NEQ:
      return `${left}` !== `${right}`;
    case ComparisonOperation.GT:
      return left > right;
    case ComparisonOperation.GTE:
      return left >= right;
    case ComparisonOperation.LT:
      return left < right;
    case ComparisonOperation.LTE:
      return left <= right;
    default:
      return false;
  }
};

export const getFill = (color: string, opacity?: number) => {
  return new Fill({
    color: getRGBColor(color, opacity),
  });
};

/**
 * Create a stroke style for a polygon or line feature.
 *
 * @function
 * @param {string} color - The stroke color (e.g., '#FF0000' for red).
 * @param {number} [opacity] - The stroke opacity (a value between 0 and 1).
 * @param {number} [width] - The stroke width.
 * @returns {Stroke} The stroke style configuration.
 */
export const getStroke = (color: string, opacity?: number, width?: number) => {
  return new Stroke({
    color: getRGBColor(color, opacity),
    width: width || 1,
  });
};

/**
 * Get an RGB color with optional alpha (opacity).
 *
 * @function
 * @param {string} colorStr - The base color string (e.g., '#FF0000' for red).
 * @param {number} [alpha] - The alpha value (opacity) as a number between 0 and 1.
 * @returns {string} The resulting RGB color string with alpha.
 */
export const getRGBColor = (colorStr: string, alpha?: number) => {
  const opacity = getOpacity(alpha);
  return tinycolor(getColorForTheme(colorStr, darkTheme)).setAlpha(opacity).toRgbString();
};

/**
 * Get the opacity value based on the provided input.
 *
 * @function
 * @param {any} value - The input value, which can be undefined, null, a number, or any other type.
 * @returns {number} The opacity value:
 *   - If `value` is undefined or null, the function returns 1.
 *   - If `value` is a number, it is returned as is.
 *   - If `value` is any other type, it is returned as is (not recommended).
 */
export function getOpacity(value: any) {
  /**
   * scenario
   * 1. when alpha is not defined -- 1
   * 2. when alpha is zero - 0 (zero is falsy)
   * 3. when alpha is given
   */
  if (value === undefined || value === null) {
    return 1;
  } else {
    return value;
  }
}
