import React, { FunctionComponent } from 'react';
import cronstrue from 'cronstrue';
import { options, StateExport, StateI, StateSync } from './types';
import { validateCron } from './utils';
import { InlineFormLabel } from '../FormLabel/FormLabel';
import { Field } from '../Forms/Field';
import { RadioButtonGroup } from '../Forms/RadioButtonGroup/RadioButtonGroup';
import { Input } from '../Input/Input';
import { Select } from '../Select/Select';
import { TimeZonePicker } from '../TimePicker/TimeZonePicker';
import { selectors } from '@grafana/e2e-selectors';

interface Props {
  state: StateI | StateSync | StateExport;
  onSelectChange: ({ value }: any, name: string) => void;
  onRadioChange: (value: string | Number[], name: string) => void;
  onCronChange: (event: React.SyntheticEvent<HTMLInputElement>) => void;
}

/**
 * Scheduling Editor for Pdf report
 * @function
 * @private
 * @param {SchedulingI} props - Scheduling props
 * @returns {React.ReactElement} - The Scheduling Editor.
 */
export const Scheduling: FunctionComponent<Props> = ({ state, onSelectChange, onRadioChange, onCronChange }) => {
  const dailyData = (
    <Field label="Time">
      <div style={{ display: 'flex' }}>
        <Select
          width={10}
          options={options.hours}
          onChange={(evt) => onSelectChange(evt, 'hour')}
          value={options.hours.find((item: any) => item.value === state.hour)}
        />
        <Select
          width={10}
          options={options.minutes}
          onChange={(evt) => onSelectChange(evt, 'minute')}
          value={options.minutes.find((item: any) => item.value === state.minute)}
        />
      </div>
    </Field>
  );

  const weeklyData = (
    <div>
      {dailyData}
      <Field label="Day">
        <Select
          options={options.days}
          onChange={(evt) => onSelectChange(evt, 'day')}
          value={options.days.find((item: any) => item.value === state.day)}
        />
      </Field>
    </div>
  );

  const monthlyData = (
    <div>
      {dailyData}
      <Field label="Date">
        <Select
          options={options.dates}
          onChange={(evt) => onSelectChange(evt, 'date')}
          value={options.dates.find((item: any) => item.value === state.date)}
        />
      </Field>
    </div>
  );

  const customData = (
    <Field label="Schedule with Cron">
      <div>
        <div className="gf-form">
          <InlineFormLabel
            tooltip={
              <div>
                * any value <br />
                , value list separator <br />
                - range of values <br />
                / step values <br />
                0-59 allowed values <br />
              </div>
            }
          >
            Minute
          </InlineFormLabel>
          <Input name="cron1" value={state.cron1} onChange={onCronChange} placeholder={state.cron1} />
        </div>
        <div className="gf-form">
          <InlineFormLabel
            tooltip={
              <div>
                * any value <br />
                , value list separator <br />
                - range of values <br />
                / step values <br />
                0-23 allowed values <br />
              </div>
            }
          >
            Hour
          </InlineFormLabel>
          <Input name="cron2" value={state.cron2} onChange={onCronChange} placeholder={state.cron2} />
        </div>
        <div className="gf-form">
          <InlineFormLabel
            tooltip={
              <div>
                * any value <br />
                , value list separator <br />
                - range of values <br />
                / step values <br />
                1-31 allowed values <br />
              </div>
            }
          >
            Day (month)
          </InlineFormLabel>
          <Input name="cron3" value={state.cron3} onChange={onCronChange} placeholder={state.cron3} />
        </div>
        <div className="gf-form">
          <InlineFormLabel
            tooltip={
              <div>
                * any value <br />
                , value list separator <br />
                - range of values <br />
                / step values <br />
                1-12 allowed values <br />
                JAN-DEC alternative single values <br />
              </div>
            }
          >
            Month
          </InlineFormLabel>
          <Input name="cron4" value={state.cron4} onChange={onCronChange} placeholder={state.cron4} />
        </div>
        <div className="gf-form">
          <InlineFormLabel
            tooltip={
              <div>
                * any value <br />
                , value list separator <br />
                - range of values <br />
                / step values <br />
                0-6 allowed values <br />
                SUN-SAT alternative single values <br />
                7 sunday (non-standard) <br />
              </div>
            }
          >
            Day (week)
          </InlineFormLabel>
          <Input name="cron5" value={state.cron5} onChange={onCronChange} placeholder={state.cron5} />
        </div>
        {
          validateCron(state) && (
            <div style={{ margin: '0.5rem' }}>
              <em>
                {cronstrue.toString(`${state.cron1} ${state.cron2} ${state.cron3} ${state.cron4} ${state.cron5}`, {
                  verbose: true,
                  use24HourTimeFormat: true,
                })}
              </em>
            </div>
          )
          // <FormLabel width={18} className="text-center">
          // { cronstrue.toString(`${options.cron1} ${options.cron2} ${options.cron3} ${options.cron4} ${options.cron5}`) }
          // </FormLabel>
        }
      </div>
    </Field>
  );
  return (
    <div>
      <h3 className="cmb-1">Scheduling Info</h3>
      <Field label="Frequency">
        <RadioButtonGroup
          value={options.frequencies.find((item: any) => item.value === state.frequency)?.value}
          options={options.frequencies}
          onChange={(env) => onRadioChange(env, 'frequency')}
        />
      </Field>
      <Field label="Timezone" aria-label={selectors.components.TimeZonePicker.container}>
        <TimeZonePicker
          includeInternal={true}
          value={state.dTimezone}
          onChange={(value) => {
            onSelectChange({ value: value }, 'dTimezone');
          }}
        />
      </Field>
      {state.frequency === 'daily'
        ? dailyData
        : state.frequency === 'weekly'
        ? weeklyData
        : state.frequency === 'monthly'
        ? monthlyData
        : state.frequency === 'custom'
        ? customData
        : ''}
    </div>
  );
};

Scheduling.displayName = 'Scheduling';
