import React from 'react';
import { Bar as VxBar } from '@visx/shape';
import { Text as VxText } from '@visx/text';

const Bar = ({ color, x, y, width, height, name, value, textColor }: any) => {
  const text = `${name} ${value}`;
  return (
    <React.Fragment>
      <VxBar x={x} y={y} width={width} height={height} fill={color} style={{ opacity: 0.8 }} />
      <VxText x={x + 10} y={y + height / 2 + 5} fill={textColor} style={{ fontSize: '1rem' }}>
        {text}
      </VxText>
    </React.Fragment>
  );
};

export default Bar;
