import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import { css, cx } from '@emotion/css';
import { usePlotContext, Portal, DEFAULT_ANNOTATION_COLOR, useTheme, useStyles } from '@grafana/ui';
import { colorManipulator, DataFrame, getDisplayProcessor, GrafanaTheme, TimeZone } from '@grafana/data';
import { AnnotationEditorForm } from './AnnotationEditorForm';
import { PlotSelection } from '../../AnnotationEditorPlugin';
import { AnnotationsDataFrameViewDTO } from '../../types';
import { getRGBColor } from '../../../googlemap/utils/prepareFields';

interface AnnotationEditorProps {
  data: DataFrame;
  timeZone: TimeZone;
  selection: PlotSelection;
  onSave: () => void;
  onDismiss: () => void;
  annotation?: AnnotationsDataFrameViewDTO;
  panelID: number;
  style: any;
}

export const AnnotationEditor: React.FC<AnnotationEditorProps> = ({
  onDismiss,
  onSave,
  timeZone,
  data,
  selection,
  annotation,
  panelID,
  style,
}) => {
  const theme = useTheme();
  const styles = useStyles(getStyles);
  const commonStyles = useStyles(getCommonAnnotationStyles);
  const plotCtx = usePlotContext();
  const [popperTrigger, setPopperTrigger] = useState<HTMLDivElement | null>(null);
  const [editorPopover, setEditorPopover] = useState<HTMLDivElement | null>(null);

  const popper = usePopper(popperTrigger, editorPopover, {
    modifiers: [
      { name: 'arrow', enabled: false },
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          rootBoundary: 'viewport',
        },
      },
    ],
  });

  if (!plotCtx || !plotCtx.getPlotInstance()?.over.getBoundingClientRect()) {
    return null;
  }

  let xField = data.fields[0];
  if (!xField) {
    return null;
  }
  const xFieldFmt = xField.display || getDisplayProcessor({ field: xField, timeZone, theme });
  const isRegionAnnotation = selection.min !== selection.max;
  return (
    <Portal>
      <>
        <div // div overlay matching uPlot canvas bbox
          style={style}
        >
          <div // Annotation marker
            className={cx(
              css`
                position: absolute;
                top: ${selection.bbox.top}px;
                left: ${selection.bbox.left}px;
                width: ${selection.bbox.width}px;
                height: ${selection.bbox.height}px;
              `,
              isRegionAnnotation ? styles.overlayRange(annotation) : styles.overlay(annotation)
            )}
          >
            <div
              ref={setPopperTrigger}
              className={
                isRegionAnnotation
                  ? cx(commonStyles(annotation).markerBar, styles.markerBar)
                  : cx(commonStyles(annotation).markerTriangle, styles.markerTriangle)
              }
            />
          </div>
        </div>

        <AnnotationEditorForm
          annotation={annotation || ({ time: selection.min, timeEnd: selection.max } as AnnotationsDataFrameViewDTO)}
          timeFormatter={(v) => xFieldFmt(v).text}
          onSave={onSave}
          onDismiss={onDismiss}
          ref={setEditorPopover}
          panelID={panelID}
          style={popper.styles.popper}
          {...popper.attributes.popper}
        />
      </>
    </Portal>
  );
};

const getStyles = (theme: GrafanaTheme) => {
  return {
    overlay: (annotation?: AnnotationsDataFrameViewDTO) => {
      const color = getRGBColor(annotation?.color || DEFAULT_ANNOTATION_COLOR);
      return css`
        border-left: 1px dashed ${color};
      `;
    },
    overlayRange: (annotation?: AnnotationsDataFrameViewDTO) => {
      const color = getRGBColor(annotation?.color || DEFAULT_ANNOTATION_COLOR);
      return css`
        background: ${colorManipulator.alpha(color, 0.1)};
        border-left: 1px dashed ${color};
        border-right: 1px dashed ${color};
      `;
    },
    markerTriangle: css`
      top: calc(100% + 2px);
      left: -4px;
      position: absolute;
    `,
    markerBar: css`
      top: 100%;
      left: 0;
      position: absolute;
    `,
  };
};

export const getCommonAnnotationStyles = (theme: GrafanaTheme) => {
  return (annotation?: AnnotationsDataFrameViewDTO) => {
    const color = getRGBColor(annotation?.color || DEFAULT_ANNOTATION_COLOR);
    return {
      markerTriangle: css`
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 4px solid ${color};
      `,
      markerBar: css`
        display: block;
        width: calc(100%);
        height: 5px;
        background: ${color};
      `,
    };
  };
};
