import React from 'react';
import { DashboardValue, ImageOptions } from '../types';
import { DashExpand } from 'app/features/linkDrawer/dashboard/DashExpand';
import { DashboardTime } from 'app/features/linkDrawer/dashboard/DashboardTime';

interface Props {
  toShowDashboard: DashboardValue;
  option: ImageOptions;
  onChange: (toShowDashboard: DashboardValue) => void;
}

export function ListDashboard(props: Props) {
  const { option, onChange, toShowDashboard } = props;

  const onDashboardSelectChange = (target: any, idx: any) => {
    onChange({ ...toShowDashboard, dashboardName: target.label, dashboardUrl: target.value });
  };

  const onUrlChangeCustomInput = (value: string, idx: number, name: boolean) => {
    let dashboard;
    if (name) {
      dashboard = { ...toShowDashboard, dashboardName: value };
    } else {
      dashboard = { ...toShowDashboard, dashboardUrl: value };
    }
    onChange(dashboard);
  };

  const handleDrawerLinkTime = (value: boolean) => {
    onChange({ ...toShowDashboard, drawerLinkTime: value });
  };

  return (
    <>
      <DashboardTime value={toShowDashboard.drawerLinkTime} drawerLinkTime={handleDrawerLinkTime} />
      <DashExpand
        option={option.toShowDashboard}
        index={1}
        onUrlChangeCustomInput={onUrlChangeCustomInput}
        onDashboardChange={onDashboardSelectChange}
      />
    </>
  );
}
