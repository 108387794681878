import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const Team: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 64 64"
      viewBox="0 0 64 64"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M21.5,34.3c-5.8,0-10.5-4.7-10.5-10.5s4.7-10.5,10.5-10.5S32,18,32,23.8C32,29.6,27.3,34.3,21.5,34.3z M21.5,18
        c-3.2,0-5.8,2.6-5.8,5.8s2.6,5.8,5.8,5.8c3.2,0,5.8-2.6,5.8-5.8S24.7,18,21.5,18z M39,57.7v-1.2C39,46.9,31.2,39,21.5,39
        S4,46.9,4,56.5v1.2C4,59,5,60,6.3,60s2.3-1,2.3-2.3v-1.2c0-7.1,5.8-12.9,12.9-12.9s12.9,5.8,12.9,12.9v1.2c0,1.3,1,2.3,2.3,2.3
        C38,60,39,59,39,57.7z M60,46c0-9-7.3-16.4-16.4-16.4c-4,0-7.9,1.5-10.9,4.2c-1,0.9-1.1,2.3-0.2,3.3c0.9,1,2.3,1.1,3.3,0.2
        c0,0,0,0,0,0c4.8-4.3,12.2-3.9,16.5,0.9c1.9,2.1,3,4.9,3,7.8c0,1.3,1,2.3,2.3,2.3S60,47.3,60,46z M44.9,25
        c-5.8,0-10.5-4.7-10.5-10.5S39,4,44.9,4s10.5,4.7,10.5,10.5C55.4,20.3,50.7,25,44.9,25z M44.9,8.6c-3.2,0-5.8,2.6-5.8,5.8
        s2.6,5.8,5.8,5.8s5.8-2.6,5.8-5.8S48.1,8.6,44.9,8.6z"
      />
    </svg>
  );
};
