import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const Home: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 16 16"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M14.2,6.4L10,2.1C8.9,1,7.1,1,6,2.1L1.8,6.4C1.5,6.7,1.3,7.1,1.3,7.5V13c0,0.9,0.7,1.7,1.7,1.7h10
        c0.9,0,1.7-0.7,1.7-1.7V7.5C14.7,7.1,14.5,6.7,14.2,6.4z M9.7,13.6H6.3v-2.2c0-0.9,0.7-1.7,1.7-1.7s1.7,0.7,1.7,1.7V13.6z M13.6,13
        c0,0.3-0.2,0.6-0.6,0.6h-2.2v-2.2c0-1.5-1.2-2.8-2.8-2.8s-2.8,1.2-2.8,2.8v2.2H3c-0.3,0-0.6-0.2-0.6-0.6V7.5c0-0.1,0.1-0.3,0.2-0.4
        l4.2-4.2c0.7-0.7,1.7-0.7,2.4,0l4.2,4.2c0.1,0.1,0.2,0.2,0.2,0.4V13z"
      />
    </svg>
  );
};
