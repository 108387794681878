import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const Alert: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 64 64"
      viewBox="0 0 64 64"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M52.8,39.8L44,54.2c-2,3.2-5.3,5.4-9.1,5.8c-0.5,0.1-1,0.1-1.5,0.1c-2.5,0-5-0.8-7-2.2
        c-4.9,3.7-11.9,2.7-15.6-2.2c-2.9-3.9-3-9.2-0.1-13.2l-3.1-3.1c-4.8-4.8-4.8-12.6,0-17.5c0.7-0.7,1.4-1.3,2.2-1.8l13.5-8.4
        c7.5-4.8,17.3-4.3,24.2,1.3l3.9-3.9c0.9-0.9,2.4-1,3.3-0.1c0.9,0.9,1,2.4,0.1,3.3c0,0,0,0-0.1,0.1l-3.9,3.9
        C56.2,23.1,57,32.4,52.8,39.8z M22.8,54.6l-8.7-8.7c-1.4,2.5-1,5.6,1.1,7.7C17.2,55.5,20.3,55.9,22.8,54.6z M46.1,18
        c-5.4-5.4-13.8-6.3-20.2-2.2l-13.5,8.4c-3.6,2.2-4.7,6.9-2.5,10.5c0.3,0.5,0.7,1,1.1,1.4L28,53.2c3,3,7.8,3,10.8,0
        c0.4-0.4,0.8-0.9,1.1-1.4l8.8-14.4C52.3,31.1,51.2,23.2,46.1,18L46.1,18z M49.1,60.1c-1.3,0-2.3-1-2.3-2.3c0-0.8,0.4-1.5,1-1.9
        c4.1-3.1,6.9-7.6,7.8-12.7c0.3-1.3,1.5-2.1,2.8-1.8s2.1,1.5,1.8,2.8c0,0,0,0,0,0.1c-1.1,6.2-4.6,11.7-9.6,15.5
        C50,60,49.6,60.1,49.1,60.1z M6.2,17.3c-1.3,0-2.3-1.1-2.3-2.4c0-0.5,0.2-1,0.5-1.4C8.2,8.5,13.8,5,20.1,3.9
        c1.3-0.3,2.5,0.6,2.8,1.8C23.1,7,22.3,8.2,21,8.5c-5.2,0.9-9.8,3.7-12.9,7.9C7.7,17,7,17.3,6.2,17.3z"
      />
    </svg>
  );
};
