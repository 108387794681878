export interface WordCloudOptions {
  colors: string[];
  deterministic: boolean;
  fontFamily: string;
  fontStyle: string;
  fontWeight: string;
  padding: number;
  rotations: number;
  rotationAngles: number;
  spiral: any;
  transitionDuration: number;
  display: string;
}

export interface SimpleOptions {
  wordCloudOptions: WordCloudOptions;
  datasource_tags_field: string;
  datasource_count_field: string;
  series_index: number;
  stop_words: string | undefined;
  datasource_stop_words: string;
  fieldSelector: {
    tagField: string;
    countField: string;
  };
  reduceOptions: any;
  minFontSize: number;
  maxFontSize: number;
}

export enum textStyle {
  Arial = 'Arial',
  TimesNewRoman = 'Times New Roman',
  Times = 'Times',
  CourierNew = 'Courier New',
  Courier = 'Courier',
  Verdana = 'Verdana',
  Georgia = 'Georgia',
  Palatino = 'Palatino',
  Garamond = 'Garamond',
  Bookman = 'Bookman',
  Tahoma = 'Tahoma',
  TrebuchetMS = 'TrebuchetMs',
  ArialBlack = 'Arial Black',
  Impact = 'Impact',
  ComicSansMS = 'ComicSans MS',
}

export const fontStyleOptions = [
  {
    label: textStyle.Arial,
    value: 'arial',
  },
  {
    label: textStyle.TimesNewRoman,
    value: 'timesnewroman',
  },
  {
    label: textStyle.Times,
    value: 'times',
  },
  {
    label: textStyle.ComicSansMS,
    value: 'comicsansms',
  },
  {
    label: textStyle.Courier,
    value: 'courier',
  },
  {
    label: textStyle.CourierNew,
    value: 'couriernew',
  },
  {
    label: textStyle.ArialBlack,
    value: 'arialblack',
  },
  {
    label: textStyle.Impact,
    value: 'impact',
  },
  {
    label: textStyle.Bookman,
    value: 'bookman',
  },
  {
    label: textStyle.Tahoma,
    value: 'tahoma',
  },
  {
    label: textStyle.TrebuchetMS,
    value: 'TrebuchetMS',
  },
  {
    label: textStyle.Garamond,
    value: 'Garamond',
  },
  {
    label: textStyle.Georgia,
    value: 'Georgia',
  },
  {
    label: textStyle.Palatino,
    value: 'Palatino',
  },
  {
    label: textStyle.Verdana,
    value: 'Verdana',
  },
];
