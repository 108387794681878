import { SingleStatBaseOptions } from '@grafana/ui';
import { ReducerID, standardEditorsRegistry, PanelOptionsEditorBuilder } from '@grafana/data';

export interface StackedBarOptions extends SingleStatBaseOptions {
  legendOrientation: any;
  orientationOptions: any;
  chartOrientation: any;
  chartOrient: string;
  tooltipThemes: any;
  tooltipTheme: any;
  vertical: any;
  pallets: any;
  pallet: any;
  stacked: any;
  labelColor: any;
  xAxis: any;
  yAxis: any;
  xlabelTotal: any;
  xlabelToAdd: any;
  categories: any;
  toChange: any;
  previousDeltaUp: any;
  ignoreMe: any;
  labels: any;
  editAxis: any;
  editBar: any;
  axisBorder: any;
  axisTicks: any;
  horizontalTooltip: any;
  disableBar: any;
  chartOptions: any;
  chartType: any;
  columns: number;
  toEditAxis: AxisEditor[];
  horizontalAlignOptions: any;
  horizontalAlign: string;
  decimal: number;
  axisNameColor: string;
  fontWeightOptions: any;
  dataLabels: boolean;
  legendShow: boolean;
  toDeleteQuery: any;
}

export interface AxisEditor {
  id: string;
  name: string;
}

export function addStandardDataReduceOptions(
  builder: PanelOptionsEditorBuilder<SingleStatBaseOptions>,
  includeOrientation = true
) {
  builder.addCustomEditor({
    id: 'reduceOptions.calcs',
    path: 'reduceOptions.calcs',
    name: 'Value',
    description: 'Choose a reducer function / calculation',
    editor: standardEditorsRegistry.get('stats-picker').editor as any,
    defaultValue: [ReducerID.mean],
    // Hides it when all values mode is on
    // showIf: currentConfig => currentConfig.reduceOptions.values === false,
  });
}
