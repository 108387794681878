import { PanelPlugin } from '@grafana/data';
import { TimeOptions } from './types';
import { TimePanel } from './TimePanel';

export const plugin = new PanelPlugin<TimeOptions>(TimePanel).useFieldConfig().setPanelOptions((builder) => {
  builder
    .addBooleanSwitch({
      path: 'leftFrom',
      name: 'Display title',
      defaultValue: true,
    })
    .addTextInput({
      path: 'middle',
      name: 'Current time title',
      defaultValue: '',
      description: '',
      showIf: (options: TimeOptions) => options.leftFrom,
    })
    .addTextInput({
      path: 'left',
      name: 'Start time title',
      defaultValue: '',
      description: '',
      showIf: (options: TimeOptions) => options.leftFrom,
    })
    .addTextInput({
      path: 'right',
      name: 'End time title',
      defaultValue: '',
      description: '',
      showIf: (options: TimeOptions) => options.leftFrom,
    })
    .addBooleanSwitch({
      path: 'date',
      name: 'Display date',
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'changeDateFormat',
      name: 'Change date format',
      description: 'Switch date format',
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'showTime',
      name: 'Display time',
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'twelveHourTime',
      name: 'Display 12 hour clock',
      description: 'Switch between 12 and 24 hour clock',
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'timeZone',
      name: 'Display timeZone',
      description: 'Get Timezone of current location',
      defaultValue: false,
    })
    .addNumberInput({
      category: ['Add Time & Date'],
      path: 'addm',
      name: 'Add Minutes',
      defaultValue: 0,
      settings: {
        integer: true,
      },
    })
    .addNumberInput({
      category: ['Add Time & Date'],
      path: 'addh',
      name: 'Add Hours',
      defaultValue: 0,
      settings: {
        integer: true,
      },
    })
    .addNumberInput({
      category: ['Add Time & Date'],
      path: 'addD',
      name: 'Add Days',
      defaultValue: 0,
      settings: {
        integer: true,
      },
    })
    .addNumberInput({
      category: ['Add Time & Date'],
      path: 'addM',
      name: 'Add Months',
      defaultValue: 0,
      settings: {
        integer: true,
      },
    })
    .addNumberInput({
      category: ['Add Time & Date'],
      path: 'addY',
      name: 'Add Years',
      defaultValue: 0,
      settings: {
        integer: true,
      },
    })
    .addColorPicker({
      category: ['Styling'],
      path: 'textcolor',
      name: 'Text color',
      defaultValue: 'rgb(2, 14, 0)',
    })
    .addColorPicker({
      category: ['Styling'],
      path: 'boxtextcolor',
      name: 'Date time color',
      defaultValue: 'rgb(2, 14, 0)',
    })
    .addColorPicker({
      category: ['Styling'],
      path: 'borderboxcolor',
      name: 'Background color',
      defaultValue: 'rgba(19, 7, 1, 0.08)',
    })
    .addBooleanSwitch({
      category: ['Styling'],
      path: 'textBold',
      name: 'Bold text',
      defaultValue: false,
    })
    .addBooleanSwitch({
      category: ['Styling'],
      path: 'textItalic',
      name: 'Italic text',
      defaultValue: false,
    })
    .addBooleanSwitch({
      category: ['Styling'],
      path: 'textUnderline',
      name: 'Underline text',
      defaultValue: false,
    });
});
