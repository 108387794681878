import React from 'react';
import { Button, useTheme } from '@grafana/ui';
import { OSMapLayerState, OSMapPanelOptions } from '../../types/types';
import { DataFrame, FieldDisplay, InterpolateFunction, ReduceDataOptions, getFieldDisplayValues } from '@grafana/data';
import { MarkersConfig, QueryLinkMarker } from '../../Layers/markerLayer/type';
import { MarkerExpandQuery } from './MarkerExpandQuery';
import { cloneDeep } from 'lodash';
import { setProperty } from '../GeojsonStyle';

/**
 * Interface representing custom props for a component.
 *
 * @interface Props
 * @property {OSMapLayerState<any>} layer - The OSMapLayerState for the component.
 * @property {InterpolateFunction | undefined} replaceVariables - The InterpolateFunction for variable replacement.
 * @property {OSMapPanelOptions | undefined} options - The OSMapPanelOptions for the component.
 * @property {DataFrame[]} data - An array of DataFrame objects.
 */
export interface Props {
  layer: OSMapLayerState<any>;
  replaceVariables: InterpolateFunction | undefined;
  options: OSMapPanelOptions | undefined;
  data: DataFrame[];
}

/**
 * Functional component for adding and configuring markers.
 *
 * @param {Props} props - The props for the AddMarker component.
 * @returns {React.ReactNode} The marker addition and configuration component.
 */
export function LinkDashboard({ layer, replaceVariables, options, data }: Props) {
  let { markersLink, queryOptions }: MarkersConfig = layer.options.config;
  const { fieldConfig }: any = options;
  const theme = useTheme();

  const dataLoad = () => {
    const reduceData: ReduceDataOptions = {
      calcs: options?.reduceOptions.calcs ?? [],
      fields: '/.*/',
      limit: 2000,
      values: true,
    };
    let newData: FieldDisplay[] = [];
    if (data!.length > 0 && replaceVariables && options) {
      newData = getFieldDisplayValues({
        fieldConfig,
        reduceOptions: reduceData,
        replaceVariables,
        theme: theme,
        data: data,
        timeZone: 'browser',
      });
    }
    // let tmarkerLink: QueryLinkMarker[] = [];
    newData.map((frame: any) => {
      if (frame.display.title === queryOptions.geohashQuerySelections.location) {
        // If frame.display.text does not exist in markerLink then add it
        // else do nothing
        const exists = markersLink.some((marker: QueryLinkMarker) => marker.location === frame.display.text);
        if (!exists) {
          const newMarker = {
            location: frame.display.text,
            url: '',
            name: frame.display.text,
          };
          markersLink = [...markersLink, newMarker];
        }
      }
    });
    const newOpt = setProperty(cloneDeep(layer.options), 'config.markersLink', markersLink);
    layer.onChange(newOpt);
  };
  const handleRemoveMarker = (index: number) => {
    const newMarkerLink = markersLink.filter((marker, idx) => idx !== index);
    const newOpt = setProperty(cloneDeep(layer.options), 'config.markersLink', newMarkerLink);
    layer.onChange(newOpt);
  };

  return (
    <>
      <div className="gf-form">
        <Button onClick={dataLoad} variant="secondary" size="sm">
          <i className="fa fa-refresh" aria-hidden="true"></i> <span style={{ marginLeft: '5px' }}>Reload Queries</span>
        </Button>
      </div>
      {markersLink &&
        markersLink.map((marker: QueryLinkMarker, index: number) => {
          return (
            <MarkerExpandQuery
              key={index}
              marker={marker}
              Idx={index}
              layer={layer}
              handleRemoveLink={handleRemoveMarker}
            />
          );
        })}
    </>
  );
}
