import React, { useState } from 'react';
import {
  Button,
  Select,
  Input,
  InlineField,
  InlineFieldRow,
  Modal,
  Field,
  useStyles,
  getAvailableIcons,
  Card,
  Icon,
  IconName,
} from '@grafana/ui';
import { DataFrame, GrafanaTheme } from '@grafana/data';
import { SymbolObj } from '../types/interface';
import { css } from 'emotion';
import { config } from '@grafana/runtime';

/**
 * @typedef {Object} LabelValue
 * @property {string} label
 * @property {IconName} value
 */
interface LabelValue {
  label: string;
  value: IconName;
}
/**
 * @typedef {Object} Props
 * @property {SymbolObj} symbol
 * @property {Array<DataFrame> | undefined} data
 * @property {function} onChange
 */
interface Props {
  symbol: SymbolObj;
  data: DataFrame[] | undefined;
  onChange: (symbol: SymbolObj) => void;
}

const sourceOptions = [
  { label: `${config.appName} Icons`, value: 'default' },
  { label: 'URL', value: 'url' },
];

/**
 * get the list of icons from backend
 * @function
 * @returns {Array<LabelValue>}
 */
const getUniconOptions = (): LabelValue[] => {
  const options = getAvailableIcons();
  const newOptions: LabelValue[] = [];
  options.map((option) => {
    newOptions.push({ label: option, value: option });
  });
  return newOptions;
};

// const getCustomOptions: LabelValue[] = [
//   { label: 'X', value: 'x' },
//   { label: 'Cross', value: 'cross' },
//   { label: 'Star', value: 'star' },
//   { label: 'Triangle', value: 'triangle' },
//   { label: 'Square', value: 'square' },
//   { label: 'Rectangle', value: 'rectangle' },
//   { label: 'Stacked', value: 'stacked' },
// ];

/**
 * @function
 * @param {Props}
 * @returns {JSX.Element}
 */
export const CustomSymbol: React.FC<Props> = ({ symbol, onChange, data }: Props) => {
  const [localSymbol, setLocalSymbol] = useState<SymbolObj>(symbol);
  const [isOpen, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const onModalSubmit = (e: any) => {
    // if sourceType == url => use it as it is
    // if sourceType == unicon => Update url to use it
    // if sourceType == custom => Use iconName while creating marker for map
    let Url = localSymbol.url;
    if (localSymbol.sourceType === 'default') {
      // If url is empty, we will create url for selected icon and use later
      if (localSymbol.iconName !== '') {
        Url = `public/img/icons/${localSymbol.sourceType}/${localSymbol.iconName}.svg`;
      }
    }

    onChange({
      ...localSymbol,
      url: Url,
    });
    setLocalSymbol({
      ...localSymbol,
      url: Url,
    });
    setOpen(false);
  };

  const onSourceChange = ({ value }: any) => {
    setLocalSymbol({
      ...localSymbol,
      sourceType: value,
    });
  };

  const onIconChange = (value: string) => {
    setLocalSymbol({
      ...localSymbol,
      iconName: value,
    });
  };

  const onUrlChange = (e: string) => {
    setLocalSymbol({
      ...localSymbol,
      url: e,
    });
  };

  const styles = useStyles(getStyles);
  /**
   * @function
   * @param {Array<LabelValue>} items
   * @returns {JSX.Element}
   */
  const prepareCard = (items: LabelValue[]) => {
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {items.map((item: LabelValue, idx: number) => (
          <Card
            style={{ width: '31%', margin: '3px' }}
            heading={item.label}
            key={idx}
            onClick={() => onIconChange(item.value)}
          >
            <Card.Figure>
              <Icon size="md" type="default" name={item.value as any} />
            </Card.Figure>
          </Card>
        ))}
      </div>
    );
  };

  return (
    <>
      {/* modal to select icon/image */}
      {isOpen && (
        <Modal
          className={styles.wrapper}
          isOpen={isOpen}
          title="Select Icon"
          onDismiss={() => setOpen(false)}
          closeOnEscape
        >
          <>
            <div>
              <Field label="Source">
                <Select
                  options={sourceOptions}
                  onChange={(e) => onSourceChange(e)}
                  value={sourceOptions.find((item: any) => item.value === localSymbol.sourceType)}
                />
              </Field>
              {localSymbol.sourceType === 'url' && (
                <Field label="URL">
                  <Input onChange={(e) => onUrlChange(e.currentTarget.value)} value={localSymbol.url} />
                </Field>
              )}
            </div>
            {localSymbol.sourceType === 'default' && (
              <div className={styles.internal}>{prepareCard(getUniconOptions())}</div>
            )}
            <div className={styles.button}>
              <Button variant="primary" style={{ marginRight: '10px' }} onClick={(e) => onModalSubmit(e)}>
                Select
              </Button>
              <Button variant="secondary" onClick={() => setOpen(false)}>
                Cancel
              </Button>
            </div>
          </>
        </Modal>
      )}
      {/* show icon/image and its name */}
      <InlineFieldRow onClick={openModal}>
        <InlineField label={null} grow>
          <Input
            value={
              localSymbol.url
                ? localSymbol.url.substr(localSymbol.url.lastIndexOf('/') + 1)
                : 'placemark_circle_highlight.png'
            }
            placeholder={'Select a Url'}
            readOnly={true}
            prefix={
              localSymbol.url ? (
                <img src={localSymbol.url} width={16} height={16} alt="img"></img>
              ) : (
                <img
                  src={'http://maps.google.com/mapfiles/kml/shapes/placemark_circle_highlight.png'}
                  width={16}
                  height={16}
                  alt="img"
                />
              )
            }
          />
        </InlineField>
      </InlineFieldRow>
    </>
  );
};

const getStyles = (theme: GrafanaTheme) => ({
  wrapper: css`
    display: flex;
    flex-flow: column;
    width: 50%;
    height: 60%;
  `,
  button: css`
    width: 100%;
    margin-top: 10px;
    justify-content: flex-end;
    display: flex;
  `,
  internal: css`
    height: 75%;
    overflow-y: scroll;
  `,
});
