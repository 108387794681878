import React, { HTMLAttributes, ReactNode } from 'react';
import { css, cx } from 'emotion';
import { GrafanaTheme } from '@grafana/data';
import { stylesFactory, useTheme } from '../../themes';

/**
 * @public
 */
export interface CardInnerProps {
  href?: string;
  children?: ReactNode;
  disableEvents?: boolean;
  disableHover?: boolean;
  CardInnerClass?: string;
}

const CardInner = ({ children, href, disableEvents, disableHover, CardInnerClass }: CardInnerProps) => {
  const theme = useTheme();
  const { inner } = getCardContainerStyles(theme, disableEvents, disableHover);
  return href ? (
    <a className={cx(inner, CardInnerClass)} href={href}>
      {children}
    </a>
  ) : (
    <div className={cx(inner, CardInnerClass)}>{children}</div>
  );
};

/**
 * @public
 */
export interface CardContainerProps extends HTMLAttributes<HTMLOrSVGElement>, CardInnerProps {
  /** Disable pointer events for the Card, e.g. click events */
  disableEvents?: boolean;
  /** No style change on hover */
  disableHover?: boolean;
  /** Custom container styles */
  className?: string;
  CardInnerClass?: string;
}

export const CardContainer = ({
  href,
  children,
  disableEvents,
  disableHover,
  className,
  CardInnerClass,
  ...props
}: CardContainerProps) => {
  const theme = useTheme();
  const { container } = getCardContainerStyles(theme, disableEvents, disableHover);
  return (
    <div {...props} className={cx(container, className)}>
      <CardInner CardInnerClass={CardInnerClass} href={href}>
        {children}
      </CardInner>
    </div>
  );
};

const getCardContainerStyles = stylesFactory((theme: GrafanaTheme, disabled = false, disableHover = false) => {
  return {
    container: css({
      display: 'flex',
      width: '100%',
      // background: theme.colors.background.secondary,
      // borderRadius: theme.shape.borderRadius(),
      position: 'relative',
      pointerEvents: disabled ? 'none' : 'auto',
      // marginBottom: theme.spacing(1),
      // transition: theme.transitions.create(['background-color', 'box-shadow', 'border-color', 'color'], {
      //   duration: theme.transitions.duration.short,
      // }),

      ...(!disableHover && {
        '&:hover': {
          // background: theme.colors.emphasize(theme.colors.background.secondary, 0.03),
          cursor: 'pointer',
          zIndex: 1,
        },
        // '&:focus': styleMixins.getFocusStyles(theme),
      }),
    }),
    inner: css({
      display: 'flex',
      width: '100%',
      padding: theme.spacing.md,
    }),
  };
});
