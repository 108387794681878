import React from 'react';
import { Button } from '@grafana/ui';

interface Props {
  reloader: number;
  onChange: (reloader: number) => void;
}

export function Reloader(props: Props) {
  const forceReload = () => {
    props.onChange(props.reloader + 1);
  };
  return (
    <div className="width-20">
      <Button onClick={forceReload} variant="primary">
        <i className="fa fa-refresh" aria-hidden="true"></i>
      </Button>
    </div>
  );
}
