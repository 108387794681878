import React, { FunctionComponent, useContext } from 'react';
import { css, cx } from 'emotion';

import { GrafanaTheme } from '@grafana/data';
import { ThemeContext } from '../../themes/ThemeContext';
import { stylesFactory } from '../../themes/stylesFactory';
import { Icon } from '../Icon/Icon';
import { Spinner } from '../Spinner/Spinner';

const getStyles = stylesFactory((theme: GrafanaTheme) => ({
  collapse: css`
    label: collapse;
    // margin-bottom: ${theme.spacing.sm};
  `,
  collapseBody: css`
    label: collapse__body;
    padding: 0 ${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.sm};
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  `,
  bodyContentWrapper: css`
    label: bodyContentWrapper;
    flex: 1;
    overflow: hidden;
  `,
  loader: css`
    label: collapse__loader;
    height: 2px;
    position: relative;
    overflow: hidden;
    background: none;
    margin: ${theme.spacing.xs};
  `,
  loaderActive: css`
    label: collapse__loader_active;
    &:after {
      content: ' ';
      display: block;
      width: 25%;
      top: 0;
      top: -50%;
      height: 250%;
      position: absolute;
      animation: loader 2s cubic-bezier(0.17, 0.67, 0.83, 0.67) 500ms;
      animation-iteration-count: 100;
      left: -25%;
      background: ${theme.palette.blue85};
    }
    @keyframes loader {
      from {
        left: -25%;
        opacity: 0.1;
      }
      to {
        left: 100%;
        opacity: 1;
      }
    }
  `,
  header: css`
    label: collapse__header;
    padding: ${theme.spacing.sm} ${theme.spacing.md};
    display: flex;
    cursor: inherit;
    transition: all 0.1s linear;
    cursor: pointer;
  `,
  headerCollapsed: css`
    label: collapse__header--collapsed;
    cursor: pointer;
    padding: ${theme.spacing.sm} ${theme.spacing.md};
  `,
  headerButtons: css`
    label: collapse__header-buttons;
    margin-right: ${theme.spacing.sm};
    margin-top: ${theme.spacing.xxs};
    font-size: ${theme.typography.size.lg};
    line-height: ${theme.typography.heading.h6};
    display: inherit;
  `,
  headerButtonsCollapsed: css`
    label: collapse__header-buttons--collapsed;
    display: none;
  `,
  headerLabel: css`
    label: collapse__header-label;
    font-weight: ${theme.typography.weight.semibold};
    margin-right: ${theme.spacing.sm};
    font-size: ${theme.typography.heading.h6};
  `,
  panelHeaderBox: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
}));

export interface Props {
  /** Expand or collapse te content */
  isOpen?: boolean;
  /** Element or text for the Collapse header */
  label: React.ReactNode;
  /** Toggle collapsed header icon */
  collapsible?: boolean;
  /** Callback for the toggle functionality */
  onToggle?: (isOpen: boolean) => void;
  status?: string;
  cronJobStatus?: string;
}

export const CollapseArrowForecast: FunctionComponent<Props> = ({
  isOpen,
  label,
  collapsible,
  onToggle,
  children,
  status,
}) => {
  const theme = useContext(ThemeContext);
  const styles = getStyles(theme);
  const onClickToggle = () => {
    if (onToggle) {
      onToggle(!isOpen);
    }
  };

  const panelClass = cx([styles.collapse, 'panel-container']);
  // const loaderClass = loading ? cx([styles.loader, styles.loaderActive]) : cx([styles.loader]);
  const headerClass = collapsible ? cx([styles.header]) : cx([styles.headerCollapsed]);
  const headerButtonsClass = collapsible ? cx([styles.headerButtons]) : cx([styles.headerButtonsCollapsed]);

  return (
    <div className={panelClass}>
      <div className={styles.panelHeaderBox} onClick={onClickToggle}>
        <div className={headerClass}>
          {status === 'completed' || status === 'imported' || status === 'done' ? (
            <div style={{ paddingRight: '2px' }}>
              <Icon size="sm" name="heart" style={{ color: 'green' }} className={`alert-rule-item__icon`} />
            </div>
          ) : status === 'failed' || status === 'error' ? (
            <div style={{ paddingRight: '2px' }}>
              <Icon size="sm" name={'heart-break'} style={{ color: 'red' }} className={`alert-rule-item__icon`} />
            </div>
          ) : status === 'active' ? (
            <div style={{ paddingRight: '2px' }}>
              <Icon
                size="sm"
                name={'heart'}
                style={{ color: 'blue', fontSize: '16px' }}
                className={`alert-rule-item__icon`}
              />
            </div>
          ) : status === 'processing' || status === 'waiting' ? (
            <div style={{ paddingRight: '10px' }}>
              <Spinner />
            </div>
          ) : (
            <></>
          )}

          <div className={cx([styles.headerLabel])}>{label}</div>
        </div>
        <div className={headerButtonsClass}>
          <Icon name={isOpen ? 'chevron-up' : 'chevron-down'} />
        </div>
      </div>
      {isOpen && (
        <div className={cx([styles.collapseBody])}>
          {/* <div className={loaderClass} /> */}
          <div className={styles.bodyContentWrapper}>{children}</div>
        </div>
      )}
    </div>
  );
};

CollapseArrowForecast.displayName = 'CollapseArrowForecast';
