import { FieldDisplay } from '@grafana/data';
import React from 'react';
import { Gauge } from './Gauge';

interface Props {
  valueField: number;
  height: number;
  width: number;
  customColor: string;
  showNum: boolean;
  showName: boolean;
  fontSize: string;
  fontSizeCDL: string;
  frame: FieldDisplay;
}

function DGauge({ fontSizeCDL, valueField, height, width, customColor, showName, showNum, fontSize, frame }: Props) {
  const gaugevalue = Number(valueField.toFixed(2));
  let value;
  if (isNaN(gaugevalue)) {
    value = 0;
  } else {
    value = gaugevalue;
  }
  const arcSegments = [{ min: 0, max: 1, color: customColor }];
  return (
    <>
      <Gauge
        height={height / 2}
        width={width / 2}
        min={0}
        max={1}
        // key={1}
        value={value}
        disabled={false}
        tickCount={11}
        arcSegments={arcSegments}
      />
      {showNum && (
        <span
          style={{
            fontSize: fontSizeCDL + 'px',
            textAlign: 'center',
            color: customColor,
            fontWeight: 'bold',
            marginTop: '5px',
            // position: 'absolute',
            //   bottom: '30%',
          }}
        >
          {(frame.display.prefix || '') + frame.display.text + (frame.display.suffix || '')}
        </span>
      )}

      {showName && (
        <span
          style={{
            fontSize: fontSize + 'px',
            textAlign: 'center',
            marginBottom: '10px',
            // position: 'absolute',
            // bottom: '20%',
          }}
        >
          {frame.display.title}
        </span>
      )}
    </>
  );
}

export default DGauge;
