import React, { FC, useMemo } from 'react';
import Chart from 'react-apexcharts';
import { FieldDisplay, GrafanaTheme, FieldConfigSource, DisplayValue } from '@grafana/data';
import { CircleChartOptions } from '../types';
import { unitDataProducer } from './config';

interface CircleProps {
  bigData: FieldDisplay[];
  width: number;
  height: number;
  options: CircleChartOptions;
  theme: GrafanaTheme;
  renderCounter: number;
  fieldConfig: FieldConfigSource<any>;
  timeZone: string;
}

export const Polar: FC<CircleProps> = ({
  width,
  height,
  bigData,
  options,
  fieldConfig,
  theme,
  renderCounter,
  timeZone,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { seriesData, colorset, localeLabels, tooltipData, force } = useMemo(
    () => computeData(bigData, options, fieldConfig, timeZone),
    [renderCounter, bigData]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const chartOptions: any = useMemo(() => generateOptions(tooltipData, options, colorset, localeLabels, theme), [
    force,
  ]);
  if (!options.polarAxis) {
    chartOptions['stroke'] = {
      width: 1,
    };
    chartOptions['plotOptions'] = {
      polarArea: {
        rings: {
          strokeWidth: 0,
        },
      },
    };
  }
  return (
    <div style={{ position: 'relative', width, height }}>
      <Chart key={force} options={chartOptions} series={seriesData} type="polarArea" width={width} height={height} />
    </div>
  );
};

const computeData = (
  bigData: FieldDisplay[],
  options: CircleChartOptions,
  fieldConfig: FieldConfigSource<any>,
  timeZone: string
) => {
  const { limit, limit2, limitLabels } = options;
  const seriesData: any = [];
  const tooltipData: DisplayValue[] = [];
  let colorset: any = [];
  const localeLabels: any = [];
  const units = Number(fieldConfig.defaults.unit) || 0;
  if (limit2 === 'top') {
    bigData.sort((a: any, b: any) => b.display.numeric - a.display.numeric);
  } else if (limit2 === 'bottom') {
    bigData.sort((a: any, b: any) => a.display.numeric - b.display.numeric);
  }

  if (limitLabels && bigData.length >= limit) {
    for (let i = 0; i < limit; i++) {
      localeLabels.push(bigData[i].display.title);
      seriesData.push(Number(bigData[i].display.numeric.toFixed(units)));
      colorset.push(bigData[i].display.color);
      tooltipData.push(bigData[i].display);
    }
    if (options.limitRemaining) {
      let remData = 0;
      for (let i = limit; i < bigData.length; i++) {
        remData += Number(bigData[i].display.numeric.toFixed(units));
      }
      localeLabels.push('Others');
      seriesData.push(remData);
      colorset.push(bigData[limit].display.color);
      const response: DisplayValue = unitDataProducer(remData, timeZone, fieldConfig);
      tooltipData.push(response);
    }
  } else {
    for (let i = 0; i < bigData.length; i++) {
      localeLabels.push(bigData[i].display.title);
      seriesData.push(Number(bigData[i].display.numeric.toFixed(units)));
      colorset.push(bigData[i].display.color);
      tooltipData.push(bigData[i].display);
    }
  }
  return {
    seriesData,
    colorset,
    localeLabels,
    tooltipData,
    force: Math.floor(Math.random() * 100000),
  };
};

const generateOptions = (
  tooltipData: DisplayValue[],
  options: CircleChartOptions,
  colorset: string[],
  localeLabels: string[],
  theme: GrafanaTheme
) => {
  return {
    chart: {
      animations: {
        enabled: false,
      },
      type: 'polarArea',
    },
    stroke: {
      colors: ['#fff'],
    },
    fill: {
      opacity: 1,
    },
    yaxis: {
      show: false,
    },
    colors: colorset,
    labels: localeLabels,
    legend: {
      show: options.legendShow,
      floating: options.legendFloat,
      position: options.legendOrientation,
      labels: {
        colors: theme.isDark ? '#d8d9da' : undefined,
      },
    },
    dataLabels: {
      enabled: options.dataLabels,
      style: {
        colors: theme.isLight ? ['#373D3F'] : ['#d8d9da'],
      },
      dropShadow: {
        enabled: false,
      },
      background: {
        enabled: false,
      },
      formatter(val: any, opts: any) {
        const name = opts.w.globals.labels[opts.seriesIndex];
        return [name, val.toFixed(2) + '%'];
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      theme: 'light',
      onDatasetHover: {
        highlightDataSeries: true,
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
        const item = tooltipData[seriesIndex];
        return (
          '<div class="apexcharts-tooltip-series-group" style="display: flex;">' +
          '<span class="apexcharts-tooltip-marker" style="background-color:' +
          w.globals.colors[seriesIndex] +
          '"></span>' +
          '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; color: black">' +
          '<div class="apexcharts-tooltip-y-group">' +
          '<span class="apexcharts-tooltip-text-label">' +
          w.globals.labels[seriesIndex] +
          ':' +
          '</span>' +
          '<span class="apexcharts-tooltip-text-value">' +
          // series[seriesIndex] +
          (item.prefix || '') +
          item.text +
          (item.suffix || '') +
          '</span>' +
          '</div>' +
          '</div>' +
          '</div>'
        );
      },
    },
  };
};
