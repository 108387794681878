import { DataFrame, FieldDisplay } from '@grafana/data';
import { BoxTimeData } from './interface';
import { findMedian } from './BoxDataCalc';

/**
 * calculate box plot data when time series selected
 * @function
 * @param {Array<DataFrame>} mainData
 * @param {number} toCluster
 * @param {number} timeRange
 * @param {Array<FieldDidplay>} getData
 * @param {boolean} clusterSwitch
 * @param {nuber} customCluster
 * @returns {BoxTimeData} Box Time data
 */

export const calculateBoxTimeData = (
  mainData: DataFrame[],
  toCluster: number,
  timeRange: number,
  getData: FieldDisplay[],
  customCluster: number
) => {
  const boxTimeData: BoxTimeData = {};
  let timeArray: number[] = [];
  let indexArray: number[] = [];
  let valueArray: [] = [];
  let medArray: number[] = [];
  let q1Array: number[] = [];
  let q3Array: number[] = [];
  let minArray: number[] = [];
  let maxArray: number[] = [];
  let timeMs = toCluster === 0 ? customCluster * 60 * 1e3 : toCluster * 60 * 1e3;
  /**
   * things working for single query,
   * specially first query
   */
  const timeObj: any = mainData[0].fields[0].values;
  const valueObj: any = mainData[0].fields[1].values;
  let fulltimeArray: number[] = timeObj.buffer;
  let initialTime: number = timeObj.buffer[0];

  /**
   * interval from time range and time in millisecond
   */
  let interval = Math.round(timeRange / timeMs);
  /**
   * interval from time array
   */
  const intervalTime = fulltimeArray[1] - fulltimeArray[0];
  /**
   * creating time array according to cluster
   */
  for (let idx = 1; idx <= interval; idx++) {
    let newtime = initialTime + timeMs * idx;
    const found = fulltimeArray.find((ele: number) => {
      return ele >= newtime;
    });
    if (found) {
      if (found === newtime) {
        timeArray.push(found);
      } else {
        timeArray.push(found - intervalTime);
      }
    }
  }
  /**
   * creating index array by finding out same value as present in time array and fulltimearray
   */
  for (let i = 0; i < timeArray.length; i++) {
    let index = fulltimeArray.findIndex((ele: number) => {
      return ele === timeArray[i];
    });
    indexArray.push(index);
  }
  valueArray = valueObj.buffer;

  /**
   * creating all required arrays
   */
  for (let i = 0; i < indexArray.length; i++) {
    let newArray: number[] = valueArray.slice(indexArray[i - 1], indexArray[i]);
    newArray.sort((a, b) => {
      return a - b;
    });
    for (let i = 0; i < newArray.length; i++) {
      if (newArray[i] == null) {
        newArray[i] = 0;
      }
    }
    // even length of array
    if (newArray.length % 2 === 0 && newArray.length !== 0) {
      //  when length is even
      let med = findMedian(newArray, newArray.length);
      medArray.push(med);
      let preMedIndex = newArray.length / 2;
      let q1 = newArray[Math.floor(preMedIndex / 2) - 1];
      q1Array.push(q1);
      let q3 = newArray[Math.floor(preMedIndex + preMedIndex / 2) - 1];
      q3Array.push(q3);
    } else {
      // when length is odd
      let medIndex = Math.floor(newArray.length / 2);
      let med = newArray[medIndex];
      medArray.push(med);
      let preMedIndex = (newArray.length - 1) / 2;
      let q1 = newArray[Math.floor(preMedIndex / 2) - 1];
      q1Array.push(q1);
      let q3 = newArray[Math.floor(preMedIndex + preMedIndex / 2)];
      q3Array.push(q3);
    }
    minArray.push(newArray[0]);
    maxArray.push(newArray[newArray.length - 1]);
  }

  boxTimeData.time = {
    name: 'time',
    values: timeArray,
  };
  boxTimeData.median = {
    name: 'median',
    values: medArray,
  };
  boxTimeData.max = {
    name: 'max',
    values: maxArray,
  };
  boxTimeData.q1 = {
    name: 'q1',
    values: q1Array,
  };
  boxTimeData.q3 = {
    name: 'q3',
    values: q3Array,
  };
  boxTimeData.min = {
    name: 'min',
    values: minArray,
  };
  boxTimeData.color = getData[0].display.color;
  boxTimeData.name = getData[0].display.title;
  return boxTimeData;
};
