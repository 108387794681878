import { sharedSingleStatMigrationHandler } from '@grafana/ui';
import { PanelPlugin } from '@grafana/data';
import { TrendPanelOptions, addStandardDataReduceOptions } from './types';
import { TrendPanel } from './TrendPanel';
import { statPanelChangedHandler } from './StatMigrations';

export const plugin = new PanelPlugin<TrendPanelOptions>(TrendPanel)
  .useFieldConfig()
  .setPanelOptions((builder) => {
    addStandardDataReduceOptions(builder);

    builder
      .addRadio({
        path: 'querytype',
        name: 'Query Mode',
        // description: 'Fills the path with options',
        settings: {
          options: [
            { value: 'one', label: 'One Query' },
            { value: 'two', label: 'Two Query' },
          ],
        },
        defaultValue: 'one',
      })
      .addBooleanSwitch({
        path: 'percentage',
        name: 'Percentage Mode',
        description: 'Toggles between absolute and percentage modes',
        defaultValue: false,
      })
      .addTextInput({
        path: 'subtext',
        name: 'Subtext',
        defaultValue: 'Trend panel',
      });
  })
  .setNoPadding()
  .setPanelChangeHandler(statPanelChangedHandler)
  .setMigrationHandler(sharedSingleStatMigrationHandler);
