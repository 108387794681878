import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const Preferences: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 64 64"
      viewBox="0 0 64 64"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M6.3,15.1h6.4c1.3,4.6,6,7.4,10.7,6.1c3-0.8,5.3-3.1,6.1-6.1h28.2c1.3,0,2.3-1,2.3-2.3s-1-2.3-2.3-2.3H29.5
        c-1.3-4.6-6-7.4-10.7-6.1c-3,0.8-5.3,3.1-6.1,6.1H6.3c-1.3,0-2.3,1-2.3,2.3S5,15.1,6.3,15.1z M21.1,8.6c2.3,0,4.1,1.8,4.1,4.1
        s-1.8,4.1-4.1,4.1S17,15,17,12.7C17,10.5,18.8,8.6,21.1,8.6z M57.7,29.7h-6.4c-1.3-4.6-6-7.4-10.7-6.1c-3,0.8-5.3,3.1-6.1,6.1H6.3
        C5,29.7,4,30.7,4,32c0,1.3,1,2.3,2.3,2.3h28.2c1.3,4.6,6,7.4,10.7,6.1c3-0.8,5.3-3.1,6.1-6.1h6.4c1.3,0,2.3-1,2.3-2.3
        C60,30.7,59,29.7,57.7,29.7z M42.9,36.1c-2.3,0-4.1-1.8-4.1-4.1s1.8-4.1,4.1-4.1c2.3,0,4.1,1.8,4.1,4.1C47,34.3,45.2,36.1,42.9,36.1
        z M57.7,48.9H29.5c-1.3-4.6-6-7.4-10.7-6.1c-3,0.8-5.3,3.1-6.1,6.1H6.3C5,48.9,4,50,4,51.3s1,2.3,2.3,2.3h6.4
        c1.3,4.6,6,7.4,10.7,6.1c3-0.8,5.3-3.1,6.1-6.1h28.2c1.3,0,2.3-1,2.3-2.3S59,48.9,57.7,48.9z M21.1,55.4c-2.3,0-4.1-1.8-4.1-4.1
        c0-2.3,1.8-4.1,4.1-4.1s4.1,1.8,4.1,4.1C25.2,53.5,23.4,55.4,21.1,55.4z"
      />
    </svg>
  );
};
