import { DataFrame, SelectableValue } from '@grafana/data';
import { FeatureLike } from 'ol/Feature';
import { OSMapLayerState } from '../types/types';
import { colorGradient } from '../utils/heatcolor';
import { QueryModes } from '../types/interface';

/**
 * Defines an array of label-value pairs representing layer options, such as layer types.
 *
 * @constant {LabelValue[]}
 */
export const layerOptions: LabelValue[] = [
  {
    label: 'GeoJson',
    value: 'geojson',
  },
  {
    label: 'Heatmap',
    value: 'heatmap',
  },
  {
    label: 'Marker',
    value: 'marker',
  },
  {
    label: 'Custom Heatmap',
    value: 'customHeatmap',
  },
  {
    label: 'Custom Marker',
    value: 'customMarker',
  },
];

/**
 * Represents label-value pairs for layer options, such as layer types.
 *
 * @interface LabelValue
 * @property {string} label - The label or display name for the option.
 * @property {string} value - The corresponding value for the option.
 */
export interface LabelValue {
  label: string;
  value: string;
}

/**
 * Defines an array of label-value pairs representing query modes for heatmaps.
 *
 * @constant {LabelValue[]}
 */
export const heatmapQueryMode: LabelValue[] = [
  {
    value: 'auto',
    label: 'Auto',
  },
  {
    value: 'geohash',
    label: 'Geohash',
  },
];

/**
 * Defines an array of label-value pairs representing color gradient options for heatmaps.
 *
 * @constant {LabelValue[]}
 */
export const heatmapColorGradient = [
  { value: colorGradient.defaultColor, label: 'Default Color' },
  { value: colorGradient.colorSpectrum, label: 'Color Spectrum' },
  { value: colorGradient.visibleSpectrum, label: 'Visible Spectrum' },
  { value: colorGradient.deepSea, label: 'Deep Sea' },
  { value: colorGradient.blackAqua, label: 'Black Aqua' },
  { value: colorGradient.heatedMetal, label: 'Heated Metal' },
  { value: colorGradient.sunRise, label: 'Sun Rise' },
  { value: colorGradient.incandescent, label: 'Incandescent' },
  { value: colorGradient.customPalette, label: 'Color Scheme' },
];

/**
 * Defines an array of label-value pairs representing symbol options for OpenStreetMap layers.
 *
 * @constant {LabelValue[]}
 */
export const openStreetSymbol: LabelValue[] = [
  { label: 'Circle', value: 'circle' },
  { label: 'X', value: 'x' },
  { label: 'Cross', value: 'cross' },
  { label: 'Star', value: 'star' },
  { label: 'Triangle', value: 'triangle' },
  { label: 'Square', value: 'square' },
  { label: 'Rectangle', value: 'rectangle' },
  { label: 'Custom', value: 'custom' },
];

/**
 * Defines an array of label-value pairs representing predefined GeoJSON sources.
 *
 * @constant {LabelValue[]}
 */
export const geojsonSource: LabelValue[] = [
  { value: 'public/maps/airports.geojson', label: 'Airports' },
  { value: 'public/maps/australia_states.geojson', label: 'Australia States' },
  { value: 'public/maps/countries.geojson', label: 'Countries' },
  { value: 'public/maps/usa_states.geojson', label: 'Usa States' },
  { value: 'public/maps/india_states_1.geojson', label: 'India States 1' },
  { value: 'public/maps/india_states_2.geojson', label: 'India States 2' },
  { value: 'public/maps/india_states_3.geojson', label: 'India States 3' },
  { value: 'public/maps/san_francisco_1.geojson', label: 'San Francisco 1' },
  { value: 'public/maps/san_francisco_2.geojson', label: 'San Francisco 2' },
  { value: 'public/maps/san_francisco_3.geojson', label: 'San Francisco 3' },
];

/**
 * Represents information about the content of a map layer, including its geometry type and properties.
 *
 * @interface LayerContentInfo
 * @property {GeometryTypeId} geometryType - The type of geometry used in the layer.
 * @property {Array<SelectableValue<string>>} properties - An array of selectable property values in the layer.
 */
export interface LayerContentInfo {
  geometryType: GeometryTypeId;
  properties: Array<SelectableValue<string>>;
}

/**
 * Defines a set of constants and configurations for map layers in the OpenStreetMap panel.
 *
 * @enum {string}
 */
export enum GeometryTypeId {
  Point = 'point',
  Line = 'line',
  Polygon = 'polygon',
  Any = '*any*',
}

/**
 * Represents the configuration options for map layers.
 *
 * @interface PropsLayer
 * @property {OSMapLayerState<any>} layer - The state of the map layer.
 * @property {DataFrame[]} [data] - An array of data frames associated with the layer.
 * @property {FeatureLike[]} [features] - An array of features associated with the layer.
 */
export interface PropsLayer {
  layer: OSMapLayerState<any>;
  data?: DataFrame[];
  features?: FeatureLike[];
}

export const QueryMode = [
  {
    value: QueryModes.Auto,
    label: 'Auto',
  },
  {
    value: QueryModes.Geohash,
    label: 'Geohash',
  },
  {
    value: QueryModes.Coords,
    label: 'Coords',
  },
  {
    value: QueryModes.Choro,
    label: 'Choro',
  },
];
