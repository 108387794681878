import React, { PureComponent } from 'react';
import appEvents from '../../app_events';
import TopSection from './TopSection';
import BottomSection from './BottomSection';
import config from 'app/core/config';
import { CoreEvents } from 'app/types';
import { Branding } from 'app/core/components/Branding/Branding';
import { Icon } from '@grafana/ui';

const homeUrl = config.appSubUrl || '/';

export class SideMenu extends PureComponent {
  toggleSideMenuSmallBreakpoint = () => {
    appEvents.emit(CoreEvents.toggleSidemenuMobile);
  };

  render() {
    const navIconColor = config.bootData?.user?.carbonPanelIcon ?? '';
    const mainClassName =
      (window.innerHeight ?? document.documentElement.clientHeight) < 550
        ? 'sidemenu__main sidemenu__small_height'
        : 'sidemenu__main';

    return [
      <div key="mainsection" className={mainClassName}>
        <a href={homeUrl} className="sidemenu__logo" key="logo">
          <Branding.MenuLogo />
        </a>
        <div className="sidemenu__logo_small_breakpoint" onClick={this.toggleSideMenuSmallBreakpoint} key="hamburger">
          <Icon name="menu-2" size="xl" />
          <span className="sidemenu__close">
            <Icon name="x" />
            &nbsp;Close
          </span>
        </div>
        <TopSection key="topsection" navIconColor={navIconColor} />
        <BottomSection key="bottomsection" navIconColor={navIconColor} />
      </div>,
    ];
  }
}
