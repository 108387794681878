import React from 'react';
import { interpolateRgb } from 'd3-interpolate';

import { color } from 'd3-color';
// import LiquidFillGauge from 'react-liquid-gauge';
import LiquidFillGauge from './LiquidFillGauge';

import { FieldDisplay } from '@grafana/data';

interface Props {
  valueField: number;
  customColor: string;
  customHeight: number;
  customWidth: number;
  showNum: boolean;
  showName: boolean;
  fontSize: string;
  fontSizeCDL: string;
  frame: FieldDisplay;
  // customStyle: any;
}

export default function LiquidGauge({
  valueField,
  customColor,
  customHeight,
  customWidth,
  showNum,
  showName,
  fontSize,
  fontSizeCDL,
  frame,
}: Props) {
  const gaugevalue = Number(valueField.toFixed(2)) * 100;
  let value = 0;
  if (isNaN(gaugevalue)) {
    value = 0;
  } else {
    value = gaugevalue;
  }
  const startColor = customColor;
  const endColor = customColor;
  // let radius;
  // if (customWidth / 4 > 100) {
  //   radius = customWidth / 4 - 35;
  // } else {
  //   radius = customWidth / 4 - 25;
  // }
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = interpolate(value / 100);
  if (value === undefined) {
    return (
      <div>
        <h1>this is not cool</h1>
      </div>
    );
  } else {
    return (
      <>
        <LiquidFillGauge
          // style={{ margin: '0px 0px 7px' }}
          // width={radius * 2}
          // height={radius * 2}
          height={customHeight / 2}
          width={customWidth / 2}
          value={value}
          percent="%"
          textSize={1}
          textOffsetX={0}
          textOffsetY={0}
          textRenderer={(props: any) => {
            const value = Math.round(props.value);
            const radius = Math.min(props.height / 2, props.width / 2);
            const textPixels = (props.textSize * radius) / 2;
            const valueStyle = {
              fontSize: textPixels,
            };
            const percentStyle = {
              fontSize: textPixels * 0.6,
            };

            return (
              <tspan>
                <tspan className="value" style={valueStyle}>
                  {value}
                </tspan>
                <tspan style={percentStyle}>{props.percent}</tspan>
              </tspan>
            );
          }}
          riseAnimation
          waveAnimation
          waveFrequency={2}
          waveAmplitude={1}
          gradient
          circleStyle={{
            fill: fillColor,
          }}
          waveStyle={{
            fill: fillColor,
          }}
          textStyle={{
            //@ts-ignore
            fill: color('#444').toString(),
            fontFamily: 'Arial',
          }}
          waveTextStyle={{
            //@ts-ignore
            fill: color('#fff').toString(),
            fontFamily: 'Arial',
          }}
          // onClick={() => {
          //   setValue(Math.random() * 100);
          //   // this.setState({ value: Math.random() * 100 });
          // }}
        />
        {showNum && (
          <span
            style={{
              fontSize: fontSizeCDL + 'px',
              textAlign: 'center',
              color: customColor,
              fontWeight: 'bold',
            }}
          >
            {(frame.display.prefix || '') + frame.display.text + (frame.display.suffix || '')}
          </span>
        )}
        {showName && (
          <span
            style={{
              fontSize: fontSize + 'px',
              textAlign: 'center',
              marginBottom: '10px',
            }}
          >
            {frame.display.title}
          </span>
        )}
      </>
    );
  }
}
