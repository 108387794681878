import React, { useCallback } from 'react';
import { Arc } from '@visx/shape';
import { Group } from '@visx/group';
import { Chord, Ribbon } from '@visx/chord';
import { ChordChartOptions } from './types';
import { SeriesTable, SeriesTableRowProps, useStyles, useTheme } from '@grafana/ui';
import { localPoint } from '@visx/event';
import { FALLBACK_COLOR, FieldDisplay, GrafanaTheme } from '@grafana/data';
import { css } from 'emotion';
import { getTooltipContainerStyles } from '@grafana/ui/src/themes/mixins';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';

/**
 * @typedef {Object} ChordProps
 * @property {Array<FieldDisplay>} values
 * @property {number} width
 * @property {number} height
 * @property {any} data
 * @property {Array<string>} color
 * @property {ChordChartOptions} options
 * @property {number} [centerSize]
 */
export type ChordProps = {
  width: number;
  height: number;
  centerSize?: number;
  data: any;
  color: string[];
  options: ChordChartOptions;
  values: FieldDisplay[];
};
/**
 * chord chart svg
 * @param {ChordProps}
 * @returns {JSX.Element}
 */
export function ChordChartSvg({ width, height, centerSize = 5, data, color, options, values }: ChordProps) {
  const styles = useStyles(getStyles);
  const { tooltip: tooltipOptions } = options;
  const outerRadius = Math.min(width, height) * 0.5 - (centerSize + 10);
  const innerRadius = outerRadius - centerSize;

  // * Very basic structure of tooltip for any visx library in grafana
  // * Start of tooltip
  const tooltip = useTooltip<SeriesTableRowProps[][]>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });
  const showTooltip = tooltipOptions.mode !== 'none' && tooltip.tooltipOpen;
  const onMouseOut = useCallback(() => {
    tooltip.hideTooltip();
  }, [tooltip]);

  const onMouseOver = (event: any, chord: any) => {
    const coords = localPoint(event.target.ownerSVGElement, event);
    const sourceDisplay = values[chord.source.index].display;
    const targetDisplay = values[chord.target.index].display;
    const equal = chord.source.index === chord.target.index;
    const tooltipData = {
      tooltipLeft: coords!.x,
      tooltipTop: coords!.y,
      tooltipData: [
        [
          {
            color: sourceDisplay.color ?? FALLBACK_COLOR,
            label: `${sourceDisplay.title} to ${targetDisplay.title}` ?? '',
            value: `${sourceDisplay.prefix || ''} ${sourceDisplay.text} ${sourceDisplay.suffix || ''}`,
          },
        ],
        [
          {
            color: equal ? '' : targetDisplay.color ?? FALLBACK_COLOR,
            label: equal ? '' : `${targetDisplay.title} to ${sourceDisplay.title}` ?? '',
            value: equal ? null : `${targetDisplay.prefix || ''} ${targetDisplay.text} ${targetDisplay.suffix || ''}`,
          },
        ],
      ],
    };
    tooltip.showTooltip(tooltipData);
  };
  //  * end of tooltip

  if (innerRadius < 15 || outerRadius < 20) {
    return (
      <div className="panel-empty">
        <p>Increase width and height of the panel</p>
      </div>
    );
  }

  return width < 10 ? null : (
    <div className="chords">
      <svg width={width} height={height} ref={containerRef}>
        <Group top={height / 2} left={width / 2}>
          <Chord matrix={data} padAngle={0.05}>
            {({ chords }) => (
              <Group>
                {chords.map((chord, i) => {
                  return (
                    <Ribbon
                      key={`ribbon-${i}`}
                      chord={chord}
                      radius={innerRadius}
                      fill={color[chord.target.index]}
                      fillOpacity={0.75}
                      onMouseOver={(event: any) => onMouseOver(event, chord)}
                      onMouseOut={onMouseOut}
                    ></Ribbon>
                  );
                })}
                {chords.groups.map((group, i) => (
                  <Arc
                    key={`Arc-${i}`}
                    data={group}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={group.startAngle}
                    endAngle={group.endAngle}
                    fill={color[i]}
                  >
                    {({ path }) => {
                      return (
                        <>
                          <ArcLabel
                            path={path}
                            arc={group}
                            color={color[i]}
                            title={values[i].display.title}
                            showLabels={options.dataLabels}
                          />
                          ;
                        </>
                      );
                    }}
                  </Arc>
                ))}
              </Group>
            )}
          </Chord>
        </Group>
      </svg>
      {showTooltip ? (
        <TooltipInPortal
          key={Math.random()}
          top={tooltip.tooltipTop}
          className={styles.tooltipPortal}
          left={tooltip.tooltipLeft}
          unstyled={true}
          applyPositionStyle={true}
        >
          <SeriesTable series={tooltip.tooltipData![0]} />
          <SeriesTable series={tooltip.tooltipData![1]} />
        </TooltipInPortal>
      ) : null}
    </div>
  );
}

/**
 * @typedef {Object} ArcLabelArg
 * @property {any} path
 * @property {any} arc
 * @property {string} color
 * @property {string | undefined} title
 * @property {boolean} showLabels
 */
interface ArcLabelArg {
  path: any;
  arc: any;
  color: string;
  title: string | undefined;
  showLabels: boolean;
}
/**
 * draw labels on chord
 * @function
 * @param {ArcLabelArg}
 * @returns {JSX.Element}
 */
const ArcLabel = ({ path, arc, color, title, showLabels }: ArcLabelArg) => {
  const theme = useTheme();

  return (
    <>
      <g>
        <path d={path()} fill={color} stroke={theme.colors.bg1} strokeWidth={1} />
        {showLabels && (
          <text
            x={path.centroid(path)[0]}
            y={path.centroid(path)[1]}
            // dy=".33em"
            textAnchor="middle"
            pointerEvents="none"
            style={{
              fill: theme.colors.text,
              fontSize: 14,
            }}
          >
            <tspan>{title ?? ''}</tspan>
          </text>
        )}
      </g>
    </>
  );
};

const getStyles = (theme: GrafanaTheme) => {
  return {
    tooltipPortal: css`
      ${getTooltipContainerStyles(theme)}
    `,
  };
};
