import React from 'react';
import { scaleLinear } from '@visx/scale';
import { AxisTop as VxAxisTop } from '@visx/axis';

interface AxisTopI {
  domainMax: number;
  xMax: number;
  textColor: string;
}

const AxisTop = (props: AxisTopI) => {
  const { domainMax, xMax, textColor } = props;
  const numTicks = xMax > 500 ? 7 : Math.floor(xMax / 100);
  const xScaleForAxis = scaleLinear({
    domain: [0, domainMax],
    range: [0, xMax],
  });
  return (
    <VxAxisTop
      top={0}
      left={0}
      scale={xScaleForAxis}
      tickLabelProps={() => ({ textAnchor: 'middle', dy: '-0.25em', fontSize: 12, fill: textColor })}
      numTicks={numTicks}
    />
  );
};

export default AxisTop;
