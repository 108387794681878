import { InlineFormLabel, InlineSwitch, Input, Select } from '@grafana/ui';
import { cloneDeep } from 'lodash';
import React, { Component } from 'react';
import { sortFields, RuleI, RuleState } from './types';

interface Props {
  rule: RuleI;
  onChange: (rule: RuleI) => void;
}

export default class HandleEditor extends Component<Props, RuleState> {
  constructor(props: Props) {
    super(props);
  }

  onRuleChange = (name: string, value: any) => {
    var rule: any = cloneDeep(this.props.rule);
    rule[name] = value;
    this.setState({ rule: rule });
    this.props.onChange(rule);
  };

  onstateFiltersChange = (e: any) => {
    var rule: any = cloneDeep(this.props.rule);
    const condition = rule.stateFilters[e.target.name] === false ? true : false;
    rule.stateFilters[e.target.name] = condition;
    this.setState({ rule: rule });
    this.props.onChange(rule);
  };

  render() {
    return (
      <>
        <div>
          <h6 aria-disabled>Options</h6>
          <div className="gf-form">
            <InlineFormLabel>Max Items</InlineFormLabel>
            <Input
              type="number"
              value={this.props.rule.maxItems}
              name="maxItems"
              onChange={(e) => this.onRuleChange(e.currentTarget.name, parseInt(e.currentTarget.value, 10))}
            />
          </div>
          <div className="gf-form">
            <InlineFormLabel>Sort Order</InlineFormLabel>
            <Select
              options={sortFields}
              value={sortFields.find((item: any) => item.value === this.props.rule.sortOrder)?.value}
              onChange={(e) => this.onRuleChange('sortOrder', e.value)}
            />
          </div>
          <div className="gf-form">
            <InlineFormLabel width={100}>Alerts From This Dashboard</InlineFormLabel>
            <InlineSwitch
              name="thisDashboardOnly"
              value={this.props.rule.thisDashboardOnly}
              onClick={(e) => {
                const condition: boolean = this.props.rule.thisDashboardOnly === false ? true : false;
                this.onRuleChange(e.currentTarget.name, condition);
              }}
              checked={this.props.rule.thisDashboardOnly}
            />
          </div>
        </div>

        <div style={{ top: '1rem', position: 'relative' }}>
          <h6 aria-disabled>State Filter</h6>
          <div className="gf-form">
            <InlineFormLabel>Ok</InlineFormLabel>
            <InlineSwitch value={this.props.rule.stateFilters.ok} name="ok" onClick={this.onstateFiltersChange} />
          </div>
          <div className="gf-form">
            <InlineFormLabel>Normal</InlineFormLabel>
            <InlineSwitch
              name="normal"
              value={this.props.rule.stateFilters.normal}
              onClick={this.onstateFiltersChange}
            />
          </div>
          <div className="gf-form">
            <InlineFormLabel>Paused</InlineFormLabel>
            <InlineSwitch
              name="paused"
              value={this.props.rule.stateFilters.paused}
              onClick={this.onstateFiltersChange}
            />
          </div>
          <div className="gf-form">
            <InlineFormLabel>No Data</InlineFormLabel>
            <InlineSwitch
              name="nodata"
              value={this.props.rule.stateFilters.nodata}
              onClick={this.onstateFiltersChange}
            />
          </div>
          <div className="gf-form">
            <InlineFormLabel>Execution Error</InlineFormLabel>
            <InlineSwitch
              name="executionerror"
              value={this.props.rule.stateFilters.executionerror}
              onClick={this.onstateFiltersChange}
            />
          </div>
          <div className="gf-form">
            <InlineFormLabel>Alerting</InlineFormLabel>
            <InlineSwitch
              name="alerting"
              value={this.props.rule.stateFilters.alerting}
              onClick={this.onstateFiltersChange}
            />
          </div>
          <div className="gf-form">
            <InlineFormLabel>Pending</InlineFormLabel>
            <InlineSwitch
              name="pending"
              value={this.props.rule.stateFilters.pending}
              onClick={this.onstateFiltersChange}
            />
          </div>
        </div>
      </>
    );
  }
}
