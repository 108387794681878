import { StandardEditorProps } from '@grafana/data';
import { Button, InlineField, Input } from '@grafana/ui';
import React from 'react';

import { toLonLat } from 'ol/proj';
import { LABEL_WIDTH } from '../StyleEditor/GeojsonStyle';
import { OSMapPanelOptions, OSMapInstanceState } from '../types/types';

/**
 * Properties for a map view editor component.
 *
 * @typedef {StandardEditorProps<any, any, OSMapPanelOptions, OSMapInstanceState>} ViewEditorProps
 */
type ViewEditorProps = StandardEditorProps<any, any, OSMapPanelOptions, OSMapInstanceState>;

/**
 * A component for editing map control properties.
 *
 * @param {ViewEditorProps} props - The component's properties.
 * @returns {JSX.Element} A React JSX element representing the map control editor.
 */
export const ControlEditor = (props: ViewEditorProps) => {
  const { map } = (props.context.instanceState as OSMapInstanceState) ?? {};

  if (!map) {
    return <div>No Map</div>;
  }
  const { value, onChange } = props;
  const onSetView = () => {
    const view = map.getView();
    if (view.getCenter()) {
      const latlon = toLonLat(view.getCenter() || [0, 0]);

      onChange({
        ...value,
        zoom: Number(view.getZoom()).toFixed(2),
        center: { ...value.center, lat: Number(latlon[1].toFixed(5)), lng: Number(latlon[0].toFixed(5)) },
      });
    }
  };

  return (
    <>
      <InlineField label="Zoom" labelWidth={LABEL_WIDTH} grow={true}>
        <Input
          type="number"
          value={value.zoom}
          placeholder="numeric value"
          onChange={(e) => onChange({ ...value, zoom: Number(e.currentTarget.value) })}
        />
      </InlineField>
      <InlineField label="Latitude" labelWidth={LABEL_WIDTH} grow={true}>
        <Input
          type="number"
          value={value.center.lat}
          placeholder="numeric value"
          onChange={(e) => onChange({ ...value, center: { ...value.center, lat: Number(e.currentTarget.value) } })}
        />
      </InlineField>
      <InlineField label="Longitude" labelWidth={LABEL_WIDTH} grow={true}>
        <Input
          type="number"
          value={value.center.lng}
          placeholder="numeric value"
          onChange={(e) => onChange({ ...value, center: { ...value.center, lng: Number(e.currentTarget.value) } })}
        />
      </InlineField>
      <InlineField grow={true}>
        <Button fullWidth={true} size="sm" onClick={onSetView}>
          Set Current View
        </Button>
      </InlineField>
    </>
  );
};
