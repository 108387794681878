import { DisplayValue } from '@grafana/data';
import { HeatmapLayer } from '@react-google-maps/api';
import Geohash from 'latlon-geohash';
import React from 'react';
import { HeatmapI } from '../interface';

declare var google: any;
/**
 * heat map layer for google base map
 * @param {HeatMapI}
 * @returns {JSX.Element}
 */
export function HeatMapLayer({
  heatmap,
  bigData,
  bigData2,
  customMarkersHeat,
  options,
  frames,
  heatMapColors,
}: HeatmapI) {
  const heatmapData: any = [];
  let hashField: DisplayValue[] = [];
  let valueField: DisplayValue[] = [];

  if (options.isQueryForHeat && bigData.length > 0) {
    if (options.querySetting === 'auto' && frames) {
      hashField = [];
      valueField = [];
      let hashFieldName = '';
      let valueFieldName = '';
      for (const field of frames[0].fields) {
        if (field.type === 'string') {
          const hashData = field.values.toArray()[0];
          try {
            const latlon = Geohash.decode(hashData);
            if (latlon) {
              hashFieldName = field.name;
            }
          } catch (error) {}
        }
        if (field.type === 'number') {
          valueFieldName = field.name;
        }
      }
      for (let j = 0; j < bigData.length; j++) {
        if (bigData[j].display.title === valueFieldName) {
          valueField.push(bigData[j].display);
        } else if (bigData[j].display.title === hashFieldName) {
          hashField.push(bigData[j].display);
        }
      }
    }
    if (options.querySetting === 'geohash') {
      hashField = [];
      valueField = [];
      for (let j = 0; j < bigData.length; j++) {
        if (bigData[j].display.title === options.querySelections.value) {
          valueField.push(bigData[j].display);
        } else if (bigData[j].display.title === options.querySelections.geohash) {
          hashField.push(bigData[j].display);
        }
      }
    }
  }
  /**
   * pushing data to heatmap array
   */
  if (options.isQueryForHeat) {
    for (let j = 0; j < valueField.length; j++) {
      const latlon = Geohash.decode(hashField[j].text);
      if (heatmap.inWeight) {
        heatmapData.push({ location: new google.maps.LatLng(latlon.lat, latlon.lon), weight: valueField[j].numeric });
      } else {
        heatmapData.push(new google.maps.LatLng(latlon.lat, latlon.lon));
      }
    }
  }

  // * custom heat markers data
  if (bigData2.length > 0) {
    for (let k = 0; k < customMarkersHeat.markers.length; k++) {
      for (let j = 0; j < bigData2.length; j++) {
        if (
          customMarkersHeat.markers[k].query.length > 0 &&
          customMarkersHeat.markers[k].query[0].value === bigData2[j].display.title
        ) {
          if (heatmap.inWeight) {
            heatmapData.push({
              location: new google.maps.LatLng(customMarkersHeat.markers[k].lat, customMarkersHeat.markers[k].lng),
              weight: bigData2[j].display.numeric,
            });
          } else {
            heatmapData.push(
              new google.maps.LatLng(customMarkersHeat.markers[k].lat, customMarkersHeat.markers[k].lng)
            );
          }
        }
      }
    }
  }

  return (
    <HeatmapLayer
      data={heatmapData}
      options={{
        radius: heatmap.maxRadiusSize,
        gradient: heatMapColors,
        opacity: heatmap.opacity,
        dissipating: true,
        maxIntensity: heatmap.inWeight ? heatmap.maxIntensity : null,
      }}
    />
  );
}
