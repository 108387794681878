// Libraries
import React, { PureComponent } from 'react';
import { getDashboardSrv } from 'app/features/dashboard/services/DashboardSrv';

// Types
import { PanelProps } from '@grafana/data';
import { getAlertRulesAsync } from './actions';
import AlertCard from './AlertCard';
import { AlertOptions } from './types';
import { config } from '@grafana/runtime';
import { CustomScrollbar } from '@grafana/ui';

interface State {
  alertItems: [];
}

export const AlertList: React.FunctionComponent<Props> = (props) => {
  if (!config.ngAlertingEnabled) {
    return (
      <div className="panel-empty">
        <p>Please enable new alerts.</p>
      </div>
    );
  }
  return <AlertListHome {...props} />;
};

interface Props extends PanelProps<AlertOptions> {}

class AlertListHome extends PureComponent<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      alertItems: [],
    };
  }

  sortResultData(data: any) {
    const sortMethod = this.props.options.alertoptions.sortOrder;
    if (sortMethod === 'asc') {
      return data.sort((a: any, b: any) => a.title.localeCompare(b.title));
    } else if (sortMethod === 'desc') {
      return data.sort((a: any, b: any) => b.title.localeCompare(a.title));
    } else if (sortMethod === 'tasc') {
      return data.sort((a: any, b: any) => b.currentStateSince.localeCompare(a.currentStateSince));
    } else if (sortMethod === 'tdesc') {
      return data.sort((a: any, b: any) => a.currentStateSince.localeCompare(b.currentStateSince));
    } else {
      return data;
    }
  }

  filterData(data: any) {
    const state = this.props.options.alertoptions.stateFilters;
    var stateKeys = Object.keys(state);
    var filterFields = stateKeys.filter((item) => {
      return state[item];
    });
    if (filterFields.length === 0) {
      return data;
    } else {
      const finaldata = data.filter((item: any) => {
        var text = item.currentState.toLowerCase();
        if (state.ok === true && text === 'ok') {
          return true;
        } else if (state.normal === true && text === 'normal') {
          return true;
        } else if (state.paused === true && text === 'paused') {
          return true;
        } else if (state.nodata === true && text === 'nodata') {
          return true;
        } else if (state.executionerror === true && text === 'executionerror') {
          return true;
        } else if (state.alerting === true && text === 'alerting') {
          return true;
        } else if (state.pending === true && text === 'pending') {
          return true;
        } else {
          return false;
        }
      });
      return finaldata;
    }
  }

  checkAlertDashboard(data: any) {
    const thisDash = this.props.options.alertoptions.thisDashboardOnly;
    if (thisDash) {
      const dashboard = getDashboardSrv().getCurrent();
      const finaldata = data.filter((item: any) => {
        return item.dashboard_uid === dashboard.uid;
      });
      return finaldata;
    } else {
      return data;
    }
  }

  allAlertsSync = async () => {
    try {
      var result = await getAlertRulesAsync();
      result = this.checkAlertDashboard(result);
      result = this.sortResultData(result);
      const finalResult = this.filterData(result);
      this.setState({ alertItems: finalResult });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount(): void {
    this.allAlertsSync();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevProps.options.alertoptions !== this.props.options.alertoptions) {
      this.allAlertsSync();
    }
  }
  render() {
    return (
      <CustomScrollbar autoHeightMin="100%" autoHeightMax="100%">
        {this.state.alertItems.map((alertitem, index) => {
          if (index < this.props.options.alertoptions.maxItems) {
            return <AlertCard key={index} alertItem={alertitem} />;
          }
          return '';
        })}
      </CustomScrollbar>
    );
  }
}
