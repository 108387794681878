import React, { useCallback } from 'react';
import { PanelProps, FieldDisplay, getFieldDisplayValues, FALLBACK_COLOR } from '@grafana/data';
import { ChordChartOptions, Node, Chord, QueryNodeI } from './types';
import { useTheme } from '@grafana/ui';
import { ChordChart } from './ChordChart';
import { changeSeriesColorConfigFactory } from '../timeseries/overrides/colorSeriesConfigFactory';

/**
 * @typedef {Object} Props
 * @extends {PanelProps<ChordChartOptions>}
 */
interface Props extends PanelProps<ChordChartOptions> {}

// * The complete Chart based on data matrix
// * in that matrix two things are very important
// * Nodes-(Coming from MultiSelect)
// * Chords - (Coming from two selects)
/**
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export const ChordPanel: React.FC<Props> = ({
  height,
  options,
  width,
  onFieldConfigChange,
  fieldConfig,
  data,
  replaceVariables,
  timeZone,
}: Props) => {
  const onSeriesColorChange = useCallback(
    (label: string, color: string) => {
      onFieldConfigChange(changeSeriesColorConfigFactory(label, color, fieldConfig));
    },
    [fieldConfig, onFieldConfigChange]
  );

  const theme = useTheme();
  // * chord represent connection between two nodeArray
  let chordDataLocal: Chord[] = [];

  if (data.series.length <= 0) {
    return (
      <div className="panel-empty">
        <p>No data</p>
      </div>
    );
  }

  // * this array represents field display values of node elements
  // * this is necessary for legend-tooltip or where we want values other than data matrix
  let modifiedData: FieldDisplay[] = [];

  const getValues = (): FieldDisplay[] => {
    return getFieldDisplayValues({
      fieldConfig,
      reduceOptions: options.reduceOptions,
      replaceVariables,
      theme: theme,
      data: data.series,
      timeZone,
    });
  };
  const bigData: FieldDisplay[] = getValues();

  options.chordData.queryNode.map((item: QueryNodeI) => {
    let singleChordData: Chord;
    let old: any[] = []; // [sourceName, sourceValue]
    for (let i = 0; i < bigData.length; i++) {
      if (item.source.label === bigData[i].display.title) {
        old.push(item.source.label, bigData[i].display.numeric);
      }
      if (item.target.label === bigData[i].display.title) {
        singleChordData = {
          source: old[0],
          target: item.target.label,
          sourceWeight: old[1],
          targetWeight: bigData[i].display.numeric,
        };
        old = [];
        chordDataLocal.push(singleChordData);
      }
    }
  });
  let nodeArray: Node[] = [];
  let colorArray: string[] = [];
  for (let k = 0; k < options.chordData.links.length; k++) {
    let singleNode: Node;
    singleNode = {
      id: options.chordData.links[k].value,
      name: options.chordData.links[k].label,
      value: bigData[k].display.numeric,
    };
    for (let i = 0; i < bigData.length; i++) {
      if (options.chordData.links[k].label === bigData[i].display.title) {
        modifiedData.push(bigData[i]);
        colorArray.push(bigData[i].display.color ?? FALLBACK_COLOR);
      }
    }
    nodeArray.push(singleNode);
  }

  // * main matrix for visx chord chart always square matrix
  // * diagonal represent nodeArray whereas other elements represents chord data(inserting 0 at this time)
  let dataMatrix: any = [];
  for (let i = 0; i < nodeArray.length; i++) {
    let singleRow = [];
    for (let j = 0; j < nodeArray.length; j++) {
      if (i === j) {
        singleRow.push(nodeArray[j].value);
      } else {
        singleRow.push(0);
      }
    }
    dataMatrix.push(singleRow);
  }

  // * if user provided any chord then we need to add that chord to dataMatrix array
  // * at places other than diagonal
  if (chordDataLocal.length > 0) {
    chordDataLocal.forEach((chord: Chord, index: number) => {
      const sourceMatched = nodeArray.findIndex((node, i) => {
        return node.id === chord.source;
      });
      const targetMatched = nodeArray.findIndex((item, i) => {
        return item.id === chord.target;
      });

      if (sourceMatched !== -1 && targetMatched !== -1) {
        // * this is the beauty of visx chart you dont need to search for target and source
        // * just indexing gives you source and target (Data Matrix index Play Important Role)
        dataMatrix[sourceMatched][targetMatched] = chord.sourceWeight;
        dataMatrix[targetMatched][sourceMatched] = chord.targetWeight;
      }
    });
  }

  if (dataMatrix.length <= 0) {
    return (
      <div className="panel-empty">
        <p>Please select nodes</p>
      </div>
    );
  }

  if (width < 90 || height < 90) {
    return (
      <div className="panel-empty">
        <p>Increase width and height of the panel</p>
      </div>
    );
  }
  return (
    <ChordChart
      values={modifiedData}
      onSeriesColorChange={onSeriesColorChange}
      width={width}
      height={height}
      data={dataMatrix}
      color={colorArray}
      options={options}
    />
  );
};
