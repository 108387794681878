import React, { FC } from 'react';
import { useTheme } from '@grafana/ui';
import { PanelProps, getFieldDisplayValues } from '@grafana/data';
import { CircleChartOptions } from './types';
import { Circle } from './components/Circle';
import { Polar } from './components/Polar';

interface Props extends PanelProps<CircleChartOptions> {}

export const CircleChart: FC<Props> = ({
  height,
  options,
  width,
  data,
  fieldConfig,
  replaceVariables,
  timeZone,
  renderCounter,
  timeRange,
}) => {
  const theme = useTheme();
  const bigData = getFieldDisplayValues({
    fieldConfig,
    reduceOptions: options.reduceOptions,
    replaceVariables,
    theme: theme,
    data: data.series,
    timeZone,
  });

  if (data.series.length <= 0) {
    return (
      <div className="panel-empty">
        <p>No data</p>
      </div>
    );
  }
  return (
    <div>
      {options.graphType === 'circle' ? (
        <Circle
          bigData={bigData}
          width={width}
          height={height}
          options={options}
          fieldConfig={fieldConfig}
          theme={theme}
          renderCounter={renderCounter}
          timeZone={timeZone}
          timeRange={timeRange}
        />
      ) : (
        <Polar
          bigData={bigData}
          width={width}
          height={height}
          options={options}
          fieldConfig={fieldConfig}
          theme={theme}
          renderCounter={renderCounter}
          timeZone={timeZone}
        />
      )}
    </div>
  );
};
