import React, { useCallback } from 'react';
import { DataFrame, DisplayValue, fieldReducers, getFieldDisplayName, reduceField } from '@grafana/data';

import {
  UPlotConfigBuilder,
  VizLegendItem,
  VizLegendOptions,
  VizLayout,
  VizLayoutLegendProps,
  VizLegend,
  GraphNGLegendEvent,
  AxisPlacement,
} from '@grafana/ui';
import { mapMouseEventToMode } from '../utils';
import { BoxPlotData } from '../types';
const defaultFormatter = (v: any) => (v == null ? '-' : v.toFixed(1));

/**
 * @typedef {Object} PlotLegendBoxProps
 * @property {Array<DataFrame>} data
 * @property {UPlotConfigBuilder} config
 * @property {string} plotType plot type
 * @property {string} boxPlotCateg
 * @property {BoxPlotData} dataMain
 * @property {Fucntion} [onSeriesColorChange]
 * @property {Function} [onLabelClick]
 * @extends {VizLegendOptions}
 * @extends {Omit<VizLayoutLegendProps, 'children'>}
 */

interface PlotLegendBoxProps extends VizLegendOptions, Omit<VizLayoutLegendProps, 'children'> {
  data: DataFrame[];
  config: UPlotConfigBuilder;
  plotType: string;
  boxPlotCateg: string;
  dataMain: BoxPlotData;
  onSeriesColorChange?: (label: string, color: string) => void;
  onLegendClick?: (event: GraphNGLegendEvent) => void;
}
/**
 * @function
 * @param {PlotLegendBoxProps} props
 * @returns {JSX.Element}
 */
export const PlotLegendBox: React.FC<PlotLegendBoxProps> = ({
  data,
  config,
  onSeriesColorChange,
  onLegendClick,
  placement,
  calcs,
  displayMode,
  plotType,
  dataMain,
  boxPlotCateg,
  ...vizLayoutLegendProps
}) => {
  const onLegendLabelClick = useCallback(
    (legend: VizLegendItem, event: React.MouseEvent) => {
      const { fieldIndex } = legend;

      if (!onLegendClick || !fieldIndex) {
        return;
      }

      onLegendClick({
        fieldIndex,
        mode: mapMouseEventToMode(event),
      });
    },
    [onLegendClick]
  );
  const legendItems = config
    .getSeries()
    .map<VizLegendItem | undefined>((s) => {
      const seriesConfig = s.props;
      const fieldIndex = seriesConfig.dataFrameFieldIndex;
      const axisPlacement = config.getAxisPlacement(s.props.scaleKey);

      if (seriesConfig.hideInLegend || !fieldIndex) {
        return undefined;
      }

      const field = data[fieldIndex.frameIndex]?.fields[fieldIndex.fieldIndex];

      if (!field) {
        return undefined;
      }

      const label = getFieldDisplayName(field, data[fieldIndex.frameIndex]!);
      if (boxPlotCateg !== 'normal' && fieldIndex.fieldIndex > 1) {
        return undefined;
      }
      return {
        disabled: !seriesConfig.show ?? false,
        fieldIndex,
        color: dataMain.box?.color?.values[fieldIndex.fieldIndex - 1]!,
        label,
        yAxis: axisPlacement === AxisPlacement.Left ? 1 : 2,
        getDisplayValues: () => {
          if (!calcs?.length) {
            return [];
          }

          const fmt = field.display ?? defaultFormatter;
          const fieldCalcs = reduceField({
            field,
            reducers: calcs,
          });

          return calcs.map<DisplayValue>((reducer) => {
            return {
              ...fmt(fieldCalcs[reducer]),
              title: fieldReducers.get(reducer).name,
            };
          });
        },
        getItemKey: () => `${label}-${fieldIndex.frameIndex}-${fieldIndex.fieldIndex}`,
      };
    })
    .filter((i) => i !== undefined) as VizLegendItem[];

  return (
    <VizLayout.Legend placement={placement} {...vizLayoutLegendProps}>
      <VizLegend
        onLabelClick={onLegendLabelClick}
        placement={placement}
        items={legendItems}
        displayMode={displayMode}
        onSeriesColorChange={onSeriesColorChange}
      />
    </VizLayout.Legend>
  );
};

PlotLegendBox.displayName = 'PlotLegendBox';
