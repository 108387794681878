/**
 * @constant
 * @type {Array<string>}
 * @default
 */
const defaultColor = ['rgba(0, 0, 0, 0)', '#00f', '#0ff', '#0f0', '#ff0', '#f00'];
/**
 * @constant
 * @type {Array<string>}
 * @default
 */
const colorSpectrum = [
  'rgba(0, 0, 0, 0)',
  'rgba(0, 255, 255, 1)',
  'rgba(0, 191, 255, 1)',
  'rgba(0, 127, 255, 1)',
  'rgba(0, 63, 255, 1)',
  'rgba(0, 0, 255, 1)',
  'rgba(0, 0, 223, 1)',
  'rgba(0, 0, 191, 1)',
  'rgba(0, 0, 159, 1)',
  'rgba(0, 0, 127, 1)',
  'rgba(63, 0, 91, 1)',
  'rgba(127, 0, 63, 1)',
  'rgba(191, 0, 31, 1)',
  'rgba(255, 0, 0, 1)',
];
/**
 * @constant
 * @type {Array<string>}
 * @default
 */
const visibleSpectrum = [
  'rgba(0, 0, 0, 0)',
  'rgb(255,0,255)',
  'rgb(0,0,255)',
  'rgb(0,255,0)',
  'rgb(255,255,0)',
  'rgb(255,0,0)',
];
/**
 * @constant
 * @type {Array<string>}
 * @default
 */
const blackAqua = ['rgba(0, 0, 0,0)', 'rgb(0, 0, 0)', 'rgb(0, 0, 255)', 'rgb(255,255,255)'];
/**
 * @constant
 * @type {Array<string>}
 * @default
 */
const deepSea = [
  'rgba(0, 0, 0, 0)',
  'rgb(0, 0, 0)',
  'rgb(24, 53, 103)',
  'rgb(46, 100, 158)',
  'rgb(23, 173, 203)',
  'rgb(0, 250, 250)',
];
/**
 * @constant
 * @type {Array<string>}
 * @default
 */
const incandescent = ['rgba(0, 0, 0, 0)', 'rgb(0,0,0)', 'rgb(139,0,0)', 'rgb(255,255,0)', 'rgb(255,255,255)'];
/**
 * @constant
 * @type {Array<string>}
 * @default
 */
const heatedMetal = [
  'rgba(0, 0, 0, 0)',
  'rgb(0,0,0)',
  'rgb(128,0,128)',
  'rgb(255,0,0)',
  'rgb(255,255,0)',
  'rgb(255,255,255)',
];
/**
 * @constant
 * @type {Array<string>}
 * @default
 */
const sunRise = ['rgba(0, 0, 0, 0)', 'rgb(255,0,0)', 'rgb(255,255,0)', 'rgb(255,255,255)'];

/**
 * @readonly
 * @enum {number}
 */
export enum colorGradient {
  defaultColor = 'defaultColor',
  colorSpectrum = 'colorSpectrum',
  incandescent = 'incandescent',
  deepSea = 'deepSea',
  heatedMetal = 'heatedMetal',
  sunRise = 'sunRise',
  visibleSpectrum = 'visibleSpectrum',
  blackAqua = 'blackAqua',
  customPalette = 'customPalette',
}
/**
 * @constant
 * @type {Object<Array<string>>}
 */
export const colorGradientArray: { [key: string]: string[] } = {
  defaultColor: defaultColor,
  colorSpectrum: colorSpectrum,
  incandescent: incandescent,
  deepSea: deepSea,
  heatedMetal: heatedMetal,
  sunRise: sunRise,
  visibleSpectrum: visibleSpectrum,
  blackAqua: blackAqua,
};
