import React, { forwardRef } from 'react';
import { useTransition, animated } from 'react-spring';
import Bar from './Bar';

const AnimatedBar = animated(Bar);

const RacingBarGroup = forwardRef(({ frameData, xScale, yScale, colorScale, textColor }: any, ref): any => {
  // @ts-ignore
  const transitions = useTransition(
    frameData.map(({ name, value }: any, idx: any) => ({
      y: yScale(idx),
      width: xScale(value),
      value,
      name,
    })),
    (d: any) => d.name,
    {
      initial: (d: any) => d,
      from: { y: yScale.range()[1], width: 0, value: 0 },
      leave: { y: yScale.range()[1], width: 0, value: 0 },
      enter: (d) => d,
      update: (d) => d,
      unique: true,
      ref,
    }
  );
  return transitions.map(({ item, props }: any) => {
    const { y, value, width } = props;
    const { name } = item;
    return (
      <AnimatedBar
        x={xScale(0)}
        y={y}
        width={width}
        height={yScale.bandwidth()}
        color={colorScale(name)}
        value={value.interpolate((v: any) => v.toFixed())}
        name={name}
        key={name}
        textColor={textColor}
      />
    );
  });
});

export default RacingBarGroup;
