import { SceneComponent, ComponentOutput } from '../SceneComponent';
import {
  Object3D,
  AnimationMixer,
  AnimationAction,
  LoopOnce,
  AnimationClip,
  Mesh,
  Texture,
  MeshLambertMaterial,
} from 'three';
import { Painter2dI } from './CanvasRenderer';
import { PlaneRenderer, Size } from './PlaneRenderer';

const HoverEvent = 'hover';
const UnhoverEvent = 'unhover';
const RepaintEvent = 'repaint';

type Inputs = {
  loadingState: string;
  texture: Texture | null;
  updateInterval: number;
  data: number;
  srcPosition: { x: number; y: number };
  srcSize: Size;
  destPosition: { x: number; y: number };
  destSize: Size;
  src: string | null;
};

type Outputs = {
  painter: Painter2dI | null;
  visible: boolean;
} & ComponentOutput;

class DashboardLoader extends SceneComponent {
  private daeComponent: SceneComponent;
  private mixer: AnimationMixer | null = null;
  private onEnterClip: AnimationClip | null = null;
  private mesh: Mesh | null = null;
  private currentTime = 0;
  private nextUpdate = 0;
  // private temperature: number = 0;
  // private tempChangeRange: number = 5;
  private image: HTMLImageElement | null = null;

  inputs: Inputs = {
    loadingState: 'Idle',
    texture: null,
    updateInterval: 3000,
    data: 100,
    srcPosition: { x: 0, y: 0 },
    srcSize: { w: 64, h: 64 },
    destPosition: { x: 0, y: 0 },
    destSize: { w: 64, h: 64 },
    src:
      'https://images.unsplash.com/photo-1598642327181-c4ea0e55025b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1042&q=80',
  };

  outputs = {
    painter: null,
    visible: false,
  } as Outputs;

  events = {
    [HoverEvent]: true,
    [UnhoverEvent]: true,
  };

  onInit() {
    const root = this.context.root;
    const THREE = this.context.three;

    let planeRenderer: PlaneRenderer;
    for (const component of root.componentIterator()) {
      if (component.componentType === 'mp.daeLoader') {
        this.daeComponent = component;
      } else if (component.componentType === 'mp.planeRenderer') {
        planeRenderer = component as PlaneRenderer;
        // @ts-ignore
        planeRenderer.outputs.objectRoot.translateZ(0.05);
        // @ts-ignore
        planeRenderer.outputs.objectRoot.translateY(0.4);
        // @ts-ignore
        planeRenderer.outputs.objectRoot.scale.set(0.5, 0.5, 0.5);
      }
    }

    this.outputs.painter = this;
    // @ts-ignore
    this.mixer = new THREE.AnimationMixer(planeRenderer.outputs.objectRoot);

    const tm = 0.2;
    const positionTrack = new THREE.VectorKeyframeTrack(
      '.scale',
      [0, tm],
      [0, 0, 0, 0.5, 0.5, 0.5],
      THREE.InterpolateSmooth
    );
    // @ts-ignore
    this.onEnterClip = new THREE.AnimationClip(null, tm, [positionTrack]);
    this.maybeLoadImage();
  }

  onInputsUpdated() {
    const THREE = this.context.three;
    if (this.inputs.loadingState === 'Loaded') {
      // @ts-ignore
      this.daeComponent.outputs.objectRoot.traverse((obj: Object3D) => {
        // we dont want line segments
        if (obj.type === 'LineSegments') {
          obj.visible = false;
        } else if (obj.type === 'Mesh') {
          this.mesh = obj as Mesh;

          const material = this.mesh.material as MeshLambertMaterial;
          if (material && material.name === '_5b76dbe388862300126c1e14') {
            const newMaterial = new THREE.MeshBasicMaterial({ map: this.inputs.texture });
            this.mesh.material = newMaterial;
          }
        }
      });
    }
    this.maybeLoadImage();
  }

  onEvent(eventType: string, eventData: unknown): void {
    if (eventType === HoverEvent) {
      const data: any = eventData;
      if (data.hover) {
        this.outputs.visible = true;
        // @ts-ignore
        const onEnterAction: AnimationAction = this.mixer.clipAction(this.onEnterClip);
        onEnterAction.stop();
        onEnterAction.loop = LoopOnce;
        onEnterAction.clampWhenFinished = true;
        onEnterAction.play();
      } else {
        this.outputs.visible = false;
      }
    }
  }

  paint(context2d: CanvasRenderingContext2D, size: Size): void {
    // const x = 490;
    // const y = 490;
    if (!this.image) {
      return;
    }

    context2d.clearRect(0, 0, this.inputs.destSize.w, this.inputs.destSize.h);
    if (this.image.width > 0) {
      context2d.drawImage(
        this.image,
        this.inputs.srcPosition.x,
        this.inputs.srcPosition.y,
        this.inputs.srcSize.w,
        this.inputs.srcSize.h,
        this.inputs.destPosition.x,
        this.inputs.destPosition.y,
        this.inputs.destSize.w,
        this.inputs.destSize.h
      );
    }
    // context2d.fillStyle = 'black';
    // context2d.beginPath();
    // context2d.arc(x, y, 400, 0, Math.PI * 2);
    // context2d.fill();

    // context2d.fillStyle = '#CF5300';
    // context2d.beginPath();
    // context2d.arc(x, y, 300, 0, Math.PI * 2);
    // context2d.fill();

    // context2d.beginPath();
    // context2d.strokeStyle = 'orange';
    // context2d.arc(x, y, 240, 0.75 * Math.PI, 0.25 * Math.PI);
    // context2d.lineCap = 'butt';
    // context2d.lineWidth = 80;
    // context2d.stroke();

    // context2d.fillStyle = 'white';
    // context2d.font = '80px Arial';
    // context2d.fillText(`${this.temperature}`, x - 120, y + 50);
  }
  private maybeLoadImage() {
    this.image = null;

    if (this.inputs.src !== null && this.inputs.src !== '') {
      const that = this;
      this.image = new Image();
      this.image.crossOrigin = 'anonymous';
      this.image.src = this.inputs.src;
      this.image.onload = function (event: Event) {
        that.notify('paint.ready');
      };
    }

    this.notify('paint.ready');
  }
  onTick(delta: number) {
    this.currentTime += delta;

    if (this.mixer) {
      this.mixer.update(delta / 1000);
    }

    if (this.currentTime > this.nextUpdate) {
      this.nextUpdate += this.inputs.updateInterval;

      // this.temperature = this.inputs.data;
      // this.temperature += Math.random() * this.tempChangeRange;
      // this.temperature = Math.trunc(this.temperature);
      // if (this.temperature > 99) {
      //   this.temperature = 99;
      //   this.tempChangeRange = -this.tempChangeRange;
      // }
      // if (this.temperature < 10) {
      //   this.temperature = 10;
      //   this.tempChangeRange = -this.tempChangeRange;
      // }

      this.notify(RepaintEvent);
    }
  }
}

export const dashboardLoaderType = 'mp.dashboardLoader';
export const makeDashboardLoader = function () {
  return new DashboardLoader();
};
