import { SceneNodeI } from '@mp/common';
const scene = {
  version: '1.0',
  payload: {
    objects: [
      // {
      //   "name": "fresnel object",
      //   "position": { "x": -1, "y": 1.4, "z": 6.8},
      //   "components": [
      //     {
      //       "type": "mp.videoRenderer",
      //       "inputs": {
      //         src: 'https://bfmbusisecure-i.akamaihd.net/hls/live/265375/876450612001/BFMBusinessS/video_0/video_0.m3u8'
      //       }
      //     }
      //   ]
      // },
      // {
      //   "name": "shadow example",
      //   "position": { "x": 0, "y": 0.5, "z": 0},
      //   "components": [
      //     {
      //       "type": "mp.shadow",
      //       "inputs": {
      //       }
      //     }
      //   ]
      // },
      {
        name: 'lights',
        position: { x: 0, y: 0, z: 0 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: { x: 1, y: 1, z: 1 },
        components: [
          {
            type: 'mp.ambientLight',
            inputs: {
              r: 1.0,
              g: 1.0,
              b: 1.0,
              intensity: 3,
            },
          },
          {
            type: 'mp.directionalLight',
            inputs: {
              intensity: 2,
              position: {
                x: 0,
                y: 2,
                z: -1,
              },
              target: {
                x: 0,
                y: -1,
                z: 2,
              },
              castShadow: true,
              debug: false,
            },
          },
          {
            type: 'mp.directionalLight',
            inputs: {
              intensity: 0.5,
              position: {
                x: 0,
                y: 2,
                z: 8,
              },
              target: {
                x: 0,
                y: 0,
                z: 2,
              },
              castShadow: true,
              debug: false,
            },
          },
        ],
      },
      {
        name: 'shadow',
        // @ts-ignore
        components: [],
      },
    ],
  },
};

export class SceneLoader {
  private nodes: SceneNodeI[] = [];

  constructor(private sdk: any, private scenesArr?: any) {}

  /**
   * Load the scene for a given model.
   *
   * @param sid sid of the model, used to lookup the scene.
   * @param callback an optional callback which is called once for scene node created.
   */
  // @ts-ignore
  async load(sid: string, callback: (node: SceneNodeI) => void = null) {
    const nodesToStop = this.nodes.splice(0);
    for (const node of nodesToStop) {
      node.stop();
    }

    const scene = sidToScene.get(sid);

    if (this.scenesArr) {
      this.scenesArr.map((item: any) => {
        scene.payload.objects.push(item);
      });
    }
    if (!scene) {
      return;
    }

    const nodesToStart: SceneNodeI[] = await this.sdk.Scene.deserialize(JSON.stringify(scene));

    if (callback) {
      for (const node of nodesToStart) {
        callback(node);
      }
    }

    for (const node of nodesToStart) {
      node.start();
      this.nodes.push(node);
    }
  }

  async reloadSensor(sid: string, scenesArr: any) {
    const nodesToStop: any = [];
    this.nodes = this.nodes.filter((item) => {
      if (item.name === 'sensor') {
        nodesToStop.push(item);
        return false;
      } else {
        return true;
      }
    });
    for (const node of nodesToStop) {
      node.stop();
    }

    const scene = sidToScene.get(sid);
    scene.payload.objects = scene.payload.objects.filter((obj: any) => {
      return obj.name !== 'sensor';
    });
    if (scenesArr) {
      scenesArr.map((item: any) => {
        scene.payload.objects.push(item);
      });
    }
    if (!scene) {
      return;
    }

    let nodesToStart: SceneNodeI[] = await this.sdk.Scene.deserialize(JSON.stringify(scene));
    nodesToStart = nodesToStart.filter((item) => {
      return item.name === 'sensor';
    });
    for (const node of nodesToStart) {
      node.start();
      this.nodes.push(node);
    }
  }

  *nodeIterator(): IterableIterator<SceneNodeI> {
    for (const node of this.nodes) {
      yield node;
    }
  }
}

const sidToScene: Map<string, any> = new Map();

sidToScene.set('AAWs9eZ9ip6', scene);
