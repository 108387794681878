import React, { FC } from 'react';
import { css } from 'emotion';
import { GrafanaTheme } from '@grafana/data';
import { useStyles } from '@grafana/ui';

/**
 * @typedef {Object} MapTooltipContentProps
 * @property {number} [index]
 * @property {string | undefined} [queryName]
 * @property {string} [value]
 * @property {string} [dashName]
 * @property {string} [dashUrl]
 * @property {boolean} [isGeohash]
 * @property {boolean} [isOpenLayer]
 * @property {any} [jsonDataObj]
 * @property {Function} [handleDashboardVisit]
 */
interface MapTooltipContentProps {
  handleDashboardVisit?: (text: string, custom?: string) => void;
  index?: number;
  queryName?: string | undefined;
  value?: string;
  dashName?: string;
  dashUrl?: string;
  isGeohash?: boolean;
  jsonDataObj?: any;
  isOpenLayer?: boolean;
  isOpen?: boolean;
}
/**
 * @function
 * @param {MapTooltipContentProps} props
 * @returns {JSX.Element}
 */
export const TooltipContent: FC<MapTooltipContentProps> = (props) => {
  const {
    value,
    index,
    queryName,
    jsonDataObj,
    // isOpenLayer,
    // dashName,
    // dashUrl,
    // handleDashboardVisit,
    // isGeohash,
    // isOpen,
  } = props;
  const styles = useStyles(getStyles);
  // Custom markers onClick
  // if (handleDashboardVisit && isOpen && isOpenLayer && dashUrl && dashName) {
  //   handleDashboardVisit(dashName, dashUrl);
  // }
  // // Geohash markers onClick
  // if (handleDashboardVisit && isOpen && isOpenLayer && isGeohash && dashName) {
  //   handleDashboardVisit(dashName);
  // }
  return (
    <table key={`tab-div-${index}`} className={styles.infoWrap}>
      <tbody>
        {value && queryName ? (
          <tr>
            <th>{queryName}</th>
            <td>{value}</td>
          </tr>
        ) : null}
        {jsonDataObj
          ? Object.keys(jsonDataObj).map((key, index) => {
              const name = jsonDataObj[key];
              if (typeof name !== 'object') {
                return (
                  <tr key={`${name}-${index}`}>
                    <th>{`${key}`}:</th>
                    <td>{`${name}`}</td>
                  </tr>
                );
              } else {
                return null;
              }
            })
          : null}
      </tbody>
    </table>
  );
};

const getStyles = (theme: GrafanaTheme) => {
  return {
    infoWrap: css`
      padding: 8px;
      width: -webkit-fill-available;
      th {
        font-weight: ${theme.typography.weight.semibold};
        padding: 2px 16px;
        font-size: ${theme.typography.size.md};
        line-height: 21px;
      }
      td {
        font-size: ${theme.typography.size.md};
        padding: 0 6px;
        text-align: end;
      }
    `,
  };
};
