import { FieldDisplay } from '@grafana/data';
import React from 'react';
//@ts-ignore
import { RadialGauge } from 'react-canvas-gauges';
interface Props {
  valueField: number;
  height: number;
  width: number;
  customColor: string;
  showNum: boolean;
  showName: boolean;
  fontSize: string;
  fontSizeCDL: string;
  frame: FieldDisplay;
}

function CanvasGauge({
  height,
  width,
  showNum,
  showName,
  fontSize,
  fontSizeCDL,
  frame,
  customColor,
  valueField,
}: Props) {
  const gaugevalue = Number(valueField.toFixed(2)) * 100;
  let value;
  if (isNaN(gaugevalue)) {
    value = 0;
  } else {
    value = gaugevalue;
  }
  if (isNaN(height)) {
    height = 1;
  }
  if (isNaN(width)) {
    width = 1;
  }

  return (
    <>
      <RadialGauge
        height={height / 2}
        width={width / 2}
        colorUnit="#ffffff"
        units="Percentage(%)"
        value={value}
        minValue={0}
        maxValue={100}
        majorTicks={['0', '10', '20', '30', '40', '50', '60', '70', '80', '90', '100']}
        minorTicks={10}
        colorPlate={customColor}
        colorNeedle={customColor}
      ></RadialGauge>
      {showNum && (
        <span
          style={{
            fontSize: fontSizeCDL + 'px',
            textAlign: 'center',
            color: customColor,
            fontWeight: 'bold',
            marginTop: '5px',
          }}
        >
          {(frame.display.prefix || '') + frame.display.text + (frame.display.suffix || '')}
        </span>
      )}
      {showName && (
        <span
          style={{
            fontSize: fontSize + 'px',
            textAlign: 'center',
            marginBottom: '10px',
          }}
        >
          {frame.display.title}
        </span>
      )}
    </>
  );
}

export default CanvasGauge;
