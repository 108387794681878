import React from 'react';
import { Field, RadioButtonGroup } from '@grafana/ui';

//* options to set default timerange and current timerange to dashboard link drawer page
export const selectOption: any = [
  { label: 'Default Timerange', value: false },
  { label: 'Current Timerange', value: true },
];

/**
 * @param {Props} props
 * @property {function} drawerLinkTime
 * @property {boolean} value
 */
interface Props {
  value: boolean;
  drawerLinkTime: (time: boolean) => void;
}

/**
 * You can apply dashboard time or default (timeRange) to dashboard link drawer page.
 * @param {Props}
 */
export function DashboardTime(props: Props) {
  const { value, drawerLinkTime } = props;

  return (
    <Field
      label="Set Default/Current Timerange"
      // description="You can apply default timerange/current timerange ) to dashboard link drawer page."
    >
      <RadioButtonGroup size="md" options={selectOption} onChange={drawerLinkTime} value={value ? true : false} />
    </Field>
  );
}
