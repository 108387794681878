import { SingleStatBaseOptions } from '@grafana/ui';
import { ReducerID, standardEditorsRegistry, PanelOptionsEditorBuilder } from '@grafana/data';

export interface SankeyPanelOptions extends SingleStatBaseOptions {
  textsize: number;
  sankeyData: SankeyDataI[];
  colorMode: boolean;
  width: number;
  selectMode: string;
  selectNode: boolean;
  labelPadding: number;
  nodePadding: number;
  nodecolor: string;
  nodealign: string;
  diagram: boolean;
}

export interface SankeyDataI {
  query: string;
  from: string;
  to: string;
}

export interface NodesLinksI {
  links: LinkI[];
  nodes: NodeI[];
}

export interface LinkI {
  source: number;
  target: number;
  value: number;
  layerColors: string;
  from: string;
  to: string;
  width: number;
}

export interface SankeyI {
  graph: NodesLinksI;
}

export interface NodeI {
  name: string;
  y0: number;
  x0: number;
  x1: number;
  y1: number;
  sourceLinks: number | number[];
  depth: number;
  height: number;
  targetLinks: number;
  value: number;
}

export interface NodeI2 {
  name: string;
  y0: number;
  x0: number;
  x1: number;
  y1: number;
  sourceLinks: number[];
  depth: number;
  height: number;
  targetLinks: number;
  value: number;
}

export function addStandardDataReduceOptions(builder: PanelOptionsEditorBuilder<SankeyPanelOptions>) {
  builder.addCustomEditor({
    category: ['Editor'],
    id: 'reduceOptions.calcs',
    path: 'reduceOptions.calcs',
    name: 'Value',
    description: 'Choose a reducer function / calculation',
    editor: standardEditorsRegistry.get('stats-picker').editor as any,
    defaultValue: [ReducerID.mean],
    // Hides it when all values mode is on
    // showIf: currentConfig => currentConfig.reduceOptions.values === false,
  });
}
