import { DataFrame, FieldConfigSource, PanelPlugin } from '@grafana/data';
import { IconName } from '@grafana/ui';

export interface PanelEditorTab {
  id: string;
  text: string;
  active: boolean;
  icon: IconName;
}

export enum PanelEditorTabId {
  Query = 'query',
  Transform = 'transform',
  Anomaly = 'anomaly',
  Alert = 'alert',
}

export enum DisplayMode {
  Fill = 0,
  Fit = 1,
  Exact = 2,
}

export const displayModes = [
  { value: DisplayMode.Fill, label: 'Fill', description: 'Use all available space' },
  { value: DisplayMode.Fit, label: 'Fit', description: 'Fit in the space keeping ratio' },
  { value: DisplayMode.Exact, label: 'Exact', description: 'Same size as the dashboard' },
];

export enum ChartViewMode {
  Default = 0,
  Table = 1,
  Graph = 2,
}

export const chartViewTypeOptions = [
  { value: ChartViewMode.Default, label: 'Default', description: 'View data in default chart' },
  { value: ChartViewMode.Table, label: 'Table', description: 'View data in table chart' },
  { value: ChartViewMode.Graph, label: 'Graph', description: 'View data in graph chart' },
];

/** @internal */
export interface Props {
  plugin: PanelPlugin;
  config: FieldConfigSource;
  onChange: (config: FieldConfigSource) => void;
  /* Helpful for IntelliSense */
  data: DataFrame[];
}
