import { Button, InlineField, useTheme } from '@grafana/ui';
import React, { useState } from 'react';
import { CustomMarkerI, Onemarker } from '../../../types/interface';
import { onchangeLayerOption } from '../../GeojsonStyle';
import { OSMapLayerState, OSMapPanelOptions } from '../../../types/types';
import { DataFrame, FieldDisplay, InterpolateFunction } from '@grafana/data';
import Map from 'ol/Map';
import { generalBigData } from '../../../Layers/hetamapLayer/general';
import { MarkerExpand } from './MarkerExpand';

/**
 * Default marker object with initial values.
 *
 * @const {Onemarker}
 * @default
 */
export const DEFAULT_Marker: Onemarker = {
  lat: 0,
  lng: 0,
  query: [{ label: '', value: '' }],
  url: '',
  name: '',
};

/**
 * Interface representing props for a component.
 *
 * @interface Props
 * @property {OSMapLayerState<any>} layer - The OSMapLayerState for the component.
 * @property {Map | undefined} map - The Map object, if available.
 * @property {InterpolateFunction | undefined} replaceVariables - The InterpolateFunction for variable replacement.
 * @property {OSMapPanelOptions | undefined} options - The OSMapPanelOptions for the component.
 * @property {DataFrame[]} data - An array of DataFrame objects.
 */
interface Props {
  layer: OSMapLayerState<any>;
  map: Map | undefined;
  replaceVariables: InterpolateFunction | undefined;
  options: OSMapPanelOptions | undefined;
  data: DataFrame[];
}

/**
 * Functional component for adding and configuring markers.
 *
 * @param {Props} props - The props for the AddMarker component.
 * @returns {React.ReactNode} The marker addition and configuration component.
 */
export function AddMarker({ layer, map, replaceVariables, options, data }: Props) {
  const [queries, setQueries]: any = useState([]);
  const theme = useTheme();

  const { fieldConfig }: any = options;
  const { markers }: CustomMarkerI = layer.options.config.customMarkers;

  const addNew = () => {
    let marker = [...layer.options.config.customMarkers.markers];
    marker.push(DEFAULT_Marker);
    onchangeLayerOption(marker, 'config.customMarkers.markers', layer);
  };

  const dataLoad = () => {
    let newData: FieldDisplay[] = [];
    if (data!.length > 0 && replaceVariables && options) {
      newData = generalBigData(fieldConfig, options.reduceOptions, replaceVariables, theme, data, 'browser');
    }
    const localQueries: any = [];
    newData?.map((ele: FieldDisplay) => {
      if (ele?.display?.title && ele.view?.dataFrame.fields[1].type === 'number') {
        localQueries.push({
          label: ele?.display?.title,
          value: ele?.display?.title,
        });
      }
    });
    setQueries(localQueries);
  };

  return (
    <>
      <InlineField style={{ gap: '10px' }}>
        <>
          <div className="gf-form">
            <Button onClick={addNew} variant="primary" size="sm">
              <i className="fa fa-plus" aria-hidden="true"></i> <span style={{ marginLeft: '5px' }}>Add New</span>
            </Button>
          </div>
          <div className="gf-form">
            <Button onClick={dataLoad} variant="secondary" size="sm">
              <i className="fa fa-refresh" aria-hidden="true"></i>{' '}
              <span style={{ marginLeft: '5px' }}>Reload Queries</span>
            </Button>
          </div>
        </>
      </InlineField>
      {markers &&
        markers.length !== 0 &&
        markers.map((marker, index) => {
          return <MarkerExpand key={index} marker={marker} Idx={index} layer={layer} map={map} queries={queries} />;
        })}
    </>
  );
}
