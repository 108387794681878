import { config } from '@grafana/runtime';
import { useTheme } from '@grafana/ui';
import { GoogleMap, TrafficLayer, useJsApiLoader } from '@react-google-maps/api';
import appEvents from 'app/core/app_events';
import React, { useEffect, useState } from 'react';
import { QueryDataCal } from '../calculation/interfcae';
import { TooltipDataProvider, TooltipProvider } from '../context';
import { LegendMode, MarkerLinkI } from '../types/interface';
import { GoogleBaseProps } from '../types/types';
import { setLegend, setLegendForHeatmap, themeCalc } from '../utils/processor';
import { GeoJson, HeatMapLayer, MarkerLayer } from './Layers';
import { CompleteMarker, HeatmapLegend } from './interface';
import { Tooltip } from './tooltip/Tooltip';
import { LinkDrawer } from 'app/features/linkDrawer/LinkDrawer';

const libraries: any = ['drawing', 'geometry', 'places', 'visualization'];

/**
 * @function
 * @param {GoogleBaseProps} props
 * @returns {JSX.Element}
 */

const GoogleBaseMap: React.FC<GoogleBaseProps> = (props: GoogleBaseProps) => {
  const theme = useTheme();

  const {
    bigData,
    bigData2,
    onOptionsChange,
    options,
    fieldConfig,
    width,
    height,
    setSharedZoom,
    getLat,
    getZoom,
    getLng,
    bigData3,
    frames,
    dataFromAutoQuery,
    dataFromGeohashQuery,
    dataFromCustomMarker,
    dataFromCustomMarkerHeat,
    timeZone,
    series,
    heatMapColors,
    timeRange,
  } = props;
  const {
    zoom,
    defaultCenter,
    mapTypeId,
    markerLink,
    heatmap,
    traffic,
    showTools,
    showController,
    latLngPicker,
    mapTheme,
    customMarkers,
    shared,
    customMarkersHeat,
    legendMode,
    heatmapSteps,
  } = options;

  const [showDrawer, setShowDrawer] = useState(false);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.googleMapsKey,
    libraries,
  });
  // @ts-ignore
  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const [infoData, setinfoData] = useState({ location: '', url: '' });

  const heatmapMinMax = calculateHeatmapMinMax(dataFromCustomMarkerHeat, dataFromGeohashQuery);

  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    setMap(map);
  }, []);

  useEffect(() => {
    if (map) {
      if (legendMode === LegendMode.Threshold || legendMode === LegendMode.Both) {
        setLegend(map, 6, fieldConfig);
      }
      if (
        heatMapColors &&
        heatMapColors.length > 0 &&
        (legendMode === LegendMode.Heatmap || legendMode === LegendMode.Both)
      ) {
        if (heatmapMinMax.MarkerHeat !== 'null') {
          setLegendForHeatmap(map, 6, heatMapColors, heatmapMinMax, heatmapSteps, series, theme, timeZone);
        }
      }
    }
    return () => {
      if (map) {
        map.controls.forEach(function (control, index) {
          if (index === 6) {
            control.clear();
          }
        });
      }
    };
  }, [map, legendMode, heatMapColors, heatmapSteps, series, theme]);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  /**
   * @function
   * Prepares data to render in slider. Works for both custom and geohash queries.
   * @param {string} markerName - Name of the marker to view
   * @param {string} [customMarkerUrl] - C
   * @return {void}
   */
  // TODO: Very poorly written
  const handleDashboardVisit = (markerName: string, customMarkerUrl?: string): void => {
    if (!showDrawer) {
      if (customMarkerUrl) {
        setinfoData({ location: markerName, url: customMarkerUrl });
        setShowDrawer(true);
        return;
      }
      // Handle geohash based markers
      const markerToLink = markerLink.find((marker: MarkerLinkI) => marker.location === markerName);

      if (markerToLink && markerToLink.url !== '' && customMarkerUrl === undefined) {
        // Use customName when possible
        if (markerToLink.customName) {
          setinfoData({ location: markerToLink.customName, url: markerToLink.url });
        } else {
          setinfoData({ location: markerToLink.location, url: markerToLink.url });
        }
        setShowDrawer(true);
      }
    }
  };

  const onClose = () => {
    setShowDrawer(false);
  };

  const lnPickerMap = (env: any) => {
    if (latLngPicker) {
      defaultCenter.lat = env.latLng.lat();
      defaultCenter.lng = env.latLng.lng();
      onOptionsChange({ ...options, defaultCenter: defaultCenter });
      appEvents.emit('alert-success', ['Default Location updated']);
    } else if (customMarkers.enabled) {
      customMarkers.markers[customMarkers.currIdx].lat = env.latLng.lat();
      customMarkers.markers[customMarkers.currIdx].lng = env.latLng.lng();
      onOptionsChange({ ...options, customMarkers: customMarkers });
      appEvents.emit('alert-success', ['Marker Location updated']);
    } else if (customMarkersHeat.enabled) {
      customMarkersHeat.markers[customMarkersHeat.currIdx].lat = env.latLng.lat();
      customMarkersHeat.markers[customMarkersHeat.currIdx].lng = env.latLng.lng();
      onOptionsChange({ ...options, customMarkersHeat: customMarkersHeat });
      appEvents.emit('alert-success', ['Marker Location updated']);
    }
  };

  function sharePicker(this: any) {
    const newCenter = this.getCenter();
    const newLat = newCenter.lat();
    const newLng = newCenter.lng();
    const newZoom = this.getZoom();
    if (shared && newZoom) {
      setSharedZoom(newZoom, newLat, newLng);
    }
  }

  return isLoaded ? (
    <TooltipDataProvider>
      <TooltipProvider>
        <GoogleMap
          onLoad={onLoad}
          onUnmount={onUnmount}
          mapContainerStyle={{
            width: width,
            height: height,
          }}
          zoom={shared ? getZoom : zoom}
          center={shared ? { lat: getLat, lng: getLng } : { lat: defaultCenter.lat, lng: defaultCenter.lng }}
          options={{
            styles: themeCalc(mapTheme, theme),
            disableDefaultUI: !showTools,
            scaleControl: showController,
            scrollwheel: showController,
            disableDoubleClickZoom: !showController,
            draggable: showController,
            mapTypeId: mapTypeId,
          }}
          onClick={(env) => lnPickerMap(env)}
          onZoomChanged={shared ? sharePicker : undefined}
          onDragEnd={shared ? sharePicker : undefined}
        >
          <MarkerLayer
            options={options}
            dataFromAutoQuery={dataFromAutoQuery}
            dataFromGeohashQuery={dataFromGeohashQuery}
            dataFromCustomMarker={dataFromCustomMarker}
            handleDashboardVisit={handleDashboardVisit}
          />
          {heatmap.enabled && (
            <HeatMapLayer
              heatmap={heatmap}
              bigData={bigData}
              bigData2={bigData2}
              customMarkersHeat={options.customMarkersHeat}
              options={options}
              bigData3={bigData3}
              frames={frames}
              heatMapColors={heatMapColors}
            />
          )}
          {map && options.geojson.isGeoJsonLayer && <GeoJson options={options} map={map} />}
          {<Tooltip options={options} handleDashboardVisit={handleDashboardVisit} />}
          {traffic && <TrafficLayer />}
          {showDrawer && (
            <LinkDrawer
              infoData={infoData}
              onClose={onClose}
              timeRange={options.drawerLinkTime ? timeRange : undefined}
            />
          )}
        </GoogleMap>
      </TooltipProvider>
    </TooltipDataProvider>
  ) : (
    <div className="panel-empty">
      <p>Loading...</p>
    </div>
  );
};

export default React.memo(GoogleBaseMap);

/**
 * @function
 * @param {CompleteMarker[][]} dataFromCustomMarkerHeat
 * @returns   Heatmap minMax value
 */
export const calculateHeatmapMinMax = (
  dataFromCustomMarkerHeat: CompleteMarker[][] | null | undefined,
  dataFromGeohashQuery: QueryDataCal | null
): HeatmapLegend => {
  if (dataFromCustomMarkerHeat || dataFromGeohashQuery) {
    let valueArray = [];
    const customMarkerHeatmap = dataFromCustomMarkerHeat ?? [];
    const heatmapGeohashQuery = dataFromGeohashQuery?.valueField ?? [];

    //* Getting numeric value from customMarkerHeatmap
    if (customMarkerHeatmap.length !== 0) {
      for (let i = 0; i < customMarkerHeatmap.length; i++) {
        for (let j = 0; j < customMarkerHeatmap[i].length; j++) {
          valueArray.push(customMarkerHeatmap[i][j].numeric);
        }
      }
    }

    //* Getting numeric value from heatmapGeohashQuery
    if (heatmapGeohashQuery.length !== 0) {
      for (let i = 0; i < heatmapGeohashQuery.length; i++) {
        valueArray.push(heatmapGeohashQuery[i].numeric);
      }
    }
    return {
      min: Math.min(...valueArray),
      max: Math.max(...valueArray),
      MarkerHeat: 'Notnull',
    };
  } else {
    return {
      min: 0,
      max: 0,
      MarkerHeat: 'null',
    };
  }
};
