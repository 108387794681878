import React, { Component } from 'react';
import {
  Frame,
  GetSDK,
  initComponents,
  SceneComponent,
  SceneNodeI,
  ComponentInteractionType,
  orientedBoxType,
  slotType,
  OrientedBox,
  ComponentEventSpyI,
  EnteractionEventI,
  sdkKey,
} from '@mp/common';
import { AppState } from '../AppState';
import { SceneLoader } from '../SceneLoader';
import { ItemList } from './ItemList';
import { ItemDesc } from '../types';

import { sceneTypes } from '../sceneTypes';

const SelectedColor = 0xffff00;
const SelectedOpacity = 0.1;
const SelectedLineOpacity = 1.0;
const UnselectedColor = 0xffffff;
const UnselectedOpacity = 0.04;
const UnselectedLineOpacity = 0.4;

interface Props {
  appState: AppState;
  sceneList: {
    scenes: any[];
    queries: any[];
  };
  tagList: {
    tags: any[];
  };
  bigData: any;
  updateCord: any;
  lock: boolean;
  sid: string;
  cameraP: number;
  cameraX: number;
  cameraY: number;
}
interface Scene {
  type: string;
  url: string;
  query: string;
  xPos: number;
  yPos: number;
  zPos: number;
  xRot: number;
  yRot: number;
  zRot: number;
  toVisit: string;
  orient: string;
}
interface State {
  slotNode: SlotNode | null;
  cameraLocation: {
    x: number;
    y: number;
    z: number;
  };
}

type SlotNode = {
  node: SceneNodeI;
  slotComponent: SceneComponent;
  modelComponent: SceneComponent;
  boxComponent: OrientedBox;
};

export class Main extends Component<Props, State> {
  private sdk: any = null;
  // @ts-ignore
  private scene: SceneLoader = null;
  private slots: SlotNode[] = [];

  constructor(props: Props) {
    super(props);

    this.state = {
      slotNode: null,
      cameraLocation: {
        x: 0,
        y: 0,
        z: 0,
      },
    };

    this.handleListSelection = this.handleListSelection.bind(this);
    this.handleOrientedBoxInteraction = this.handleOrientedBoxInteraction.bind(this);
  }

  async componentDidMount() {
    this.sdk = await GetSDK('sdk-iframe', sdkKey);
    await this.sdk.Scene.configure((renderer: any, three: any) => {
      renderer.physicallyCorrectLights = true;
      renderer.gammaFactor = 2.2;
      renderer.gammaOutput = true;
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.bias = 0.0001;
      renderer.shadowMap.type = three.PCFSoftShadowMap;
    });
    await initComponents(this.sdk);

    if (this.props.sceneList.scenes.length > 0) {
      const newLocalScene: any[] = [];
      this.props.sceneList.scenes.map((scenes: Scene) => {
        let val = '0';
        this.props.bigData.map((bigs: any) => {
          if (bigs.display.title === scenes.query) {
            val = (bigs.display.prefix || '') + bigs.display.text + (bigs.display.suffix || '');
          }
        });
        const toScene = sceneTypes(
          scenes.type,
          scenes.url,
          scenes.xPos,
          scenes.yPos,
          scenes.zPos,
          scenes.xRot,
          scenes.yRot,
          scenes.zRot,
          val,
          scenes.query,
          scenes.toVisit,
          scenes.orient
        );
        newLocalScene.push(toScene);
      });
      this.scene = new SceneLoader(this.sdk, newLocalScene);
    } else {
      this.scene = new SceneLoader(this.sdk);
    }

    this.sdk.Mattertag.add(this.props.tagList.tags).then(function (mattertagIds: any) {
      // console.log(mattertagIds);
      // output: TODO
    });
    const slots: SlotNode[] = [];

    class InteractionHandler implements ComponentEventSpyI<EnteractionEventI> {
      eventType = ComponentInteractionType.CLICK;
      constructor(private mainComponent: Main) {}
      onEvent(payload: EnteractionEventI) {
        this.mainComponent.handleOrientedBoxInteraction(payload.node, payload.component, payload.type);
      }
    }

    const handler = new InteractionHandler(this);
    const findSlots = (node: SceneNodeI) => {
      // @ts-ignore
      let slot: SceneComponent = null;
      // @ts-ignore
      let model: SceneComponent = null;
      // @ts-ignore
      let box: OrientedBox = null;
      const componentIterator: IterableIterator<SceneComponent> = node.componentIterator();
      for (const component of componentIterator) {
        if (component.componentType === slotType) {
          slot = component;
        } else if (component.componentType === 'mp.gltfLoader') {
          model = component;
        } else if (component.componentType === orientedBoxType) {
          box = component as OrientedBox;
          box.spyOnEvent(handler);
          box.inputs.color = UnselectedColor;
          box.inputs.opacity = UnselectedOpacity;
        }
      }

      if (slot && model) {
        slots.push({
          node: node,
          slotComponent: slot,
          modelComponent: model,
          boxComponent: box,
        });
      }
    };

    this.slots = slots;
    await this.scene.load('AAWs9eZ9ip6', findSlots);

    // console.log(this.scene);
    // console.log(this.sdk);

    setInterval(async () => {
      if (!this.props.lock) {
        const d2 = await this.sdk.Camera.getPose();
        this.setState({ ...this.state, cameraLocation: d2.position });
        this.props.updateCord(d2.position);
      }
    }, 3000);

    setInterval(() => {
      // @ts-ignore
      if (this.scene.scenesArr) {
        // @ts-ignore
        this.scene.scenesArr = this.scene.scenesArr.map((item: any) => {
          if (item.name === 'sensor') {
            let val = '0';
            this.props.bigData.map((bigs: any) => {
              if (bigs.display.title === item.components[3].inputs.query) {
                val = (bigs.display.prefix || '') + bigs.display.text + (bigs.display.suffix || '');
              }
            });
            item.components[3].inputs.data = val;
          }
          return item;
        });
        // @ts-ignore
        let sensorList = this.scene.scenesArr;
        sensorList = sensorList.filter((item: any) => {
          return item.name === 'sensor';
        });
        this.scene.reloadSensor('AAWs9eZ9ip6', sensorList);
      }
    }, 60000);
  }

  private handleListSelection(item: ItemDesc) {
    const slotNode = this.state.slotNode;
    if (!slotNode) {
      return;
    }
    // @ts-ignore
    slotNode.slotComponent.inputs.model = item.url;
    // @ts-ignore
    slotNode.modelComponent.inputs.localPosition.x = item.position.x;
    // @ts-ignore
    slotNode.modelComponent.inputs.localPosition.y = item.position.y;
    // @ts-ignore
    slotNode.modelComponent.inputs.localPosition.z = item.position.z;
    // @ts-ignore
    slotNode.modelComponent.inputs.localRotation.x = item.rotation.x;
    // @ts-ignore
    slotNode.modelComponent.inputs.localRotation.y = item.rotation.y;
    // @ts-ignore
    slotNode.modelComponent.inputs.localRotation.z = item.rotation.z;
    // @ts-ignore
    slotNode.modelComponent.inputs.localScale.x = item.scale.x;
    // @ts-ignore
    slotNode.modelComponent.inputs.localScale.y = item.scale.y;
    // @ts-ignore
    slotNode.modelComponent.inputs.localScale.z = item.scale.z;
    // @ts-ignore
  }

  private handleOrientedBoxInteraction(
    node: SceneNodeI,
    component: SceneComponent,
    interactionType: ComponentInteractionType
  ) {
    if (interactionType === ComponentInteractionType.CLICK) {
      // select this node
      for (const slot of this.slots) {
        if (slot.node === node) {
          for (const componentSearch of node.componentIterator()) {
            if (componentSearch === component) {
              const lastSlotNode = this.state.slotNode;
              if (lastSlotNode) {
                lastSlotNode.boxComponent.inputs.color = UnselectedColor;
                lastSlotNode.boxComponent.inputs.opacity = UnselectedOpacity;
                lastSlotNode.boxComponent.inputs.lineOpacity = UnselectedLineOpacity;
              }

              this.setState({ ...this.state, slotNode: slot });

              slot.boxComponent.inputs.color = SelectedColor;
              slot.boxComponent.inputs.opacity = SelectedOpacity;
              slot.boxComponent.inputs.lineOpacity = SelectedLineOpacity;
            }
          }
        }
      }
    }
  }

  render() {
    // Forward url params.
    // const params = objectFromQuery();
    // params.m = params.m || 'j4RZx7ZGM6T';
    // params.play = params.play || '1';
    // params.qs = params.qs || '1';
    // params.sr = params.sr || '-.15';
    // params.ss = params.ss || '25';

    // const queryString = Object.keys(params)
    //   .map(key => key + '=' + params[key])
    //   .join('&');
    // const src = `/bundle/showcase.html?${queryString}`;
    // ./bundle/showcase.html?sr=2.68,.36&ss=29&m=j4RZx7ZGM6T&play=1&qs=1&title=0&qs=1&hr=0&brand=0&help=0
    // /public/matterport/bundle/showcase.html?m=${this.props.sid}&play=1&qs=1&sr=-.15&ss=25
    const src = `/public/matterport/bundle/showcase.html?sr=${this.props.cameraX},${this.props.cameraY}&ss=${this.props.cameraP}&m=${this.props.sid}&play=1&qs=1&title=1&qs=1&hr=0&brand=0&help=0`;

    let filteredItems: ItemDesc[] = [];
    const { slotNode } = this.state;

    if (slotNode) {
      const { items, slots } = this.props.appState;
      const category = slots.get(slotNode.node.name);

      if (category) {
        // @ts-ignore
        filteredItems = items.get(category);
      }
    }
    return (
      <div className="main">
        {/* <CameraView></CameraView> */}
        {!this.props.lock && (
          <span className="list-wrapper">
            x: {this.state.cameraLocation.x} <br />
            y: {this.state.cameraLocation.y} <br />
            z: {this.state.cameraLocation.z} <br />
          </span>
        )}
        <ItemList items={filteredItems} onSelected={this.handleListSelection}></ItemList>
        <Frame src={src}></Frame>
      </div>
    );
  }
}

// from cwf/modules/browser.ts
export const objectFromQuery = (url?: string): { [key: string]: string } => {
  const regex = /[#&?]([^=]+)=([^#&?]+)/g;
  url = url || window.location.href;
  const object: { [param: string]: string } = {};
  let matches;
  // regex.exec returns new matches on each
  // call when we use /g like above
  while ((matches = regex.exec(url)) !== null) {
    object[matches[1]] = decodeURIComponent(matches[2]);
  }
  return object;
};
