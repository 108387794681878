import React, { useEffect, useState } from 'react';
import { InlineFormLabel, Select, Input, Button } from '@grafana/ui';
import { getBackendSrv } from '@grafana/runtime';
import { PropsExpand } from './MarkerExpandQuery';
import { cloneDeep } from 'lodash';
import { setProperty } from '../GeojsonStyle';
// import { MarkersConfig } from '../../Layers/markerLayer/type';

/**
 * Functional component for displaying and configuring a marker.
 *
 * @param {PropsExpand} props - The props for the Marker component.
 * @returns {React.ReactNode} The marker configuration component.
 */
export function MarkerLink(props: PropsExpand) {
  const [dashboards, setDashboards]: any = useState([]);
  const { marker, Idx, layer, handleRemoveLink } = props;
  let newMarker = cloneDeep(layer.options.config.markersLink);

  useEffect(() => {
    getBackendSrv()
      .get('api/search?type=dash-db')
      .then((res: any) => {
        const dashboardsLocal = [];
        for (let i = 0; i < res.length; i++) {
          dashboardsLocal.push({ label: res[i].title, value: res[i].url });
        }
        setDashboards(dashboardsLocal);
      });
  }, []);

  const onChangeProperty = (path: string, val: any, target?: any) => {
    if (target && path.length === 0) {
      newMarker[Idx] = {
        ...newMarker[Idx],
        url: target.value,
        name: target.label,
      };
      const newOpt = setProperty(cloneDeep(layer.options), 'config.markersLink', newMarker);
      layer.onChange(newOpt);
    } else {
      const newValueObj = setProperty(Object.assign({}, marker), path, val);
      newMarker[Idx] = newValueObj;
      const newOpt = setProperty(cloneDeep(layer.options), 'config.markersLink', newMarker);
      layer.onChange(newOpt);
    }
  };

  return (
    <>
      <div className="gf-form gf-form--grow">
        <InlineFormLabel width={10} tooltip={'Select dashboard from the given list'}>
          Select Dashboard
        </InlineFormLabel>
        <Select
          options={dashboards}
          value={dashboards.find((item: any) => item.value === marker.url)}
          onChange={(target: any) => onChangeProperty('', '', target)}
        />
      </div>
      <div className="gf-form">
        <InlineFormLabel width={10} tooltip={'Provide a custom Name'}>
          Custom Name
        </InlineFormLabel>
        <Input value={marker.name} onChange={(event: any) => onChangeProperty('name', event.currentTarget.value)} />
      </div>
      <div className="gf-form">
        <InlineFormLabel width={10} tooltip={'Provide a custom URL'}>
          Custom URL
        </InlineFormLabel>
        <Input value={marker.url} onChange={(event: any) => onChangeProperty('url', event.currentTarget.value)} />
      </div>
      <div className="gf-form" style={{ justifyContent: 'end' }}>
        <Button onClick={() => handleRemoveLink(Idx)} variant="destructive" size="sm">
          <i className="fa fa-trash" aria-hidden="true"></i>
        </Button>
      </div>
    </>
  );
}
