import React from 'react';
import { InlineField, InlineFieldRow, Select } from '@grafana/ui';
import { reducerTypes } from '../types';

export const Reduce = ({ labelWidth, onChange, refIds, query, onRunQuery }: any) => {
  const reduceList = reducerTypes.find((o: any) => o.value === query.reducer);

  const handleRefIdChange = (e: any) => {
    onChange({ ...query, expression: e.value });
    onRunQuery();
  };

  const handleReducer = (e: any) => {
    onChange({ ...query, reducer: e.value });
  };

  return (
    <InlineFieldRow>
      <InlineField label="Function" labelWidth={labelWidth}>
        <Select options={reducerTypes} value={reduceList} onChange={handleReducer} width={25} />
      </InlineField>
      <InlineField label="Input" labelWidth={labelWidth}>
        <Select onChange={handleRefIdChange} options={refIds} value={query.expression} width={20} />
      </InlineField>
    </InlineFieldRow>
  );
};
