import { PanelData } from '@grafana/data';
import Map from 'ol/Map';
import { Geometry, Polygon } from 'ol/geom';
import OLVectorLayer from 'ol/layer/Vector';
import OLVectorSource from 'ol/source/Vector';
import { OSMapLayerOptions, OSMapLayerRegistryItem } from '../../LayerEditor/types';
import { MapLayerType, QueryModes } from '../../types/interface';
import { PanelConfig } from '../../types/types';
import { checkRuleIsMatched, getOpacity, getStyle } from '../geojsonLayer/general';
import { createMarker, createPolygonMarker, getGeohashValues } from '../hetamapLayer/general';
import { GeohashField } from '../hetamapLayer/type';
import { defaultOptions } from './default';
import { getLatLng, getMarkerStyle } from './general';
import { MarkerFeatureStyleConfig, MarkerStyleConfig, MarkersConfig, QueryLinkMarker } from './type';
import Feature from 'ol/Feature';

/**
 * Represents a layer configuration for rendering markers on a map.
 *
 * @constant {OSMapLayerRegistryItem<MarkersConfig>} markersLayer
 * @property {MapLayerType} id - The unique identifier for the markers layer type.
 * @property {string} name - The name of the markers layer.
 * @property {string} description - A brief description of the markers layer.
 * @property {boolean} isBaseMap - Specifies whether the layer is a base map layer.
 * @property {boolean} showLocation - Specifies whether to display location information on the map.
 * @property {OSMapLayerOptions<MarkersConfig>} defaultOptions - The default options for the markers layer.
 * @property {function} create - A function for creating the markers layer on a map.
 */
export const markersLayer: OSMapLayerRegistryItem<MarkersConfig> = {
  id: MapLayerType.MARKER,
  name: 'Markers',
  description: 'use markers to render each data point',
  isBaseMap: false,
  showLocation: true,
  defaultOptions,

  create: async (map: Map, options: OSMapLayerOptions<MarkersConfig>) => {
    // Assert default values
    const config = {
      ...defaultOptions,
      ...options?.config,
    };

    let vectorSource = new OLVectorSource();

    // vector layer
    let vectorLayer = new OLVectorLayer({
      source: vectorSource,
      opacity: getOpacity(options.opacity),
    });

    return {
      init: () => vectorLayer,
      update: (data: PanelData, panelConfig: PanelConfig) => {
        const { timeLapseCurrTime, displayOptions } = panelConfig;
        const { queryOptions, style } = config;

        // clear data of the layer
        vectorSource.clear();
        let rules = config.rules;
        rules = rules.filter((rule) => rule.check?.property !== '' && rule.check?.value !== '');

        const fieldArray = getGeohashValues(data.series ?? [], queryOptions);
        if (fieldArray) {
          fieldArray.forEach((field) => {
            // Get marker dashboard name and url link.
            const markerNameLink = getDasboardNameLink(field, options.config?.markersLink ?? []);

            const isTimeLapse = displayOptions?.timelapse.isTimeLapse && timeLapseCurrTime && data.request?.intervalMs;
            if (queryOptions.queryMode !== QueryModes.Choro) {
              if (isTimeLapse && timeLapseCurrTime) {
                if (
                  field.time <= timeLapseCurrTime &&
                  field.time > timeLapseCurrTime - (data.request?.intervalMs || 0)
                ) {
                  if (markerNameLink !== null) {
                    addMarkerFeature(vectorSource, field, style, options.name, rules, markerNameLink);
                  } else {
                    addMarkerFeature(vectorSource, field, style, options.name, rules);
                  }
                }
              }
              if (!isTimeLapse) {
                if (markerNameLink !== null) {
                  addMarkerFeature(vectorSource, field, style, options.name, rules, markerNameLink);
                } else {
                  addMarkerFeature(vectorSource, field, style, options.name, rules);
                }
              }
            } else {
              if (!isTimeLapse) {
                if (markerNameLink !== null) {
                  addMarkerPolygonFeature(vectorSource, field, style, options.name, rules, markerNameLink);
                } else {
                  addMarkerPolygonFeature(vectorSource, field, style, options.name, rules);
                }
              }
            }
          });
        }
      },
      getFeaturesNew: () => vectorSource.getFeatures(),
    };
  },
};

/**
 * Adds a marker feature to the vector source on a map, applying style rules if specified.
 *
 * @function
 * @param {OLVectorSource<Geometry>} vectorSource - The vector source to which the marker feature will be added.
 * @param {GeohashField} field - The field containing information for the marker.
 * @param {MarkerStyleConfig} style - The default style configuration for the marker.
 * @param {string} layerName - The name of the layer where the marker belongs.
 * @param {MarkerFeatureStyleConfig[]} rules - An array of style rules for the marker.
 */
function addMarkerFeature(
  vectorSource: OLVectorSource<Geometry>,
  field: GeohashField,
  style: MarkerStyleConfig,
  layerName: string,
  rules: MarkerFeatureStyleConfig[],
  markerNameLink?: {
    dashboardName: string;
    dashboardUrl: string;
  }
) {
  const { percent, hash, name, value, color: fieldColor } = field;

  const sizeFinal = (style.size.max - style.size.min) * (Number(percent) || 0) + style.size.min;

  const latlon = getLatLng(hash);

  //Create marker Feature
  const markerFeature = markerNameLink
    ? createMarker(
        latlon.lng,
        latlon.lat,
        value,
        layerName,
        name,
        markerNameLink.dashboardName,
        markerNameLink.dashboardUrl
      )
    : createMarker(latlon.lng, latlon.lat, value, layerName, name);
  vectorSource.addFeature(markerFeature);

  if (rules.length) {
    for (let index = 0; index < rules.length; index++) {
      const { check, style: ruleStyle } = rules[index];
      if (!check || !ruleStyle) {
        continue;
      }
      const { operation, value, property } = check;
      const {
        fillOpacity,
        size: { min, max },
        symbol,
        color,
        rotation,
        markerIcon,
      } = ruleStyle;
      const sizeFinal = (max - min) * (Number(percent) || 0) + min;

      const cond = checkRuleIsMatched(markerFeature.get(property), operation, value!);
      if (cond) {
        markerFeature.setStyle(getMarkerStyle(fillOpacity, color, sizeFinal, symbol, markerIcon, rotation));
        return;
      }
    }
    markerFeature.setStyle(
      getMarkerStyle(style.fillOpacity, fieldColor, sizeFinal, style.symbol, style.markerIcon, style.rotation)
    );
  } else {
    markerFeature.setStyle(
      getMarkerStyle(style.fillOpacity, fieldColor, sizeFinal, style.symbol, style.markerIcon, style.rotation)
    );
  }
}

/**
 * Adds a polygon marker feature to the vector source on a map, applying style rules if specified.
 *
 * @function
 * @param {OLVectorSource<Geometry>} vectorSource - The vector source to which the polygon marker feature will be added.
 * @param {GeohashField} field - The field containing information for the polygon marker.
 * @param {MarkerStyleConfig} style - The default style configuration for the polygon marker.
 * @param {string} layerName - The name of the layer where the polygon marker belongs.
 * @param {MarkerFeatureStyleConfig[]} rules - An array of style rules for the polygon marker.
 */
function addMarkerPolygonFeature(
  vectorSource: OLVectorSource<Geometry>,
  field: GeohashField,
  style: MarkerStyleConfig,
  layerName: string,
  rules: MarkerFeatureStyleConfig[],
  markerNameLink?: { dashboardName: string; dashboardUrl: string }
) {
  const { percent, name, value, hash1, hash2, hash3, hash4 } = field;

  const sizeFinal = (style.size.max - style.size.min) * (Number(percent) || 0) + style.size.min;

  let latlon1 = getLatLng(hash1 || 'r7179w8ywth6');
  let latlon2 = getLatLng(hash2 || 'r714nsden2sb');
  let latlon3 = getLatLng(hash3 || 'r7193bk8hdrz');
  let latlon4 = getLatLng(hash4 || 'r71egk9fh83k');
  let markerPolygon: Feature<Polygon>;
  //Create marker Feature
  if (latlon1 && latlon2 && latlon3 && latlon4) {
    markerPolygon = markerNameLink
      ? createPolygonMarker(
          latlon1.lng,
          latlon2.lng,
          latlon3.lng,
          latlon4.lng,
          latlon1.lat,
          latlon2.lat,
          latlon3.lat,
          latlon4.lat,
          value,
          layerName,
          name,
          markerNameLink.dashboardName,
          markerNameLink.dashboardUrl
        )
      : createPolygonMarker(
          latlon1.lng,
          latlon2.lng,
          latlon3.lng,
          latlon4.lng,
          latlon1.lat,
          latlon2.lat,
          latlon3.lat,
          latlon4.lat,
          value,
          layerName,
          name
        );

    vectorSource.addFeature(markerPolygon);

    if (rules.length) {
      for (let index = 0; index < rules.length; index++) {
        const { check, style: ruleStyle } = rules[index];
        if (!check || !ruleStyle) {
          continue;
        }
        const { operation, value, property } = check;
        const {
          fillOpacity,
          size: { min, max },
          symbol,
          color,
          rotation,
        } = ruleStyle;
        const sizeFinal = (max - min) * (Number(percent) || 0) + min;

        const cond = checkRuleIsMatched(markerPolygon.get(property), operation, value!);
        if (cond) {
          markerPolygon.setStyle(getStyle(symbol, color, fillOpacity, sizeFinal, rotation));
          return;
        }
      }
      markerPolygon.setStyle(getStyle(style.symbol, style.color, style.fillOpacity, sizeFinal, style.rotation));
    } else {
      markerPolygon.setStyle(getStyle(style.symbol, style.color, style.fillOpacity, sizeFinal, style.rotation));
    }
  }
}

function getDasboardNameLink(field: GeohashField, markerLinkList: QueryLinkMarker[]) {
  if (markerLinkList.length !== 0) {
    const data = markerLinkList.filter((item) => item.location === field.name);
    return data.length !== 0 && data[0].name.length > 0 && data[0].url.length > 0
      ? { dashboardName: data[0].name, dashboardUrl: data[0].url }
      : null;
  } else {
    return null;
  }
}
