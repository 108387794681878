import { EventBusExtended } from '@grafana/data';

let singletonInstance: EventBusExtended;

/**
 * Used during startup by Carbon to set the EventBusSrv so it is available
 * via the {@link getEventSrv} to the rest of the application.
 *
 * @internal
 */
export const setEventSrv = (instance: EventBusExtended) => {
  singletonInstance = instance;
};

/**
 * Used to retrieve the {@link EventBusSrv} that can be used to communicate
 * via http(s) to a remote backend such as the Carbon backend, a datasource etc.
 *
 * @public
 */
export const getEventSrv = (): EventBusExtended => singletonInstance;
