import { Map as OpenLayerMap } from 'ol';
import { Component } from 'react';

/**
 * Props for the HeatMapData component.
 *
 * @typedef {Object} Props
 * @property {OpenLayerMap | undefined} map - The OpenLayers map instance.
 * @property {function} onDataChange - A function to be called when data changes.
 */
interface Props {
  map: OpenLayerMap | undefined;
  onDataChange: () => void;
}

/**
 * State for the HeatMapData component.
 *
 * @typedef {Object} State
 * @property {number | undefined} currZoom - The current zoom level.
 */
interface State {
  currZoom: number | undefined;
}

/**
 * Control options for OpenStreetMap.
 *
 * @class HeatMapData
 * @extends {Component<Props, State>}
 */
export class HeatMapData extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { currZoom: props.map?.getView().getZoom() };
  }

  componentDidMount() {
    const { map, onDataChange } = this.props;
    map?.on('moveend', () => {
      this.setState({ ...this.state, currZoom: map?.getView().getZoom() });
      onDataChange();
    });
  }

  render() {
    return null;
  }
}
