import uPlot, { Axis, Cursor, BBox } from 'uplot';
import { Quadtree, Rect, pointWithin } from './quadtree';
import { distribute, SPACE_BETWEEN } from './distribute';
import { TooltipInterface, BarValueVisibility, BarStackingMode, VizTextDisplayOptions, BarGraphMode } from './types';
import { DataFrame, dateTimeFormat, GrafanaTheme, systemDateFormats, VizOrientation } from '@grafana/data';
import { calculateFontSize } from '@grafana/ui';
import { getTimeZone } from '@grafana/data/src/datetime/common';
import { preparePlotDataForTotals } from './utils/preparePlotData';

const pxRatio = devicePixelRatio;

const groupDistr = SPACE_BETWEEN;
const barDistr = SPACE_BETWEEN;
// min.max font size for value label
const VALUE_MIN_FONT_SIZE = 8;
const VALUE_MAX_FONT_SIZE = 30;
const BAR_FONT_SIZE_RATIO = 0.65;
// distance between label and a bar in % of bar width
const LABEL_OFFSET_FACTOR_VT = 0.1;
const LABEL_OFFSET_FACTOR_HZ = 0.15;
// max distance
const LABEL_OFFSET_MAX_VT = 5;
const LABEL_OFFSET_MAX_HZ = 10;
const MIDDLE_BASELINE_SHIFT = 0.1;

/**
 * @typedef {Object} BarsOptions
 * @property {number} xOri
 * @property {number} xDir
 * @property {numbmer} groupWidth
 * @property {number} toBarWidth
 * @property {BarValueVisibility} showValue
 * @property {BarStackingMode} stacking
 * @property {VizTextDisplayOptions} [text]
 * @property {VizOrientation} toOrientation
 * @property {string} graphMode
 * @property {DataFrame} data
 * @property {Array<any>} colorArray
 * @property {Function} rawValue
 * @property {Function} formatValue
 * @property {Function} [onHover]
 * @property {Function} [onLeave]
 */
export interface BarsOptions {
  xOri: 1 | 0;
  xDir: 1 | -1;
  groupWidth: number;
  toBarWidth: number;
  showValue: BarValueVisibility;
  stacking: BarStackingMode;
  text?: VizTextDisplayOptions;
  toOrientation: VizOrientation;
  graphMode: string;
  data: DataFrame;
  colorArray: any[];
  spaceXaxis?: number;
  spacingLTR: boolean;
  /**
   * returns the value
   *
   * @param {number} seriesIdx index
   * @param {number} valueIdx index
   *
   * @method
   */
  rawValue: (seriesIdx: number, valueIdx: number) => number | null;
  /**
   * returns the string
   *
   * @param {number} seriesIdx index
   * @param {any} value index
   *
   * @method
   */
  formatValue: (seriesIdx: number, value: any) => string;
  /**
   * returns the void
   *
   * @param {number} seriesIdx index
   * @param {any} valueIdx index
   *
   * @method
   */
  onHover?: (seriesIdx: number, valueIdx: any) => void;
  /**
   * returns the value
   *
   * @param {number} seriesIdx index
   * @param {any} valueIdx index
   *
   * @method
   */
  onLeave?: (seriesIdx: number, valueIdx: any) => void;
}

/**
 * provide all configuration to the bar chart
 * @function
 * @param {BarsOptions} opts
 * @param {GrafanaTheme} theme
 */
export function getConfig(opts: BarsOptions, theme: GrafanaTheme) {
  let {
    xOri: ori,
    xDir: dir,
    groupWidth,
    toBarWidth,
    formatValue,
    showValue,
    rawValue,
    graphMode,
    data,
    colorArray,
    stacking,
    spaceXaxis = 0,
    spacingLTR = false,
  } = opts;
  const isXHorizontal = ori === 0;
  const hasAutoValueSize = !Boolean(opts.text?.valueSize);
  const isStacked = opts.stacking !== BarStackingMode.None;
  const pctStacked = opts.stacking === BarStackingMode.Percent;
  const alignedTotals = preparePlotDataForTotals(data);
  if (isStacked) {
    [groupWidth, toBarWidth] = [toBarWidth, groupWidth];
  }
  let barsPctLayout: Array<null | { offs: number[]; size: number[] }> = [];
  let vSpace = Infinity;
  let hSpace = Infinity;
  let qt: Quadtree;

  let hovered: Rect | undefined;
  let barRects: Rect[] = [];

  let barMark = document.createElement('div');
  barMark.classList.add('bar-mark');
  barMark.style.position = 'absolute';
  barMark.style.background = 'rgba(255,255,255,0.4)';

  const xSplits: Axis.Splits = (u: uPlot, axisIdx: number) => {
    /**
     * automatic x values reversed according to canvas width and height
     */
    const dim = ori === 0 ? u.bbox.width : u.bbox.height;
    const _dir = dir * (ori === 0 ? 1 : -1);
    let dataLength = u.data[0].length;
    let lastIdxValue = dataLength - 1;

    let tickSkip = 0;
    let splits: number[] = [];

    if (spaceXaxis !== 0) {
      let LTR = spaceXaxis * (spacingLTR ? -1 : 1);
      let Dim = dim / pxRatio;
      let numbnerOfTicks = Math.abs(Math.floor(Dim / LTR));

      tickSkip = dataLength < numbnerOfTicks ? 0 : Math.ceil(dataLength / numbnerOfTicks);
      u.data[0].forEach((v, i) => {
        let xAxisTicks = tickSkip !== 0 && (LTR > 0 ? i : lastIdxValue - i) % tickSkip > 0;
        if (!xAxisTicks) {
          splits.push(i);
        }
      });
    } else {
      distribute(u.data[0].length, groupWidth, groupDistr, null, (di, lftPct, widPct) => {
        let groupLftPx = (dim * lftPct) / pxRatio;
        let groupWidPx = (dim * widPct) / pxRatio;

        let groupCenterPx = groupLftPx + groupWidPx / 2;

        splits.push(u.posToVal(groupCenterPx, 'x'));
      });
    }

    return _dir === 1 ? splits : splits.reverse();
  };
  // @ts-ignore
  const xValuesTime: Axis.Values = (u) => {
    let foundIncr = u.data[0][1] - u.data[0][0];
    return u.data[0].map((x) => formatTime(x, foundIncr));
  };
  const xValues: Axis.Values = (u) => {
    return u.data[0].map((x) => x);
  };

  const timeUnitSize = {
    second: 1000,
    minute: 60 * 1000,
    hour: 60 * 60 * 1000,
    day: 24 * 60 * 60 * 1000,
    month: 28 * 24 * 60 * 60 * 1000,
    year: 365 * 24 * 60 * 60 * 1000,
  };

  /** Format time axis ticks */
  function formatTime(v: any, foundIncr: number) {
    const timeZone = getTimeZone();
    const yearRoundedToDay = Math.round(timeUnitSize.year / timeUnitSize.day) * timeUnitSize.day;
    const incrementRoundedToDay = Math.round(foundIncr / timeUnitSize.day) * timeUnitSize.day;

    let format = systemDateFormats.interval.year;

    if (foundIncr < timeUnitSize.second) {
      format = systemDateFormats.interval.second.replace('ss', 'ss.SS');
    } else if (foundIncr <= timeUnitSize.minute) {
      format = systemDateFormats.interval.second;
    } else if (foundIncr <= timeUnitSize.hour) {
      format = systemDateFormats.interval.minute;
    } else if (foundIncr <= timeUnitSize.day) {
      format = systemDateFormats.interval.hour;
    } else if (foundIncr <= timeUnitSize.month) {
      format = systemDateFormats.interval.day;
    } else if (incrementRoundedToDay === yearRoundedToDay) {
      format = systemDateFormats.interval.year;
    } else if (foundIncr <= timeUnitSize.year) {
      format = systemDateFormats.interval.month;
    }
    // eslint-disable-next-line radix
    let x = parseInt(v);
    return dateTimeFormat(x, { format, timeZone });
  }
  let distrTwo = (groupCount: number, barCount: number) => {
    let out = Array.from({ length: barCount }, () => ({
      offs: Array(groupCount).fill(0),
      size: Array(groupCount).fill(0),
    }));

    distribute(groupCount, groupWidth, groupDistr, null, (groupIdx, groupOffPct, groupDimPct) => {
      distribute(barCount, toBarWidth, barDistr, null, (barIdx, barOffPct, barDimPct) => {
        out[barIdx].offs[groupIdx] = groupOffPct + groupDimPct * barOffPct;
        out[barIdx].size[groupIdx] = groupDimPct * barDimPct;
      });
    });

    return out;
  };

  let distrOne = (groupCount: number, barCount: number) => {
    let out = Array.from({ length: barCount }, () => ({
      offs: Array(groupCount).fill(0),
      size: Array(groupCount).fill(0),
    }));

    distribute(groupCount, groupWidth, groupDistr, null, (groupIdx, groupOffPct, groupDimPct) => {
      distribute(barCount, toBarWidth, barDistr, null, (barIdx, barOffPct, barDimPct) => {
        out[barIdx].offs[groupIdx] = groupOffPct;
        out[barIdx].size[groupIdx] = groupDimPct;
      });
    });

    return out;
  };
  let drawBars = uPlot.paths.bars!({
    disp: {
      x0: {
        unit: 2,
        values: (u, seriesIdx) => barsPctLayout[seriesIdx]!.offs,
      },
      size: {
        unit: 2,
        values: (u, seriesIdx) => barsPctLayout[seriesIdx]!.size,
      },
      /**
       * stroke and fill decide the color of single bar.
       * we only need this when we are making no time series bar graph
       */
      stroke:
        graphMode !== BarGraphMode.Timeseries && stacking === BarStackingMode.None
          ? {
              unit: 3,
              values: (u, seriesIdx) =>
                u.data[1].map((v, i) => {
                  return colorArray[i];
                }),
            }
          : undefined,
      fill:
        graphMode !== BarGraphMode.Timeseries && stacking === BarStackingMode.None
          ? {
              unit: 3,
              values: (u, seriesIdx) =>
                u.data[1].map((v, i) => {
                  return colorArray[i];
                }),
            }
          : undefined,
    },
    // collect rendered bar geometry
    each: (u, seriesIdx, dataIdx, lft, top, wid, hgt) => {
      // we get back raw canvas coords (included axes & padding)
      // translate to the plotting area origin
      lft -= u.bbox.left;
      top -= u.bbox.top;

      let val = u.data[seriesIdx][dataIdx]!;

      // accum min space abvailable for labels
      if (isXHorizontal) {
        vSpace = Math.min(vSpace, val < 0 ? u.bbox.height - (top + hgt) : top);
        hSpace = wid;
      } else {
        vSpace = hgt;
        hSpace = Math.min(hSpace, val < 0 ? lft : u.bbox.width - (lft + wid));
      }

      let barRect = { x: lft, y: top, w: wid, h: hgt, sidx: seriesIdx, didx: dataIdx };
      qt.add(barRect);
      /**
       * adding all single bar configurations into barRects
       */
      barRects.push(barRect);
    },
  });

  const init = (u: uPlot) => {
    let over = u.root.querySelector('.u-over')! as HTMLElement;
    over.style.overflow = 'hidden';
    over.appendChild(barMark);
  };
  // hide crosshair cursor & hover points
  const cursor: Cursor = {
    x: true,
    y: false,
    points: {
      show: false,
    },
  };

  // disable selection
  // uPlot types do not export the Select interface prior to 1.6.4
  const select: Partial<BBox> = {
    show: false,
  };

  /**
   *
   * clear canvas and other variables
   */
  const drawClear = (u: uPlot) => {
    qt = qt || new Quadtree(0, 0, u.bbox.width, u.bbox.height);

    qt.clear();

    // clear the path cache to force drawBars() to rebuild new quadtree
    u.series.forEach((s) => {
      // @ts-ignore
      s._paths = null;
    });

    if (isStacked) {
      //barsPctLayout = [null as any].concat(distrOne(u.data.length - 1, u.data[0].length));
      barsPctLayout = [null as any].concat(distrOne(u.data[0].length, u.data.length - 1));
    } else {
      barsPctLayout = [null as any].concat(distrTwo(u.data[0].length, u.data.length - 1));
    }
    barRects.length = 0;
    vSpace = hSpace = Infinity;
  };

  const LABEL_OFFSET_FACTOR = isXHorizontal ? LABEL_OFFSET_FACTOR_VT : LABEL_OFFSET_FACTOR_HZ;
  const LABEL_OFFSET_MAX = isXHorizontal ? LABEL_OFFSET_MAX_VT : LABEL_OFFSET_MAX_HZ;

  /**
   * uPlot hook to draw the labels on the bar chart.
   */
  const draw = (u: uPlot) => {
    if (showValue === BarValueVisibility.Never) {
      return;
    }
    // pre-cache formatted labels
    let texts = Array(barRects.length);
    let labelOffset = LABEL_OFFSET_MAX;

    barRects.forEach((r, i) => {
      texts[i] = formatValue(r.sidx, rawValue(r.sidx, r.didx)! / (pctStacked ? alignedTotals![r.sidx][r.didx]! : 1));
      labelOffset = Math.min(labelOffset, Math.round(LABEL_OFFSET_FACTOR * (isXHorizontal ? r.w : r.h)));
    });

    let fontSize = opts.text?.valueSize ?? VALUE_MAX_FONT_SIZE;

    if (hasAutoValueSize) {
      for (let i = 0; i < barRects.length; i++) {
        fontSize = Math.round(
          Math.min(
            fontSize,
            VALUE_MAX_FONT_SIZE,
            calculateFontSize(
              texts[i],
              hSpace * (isXHorizontal ? BAR_FONT_SIZE_RATIO : 1) - (isXHorizontal ? 0 : labelOffset),
              vSpace * (isXHorizontal ? 1 : BAR_FONT_SIZE_RATIO) - (isXHorizontal ? labelOffset : 0),
              1
            )
          )
        );
        if (fontSize < VALUE_MIN_FONT_SIZE && showValue !== BarValueVisibility.Always) {
          return;
        }
      }
    }

    u.ctx.save();

    u.ctx.fillStyle = theme.colors.text;
    u.ctx.font = `${fontSize}px Roboto`;
    let middleShift = isXHorizontal ? 0 : -Math.round(MIDDLE_BASELINE_SHIFT * fontSize);

    let curAlign: CanvasTextAlign, curBaseline: CanvasTextBaseline;

    /**
     * fill bars with values
     */
    barRects.forEach((r, i) => {
      let value = rawValue(r.sidx, r.didx);
      let text = texts[i];
      if (value != null) {
        let align: CanvasTextAlign = isXHorizontal ? 'center' : value < 0 ? 'right' : 'center';
        let baseline: CanvasTextBaseline = isXHorizontal ? (value < 0 ? 'top' : 'alphabetic') : 'middle';
        if (align !== curAlign) {
          u.ctx.textAlign = curAlign = align;
        }

        if (baseline !== curBaseline) {
          u.ctx.textBaseline = curBaseline = baseline;
        }
        // actual width calculation in stacking mode
        let barW;
        // actual width calculation in stacking mode
        let barH;
        if (i > u.data[0].length - 1) {
          barW = barRects[i - u.data[0].length].w + (r.w - barRects[i - u.data[0].length].w) / 2;
        } else {
          barW = r.w / 2;
        }
        if (i > u.data[0].length - 1) {
          barH = (r.h - barRects[i - u.data[0].length].h) / 2;
        } else {
          barH = r.h / 2;
        }

        u.ctx.fillText(
          text,
          u.bbox.left +
            (isXHorizontal
              ? r.x + r.w / 2
              : value <= 0
              ? r.x + labelOffset
              : isStacked
              ? r.x + barW
              : r.x + r.w / 2 + labelOffset),
          u.bbox.top +
            (isXHorizontal
              ? value < 0
                ? r.y + r.h + labelOffset
                : isStacked
                ? r.y + barH
                : r.y + r.h / 2 - labelOffset
              : r.y + r.h / 2 - middleShift)
        );
      }
    });
    u.ctx.restore();
  };
  /**
   *
   * @param seriesIndex
   * @param datapointIndex
   * @param showTooltip
   * @param u
   */
  const tooltipMaker: TooltipInterface = (seriesIndex, datapointIndex, showTooltip, u) => {
    let found: Rect | undefined;
    let cx = u.cursor.left! * pxRatio;
    let cy = u.cursor.top! * pxRatio;

    qt.get(cx, cy, 1, 1, (o) => {
      if (pointWithin(cx, cy, o.x, o.y, o.x + o.w, o.y + o.h)) {
        found = o;
      }
    });

    if (found) {
      if (found !== hovered) {
        barMark.style.display = '';
        barMark.style.left = found!.x / pxRatio + 'px';
        barMark.style.top = found!.y / pxRatio + 'px';
        barMark.style.width = found!.w / pxRatio + 'px';
        barMark.style.height = found!.h / pxRatio + 'px';
        hovered = found;
        seriesIndex(hovered.sidx);
        datapointIndex(hovered.didx);
        showTooltip();
      }
    } else if (hovered !== undefined) {
      seriesIndex(hovered!.sidx);
      datapointIndex(hovered!.didx);
      showTooltip();
      hovered = undefined;
      barMark.style.display = 'none';
    } else {
      showTooltip(true);
    }
  };

  return {
    // cursor & select opts
    cursor,
    select,

    // scale & axis opts
    xValues,
    xValuesTime,
    xSplits,

    // pathbuilders
    drawBars,
    // drawPoints,

    // hooks
    draw,
    init,
    drawClear,
    // setCursor,
    tooltipMaker,
    // prepData,
  };
}
