import { EventBus, FieldConfigSource, GrafanaTheme } from '@grafana/data';
import { config } from '@grafana/runtime';
import { useTheme } from '@grafana/ui';
import { css } from 'emotion';

import { Map as OpenLayerMap } from 'ol';
import { Control, FullScreen, Rotate, ScaleLine, Zoom } from 'ol/control';
import { Coordinate } from 'ol/coordinate';
import { DragRotateAndZoom } from 'ol/interaction';
import DragPan from 'ol/interaction/DragPan';
import MouseWheelZoom from 'ol/interaction/MouseWheelZoom';
import PinchRotate from 'ol/interaction/PinchRotate';
import PinchZoom from 'ol/interaction/PinchZoom';
import { fromLonLat, toLonLat } from 'ol/proj';
import { useEffect, useState } from 'react';
import { setLegend } from '../Legend/legend';
import { LegendMode } from '../types/interface';
import { OSMapPanelOptions } from '../types/types';
import { ShareZoomEvent } from '../utils/events';
import { CustomControl } from './CustomControlLegend';

/**
 * Properties for a map component.
 *
 * @typedef {Object} Props
 * @property {number} width - The width of the map component.
 * @property {number} height - The height of the map component.
 * @property {OpenLayerMap | undefined} map - The OpenLayerMap instance.
 * @property {OSMapPanelOptions} options - Configuration options for the map.
 * @property {EventBus} eventBus - The event bus for handling map events.
 * @property {FieldConfigSource<any>} fieldConfig - The field configuration source.
 * @property {CustomControl} customControl - The custom control for the map.
 */
interface Props {
  width: number;
  height: number;
  map: OpenLayerMap | undefined;
  options: OSMapPanelOptions;
  eventBus: EventBus;
  fieldConfig: FieldConfigSource<any>;
  customControl: CustomControl;
}

/**
 * Map controller component.
 *
 * @param {Props} props - The component props.
 * @returns {null} Returns null as this component doesn't render content.
 */
export function Controller(props: Props): null {
  const { map, options, width, height, eventBus, fieldConfig, customControl } = props;
  const { mapView, showController, showTools, showInfo, shared, legendMode } = options;
  const theme = useTheme();
  const styles = getStyles(theme);

  const [currZoom, setCurrZoom] = useState<number | undefined>();
  const [currCenter, setCurrCenter] = useState<Coordinate | undefined>(
    fromLonLat([mapView.center.lng, mapView.center.lat])
  );

  //Threshold Legend
  const { legend } = setLegend(fieldConfig, options.showInfo);

  useEffect(() => {
    map?.on('moveend', () => {
      const view = map?.getView();
      setCurrZoom(view.getZoom());
      setCurrCenter(view.getCenter());

      if (shared) {
        eventBus?.publish({
          type: ShareZoomEvent.type,
          payload: {
            sharedView: view,
          },
        });
      }
    });
  }, [map]);

  useEffect(() => {
    if (shared) {
      eventBus.subscribe(ShareZoomEvent, (event: ShareZoomEvent) => {
        const currentView = event.payload.sharedView;
        map?.setView(currentView);
      });
    }
  }, [map, shared]);

  // zoom change handler
  useEffect(() => {
    map?.getView().setZoom(mapView.zoom);
  }, [mapView.zoom, map]);

  // center change handler
  useEffect(() => {
    map?.getView().setCenter(fromLonLat([mapView.center.lng, mapView.center.lat]));
  }, [mapView.center, map]);

  useEffect(() => {
    if (showController) {
      map?.addInteraction(new MouseWheelZoom());
      map?.addInteraction(new DragPan());
      map?.addInteraction(new PinchZoom());
      map?.addInteraction(new PinchRotate());
      map?.addInteraction(new DragRotateAndZoom());
    }
    return () => {
      map?.getInteractions().clear();
    };
  }, [showController, map]);

  // width and height change handler
  useEffect(() => {
    map?.updateSize();
  }, [width, height, map]);

  useEffect(() => {
    map?.getControls().forEach((control) => {
      if (control instanceof CustomControl) {
        map?.removeControl(control);
      }
    });

    let legendControl: Control;
    let heatGradientLegend: Control;
    let scaleLine: Control;
    let mapInfoControl: Control;
    let zoomControl: Control;
    let fullScreenControl: Control;
    let rotateControl: Control;

    if (showTools) {
      zoomControl = new Zoom({
        className: styles.zoomOpt,
        zoomInClassName: styles.zoomIn,
        zoomOutClassName: styles.zoomOut,
      });
      map?.addControl(zoomControl);

      fullScreenControl = new FullScreen({
        className: styles.fullScreen,
        label: getFullScreenElements(false),
        labelActive: getFullScreenElements(true),
        activeClassName: styles.active,
        inactiveClassName: styles.active,
      });
      map?.addControl(fullScreenControl);

      rotateControl = new Rotate({
        className: styles.rotate,
        label: getRotateElement(),
        // compassClassName: styles.activeRotate,
        autoHide: false,
      });
      // map?.addControl(rotateControl);
    }
    if (legendMode === LegendMode.Heatmap || legendMode === LegendMode.Both) {
      map?.addControl(customControl);
    }

    if (legend && (legendMode === LegendMode.Threshold || legendMode === LegendMode.Both)) {
      legendControl = new Control({ element: legend });
      map?.addControl(legendControl);
    }

    if (showInfo) {
      scaleLine = new ScaleLine({ minWidth: 100, className: styles.scaleLine });
      map?.addControl(scaleLine);
      const mapInfo = getMapInfo(config.theme, currZoom, currCenter);
      mapInfoControl = new Control({ element: mapInfo });
      map?.addControl(mapInfoControl);
    }

    return () => {
      map?.removeControl(legendControl);
      map?.removeControl(heatGradientLegend);
      map?.removeControl(scaleLine);
      map?.removeControl(mapInfoControl);
      map?.removeControl(zoomControl);
      map?.removeControl(fullScreenControl);
      map?.removeControl(rotateControl);
      map?.removeControl(customControl);
    };
  }, [showTools, map, legend, mapView, currZoom, currZoom, showInfo, customControl, legendMode]);

  return null;
}

/**
 * Creates a DOM element with map information.
 *
 * @param {GrafanaTheme} theme - The Grafana theme for styling.
 * @param {number} [zoom] - The current zoom level of the map.
 * @param {Coordinate} [center] - The center coordinates of the map.
 * @returns {HTMLDivElement} Returns a DOM element with map information.
 */
function getMapInfo(theme: GrafanaTheme, zoom?: number, center?: Coordinate) {
  const div = document.createElement('div');
  div.setAttribute(
    'style',
    `padding: 5px;
     color: ${theme.colors.text};
     display:flex; 
     flex-direction: column;
     border-radius: 4px; 
     position: absolute; 
     top: 10px; 
     left: 10px;
     font-size: ${theme.typography.size.sm};
     background-color: ${theme.colors.bg1}; 
     border: 2px solid ${theme.colors.border1};
     `
  );
  const span = document.createElement('span');
  span.innerHTML = `zoom: ${zoom?.toFixed(2) || 0}`;

  const span1 = document.createElement('span');
  const span2 = document.createElement('span');
  if (center) {
    const latlon = toLonLat(center);

    span1.innerHTML = `lng: ${latlon[0].toFixed(5)}`;
    span2.innerHTML = `lat: ${latlon[1].toFixed(5)}`;
  }
  div.appendChild(span);
  div.appendChild(span1);
  div.appendChild(span2);
  return div;
}

/**
 * Creates a DOM element representing full-screen control with an icon.
 *
 * @param {boolean} isActive - Indicates whether the full-screen mode is active.
 * @returns {HTMLDivElement} Returns a DOM element representing full-screen control.
 */
function getFullScreenElements(isActive: boolean) {
  const div = document.createElement('div');
  const span = document.createElement('span');

  const icon = document.createElement('i');
  icon.className = isActive ? 'fa fa-compress' : 'fa fa-arrows-alt';
  icon.setAttribute('aria-hidden', 'true');

  span.appendChild(icon);
  div.appendChild(span);

  return div;
}

function getRotateElement() {
  const div = document.createElement('div');
  const span = document.createElement('span');

  const icon = document.createElement('i');
  icon.className = 'fa fa-arrow-up';
  icon.setAttribute('aria-hidden', 'true');

  span.appendChild(icon);
  div.appendChild(span);

  return div;
}

export const getStyles = (theme: GrafanaTheme) => {
  return {
    zoomOpt: css`
      display: flex;
      flex-direction: column;
      bottom: 10px;
      right: 10px;
      text-align: center;
      border-radius: 3px;
      position: absolute;
    `,
    zoomIn: css`
      width: 25px;
      background-color: ${theme.colors.bg1};
      border: 2px solid ${theme.colors.border1};
      border-radius: 4px 4px 0px 0px;
    `,
    zoomOut: css`
      width: 25px;
      background-color: ${theme.colors.bg1};
      border: 2px solid ${theme.colors.border1};
      border-radius: 0px 0px 4px 4px;
      border-top: 0px;
    `,
    scaleLine: css`
      display: flex;
      flex-direction: column;
      bottom: 10px;
      left: 10px;
      text-align: center;
      position: absolute;
      color: ${theme.colors.text};
      border-radius: 3px;
      border: 2px solid ${theme.colors.border1};
      border-top: 0px;
      background-color: ${theme.colors.bg1};
    `,
    fullScreen: css`
      display: flex;
      flex-direction: column;
      bottom: 65px;
      right: 10px;
      text-align: center;
      position: absolute;
      border-radius: 3px;
    `,
    rotate: css`
      display: flex;
      flex-direction: column;
      bottom: 120px;
      right: 10px;
      text-align: center;
      position: absolute;
      border-radius: 3px;
      padding: 5px;
      border-radius: 4px;
      background-color: ${theme.colors.bg1};
      border: 2px solid ${theme.colors.border1};
    `,
    active: css`
      padding: 5px;
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      bottom: 0.1px;
      right: 0.1px;
      position: absolute;
      background-color: ${theme.colors.bg1};
      border: 2px solid ${theme.colors.border1};
    `,
    activeRotate: css`
      padding: 5px;
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      bottom: 0.1px;
      right: 0.1px;
      background-color: ${theme.colors.bg1};
      border: 2px solid ${theme.colors.border1};
    `,
  };
};
