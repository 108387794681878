import React from 'react';
import { StandardEditorProps } from '@grafana/data';
import { Slider, Label } from '@grafana/ui';

interface Props
  extends StandardEditorProps<{
    rotationAngles: number;
    padding: number;
    rotations: number;
  }> {}

export const wordAttributesEditor: React.FC<Props> = ({ value, onChange }) => {
  // TO ADJUST THE PADDING
  function paddinghandleChange(e: any) {
    onChange({ ...value, padding: e });
  }
  // TO ADJUST THE ROTATION
  function rotationhandleChange(e: any) {
    onChange({ ...value, rotations: e });
  }
  return (
    <>
      <div style={{ marginTop: '5px', marginBottom: '10px' }}>
        <Slider min={1} max={100} step={1} onChange={(e: any) => paddinghandleChange(e)} value={value.padding} />
      </div>
      <div style={{ marginTop: '5px', marginBottom: '10px' }}>
        <Label description="Select the number of Rotations">WordCloud Rotations</Label>
        <Slider min={0} max={50} step={1} onChange={(e: any) => rotationhandleChange(e)} value={value.rotations} />
      </div>
    </>
  );
};
