import React from 'react';
import { PanelPlugin } from '@grafana/data';
import { ChordChartOptions, addStandardDataReduceOptions, ChordChartLegendValues } from './types';
import { ChordPanel } from './ChordPanel';
import { HandleEditor } from './HandleEditor';
import { LegendDisplayMode } from '@grafana/ui';

export const plugin = new PanelPlugin<ChordChartOptions>(ChordPanel).useFieldConfig().setPanelOptions((builder) => {
  addStandardDataReduceOptions(builder);

  builder
    // Custom editor for handling mappings
    .addCustomEditor({
      category: ['Editor'],
      id: 'chordData',
      path: 'chordData',
      name: 'Add Nodes',
      description: 'Add Nodes to the chart',
      defaultValue: {
        links: [],
        queryNode: [],
      },
      editor(props: any) {
        return (
          <HandleEditor
            chordData={props.value}
            onChange={props.onChange}
            contextData={props.context}
            panelData={props.context.data}
          />
        );
      },
    })
    .addBooleanSwitch({
      path: 'dataLabels',
      name: 'Data Labels',
      description: 'Shows data labels on top of the chart',
      defaultValue: true,
      category: ['Chart Options'],
    })
    .addRadio({
      path: 'tooltip.mode',
      name: 'Tooltip mode',
      description: '',
      defaultValue: 'single',
      category: ['Chart Options'],
      settings: {
        options: [
          { value: 'single', label: 'Show' },
          { value: 'none', label: 'Hide' },
        ],
      },
    })
    .addRadio({
      path: 'legend.displayMode',
      name: 'Legend mode',
      description: '',
      defaultValue: LegendDisplayMode.List,
      category: ['Chart Options'],
      settings: {
        options: [
          { value: LegendDisplayMode.List, label: 'List' },
          { value: LegendDisplayMode.Table, label: 'Table' },
          { value: LegendDisplayMode.Hidden, label: 'Hidden' },
        ],
      },
    })
    .addRadio({
      path: 'legend.placement',
      name: 'Legend placement',
      description: '',
      defaultValue: 'bottom',
      category: ['Chart Options'],
      settings: {
        options: [
          { value: 'bottom', label: 'Bottom' },
          { value: 'right', label: 'Right' },
        ],
      },
      showIf: (c) => c.legend.displayMode !== LegendDisplayMode.Hidden,
    })
    .addMultiSelect({
      name: 'Legend values',
      path: 'legend.values',
      category: ['Chart Options'],
      settings: {
        options: [
          { value: ChordChartLegendValues.Percent, label: 'Percent' },
          { value: ChordChartLegendValues.Value, label: 'Value' },
        ],
      },
      showIf: (c) => c.legend.displayMode !== LegendDisplayMode.Hidden,
    });
});
