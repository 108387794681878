import React, { PureComponent } from 'react';
import { Button, VerticalGroup } from '@grafana/ui';
import { EditorSensorItem } from './EditorSensorItem';
import update from 'immutability-helper';
import Sensor from '../Types/Sensor';
import { DashboardTime } from 'app/features/linkDrawer/dashboard/DashboardTime';

interface Props {
  sensorData: {
    sensors: Sensor[];
    data: any;
    reload: number;
    drawerLinkTime: boolean;
  };

  QueryData: any;

  onChange: (sensors: { sensors: Sensor[]; data: any; reload: number; drawerLinkTime: boolean }) => void;
}

interface State {
  sensors: Sensor[];
}

const defaultNewSensor: Sensor = {
  dashboardName: 'Name',
  value: undefined,
  visible: true,
  backgroundColor: '#000',
  fontColor: '#FFF',
  bold: false,
  dashboardUrl: '',
  position: {
    x: 50,
    y: 50,
  },
  Manual: false,
  fontStyle: 'normal',
};

export class EditorSensorList extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sensors: this.props.sensorData.sensors || [],
    };
  }

  onRemove = (idx: number) => {
    this.setState(
      (prevState: State) => ({
        ...prevState,
        sensors: prevState.sensors.filter((sensor, index) => index !== idx),
      }),
      () => this.onChange()
    );
  };

  onChange = () => {
    this.props.onChange({ ...this.props.sensorData, sensors: this.state.sensors });
  };

  onSensorChange = (sensor: Sensor, index: number): void => {
    this.setState(
      {
        sensors: update(this.state.sensors, { [index]: { $set: sensor } }),
      },
      () => {
        this.onChange();
      }
    );
  };

  addNewSensor = () => {
    this.setState(
      {
        sensors: update(this.state.sensors, { $push: [defaultNewSensor] }),
      },
      () => {
        this.onChange();
      }
    );
  };

  onQueryReload = () => {
    this.props.onChange({ ...this.props.sensorData, reload: this.props.sensorData.reload + 1 });
  };

  handleDrawerLinkTime = (value: boolean) => {
    this.props.onChange({ ...this.props.sensorData, drawerLinkTime: value });
  };

  handleRemoveSensor = (index: number) => {
    this.onRemove(index);
  };

  render() {
    const { sensors } = this.state;
    return (
      <>
        <DashboardTime value={this.props.sensorData.drawerLinkTime} drawerLinkTime={this.handleDrawerLinkTime} />
        <VerticalGroup spacing="md">
          {sensors &&
            sensors.map((sensor: Sensor, index: number) => {
              return (
                <EditorSensorItem
                  key={index}
                  sensor={sensor}
                  data={this.props.sensorData.data}
                  QueryData={this.props.QueryData}
                  onChange={this.onSensorChange}
                  index={index}
                  handleRemoveSensor={this.handleRemoveSensor}
                />
              );
            })}
          <Button onClick={this.addNewSensor} variant="primary" size="md">
            Add New
          </Button>
          <div className="gf-form gf-form--grow">
            <Button
              icon="refresh"
              style={{ width: 'fit-content' }}
              onClick={this.onQueryReload}
              variant="secondary"
              size="md"
            >
              Refresh Queries
            </Button>
          </div>
        </VerticalGroup>
      </>
    );
  }
}
