import React from 'react';
import { PanelPlugin } from '@grafana/data';
import { SimpleOptions, addStandardDataReduceOptions } from './Types/SimpleOptions';
import { ImageItPanel } from './ImageItPanel';
import { EditorSensorList } from './CustomEditors/EditorSensorList';
import { ImageViewer } from './CustomEditors/ImageViewer';

export const plugin = new PanelPlugin<SimpleOptions>(ImageItPanel).useFieldConfig().setPanelOptions((builder) => {
  // @ts-ignore
  addStandardDataReduceOptions(builder);
  const panelOptionsBuilder = builder
    .addCustomEditor({
      id: 'toShowImg',
      path: 'toShowImg',
      name: 'Select Image',
      description: 'Choose an image file to view.',
      defaultValue: '',
      editor(props: any) {
        return <ImageViewer toShowImg={props.value} onChange={props.onChange} />;
      },
    })
    .addTextInput({
      path: 'imageUrl',
      name: 'Image URL',
      description: 'URL of base image',
      defaultValue: '',
    })
    .addSelect({
      path: 'fontStyle',
      name: 'Font Style',
      description: 'Choosing font style',
      defaultValue: 'normal',
      settings: {
        options: [
          {
            value: 'normal',
            label: 'Normal',
          },
          {
            value: 'italic',
            label: 'Italic',
          },
          {
            value: 'oblique',
            label: 'Oblique',
          },
        ],
      },
    })
    .addNumberInput({
      path: 'minimum',
      name: 'Min',
      description: 'Minimum Font Size',
      defaultValue: 5,
    })
    .addNumberInput({
      path: 'maximum',
      name: 'Max',
      description: 'Maximum Font Size',
      defaultValue: 18,
    })
    .addBooleanSwitch({
      path: 'lockSensors',
      name: 'Lock sensors',
      defaultValue: false,
      category: ['Sensor Editor'],
    })
    .addBooleanSwitch({
      path: 'dashVisit',
      name: 'Link Dashboard',
      defaultValue: true,
      category: ['Sensor Editor'],
    })
    .addCustomEditor({
      id: 'sensorData',
      path: 'sensorData',
      name: 'List of sensors',
      // description: 'List of sensors',
      defaultValue: {
        sensors: [],
        data: [],
        reload: 1,
      },
      editor(props: any) {
        return <EditorSensorList QueryData={props.context.data} sensorData={props.value} onChange={props.onChange} />;
      },
      category: ['Sensor Editor'],
    });

  return panelOptionsBuilder;
});
