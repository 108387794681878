export const sortFields = [
  { label: 'Alphabetical (asc)', value: 'asc' },
  { label: 'Alphabetical (desc)', value: 'desc' },
  { label: 'Time (asc)', value: 'tasc' },
  { label: 'Time (desc)', value: 'tdesc' },
];

export interface RuleState {
  rule: {
    maxItems: number;
    sortOrder: string;
    thisDashboardOnly: boolean;
    stateFilters: {
      ok: boolean;
      normal: boolean;
      paused: boolean;
      nodata: boolean;
      executionerror: boolean;
      alerting: boolean;
      pending: boolean;
    };
  };
}

export interface RuleI {
  maxItems: number;
  sortOrder: string;
  thisDashboardOnly: boolean;
  stateFilters: {
    ok: boolean;
    normal: boolean;
    paused: boolean;
    nodata: boolean;
    executionerror: boolean;
    alerting: boolean;
    pending: boolean;
  };
}

export const defaultState = {
  maxItems: 10,
  sortOrder: 'asc',
  thisDashboardOnly: false,
  stateFilters: {
    ok: false,
    normal: false,
    paused: false,
    nodata: false,
    executionerror: false,
    alerting: false,
    pending: false,
  },
};

export interface AlertOptions {
  alertoptions: any;
}

/**
 * Helper class for rss-parser
 */
export interface RssFeed {
  title?: string;
  description?: string;
  items: RssItem[];
}

export interface RssItem {
  title: string;
  link: string;
  pubDate?: string;
  content?: string;
  contentSnippet?: string;
}
