import { ArrayVector, DataFrame, FieldType, urlUtil } from '@grafana/data';
import { parseInt } from 'lodash';
import { getBackendSrv } from '@grafana/runtime';
import { getDashboardSrv } from 'app/features/dashboard/services/DashboardSrv';

interface SingleStateObject {
  AlertId: number;
  CreatedAt: number;
  CurrState: string;
  Data: string;
  PrevState: string;
}
export function annotationPrepare(annotationsAlertList: any, timeFrom: number, timeTo: number) {
  let annotation_time: number[] = [];
  let annotation_data: string[] = [];
  let annotation_state: string[] = [];
  const annotationsDataFrame: DataFrame[] = [];

  // * run according to alerts length
  if (annotationsAlertList) {
    for (let index = 0; index < annotationsAlertList.length; index++) {
      const singleAlertAnnotation = annotationsAlertList[index];
      annotation_time = [];
      annotation_state = [];
      annotation_data = [];
      for (let j = 0; j < singleAlertAnnotation.length; j++) {
        const singleChangingStateObject: SingleStateObject = singleAlertAnnotation[j];
        if (singleChangingStateObject.CreatedAt * 1000 > timeFrom) {
          annotation_time.push(singleChangingStateObject.CreatedAt * 1000);
          annotation_data.push(singleChangingStateObject.Data);
          annotation_state.push(singleChangingStateObject.CurrState);
        }
      }
      if (annotation_time && annotation_data && annotation_state) {
        annotationsDataFrame.push({
          name: `demo title ${Math.random() * 3}`,
          fields: [
            {
              name: 'time',
              type: FieldType.time,
              values: new ArrayVector(annotation_time.map((v) => v)),
              config: {},
            },
            {
              name: 'text',
              type: FieldType.string,
              values: new ArrayVector(annotation_data.map((v) => v)),
              config: {},
            },
            {
              name: 'currentState',
              type: FieldType.string,
              values: new ArrayVector(annotation_state.map((v) => v)),
              config: {},
            },
          ],
          length: annotation_time.length,
        });
      }
    }
  }
  return annotationsDataFrame ? annotationsDataFrame : null;
}

export const getAlertRuleList = async (id: number) => {
  // * used only in fetching alerts for annotations
  let panelId = 0;
  const searchedParams = urlUtil.getUrlSearchParams();
  if (searchedParams?.editPanel) {
    // * when panel is editing
    panelId = parseInt(searchedParams.editPanel);
  } else {
    // * when panel is in dashboard
    panelId = id;
  }

  // * backend service for dashboard details
  if (panelId > 0) {
    try {
      const dash = getDashboardSrv().getCurrent();
      // todo: get annotations for all time-series panels in the dashboard at once
      // todo: the getAlertRuleList method is called for every panel in the dashboard individually which should not be the case
      // todo: for now one more case for open dashboard has been added in the getAlertRuleList method and respective api is being hit
      // todo: but in the future all the annotations will be fetched for every time-series panel at once for a dashboard
      // todo: hittig api for every panel individually should be avoided
      // Check if open dashboard
      if (dash.isOpen) {
        // Check if annotations is enabled or not. If not enabled return null.
        if (!dash.openDashboardData.annotations) {
          return null;
        }
        return await getBackendSrv().post(`/open-dashboard/annotation/${dash.uid}/${panelId}`);
      }
      // Proceed in non open dashboard mode
      return await getBackendSrv().get(`/api/annotation/${dash.uid}/${panelId}`);
    } catch (err) {
      console.log(err);
    }
  }
  return [];
};

export function annotationListPrepare(annotationList: any) {
  let annotation_time: number[] = [];
  let annotation_data: string[] = [];
  let annotation_state: string[] = [];
  const annotationsListDataFrame: DataFrame[] = [];

  // * run according to annotation length
  if (annotationList && annotationList.length !== 0) {
    for (let index = 0; index < annotationList.length; index++) {
      const singleAlertAnnotation = annotationList[index];
      annotation_time = [];
      annotation_state = [];
      annotation_data = [];
      if (singleAlertAnnotation.time) {
        annotation_time.push(singleAlertAnnotation.time);
        annotation_data.push(singleAlertAnnotation);
        annotation_state.push('Warning');
      }

      if (annotation_time && annotation_data && annotation_state) {
        annotationsListDataFrame.push({
          name: `demo title ${Math.random() * 3}`,
          fields: [
            {
              name: 'time',
              type: FieldType.time,
              values: new ArrayVector(annotation_time.map((v) => v)),
              config: {},
            },
            {
              name: 'data',
              type: FieldType.string,
              values: new ArrayVector(annotation_data.map((v) => v)),
              config: {},
            },
            {
              name: 'currentState',
              type: FieldType.string,
              values: new ArrayVector(annotation_state.map((v) => v)),
              config: {},
            },
          ],
          length: annotation_time.length,
        });
      }
    }
  }
  return annotationsListDataFrame ? annotationsListDataFrame : null;
}
