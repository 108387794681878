import { PanelPlugin } from '@grafana/data';
import { TextOptions, addStandardDataReduceOptions } from './types/panel';
import { HelpersEditor, TextEditor, TextPanel } from './components';
import { CodeLanguageOptions, DefaultOptions, EveryRowOptions, FormatOptions, RenderSeriesPicker } from './constants';
/**
 * Panel Plugin
 */
export const plugin = new PanelPlugin<TextOptions>(TextPanel)
  .useFieldConfig()
  .setNoPadding()
  .setPanelOptions((builder: any) => {
    // @ts-ignore
    addStandardDataReduceOptions(builder);
    builder
      .addBooleanSwitch({
        path: 'fontoverride',
        name: 'Override Font',
        description: 'Enable to override default font size',
        defaultValue: false,
      })
      .addNumberInput({
        path: 'minlimit',
        name: 'Minimum Font Factor',
        description: 'Sets the minimum factor to scale the default font size',
        defaultValue: DefaultOptions.minlimit,
        settings: {
          placeholder: '1',
          // integer: true,
          min: 0,
          max: 2,
        },
        showIf: (options: TextOptions) => options.fontoverride,
      })
      .addNumberInput({
        path: 'maxlimit',
        name: 'Maximum Font Factor',
        description: 'Sets the maximum factor to scale the default font size',
        defaultValue: DefaultOptions.maxlimit,
        settings: {
          placeholder: '1',
          // integer: true,
          min: 0,
          max: 2,
        },
        showIf: (options: TextOptions) => options.fontoverride,
      })
      .addRadio({
        path: 'everyRow',
        name: 'Render template',
        settings: {
          options: EveryRowOptions,
        },
        defaultValue: DefaultOptions.everyRow,
      })
      .addRadio({
        path: 'seriespicker',
        name: 'Render Series Picker',
        settings: {
          options: RenderSeriesPicker,
        },
        defaultValue: DefaultOptions.seriespicker,
      })
      /**
       * Editor
       */
      .addRadio({
        path: 'editor.language',
        name: 'Primary Content Language',
        description: 'Used for formatting and suggestions.',
        settings: {
          options: CodeLanguageOptions,
        },
        defaultValue: DefaultOptions.editor.language,
        category: ['Editor'],
      })
      .addRadio({
        path: 'editor.format',
        name: 'Formatting',
        settings: {
          options: FormatOptions,
        },
        defaultValue: DefaultOptions.editor.format,
        category: ['Editor'],
      })
      .addSliderInput({
        path: 'editor.height',
        name: 'Height, px',
        defaultValue: DefaultOptions.editor.height,
        settings: {
          min: 100,
          max: 2000,
        },
        category: ['Editor'],
      })
      /**
       * Content
       */
      .addCustomEditor({
        id: 'content',
        path: 'content',
        name: 'Content',
        description: 'Supports Markdown and Handlebars',
        defaultValue: DefaultOptions.content,
        editor: TextEditor,
        category: ['Editor'],
      })
      .addCustomEditor({
        id: 'defaultContent',
        path: 'defaultContent',
        name: 'Default Content',
        description: 'Displayed when query result is empty.',
        defaultValue: DefaultOptions.defaultContent,
        editor: TextEditor,
        category: ['Editor'],
      })
      .addCustomEditor({
        id: 'helpers',
        path: 'helpers',
        name: 'JavaScript Code',
        description: 'Allows to add Handlebars Helpers and event handlers.',
        defaultValue: DefaultOptions.helpers,
        editor: HelpersEditor,
        category: ['Helpers'],
      });

    return builder;
  });
