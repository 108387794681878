import React, { useCallback } from 'react';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Point } from '@visx/point';
import { useTooltip, Tooltip, defaultStyles } from '@visx/tooltip';
import { Line, LineRadial } from '@visx/shape';
import { Text } from '@visx/text';
import tinycolor from 'tinycolor2';
import darkTheme from '@grafana/ui/src/themes/dark';

import { degrees, genAngles, genPoints, genPolygonPoints, defaultMargin } from './utils';
import { DataToUse, RadarPanelOptions } from './types';
import { getColorForTheme } from '@grafana/data';
import { useTheme } from '@grafana/ui';

/**
 * @constant
 */
const silver = '#d9d9d9';

const y = (d: number) => d;

/**
 * @typedef {Object} RadarData
 * @property {any} webs
 * @property {any} points
 * @property {Array<number>} data
 * @property {string} name
 * @property {string} stroke
 * @property {Array<string>} [dataStr]
 * @property {any} polygonPoints
 */
interface RadarData {
  webs: any;
  points: any;
  polygonPoints: any;
  data: number[];
  name: string;
  stroke: string;
  dataStr?: string[];
}

/**
 * @typedef {Object} RadarSvgProps
 * @property {number} width
 * @property {number} height
 * @property {object} [margin]
 * @property {number} top
 * @property {number} right
 * @property {number} bottom
 * @property {number} left
 * @property {RadarPanelOptions} options
 * @property {Array<DataToUse>} newData
 */
export type RadarSvgProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  options: RadarPanelOptions;
  newData: DataToUse[];
};
/**
 *
 * @param {RadarSvgProps}
 * @returns {JSX.Element}
 */
export function RadarChartSvg({ width, height, margin = defaultMargin, newData, options }: RadarSvgProps) {
  const { levels = 9, pointcolor, categories, showpoint, fillopacity } = options;
  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip();

  // bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const radius = Math.min(xMax, yMax) / 2;
  const zeroPoint = new Point({ x: 0, y: 0 });

  // point color
  const pointCol = tinycolor(getColorForTheme(pointcolor, darkTheme)).toString();
  const theme = useTheme();
  const radialScale = scaleLinear<number>({
    range: [0, Math.PI * 2],
    domain: [degrees, 0],
  });

  //  generating radar Data for svg group
  let radarData: RadarData[] = [];
  for (let index = 0; index < newData.length; index++) {
    const singleData = newData[index].data;
    const name = newData[index].name;
    const stroke = newData[index].stroke;
    const dataStr = newData[index].dataStr;
    const yScale = scaleLinear<number>({
      range: [0, radius],
      //   @ts-ignore
      domain: [0, Math.max(...singleData.map(y))],
    });
    const webs = genAngles(singleData.length);
    const points = genPoints(singleData.length, radius);
    const polygonPoints = genPolygonPoints(singleData, (d) => yScale(d) ?? 0, y);
    radarData.push({ data: singleData, webs, points, polygonPoints, name, stroke, dataStr });
  }

  const handleMouseOver = useCallback(
    (coords, datum) => {
      showTooltip({
        tooltipLeft: coords.x,
        tooltipTop: coords.y,
        tooltipData: datum,
      });
    },
    [showTooltip]
  );

  const tooltipStyles = {
    ...defaultStyles,
    backgroundColor: 'rgba(50,50,50,0.8)',
    color: 'white',
    padding: 12,
  };
  return (
    <div>
      <svg width={width} height={height}>
        {radarData &&
          radarData.map((group, index) => {
            return (
              <Group key={`Group-${index}`} top={height / 2} left={width / 2}>
                {[...new Array(levels)].map((_, i) => (
                  <>
                    {index === 0 && (
                      <LineRadial
                        key={`web-${i}-${index}`}
                        data={group.webs}
                        angle={(d: any) => radialScale(d.angle - 180) ?? 0}
                        radius={((i + 1) * radius) / levels}
                        fill="none"
                        stroke={silver}
                        strokeWidth={2}
                        strokeOpacity={0.8}
                        strokeLinecap="round"
                      />
                    )}
                  </>
                ))}
                {[...new Array(group.data.length)].map((_, i) => (
                  <>
                    {index === 0 && (
                      <Line key={`radar-line-${i}-${index}`} from={zeroPoint} to={group.points[i]} stroke={silver} />
                    )}
                    {index === 0 && (
                      <Text
                        key={`text-${i}`}
                        textAnchor="middle"
                        verticalAnchor="middle"
                        dx={group.points[i].x}
                        dy={group.points[i].y}
                        style={{
                          fill: theme.isDark ? '#FFFFFF' : '#000000',
                          fontSize: 16,
                          fontWeight: 600,
                        }}
                      >
                        {categories[i]}
                      </Text>
                    )}
                  </>
                ))}
                <polygon
                  key={`polygon-point-${index}}`}
                  points={group.polygonPoints.pointString}
                  fill={group.stroke}
                  fillOpacity={fillopacity}
                  stroke={group.stroke}
                  strokeWidth={1}
                />
                {showpoint &&
                  group.polygonPoints.points.map((point: any, i: number) => (
                    <circle
                      key={`radar-point-${i}-${index}`}
                      cx={point.x}
                      cy={point.y}
                      r={4}
                      fill={pointCol}
                      onMouseOver={() => {
                        handleMouseOver(point, `${categories[i]}: ${group.dataStr![i]}`);
                      }}
                      onMouseOut={hideTooltip}
                    />
                  ))}
              </Group>
            );
          })}
      </svg>
      {tooltipOpen && (
        <Tooltip
          key={Math.floor(Math.random() * 1000)}
          top={tooltipTop! + height / 2 - margin.top}
          left={tooltipLeft! + width / 2}
          style={tooltipStyles}
        >
          <strong>{tooltipData}</strong>
        </Tooltip>
      )}
    </div>
  );
}
