import { VizOrientation, DataFrameFieldIndex } from '@grafana/data';
import { VizLegendOptions, AxisConfig, GraphGradientMode, HideableFieldConfig } from '@grafana/ui';

export enum BarGraphMode {
  Bar = 'bar',
  Timeseries = 'timeseries',
}

/**
 * tooltip options
 * @enum {string} TooltipDisplayMode
 * @property {string} Single tooltip shows single value
 * @property {string} Multi tooltip shows multi values
 * @property {string} None no tooltip
 */

export enum TooltipDisplayMode {
  Single = 'single',
  Multi = 'multi',
  None = 'none',
}

/**
 * VizTooltip Options
 * @typedef {Object} VizTooltipOptions
 * @property {TooltipDisplayMode} mode
 */

export type VizTooltipOptions = {
  mode: TooltipDisplayMode;
};

/**
 * OptionsWithTooltip
 * @typedef {Object} OptionsWithTooltip
 * @property {VizTooltipOptions} tooltip
 */
export interface OptionsWithTooltip {
  tooltip: VizTooltipOptions;
}

export enum GraphNGLegendEventMode {
  ToggleSelection = 'select',
  AppendToSelection = 'append',
}
/**
 * @typedef {Object} GraphNGLegendEvent
 * @property {DataFrameFieldIndex} fieldIndex
 * @property {GraphNGLegendEvent} mode
 */
export interface GraphNGLegendEvent {
  fieldIndex: DataFrameFieldIndex;
  mode: GraphNGLegendEventMode;
}

/**
 * Bar stacking options
 * @enum {string} BarStackingMode
 * @property {string} Normal when stacking is from values of corresponding index
 * @property {string} Percent when stacking is from values converted into percentage
 * @property {string} None no stacking
 */
export enum BarStackingMode {
  None = 'none',
  Normal = 'normal',
  Percent = 'percent',
}

/**
 * Bar Value visibility options
 * @enum {string} BarValueVisibility
 * @property {string} Auto value of visible in auto mode
 * @property {string} Never bar values not visible
 * @property {string} Always bar values always visible
 */
export enum BarValueVisibility {
  Auto = 'auto',
  Never = 'never',
  Always = 'always',
}

export enum labelsMinimumSpacing {
  None = 0,
  Small = 100,
  Medium = 200,
  large = 300,
}

/**
 * Bar Chart Props
 * @typedef {Object} BarChartOptions
 * @property {VizOrientation} toOrientation orientation of bars
 * @property {BarStackingMode} stacking stacking of bar
 * @property {VizLegendOptions} legend legend display properties
 * @property {BarValueVisibility} showValue show or hide bar values
 * @property {number} toBarWidth bar width
 * @property {number} groupWidth group width
 * @property {any} timeZone time zone
 * @property {rawValueCb} rawValue rawValue used by uplot to identify indexes
 * @property {boolean} timeSeries is bar graph required in time series mode
 * @property {Array<any>} colorArray
 * @property {Array<any>} colorArrayStack
 * @property {any} groupData used in custom editor
 * @extends {OptionsWithTooltip}
 */

export interface BarChartOptions extends OptionsWithTooltip {
  toOrientation: VizOrientation;
  legend: VizLegendOptions;
  stacking: BarStackingMode;
  showValue: BarValueVisibility;
  toBarWidth: number;
  groupWidth: number;
  timeZone: any;
  rawValue: (seriesIdx: number, valueIdx: number) => number;
  graphMode: string;
  // timeSeries: boolean;
  colorArray: any[];
  // groupSize: number;
  colorArrayStack: any[];
  groupData: {
    groups: Group[];
  };
  labelSpacingXaxis: number;
  rotationXaxis: number;
  spacingLTR: boolean;
}

/**
 * bar chart field config
 * @typedef {Object} BarChartFieldConfig
 * @property {number} [lineWidth] line width
 * @property {number} [fillOpacity] opacity of bar
 * @property {GraphGradientMode} [gradientMode] gradient mode
 * @extends {AxisConfig}
 * @extends {HideableFieldConfig}
 */
export interface BarChartFieldConfig extends AxisConfig, HideableFieldConfig {
  lineWidth?: number; // 0
  fillOpacity?: number; // 100
  gradientMode?: GraphGradientMode;
}

export const defaultBarChartFieldConfig: BarChartFieldConfig = {
  lineWidth: 1,
  fillOpacity: 80,
  gradientMode: GraphGradientMode.None,
  axisSoftMin: 0,
};

export type TooltipInterface = (
  seriesIndex: (sIdx: number | null) => void,
  datapointIndex: (dIdx: number | null) => void,
  showTooltip: (clear?: boolean) => void,
  u: uPlot
) => void;

/**
 * @typedef {Object} VizTextDisplayOptions
 * @property {number} [titleSize]
 * @property {number} [valueSize]
 */
export interface VizTextDisplayOptions {
  /* Explicit title text size */
  titleSize?: number;
  /* Explicit value text size */
  valueSize?: number;
}
/**
 * @typedef {Object} Group
 */
//  * @property {string} name
//  * @property {Array<any>} query
type Group = {
  name: string;
  query: any[];
};

export default Group;
/**
 * callback for series and value index
 * @callback rawValueCb
 * @param {number} seriesIdx series index
 * @param {number} valueIdx value index
 */
