import {
  ArrayVector,
  FieldDisplay,
  PanelProps,
  VizOrientation,
  getFieldDisplayValues,
  outerJoinDataFramesBox,
} from '@grafana/data';
import { GraphNGLegendEvent, useTheme } from '@grafana/ui';
import { cloneDeep } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { changeSeriesColorConfigFactory } from '../timeseries/overrides/colorSeriesConfigFactory';
import { hideSeriesConfigFactory } from '../timeseries/overrides/hideSeriesConfigFactory';
import { GraphNG } from './BarChart';
import { BarChartOptions, BarGraphMode, BarStackingMode, TooltipDisplayMode } from './types';
import { prepareGraphableFrames } from './utils/utils';

/**
 * @typedef {Object} Props
 * @property {PanelProps<BarChartOptions>} PanelProps panel props extends with bar chart options
 */

interface Props extends PanelProps<BarChartOptions> {}

/**
 * Bar chart Panel
 * @function
 * @param {Props} Props props used by bar chart panel
 * @returns {JSX.Element}
 *
 * @mermaid
 *  graph TD;
 *      A(first run) --> B[Add Query or Queries];
 *      B --> C{Add Required Transform}
 *      C --> D[time series bar graph];
 *      C --> |default| E[non time series bar graph];
 *      D --> |X-axis| H[time];
 *      D --> |Y-axis| I[value];
 *      E --> |X-axis| F[query name];
 *      E --> |Y-axis| G[Reducer value];
 *      F --> J{Stacking};
 *      G --> J{Stacking};
 *      J --> |off| K[No. of queries = No. of bars];
 *      J --> |Normal| L[Add Group];
 *      J --> |Percent| L[Add Group];
 *      L --> |Value Wise| M[No of group = No. of Bars];
 *      L --> |Percent Wise| M[No of group = No. of Bars];
 *      H --> N{Stacking};
 *      I --> N{Stacking};
 *      N --> |off| O[No. of queries = No. of Bars in A Group];
 *      N --> |Normal| P[Single Bar According to time with Stacked];
 *      N --> |Percent| P[Single Bar According to time with Stacked];
 */

export const BarChartPanel: React.FunctionComponent<Props> = ({
  data,
  options,
  width,
  height,
  fieldConfig,
  onFieldConfigChange,
  replaceVariables,
  timeZone,
  timeRange,
}) => {
  const theme = useTheme();

  const aligned = outerJoinDataFramesBox({ frames: data?.series });

  const orientation = useMemo(() => {
    if (!options.toOrientation || options.toOrientation === VizOrientation.Auto) {
      return width < height ? VizOrientation.Horizontal : VizOrientation.Vertical;
    }
    return options.toOrientation;
  }, [width, height, options.toOrientation]);

  const onLegendClick = useCallback(
    (event: GraphNGLegendEvent) => {
      onFieldConfigChange(hideSeriesConfigFactory(event, fieldConfig, data.series));
    },
    [fieldConfig, onFieldConfigChange, data.series]
  );

  const onSeriesColorChange = useCallback(
    (label: string, color: string) => {
      onFieldConfigChange(changeSeriesColorConfigFactory(label, color, fieldConfig));
    },
    [fieldConfig, onFieldConfigChange]
  );
  // forced multi tooltip when stacking
  const tooltip = useMemo(() => {
    if (
      (options.tooltip.mode !== TooltipDisplayMode.None && options.stacking === BarStackingMode.Normal) ||
      options.stacking === BarStackingMode.Percent
    ) {
      return { ...options.tooltip, mode: TooltipDisplayMode.Multi };
    } else if (
      options.graphMode !== BarGraphMode.Timeseries &&
      options.stacking === BarStackingMode.None &&
      options.tooltip.mode === TooltipDisplayMode.Multi
    ) {
      return { ...options.tooltip, mode: TooltipDisplayMode.Single };
    }
    return options.tooltip;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.tooltip.mode, options.stacking]);

  if (!aligned?.length) {
    return (
      <div className="panel-empty">
        <p>{'No data found in response'}</p>
      </div>
    );
  }

  const { frames, warn } = prepareGraphableFrames([aligned], theme, options.stacking, options.graphMode);

  if (!frames || warn) {
    return (
      <div className="panel-empty">
        <p>{warn ?? 'No data found in response'}</p>
      </div>
    );
  }

  //* Forced stacking off when group length is zero or when someone remove all group
  if (options.graphMode !== BarGraphMode.Timeseries && options.groupData.groups.length === 0) {
    options.stacking = BarStackingMode.None;
  }

  const getValues = (): FieldDisplay[] => {
    return getFieldDisplayValues({
      fieldConfig,
      // @ts-ignore
      reduceOptions: options.reduceOptions,
      replaceVariables,
      theme: theme,
      data: [aligned],
      timeZone,
    });
  };

  let clonedArray = cloneDeep(frames);
  let colorArray = [];
  const reducerValue = getValues();

  //* Forming new data frame for no time series bar graph
  if (options.graphMode !== BarGraphMode.Timeseries) {
    // time array, color array and value array making for no time series bar graph
    let timeArray: Array<string | undefined>;
    let nextData = clonedArray[0].fields;
    const newTimeObj: any = nextData[0].values;

    // Removing all element from time buffer so that we can push name of the query
    timeArray = newTimeObj?.buffer;
    timeArray.length = 0;
    for (let i = 1; i < nextData.length; i++) {
      nextData[i].values = new ArrayVector([]);
    }

    for (let i = 1; i < nextData.length; i++) {
      // Updated Series value According to (reducer function) of the query.
      const newValObj: any = nextData[1].values;
      newValObj.buffer.push(reducerValue[i - 1].display.numeric);

      // Update time with name of the query
      timeArray.push(reducerValue[i - 1].display.title);

      // Push Color value from (reducer function) of the query
      colorArray.push(reducerValue[i - 1].display.color);
    }
    nextData.length = 2;
  }

  //* Passing color array2 for stacking bar/legend/tooltip color
  let colorArray2 = [];
  for (let i = 0; i < reducerValue.length; i++) {
    colorArray2.push(reducerValue[i].display.color);
  }

  // array for stacking bar graph(no time)
  // when group is present
  // for eg.
  // user formed 2 group with name G1 and G2
  // Total queries -> 8 -> Q1, Q2, Q3, Q4......Q8
  // G1 - Queries -> Q1, Q2, Q8
  // G2 - Queries -> Q3, Q4, Q2
  // Then Final Data array which we need to send is
  // field1 -> time [G1, G2]
  // field2 -> Q1[value, null]
  // field3 -> Q2 [value, value]
  // field4 -> Q4 [null, value]
  // and so on
  let clonedArray2 = cloneDeep(frames);

  if (options.graphMode !== BarGraphMode.Timeseries && options.stacking !== BarStackingMode.None) {
    let timeArray2: Array<string | undefined>;
    let nextData2 = clonedArray2[0].fields;

    // empty the time array and all other numeric fields
    const newTimeObj: any = nextData2[0].values;
    timeArray2 = newTimeObj.buffer;
    timeArray2.length = 0;
    for (let i = 1; i < nextData2.length; i++) {
      nextData2[i].values = new ArrayVector([]);
    }

    // prepare a array for bar graph in group mode
    //
    for (let i = 0; i < options.groupData.groups.length; i++) {
      timeArray2.push(options.groupData.groups[i].name);
      let queryArr = options.groupData.groups[i].query.slice(0);

      for (let j = 0; j < reducerValue.length; j++) {
        if (queryArr[0] !== undefined) {
          for (let k = 0; k < queryArr.length; k++) {
            if (queryArr[k].label === reducerValue[j].display.title) {
              const num = reducerValue[j].display.numeric;
              const valObj: any = nextData2[j + 1].values;
              valObj.buffer[i] = num;
              break;
            } else {
              const valObj: any = nextData2[j + 1].values;
              valObj.buffer[i] = null;
            }
          }
        } else {
          const valObj: any = nextData2[j + 1].values;
          valObj.buffer[i] = null;
        }
      }
    }
  }

  return (
    <GraphNG
      data={
        options.graphMode === BarGraphMode.Timeseries
          ? frames
          : options.stacking !== BarStackingMode.None
          ? clonedArray2
          : clonedArray
      }
      width={width}
      height={height}
      onLegendClick={onLegendClick}
      onSeriesColorChange={onSeriesColorChange}
      {...options}
      timeRange={timeRange}
      toOrientation={orientation}
      timeZone={timeZone}
      tooltipMode={tooltip.mode}
      colorArray={colorArray}
      colorArrayStack={colorArray2}
      fieldConfig={fieldConfig}
    />
  );
};
