import { DataFrame, FieldColorModeId, LoadingState, dateTime } from '@grafana/data';
import { calculateTimeDifference } from 'app/features/forecasting/utils/convertToPanelData';
import { getType } from 'app/features/forecasting/utils/csvStringToPanelData';

export const convertToPanelData = (data: any, onUpdateTimeRange?: (e: any) => void) => {
  const dataframes: DataFrame[] = data.map((ele: any) => {
    const { x, y } = ele;
    const objectData: any = { X: x, Y: y };
    return {
      length: ele.x.length,
      fields: Object.keys(objectData).map((key: string) => ({
        name: getType(objectData[key][0]) === 'time' ? 'Time' : ele.name,
        type: getType(objectData[key][0]),
        config: {
          color: {
            mode: FieldColorModeId.PaletteClassic,
          },
        },
        values: {
          length: objectData[key].length,
          get: (index: number) => objectData[key][index],
          toArray: () => objectData[key],
        },
      })),
    };
  });
  const startTime = data[0].x[0];
  const endTime = data[0].x[data[0].x.length - 1];
  const timeRange = {
    from: dateTime(startTime),
    to: dateTime(endTime),
    raw: {
      from: `now${calculateTimeDifference(startTime)}`,
      to: `now${calculateTimeDifference(endTime)}`,
    },
  };
  if (onUpdateTimeRange) {
    onUpdateTimeRange(timeRange);
  }
  const state = LoadingState.Done;
  return { state, series: dataframes, timeRange };
};
