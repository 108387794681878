import React, { ComponentType } from 'react';
import { css, cx } from 'emotion';
import { GrafanaTheme, toPascalCase } from '@grafana/data';
import { stylesFactory } from '../../themes/stylesFactory';
import { useTheme } from '../../themes/ThemeContext';
import { IconName, IconType, IconSize, AlwaysMonoIcons } from '../../types/icon';
import * as MonoIcon from './assets';
import { customIcons } from './custom';
import { SvgProps } from './assets/types';
import SVG from 'react-inlinesvg';
import { cacheInitialized, initIconCache, iconRoot } from './iconBundle';
import { toLower } from 'lodash';

const alwaysMonoIcons: AlwaysMonoIcons[] = [
  'Dashboard',
  'grafana',
  'favorite',
  'heart-break',
  'heart',
  'panel-add',
  'reusable-panel',
  'wrangler',
];

export interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
  name: IconName;
  size?: IconSize;
  type?: IconType;
}

const getIconStyles = stylesFactory((theme: GrafanaTheme) => {
  return {
    container: css`
      label: Icon;
      display: inline-block;
    `,
    icon: css`
      vertical-align: middle;
      display: inline-block;
      margin-bottom: ${theme.spacing.xxs};
      // fill: currentColor;
      // stroke: red;
    `,
    orange: css`
      fill: ${theme.palette.orange};
    `,
  };
});

// default is for unicons
function getIconComponent(name: IconName, type: string): ComponentType<SvgProps> | undefined {
  if (alwaysMonoIcons.includes(name as AlwaysMonoIcons)) {
    type = 'mono';
  }

  if (name?.startsWith('gf-')) {
    return customIcons[name];
  }

  if (type !== 'mono') {
    return undefined;
  }

  const iconName = toPascalCase(name);
  // @ts-ignore
  let Component = MonoIcon[iconName];

  return Component ?? undefined;
}

export const Icon = React.forwardRef<HTMLDivElement, IconProps>(
  ({ size = 'md', type = 'default', name, className, style, ...divElementProps }, ref) => {
    const theme = useTheme();
    const styles = getIconStyles(theme);
    const svgSize = getSvgSize(size);

    /* Temporary solution to display also font awesome icons */
    if (name?.startsWith('fa fa-')) {
      return <i className={getFontAwesomeIconStyles(name, className)} {...divElementProps} style={style} />;
    }

    const Component = getIconComponent(name, type);
    if (Component) {
      return (
        <div className={styles.container} {...divElementProps} ref={ref}>
          <Component
            size={svgSize}
            className={cx(styles.icon, { [styles.orange]: name === 'favorite' }, className)}
            style={style}
            fill={style?.stroke && style.stroke !== '' ? style.stroke : 'currentColor'}
          />
        </div>
      );
    }

    if (!cacheInitialized) {
      initIconCache();
    }

    const svgWid = name?.startsWith('gf-bar-align') ? 16 : name?.startsWith('gf-interp') ? 30 : svgSize;
    const svgPath = `${iconRoot}default/${toLower(name)}.svg`;

    return (
      <div className={styles.container} {...divElementProps} ref={ref}>
        <SVG src={svgPath} width={svgWid} height={svgSize} className={cx(styles.icon, className)} style={style} />
      </div>
    );
  }
);

Icon.displayName = 'Icon';

function getFontAwesomeIconStyles(iconName: string, className?: string): string {
  return cx(
    iconName,
    {
      'fa-spin': iconName === 'fa fa-spinner',
    },
    className
  );
}

/* Transform string with px to number and add 2 pxs as path in svg is 2px smaller */
export const getSvgSize = (size: IconSize) => {
  switch (size) {
    case 'xs':
      return 12;
    case 'sm':
      return 14;
    case 'md':
      return 16;
    case 'lg':
      return 18;
    case 'xl':
      return 24;
    case 'mxl':
      return 30;
    case 'xxl':
      return 36;
    case 'mxxl':
      return 44;
    case 'xxxl':
      return 48;
  }
};
