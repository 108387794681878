import React from 'react';
import * as d3 from 'd3';
import { useTheme } from '@grafana/ui';
import { ChartPropTypes, ChartDefaultProps, DataType } from './types';
// import { useResponsive } from './useResponsive';

export function Chart(props: ChartPropTypes) {
  const theme = useTheme();
  const { width: propWidth, height: propHeight, chartData: data, columns, legendGap, placement, maxSpeed } = props;
  const containerRef = React.useRef<SVGSVGElement>(null);
  const axisContainerRef = React.useRef<HTMLDivElement>(null);

  // const containerSize = useResponsive(axisContainerRef, {
  //   width: propWidth,
  //   height: propHeight,
  // });
  const [size, setSize] = React.useState({
    width: propWidth,
    height: propHeight,
  });

  React.useEffect(() => {
    // const { width, height } = containerSize;
    // if (responsive) {
    //   const rect = Math.min(width, height);
    //   setSize({ width: rect, height: rect });
    // } else {
    // }
    setSize({ width: propWidth, height: propHeight });
  }, [props]);

  React.useEffect(() => {
    const { current } = containerRef;
    if (current === null) {
      return;
    }

    const { width, height } = size;
    const svg = d3.select(containerRef.current);
    svg.selectAll('*').remove();
    const margin = { top: 40, right: 80, bottom: 40, left: 40 };
    const innerRadius = 20;
    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;
    const outerRadius = Math.min(chartWidth, chartHeight - 15) / 2.4 - legendGap / 2;
    const g = svg.append('g').attr('transform', `translate(${width / 2},${height / 2})`);

    const angle = d3.scaleLinear().range([0, 2 * Math.PI]);
    const radius = d3.scaleLinear().range([innerRadius, outerRadius]);
    const x = d3
      .scaleBand()
      .range([0, 2 * Math.PI])
      .align(0);
    // const xGroup = d3
    //   .scaleBand()
    //   .range([0, 2 * Math.PI])
    //   .align(0);
    const y = d3
      .scaleLinear() // you can try scaleRadial but it scales differently
      .range([innerRadius, outerRadius]);
    //to add the color
    const z = d3.scaleOrdinal().range(props.colors);
    x.domain(data.map((d) => String(d.angle)));
    // xGroup.domain(columns.map((d) => d));
    // y.domain([0, (d3.max(data, (d) => d.total) || 0) > 1 ? d3.max(data, (d) => d.total) || 0 : 1]);
    y.domain([0, d3.max(data, (d) => d.total) || 0]);

    z.domain(columns.slice(1));

    // Extend the domain slightly to match the range of [0, 2π].
    angle.domain([0, d3.max(data, (_, i): number => i + 1) || 0]);
    radius.domain([0, d3.max(data, (): number => 0) || 0]);
    // radius.domain([innerRadius, outerRadius]);
    const angleOffset = -360.0 / data.length / 2.0;
    const stackGen: any = d3.stack().keys(columns.slice(1));
    const arcVal: d3.Arc<SVGPathElement, d3.DefaultArcObject> = d3 // d3.DefaultArcObject
      .arc()
      .innerRadius((d: any) => Number(y(d[0])))
      .outerRadius((d: any) => Number(y(d[1])))
      // @ts-ignore
      .startAngle((d) => Number(x(d.data.angle)))
      // .startAngle((d) => x(String(d.startAngle)) || 0)
      // @ts-ignore
      .endAngle((d) => Number(x(d.data.angle)) + x.bandwidth())
      // .endAngle((d) => x(String(d.endAngle)) || 0 + x.bandwidth())
      .padAngle(0.0)
      .padRadius(innerRadius);
    const arcParent = g
      .append('g')
      .selectAll('g')
      // @ts-ignore
      .data(stackGen(data))
      .enter()
      .append('g')
      .attr('fill', (d: any) => {
        return z(d.key) as string;
      });
    // @ts-ignore
    const arc: d3.Selection<
      SVGPathElement,
      d3.SeriesPoint<DataType>,
      d3.EnterElement,
      d3.Series<DataType, string>
    > = arcParent
      .selectAll<SVGPathElement, d3.SeriesPoint<DataType>>('path')
      .data((d: any) => d)
      .enter()
      .append('path');
    arc
      // @ts-ignore
      .attr('d', arcVal)
      .attr('transform', `rotate(${angleOffset})`);
    const label: any = g
      .append('g')
      .selectAll('g')
      .data(data)
      .enter()
      .append('g')
      .attr('text-anchor', 'middle')
      .attr('transform', (d) => {
        return `rotate(${
          // @ts-ignore
          ((Number(x(d.angle)) + x.bandwidth() / 2) * 180) / Math.PI - (90 - angleOffset)
        })translate(${outerRadius + 30},0)`;
      });
    label
      .append('text')
      // eslint-disable-next-line no-confusing-arrow
      .attr('transform', (d: any, _i: any) =>
        // @ts-ignore
        (x(d.angle) + x.bandwidth() / 2 + Math.PI / 2) % (2 * Math.PI < Math.PI)
          ? 'rotate(90) translate(0,16)'
          : 'rotate(-90) translate(0,-9)'
      )
      .attr('transform', 'rotate(90) translate(0,-9)')
      .text((d: any) => d.angle)
      .attr('style', `font-size: 14; fill: ${theme.colors.text}`);
    g.selectAll('.axis')
      .data(d3.range(angle.domain()[1]))
      .enter()
      .append('g')
      .attr('class', 'axis')
      .attr('transform', (d) => `rotate(${(angle(d) * 180) / Math.PI})`)
      .call(
        d3
          // @ts-ignore
          .axisLeft()
          // @ts-ignore
          .scale(radius.copy().range([-innerRadius, -(outerRadius + 10)]))
      );
    const yAxis = g.append('g').attr('text-anchor', 'middle');
    const yTick = yAxis.selectAll('g').data(y.ticks(5).slice(1)).enter().append('g');
    yTick
      .append('circle')
      .attr('fill', 'none')
      .attr('stroke', theme.colors.text)
      // .attr('stroke-dasharray', '4,4')
      .attr('r', y);
    yTick
      .append('text')
      .attr('y', (d) => -y(d))
      .attr('dy', '-0.35em')
      .attr('x', () => -10)
      .text(y.tickFormat(5, '%'))
      .style('font-size', 10);

    const legend = g
      .append('g')
      .attr(
        'transform',
        placement === 'right'
          ? `translate(${width / 2 - 115}, ${maxSpeed > 20 ? -(height / 2 - 70) : -(height / 2 - 50)})`
          : `translate(${-(width / 2 - (maxSpeed <= 10 ? 170 : maxSpeed <= 20 ? 195 : 260))}, ${height / 2 - 15})`
      )

      .selectAll('g')
      .data(placement === 'right' ? columns.slice(1).reverse() : columns.slice(1))
      .enter()
      .append('g')
      .attr('transform', (d, i) => {
        return placement === 'right'
          ? `translate(${45 + legendGap / 2},${40 + (i - (columns.length - 1) / 2) * 20})`
          : `translate(${40 + (i - (columns.length - 1) / 2) * (maxSpeed <= 10 ? 50 : maxSpeed <= 20 ? 57 : 57)}, 0)`;
      });
    legend
      .append('rect')
      .attr('width', 18)
      .attr('height', 5)
      // @ts-ignore
      .attr('fill', z);
    legend
      .append('text')
      .attr('x', (d, i) => {
        if (placement === 'right') {
          return 25;
        } else {
          return 22;
        }
      })
      .attr('y', 17)

      .attr('dy', '-0.9em')
      .text((d) => d)
      .style('font-size', 12);
    g.exit().remove();
  }, [size]);

  return (
    <div style={{ width: '100%', height: 'auto' }} ref={axisContainerRef}>
      <svg
        style={{ stroke: theme.colors.text }}
        className="axis"
        width={size.width}
        height={size.height}
        ref={containerRef}
      />
    </div>
  );
}

Chart.defaultProps = ChartDefaultProps;
