import React from 'react';
import isNumber from 'lodash/isNumber';
import { BoxPlotData, GraphNGLegendEventMode, defaultGraphConfig } from '../types';

import {
  DataFrame,
  FieldConfig,
  FieldType,
  formattedValueToString,
  getFieldColorModeForField,
  getFieldDisplayName,
  getFieldSeriesColor,
  GrafanaTheme,
  TimeRange,
  TimeZone,
} from '@grafana/data';
import {
  UPlotConfigBuilder,
  AxisPlacement,
  DrawStyle,
  GraphFieldConfig,
  PointVisibility,
  ScaleDirection,
  ScaleOrientation,
  ScaleDistribution,
} from '@grafana/ui';

import { CandlesOptions, getConfig } from './candle';
import { GraphNGProps } from './GraphNG';

const defaultFormatter = (v: any) => (v == null ? '-' : v.toFixed(1));

/**
 * @constant
 * @default
 */
export const UPlot_UNIT = '__fixed';

/**
 * @constant
 * @default
 */
const defaultConfig: GraphFieldConfig = {
  drawStyle: DrawStyle.Line,
  showPoints: PointVisibility.Auto,
  axisPlacement: AxisPlacement.Auto,
};

export function mapMouseEventToMode(event: React.MouseEvent): GraphNGLegendEventMode {
  if (event.ctrlKey || event.metaKey || event.shiftKey) {
    return GraphNGLegendEventMode.AppendToSelection;
  }
  return GraphNGLegendEventMode.ToggleSelection;
}

/**
 * uplot config builder for waterfall chart
 * @param {Dataframe} frame
 * @param {GrafanaTheme} theme
 * @param {any} getTimeRange
 * @param {any} getTimeZone
 * @param {BoxPlotData} dataAll
 * @param {GraphNGProps} otherProps
 * @returns {UPlotConfigBuilder} builder
 */
export function preparePlotConfigBuilder(
  frame: DataFrame,
  theme: GrafanaTheme,
  getTimeRange: () => TimeRange,
  getTimeZone: () => TimeZone,
  dataAll: BoxPlotData,

  {
    // toShowPlot,
    typeWaterfallPlot,
    timeZone,
    toCluster,
    clusterSwitch,
    customClusterSize,
    typeBoxPlot,
    ohlcData,
    queryIndex,
    upcolor,
    downcolor,
    risecolor,
    fallcolor,
    barlineSwitch,
    labelSwitch,
    total,
    includeTimeField,
  }: GraphNGProps
): UPlotConfigBuilder {
  const builder = new UPlotConfigBuilder(getTimeZone);
  const opts: CandlesOptions = {
    data: frame,
    dataAll: dataAll,
    ohlcData: ohlcData,
    queryIndex: queryIndex,
    toCluster: toCluster,
    clusterSwitch: clusterSwitch,
    customClusterSize: customClusterSize,
    upcolor: upcolor,
    risecolor: risecolor,
    fallcolor: fallcolor,
    downcolor: downcolor,
    barlineSwitch: barlineSwitch,
    labelSwitch: labelSwitch,
    total: total,
    onHover: (seriesIdx: number, valueIdx: number) => {
      console.log('hover', { seriesIdx, valueIdx });
    },
    onLeave: (seriesIdx: number, valueIdx: number) => {
      console.log('leave', { seriesIdx, valueIdx });
    },
    timeZone: timeZone,
  };
  const config = getConfig(opts, theme);
  builder.setCursor(config.cursor);
  builder.setSelect(config.select);
  // builder.addHook('init', config.init);
  /**
   * clear the canvas
   */
  builder.addHook('drawClear', config.drawClear);
  /**
   * adding tooltip hook
   */
  builder.setShowTooltip(config.tooltipMaker);

  /**
   * If waterfall chart is selected with type query, then only this 'if' condition will run
   * which is selected as by default.
   */
  if (typeWaterfallPlot === 'query') {
    /**
     * uplot hook set to draw waterfall from candle.ts file
     */
    builder.addHook('draw', config.drawWaterfall);
  } else {
    /**
     * uplot hook set to draw waterfall with time from candle.ts file
     */
    builder.addHook('draw', config.drawWaterfallWithTime);
  }
  // builder.addHook('drawLabelsOnBar', config.drawLabelsOnBar);

  // X is the first field in the aligned frame
  const xField = frame.fields[0];
  let seriesIndex = 0;

  const minRange: any = dataAll.waterfall?.indexArray[0] - 1;
  const maxRange: any = !total
    ? dataAll.waterfall?.indexArray[dataAll.waterfall?.indexArray.length - 1] + 1
    : dataAll.waterfall?.indexArray[dataAll.waterfall?.indexArray.length - 1] + 2;
  /**
   * builder.addScale and builder.addAxis.
   * below two are for x axis.
   */
  builder.addScale({
    scaleKey: 'x',
    orientation: ScaleOrientation.Horizontal,
    direction: ScaleDirection.Right,
    distribution: ScaleDistribution.Ordinal,
    isTime: typeWaterfallPlot === 'query' ? false : true,
    /**
     * defining custom x axis range
     */
    range: typeWaterfallPlot !== 'query' ? undefined : [minRange, maxRange],
  });
  /**
   * splits and values only required when query switch in off
   */
  /**
   * splits and values accepts array of same length
   * splits - divided axis into no. parts as the length of array
   * values - values to show on axis
   */
  builder.addAxis({
    scaleKey: 'x',
    isTime: includeTimeField ? true : typeWaterfallPlot === 'query' ? false : true,
    splits: typeWaterfallPlot === 'query' ? undefined : dataAll.waterfall?.time.values,
    values: includeTimeField ? config.xTimeCandle : typeWaterfallPlot === 'query' ? config.xValuesCandle : undefined,
    placement: AxisPlacement.Bottom,
    timeZone: getTimeZone(),
    theme,
    grid: false,
  });

  let indexByName: Map<string, number> | undefined = undefined;

  if (includeTimeField || typeWaterfallPlot === 'timeseries') {
    for (let i = 0; i < frame.fields.length; i++) {
      const field = frame.fields[i];

      const config = field.config as FieldConfig<GraphFieldConfig>;

      const customConfig: GraphFieldConfig = {
        ...defaultConfig,
        ...config.custom,
      };

      if (field === xField || field.type !== FieldType.number) {
        continue;
      }
      field.state!.seriesIndex = seriesIndex++;

      const fmt = field.display ?? defaultFormatter;
      const scaleKey = config.unit || UPlot_UNIT;
      const colorMode = getFieldColorModeForField(field);
      // const scaleColor = getFieldSeriesColor(field, theme);
      // const seriesColor = scaleColor.color;

      // The builder will manage unique scaleKeys and combine where appropriate
      builder.addScale({
        scaleKey,
        orientation: ScaleOrientation.Vertical,
        direction: ScaleDirection.Up,
        distribution: customConfig.scaleDistribution?.type,
        log: customConfig.scaleDistribution?.log,
        min: field.config.min,
        max: field.config.max,
        softMin: customConfig.axisSoftMin,
        softMax: customConfig.axisSoftMax,
        range: undefined,
      });

      if (customConfig.axisPlacement !== AxisPlacement.Hidden) {
        builder.addAxis({
          scaleKey,
          label: customConfig.axisLabel,
          size: customConfig.axisWidth,
          placement: customConfig.axisPlacement ?? AxisPlacement.Auto,
          formatValue: (v) => formattedValueToString(fmt(v)),
          theme,
        });
      }

      const showPoints = customConfig.drawStyle === DrawStyle.Points ? PointVisibility.Always : customConfig.showPoints;

      let { fillOpacity } = customConfig;
      let pathBuilder: uPlot.Series.PathBuilder | undefined = undefined;
      let pointsBuilder: uPlot.Series.Points.Show | undefined = undefined;

      // disable default renderers
      /**
       * disable default renderers. Most important step is played here.
       * restricting uplot canvas to plot line chart when index is less and equal to 4.
       */
      if (i <= 4) {
        pathBuilder = () => null;
        pointsBuilder = () => undefined;
      }
      if (customConfig.fillBelowTo) {
        if (!indexByName) {
          indexByName = getNamesToFieldIndex(frame);
        }
        const t = indexByName.get(getFieldDisplayName(field, frame));
        const b = indexByName.get(customConfig.fillBelowTo);
        if (isNumber(b) && isNumber(t)) {
          builder.addBand({
            series: [t, b],
            fill: null as any, // using null will have the band use fill options from `t`
          });
        }
        if (!fillOpacity) {
          fillOpacity = 35; // default from flot
        }
      }

      builder.addSeries({
        scaleKey,
        pathBuilder,
        pointsBuilder,
        showPoints,
        colorMode,
        fillOpacity,
        theme,
        drawStyle: customConfig.drawStyle!,
        // lineColor: true ? dataAll.waterfall?.color![i - 1] : dataAll.waterfall?.color![i - 4],
        lineWidth: customConfig.lineWidth,
        lineInterpolation: customConfig.lineInterpolation,
        lineStyle: customConfig.lineStyle,
        barAlignment: customConfig.barAlignment,
        pointSize: customConfig.pointSize,
        // pointColor: querySwitch ? dataAll.waterfall?.color![i - 1] : dataAll.waterfall?.color![i - 4],
        spanNulls: customConfig.spanNulls || false,
        show: !customConfig.hideFrom?.graph,
        gradientMode: customConfig.gradientMode,
        thresholds: config.thresholds,

        // The following properties are not used in the uPlot config, but are utilized as transport for legend config
        dataFrameFieldIndex: field.state?.origin,
        fieldName: getFieldDisplayName(field, frame),
        hideInLegend: customConfig.hideFrom?.legend,
      });
    }
  } else {
    for (let i = 1; i < frame.fields.length; i++) {
      const field = frame.fields[i];
      field.state!.seriesIndex = seriesIndex++;
      const customConfig: GraphFieldConfig = { ...defaultGraphConfig, ...field.config.custom };

      const scaleKey = field.config.unit || UPlot_UNIT;
      const colorMode = getFieldColorModeForField(field);
      const scaleColor = getFieldSeriesColor(field, theme);
      const seriesColor = scaleColor.color;
      builder.addSeries({
        scaleKey,
        pxAlign: false,
        lineWidth: customConfig.lineWidth,
        lineColor: seriesColor,
        // lineStyle: customConfig.lineStyle,
        fillOpacity: customConfig.fillOpacity,
        theme,
        colorMode,
        // pathBuilder,
        // pointsBuilder,
        show: !customConfig.hideFrom?.graph,
        gradientMode: customConfig.gradientMode,
        thresholds: field.config.thresholds,

        // The following properties are not used in the uPlot config, but are utilized as transport for legend config
        dataFrameFieldIndex: {
          fieldIndex: i,
          frameIndex: 0,
        },
        fieldName: getFieldDisplayName(field, frame),
        hideInLegend: customConfig.hideFrom?.legend,
      });
      // The builder will manage unique scaleKeys and combine where appropriate
      builder.addScale({
        scaleKey,
        min: field.config.min,
        max: field.config.max,
        softMin: customConfig.axisSoftMin,
        softMax: customConfig.axisSoftMax,
        orientation: ScaleOrientation.Vertical,
        direction: ScaleDirection.Up,
        range: undefined,
      });

      builder.addAxis({
        scaleKey,
        label: customConfig.axisLabel,
        size: customConfig.axisWidth,
        placement: customConfig.axisPlacement ?? AxisPlacement.Left,
        formatValue: (v) => formattedValueToString(field.display!(v)),
        theme,
      });
    }
  }

  return builder;
}

/**
 * returns the names array
 * @param {DataFrame } frame
 * @returns {Map<string, number>} names
 */
export function getNamesToFieldIndex(frame: DataFrame): Map<string, number> {
  const names = new Map<string, number>();
  for (let i = 0; i < frame.fields.length; i++) {
    names.set(getFieldDisplayName(frame.fields[i], frame), i);
  }
  return names;
}
