import React, { PureComponent } from 'react';
import { PanelProps, getColorForTheme } from '@grafana/data';
import { withTheme, Themeable } from '@grafana/ui';
//Import all needed types in the Clockpannel.ts.
import { ClockOptions, ClockType, ZoneFormat, ClockMode, ClockRefresh } from './types';
import { css } from 'emotion';
import { cloneDeep } from 'lodash';

// eslint-disable-next-line
import moment, { Moment } from 'moment-timezone';
import './external/moment-duration-format';
import { getTemplateSrv } from '@grafana/runtime';

interface Props extends Themeable, PanelProps<ClockOptions> {}
interface State {
  // eslint-disable-next-line
  now: Moment;
}

export function getTimeZoneNames(): string[] {
  return (moment as any).tz.names();
}

/**
 * Class representing a UnthemedClockPanel.
 * @extends PureComponent
 */
class UnthemedClockPanel extends PureComponent<Props, State> {
  timerID?: any = 0;
  state = { now: this.getTZ(), timezone: '' };

  componentDidMount() {
    this.initTimers();
  }

  /**
   *Representing a componentDidUpdate class function.
   *@function
   * @param {Props} prevProps -For updating data.
   */
  componentDidUpdate(prevProps: Props) {
    const { options, data } = this.props;
    const { options: prevOptions, data: prevData } = prevProps;
    if (options.refresh !== prevOptions.refresh) {
      this.initTimers();
    }

    if (prevData !== data) {
      this.onPanelRefresh();
    }
  }

  /**
   * Represents a initTimers.
   * @function
   */
  initTimers = () => {
    const { refresh } = this.props.options;

    if (this.timerID) {
      clearInterval(this.timerID);
      this.timerID = 0;
    }

    if (refresh === ClockRefresh.dashboard) {
      return this.tick();
    }

    const delay = 1000; // 1sec
    this.timerID = setInterval(
      () => this.tick(),
      delay // 1 second or 1 min
    );
  };

  /**
   * Represents a onPanelRefresh.Check clock refresh option and refresh the data.
   * @function
   */
  onPanelRefresh = () => {
    const { refresh } = this.props.options;
    if (refresh === ClockRefresh.dashboard) {
      this.tick();
    }
  };

  /**
   *Representing a componentWillUnmount class function.
   *@function
   */
  componentWillUnmount() {
    if (this.timerID) {
      clearInterval(this.timerID);
      this.timerID = 0;
    }
  }

  /**
   *Representing a tick. Updating clock every 1 second (current Time ,end Time ,Start Time).
   *@function
   */
  tick() {
    let { timezone } = this.props.options;
    // if (this.props.timeZone !== '' && this.props.timeZone !== 'browser') {
    //   timezone = this.props.timeZone

    // }

    this.setState({ now: this.getTZ(timezone) });

    const { timeRange } = this.props;
    timeRange.from.add(1, 'second');
    timeRange.to.add(1, 'second');
  }

  /**
   *Representing a getTimeFormat.Getting the timeformat value.
   *@function
   *@returns timeformat
   */
  getTimeFormat() {
    const { clockType, timeSettings } = this.props.options;

    if (clockType === ClockType.Custom && timeSettings.customFormat) {
      return timeSettings.customFormat;
    }

    if (clockType === ClockType.H12) {
      return 'h:mm:ss A';
    }

    return 'HH:mm:ss';
  }

  // Return a new moment instnce in the selected timezone
  // eslint-disable-next-line
  /**
   *Representing a getTZ.Get the Timezone value.
   *@function
   *@returns Timezone
   */
  getTZ(tz?: string): Moment {
    if (!tz) {
      tz = (moment as any).tz.guess();
    } else {
      tz = getTemplateSrv().replace(tz);
    }
    // console.log((moment() as any).tz(tz))
    return (moment() as any).tz(tz);
  }

  getCountdownText(): string {
    const { now } = this.state;
    const { countdownSettings, timezone } = this.props.options;

    if (!countdownSettings.endCountdownTime) {
      return countdownSettings.endText;
    }

    const timeLeft = moment.duration(
      moment(this.props.replaceVariables(countdownSettings.endCountdownTime))
        .utcOffset(this.getTZ(timezone).format('Z'), true)
        .diff(now)
    );
    let formattedTimeLeft = '';

    if (timeLeft.asSeconds() <= 0) {
      return countdownSettings.endText;
    }

    if (countdownSettings.customFormat === 'auto') {
      return (timeLeft as any).format();
    }

    if (countdownSettings.customFormat) {
      return (timeLeft as any).format(countdownSettings.customFormat);
    }

    let previous = '';

    if (timeLeft.years() > 0) {
      formattedTimeLeft = timeLeft.years() === 1 ? '1 year, ' : timeLeft.years() + ' years, ';
      previous = 'years';
    }
    if (timeLeft.months() > 0 || previous === 'years') {
      formattedTimeLeft += timeLeft.months() === 1 ? '1 month, ' : timeLeft.months() + ' months, ';
      previous = 'months';
    }
    if (timeLeft.days() > 0 || previous === 'months') {
      formattedTimeLeft += timeLeft.days() === 1 ? '1 day, ' : timeLeft.days() + ' days, ';
      previous = 'days';
    }
    if (timeLeft.hours() > 0 || previous === 'days') {
      formattedTimeLeft += timeLeft.hours() === 1 ? '1 hour, ' : timeLeft.hours() + ' hours, ';
      previous = 'hours';
    }

    if (timeLeft.minutes() > 0 || previous === 'hours') {
      formattedTimeLeft += timeLeft.minutes() === 1 ? '1 minute, ' : timeLeft.minutes() + ' minutes, ';
    }

    formattedTimeLeft += timeLeft.seconds() === 1 ? '1 second ' : timeLeft.seconds() + ' seconds';
    return formattedTimeLeft;
  }

  getCountupText(): string {
    const { now } = this.state;
    const { countupSettings, timezone } = this.props.options;

    if (!countupSettings.beginCountupTime) {
      return countupSettings.beginText;
    }

    const timePassed = moment.duration(
      moment(now).diff(
        moment(this.props.replaceVariables(countupSettings.beginCountupTime)).utcOffset(
          this.getTZ(timezone).format('Z'),
          true
        )
      )
    );

    let formattedTimePassed = '';

    if (timePassed.asSeconds() <= 0) {
      return countupSettings.beginText;
    }

    if (countupSettings.customFormat === 'auto') {
      return (timePassed as any).format();
    }

    if (countupSettings.customFormat) {
      return (timePassed as any).format(countupSettings.customFormat);
    }

    let previous = '';

    if (timePassed.years() > 0) {
      formattedTimePassed = timePassed.years() === 1 ? '1 year, ' : timePassed.years() + ' years, ';
      previous = 'years';
    }
    if (timePassed.months() > 0 || previous === 'years') {
      formattedTimePassed += timePassed.months() === 1 ? '1 month, ' : timePassed.months() + ' months, ';
      previous = 'months';
    }
    if (timePassed.days() > 0 || previous === 'months') {
      formattedTimePassed += timePassed.days() === 1 ? '1 day, ' : timePassed.days() + ' days, ';
      previous = 'days';
    }
    if (timePassed.hours() > 0 || previous === 'days') {
      formattedTimePassed += timePassed.hours() === 1 ? '1 hour, ' : timePassed.hours() + ' hours, ';
      previous = 'hours';
    }

    if (timePassed.minutes() > 0 || previous === 'hours') {
      formattedTimePassed += timePassed.minutes() === 1 ? '1 minute, ' : timePassed.minutes() + ' minutes, ';
    }

    formattedTimePassed += timePassed.seconds() === 1 ? '1 second ' : timePassed.seconds() + ' seconds';
    return formattedTimePassed;
  }

  /**
   *Representing a renderZone.render the selected  timezone .
   *@function
   *@returns Timezone
   */
  renderZone() {
    const { timezoneSettings } = this.props.options;
    let { timeZone } = this.props;
    const { zoneFormat } = timezoneSettings;
    let now: any = this.state.now;

    if (timeZone !== '' && timeZone !== 'browser') {
      now = moment.tz(now._d, timeZone);
    }

    // console.log(now)
    const clazz = css`
      font-size: ${timezoneSettings.fontSize};
      font-weight: ${timezoneSettings.fontWeight};
      line-height: 1.4;
    `;

    let zone = this.props.options.timezone || '';
    switch (zoneFormat) {
      case ZoneFormat.offsetAbbv:
        zone = now.format('Z z');
        break;
      case ZoneFormat.offset:
        zone = now.format('Z');
        break;
      case ZoneFormat.abbv:
        zone = now.format('z');
        break;
      default:
        try {
          zone = (this.getTZ(zone) as any)._z.name;
        } catch (e) {
          console.log('Error getting timezone', e);
        }
    }

    return (
      <h4 className={clazz}>
        {zone}
        {zoneFormat === ZoneFormat.nameOffset && (
          <>
            <br />({now.format('Z z')})
          </>
        )}
      </h4>
    );
  }

  /**
   *Representing a renderDate.render the  Date of selected by user .
   *@function
   *@returns Date
   */
  renderDate() {
    const { dateSettings, addD, addM, addY, Time_mode } = this.props.options;
    const { timeRange, timeZone } = this.props;

    let currentTime: any = this.state.now;
    let dataTimeFrom: any = cloneDeep(timeRange.from);
    let dataTimeTo: any = cloneDeep(timeRange.to);
    if (timeZone !== '' && timeZone !== 'browser') {
      dataTimeFrom = moment.tz(dataTimeFrom, timeZone);
      dataTimeTo = moment.tz(dataTimeTo, timeZone);
      currentTime = moment.tz(currentTime._d, timeZone);
    }

    const clazz = css`
      font-size: ${dateSettings.fontSize};
      font-weight: ${dateSettings.fontWeight};
    `;

    let disp = currentTime.locale(dateSettings.locale || '').format(dateSettings.dateFormat);

    if (addD !== 0) {
      dataTimeFrom.add(addD, 'day').format(dateSettings.dateFormat);
      dataTimeTo.add(addD, 'day').format(dateSettings.dateFormat);
      disp = currentTime.add(addD, 'day').format(dateSettings.dateFormat);
    }
    if (addM !== 0) {
      dataTimeFrom.add(addM, 'months').format(dateSettings.dateFormat);
      dataTimeTo.add(addM, 'months').format(dateSettings.dateFormat);
      disp = currentTime.add(addM, 'months').format(dateSettings.dateFormat);
    }
    if (addY !== 0) {
      dataTimeFrom.add(addY, 'year').format(dateSettings.dateFormat);
      dataTimeTo.add(addY, 'year').format(dateSettings.dateFormat);
      disp = currentTime.add(addY, 'year').format(dateSettings.dateFormat);
    }
    return (
      <span>
        <h3 className={clazz}>
          {Time_mode === ClockMode.Current
            ? disp
            : Time_mode === ClockMode.Start
            ? dataTimeFrom.format(dateSettings.dateFormat)
            : Time_mode === ClockMode.End
            ? dataTimeTo.format(dateSettings.dateFormat)
            : ''}
        </h3>
      </span>
    );
  }

  /**
   *Representing a renderTime.render the time  selected by timemode. {Current ,Start ,end} .
   *@function
   *@returns time
   */
  renderTime() {
    let { options, timeRange, timeZone } = this.props;
    let { timeSettings, mode, addh, addm, Time_mode } = options;
    let currentTime: any = this.state.now;
    let dataTimeFrom: any = cloneDeep(timeRange.from);
    let dataTimeTo: any = cloneDeep(timeRange.to);

    // todo: its not simultaneously working with both selected option of  timezone.(TimeZone Browser, Timezone variable )
    if (timeZone) {
      if (timeZone !== '' && timeZone !== 'browser') {
        timeZone = getTemplateSrv().replace(timeZone);
      } else {
        timeZone = timeZone;
      }
    }

    if (timeZone !== '' && timeZone !== 'browser') {
      dataTimeFrom = moment.tz(dataTimeFrom, timeZone);
      dataTimeTo = moment.tz(dataTimeTo, timeZone);
      currentTime = moment.tz(currentTime._d, timeZone);
    }

    const clazz = css`
      font-size: ${timeSettings.fontSize};
      font-weight: ${timeSettings.fontWeight};
    `;
    let disp = currentTime.format(this.getTimeFormat());

    if (mode === ClockMode.countdown) {
      disp = this.getCountdownText();
    } else if (mode === ClockMode.countup) {
      disp = this.getCountupText();
    }

    if (addm !== 0) {
      dataTimeFrom.add(addm, 'minute').format(this.getTimeFormat());
      dataTimeTo.add(addm, 'minute').format(this.getTimeFormat());
      disp = currentTime.add(addm, 'minute').format(this.getTimeFormat());
    }
    if (addh !== 0) {
      dataTimeFrom.add(addh, 'hours').format(this.getTimeFormat());
      dataTimeTo.add(addh, 'hours').format(this.getTimeFormat());
      disp = currentTime.add(addh, 'hours').format(this.getTimeFormat());
    }

    return (
      <h2 className={clazz}>
        {Time_mode === ClockMode.Current
          ? disp
          : Time_mode === ClockMode.Start
          ? dataTimeFrom.format(this.getTimeFormat())
          : Time_mode === ClockMode.End
          ? dataTimeTo.format(this.getTimeFormat())
          : ''}
      </h2>
    );
  }

  render() {
    const { options, width, height, theme } = this.props;
    const { dateSettings, timezoneSettings, bgColor } = options;

    const clazz = css`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      background-color: ${!bgColor ? theme.colors.bg1 : getColorForTheme(bgColor, theme)};
    `;

    return (
      <div
        className={clazz}
        style={{
          width,
          height,
        }}
      >
        {dateSettings.showDate && this.renderDate()}
        {this.renderTime()}
        {timezoneSettings.showTimezone && this.renderZone()}
      </div>
    );
  }
}

export const ClockPanel = withTheme(UnthemedClockPanel);
