import { ComparisonOperation, defaultStyleConfig } from '../Layers/geojsonLayer/default';
import { FeatureStyleConfig } from '../Layers/geojsonLayer/type';

/**
 * Default feature style rule configuration.
 * @type {FeatureStyleConfig}
 */
export const DEFAULT_STYLE_RULE: FeatureStyleConfig = {
  style: defaultStyleConfig,
  check: {
    property: '',
    operation: ComparisonOperation.EQ,
    value: '',
  },
};

/**
 * Array of comparison operators with corresponding labels.
 * @type {Array<{ label: string, value: ComparisonOperation }>}
 */
export const comparators = [
  { label: '==', value: ComparisonOperation.EQ },
  { label: '!=', value: ComparisonOperation.NEQ },
  { label: '>', value: ComparisonOperation.GT },
  { label: '>=', value: ComparisonOperation.GTE },
  { label: '<', value: ComparisonOperation.LT },
  { label: '<=', value: ComparisonOperation.LTE },
];
