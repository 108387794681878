import { DataFrame, Field, FieldMatcherID, fieldMatchers } from '@grafana/data';
// import { measureText } from '@grafana/ui';
import { cloneDeep } from 'lodash';
import { preparePlotFrame } from '../../timeline/utils/utils';
import { Direction, ChartData, Count, DirectionCount } from './types';

export const countPush = (countCopy: Count | any, dir: Direction, speed: number, maxSpeed: number) => {
  if (maxSpeed <= 10) {
    if (speed < 1) {
      countCopy[dir]['0-1'] += 1;
    } else if (speed < 2) {
      countCopy[dir]['1-2'] += 1;
    } else if (speed < 3) {
      countCopy[dir]['2-3'] += 1;
    } else if (speed < 4) {
      countCopy[dir]['3-4'] += 1;
    } else if (speed < 5) {
      countCopy[dir]['4-5'] += 1;
    } else if (speed < 6) {
      countCopy[dir]['5-6'] += 1;
    } else if (speed < 7) {
      countCopy[dir]['6-7'] += 1;
    } else {
      countCopy[dir]['7+'] += 1;
    }
  } else if (maxSpeed <= 20) {
    if (speed < 1) {
      countCopy[dir]['0-1'] += 1;
    } else if (speed < 3) {
      countCopy[dir]['1-3'] += 1;
    } else if (speed < 5) {
      countCopy[dir]['3-5'] += 1;
    } else if (speed < 8) {
      countCopy[dir]['5-8'] += 1;
    } else if (speed < 11) {
      countCopy[dir]['8-11'] += 1;
    } else if (speed < 14) {
      countCopy[dir]['11-14'] += 1;
    } else if (speed < 17) {
      countCopy[dir]['14-17'] += 1;
    } else {
      countCopy[dir]['17+'] += 1;
    }
  } else {
    if (speed < 1) {
      countCopy[dir]['0-1'] += 1;
    } else if (speed < 3) {
      countCopy[dir]['1-3'] += 1;
    } else if (speed < 5) {
      countCopy[dir]['3-5'] += 1;
    } else if (speed < 8) {
      countCopy[dir]['5-8'] += 1;
    } else if (speed < 12) {
      countCopy[dir]['8-12'] += 1;
    } else if (speed < 16) {
      countCopy[dir]['12-16'] += 1;
    } else if (speed < 20) {
      countCopy[dir]['16-20'] += 1;
    } else if (speed < 25) {
      countCopy[dir]['20-25'] += 1;
    } else if (speed < 30) {
      countCopy[dir]['25-30'] += 1;
    } else {
      countCopy[dir]['30+'] += 1;
    }
  }
};

export const classifyDir = (direction: number): Direction => {
  const dTh = 11.25;
  let dir;
  if (direction >= 360 - dTh || direction < dTh) {
    dir = Direction.N;
  } else if (direction >= dTh && direction < dTh * 3) {
    dir = Direction.NNE;
  } else if (direction >= dTh * 3 && direction < dTh * 5) {
    dir = Direction.NE;
  } else if (direction >= dTh * 5 && direction < dTh * 7) {
    dir = Direction.ENE;
  } else if (direction >= dTh * 7 && direction < dTh * 9) {
    dir = Direction.E;
  } else if (direction >= dTh * 9 && direction < dTh * 11) {
    dir = Direction.ESE;
  } else if (direction >= dTh * 11 && direction < dTh * 13) {
    dir = Direction.SE;
  } else if (direction >= dTh * 13 && direction < dTh * 15) {
    dir = Direction.SSE;
  } else if (direction >= dTh * 15 && direction < dTh * 17) {
    dir = Direction.S;
  } else if (direction >= dTh * 17 && direction < dTh * 19) {
    dir = Direction.SSW;
  } else if (direction >= dTh * 19 && direction < dTh * 21) {
    dir = Direction.SW;
  } else if (direction >= dTh * 21 && direction < dTh * 23) {
    dir = Direction.WSW;
  } else if (direction >= dTh * 23 && direction < dTh * 25) {
    dir = Direction.W;
  } else if (direction >= dTh * 25 && direction < dTh * 27) {
    dir = Direction.WNW;
  } else if (direction >= dTh * 27 && direction < dTh * 29) {
    dir = Direction.NW;
  } else if (direction >= dTh * 29 && direction < dTh * 31) {
    dir = Direction.NNW;
  } else {
    throw new Error('over 360 or under 0');
  }
  return dir;
};

type Data = {
  direction: number[];
  speed: number[];
};

export function calculateWindRose(data: Data, countRef: Count | any, maxSpeed: number): ChartData[] {
  let countCopy = cloneDeep(countRef);
  const dataLength = data.direction.length;
  data.direction.map((direction, index) => {
    const speed = data.speed[index];
    const dir = classifyDir(direction);
    countPush(countCopy, dir, speed, maxSpeed);
  });
  const ret = Object.keys(countCopy).map((key) => {
    let total = 0;
    const elements = Object.keys(countCopy[key]).map((subkey) => {
      const E = countCopy[key][subkey] / dataLength;
      total += E;
      return { [subkey]: E };
    });
    const obj: any = {};
    elements.map((val) => {
      const [elKey] = Object.keys(val);
      const [elVal] = Object.values(val);
      obj[elKey] = elVal;
      return obj;
    });
    return {
      angle: key,
      ...obj,
      total,
    } as ChartData;
  });

  return ret;
}

export function calculateWindRoseColumns(data: ChartData): string[] {
  const psuedo = cloneDeep(data);
  delete psuedo.angle;
  delete psuedo.total;
  const columns = ['angle'];
  Object.keys(psuedo).map((key) => {
    columns.push(key);
  });
  return columns;
}

export const windRoseFrames = (frames: DataFrame[], options: any) => {
  const timelineFrame = preparePlotFrame(frames, {
    x: fieldMatchers.get(FieldMatcherID.firstTimeField).get({}),
    y: fieldMatchers.get(FieldMatcherID.numeric).get({}),
  });

  if (!timelineFrame) {
    return { err: 'Could not generate timeline frame' };
  }

  let valueField: Field;
  const windData: Data = {
    direction: [],
    speed: [],
  };

  timelineFrame.fields.forEach((field: Field) => {
    if (field.name === options.windDir) {
      windData.direction = field.values.toArray();
    }
    if (field.name === options.windSpeed) {
      windData.speed = field.values.toArray();
      valueField = field;
    }
  });

  if (windData.speed.length <= 0 || windData.direction.length <= 0) {
    return { err: 'Could not generate timeline frame' };
  }

  return {
    windData,
    valueField: valueField!,
  };
};

export function generateCount(max: number): Count | any {
  let rangeObj: DirectionCount;
  const direction = [
    'N',
    'NNE',
    'NE',
    'ENE',
    'E',
    'ESE',
    'SE',
    'SSE',
    'S',
    'SSW',
    'SW',
    'WSW',
    'W',
    'WNW',
    'NW',
    'NNW',
  ];
  const count: Count | any = {};
  if (max <= 10) {
    rangeObj = {
      '0-1': 0,
      '1-2': 0,
      '2-3': 0,
      '3-4': 0,
      '4-5': 0,
      '5-6': 0,
      '6-7': 0,
      '7+': 0,
    };
  } else if (max <= 20) {
    rangeObj = {
      '0-1': 0,
      '1-3': 0,
      '3-5': 0,
      '5-8': 0,
      '8-11': 0,
      '11-14': 0,
      '14-17': 0,
      '17+': 0,
    };
  } else {
    rangeObj = {
      '0-1': 0,
      '1-3': 0,
      '3-5': 0,
      '5-8': 0,
      '8-12': 0,
      '12-16': 0,
      '16-20': 0,
      '20-25': 0,
      '25-30': 0,
      '30+': 0,
    };
  }
  // const count: Count | any = {
  //   'N': rangeObj,
  //   'NNE': rangeObj,
  //   'NE': rangeObj,
  //   'ENE': rangeObj,
  //   'E': rangeObj,
  //   'ESE': rangeObj,
  //   'SE': rangeObj,
  //   'SSE': rangeObj,
  //   'S': rangeObj,
  //   'SSW': rangeObj,
  //   'SW': rangeObj,
  //   'WSW': rangeObj,
  //   'W': rangeObj,
  //   'WNW': rangeObj,
  //   'NW': rangeObj,
  //   'NNW': rangeObj

  // }

  direction.forEach((dir) => {
    count[dir] = cloneDeep(rangeObj);
  });
  return count;
}
