import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const Search: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M21.8,20.6l-5-5c2.9-3.6,2.4-8.8-1.2-11.7S6.8,1.5,3.9,5.1s-2.4,8.8,1.2,11.7c3.1,2.5,7.5,2.5,10.6,0l5,5
        c0.3,0.3,0.9,0.3,1.2,0C22.1,21.4,22.1,20.9,21.8,20.6z M10.4,17c-3.7,0-6.7-3-6.7-6.7s3-6.7,6.7-6.7s6.7,3,6.7,6.7
        C17,14,14,17,10.4,17z"
      />
    </svg>
  );
};
