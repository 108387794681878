import React, { useEffect, useState } from 'react';
import { AppEvents, DataFrame, TransformerRegistryItem, TransformerUIProps } from '@grafana/data';
import { CustomTranformationOptions, CustomTransformationTransformer } from './CustomTranform';
import appEvents from 'app/core/app_events';
import MonacoEditor from '@monaco-editor/react';
import { Button } from '@grafana/ui';
import { config } from '@grafana/runtime';

export function CustomTranformationEditor(props: TransformerUIProps<CustomTranformationOptions>): React.ReactElement {
  const { options, onChange, input } = props;
  const [codeText, setCodeText] = useState<undefined | string>(() => {
    return `
      // The below is just the body of the function that will be executed on save function button click
      // do the manipulation to the dataframe variable and return it in the end
      // example code for adding two fields A an B in a time series dataframe is shown below
      let resultDataFrame = new MutableDataFrame({
        fields: cloneDeep(dataframe.fields),
        length: dataframe.length,
        meta: cloneDeep(dataframe.meta),
        name: dataframe.name,
        refId: dataframe.refId,
      });
    
      let fieldA = dataframe.fields.find((field) => field.name === "A");
      let fieldB = dataframe.fields.find((field) => field.name === "B");
      let arrayA = fieldA?.values.toArray();
      let arrayB = fieldB?.values.toArray();
    
      if (arrayA && arrayB) {
        let resultArray = arrayA.map((value, index) => value + arrayB[index]);
    
        let resultField = {
          name: 'Result',
          type: FieldType.number,
          config: {
            filterable: true,
          },
          values: new ArrayVector(resultArray),
        };
    
        resultDataFrame.addField(resultField);
      }
    
      return resultDataFrame;`;
  });

  const checkIfTimeseriesData = (input: DataFrame[]) => {
    let isTimeSeries = true;
    if (!input.length) {
      return isTimeSeries;
    }
    for (const item of input) {
      if (!item.fields || item.fields.length === 0 || item.fields[0].type !== 'time') {
        isTimeSeries = false; // If any dataframe is not a time series, set to false
        break; // No need to continue checking
      }
    }
    return isTimeSeries;
  };

  useEffect(() => {
    if (options.codeString !== undefined && options.codeString !== '') {
      setCodeText(options.codeString);
    }
  }, []);

  useEffect(() => {
    if (!checkIfTimeseriesData(input)) {
      appEvents.emit(AppEvents.alertError, ['Custom Transformation only works with time series dataframe.']);
      return;
    }
  }, [input]);
  return (
    <>
      <MonacoEditor
        language="typescript"
        theme={config.theme.name === 'Grafana Light' ? 'vs-light' : 'vs-dark'}
        value={codeText}
        onChange={(value) => setCodeText(value)}
        width={'100%'}
        height={'300px'}
      />
      <Button
        variant="primary"
        onClick={() => onChange({ ...options, codeString: codeText })}
        style={{ marginTop: '10px' }}
      >
        Save Function
      </Button>
    </>
  );
}
export const customTransformationRegistryItem: TransformerRegistryItem<CustomTranformationOptions> = {
  id: CustomTransformationTransformer.id,
  editor: CustomTranformationEditor,
  transformation: CustomTransformationTransformer,
  name: CustomTransformationTransformer.name,
  description: CustomTransformationTransformer.description,
};
