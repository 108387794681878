import React, { useEffect, useState } from 'react';
import { cloneDeep, isNumber, toString, isBoolean } from 'lodash';
import { DecimalCount, DecimalInfo, getValueFormat, PanelProps } from '@grafana/data';
import { NoData } from '@grafana/ui';
import Box from './Box';
import { BoxPlotOptions } from './types';
import { getGraphSeriesModel } from './getGraphSeriesModel';

const timelyday = [
  'now-5m',
  'now-15m',
  'now-30m',
  'now-1h',
  'now-3h',
  'now-6h',
  'now-12h',
  'now-24h',
  'now/d',
  '5m',
  '15m',
  '30m',
  '1h',
  '3h',
  '6h',
  '12h',
  '24h',
  '2d',
  '1d/d',
  '2d/d',
  '7d/d',
];
const timelyfull = ['now-2d', 'now-1d/d', 'now-2d/d', 'now-7d/d', '2d', '1d/d', '2d/d', '7d/d'];

interface Props extends PanelProps<BoxPlotOptions> {}

export function BoxPlotPanel(props: Props) {
  const [newChartData, setnewChartData] = useState([]);
  const [noData, setNoData]: any = useState(undefined);
  const [noDataColor, setNoDataColor]: any = useState('');
  const [tooltipTpl, settooltipTpl]: any = useState('');

  const { data, options, width, height, fieldConfig } = props;

  const getDecimalsForValue = (value: number, decimalOverride?: DecimalCount): DecimalInfo => {
    if (isNumber(decimalOverride)) {
      return { decimals: decimalOverride, scaledDecimals: null };
    }
    let dec = -Math.floor(Math.log(value) / Math.LN10) + 1;
    const magn = Math.pow(10, -dec);
    const norm = value / magn;
    let size;

    if (norm < 1.5) {
      size = 1;
    } else if (norm < 3) {
      size = 2;
      if (norm > 2.25) {
        size = 2.5;
        ++dec;
      }
    } else if (norm < 7.5) {
      size = 5;
    } else {
      size = 10;
    }

    size *= magn;

    if (value % 1 === 0) {
      dec = 0;
    }

    const decimals = Math.max(0, dec);
    const scaledDecimals = decimals - Math.floor(Math.log(size) / Math.LN10) + 2;

    return { decimals, scaledDecimals };
  };
  const toNumber = (value: any): number => {
    if (typeof value === 'number') {
      return value;
    }
    if (value === '' || value === null || value === undefined || Array.isArray(value)) {
      return NaN;
    }
    if (typeof value === 'boolean') {
      return value ? 1 : 0;
    }
    return toNumber(value);
  };

  const unitDataProducer = (value: any) => {
    if (fieldConfig.defaults.unit === undefined || fieldConfig.defaults.unit === 'none') {
      return {
        prefix: '',
        text: value,
        suffix: '',
      };
    }
    let text = toString(value);
    let numeric = toNumber(value);
    // @ts-ignore
    let prefix: string | undefined = undefined;
    let suffix: string | undefined = undefined;
    let shouldFormat = true;
    if (!isNaN(numeric)) {
      if (shouldFormat && !isBoolean(value)) {
        const { decimals, scaledDecimals } = getDecimalsForValue(value, fieldConfig.defaults.decimals || 2);
        const formatFunc = getValueFormat(fieldConfig.defaults.unit || 'none');
        const v = formatFunc(numeric, decimals, scaledDecimals, props.timeZone);
        text = v.text;
        suffix = v.suffix;
        prefix = v.prefix;
        if (text) {
          // return (prefix || '') + '' + text + '' + (suffix || '');
          return {
            text: text,
            numeric: numeric,
            prefix: prefix || '',
            suffix: suffix || '',
          };
        }
      }
      if (!text) {
        text = ''; // No data
      }
      // return (prefix || '') + '' + text + '' + (suffix || '');
      return {
        text: text,
        numeric: numeric,
        prefix: prefix || '',
        suffix: suffix || '',
      };
      // return { text, numeric, prefix, suffix};
    } else {
      // return value;
      return {
        text: text,
        numeric: numeric,
        prefix: prefix || '',
        suffix: suffix || '',
      };
    }
  };
  const uiHandler = (newChartData2: any) => {
    const newUnit = unitDataProducer(Math.max(...newChartData2));
    settooltipTpl(`
      <li style="text-align: left">
      Value：${newUnit.prefix} {value} ${newUnit.suffix}<br/>
      </li>
    `);
  };
  useEffect(() => {
    const newData = getGraphSeriesModel(
      props.data.series,
      props.timeZone,
      props.options.series || {},
      props.options.graph,
      props.options.legend,
      props.fieldConfig
    );
    if (newData && newData.length > 0) {
      // let newSeriesData = newData[0].data;
      let newSeriesData = cloneDeep(newData[0].data);
      newSeriesData = newSeriesData.filter((item) => {
        return item[1] !== null;
      });
      newSeriesData = newSeriesData.sort(function (a: any, b: any) {
        return a[0] - b[0];
      });
      let values: any = [];
      let timestamps: any = [];
      newSeriesData.map((item) => {
        timestamps.push(item[0]);
        values.push(item[1]);
      });
      if (options.clusteringMethod === 'timestamp') {
        const newChartData1: any = [];
        const newChartData2: any = [];
        while (true) {
          let newtsp = timestamps[0] + options.clusterSize;
          timestamps.push(newtsp);
          timestamps = timestamps.sort((a: any, b: any) => a - b);
          let idx = timestamps.indexOf(newtsp) + 1;
          if (!timestamps[idx + 1]) {
            break;
          }
          const smallTimestamp = timestamps.splice(0, idx + 1); // to remove newtsp
          const smallArray = values.splice(0, idx);
          let d: any = new Date(smallTimestamp[smallTimestamp.length - 1]);
          // @ts-ignore
          // if(props.timeRange.raw && props.timeRange.raw.from.match(/([m|h|1d|2d])/)) {
          if (props.timeRange.raw && timelyday.includes(props.timeRange.raw.from)) {
            d = d.toLocaleTimeString();
            // @ts-ignore
          } else if (props.timeRange.raw && timelyfull.includes(props.timeRange.raw.from)) {
            d = d.toLocaleString();
          } else {
            d = d.toLocaleDateString();
          }
          const newUnit = unitDataProducer(smallArray[smallArray.length - 1]);
          newChartData1.push({
            type: d,
            range: +newUnit.text,
          });
          newChartData2.push(smallArray[smallArray.length - 1]);
        }
        setnewChartData(newChartData1);
        uiHandler(newChartData2);
      } else {
        const newChartData1: any = [];
        const newChartData2: any = [];
        const itr = Math.floor(timestamps.length / options.numBoxes);
        for (let i = 0; i < options.numBoxes; i++) {
          const smallArray = values.splice(0, itr);
          let d: any = new Date(timestamps[i * itr + itr]);
          // @ts-ignore
          // if(props.timeRange.raw && props.timeRange.raw.from.match(/([m|h|1d|2d])/)) {
          if (props.timeRange.raw && timelyday.includes(props.timeRange.raw.from)) {
            d = d.toLocaleTimeString();
          } else {
            d = d.toLocaleDateString();
          }
          const newUnit = unitDataProducer(smallArray[smallArray.length - 1]);
          newChartData1.push({
            type: d,
            range: +newUnit.text,
          });
          newChartData2.push(smallArray[smallArray.length - 1]);
          // }
        }
        setnewChartData(newChartData1);
        uiHandler(newChartData2);
      }
    } else {
      setNoData(fieldConfig.defaults.noValue || 'No data');
      setNoDataColor(fieldConfig.defaults.thresholds?.steps[0].color);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, options]);
  return (
    <div style={{ position: 'relative', width, height }} className="text-center">
      {data.series && data.series.length > 0 ? (
        <Box
          width={width}
          height={height}
          newChartData={newChartData}
          bg={options.bg}
          thresholds={props.fieldConfig.defaults.thresholds}
          tooltipTpl={tooltipTpl}
        />
      ) : (
        <NoData text={noData} width={width} height={height} color={noDataColor} />
      )}
    </div>
  );
}
