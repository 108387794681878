import React from 'react';
import { AlignedData } from 'uplot';
import { DataFrame, TimeRange, TimeZone } from '@grafana/data';
import { preparePlotConfigBuilder } from './builder';
import {
  VizLayout,
  Themeable,
  UPlotChart,
  GraphNGLegendEvent,
  withTheme,
  preparePlotData,
  LegendDisplayMode,
  VizLegendOptions,
  UPlotConfigBuilder,
} from '@grafana/ui';
import { BoxPlotData, BoxPlotOptions } from '../types';
import { PlotLegendWaterfall } from './PlotLegendWaterfall';
import { TooltipPluginWaterfall } from './ToolTipPluginWaterfall';

/**
 * @constant
 * @default
 */
export const UPlot_UNIT = '__fixed';

/**
 * @typedef {Object} GraphNGProps
 * @property {number} width
 * @property {number} height
 * @property {BoxPlotData} dataMain
 * @property {TimeRange} timeRange
 * @property {VizLegendOptions} legend
 * @property {TimeZone} timeZone
 * @property {string} tooltipMode
 * @property {Function} [onLegendClick]
 * @property {Function} [onSeriesColorChange]
 * @property {React.ReactNode} [children]
 * @property {string} [chartType]
 * @property {Array<number>} [queryIndex]
 * @property {boolean} [includeTimeField]
 * @extends {Themeable}
 * @extends {BoxPlotOptions}
 */

export interface GraphNGProps extends Themeable, BoxPlotOptions {
  width: number;
  height: number;
  dataMain: BoxPlotData;
  timeRange: TimeRange;
  legend: VizLegendOptions;
  timeZone: TimeZone;
  tooltipMode: string;
  onLegendClick?: (event: GraphNGLegendEvent) => void;
  onSeriesColorChange?: (label: string, color: string) => void;
  children?: React.ReactNode;
  chartType?: any;
  queryIndex?: any;
  includeTimeField: boolean;
  endyearSwitch: boolean;
}

/**
 * @typedef {Object} GraphNGState
 * @property {DataFrame} alignedDataFrame
 * @property {UPlotConfigBuilder} config
 * @property {BoxPlotData} dataAll
 */
interface GraphNGState {
  data: AlignedData;
  alignedDataFrame: DataFrame;
  config: UPlotConfigBuilder;
  dataAll: BoxPlotData;
}

/**
 * Waterfall chart component fo main work.
 * @class The UnthemedGraphNG
 * @extends {React.Component<GraphNGProps, GraphNGState>}
 */
class UnthemedGraphNG extends React.Component<GraphNGProps, GraphNGState> {
  /**
   * Create the Waterfall Plot.
   * @constructs WaterfallPlot
   */
  constructor(props: GraphNGProps) {
    super(props);
    this.state = {} as GraphNGState;
  }

  static getDerivedStateFromProps(props: GraphNGProps, state: GraphNGState) {
    const frame = props.dataMain.frames[0];
    const dataAll = props.dataMain;
    if (!frame) {
      return { ...state };
    }

    return {
      ...state,
      data: preparePlotData(frame),
      alignedDataFrame: frame,
      dataAll: dataAll,
    };
  }

  componentDidMount() {
    const { theme } = this.props;

    // alignedDataFrame is already prepared by getDerivedStateFromProps method
    const { alignedDataFrame, dataAll } = this.state;

    if (!alignedDataFrame && !dataAll) {
      return;
    }
    this.setState({
      config: preparePlotConfigBuilder(
        alignedDataFrame,
        theme,
        this.getTimeRange,
        this.getTimeZone,
        dataAll,
        this.props
      ),
    });
  }

  componentDidUpdate(prevProps: GraphNGProps) {
    const { dataMain, theme, ohlcData } = this.props;
    const { alignedDataFrame, dataAll } = this.state;
    let shouldConfigUpdate = false;
    let stateUpdate = {} as GraphNGState;

    if (this.state.config === undefined || this.props.timeZone !== prevProps.timeZone) {
      shouldConfigUpdate = true;
    }

    if (dataMain !== prevProps.dataMain) {
      if (!alignedDataFrame) {
        return;
      }

      if (shouldConfigUpdate || dataMain.frames !== prevProps.dataMain.frames || prevProps.ohlcData !== ohlcData) {
        const builder = preparePlotConfigBuilder(
          alignedDataFrame,
          theme,
          this.getTimeRange,
          this.getTimeZone,
          dataAll,
          this.props
        );
        stateUpdate = { ...stateUpdate, config: builder };
      }
    }

    if (Object.keys(stateUpdate).length > 0) {
      this.setState(stateUpdate);
    }
  }

  getTimeRange = () => {
    return this.props.timeRange;
  };

  getTimeZone = () => {
    return this.props.timeZone;
  };

  //* code to show tooltip
  renderLegend() {
    const { legend, onSeriesColorChange, onLegendClick, dataMain, chartType, includeTimeField } = this.props;
    const { config } = this.state;

    // In case of includeTimeField we don't want to display legend
    if (includeTimeField || !config || (legend && legend.displayMode === LegendDisplayMode.Hidden)) {
      return;
    }

    return (
      <PlotLegendWaterfall
        data={dataMain}
        config={config}
        onSeriesColorChange={onSeriesColorChange}
        onLegendClick={onLegendClick}
        maxHeight="35%"
        maxWidth="60%"
        {...legend}
        chartType={chartType}
      />
    );
  }

  render() {
    const { width, height, children, timeZone, timeRange, tooltipMode, ...plotProps } = this.props;
    const { config, data } = this.state;
    if (!this.state.data || !this.state.config) {
      return null;
    }

    return (
      <VizLayout width={width} height={height} legend={this.renderLegend()}>
        {(vizWidth: number, vizHeight: number) => (
          <UPlotChart
            {...plotProps}
            config={config!}
            data={data}
            width={vizWidth}
            height={vizHeight}
            timeRange={timeRange}
          >
            {children}
            <TooltipPluginWaterfall
              config={config}
              mode={tooltipMode}
              data={this.props.dataMain.frames[0]}
              timeZone={this.props.timeZone}
              dataMain={plotProps.dataMain}
              plotType="candlestick"
              boxPlotCateg={plotProps.typeBoxPlot}
              chartType={this.props.chartType}
              queryIndex={plotProps.queryIndex}
            />
          </UPlotChart>
        )}
      </VizLayout>
    );
  }
}

export const WaterfallPlot = withTheme(UnthemedGraphNG);
WaterfallPlot.displayName = 'WaterfallPlot';
