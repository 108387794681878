import {
  FieldColorModeId,
  FieldConfigProperty,
  FieldType,
  PanelPlugin,
  standardEditorsRegistry,
  thresholdsOverrideProcessor,
} from '@grafana/data';
import { PieChartPanel } from './PieChartPanel';
import { addHideFrom } from '../timeseries/config';
import {
  PieChartOptions,
  PieChartType,
  PieChartLabels,
  PieChartLegendValues,
  addStandardDataReduceOptions,
} from './types/types';
import { FieldSelectEditor, LegendDisplayMode } from '@grafana/ui';
// import { SelectEditor } from './customEditor/SelectEditor';
import React from 'react';
import { buildColorPaletteOptions, paletteSelected } from '../carpetplot/module';
import { HeatmapFieldConfig } from '../carpetplot/types';
import { CircleOrPieSelectEditor } from 'app/features/linkDrawer/CircleOrPieSelectEditor';

export const plugin = new PanelPlugin<PieChartOptions>(PieChartPanel)
  .useFieldConfig({
    standardOptions: {
      [FieldConfigProperty.Color]: {
        defaultValue: {
          mode: FieldColorModeId.PaletteClassic,
        },
      },
    },
    useCustomConfig: (builder) => {
      addHideFrom(builder);
      builder
        .addSelect({
          path: 'colorPalette',
          name: 'Color palette',
          settings: {
            options: buildColorPaletteOptions(),
          },
          defaultValue: 'interpolateSpectral',
          category: ['WindRose Options'],
        })
        .addBooleanSwitch({
          path: 'invertPalette',
          name: 'Invert color palette',
          defaultValue: false,
          showIf: (config: HeatmapFieldConfig) =>
            config.colorPalette !== 'custom' && config.colorPalette !== 'fieldOptions',
          category: ['WindRose Options'],
        })
        .addColorPicker({
          path: 'nullValueColor',
          name: 'Null value color',
          defaultValue: 'rgb(155, 155, 155)',
          category: ['WindRose Options'],
        })
        .addSelect({
          path: 'colorSpace',
          name: 'Color space',
          settings: {
            options: [
              { value: 'rgb', label: 'RGB' },
              { value: 'hsl', label: 'HSL' },
              { value: 'hcl', label: 'HCL' },
              { value: 'lab', label: 'Lab' },
              { value: 'cubehelix', label: 'Cubehelix' },
            ],
          },
          showIf: paletteSelected('custom'),
          defaultValue: 'rgb',
          category: ['WindRose Options'],
        })
        .addCustomEditor({
          id: 'thresholds',
          path: 'thresholds',
          name: 'Thresholds',
          editor: standardEditorsRegistry.get('thresholds').editor as any,
          override: standardEditorsRegistry.get('thresholds').editor as any,
          process: thresholdsOverrideProcessor,
          shouldApply: (field) => field.type === FieldType.number,
          showIf: paletteSelected('custom'),
          category: ['WindRose Options'],
        });
    },
  })
  .setPanelOptions((builder) => {
    addStandardDataReduceOptions(builder);
    builder
      .addRadio({
        name: 'Chart type',
        path: 'pieType',
        category: ['Chart Options'],
        settings: {
          options: [
            { value: PieChartType.Pie, label: 'Pie' },
            { value: PieChartType.Donut, label: 'Donut' },
            { value: PieChartType.Polar, label: 'Polar' },
            { value: PieChartType.Radial, label: 'Radial' },
            { value: PieChartType.WindRose, label: 'WindRose' },
          ],
        },
        defaultValue: PieChartType.Pie,
      })
      .addRadio({
        path: 'radialExtend',
        name: 'Extends Radial',
        description: 'Extends Radial Graph',
        category: ['Chart Options'],
        settings: {
          options: [
            { value: '360', label: '360°' },
            { value: '270', label: '270°' },
          ],
        },
        defaultValue: '270',
        showIf: (options: PieChartOptions) => options.pieType === 'radial',
      })
      .addRadio({
        path: 'radialOptionsSelected',
        name: 'Radial Data',
        description: 'Radial Data Options',
        category: ['Chart Options'],
        settings: {
          options: [
            { value: 'max', label: 'Maximum' },
            { value: 'total', label: 'Total' },
            { value: 'custom', label: 'Custom' },
          ],
        },
        defaultValue: 'max',
        showIf: (options: PieChartOptions) => options.pieType === 'radial',
      })
      .addNumberInput({
        path: 'radialInput',
        name: 'Custom Value',
        description: 'Enter a value to adjust the radial bar',
        category: ['Chart Options'],
        defaultValue: 10000,
        settings: {
          placeholder: '10000',
          integer: true,
          // min: 0,
          // max: 10,
        },
        showIf: (options: PieChartOptions) =>
          options.radialOptionsSelected === 'custom' && options.pieType === 'radial',
      })
      .addMultiSelect({
        name: 'Labels',
        path: 'displayLabels',
        description: 'Select the labels to be displayed in the pie chart',
        category: ['Chart Options'],
        settings: {
          options: [
            { value: PieChartLabels.Percent, label: 'Percent' },
            { value: PieChartLabels.Name, label: 'Name' },
            { value: PieChartLabels.Value, label: 'Value' },
          ],
        },
      })
      .addRadio({
        path: 'tooltip.mode',
        name: 'Tooltip mode',
        description: '',
        defaultValue: 'single',
        category: ['Chart Options'],
        settings: {
          options: [
            { value: 'single', label: 'Single' },
            { value: 'multi', label: 'All' },
            { value: 'none', label: 'Hidden' },
          ],
        },
      })
      .addRadio({
        path: 'legend.displayMode',
        name: 'Legend mode',
        description: '',
        defaultValue: LegendDisplayMode.List,
        category: ['Chart Options'],
        settings: {
          options: [
            { value: LegendDisplayMode.List, label: 'List' },
            { value: LegendDisplayMode.Table, label: 'Table' },
            { value: LegendDisplayMode.Hidden, label: 'Hidden' },
          ],
        },
      })
      .addRadio({
        path: 'legend.placement',
        name: 'Legend placement',
        description: '',
        defaultValue: 'bottom',
        category: ['Chart Options'],
        settings: {
          options: [
            { value: 'bottom', label: 'Bottom' },
            { value: 'right', label: 'Right' },
          ],
        },
        showIf: (c) => c.legend.displayMode !== LegendDisplayMode.Hidden,
      })
      .addMultiSelect({
        name: 'Legend values',
        path: 'legend.values',
        category: ['Chart Options'],
        settings: {
          options: [
            { value: PieChartLegendValues.Percent, label: 'Percent' },
            { value: PieChartLegendValues.Value, label: 'Value' },
          ],
        },
        showIf: (c) => c.legend.displayMode !== LegendDisplayMode.Hidden,
      })
      .addBooleanSwitch({
        path: 'limitSwitch',
        name: 'Enable Limit',
        category: ['Limit Data'],
        description: 'Display data according to limit',
        defaultValue: false,
      })
      .addNumberInput({
        path: 'limitValue',
        name: 'Limit',
        description: 'Limit displayed items',
        defaultValue: 10,
        category: ['Limit Data'],
        showIf: (options: PieChartOptions) => options.limitSwitch,
      })
      .addRadio({
        path: 'limitOrder',
        name: 'Limit Order',
        description: 'Filters data from top or bottom',
        settings: {
          options: [
            { value: 'none', label: 'None' },
            { value: 'top', label: 'Top' },
            { value: 'bottom', label: 'Bottom' },
          ],
        },
        defaultValue: 'none',
        category: ['Limit Data'],
        showIf: (options: PieChartOptions) => options.limitSwitch,
      })
      .addBooleanSwitch({
        path: 'limitRemaining',
        name: 'Aggregate Remaining Data',
        description: 'Aggregate remaining data into Others',
        defaultValue: false,
        category: ['Limit Data'],
        showIf: (options: PieChartOptions) => options.limitSwitch,
      })
      .addBooleanSwitch({
        path: 'dashboardVisitMode',
        name: 'Enable Link Dashboard',
        // description: 'Enables `dashboard-visit-mode`',
        defaultValue: false,
        // showIf: (options: PieChartOptions) => options.pieType === 'pie' || options.pieType === 'donut',
        category: ['Link Dashboard'],
      })
      .addCustomEditor({
        id: 'toVisitDashboard',
        path: 'toVisitDashboard',
        name: 'Dashboard Links',
        // description: 'To Link Dashboard',
        defaultValue: [],
        editor(props: any) {
          return (
            <CircleOrPieSelectEditor bigData={props.context} toVisitDashboard={props.value} onChange={props.onChange} />
          );
        },
        // showIf: (options: PieChartOptions) =>
        //   options.dashboardVisitMode && (options.pieType === 'pie' || options.pieType === 'donut'),
        category: ['Link Dashboard'],
      })
      .addCustomEditor({
        id: 'windDir',
        path: 'windDir',
        name: 'Wind Direction',
        description: 'Field to use for the Wind Direction. Must be unique.',
        editor: FieldSelectEditor,
        category: ['WindRose Query Editor'],
        settings: {
          filterByType: [FieldType.number],
        },
        showIf: (options: PieChartOptions) => options.pieType === PieChartType.WindRose,
      })
      .addCustomEditor({
        id: 'windSpeed',
        path: 'windSpeed',
        name: 'Wind Speed',
        description: 'Field to use for the Wind Speed. Must be unique.',
        editor: FieldSelectEditor,
        category: ['WindRose Query Editor'],
        settings: {
          filterByType: [FieldType.number],
        },
        showIf: (options: PieChartOptions) => options.pieType === PieChartType.WindRose,
      })
      .addNumberInput({
        path: 'legendGap',
        name: 'Legend Gap',
        // description: 'Limit displayed items',
        defaultValue: 20,
        showIf: (options: PieChartOptions) => options.pieType === PieChartType.WindRose,
        category: ['WindRose Query Editor'],
      });
  });
