import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Group } from '@visx/group';
// @ts-ignore
import { sankeyCircular as d3Sankey } from 'd3-sankey-circular';
import { HierarchyDefaultNode as DefaultNode } from '@visx/hierarchy';
import { SankeyGraph } from 'd3-sankey';

Sankey.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.func,
};

export default function Sankey({
  top,
  left,
  className,
  data,
  size,

  nodeId,
  nodeAlign,
  nodeWidth,
  nodePadding,
  nodePaddingRatio,
  extent,
  iterations,
  circularLinkGap,

  children,
  nodeComponent = DefaultNode,
  ...restProps
}: {
  top: number;
  left: number;
  className: string;
  data: SankeyGraph<{}, {}>;
  size: [number, number];
  nodeId: any;
  nodeAlign: any;
  nodeWidth: number;
  nodePadding: number;
  nodePaddingRatio: number;
  extent: [[number, number], [number, number]];
  iterations: number;
  circularLinkGap: number;
  children: any;
  nodeComponent: any;
}) {
  const sankey = d3Sankey();
  if (size) {
    sankey.size(size);
  }
  if (nodeId) {
    sankey.nodeId(nodeId);
  }
  if (nodeAlign) {
    sankey.nodeAlign(nodeAlign);
  }
  if (nodeWidth) {
    sankey.nodeWidth(nodeWidth);
  }
  if (nodePadding) {
    sankey.nodePadding(nodePadding);
  }
  if (nodePaddingRatio) {
    sankey.nodePaddingRatio(nodePaddingRatio);
  }
  if (extent) {
    sankey.extent(extent);
  }
  if (iterations) {
    sankey.iterations(iterations);
  }
  if (circularLinkGap) {
    sankey.circularLinkGap(circularLinkGap);
  }

  const sankeyData = sankey(data);

  if (!!children) {
    return (
      <Group top={top} left={left} className={cx('vx-sankey', className)}>
        {children({ data: sankeyData })}
      </Group>
    );
  }

  // TODO: Support no-child function as well (nodeComponent/linkComponent)

  // return (
  //   <Group top={top} left={left} className={cx('vx-sankey', className)}>
  //     {nodeComponent &&
  //       descendants.map((node, i) => {
  //         return (
  //           <Group key={`pack-node-${i}`}>
  //             {React.createElement(nodeComponent, { node })}
  //           </Group>
  //         );
  //       })}
  //   </Group>
  // );
  return null;
}
