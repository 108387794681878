import React, { useEffect, useLayoutEffect, useState } from 'react';

import { UPlotConfigBuild } from '../config/UPlotConfigBuild';
import { SelectionPlugin } from './SelectionPlugin';
import { PlotSelection } from '../types';
import { pluginLog2 } from '../utils';

interface ZoomPluginProps {
  onZoom: (range: { from: number; to: number }) => void;
  config?: UPlotConfigBuild;
}

// min px width that triggers zoom
const MIN_ZOOM_DIST = 5;

/**
 * @alpha
 */
export const ZoomPlugin: React.FC<ZoomPluginProps> = ({ onZoom }) => {
  return (
    <SelectionPlugin
      id="Zoom"
      /* very time series oriented for now */
      onSelect={(selection) => {
        if (selection.bbox.width < MIN_ZOOM_DIST) {
          return;
        }
        onZoom({ from: selection.min, to: selection.max });
      }}
    />
  );
};

export const UPlotZoom: React.FC<ZoomPluginProps> = ({ onZoom, config }) => {
  const [selection, setSelection] = useState<PlotSelection | null>(null);

  useEffect(() => {
    if (selection) {
      pluginLog2('ZoomPlugin', false, 'selected', selection);
      if (selection.bbox.width < MIN_ZOOM_DIST) {
        return;
      }
      onZoom({ from: selection.min, to: selection.max });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  useLayoutEffect(() => {
    config &&
      config.addHook('setSelect', (u) => {
        const min = u.posToVal(u.select.left, 'x');
        const max = u.posToVal(u.select.left + u.select.width, 'x');

        setSelection({
          min,
          max,
          bbox: {
            left: u.bbox.left / window.devicePixelRatio + u.select.left,
            top: u.bbox.top / window.devicePixelRatio,
            height: u.bbox.height / window.devicePixelRatio,
            width: u.select.width,
          },
        });

        // manually hide selected region (since cursor.drag.setScale = false)
        /* @ts-ignore */
        u.setSelect({ left: 0, width: 0 }, false);
      });
  }, [config]);

  return null;
};
