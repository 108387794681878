import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const Snapshots: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 16 16"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M12,3.4h-0.3l-1.3-1.6c-0.3-0.4-0.8-0.7-1.4-0.7H6.9c-0.5,0-1,0.2-1.4,0.7L4.3,3.4H4c-1.6,0-2.9,1.3-2.9,2.9V12
        c0,1.6,1.3,2.9,2.9,2.9h8c1.6,0,2.9-1.3,2.9-2.9V6.3C14.9,4.7,13.6,3.4,12,3.4z M6.4,2.5c0.1-0.1,0.3-0.2,0.5-0.2h2.2
        c0.2,0,0.3,0.1,0.5,0.2l0.7,0.9H5.7L6.4,2.5z M13.7,12c0,1-0.8,1.7-1.7,1.7H4c-1,0-1.7-0.8-1.7-1.7V6.3c0-1,0.8-1.7,1.7-1.7h8
        c1,0,1.7,0.8,1.7,1.7V12z M8,5.7c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4s3.4-1.5,3.4-3.4C11.4,7.2,9.9,5.7,8,5.7z M8,11.4
        c-1.3,0-2.3-1-2.3-2.3s1-2.3,2.3-2.3s2.3,1,2.3,2.3S9.3,11.4,8,11.4z"
      />
    </svg>
  );
};
