import React from 'react';
import { Button, useTheme } from '@grafana/ui';
import { MarkerLinkI, SingleCustomMarker } from '../types/interface';
import { getFieldDisplayValues } from '@grafana/data';
import { MapPanelProps } from '../GoogleMapPanel';
import { DashExpand } from 'app/features/linkDrawer/dashboard/DashExpand';
import { DashboardTime } from 'app/features/linkDrawer/dashboard/DashboardTime';

/**
 * @typedef {Object} Props
 * @property {Array<MarkerLinkI>} markerLink
 * @property {function} onChange
 * @property {MapPanelProps} bigData
 */
interface Props {
  markerLink: MarkerLinkI[];
  onChange: (markerLink: MarkerLinkI[]) => void;
  bigData: MapPanelProps;
}
/**
 * dashboard link to marker
 * @param {Props}
 * @returns {JSX.Element}
 */
export function MarkerLink({ markerLink, bigData, onChange }: Props): JSX.Element {
  const theme = useTheme();

  const dataLoad = (event: React.MouseEvent) => {
    const { data, options, replaceVariables, fieldConfig } = bigData;
    const newData = getFieldDisplayValues({
      fieldConfig,
      reduceOptions: options.reduceOptions,
      replaceVariables,
      theme: theme,
      data: data as any,
      timeZone: 'browser',
    });

    // Do not remove everything from dataLoad button just append new options into markerLink
    newData.map((frame: any) => {
      if (frame.display.title === options.querySelections.location) {
        // If frame.display.text does not exist in markerLink then add it
        // else do nothing
        const exists = markerLink.some((marker: MarkerLinkI) => marker.location === frame.display.text);
        if (!exists) {
          markerLink.push({
            location: frame.display.text,
            customName: '',
            url: '',
          });
        }
      }
    });

    // Filter duplicates from newMarkerLinks
    // const filteredMarkerLink = newMarkerLinks.filter(
    //   (marker, index, self) => index === self.findIndex((m) => m.location === marker.location)
    // );
    // onChange(filteredMarkerLink);
    onChange(markerLink);
  };
  const onMarkerUrlChange = (target: any, index: number) => {
    markerLink[index].customName = target.label;
    markerLink[index].url = target.value;
    onChange(markerLink);
  };

  // Callback to update name and url for custom name & url
  const onUrlInputChange = (value: string, index: number, eventType: boolean) => {
    if (eventType) {
      markerLink[index].customName = value;
    } else {
      markerLink[index].url = value;
    }
    onChange(markerLink);
  };

  // Update custom marker Url
  const onCustomMarkerUrlChange = (target: any, index: number) => {
    bigData.options.customMarkers.markers[index].url = target.value;
    bigData.options.customMarkers.markers[index].customName = target.label;
    onChange(markerLink);
  };

  // Update custom marker input (name and url)
  const onCustomMarkerInputChange = (value: string, index: number, eventType: boolean) => {
    if (eventType) {
      bigData.options.customMarkers.markers[index].customName = value;
    } else {
      bigData.options.customMarkers.markers[index].url = value;
    }
    onChange(markerLink);
  };
  const handleRemoveLinkCustom = (idx: number) => {
    bigData.options.customMarkers.markers = bigData.options.customMarkers.markers.filter(
      (dash, index) => index !== idx
    );
    onChange(markerLink);
  };
  const handleRemoveLink = (idx: number) => {
    const newMarkerLink = markerLink.filter((dash, index) => index !== idx);
    onChange(newMarkerLink);
  };

  const handleDrawerLinkTime = (value: boolean) => {
    bigData.options.drawerLinkTime = value;
    onChange(markerLink);
  };

  return (
    <>
      <DashboardTime value={bigData.options.drawerLinkTime} drawerLinkTime={handleDrawerLinkTime} />
      <div>
        {bigData.options.customMarkers.markers &&
          bigData.options.customMarkers.markers.map((marker: SingleCustomMarker, index: number) => {
            return (
              <DashExpand
                key={index}
                title={'C-Marker' + '  ' + (index + 1) + ' | ' + marker.query[0].value}
                option={marker}
                index={index}
                onDashboardChange={onCustomMarkerUrlChange}
                onUrlChangeCustomInput={onCustomMarkerInputChange}
                handleRemoveLink={handleRemoveLinkCustom}
              />
            );
          })}
        {!bigData.options.isQueryForHeat &&
          markerLink.map((marker: MarkerLinkI, index: number) => {
            return (
              <DashExpand
                key={index}
                title={index + 1 + ' | ' + marker.location}
                option={marker}
                index={index}
                onDashboardChange={onMarkerUrlChange}
                onUrlChangeCustomInput={onUrlInputChange}
                handleRemoveLink={handleRemoveLink}
              />
            );
          })}
        <div className="gf-form gf-form--grow">
          {/* <InlineFormLabel
            tooltip="It replaces old data with new data, use with care. If not working please check the
          `Location Name Field` under `Query Settings`. The retuned query data-frame should contain
          atleast one column matching `Location Name Field`. If some data points missing, plese increase
          the `Max number of rows limit` under `Display` option."
          >
            Refresh Queries
          </InlineFormLabel> */}
          <Button icon="refresh" onClick={dataLoad} variant="secondary" size="md">
            Refresh Queries
          </Button>
        </div>
      </div>
    </>
  );
}
