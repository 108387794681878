import React, { useEffect, useState, FC } from 'react';
import { PanelProps, FieldDisplay, getFieldDisplayValues } from '@grafana/data';
import { SimpleOptions } from './types';
import { css, cx } from 'emotion';
import { stylesFactory, useTheme } from '@grafana/ui';
import { Text } from '@visx/text';
import { Wordcloud } from '@visx/wordcloud';
import { scaleLog } from '@visx/scale';
interface WordData {
  text: string;
  value: number;
}
interface Props extends PanelProps<SimpleOptions> {}
export const SimplePanel: FC<Props> = ({ options, data, width, height, replaceVariables, fieldConfig, timeZone }) => {
  const styles = getStyles();
  const theme = useTheme();
  const [word, setWord] = useState([]);

  useEffect(() => {
    if (data.series.length !== 0) {
      const getValues = (): FieldDisplay[] => {
        return getFieldDisplayValues({
          fieldConfig,
          reduceOptions: options.reduceOptions,
          replaceVariables,
          theme: theme,
          data: data.series,
          timeZone,
        });
      };
      /**
       * This will refactor all the values. It will check the frequencies of all the values that are coming in and accordingly assign a value to all of them which will define their weight.
       * @function
       * refactoredValues
       * @return FinalResult - Refactored Data.
       */
      const refactoredValues = () => {
        const newData = getValues();

        // default value
        const defaultValue = 5;
        // final output comes here
        var finalData = [];
        for (let f = 0; f < newData.length; f++) {
          // for first it will set default value
          newData[f].display.numeric = defaultValue;
        }

        for (let i = 0; i < newData.length; i++) {
          var incriment = 0;
          // it will check the value is matching with default ( for this we can know we scan this value first time)
          if (newData[i].display.numeric === defaultValue) {
            // it will check the matching value ( frequency)
            for (let index = 0; index < newData.length; index++) {
              if (i !== index && newData[index].display.text === newData[i].display.text) {
                // changing the values of matching values also for matched because it will never push again
                newData[i].display.numeric = newData[i].display.numeric + defaultValue;
                newData[index].display.numeric = newData[i].display.numeric;
                // to ensure matching is true
                incriment = 1;
              }
              if (index === newData.length - 1) {
                if (incriment === 1) {
                  // this run if matching is 1
                  finalData.push(newData[i]);
                  // incrimenting the current loop
                  i++;
                }
              }
            }
            // it will push the code if not matching
            finalData.push(newData[i]);
          }
        }
        return finalData;
      };

      // not a number will be ignored in this part work need to be done next time
      let array: any = [];
      const NewBigData = refactoredValues();
      NewBigData.map((item) => {
        if (item.display.numeric && options.wordCloudOptions.display === 'value') {
          array.push({ text: item.display.text, value: item.display.numeric, color: item.display.color });
        }
        if (item.display.numeric && options.wordCloudOptions.display === 'text') {
          array.push({ text: item.display.title, value: item.display.numeric, color: item.display.color });
        }
      });
      setWord(array);
    }
  }, [data, options, fieldConfig, replaceVariables, theme, timeZone]);
  if (data.series.length <= 0) {
    return (
      <div className="panel-empty">
        <p>No data</p>
      </div>
    );
  }

  if (options.minFontSize > options.maxFontSize) {
    return (
      <div className="panel-empty">
        <p>Minimum font size should be less than Maximum font size</p>
      </div>
    );
  }
  const fontScale = scaleLog({
    // @ts-ignore
    domain: [Math.min(...word.map((w) => w.value)), Math.max(...word.map((w) => w.value))],
    range: [options.minFontSize, options.maxFontSize],
  });
  const fontSizeSetter = (datum: WordData) => fontScale(datum.value);

  return (
    <div
      className={cx(
        styles.wrapper,
        css`
          width: ${width}px;
          height: ${height}px;
        `
      )}
    >
      <div className="wordcloud">
        <Wordcloud
          words={word}
          width={width}
          height={height}
          fontSize={fontSizeSetter}
          font={options.wordCloudOptions.fontFamily ?? 'impact'}
          padding={options.wordCloudOptions.padding}
          spiral={options.wordCloudOptions.spiral}
          rotate={options.wordCloudOptions.rotations}
        >
          {(cloudWords) => {
            return cloudWords.map((w: any, i) => {
              return (
                <Text
                  key={i}
                  values={w.value}
                  fill={w.color}
                  textAnchor={'middle'}
                  transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
                  fontSize={w.size}
                  fontFamily={w.font}
                >
                  {w.text}
                </Text>
              );
            });
          }}
        </Wordcloud>
      </div>
    </div>
  );
};

const getStyles = stylesFactory(() => {
  return {
    wrapper: css`
      position: relative;
    `,
    svg: css`
      position: absolute;
      top: 0;
      left: 0;
    `,
    textBox: css`
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
    `,
  };
});
