import React from 'react';
import { Select, InlineFormLabel, Button, Field, MultiSelect, useTheme } from '@grafana/ui';
import { cloneDeep } from 'lodash';
import { DataFrame, FieldDisplay, getFieldDisplayValues } from '@grafana/data';
import { ChordDataType, LabelValueI, QueryNodeI } from './types';

interface Props {
  contextData: any;
  chordData: ChordDataType;
  onChange: (chordData: ChordDataType) => void;
  panelData: DataFrame[];
}

export function HandleEditor(props: Props) {
  const [queries, setQueries] = React.useState<LabelValueI[]>([]);
  const [tmpSource, setTmpSource] = React.useState<LabelValueI>();
  const [tmpTarget, setTmpTarget] = React.useState<LabelValueI>();
  const chordData: ChordDataType = props.chordData;
  const theme = useTheme();

  // * Options for Multi Select
  const onDataLoad = () => {
    const { options, replaceVariables } = props.contextData;
    const setoptions = cloneDeep(options.reduceOptions);
    delete setoptions.fields;
    delete setoptions.limit;
    delete setoptions.values;
    let fieldData: FieldDisplay[] = getFieldDisplayValues({
      fieldConfig: options.fieldConfig,
      reduceOptions: setoptions,
      replaceVariables,
      theme: theme,
      data: props.panelData!,
      sparkline: false,
      timeZone: 'browser',
    });
    const localQueries: LabelValueI[] = [];
    fieldData?.map((ele: FieldDisplay, i: number) => {
      if (ele?.display?.title) {
        localQueries.push({
          label: ele.display.title,
          value: ele.display.title,
        });
      }
    });
    setQueries(localQueries);
  };

  // * Setting the source index
  const onFromChange = (value: any) => {
    setTmpSource(value);
  };

  // * Setting the target index
  const onToChange = (value: any) => {
    setTmpTarget(value);
  };

  // * delete single chord
  const deleteValue = (index: any) => {
    const arrayToFilter = props.chordData.queryNode;

    const newArray: any = arrayToFilter.filter((item: any, i: any) => {
      return index !== i;
    });
    props.chordData.queryNode = newArray;

    props.onChange(props.chordData);
  };

  // * Creating an array of chords (source-target)
  const handleSubmitData = ({ value }: any) => {
    if (tmpSource && tmpTarget) {
      props.chordData.queryNode.push({
        source: tmpSource,
        target: tmpTarget,
      });
      props.onChange(props.chordData);
      setTmpSource(undefined);
      setTmpTarget(undefined);
    }
  };

  // * Handling Multislect Values and query updation in bigdata
  const nodeSelector = (value: any) => {
    props.chordData.links = value;
    props.onChange(props.chordData);
  };

  return (
    <div>
      {/* Select Nodes */}
      <div className="gf-form">
        <InlineFormLabel>Select Nodes</InlineFormLabel>
        <MultiSelect options={queries} onChange={(value) => nodeSelector(value)} value={chordData.links} />
        <Button style={{ marginLeft: '5px' }} onClick={onDataLoad} variant="secondary" size="md">
          <i className="fa fa-refresh" aria-hidden="true"></i>
        </Button>
      </div>
      {/* Selecting Source and target */}
      <div className="gf-form">
        <Field label="Add Chord">
          <>
            <div className="gf-form">
              <InlineFormLabel tooltip="Select query for starting point">Source</InlineFormLabel>
              <Select
                width={20}
                options={chordData.links}
                onChange={(value) => onFromChange(value)}
                value={chordData.links.find((item: any) => item.value === tmpSource)}
              />
            </div>
            <div className="gf-form">
              <InlineFormLabel tooltip="Select query for ending point">Target</InlineFormLabel>
              <Select
                width={20}
                options={chordData.links}
                onChange={(value) => onToChange(value)}
                value={chordData.links.find((item: any) => item.value === tmpTarget)}
              />
            </div>
            <div className="gf-form">
              <Button variant="primary" onClick={handleSubmitData}>
                Add
              </Button>
            </div>
          </>
        </Field>
      </div>
      {/* List of All chords - (Source and target) */}
      {props.chordData.queryNode.length > 0 && (
        <div className="gf-form" style={{ marginTop: '10px' }}>
          <Field label="Added Chords">
            <>
              {props.chordData.queryNode.map((item: QueryNodeI, index: any) => {
                return (
                  <div key={index} style={{ display: 'flex', marginBottom: '10px' }}>
                    <InlineFormLabel>
                      {chordData.links.map((item2: LabelValueI) => {
                        if (item.source.label === item2.label) {
                          return <div key={`source-${item2.label}-${index}`}>{item2.label}</div>;
                        } else {
                          return;
                        }
                      })}
                      <div>{`-->>`}</div>
                      {chordData.links.map((item2: LabelValueI) => {
                        if (item.target.label === item2.label) {
                          return <div key={`target-${item2.label}-${index}`}>{item2.label}</div>;
                        } else {
                          return;
                        }
                      })}
                    </InlineFormLabel>
                    <Button
                      key={`remove-${index}`}
                      className="max-width-5"
                      variant="destructive"
                      onClick={() => deleteValue(index)}
                    >
                      <i className="fa fa-trash-o" aria-hidden="true"></i>
                    </Button>
                  </div>
                );
              })}
            </>
          </Field>
        </div>
      )}
    </div>
  );
}
