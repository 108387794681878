import React from 'react';
import { Input, Button, InlineFormLabel } from '@grafana/ui';

interface Props {
  tagList: {
    tags: any[];
    currLoc: {
      x: number;
      y: number;
      z: number;
    };
  };
  onChange: (tagList: { tags: any[] }) => void;
}
interface Scene {
  label: string;
  description: string;
  anchorPosition: Dim;
  stemVector: Dim;
}
interface Dim {
  x: number;
  y: number;
  z: number;
}
export function TagEditor(props: Props) {
  const addNewLabel = () => {
    const oldScenes = props.tagList;
    oldScenes.tags.push({
      label: '',
      description: '',
      anchorPosition: {
        x: 0,
        y: 0,
        z: 0,
      },
      stemVector: {
        x: 0,
        y: 0,
        z: 0,
      },
    });
    props.onChange(oldScenes);
  };
  const onlabelChange = (event: React.SyntheticEvent<HTMLInputElement>, index: number) => {
    const oldScenes = props.tagList;
    oldScenes.tags[index].label = event.currentTarget.value;
    props.onChange(oldScenes);
  };
  const ondescriptionChange = (event: React.SyntheticEvent<HTMLInputElement>, index: number) => {
    const oldScenes = props.tagList;
    oldScenes.tags[index].description = event.currentTarget.value;
    props.onChange(oldScenes);
  };
  const onXPosChange = (event: React.SyntheticEvent<HTMLInputElement>, index: number) => {
    const oldScenes = props.tagList;
    oldScenes.tags[index].anchorPosition.x = +event.currentTarget.value;
    props.onChange(oldScenes);
  };
  const onYPosChange = (event: React.SyntheticEvent<HTMLInputElement>, index: number) => {
    const oldScenes = props.tagList;
    oldScenes.tags[index].anchorPosition.y = +event.currentTarget.value;
    props.onChange(oldScenes);
  };
  const onZPosChange = (event: React.SyntheticEvent<HTMLInputElement>, index: number) => {
    const oldScenes = props.tagList;
    oldScenes.tags[index].anchorPosition.z = +event.currentTarget.value;
    props.onChange(oldScenes);
  };
  const onXRotChange = (event: React.SyntheticEvent<HTMLInputElement>, index: number) => {
    const oldScenes = props.tagList;
    oldScenes.tags[index].stemVector.x = +event.currentTarget.value;
    props.onChange(oldScenes);
  };
  const onYRotChange = (event: React.SyntheticEvent<HTMLInputElement>, index: number) => {
    const oldScenes = props.tagList;
    oldScenes.tags[index].stemVector.y = +event.currentTarget.value;
    props.onChange(oldScenes);
  };
  const onZRotChange = (event: React.SyntheticEvent<HTMLInputElement>, index: number) => {
    const oldScenes = props.tagList;
    oldScenes.tags[index].stemVector.z = +event.currentTarget.value;
    props.onChange(oldScenes);
  };
  const deleteLabel = (index: number) => {
    let oldScenes = props.tagList;
    oldScenes.tags = oldScenes.tags.filter((item, idx) => {
      return index !== idx;
    });
    props.onChange(oldScenes);
  };
  const loadCurrLoc = (index: number) => {
    let oldTags = props.tagList;
    oldTags.tags[index].anchorPosition = oldTags.currLoc;
    props.onChange(oldTags);
  };
  const { tags } = props.tagList;
  return (
    <div className="width-20">
      {tags &&
        tags.map((scene: Scene, index: number) => {
          return (
            <div key={index} className="cmb-1">
              <div className="gf-form max-width-30">
                <InlineFormLabel width={8}>Label</InlineFormLabel>
                <Input value={scene.label} onChange={(event) => onlabelChange(event, index)} />
              </div>
              <div className="gf-form max-width-30">
                <InlineFormLabel width={8}>Description</InlineFormLabel>
                <Input value={scene.description} onChange={(event) => ondescriptionChange(event, index)} />
              </div>
              <div className="gf-form max-width-30">
                <InlineFormLabel
                  tooltip="Increment in x will move the object in your right direction, decrement will move it to the left. To pass the current co-ordinates use reload button."
                  width={8}
                >
                  X Position
                </InlineFormLabel>
                <Input value={scene.anchorPosition.x} onChange={(event) => onXPosChange(event, index)} />
                <Button onClick={() => loadCurrLoc(index)} variant="secondary">
                  <i className="fa fa-refresh" aria-hidden="true"></i>
                </Button>
              </div>
              <div className="gf-form max-width-30">
                <InlineFormLabel
                  tooltip="Increment in y will move the object upwards, decrement will move it downwards. To pass the current co-ordinates use reload button."
                  width={8}
                >
                  Y Position
                </InlineFormLabel>
                <Input value={scene.anchorPosition.y} onChange={(event) => onYPosChange(event, index)} />
              </div>
              <div className="gf-form max-width-30">
                <InlineFormLabel
                  tooltip="Increment in z will move the object forward, decrement will move it backwards. To pass the current co-ordinates use reload button."
                  width={8}
                >
                  Z Position
                </InlineFormLabel>
                <Input value={scene.anchorPosition.z} onChange={(event) => onZPosChange(event, index)} />
              </div>
              <div className="gf-form max-width-30">
                <InlineFormLabel width={8}>X Stem Vector</InlineFormLabel>
                <Input value={scene.stemVector.x} onChange={(event) => onXRotChange(event, index)} />
              </div>
              <div className="gf-form max-width-30">
                <InlineFormLabel width={8}>Y Stem Vector</InlineFormLabel>
                <Input value={scene.stemVector.y} onChange={(event) => onYRotChange(event, index)} />
              </div>
              <div className="gf-form max-width-30">
                <InlineFormLabel width={8}>Z Stem Vector</InlineFormLabel>
                <Input value={scene.stemVector.z} onChange={(event) => onZRotChange(event, index)} />
              </div>
              <Button onClick={() => deleteLabel(index)} variant="destructive">
                <i className="fa fa-trash-o" aria-hidden="true"></i>
              </Button>
            </div>
          );
        })}
      <Button onClick={addNewLabel} variant="secondary">
        Add New
      </Button>
    </div>
  );
}
