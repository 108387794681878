import React, { useState } from 'react';
import { Collapse } from '@grafana/ui';
import { OSMapLayerState } from '../../types/types';
import { MarkerLink } from './MarkerLink';

/**
 * Interface representing custom props for expanding a marker.
 *
 * @interface PropsMarkerExpand
 * @property {Onemarker} marker - The marker to expand.
 * @property {number} Idx - The index of the marker.
 * @property {OSMapLayerState<any>} layer - The OSMapLayerState for the component.
 * @property {Map | undefined} map - The Map object, if available.
 * @property {any} queries - Queries associated with the marker.
 */
export interface PropsExpand {
  marker: any;
  Idx: number;
  layer: OSMapLayerState<any>;
  handleRemoveLink: (index: number) => void;
}

/**
 * Functional component for expanding a marker.
 *
 * @param {PropsExpand} props - The props for the MarkerExpand component.
 * @returns {React.ReactNode} The expanded marker component.
 */
export function MarkerExpandQuery(props: PropsExpand) {
  const { marker, Idx, layer, handleRemoveLink } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Collapse
      label={Idx + 1 + ' ' + '|' + ' ' + marker.location}
      isOpen={isOpen}
      onToggle={() => setIsOpen(!isOpen)}
      collapsible={true}
      overflowOffMap={true}
    >
      <MarkerLink marker={marker} Idx={Idx} layer={layer} handleRemoveLink={handleRemoveLink} />
    </Collapse>
  );
}
