import React, { useCallback } from 'react';
import { DataFrame, DisplayValue, fieldReducers, getFieldDisplayName, reduceField } from '@grafana/data';
import {
  AxisPlacement,
  GraphNGLegendEvent,
  UPlotConfigBuilder,
  VizLayout,
  VizLayoutLegendProps,
  VizLegend,
  VizLegendItem,
  VizLegendOptions,
} from '@grafana/ui';
import { mapMouseEventToMode } from './utils';
import { BarGraphMode, BarStackingMode } from '../types';

const defaultFormatter = (v: any) => (v == null ? '-' : v.toFixed(1));

/**
 * @typedef {Object} PlotLegendProps
 * @property {Array<DataFrame>} data
 * @property {UPlotConfigBuilder} config
 * @property {Array<any>} colorArray  color array
 * @property {stirng} noTime is bar graph related to time series
 * @property {BarStackingMode} stackingMode stacking mode
 * @property {Fucntion} [onSeriesColorChange]
 * @property {Function} [onLabelClick]
 * @extends VizLegendOptions
 * Omit<VizLayoutLegendProps, 'children'>
 */

interface PlotLegendProps extends VizLegendOptions, Omit<VizLayoutLegendProps, 'children'> {
  data: DataFrame[];
  config: UPlotConfigBuilder;
  noTime: string;
  colorArray: any[];
  stackingMode: BarStackingMode;
  onSeriesColorChange?: (label: string, color: string) => void;
  onLegendClick?: (event: GraphNGLegendEvent) => void;
}
/**
 * @function
 * @param {PlotLegendProps} props
 * @returns {JSX.Element}
 */
export const PlotLegendBarChart: React.FC<PlotLegendProps> = ({
  data,
  config,
  onSeriesColorChange,
  onLegendClick,
  placement,
  calcs,
  displayMode,
  noTime,
  colorArray,
  stackingMode,
  ...vizLayoutLegendProps
}) => {
  const onLegendLabelClick = useCallback(
    (legend: VizLegendItem, event: React.MouseEvent) => {
      const { fieldIndex } = legend;

      if (!onLegendClick || !fieldIndex) {
        return;
      }

      onLegendClick({
        fieldIndex,
        mode: mapMouseEventToMode(event),
      });
    },
    [onLegendClick]
  );
  const legendItems = config
    .getSeries()
    .map<VizLegendItem | undefined>((s) => {
      const seriesConfig = s.props;
      const fieldIndex = seriesConfig.dataFrameFieldIndex;
      const axisPlacement = config.getAxisPlacement(s.props.scaleKey);

      if (seriesConfig.hideInLegend || !fieldIndex) {
        return undefined;
      }

      const field = data[fieldIndex.frameIndex]?.fields[fieldIndex.fieldIndex];

      if (!field) {
        return undefined;
      }
      const label = getFieldDisplayName(field, data[fieldIndex.frameIndex]!);
      return {
        disabled: !seriesConfig.show ?? false,
        fieldIndex,
        color: seriesConfig.lineColor!,
        label,
        yAxis: axisPlacement === AxisPlacement.Left ? 1 : 2,
        getDisplayValues: () => {
          if (!calcs?.length) {
            return [];
          }

          const fmt = field.display ?? defaultFormatter;

          const fieldCalcs = reduceField({
            field,
            reducers: calcs,
          });

          return calcs.map<DisplayValue>((reducer) => {
            return {
              ...fmt(fieldCalcs[reducer]),
              suffix: stackingMode === BarStackingMode.Percent ? '' : fmt(fieldCalcs[reducer]).suffix,
              title: fieldReducers.get(reducer).name,
            };
          });
        },
        getItemKey: () => `${label}-${fieldIndex.frameIndex}-${fieldIndex.fieldIndex}`,
      };
    })
    .filter((i) => i !== undefined) as VizLegendItem[];

  let timeArray = [];

  for (let i = 0; i < data[0].fields[0].values.length; i++) {
    timeArray.push({
      color: colorArray[i],
      label: data[0].fields[0].values.get(i),
      yAxis: 1,
    });
  }
  const legendItems2 = timeArray;
  return (
    <VizLayout.Legend placement={placement} {...vizLayoutLegendProps}>
      <VizLegend
        onLabelClick={onLegendLabelClick}
        placement={placement}
        items={noTime === BarGraphMode.Timeseries ? legendItems : stackingMode !== 'none' ? legendItems : legendItems2}
        displayMode={displayMode}
        onSeriesColorChange={onSeriesColorChange}
      />
    </VizLayout.Legend>
  );
};

PlotLegendBarChart.displayName = 'PlotLegendBarChart';
