import { SingleStatBaseOptions, VizLegendOptions } from '@grafana/ui';
import {
  ReducerID,
  standardEditorsRegistry,
  FieldOverrideContext,
  getFieldDisplayName,
  escapeStringForRegex,
  PanelOptionsEditorBuilder,
  FieldDisplay,
  PanelProps,
} from '@grafana/data';
/**
 * @enum {string} PieChartType
 */
export enum PieChartType {
  Pie = 'pie',
  Donut = 'donut',
  Polar = 'polar',
  Radial = 'radial',
  WindRose = 'windrose',
}
/**
 * @enum {string} TooltipDisplayMode
 */
export enum TooltipDisplayMode {
  Single = 'single',
  Multi = 'multi',
  None = 'none',
}
/**
 * @enum {string}
 */
export enum PieChartLegendValues {
  Value = 'value',
  Percent = 'percent',
}
/**
 * @enum {string}
 */
export enum PieChartLabels {
  Name = 'name',
  Value = 'value',
  Percent = 'percent',
}
/**
 * @typedef {Object} PieChartLegendOptions
 * @property {Array<PieChartLegendValues>} values
 * @extends {VizLegendOptions}
 */
export interface PieChartLegendOptions extends VizLegendOptions {
  values: PieChartLegendValues[];
}
/**
 * @typedef {Object} VizTooltipOptions
 * @property {TooltipDisplayMode} mode
 */
export type VizTooltipOptions = {
  mode: TooltipDisplayMode;
};
/**
 * dashboard visit
 * @typedef {Object} VisitDashboard
 * @property {Visit[]} visitDashboard
 * @property {boolean} drawerLinkTime
 */
export interface VisitDashboard {
  visitDashboard: Visit[];
  drawerLinkTime: boolean;
}
/**
 * dashboard visit
 * @typedef {Object} Visit
 * @property {string} name
 * @property {string} url
 */
export interface Visit {
  queryName: string;
  dashboardUrl: string;
  dashboardName: string;
}
/**
 * pie chart different data
 * @typedef {Object} PieLayout
 * @property {number} position canvas pixel center
 * @property {number} size size of pie chart
 * @property {number} outerRadius
 * @property {number} innerRadius
 * @property {number} gradientFromOffset
 */
export interface PieLayout {
  position: number;
  size: number;
  outerRadius: number;
  innerRadius: number;
  gradientFromOffset: number;
}

/**
 * @typedef {Object} OptionsWithTooltip
 * @property {VizTooltipOptions} tooltip
 */
export interface OptionsWithTooltip {
  tooltip: VizTooltipOptions;
}
/**
 * pie chart options
 * @typedef {Object} PieChartOptions
 * @property {PieChartType} pieType pie, donut, polar, radial
 * @property {Array<PieChartLabels>} displayLabels labels on pie chart
 * @property {boolean} dashboardVisitMode on click visit dashboard
 * @property {Array<Visit>} dashboard visit array
 * @property {string} radialOptionsSelected choose between max total and custom
 * @property {number} radialInput adjust radial bar
 * @property {string} radialExtend extend radial graph to 360 or 270
 * @property {boolean} limitSwitch
 * @property {number} limitValue
 * @property {string} limitOrder
 * @property {boolean} limitRemaining
 *
 */
export interface PieChartOptions extends SingleStatBaseOptions, OptionsWithTooltip {
  pieType: PieChartType;
  displayLabels: PieChartLabels[];
  legend: PieChartLegendOptions;
  dashboardVisitMode: boolean;
  toVisitDashboard: VisitDashboard;
  radialOptionsSelected: string;
  radialInput: number;
  radialExtend: string;
  limitSwitch: boolean;
  limitValue: number;
  limitOrder: string;
  limitRemaining: boolean;
  windDir: string;
  windSpeed: string;
  colorPalette: string;
  legendGap: number;
}
/**
 * @typedef {Object} PiePanelProps
 * @property {PanelProps<PieChartOptions>} PanelProps panel props extends with box plot options
 */
export interface PiePanelProps extends PanelProps<PieChartOptions> {}

/**
 * @typedef {Object} PieChartProps
 * @property {Array<FieldDisplay>} values
 * @property {function} [onSeriesColorChange]
 * @property {PieChartOptions} restProps
 */
export interface PieChartProps {
  values: FieldDisplay[];
  onSeriesColorChange?: (label: string, color: string) => void;
  restProps: PieChartOptions;
}

export function addStandardDataReduceOptions<T extends SingleStatBaseOptions>(
  builder: PanelOptionsEditorBuilder<T>,
  includeFieldMatcher = true
) {
  builder.addRadio({
    path: 'reduceOptions.values',
    name: 'Show',
    description: 'Calculate a single value per column or series or show each row',
    settings: {
      options: [
        { value: false, label: 'Calculate' },
        { value: true, label: 'All values' },
      ],
    },
    defaultValue: false,
  });

  builder.addNumberInput({
    path: 'reduceOptions.limit',
    name: 'Limit',
    description: 'Max number of rows to display',
    settings: {
      placeholder: '25',
      integer: true,
      min: 1,
      max: 5000,
    },
    showIf: (options) => options.reduceOptions.values === true,
  });

  builder.addCustomEditor({
    id: 'reduceOptions.calcs',
    path: 'reduceOptions.calcs',
    name: 'Calculation',
    description: 'Choose a reducer function / calculation',
    editor: standardEditorsRegistry.get('stats-picker').editor as any,
    defaultValue: [ReducerID.lastNotNull],
    // Hides it when all values mode is on
    showIf: (currentConfig) => currentConfig.reduceOptions.values === false,
  });

  if (includeFieldMatcher) {
    builder.addSelect({
      path: 'reduceOptions.fields',
      name: 'Fields',
      description: 'Select the fields that should be included in the panel',
      settings: {
        allowCustomValue: true,
        options: [],
        getOptions: async (context: FieldOverrideContext) => {
          const options = [
            { value: '', label: 'Numeric Fields' },
            { value: '/.*/', label: 'All Fields' },
          ];
          if (context && context.data) {
            for (const frame of context.data) {
              for (const field of frame.fields) {
                const name = getFieldDisplayName(field, frame, context.data);
                const value = `/^${escapeStringForRegex(name)}$/`;
                options.push({ value, label: name });
              }
            }
          }
          return Promise.resolve(options);
        },
      },
      defaultValue: '',
    });
  }
}
