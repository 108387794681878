import { Field, FieldType } from '@grafana/data';
import { ComponentSize } from './size';
export type IconType = 'mono' | 'default';
export type IconSize = ComponentSize | 'xl' | 'xxl' | 'mxl' | 'mxxl' | 'xxxl';

export const getAvailableIcons = () =>
  [
    '2fa',
    '3d-cube-sphere',
    '3d-rotate',
    'a-b-off',
    'a-b',
    'abacus',
    'access-point-off',
    'access-point',
    'accessible-off',
    'accessible',
    'activity-heartbeat',
    'activity',
    'ad-2',
    'ad',
    'address-book',
    'adjustments-alt',
    'adjustments-horizontal',
    'adjustments-off',
    'adjustments',
    'aerial-lift',
    'affiliate',
    'alarm-off',
    'alarm',
    'album',
    'alert-circle',
    'alert-octagon',
    'alert-triangle',
    'alien',
    'align-center',
    'align-justified',
    'align-left',
    'align-right',
    'ambulance',
    'anchor',
    'angle',
    'antenna-bars-1',
    'antenna-bars-2',
    'antenna-bars-3',
    'antenna-bars-4',
    'antenna-bars-5',
    'antenna-bars-off',
    'antenna',
    'aperture',
    'api-app-off',
    'api-app',
    'api',
    'app-window',
    'apple',
    'apps-off',
    'apps',
    'archive-off',
    'archive',
    'armchair-2',
    'armchair',
    'arrow-autofit-content',
    'arrow-autofit-down',
    'arrow-autofit-height',
    'arrow-autofit-left',
    'arrow-autofit-right',
    'arrow-autofit-up',
    'arrow-autofit-width',
    'arrow-back-up',
    'arrow-back',
    'arrow-bar-down',
    'arrow-bar-left',
    'arrow-bar-right',
    'arrow-bar-to-down',
    'arrow-bar-to-left',
    'arrow-bar-to-right',
    'arrow-bar-to-up',
    'arrow-bar-up',
    'arrow-bear-left-2',
    'arrow-bear-left',
    'arrow-bear-right-2',
    'arrow-bear-right',
    'arrow-big-down-line',
    'arrow-big-down-lines',
    'arrow-big-down',
    'arrow-big-left-line',
    'arrow-big-left-lines',
    'arrow-big-left',
    'arrow-big-right-line',
    'arrow-big-right-lines',
    'arrow-big-right',
    'arrow-big-top',
    'arrow-big-up-line',
    'arrow-big-up-lines',
    'arrow-bottom-bar',
    'arrow-bottom-circle',
    'arrow-bottom-square',
    'arrow-bottom-tail',
    'arrow-curve-left',
    'arrow-curve-right',
    'arrow-down-circle',
    'arrow-down-left-circle',
    'arrow-down-left',
    'arrow-down-right-circle',
    'arrow-down-right',
    'arrow-down',
    'arrow-fork',
    'arrow-forward-up',
    'arrow-forward',
    'arrow-left-bar',
    'arrow-left-circle',
    'arrow-left-right',
    'arrow-left-square',
    'arrow-left-tail',
    'arrow-left',
    'arrow-loop-left-2',
    'arrow-loop-left',
    'arrow-loop-right-2',
    'arrow-loop-right',
    'arrow-merge',
    'arrow-narrow-down',
    'arrow-narrow-left',
    'arrow-narrow-right',
    'arrow-narrow-up',
    'arrow-ramp-left-2',
    'arrow-ramp-left-3',
    'arrow-ramp-left',
    'arrow-ramp-right-2',
    'arrow-ramp-right-3',
    'arrow-ramp-right',
    'arrow-right-bar',
    'arrow-right-circle',
    'arrow-right-square',
    'arrow-right-tail',
    'arrow-right',
    'arrow-rotary-first-left',
    'arrow-rotary-first-right',
    'arrow-rotary-last-left',
    'arrow-rotary-last-right',
    'arrow-rotary-left',
    'arrow-rotary-right',
    'arrow-rotary-straight',
    'arrow-sharp-turn-left',
    'arrow-sharp-turn-right',
    'arrow-top-bar',
    'arrow-top-circle',
    'arrow-top-square',
    'arrow-top-tail',
    'arrow-up-circle',
    'arrow-up-left-circle',
    'arrow-up-left',
    'arrow-up-right-circle',
    'arrow-up-right',
    'arrow-up',
    'arrow-wave-left-down',
    'arrow-wave-left-up',
    'arrow-wave-right-down',
    'arrow-wave-right-up',
    'arrows-cross',
    'arrows-diagonal-2',
    'arrows-diagonal-minimize-2',
    'arrows-diagonal-minimize',
    'arrows-diagonal',
    'arrows-double-ne-sw',
    'arrows-double-nw-se',
    'arrows-double-se-nw',
    'arrows-double-sw-ne',
    'arrows-down-up',
    'arrows-down',
    'arrows-horizontal',
    'arrows-join-2',
    'arrows-join',
    'arrows-left-down',
    'arrows-left-right',
    'arrows-left',
    'arrows-maximize',
    'arrows-minimize',
    'arrows-random',
    'arrows-right-down',
    'arrows-right-left',
    'arrows-right',
    'arrows-shuffle-2',
    'arrows-shuffle',
    'arrows-sort',
    'arrows-split-2',
    'arrows-split',
    'arrows-up-down',
    'arrows-up-left',
    'arrows-up-right',
    'arrows-up',
    'arrows-vertical',
    'artboard-off',
    'artboard',
    'aspect-ratio-off',
    'aspect-ratio',
    'asterisk-simple',
    'asterisk',
    'at-off',
    'at',
    'atom-2',
    'atom',
    'augmented-reality',
    'award',
    'axe',
    'axis-x',
    'axis-y',
    'baby-carriage',
    'backhoe',
    'backpack',
    'backspace',
    'badge',
    'badges',
    'ball-american-football',
    'ball-baseball',
    'ball-basketball',
    'ball-bowling',
    'ball-football-off',
    'ball-football',
    'ball-tennis',
    'ball-volleyball',
    'ballon',
    'ballpen-off',
    'ballpen',
    'ban',
    'bandage',
    'barbell-off',
    'barbell',
    'barcode-off',
    'barcode',
    'barrel',
    'barrier-block-off',
    'barrier-block',
    'baseline',
    'basket-off',
    'basket',
    'bath',
    'battery-1',
    'battery-2',
    'battery-3',
    'battery-4',
    'battery-automotive',
    'battery-charging-2',
    'battery-charging',
    'battery-eco',
    'battery-off',
    'battery',
    'beach-off',
    'beach',
    'bed',
    'beer',
    'bell-minus',
    'bell-off',
    'bell-plus',
    'bell-ringing-2',
    'bell-ringing',
    'bell-school',
    'bell-x',
    'bell-z',
    'bell',
    'bible',
    'bike-off',
    'bike',
    'binary',
    'biohazard-off',
    'biohazard',
    'blockquote',
    'bluetooth-connected',
    'bluetooth-off',
    'bluetooth-x',
    'bluetooth',
    'blur',
    'bold-off',
    'bold',
    'bolt-off',
    'bolt',
    'bone-off',
    'bone',
    'book-2',
    'book-download',
    'book-off',
    'book-upload',
    'book',
    'bookmark-off',
    'bookmark',
    'bookmarks-off',
    'bookmarks',
    'books-off',
    'books',
    'border-all',
    'border-bottom',
    'border-horizontal',
    'border-inner',
    'border-left',
    'border-none',
    'border-outer',
    'border-radius',
    'border-right',
    'border-style-2',
    'border-style',
    'border-top',
    'border-vertical',
    'bottle',
    'bow',
    'box-margin',
    'box-model-2',
    'box-model',
    'box-multiple-0',
    'box-multiple-1',
    'box-multiple-2',
    'box-multiple-3',
    'box-multiple-4',
    'box-multiple-5',
    'box-multiple-6',
    'box-multiple-7',
    'box-multiple-8',
    'box-multiple-9',
    'box-multiple',
    'box-padding',
    'box',
    'braces-off',
    'braces',
    'brackets-off',
    'brackets',
    'brand-adobe',
    'brand-airbnb',
    'brand-airtable',
    'brand-android',
    'brand-angular',
    'brand-apple-arcade',
    'brand-apple',
    'brand-appstore',
    'brand-asana',
    'brand-behance',
    'brand-bing',
    'brand-bitbucket',
    'brand-booking',
    'brand-bootstrap',
    'brand-chrome',
    'brand-codepen',
    'brand-codesandbox',
    'brand-css3',
    'brand-cucumber',
    'brand-debian',
    'brand-deviantart',
    'brand-discord',
    'brand-disqus',
    'brand-docker',
    'brand-doctrine',
    'brand-dribbble',
    'brand-edge',
    'brand-facebook',
    'brand-figma',
    'brand-firebase',
    'brand-firefox',
    'brand-flickr',
    'brand-foursquare',
    'brand-framer',
    'brand-git',
    'brand-github',
    'brand-gitlab',
    'brand-gmail',
    'brand-google-analytics',
    'brand-google-drive',
    'brand-google-play',
    'brand-google',
    'brand-gravatar',
    'brand-hipchat',
    'brand-html5',
    'brand-instagram',
    'brand-javascript',
    'brand-kickstarter',
    'brand-kotlin',
    'brand-lastfm',
    'brand-linkedin',
    'brand-loom',
    'brand-mastercard',
    'brand-medium',
    'brand-mercedes',
    'brand-messenger',
    'brand-meta',
    'brand-netbeans',
    'brand-netflix',
    'brand-nextjs',
    'brand-notion',
    'brand-nuxt',
    'brand-nytimes',
    'brand-open-source',
    'brand-opera',
    'brand-pagekit',
    'brand-patreon',
    'brand-paypal',
    'brand-php',
    'brand-pinterest',
    'brand-pocket',
    'brand-producthunt',
    'brand-python',
    'brand-react-native',
    'brand-reddit',
    'brand-safari',
    'brand-sass',
    'brand-sentry',
    'brand-shazam',
    'brand-sketch',
    'brand-skype',
    'brand-slack',
    'brand-snapchat',
    'brand-soundcloud',
    'brand-spotify',
    'brand-stackoverflow',
    'brand-steam',
    'brand-stripe',
    'brand-sublime-text',
    'brand-svelte',
    'brand-tabler',
    'brand-tailwind',
    'brand-telegram',
    'brand-tidal',
    'brand-tiktok',
    'brand-tinder',
    'brand-tripadvisor',
    'brand-tumblr',
    'brand-twitch',
    'brand-twitter',
    'brand-uber',
    'brand-ubuntu',
    'brand-unsplash',
    'brand-vercel',
    'brand-vimeo',
    'brand-visual-studio',
    'brand-vk',
    'brand-vue',
    'brand-whatsapp',
    'brand-windows',
    'brand-yahoo',
    'brand-ycombinator',
    'brand-youtube',
    'bread',
    'briefcase',
    'brightness-2',
    'brightness-down',
    'brightness-half',
    'brightness-up',
    'brightness',
    'browser-check',
    'browser-off',
    'browser-plus',
    'browser-x',
    'browser',
    'brush-off',
    'brush',
    'bucket',
    'bug-off',
    'bug',
    'building-arch',
    'building-bank',
    'building-bridge-2',
    'building-bridge',
    'building-carousel',
    'building-castle',
    'building-church',
    'building-community',
    'building-cottage',
    'building-factory-2',
    'building-factory',
    'building-fortress',
    'building-hospital',
    'building-lighthouse',
    'building-monument',
    'building-pavilon',
    'building-skyscraper',
    'building-store',
    'building-warehouse',
    'building',
    'bulb-off',
    'bulb',
    'bulldozer',
    'bus',
    'businessplan',
    'butterfly',
    'c-sharp',
    'cake',
    'calculator-off',
    'calculator',
    'calendar-event',
    'calendar-minus',
    'calendar-off',
    'calendar-plus',
    'calendar-stats',
    'calendar-time',
    'calendar',
    'camera-minus',
    'camera-off',
    'camera-plus',
    'camera-rotate',
    'camera-selfie',
    'camera',
    'candle',
    'candy-off',
    'candy',
    'capture-off',
    'capture',
    'car-crane',
    'car-crash',
    'car-off',
    'car',
    'caravan',
    'cardboards-off',
    'cardboards',
    'caret-down',
    'caret-left',
    'caret-right',
    'caret-up',
    'cash-banknote-off',
    'cash-banknote',
    'cash',
    'cast-off',
    'cast',
    'ce-off',
    'ce',
    'cell-signal-1',
    'cell-signal-2',
    'cell-signal-3',
    'cell-signal-4',
    'cell-signal-5',
    'cell-signal-off',
    'cell',
    'certificate-2-off',
    'certificate-2',
    'certificate-off',
    'certificate',
    'charging-pile',
    'chart-arcs-3',
    'chart-arcs',
    'chart-area-line',
    'chart-area',
    'chart-arrows-vertical',
    'chart-arrows',
    'chart-bar',
    'chart-bubble',
    'chart-candle',
    'chart-circles',
    'chart-donut-2',
    'chart-donut-3',
    'chart-donut-4',
    'chart-donut',
    'chart-dots-2',
    'chart-dots-3',
    'chart-dots',
    'chart-infographic',
    'chart-line',
    'chart-pie-2',
    'chart-pie-3',
    'chart-pie-4',
    'chart-pie',
    'chart-radar',
    'check',
    'checkbox',
    'checklist',
    'checks',
    'checkup-list',
    'cheese',
    'chevron-down-left',
    'chevron-down-right',
    'chevron-down',
    'chevron-left',
    'chevron-right',
    'chevron-up-left',
    'chevron-up-right',
    'chevron-up',
    'chevrons-down-left',
    'chevrons-down-right',
    'chevrons-down',
    'chevrons-left',
    'chevrons-right',
    'chevrons-up-left',
    'chevrons-up-right',
    'chevrons-up',
    'christmas-tree',
    'circle-0',
    'circle-1',
    'circle-2',
    'circle-3',
    'circle-4',
    'circle-5',
    'circle-6',
    'circle-7',
    'circle-8',
    'circle-9',
    'circle-check',
    'circle-dashed',
    'circle-dot',
    'circle-dotted',
    'circle-half-2',
    'circle-half-vertical',
    'circle-half',
    'circle-minus',
    'circle-off',
    'circle-plus',
    'circle-rectangle-off',
    'circle-rectangle',
    'circle-square',
    'circle-triangle',
    'circle-x',
    'circle',
    'circles',
    'clear-all',
    'clear-formatting',
    'click',
    'clipboard-check',
    'clipboard-list',
    'clipboard-off',
    'clipboard-plus',
    'clipboard-text',
    'clipboard-x',
    'clipboard',
    'clock-2',
    'clock-off',
    'clock',
    'cloud-download',
    'cloud-fog',
    'cloud-lock-open',
    'cloud-lock',
    'cloud-off',
    'cloud-rain',
    'cloud-snow',
    'cloud-storm',
    'cloud-upload',
    'cloud',
    'clubs',
    'code-minus',
    'code-off',
    'code-plus',
    'code',
    'coffee',
    'coin-off',
    'coin',
    'color-picker-off',
    'color-picker',
    'color-swatch-off',
    'color-swatch',
    'column-insert-left',
    'column-insert-right',
    'columns-off',
    'columns',
    'comet',
    'command',
    'compass-off',
    'compass',
    'components-off',
    'components',
    'cone-2',
    'cone',
    'confetti',
    'container',
    'contrast-2',
    'contrast',
    'cookie-off',
    'cookie',
    'copy-off',
    'copy',
    'copyleft-off',
    'copyleft',
    'copyright-off',
    'copyright',
    'corner-down-left-double',
    'corner-down-left',
    'corner-down-right-double',
    'corner-down-right',
    'corner-left-down-double',
    'corner-left-down',
    'corner-left-up-double',
    'corner-left-up',
    'corner-right-down-double',
    'corner-right-down',
    'corner-right-up-double',
    'corner-right-up',
    'corner-up-left-double',
    'corner-up-left',
    'corner-up-right-double',
    'corner-up-right',
    'cpu-2',
    'cpu',
    'crane',
    'creative-commons',
    'credit-card-off',
    'credit-card',
    'cricket',
    'crop',
    'cross',
    'crosshair',
    'crown-off',
    'crown',
    'crutches',
    'cup',
    'curling',
    'curly-loop',
    'currency-bahraini',
    'currency-baht',
    'currency-bitcoin',
    'currency-cent',
    'currency-dinar',
    'currency-dirham',
    'currency-dogecoin',
    'currency-dollar-australian',
    'currency-dollar-canadian',
    'currency-dollar-singapore',
    'currency-dollar',
    'currency-ethereum',
    'currency-euro',
    'currency-forint',
    'currency-frank',
    'currency-krone-czech',
    'currency-krone-danish',
    'currency-krone-swedish',
    'currency-leu',
    'currency-lira',
    'currency-litecoin',
    'currency-naira',
    'currency-pound',
    'currency-real',
    'currency-renminbi',
    'currency-ripple',
    'currency-riyal',
    'currency-rubel',
    'currency-rupee',
    'currency-shekel',
    'currency-taka',
    'currency-tugrik',
    'currency-won',
    'currency-yen',
    'currency-zloty',
    'currency',
    'current-location',
    'cursor-text',
    'cut',
    'dashboard',
    'database-export',
    'database-import',
    'database-off',
    'database',
    'dental',
    'details',
    'device-analytics',
    'device-audio-tape',
    'device-cctv',
    'device-computer-camera-off',
    'device-computer-camera',
    'device-desktop-analytics',
    'device-desktop-off',
    'device-desktop',
    'device-floppy',
    'device-gamepad',
    'device-heart-monitor',
    'device-laptop-off',
    'device-laptop',
    'device-mobile-message',
    'device-mobile-off',
    'device-mobile-rotated',
    'device-mobile-vibration',
    'device-mobile',
    'device-nintendo',
    'device-speaker',
    'device-tablet-off',
    'device-tablet',
    'device-tv-off',
    'device-tv',
    'device-watch-off',
    'device-watch-stats-2',
    'device-watch-stats',
    'device-watch',
    'devices-2',
    'devices-off',
    'devices-pc',
    'devices',
    'dialpad',
    'diamond',
    'diamonds',
    'dice-1',
    'dice-2',
    'dice-3',
    'dice-4',
    'dice-5',
    'dice-6',
    'dice',
    'dimensions',
    'direction-horizontal',
    'direction',
    'directions',
    'disabled-2',
    'disabled',
    'disc',
    'discount-2',
    'discount',
    'divide',
    'dna-2',
    'dna',
    'dog-bowl',
    'door-enter',
    'door-exit',
    'door',
    'dots-circle-horizontal',
    'dots-diagonal-2',
    'dots-diagonal',
    'dots-vertical',
    'dots',
    'download',
    'drag-drop-2',
    'drag-drop',
    'drone-off',
    'drone',
    'drop-circle',
    'droplet-filled-2',
    'droplet-filled',
    'droplet-half-2',
    'droplet-half',
    'droplet-off',
    'droplet',
    'ear-off',
    'ear',
    'edit-circle',
    'edit',
    'egg',
    'elevator',
    'emergency-bed',
    'emphasis',
    'engine',
    'equal-not',
    'equal',
    'eraser',
    'error-404',
    'exchange',
    'exclamation-mark',
    'exposure',
    'external-link',
    'eye-check',
    'eye-off',
    'eye-table',
    'eye',
    'eyeglass-2',
    'eyeglass',
    'face-id-error',
    'face-id',
    'face-mask',
    'fall',
    'feather',
    'fence',
    'fidget-spinner',
    'file-3d',
    'file-alert',
    'file-analytics',
    'file-arrow-left',
    'file-arrow-right',
    'file-barcode',
    'file-certificate',
    'file-chart',
    'file-check',
    'file-code-2',
    'file-code',
    'file-database',
    'file-description',
    'file-diff',
    'file-digit',
    'file-dislike',
    'file-dollar',
    'file-dots',
    'file-download',
    'file-euro',
    'file-export',
    'file-horizontal',
    'file-import',
    'file-info',
    'file-invoice',
    'file-like',
    'file-minus',
    'file-music',
    'file-off',
    'file-pencil',
    'file-phone',
    'file-plus',
    'file-power',
    'file-report',
    'file-rss',
    'file-scissors',
    'file-search',
    'file-settings',
    'file-shredder',
    'file-signal',
    'file-spreadsheet',
    'file-star',
    'file-symlink',
    'file-text',
    'file-time',
    'file-typography',
    'file-unknown',
    'file-upload',
    'file-vector',
    'file-x',
    'file-zip',
    'file',
    'files-off',
    'files',
    'filter-off',
    'filter',
    'fingerprint',
    'firetruck',
    'first-aid-kit',
    'fish',
    'flag-2',
    'flag-3',
    'flag',
    'flame',
    'flare',
    'flask-2',
    'flask',
    'flip-horizontal',
    'flip-vertical',
    'float-center',
    'float-left',
    'float-none',
    'float-right',
    'flower',
    'focus-2',
    'focus-centered',
    'focus',
    'fold-down',
    'fold-up',
    'fold',
    'folder-minus',
    'folder-off',
    'folder-plus',
    'folder-x',
    'folder',
    'folders',
    'forbid-2',
    'forbid',
    'forklift',
    'forms',
    'fountain',
    'frame',
    'free-rights',
    'friends',
    'gas-station',
    'gauge',
    'gavel',
    'gender-agender',
    'gender-androgyne',
    'gender-bigender',
    'gender-demiboy',
    'gender-demigirl',
    'gender-epicene',
    'gender-female',
    'gender-femme',
    'gender-genderfluid',
    'gender-genderless',
    'gender-genderqueer',
    'gender-hermaphrodite',
    'gender-intergender',
    'gender-male',
    'gender-neutrois',
    'gender-third',
    'gender-transgender',
    'gender-trasvesti',
    'geometry',
    'ghost',
    'gift',
    'git-branch',
    'git-commit',
    'git-compare',
    'git-fork',
    'git-merge',
    'git-pull-request-closed',
    'git-pull-request-draft',
    'git-pull-request',
    'gizmo',
    'glass-full',
    'glass-off',
    'glass',
    'globe',
    'golf',
    'gps',
    'grain',
    'grid-dots',
    'grid-pattern',
    'grill',
    'grip-horizontal',
    'grip-vertical',
    'growth',
    'h-1',
    'h-2',
    'h-3',
    'h-4',
    'h-5',
    'h-6',
    'hammer',
    'hand-click',
    'hand-finger',
    'hand-grab',
    'hand-little-finger',
    'hand-middle-finger',
    'hand-move',
    'hand-off',
    'hand-ring-finger',
    'hand-rock',
    'hand-stop',
    'hand-three-fingers',
    'hand-two-fingers',
    'hanger-2',
    'hanger',
    'hash',
    'haze',
    'heading',
    'headphones-off',
    'headphones',
    'headset',
    'heart-broken',
    'heart-handshake',
    'heart-rate-monitor',
    'heart',
    'heartbeat',
    'helicopter-landing',
    'helicopter',
    'helmet',
    'help',
    'hexagon-off',
    'hexagon',
    'hexagons',
    'hierarchy-2',
    'hierarchy',
    'highlight',
    'history',
    'home-2',
    'home',
    'hotel-service',
    'hourglass-high',
    'hourglass-low',
    'hourglass',
    'ice-cream-2',
    'ice-cream',
    'ice-skating',
    'id-badge-2',
    'id-badge',
    'id',
    'inbox',
    'indent-decrease',
    'indent-increase',
    'infinity',
    'info-circle',
    'info-square',
    'italic',
    'jump-rope',
    'karate',
    'kering',
    'key',
    'keyboard-hide',
    'keyboard-off',
    'keyboard-show',
    'keyboard',
    'ladder',
    'lamp-2',
    'lamp',
    'language-hiragana',
    'language-katakana',
    'language',
    'lasso',
    'layers-difference',
    'layers-intersect-2',
    'layers-intersect',
    'layers-linked',
    'layers-subtract',
    'layers-union',
    'layout-2',
    'layout-align-bottom',
    'layout-align-center',
    'layout-align-left',
    'layout-align-middle',
    'layout-align-right',
    'layout-align-top',
    'layout-board-split',
    'layout-board',
    'layout-bottombar',
    'layout-cards',
    'layout-columns',
    'layout-dashboard',
    'layout-distribute-horizontal',
    'layout-distribute-vertical',
    'layout-grid-add',
    'layout-grid',
    'layout-kanban',
    'layout-list',
    'layout-navbar',
    'layout-rows',
    'layout-sidebar-left-collapse',
    'layout-sidebar-left-expand',
    'layout-sidebar-right-collapse',
    'layout-sidebar-right-expand',
    'layout-sidebar-right',
    'layout-sidebar',
    'layout',
    'leaf',
    'lego',
    'lemon-2',
    'lemon',
    'letter-a',
    'letter-b',
    'letter-c',
    'letter-case-lower',
    'letter-case-toggle',
    'letter-case-upper',
    'letter-case',
    'letter-d',
    'letter-e',
    'letter-f',
    'letter-g',
    'letter-h',
    'letter-i',
    'letter-j',
    'letter-k',
    'letter-l',
    'letter-m',
    'letter-n',
    'letter-o',
    'letter-p',
    'letter-q',
    'letter-r',
    'letter-s',
    'letter-spacing',
    'letter-t',
    'letter-u',
    'letter-v',
    'letter-w',
    'letter-x',
    'letter-y',
    'letter-z',
    'license',
    'lifebuoy',
    'line-dashed',
    'line-dotted',
    'line-height',
    'line',
    'link',
    'list-check',
    'list-details',
    'list-numbers',
    'list-search',
    'list',
    'live-photo',
    'live-view',
    'loader-quarter',
    'loader',
    'location',
    'lock-access',
    'lock-off',
    'lock-open',
    'lock-square',
    'lock',
    'login',
    'logout',
    'lollipop',
    'luggage',
    'lungs',
    'macro',
    'magnet',
    'mail-fast',
    'mail-forward',
    'mail-opened',
    'mail',
    'mailbox',
    'man',
    'manual-gearbox',
    'map-2',
    'map-pin-off',
    'map-pin',
    'map-pins',
    'map-search',
    'map',
    'markdown',
    'marquee-2',
    'marquee',
    'mars',
    'mask-off',
    'mask',
    'massage',
    'math-avg',
    'math-function',
    'math-max',
    'math-min',
    'math-symbols',
    'math',
    'maximize',
    'meat',
    'medal-2',
    'medal',
    'medical-cross',
    'medicine-syrup',
    'menu-2',
    'menu',
    'message-2-code',
    'message-2-share',
    'message-2',
    'message-circle-2',
    'message-circle-off',
    'message-circle',
    'message-code',
    'message-dots',
    'message-language',
    'message-off',
    'message-plus',
    'message-report',
    'message-share',
    'message',
    'messages-off',
    'messages',
    'microphone-2',
    'microphone-off',
    'microphone',
    'microscope',
    'military-award',
    'military-rank',
    'milk',
    'minimize',
    'minus-vertical',
    'minus',
    'mist',
    'mood-boy',
    'mood-confuzed',
    'mood-crazy-happy',
    'mood-cry',
    'mood-empty',
    'mood-happy',
    'mood-kid',
    'mood-nervous',
    'mood-neutral',
    'mood-sad',
    'mood-smile',
    'mood-suprised',
    'mood-tongue',
    'moon-2',
    'moon-stars',
    'moon',
    'moped',
    'motorbike',
    'mountain',
    'mouse',
    'movie',
    'mug',
    'multiplier-0-5x',
    'multiplier-1-5x',
    'multiplier-1x',
    'multiplier-2x',
    'mushroom',
    'music',
    'network',
    'new-section',
    'news',
    'nfc',
    'no-copyright',
    'no-creative-commons',
    'no-derivatives',
    'north-star',
    'note',
    'notebook',
    'notes',
    'notification',
    'number-0',
    'number-1',
    'number-2',
    'number-3',
    'number-4',
    'number-5',
    'number-6',
    'number-7',
    'number-8',
    'number-9',
    'numbers',
    'nurse',
    'octagon-off',
    'octagon',
    'old',
    'olympics',
    'omega',
    'outlet',
    'oval-vertical',
    'oval',
    'overline',
    'package',
    'packge-export',
    'packge-import',
    'pacman',
    'page-break',
    'paint',
    'palette',
    'panorama-horizontal',
    'panorama-vertical',
    'paper-bag',
    'paperclip',
    'parachute',
    'parentheses',
    'parking',
    'paw',
    'peace',
    'pencil',
    'pennant-2',
    'pennant',
    'pentagon',
    'pepper',
    'percentage',
    'perspective',
    'phone-call',
    'phone-calling',
    'phone-check',
    'phone-incoming',
    'phone-off',
    'phone-outgoing',
    'phone-pause',
    'phone-plus',
    'phone-x',
    'phone',
    'photo-off',
    'photo',
    'physotherapist',
    'picture-in-picture-off',
    'picture-in-picture-on',
    'picture-in-picture-top',
    'picture-in-picture',
    'pig',
    'pill',
    'pills',
    'pin',
    'pinned-off',
    'pinned',
    'pizza',
    'plane-arrival',
    'plane-departure',
    'plane-inflight',
    'plane',
    'planet',
    'plant-2',
    'plant',
    'play-card',
    'player-eject',
    'player-pause',
    'player-play',
    'player-record',
    'player-skip-back',
    'player-skip-forward',
    'player-stop',
    'player-track-next',
    'player-track-prev',
    'playlist-add',
    'playlist-x',
    'playlist',
    'plug-connected-x',
    'plug-connected',
    'plug-x',
    'plug',
    'plus',
    'point',
    'pokeball',
    'polaroid',
    'polygon',
    'pool',
    'power',
    'pray',
    'premium-rights',
    'prescription',
    'presentation-analytics',
    'presentation',
    'printer',
    'prison',
    'prompt',
    'propeller',
    'puzzle-2',
    'puzzle',
    'pyramid',
    'qrcode',
    'question-mark',
    'quote',
    'radar-2',
    'radar',
    'radio',
    'radioactive',
    'radius-bottom-left',
    'radius-bottom-right',
    'radius-top-left',
    'radius-top-right',
    'rainbow',
    'receipt-2',
    'receipt-off',
    'receipt-refund',
    'receipt-tax',
    'receipt',
    'recharging',
    'record-mail',
    'rectangle-vertical',
    'rectangle',
    'recycle',
    'refresh-alert',
    'refresh-dot',
    'refresh',
    'registered',
    'relation-many-to-many',
    'relation-one-to-many',
    'relation-one-to-one',
    'repeat-once',
    'repeat',
    'replace',
    'report-analytics',
    'report-medical',
    'report-money',
    'report-search',
    'report',
    'resize',
    'ripple',
    'road-sign',
    'road',
    'robot',
    'rocket',
    'roller-skating',
    'rollercoaster',
    'rotate-2',
    'rotate-360',
    'rotate-clockwise-2',
    'rotate-clockwise',
    'rotate-dot',
    'rotate-rectangle',
    'rotate',
    'route',
    'router',
    'row-insert-bottom',
    'row-insert-top',
    'rss',
    'ruler-2',
    'ruler',
    'run',
    'sailboat',
    'salt',
    'satellite',
    'sausage',
    'scale-outline',
    'scale',
    'scan',
    'school',
    'scissors',
    'scooter-electric',
    'scooter',
    'screen-share-off',
    'screen-share',
    'scribble',
    'scuba-mask',
    'search',
    'section-sign',
    'section',
    'seeding',
    'select',
    'selector',
    'send',
    'separator-horizontal',
    'separator-vertical',
    'separator',
    'server-2',
    'server',
    'servicemark',
    'settings-automation',
    'settings',
    'shadow-off',
    'shadow',
    'shape-2',
    'shape-3',
    'shape',
    'share',
    'shield-check',
    'shield-checkered',
    'shield-chevron',
    'shield-lock',
    'shield-off',
    'shield-x',
    'shield',
    'ship',
    'shirt',
    'shoe',
    'shopping-cart-discount',
    'shopping-cart-off',
    'shopping-cart-plus',
    'shopping-cart-x',
    'shopping-cart',
    'shredder',
    'sign-left',
    'sign-right',
    'signature',
    'sitemap',
    'skateboard',
    'sleigh',
    'slice',
    'slideshow',
    'smart-home',
    'smoking-no',
    'smoking',
    'snowflake',
    'soccer-field',
    'social',
    'sock',
    'sofa',
    'sort-ascending-2',
    'sort-ascending-letters',
    'sort-ascending-numbers',
    'sort-ascending',
    'sort-descending-2',
    'sort-descending-letters',
    'sort-descending-numbers',
    'sort-descending',
    'soup',
    'space',
    'spacing-horizontal',
    'spacing-vertical',
    'spade',
    'speakerphone',
    'speedboat',
    'sport-billard',
    'square-0',
    'square-1',
    'square-2',
    'square-3',
    'square-4',
    'square-5',
    'square-6',
    'square-7',
    'square-8',
    'square-9',
    'square-asterisk',
    'square-check',
    'square-dot',
    'square-forbid-2',
    'square-forbid',
    'square-half',
    'square-minus',
    'square-off',
    'square-plus',
    'square-root-2',
    'square-root',
    'square-rotated-forbid-2',
    'square-rotated-forbid',
    'square-rotated-off',
    'square-rotated',
    'square-toggle-horizontal',
    'square-toggle',
    'square-x',
    'square',
    'squares-diagonal',
    'squares-filled',
    'stack-2',
    'stack-3',
    'stack',
    'stairs-down',
    'stairs-up',
    'stairs',
    'star-half',
    'star-off',
    'star',
    'stars',
    'steering-wheel',
    'step-into',
    'step-out',
    'stethoscope',
    'sticker',
    'strikethrough',
    'submarine',
    'subscript',
    'subtask',
    'sum',
    'sun-off',
    'sun',
    'sunrise',
    'sunset',
    'superscript',
    'swimming',
    'switch-2',
    'switch-3',
    'switch-horizontal',
    'switch-vertical',
    'switch',
    'sword',
    'table-export',
    'table-import',
    'table-off',
    'table',
    'tag-off',
    'tag',
    'tags-off',
    'tags',
    'tallymark-1',
    'tallymark-2',
    'tallymark-3',
    'tallymark-4',
    'tallymarks',
    'tank',
    'target',
    'telescope',
    'temperature-celsius',
    'temperature-fahrenheit',
    'temperature-minus',
    'temperature-plus',
    'temperature',
    'template',
    'tent',
    'terminal-2',
    'terminal',
    'test-pipe-2',
    'test-pipe',
    'text-direction-ltr',
    'text-direction-rtl',
    'text-resize',
    'text-wrap-disabled',
    'text-wrap',
    'thermometer',
    'thumb-down',
    'thumb-up',
    'ticket',
    'tie',
    'tilt-shift',
    'timeline',
    'tir',
    'toggle-left',
    'toggle-right',
    'toilet-paper',
    'tool',
    'tools-kitchen-2',
    'tools-kitchen',
    'tools',
    'tornado',
    'tournament',
    'track',
    'tractor',
    'trademark',
    'traffic-cone',
    'traffic-lights',
    'train',
    'transfer-in',
    'transfer-out',
    'trash-off',
    'trash-x',
    'trash',
    'tree',
    'trees',
    'trending-down-2',
    'trending-down-3',
    'trending-down',
    'trending-up-2',
    'trending-up-3',
    'trending-up',
    'triangle-inverted',
    'triangle-off',
    'triangle-square-circle',
    'triangle',
    'triangles',
    'trident',
    'trophy',
    'truck-delivery',
    'truck-off',
    'truck-return',
    'truck',
    'typography',
    'umbrella',
    'underline',
    'unlink',
    'upload',
    'urgent',
    'usb',
    'user-check',
    'user-circle',
    'user-exclamation',
    'user-minus',
    'user-off',
    'user-plus',
    'user-search',
    'user-x',
    'user',
    'users',
    'vaccine-bottle',
    'vaccine',
    'variable',
    'vector-beizer-2',
    'vector-bezier',
    'vector-triangle',
    'vector',
    'venus',
    'versions',
    'video-minus',
    'video-off',
    'video-plus',
    'video',
    'view-360',
    'viewfinder',
    'viewport-narrow',
    'viewport-wide',
    'vinyl',
    'virus-off',
    'virus-search',
    'virus',
    'vocabulary',
    'volume-2',
    'volume-3',
    'volume',
    'walk',
    'wall',
    'wallet',
    'wallpaper',
    'wand',
    'wave-saw-tool',
    'wave-sine',
    'wave-square',
    'webhook',
    'wifi-0',
    'wifi-1',
    'wifi-2',
    'wifi-off',
    'wifi',
    'wind',
    'windmill',
    'window',
    'windsock',
    'wiper-wash',
    'wiper',
    'woman',
    'world-download',
    'world-latitude',
    'world-longitude',
    'world-upload',
    'world',
    'wrecking-ball',
    'writing-sign',
    'writing',
    'x',
    'yin-yang',
    'yoga',
    'zodiac-aquarius',
    'zodiac-aries',
    'zodiac-cancer',
    'zodiac-capricorn',
    'zodiac-gemini',
    'zodiac-leo',
    'zodiac-libra',
    'zodiac-pisces',
    'zodiac-sagittarius',
    'zodiac-scorpio',
    'zodiac-taurus',
    'zodiac-virgo',
    'zoom-cancel',
    'zoom-check',
    'zoom-code',
    'zoom-exclamation',
    'zoom-in',
    'zoom-money',
    'zoom-out',
    'zoom-question',
  ] as const;

/** Get the icon for a given field type */
export function getFieldTypeIcon(field?: Field) {
  if (field) {
    switch (field.type) {
      case FieldType.time:
        return 'alarm';
      case FieldType.string:
        return 'font';
      case FieldType.number:
        return 'calculator';
      case FieldType.boolean:
        return 'toggle-on';
      case FieldType.trace:
        return 'info-circle';
      case FieldType.other:
        return 'brackets-curly';
    }
  }
  return 'question-mark';
}

export type AlwaysMonoIcons =
  | 'Dashboard'
  | 'grafana'
  | 'favorite'
  | 'heart-break'
  | 'heart'
  | 'panel-add'
  | 'reusable-panel'
  | 'wrangler';

type MonoIcons =
  | 'Apps'
  | 'Bell'
  | 'Circle'
  | 'Favorite'
  | 'Folder'
  | 'FolderPlus'
  | 'Grafana'
  | 'Heart'
  | 'HeartBreak'
  | 'Import'
  | 'PlusSquare'
  | 'ReusablePanel'
  | 'SquareShape'
  | 'Alert'
  | 'Dashboard'
  | 'Addfolder'
  | 'ImportDashboard'
  | 'Plus'
  | 'Home'
  | 'Manage'
  | 'Snapshots'
  | 'Explore'
  | 'AlertRules'
  | 'Channels'
  | 'Datasources'
  | 'User'
  | 'Team'
  | 'Preferences'
  | 'ApiKeys'
  | 'Cog'
  | 'Org'
  | 'Stats'
  | 'Shield'
  | 'Question'
  | 'Sync'
  | 'Search'
  | 'PanelAdd'
  | 'Save'
  | 'Playlist';

// | 'BellCircle'
// | 'BellCircleActive'
// | 'DatabaseCircle'
// | 'DatabaseCircleActive'
// | 'ProcessCircle'
// | 'ProcessCircleActive';

type CustomIcons =
  | 'gf-interpolation-linear'
  | 'gf-interpolation-smooth'
  | 'gf-interpolation-step-before'
  | 'gf-interpolation-step-after'
  | 'gf-logs'
  | 'gf-bar-alignment-after'
  | 'gf-bar-alignment-before'
  | 'gf-bar-alignment-center';

export type IconName = ReturnType<typeof getAvailableIcons>[number] | AlwaysMonoIcons | CustomIcons | MonoIcons;
