import React, { FC } from 'react';
import { TabContent, useStyles } from '@grafana/ui';
import { CompleteMarker } from '../interface';
import { TooltipContent } from './TooltipContent';
import { css } from 'emotion';
import { GrafanaTheme } from '@grafana/data';

/**
 * @typedef {Object} MapToolTipTabsProps
 * @property {Array<CompleteMarker>} [markers]
 * @property {Function} [handleDashboardVisit]
 * @property {string} [locField]
 * @property {string} [valueField]
 * @property {object} [jsonDataObj]
 * @property {string} [tabLayerName]
 * @property {boolean} [isOpenLayer]
 */
interface MapToolTipTabsProps {
  markers?: CompleteMarker[];
  handleDashboardVisit?: (text: string, custom?: string) => void;
  locField?: string;
  valueField?: string;
  jsonDataObj?: {};
  tabLayerName?: string;
  isOpenLayer?: boolean;
  isOpen?: boolean;
}
/**
 * @function
 * @param {MapToolTipTabsProps} props
 * @returns {JSX.Element}
 */
export const TooltipTabs: FC<MapToolTipTabsProps> = (props) => {
  const { markers, handleDashboardVisit, locField, valueField, jsonDataObj, isOpenLayer, isOpen } = props;
  const styles = useStyles(getStyles);

  return (
    <div key={'tooltip content'}>
      <TabContent className={styles.tabContent}>
        {markers &&
          markers.length > 1 &&
          markers.map((marker: CompleteMarker, index: number) => {
            return (
              <TooltipContent
                key={`tooltip-content-multi-${index}`}
                queryName={marker.title}
                handleDashboardVisit={handleDashboardVisit}
                index={index}
                dashName={marker.name}
                dashUrl={marker.url}
                value={`${marker.prefix || ''} ${marker.text} ${marker.suffix || ''}`}
                isGeohash={false}
                isOpenLayer={isOpenLayer}
                isOpen={isOpen}
              />
            );
          })}
        {markers &&
          markers.length === 1 &&
          markers.map((marker: CompleteMarker, index: number) => {
            return (
              <TooltipContent
                key={`tooltip-content-single-${index}`}
                queryName={marker.title}
                handleDashboardVisit={handleDashboardVisit}
                index={index}
                dashName={marker.name}
                dashUrl={marker.url}
                value={`${marker.prefix || ''} ${marker.text} ${marker.suffix || ''}`}
                isGeohash={false}
                isOpenLayer={isOpenLayer}
                isOpen={isOpen}
              />
            );
          })}
        {locField && valueField && (
          <TooltipContent
            key={`tooltip-content-geo`}
            queryName={locField}
            handleDashboardVisit={handleDashboardVisit}
            dashName={locField}
            dashUrl={locField}
            value={valueField}
            isGeohash={true}
            isOpenLayer={isOpenLayer}
            isOpen={isOpen}
          />
        )}
        {valueField && !locField && (
          <TooltipContent key={`tooltip-content-auto`} queryName={undefined} value={valueField} isGeohash={false} />
        )}
        {jsonDataObj && <TooltipContent jsonDataObj={jsonDataObj} />}
      </TabContent>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme) => {
  return {
    tabContent: css`
      background: ${theme.colors.panelBg};
      height: 100%;
    `,
    tabBarContent: css`
      font-weight: ${theme.typography.weight.semibold};
      font-size: ${theme.typography.size.md};
    `,
  };
};
