import { BusEventWithPayload } from '@grafana/data';

export interface ShareZoomPayLoad {
  zoom: number;
  lat: number;
  lng: number;
}

export class ShareZoomEvent extends BusEventWithPayload<ShareZoomPayLoad> {
  static type = 'share-zoom';
}
