import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const Plus: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M22.2,11.1h-9.3V1.8c0-0.5-0.4-0.9-0.9-0.9l0,0c-0.5,0-0.9,0.4-0.9,0.9v9.3H1.8c-0.5,0-0.9,0.4-0.9,0.9l0,0
        c0,0.5,0.4,0.9,0.9,0.9h9.3v9.3c0,0.5,0.4,0.9,0.9,0.9l0,0c0.5,0,0.9-0.4,0.9-0.9v-9.3h9.3c0.5,0,0.9-0.4,0.9-0.9l0,0
        C23.2,11.5,22.7,11.1,22.2,11.1z"
      />
    </svg>
  );
};
