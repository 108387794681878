import React from 'react';
import { FieldOverrideEditorProps } from '@grafana/data';
import { Select } from '@grafana/ui';

export const YAxis2Editor: React.FC<FieldOverrideEditorProps<any, any>> = ({ value, onChange, item, context }) => {
  if (context.data && context.data.length > 0) {
    const options = context.data
      .flatMap((frame) => frame.fields)
      .map((field) => ({
        label: field.name,
        value: field.name,
      }));

    return (
      <Select
        isClearable
        isLoading={false}
        value={value as string | null}
        onChange={(e) => {
          onChange(e?.value);
        }}
        options={options}
      />
    );
  }

  return <Select onChange={() => {}} disabled={true} />;
};
