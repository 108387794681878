import { PanelData } from '@grafana/data';
import Feature, { FeatureLike } from 'ol/Feature';
import Map from 'ol/Map';
import { unByKey } from 'ol/Observable';
import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { ReplaySubject } from 'rxjs';
import { first, map as rxjsmap } from 'rxjs/operators';
import { OSMapLayerOptions, OSMapLayerRegistryItem } from '../../LayerEditor/types';
import { getLayerPropertyInfo } from '../../StyleEditor/util';
import { MapLayerType } from '../../types/interface';
import { checkRuleIsMatched, getOpacity, getStyle } from './general';
import { GeoJSONMapperConfig, defaultOptions } from './type';

/**
 * Represents a GeoJSON layer configuration for mapping.
 *
 * @typedef {Object} GeoJSONLayerConfig
 * @property {MapLayerType} id - The identifier for the GeoJSON layer.
 * @property {string} name - The display name for the GeoJSON layer.
 * @property {string} description - A description of the GeoJSON layer.
 * @property {boolean} isBaseMap - Indicates whether it's a base map (typically false for data overlays).
 * @property {GeoJSONMapperConfig} defaultOptions - Default configuration options for the GeoJSON layer.
 */
export const geojsonLayer: OSMapLayerRegistryItem<GeoJSONMapperConfig> = {
  id: MapLayerType.GEOJSON,
  name: 'GeoJSON',
  description: 'Load static data from a geojson file OR URL',
  isBaseMap: false,
  defaultOptions,

  /**
   * Creates a GeoJSON map layer based on the provided configuration.
   *
   * @function
   * @async
   * @param {Map} map - The map to which the GeoJSON layer is added.
   * @param {OSMapLayerOptions<GeoJSONMapperConfig>} options - Options for the GeoJSON layer.
   * @returns {Promise<GeoJSONLayer>} A promise that resolves to the created GeoJSON layer.
   */
  create: async (map: Map, options: OSMapLayerOptions<GeoJSONMapperConfig>) => {
    const config = { ...defaultOptions, ...options.config };

    // https://raw.githubusercontent.com/tonywr71/GeoJson-Data/master/australian-states.min.geojson
    const source = new VectorSource({
      url: config.src,
      format: new GeoJSON(),
    });

    let rules = config.rules;
    rules = rules.filter((rule) => rule.check?.property !== '' && rule.check?.value !== '');

    const vectorLayer = new VectorLayer({
      source,
      style: (feature: Feature) => {
        feature.set('layerName', options.name);

        if (rules.length) {
          for (let index = 0; index < rules.length; index++) {
            const { check, style } = rules[index];
            if (!check || !style) {
              continue;
            }
            const { operation, value, property } = check;
            const { opacity, size, symbol, color, rotation } = style;
            const cond = checkRuleIsMatched(feature.get(property), operation, value!);
            if (cond) {
              return getStyle(symbol, color, opacity, size, rotation);
            }
          }
          return getStyle(
            config.style.symbol,
            config.style.color,
            config.style.opacity,
            config.style.size,
            config.style.rotation
          );
        } else {
          return getStyle(
            config.style.symbol,
            config.style.color,
            config.style.opacity,
            config.style.size,
            config.style.rotation
          );
        }
      },
    });

    const featuresObj = new ReplaySubject<FeatureLike[]>();
    const key = source.on('change', () => {
      //one geojson loads
      if (source.getState() === 'ready') {
        unByKey(key);
        featuresObj.next(source.getFeatures());
      }
    });

    vectorLayer.setOpacity(getOpacity(options.opacity));

    return {
      init: () => vectorLayer,
      update: (data: PanelData) => {},
      getLayerInfo: () => {
        return featuresObj.pipe(
          first(),
          rxjsmap((v) => getLayerPropertyInfo(v))
        );
      },
      getFeatures: () => featuresObj,
    };
  },
};
