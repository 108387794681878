import { HideableFieldConfig, OptionsWithTooltip, OptionsWithLegend, VisibilityMode } from '@grafana/data';

export enum ChartTypeConfig {
  Timeline = 'changes',
  Status = 'samples',
}

export interface TimelineOptions extends OptionsWithLegend, OptionsWithTooltip {
  mode: string;
  rowHeight: number;
  chartType: string;
  colWidth?: number;
  yAxisShowHide: boolean;
  showValue: VisibilityMode;
  alignValue?: ValueAlignment;
}

export type ValueAlignment = 'left' | 'center' | 'right';

export interface TimelineFieldConfig extends HideableFieldConfig {
  lineWidth?: number; // 0
  fillOpacity?: number; // 100
}

export const defaultPanelOptions: Partial<TimelineOptions> = {
  rowHeight: 0.9,
  chartType: ChartTypeConfig.Timeline,
  showValue: VisibilityMode.Auto,
  alignValue: 'left',
};

export const defaultTimelineFieldConfig: TimelineFieldConfig = {
  lineWidth: 0,
  fillOpacity: 100,
};
