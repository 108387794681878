import React from 'react';
import { PanelPlugin } from '@grafana/data';
import { ImageOptions } from './types';
import { ImagePanel } from './ImagePanel';
import { statPanelChangedHandler } from './StatMigrations';
import { ImageViewer } from './components/ImageViewer';
import { ListDashboard } from './components/ListDashboard';

export const plugin = new PanelPlugin<ImageOptions>(ImagePanel)
  .setPanelOptions((builder) => {
    builder
      .addBooleanSwitch({
        path: 'aspectRatio',
        name: 'Aspect-Ratio',
        description: 'Image with Aspect ratio',
        defaultValue: true,
      })
      .addCustomEditor({
        id: 'toShowImg',
        path: 'toShowImg',
        name: 'Select Image',
        description: 'Select an image to view',
        defaultValue: '',
        editor(props: any) {
          return <ImageViewer toShowImg={props.value} onChange={props.onChange} />;
        },
      })
      .addTextInput({
        path: 'text',
        name: 'URL',
        description: 'Provide external URL for the image',
        defaultValue: '',
      })
      .addBooleanSwitch({
        path: 'onClick',
        name: 'On Click',
        description: 'toggle on click event',
        defaultValue: false,
      })
      .addCustomEditor({
        id: 'toShowDashboard',
        path: 'toShowDashboard',
        name: 'Link',
        // description: 'Select dashboard from the given list',
        defaultValue: '',
        editor(props: any) {
          return (
            <ListDashboard toShowDashboard={props.value} option={props.context.options} onChange={props.onChange} />
          );
        },
      });
    // .addTextInput({
    //   path: 'dashboardName',
    //   name: 'Provide Custom Name',
    //   defaultValue: '',
    // })
    // .addTextInput({
    //   path: 'dashboardUrl',
    //   name: 'On Click URL',
    //   // description: 'Provide external URL for the image',
    //   defaultValue: '',
    //   // showIf: (options: ImageOptions) => options.label,
    // });
  })
  .setNoPadding()
  .setPanelChangeHandler(statPanelChangedHandler);
