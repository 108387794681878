import React from 'react';
import { Field } from '@grafana/ui';
import { QueryModeStyle } from './QueryModeStyle';
import { PropsLayer } from './type';
import { MarkerStyle } from './CustomMarkerStyle/MarkerStyle';

/**
 * Render the style configuration for a marker layer.
 *
 * @param {PropsLayer} props - The component props containing layer, data, and features.
 * @returns {JSX.Element} The JSX element representing the marker layer style configuration.
 */
export function MarkerLayerStyle({ layer, data, features }: PropsLayer) {
  return (
    <>
      <Field>
        <QueryModeStyle data={data} layer={layer} />
      </Field>
      <MarkerStyle features={features} layer={layer} />
    </>
  );
}
