import { FieldConfigSource, getValueFormat, DecimalCount, DecimalInfo } from '@grafana/data';
import _ from 'lodash';

export const unitDataProducer = (value: any, timeZone: string, fieldConfig: FieldConfigSource<any>) => {
  let prefix: string | undefined = undefined;
  let suffix: string | undefined = undefined;

  if (fieldConfig.defaults.unit === undefined || fieldConfig.defaults.unit === 'none') {
    const newValue = value.toFixed(fieldConfig.defaults.decimals || 0);
    return { text: newValue, numeric: newValue, prefix, suffix };
  }

  let text = _.toString(value);
  let numeric = toNumber(value);
  // @ts-ignore
  let shouldFormat = true;
  if (!isNaN(numeric)) {
    if (shouldFormat && !_.isBoolean(value)) {
      const { decimals, scaledDecimals } = getDecimalsForValue(value, fieldConfig.defaults.decimals || 0);
      const formatFunc = getValueFormat(fieldConfig.defaults.unit || 'none');
      const v = formatFunc(numeric, decimals, scaledDecimals, timeZone);
      text = v.text;
      suffix = v.suffix;
      prefix = v.prefix;
      if (text) {
        return { text, numeric, prefix, suffix };
      }
    }
    if (!text) {
      text = ''; // No data
    }
    return { text, numeric, prefix, suffix };
  } else {
    return { text, numeric, prefix, suffix };
  }
};

const toNumber = (value: any): number => {
  if (typeof value === 'number') {
    return value;
  }
  if (value === '' || value === null || value === undefined || Array.isArray(value)) {
    return NaN;
  }
  if (typeof value === 'boolean') {
    return value ? 1 : 0;
  }
  return _.toNumber(value);
};

const getDecimalsForValue = (value: number, decimalOverride?: DecimalCount): DecimalInfo => {
  if (_.isNumber(decimalOverride)) {
    return { decimals: decimalOverride, scaledDecimals: null };
  }
  let dec = -Math.floor(Math.log(value) / Math.LN10) + 1;
  const magn = Math.pow(10, -dec);
  const norm = value / magn;
  let size;

  if (norm < 1.5) {
    size = 1;
  } else if (norm < 3) {
    size = 2;
    if (norm > 2.25) {
      size = 2.5;
      ++dec;
    }
  } else if (norm < 7.5) {
    size = 5;
  } else {
    size = 10;
  }

  size *= magn;

  if (value % 1 === 0) {
    dec = 0;
  }

  const decimals = Math.max(0, dec);
  const scaledDecimals = decimals - Math.floor(Math.log(size) / Math.LN10) + 2;

  return { decimals, scaledDecimals };
};
