import React, { useState } from 'react';
import {
  Button,
  Select,
  Input,
  InlineField,
  InlineFieldRow,
  Modal,
  Field,
  useStyles,
  getAvailableIcons,
  Card,
  Icon,
  IconName,
} from '@grafana/ui';
import { GrafanaTheme, SelectableValue } from '@grafana/data';
import { css } from 'emotion';
import { config } from '@grafana/runtime';
import { OSMapLayerState } from '../types/types';
import { MarkerStyleConfig } from '../Layers/markerLayer/type';
import { onchangeLayerOption } from '../StyleEditor/GeojsonStyle';
import { prepareSVGImageForColor } from '../Layers/markerLayer/general';
import { cloneDeep } from 'lodash';
import { SymbolObj } from '../types/interface';

/**
 * @typedef {Object} LabelValue
 * @property {string} label
 * @property {IconName} value
 */
interface LabelValue {
  label: string;
  value: IconName;
}

interface Props {
  layer: OSMapLayerState<any>;
}

const sourceOptions = [
  { label: `${config.appName} Icons`, value: 'default' },
  { label: 'URL', value: 'url' },
];

/**
 * Get an array of label-value options representing available icons.
 *
 * @function
 * @returns {LabelValue[]} An array of label-value options where each option has a label and a value.
 */
const getUniconOptions = (): LabelValue[] => {
  const options = getAvailableIcons();
  const newOptions: LabelValue[] = [];
  options.map((option) => {
    newOptions.push({ label: option, value: option });
  });
  return newOptions;
};

export const getIconOptions: Array<SelectableValue<string>> = [
  { label: 'Circle', value: 'circle' },
  { label: 'X', value: 'x' },
  { label: 'Cross', value: 'cross' },
  { label: 'Star', value: 'star' },
  { label: 'Triangle', value: 'triangle' },
  { label: 'Square', value: 'square' },
  { label: 'Rectangle', value: 'rectangle' },
];

/**
 * @function
 * @param {Props}
 */
export const CustomSymbol: React.FC<Props> = ({ layer }: Props) => {
  const { markerIcon }: MarkerStyleConfig = layer.options.config.style;
  const { url, iconName, sourceType, urlPath } = markerIcon;
  const [isOpen, setOpen] = useState(false);
  let customIcon: SymbolObj = cloneDeep(markerIcon);

  const openModal = () => {
    setOpen(true);
  };

  const onModalSubmit = async (e: any) => {
    // if sourceType == url => use it as it is
    // if sourceType == unicon => Update url to use it
    // if sourceType == custom => Use iconName while creating marker for map
    if (sourceType === 'default') {
      // If url is empty, we will create url for selected icon and use later
      if (iconName !== '') {
        const Url = `public/img/icons/${sourceType}/${iconName}.svg`;
        customIcon = { ...customIcon, url: await prepareSVGImageForColor(Url), urlPath: Url };
      }
    }
    if (sourceType === 'url') {
      customIcon = { ...customIcon, url: url, urlPath: url };
    }
    onchangeLayerOption(customIcon, 'config.style.markerIcon', layer);

    setOpen(false);
  };

  const styles = useStyles(getStyles);
  /**
   * @function
   * @param {Array<LabelValue>} items
   * @returns {JSX.Element}
   */
  const prepareCard = (items: LabelValue[]) => {
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {items.map((item: LabelValue, idx: number) => (
          <Card
            style={{ width: '31%', margin: '3px' }}
            heading={item.label}
            key={idx}
            onClick={() => onchangeLayerOption(item.value, 'config.style.markerIcon.iconName', layer)}
          >
            <Card.Figure>
              <Icon size="md" type="default" name={item.value as any} />
            </Card.Figure>
          </Card>
        ))}
      </div>
    );
  };

  return (
    <>
      {/* modal to select icon/image */}
      {isOpen && (
        <Modal
          className={styles.wrapper}
          isOpen={isOpen}
          title="Select Icon"
          onDismiss={() => setOpen(false)}
          closeOnEscape
        >
          <>
            <div>
              <Field label="Source">
                <Select
                  options={sourceOptions}
                  onChange={(e) => onchangeLayerOption(e.value, 'config.style.markerIcon.sourceType', layer)}
                  value={sourceOptions.find((item: any) => item.value === sourceType)}
                />
              </Field>
              {sourceType === 'url' && (
                <Field label="URL">
                  <Input
                    onChange={(e) => onchangeLayerOption(e.currentTarget.value, 'config.style.markerIcon.url', layer)}
                    value={url}
                  />
                </Field>
              )}
            </div>
            {sourceType === 'default' && <div className={styles.internal}>{prepareCard(getUniconOptions())}</div>}
            <div className={styles.button}>
              <Button variant="primary" style={{ marginRight: '10px' }} onClick={(e) => onModalSubmit(e)}>
                Select
              </Button>
              <Button variant="secondary" onClick={() => setOpen(false)}>
                Cancel
              </Button>
            </div>
          </>
        </Modal>
      )}
      {/* show icon/image and its name */}
      <InlineFieldRow onClick={openModal}>
        <InlineField label="Custom Symbol" grow>
          <Input
            value={urlPath?.length !== 0 ? urlPath?.substr(urlPath?.lastIndexOf('/') + 1) : 'placemark_circle.svg'}
            placeholder={'Select a Url'}
            readOnly={true}
            prefix={
              urlPath ? (
                <img src={urlPath} width={16} height={16} alt="img"></img>
              ) : (
                <img src={'public/img/icons/default/circle.svg'} width={16} height={16} alt="img" />
              )
            }
          />
        </InlineField>
      </InlineFieldRow>
    </>
  );
};

const getStyles = (theme: GrafanaTheme) => ({
  wrapper: css`
    display: flex;
    flex-flow: column;
    width: 50%;
    height: 60%;
  `,
  button: css`
    width: 100%;
    margin-top: 10px;
    justify-content: flex-end;
    display: flex;
  `,
  internal: css`
    height: 75%;
    overflow-y: scroll;
  `,
});
