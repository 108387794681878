import React, { useEffect, useState } from 'react';
import { InlineFormLabel, Select, Input, Button } from '@grafana/ui';
import { getBackendSrv } from '@grafana/runtime';
import { PropsExpand } from './DashExpand';

export function DashLink(props: PropsExpand) {
  const { index, onUrlChangeCustomInput, onDashboardChange, option, handleRemoveLink } = props;
  const [dashboards, setDashboards]: any = useState([]);

  useEffect(() => {
    getBackendSrv()
      .get('api/search?type=dash-db')
      .then((res: any) => {
        const dashboardsLocal = [];
        for (let i = 0; i < res.length; i++) {
          dashboardsLocal.push({ label: res[i].title, value: res[i].url });
        }
        dashboards.push({ label: 'None', value: '' });
        setDashboards(dashboardsLocal);
      });
  }, []);

  return (
    <>
      <div className="gf-form gf-form--grow">
        <InlineFormLabel width={10} tooltip={'Select dashboard from the given list'}>
          Select Dashboard
        </InlineFormLabel>
        <Select
          options={dashboards}
          value={dashboards.find(
            (item: any) => item.value === (option.dashboardUrl ? option.dashboardUrl : option.url)
          )}
          onChange={(target: any) => onDashboardChange(target, index)}
        />
      </div>
      <div className="gf-form">
        <InlineFormLabel width={10} tooltip={'Provide a custom Name'}>
          Custom Name
        </InlineFormLabel>
        <Input
          defaultValue={option.dashboardName ? option.dashboardName : option.customName ?? ''}
          onBlur={(event) => {
            onUrlChangeCustomInput(event.target.value, index, true);
          }}
        />
      </div>
      <div className="gf-form">
        <InlineFormLabel width={10} tooltip={'Provide a custom URL'}>
          Custom URL
        </InlineFormLabel>
        <Input
          defaultValue={option.dashboardUrl ? option.dashboardUrl : option.url ?? ''}
          onBlur={(event) => {
            onUrlChangeCustomInput(event.target.value, index, false);
          }}
        />
      </div>
      {handleRemoveLink && (
        <div className="gf-form" style={{ justifyContent: 'start', marginTop: '10px' }}>
          <Button icon="trash" onClick={() => handleRemoveLink(index)} variant="destructive" size="md">
            Remove Link
          </Button>
        </div>
      )}
    </>
  );
}
