import { PanelPlugin } from '@grafana/data';
import { AlertList } from './AlertList';
import { AlertOptions, defaultState } from './types';
import HandleEditor from './HandleEditor';
import React from 'react';

export const plugin = new PanelPlugin<AlertOptions>(AlertList).setPanelOptions((builder) => {
  builder.addCustomEditor({
    id: 'alertoptions',
    path: 'alertoptions',
    name: '',
    defaultValue: defaultState,
    editor(props: any) {
      return <HandleEditor rule={props.value} onChange={props.onChange} />;
    },
  });
});
