import { FALLBACK_COLOR } from '@grafana/data';

/**
 * @constant
 */
export const degrees = 360;
/**
 * @constant
 */
export const defaultMargin = { top: 20, left: 20, right: 20, bottom: 20 };
/**
 * create web for radar chart
 * @param {number} length
 * @returns {any}
 */
export const genAngles = (length: number) =>
  // @ts-ignore
  [...new Array(length + 1)].map((_, i) => ({
    angle: i * (degrees / length),
  }));

/**
 * create points in radar chart
 * @param {number} length
 * @param {number} radius
 * @returns {any}
 */
export const genPoints = (length: number, radius: number) => {
  const step = (Math.PI * 2) / length;
  return [...new Array(length)].map((_, i) => ({
    x: radius * Math.sin(i * step),
    y: radius * Math.cos(i * step),
  }));
};

/**
 * @typedef {Object} PointsInt
 * @property {number} x
 * @property {number} y
 */
interface PointsInt {
  x: number;
  y: number;
}
/**
 * get polygon points in radar chart
 * @param {Array<Datum>} dataArray
 * @param {function} scale
 * @param {function} getValue
 * @returns {object} {Array<PointsInt>, string}
 */
export function genPolygonPoints<Datum>(
  dataArray: Datum[],
  scale: (n: number) => number,
  getValue: (d: Datum) => number
) {
  const step = (Math.PI * 2) / dataArray.length;
  const points: PointsInt[] = new Array(dataArray.length).fill({
    x: 0,
    y: 0,
  });
  const pointString: string = new Array(dataArray.length).fill('').reduce((res, _, i) => {
    if (i > dataArray.length) {
      return res;
    }
    const xVal = scale(getValue(dataArray[i])) * Math.sin(i * step);
    const yVal = scale(getValue(dataArray[i])) * Math.cos(i * step);
    points[i] = { x: xVal, y: yVal };
    res += `${xVal},${yVal} `;
    return res;
  }, []);

  return { points, pointString };
}
/**
 * tooltip for radar chart
 * @param {string} color
 * @param {string} categories
 * @param {number} value
 * @returns {Object} {string,string,number}
 */
export function getTooltipDataRadar(color: string, categories: string, value: number) {
  return {
    color: color ?? FALLBACK_COLOR,
    label: categories,
    value: Math.round(value),
  };
}
