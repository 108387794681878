import React from 'react';
import { Button, Icon, InlineField, InlineFieldRow } from '@grafana/ui';
import { Classic_conditions } from './Classic_conditions';
import { defaultClassicCondition } from '../types';

export const Classic = ({ onChange, query, refIds, onRunQuery }: any) => {
  const onConditionChange = (condition: any, index: number) => {
    if (query.conditions) {
      onChange({
        ...query,
        conditions: [...query.conditions.slice(0, index), condition, ...query.conditions.slice(index + 1)],
      });
    }
  };

  const onAddOperation = () => {
    if (query.conditions) {
      onChange({
        ...query,
        conditions:
          query.conditions.length > 0 ? [...query.conditions, defaultClassicCondition] : [defaultClassicCondition],
      });
    }
  };

  const onRemoveOperation = (index: number) => {
    if (query.conditions) {
      const condition = query.conditions[index];
      const conditions = query.conditions
        .filter((c: any) => c !== condition)
        .map((c: any, index: any) => {
          if (index === 0) {
            return {
              ...c,
              operator: {
                type: 'when',
              },
            };
          }
          return c;
        });
      onChange({
        ...query,
        conditions,
      });
    }
  };

  return (
    <div>
      <InlineFieldRow>
        <InlineField label="Conditions" labelWidth={14}>
          <div>
            {query.conditions?.map((condition: any, index: any) => {
              if (!condition) {
                return;
              }
              return (
                <Classic_conditions
                  key={index}
                  index={index}
                  condition={condition}
                  onChange={(condition: any) => onConditionChange(condition, index)}
                  onRemoveOperation={onRemoveOperation}
                  onRunQuery={onRunQuery}
                  refIds={refIds}
                />
              );
            })}
          </div>
        </InlineField>
      </InlineFieldRow>
      <Button variant="secondary" type="button" onClick={onAddOperation}>
        <Icon name="plus" />
      </Button>
    </div>
  );
};
