import { PanelOptionsEditorBuilder } from '@grafana/data';
import React from 'react';
import { CustomMarkerHeat } from '../custom';
import { MapPanelOptions } from '../types/types';

export function heatmaplayerOptions<T extends MapPanelOptions>(builder: PanelOptionsEditorBuilder<T>) {
  builder
    .addBooleanSwitch({
      path: 'heatmap.enabled',
      name: 'Enable Heatmap',
      defaultValue: false,
      category: ['Heatmap Editor'],
    })
    .addSelect({
      path: 'heatmapColor',
      name: 'Choose Color Gradient',
      category: ['Heatmap Editor'],
      settings: {
        options: [
          {
            value: 'Default Color',
            label: 'Default Color',
          },
          {
            value: 'Color Spectrum',
            label: 'Color Spectrum',
          },
          {
            value: 'Visible Spectrum',
            label: 'Visible Spectrum',
          },
          {
            value: 'Deep Sea',
            label: 'Deep Sea',
          },
          {
            value: 'Black Aqua',
            label: 'Black Aqua',
          },
          {
            value: 'Heated Metal',
            label: 'Heated Metal',
          },
          {
            value: 'Sun Rise',
            label: 'Sun Rise',
          },
          {
            value: 'Incandescent Lamp',
            label: 'Incandescent Lamp',
          },
          {
            value: 'Custom Palette',
            label: 'Custom Palette',
          },
        ],
      },
      defaultValue: 'Default Color',
      showIf: (options: MapPanelOptions) => options.heatmap.enabled,
    })
    .addNumberInput({
      path: 'heatmap.maxRadiusSize',
      name: 'Heatmap Radius',
      defaultValue: 8,
      category: ['Heatmap Editor'],
      showIf: (options: MapPanelOptions) => options.heatmap.enabled,
    })
    .addNumberInput({
      path: 'heatmap.opacity',
      name: 'Heatmap Opacity',
      defaultValue: 0.7,
      settings: {
        min: 0,
        max: 1,
        step: 0.1,
      },
      category: ['Heatmap Editor'],
      showIf: (options: MapPanelOptions) => options.heatmap.enabled,
    })
    .addBooleanSwitch({
      path: 'heatmap.inWeight',
      name: 'Include Weight',
      defaultValue: false,
      category: ['Heatmap Editor'],
      showIf: (options: MapPanelOptions) => options.heatmap.enabled,
    })
    .addNumberInput({
      path: 'heatmap.weightFactor',
      name: 'Weight Factor',
      defaultValue: 1,
      settings: {
        min: 0,
        step: 1,
      },
      category: ['Heatmap Editor'],
      showIf: (options: MapPanelOptions) => options.heatmap.enabled && options.heatmap.inWeight,
    })
    .addCustomEditor({
      id: 'customMarkersHeat',
      path: 'customMarkersHeat',
      name: 'Custom Markers for Heatmap',
      defaultValue: { markers: [], currIdx: -1, enabled: false, url: '' },
      editor(props: any) {
        return <CustomMarkerHeat customMarkers={props.value} onChange={props.onChange} bigData={props.context} />;
      },
      category: ['Heatmap Editor'],
      showIf: (options: MapPanelOptions) => options.heatmap.enabled,
    });
}
