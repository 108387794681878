import { DataFrame, DataFrameView, dateTimeFormat, systemDateFormats, TimeZone } from '@grafana/data';
import { ALERTING_COLOR, EventsCanvas, OK_COLOR, PENDING_COLOR, usePlotContext } from '@grafana/ui';
import React, { useCallback, useEffect, useRef } from 'react';
import { AnnotationMarkerForAlert } from './AnnotationMarkerForAlert';

interface AnnotationsPluginProps {
  annotations: DataFrame[];
  timeZone: TimeZone;
}

interface AnnotationsDataFrameViewDTO {
  time: number;
  text: string;
  tags: string[];
  currentState: string;
}

export const AnnotationsPluginForAlert: React.FC<AnnotationsPluginProps> = ({ annotations, timeZone }) => {
  // const pluginId = 'AnnotationsPlugin';
  const plotCtx = usePlotContext();

  const annotationsRef = useRef<Array<DataFrameView<AnnotationsDataFrameViewDTO>>>();

  const timeFormatter = useCallback(
    (value: number) => {
      return dateTimeFormat(value, {
        format: systemDateFormats.fullDate,
        timeZone,
      });
    },
    [timeZone]
  );

  useEffect(() => {
    if (plotCtx.isPlotReady) {
      const views: Array<DataFrameView<AnnotationsDataFrameViewDTO>> = [];

      for (const frame of annotations) {
        views.push(new DataFrameView(frame));
      }

      annotationsRef.current = views;
    }
  }, [plotCtx.isPlotReady, annotations]);

  useEffect(() => {
    const unregister = plotCtx.registerPlugin({
      id: 'AnnotationsPluginForALert',
      hooks: {
        // Render annotation lines on the canvas
        draw: (u) => {
          /**
           * We cannot rely on state value here, as it would require this effect to be dependent on the state value.
           * This would make the plugin re-register making the entire plot to reinitialise. ref is the way to go :)
           */

          if (!annotationsRef.current) {
            return null;
          }

          const ctx = u.ctx;

          if (!ctx) {
            return;
          }

          ctx.save();
          ctx.beginPath();
          ctx.rect(u.bbox.left, u.bbox.top, u.bbox.width, u.bbox.height);
          ctx.clip();
          /**
           * we can't use the ctx.setLineDash([5,5]) because it will modify whole graph into dashed line so we
           * designed a function to create small small line on xpos - so that it will look like dashed line
           * @param xpos
           * @param lineLength
           * @param color
           */
          const dashedLine = (xpos: number, lineLength: number, color: string) => {
            let ny = lineLength;

            ny /= lineLength;
            let i = 0; // the current line position in pixels
            ctx.beginPath(); // start a path
            ctx.strokeStyle = color;
            ctx.lineWidth = 1;
            while (i < lineLength) {
              // do while less than line length
              // draw the dash
              ctx.moveTo(xpos, ny * i);
              i = Math.min(lineLength, i + 5);
              ctx.lineTo(xpos, ny * i);

              i += 5;
              if (i <= 0) {
                // something is wrong so exit rather than endless loop
                break;
              }
            }
            ctx.stroke(); // stroke
          };
          for (let i = 0; i < annotationsRef.current.length; i++) {
            const annotationsView = annotationsRef.current[i];
            for (let j = 0; j < annotationsView.length; j++) {
              const annotation = annotationsView.get(j);

              if (!annotation.time) {
                continue;
              }
              // * xpos of time in pixel on canvas
              const xpos = u.valToPos(annotation.time, 'x', true);
              // * color based on current state
              const color =
                annotation.currentState === 'Normal'
                  ? OK_COLOR
                  : annotation.currentState === 'Pending'
                  ? PENDING_COLOR
                  : ALERTING_COLOR;
              dashedLine(xpos, u.bbox.top + u.bbox.height, color);
            }
          }
          return;
        },
      },
    });
    // * cleanup after every render
    return () => {
      unregister();
    };
  }, []);

  const mapAnnotationToXYCoords = useCallback(
    (frame: DataFrame, index: number) => {
      const view = new DataFrameView<AnnotationsDataFrameViewDTO>(frame);
      const annotation = view.get(index);
      const plotInstance = plotCtx.getPlotInstance();
      if (!annotation.time || !plotInstance) {
        return undefined;
      }

      return {
        x: plotInstance.valToPos(annotation.time, 'x'),
        y: plotInstance.bbox.height / window.devicePixelRatio + 4,
      };
    },
    [plotCtx.getPlotInstance]
  );

  const renderMarker = useCallback(
    (frame: DataFrame, index: number) => {
      const view = new DataFrameView<AnnotationsDataFrameViewDTO>(frame);
      const annotation = view.get(index);
      return (
        <AnnotationMarkerForAlert
          time={timeFormatter(annotation.time)}
          text={annotation.text}
          tags={annotation.tags}
          currentState={annotation.currentState}
        />
      );
    },
    [timeFormatter]
  );

  return (
    <EventsCanvas
      id="annotationsforalert"
      events={annotations}
      renderEventMarker={renderMarker}
      mapEventToXYCoords={mapAnnotationToXYCoords}
    />
  );
};
