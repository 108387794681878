import { SelectableValue } from '@grafana/data';
import { Field, InlineField, RadioButtonGroup, Select, useTheme } from '@grafana/ui';
import React from 'react';
import { QueryConfig } from '../Layers/hetamapLayer/type';
import { MapLayerType, QueryModes } from '../types/interface';
import { LABEL_WIDTH, onchangeLayerOption } from './GeojsonStyle';
import { PropsLayer, QueryMode } from './type';
import { prepareGeohashSelections, prepareOptionsForDataField } from './util';

/**
 * Renders the query mode and configuration options for an OpenStreetMap layer.
 *
 * @param {PropsLayer} props - The props containing layer and data information.
 * @returns {JSX.Element} Returns a JSX element representing the query mode and configuration options.
 */
export function QueryModeStyle({ layer, data }: PropsLayer) {
  const theme = useTheme();
  const { queryMode, geohashQuerySelections, dataField }: QueryConfig = layer.options.config.queryOptions;
  const {
    location,
    geohash,
    value,
    latitude,
    longitude,
    lat1,
    lat2,
    lat3,
    lat4,
    lng1,
    lng2,
    lng3,
    lng4,
  } = geohashQuerySelections;
  const { dataFieldOptions } = prepareOptionsForDataField(data ?? []);
  const { queryOptionsNum, queryOptionsString } = prepareGeohashSelections(data ?? [], dataField, theme);

  return (
    <>
      <InlineField
        label="Query Mode"
        labelWidth={14}
        style={{ gap: '5px' }}
        tooltip={'Select the data field and then select Query Mode'}
      >
        <>
          <Select
            noOptionsMessage="no Data fields found"
            options={dataFieldOptions}
            onChange={(e: SelectableValue<string>) =>
              onchangeLayerOption(e.value, 'config.queryOptions.dataField', layer)
            }
            value={dataFieldOptions.find((item: any) => item.value === dataField)}
            placeholder="Data Field"
          />
          <RadioButtonGroup
            options={QueryMode}
            value={queryMode}
            onChange={(v) => onchangeLayerOption(v, 'config.queryOptions.queryMode', layer)}
            size="sm"
          />
        </>
      </InlineField>
      {queryMode !== QueryModes.Auto && (
        <>
          <InlineField label="Name" labelWidth={LABEL_WIDTH}>
            <Select
              noOptionsMessage="no Name fields found"
              options={queryOptionsString}
              onChange={(e: SelectableValue<string>) =>
                onchangeLayerOption(e.value, 'config.queryOptions.geohashQuerySelections.location', layer)
              }
              value={queryOptionsString.find((item: any) => item.value === location)}
            />
          </InlineField>
          <InlineField label="Metric" labelWidth={LABEL_WIDTH}>
            <Select
              noOptionsMessage="no Metric fields found"
              options={queryOptionsNum}
              onChange={(e: SelectableValue<string>) =>
                onchangeLayerOption(e.value, 'config.queryOptions.geohashQuerySelections.value', layer)
              }
              value={queryOptionsNum.find((item: any) => item.value === value)}
            />
          </InlineField>
        </>
      )}
      {queryMode === QueryModes.Geohash && (
        <>
          <InlineField label="Geohash" labelWidth={LABEL_WIDTH}>
            <Select
              noOptionsMessage="no Geohash fields found"
              options={queryOptionsString}
              onChange={(e: SelectableValue<string>) =>
                onchangeLayerOption(e.value, 'config.queryOptions.geohashQuerySelections.geohash', layer)
              }
              value={queryOptionsString.find((item: any) => item.value === geohash)}
            />
          </InlineField>
        </>
      )}
      {queryMode === QueryModes.Coords && (
        <>
          <InlineField label="Latitude" labelWidth={LABEL_WIDTH}>
            <Select
              noOptionsMessage="no Latitude fields found"
              options={queryOptionsNum}
              onChange={(e: SelectableValue<string>) =>
                onchangeLayerOption(e.value, 'config.queryOptions.geohashQuerySelections.latitude', layer)
              }
              value={queryOptionsNum.find((item: any) => item.value === latitude)}
            />
          </InlineField>
          <InlineField label="Longitude" labelWidth={LABEL_WIDTH}>
            <Select
              noOptionsMessage="no Longitude fields found"
              options={queryOptionsNum}
              onChange={(e: SelectableValue<string>) =>
                onchangeLayerOption(e.value, 'config.queryOptions.geohashQuerySelections.longitude', layer)
              }
              value={queryOptionsNum.find((item: any) => item.value === longitude)}
            />
          </InlineField>
        </>
      )}
      {queryMode === QueryModes.Choro && layer.options.type === MapLayerType.MARKER && (
        <>
          <Field label={'lat lng of polygon'} description="choose all four corner coordinates of house">
            <>
              <InlineField label="Lat-Lng 1" labelWidth={LABEL_WIDTH} tooltip={'corner 1'}>
                <>
                  <Select
                    noOptionsMessage="no Latitude fields found"
                    options={queryOptionsNum}
                    onChange={(e: SelectableValue<string>) =>
                      onchangeLayerOption(e.value, 'config.queryOptions.geohashQuerySelections.lat1', layer)
                    }
                    value={queryOptionsNum.find((item: any) => item.value === lat1)}
                  />
                  <Select
                    noOptionsMessage="no Longitude fields found"
                    options={queryOptionsNum}
                    onChange={(e: SelectableValue<string>) =>
                      onchangeLayerOption(e.value, 'config.queryOptions.geohashQuerySelections.lng1', layer)
                    }
                    value={queryOptionsNum.find((item: any) => item.value === lng1)}
                  />
                </>
              </InlineField>
              <InlineField label="Lat-Lng 2" labelWidth={LABEL_WIDTH} tooltip={'corner 2'}>
                <>
                  <Select
                    noOptionsMessage="no Latitude fields found"
                    options={queryOptionsNum}
                    onChange={(e: SelectableValue<string>) =>
                      onchangeLayerOption(e.value, 'config.queryOptions.geohashQuerySelections.lat2', layer)
                    }
                    value={queryOptionsNum.find((item: any) => item.value === lat2)}
                  />
                  <Select
                    noOptionsMessage="no Longitude fields found"
                    options={queryOptionsNum}
                    onChange={(e: SelectableValue<string>) =>
                      onchangeLayerOption(e.value, 'config.queryOptions.geohashQuerySelections.lng2', layer)
                    }
                    value={queryOptionsNum.find((item: any) => item.value === lng2)}
                  />
                </>
              </InlineField>
              <InlineField label="Lat-Lng 3" labelWidth={LABEL_WIDTH} tooltip={'corner 3'}>
                <>
                  <Select
                    noOptionsMessage="no Latitude fields found"
                    options={queryOptionsNum}
                    onChange={(e: SelectableValue<string>) =>
                      onchangeLayerOption(e.value, 'config.queryOptions.geohashQuerySelections.lat3', layer)
                    }
                    value={queryOptionsNum.find((item: any) => item.value === lat3)}
                  />
                  <Select
                    noOptionsMessage="no Longitude fields found"
                    options={queryOptionsNum}
                    onChange={(e: SelectableValue<string>) =>
                      onchangeLayerOption(e.value, 'config.queryOptions.geohashQuerySelections.lng3', layer)
                    }
                    value={queryOptionsNum.find((item: any) => item.value === lng3)}
                  />
                </>
              </InlineField>
              <InlineField label="Lat-Lng 4" labelWidth={LABEL_WIDTH} tooltip={'corner 4'}>
                <>
                  <Select
                    noOptionsMessage="no Latitude fields found"
                    options={queryOptionsNum}
                    onChange={(e: SelectableValue<string>) =>
                      onchangeLayerOption(e.value, 'config.queryOptions.geohashQuerySelections.lat4', layer)
                    }
                    value={queryOptionsNum.find((item: any) => item.value === lat4)}
                  />
                  <Select
                    noOptionsMessage="no Longitude fields found"
                    options={queryOptionsNum}
                    onChange={(e: SelectableValue<string>) =>
                      onchangeLayerOption(e.value, 'config.queryOptions.geohashQuerySelections.lng4', layer)
                    }
                    value={queryOptionsNum.find((item: any) => item.value === lng4)}
                  />
                </>
              </InlineField>
            </>
          </Field>
        </>
      )}
    </>
  );
}
