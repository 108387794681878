import { PanelPlugin } from '@grafana/data';
import { BarOptions } from './types';
import { BarPanel } from './BarPlotPanel';

export const plugin = new PanelPlugin<BarOptions>(BarPanel).useFieldConfig().setPanelOptions((builder) => {
  return builder
    .addRadio({
      path: 'graphType',
      name: 'Graph Type',
      description: 'Select the type of graph want see',
      settings: {
        options: [
          { value: 'bar', label: 'Bar' },
          { value: 'line', label: 'Line' },
          { value: 'both', label: 'Both' },
        ],
      },
      defaultValue: 'bar',
    })
    .addBooleanSwitch({
      path: 'xaxisLabels',
      name: 'X Axis Labels',
      description: 'Shows data labels on x axis',
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'overridetime',
      name: 'Override Time Conversion',
      description: 'Shows complete date-time',
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'horizontalTooltip',
      name: 'Ruler',
      description: 'Enables a ruler with the tooltip.',
      defaultValue: false,
    })
    .addTextInput({
      path: 'tooltipStart',
      name: 'Tooltip Start Text',
      defaultValue: 'Start',
    })
    .addTextInput({
      path: 'tooltipEnd',
      name: 'Tooltip End Text',
      defaultValue: 'End',
    });
});
