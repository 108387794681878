import { heatmapColorGradient } from '../../StyleEditor/type';
import { AggregationForHeat } from '../../types/interface';
import { defaultQueryConfig } from '../markerLayer/default';
import { HeatmapConfig } from './type';

/**
 * Default configuration options for styling heatmaps on a map.
 *
 * @constant
 * @type {Object}
 * @property {boolean} weight - Indicates whether to use weighted data for the heatmap.
 * @property {number} blur - The blur radius for the heatmap.
 * @property {number} radius - The radius for the heatmap.
 * @property {string} colorGradient - The color gradient for the heatmap.
 * @property {number} opacity - The opacity of the heatmap (0 to 1).
 */
export const defaultHetmapStyleConfig = Object.freeze({
  weight: false,
  blur: 1,
  radius: 10,
  colorGradient: heatmapColorGradient[0].value,
  opacity: 1,
});

/**
 * Default configuration options for a heatmap visualization.
 *
 * @constant
 * @type {HeatmapConfig}
 * @property {HeatmapStyleConfig} style - The default style configuration for the heatmap.
 * @property {QueryConfig} queryOptions - The default query configuration for the heatmap.
 * @property {string} aggregationData - The default aggregation mode for the heatmap.
 */
export const defaultOptions: HeatmapConfig = {
  style: defaultHetmapStyleConfig,
  queryOptions: defaultQueryConfig,
  aggregationData: AggregationForHeat.NONE,
};
