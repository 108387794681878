import { Field, InlineField, Input, Select, Slider, Switch, RadioButtonGroup } from '@grafana/ui';
import React from 'react';
import { LABEL_WIDTH, onchangeLayerOption } from '../GeojsonStyle';
import { PropsLayer, heatmapColorGradient } from '../type';
import { HetamapStyleConfig } from '../../Layers/hetamapLayer/type';
import { AggregationForHeat, MapLayerType } from '../../types/interface';

/**
 * Array of aggregation options for heatmaps.
 * @type {AggregationOption[]}
 */
const AggregationOptions = [
  { value: AggregationForHeat.MEAN, label: 'Mean' },
  { value: AggregationForHeat.MIN, label: 'Min' },
  { value: AggregationForHeat.MAX, label: 'Max' },
  { value: AggregationForHeat.SUM, label: 'Sum' },
  { value: AggregationForHeat.NONE, label: 'None' },
];

/**
 * Renders the style settings for a heatmap layer.
 *
 * @param {PropsLayer} layer - The heatmap layer.
 */
export function HeatmapStyle({ layer }: PropsLayer) {
  const { style, aggregationData } = layer.options.config;
  const { weight, blur, radius, colorGradient, opacity }: HetamapStyleConfig = style;

  return (
    <>
      <Field label="Heatmap style">
        <>
          <InlineField label="Opacity" labelWidth={LABEL_WIDTH}>
            <Slider
              min={0}
              max={1}
              step={0.1}
              value={opacity}
              onChange={(v) => onchangeLayerOption(v, 'config.style.opacity', layer)}
            />
          </InlineField>
          <InlineField label="Color Gradient" labelWidth={LABEL_WIDTH}>
            <Select
              noOptionsMessage="no Color Gradient fields found"
              options={heatmapColorGradient}
              onChange={(v) => onchangeLayerOption(v.value, 'config.style.colorGradient', layer)}
              value={colorGradient}
            />
          </InlineField>
          <InlineField label="Radius" labelWidth={LABEL_WIDTH}>
            <Input
              type="number"
              value={radius}
              onChange={(e) => onchangeLayerOption(Number(e.currentTarget.value), 'config.style.radius', layer)}
            />
          </InlineField>
          <InlineField label="Blur size" labelWidth={LABEL_WIDTH}>
            <Slider
              min={0}
              max={15}
              step={1}
              value={blur}
              onChange={(v) => onchangeLayerOption(v, 'config.style.blur', layer)}
            />
          </InlineField>
          <InlineField label="Include Weight" style={{ alignItems: 'center' }} labelWidth={LABEL_WIDTH}>
            <Switch
              value={weight}
              onChange={(e) => onchangeLayerOption(e.currentTarget.checked, 'config.style.weight', layer)}
            />
          </InlineField>
          <InlineField label="Show Tooltip" style={{ alignItems: 'center' }} labelWidth={LABEL_WIDTH}>
            <Switch
              value={layer.options.tooltip}
              onChange={(e) => onchangeLayerOption(e.currentTarget.checked, 'tooltip', layer)}
            />
          </InlineField>
          {layer.options.type === MapLayerType.HEATMAP && (
            <InlineField
              label="AggregateData"
              labelWidth={LABEL_WIDTH + 4}
              tooltip={'aggregate heatmap data according to zoom. when zoom level changes data on map aggregates'}
            >
              <RadioButtonGroup
                options={AggregationOptions}
                value={aggregationData}
                size="sm"
                onChange={(e) => {
                  onchangeLayerOption(e, 'config.aggregationData', layer);
                }}
              />
            </InlineField>
          )}
        </>
      </Field>
    </>
  );
}
