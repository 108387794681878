import { TextOptions } from '../types';
import { CodeLanguage, Format } from './editor';

/**
 * Default Options
 */
export const DefaultOptions: TextOptions = {
  content: '{{variable "{__user.login}"}}',
  defaultContent: "The query didn't return any results.",
  everyRow: false,
  editor: { height: 200, format: Format.AUTO, language: CodeLanguage.MARKDOWN },
  helpers: '',
  reduceOptions: '',
  fontoverride: false,
  minlimit: 0.8,
  maxlimit: 1.3,
  seriespicker: false,
};
