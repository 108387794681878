import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const Explore: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M19.2,7.8h-2.7c-0.6-1.4-1.4-2.7-2.4-3.9
        C16.3,4.5,18.1,5.9,19.2,7.8z M15.8,12c0,0.8-0.1,1.7-0.4,2.5H8.6c-0.3-0.8-0.4-1.7-0.4-2.5s0.1-1.7,0.4-2.5h6.7
        C15.6,10.3,15.7,11.2,15.8,12z M9.3,16.2h5.4C14,17.6,13.1,18.9,12,20C10.9,18.9,10,17.6,9.3,16.2z M9.3,7.8C10,6.4,10.9,5.2,12,4
        c1.1,1.1,2,2.4,2.7,3.8H9.3z M9.8,4C8.8,5.2,8,6.5,7.4,7.9H4.8C5.9,5.9,7.7,4.5,9.8,4z M4.1,9.5H7c-0.2,0.8-0.3,1.7-0.3,2.5
        s0.1,1.7,0.3,2.5H4.1C3.5,12.9,3.5,11.1,4.1,9.5z M4.8,16.2h2.7c0.6,1.4,1.4,2.7,2.4,3.9C7.7,19.5,5.9,18.1,4.8,16.2z M14.2,20
        c1-1.2,1.7-2.5,2.4-3.9h2.7C18.1,18.1,16.3,19.5,14.2,20z M20,14.5h-2.9c0.2-0.8,0.3-1.7,0.3-2.5s-0.1-1.7-0.3-2.5H20
        C20.5,11.1,20.5,12.9,20,14.5L20,14.5z"
      />
    </svg>
  );
};
