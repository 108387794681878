import React, { useState, useEffect } from 'react';
import { PanelProps, FieldDisplay, getFieldDisplayValues } from '@grafana/data';
import { NoData } from '@grafana/ui';
import { config } from 'app/core/config';
import { TrendPanelOptions } from './types';
import { Display } from './Display';

interface Props extends PanelProps<TrendPanelOptions> {}

// TODO: CODE REFACTORING NEEDED
export function TrendPanel(props: Props) {
  const [displayData, setdisplayData] = useState(<div></div>);
  const [values, setValues] = useState<any>({ noData: undefined, noDataColor: '', isPositive: false });

  const { height, options, width, data, replaceVariables, fieldConfig, timeZone } = props;
  const { noData, noDataColor, isPositive } = values;

  const getValues = (reducedOptions: any): FieldDisplay[] => {
    return getFieldDisplayValues({
      fieldConfig,
      reduceOptions: reducedOptions,
      replaceVariables,
      theme: config.theme,
      data: data.series,
      timeZone,
    });
  };

  useEffect(() => {
    switch (options.querytype) {
      case 'one':
        const toMutateLastNotNull = options.reduceOptions;
        toMutateLastNotNull.calcs[0] = 'lastNotNull';
        const bigData = getValues(toMutateLastNotNull);
        const toMutateFirstNotNull = options.reduceOptions;
        toMutateFirstNotNull.calcs[0] = 'firstNotNull';
        const bigData2 = getValues(toMutateFirstNotNull);
        const text2 = Number(bigData2[0].display.text);
        const text = Number(bigData[0].display.text);
        if (bigData[0].name !== 'No data') {
          let abs = options.percentage ? ((text2 - text) / text) * 100 : text2 - text;
          abs = Number(abs.toFixed(bigData[0].field.decimals || 2));
          setdisplayData(
            <div>
              {bigData[0].display.prefix} <span>{abs}</span> {bigData[0].display.suffix}
            </div>
          );
          setValues({ noData: undefined, noDataColor: bigData[0].display.color, isPositive: abs >= 0 });
        } else {
          setValues({ ...data, noData: text, noDataColor: bigData[0].display.color });
        }
        break;

      default:
        const bigData1 = getValues(options.reduceOptions);
        const text0 = Number(bigData1[0].display.text);
        if (bigData1[0].name !== 'No data') {
          if (bigData1.length > 1) {
            const text1 = Number(bigData1[1].display.text);
            let abs = options.percentage ? ((text0 - text1) / text0) * 100 : text0 - text1;
            abs = Number(abs.toFixed(bigData1[0].field.decimals || 2));
            setdisplayData(
              <div>
                {bigData1[0].display.prefix} <span>{abs}</span> {bigData1[0].display.suffix}
              </div>
            );
            setValues({ noData: undefined, noDataColor: bigData1[0].display.color, isPositive: abs >= 0 });
          } else {
            setValues({ ...data, noData: 'Not enough queries', noDataColor: bigData1[0].display.color });
          }
        } else {
          setValues({ ...data, noData: text0, noDataColor: bigData1[0].display.color });
        }
        break;
    }
  }, [data, options]);

  if (noData) {
    return <NoData text={noData} width={width} height={height} color={noDataColor} />;
  }

  return (
    <div style={{ position: 'relative', width, height }}>
      <div>
        <Display
          text={displayData}
          width={width}
          height={height}
          color={noDataColor}
          isPositive={isPositive}
          subtext={options.subtext}
        />
      </div>
    </div>
  );
}
