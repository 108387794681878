import { RegistryItemWithOptions } from '@grafana/data';
import { Map as OpenLayerMap } from 'ol';
import { OSMapLayerHandler } from '../types/types';
import { MapLayerType } from '../types/interface';

/**
 * Represents an item in the map layer registry with configuration options.
 *
 * @interface OSMapLayerRegistryItem
 * @template TConfig - The type of configuration options for the map layer.
 * @extends {RegistryItemWithOptions}
 * @property {boolean} [isBaseMap] - Indicates whether the layer is a base map.
 * @property {boolean} [showLocation] - Indicates whether to show location information.
 * @property {boolean} [showOpacity] - Indicates whether to show opacity settings.
 *
 * @property {function} create - A function that creates and initializes the map layer.
 * @param {OpenLayerMap} map - The map to which the layer is added.
 * @param {OSMapLayerOptions<TConfig>} options - Options for the map layer.
 * @returns {Promise<OSMapLayerHandler>} A promise that resolves to a map layer handler.
 */
export interface OSMapLayerRegistryItem<TConfig = OSMapLayerOptions> extends RegistryItemWithOptions {
  isBaseMap?: boolean;
  showLocation?: boolean;
  showOpacity?: boolean;
  create: (map: OpenLayerMap, options: OSMapLayerOptions<TConfig>) => Promise<OSMapLayerHandler>;
}

/**
 * Options for configuring a map layer.
 *
 * @interface OSMapLayerOptions
 * @template TConfig - The type of configuration options for the map layer.
 * @property {MapLayerType} type - The type of the map layer.
 * @property {string} name - The name or display label of the map layer.
 * @property {TConfig} [config] - Custom configuration options depending on the layer type.
 * @property {number} [opacity] - The opacity of the map layer, ranging from 0 (completely transparent) to 1 (fully opaque).
 * @property {boolean} [tooltip] - Indicates whether tooltips should be displayed for features on the layer.
 */
export interface OSMapLayerOptions<TConfig = any> {
  type: MapLayerType;
  name: string;
  // Custom options depending on the type
  config?: TConfig;
  // Layer opacity (0-1)
  opacity?: number;
  //Check tooltip
  tooltip?: boolean;
}

/**
 * Default configuration options for a GeoJSON map layer.
 *
 * @constant {OSMapLayerOptions} getDefaultGeojsonConfig
 * @default
 * @property {Object} config - Configuration options specific to the GeoJSON layer.
 * @property {FeatureStyleConfig[]} config.rules - An array of feature styling rules.
 * @property {string} config.src - The URL or file path to the GeoJSON data source.
 * @property {StyleConfig} config.style - The default style for features on the GeoJSON layer.
 * @property {string} name - The name or display label of the GeoJSON layer.
 * @property {boolean} tooltip - Indicates whether tooltips should be displayed for features on the layer.
 * @property {MapLayerType} type - The type of the map layer, which is MapLayerType.GEOJSON.
 * @property {number} opacity - The opacity of the GeoJSON layer, ranging from 0 (completely transparent) to 1 (fully opaque).
 */
export const getDefaultGeojsonConfig: OSMapLayerOptions = {
  config: {
    rules: [],
    src: 'public/maps/australia_states.geojson',
    style: {
      color: '',
      opacity: 0.4,
      size: 5,
      symbol: 'circle',
    },
  },
  name: '',
  tooltip: true,
  type: MapLayerType.GEOJSON,
  opacity: 1,
};

/**
 * Capitalizes the first letter of a given string.
 *
 * @function
 * @param {string} str - The input string to capitalize.
 * @returns {string} The input string with the first letter capitalized.
 */
export function makeFirstCapital(str: string): string {
  const str2 = str.charAt(0).toUpperCase() + str.slice(1);
  return str2;
}
