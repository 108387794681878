import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const Save: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 18 18"
      viewBox="0 0 18 18"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M9,7.8c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5S10.4,7.8,9,7.8z M9,11.5
        c-0.7,0-1.2-0.6-1.2-1.2S8.3,9,9,9c0.7,0,1.2,0.6,1.2,1.2S9.7,11.5,9,11.5z M15.6,4.1l-1.7-1.7c-0.6-0.6-1.4-0.9-2.2-0.9H4.6
        c-1.7,0-3.1,1.4-3.1,3.1v8.7c0,1.7,1.4,3.1,3.1,3.1h8.7c1.7,0,3.1-1.4,3.1-3.1V6.3C16.5,5.5,16.2,4.7,15.6,4.1z M12.1,2.8v0.6
        c0,1-0.8,1.9-1.9,1.9H7.8c-1,0-1.9-0.8-1.9-1.9V2.8h5.8C11.8,2.8,12,2.8,12.1,2.8z M15.2,13.4c0,1-0.8,1.9-1.9,1.9H4.6
        c-1,0-1.9-0.8-1.9-1.9V4.6c0-1,0.8-1.9,1.9-1.9v0.6c0,1.7,1.4,3.1,3.1,3.1h2.5c1.6,0,3-1.3,3.1-2.9L14.7,5c0.3,0.4,0.5,0.8,0.5,1.3
        V13.4z"
      />
    </svg>
  );
};
