import { PanelProps } from '@grafana/data';
import { stylesFactory, useTheme } from '@grafana/ui';
import { css, cx } from 'emotion';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import GoogleBaseMap from './Google/GoogleBaseMap';
import { autoQueryData, customMarkerData, customMarkerDataHeat, generalBigData, geohashQueryData } from './calculation';
import { ShareZoomEvent } from './utils/events';
import { ColorGradient } from './utils/heatcolor';
import { getColorArrayFromCustomPalette, prepareGraphableFields } from './utils/prepareFields';
import { MapPanelOptions } from './types/types';

/*
 * @typedef {Object} MapPanelProps
 * @property {PanelProps<MapPanelOptions>} PanelProps panel props extends with box plot options
 */
export interface MapPanelProps extends PanelProps<MapPanelOptions> {}

/**
 * Google map panel
 * @function
 * @param {MapPanelProps} props
 * @returns {JSX.Element}
 *
 * @mermaid
 *  graph TD;
 *      A(first run) --> B[Add Query or Queries];
 *      B --> |default| C[Google Base Map]
 *      B --> D[Open Street Map]
 *      C --> E[Marker Editor];
 *      C --> F[Custom Marker Editor];
 *      C --> G[Heat Map Layer];
 *      C --> H[Custom Marker Heat Map Layer]
 *      C --> I[Geojson Layer]
 *      C --> J[Traffic Layer]
 *      D --> E[Marker Editor];
 *      D --> F[Custom Marker Editor];
 *      D --> G[Heat Map Layer];
 *      D --> H[Custom Marker Heat Map Layer]
 *      D --> I[Geojson Layer]
 *      D --> J[Traffic Layer]
 */

export const GoogleMapPanel: React.FC<MapPanelProps> = (props: MapPanelProps) => {
  const {
    options,
    width,
    height,
    data,
    onOptionsChange,
    fieldConfig,
    replaceVariables,
    timeZone,
    eventBus,
    timeRange,
  } = props;
  const [getZoom, setGetZoom] = useState<number>(options.zoom);
  const [getLat, setLat] = useState<number>(options.defaultCenter.lat);
  const [getLng, setLng] = useState<number>(options.defaultCenter.lng);
  const styles = getStyles();
  const theme = useTheme();

  /**
   * publish center and latitude and longitude when shared is true
   * @function
   * @param {number} newZoom
   * @param {number} newLat
   * @param {number} newLng
   */
  const setSharedZoom = (newZoom: number, newLat: number, newLng: number) => {
    if (options.shared) {
      eventBus?.publish({
        type: ShareZoomEvent.type,
        payload: {
          zoom: newZoom,
          lat: newLat,
          lng: newLng,
        },
      });
      setGetZoom(newZoom);
      setLat(newLat);
      setLng(newLng);
    }
  };

  useEffect(() => {
    if (options.shared) {
      props.eventBus.subscribe(ShareZoomEvent, (event: ShareZoomEvent) => {
        const currentZoom = event.payload.zoom;
        const currentLat = event.payload.lat;
        const currentLng = event.payload.lng;
        if (currentZoom !== getZoom) {
          setGetZoom(currentZoom);
        }
        if (currentLat !== getLat) {
          setLat(currentLat);
        }
        if (currentLng !== getLng) {
          setLng(currentLng);
        }
      });
    }
  }, []);

  const setoptions = cloneDeep(options.reduceOptions);
  delete setoptions.fields;
  delete setoptions.limit;
  delete setoptions.values;

  const bigData = generalBigData(fieldConfig, options.reduceOptions, replaceVariables, theme, data.series, timeZone);
  const bigData2 = generalBigData(fieldConfig, setoptions, replaceVariables, theme, data.series, timeZone);
  // * prepare fields for auto query selection
  const frames = prepareGraphableFields(data?.series, theme);

  const bigData3 = generalBigData(fieldConfig, options.reduceOptions, replaceVariables, theme, frames!, timeZone);

  const dataFromAutoQuery = frames ? autoQueryData(frames, bigData3) : null;
  const dataFromGeohashQuery = geohashQueryData(bigData3, options.querySelections);
  const dataFromCustomMarker = customMarkerData(bigData2, options.customMarkers);
  const dataFromCustomMarkerHeat = customMarkerDataHeat(bigData2, options.customMarkersHeat);

  let heatmapColors: string[] = [];
  if (options.heatmapColor === 'Custom Palette') {
    heatmapColors = getColorArrayFromCustomPalette(fieldConfig, true);
  } else {
    heatmapColors = ColorGradient[options.heatmapColor];
  }

  return (
    <div
      className={cx(
        theme.isDark && 'googlemaps-dark',
        styles.wrapper,
        css`
          width: ${width}px;
          height: ${height}px;
        `
      )}
    >
      <GoogleBaseMap
        width={width}
        height={height}
        bigData={bigData}
        bigData2={bigData2}
        onOptionsChange={onOptionsChange}
        options={options}
        fieldConfig={fieldConfig}
        setSharedZoom={setSharedZoom}
        getZoom={getZoom}
        getLat={getLat}
        getLng={getLng}
        bigData3={bigData3}
        frames={frames!}
        dataFromAutoQuery={dataFromAutoQuery}
        dataFromGeohashQuery={dataFromGeohashQuery}
        dataFromCustomMarker={dataFromCustomMarker}
        dataFromCustomMarkerHeat={dataFromCustomMarkerHeat}
        timeZone={timeZone}
        series={data.series}
        heatMapColors={heatmapColors}
        timeRange={timeRange}
      />
    </div>
  );
};

const getStyles = stylesFactory(() => {
  return {
    wrapper: css`
      position: relative;
    `,
    textBox: css`
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
    `,
  };
});
