import {
  DataFrame,
  FieldType,
  GrafanaTheme,
  Field,
  ArrayVector,
  FieldConfigSource,
  getColorForTheme,
  dateTimeFormat,
  getTimeZone,
  systemDateFormats,
} from '@grafana/data';
import { checkValidColors } from '@grafana/ui';
import darkTheme from '@grafana/ui/src/themes/dark';
import tinycolor from 'tinycolor2';

export function prepareGraphableFields(series: DataFrame[], theme: GrafanaTheme): DataFrame[] | null {
  if (!series?.length) {
    return null;
  }

  let copy: Field;

  const frames: DataFrame[] = [];

  for (let frame of series) {
    const fields: Field[] = [];

    let hasTimeField = false;
    let hasValueField = false;
    let hasStringField = false;

    for (const field of frame.fields) {
      switch (field.type) {
        case FieldType.time:
          hasTimeField = true;
          fields.push(field);
          break;
        case FieldType.number:
          hasValueField = true;
          copy = {
            ...field,
            values: new ArrayVector(
              field.values.toArray().map((v) => {
                if (!(Number.isFinite(v) || v === null)) {
                  return null;
                }
                return v;
              })
            ),
          };
          fields.push(copy);
          break; // ok
        case FieldType.string:
          hasStringField = true;
          fields.push(field);
          break;
      }
    }

    if (hasTimeField && hasValueField && hasStringField) {
      frames.push({
        ...frame,
        fields,
      });
    }
  }
  if (frames.length) {
    return frames;
  }

  return null;
}

export const getColorArrayFromCustomPalette = (fieldConfig: FieldConfigSource<any>, isGoogleMap: boolean) => {
  let heatmapColors: string[] = [];

  if (fieldConfig.defaults.customPalette?.mode === 'loader') {
    if (
      fieldConfig.defaults.customPalette?.rawColors.split(';') &&
      fieldConfig.defaults.customPalette?.rawColors.split(';').length >= 2
    ) {
      const rawColors = [...fieldConfig.defaults.customPalette?.rawColors.split(';')];
      heatmapColors = checkValidColors(rawColors);
      // reversing the color array as starting colors are darker
      heatmapColors.reverse();
    }
  } else {
    if (fieldConfig.defaults.customPalette?.colors && fieldConfig.defaults.customPalette?.colors.length >= 2) {
      heatmapColors = [...fieldConfig.defaults.customPalette?.colors];
      heatmapColors.reverse();
    }
  }

  heatmapColors = heatmapColors.map((color) => getRGBColor(color));

  if (isGoogleMap) {
    heatmapColors.unshift('rgba(0,0,0,0)');
  }
  return heatmapColors;
};

export const getRGBColor = (colorStr: string, alpha?: number) => {
  return tinycolor(getColorForTheme(colorStr, darkTheme))
    .setAlpha(alpha || 1)
    .toRgbString();
};

const timeUnitSize = {
  second: 1000,
  minute: 60 * 1000,
  hour: 60 * 60 * 1000,
  day: 24 * 60 * 60 * 1000,
  month: 28 * 24 * 60 * 60 * 1000,
  year: 365 * 24 * 60 * 60 * 1000,
};

/** Format time axis ticks */
export function formatTime(v: number | string, foundIncr: number) {
  const timeZone = getTimeZone();
  const yearRoundedToDay = Math.round(timeUnitSize.year / timeUnitSize.day) * timeUnitSize.day;
  const incrementRoundedToDay = Math.round(foundIncr / timeUnitSize.day) * timeUnitSize.day;

  let format = systemDateFormats.interval.minute;
  if (foundIncr < timeUnitSize.second) {
    format = systemDateFormats.interval.second.replace('ss', 'ss.SS');
  } else if (foundIncr <= timeUnitSize.minute) {
    format = systemDateFormats.interval.second;
  } else if (foundIncr <= timeUnitSize.hour) {
    format = systemDateFormats.interval.minute;
  } else if (foundIncr <= timeUnitSize.day) {
    format = systemDateFormats.interval.hour;
  } else if (foundIncr <= timeUnitSize.month) {
    format = systemDateFormats.interval.day;
  } else if (incrementRoundedToDay === yearRoundedToDay) {
    format = systemDateFormats.interval.year;
  } else if (foundIncr <= timeUnitSize.year) {
    format = systemDateFormats.interval.month;
  }

  let x = parseInt(typeof v === 'number' ? `${v}` : v, 10);
  return dateTimeFormat(x, { format, timeZone });
}
