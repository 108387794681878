import { VariableModel } from '@grafana/data';

interface FinalResultI {
  from: string;
  query: string;
  to: string;
}
interface VarsSelectedI {
  name: string;
  selected: string[];
}

/**
 * It will refactor all the data. Whatever global variable you have input into the data of the panel in the panelData, it will match that variable with {globalVarSelectedValues} and replace its selected values with panelData's variable.
 * @function
 * @param {Array} globalVarSelectedValues - It will contain selected values of the entire global variables which will be obtained from the {getNodeName function}
 * @param {SankeyDataI} panelData - It will contain the data of the whole panel.
 * @returns myFinalRefactoredData - refectored panelData Before prepareSankeyData
 */
export function refactorVariable(selectedData: VarsSelectedI[], sankeyData: FinalResultI[]) {
  var finalResult: FinalResultI[] = [];
  var fromSankeyData: FinalResultI[] = [];

  // 1. RUN FOR FROM KEY
  // 2. RUN FOR TO KEY

  // THIS WILL WORK ONLY FOR FROM KEY
  sankeyData.map((element: FinalResultI) => {
    if (element.from.includes('$')) {
      // This will work when the given property includes $
      selectedData.map((variable: VarsSelectedI) => {
        if (variable.name === element.from) {
          variable.selected.map((option: string) => {
            // fromsankeyData is the temporary Variable
            fromSankeyData.push({
              from: option,
              query: element.query,
              to: element.to,
            });
          });
        }
      });
    } else {
      // If not includes the $ it will directly passed out the values
      fromSankeyData.push({
        from: element.from,
        query: element.query,
        to: element.to,
      });
    }
  });

  // THIS WILL WORK ONLY FOR TO KEY
  fromSankeyData.map((element: FinalResultI) => {
    // This will work when the given property includes $
    if (element.to.includes('$')) {
      selectedData.map((variable: VarsSelectedI) => {
        if (variable.name === element.to) {
          variable.selected.map((option: string) => {
            // finalResult is the final output
            finalResult.push({
              from: element.from,
              query: element.query,
              to: option,
            });
          });
        }
      });
    } else {
      // If not includes the $ it will directly passed out the values
      finalResult.push({
        from: element.from,
        query: element.query,
        to: element.to,
      });
    }
  });
  return finalResult;
}

/**
 * Returns selected values from a global variable.
 * @function
 * @param {VariableModel[]} vars - Global variables data
 * @returns {VarsSelectedI[]} - Selected values
 */

export function getNodeName(vars: VariableModel[]) {
  const varsSelected: VarsSelectedI[] = [];
  vars.map((element: any) => {
    // created {selected} becaues sometimes multiple select values{ so i have to pack it into array}
    var selected: string[] = [];
    element.options.map((option: { selected: boolean; value: string }) => {
      if (option.selected === true) {
        selected.push(option.value);
      }
    });
    // this will push the selected values with his name
    varsSelected.push({
      name: `$${element.name}`,
      selected: selected,
    });
  });
  return varsSelected;
}
