import React from 'react';
import { sharedSingleStatMigrationHandler } from '@grafana/ui';
import { PanelPlugin } from '@grafana/data';
import { MatterportOptions, addStandardDataReduceOptions } from './types';
import { Matterport } from './Matterport';
import { statPanelChangedHandler } from './StatMigrations';
import { SceneEditor } from './Editors/SceneEditor';
import { TagEditor } from './Editors/TagEditor';
import { Reloader } from './Editors/Reloader';

export const plugin = new PanelPlugin<MatterportOptions>(Matterport)
  .useFieldConfig()
  .setPanelOptions((builder) => {
    addStandardDataReduceOptions(builder);
    builder
      .addBooleanSwitch({
        path: 'lock',
        name: 'Lock Positioning',
        description: 'Locks on map co-ordinate display',
        defaultValue: false,
      })
      .addTextInput({
        path: 'sid',
        name: 'SID',
        description: 'Provide Matterport SID',
        defaultValue: 'j4RZx7ZGM6T',
      })
      .addNumberInput({
        path: 'cameraP',
        name: 'Camera Position',
        description: 'Camera position when start',
        defaultValue: 25,
      })
      .addNumberInput({
        path: 'cameraX',
        name: 'Horizontal Movement',
        description: 'Camera position when start',
        defaultValue: -0.15,
      })
      .addNumberInput({
        path: 'cameraY',
        name: 'Vertical Movement',
        description: 'Camera position when start',
        defaultValue: 0,
      })
      .addCustomEditor({
        id: 'sceneList',
        path: 'sceneList',
        name: 'Object Placement',
        // description: 'To Link Dashboard',
        defaultValue: { scenes: [], queries: [], currLoc: {} },
        editor(props: any) {
          return <SceneEditor sceneList={props.value} onChange={props.onChange} />;
        },
        // showIf: (options: StackedBarOptions) => options.editBar,
      })
      .addCustomEditor({
        id: 'tagList',
        path: 'tagList',
        name: 'Tag Placement',
        // description: 'To Link Dashboard',
        defaultValue: { tags: [], currLoc: {} },
        editor(props: any) {
          return <TagEditor tagList={props.value} onChange={props.onChange} />;
        },
        // showIf: (options: StackedBarOptions) => options.editBar,
      })
      .addCustomEditor({
        id: 'reloader',
        path: 'reloader',
        name: 'Reload Scene',
        // description: 'To Link Dashboard',
        defaultValue: 0,
        editor(props: any) {
          return <Reloader reloader={props.value} onChange={props.onChange} />;
        },
        // showIf: (options: StackedBarOptions) => options.editBar,
      });
  })
  .setNoPadding()
  .setPanelChangeHandler(statPanelChangedHandler)
  .setMigrationHandler(sharedSingleStatMigrationHandler);
