import React from 'react';
import { ImageOptions } from './types';
import { PanelProps } from '@grafana/data';
import { NormalImage, AspectRatioImage } from './components/Image';

interface Props extends PanelProps<ImageOptions> {}

export const ImagePanel: React.FC<Props> = ({ options, width, height, timeRange }) => {
  let imageUrl = '';
  if (options.toShowImg && options.toShowImg !== '') {
    imageUrl = `/api/image/${options.toShowImg}`;
  }
  if (options.text !== undefined && options.text !== '') {
    imageUrl = options.text;
  }
  // Don't proceed further if there is no image
  if (imageUrl === '') {
    return (
      <div className="panel-empty">
        <p>Please select an image</p>
      </div>
    );
  }

  /*
   * Url types:
   * https://www.google.com/ - Works
   * http://localhost:3000/ - Works
   * /d/8EaR5rP4k/digital-twin?editPanel=2&orgId=2 - Works
   * www.google.com/ - Will not work. Need to add some code for this case.
   */
  let urlImage = options.toShowDashboard.dashboardUrl;
  if (urlImage) {
    if (urlImage.includes('/d/') || urlImage.includes('https://') || urlImage.includes('http://')) {
      // Do nothing
    } else {
      urlImage = 'https://' + urlImage;
    }
  }
  return (
    <div>
      {options.aspectRatio ? (
        <AspectRatioImage
          imageUrl={imageUrl}
          onClick={options.onClick}
          dashboardUrl={urlImage}
          width={width}
          height={height}
          dashboardName={options.toShowDashboard.dashboardName}
          timeRange={timeRange}
          drawerTimeRange={options.toShowDashboard.drawerLinkTime}
        />
      ) : (
        <NormalImage
          imageUrl={imageUrl}
          onClick={options.onClick}
          dashboardUrl={urlImage}
          width={width}
          height={height}
          dashboardName={options.toShowDashboard.dashboardName}
          timeRange={timeRange}
          drawerTimeRange={options.toShowDashboard.drawerLinkTime}
        />
      )}
    </div>
  );
};
