import { isNumber } from 'lodash';
import { BoxPlotData } from '../types';

import {
  DataFrame,
  FieldConfig,
  FieldType,
  formattedValueToString,
  getFieldColorModeForField,
  getFieldDisplayName,
  GrafanaTheme,
  TimeRange,
  TimeZone,
} from '@grafana/data';
import {
  UPlotConfigBuilder,
  AxisPlacement,
  DrawStyle,
  GraphFieldConfig,
  PointVisibility,
  ScaleDirection,
  ScaleOrientation,
  ScaleDistribution,
} from '@grafana/ui';

import { CandleOptions, getConfig } from './candle';
import { CandleStickChartProps } from './GraphNG';
import { getMax, getMin } from '../calculation/CandleDataCalc';
import uPlot, { Scale } from 'uplot';

interface ScaleProps {
  scaleKey: string;
  isTime?: boolean;
  min?: number | null;
  max?: number | null;
  softMin?: number | null;
  softMax?: number | null;
  range?: Scale.Range;
  distribution?: ScaleDistribution;
  orientation: ScaleOrientation;
  direction: ScaleDirection;
  log?: number;
}
const defaultFormatter = (v: any) => (v == null ? '-' : v.toFixed(1));
/**
 * @constant
 * @default
 */
export const UPlot_UNIT = '__fixed';

/**
 * @constant
 * @default
 */
const defaultConfig: GraphFieldConfig = {
  drawStyle: DrawStyle.Line,
  showPoints: PointVisibility.Auto,
  axisPlacement: AxisPlacement.Auto,
};
/**
 * uplot config builder for candle stick chart
 * @param {Dataframe} frame
 * @param {GrafanaTheme} theme
 * @param {any} getTimeRange
 * @param {any} getTimeZone
 * @param {BoxPlotData} dataAll
 * @param {CandleStickChartProps} otherProps
 * @returns {UPlotConfigBuilder} builder
 */
export function preparePlotConfigBuilder(
  frame: DataFrame,
  theme: GrafanaTheme,
  getTimeRange: () => TimeRange,
  getTimeZone: () => TimeZone,
  dataAll: BoxPlotData,
  otherProps: CandleStickChartProps
): UPlotConfigBuilder {
  const { toCluster, customClusterSize, querySwitch, ohlcData, queryIndex, upcolor, downcolor } = otherProps;

  const builder = new UPlotConfigBuilder(getTimeZone);
  const opts: CandleOptions = {
    data: frame,
    dataAll: dataAll,
    ohlcData: ohlcData,
    queryIndex: queryIndex!,
    querySwitch: querySwitch,
    toCluster: toCluster,
    customClusterSize: customClusterSize,
    upcolor: upcolor,
    downcolor: downcolor,
  };
  const config = getConfig(opts, theme);
  builder.setCursor(config.cursor);
  builder.setSelect(config.select);
  /**
   * clear the canvas
   */
  builder.addHook('drawClear', config.drawClear);
  /**
   * adding tooltip hook
   */
  builder.setShowTooltip(config.tooltipMaker);
  /**
   * uplot hook set to draw candles from candle.ts file
   */
  builder.addHook('draw', config.drawCandles);

  // X is the first field in the aligned frame
  const xField = frame.fields[0];
  let seriesIndex = 0;
  const incrNew = toCluster === 0 ? customClusterSize * 60 * 1e3 : toCluster * 60 * 1e3;
  const minCandle = getMin(dataAll.candle?.low?.values!, dataAll.candle?.low?.values?.length!);
  let maxArray = [];
  for (let index = 1; index < frame.fields.length; index++) {
    const element = frame.fields[index].values.toArray();
    const max = getMax(element, element.length);
    maxArray.push(max);
  }
  const maxCandle = getMax(maxArray, maxArray.length);
  /**
   * builder.addScale and builder.addAxis.
   * below two are for x axis.
   */
  builder.addScale({
    scaleKey: 'x',
    orientation: ScaleOrientation.Horizontal,
    direction: ScaleDirection.Right,
    distribution: ScaleDistribution.Ordinal,
    isTime: true,
    /**
     * defining custom x axis range
     */
    range: querySwitch
      ? () => {
          const r = getTimeRange();
          return [r.from.valueOf(), r.to.valueOf()];
        }
      : [
          dataAll.candle?.time?.values[0]! - incrNew,
          dataAll.candle?.time?.values[dataAll.candle?.time?.values.length - 1]! + incrNew,
        ],
  });
  /**
   * splits and values only required when query switch in off
   */
  /**
   * * splits and values accepts array of same length
   * * splits - divided axis into no. parts as the length of array
   * * values - values to show on axis
   */
  builder.addAxis({
    scaleKey: 'x',
    isTime: true,
    splits: querySwitch ? undefined : dataAll.candle?.time?.values,
    values: querySwitch ? undefined : config.xValuesCandleTime,
    placement: AxisPlacement.Bottom,
    timeZone: getTimeZone(),
    theme,
    grid: false,
  });

  let indexByName: Map<string, number> | undefined = undefined;
  /**
   * below are for y axis.
   * looping according to fields length.
   */
  for (let i = 0; i < frame.fields.length; i++) {
    const field = frame.fields[i];

    const config = field.config as FieldConfig<GraphFieldConfig>;

    const customConfig: GraphFieldConfig = {
      ...defaultConfig,
      ...config.custom,
    };

    if (field === xField || field.type !== FieldType.number) {
      continue;
    }
    field.state!.seriesIndex = seriesIndex++;

    const fmt = field.display ?? defaultFormatter;
    const scaleKey = config.unit || UPlot_UNIT;
    const colorMode = getFieldColorModeForField(field);
    // const scaleColor = getFieldSeriesColor(field, theme);
    // const seriesColor = scaleColor.color;

    // The builder will manage unique scaleKeys and combine where appropriate
    builder.addScale({
      scaleKey,
      orientation: ScaleOrientation.Vertical,
      direction: ScaleDirection.Up,
      distribution: customConfig.scaleDistribution?.type,
      log: customConfig.scaleDistribution?.log,
      min: field.config.min,
      max: field.config.max,
      softMin: customConfig.axisSoftMin,
      softMax: customConfig.axisSoftMax,
      range: querySwitch
        ? undefined
        : [minCandle >= 0 ? 0 : minCandle * 0.8, maxCandle >= 0 ? maxCandle * 1.2 : maxCandle * 0.8],
    });

    if (customConfig.axisPlacement !== AxisPlacement.Hidden) {
      builder.addAxis({
        scaleKey,
        label: customConfig.axisLabel,
        size: customConfig.axisWidth,
        placement: customConfig.axisPlacement ?? AxisPlacement.Auto,
        formatValue: (v) => formattedValueToString(fmt(v)),
        theme,
      });
    }

    const showPoints = customConfig.drawStyle === DrawStyle.Points ? PointVisibility.Always : customConfig.showPoints;

    let { fillOpacity } = customConfig;
    let pathBuilder: uPlot.Series.PathBuilder | undefined = undefined;
    let pointsBuilder: uPlot.Series.Points.Show | undefined = undefined;

    /**
     * disable default renderers. Most important step is played here.
     * restricting uplot canvas to plot line chart when index is less and equal to 4.
     */
    if (i <= 4) {
      pathBuilder = () => null;
      pointsBuilder = () => undefined;
    }
    if (customConfig.fillBelowTo) {
      if (!indexByName) {
        indexByName = getNamesToFieldIndex(frame);
      }
      const t = indexByName.get(getFieldDisplayName(field, frame));
      const b = indexByName.get(customConfig.fillBelowTo);
      if (isNumber(b) && isNumber(t)) {
        builder.addBand({
          series: [t, b],
          fill: null as any, // using null will have the band use fill options from `t`
        });
      }
      if (!fillOpacity) {
        fillOpacity = 35; // default from flot
      }
    }

    builder.addSeries({
      scaleKey,
      pathBuilder,
      pointsBuilder,
      showPoints,
      colorMode,
      fillOpacity,
      theme,
      drawStyle: customConfig.drawStyle!,
      /**
       * line color and point color both are deciding here on the basis of color array.
       * query switch is playing role and index color passing into it.
       */
      lineColor: querySwitch ? dataAll.candle?.color![i - 1] : dataAll.candle?.color![i - 4],
      // lineColor: dataAll.candle?.color![i - 1],
      lineWidth: customConfig.lineWidth,
      lineInterpolation: customConfig.lineInterpolation,
      lineStyle: customConfig.lineStyle,
      barAlignment: customConfig.barAlignment,
      pointSize: customConfig.pointSize,
      pointColor: querySwitch ? dataAll.candle?.color![i - 1] : dataAll.candle?.color![i - 4],
      spanNulls: customConfig.spanNulls || false,
      show: !customConfig.hideFrom?.graph,
      gradientMode: customConfig.gradientMode,
      thresholds: config.thresholds,

      // The following properties are not used in the uPlot config, but are utilized as transport for legend config
      dataFrameFieldIndex: {
        fieldIndex: i,
        frameIndex: 0,
      },
      fieldName: getFieldDisplayName(field, frame),
      hideInLegend: customConfig.hideFrom?.legend,
    });
  }

  return builder;
}
/**
 * returns the names array
 * @param {DataFrame } frame
 * @returns {Map<string, number>} names
 */
export function getNamesToFieldIndex(frame: DataFrame): Map<string, number> {
  const names = new Map<string, number>();
  for (let i = 0; i < frame.fields.length; i++) {
    names.set(getFieldDisplayName(frame.fields[i], frame), i);
  }
  return names;
}

//* For scale update in candlePlot.
export function setBoxplotMinMaxRangeCandle(min: number, max: number) {
  const RangeMinmax = uPlot.rangeNum(min, max, 0.1, true);
  const newrange: uPlot.Scale.Range = RangeMinmax;
  const newscale: ScaleProps = {
    min: min,
    max: max,
    scaleKey: '__fixed',
    range: newrange,
    orientation: 1,
    direction: 1,
    softMax: newrange[1],
    softMin: newrange[0],
  };
  return newscale;
}
