import React, { useEffect, useState } from 'react';
import { TransformerRegistryItem, TransformerUIProps, classicColors, DataFrame } from '@grafana/data';
import { prepareTreeDataTransformer, PrepareTreeDataOptions } from './prepareTreeData';
import { InlineField, InlineFieldRow, MultiSelect, Button, Select } from '@grafana/ui';
interface TypeData {
  id: string | number;
  color: string;
  children: TypeData[];
  [parentId: string]: any;
  size: number;
  name: string;
}

type queryOptionsType = {
  color: string;
  label: string | undefined;
  size: number;
  value: string | undefined;
  children?: any;
};
type valueType = {
  label: string;
};

export function PrepareTreeDataEditor(props: TransformerUIProps<PrepareTreeDataOptions>): React.ReactElement {
  const { options, onChange, input } = props;
  const [data, setData] = useState<TypeData[]>([]);
  let [queryOptions, setQueryOptions] = useState<queryOptionsType[]>([]);
  let [parents, setParents] = useState<TypeData[]>([]);
  let [value, setValue] = useState<valueType[]>([]);
  function getRandomColor(i: number) {
    let index = i % 56;
    let color = classicColors[index];
    return color;
  }

  const getValueParents = (array: TypeData[]) => {
    let a = array.filter((item: TypeData) => item.parentId === null);
    let b = a.map((item: TypeData) => ({ label: item.name }));
    setValue(b);
    return null;
  };

  useEffect(() => {
    let op: queryOptionsType[] = [];

    input.map((item: DataFrame, index: number) => {
      if (!item.meta) {
        op.push({
          label: item.fields[1].name,
          value: item.fields[1].name,
          size: 80,
          color: getRandomColor(index),
        });
      } else {
        op.push({
          label: item.name,
          value: item.name,
          size: 80,
          color: getRandomColor(index),
        });
      }
    });
    getValueParents(parents);
    setQueryOptions(op);
    if (options.format) {
      let res: TypeData[] = [];
      const cb = (e: TypeData) => {
        res.push({
          id: e.id,
          name: e.name,
          parentId: e.parentId,
          children: [],
          size: e.size,
          color: e.color,
        });
        e.children && e.children.forEach(cb);
      };
      options.format.forEach(cb);
      //@ts-ignore
      setParents([...res]);
    }
  }, [props.input]);

  const onParentSelect = (item: queryOptionsType) => {
    console.log(item);

    if (item.children) {
      parents[0] = { id: 0, name: item.label!, children: item.children, size: item.size, color: item.color };
    } else {
      parents[0] = {
        id: 0,
        name: item.label!,
        parentId: null,
        children: [],
        size: item.size,
        color: item.color,
      };
    }

    setParents(parents);
  };

  const removeDuplication = (arr: TypeData[]) => {
    var finalData: TypeData[] = [];
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      if (finalData.length === 0) {
        finalData.push(element);
      } else {
        var condition = true;
        for (let index = 0; index < finalData.length; index++) {
          const e = finalData[index];
          if (element.name === e.name && element.parentId === e.parentId) {
            condition = false;
          }
        }
        if (condition) {
          finalData.push(element);
        }
      }
    }
    return finalData;
  };

  const onChildSelect = (value: queryOptionsType[], parentItem: TypeData, index: number) => {
    let arraywithChildren: TypeData[] | any = [];
    arraywithChildren = value.map((c: queryOptionsType, i: number) => {
      for (let j = 0; j < parents.length; j++) {
        if (c.label !== parents[j].name && parentItem.id !== parents[j].parentId) {
          return {
            name: c.label,
            parentId: parentItem.id,
            id: parents.length,
            children: [],
            size: c.size,
            color: c.color,
          };
        }
      }
      return;

      // setParents([...parents, { name: c.label, value: 3, parentId: parentItem.id, id: uuidv4() }]);
    });
    // var finalData = [...parents, ...arraywithChildren];
    var finalData: TypeData[] = removeDuplication([...parents, ...arraywithChildren]);
    setParents(finalData);
  };

  useEffect(() => {
    var dataTo = parents.reduce(function (r: any, a: TypeData) {
      function getParent(s: any, b: any) {
        return b.id === a.parentId ? b : b.children && b.children.reduce(getParent, s);
      }

      var index = 0,
        node;
      if ('parentId' in a) {
        node = r.reduce(getParent, {});
      }
      if (node && Object.keys(node).length) {
        let find = false;
        for (let y = 0; y < node.children.length; y++) {
          if (node.children[y].name === a.name) {
            find = true;
          }
        }
        if (!find) {
          node.children = node.children || [];
          node.children.push(a);
          node.size = 0;
        }
        // node.children = node.children || [];
        // node.children.push(a);
      } else {
        while (index < r.length) {
          if (r[index].parentId === a.id) {
            a.children = (a.children || []).concat(r.splice(index, 1));
          } else {
            index++;
          }
        }
        r.push(a);
      }
      return r;
    }, []);
    setData([...dataTo]);
  }, [parents]);

  const onButtonSave = (e: React.SyntheticEvent<EventTarget>) => {
    onChange({
      ...options,
      format: data,
    });
  };
  const getAllParents = (id: string, array: TypeData[]) => {
    let all: TypeData[] = [];
    const nested = (id: string, array2: TypeData[]) => {
      array.filter((item: TypeData) => {
        if (item.id === id) {
          all.push(item);
          if (item.parentId !== null) {
            nested(item.parentId, array2);
          }
        }
      });
    };
    nested(id, array);
    return all.reverse();
  };

  const findItemNested = (arr: TypeData[], itemId: string, nestingKey: string): string =>
    arr.reduce((a: any, item: TypeData) => {
      if (a) {
        return a;
      }
      if (item.id === itemId) {
        if (item.parentId !== null) {
          let b = getAllParents(item.parentId, parents)
            .map((item: TypeData) => item.name)
            .join('->');
          return `${b}->${item.name}`;
        } else {
          return item.name;
        }
      }
      if (item[nestingKey]) {
        return findItemNested(item[nestingKey], itemId, nestingKey);
      }
    }, null);
  const getValue = (id: string | number, array: TypeData[]) => {
    let a = array.filter((item: TypeData) => id === item.parentId);
    let b = a.map((item: TypeData) => ({ label: item.name }));
    return b;
  };

  return (
    <>
      {value.length > 0 && (
        <InlineFieldRow>
          <InlineField label="Root">
            <Select defaultValue={value} width={50} options={queryOptions} onChange={onParentSelect} />
          </InlineField>
        </InlineFieldRow>
      )}
      {value.length === 0 && (
        <InlineFieldRow>
          <InlineField label="Root">
            <Select width={50} options={queryOptions} onChange={onParentSelect} />
          </InlineField>
        </InlineFieldRow>
      )}
      {parents &&
        parents.map((item: TypeData, index: number) => {
          if (item.parentId !== null) {
            return (
              <InlineField
                key={index}
                style={{
                  marginLeft:
                    (`${findItemNested(parents, item.parentId, 'children')} -> ${item.name}`.split('->').length - 1) *
                    20,
                }}
                label={`${findItemNested(parents, item.parentId, 'children')} -> ${item.name}`}
              >
                <MultiSelect
                  width={50}
                  defaultValue={getValue(item.id, parents)}
                  options={queryOptions}
                  onChange={(value: queryOptionsType[]) => onChildSelect(value, item, index)}
                />
              </InlineField>
            );
          } else {
            return (
              <InlineFieldRow key={index}>
                <InlineField label={`Root->${item.name}`}>
                  <MultiSelect
                    width={50}
                    // width={30}
                    defaultValue={getValue(item.id, parents)}
                    options={queryOptions}
                    onChange={(value: queryOptionsType[]) => onChildSelect(value, item, index)}
                  />
                </InlineField>
              </InlineFieldRow>
            );
          }
        })}
      <Button onClick={(e: React.SyntheticEvent<EventTarget>) => onButtonSave(e)}>Save </Button>
    </>
  );
}

export const prepareTreeDataTransformerRegistryItem: TransformerRegistryItem<PrepareTreeDataOptions> = {
  id: prepareTreeDataTransformer.id,
  editor: PrepareTreeDataEditor,
  transformation: prepareTreeDataTransformer,
  name: prepareTreeDataTransformer.name,
  description: prepareTreeDataTransformer.description,
};
