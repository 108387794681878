import { SingleStatBaseOptions, VizLegendOptions } from '@grafana/ui';
import { ReducerID, standardEditorsRegistry, PanelOptionsEditorBuilder } from '@grafana/data';

/**
 * Chord chart options
 * @typedef {Object} ChordChartOptions
 * @property {ChordDataType} chordData
 * @property {ChordChartLegendOptions} legend
 * @property {boolean} dataLabels
 * @extends {SingleStatBaseOptions}
 * @extends {OptionsWithTooltip}
 */
export interface ChordChartOptions extends SingleStatBaseOptions, OptionsWithTooltip {
  chordData: ChordDataType;
  legend: ChordChartLegendOptions;
  dataLabels: boolean;
}

/**
 * @typedef {Object} ChordDataType
 * @property {Array<QueryNodeI>} queryNode
 * @property {Array<LabelValueI>} links
 */
export interface ChordDataType {
  queryNode: QueryNodeI[];
  links: LabelValueI[];
}
/**
 * @typedef {Object} QueryNodeI
 * @property {Array<LabelValueI>} source
 * @property {Array<LabelValueI>} target
 */
export interface QueryNodeI {
  source: LabelValueI;
  target: LabelValueI;
}
/**
 * @typedef {Object} LabelValueI
 * @property {string} label
 * @property {string} value
 */
export interface LabelValueI {
  label: string;
  value: string;
}
/**
 * @typedef {Object} Node
 * @property {string} id
 * @property {string} name
 * @property {string} value
 */
export interface Node {
  id: string;
  name: string;
  value: number;
}
/**
 * @typedef {Object} Chord
 * @property {string} source
 * @property {string} target
 * @property {number} sourceWeight
 * @property {number} targetWeight
 */
export interface Chord {
  source: string;
  target: string;
  sourceWeight: number;
  targetWeight: number;
}

/**
 * @enum {ChordChartLegendValues}
 */
export enum ChordChartLegendValues {
  Value = 'value',
  Percent = 'percent',
}
/**
 * @enum {ChordChartLabels}
 */
export enum ChordChartLabels {
  Name = 'name',
  Value = 'value',
  Percent = 'percent',
}

export interface ChordChartLegendOptions extends VizLegendOptions {
  values: ChordChartLegendValues[];
}
export interface OptionsWithTooltip {
  tooltip: VizTooltipOptions;
}
export type VizTooltipOptions = {
  mode: TooltipDisplayMode;
};
export enum TooltipDisplayMode {
  Single = 'single',
  Multi = 'multi',
  None = 'none',
}

export function addStandardDataReduceOptions(
  builder: PanelOptionsEditorBuilder<SingleStatBaseOptions>,
  includeOrientation = true
) {
  builder.addCustomEditor({
    category: ['Editor'],
    id: 'reduceOptions.calcs',
    path: 'reduceOptions.calcs',
    name: 'Value',
    description: 'Choose a reducer function / calculation',
    editor: standardEditorsRegistry.get('stats-picker').editor as any,
    defaultValue: [ReducerID.mean],
    //  Hides it when all values mode is on
    // showIf: (currentConfig) => currentConfig.reduceOptions.values === false,
  });
}
