import {
  CartesianCoords2D,
  DataFrame,
  TimeZone,
  getValueFormat,
  getFieldDisplayName,
  formattedValueToString,
} from '@grafana/data';
import React, { useLayoutEffect, useState } from 'react';
import { useMountedState } from 'react-use';
import uPlot from 'uplot';
import {
  Portal,
  VizTooltipContainer,
  UPlotConfigBuilder,
  SeriesTable,
  SeriesTableRowProps,
  findMidPointYPosition,
} from '@grafana/ui';
import { BoxPlotData } from '../types';

/**
 * tooltip plugin props
 * @typedef {Object} TooltipPluginProps
 * @property {TimeZone} timezone
 * @property {DataFrame} data frame for tooltip data
 * @property {UPlotConfigBuilder} config config to see tooltip enable or not setting up hook
 * @property {string} [mode] tooltip mode
 * @property {BoxPlotData} [dataMain]
 * @property {string} plotType
 * @property {string} boxPlotCateg
 * @property {string | undefined} chartType
 * @property {Array<number> | undefined} queryIndex
 */
interface TooltipPluginProps {
  timeZone: TimeZone;
  data: DataFrame;
  config: UPlotConfigBuilder;
  mode?: string;
  dataMain?: BoxPlotData;
  plotType: string;
  boxPlotCateg: string;
  chartType: any;
  queryIndex: any;
}

/**
 * @constant
 * @default
 */
const TOOLTIP_OFFSET = 10;

/**
 * tooltip component
 * @function
 * @param {TooltipPluginProps} props
 * @returns {JSX.Element}
 */
export const TooltipPluginWaterfall: React.FC<TooltipPluginProps> = ({
  mode,
  timeZone,
  config,
  queryIndex,
  ...otherProps
}) => {
  const [focusedSeriesIdx, setFocusedSeriesIdx] = useState<number | null>(null);
  const [focusedPointIdx, setFocusedPointIdx] = useState<number | null>(null);
  const [coords, setCoords] = useState<CartesianCoords2D | null>(null);
  // const [isActive, setIsActive] = useState<boolean>(false);
  const isMounted = useMountedState();
  // Add uPlot hooks to the config, or re-add when the config changed
  useLayoutEffect(() => {
    let plotInstance: uPlot | undefined = undefined;
    let bbox: DOMRect | undefined = undefined;

    const plotMouseLeave = () => {
      if (!isMounted()) {
        return;
      }
      setCoords(null);
      // setIsActive(false);
      plotInstance?.root.classList.remove('plot-active');
    };

    const plotMouseEnter = () => {
      if (!isMounted()) {
        return;
      }
      // setIsActive(true);
      plotInstance?.root.classList.add('plot-active');
    };

    // cache uPlot plotting area bounding box
    config.addHook('syncRect', (u: uPlot, rect: any) => {
      bbox = rect;
    });

    config.addHook('init', (u: uPlot) => {
      plotInstance = u;

      u.over.addEventListener('mouseleave', plotMouseLeave);
      u.over.addEventListener('mouseenter', plotMouseEnter);
    });

    const tooltipPlugin = config.getShowTooltip();
    if (tooltipPlugin) {
      config.addHook('setCursor', (u: uPlot) => {
        tooltipPlugin(
          setFocusedSeriesIdx,
          setFocusedPointIdx,
          (clear: any) => {
            if (clear) {
              setCoords(null);
              return;
            }

            if (!bbox) {
              return;
            }

            const { x, y } = positionTooltip(u, bbox);
            if (x !== undefined && y !== undefined) {
              setCoords({ x, y });
            }
          },
          u
        );
      });
    } else {
      config.addHook('setLegend', (u: uPlot) => {
        if (!isMounted()) {
          return;
        }
        setFocusedPointIdx(u.legend.idx!);
      });

      // default series/datapoint idx retireval
      config.addHook('setCursor', (u: uPlot) => {
        if (!bbox || !isMounted()) {
          return;
        }

        const { x, y } = positionTooltip(u, bbox);
        if (x !== undefined && y !== undefined) {
          setCoords({ x, y });
        } else {
          setCoords(null);
        }
      });

      config.addHook('setSeries', (_: any, idx: number) => {
        if (!isMounted()) {
          return;
        }
        setFocusedSeriesIdx(idx);
      });
    }

    return () => {
      setCoords(null);
      if (plotInstance) {
        plotInstance.over.removeEventListener('mouseleave', plotMouseLeave);
        plotInstance.over.removeEventListener('mouseenter', plotMouseEnter);
      }
    };
  }, [config, setCoords, setFocusedPointIdx]);

  if (focusedPointIdx === null) {
    return null;
  }

  const xValModifiedCandle = fmtDate(otherProps.data.fields[0].values.get(focusedPointIdx));

  let tooltip: React.ReactNode = null;
  /**
   * get unit from fields. used in calculation of value according to it
   */
  const unitGet = otherProps.data.fields[0].config.unit;
  const dec = otherProps.data.fields[1].config.decimals ?? 0;
  /**
   * gives value after formatting
   */
  const formatFunc = getValueFormat(unitGet || 'none');
  if (mode === 'single' && focusedSeriesIdx !== null) {
    const frame = otherProps.data;
    const fields = frame.fields;
    const fieldHigh = otherProps.data.fields[2];
    const fieldLow = otherProps?.dataMain?.waterfall?.valueArray;
    const formattedLowValue = formatFunc(fieldLow[focusedPointIdx], dec, null);
    const formattedHighValue = formatFunc(fieldHigh.values.get(focusedPointIdx), dec, null);
    let series: SeriesTableRowProps[] = [];

    series.push({
      color: '',
      label: 'Rise/Fall: ',
      value: `${formattedLowValue.prefix ?? ''}${formattedLowValue.text}${formattedLowValue.suffix ?? ''}`,
    });
    series.push({
      // color: otherProps.dataMain?.candle?.color![0],
      color: '',
      label: 'Current value: ',
      value: `${formattedHighValue.prefix ?? ''}${formattedHighValue.text}${formattedHighValue.suffix ?? ''}`,
    });

    for (let i = 5; i < fields.length; i++) {
      const field = frame.fields[i];
      /**
       * tooltip actual when index is for other fields which are not included in candle stick making
       */
      if (!field) {
        return null;
      }
      const display = field.display!(otherProps.data.fields[i].values.get(focusedPointIdx));
      series.push({
        color: otherProps.dataMain?.waterfall?.color![i - 4],
        label: getFieldDisplayName(field, frame),
        value: display ? formattedValueToString(display) : null,
        isActive: focusedSeriesIdx === i,
      });
    }
    tooltip = <SeriesTable series={series} timestamp={xValModifiedCandle} />;
  }
  return (
    <Portal>
      {tooltip && coords && (
        <VizTooltipContainer position={{ x: coords.x, y: coords.y }} offset={{ x: TOOLTIP_OFFSET, y: TOOLTIP_OFFSET }}>
          {tooltip}
        </VizTooltipContainer>
      )}
    </Portal>
  );
};

function isCursourOutsideCanvas({ left, top }: uPlot.Cursor, canvas: DOMRect) {
  if (left === undefined || top === undefined) {
    return false;
  }
  return left < 0 || left > canvas.width || top < 0 || top > canvas.height;
}

export function positionTooltip(u: uPlot, bbox: DOMRect) {
  let x, y;
  const cL = u.cursor.left || 0;
  const cT = u.cursor.top || 0;

  if (isCursourOutsideCanvas(u.cursor, bbox)) {
    const idx = u.posToIdx(cL);
    // when cursor outside of uPlot's canvas
    if (cT < 0 || cT > bbox.height) {
      let pos = findMidPointYPosition(u, idx);

      if (pos) {
        y = bbox.top + pos;
        if (cL >= 0 && cL <= bbox.width) {
          // find x-scale position for a current cursor left position
          x = bbox.left + u.valToPos(u.data[0][u.posToIdx(cL)], u.series[0].scale!);
        }
      }
    }
  } else {
    x = bbox.left + cL;
    y = bbox.top + cT;
  }

  return { x, y };
}
export function fmtDate(ts: any) {
  const d = new Date(ts);

  if (!isNaN(d.getTime())) {
    let text = d.toLocaleString().replace(',', '');
    return text;
  }
  return ts;
}
