import React from 'react';
import { Button, Select, useTheme } from '@grafana/ui';
import { FieldDisplay, getFieldDisplayValues } from '@grafana/data';

interface Props {
  toDeleteQuery: any[];
  onChange: (toDeleteQuery: any[]) => void;
  bigData: any;
}
let deleteQuery = '';
let deleteValue = '';

export function EditBar({ toDeleteQuery, onChange, bigData }: Props) {
  const [columnTracker, setcolumnTracker] = React.useState<any[]>([]);
  const [queryTracker, setqueryTracker] = React.useState<any[]>([]);
  const theme = useTheme();

  const onXLabelChanged1 = ({ value }: any) => {
    deleteQuery = value;
  };
  const onXLabelChanged2 = ({ value }: any) => {
    deleteValue = value;
  };
  const handleDeleteQueryValueClick = (target: any) => {
    const oldData: any = toDeleteQuery[0].deletedQueryValue;
    const pushData = {
      deleteQuery: deleteQuery,
      deleteValue: deleteValue,
    };
    oldData.push(pushData);
    toDeleteQuery[0].deletedQueryValue = oldData;
    onChange(toDeleteQuery);
  };
  const dataLoad = (event: React.MouseEvent) => {
    const getValues = (): FieldDisplay[] => {
      const { data, options, replaceVariables, fieldConfig } = bigData;
      return getFieldDisplayValues({
        fieldConfig,
        reduceOptions: options.reduceOptions,
        replaceVariables,
        theme: theme,
        data: data,
        timeZone: 'browser',
      });
    };
    const BigData = getValues();
    const newArrCol: any[] = [];
    const newArrQur: any[] = [];
    BigData.map((frame: any, index: number) => {
      newArrCol.push({
        value: `Column ${index + 1}`,
        label: `Column ${index + 1}`,
      });
      newArrQur.push({
        value: frame.display.title,
        label: frame.display.title,
      });
    });
    setcolumnTracker(newArrCol);
    setqueryTracker(newArrQur);
  };
  return (
    <div>
      <div className="gf-form gf-form--grow">
        <label className="gf-form-label width-9">Query Name</label>
        <Select
          options={queryTracker}
          onChange={onXLabelChanged1}
          value={queryTracker.find((item: any) => item.value === deleteQuery)}
        />
      </div>
      <div className="gf-form gf-form--grow">
        <label className="gf-form-label width-9">Value Name</label>
        <Select
          options={columnTracker}
          onChange={onXLabelChanged2}
          value={columnTracker.find((item: any) => item.value === deleteValue)}
        />
      </div>
      <div className="gf-form">
        <Button type="submit" variant="destructive" size="md" onClick={handleDeleteQueryValueClick}>
          <i className="fa fa-trash" />
        </Button>
      </div>
      <div className="gf-form gf-form--grow width-20">
        <label className="gf-form-label width-9">Reload Queries</label>
        <Button onClick={dataLoad} variant="secondary" size="md">
          <i className="fa fa-refresh" aria-hidden="true"></i>
        </Button>
      </div>
    </div>
  );
}
