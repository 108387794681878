import {
  CartesianCoords2D,
  DataFrame,
  FieldConfigSource,
  FieldType,
  formattedValueToString,
  getDisplayProcessor,
  getFieldDisplayName,
  TimeZone,
} from '@grafana/data';
import {
  findMidPointYPosition,
  Portal,
  SeriesTable,
  SeriesTableRowProps,
  UPlotConfigBuilder,
  useTheme,
  VizTooltipContainer,
} from '@grafana/ui';
import React, { useLayoutEffect, useState } from 'react';
import { useMountedState } from 'react-use';
import uPlot from 'uplot';
import { BarGraphMode, BarStackingMode } from '../types';
import { preparePlotDataForTotals } from './preparePlotData';

/**
 * tooltip plugin props
 * @typedef {Object} TooltipPluginProps
 * @property {TimeZone} timezone timezone
 * @property {DataFrame} data data frame for tooltip data
 * @property {UPlotConfigBuilder} config config to see tooltip enable or not setting up hook
 * @property {string} [mode] tooltip mode
 * @property {Array<any>} colorArray  color array
 * @property {string} noTime is bar graph related to time series
 * @property {Array<any>} colorArrayStack color in stack
 * @property {BarStackingMode} stacking stacking mode
 * @property {FieldConfigSource} fieldConfig field config
 * @property {Method} [renderTooltip]
 */
interface TooltipPluginProps {
  timeZone: TimeZone;
  data: DataFrame;
  config: UPlotConfigBuilder;
  mode?: string;
  colorArray: any[];
  noTime: string;
  colorArrayStack: any[];
  stacking: BarStackingMode;
  fieldConfig: FieldConfigSource;
  // Allows custom tooltip content rendering. Exposes aligned data frame with relevant indexes for data inspection
  // Use field.state.origin indexes from alignedData frame field to get access to original data frame and field index.
  /**
   * render tooltip
   * @param {DataFrame} alignedFrame
   * @param {number | null} seriesIdx
   * @param {number | null} datapointIdx
   * @method
   */
  renderTooltip?: (alignedFrame: DataFrame, seriesIdx: number | null, datapointIdx: number | null) => React.ReactNode;
}

const TOOLTIP_OFFSET = 10;

/**
 * tooltip component
 * @function
 * @param {TooltipPluginProps} props
 * @returns {JSX.Element}
 */

export const TooltipPluginBarChart: React.FC<TooltipPluginProps> = ({
  mode,
  timeZone,
  config,
  renderTooltip,
  ...otherProps
}) => {
  const theme = useTheme();
  const [focusedSeriesIdx, setFocusedSeriesIdx] = useState<number | null>(null);
  const [focusedPointIdx, setFocusedPointIdx] = useState<number | null>(null);
  const [coords, setCoords] = useState<CartesianCoords2D | null>(null);
  // const [isActive, setIsActive] = useState<boolean>(false);
  const isMounted = useMountedState();
  const pctStacked = otherProps.stacking === BarStackingMode.Percent;

  // Add uPlot hooks to the config, or re-add when the config changed
  useLayoutEffect(() => {
    let plotInstance: uPlot | undefined = undefined;
    let bbox: DOMRect | undefined = undefined;

    const plotMouseLeave = () => {
      if (!isMounted()) {
        return;
      }
      setCoords(null);
      // setIsActive(false);
      plotInstance?.root.classList.remove('plot-active');
    };

    const plotMouseEnter = () => {
      if (!isMounted()) {
        return;
      }
      // setIsActive(true);
      plotInstance?.root.classList.add('plot-active');
    };

    // cache uPlot plotting area bounding box
    config.addHook('syncRect', (u: uPlot, rect: any) => {
      bbox = rect;
    });

    config.addHook('init', (u: uPlot) => {
      plotInstance = u;

      u.over.addEventListener('mouseleave', plotMouseLeave);
      u.over.addEventListener('mouseenter', plotMouseEnter);

      // if (sync === DashboardCursorSync.Crosshair) {
      //   u.root.classList.add('shared-crosshair');
      // }
    });

    const tooltipPlugin = config.getShowTooltip();
    if (tooltipPlugin) {
      config.addHook('setCursor', (u: uPlot) => {
        tooltipPlugin(
          setFocusedSeriesIdx,
          setFocusedPointIdx,
          (clear: any) => {
            if (clear) {
              setCoords(null);
              return;
            }

            if (!bbox) {
              return;
            }

            const { x, y } = positionTooltip(u, bbox);
            if (x !== undefined && y !== undefined) {
              setCoords({ x, y });
            }
          },
          u
        );
      });
    } else {
      config.addHook('setLegend', (u: uPlot) => {
        if (!isMounted()) {
          return;
        }
        setFocusedPointIdx(u.legend.idx!);
      });

      // default series/datapoint idx retireval
      config.addHook('setCursor', (u: uPlot) => {
        if (!bbox || !isMounted()) {
          return;
        }

        const { x, y } = positionTooltip(u, bbox);
        if (x !== undefined && y !== undefined) {
          setCoords({ x, y });
        } else {
          setCoords(null);
        }
      });

      config.addHook('setSeries', (_: any, idx: number) => {
        if (!isMounted()) {
          return;
        }
        setFocusedSeriesIdx(idx);
      });
    }

    return () => {
      setCoords(null);
      if (plotInstance) {
        plotInstance.over.removeEventListener('mouseleave', plotMouseLeave);
        plotInstance.over.removeEventListener('mouseenter', plotMouseEnter);
      }
    };
  }, [config, setCoords, setFocusedPointIdx]);

  if (focusedPointIdx === null) {
    return null;
  }

  // GraphNG expects aligned data, let's take field 0 as x field. FTW
  let xField = otherProps.data.fields[0];
  let timeObj: any = xField.values;
  let timeArray = timeObj.buffer;
  // let foundIncr = timeArray[1] - timeArray[0];
  if (!xField) {
    return null;
  }
  // const xFieldFmt = xField.display || getDisplayProcessor({ field: xField, timeZone, theme });
  let tooltip: React.ReactNode = null;
  let decimalMultiplier = 100;
  if (otherProps.fieldConfig?.defaults?.decimals) {
    for (let index = 0; index < otherProps.fieldConfig.defaults.decimals; index++) {
      decimalMultiplier = decimalMultiplier * 10;
    }
  }
  // const xVal = xFieldFmt(xField!.values.get(focusedPointIdx)).text;
  const xValModified = fmtDate(timeArray[focusedPointIdx]);
  const alignedTotals = preparePlotDataForTotals(otherProps.data);

  if (!renderTooltip) {
    // when interacting with a point in single mode
    if (mode === 'single' && focusedSeriesIdx !== null && otherProps.noTime === BarGraphMode.Timeseries) {
      const field = otherProps.data.fields[focusedSeriesIdx];
      if (!field) {
        return null;
      }

      const fieldFmt = field.display || getDisplayProcessor({ field, timeZone, theme });
      const value = fieldFmt(field.values.get(focusedPointIdx));
      tooltip = (
        <SeriesTable
          series={[
            {
              color: value.color,
              label: getFieldDisplayName(field, otherProps.data),
              value: value ? formattedValueToString(value) : null,
            },
          ]}
          timestamp={xValModified}
        />
      );
    }
    if (mode === 'multi' && otherProps.noTime === BarGraphMode.Timeseries) {
      let series: SeriesTableRowProps[] = [];
      const frame = otherProps.data;
      const fields = frame.fields;
      for (let i = 1; i < fields.length; i++) {
        const field = frame.fields[i];
        if (
          !field ||
          field === xField ||
          field.type === FieldType.time ||
          field.type !== FieldType.number ||
          field.config.custom?.hideFrom?.tooltip ||
          field.config.custom?.hideFrom?.viz
        ) {
          continue;
        }
        const display = field.display!(otherProps.data.fields[i].values.get(focusedPointIdx));
        /**
         // * beautifully setting up tooltip when stacking mode is percentage - also using field decimal option
         // * same method is also present when No time series bar graph
         // * decimal auto - by multiplying 1000 we are selecting 4 digit after math.round then dividing by 100 so that we will percentage till two decimal points
         // * decimal - 0(zero) - multiplying 100 - only two digit selected - dividing by 1 - percentage upto zero decimal
         */
        series.push({
          color: otherProps.colorArrayStack[i - 1],
          label: getFieldDisplayName(field, frame),
          value: display
            ? pctStacked
              ? `${display.prefix ?? ''}${
                  Math.round(
                    (display.numeric / (alignedTotals[i][focusedPointIdx] ?? 1)) *
                      (otherProps.fieldConfig?.defaults?.decimals
                        ? decimalMultiplier
                        : otherProps.fieldConfig?.defaults?.decimals === 0
                        ? 100
                        : 10000)
                  ) /
                  (otherProps.fieldConfig?.defaults?.decimals
                    ? decimalMultiplier / 100
                    : otherProps.fieldConfig?.defaults?.decimals === 0
                    ? 1
                    : 100)
                }${display.suffix ?? ''}`
              : formattedValueToString(display)
            : null,
          isActive: focusedSeriesIdx === i,
        });
      }

      tooltip = <SeriesTable series={series} timestamp={xValModified} />;
    }
    // tooltip for no time series bar graph
    if (mode === 'single' && focusedSeriesIdx !== null && otherProps.noTime === BarGraphMode.Bar) {
      const field = otherProps.data.fields[focusedSeriesIdx];
      const timeField: any = otherProps.data.fields[0].values;
      const timeFieldBuffer = timeField.buffer;
      if (!field) {
        return null;
      }

      const fieldFmt = field.display || getDisplayProcessor({ field, timeZone, theme });
      const value = fieldFmt(field.values.get(focusedPointIdx));
      tooltip = (
        <SeriesTable
          series={[
            {
              color: otherProps.colorArray[focusedPointIdx],
              label: timeFieldBuffer[focusedPointIdx],
              value: value ? formattedValueToString(value) : null,
            },
          ]}
          timestamp={timeFieldBuffer[focusedPointIdx]}
        />
      );
    }
    if (mode === 'multi' && otherProps.noTime === BarGraphMode.Bar) {
      let series: SeriesTableRowProps[] = [];
      const frame = otherProps.data;
      const fields = frame.fields;
      const timeField: any = frame.fields[0].values;
      const timeFieldBuffer = timeField.buffer;
      for (let i = 1; i < fields.length; i++) {
        const field = frame.fields[i];
        if (
          !field ||
          field === xField ||
          field.config.custom?.hideFrom?.tooltip ||
          field.config.custom?.hideFrom?.viz
        ) {
          continue;
        }

        const display = field.display!(otherProps.data.fields[i].values.get(focusedPointIdx));
        // eslint-disable-next-line use-isnan
        display.percent !== 0
          ? series.push({
              color: otherProps.colorArrayStack[i - 1],
              label: getFieldDisplayName(field, frame),
              value: display
                ? pctStacked
                  ? `${display.prefix ?? ''}${
                      Math.round(
                        (display.numeric / (alignedTotals[i][focusedPointIdx] ?? 1)) *
                          (otherProps.fieldConfig?.defaults?.decimals
                            ? decimalMultiplier
                            : otherProps.fieldConfig?.defaults?.decimals === 0
                            ? 100
                            : 10000)
                      ) /
                      (otherProps.fieldConfig?.defaults?.decimals
                        ? decimalMultiplier / 100
                        : otherProps.fieldConfig?.defaults?.decimals === 0
                        ? 1
                        : 100)
                    }${display.suffix ?? ''}`
                  : formattedValueToString(display)
                : null,
              isActive: focusedSeriesIdx === i,
            })
          : series.push({
              color: '',
              label: '',
              value: null,
              isActive: focusedSeriesIdx === i,
            });
      }
      tooltip = <SeriesTable series={series} timestamp={timeFieldBuffer[focusedPointIdx]} />;
    }
  } else {
    tooltip = renderTooltip(otherProps.data, focusedSeriesIdx, focusedPointIdx);
  }

  return (
    <Portal>
      {tooltip && coords && (
        <VizTooltipContainer position={{ x: coords.x, y: coords.y }} offset={{ x: TOOLTIP_OFFSET, y: TOOLTIP_OFFSET }}>
          {tooltip}
        </VizTooltipContainer>
      )}
    </Portal>
  );
};

function isCursourOutsideCanvas({ left, top }: uPlot.Cursor, canvas: DOMRect) {
  if (left === undefined || top === undefined) {
    return false;
  }
  return left < 0 || left > canvas.width || top < 0 || top > canvas.height;
}

/**
 * Given uPlot cursor position, figure out position of the tooltip withing the canvas bbox
 * Tooltip is positioned relatively to a viewport
 * @internal
 **/
export function positionTooltip(u: uPlot, bbox: DOMRect) {
  let x, y;
  const cL = u.cursor.left || 0;
  const cT = u.cursor.top || 0;

  if (isCursourOutsideCanvas(u.cursor, bbox)) {
    const idx = u.posToIdx(cL);
    // when cursor outside of uPlot's canvas
    if (cT < 0 || cT > bbox.height) {
      let pos = findMidPointYPosition(u, idx);

      if (pos) {
        y = bbox.top + pos;
        if (cL >= 0 && cL <= bbox.width) {
          // find x-scale position for a current cursor left position
          x = bbox.left + u.valToPos(u.data[0][u.posToIdx(cL)], u.series[0].scale!);
        }
      }
    }
  } else {
    x = bbox.left + cL;
    y = bbox.top + cT;
  }

  return { x, y };
}

export function fmtDate(ts: any) {
  // eslint-disable-next-line radix
  const newTs = parseInt(ts);
  const d = new Date(newTs);
  if (!isNaN(d.getTime())) {
    let text = d.toLocaleString().replace(',', '');
    return text;
  }
  return ts;
}
