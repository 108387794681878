import { DataSourceInstanceSettings, DataSourcePluginMeta, PluginType } from '@grafana/data';
import { ExpressionQuery, OperationType } from './types';
import ExpressionQueryEditor from './ExpressionQueryEditor';
import { DataSourceWithBackend, config } from '@grafana/runtime';

export class ExpressionDatasourceApi extends DataSourceWithBackend<ExpressionQuery> {
  constructor(public instanceSettings: DataSourceInstanceSettings) {
    super(instanceSettings);
  }

  getCollapsedText(query: ExpressionQuery) {
    return `Expression: ${query.type}`;
  }

  newQuery(query?: Partial<ExpressionQuery>): ExpressionQuery {
    return {
      refId: '--', // Replaced with query
      type: query?.type ?? OperationType.math,
      datasource: ExpressionDatasourceID,
      conditions: query?.conditions ?? undefined,
    };
  }
}

export const ExpressionDatasourceID = '__expr__';

export const instanceSettings: DataSourceInstanceSettings = {
  id: -100,
  uid: ExpressionDatasourceID,
  name: ExpressionDatasourceID,
  type: ExpressionDatasourceID,
  meta: {
    baseUrl: '',
    module: '',
    type: PluginType.datasource,
    name: ExpressionDatasourceID,
    id: ExpressionDatasourceID,
    info: {
      author: {
        name: `${config.appName}`,
      },
      logos: {
        small: 'public/img/icn-datasource.svg',
        large: 'public/img/icn-datasource.svg',
      },
      description: `Adds expression support to ${config.appName}`,
      screenshots: [],
      links: [],
      updated: '',
      version: '',
    },
  },
  jsonData: {},
};

export const expressionDatasource = new ExpressionDatasourceApi(instanceSettings);
expressionDatasource.meta = {
  id: ExpressionDatasourceID,
  info: {
    logos: {
      small: 'public/img/icn-datasource.svg',
      large: 'public/img/icn-datasource.svg',
    },
  },
} as DataSourcePluginMeta;
expressionDatasource.components = {
  QueryEditor: ExpressionQueryEditor,
};
