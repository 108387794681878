import { StandardEditorProps } from '@grafana/data';
import React from 'react';
import { OSMapPanelOptions, OSMapInstanceState, OSMapLayerState } from '../types/types';
import { MapLayerType } from '../types/interface';
import { InlineField, InlineFieldRow, Select } from '@grafana/ui';
import { uniqueId } from 'lodash';
import { LABEL_WIDTH } from '../StyleEditor/GeojsonStyle';

/**
 * An array of base map tile options.
 *
 * @constant {Array<{value: MapLayerType, label: string, description: string}>} baseMapTileOptions
 * @default
 * @description These options represent different base map tile types.
 * @property {MapLayerType} value - The value representing the base map tile type.
 * @property {string} label - The label or display name for the base map tile type.
 * @property {string} description - A description of the base map tile type.
 */
const baseMapTileOptions = [
  { value: MapLayerType.DEFAULT, label: 'Standard', description: 'standard open street map theme' },
  { value: MapLayerType.ROADMAP, label: 'Google ROADMAP', description: 'normal, default 2D map' },
  {
    value: MapLayerType.HYBRID,
    label: 'Google HYBRID',
    description: 'photographic map + roads and city names',
  },
  { value: MapLayerType.SATELLITE, label: 'Google SATELLITE', description: 'photographic map' },
  { value: MapLayerType.TERRAIN, label: 'Google TERRAIN', description: 'map with mountains, rivers, etc.' },
  {
    value: MapLayerType.STAMENWATERCOLOR,
    label: 'Stamen Water Color',
    description: 'painted with water color',
  },
  {
    value: MapLayerType.STAMENTERRAIN,
    label: 'Stamen Terrain',
    description: 'map with mountains, rivers, etc.',
  },
  {
    value: MapLayerType.STAMENTONER,
    label: 'Stamen Toner',
    description: 'stamen toner theme black and white',
  },
  { value: MapLayerType.CARTO, label: 'Carto Basemap', description: 'carto raster basemap' },
];

/**
 * An array of Carto map style options.
 *
 * @constant {Array<{value: string, label: string}>} cartoStyle
 * @default
 * @description These options represent different Carto map styles.
 * @property {string} value - The value representing the Carto style.
 * @property {string} label - The label or display name for the Carto style.
 */
const cartoStyle = [
  { value: 'dark_all', label: 'Dark theme with Labels' },
  {
    value: 'light_all',
    label: 'Light theme with Labels',
  },
  { value: 'dark_nolabels', label: 'Dark theme without Labels' },
  {
    value: 'light_nolabels',
    label: 'Light theme without Labels',
  },
];

/**
 * Properties for a map layers editor component.
 *
 * @typedef {StandardEditorProps<any, any, OSMapPanelOptions, OSMapInstanceState>} LayersEditorProps
 */
type LayersEditorProps = StandardEditorProps<any, any, OSMapPanelOptions, OSMapInstanceState>;

/**
 * A component for editing base map layers and their styles.
 *
 * @function
 * @param {LayersEditorProps} props - The properties for the BaseLayerEditor component.
 * @param {StandardEditorContext} props.context - The context object..
 */
export const BaseLayerEditor = (props: LayersEditorProps) => {
  const { layers, actions } = props.context.instanceState ?? {};
  if (!layers || !actions) {
    return <div>No layers?</div>;
  }

  const onChangeBaseMap = (layer: OSMapLayerState<any>, value: MapLayerType) => {
    if (value === MapLayerType.CARTO) {
      layer.onChange({ ...layer.options, type: value, config: { theme: 'dark_nolabels' } });
    } else {
      layer.onChange({ ...layer.options, type: value });
    }
  };
  const onChangeCarto = (layer: OSMapLayerState<any>, value: string) => {
    layer.onChange({ ...layer.options, config: { theme: value } });
  };
  return (
    <>
      {layers &&
        layers.length > 0 &&
        layers.map((layer, i) => {
          if (i === 0) {
            return (
              <InlineFieldRow key={`${i}${uniqueId()}`}>
                <InlineField label={'Base Map'} style={{ width: '100%' }} labelWidth={LABEL_WIDTH}>
                  <Select
                    noOptionsMessage="no strings fields found"
                    options={baseMapTileOptions}
                    onChange={(e) => onChangeBaseMap(layer, e.value!)}
                    value={layer.options.type}
                  />
                </InlineField>
                {layer.options.type === MapLayerType.CARTO && (
                  <InlineField label={'Carto Style'} style={{ width: '100%' }} labelWidth={LABEL_WIDTH}>
                    <Select
                      noOptionsMessage="no strings fields found"
                      options={cartoStyle}
                      onChange={(e) => onChangeCarto(layer, e.value!)}
                      value={layer.options.config.theme}
                    />
                  </InlineField>
                )}
              </InlineFieldRow>
            );
          } else {
            return null;
          }
        })}
    </>
  );
};
