import uPlot, { Axis, Cursor, BBox } from 'uplot';
import { DataFrame, getColorForTheme, GrafanaTheme } from '@grafana/data';
import { BoxPlotData, TooltipInterface, OHLC } from '../types';
import { Quadtree, Rect, pointWithin } from '../quadtree';
import { formatTime } from '../utils';
import tinycolor from 'tinycolor2';
import darkTheme from '@grafana/ui/src/themes/dark';

/**
 * @typedef {Object} CandleOptions
 * @property {DataFrame} data
 * @property {OHLC} ohlcData
 * @property {Array<number>} queryIndex
 * @property {boolean | undefined} querySwitch
 * @property { BoxPlotData} dataAll
 * @property {number} toCluster
 * @property {number} customClusterSize
 * @property {string} upcolor
 * @property {string} downcolor
 * @property {boolean} clusterSwitch
 */
export interface CandleOptions {
  data: DataFrame;
  ohlcData: OHLC;
  queryIndex: number[];
  querySwitch: boolean | undefined;
  dataAll: BoxPlotData;
  toCluster: number;
  customClusterSize: number;
  upcolor: string;
  downcolor: string;
}
/**
 * @constant
 * @default
 */
const pxRatio = devicePixelRatio;
/**
 * hooks, tooltip, x axis etc. calculated here to add in uplot builder
 * @param {CandleOptions} opts
 * @param {GrafanaTheme} theme
 * @returns {any} returns many hooks and data
 */
export function getConfig(opts: CandleOptions, theme: GrafanaTheme) {
  const { dataAll, toCluster, customClusterSize, upcolor, downcolor } = opts;

  let qt: Quadtree;
  let hovered: Rect | undefined;

  let barMark = document.createElement('div');

  barMark.classList.add('bar-mark');
  barMark.style.position = 'absolute';
  barMark.style.background = 'rgba(255,255,255,0.4)';

  // hide crosshair cursor & hover points
  const cursor: Cursor = {
    x: true,
    y: false,
    points: {
      show: false,
    },
  };
  const select: Partial<BBox> = {
    show: false,
  };
  /**
   * from data candles are made here
   */
  const drawCandles = (u: uPlot) => {
    /**
     * @constant
     * @default
     */
    const gap = 2,
      shadowColor = theme.colors.text,
      bearishColor = tinycolor(getColorForTheme(upcolor, darkTheme)).toString(),
      bullishColor = tinycolor(getColorForTheme(downcolor, darkTheme)).toString(),
      bodyMaxWidth = 20,
      shadowWidth = 2,
      bodyOutline = 1;
    u.ctx.save();

    let [idx0, idx1] = u.series[0].idxs!;
    /**
     * @constant
     * @default
     */
    const offset = (shadowWidth % 2) / 2;
    u.ctx.translate(offset, offset);
    for (let i = idx0; i <= idx1; i++) {
      let xVal = u.data[0][i];

      let open = u.data[1][i];
      let high = u.data[2][i];
      let low = u.data[3][i];
      let close = u.data[4][i];
      /**
       * u.valToPos function gives pixel value in canvas according to value
       */
      let timeAsX = u.valToPos(xVal, 'x', true);
      let openAsY = u.valToPos(open!, u.series[1].scale!, true);
      let highAsY = u.valToPos(high!, u.series[1].scale!, true);
      let lowAsY = u.valToPos(low!, u.series[1].scale!, true);
      let closeAsY = u.valToPos(close!, u.series[1].scale!, true);

      // shadow rect
      let shadowHeight = Math.max(highAsY, lowAsY) - Math.min(highAsY, lowAsY);
      let shadowX = timeAsX - shadowWidth / 2;
      let shadowY = Math.min(highAsY, lowAsY);

      u.ctx.fillStyle = shadowColor;
      u.ctx.fillRect(Math.round(shadowX), Math.round(shadowY), Math.round(shadowWidth), Math.round(shadowHeight));

      // body rect
      let columnWidth = u.bbox.width / (idx1 - idx0);
      let bodyWidth = Math.min(bodyMaxWidth, columnWidth - gap);
      let bodyHeight = Math.max(closeAsY, openAsY) - Math.min(closeAsY, openAsY);
      let bodyX = timeAsX - bodyWidth / 2;
      let bodyY = Math.min(closeAsY, openAsY);
      let bodyColor = open! > close! ? bearishColor : bullishColor;
      /**
       * tooltip hover interaction quadtree.
       * adding tooltip related data to quad tree.
       * this will gives us that tooltip is present in cursor vicinity or not
       */
      qt.add({
        x: timeAsX - u.bbox.left - bodyWidth / 2,
        y: u.bbox.top,
        w: bodyWidth,
        h: u.bbox.height - u.bbox.top,
        sidx: 1,
        didx: i,
      });
      u.ctx.fillStyle = open! > close! ? bearishColor : bullishColor;
      u.ctx.fillRect(Math.round(bodyX), Math.round(bodyY), Math.round(bodyWidth), Math.round(bodyHeight));

      u.ctx.fillStyle = bodyColor;
      u.ctx.fillRect(
        Math.round(bodyX + bodyOutline),
        Math.round(bodyY + bodyOutline),
        Math.round(bodyWidth - bodyOutline * 2),
        Math.round(bodyHeight - bodyOutline * 2)
      );
    }

    u.ctx.translate(-offset, -offset);

    u.ctx.restore();
  };
  /**
   * custom designed x values function
   * @function
   * @param {uPlot} u
   * @param {number[]} val
   * @returns {Array<string>} x axis data
   */
  const xValuesCandleTime: Axis.Values = (u: uPlot, val: number[]): string[] => {
    let xDataTime = [];
    for (let index = 0; index < dataAll.candle?.time?.values.length!; index++) {
      const incrNew = toCluster === 0 ? customClusterSize * 60 * 1e3 : toCluster * 60 * 1e3;
      const time = formatTime(dataAll.candle?.time?.values[index], incrNew);
      xDataTime.push(time);
    }
    return xDataTime;
  };

  const drawClear = (u: uPlot) => {
    qt = qt || new Quadtree(0, 0, u.bbox.width, u.bbox.height);
    qt.clear();

    // clear the path cache to force drawBox() to rebuild new quadtree
    u.series.forEach((s) => {
      // @ts-ignore
      s._paths = null;
    });
  };
  const tooltipMaker: TooltipInterface = (seriesIndex, datapointIndex, showTooltip, u) => {
    let found: Rect | undefined;
    let cx = u.cursor.left! * pxRatio;
    let cy = u.cursor.top! * pxRatio;
    qt.get(cx, cy, 1, 1, (o) => {
      if (pointWithin(cx, cy, o.x, o.y, o.x + o.w, o.y + o.h)) {
        found = o;
      }
    });
    if (found) {
      // prettier-ignore
      if (found !== hovered) {
        /* eslint-disable no-multi-spaces */
        barMark.style.display = '';
        barMark.style.left   = found!.x / pxRatio + 'px';
        barMark.style.top    = found!.y / pxRatio + 'px';
        barMark.style.width  = found!.w / pxRatio + 'px';
        barMark.style.height = found!.h / pxRatio + 'px';
        hovered = found;
        seriesIndex(hovered.sidx);
        datapointIndex(hovered.didx);
        showTooltip();
        /* eslint-enable */
      }
    } else if (hovered !== undefined) {
      seriesIndex(hovered!.sidx);
      datapointIndex(hovered!.didx);
      showTooltip();
      hovered = undefined;
      barMark.style.display = 'none';
    } else {
      showTooltip(true);
    }
  };

  return {
    // cursor & select opts
    cursor,
    select,

    // scale & axis opts
    xValuesCandleTime,

    // hooks
    drawCandles,
    drawClear,
    tooltipMaker,
  };
}
