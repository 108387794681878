import { config } from '@grafana/runtime';
import { getTimeSrv } from 'app/features/dashboard/services/TimeSrv';
import { createShortLink } from 'app/core/utils/shortLinks';
import { getTemplateSrv } from 'app/features/templating/template_srv';
import { PanelModel, urlUtil } from '@grafana/data';

export function buildParams(
  useCurrentTimeRange: boolean,
  includeTemplateVars: boolean,
  selectedTheme?: string,
  panel?: PanelModel
) {
  let params = urlUtil.getUrlSearchParams();

  const range = getTimeSrv().timeRange();
  params.from = range.from.valueOf();
  params.to = range.to.valueOf();
  params.orgId = config.bootData.user.orgId;

  if (!useCurrentTimeRange) {
    delete params.from;
    delete params.to;
  }

  if (includeTemplateVars) {
    getTemplateSrv().fillVariableValuesForUrl(params);
  }

  if (selectedTheme !== 'current') {
    params.theme = selectedTheme;
  }

  if (panel && !params.editPanel) {
    params.viewPanel = panel.id;
  }

  return params;
}

export function buildBaseUrl() {
  let baseUrl = window.location.href;
  const queryStart = baseUrl.indexOf('?');

  if (queryStart !== -1) {
    baseUrl = baseUrl.substring(0, queryStart);
  }

  return baseUrl;
}

export async function buildShareUrl(
  useCurrentTimeRange: boolean,
  includeTemplateVars: boolean,
  selectedTheme?: string,
  panel?: PanelModel,
  shortenUrl?: boolean
) {
  const baseUrl = buildBaseUrl();
  const params = buildParams(useCurrentTimeRange, includeTemplateVars, selectedTheme, panel);
  const shareUrl = urlUtil.appendQueryToUrl(baseUrl, urlUtil.toUrlParams(params));
  if (shortenUrl) {
    return await createShortLink(shareUrl);
  }
  return shareUrl;
}

export function buildSoloUrl(
  useCurrentTimeRange: boolean,
  includeTemplateVars: boolean,
  selectedTheme?: string,
  panel?: PanelModel
) {
  const baseUrl = buildBaseUrl();
  const params = buildParams(useCurrentTimeRange, includeTemplateVars, selectedTheme, panel);

  let soloUrl = baseUrl.replace(config.appSubUrl + '/dashboard/', config.appSubUrl + '/dashboard-solo/');
  soloUrl = soloUrl.replace(config.appSubUrl + '/d/', config.appSubUrl + '/d-solo/');

  params.panelId = params.editPanel ?? params.viewPanel;
  delete params.editPanel;
  delete params.viewPanel;

  return urlUtil.appendQueryToUrl(soloUrl, urlUtil.toUrlParams(params));
}

export function buildSoloUrl3(
  useCurrentTimeRange: boolean,
  includeTemplateVars: boolean,
  selectedTheme?: string,
  panel?: PanelModel
) {
  const baseUrl = buildBaseUrl();
  const params = buildParams(useCurrentTimeRange, includeTemplateVars, selectedTheme, panel);

  let soloUrl = baseUrl.replace(config.appSubUrl + '/dashboard/', config.appSubUrl + '/render3/dashboard/');
  soloUrl = soloUrl.replace(config.appSubUrl + '/d/', config.appSubUrl + '/render3/d/');

  params.panelId = params.editPanel ?? params.viewPanel;
  delete params.editPanel;

  return urlUtil.appendQueryToUrl(soloUrl, urlUtil.toUrlParams(params));
}
export function buildSoloUrl2(
  useCurrentTimeRange: boolean,
  includeTemplateVars: boolean,
  selectedTheme?: string,
  panel?: PanelModel
) {
  const baseUrl = buildBaseUrl();
  const params = buildParams(useCurrentTimeRange, includeTemplateVars, selectedTheme, panel);

  let soloUrl = baseUrl.replace(config.appSubUrl + '/dashboard/', config.appSubUrl + '/render3/dashboard/');
  soloUrl = soloUrl.replace(config.appSubUrl + '/d/', config.appSubUrl + '/render3/d/');

  params.panelId = params.editPanel ?? params.viewPanel;
  delete params.editPanel;
  delete params.viewPanel;
  delete params.panelId;
  return urlUtil.appendQueryToUrl(soloUrl, urlUtil.toUrlParams(params));
}

export function buildImageUrl(
  useCurrentTimeRange: boolean,
  includeTemplateVars: boolean,
  timeZone: string,
  selectedTheme?: string,
  panel?: PanelModel
) {
  let soloUrl = buildSoloUrl(useCurrentTimeRange, includeTemplateVars, selectedTheme, panel);

  let imageUrl = soloUrl.replace(config.appSubUrl + '/dashboard-solo/', config.appSubUrl + '/render/dashboard-solo/');
  imageUrl = imageUrl.replace(config.appSubUrl + '/d-solo/', config.appSubUrl + '/render/d-solo/');
  imageUrl += '&width=1000&height=500' + createTimeZone(timeZone);
  return imageUrl;
}

function prepareDimention(layout: string, orientation: string, pageSize: Number[], panel?: PanelModel) {
  // portrait
  if (orientation === 'portrait') {
    if (layout === 'grid') {
      return `&width=${pageSize[0]}&height=${pageSize[1]}&pagesize=${pageSize[4]}`;
    } else if (layout === 'simple') {
      return `&width=${pageSize[2]}&height=${pageSize[3]}&pagesize=${pageSize[4]}`;
    } else if (panel) {
      return `&width=${pageSize[0]}&height=${pageSize[1]}`;
    } else {
      return `&width=${1080}&height=${1600}`;
    }
  } else {
    // landscape
    if (layout === 'grid') {
      return `&width=${pageSize[1]}&height=${pageSize[0]}&pagesize=${pageSize[4]}`;
    } else if (layout === 'simple') {
      return `&width=${pageSize[3]}&height=${pageSize[2]}&pagesize=${pageSize[4]}`;
    } else if (panel) {
      return `&width=${pageSize[1]}&height=${pageSize[0]}`;
    } else {
      return `&width=${1600}&height=${1080}`;
    }
  }
}
export function generateWidthHeight(layout: string, orientation: string, pageSize: Number[], panel?: PanelModel) {
  // const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  // const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  // return `&width=${width}&height=${height}&rtype=carbon`;
  return prepareDimention(layout, orientation, pageSize, panel) + '&rtype=carbon';
}

export function buildPanelPDFUrl(
  useCurrentTimeRange: boolean,
  includeTemplateVars: boolean,
  timeZone: string,
  kiosk: boolean,
  layout: string,
  orientation: string,
  pageSize: Number[],
  selectedTheme?: string,
  panel?: PanelModel
) {
  let soloUrl = buildSoloUrl3(useCurrentTimeRange, includeTemplateVars, selectedTheme, panel);
  soloUrl += generateWidthHeight(layout, orientation, [500, 1000], panel) + createTimeZone(timeZone);
  soloUrl += kiosk ? '&kiosk' : '&kiosk=tv';
  return soloUrl;
}

export function buildDashPDFUrl(
  useCurrentTimeRange: boolean,
  includeTemplateVars: boolean,
  timeZone: string,
  kiosk: boolean,
  layout: string,
  orientation: string,
  pageSize: Number[],
  invoice: boolean,
  selectedTheme?: string
) {
  let soloUrl = buildSoloUrl2(useCurrentTimeRange, includeTemplateVars, selectedTheme);
  soloUrl += `&layout=${layout}&orientation=${orientation}`;
  soloUrl += generateWidthHeight(layout, orientation, pageSize) + createTimeZone(timeZone);
  soloUrl += invoice ? '&invoice=true' : '';
  soloUrl += kiosk ? '&kiosk' : '&kiosk=tv';
  return soloUrl;
}

export function buildIframeHtml(useCurrentTimeRange: boolean, selectedTheme?: string, panel?: PanelModel) {
  let soloUrl = buildSoloUrl(useCurrentTimeRange, false, selectedTheme, panel);
  return '<iframe src="' + soloUrl + '" width="450" height="200" frameborder="0"></iframe>';
}

export function createTimeZone(ianaName: string) {
  return '&tz=' + ianaName;
}
