import { PanelPlugin } from '@grafana/data';
import {
  BoxPlotOptions,
  // addStandardDataReduceOptions,
  // FieldConfigProperty
} from './types';
import { BoxPlotPanel } from './BoxPlotPanel';
import { statPanelChangedHandler } from './StatMigrations';
// import { ColorPickerEditorSingle } from './ColorPickerEditorSingle';

export const plugin = new PanelPlugin<BoxPlotOptions>(BoxPlotPanel)
  .useFieldConfig()
  .setPanelOptions((builder) => {
    // addStandardDataReduceOptions(builder);
    builder
      // .addTextInput({
      //   path: 'xaxisName',
      //   name: 'X Axis',
      //   defaultValue: 'Properties',
      //   description: '',
      // })
      // .addTextInput({
      //   path: 'yaxisName',
      //   name: 'Y Axis',
      //   defaultValue: 'Values',
      //   description: '',
      // })
      .addSelect({
        path: 'clusteringMethod',
        name: 'Clustering Method',
        description: 'type of clustering',
        settings: {
          options: [
            { label: 'Timestamp', value: 'timestamp' },
            { label: 'Number of boxes', value: 'numboxes' },
          ],
        },
        defaultValue: 'timestamp',
      })
      .addNumberInput({
        path: 'numBoxes',
        name: 'Number of Boxes',
        description: 'To determine the number of boxes per dataset.',
        defaultValue: 10,
        settings: {
          integer: true,
        },
        showIf: (options: BoxPlotOptions) => options.clusteringMethod === 'numboxes',
      })
      .addSelect({
        path: 'clusterSize',
        name: 'Cluster Size',
        // description: 'Select the type of Chart',
        settings: {
          options: [
            { label: '10 Minutes', value: 10 * 60 * 1000 },
            { label: '30 Minutes', value: 30 * 60 * 1000 },
            { label: '1 Hour', value: 60 * 60 * 1000 },
            { label: '3 Hour', value: 3 * 60 * 60 * 1000 },
            { label: '6 Hour', value: 6 * 60 * 60 * 1000 },
            { label: '12 Hour', value: 12 * 60 * 60 * 1000 },
            { label: '24 Hour', value: 24 * 60 * 60 * 1000 },
            { label: '1 Day', value: 1000 * 60 * 60 * 24 },
            { label: '2 Days', value: 1000 * 60 * 60 * 24 * 2 },
            { label: '7 Days', value: 1000 * 60 * 60 * 24 * 7 },
            { label: '14 Days', value: 1000 * 60 * 60 * 24 * 14 },
            { label: '30 Days', value: 1000 * 60 * 60 * 24 * 30 },
            { label: '1 Week', value: 1000 * 60 * 60 * 24 * 7 },
            { label: '1 Month', value: 1000 * 60 * 60 * 24 * 30 * 1 },
            { label: '3 Months', value: 1000 * 60 * 60 * 24 * 30 * 3 },
            { label: '6 Months', value: 1000 * 60 * 60 * 24 * 30 * 6 },
            { label: '1 Year', value: 1000 * 60 * 60 * 24 * 30 * 12 },
          ],
        },
        defaultValue: 30 * 60 * 1000,
        showIf: (options: BoxPlotOptions) => options.clusteringMethod === 'timestamp',
      });
  })
  .setNoPadding()
  .setPanelChangeHandler(statPanelChangedHandler);
