import React, { FC, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { FieldDisplay, GrafanaTheme, FieldConfigSource, DisplayValue, TimeRange } from '@grafana/data';
import { CircleChartOptions } from '../types';
import { unitDataProducer } from './config';
import { DrawerData, LinkDrawer } from 'app/features/linkDrawer/LinkDrawer';

interface CircleProps {
  bigData: FieldDisplay[];
  width: number;
  height: number;
  options: CircleChartOptions;
  theme: GrafanaTheme;
  renderCounter: number;
  fieldConfig: FieldConfigSource<any>;
  timeZone: string;
  timeRange: TimeRange;
}

export const Circle: FC<CircleProps> = ({
  width,
  height,
  bigData,
  options,
  fieldConfig,
  theme,
  renderCounter,
  timeZone,
  timeRange,
}) => {
  const [infoData, setinfoData] = useState<DrawerData>({ location: '', url: '' });
  const [showDrawer, setShowDrawer] = React.useState(false);

  const onCloseDrawer = () => {
    setShowDrawer(false);
  };

  const drawerhandle = (urlName: string, url: string) => {
    if (urlName.length !== 0) {
      setinfoData({ location: urlName, url: url });
    } else {
      setinfoData({ location: url, url: url });
    }
    setShowDrawer(!showDrawer);
  };
  const { seriesData, colorset, localeLabels, tooltipData, force } = useMemo(
    () => computeData(bigData, options, fieldConfig, timeZone),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [renderCounter, bigData]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const chartOptions = useMemo(
    () => generateOptions(tooltipData, options, colorset, localeLabels, theme, width, drawerhandle),
    [force]
  );

  return (
    <div style={{ position: 'relative', width, height }}>
      <Chart
        key={force}
        options={chartOptions}
        series={seriesData}
        type={options.chartType}
        width={width}
        height={height}
      />
      {showDrawer && (
        <LinkDrawer
          infoData={infoData}
          onClose={onCloseDrawer}
          timeRange={options.toVisitDashboard.drawerLinkTime ? timeRange : undefined}
        />
      )}
    </div>
  );
};

const computeData = (
  bigData: FieldDisplay[],
  options: CircleChartOptions,
  fieldConfig: FieldConfigSource<any>,
  timeZone: string
) => {
  let seriesData: any = [];
  const tooltipData: DisplayValue[] = [];
  const localeLabels: any = [];
  const { limit2, limit, limitLabels } = options;
  const units = Number(fieldConfig.defaults.unit) || 0;
  if (limit2 === 'top') {
    bigData.sort((a: any, b: any) => b.display.numeric - a.display.numeric);
  } else if (limit2 === 'bottom') {
    bigData.sort((a: any, b: any) => a.display.numeric - b.display.numeric);
  }

  let colorset: any = [];
  if (limitLabels && bigData.length >= limit) {
    for (let i = 0; i < limit; i++) {
      localeLabels.push(bigData[i].display.title);
      seriesData.push(Number(bigData[i].display.numeric.toFixed(units)));
      colorset.push(bigData[i].display.color);
      tooltipData.push(bigData[i].display);
    }
    if (options.limitRemaining) {
      let remData = 0;
      for (let i = limit; i < bigData.length; i++) {
        remData += Number(bigData[i].display.numeric.toFixed(units));
      }
      localeLabels.push('Others');
      seriesData.push(remData);
      colorset.push(bigData[limit].display.color);
      const response: DisplayValue = unitDataProducer(remData, timeZone, fieldConfig);
      tooltipData.push(response);
    }
  } else {
    for (let i = 0; i < bigData.length; i++) {
      localeLabels.push(bigData[i].display.title);
      seriesData.push(Number(bigData[i].display.numeric.toFixed(units)));
      colorset.push(bigData[i].display.color);
      tooltipData.push(bigData[i].display);
    }
  }
  if (options.chartType === 'radialBar') {
    if (options.radialOptionsSelected === 'max') {
      // @ts-ignore
      const max: number = Math.max(...seriesData);
      seriesData = seriesData.map((value: number, index: number) => {
        let num = (+value * 100) / max;
        num = Number(num.toFixed(units));
        return num;
      });
    } else if (options.radialOptionsSelected === 'total') {
      const sum: number = seriesData.reduce((a: any, b: any) => a + b, 0);
      seriesData = seriesData.map((value: number, index: number) => {
        let num = (+value * 100) / sum;
        num = Number(num.toFixed(units));
        return num;
      });
    } else {
      const inputValue = options.radialInput;
      seriesData = seriesData.map((value: number, index: number) => {
        let num = (+value * 100) / inputValue;
        num = Number(num.toFixed(units));
        return num;
      });
    }
  }
  return {
    seriesData,
    colorset,
    localeLabels,
    tooltipData,
    force: Math.floor(Math.random() * 100000),
  };
};

const generateOptions = (
  tooltipData: DisplayValue[],
  options: CircleChartOptions,
  colorset: string[],
  localeLabels: string[],
  theme: GrafanaTheme,
  width: number,
  drawerhandle: any
) => {
  return {
    chart: {
      animations: {
        enabled: false,
      },
      type: options.chartType,
      toolbar: {
        show: true,
      },
    },
    colors: colorset,
    labels: localeLabels,
    dataLabels: {
      enabled: options.dataLabels,
      style: {
        colors: theme.isLight ? ['#373D3F'] : ['#d8d9da'],
      },
      dropShadow: {
        enabled: false,
      },
      formatter(val: any, opts: any) {
        const name = opts.w.globals.labels[opts.seriesIndex];
        return [name, val.toFixed(2) + '%'];
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      enabled: true,
      shared: true,
      theme: 'light',
      onDatasetHover: {
        highlightDataSeries: true,
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
        const item = tooltipData[seriesIndex];
        return (
          '<div class="apexcharts-tooltip-series-group" style="display: flex;">' +
          '<span class="apexcharts-tooltip-marker" style="background-color:' +
          w.globals.colors[seriesIndex] +
          '"></span>' +
          '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">' +
          '<div class="apexcharts-tooltip-y-group">' +
          '<span class="apexcharts-tooltip-text-label">' +
          w.globals.labels[seriesIndex] +
          ':' +
          '</span>' +
          '<span class="apexcharts-tooltip-text-value">' +
          // series[seriesIndex] +
          (item.prefix || '') +
          item.text +
          (item.suffix || '') +
          '</span>' +
          '</div>' +
          '</div>' +
          '</div>'
        );
      },
    },
    legend: {
      show: width > 650 ? options.legendShow : false,
      floating: options.legendFloat,
      labels: {
        colors: theme.isDark ? '#d8d9da' : undefined,
      },
      // @ts-ignore
      horizontalAlign: options.horizontalAlign,
      position: options.legendOrientation,
      markers: {
        width: options.dashboardVisitMode ? 16 : 11,
        height: options.dashboardVisitMode ? 16 : 11,
        customHTML: () => {
          if (options.dashboardVisitMode) {
            return '<span class="custom-marker" style="color: black;"><i class="fa fa-arrow-circle-right" style="font-size: 17px;" aria-hidden="true"></i></span>';
          } else {
            return '';
          }
        },
        onClick: (chart: any, seriesIndex: any) => {
          if (options.dashboardVisitMode) {
            Object.keys(options.toVisitDashboard.visitDashboard).map((key: any) => {
              if (options.toVisitDashboard.visitDashboard[key].queryName === localeLabels[seriesIndex]) {
                const urlToGo: any = options.toVisitDashboard.visitDashboard[key].dashboardUrl;
                const urlToName: any = options.toVisitDashboard.visitDashboard[key].dashboardName;
                if (urlToGo !== '') {
                  // parent.open(urlToGo);
                  drawerhandle(urlToName, urlToGo);
                }
              }
            });
          }
        },
      },
    },
  };
};
