/* eslint-disable prettier/prettier */
export type Each = (index: number, offPct: number, dimPct: number) => void;

export function walker(numItems: number, sizeFactor: number, onlyIdx: number | null, each: Each) {
  let space = 1 - sizeFactor;

  let gap = space / (numItems - 1);

  if (isNaN(gap) || gap === Infinity) {
    gap = 0;
  }

  let offs = 0;

  let iwid = sizeFactor / numItems;
  let _iwid = roundDecimal(iwid, 6);

  if (onlyIdx == null) {
    for (let i = 0; i < numItems; i++) {
      each(i, coord(i, offs, iwid, gap), _iwid);
    }
  } else {
    each(onlyIdx, coord(onlyIdx, offs, iwid, gap), _iwid);
  }
}

function roundDecimal(val: number, dec: number) {
  return Math.round(val * (dec = 10 ** dec)) / dec;
}

const coord = (i: number, offs: number, iwid: number, gap: number) => roundDecimal(offs + i * (iwid + gap), 6);
