import {
  PanelPlugin,
  standardEditorsRegistry,
  ReducerID,
  FieldOverrideContext,
  getFieldDisplayName,
  escapeStringForRegex,
} from '@grafana/data';
import { SimpleOptions, fontStyleOptions } from './types';
import { SimplePanel } from './SimplePanel';
import { wordAttributesEditor } from './components/wordAttributesEditor';

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).useFieldConfig().setPanelOptions((builder) => {
  return (
    builder
      // YOU CAN SELECT CALCULATION OR VALUES
      .addRadio({
        path: 'reduceOptions.values',
        name: 'Show',
        description: 'Calculate a single value per column or series or show each row',
        settings: {
          options: [
            { value: false, label: 'Calculate' },
            { value: true, label: 'All values' },
          ],
        },
        // category: valueOptionsCategory,
        defaultValue: false,
      })
      // TO GIVE THE LIMIT FOR THE VALUES
      .addNumberInput({
        path: 'reduceOptions.limit',
        name: 'Limit',
        description: 'Max number of rows to display',
        // category: valueOptionsCategory,
        settings: {
          placeholder: '25',
          integer: true,
          min: 1,
          max: 5000,
        },
        showIf: (options) => options.reduceOptions.values === true,
      })
      // CUSTOM EDITOR
      .addCustomEditor({
        id: 'reduceOptions.calcs',
        path: 'reduceOptions.calcs',
        name: 'Value',
        description: 'Choose a reducer function / calculation',
        editor: standardEditorsRegistry.get('stats-picker').editor as any,
        defaultValue: [ReducerID.mean],
        showIf: (c) => {
          return true;
        },
        // Hides it when all values mode is on
        // showIf: currentConfig => currentConfig.reduceOptions.values === false,
      })
      // TO SELECT THE FILEDS
      .addSelect({
        path: 'reduceOptions.fields',
        name: 'Fields',
        description: 'Select the fields that should be included in the panel',
        // category: valueOptionsCategory,
        settings: {
          allowCustomValue: true,
          options: [],
          getOptions: async (context: FieldOverrideContext) => {
            const options = [
              { value: '', label: 'Numeric Fields' },
              { value: '/.*/', label: 'All Fields' },
            ];
            if (context && context.data) {
              for (const frame of context.data) {
                for (const field of frame.fields) {
                  const name = getFieldDisplayName(field, frame, context.data);
                  const value = `/^${escapeStringForRegex(name)}$/`;
                  options.push({ value, label: name });
                }
              }
            }
            return Promise.resolve(options);
          },
        },
        defaultValue: '',
      })
      // TO SELECT THE FONT FAMILY
      .addSelect({
        path: 'wordCloudOptions.fontFamily',
        name: 'Font Family',
        description: 'Mention Font Style that should be applied to each Word',
        defaultValue: 'impact',
        settings: {
          options: fontStyleOptions,
        },
      })
      // CUSTOM EDITOR
      .addCustomEditor({
        id: 'wordCloudOptions',
        path: 'wordCloudOptions',
        name: 'Padding',
        description: 'Select the padding for each Word/Tag',
        editor: wordAttributesEditor,
        defaultValue: {
          rotationAngles: 0,
          padding: 0,
          rotations: 0,
        },
      })
      // TO SET MINIMUM FONT SIZE
      .addSliderInput({
        path: 'minFontSize',
        name: 'Minimum Font Size',
        description: 'Choose Minimum Font Size',
        defaultValue: 8,
        settings: {
          min: 8,
          max: 32,
          step: 1,
        },
      })
      // TO SET MAXIMUM FONT SIZE
      .addSliderInput({
        path: 'maxFontSize',
        name: 'Maximum Font Size',
        description: 'Choose Maximum Font Size',
        defaultValue: 48,
        settings: {
          min: 24,
          max: 100,
          step: 1,
        },
      })
      // TO SELECT TEXT OR VALUE
      .addRadio({
        path: 'wordCloudOptions.display',
        defaultValue: 'value',
        name: 'Display',
        settings: {
          options: [
            {
              value: 'text',
              label: 'Text',
            },
            {
              value: 'value',
              label: 'Value',
            },
          ],
        },
      })
      // RADIO VALUE
      .addRadio({
        path: 'wordCloudOptions.spiral',
        defaultValue: 'archimedean',
        name: 'Wordcloud spiral',
        settings: {
          options: [
            {
              value: 'archimedean',
              label: 'Archimedean',
            },
            {
              value: 'rectangular',
              label: 'Rectangular',
            },
          ],
        },
      })
  );
});
