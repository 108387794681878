import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const User: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 64 64"
      viewBox="0 0 64 64"
      width={size}
      height={size}
      {...rest}
    >
      <path
        d="M32,32c7.7,0,13.9-6.2,13.9-13.9S39.7,4.3,32,4.3s-13.9,6.2-13.9,13.9C18.1,25.8,24.3,32,32,32z M32,8.9
        c5.1,0,9.2,4.1,9.2,9.2s-4.1,9.2-9.2,9.2s-9.2-4.1-9.2-9.2S26.9,8.9,32,8.9z M32,36.6c-11.5,0-20.8,9.3-20.8,20.8
        c0,1.3,1,2.3,2.3,2.3s2.3-1,2.3-2.3c0-8.9,7.2-16.2,16.2-16.2s16.2,7.2,16.2,16.2c0,1.3,1,2.3,2.3,2.3s2.3-1,2.3-2.3
        C52.8,45.9,43.5,36.6,32,36.6z"
      />
    </svg>
  );
};
