import {
  FieldConfigEditorBuilder,
  FieldType,
  identityOverrideProcessor,
  PanelOptionsEditorBuilder,
  StatsPickerConfigSettings,
  standardEditorsRegistry,
  stringOverrideProcessor,
} from '@grafana/data';
import {
  AxisConfig,
  graphFieldOptions,
  AxisPlacement,
  ScaleDistributionConfig,
  ScaleDistribution,
  LegendDisplayMode,
  DrawStyle,
  GraphFieldConfig,
  PointVisibility,
  LineStyle,
} from '@grafana/ui';
import { FillBellowToEditor } from '../timeseries/FillBelowToEditor';
import { LineStyleEditor } from '../timeseries/LineStyleEditor';
import { ScaleDistributionEditor } from '../timeseries/ScaleDistributionEditor';
import { OptionsWithLegend } from '../timeseries/types';

export function addAxisConfig(
  builder: FieldConfigEditorBuilder<AxisConfig>,
  defaultConfig: AxisConfig,
  hideScale?: boolean
) {
  builder
    .addRadio({
      path: 'axisPlacement',
      name: 'Placement',
      category: ['Axis'],
      defaultValue: graphFieldOptions.axisPlacement[0].value,
      settings: {
        options: graphFieldOptions.axisPlacement,
      },
    })
    .addTextInput({
      path: 'axisLabel',
      name: 'Label',
      category: ['Axis'],
      defaultValue: '',
      settings: {
        placeholder: 'Optional text',
      },
      showIf: (c) => c.axisPlacement !== AxisPlacement.Hidden,
      // no matter what the field type is
      shouldApply: () => true,
    })
    .addNumberInput({
      path: 'axisWidth',
      name: 'Width',
      category: ['Axis'],
      settings: {
        placeholder: 'Auto',
      },
      showIf: (c) => c.axisPlacement !== AxisPlacement.Hidden,
    });
  if (!hideScale) {
    builder.addCustomEditor<void, ScaleDistributionConfig>({
      id: 'scaleDistribution',
      path: 'scaleDistribution',
      name: 'Scale',
      category: ['Axis'],
      editor: ScaleDistributionEditor,
      override: ScaleDistributionEditor,
      defaultValue: { type: ScaleDistribution.Linear },
      shouldApply: (f) => f.type === FieldType.number,
      process: identityOverrideProcessor,
    });
  }
}

export function addCandleStickOptions(builder: FieldConfigEditorBuilder<GraphFieldConfig>, cfg: GraphFieldConfig) {
  builder
    .addRadio({
      path: 'drawStyle',
      name: 'Style',
      defaultValue: cfg.drawStyle,
      settings: {
        options: graphFieldOptions.drawStyle,
      },
      category: ['Candlestick timeseries options'],
    })
    .addRadio({
      path: 'lineInterpolation',
      name: 'Line interpolation',
      defaultValue: cfg.lineInterpolation,
      settings: {
        options: graphFieldOptions.lineInterpolation,
      },
      showIf: (c) => c.drawStyle === DrawStyle.Line,
      category: ['Candlestick timeseries options'],
    })
    .addRadio({
      path: 'barAlignment',
      name: 'Bar alignment',
      defaultValue: cfg.barAlignment,
      settings: {
        options: graphFieldOptions.barAlignment,
      },
      showIf: (c) => c.drawStyle === DrawStyle.Bars,
      category: ['Candlestick timeseries options'],
    })
    .addSliderInput({
      path: 'lineWidth',
      name: 'Line width',
      defaultValue: cfg.lineWidth,
      settings: {
        min: 0,
        max: 10,
        step: 1,
      },
      showIf: (c) => c.drawStyle !== DrawStyle.Points,
      category: ['Candlestick timeseries options'],
    })
    .addSliderInput({
      path: 'fillOpacity',
      name: 'Fill opacity',
      defaultValue: cfg.fillOpacity,
      settings: {
        min: 0,
        max: 100,
        step: 1,
      },
      showIf: (c) => c.drawStyle !== DrawStyle.Points,
      category: ['Candlestick timeseries options'],
    })
    .addRadio({
      path: 'gradientMode',
      name: 'Gradient mode',
      defaultValue: graphFieldOptions.fillGradient[0].value,
      settings: {
        options: graphFieldOptions.fillGradient,
      },
      showIf: (c) => c.drawStyle !== DrawStyle.Points,
      category: ['Candlestick timeseries options'],
    })
    .addCustomEditor({
      id: 'fillBelowTo',
      path: 'fillBelowTo',
      name: 'Fill below to',
      editor: FillBellowToEditor,
      override: FillBellowToEditor,
      process: stringOverrideProcessor,
      hideFromDefaults: true,
      shouldApply: (f: any) => true,
      category: ['Candlestick timeseries options'],
    })
    .addCustomEditor<void, LineStyle>({
      id: 'lineStyle',
      path: 'lineStyle',
      name: 'Line style',
      showIf: (c) => c.drawStyle === DrawStyle.Line,
      editor: LineStyleEditor,
      override: LineStyleEditor,
      process: identityOverrideProcessor,
      shouldApply: (f: any) => f.type === FieldType.number,
      category: ['Candlestick timeseries options'],
    })
    .addRadio({
      path: 'spanNulls',
      name: 'Null values',
      defaultValue: false,
      settings: {
        options: [
          { label: 'Gaps', value: false },
          { label: 'Connected', value: true },
        ],
      },
      showIf: (c: any) => c.drawStyle === DrawStyle.Line,
      category: ['Candlestick timeseries options'],
    })
    .addRadio({
      path: 'showPoints',
      name: 'Show points',
      defaultValue: graphFieldOptions.showPoints[0].value,
      settings: {
        options: graphFieldOptions.showPoints,
      },
      showIf: (c: any) => c.drawStyle !== DrawStyle.Points,
      category: ['Candlestick timeseries options'],
    })
    .addSliderInput({
      path: 'pointSize',
      name: 'Point size',
      defaultValue: 5,
      settings: {
        min: 1,
        max: 40,
        step: 1,
      },
      showIf: (c: any) => c.showPoints !== PointVisibility.Never || c.drawStyle === DrawStyle.Points,
      category: ['Candlestick timeseries options'],
    });
}

export function addLegendOptions<T extends OptionsWithLegend>(builder: PanelOptionsEditorBuilder<T>) {
  builder
    .addRadio({
      path: 'legend.displayMode',
      name: 'Legend mode',
      description: '',
      defaultValue: LegendDisplayMode.List,
      category: ['Tooltip & Legend'],
      settings: {
        options: [
          { value: LegendDisplayMode.List, label: 'List' },
          { value: LegendDisplayMode.Table, label: 'Table' },
          { value: LegendDisplayMode.Hidden, label: 'Hidden' },
        ],
      },
    })
    .addRadio({
      path: 'legend.placement',
      name: 'Legend placement',
      description: '',
      defaultValue: 'bottom',
      category: ['Tooltip & Legend'],
      settings: {
        options: [
          { value: 'bottom', label: 'Bottom' },
          { value: 'right', label: 'Right' },
        ],
      },
      showIf: (c) => c.legend.displayMode !== LegendDisplayMode.Hidden,
    })
    .addCustomEditor<StatsPickerConfigSettings, string[]>({
      id: 'legend.calcs',
      path: 'legend.calcs',
      name: 'Legend calculations',
      description: 'Choose a reducer functions / calculations to include in legend',
      category: ['Tooltip & Legend'],
      editor: standardEditorsRegistry.get('stats-picker').editor as any,
      defaultValue: [],
      settings: {
        allowMultiple: true,
      },
      showIf: (currentConfig) => currentConfig.legend.displayMode !== LegendDisplayMode.Hidden,
    });
}
