import React, { useEffect, useImperativeHandle, useMemo, useRef, useState, useLayoutEffect } from 'react';
import {
  // @ts-ignore
  schemeTableau10,
  schemeCategory10,
  schemeAccent,
  schemeDark2,
  schemePaired,
  schemePastel1,
  interpolateCubehelixDefault,
  interpolateSinebow,
  // @ts-ignore
  interpolateCividis,
  schemePastel2,
  schemeSet3,
  interpolateRainbow,
  schemeSet1,
  // @ts-ignore
  interpolateTurbo,
  schemeSet2,
} from 'd3-scale-chromatic';
import { scaleLinear, scaleBand, scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';
import RacingAxisTop from './RacingAxisTop';
import RacingBarGroup from './RacingBarGroup';

interface Props {
  numOfBars: any;
  width: any;
  height: any;
  margin: any;
  keyframes: any;
  onStart: any;
  onStop: any;
  legendBottom: string;
  pallet: string;
  textColor: string;
  legendSize: number;
  speed: any;
}
const RacingBarChart: any = React.forwardRef(
  (
    {
      numOfBars,
      width,
      height,
      margin,
      keyframes,
      onStart,
      onStop,
      legendBottom,
      pallet,
      legendSize,
      textColor,
      speed,
    }: Props,
    ref
  ) => {
    const [{ frameIdx, animationKey, playing }, setAnimation] = useState({
      frameIdx: 0,
      animationKey: 0,
      playing: false,
    });

    const [tell, setTell] = useState(0);
    const updateFrameRef: any = useRef();
    // when replay, increment the key to rerender the chart.
    useEffect(() => {
      if (!updateFrameRef.current) {
        updateFrameRef.current = setTimeout(() => {
          setAnimation(({ frameIdx: prevFrameIdx, playing, ...others }) => {
            const isLastFrame = prevFrameIdx === keyframes.length - 1;
            const nextFrameIdx = isLastFrame ? prevFrameIdx : prevFrameIdx + 1;
            return {
              ...others,
              frameIdx: playing ? nextFrameIdx : prevFrameIdx,
              playing: !!(playing && !isLastFrame),
            };
          });
          updateFrameRef.current = null;
          if (playing) {
            setTell(tell + 1);
          }
        }, +speed);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tell]);
    useEffect(() => {
      if (!updateFrameRef.current) {
        updateFrameRef.current = setTimeout(() => {
          setAnimation(({ frameIdx: prevFrameIdx, playing, ...others }) => {
            const isLastFrame = prevFrameIdx === keyframes.length - 1;
            const nextFrameIdx = isLastFrame ? prevFrameIdx : prevFrameIdx + 1;
            return {
              ...others,
              frameIdx: playing ? nextFrameIdx : prevFrameIdx,
              playing: !!(playing && !isLastFrame),
            };
          });
          updateFrameRef.current = null;
          if (playing) {
            setTell(tell + 1);
          }
        }, +speed);
      }
    });
    const barGroupRef = useRef();
    const axisRef = useRef();

    useImperativeHandle(ref, () => ({
      replay: () => {
        clearTimeout(updateFrameRef.current);

        updateFrameRef.current = null;
        setAnimation(({ animationKey, ...others }) => ({
          ...others,
          frameIdx: 0,
          animationKey: animationKey + 1,
          playing: true,
        }));
      },
      start: () => {
        setAnimation((animation) => ({
          ...animation,
          playing: true,
        }));
      },
      stop: () => {
        setAnimation((animation) => ({
          ...animation,
          playing: false,
        }));
        // @ts-ignore
        barGroupRef.current.stop();
        // @ts-ignore
        axisRef.current.stop();
      },
      playing,
    }));

    const prevPlayingRef = useRef(playing);
    useEffect(() => {
      if (prevPlayingRef.current !== playing) {
        if (playing) {
          onStart();
        } else {
          onStop();
        }
      }
      prevPlayingRef.current = playing;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playing]);
    useLayoutEffect(() => {
      if (barGroupRef.current) {
        if (playing) {
          // @ts-ignore
          barGroupRef.current.start();
          // @ts-ignore
          axisRef.current.start();
        }
      }
    });
    const frame = keyframes[frameIdx];
    const { date: currentDate, data: frameData } = frame;
    const values = frameData.map(({ value }: any) => value);
    const xMax = width - margin.left - margin.right;
    const yMax = height - margin.top - margin.bottom;
    const domainMax = Math.max(...values);

    const xScale = scaleLinear({
      domain: [0, domainMax],
      range: [0, xMax],
    });

    const yScale = useMemo(
      () =>
        scaleBand({
          domain: new Array(numOfBars).fill(0).map((_, idx) => idx),
          range: [0, yMax],
        }),
      [numOfBars, yMax]
    );
    const nameList = useMemo(() => {
      if (keyframes.length === 0) {
        return [];
      }
      return keyframes[0].data.map((d: any) => d.name);
    }, [keyframes]);
    const selectPallet = () => {
      if (pallet === 'schemeTableau10') {
        return schemeTableau10;
      } else if (pallet === 'schemeCategory10') {
        return schemeCategory10;
      } else if (pallet === 'schemeAccent') {
        return schemeAccent;
      } else if (pallet === 'schemeDark2') {
        return schemeDark2;
      } else if (pallet === 'schemePaired') {
        return schemePaired;
      } else if (pallet === 'schemePastel1') {
        return schemePastel1;
      } else if (pallet === 'interpolateCubehelixDefault') {
        return interpolateCubehelixDefault;
      } else if (pallet === 'interpolateSinebow') {
        return interpolateSinebow;
      } else if (pallet === 'interpolateCividis') {
        return interpolateCividis;
      } else if (pallet === 'schemePastel2') {
        return schemePastel2;
      } else if (pallet === 'schemeSet3') {
        return schemeSet3;
      } else if (pallet === 'interpolateRainbow') {
        return interpolateRainbow;
      } else if (pallet === 'schemeSet1') {
        return schemeSet1;
      } else if (pallet === 'interpolateTurbo') {
        return interpolateTurbo;
      } else if (pallet === 'schemeSet2') {
        return schemeSet2;
      } else {
        return schemeTableau10;
      }
    };
    const colorScale = useMemo(
      () =>
        // @ts-ignore
        scaleOrdinal(selectPallet())
          .domain(nameList)
          // @ts-ignore
          .range(selectPallet()),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [nameList]
    );

    // const dateInYear = currentDate.getFullYear();
    let dateInYear: any;
    if (legendBottom === 'toString()') {
      dateInYear = currentDate.toString().split('GMT')[0];
    } else if (legendBottom === 'toLocaleTimeString()') {
      dateInYear = currentDate.toLocaleTimeString();
    } else if (legendBottom === 'toDateString()') {
      dateInYear = currentDate.toDateString();
    } else if (legendBottom === 'toLocaleString()') {
      dateInYear = currentDate.toLocaleString();
    } else if (legendBottom === 'getFullYear()') {
      dateInYear = currentDate.getFullYear();
    } else if (legendBottom === 'getSomething()') {
      dateInYear = currentDate.toDateString().split(' ')[1];
    } else {
      dateInYear = currentDate.toString().split('GMT')[0];
    }

    return (
      <svg width={width} height={height}>
        <Group top={margin.top} left={margin.left} key={animationKey}>
          <RacingBarGroup
            // @ts-ignore
            frameData={frameData.slice(0, numOfBars)}
            xScale={xScale}
            yScale={yScale}
            colorScale={colorScale}
            ref={barGroupRef}
            textColor={textColor}
          />
          <text
            textAnchor="end"
            style={{ fontSize: legendSize + 'rem', fontWeight: 'bold', opacity: '0.5' }}
            x={xMax}
            y={yMax - 30}
            // fill={textColor}
            fill="#85897e"
          >
            {dateInYear}
          </text>
          <line x1={0} y1={0} x2={0} y2={yMax} stroke={textColor} />
          <RacingAxisTop domainMax={domainMax} xMax={xMax} ref={axisRef} textColor={textColor} />
        </Group>
      </svg>
    );
  }
);

RacingBarChart.defaultProps = {
  width: 500,
  height: 600,
  margin: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 100,
  },
};

export default RacingBarChart;
