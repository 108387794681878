import { FieldColorModeId, LoadingState, dateTime } from '@grafana/data';
import { getType } from './csvStringToPanelData';
import { calculateTimeDifference } from './convertToPanelData';

export function jsonStringToPanelData(jsonString: string, onUpdateTimeRange?: (e: any) => void) {
  const data = JSON.parse(jsonString);
  const columnNames = Object.keys(data[0]);
  let columnValues: any = {};
  let timeColumnName = '';

  columnNames.forEach((columnName: any) => {
    columnValues[columnName] = [];
  });

  for (let i = 0; i < data.length; i++) {
    const rowData = data[i];

    for (let j = 0; j < columnNames.length; j++) {
      const columnName = columnNames[j];
      columnValues[columnName].push(rowData[columnName]);

      // Check if the value is of type DateTime
      if (i === 0 && new Date(rowData[columnName]).toString() !== 'Invalid Date') {
        timeColumnName = columnName;
      }
    }
  }

  const timeArray = columnValues[timeColumnName];

  const series: any = {
    length: timeArray.length,
    refId: 'A',
    fields: Object.keys(columnValues).map((key: string) => ({
      name: key,
      type: getType(columnValues[key][0]),
      config: {
        color: {
          mode: FieldColorModeId.PaletteClassic,
        },
      },
      values: {
        length: columnValues[key].length,
        get: (index: number) => columnValues[key][index],
        toArray: () => columnValues[key],
      },
    })),
  };

  const startTime = timeArray[0];
  const endTime = timeArray[timeArray.length - 1];
  const timeRange = {
    from: dateTime(startTime),
    to: dateTime(endTime),
    raw: {
      from: `now${calculateTimeDifference(startTime)}`,
      to: `now${calculateTimeDifference(endTime)}`,
    },
  };

  if (onUpdateTimeRange) {
    onUpdateTimeRange(timeRange);
  }

  const state = LoadingState.Done;
  return { state, series: [series], timeRange };
}
