import { useTheme } from '@grafana/ui';
import React, { useMemo } from 'react';
import { MapSlider } from './Slider';
import { formatTime } from '../utils/prepareFields';

interface SliderWrapperProps {
  intervalMs: number;
  minTime: number;
  maxTime: number;
  isPlaying: boolean;
  onPlaying: (env: any) => void;
  onSliderChange: (value: any) => void;
  currTime: number;
  windowWidth: number;
  isShowInfo: boolean;
}

export const SliderWrapper = ({
  intervalMs,
  minTime,
  maxTime,
  onPlaying,
  isPlaying,
  currTime,
  onSliderChange,
  windowWidth,
  isShowInfo,
}: SliderWrapperProps) => {
  const theme = useTheme();
  const { width, stepsMark } = useMemo(() => {
    switch (true) {
      case windowWidth <= 480:
        return { width: isShowInfo ? '70%' : '90%', stepsMark: 2 };
      case windowWidth > 480 && windowWidth <= 768:
        return { width: '70%', stepsMark: 4 };
      case windowWidth > 768 && windowWidth <= 1024:
        return { width: '60%', stepsMark: 6 };
      case windowWidth > 1024 && windowWidth <= 1200:
        return { width: '45%', stepsMark: 6 };
      case windowWidth > 1200:
        return { width: '45%', stepsMark: 6 };
      default:
        return { width: '45%', stepsMark: 6 };
    }
  }, [windowWidth]);

  const height = useMemo(() => {
    if (formatTime(intervalMs, (maxTime - minTime) / (stepsMark + 2)).length > 5) {
      return '95px';
    } else {
      return '65px';
    }
  }, [intervalMs, maxTime, minTime]);

  return (
    <div
      style={{
        padding: '2px 15px 10px 15px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: '4px',
        position: 'absolute',
        top: '8px',
        right: '10px',
        width,
        backgroundColor: `${theme.colors.bg1}`,
        height: height,
        border: `1px solid ${theme.colors.border1}`,
      }}
    >
      <button
        style={{ background: 'none', color: theme.colors.textBlue, border: 'none' }}
        onClick={(env) => onPlaying(env)}
      >
        {!isPlaying ? (
          <i className="fa fa-play" aria-hidden="true"></i>
        ) : (
          <i className="fa fa-pause" aria-hidden="true"></i>
        )}
      </button>
      <div style={{ marginLeft: '15px', marginBottom: '5px', width: '90%' }}>
        <MapSlider
          minTime={minTime}
          maxTime={maxTime}
          value={currTime}
          onChange={onSliderChange}
          stepsMark={stepsMark}
        />
      </div>
    </div>
  );
};
