import React from 'react';
import { AlignedData } from 'uplot';
import { DataFrame, TimeRange, TimeZone } from '@grafana/data';
import { BoxPlotOptions, BoxPlotData } from '../types';
import { preparePlotConfigBuilderBox, setBoxplotMinMaxRange } from './builder';
import {
  VizLayout,
  Themeable,
  UPlotChart,
  GraphNGLegendEvent,
  withTheme,
  preparePlotData,
  LegendDisplayMode,
  VizLegendOptions,
  UPlotConfigBuilder,
} from '@grafana/ui';
import { TooltipPluginBox } from './TooltipPluginBox';
import { PlotLegendBox } from './PlotLegendBox';
/**
 * @typedef {Object} BoxPlotProps
 * @property {number} width
 * @property {number} height
 * @property {BoxPlotData} dataMain
 * @property {TimeRange} timeRange
 * @property {VizLegendOptions} legend
 * @property {TimeZone} timeZone
 * @property {string} tooltipMode
 * @property {Function} [onLegendClick]
 * @property {Function} [onSeriesColorChange]
 * @property {FieldConfigSource} fieldConfig
 * @property {React.ReactNode} [children]
 * @extends {Themeable}
 * @extends {BoxPlotOptions}
 */

interface Minmaxvalue {
  min: number;
  max: number;
}

export interface BoxPlotProps extends Themeable, BoxPlotOptions {
  width: number;
  height: number;
  dataMain: BoxPlotData;
  timeRange: TimeRange;
  legend: VizLegendOptions;
  timeZone: TimeZone;
  tooltipMode: string;
  onLegendClick?: (event: GraphNGLegendEvent) => void;
  onSeriesColorChange?: (label: string, color: string) => void;
  children?: React.ReactNode;
  minmax: Minmaxvalue;
}

/**
 * @typedef {Object} BoxPlotState
 * @property {AlignedData} data
 * @property {DataFrame} alignedDataFrame
 * @property {UPlotConfigBuilder} config
 * @property {BoxPlotData} dataAll
 */
interface BoxPlotState {
  data: AlignedData;
  alignedDataFrame: DataFrame;
  config: UPlotConfigBuilder;
  dataAll: BoxPlotData;
}

/**
 * BoxPlot chart component fo main work.
 * @class The BoxPlotChart
 * @extends {React.Component<BoxPlotProps, BoxPlotState>}
 */

class BoxPlotChart extends React.Component<BoxPlotProps, BoxPlotState> {
  /**
   * Create the BoxPlotChart.
   * @constructs BoxPlotChart
   */
  constructor(props: BoxPlotProps) {
    super(props);
    this.state = {} as BoxPlotState;
  }
  static getDerivedStateFromProps(props: BoxPlotProps, state: BoxPlotState) {
    const frame = props.dataMain.frames[0];
    const dataAll = props.dataMain;
    if (!frame) {
      return { ...state };
    }

    return {
      ...state,
      data: preparePlotData(frame),
      alignedDataFrame: frame,
      dataAll: dataAll,
    };
  }
  rawValue = (seriesIdx: number, valueIdx: number) => {
    let field = this.props.dataMain.frames[0].fields.find(
      (f: any) => f.type === 'number' && f.state?.seriesIndex === seriesIdx - 1
    );
    return field!.values.get(valueIdx);
  };

  /**
   * update config state when we received aligned data frame
   *
   * @method
   */
  componentDidMount() {
    const { theme } = this.props;
    // alignedDataFrame is already prepared by getDerivedStateFromProps method
    const { alignedDataFrame, dataAll } = this.state;

    if (!alignedDataFrame && !dataAll) {
      return;
    }
    const builplot = preparePlotConfigBuilderBox(alignedDataFrame, theme, dataAll, this.rawValue, this.props);
    //* Update the scale of the boxplot to the min-max values.
    const updateScale = setBoxplotMinMaxRange(this.props.minmax.min, this.props.minmax.max);
    builplot.addScale(updateScale);
    this.setState({
      config: builplot,
    });
  }

  componentDidUpdate(prevProps: BoxPlotProps) {
    const { dataMain, theme, rawValue } = this.props;
    const { alignedDataFrame, dataAll } = this.state;
    let shouldConfigUpdate = false;
    let stateUpdate = {} as BoxPlotState;
    if (
      this.state.config === undefined ||
      this.props.timeZone !== prevProps.timeZone ||
      rawValue !== prevProps.rawValue
    ) {
      shouldConfigUpdate = true;
    }

    if (shouldConfigUpdate || dataMain.frames !== prevProps.dataMain.frames) {
      const builder = preparePlotConfigBuilderBox(alignedDataFrame, theme, dataAll, this.rawValue, this.props);
      //* Update the scale of the boxplot to the min-max values. When the min-max value is update.
      const updateScale = setBoxplotMinMaxRange(this.props.minmax.min, this.props.minmax.max);
      builder.addScale(updateScale);
      stateUpdate = { ...stateUpdate, config: builder };
    }
    if (Object.keys(stateUpdate).length > 0) {
      this.setState(stateUpdate);
    }
  }

  getTimeRange = () => {
    return this.props.timeRange;
  };
  getTimeZone = () => {
    return this.props.timeZone;
  };
  renderLegend() {
    const { legend, onSeriesColorChange, onLegendClick, dataMain, toShowPlot, typeBoxPlot } = this.props;
    const { config } = this.state;
    if (!config || (legend && legend.displayMode === LegendDisplayMode.Hidden)) {
      return;
    }

    return (
      <PlotLegendBox
        data={dataMain.frames}
        config={config}
        onSeriesColorChange={onSeriesColorChange}
        onLegendClick={onLegendClick}
        maxHeight="35%"
        maxWidth="60%"
        {...legend}
        plotType={toShowPlot}
        boxPlotCateg={typeBoxPlot}
        dataMain={dataMain}
      />
    );
  }

  render() {
    const { width, height, children, timeZone, timeRange, tooltipMode, ...plotProps } = this.props;
    const { config, data } = this.state;

    if (!this.state.data || !this.state.config) {
      return null;
    }
    // const newDataBoxTime = preparePlotDataBoxTime(dataAll?.boxTime!);
    // const newDataBox = preparePlotDataBox(dataAll?.box!);
    return (
      <VizLayout width={width} height={height} legend={this.renderLegend()}>
        {(vizWidth: number, vizHeight: number) => (
          <UPlotChart
            {...plotProps}
            config={config!}
            data={data}
            width={vizWidth}
            height={vizHeight}
            timeRange={timeRange}
          >
            {children}
            <TooltipPluginBox
              config={config}
              mode={tooltipMode}
              data={this.props.dataMain.frames[0]}
              timeZone={this.props.timeZone}
              dataMain={plotProps.dataMain}
              plotType={plotProps.toShowPlot}
              boxPlotCateg={plotProps.typeBoxPlot}
            />
          </UPlotChart>
        )}
      </VizLayout>
    );
  }
}

export const BoxPlot = withTheme(BoxPlotChart);
BoxPlot.displayName = 'BoxPlot';
