import React, { useState } from 'react';
import { Slider } from '@grafana/ui';

export default function FieldSlider({ changer, data, type, min, max, step }: any) {
  const [sv, setSv] = useState<number>(data);
  const sliderHandler = (e: any) => {
    setSv(e);
    changer(e);
  };
  if (type === 'first') {
    return (
      <div>
        <Slider min={min} max={max} value={sv} step={step} onChange={sliderHandler} />
      </div>
    );
  } else {
    return null;
  }
}

// export default FieldSlider;
